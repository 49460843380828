import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { AppComponent } from './app.component';
import { appRoutes } from './app.routes';
import { RollbarErrorHandler, SharedModule } from '@phase-ii/shared';
import { environment } from '../environments/environment';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { TokenInterceptor } from '@phase-ii/auth';
import { ReferralCodeModule } from './referral-code/referral-code.module';
import { ReactiveFormsModule } from '@angular/forms';
import { AffiliateSharedModule } from './shared/shared.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { PayoutRequestModule } from './payout-request/payout-request.module';
import { UsersModule } from './users/users.module';
import { AccountDetailsModule } from './account-details/account-details.module';
import { AuthModule } from './auth/auth.module';
import { SettingsModule } from './settings/settings.module';
import { PayoutLogsModule } from './payout-logs/payout-logs.module';
import { initializeAppFactory } from './app.initializer';
import { BackendConfigService } from '@phase-ii/common';


@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    HttpClientModule,
    AffiliateSharedModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    SharedModule,
    ReferralCodeModule,
    AuthModule,
    PayoutRequestModule,
    UsersModule,
    ReferralCodeModule,
    AccountDetailsModule,
    SettingsModule, PayoutLogsModule,
    RouterModule.forRoot(appRoutes, { initialNavigation: 'enabledBlocking' }),
  ],
  providers: [
    { provide: 'environment', useValue: environment },
    { provide: ErrorHandler, useClass: RollbarErrorHandler },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: initializeAppFactory,
      deps: [BackendConfigService],
      multi: true
    }
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
