import { SelectionModel } from '@angular/cdk/collections';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'phase-ii-common-sort',
  templateUrl: './common-sort.component.html',
  styleUrls: ['./common-sort.component.scss'],
})
export class CommonSortComponent implements OnInit {
  /**
   * Input variable is Userd to  data alignment in the data card (if true, all data are aligned in the left side or its aligned canter).
   * @type { any }
   */
  @Input() isLeftAlign: boolean = null;
  /**
   * Input variable which is used to decide whether the page is readonly to the user
   * @type { any }
   */
  @Input() clickable: boolean = false;
  /**
   * Input variable that is used to hold the properties of the table and its related actions
   * @type { any }
   */
  @Input() sortlistProperties: any;
  /**
   * Input variable which is used to store the details about each column in tables and its related types, conditions, and designs
   * @type { any }
   */
  @Input() sortColumnsRef: any;
  /**
   * Input variable used to store the Actions icon of the card's right top sections (it can be icons or buttons).
   * @type { any }
   */
  @Input() headerActions: any;
  /**
   * Input variable that is used to store the actions (icons of each row) in that card and that is common to all rows in that card
   * @type { any[] }
   */
  // @Input() RowActionIcon: any[];
  /**
   * variable that is used to store the current display data for the page.
   * @type { any }
   */
  @Input() listData: any;
  /**
   * variable that is used to store the sorted order position INDEX.
   * @type { any }
   */
  @Input() sortedIndexName: any;
  /**
   * Input variable that is used to display the error message in the table
   * @type { any }
   */
  @Input() errorHandler: boolean = false;
  /**
   * variable that is used to decide the fxFlex value based on column count
   * @type { number }
   */
  fxFlex: number = 15;
  /**
   * Event Emitter, which is used to emit a (** single row of data **) while clicking any actions on the row.
   * @type { any }
   */
  @Output() actionClicked: any = new EventEmitter<any>();
  /**
   * Event Emitter which is used to clicked Header button or Icon click  emit the data and its icon action to the parent component.
   * @type { any }
   */
  @Output() HeadericonClicked: any = new EventEmitter<any>();
  /**
   * Event Emitter which is used to emit the (** selected row of the data **) to the parent component.
   * @type { any }
   */
  @Output() selectedData: any = new EventEmitter<any>();
  /**
   * Event Emitter which is used to emit the (** sorted data **) to the parent component.
   * @type { any }
   */
  @Output() sortedData: any = new EventEmitter<any>();
  /**
   * Event Emitter which is used to emit the clicked row and checkbox state(On/Off) to the parent component.
   * @type { any }
   */
  @Output() checkBoxChanged: any = new EventEmitter<any>();
  /**
   * Its variable Used to Store Sorted Array List
   */
  sortArray: any = [];
  /**
   * Selection Model which is used in checkbox multiselect to store the multiselect
   * @type { any }
   */
  selection: any = new SelectionModel<any>(true, []);
  /**
   * Input variable which is used to decide whether the loader is displaying or not in some cases
   * @type { b }
   */
  @Input() displayLoader: boolean = false;

  // //@input() headerActions
  // headerActions = [
  //   { name: 'Save', value: 'Save', toolTip: "Save Sort", buttonText: "Save" },
  //   { name: 'next', value: 'next-btn', iconColor: 'var(--primary-color)', isConditionApplied: false, buttonText: 'Next', buttonType: 'secondary-button' },
  //   { name: 'filter_list', value: 'filter', toolTip: "Filter", isConditionApplied: true, conditionType: 'DATA_EMPTY' }
  // ];
  // // @input() listActionIcon
  // listActionIcon = [{ iconName: ' open_with ', method: 'onRowSelect', toolTip: 'replace', iconColor: '#0492c2' }]
  // // @input() listData
  // listData = [
  //   {
  //     "id": 1,
  //     "storeId": 28,
  //     "importId": null,
  //     "lastModifiedBy": null,
  //     "isDeleted": false,
  //     "code": "UPSELL_PRODUCT",
  //     "sortOrder": 14,
  //     "createdAt": "2022-12-21T11:27:28.377Z",
  //     "modifiedAt": "2022-12-21T11:27:28.377Z",
  //     "isLinkBackProduct": false,
  //     "productId": 20991,
  //     "relatedProductId": 16791,
  //     "Product Name": "Sonata Watch",
  //     "FeaturedImage": "https://zenbasket-prod.s3.ap-south-1.amazonaws.com/gallery/attachment1669379685715.png"
  //   },
  //   {
  //     "id": 2,
  //     "storeId": 28,
  //     "importId": null,
  //     "lastModifiedBy": null,
  //     "isDeleted": false,
  //     "code": "UPSELL_PRODUCT",
  //     "sortOrder": 14,
  //     "createdAt": "2022-12-21T11:27:28.377Z",
  //     "modifiedAt": "2022-12-21T11:27:28.377Z",
  //     "isLinkBackProduct": false,
  //     "productId": 20991,
  //     "relatedProductId": 16791,
  //     "Product Name": "masala pori",
  //     "FeaturedImage": "https://zenbasket-prod.s3.ap-south-1.amazonaws.com/gallery/attachment1669379708255.png"
  //   },
  //   {
  //     "id": 3,
  //     "storeId": 28,
  //     "importId": null,
  //     "lastModifiedBy": null,
  //     "isDeleted": false,
  //     "code": "UPSELL_PRODUCT",
  //     "sortOrder": 14,
  //     "createdAt": "2022-12-21T11:27:28.377Z",
  //     "modifiedAt": "2022-12-21T11:27:28.377Z",
  //     "isLinkBackProduct": false,
  //     "productId": 20991,
  //     "relatedProductId": 16791,
  //     "Product Name": "Mobile",
  //     "FeaturedImage": "https://zenbasket-prod.s3.ap-south-1.amazonaws.com/gallery/attachment1669379730460.png"
  //   }
  // ];
  // // @input sortColumnsRef
  // sortColumnsRef = [
  //   { column: 'FeaturedImage', type: 'Image', class: 'order-id', suffixText: 'Product Image', style: 'square' },
  //   { column: 'Product Name', type: 'Text', class: 'order-id', suffixText: 'Product Name' },
  // ];
  // // @input sortlistProperties
  // sortlistProperties = {
  //   checkBox: true,
  //   profileImage: false,
  //   columns: 3,
  //   rowElementClicked: false,
  //   dataOnly: true,
  //   cardMinHeight: '93px',
  //   suffixText: false,
  //   ShuffleButton:
  //   {
  //     condition: true,
  //     buttonType: null,
  //   },
  //   tableTitleHeader: {
  //     condition: true,
  //     title: "Cross Sell Product List",
  //     color: null
  //   }
  // };
  /**
   * Angular life cyle hook that initiates the component. 
   */
  ngOnInit(): void {
    this.fxFlex = 100 / (this.sortColumnsRef && this.sortColumnsRef.length);
    if (this.isLeftAlign == null) {
      this.isLeftAlign = this.sortColumnsRef && this.sortColumnsRef.length && this.sortColumnsRef.length <= 2;
    }
    this.displayLoader = this.listData ? false : true;
  }
  /**
   * Method to emit the method while clicking the header action icons and buttons to emit the parent component.
   * @param value To get selected icon or Button value
   * @param icon  To get selected icon or Button datas
   */
  iconEvent(value: any, icon?: any): void {
    this.HeadericonClicked.emit(value);
  }
  /**
   * actionClicked event will emit the icon and data in parent component.
   * @param event - To get click event .
   * @param icon  - To get the Icon Info.
   * @param data  - To get Selected Row data.
   */
  actionClick(event: any, icon: any, data: any): void {
    this.actionClicked.emit(Object.assign({}, icon, { data: data }));
  }
  /**
   * Method Which is used to Perform action during row element click
   * @param data - To get Selected Row data.
   */
  rowClick(data: any): void {
    if (this.sortlistProperties && this.sortlistProperties.rowElementClicked) {
      this.selectedData.emit(data);
    }
  }
  /**
   * Method which is changing sorting position by dragging and dropping in order 
   * @param event 
   */
  dropAction(event: CdkDragDrop<string[]>): void {
    if (event && event.previousContainer === event.container) {
      moveItemInArray(event.container && event.container.data, event.previousIndex, event.currentIndex);
    }
    this.sortArray = event && event.previousContainer && event.previousContainer.data;
    for (let i = 0; i < this.sortArray.length; i++) {
      this.sortArray[i][this.sortedIndexName] = i + 1;
    }
    this.sortedData.emit(this.sortArray);
  }
  /**
   * Method which is used to display data position change in randamly
   */
  shuffleButtonClick() {
    this.listData.sort(() => { return 0.5 - Math.random() });
    this.sortedData.emit(this.sortArray);
  }
  /**
   * Method Which is used change ToolTip Condition 
   * @param condition - Conditions to get tooltip based on different conditions
   * @param data - the data value in that column to get proper tooltip for that column.
   * @param defaultToolTip - default tooltip if no conditions are matched
   * @returns it returns the tooltip for the coloumn based on conditions
   */
  getToolTipForColumn(condition: any, data: any, defaultToolTip: any): string {
    let toolTip = condition[data] ? condition[data] : defaultToolTip;
    return (toolTip && toolTip != undefined) ? toolTip : '';
  }
  /**
   * Method Which is Used to change column icons
   * @param condition - Conditions to get icon based on different conditions
   * @param data - the data value in that column to get proper icon for that
   * @param defaultIcon - default icon if no conditions are matched
   * @returns it returns the icon for the coloumn based on conditions
   */
  getIconForColumn(condition: any, data: any, defaultIcon: any): string {
    let icon = condition[data] ? condition[data] : defaultIcon;
    return (icon && icon != undefined) ? icon : 'label';
  }
  /**
   * Function called when the user clicks on master check box and it will emit the checkd CheckBox Value.
   */
  masterToggle() {
    this.isAllSelected() ? this.selection.clear() : this.listData && this.listData.forEach((row: any) => this.selection.select(row));
    this.checkBoxChanged.emit(this.selection.selected);
  }
  /**
   * Method is check all check box is checked or Not
   * @returns it will return boolan value based on all seleted items in that display
   */
  isAllSelected() {
    const numSelected = this.selection && this.selection.selected && this.selection.selected.length;
    const numRows = this.listData && this.listData.length;
    return numSelected === numRows;
  }
  /**
   * Method Which is used to emit Check box Row Single line click. 
   * @param event - click event for checkbox click
   * @param data  - it passes the row data of clicked row
   */
  singleCheckBoxClick(event: any, data: any) {
    this.selection.toggle(data);
    this.checkBoxChanged.emit(this.selection && this.selection.selected);
  }
}
