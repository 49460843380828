<div class="page" *ngIf="historyLogs?.length > 0">
    <section class="timeline">
        <div class="container">
            <div class="timeline-item" *ngFor="let item of historyLogs;let i=index;">
                <div class="timeline-content js--fadeInLeft" *ngIf="i < 4">
                    <div class="date">{{ item?.createdAt | date }}</div>
                    <p class="comments">{{ item?.orderStatus ? item?.orderStatus : item?.comments }}</p>
                </div>
            </div>
        </div>
        <div style="float: right;" *ngIf="historyLogs?.length >= 5">
            <a class="show-more" data-control="show-more" (click)="showMore();">{{isShowMore?"Show Less":"Show
                More"}}</a>
        </div>
    </section>
</div>
<div *ngIf="isShowMore" fxLayout="row" fxLayoutAlign="center center">
    <mat-card fxFlex="80">
        <phase-ii-common-card-v-ii [needPaginator]="true" [listProperties]="pageDetails?.listProperty"
            [columnsRef]="pageDetails?.columnRef" [actionsIcon]="pageDetails?.actionIcons"
            (actionClicked)="listAction($event)" (valueChanged)="getChangedData($event)"
            (searchedText)="onSearch($event)" [paginatorVersion]="2">
        </phase-ii-common-card-v-ii>
    </mat-card>
</div>