/**
 * Class wish is used to store default values for storefront.
 */
export class SharedConstant {
  /**
  * Variable menu used to store the blog sort details.
  */
  blogSort = [
    { label: 'Blog Post Title (A-Z)', column: 'title', order: 'ASC' },
    { label: 'Blog Post Title (Z-A)', column: 'title', order: 'DESC' },
    { label: 'Newest First', column: 'createdAt', order: 'DESC' },
    { label: 'Oldest First', column: 'createdAt', order: 'ASC' }];
  dialogButtonText = { deleteAll: 'deleteAll', cancel: 'cancel', okay: 'Okay', yes: 'Yes', no: 'No' };
  /**
  * Variable menu used to store the menu details.
  */
  menu = [
    {
      "isDefault": true,
      "pageCustomIcon": "shopping_cart",
      "pageCustomLink": null,
      "pageCustomTitle": null
    },
    {
      "isDefault": true,
      "pageCustomIcon": "notifications_active",
      "pageCustomLink": null,
      "pageCustomTitle": null
    }, {
      "isDefault": true,
      "pageCustomIcon": "account_circle",
      "pageCustomLink": null,
      "pageCustomTitle": null,
      "subMenu": [
        {
          "pageCustomTitle": "My Account",
          "pageCustomLink": "/app/adminprofile",
          "isReadOnly": false
        },
        // {
        //   "isDefault": false,
        //   "pageCustomTitle": "My Account",
        //   "pageCustomLink": "/app/adminprofile/:token",
        //   "isReadOnly": false
        // },
        {
          "pageCustomTitle": "Service ",
          "pageCustomLink": "/app/termsofservice",
          "isReadOnly": false
        },
        {
          "pageCustomTitle": "Billing & Plans",
          "pageCustomLink": "/app/subscriptionhistory",
          "isReadOnly": false
        },
        {
          "pageCustomTitle": "Activity Logs",
          "pageCustomLink": "/app/users/adminloginhistory/true",
          "isReadOnly": false
        },
        {
          "pageCustomTitle": "Logout",
          "pageCustomLink": "/signin",
          "isReadOnly": false
        },
      ]
    }, {
      "isDefault": false,
      // "pageCustomIcon": "notifications_active",
      "pageCustomLink": '/app/upgradeplans/:id',
      "pageCustomTitle": 'Upgrade Plan',
      subMenu: []
    }, {
      "isDefault": false,
      // "pageCustomIcon": "notifications_active",
      "pageCustomLink": '/app/revenue-report',
      "pageCustomTitle": 'Revenue Report',
      subMenu: []
    }, {
      "isDefault": false,
      // "pageCustomIcon": "notifications_active",
      "pageCustomLink": '/app/product-report',
      "pageCustomTitle": 'Product Report',
      subMenu: []
    }, {
      "isDefault": false,
      // "pageCustomIcon": "notifications_active",
      "pageCustomLink": '/app/order-report',
      "pageCustomTitle": 'Order Report',
      subMenu: []
    }, {
      "isDefault": false,
      // "pageCustomIcon": "notifications_active",
      "pageCustomLink": '/app/customer-report',
      "pageCustomTitle": 'Customer Report',
      subMenu: []
    }, {
      "isDefault": false,
      // "pageCustomIcon": "notifications_active",
      "pageCustomLink": '/app/offer-report',
      "pageCustomTitle": 'Offer Report',
      subMenu: []
    },
    {
      "isDefault": false,
      "pageCustomLink": "/app/updatecard",
      "pageCustomTitle": "updatecard",
      subMenu: []
    },
    {
      "isDefault": false,
      "pageCustomTitle": "My Account",
      "pageCustomLink": "/app/adminprofile/:token",
      "isReadOnly": false,
      subMenu: []
    },
    {
      "isDefault": false,
      "pageCustomLink": '/app/automatic-offer-report',
      "pageCustomTitle": 'Automatic Offer Report',
      subMenu: []
    },
    {
      "isDefault": false,
      "pageCustomLink": '/app/buyx-gety-offer-report',
      "pageCustomTitle": 'Buy X Get Y Offer Report',
      subMenu: []
    },
    {
      "isDefault": false,
      "pageCustomLink": '/app/payx-get-free-offer-report',
      "pageCustomTitle": 'Pay X Get Free Offer Report',
      subMenu: []
    }
    // {
    //   "isDefault": false,
    //   // "pageCustomIcon": "notifications_active",
    //   "pageCustomLink": '/app/settings/companydetails',
    //   "pageCustomTitle": 'storedetails',
    //   subMenu: []
    // },
    // {
    //   "isDefault": false,
    //   // "pageCustomIcon": "notifications_active",
    //   "pageCustomLink": '/app/settings/locationslist',
    //   "pageCustomTitle": 'storedetails',
    //   subMenu: []
    // }
  ];
  /**
   * Variable menu used to store the menu details.
   */
  topMenu = [
    {
      "isDefault": true,
      "pageCustomIcon": "live_help",
      "pageCustomLink": null,
      "pageCustomTitle": null,
      "subMenu": [
        {
          "pageCustomTitle": "Help Center",
          "pageCustomLink": "https://getzenbasket.com/help-center/",
          "notOnlyTopNav": true,
          "isReadOnly": false
        },
        {
          "pageCustomTitle": "Videos",
          "pageCustomLink": "https://www.youtube.com/@zenbasketecommerce/videos",
          "notOnlyTopNav": true,
          "isReadOnly": false
        },
        {
          "pageCustomTitle": "Blogs",
          "pageCustomLink": "https://getzenbasket.com/blogs/",
          "notOnlyTopNav": true,
          "isReadOnly": false
        },
        {
          "pageCustomTitle": "Contact us",
          "pageCustomLink": "https://zenbasket.in/contactus/",
          "notOnlyTopNav": true,
          "isReadOnly": false
        }
      ]
    },
    {
      "isDefault": true,
      "pageCustomIcon": "shopping_cart",
      "pageCustomLink": null,
      "pageCustomTitle": null
    },
    {
      "isDefault": true,
      "pageCustomIcon": "notifications_active",
      "pageCustomLink": null,
      "pageCustomTitle": null,
      "subMenu": [
        {
          "pageCustomTitle": "Orders",
          "pageCustomLink": "/app/orders/orderlist/NEW",
          "notOnlyTopNav": true,
          "isReadOnly": false
        },
        {
          "pageCustomTitle": "Expired Orders",
          "pageCustomLink": "/app/orders/orderlist/ExpiredOrders",
          "notOnlyTopNav": true,
          "isReadOnly": false
        },
        {
          "pageCustomTitle": "Pre-ordered Orders",
          "pageCustomLink": "/app/products/preorderCustomer/NEW",
          "isReadOnly": false
        },
        {
          "pageCustomTitle": "Queries",
          "pageCustomLink": "/app/customer/customerqueries/NEW",
          "isReadOnly": false
        }
      ]

    },
    {
      "isDefault": true,
      "pageCustomIcon": "account_circle",
      "pageCustomLink": null,
      "pageCustomTitle": null,
      "subMenu": [
        {
          "pageCustomTitle": "My Account",
          "pageCustomLink": "/app/adminprofile",
          "isReadOnly": false
        },
        // {
        //   "pageCustomTitle": "Help Center",
        //   "subMenu": [
        //     // {
        //     //   "pageCustomTitle": "Contact us",
        //     //   "pageCustomLink": "https://zenbasket.in/contactus/",
        //     //   "isReadOnly": false
        //     // },
        //     {
        //       "pageCustomTitle": "Tutorials",
        //       "pageCustomLink": "https://www.youtube.com/@zenbasketecommerce/videos",
        //       "isReadOnly": false
        //     },
        //   ]
        // },
        // {
        //   "isDefault": false,
        //   "pageCustomTitle": "My Account",
        //   "pageCustomLink": "/app/adminprofile/:token",
        //   "isReadOnly": false
        // },
        {
          "pageCustomTitle": "Service Agreement",
          "pageCustomLink": "/app/termsofservice",
          "isReadOnly": false
        },
        {
          "pageCustomTitle": "Billing & Plans",
          "pageCustomLink": "/app/subscriptionhistory",
          "isReadOnly": false
        },
        {
          "pageCustomTitle": "Activity Logs",
          // "pageCustomLink": "/app/users/userloginhistory",
          "pageCustomLink": "/app/users/adminloginhistory/true",
          "isReadOnly": false
        },
        {
          "pageCustomTitle": "Admin Apps",
          "iconData": [
            { "src": "./assets/play-store.png", "link": "https://play.google.com/store/apps/details?id=com.zenbasketadmin" },
            { "src": "./assets/ios-store.png", "link": "https://apps.apple.com/us/app/zenbasket-store-admin/id1621651220" },
          ],
          "isReadOnly": false
        },
        {
          "pageCustomTitle": "Logout",
          "pageCustomLink": "/signin",
          "isReadOnly": false
        },
      ]
    }, {
      "isDefault": false,
      // "pageCustomIcon": "notifications_active",
      "pageCustomLink": '/app/upgradeplans/:id',
      "pageCustomTitle": 'Upgrade Plan',
      subMenu: []
    }, {
      "isDefault": false,
      // "pageCustomIcon": "notifications_active",
      "pageCustomLink": '/app/mobileplans/:storeId',
      "pageCustomTitle": 'Mobile Subscription Plan',
      subMenu: []
    }, {
      "isDefault": false,
      // "pageCustomIcon": "notifications_active",
      "pageCustomLink": '/app/revenue-report',
      "pageCustomTitle": 'Revenue Report',
      subMenu: []
    }, {
      "isDefault": false,
      // "pageCustomIcon": "notifications_active",
      "pageCustomLink": '/app/product-report',
      "pageCustomTitle": 'Product Report',
      subMenu: []
    }, {
      "isDefault": false,
      // "pageCustomIcon": "notifications_active",
      "pageCustomLink": '/app/order-report',
      "pageCustomTitle": 'Order Report',
      subMenu: []
    }, {
      "isDefault": false,
      // "pageCustomIcon": "notifications_active",
      "pageCustomLink": '/app/customer-report',
      "pageCustomTitle": 'Customer Report',
      subMenu: []
    }, {
      "isDefault": false,
      // "pageCustomIcon": "notifications_active",
      "pageCustomLink": '/app/offer-report',
      "pageCustomTitle": 'Offer Report',
      subMenu: []
    },
    {
      "isDefault": false,
      "pageCustomLink": "/app/updatecard",
      "pageCustomTitle": "updatecard",
      subMenu: []
    },
    {
      "isDefault": false,
      "pageCustomTitle": "My Account",
      "pageCustomLink": "/app/adminprofile/:token",
      "isReadOnly": false,
      subMenu: []
    },
    {
      "isDefault": false,
      "pageCustomLink": '/app/automatic-offer-report',
      "pageCustomTitle": 'Automatic Offer Report',
      subMenu: []
    },
    {
      "isDefault": false,
      "pageCustomLink": '/app/buyx-gety-offer-report',
      "pageCustomTitle": 'Buy X Get Y Offer Report',
      subMenu: []
    },
    {
      "isDefault": false,
      "pageCustomLink": '/app/payx-get-free-offer-report',
      "pageCustomTitle": 'Pay X Get Free Offer Report',
      subMenu: []
    }
    // {
    //   "isDefault": false,
    //   // "pageCustomIcon": "notifications_active",
    //   "pageCustomLink": '/app/settings/companydetails',
    //   "pageCustomTitle": 'storedetails',
    //   subMenu: []
    // },
    // {
    //   "isDefault": false,
    //   // "pageCustomIcon": "notifications_active",
    //   "pageCustomLink": '/app/settings/locationslist',
    //   "pageCustomTitle": 'storedetails',
    //   subMenu: []
    // }
  ];
  /**
    * Variable dashboardMenu used to store the dashboard Menu details.
    */
  dashboardMenu = {
    isDefault: true,
    pageCustomIcon: "dashboard",
    pageCustomLink: "/app/dashboard",
    pageCustomTitle: "Dashboard",
    subMenu: [],

  };
  /**
   * dialog box messages for store front nav bar.
   * @type {object}
   */
  dialogMessages = {
    enterValidData: "Please enter valid data",
    sheetMissing: 'Sheet is either missing or mismatched',
    templateRestored: 'Template restored successfully',
    MailRestoreTemplate: 'Are you sure you want to restore this Mail template?',
    fetchMenuFailed: 'Something went wrong.Please try again later!',
    fetchDiscountFailed: 'Failed to load discount details.',
    loadUserHistoryDataFailed: 'Failed to load User Histories..',
    reviewUpdationFailed: "Failed to update your review. Please try again after some time",
    reviewUpdationSuccess: "Review updated successfully",
    sortMenuSuccess: "Menu Sorted successfully!",
    sortMenuFailed: "Menu Sorting failed!",
    noSort: "You have no sort changes!",
    domainDeleteConfirmation: "Are you sure you want to remove your domain name?",
    errorDeletingDomain: "Error in deleting domains. Please try again.",
    closeDomain: "You have unsaved changes. Are you sure you want to close?",
    domainDeleteSuccess: "The domain deletion process was initiated successfully. It may take up to 30 minutes for your domain to be deleted.",
    domainCreatedSuccessfully: "Domain Created Successfully. Please wait up to 10 minutes for CName and CValue generation, and check again after 10 minutes.",
    domainVerificationNeeded: "Your domain configuration has yet to be generated. Please try again after 10 minutes.",
    domainActivationNeeded: "Your domain has not been activated yet. Please try again after 10 minutes.",
    domainActivationFailed: "Failed to get domain activation status.",
    domainCreationFailed: "Domain Creation failed. Please try again.",
    backToCheckout: 'Are you sure by leaving this page you will lose your selected payment details?',
    unsavedChanges: 'You have unsaved changes. Are you sure you want to leave this page?',
    sureToLeave: 'Are you sure you want to leave?',
    checkAfterFiveMinutes: 'CName and CValue has not yet been generated. Please check again after some time',
    getFailed: "Failed to get data",
    sureToClose: 'Are you sure you want to close?',
    giftCardFetchFailed: 'Failed to retrieve gift card details.',
    blogFetchFailed: 'Failed to get blog details!',
    postCommentSuccess: 'Your comment was posted successfully! Thank you!',
    pendingPostCommentSuccess: 'Your comment was posted successfully. We will publish it in a little while, as our blog is moderated.',
    giftCardDeleteConfirmation: 'Do you want to remove the applied gift card?',
    paymentPluginDisabled: 'Online Payment plugins are not enabled!',
    giftCardPaymentPluginDisabled: 'Plugins for online payments are not active.',
    themeDashboardRestriction: 'No locations added. Kindly add locations to edit the design.',
    notificationStoreNameWarning: 'NO Store Found',
    notificationStoreNameError: 'Error while getting  store name. Please try again later!',
    removeSection: 'Are you sure you want to remove this section?',
    mandatoryFieldFill: "Please fill out the mandatory fields.",
    fetchBlogFailed: "Failed to fetch blog details",
    deleteSectionRestriction: 'There should be atleast one section for a page',
    digitalproductFetchFailed: 'Failure to retrieve digital product details.',
    useDetailsFetchFailed: 'Failed to fetch user details',
    storeFrontLogout: 'Are you sure you want to logout?',
    s3ImgSizeExceeded: 'Your Storage limit has been exceeded! Please upgrade your plan to add more images.',
    alertforLoginToPurchaseGiftCard: 'Kindly Register or log in to the store to buy a gift card.',
    failedToFetchProductDetails: 'Failed to fetch product details',
    timeZoneError: 'Failed to get the time zone',
    tryAgain: 'Please Try Again Later ',
    referralCodeErr: 'Failed to check referral code',
    timerLogPluginOff: 'Sales countdown timer plugin is turned off. No further action can be taken.',
    limitsFailed: 'Failed to fetch limits',
    updatedomainSuccessfully: 'Sub-domain Name updated successfully!',
    somethingWentWrong: 'Something went wrong.',
    cantDeleteDefaultSection: 'This default section cannot be removed from the page.',
    translationFailed: 'Failed to translate Data',
    translationMissing: 'Failed to get translation data',
    ckEditorWarning: 'Kindly wait, as the editor may require some time to load.',
    translateFormWarning: 'You have no changes to save.',
    autoTranslationInfo: 'The manual entries will not be replaced by auto-translation.',
    autoTranslationWarning: 'You\'re Already Translated',
    ensureAllFiledsEmpty: 'For Auto Translation, at least one field needs to be empty.',
    noTranslatedDataMessage: 'There is no data available for translation.',
    noData: 'There is no item to translate.',
    translatedSuccess: 'Data translated successfully.',
    translatedFailed: 'Data translation failed.',
    updateFailed: 'Failed to update the translated data',
    saveFailed: 'Failed to save the translated data',
    autoTranslateUpdateFailed: 'Failed to update the auto-translated data.',
    returnRequestPluginOff: 'Return request plugin is turned off. No further action can be taken.',
    returnRequestFetchFailed: 'Failed to get return request details.',
    preProcessIdFailure: 'Something went wrong. Please try again after some time.'
  };
  /**
   * Variable for ribbon.
   * @type {object}
   */
  ribbons: any = { positionName: 'RIBBON_POSITIONS' };
  /**
   * Variable used to store role name.
   * @type {array}
   */
  roleName: Array<any> = ['Customer', 'Guest'];
  /**
   * Login hostory filters.
   * @type {array}
   */
  historyFilterOption: Array<any> = [
    'Today', 'Yesterday',
    'This Week',
    'This Month',
    // 'This Year',
    // 'Custom Date',
  ];
  /**
   * Variable historyDetailsTitle used to list the history page title
   * @type {string}
   */
  historyDetailsTitle: string = 'Login History Details';
  /**
   * Variable superAdminCode is for super admin role code.
   * @type {string}
   */
  storeAdminCode: string = 'STOREADMIN';
  /**
   * variable which is used to store dialog type.
   * @type {object}
   */
  dialogType: any = {
    alert: 'alert',
    confirmation: 'confirmation',
    information: 'information',
    success: 'success',
    failure: 'failure',
    warning: 'warning',
    earningPoints: 'earningPoints'
  };
  /**
   * Variable which is used to store the styles of the dialog.
   * @type {array}
   */
  dialogStyle: Array<any> = [
    { name: 'success', color: 'hsl(162deg 57% 49%)' },
    { name: 'failure', color: '#ff5349' },
    { name: 'alert', color: 'gray' },
    { name: 'information', color: 'gray' },
    { name: 'confirmation', color: 'gray' },
  ];
  /**
   * variable which is used to store the button name.
   * @type {object}
   */
  buttonText = {
    ok: 'Okay',
    yes: 'Yes',
    no: 'No',
    cancel: 'Cancel',
    submit: 'Submit',
    save: 'Save',
    leave: 'Leave',
    stay: 'Stay',
    continue: 'Continue',
    publish: 'Publish'
  };
  /**
   * variable which is used to store the header of the dialog.
   * @type {object}
   */
  dialogHeader: any = {
    confirmation: 'Confirmation',
    information: 'Information',
    alert: 'Alert',
    warning: 'Warning',
    success: 'Success',
    failure: 'Failure',
    areYouSure: 'Are you sure?',
  };
  /**
   * variable which is used to store all the messages to be displayed.
   * @type {object}
   */
  message = {
    orderCode: 'ORDERS',
    returnNotificationCode: 'RETURN_NOTIFICATION',
    smsTemplate: 'SMS Templates',
    emailTemplate: 'Email Templates',
    firstName: 'User First Name',
    lastName: 'User Last Name',
    email: 'email',
    sms: 'sms',
    IsAvailable: 'available',
    notAuthorized: 'Not Authorized',
    maxFileCount: 'Pls Contact Admin to add more than {{count}} files',
    unsavedChanges:
      'You have unsaved changes. Are you sure you want to leave this page?',
    dialogClose: 'Are you sure you want to close?',
    orderPlacedSuccess: 'Order placed successfully!',
    checkConnection: 'Check your internet connection',
    invalidCredential: 'Invalid username or password, please try again',
    fieldRequired: 'This field is required',
    invalidField: 'This field is invalid',
    invalidName: 'Please enter a valid name!',
    invalidEmail: 'Please enter a valid email!',
    incorrectPassword:
      'The password you entered is incorrect. Please try again.',
    incorrectPasswordLength: 'Password should contain 8 characters!',
    invalidPhoneNumber: 'Please enter a valid phone number!',
    invalidCountryCode: 'Please enter a valid country code!',
    emailExists: 'Email already exist!',
    uploadSuccess: 'Details uploaded successfully!',
    uploadFailure:
      'Failed to upload the details! Please check the entered data.',
    passwordMismatch: 'Password does not match!',
    noRecordsFound: 'No Records Found!',
    noSaveChanges: 'You have no changes to save.',
    invalidAddress: 'Address in Invalid!',
    userSuccess: 'User added successfully!',
    dataSuccess: 'Data added successfully!',
    recaptcha_required: 'Recaptcha is required',
    mandatoryField: 'Please fill out all the mandatory fields properly.',
    dataSaved: 'Data saved successfully!',
    addressSaved: 'Address {event} successfully!',
    addressUpdated: 'Card address {event} successfully!',
    userUpdate: 'Staff details updated successfully!',
    dataUpdate: 'Data updated successfully!',
    cancel: 'Are you sure you want to cancel?',
    delete: 'Are you sure you want to delete?',
    passwordChanged: 'Password has been changed successfully!',
    charactersOnly: 'Please enter only characters',
    specialCharactersOnly: 'Please enter only special characters',
    setTheme: "Changes made in the current theme will not apply to this theme. Are you sure you want to switch to this theme?",
    setThemeConfirmation: 'Are you sure you want to set this theme for your Store?',
    setThemeSuccess: 'Theme set successfully!',
    congrats: 'Your Store is all set. You will now be redirected to your Dashboard.',
    setThemeFailed: 'Setting the theme failed. Please try again!',
    dataDelete: 'Data deleted successfully!',
    failedToDelete: 'Failed to delete the data.',
    noDeleteImgs: "You have no image(s) selected to remove.",
    noShippingAddres: 'There is no shipping address!',
    imageCount: 'Only two images can be added!',
    imageSize: 'Image size exceeded!',
    imageAddSize: 'image count reached the maximum limit!',
    imageLimitExceeded: 'image count reached the maximum limit',
    imageUploadSucess: 'Image Uploaded Successfully!',
    imageDelete: 'Image Data deleted successfully',
    imageUpdateDetailsFailure: "Failed to update uploaded image. Please refresh the page",
    imageDeleteConfirm: "Do you want to delete the selected image(s) even if it is already used?",
    failedSave: 'Failed to save the data.',
    failedtoSaveGiftCard: "Failed to save the data.",
    failedToget: 'Failed to get the data.',
    tryAgainLater: 'Something went wrong , Please try again!',
    pleaseTryAgainLater: 'Something went wrong, Please try again',
    failedTogetUserData: 'Failed to get the Store data!. Please try again!',
    paymentMethodNotAvailable:
      'Please enable any payment method in the plugins to proceed further.',
    paymentMethodNotAvailableStoreFront: 'No Payment method is available. Please contact your admin.',
    billingAddress: 'Please check your card details and billing address.',
    manualOrderBillingAddress: 'There are no card details to proceed with your payment process.',
    saveGiftCardSuccess: 'Gift Card Purchased Successfully.',
    savedigitalProductSuccess: 'Digital product purchased successfully.',
    savedigitalProductFailure: 'Unable to purchase digital product.',
    errorInSaving: 'Error in payment and placing gift card: ',
    cardDetails: 'Please select any one of the cards.',
    selectCardAlert: 'Please choose any one of the card from the list.',
    tipRequired: 'Please enter the tip amount!',
    zipCodeUnAvaliable: 'Zip code must be close to the selected location',
    pickUpform: 'Please fill in the pickup time for the selected pickup option.',
    shippingAndPaymentRequired: 'Please select the shipping and delivery methods.',
    paymentRequired: 'Please choose the payment method.',
    contactInformation: 'Please fill the contact information.',
    customerNotes: 'Please fill in valid customer notes.',
    paymentFailed: 'Failed to proceed with the payment',
    onlinePaymentFailed: 'Failed to complete your payment.',
    paymentFetchFailed: 'Failed to fetch Payment Details',
    giftcardPaymentFetchFailed: 'Failed to retrieve payment details',
    summaryPaymentFetchFailed: 'Failed to retrieve summary details.',
    fetchFailed: 'Failed to fetch Details',
    transactionCancelled: "Your transaction has been cancelled.",
    giftCardPlugin: "The gift card is currently inactive, so you are unable to proceed with the checkout process.",
    cardCredientials:
      'There is no credientials for the card details,you can use cash on delivery to proceed the payment',
    isDefaultAlert: 'Are you sure you want to change your default address?',
    failedToload: 'Failed to load the calculate summary details',
    paymentPreprocessFailed: 'Something went wrong, Please Try Again Later',
    failedToloadDigitalProduct: 'Failed to load the summary details.',
    isDefaultAlertCard: 'Are you sure you want to change your default card?',
    confirmCardDelete: 'Are you sure you want to delete the card details?',
    isDefaultoneAlert: 'You must have one default address',
    fullfillmentAlert: 'Please contact store admin.',
    confiramationSku:
      'Are you sure you want to remove it, since it affects the previous variants details?',
    confiramationCheckVariant:
      'Are you sure you want to enable variants?',
    requiredField: 'Please fill the required fields',
    ribbons: 'Ribbons Details Saved Successfully',
    ribbonUpdate: 'Ribbons Details Updated Successfully',
    checkMinOrderAmount: 'Your subtotal amount is lower than the minimum order limit. ',
    checkMaxOrderAmount: 'Your subtotal amount exceeded the maximum order limit. ',
    shippingPaymentNotSaved: 'Please save your shipping address',
    noPickUpSelected: 'Please select the pickup method.',
    noShippingAddress: 'Please select a Shipping address.',
    contactInformationNotSaved: 'Please save your contact information.',
    default: 'DEFAULT',
    deleteDiscountConfirmation: 'Do you want to remove the applied discount?',
    deleteConfirmationforDiscount: 'Do you wish to cancel the discount that was applied?',
    colorRequired: 'Color is required',
    attentionBannerSuccess: 'Attention Banner configured successfully. Click Update to see the changes.',
    attentionBannerCloseAlert: 'Attention banner will be turned off, If mandatory fields are not filled. Are you sure want to close?',
    attentionBannerLimit: 'Attention Banner should not exceed 100 characters.',
    subscriptionProduct: 'Are you sure you want to remove it, since it affects the subscription product features?',
    subscriptionProductErr: 'Something went wrong , Please contact adminstrator.',
    storeAllDaysClosed: 'All days are closed for this location!',
    storeFrontLogout: 'Are you sure you want to logout?',
    tip: 'Tip',
    isInvalid: 'is invalid!',
    isRequired: 'is required!',
    shouldNotExceed: 'should not exceed',
    invalidLandmark: 'Invalid landmark',
    landmarkExceedCharacters: 'landmark should not exceed 60 characters',
    invalidaddress: 'Invalid address',
    addressExceedCharactersLimit: 'Address should not exceed 60 characters',
    addressLine2ExceedCharacters: 'Address line 2 should not exceed 100 characters!',
    addressLine1ExceedCharacters: 'Address line 1 should not exceed 100 characters!',
    addressLine1Required: 'Address line 1 is required!',
    zipCodeInvalid: 'Zip Code is invalid!',
    zipCodeRequired: 'Zip Code is required!',
    cityExceedCharactersLimit: 'The city should not exceed 30 characters!',
    cityIsInvalid: 'City is invalid!',
    cityRequired: 'City is required!',
    phoneNumberInvalid: 'Phone number is invalid!',
    phoneNumberRequired: 'Phone Number is required!',
    stateInvalid: 'State is invalid!',
    stateRequired: 'State is required!',
    countryInvalid: 'Country is invalid!',
    countryRequired: 'Country is required!',
    fullNameExceedCharactersLimit: 'The full name should not exceed 50 characters!',
    invalidname: 'Invalid Name!',
    nameRequired: 'Name is required!',
    enterValidCustomerNotes: 'Enter valid customer notes!',
    destinationSelectionRequired: ' Destination selection is required!',
    imageStorageLimitExceeded: ' Image(s) uploads failed due to storage limit exceeded',
    imagesUploadedSuccessfully: 'Image(s) were uploaded successfully',
    renameImgExceed: 'Image name should not exceed 100 characters!',
    renameImgSuccess: 'Image name updated successfully',
    renameImgPatternError: 'Only alphanumeric characters are allowed!',
    subscriptionAlert: 'Upgrade your plugin plan to add subscription product',
    pluignErr:'Error in getting plugin details',
    phoneNumberExceeds: 'Contact number should not exceed 20 digits.',
    zipcodeExceeds: 'Zipcode should not exceed 20 digits.',
    invalidContactNumber: 'Contact number is invalid!',
    errorOnInit: 'Error in OnInit: ',
    getPaymentError: 'Error in getPaymentDetails: ',
    getAddressError: 'Error in getAddress: ',
    getCountriesError: 'Error in getCountries: ',
    getDiscountError: 'Error in getDiscountTypes: ',
    saveAddressError: 'Error in saveAddress: ',
    redeemGiftcardError: 'Error in onRedeemGiftCard: ',
    removeGiftcardError: 'Error in removeGiftCard: ',
    redeemCouponcodeError: 'Error in onRedeemCouponCode: ',
    removeDiscountError: 'Error in removeDiscount : ',
    paymentPlaceOrderError: 'Error in paymentAndPlaceOrder: ',
    orderSummaryError: 'Error in orderSummary: ',
    failedToLoad: 'Failed to load the calculate summary details',
    failedToSave: 'Failed to save the data.',
    errorOnChanges: 'Error in onChanges: ',
    loadStripeElementsError: 'Error in loadStripeElements: ',
    setBillingAddressError: 'Error in setBillingAddress: ',
    paymentError: 'Error in onPayment: '
  };
  /**
   * Variable used to chip color values.
   * @type {object}
   */
  tableChipColor: any = {
    red: 'red',
    green: 'green',
    purple: 'purple',
    yellow: 'yellow',
    black: 'black',
    orange: 'orange',
    steelblue: 'steelblue',
  };
  /**
   * Variable used to store file formate
   */
  fileFormat = {
    jpg: 'jpg', png: 'png', jpeg: 'jpeg', csv: 'csv', svg: 'svg', xlsx: 'xlsx'
  }
  /**
   * Variable which is used to store the file error message.
   */
  fileErrorMessage = {
    maxLimit: 'You can only upload a maximum of {size} files.',
    size: 'Make sure to upload a file {size} and try again.',
    format: 'Make sure to upload a valid file and try again.'
  }
  /**
   * Variable used to store image count.
   * @type {number}
   */
  imageCount: number = 2;
  /**
   * Variable used to store maximum image file size.
   * @type {number}
   */
  maxFileSize: number = 2000000;
  /**
   * Variable used to store guest role name.
   * @type {string}
   */
  guest: string = 'Guest';
  /**
* Variable which is used for paginator
* @type {object}
*/
  paginator = {
    pageLengthOptions: [5, 10, 20, 30, 40],
    defaultPageSize: 10
  };
  /**
   * Variable used to store address type values.
   * @type {object}
   */
  addressType = ['Home', 'Office', 'Others'];

  /**
   * Variable which is used to store the customer verification message.
   */

  customerErrorMessages = {
    alertHeader: "Alert",
    failureHeader: "Failure",
    successHeader: "Success",
    tokenAlreadyVerified: "Customer already verified",
    TOKENSUCCESS: "Your customer account has been approved successfully",
    TOKENDECLINE: "Your customer account has been declined successfully",
    customerNotFound: 'Customer not found',
    mailNotSent: 'Approved confirmation mail is not sent to your customer'
  };
  /**
  * Country list for display domain change in shopapp
  */
  countryOptions = [
    { flag: 'in', iconColor: '#E1306C', text: 'IN' },
    { flag: 'us', iconColor: '#0E76A8', text: 'US' },
  ];

  /**
   * Variable used to store mail template categories.
   */
  templateCategories = [
    {
      id: 1,
      categoryName: 'Admin'
    },
    {
      id: 2,
      categoryName: 'Super Admin'
    },
    {
      id: 3,
      categoryName: 'Marketing'
    }
  ];
  //prem
  snackBarType = {
    success: 'success',
    failure: 'failure',
    information: 'information',
    alert: 'alert',
    DEFAULT: 'default',
  }
  snackBarDetails = [
    { type: 'success', panelClass: 'success-bar', icon: 'done' },
    { type: 'failure', panelClass: 'error-bar', icon: 'error' },
    { type: 'information', panelClass: 'info-bar', icon: 'info' },
    { type: 'alert', panelClass: 'warning-bar', icon: 'warning' },
    { type: 'default', panelClass: 'default-bar', icon: 'info' },
  ]
  snackBarPosition = {
    TopRight: { hPosition: 'right', vPosition: 'top' },
    TopCenter: { hPosition: 'center', vPosition: 'top' },
    TopLeft: { hPosition: 'left', vPosition: 'top' },
    BottomRight: { hPosition: 'right', vPosition: 'bottom' },
    BottomCenter: { hPosition: 'center', vPosition: 'bottom' },
    BottomLeft: { hPosition: 'left', vPosition: 'bottom' },
  }
}
