// Component which is used for display the dialog box.
import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SharedConstant } from '../../constants/shared-constant';
// import { DynamicTranslationService } from '../../services/translation.service';
/**
 * Component which is used for display the  dialog box.
 */
@Component({
  selector: 'phase-ii-app-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss']
})
export class DialogComponent extends SharedConstant implements OnInit {
  /**
   * Variable used for the language translation
   * @type {any}
   */
  translate: any;
  /**
   * Component constructor which is used to inject the required services.
   * @param data The data which is used for displaying the message in dialog box.
   * @param translatService used for the translation
   */
  constructor(@Inject(MAT_DIALOG_DATA) public data: any) {
    super();
  }
  /**
   * Component OnInit life cycle hook.
   */
  ngOnInit() {
    // this.translatService.multiLoadTranslation(["Dialog"]).then((res: any) => {
    //   this.translate = res;
    // }).catch((error) => {
    //   console.log("Failed to fetch translations");
    // });
    const data = this.dialogStyle.find(v => v.name === this.data.actionType);
    if (data && data.color)
      this.data.color = data.color;
  }
}
