<cen-loader-vii *ngIf="requestDetails?.isLoader">
</cen-loader-vii>
<phase-ii-header-page [heading]="requestDetails?.heading">
</phase-ii-header-page>
<div class="page-container" fxLayout="row" fxLayoutGap="10px" fxLayout.lt-md="column" fxLayout.xs="column">
  <div *ngIf="isFilter" fxLayout="column" class="page-container_filter_field">
    <phase-ii-filter [filters]="statusFilter" (filterClosed)="onFilterClose()"
      (filterPredicate)="onFilterSelected($event)"></phase-ii-filter>
  </div>
  <mat-card fxFlex="100">
    <phase-ii-common-card-v-ii [paginatorVersion]="2" [needPaginator]="true"
      [listProperties]="requestDetails?.listProperties" [columnsRef]="requestDetails?.columnsRef"
      (iconClicked)="cardActions($event)" (actionClicked)="listAction($event)"
      [actionsIcon]="requestDetails?.actionIcon" [actions]="requestDetails?.actions"
      (valueChanged)="getChangedData($event)" (searchedText)="search($event)">
    </phase-ii-common-card-v-ii>
  </mat-card>
</div>
<ng-template #requestStatus class="requestStatus">
  <h5 mat-dialog-title>
    Status Details
    <mat-icon data-control="closeIcon" class="onclose" (click)="onClose()">close</mat-icon>
  </h5>
  <div mat-dialog-content class="status_dialog">
    <form [formGroup]="requestForm">
      <div fxLayout="column" class="status_dialog_requestForm">
        <div fxLayout="row">
          <mat-form-field fxFlex="100%" appearance="outline">
          <mat-label>Name</mat-label>
            <input [readonly]="true" data-control="userName" formControlName="userName" matInput />
          </mat-form-field>
        </div>
        <div fxLayout="row">
          <mat-form-field fxFlex="100%" appearance="outline">
          <mat-label>Status</mat-label>
            <mat-select required formControlName="status" data-control="status" (selectionChange)="onpaymentComplete($event)">
              <mat-option [value]="status" [attr.data-control]="status?.value | dataControl" *ngFor="let status of requestDetails?.status" [disabled]="status.disableStatus===true" >
                {{status?.value}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div fxLayout="row"  *ngIf="isPaymentCompleted">
          <mat-form-field fxFlex="100%" appearance="outline">
          <mat-label>Transaction Id</mat-label>
            <input [readonly]="isPaymentForm" data-control="transactionId" formControlName="transactionId" matInput required >
            <mat-error *ngIf="requestForm.get('transactionId').hasError('required')" data-control="transactionIdReq">
              {{constant?.ERROR_MESSAGES?.TRANSACTION_ID_REQ}}
            </mat-error>
            <mat-error *ngIf="requestForm.get('transactionId').hasError('minlength')" data-control="transactionIdMInLength">
              {{constant?.ERROR_MESSAGES?.TRANSACTION_ID_MIN}}
            </mat-error>
            <mat-error *ngIf="requestForm.get('transactionId').hasError('maxlength')" data-control="transactionIdMaxLength">
              {{constant?.ERROR_MESSAGES?.TRANSACTION_ID_MAX}}
            </mat-error>
          </mat-form-field>
        </div>
        <div fxLayout="row"  *ngIf="isPaymentCompleted">
          <mat-form-field fxFlex="100%" appearance="outline">
          <mat-label>Description</mat-label>
            <input [readonly]="isPaymentForm" data-control="description" formControlName="description" matInput>
                <mat-error *ngIf="requestForm.get('description').hasError('maxlength')" data-control="descriptionInvalid">
                  {{constant?.ERROR_MESSAGES?.DESCRIPTION_MAX}}
                </mat-error>
          </mat-form-field>
        </div>
      </div>
    </form>
  </div>
  <div class="mat-dialog-action-inside-form cancel_save_btn" mat-dialog-actions
    fxLayoutGap="10px" fxLayoutAlign="center center">
    <button mat-raised-button class='secondary-button' data-control="cancelButton" (click)="onClose()">
      Cancel
    </button>
    <button mat-raised-button class='primary-button' data-control="saveButton" (click)="onSave()" >
      <div *ngIf="!isButtonLoader">Save</div>
      <cen-loader *ngIf="isButtonLoader" [type]="'content'" [name]="'cen-dot-spinner'" class="cenLoader"
        >
      </cen-loader>
    </button>
  </div>
</ng-template>
<ng-template #viewAccountDetails class="viewAccountDetails" >
  <h5 mat-dialog-title class='viewdetails' fxLayout="row">View Account Details
    <span class='viewdetails_example-spacer'></span>
    <mat-icon class='viewdetails_closeIcon' matDialogClose data-control="close">close</mat-icon>
  </h5>
  <div mat-dialog-content>
      <h3 class=" main_card_header" fxLayout="row">
        <strong fxFlex="35%">Bank Name
          <Code></Code></strong>:&nbsp;
        <strong class="form-value" fxFlex="65%">
          {{ requestDetails?.accountDetails?.bankName ?
          requestDetails?.accountDetails?.bankName : '---' }}
        </strong>
      </h3>
      <h3 class="main_card_header" fxLayout="row">
        <strong fxFlex="35%">Branch Name<Code></Code></strong>:&nbsp;
        <strong class="form-value" fxFlex="65%">
          {{ requestDetails?.accountDetails?.branchName ?
          requestDetails?.accountDetails?.branchName : '---' }}
        </strong>
      </h3>
      <h3 class="main_card_header" fxLayout="row">
        <strong fxFlex="35%">Account Number</strong>:&nbsp;
        <strong class="form-value" fxFlex="65%">
          {{requestDetails?.accountDetails?.accountNumber?requestDetails.accountDetails.accountNumber:'---'}}
        </strong>
      </h3>
      <h3 class="main_card_header" fxLayout="row">
        <strong fxFlex="35%">IFSC code
        </strong>:&nbsp;
        <strong class="form-value" fxFlex="65%">
          {{requestDetails?.accountDetails?.ifscCode
          ?requestDetails.accountDetails.ifscCode
          :'---'}}
        </strong>
      </h3>
      <h3 class="main_card_header" fxLayout="row">
        <strong fxFlex="35%">MICR code<Code></Code></strong>:&nbsp;
        <strong class="form-value" fxFlex="65%">
          {{requestDetails?.accountDetails?.micrCode
          ?requestDetails.accountDetails.micrCode
          :'---'}}
        </strong>
      </h3>
      <h3 class="main_card_header" fxLayout="row">
        <strong fxFlex="35%">VPA Id<Code></Code></strong>:&nbsp;
        <strong class="form-value" fxFlex="65%">
          {{requestDetails?.accountDetails?.vpaId
          ?requestDetails.accountDetails.vpaId
          :'---'}}
        </strong>
      </h3>
  </div>
</ng-template>