import { Component, OnInit, OnDestroy } from '@angular/core';
import { DatePipe } from '@angular/common';
import * as  _ from 'lodash';
import { filter, mergeMap } from 'rxjs/operators';
import { of, Subscription } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { ReportsService } from '../../services/reports.service';
import { CommonService } from '../../../../../shared/src/lib/services/common.service';
import { ColumnsRef, CommonAdminShardConstants, ListProperties } from '../../constants/shared-constant';
import { Router } from '@angular/router';
import { ButtonInfo, Heading } from '../../../../../shared/src/lib/models/header-page';
import { AuthService } from '@phase-ii/auth';
import { PaginationService } from '../../../../../shared/src/lib/services/pagination.service';
import { DialogService } from '@phase-ii/common';
import { ExportComponent } from '../export/export.component';
import { ExportObject } from '../../models/export-details';
import { dateObject } from '../../models/common-admin-model';
import * as moment from 'moment';

/**
* Component which is used to view/export product report.
*/
@Component({
  selector: 'phase-ii-product-report',
  templateUrl: './product-report.component.html',
  styleUrls: ['./product-report.component.scss'],
})
export class ProductReportComponent implements OnInit, OnDestroy {

  /**
  * Variable which is used to define the pagesize
  */
  pageSize: any = new CommonAdminShardConstants().paginator.defaultPageSize;
  /**
* Variable which is used to define the error messages
* @type{any}
*/
  errorMessages: { [key: string]: string };
  /**
  * Variable which is used to subscribe and unsubscribe.
  * @type{Subscritpion}
  */
  subscriptionObj: Subscription = new Subscription();
  /**
* paginator data for export
*/
  paginatorValue = { limit: null, offset: null };
  /**
  * variable which is used to store location id
  */
  location_id!: any;
  /**
* variable which is used to store sort value
*/
  sort!: any;
  /**
  * variable which is used to store page details
  */
  pageDetails: {
    heading: any,
    buttonInfo: ButtonInfo[],
    listProperty: ListProperties,
    columnRef: ColumnsRef[],
    isLoader: boolean,
    listDataSource: any,
    showFilterAndSort: boolean,
    actions: any,
    filterConstrain: any,
    checker: any,
    errorHandler: boolean,
    currencySymbol: any
    paginator: any
    chart: boolean,
    tab: boolean,
    storeList: any,
    roleId: number,
    storeId: number
  } = {
      heading: null,
      buttonInfo: null,
      listProperty: null,
      columnRef: null,
      isLoader: null,
      listDataSource: null,
      showFilterAndSort: false,
      actions: [],
      filterConstrain: null,
      checker: null,
      errorHandler: false,
      currencySymbol: null,
      paginator: null,
      chart: false,
      tab: false,
      storeList: null,
      roleId: null,
      storeId: null
    };
  /**
  * Variable which is used to define the table columns for product.
  * @type{Colums[]}
  */
  columns = [
    { name: 'Product', value: 'product_name', sort: true },
    { name: 'Quantity', value: 'net_quantity', sort: true },
    { name: 'Amount', value: 'amount', type: "amount", sort: true },
  ];
  /**
* variable which is used to store selected from date.
*/
  fromDate!: any;
  /**
* variable which is used to store selected to date.
*/
  toDate!: any;
  /**
* variable which is used to store search value.
*/
  searchText!: string;
  /**
* variable which contains store id.
*/
  storeId!: any;
  /**
* variable which is used to store current user details.
*/
  currentUser!: any;
  /**
* variable which contains list of available stores.
*/
  storeList: any[] = [];
  /**
* variable which is used to store whether the response is retrieved or not.
*/
  isRetrieve: boolean = false;
  /**
* variable which is used to store limit for paginator.
*/
  paginatorLimit!: any;
  /**
* variable which is used to store offset for paginator.
*/
  paginatorOffset!: number;
  /**
* variable which is used to store current role details.
*/
  currentRole!: string;
  /**commonConstant used to acccess the common constant values. */
  commonConstant = new CommonAdminShardConstants();
  /**
 * Variable which is used to store the created date of the store.
 * @type {Date}
 */
  createdDate!: Date;
  /**
 * component constructor which is used to inject the required services.
 * @param reportService  To access methods inside ReportService.
 * @param authService  To access methods inside AuthService.
 * @param displayDialog  To access methods inside MatDialog.
 * @param paginatorService  To access methods inside PaginationService.
 * @param commonService  To access methods inside CommonService.
 * @param datePipe  To access methods inside DatePipe.
 * @param router  To access methods inside Router.
 */
  constructor(
    private reportService: ReportsService,
    private authService: AuthService,
    private displayDialog: MatDialog,
    private paginatorService: PaginationService,
    private commonService: CommonService,
    private dialogService: DialogService,
    private datePipe: DatePipe,
    private router: Router
  ) { }

  /**
* Angular hook life cycle to initialize the component
*/
  ngOnInit(): void {
    this.sort = { direction: 'desc' };
    // this.toDate = new Date();
    // this.fromDate = new Date(Number(this.toDate));
    this.location_id = 'All';
    this.subscriptionObj.add(this.authService.user.pipe(filter((res) => {
      if (res) {
        return res;
      }
    }), mergeMap((user: any) => {
      if (user) {
        this.currentUser = user;
        this.currentRole = this.currentUser.roleCode ? this.currentUser.roleCode : this.currentUser.role.code;
        this.pageDetails.roleId = this.currentUser?.roleId;
        this.pageDetails.currencySymbol = user.store && user.store.currencyData && user.store.currencyData.currencySymbol;
      }
      if (this.currentRole === 'SUPERADMIN') {
        return this.commonService.getAllStoresName();
      } else {
        this.pageDetails.storeId = this.currentUser?.storeId ? this.currentUser.storeId : null;
        return of({});
      }
    }), mergeMap((store: any) => {
      if (store && store['stores']) {
        this.storeList.push({ name: "All", id: "All" });
        store['stores'].forEach((element) => {
          this.storeList.push({
            id: element?.id,
            name: element?.name
          });
        });
        this.storeId = this.storeList[0]?.id;
        this.pageDetails.storeList = this.storeList;
      }
      return this.reportService.getStoreData(this.pageDetails.storeId);
    }), mergeMap((data: any) => {
      if (data) {
        this.createdDate = data.settings && data.settings.createdAt;
      }
      return this.authService.dashboardFilter;
    })).subscribe((value: dateObject) => {
      if (value) {
        if (value.filterText == 'Lifetime') {
          this.fromDate = this.createdDate;
        }
        else {
          this.fromDate = value.fromDate ? value.fromDate : new Date();
          this.fromDate.setHours(0, 0, 0, 0);
        }
      }
      else {
        this.fromDate = new Date();
        this.fromDate.setHours(0, 0, 0, 0);
      }
      this.toDate = new Date();
      this.toDate.setHours(23, 59, 59, 999);
      this.storeId = this.currentUser?.storeId ? this.currentUser.storeId : null;
      this.getProductReport(0, 2 * this.pageSize, true);
    }, (err) => {
      this.pageDetails.isLoader = false;
    }));
  }

  /**
   *Function which is used to get the product report details.
   */
  getProductReport(offset: number, limit: number, isNew: boolean): void {
    this.paginatorOffset = offset;
    this.paginatorLimit = this.pageSize;
    const start_date = this.fromDate ? _.cloneDeep(this.fromDate) : null;
    const to_date = this.toDate ? this.toDate : null;
    let data = {
      offset: offset,
      limit: limit ? limit : this.pageSize,
      location: this.location_id ? this.location_id : null,
      store: this.storeId ? this.storeId : null,
      from_date: moment.utc(this.datePipe.transform(start_date, 'yyyy-MM-dd')).utcOffset('+0:00').format('YYYY-MM-DD 00:00:00.000 +00:00'),
      to_date: moment.utc(this.datePipe.transform(to_date, 'yyyy-MM-dd')).utcOffset('+0:00').format('YYYY-MM-DD 23:59:59.000 +00:00'),
      filterText: '',
      sort: this.sort ? this.sort : null
    }
    if (this.storeId === 'All') {
      this.reportService.getAllProductReport(data).subscribe((res) => {
        if (res && res['productData'] && res['productData'].rows) {
          this.pageDetails.listDataSource = res['productData'].rows ? res['productData'].rows : [];
          this.isRetrieve = true;
          this.pageDetails.paginator = {
            new: isNew,
            rows: this.pageDetails.listDataSource,
            count: res['productData'].count?.length ? res['productData'].count?.length : res['productData']?.count,
          }


          this.pageDetails.listProperty = {
            globalSearch: false,
            columns: 2,
            dataOnly: true,
            suffixText: true,
            checkBox: false,
            isImageDisplay: false
          };
          this.pageDetails.checker = false;
          this.pageDetails.isLoader = false;
          this.paginatorService.listPaginatorData.next(this.pageDetails.paginator);
          this.pageDetails.columnRef = [
            { column: 'name', type: 'Text', suffixText: 'Product Name' },
            { column: 'quantity', type: 'Number', suffixText: 'Quantity' },
            { column: 'amount', type: 'Amount', suffixText: 'Amount', currency: this.pageDetails?.currencySymbol }
          ];
          this.pageDetails.heading = {
            title: 'Sold Product Report',
            description: 'Generates report based on sold product',
            isHeaderIcon: true,
            iconName: 'link',
            iconToolTip: 'Help',
            iconLink: 'https://help.getzenbasket.com/product_report.html'
          };
          this.pageDetails.buttonInfo =
            [
              {
                name: 'Back', method: 'back()', class: 'secondary-button'
              },
              {
                name: 'Export Report', class: 'primary-button', method: 'exportexcel()'
              }
            ];
        }
      });
    } else if (this.storeId !== null) {
      this.reportService.getProductReport(this.storeId, data).subscribe((res) => {
        if (res && res['productData'] && res['productData'].rows) {
          this.pageDetails.listDataSource = res['productData'].rows ? res['productData'].rows : [];
          this.isRetrieve = true;
          this.pageDetails.paginator = {
            new: isNew,
            rows: this.pageDetails.listDataSource,
            count: res['productData']?.count?.length ? res['productData'].count.length : res['productData'].count,
          }

          this.pageDetails.listProperty = {
            globalSearch: false,
            columns: 2,
            dataOnly: true,
            suffixText: true,
            checkBox: false,
            isImageDisplay: false
          };
          this.pageDetails.checker = false;
          this.pageDetails.isLoader = false;
          this.paginatorService.listPaginatorData.next(this.pageDetails.paginator);
          this.pageDetails.columnRef = [
            { column: 'name', type: 'Text', suffixText: 'Product Name' },
            { column: 'quantity', type: 'Number', suffixText: 'Quantity' },
            { column: 'amount', type: 'Amount', suffixText: 'Amount', currency: this.pageDetails.currencySymbol }
          ];
          this.pageDetails.heading = {
            title: 'Sold Product Report',
            description: 'Generates report based on sold product',
            isHeaderIcon: true,
            iconName: 'link',
            iconToolTip: 'Help',
            iconLink: 'https://help.getzenbasket.com/product_report.html'
          };
          this.pageDetails.buttonInfo =
            [
              {
                name: 'Back', method: 'back()', class: 'secondary-button'
              },
              {
                name: 'Export Report', class: 'primary-button', method: 'exportexcel()'
              }
            ];
        }
      });
    } else {
      this.dialogDisplay(this.commonConstant &&
        this.commonConstant.errorMessage
        && this.commonConstant.errorMessage.errorMessage,
        this.commonConstant.dialogType.failure);
    }

  }

  /**
* Method which is used to navigate to dashboard page.
*/
  back(): void {
    this.router.navigate(['app/dashboard']);
  }

  /**
* Method which is used to get product details based on selected date range.
* @param event To get offset and limit.
*/
  onDataChange(event: any): void {
    this.getProductReport(event?.offset, event?.limit, false);
  }
  /**
 * Method which is used to get changed page data.
 * @param event To get offset and limit.
 */
  changedData(event: any): void {
    if (event) {
      this.paginatorValue.limit = event?.limit ? event.limit : null;
      this.paginatorValue.offset = event?.offset ? event.offset : null;
    }
    this.getProductReport(event?.offset, event?.limit, false);
  }
  /**
   * Method which is used to get paginator data.
   * @param event To get offset and limit.
   */
  paginatorData(event: any): void {
    this.paginatorLimit = event?.pageSize;
    this.paginatorOffset = event?.pageIndex * event?.pageSize;
    this.paginatorLimit = event?.pageSize;
    this.paginatorOffset = event?.pageIndex * event?.pageSize;
    this.pageSize = event?.pageSize;
  }

  /**
   * Method which is used to get filter data.
   * @param event To get locationId, storeId, fromData, toDate, offset and limit.
   */
  filterAction(event: any): void {
    this.location_id = event?.location_id ? event.location_id : null;
    this.storeId = event?.store_id ? event.store_id : null;
    this.fromDate = event.from_date ? event.from_date : this.fromDate ? this.fromDate : null;
    this.toDate = event.to_date ? event.to_date : this.toDate ? this.toDate : null;
    this.paginatorValue.limit = event?.limit ? event.limit : null;
    this.paginatorValue.offset = event?.offset ? event.offset : 0;
    // }
    this.sort = event?.sort;
    if (event?.exportexcel) {
      this.exportProductReport(event?.offset, event?.limit);
    } else {
      this.getProductReport(event?.offset, event?.limit, true);
    }
  }

  /**
   * Method which is used to export product report.
   * @param offset To get offset value.
   * @param limit To get limit value.
   */
  exportProductReport(offset: number, limit: number): void {
    if (this.pageDetails.listDataSource && this.pageDetails.listDataSource.length > 0) {
      let filterConstrain = {
        from_date: this.fromDate ? this.datePipe.transform(this.fromDate, 'yyyy-MM-dd') : null,
        to_date: this.toDate ? this.datePipe.transform(this.toDate, 'yyyy-MM-dd') : null,
        locationId: this.location_id ? this.location_id : null
      }
      const data: ExportObject = {
        title: 'Export ',
        exportApiUrl: 'stores/' + this.storeId + '/export/report/product',
        limit: this.paginatorLimit ? this.paginatorLimit : this.paginatorValue.limit ? this.paginatorValue.limit : this.pageSize,
        offset: this.paginatorOffset ? this.paginatorOffset : this.paginatorValue.offset ? this.paginatorValue.offset : 0,
        type: false,
        fileName: 'product',
        expansion: true,
        filterData: filterConstrain ? filterConstrain : null,
        selectedData: []
      };
      const dialogRefData = this.displayDialog.open(ExportComponent, { disableClose: true });
      if (dialogRefData && dialogRefData.componentInstance) {
        dialogRefData.componentInstance.contentData = data;
      }
    } else {
      this.dialogDisplay(this.commonConstant &&
        this.commonConstant.dialogMessages
        && this.commonConstant.dialogMessages.noDataExport,
        this.commonConstant.dialogType.alert);
    }
  }

  /**
* Method used to display the dialog
* @param message has the message
* @param type has the dialog type.
*/
  dialogDisplay(message: any, type: any): any {
    return this.dialogService.dialogMethod(message, type, true);
  }

  /**
  * Component OnDestroy life cycle hook.
  * And unsubscribe all the subscriptions in the component.
  */
  ngOnDestroy(): void {
    this.subscriptionObj.unsubscribe();
  }
}  
