<cen-loader-vii *ngIf="isLoader"></cen-loader-vii>
<div *ngIf="planCount?.length > 0">
  <phase-ii-header-page [heading]="constant?.subscribedPlugin" [buttonInfo]="buttonInfo"
    (methodClicked)="functionTriggered($event)">
  </phase-ii-header-page>
  <div>
    <div class="container" *ngFor="let val of [].constructor(planCount?.length);let i=index">
      <h2>{{planCount && planCount[i]}}</h2>
      <div fxLayout="row wrap" fxLayoutAlign.lt-sm="center">
        <mat-card fxLayout="row" fxFlex="318px" *ngFor="let plugin of pluginArray[planCount[i]];let n=index">
          <div class="pluginImage" fxFlex="30%" fxLayoutAlign="center center">
            <img [src]="assetsUrl + plugin?.plugin?.pluginImage" onerror="this.src='./assets/no-image.png';">
          </div>
          <div fxFlex="70%" class="pluginDetails">
            <h4 class="pluginDetails_pluginName" [matTooltip]="plugin?.plugin?.pluginName" matTooltipPosition="above"
              [matTooltipDisabled]="plugin?.plugin?.pluginName?.length<15">{{plugin?.plugin?.pluginName}}</h4>
            <div class="pluginDetails_price">{{ currency + ' ' + plugin?.pluginPlan?.pluginPlanPriceInfos[0]?.price}}
            </div>
            <div class="pluginDetails_toggle" fxLayoutGap="10px" fxLayout="row">
              <div>{{plugin?.isRenewal ? "Enable" : "Disable"}}</div>
              <mat-slide-toggle [disabled]="isLoader" [checked]="plugin?.isRenewal"
                (click)="calculate(n,planCount[i])"></mat-slide-toggle>
            </div>
          </div>
        </mat-card>
      </div>
    </div>
  </div>
</div>