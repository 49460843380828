import { Component, OnDestroy, OnInit } from '@angular/core';
import { of, Subscription } from 'rxjs';
import * as printInvoice from 'print-js';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonDataService } from '../../../../../shared/src/lib/services/common-data.service';
import { ActionIcon, ColumnsRef, CommonConstants, ListProperties } from '../../../../../shared/src/lib/constants/shared-constant';
import { CommonService } from '../../../../../shared/src/lib/services/common.service';
import { ButtonInfo } from '../../../../../shared/src/lib/models/header-page';
import { mergeMap } from 'rxjs/operators';
import { SharedConstant } from 'libs/common/src/lib/constants/shared-constant';
import { DialogService } from 'libs/common/src/lib/services/dialog.service';
import { AuthService } from '@phase-ii/auth';
import { dataConfig } from 'libs/common/src/lib/services/config';

/**
 * AUTHOR: SANTHOSH S and VIGNESHWARAN R
 * Component is used to display the subscriptions of the user for both admin and super admin side and also allows the user to print the invoice of the particular subscription
 */
@Component({
  selector: 'phase-ii-subscription-member',
  templateUrl: './subscription-member.component.html',
  styleUrls: ['./subscription-member.component.scss']
})
export class SubscriptionMemberComponent extends SharedConstant implements OnInit, OnDestroy {

  /**
   * Subscription object
   * @type {Subscription}
   */
  subscriptionObj = new Subscription();
  /**
   * Variable which is used to get constant values of customer module
   * @type {object}
   */
  Constants = new CommonConstants();
  /**
   * Variable which is used to store property needed property of card list
   * @type {ListProperties}
   */
  listProperty: ListProperties = {
    profileImage: false,
    globalSearch: false,
    columns: 3,
    rowElementClicked: false,
    dataOnly: true,
    checkBox: false,
    suffixText: true,
    noPagination: true,
  };
  /**
   * Variable which is used to store property needed property of card list
   * @type {ListProperties}
   */
  listPropertyForExpired: ListProperties = {
    profileImage: false,
    globalSearch: false,
    columns: 3,
    rowElementClicked: false,
    isNewImage: { isNew: false, url: './assets/No billing and plans.png', text: 'Inactive Plan details are listed here...!' },
    dataOnly: true,
    checkBox: false,
    suffixText: true,
    serverSidePagination: false,
    isLocalPagination: true,
  };
  /**
 * Variable which is used to store property needed property of card list
 * @type {ListProperties}
 */
  listProperty1: ListProperties = {
    profileImage: false,
    globalSearch: false,
    columns: 3,
    rowElementClicked: false,
    dataOnly: true,
    checkBox: false,
    suffixText: true,
    noPagination: true,
  };
  /**
   * Variable which is used to store property needed property of card list
   * @type {ListProperties}
   */
  listPropertyForHistory: ListProperties = {
    profileImage: false,
    globalSearch: false,
    columns: 4,
    rowElementClicked: false,
    isNewImage: { isNew: false, url: './assets/No billing and plans.png', text: 'History of mobile subscription  details are listed here...!' },
    dataOnly: true,
    checkBox: false,
    suffixText: true,
    serverSidePagination: false,
    isLocalPagination: true,
  };
  /**
   * Variable to store what buttons are required to display in the page for admin side
   * @type {ButtonInfo}
   */
  buttonInfo: ButtonInfo[] = [];
  /**
   * Variable to store what buttons are required to display in the page for super admin side
   * @type {ButtonInfo}
   */
  buttonInfoForSuperAdmin: ButtonInfo[] = [
    {
      name: 'Cancel', class: 'secondary-button', method: 'navigateToListPage'
    }
  ];
  /**
  * Variable to store what buttons are required to display in the page for super admin side
  * @type {ButtonInfo}
  */
  buttonInfoForSuperAdmin1: ButtonInfo[] = [];
  /**
 * Variable to store what buttons are required to display in the page for admin side
 * @type {ButtonInfo}
 */
  buttonInfo1: ButtonInfo[] = [];
  /**
   * Variable which is used to get columns for common card list
   * @type {ColumnsRef[]}
   */
  columnRefForActivePlan: ColumnsRef[];
  /**
   * Variable which is used to get columns for common card list
   * @type {ColumnsRef[]}
   */
  columnRefForInactivePlan: ColumnsRef[];
  /**
  * Variable which is used to get columns for common card list
  * @type {ColumnsRef[]}
  */
  columnRefForMobileActivePlan: ColumnsRef[];
  /**
   * Variable which is used to get columns for common card list
   * @type {ColumnsRef[]}
   */
  columnRefForMobileHistory: ColumnsRef[];
  /**
   * Variable to store the subscription data fetched from back end
   * @type {any}
   */
  invoiceData: any = [];
  /**
   * Variable to store the subscription data fetched from back end
   * @type {any}
   */
  mobileAppData: any = [];
  /** 
   * Icon array used for display icons in card list
   * @type {ActionIcon}
   */
  activeActionIcons: ActionIcon[] = [
    { iconName: 'print', method: 'printInvoice', toolTip: 'Print Invoice' },
    { iconName: 'mail_outline', method: 'sendMailInvoice', toolTip: 'Send Mail Invoice' },

  ];
  /** 
   * Icon array used for display icons in card list
   * @type {ActionIcon}
   */
  actionIcons: ActionIcon[] = [
    { iconName: 'print', method: 'printInvoice', toolTip: 'Print Invoice' },
    { iconName: 'mail_outline', method: 'sendMailInvoice', toolTip: 'Send Mail Invoice' }
  ];
  /**
   * Variable to store the active plans fetched from back end
   * @type {any[]}
   */
  activePlan: any = [];
  /**
   * Variable to store the in active plans fetched from back end
   * @type {any[]}
   */
  inActivePlan: any = [];
  /**
 * Variable to store the  mobile subscriptions active plans fetched from back end
 * @type {any[]}
 */
  mobileActivePlan: any = [];
  /**
* Variable to store the history of mobile subscription plans fetched from back end
* @type {any[]}
*/
  mobileHistoryPlan: any = [];
  /**
   * Variable to check whether the loader must be displayed or not
   * @type {boolean}
   */
  isLoader!: boolean;
  /**
   * Variable to start the loader whilst the invoice is being fetched
   * @type {boolean}
   */
  invoiceLoader!: boolean;
  /**
   * Variable to store the particular store id
   * @type {number}
   */
  storeId!: number;
  /**
   * Variable to check whether the page is for superadmin
   * @type {boolean}
   */
  isSuperAdmin!: boolean;
  /**
   * Variable to store the currency unit
   * @type {string}
   */
  currencyUnit = 'USD';
  /**
   * Variable to store the current user details
   * @type {any}
   */
  userDetails: any;
  /**
   * Variable to store the invoice variables of the common constants class
   */
  invoice = new CommonConstants().invoice;
  /**
 * Variable to store the currency symbol
 */
  currencySymbol: any;
  /**
* Variable used to check whether it is readonly or not
*/
  isReadOnly: any;
  /**
* Variable used to check whether it is suspend or not
*/
  suspend: any;
  currentSk: any;
  isRenewal: any;
  /**
* Variable used to check it is mobile app subscription or not
*/
  isMobileAppSubscription: boolean
  /**
* Variable used to check it have mobile info or not
*/
  isNewMobileSubscription: boolean
  /**
* Variable used to check it is mobile app subscription or not
*/
  isMobileApp: boolean
  /**
   * Variable to check whether the mobile Application plugin is enabled or not
   * @type {boolean}
   */
  isMobilePlugin: boolean;
  /**
   * Component constructor for injecting needed services
   * @param dialogService used to open and close the success, failure or other dialog box with required error message
   * @param authService used to access all the API calls in the auth service
   * @param router used to navigate to other page using navigate function
   * @param commonDataService used to access the function for get/set params
   * @param paginationService used to access the function of paginator service
   * @param route used to subscribe the params of the URL
   * @param commonService used to access all the API calls in the common service
   */
  constructor(
    private dialogService: DialogService,
    private authService: AuthService,
    private router: Router,
    private commonDataService: CommonDataService,
    private route: ActivatedRoute,
    private commonService: CommonService,
  ) {
    super();
  }
  /**
   * Angular life cycle begins
   * @type {void}
   */
  ngOnInit(): void {
    this.isLoader = true;

    this.subscriptionObj.add(this.commonService.getStoreadminSettings('MOBILE_APPLICATION').pipe(mergeMap((res: any) => {
      if (res) {
        this.isMobilePlugin = res.details && res.details.isActive;
      }
      return this.route.params;
    }), mergeMap((params: any) => {
      if (params && params.id) {
        this.storeId = this.commonDataService.getParam(params.id);
        this.isSuperAdmin = true;
        return this.authService.getAdminDetails(this.storeId);
      } else {
        return this.authService.user;
      }
    }), mergeMap((res: any) => {
      if (this.isSuperAdmin)
        return of(res.currentUser);
      else {
        if (res && res.roleCode == 'STOREADMIN')
          this.buttonInfo.push(
            {
              name: 'Change Plan', class: 'primary-button', method: 'upgradePlan'
            },
          )
        if (this.isMobilePlugin && res && res.roleCode == 'STOREADMIN') {
          this.buttonInfo.push({
            name: 'MobileApp Subscription', class: 'primary-button', method: 'mobileAppSubscription'

          })
        }
        return of(res);
      }
    })).subscribe((user: any) => {
      if (user && user.store && user.store.storeId && user.store.id) {
        this.suspend = user && user.store && user.store.isSuspended
        this.userDetails = user;
        this.storeId = user.store.storeId;
        this.isMobileAppSubscription = user.store.isMobileApp;
        this.currencyUnit = this.isSuperAdmin ? (user.store.currencyData && user.store.currencyData.currencyUnit ? user.store.currencyData.currencyUnit : 'USD') : (user.purchasedCurrency && user.purchasedCurrency.currencyUnit ? user.purchasedCurrency.currencyUnit : 'USD');
        this.currencySymbol = this.isSuperAdmin ? (user.store.currencyData && user.store.currencyData.currencySymbol ? user.store.currencyData.currencySymbol : null) : (user.purchasedCurrency && user.purchasedCurrency.currencySymbol ? user.purchasedCurrency.currencySymbol : null);
        if (this.isSuperAdmin)
          this.getAllPlan();
        else
          this.getAllPlan(true);
      } else {
        this.authService.getCurrentUser(dataConfig.storeAdminCode);
      }
    }, (err) => {
      this.isLoader = false;
      this.dialogService.openDialog({
        header: this.dialogHeader.failure,
        message: this.Constants.dialogMessages.ErrSubscriptionMember,
        actionType: this.dialogType.failure,
        button: { right: this.buttonText.ok }
      });
    }));
  }
  /**
   * Method is used to get all the subscription plans of a particular user from the back end and split them into active and inactive plans and display them to the user
   * @param isAdmin Variable to check whether the page is for admin
   */
  getAllPlan(isAdmin?: boolean): void {
    this.subscriptionObj.add(
      this.commonService.getActivePlan(this.storeId).subscribe((res: any) => {
        this.mobileAppData = res && res.mobileInfo && res.mobileInfo.result;
        this.invoiceData = res && res.customerInfo && res.customerInfo.result;
        if (res && res.customerInfo && res.customerInfo.result) {
          this.isLoader = false;
          this.setPlanData(isAdmin);
        }
        if (res && res.mobileInfo && res.mobileInfo.result && res.mobileInfo.result.length) {
          this.isLoader = false;
          if (this.isMobilePlugin && !isAdmin) {
            this.buttonInfoForSuperAdmin.splice(0, 0,
              {
                name: 'MobileApp Subscription', class: 'primary-button', method: 'mobileAppSubscription'
              })
          }
          this.setMobilePlanData();
        }
        else if (res && res.mobileInfo && res.mobileInfo.result && !res.mobileInfo.result.length) {
          this.isNewMobileSubscription = true;
        }
        else {
          this.dialogService.openDialog({
            header: this.dialogHeader.failure,
            message: this.Constants.dialogMessages.ErrSubscriptionMember,
            actionType: this.dialogType.failure,
            button: { right: this.buttonText.ok }
          });
          this.isLoader = false;
        }
      }, (err) => {
        this.dialogService.openDialog({
          header: this.dialogHeader.failure,
          message: this.Constants.dialogMessages.ErrSubscriptionMember,
          actionType: this.dialogType.failure,
          button: { right: this.buttonText.ok }
        });
        this.isLoader = false;
      }));
  }

  /**
   * method to set the data to the common card
   */
  setPlanData(isAdmin?: boolean): void {
    this.invoiceData.forEach(element => {
      element.IsAutoRenewal = element.IsAutoRenewal ? "Yes" : "No";
      element.PlanAmount = parseFloat(element && element.PlanAmount && element.PlanAmount != undefined ? element.PlanAmount : 0).toFixed(2);
      element.PlanDetails =  element.PlanDetails ? JSON.parse(element.PlanDetails):null;
      // if (element && element.Plans && element.Plans.AdditionalCharge)
      if (element && element.AdditionalCharge)
        element.AdditionalCharge = (parseFloat(element.AdditionalCharge  != undefined ? element.AdditionalCharge : 0).toFixed(2));
      element.TotalAmount = (parseFloat(element.PlanAmount) +
        (parseFloat((element && element.TaxAmount && element.TaxAmount != undefined ? Number(element.TaxAmount) : 0).toFixed(2))) +
        (parseFloat((element && element.SetUpCost && element.SetUpCost != undefined ? Number(element.SetUpCost) : 0).toFixed(2))) +
        (parseFloat((element && element.OtherCharges && element.OtherCharges != undefined ? Number(element.OtherCharges) : 0).toFixed(2)))+
        (parseFloat((element && element.PluginAmount && element.PluginAmount != undefined ? Number(element.PluginAmount) : 0).toFixed(2))) ) -
        (parseFloat((element && element.DiscountAmount && element.DiscountAmount != undefined ? Number(element.DiscountAmount) : 0).toFixed(2))) -
        (parseFloat((element && element.UpgradeDiscountAmount && element.UpgradeDiscountAmount != undefined ? Number(element.UpgradeDiscountAmount) : 0).toFixed(2)));
      if (element.IsActive) {
        const date1 = new Date();
        if (element.EndDate) {
          const date2 = new Date(element.EndDate);
          const differenceInTime = date2.getTime() - date1.getTime();
          const differenceInDays = differenceInTime / (1000 * 3600 * 24);
          element['RemainingDays'] = 'Expires in ' + Math.floor(differenceInDays + 1) + ' days';
        }
        this.activePlan.push(element);
      } else {
        this.inActivePlan.push(element);
      }
    });
    this.listPropertyForExpired.isNewImage.isNew = this.inActivePlan && this.inActivePlan.length == 0 ? true : false;
    this.columnRefForActivePlan = [
      { column: 'PlanDetails.PlanName', type: 'Object', suffixText: 'Plan Name' },
      { column: 'PlanDetails.FrequencyType', type: 'Object', suffixText: 'Frequency Type' },
      { column: 'StartDate', type: 'Date', suffixText: 'Start Date' },
      { column: 'EndDate', type: 'Date', suffixText: 'End Date' },
      { column: 'IsAutoRenewal', type: 'Text', suffixText: 'Auto Renewal' },
      { column: 'TotalAmount', type: 'Amount', suffixText: 'Amount', currency: this.currencyUnit },
      { column: 'RemainingDays', type: 'Text', suffixText: 'Remaining Days' },
    ];
    this.columnRefForInactivePlan = [
      { column: 'PlanDetails.PlanName', type: 'Object', suffixText: 'Plan Name' },
      { column: 'PlanDetails.FrequencyType', type: 'Object', suffixText: 'Frequency Type' },
      { column: 'StartDate', type: 'Date', suffixText: 'Start Date' },
      { column: 'EndDate', type: 'Date', suffixText: 'End Date' },
      { column: 'TotalAmount', type: 'Amount', suffixText: 'Amount', currency: this.currencyUnit },
    ];
    if (!isAdmin) {
      this.buttonInfoForSuperAdmin.splice(0, 0,
        {
          name: this.suspend ? 'Resume User' : 'Suspend User', class: 'primary-button', method: 'suspendUser'
        }
      );
    }
  }
  /**
   * Method to check whether invoice is availabe for the subscription plan. If not generate a new Invoice URL and display them to the user
   * @param invoiceDetails variable to store the subscription details
   */
  printInvoice(invoiceDetails: any,): void {
    this.invoiceLoader = true;
    this.invoiceCreation(invoiceDetails, true)
  }
  /**
   * Method for creating an invoice and optionally generating a PDF
   * @param invoiceDetails variable to store the subscription details
   * @param createPdf 
   */
  invoiceCreation(invoiceDetails, createPdf: boolean) {
    if (invoiceDetails && invoiceDetails.InvoiceUrl && createPdf) {
      this.createPdf(invoiceDetails);
    }
    else {
      const invoiceGeneration = this.isMobileApp ? this.commonService.printMobileInvoice({ id: invoiceDetails.SK }) : this.commonService.printInvoice({ id: invoiceDetails.SK })
      this.subscriptionObj.add(invoiceGeneration
        .subscribe((res: any) => {
          if (res && res.invoiceUrl && createPdf) {
            this.createPdf({ InvoiceUrl: res.invoiceUrl });
          } else if (createPdf) {
            this.printInvoiceErr();
          } else if (!createPdf) {
            this.dialogService.openDialog({
              header: this.dialogHeader.success,
              message: this.Constants.dialogMessages.sendMailInvoiceSuccess,
              actionType: this.dialogType.success,
              button: { right: this.buttonText.ok }
            });
          }
        }, (err) => {
          this.printInvoiceErr();
        }));
    }
  }

  /**
   * Method for throw error for print invoice
   */
  printInvoiceErr(): void {
    this.dialogService.openDialog({
      header: this.dialogHeader.failure,
      message: this.Constants.dialogMessages.InvoiceError,
      actionType: this.dialogType.failure,
      button: { right: this.buttonText.ok }
    });
    this.invoiceLoader = false;
  }
  /**
   * Method to generate pdf
   * @param invoiceDetails variable to store the subscription details
   */
  createPdf(invoiceDetails): void {
    this.subscriptionObj.add(this.commonService.getInvoice({ invoiceUrl: invoiceDetails.InvoiceUrl }, this.storeId).subscribe((res: any) => {
      if (res && res.invoice && res.invoice.result && res.invoice.result.content && res.invoice.result.content.Body) {
        this.invoiceLoader = false;
        const byteArray = new Uint8Array(res['invoice']['result']['content']['Body']['data']);
        const file = new Blob([byteArray], { type: 'application/pdf;charset=utf-8;' });
        const fileURL = URL.createObjectURL(file);
        printInvoice(fileURL);
      } else {
        this.printInvoiceErr();
      }
    }, (err) => {
      this.printInvoiceErr();
    }));
  }
  /**
 * Method to form mail data
 * @param invoiceDetails variable to store the subscription details
 */
  formMailData(invoiceDetails): any {
    return {
      "templateUniqueId": "SUBSCRIPTION_INVOICE",
      "companyId": 3,
      "recipient": this.userDetails && this.userDetails.email,
      "templateContent": {
        "userName": (this.userDetails && this.userDetails.firstName || '') + ' ' + (this.userDetails && this.userDetails.lastName || ''),
        "planName": (invoiceDetails && (invoiceDetails.PlanDetails ? (invoiceDetails.PlanDetails.PlanName || 'Free Plan') : null))
      }
    }
  }

  /**
   * Method to navigate to the upgrade plan page
   */
  upgradePlan(): void {
    if (this.activePlan.length === 1) {
      this.isLoader = true;
      this.subscriptionObj.add(this.commonService.storePlanCount(this.storeId).subscribe((res: any) => {
        if (res && res.details) {
          this.deleteRequest(res.details);
        }
        else {
          const data = this.commonDataService.setParam(this.storeId);
          this.router.navigate(['/app/upgradeplans', data]);
        }
        this.isLoader = false;
      }, (err) => {
        if (err) {
          this.isLoader = false;
          this.dialogService.dialogMethod(this.Constants.dialogMessages.errorUpdatingPlan, this.Constants.dialogType.failure, true);
        }
      }));
    }
    else if (this.activePlan.length===0) {
      this.dialogService.openDialog({
        header: this.dialogHeader.alert,
        message: this.Constants.dialogMessages.errorGettingAllPlans,
        actionType: this.dialogType.alert,
        button: { right: this.buttonText.ok }
      });
    }
    else {
      this.dialogService.openDialog({
        header: this.dialogHeader.alert,
        message: this.Constants.dialogMessages.upgradingDisable,
        actionType: this.dialogType.alert,
        button: { right: this.buttonText.ok }
      });
    }
  }
  /**
  * Method to delete the previously selected plan's request for the store
  */
  deleteRequest(details) {
    const dialogRef = this.dialogService.openDialog({
      header: 'Confirmation!',
      message:
        this.Constants.dialogMessages.alreadyRequested +
        ' ' +
        details.planDetails.PlanName +
        ' ' +
        details.planDetails.FrequencyType +
        ' ' +
        this.Constants.dialogMessages.alreadyRequested1,
      actionType: this.Constants.dialogType.confirmation,
      button: { right: this.buttonText.yes, left: this.buttonText.no },
      disableClose: true,
      isDialog: true
    });
    this.subscriptionObj.add(dialogRef.afterClosed().pipe(mergeMap((res: any) => {
      if (res) {
        this.isLoader = true;
        return this.commonService.deleteUpcominPlanRequest(this.storeId);
      }
      else {
        return of(null);
      }
    })).subscribe((res: any) => {
      this.isLoader = false;
      if (res && res.success) {
        const data = this.commonDataService.setParam(this.storeId);
        this.router.navigate(['/app/upgradeplans', data]);
      }
    }, (err) => {
      if (err) {
        this.isLoader = false;
        this.dialogService.dialogMethod(this.Constants.dialogMessages.errorUpdatingPlan, this.Constants.dialogType.failure, true);
      }
    }));
  }

  /**
   * Method which can be handle the value emitted from common card list.
   * @param event To get the selected event
  */
  listAction(event: any): void {
    if (event.method) {
      this[event.method](event.data);
    }
  }

  /**
   * Method used to trigger the function.
   * @param event is the event.
   */
  functionTriggered(event): void {
    if (event) {
      this[event]();
    }
  }

  /**
   * Variable to navigate to subscription list page
   * @type {void}
   */
  navigateToListPage(): void {
    this.router.navigate(['/app/subscription/companies']);
  }

  /**
   * Method to suspend the particular client from superadmin side
   * @type {void}
   */
  suspendUser(): void {
    const data = { suspend: this.suspend ? false : true, sk: [] };
    if (this.activePlan && this.activePlan.length) {
      for (let i = 0; i < this.activePlan.length; i++) {
        data.sk.push(this.activePlan[i].SK)
      }
    }
    const dialogRef = this.dialogService.openDialog({
      header: 'Confirmation!',
      message: this.suspend ? this.Constants.dialogMessages.resumeConfirmation : this.Constants.dialogMessages.suspensionConfirmation,
      actionType: this.dialogType.confirmation,
      button: { right: this.buttonText.yes, left: this.buttonText.no }
    });
    this.subscriptionObj.add(dialogRef.afterClosed().subscribe((res: any) => {
      if (res) {
        this.invoiceLoader = true;
        this.updateSubsciption(data);
      }
    }));
  }

  /**
   * Method to update for suspending a user
   * @param data holds values
   */
  updateSubsciption(data): void {
    this.subscriptionObj.add(this.commonService.suspendUser(data, this.storeId).subscribe((res: any) => {
      if (res) {
        this.dialogService.openDialog({
          header: this.dialogHeader.success,
          message: this.suspend ? this.Constants.dialogMessages.resumeSuccess : this.Constants.dialogMessages.suspensionSuccess,
          actionType: this.dialogType.success,
          button: { right: this.buttonText.ok }
        });
        this.router.navigate(['/app/subscription/companies']);
      }
      this.invoiceLoader = false;
    }, (err) => {
      this.invoiceLoader = false;
      this.dialogService.openDialog({
        header: this.dialogHeader.failure,
        message: !this.suspend ? this.Constants.dialogMessages.errResuming : this.Constants.dialogMessages.errSuspending,
        actionType: this.dialogType.failure,
        button: { right: this.buttonText.ok }
      });
    }));
  }

  /**
   * Method to send invoice mail
   * @param invoiceDetails variable to store the subscription details
   */
  sendMailInvoice(invoiceDetails: any) {
    this.invoiceLoader = true;
    if (invoiceDetails && invoiceDetails.InvoiceUrl) {
      const mailData = this.formMailData(invoiceDetails);
      const data = {
        mailData: mailData,
        filename: invoiceDetails && invoiceDetails.InvoiceUrl
      };
      this.subscriptionObj.add(this.commonService.sendInvoice(data).subscribe((res: any) => {
        this.invoiceLoader = false;
        if (res) {
          this.dialogService.openDialog({
            header: this.dialogHeader.success,
            message: this.Constants.dialogMessages.sendMailInvoiceSuccess,
            actionType: this.dialogType.success,
            button: { right: this.buttonText.ok }
          });
        }
      }, (err) => {
        this.invoiceLoader = false;
        this.dialogService.openDialog({
          header: this.dialogHeader.failure,
          message: this.Constants.dialogMessages.sendMailInvoiceFailed,
          actionType: this.dialogType.failure,
          button: { right: this.buttonText.ok }
        });
      }));
    }
    else {
      this.invoiceCreation(invoiceDetails, false)
    }
  }
  /**
   * method to set the data to the common card
   */
  setMobilePlanData() {
    this.mobileAppData.forEach(element => {
      element.IsAutoRenewal = element.IsAutoRenewal ? "Yes" : "No";
      element.PlanAmount = parseFloat(element && element.PlanAmount && element.PlanAmount != undefined ? element.PlanAmount : 0).toFixed(2);
      element.TotalAmount = (parseFloat(element.PlanAmount) +
        (parseFloat((element && element.TaxAmount && element.TaxAmount != undefined ? element.TaxAmount : 0).toFixed(2))) +
        (parseFloat((element && element.OtherCharges && element.OtherCharges != undefined ? element.OtherCharges : 0).toFixed(2))))

      if (element.IsActive) {
        const date1 = new Date();
        if (element.EndDate) {
          const date2 = new Date(element.EndDate);
          const differenceInTime = date2.getTime() - date1.getTime();
          const differenceInDays = differenceInTime / (1000 * 3600 * 24);
          element['RemainingDays'] = 'Expires in ' + Math.floor(differenceInDays + 1) + ' days';
        }
        this.mobileActivePlan.push(element);
      } else {
        this.mobileHistoryPlan.push(element);
      }
    });

    this.listPropertyForHistory.isNewImage.isNew = this.mobileHistoryPlan && this.mobileHistoryPlan.length == 0 ? true : false;

    this.columnRefForMobileActivePlan = [
      { column: 'PlanName', type: 'Text', suffixText: 'Plan Name' },
      { column: 'FrequencyType', type: 'Text', suffixText: 'Frequency Type' },
      { column: 'StartDate', type: 'Date', suffixText: 'Start Date' },
      { column: 'EndDate', type: 'Date', suffixText: 'End Date' },
      { column: 'IsAutoRenewal', type: 'Text', suffixText: 'Auto Renewal' },
      { column: 'TotalAmount', type: 'Amount', suffixText: 'Amount', currency: this.currencyUnit },
      { column: 'RemainingDays', type: 'Text', suffixText: 'Remaining Days' },
    ];

    this.columnRefForMobileHistory = [
      { column: 'PlanName', type: 'Text', suffixText: 'Plan Name' },
      { column: 'FrequencyType', type: 'Text', suffixText: 'Frequency Type' },
      { column: 'StartDate', type: 'Date', suffixText: 'Start Date' },
      { column: 'EndDate', type: 'Date', suffixText: 'End Date' },
      { column: 'TotalAmount', type: 'Amount', suffixText: 'Amount', currency: this.currencyUnit },
    ];
  }
  /**
  * Method to navigate to list mobile app subscriptions
  */
  mobileAppSubscription(): void {
    this.isMobileApp = true;
    this.buttonInfo1.push(
      {
        name: 'Web App Subscription', class: 'primary-button', method: 'WebAppSubscription'
      }
    )

    this.buttonInfoForSuperAdmin1.push(
      {
        name: 'Web App Subscription', class: 'primary-button', method: 'WebAppSubscription'
      }
    );
  }
  /**
  * Method to navigate to mobile app purchase page
  */
  onNavigate() {
    this.router.navigate(['/app/mobileplans/', this.storeId]);
  }
  /**
  * Method to navigate to list  subscriptions
  */
  WebAppSubscription(): void {
    this.isMobileApp = false;
    this.buttonInfo1 = [];
    this.buttonInfoForSuperAdmin1 = [];
  }
  /**
   * Component OnDestroy life cycle hook.
   * And unsubscribe all the subscriptions in the component.
   */
  ngOnDestroy(): void {
    this.subscriptionObj.unsubscribe();
  }
}
