import { Injectable, EventEmitter } from '@angular/core';
import { HttpRoutingService } from './httpRouting.service';
import { BehaviorSubject, Subscription } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class websiteSettingsService {
  pageConfig = new BehaviorSubject(null);
  constructor(private httpRoutingService: HttpRoutingService) { }
  currentActiveTab = new EventEmitter<string>();
  manualOrder = new BehaviorSubject<any>(false);



  getAboutSettings(data?) {
    return this.httpRoutingService.getMethod('stores/' + data?.storeId + '/websitesettings');
  }
  getStoreInfoByUniqueId(data?) {
    return this.httpRoutingService.postMethodWithId('user/getStoreInfoByUniqueId', data ? data : {});
  }
  getDeliveryDetails(data) {
    return this.httpRoutingService.postMethod('payment/getDeliveryDetails', data);
  }
  getUserFAQ(data) {
    return this.httpRoutingService.postMethodWithId('user/getUserFAQ', data);
  }
  getFAQCount() {
    return this.httpRoutingService.postMethodWithId('user/getFAQCount', {});
  }
  getPolicy(data) {
    return this.httpRoutingService.postMethodWithId('user/getPolicy', data);
  }

}
