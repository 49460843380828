/**
 * Import statements.
 */
import { Component, Input } from '@angular/core';
import { CommonService } from 'libs/shared/src/lib/services/common.service';
import { Subscription, fromEvent } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { LoadTranslationService } from '@phase-ii/common';
/**
 * Component which is used for common loader for overall application.
 */
@Component({
  selector: 'cen-loader-vii',
  templateUrl: './cen-loader.component.html',
  styleUrls: ['./cen-loader.component.scss']
})

export class CenLoaderComponent {
  /**
   * Variable to store the type of the loader
   * @type {string}
   */
  @Input() type: string;
  @Input() zoom: any;
  awsUrl: string;
  subscriptionObj = new Subscription();
  loaderDetails: any;
  language = "";
  constructor(private commonService: CommonService,
    public route: ActivatedRoute,
    public loaderTranslationService: LoadTranslationService
  ) { }
  ngOnInit(): void {
    this.awsUrl = this.commonService.environment.AWSImageUrl;
    this.subscriptionObj.add(this.commonService.loaderDetails.subscribe((res: any) => {
      this.language = localStorage.getItem('language') ?? 'en';
      this.loaderDetails = res;
    }));
  }
}
