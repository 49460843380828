<cen-loader-vii *ngIf="pageDetails?.isLoader || pageDetails?.listLoader"></cen-loader-vii>
<phase-ii-header-page [heading]="pageDetails?.heading" [buttonInfo]="pageDetails?.buttonInfo"
  (methodClicked)="buttonAction($event)"></phase-ii-header-page>
<div *ngIf="!pageDetails?.isLoader || !pageDetails?.listLoader" class="page-container" fxLayout="row" fxLayoutGap="10px"
  fxLayout.xs="column">
  <mat-card fxFlex="100%">
    <phase-ii-common-card-v-ii [paginatorVersion]="2" [needPaginator]="true" [actionsIcon]="pageDetails?.actionIcons"
      (searchedText)="search($event)" [listProperties]="pageDetails?.listProperty"
      [columnsRef]="pageDetails?.columnsRef" (actionClicked)="listAction($event)"
      (valueChanged)="getChangedData($event)">
    </phase-ii-common-card-v-ii>
  </mat-card>
</div>