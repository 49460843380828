import { Injectable } from '@angular/core';
import { HttpRoutingService, API as Routes } from '@phase-ii/common';
@Injectable({
  providedIn: 'root'
})
export class FilterService {

  constructor(private httpService: HttpRoutingService) { }
  /**
  * Method which is used to get Savedfilters based on code
  * @param code code for Savedfilters Unicode  
  * @returns
  */
  getSavedFilterByCode(storeId, code) {
    const url = this.httpService.replaceUrlParam(Routes.GET_SAVEDFILTER_BY_CODE, {
      storeId: storeId,
    });
    return this.httpService.getMethod(url, { code: code });
  }
  /**
   * Method used to Create Savedfilters details
   * @param storeId is the id of the store.
   * @param data is the data to Create Savedfilters details.
   */
  createSavedFilterByCode(storeId: number, data: any) {
    const url = this.httpService.replaceUrlParam(Routes.CREATE_SAVEDFILTER_BY_CODE, { storeId: storeId });
    return this.httpService.postMethod(url, data);
  }
  /**
  * Method used to Update Savedfilters details
  * @param storeId is the id of the store.
  * @param data is the data to Create Savedfilters details.
  */
  // updateSavedFilterByCode(storeId, data) {
  //   const url = this.httpService.replaceUrlParam(Routes.UPDATE_SAVEDFILTER_BY_CODE, {
  //     storeId: storeId
  //   });
  //   return this.httpService.postMethod(url, data);
  // }

  /**
    * Method used to Delete Savedfilter
    * @param storeId is the id of the store.
    * @param filterId is the data to Create Savedfilters details.
    * @returns 
    */
  deleteSavedFilterByCode(storeId: number, filterId: number) {
    const url = this.httpService.replaceUrlParam(Routes.DELETE_SAVEDFILTER_BY_CODE, { storeId: storeId, filterId: filterId });
    return this.httpService.deleteMethod(url);
  }
}
