import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'sentenceCase'
})
export class SentenceCasePipe implements PipeTransform {

  transform(input: string): string {
    input = input.trim();
    if (!input) return '';
    const sentences = input.split('. ');
    const sentenceCaseSentences = sentences.map((sentence) => {
      return sentence.charAt(0).toUpperCase() + sentence.slice(1).toLowerCase();
    });
    return sentenceCaseSentences.join('. ');
  }

}
