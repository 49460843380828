<div mat-dialog-title fxLayout="row" fxLayoutAlign="space-between center" class="ribbon-header">
    <div>{{data?.ribbonDetail?.length ? 'Edit Ribbon': 'Add Ribbon'}}</div>
    <mat-icon class="show-cursor close-icon" (click)="onClose()">close</mat-icon>
</div>

<!-- form group for ribbon -->
<div mat-dialog-content fxLayout="column" class="ribbon-container">
    <cen-loader *ngIf="isLoader" [type]="'content'" [name]="'cen-line-spinner'">
    </cen-loader>

    <div fxLayout="column" *ngIf="!isLoader && ribbonData?.length >0" fxLayoutGap="5%" class="inner-container">
        <div fxLayout="row">
            <div fxFlex="50%" fxLayout="row wrap" fxLayoutAlign="center" *ngIf="ribbon?.get('design')?.length">
                <div class="option-card mat-elevation-11">
                    <!-- image display with ribbon -->
                    <div
                        [ngClass]="ribbon?.get('design')['controls'][selectedIndex]?.get('isOverlayHeader')?.value ? 'container-inside' : null">
                        <div id="main"
                            *ngIf=" ribbon?.get('design')['controls'][selectedIndex]?.get('overlayRibbonPosition')?.value === 'topLeft' || ribbon?.get('design')['controls'][selectedIndex]?.get('overlayRibbonPosition')?.value === 'topRight'"
                            [ngClass]="ribbon?.get('design')['controls'][selectedIndex]?.get('isOverlayHeader')?.value ? ribbon?.get('design')['controls'][selectedIndex]?.get('overlayRibbonPosition')?.value : null"
                            [innerHtml]="html"></div>
                        <div fxLayout="row" fxLayoutAlign="center "
                            [ngStyle]="(ribbon?.get('design')['controls'][selectedIndex]?.get('overlayRibbonPosition')?.value === 'bottomLeft' || ribbon?.get('design')['controls'][selectedIndex]?.get('overlayRibbonPosition')?.value === 'bottomRight') ? {'position': 'relative;'} : null">
                            <img [ngClass]="'ribbon-editor-image'"
                                [src]="data?.productDetail?.image ? data?.productDetail?.image : '../../../assets/noimagejpg.jpg'"
                                [alt]="data?.productDetail?.name" onerror="this.src='../../../assets/noimagejpg.jpg';">
                            <div *ngIf=" (ribbon?.get('design')['controls'][selectedIndex]?.get('overlayRibbonPosition')?.value === 'bottomLeft' || ribbon?.get('design')['controls'][selectedIndex]?.get('overlayRibbonPosition')?.value === 'bottomRight') && ribbon?.get('design')['controls'][selectedIndex]?.get('isOverlayHeader')?.value"
                                [ngClass]="ribbonName ? ribbonName+ribbon?.get('design')['controls'][selectedIndex]?.get('overlayRibbonPosition')?.value : ribbon?.get('design')['controls'][selectedIndex]?.get('overlayRibbonPosition')?.value"
                                [innerHtml]="html"></div>
                        </div>
                    </div>

                    <div fxLayout="row" fxLayoutAlign=" center" fxLayoutGap="3px" class="product-name-container">
                        <span class="prod-name">{{data?.productDetail?.name }}</span>
                    </div>
                    <div *ngIf="(ribbon?.get('design')['controls'][selectedIndex]?.get('overlayRibbonPosition')?.value === 'bottomLeft' || ribbon?.get('design')['controls'][selectedIndex]?.get('overlayRibbonPosition')?.value === 'bottomRight') && !ribbon?.get('design')['controls'][selectedIndex]?.get('isOverlayHeader')?.value"
                        [innerHtml]="html"></div>
                    <!-- <br><br> -->
                </div>
            </div>
            <div fxFlex="40%">
                <div class="product-heading">{{ribbonDetails?.ribbonHeading}} </div>
                <form [formGroup]="ribbon" *ngIf="ribbon?.get('design')?.length">
                    <div formArrayName="design">
                        <div *ngFor="let ribbons of ribbon?.get('design')?.controls ;let id=index" [formGroupName]="id"
                            fxLayoutGap="13px" fxLayout="column" fxLayout.lt-sm="column">
                            <!-- ribbon name field -->
                            <mat-form-field>
                                <mat-label data-control="ribbonName">Ribbon Name</mat-label>
                                <mat-select formControlName="ribbons"
                                    (selectionChange)="selectedRibbon($event?.value,id)" data-control="ribbons">
                                    <mat-option *ngFor="let designs of ribbonData" [value]="designs">
                                        {{designs?.text}}
                                    </mat-option>
                                </mat-select>
                                <mat-error data-control="ribbonRepeated"
                                    *ngIf="ribbon?.get('design')['controls'][id]?.get('ribbons')?.errors?.isDuplicate">
                                    {{ribbonDetails?.existError}}
                                </mat-error>
                                <mat-error data-control="ribbonRequired"
                                    *ngIf="ribbon?.get('design')['controls'][id]?.get('ribbons')?.touched && ribbon?.get('design')['controls'][id]?.get('ribbons')?.hasError('required')">
                                    {{ribbonDetails?.emptyError}}
                                </mat-error>
                            </mat-form-field>
                            <!-- position field -->
                            <mat-form-field>
                                <mat-label data-control="position">Position</mat-label>
                                <mat-select formControlName="overlayRibbonPosition" data-control="overlayRibbonPosition"
                                    (selectionChange)="changePosition($event?.value,id)">
                                    <mat-option *ngFor="let design of positionType" [value]="design?.value">
                                        {{ribbonDetails[design?.value]}}
                                    </mat-option>
                                </mat-select>
                                <mat-error data-control="positionRequired"
                                    *ngIf="ribbon?.get('design')['controls'][id]?.get('overlayRibbonPosition')?.touched && ribbon?.get('design')['controls'][id]?.get('overlayRibbonPosition')?.hasError('required')">
                                    {{ribbonDetails?.positionRequired}}
                                </mat-error>
                            </mat-form-field>
                        </div>
                    </div>
                </form>
            </div>
        </div>
        <!-- product design card -->
        <div fxLayoutAlign="center center">
            <button mat-raised-button class="ribbon-button"
                (click)="save()" data-control="saveButton">Save</button>
        </div>
    </div>

    <div *ngIf="!isLoader && ribbonData?.length === 0" fxLayout="column" fxLayoutAlign="center center">
        <img src="./assets/sad1.png" class="align-center" class="ribbon-not-found-image" alt="Ribbon not found">
        <span class="ribbon-not-found">{{ribbonDetails?.ribbonNotFound}}</span>
    </div>
</div>