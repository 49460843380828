/**
 * Class to provide constant values to be used throughout blog module
 */
export class BlogConstants {
  /**
  * Variable used to store error message for name field
  */
  Name = {
    required: 'Name is required.',
    pattern: 'Name accepts the characters only.',
    maxlength: 'Name should not exceed 40 characters.'
  }
  /**
* Variable used to store error message for email field
*/
  Email = {
    required: 'Email is required.',
    pattern: 'Email address is invalid.'
  }
  /**
* Variable used to store error message for comment field
*/
  comment = {
    required: 'Comment is required.',
    maxlength: 'Comment should not exceed 255 characters.'
  }

  /**
* Variable used to store comment setting
*/
  commentSettings = {
    pending: 'Pending',
    autoPublished: 'Auto Published'
  }
}
