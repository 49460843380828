import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, TemplateRef, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AuthService } from '@phase-ii/auth';
import { Subscription } from 'rxjs';
import { filter, switchMap } from 'rxjs/operators';
import { ActionIcon, ColumnsRef, CommonAdminShardConstants, ListProperties } from '../../constants/shared-constant';
import { PaginationService } from 'libs/shared/src/lib/services/pagination.service';
import { Heading } from 'libs/shared/src/lib/models/header-page';
import { CommonService } from 'libs/shared/src/lib/services/common.service';

/**
 * This component used to list transaction log
 */
@Component({
  selector: 'phase-ii-transaction-log-list',
  templateUrl: './transaction-log-list.component.html',
  styleUrls: ['./transaction-log-list.component.scss']
})
export class TransactionLogListComponent extends CommonAdminShardConstants implements OnInit, OnDestroy, OnChanges {
  /**
       * Variable which is used to refer the input.
       */
  @ViewChild('reason') reason: TemplateRef<any>;

  /**
   * storeId from parent
   */
  @Input() storeId;
  /**
   * input from superadmin
   */
  @Input() isSuperAdmin;
  /**
   * paginator data for export
   */
  paginatorValue = { limit: null, offset: null };
  /**
* Variable which is used to define the pagesize
* @type {number}
*/
  pageSize: number = new CommonAdminShardConstants().paginator.defaultPageSize;
  // @Input() loader;
  /** 
    * subscription object
    * */
  subscriptionObject: Subscription = new Subscription();
  /**
  * Variable which is used to store Transaction List Count
  */
  transactionLogListCount!: number;
  /**
    * object of datas to be displayed
    */
  pageDetails: {
    heading: Heading,
    listProperty: ListProperties,
    columnRef: ColumnsRef[],
    actionIcons: ActionIcon[],
    isLoader: boolean,
    listDataSource: any,
    checker: any,
    errorHandler: boolean,
    actions: any,
    currencyUnit: any,
    listLoader: boolean,
    searchText: any,
    options: any,
    seletecedFilter: any


  } = {
      heading: null,
      listProperty: null,
      columnRef: null,
      actionIcons: null,
      isLoader: true,
      checker: null,
      listDataSource: null,
      errorHandler: false,
      actions: null,
      currencyUnit: null,
      listLoader: false,
      searchText: null,
      options: [],
      seletecedFilter: 'All'


    };
  /**
   * import header details
   */
  importHeader = [
    [
      { header: 'transactionId', key: 'transactionId', width: '15', sheetNo: 1 },
      { header: 'amount', key: 'amount', width: '15', sheetNo: 1 },
      { header: 'transactionPhase', key: 'transactionPhase', width: '15', sheetNo: 1 },
      { header: 'reasonForFailure', key: 'reasonForFailure', width: '15', sheetNo: 1 },
      { header: 'status', key: 'status', width: '15', sheetNo: 1 },
      { header: 'createdOn', key: 'createdOn', width: '15', sheetNo: 1 },
      { header: 'email', key: 'email', width: '15', sheetNo: 1 },
      { header: 'pluginName', key: 'pluginName', width: '15', sheetNo: 1 }

    ]];

  /**
 * Variable which is used to store pagination Details.
 */
  paginationDetails: {
    pageSize: number,
    itemsCount?: number

  } = {
      pageSize: 10
    }
  /**
   * Component constructor to inject the required services.
   * @param dialog this service is used for dialog box display
   * @param paginationService  this service is used for get & set pagination data
   * @param router this service is used for page navigation
   * @param authService this service is used for user data 
   * @param commonService  this service is used for encrypt decrypt params
   */
  constructor(
    private dialog: MatDialog,
    private paginationService: PaginationService,
    private router: Router,
    private authService: AuthService,

    private commonService: CommonService,
  ) {
    super();
  }

  /**
     * Angular life Cycle hook.
     */
  ngOnInit(): void {
    this.authService.isReadOnly.subscribe((res: any) => {
      this.pageDetails.checker = res;
    });
    if (this.router.url.includes('storestransactionlist')) {
      this.isSuperAdmin = true;
      this.pageDetails.isLoader = false;


    } else {
      this.pageIntialize();
    }
    this.onSearch();
  }
  /**
   *this method used to detect changes in parent component
   */
  ngOnChanges(changes: SimpleChanges) {

    this.pageIntialize();
  }
  /**
 * Method which is used to changed records based on search.

 */
  onSearch(): void {
    this.subscriptionObject.add(this.commonService.searchSubject.pipe(filter(res => res !== null),
      switchMap(response => {
        this.pageDetails.searchText = response;
        return this.commonService.getAllTransactions(this.storeId ? this.storeId : null, { limit: 2 * this.pageSize, offset: 0, searchText: response, filterValue: this.pageDetails.seletecedFilter && this.pageDetails.seletecedFilter == 'All' ? null : this.pageDetails.seletecedFilter });
      })).subscribe((res: any) => {

        this.setPaginationValue(res, true);
        this.pageDetails.isLoader = false;

      }, err => {
        this.pageDetails.isLoader = false;
        this.pageDetails.errorHandler = true;
      }));

  }
  /**
   * this method used to set pagination data
   * @param res response from api call
   * @param isNew boolean to denote first api call
   */
  setPaginationValue(res: any, isNew: boolean) {
    if (res && res.transactions && res.transactions.rows) {
      this.pageDetails.listDataSource = res.transactions.rows;
      this.paginationDetails.itemsCount = res.transactions.count;
      this.pageDetails.listDataSource.forEach(element => {
        element['orderId'] = element.paymentOrder && element.paymentOrder.customizedOrderId ? element.paymentOrder.customizedOrderId : null;
        element['rowActionsIcon'] = element.status && element.status == 'Success' ? [{}] : [
          { iconName: 'visibility', method: 'viewReason', toolTip: 'View Reason', iconColor: '#0492c2' }
        ];
      });
      this.paginationService.listPaginatorData.next(
        {
          new: isNew,
          rows: this.pageDetails.listDataSource,
          count: res.transactions.count
        });
    }
    if (res && res.transactions && res.transactions.count >= 0) {
      if (!this.pageDetails.searchText && this.pageDetails.seletecedFilter == 'All') {
        this.transactionLogListCount = res.transactions.count;
      }
    }
    this.pageDetails.listProperty.isNewImage.isNew = this.transactionLogListCount == 0 ? true : false;
  }
  /**
   * this method used to intialize page
   */
  pageIntialize(): void {
    if (this.storeId) {
      this.variableInitialization();
      this.getAllTransaction(0, 2 * this.pageSize, true);
    }
    else {
      this.subscriptionObject.add(this.authService.user.subscribe((res: any) => {
        if (res && res.storeId) {
          this.storeId = res.storeId;
          this.pageDetails.currencyUnit = res.store && res.store.currencyData ? res.store.currencyData.currencyUnit : null;
          this.variableInitialization();
          this.getAllTransaction(0, 2 * this.pageSize, true);
        }
      }))
    }
  }
  /**
   * this method used for page variable intialization
   */
  variableInitialization(): void {
    this.pageDetails.heading = this.isSuperAdmin ? {
      title: this.transactionHeadings.transactionHistory,
      description: this.transactionHeadings.transactionDescription
    } : {
      title: this.transactionHeadings.transactionHistory,
      description: this.transactionHeadings.transactionDescription,
      isHeaderIcon: true,
      iconLink: this.transactionHeadings.transactionDocLink
    };

    this.pageDetails.columnRef = [
      { column: 'orderId', type: 'Text', suffixText: 'Order Id' },
      { column: 'status', type: 'Chip', backgroundColor: 'green', classCondition: { 'Failed': 'inActiveChip', 'Success': 'requiredChip', 'Pending': 'pendingChip' }, suffixText: 'Payment Status' },
      { column: 'amount', type: 'Amount', suffixText: 'Amount', currency: this.pageDetails.currencyUnit },
      { column: 'transactionPhase', type: 'Text', suffixText: 'Transaction' },
      { column: 'createdOn', type: 'Date', suffixText: 'Created On' }
    ];
    this.pageDetails.listProperty = {
      globalSearch: true,
      columns: 4,
      dataOnly: true,
      rowElementClicked: false,
      suffixText: true,
      searchPlaceHolder: 'Order Id',
      cardBorderCondition: {
        field: 'status',
        condition: {
          'Success': 'greenBorder',
          'Failed': 'redBorder',
          'Pending': 'pendingBorder'
        }
      },
      isNewImage: {
        isNew: false,
        url: './assets/transactionLogList.png',
        text: 'No transaction history is available currently! It will be updated and displayed here once online orders are made.'
      }
    };
    this.pageDetails.options = {
      values: [{ status: 'All' }, { status: 'Success' }, { status: 'Failed' }],
      label: 'Choose Status',                         // Label for the dropdown
      defaultOption: 'All',           // Default option value
      matValueField: 'status',                              // Value field name for mat-select
      matLabelField: 'status'
    };
  }
  /**
   *this method is used to get all role 
   * @param offset offset
   * @type {Number}
   * @param limit limit
   * @type {Number}
   * @param isNew pagination variable
   * @type {boolean}
   */
  getAllTransaction(offset: number, limit: number, isNew: boolean): void {
    const data = {
      offset: offset,
      limit: limit,
      searchText: this.pageDetails.searchText ? this.pageDetails.searchText : '',
      filterValue: this.pageDetails.seletecedFilter && this.pageDetails.seletecedFilter == 'All' ? null : this.pageDetails.seletecedFilter
    };
    this.pageDetails.listLoader = true;
    this.subscriptionObject.add(this.commonService.getAllTransactions(this.storeId ? this.storeId : null, data).subscribe((res: any) => {
      this.setPaginationValue(res, isNew);
      this.pageDetails.isLoader = false;
      this.pageDetails.listLoader = false;
    }, (err) => {
      if (err) {
        this.pageDetails.isLoader = false;
        this.pageDetails.listLoader = false;
        this.pageDetails.errorHandler = true;
      }
    }))
  }
  /**
   * Function which is used for get product details of changed status
   * @param event returns location id while change status
   */
  changeStatus(event: any): void {
    this.pageDetails.seletecedFilter = event;
    this.pageDetails.listLoader = true;
    if (this.pageDetails.seletecedFilter) {
      this.getAllTransaction(0, 2 * this.pageSize, true);
    }
  }
  /**
   * this method used to view reason
   * @param event event emmitted from clickrd row
   */
  viewReason(event: any): void {
    this.dialog.open(this.reason, {
      autoFocus: false,
      data: event.data
    });
  }
  /**
   * this method used to close the reason dialog box
   */
  closeReasonDisplay(): void {
    this.dialog.closeAll();
  }

  /**
* Method which is used to get changed page data.
* @param event To get offset and limit.
* @type {Object}
*/
  getChangedData(event: any): void {
    if (event) {
      this.getAllTransaction(event.offset, event.limit, false);
    }
  }
  /**
   * this method used to get pagination data for export
   * @param event emmitted event
   */
  getPaginatorData(event: any): void {
    this.paginatorValue.limit = event.pageSize;
    this.paginatorValue.offset = event.pageIndex * event.pageSize;
  }
  /**
   * method for action button click
   * @param event id of selected row
   * @type {Object}
   */
  listAction(event: any): void {
    if (event && event.method) {
      this[event.method](event);
    }
  }
  /**
  * method which is called when icon is clicked
  * @param event icon name as event
  */

  /**
 * Method is used for get selected store's id 
 * @param event To get id of store
 */
  selectedStore(event: any): void {
    this.storeId = event.id ? event.id : event;
    if (event.storeDetails && event.storeDetails.length && event.storeDetails[0].currency && event.storeDetails[0].currency.currencyCode)
      this.pageDetails.currencyUnit = event.storeDetails[0].currency.currencyCode;
    this.pageIntialize();
  }
  /**
* Component OnDestroy life cycle hook.
* And unsubscribe all the subscriptions in the component.
*/

  ngOnDestroy(): void {
    this.paginationService.listPaginatorData.next(null);
    this.subscriptionObject.unsubscribe();
  }
}
