<div [fxLayout]="ellipse ? 'column':'row'" [fxLayoutAlign]="ellipse ? 'end end': 'space-between center'"
  [fxLayoutGap]="ellipse ? '10px':''" [ngClass]="{'snackbar':true ,'snackbar-container': ellipse}"
  [fxLayout.sm]="large ? 'column' : 'row'" [fxLayoutAlign.sm]="large ? 'end end' : 'space-between center'"
  fxLayout.xs="column" fxLayoutAlign.xs="end end">
  <div fxLayout="row" fxLayoutAlign="start center" fxFlex="90%" [ngStyle]="{'width': ellipse ? '100%' : '90%'}"
    [ngStyle.sm]="{'width': large ? '100%' : '90%'}"
    [ngClass]="ellipse ? 'snackbar-large-content' : 'snackbar-content'">
    <mat-icon *ngIf="data?.prefixIcon" fxFlex="8%" fxFlex.xs="10%" [fxHide.xs]="large"
      [ngClass]="large ? 'prefix-icon-large' : 'prefix-icon'" data-control="snackbarIcon">
      {{data?.icon}}
    </mat-icon>
    <span *ngIf="data?.msg" class="message" [fxFlex]="data?.prefixIcon ? '90%': '100%'"
      [fxFlex.xs]="data?.prefixIcon && !large ? '90%': '100%'" data-control="snackbarMsg">
      {{data?.msg}}
    </span>
  </div>
  <div [ngClass]="{'snackbar-action-large': ellipse, 'snackbar-action':true}" fxLayout="row" fxFlex="10%"
    fxLayoutAlign="center center" matSnackBarAction (click)="snackBarRef.dismiss()">
    <mat-icon *ngIf="!data?.closeButton && data?.actionIcon" data-control="rightbutton" class="action">
      clear
    </mat-icon>
    <span *ngIf="data?.closeButton" class="action" data-control="snackbarAction">
      {{data?.closeButton}}
    </span>
  </div>
</div>