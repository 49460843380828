<cen-loader-vii *ngIf="pageDetails?.listLoader || pageDetails?.isLoader"></cen-loader-vii>
<div>
  <phase-ii-header-page [heading]="heading">
  </phase-ii-header-page>
  <div class="common-page-container" fxLayout="row" fxLayoutGap="10px" fxLayout.xs="column">
    <div fxFlex="100" *ngIf="pageDetails?.columnRef?.length">
      <mat-card [class.disabled]="pageDetails?.listLoader">
        <phase-ii-common-card-v-ii [errorHandler]="pageDetails?.isError"
            [needPaginator]="true" [paginatorVersion]="2" [listProperties]="pageDetails?.listProperty"
            [columnsRef]="pageDetails?.columnRef" [rightTopFilter]="orderTypeSearch"
            (valueChanged)="getChangedData($event)" (paginatorData)="getPaginatorData($event)" (actionClicked)="listAction($event)" (rightFilterChanged)="orderTypeChange($event)">
        </phase-ii-common-card-v-ii>
    </mat-card>
    </div>
  </div>
</div>
<ng-template #cardDetails>
  <ng-container *ngIf="!pageDetails?.listLoader">
  <p mat-dialog-title class="secondary-button cardDetailsTitle">
      Map payment order
      <span> <mat-icon class="close-icon"
              data-control="dialogClose" (click)="dialogClose();">close</mat-icon></span>
  </p>
<div *ngIf="paymentId === null" class="procedureSection">
  <h4 style="text-align: center;padding: 0% 3% 0% 3%; margin:0;line-height:1.4"> The payment gateway in use here is <span style="color:var(--primary-color);">{{paymentType}}</span>. {{(!showProcedure)? "To know the step-by-step procedure to find the " + placeholder + "," :"Follow the step-by-step procedure to find the " + placeholder+ "."}} &nbsp;<span *ngIf="!showProcedure"
    style="text-decoration: underline;color:var(--primary-color);cursor: pointer;"
    (click)="showProcedure=!showProcedure">click here.&nbsp;</span></h4>
  <div *ngIf="showProcedure" class="procedureAlign">
    <ul>
        <li *ngFor="let procedure of procedureForPayment">{{procedure}}</li>
    </ul>
  </div>
</div>
  <div mat-dialog-content class="card-details-dialog-content">
      <form [formGroup]="cardDetailsForm">
          <div fxLayout="column">
              <mat-form-field appearance="outline">
                <mat-label>{{placeholder}}</mat-label>
                <input data-control="paymentId" matInput
                    formControlName="paymentId" required />
                <mat-error data-control="paymentOrderIdRequired" *ngIf="cardDetailsForm?.get('paymentId')?.hasError('required')">
                    {{errMsgRequired}}
                </mat-error>   
            </mat-form-field>
              <div fxLayout="row" fxLayoutAlign="center center" mat-dialog-actions>
                  <button type="button" data-control="verifyMap" (click)="verifyMap()"
                      [ngClass]="{'primary-button':cardDetailsForm.value,'disabled-button':cardDetailsForm.invalid}"
                      [disabled]="cardDetailsForm.invalid" mat-raised-button>
                      <div><span *ngIf="!pageDetails.buttonLoader">Verify and map</span></div>
                      <div>
                        <cen-loader *ngIf="pageDetails.buttonLoader" [type]="'content'" [name]="'cen-dot-spinner'">
                        </cen-loader>
                      </div>
                  </button>
              </div>
          </div>
      </form>
  </div>
</ng-container>
</ng-template>