/**
 * This component is used for Listing Unfulfilled Orders
 */
import { Component, Input, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ColumnsRef, CommonAdminShardConstants, ListProperties } from '../../constants/shared-constant';
import { filter, mergeMap, Subscription } from 'rxjs';
import { SharedService } from '../../../../../themes/shared-theme/src';
import { DialogService } from 'libs/common/src/lib/services/dialog.service';
import { UNFULFILLED_ORDERS_CONSTANT } from '../../constants/unfulfilledOrdersConstant';
import { PaginationService } from 'libs/shared/src/lib/services/pagination.service';
import { getUnfulfilledOrdersData, orderTypeConstantArray, retryOrderResponse, rowAction, rowDataObject } from '../../models/unfulfilled-orders.model';
import { MatDialog } from '@angular/material/dialog';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { CommonDataService } from 'libs/shared/src/lib/services/common-data.service';

/**
 * Component used to common unfulfilled orders.
 */
@Component({
  selector: 'phase-ii-common-unfulfilled-orders',
  templateUrl: './unfulfilled-orders.component.html',
  styleUrls: ['./unfulfilled-orders.component.scss']
})
export class UnfulfilledOrdersComponent extends CommonAdminShardConstants implements OnInit, OnDestroy {
  /**
   * storeId from parent
   */
  @Input() storeId;
  /**
   * Input from superadmin
   */
  @Input() isSuperAdmin;
  /**
   * Input from storeadmin and superadmin to store orderTypeConstants
   */
  @Input() orderTypeConstants;

  /**
   * Input from storeadmin and superadmin to store readOnly
   */
  @Input() isReadonly;
    /**
   * Input from storeadmin and superadmin to store currencyUnit
   */
  @Input() currencyUnit;
    /**
   * Input from storeadmin and superadmin to store Heading for the component
   */
  @Input() heading;
  /**
  * Variable to hold page details
  * @type {object}
  */
  pageDetails: {
    storeId: number | string;
    listLoader: boolean;
    isLoader: boolean;
    buttonLoader: boolean;
    columnRef: ColumnsRef[],
    listProperty: ListProperties,
    isError: boolean,
    unfulfilledOrdersInfo: any,
    itemsCount: number,
    listDataSource: rowDataObject[]
  } = {
      storeId: null,
      isLoader: null,
      listLoader: null,
      buttonLoader: null,
      columnRef: null,
      listProperty: null,
      isError: false,
      unfulfilledOrdersInfo: [{}],
      itemsCount: 0,
      listDataSource: []
      }

  /**
   * variable used to store pagination details
   * @type {Object}
   */
  paginationDetails: {
    offset: number,
    pageSize: number;
    itemsCount?: number;
    limit?: number;
  } = {
      pageSize: new CommonAdminShardConstants().paginator.defaultPageSize,
      offset: 0,
      itemsCount: null,
      limit: 10
    };
  /**
   * variable that is used to show the cardDetails section
   */
    @ViewChild('cardDetails', { static: false }) cardDetails: TemplateRef<any>;
  /**
   * variable that is used to store preprocessId
   * @type {number}
   */
    preProcessId: number;
  /**
   * variable that is used to store ordertype
   * @type {string}
   */
    orderType: string;
    /**
   * variable that is used to store pluginId
   * @type {number}
   */
    pluginId: number;
    /**
   * variable that is used to store paymentId
   * @type {number}
   */
    paymentId: string;
  /**
   * variable that is used to store paymentType
   * @type {string}
   */
    paymentType: string;
      /**
   * variable to store the orderTypeSearch
   * @type {any}
   */
    orderTypeSearch: any;
  /**
  * Variable which is used to subscribe and unsubscribe.
  * @type {Subscription}
  */
    subscriptionObj: Subscription = new Subscription(); 
  /**
  * cardDetailsForm used to assign card details.
  * @type {FormGroup}
  */
  cardDetailsForm: UntypedFormGroup;
  /**
  * Variable used to store required error message.
  * @type {string}
  */
  errMsgRequired: string = '';
  /**
  * Variable used to store procedure for payment.
  * @type {string[]}
  */
  procedureForPayment:string[]=[];
  /**
  * Variable used to store orderTypeConstants array.
  * @type {orderTypeConstantArray}
  */
  orderTypeConstantsArray: orderTypeConstantArray[]=[];
  /**
  * Variable used to store boolean value.
  * @type {boolean}
  */
  showProcedure:boolean = false;
  /**
  * Variable used to store selectedOrderTypeName.
  * @type {string}
  */
  selectedOrderTypeName: string;
  /**
  * Variable used to store selectedOrderTypeValue.
  * @type {string}
  */
  selectedOrderTypeValue: string[];
  /**
  * Variable used to store data for verification.
  * @type {rowAction}
  */
  dataForVerification: rowAction;
  /**
  * Variable used to store placeholder value.
  * @type {string}
  */
  placeholder: string;
  /**
  * Component constructor to inject reqiured service.
  * @param sharedService To access sharedService.
  * @param paginationService is to access the paginationService.
  * @param dialogService is to access the dialogService.
  * @param dialog is to open the dialog box.
  * @param commonDataService is to access methods from commonDataService.
  */
    constructor(
      private sharedService: SharedService,
      private paginationService: PaginationService,
      private dialogService: DialogService,
      private dialog: MatDialog,
      private commonDataService: CommonDataService,
    ) {
      super()
    }
  /**
  * Component OnInit life cycle hook
  *  @type {void}
  */
  ngOnInit(): void{
        this.storeId = this.storeId? this.storeId : 'DEFAULT';
        this.orderTypeConstantsArray = this.orderTypeConstants?JSON.parse(this.orderTypeConstants).map((type,index)=>({
          id: index + 1,
          type: type
        })):[];
        
        this.orderTypeSearch = {
          values: this.orderTypeConstantsArray,
          label: 'Order Type',
          defaultOption: 0,
          matValueField: 'id',
          matLabelField: 'type'
        };
        this.orderTypeSearch.values.unshift({ id: 0, type: 'All' });
        this.selectedOrderTypeValue = this.orderTypeConstants? JSON.parse(this.orderTypeConstants) : [];
        this.variableInitialization();
        this.getAllUnfulfilledOrders(0, 2 *(this.paginationDetails && this.paginationDetails.pageSize), true);
  }

  /**
   * Method which is used to initialize variables.
   */
  variableInitialization(): void{
    this.pageDetails.columnRef = this.isSuperAdmin ? UNFULFILLED_ORDERS_CONSTANT && UNFULFILLED_ORDERS_CONSTANT.columnSuperAdminUnfulfilledOrders:UNFULFILLED_ORDERS_CONSTANT && UNFULFILLED_ORDERS_CONSTANT.columnForUnfulfilledOrders;
    this.pageDetails.listProperty = {globalSearch: false, columns: 7, dataOnly: true, rowElementClicked: false, suffixText: true, searchPlaceHolder: "Order Id", isNewImage: { isNew: false, url: this.isSuperAdmin?'./assets/no-records-found.png':'./assets/paymentLog.png', text: 'There are no unfulfilled orders available. Unfulfilled order details will be listed once there is a failure in placing an order.'} };
    if (this.pageDetails.columnRef.length && this.pageDetails.columnRef[4] && this.currencyUnit) {
      this.pageDetails.columnRef[4]['currency'] = this.currencyUnit;
    }
  }
  /**
  * Method used for get the UnfulfilledOrders
  * @param offset use to get the offset
  * @param limit use to get the limit
  * @param isNew use to get the isNew
  */
  getAllUnfulfilledOrders(offset: number, limit: number, isNew?: boolean): void{
    const data = { offset: offset, limit: limit, orderTypeConstants: this.selectedOrderTypeName !== 'All'?  JSON.stringify(this.selectedOrderTypeValue) : this.orderTypeConstants};
    this.pageDetails.listLoader = true;
    if (this.storeId !== null) {
      this.subscriptionObj.add(this.sharedService.getAllUnfulfilledOrders(this.storeId, data).subscribe({next:(res: getUnfulfilledOrdersData) => {
      if (res && res['result']['rows']) {
        this.pageDetails.unfulfilledOrdersInfo.count = null;
        this.pageDetails.unfulfilledOrdersInfo = [{}];
        this.unfulfilledOrdersResponse(res, isNew);
      }
      this.pageDetails.isLoader = false;
      this.pageDetails.listLoader = false;
      this.pageDetails.isError = false;
    }, error: (err) => {
      if (err) {
        this.pageDetails.isLoader = false;
        this.pageDetails.listLoader = false;
        this.pageDetails.isError = true;
        this.dialogService.dialogMethod(UNFULFILLED_ORDERS_CONSTANT.dialogMessages.unfulfilledOrdersListFailed, UNFULFILLED_ORDERS_CONSTANT.dialogType.failure, true);
      }
    }
    }))
  }
  }
  /**
   * Method used for handle unfulfilledOrdersResponse
   * @param res to get event
   * @type {getUnfulfilledOrdersData}
   * @param isNew use to get the isNew
   */
  unfulfilledOrdersResponse(res: getUnfulfilledOrdersData, isNew?: boolean): void {
    if (res['result'] && res['result'].rows && res['result'].rows.length) {
      this.pageDetails.listDataSource = res?.['result']?.['rows'] ? res?.['result']?.['rows'] : [];
      this.pageDetails.itemsCount = res?.['result']?.['count'];
      this.pageDetails.listDataSource && this.pageDetails.listDataSource.length && this.pageDetails.listDataSource.forEach((item, index) => {
        this.pageDetails.unfulfilledOrdersInfo[index] = this.isSuperAdmin? {
          id: item && item.id,
          storeName: item && item.storeName,
          storeId: item && item.storeId,
          amount: item && item.amount,
          paymentStatus: item && item.paymentStatus,
          createdAt: item && item.createdAt,
          type: item && item.type,
          paymentOrderId: item && item.paymentOrderId,
          paymentId: item && item.paymentId,
          pluginId: item && item.pluginId
        }:{
          id: item && item.id,
          customerName: item && item.customerName,
          email: item && item.email,
          amount: item && item.amount,
          paymentStatus: item && item.paymentStatus,
          paymentType: item && item.paymentType,
          createdAt: item && item.createdAt,
          type: item && item.type,
          paymentOrderId: item && item.paymentOrderId,
          paymentId: item && item.paymentId,
          pluginId: item && item.pluginId
        };
        item.rowActionsIcon = this.isReadonly ? [] : [ (item?.paymentOrderId) ? { iconName: 'refresh', method: 'onRetryOrder', toolTip: 'Retry Order', iconColor: '#03c04a' } : (this.isSuperAdmin)?{ iconName: 'refresh', method: 'onRetryOrder', toolTip: 'Retry Order', iconColor: '#03c04a' }:{ iconName: 'credit_card', method: 'onCardClick', toolTip: 'card', iconColor: '#016f9b' } ,
            { iconName: 'delete', method: 'onRemove', toolTip: 'Delete Order', iconColor: '#f72121' }
          ]
      });
      if (res && res['result'] && res['result'].count >= 0) {
        this.pageDetails.listProperty.isNewImage.isNew = this.pageDetails.itemsCount == 0 ? true : false;
      }
      this.paginationService.listPaginatorData.next(
        {
          new:isNew,
          rows: this.pageDetails.listDataSource,
          count: this.pageDetails.itemsCount
        });
        this.pageDetails.isLoader = false;
    }
    else{
      this.pageDetails.unfulfilledOrdersInfo = [{}];
      if (res && res['result'] && res['result'].count == 0) {
        this.pageDetails.listProperty.isNewImage.isNew = this.pageDetails.itemsCount == 0 ? true : false;
      }
      this.paginationService.listPaginatorData.next(
        {
        new: isNew,
        rows: [],
        count: this.paginationDetails.limit,
        });
      this.pageDetails.isLoader = false;
      this.pageDetails.listLoader = false;
    }

  }
/**
* Method to delete the particular order from table.
* @param event To get the selected event data for delete
* @type {rowAction}
*/
  onRemove(event: rowAction): void {
      if (event && event.data && event.data.id) {
        const dialogRef = this.dialogService.dialogMethod(UNFULFILLED_ORDERS_CONSTANT && UNFULFILLED_ORDERS_CONSTANT.dialogMessages && UNFULFILLED_ORDERS_CONSTANT.dialogMessages.deleteUnfulfilledOrder,
          UNFULFILLED_ORDERS_CONSTANT.dialogType.confirmation, true);
        this.subscriptionObj.add(dialogRef.afterClosed().pipe(filter((result: boolean) => {
          return result;
        }), mergeMap(():any => {          
          return this.sharedService.deleteUnfulfilledOrders(this.storeId, event.data.id);
        })).subscribe((res) => {
          if (res) {
            this.removeUnFulfilledOrders(event.data.id);
            this.paginationDetails.offset = 0;
            this.paginationDetails.limit = this.paginationDetails.pageSize;
            this.getAllUnfulfilledOrders(this.paginationDetails.offset,this.paginationDetails.limit,true);
            this.dialogService.dialogMethod(UNFULFILLED_ORDERS_CONSTANT.dialogMessages.deleteUnfulfiledOrderSuccess,
              UNFULFILLED_ORDERS_CONSTANT.dialogType.success, true);
          }
        }, (err) => {
          if (err) {
            this.dialogService.dialogMethod(UNFULFILLED_ORDERS_CONSTANT.dialogMessages.deleteUnfulfiledOrderFailure,
              UNFULFILLED_ORDERS_CONSTANT.dialogType.failure, true);
          }
        }));
      }
  }
/**
* Method used to remove an order from card list
* @param id to get id
* @type {number}
*/
  removeUnFulfilledOrders(id: number): void {
    this.pageDetails.itemsCount = this.pageDetails.itemsCount - 1;
    const index = this.pageDetails.unfulfilledOrdersInfo.findIndex((element) => element.id === id);
    if (index >= 0) {
      this.pageDetails.unfulfilledOrdersInfo.splice(index, 1);
    }
  }
  /**
  * Method which is used to get changed page data.
  * @param event To get offset and limit.
  */
  getChangedData(event: { limit: number, offset: number }): void {
    if (event) {
    this.paginationDetails.limit = event.limit;
    this.paginationDetails.offset = event.offset;
    this.getAllUnfulfilledOrders(event.offset, event.limit);
    }
  }
  /**
  * Method used to paginate the unfulfilled orders list
  * @param event to get event
  */
  getPaginatorData(event: { pageSize: number, pageIndex: number }): void {
    if (event) {
      this.paginationDetails.pageSize = event && event.pageSize;
      this.paginationDetails.limit = event.pageSize;
      this.paginationDetails.offset = event.pageIndex * event.pageSize;
    }
  }
  /**
 * method for action button click
 * @param event id of selected row
 * @type {rowAction}
 */
  listAction(event: rowAction): void {
    if (event && event.method) {
      this[event.method](event);
    }
  }
/**
 * Method for filter the order type wise
 * @param event
 * @type {number}
 */
  orderTypeChange(event: number): void {
    this.pageDetails.isLoader = true;
    const selectedType = this.orderTypeConstantsArray && this.orderTypeConstantsArray[event] && this.orderTypeConstantsArray[event].type;   
    this.selectedOrderTypeName = selectedType;
    this.selectedOrderTypeValue = [selectedType];
    this.paginationDetails.offset = 0;
    this.paginationDetails.limit = this.paginationDetails.pageSize;
    this.getAllUnfulfilledOrders(this.paginationDetails.offset,this.paginationDetails.limit,true);
  }
  /**
   * Method to retry order
   * @param event data from the selected row
   * @type {rowAction}
   */
  onRetryOrder(event: rowAction): void {
    if (event && event.data && event.data.id) {
      const data = { preProcessId: event.data.id, isRetryOrder: true, currentDate: new Date()};
      this.pageDetails.listLoader = true;
      this.subscriptionObj.add(this.sharedService.retryOrder(this.storeId, data).subscribe({next:(res: retryOrderResponse) => {    
        if (res && res.success) {
          this.removeUnFulfilledOrders(event.data.id);
          this.paginationDetails.offset = 0;
          this.paginationDetails.limit = this.paginationDetails.pageSize;
          this.getAllUnfulfilledOrders(this.paginationDetails.offset,this.paginationDetails.limit,true);
          this.dialogService.dialogMethod(UNFULFILLED_ORDERS_CONSTANT.dialogMessages.orderCreationSuccess, UNFULFILLED_ORDERS_CONSTANT.dialogType.success, true);
        }
        else {
          this.dialogService.dialogMethod(UNFULFILLED_ORDERS_CONSTANT.dialogMessages.orderCreationFailed, UNFULFILLED_ORDERS_CONSTANT.dialogType.failure, true);
        }
        this.pageDetails.isLoader = false;
        this.pageDetails.listLoader = false;
        this.pageDetails.isError = false;
      }, error: (err) => {
        if (err) {
          this.pageDetails.isLoader = false;
          this.pageDetails.listLoader = false;
          this.pageDetails.isError = true;
          this.dialogService.dialogMethod(UNFULFILLED_ORDERS_CONSTANT.dialogMessages.orderCreationFailed, UNFULFILLED_ORDERS_CONSTANT.dialogType.failure, true);
        }
      }
      }))
    }
  }
  /**
   * Method to display card
   * @param event data from the selected row
   * @type {rowAction}
   */
  onCardClick(event: rowAction): void{
    if(event && event.data && event.data.id){
      this.dataForVerification = event;
      this.preProcessId = event.data.id;
      this.paymentType = event.data.paymentType ? event.data.paymentType : null;
      this.orderType = event.data.type ? event.data.type : null;
      this.pluginId = event.data.pluginId ? event.data.pluginId : null;
      this.paymentId = event.data.paymentId ? event.data.paymentId : null;
      this.placeholder = event.data.placeholder ? event.data.placeholder : 'Payment Id' ;
      this.cardDetailsForm = new UntypedFormGroup({
        paymentId: new UntypedFormControl({value: event.data.paymentId ? event.data.paymentId : null, disabled:this.paymentId!=null},[Validators.required])
      })
      this.errMsgRequired = (this.placeholder + ' ') + (UNFULFILLED_ORDERS_CONSTANT && UNFULFILLED_ORDERS_CONSTANT.errorMessages && UNFULFILLED_ORDERS_CONSTANT.errorMessages.requiredMsg);
      this.procedureForPayment = UNFULFILLED_ORDERS_CONSTANT && UNFULFILLED_ORDERS_CONSTANT?.[this.paymentType]? UNFULFILLED_ORDERS_CONSTANT?.[this.paymentType]:[];
      this.dialog.open(this.cardDetails, { disableClose: true, autoFocus: false, width: '800px' });
    }
  }

  /**
   * Method to close the dialog box and resetting the form in the dialogbox
   * @type {void}
   */
    dialogClose(): void {
      if (this.cardDetailsForm && this.cardDetailsForm.dirty) {
        const dialogRef = this.dialogService.dialogMethod(UNFULFILLED_ORDERS_CONSTANT.dialogMessages.unsavedChanges, UNFULFILLED_ORDERS_CONSTANT.dialogType.confirmation, true, true);
        this.subscriptionObj.add(dialogRef.afterClosed().subscribe((result: any) => {
          if (result) {
            this.showProcedure = false;
            this.dialog.closeAll();
            this.cardDetailsForm.reset();
          }
        }));
      } else {
        this.showProcedure = false;
        this.dialog.closeAll();
        this.cardDetailsForm.reset();
      }
    }
  /**
   * Method used to verify and map
   * @type {void}
   */
    verifyMap():void{
      const data = { id : this.preProcessId, paymentId: this.commonDataService.encryptDetails(this.cardDetailsForm?.value?.paymentId), paymentType: this.paymentType, orderType: this.orderType, pluginId: this.pluginId };
      this.pageDetails.listLoader = true;
      this.pageDetails.buttonLoader = true;
      this.subscriptionObj.add(this.sharedService.verifyPaymentOrder(this.storeId, data).subscribe({next:(res: any) => {   
        if(res){ 
          if (res.result && res.result.paymentOrderId) {
            this.pageDetails.buttonLoader = false;
            this.pageDetails.listLoader = false;
            this.dialogService.dialogMethod(UNFULFILLED_ORDERS_CONSTANT.dialogMessages.verifyPaymentOrderSuccess, UNFULFILLED_ORDERS_CONSTANT.dialogType.success, true);
            const dialogRef=this.dialogService.dialogMethod(UNFULFILLED_ORDERS_CONSTANT.dialogMessages.retryConfirmation, UNFULFILLED_ORDERS_CONSTANT.dialogType.confirmation, true, true);
            this.subscriptionObj.add(dialogRef.afterClosed().subscribe((result: any) => {
              if (result) {
                this.onRetryOrder(this.dataForVerification);
              }
              else{
                this.paginationDetails.offset = 0;
                this.paginationDetails.limit = this.paginationDetails.pageSize;
                this.getAllUnfulfilledOrders(this.paginationDetails.offset,this.paginationDetails.limit,true);
              }
            }));
          }
          else {
            this.dialogService.dialogMethod(UNFULFILLED_ORDERS_CONSTANT.dialogMessages.verifyPaymentOrderFailed, UNFULFILLED_ORDERS_CONSTANT.dialogType.failure, true);
            this.pageDetails.buttonLoader = false;
          }
        }
        this.pageDetails.isLoader = false;
        this.pageDetails.listLoader = false;
        this.pageDetails.isError = false;
      }, error: (err) => {
        if (err) {
          this.pageDetails.isLoader = false;
          this.pageDetails.listLoader = false;
          this.pageDetails.buttonLoader = false;
          this.pageDetails.isError = true;
          this.dialogService.dialogMethod(err.error.error.details.message, UNFULFILLED_ORDERS_CONSTANT.dialogType.failure, true);
        }
      }
      }))
      this.dialog.closeAll();
    }
  /**
  * Method used to unsubscribe the subcribtion arrays
  * @type {void}
  */
  ngOnDestroy(): void {
    this.paginationService.listPaginatorData.next(null);
    this.subscriptionObj.unsubscribe();
  }
}
