import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, UntypedFormArray } from '@angular/forms';
/**
 * Component used to diaply the single question set
 */
@Component({
  selector: 'phase-ii-custom-questions',
  templateUrl: './custom-questions.component.html',
  styleUrls: ['./custom-questions.component.scss']
})
export class CustomQuestionsComponent implements OnInit {
  /**
   * Variable used to store the single question set.
   * @type {any}
   */
  @Input() question: any;
  /**
   * Variable used to emit the formcontrol values.
   * @type {EventEmitter} 
   */
  @Output() customQuestionValues = new EventEmitter<any>();
  /**
   * Variable used to store the dynamic formcontrol values.
   * @type {FormGroup}
   */
  questionForm = new UntypedFormGroup({});
  /**
   * Component onInit life cycle.
   */
  ngOnInit(): void {
    if (this.question.controlTypeName === 'Checkbox') {
      this.questionForm = new UntypedFormGroup({
        [this.question.checkoutQuestionId]: new UntypedFormArray([])
      });
      this.question.optionValues.forEach((item) => {
        (<UntypedFormArray>this.questionForm.get([this.question.checkoutQuestionId])).push(new UntypedFormGroup({
          optionName: new UntypedFormControl(item),
          optionChecked: new UntypedFormControl(false)
        }));
      });
    } else {
      this.questionForm = new UntypedFormGroup({
        [this.question.checkoutQuestionId]: new UntypedFormControl(null)
      });
    }
  }
}