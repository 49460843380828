<cen-loader-vii *ngIf="pageDetails?.isLoader"></cen-loader-vii>
<phase-ii-header-page [heading]="pageDetails?.heading" [buttonInfo]="pageDetails?.buttonInfo"
    (methodClicked)="buttonAction($event)">
</phase-ii-header-page>

<div class=" page-container" fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="10px" fxLayout.xs="column">
    <div *ngIf="pageDetails?.isFilter" fxLayout="column" class="isFilter">
        <phase-ii-filter [filters]="pageDetails?.UserListFilter" (filterClosed)="closeFilter()"
            (filterPredicate)="onFilterSelected($event)">
        </phase-ii-filter>
    </div>
    <mat-card fxFlex="100">
        <phase-ii-common-card-v-ii [listProperties]="listProperty" [needPaginator]="true" [paginatorVersion]="2"
            (iconClicked)="listAction($event)" [actions]="pageDetails?.headerAction" (searchedText)="search($event)"
            [columnsRef]="pageDetails?.columnRef" (paginatorData)="getPaginatorData($event)"
            (valueChanged)="getChangedData($event)" (actionClicked)="listAction($event)"
            (moreActionsClicked)="listAction($event)">
        </phase-ii-common-card-v-ii>
    </mat-card>
</div>

<ng-template #viewAccountDetails class="viewAccountDetails">
    <h5 mat-dialog-title class='viewdetails' fxLayout="row">View Account Details
        <span class='example-spacer'></span>
        <mat-icon class='closeIcon' matDialogClose data-control="close">close</mat-icon>
    </h5>
    <div mat-dialog-content>
        <div>
            <h3 class=" main_card_header" fxLayout="row">
                <strong fxFlex="35%">Account Holder Name
                    <Code></Code></strong>:&nbsp;
                <strong class="form-value" fxFlex="65%">
                    {{ value?.accountHolderName ?
                        value?.accountHolderName : '-' }}
                </strong>
            </h3>
            <h3 class=" main_card_header" fxLayout="row">
                <strong fxFlex="35%">Bank Name
                    <Code></Code></strong>:&nbsp;
                <strong class="form-value" fxFlex="65%">
                    {{ value?.bankName ?
                        value?.bankName : '-' }}
                </strong>
            </h3>
            <h3 class="main_card_header" fxLayout="row">
                <strong fxFlex="35%">Branch Name<Code></Code></strong>:&nbsp;
                <strong class="form-value" fxFlex="65%">
                    {{ value?.branchName ?
                        value?.branchName : '-' }}
                </strong>
            </h3>
            <h3 class="main_card_header" fxLayout="row">
                <strong fxFlex="35%">Account Number</strong>:&nbsp;
                <strong class="form-value" fxFlex="65%">
                    {{ value?.accountNumber ?
                        value?.accountNumber : '-' }}
                </strong>
            </h3>
            <h3 class="main_card_header" fxLayout="row">
                <strong fxFlex="35%">IFSC code
                </strong>:&nbsp;
                <strong class="form-value" fxFlex="65%">
                    {{ value?.ifscCode ?
                        value?.ifscCode : '-' }}
                </strong>
            </h3>
            <h3 class="main_card_header" fxLayout="row">
                <strong fxFlex="35%">MICR code<Code></Code></strong>:&nbsp;
                <strong class="form-value" fxFlex="65%">
                    {{ value?.micrCode ?
                        value?.micrCode : '-' }}
                </strong>
            </h3>
            <h3 class="main_card_header" fxLayout="row">
                <strong fxFlex="35%">VPA Id<Code></Code></strong>:&nbsp;
                <strong class="form-value" fxFlex="65%">
                    {{ value?.vpaId ?
                        value?.vpaId : '-' }}
                </strong>
            </h3>
        </div>
    </div>
</ng-template>