<div class="share-button-container">
  <div class="align-button" *ngFor="let button of include">
    <button mat-fab [shareButton]="button" #bt="shareButton" [style.backgroundColor]="bt?.color" [url]="url"
      [title]="title" [description]="description" [image]="image"
      [ngClass]="{'size' : true, 'tw-button' : button === 'twitter'}" (click)="onButtonClick(button)">
      <img class="share-icon-image" [src]="'./assets/social-share-icons/' + button + '.png'" *ngIf="bt"
        alt="social icon">
    </button>
    <p *ngIf="showText" [style.color]="bt?.color" [ngClass]="{'social-icon-text' : true, 'tw' : button === 'twitter'}">
      {{button === 'copy' ? 'Copy link' : capitalizeFirstLetter(button)}}</p>
  </div>
</div>