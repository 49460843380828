import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { PaginationService } from '../../services/pagination.service';
import { CommonConstants, PaginatorDetails } from '../../constants/shared-constant';
import { Subscription } from 'rxjs';

@Component({
  selector: 'phase-ii-paginator',
  templateUrl: './paginator.component.html',
  styleUrls: ['./paginator.component.scss']
})
export class PaginatorComponent extends CommonConstants implements OnInit, OnDestroy {
  pageData = {
    rows: [],
    count: 0,
    new: true
  };
  // count = 0;
  pageOptions: PaginatorDetails = {
    pageIndex: 0,
    pageSize: this.paginator.defaultPageSize,
    previousPageIndex: null,
    length: null
  };
  dataArray = [];
  @Output() emitData = new EventEmitter(null);
  @Output() valueChanged = new EventEmitter(null);
  @Output() paginatorData = new EventEmitter(null);
  @Input() isLocalPagination: boolean = false;
  isOrderChanged: boolean;
  isLoading: boolean;
  startPosition = 0;
  lastIndexStored = 0;
  currentIndex = 0;
  reversedArray: boolean;
  moveForward = true;
  arrayFirstIndex = 0;
  offset = 0;
  startIndex = 0;
  jumpedToFirst = false;
  jumpedToLast :boolean;
  prevPageSize: number;
  deleteCount = 0;
  isDeleted = false;
  continuousDelete = true;
  continuousDeleteCount = 0;
  prevOffset = 0;
  subscriptionObj: Subscription = new Subscription();
  // isLoader = true;
  constructor(private paginationService: PaginationService) {
    super();
  }

  ngOnInit() {
    this.subscriptionObj.add(this.paginationService.listPaginatorData.subscribe((data) => {
      // console.log("Data.....", data);
      if (!this.isLocalPagination) {
        // console.log('data', data);
        if (data && data.count > 0) {
          // this.isLoader = false;
          if (data.id) {
            const tempData = this.dataArray.find((result, index) => {
              if ((data.field && result[data.field] === data.id) || (!data.field && result.id === data.id)) {
                if (data.rows.length > 0) {
                  this.dataArray[index] = data.rows[0];
                  data.rows = [];
                }
                else {
                  let tempOffset, tempStartIndex, tempArray = [];
                  this.isDeleted = true;
                  this.deleteCount += 1;
                  // console.log("StartIndex", this.startIndex, this.prevOffset, this.jumpedToLast, this.jumpedToFirst, this.moveForward);
                  if (this.jumpedToLast) {
                    tempOffset = null;
                  }
                  else if (this.moveForward) {
                    if (this.prevOffset === 0)
                      tempOffset = (this.pageOptions.pageSize * 2) - 1;
                    // tempOffset = (this.pageOptions.pageSize * 2) - this.deleteCount;
                    else
                      tempOffset = this.prevOffset + this.pageOptions.pageSize - 1;
                  }
                  else if ((this.prevOffset) > (this.pageOptions.pageSize * this.pageOptions.pageIndex)) {
                    tempOffset = this.prevOffset + this.pageOptions.pageSize - 1;
                  }
                  else {
                    tempOffset = this.prevOffset + this.dataArray.length - 1;
                  }
                  if (this.continuousDelete) {
                    tempOffset += this.continuousDeleteCount;
                    this.continuousDeleteCount += 1;
                  }
                  // console.log("TempOffset.............startIndex ..............", tempOffset, this.startIndex);
                  if (tempOffset && tempOffset < this.pageOptions.length) {
                    // this.isLoader = true;
                    this.valueChanged.emit({
                      // offset: tempOffset + this.deleteCount,
                      offset: tempOffset,
                      limit: 1
                    });
                  }
                  this.pageOptions.length = data.count;
                  this.dataArray.splice(index, 1);
                  tempStartIndex = this.startPosition - (this.arrayFirstIndex * this.pageOptions.pageSize);
                  tempArray = this.dataArray.slice(tempStartIndex, tempStartIndex + this.pageOptions.pageSize);
                  if (tempArray.length === 0) {
                    // this.pageOptions.pageIndex -= 1;
                    // this.startPosition -= this.pageOptions.pageSize;
                    // tempStartIndex = this.startPosition - (this.arrayFirstIndex * this.pageOptions.pageSize);
                    // console.log("Pageoptions.....", this.pageOptions);
                    this.pageOptions.previousPageIndex = this.pageOptions.pageIndex;
                    this.pageOptions.pageIndex -= 1;
                    this.onPageChanged(this.pageOptions);
                    // console.log("Conditions ......... ", !this.isCurrentPageFirstPage(), !this.moveForward, (this.pageOptions.pageIndex - this.arrayFirstIndex === 0), (this.pageOptions.pageIndex !== 0), this.pageOptions.pageIndex, this.arrayFirstIndex);
                    // if (!this.isCurrentPageFirstPage() && (this.pageOptions.pageIndex - this.arrayFirstIndex === 0) && (this.pageOptions.pageIndex !== 0)) {
                    //   this.offset = (this.arrayFirstIndex - 1) * this.pageOptions.pageSize;
                    //   this.prevOffset = this.offset;
                    //   this.valueChanged.emit({
                    //     offset: this.offset,
                    //     limit: this.pageOptions.pageSize
                    //   });
                    // }
                  }
                  else {
                    // console.log('125 temparray else emited');
                    this.emitData.emit(this.dataArray.slice(tempStartIndex,
                      tempStartIndex + this.pageOptions.pageSize));
                  }
                }
                return true;
              }
            });
          }
          else {
            this.continuousDelete = false;
            this.continuousDeleteCount = 0;
            if (data.new) {
              this.startPosition = 0;
              this.pageOptions.pageIndex = 0;
              this.pageOptions.previousPageIndex = 0;
              this.deleteCount = 0;
              this.dataArray = [];
              // this.pageOptions.pageSize = this.paginator.defaultPageSize;
              this.jumpedToLast = false;
            }
            if (data.rows.length) {
              if (this.dataArray.length === 0) {
                if (this.jumpedToLast) {
                  // console.log('jumpedToLast called');
                  this.emitData.emit(data.rows.slice(this.pageOptions.pageSize, 2 * this.pageOptions.pageSize));
                } else if (this.jumpedToFirst) {
                  // console.log('jumpedToFirst called');
                  this.emitData.emit(data.rows.slice(0, this.pageOptions.pageSize));
                } else {
                  // console.log('else of first and last jump called');
                  // console.log("Initial value...", this.startPosition, this.startPosition + this.pageOptions.pageSize);
                  this.emitData.emit(data.rows.slice(this.startPosition, this.startPosition + this.pageOptions.pageSize));
                }
              }
              this.pageOptions.length = data.count;
              this.appendData(data);
            }
          }
        } else {
          // this.isLoader = false;
          // console.log('Else part of data count = 0 called');
          this.dataArray = [];
          this.emitData.emit([]);
        }
      }
      else {
        if (data && data.count > 0 && data.rows) {
          // console.log('initial', this.pageOptions);

          this.dataArray = data.rows;
          this.pageOptions.length = data.count;
          let offset = this.pageOptions.pageIndex * this.pageOptions.pageSize;
          const limit = this.pageOptions.pageSize;
          // console.log(offset, limit);
          if (offset >= this.dataArray.length) {
            offset -= limit;
            this.pageOptions.pageIndex = this.pageOptions.previousPageIndex;
            this.pageOptions.previousPageIndex -= this.pageOptions.previousPageIndex;
          }
          // console.log('line 184 called');
          this.emitData.emit(this.dataArray.slice(offset, offset + limit));
          // this.emitData.emit(this.dataArray.slice(0, this.pageOptions.pageSize));
        } else {
          // this.isLoader = false;
          this.dataArray = [];
          // console.log('line 190 called');
          this.emitData.emit([]);
        }
      }
    }));
  }

  /**
   * Method which is used to check whether the user in last page
   */
  isCurrentPageFirstPage() {
    return this.pageOptions && this.pageOptions.pageIndex === 0;
  }
  /**
   * Method which is used to check whether the user in last page
   */
  isCurrentPageLastPage() {
    return ((this.pageOptions.pageIndex + 1) * this.pageOptions.pageSize) >= this.pageOptions.length;
  }

  onPageChanged(event) {
    this.prevPageSize = this.pageOptions.pageSize;
    this.pageOptions = event;
    // console.log('page change event', event);
    this.paginatorData.emit(event);
    if (!this.isLocalPagination) {
      if (this.pageOptions.pageSize === this.prevPageSize) {
        if ((this.pageOptions.pageIndex > this.pageOptions.previousPageIndex)) {
          this.moveForward = true;
        }
        else if (this.pageOptions.pageIndex < this.pageOptions.previousPageIndex) {
          this.moveForward = false;
        }
        if (this.moveForward) {
          this.startPosition += this.pageOptions.pageSize;
        }
        else {
          this.startPosition -= this.pageOptions.pageSize;
        }
        if (this.isCurrentPageFirstPage() && ((this.pageOptions.previousPageIndex - this.pageOptions.pageIndex) >= 2)) {
          this.jumpedToFirst = true;
          this.jumpedToLast = false;
          this.prevOffset = 0;
          this.dataArray = [];
          this.startPosition = 0;
          this.arrayFirstIndex = 0;
          this.deleteCount = 0;
          // this.moveForward = true;
          // this.isLoader = true;
          this.valueChanged.emit({
            offset: 0,
            limit: this.pageOptions.pageSize * 2
          });
          return;
        }
        else if (this.isCurrentPageLastPage() && ((this.pageOptions.pageIndex - this.pageOptions.previousPageIndex) >= 2)) {
          this.jumpedToLast = true;
          this.jumpedToFirst = false;
          this.prevOffset = (this.pageOptions.pageIndex - 1) * this.pageOptions.pageSize;
          this.dataArray = [];
          this.startPosition = this.pageOptions.pageIndex * this.pageOptions.pageSize;
          this.arrayFirstIndex = this.pageOptions.pageIndex - 1;
          this.deleteCount = 0;
          // this.moveForward = false;
          // this.isLoader = true;
          this.valueChanged.emit({
            offset: (this.pageOptions.pageIndex - 1) * this.pageOptions.pageSize,
            limit: this.pageOptions.pageSize * 2
          });
          return;
        }
      } else {
        this.pageOptions.pageIndex = 0;
        this.pageOptions.previousPageIndex = 0;
        this.prevOffset = 0;
        // this.isLoader = true;
        this.valueChanged.emit({
          offset: 0,
          limit: this.pageOptions.pageSize * 2
        });
        this.dataArray = [];
        this.startPosition = 0;
        this.arrayFirstIndex = 0;
        this.deleteCount = 0;
        return;
      }

      this.startIndex = this.startPosition - (this.arrayFirstIndex * this.pageOptions.pageSize);

      // console.log("StartPosition", this.startPosition, this.arrayFirstIndex, this.startIndex, this.dataArray);
      // console.log('280 called','start', this.startIndex,'end' ,this.startIndex + this.pageOptions.pageSize);
      // console.log(this.dataArray);
      this.emitData.emit(this.dataArray.slice(this.startIndex, this.startIndex + this.pageOptions.pageSize));
      if (!this.isCurrentPageLastPage() && this.moveForward && (this.dataArray.length <= ((1 + this.pageOptions.pageIndex - this.arrayFirstIndex) * this.pageOptions.pageSize))) {
        this.offset = (this.pageOptions.pageIndex + 1) * this.pageOptions.pageSize
      }
      else if (!this.isCurrentPageFirstPage() && !this.moveForward && (this.pageOptions.pageIndex - this.arrayFirstIndex === 0) && (this.pageOptions.pageIndex !== 0)) {
        this.offset = (this.arrayFirstIndex - 1) * this.pageOptions.pageSize;
        // this.arrayFirstIndex -= 1;
      }
      else {
        this.offset = null;
      }
      if (this.offset != null) {
        this.prevOffset = this.offset;
        // this.isLoader = true;
        this.valueChanged.emit({
          // offset: this.deleteCount ? (this.offset + this.deleteCount) : this.offset,
          offset: this.offset,
          // limit: this.deleteCount ? (this.pageOptions.pageSize + this.deleteCount) : this.pageOptions.pageSize
          limit: this.pageOptions.pageSize
        });
        // this.deleteCount = 0;
      }
    } else {
      const offset = event.pageIndex * event.pageSize;
      const limit = this.pageOptions.pageSize;
      // console.log(offset, limit);
      // console.log('307 called');
      this.emitData.emit(this.dataArray.slice(offset, offset + limit));

    }
  }

  appendData(data) {

    if (this.isDeleted && (data.rows.length === 1)) {
      this.dataArray = [...this.dataArray, ...data.rows];
      this.isDeleted = false;
      // console.log("Data Array ..:", this.dataArray);
      return;
    }
    if (this.dataArray.length > (4 * this.pageOptions.pageSize)) {
      this.jumpedToLast = false;
      this.jumpedToFirst = false;
      if (this.moveForward) {
        this.dataArray = this.dataArray.slice(this.pageOptions.pageSize);
        this.arrayFirstIndex += 1;
      } else {
        this.dataArray = this.dataArray.slice(0, 4 * this.pageOptions.pageSize);
        this.arrayFirstIndex -= 1;
      }
    }
    else {
      if (this.jumpedToLast && !this.moveForward) {
        this.arrayFirstIndex -= 1;
      }
    }

    if (this.moveForward) {
      this.dataArray = [...this.dataArray, ...data.rows]
    }
    else {
      this.dataArray = [...data.rows, ...this.dataArray]
    }
    // console.log("Data Array ..:", this.dataArray);

  }
  ngOnDestroy() {
    if (this.subscriptionObj) {
      this.subscriptionObj.unsubscribe();
    }
    this.dataArray = [];
  }
}
