import { Component, OnInit, TemplateRef } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { DialogService } from 'libs/common/src/lib/services/dialog.service';
import { AffiliateAuthService } from '../../services/auth.service';
import { Router } from '@angular/router';
import { Subscription, of } from 'rxjs';
import { CommonConstants, CommonDataService } from '@phase-ii/shared';
import { mergeMap } from 'rxjs/operators';
import { AuthConstants } from '../../constants/authConstant';

@Component({
  selector: 'phase-ii-admin-dashboard',
  templateUrl: './admin-dashboard.component.html',
  styleUrls: ['./admin-dashboard.component.scss'],
})
export class AdminDashboardComponent implements OnInit {
  /**
   * Toottip for ask for payment button
   */
  maTipAskForPayment = 'To enable the "Ask for Payment" button, you need to add an account';
  /**
   * Arrays to store counts and target counts for different metrics.
   */
  count = [0, 0, 0, 0];
  /**
   * Arrays to store counts and target counts for different metrics.
   */
  targetCount = [0, 0, 0, 0];
  /**
   * Boolean flag for displaying cash-update-content-container UI elements.
   */
  display = false;
  /**
  * Variables for payout form and related functionality.
  */
  maxPayoutValue: any;
  /**
  * Variables for payout form and related functionality.
  */
  payoutDialogBoxRef: any;
  /**
  * Variables for payout form and related functionality.
  */
  PayoutForm: FormGroup;
  /**
  * Variables for affiliate market user ID.
  */
  userId: any;
  /**
   * Boolean flags for controlling button states.
   */
  disableButton = false;
  /**
   * Boolean flags to check super admin user.
   */
  isSuperAdmin: any;
  /**
   * Variables for Threshold value.
   */
  thresholdValue: any;
  /**
   * Variables for completed threshold values.
   */
  completedThresholdValue: any;
  /**
   * Variables for currency symbol.
   */
  currencySymbol: any;
  /**
  * Subscriptionobj for managing observables.
  */
  subscriptionObj: Subscription = new Subscription();
  /**
  * Boolean flag for displaying info icon.
  */
  showInfoIcon = false;
  /**
   * Loader flags to display loader icon.
   */
  loader = true;
  /**
   * Loader flags to display loader icon.
   */
  payoutLoader = false;
  /**
  * Boolean flag to check payment process.
  */
  paymentOnProcess = false;
  /*
  * Common constants used in the component. An instance of the CommonConstants class is created for reusable constants.
  */
  commonConstant = new CommonConstants();
  /*
  *  Auth constants used in the component. An instance of the AuthConstants class is created for reusable constants.
  */
  authConstants = new AuthConstants();

  /**
   * Constructor for AdminDashboardComponent.
   * @param {Router} router - Angular router for navigation.
   * @param {AffiliateAuthService} authService - Service for affiliate user authentication.
   * @param {MatDialog} dialog - Angular Material dialog for UI interaction.
   * @param {CommonDataService} commonDataService - Service for common data operations.
   * @param {DialogService} dialogService - Service for managing dialogs.
   */
  constructor(
    private router: Router,
    private authService: AffiliateAuthService,
    private dialog: MatDialog,
    private commonDataService: CommonDataService,
    private dialogService: DialogService
  ) { }

  /**
   * Lifecycle hook: Angular OnInit.
   * Initiates the component with user details and sets up necessary data.
   */
  ngOnInit(): void {
    this.subscriptionObj.add(this.authService.user.pipe(mergeMap((res: any) => {
      if (res) {
        this.userId = res.id;
        this.currencySymbol = res.currencyData && res.currencyData.currency && res.currencyData.currency.currencySymbol;
        this.isSuperAdmin = res.isSuperadmin;
        return this.authService.getUserBankAccountDetails(this.userId);
      }
      else {
        return of(null);
      }
    }
    )).subscribe((res) => {
      if (res) {
        const decryptedData = this.commonDataService.decryptDetails(res['result']);
        const decryptedDataResult = JSON.parse(decryptedData);
        if (decryptedDataResult) {
          this.initializeCardValues(true);
        } else {
          this.initializeCardValues(false);
        }
      }
    }, (err) => {
      this.dialogService.dialogMethod(this.authConstants.errorMessage.generalError, this.commonConstant.dialogType.failure, true);
    }));
  }

  /**
   * Initializes card values for the Admin Dashboard based on affiliate user details.
   * If the user is not a super admin, it fetches affiliate user details,
   * calculates various metrics, and updates the UI accordingly.
   * @param {boolean} userBankAccDetailsExist - Indicates whether user bank account details exist.
   */
  initializeCardValues(userBankAccDetailsExist?: boolean) {
    if (this.isSuperAdmin === false) {
      this.subscriptionObj.add(this.authService.getAffiliateUserDetails().subscribe((res: any) => {
        if (res) {
          this.targetCount[0] = res.dashboardData && res.dashboardData.usersCount;
          this.targetCount[1] = res.dashboardData && res.dashboardData.paymentCount;
          this.targetCount[2] = parseFloat(res.dashboardData && res.dashboardData.revenue) + parseFloat(res.dashboardData && res.dashboardData.balance);
          this.targetCount[3] = parseFloat(res.dashboardData && res.dashboardData.balance);
          this.thresholdValue = parseFloat(res.dashboardData && res.dashboardData.thresholdValue);
          this.completedThresholdValue = this.thresholdValue - this.targetCount[3];
          for (let i = 0; i < this.count.length; i++) {
            this.animateValue(i, 0, this.targetCount[i], 5000);
          }
          this.display = this.thresholdValue > this.targetCount[3];
          this.maxPayoutValue = this.targetCount[3];
          this.toDisableAskForPaymentButton(userBankAccDetailsExist);
        } else {
          this.dialogService.dialogMethod(this.authConstants.errorMessage.dataRetrievalError, this.commonConstant.dialogType.failure, true);
        }
      }, (err) => {
        this.dialogService.dialogMethod(this.authConstants.errorMessage.generalError, this.commonConstant.dialogType.failure, true);
      }));
    }
  }

  /**
   * Animates a numeric value change for a specific index within the 'count' array.
   * @param {number} index - The index in the 'count' array to animate.
   * @param {number} start - The starting value of the animation.
   * @param {number} end - The target value to reach through the animation.
   * @param {number} duration - The duration of the animation in milliseconds.
   */
  animateValue(index, start, end, duration) {
    let startTimestamp = null;
    const step = (timestamp) => {
      if (!startTimestamp) startTimestamp = timestamp;
      const progress = Math.min((timestamp - startTimestamp) / duration, 1);
      this.count[index] = Math.floor(progress * (end - start) + start);
      if (progress < 1) {
        window.requestAnimationFrame(step);
      }
    };
    window.requestAnimationFrame(step);
  }

  /**
   * Checks and updates the state of the "Ask for Payment" button and related UI elements
   * based on payout details and user bank account existence.
   * @param {boolean} userBankAccDetailsExist - Indicates whether user bank account details exist.
   */
  toDisableAskForPaymentButton(userBankAccDetailsExist?: boolean): void {
    this.subscriptionObj.add(this.authService.getPayoutDetails(this.userId).subscribe((res: any) => {
      if (res) {
        const yetToBeginStatus = res.result.filter(item => item.status === 'Yet to Begin' || item.status === 'Payment Initiated')
        if (!userBankAccDetailsExist && userBankAccDetailsExist !== undefined && !this.display) {
          this.showInfoIcon = true;
          this.disableButton = true;
        }
        else if (yetToBeginStatus.length > 0) {
          this.disableButton = true;
          this.paymentOnProcess = true;
          this.showInfoIcon = false;
        } else {
          this.disableButton = false;
          this.showInfoIcon = false;
        }
        this.loader = false;
      } else {
        this.dialogService.dialogMethod(this.authConstants.errorMessage.dataRetrievalError, this.commonConstant.dialogType.failure, true);
      }
    },
      (err) => {
        this.dialogService.dialogMethod(this.authConstants.errorMessage.generalError, this.commonConstant.dialogType.failure, true);
      }));
  }

  /**
   * Opens the payout dialog box and initializes the payout form with validation rules.
   * @param {TemplateRef<any>} templateRef - Angular template reference for the dialog content.
   */
  openPayoutDialogBox(templateRef: TemplateRef<any>) {
    this.PayoutForm = new FormGroup(
      {
        amount: new FormControl(null, [
          Validators.required, Validators.max(this.maxPayoutValue),
          Validators.min(1),
          Validators.pattern('^-?[0-9]+(\.[0-9][0-9]?)?')
        ])
      }
    )
    this.payoutDialogBoxRef = this.dialog.open(templateRef, {
      width: '400px',
      disableClose: true,
      autoFocus: false
    });
  }

  /**
   * Handles the closing of the payout dialog box, considering the state of the PayoutForm.
   * If the amount field is not empty and greater than 0, prompts the user for confirmation before closing.
   * If the form is not dirty (no changes), closes the dialog without confirmation.
   */
  ExitPayoutDialogBox() {
    if (!this.PayoutForm.get('amount').value) {
      this.payoutDialogBoxRef.close();
    } else if (this.PayoutForm && this.PayoutForm.get('amount') && this.PayoutForm.get('amount').value > 0) {
      const dialogRef = this.dialogService.dialogMethod(this.authConstants.confirmMessages.paymentCancellation, this.commonConstant.dialogType.confirmation, true);
      this.subscriptionObj.add(
        dialogRef.afterClosed().subscribe((result: boolean) => {
          if (result) {
            this.payoutDialogBoxRef.close();
          }
        })
      );
    } else {
      this.payoutDialogBoxRef.close();
    }
  }

  /**
   * Opens a dialog box to confirm if the user wants to request a payment, 
   * and submits the payout form if the user confirms.
   */
  openRequestPaymentDialogBox() {
    if (this.PayoutForm && this.PayoutForm.get('amount') && this.PayoutForm.get('amount').value === null) {
      this.PayoutForm.get('amount').markAsTouched();
    }
    if ((this.PayoutForm && this.PayoutForm.get('amount') && this.PayoutForm.get('amount').value > 0) && this.PayoutForm && this.PayoutForm.get('amount') && this.PayoutForm.get('amount').value <= this.maxPayoutValue) {
      const dialogRef = this.dialogService.dialogMethod(this.authConstants && this.authConstants.confirmMessages && this.authConstants.confirmMessages.requestPayment, this.commonConstant.dialogType.confirmation, true);
      this.subscriptionObj.add(
        dialogRef.afterClosed().subscribe((result: boolean) => {
          if (result) {
            this.payoutDialogBoxRef.close();
            this.submitPayoutForm();
          }
        })
      );
    }
  }

  /**
   * Submits the payout form to request a payment.
   * Sends the payout request data to the server and handles the response.
   */
  submitPayoutForm() {
    const data = {
      "totalAmount": this.PayoutForm.value.amount,
      "userId": this.userId
    }
    if (this.PayoutForm.valid) {
      this.payoutLoader = true;
      this.subscriptionObj.add(this.authService.getPayoutLogDetails(data).subscribe((res: any) => {
        if (res) {
          this.payoutLoader = false;
          this.toDisableAskForPaymentButton();
          this.dialogService.dialogMethod(this.authConstants.successMessages.paymentSuccess, this.commonConstant.dialogType.success, true);
        } else {
          this.dialogService.dialogMethod(this.authConstants.errorMessage.dataRetrievalError, this.commonConstant.dialogType.failure, true);
        }
      }, (err) => {
        this.payoutLoader = false;
        this.dialogService.dialogMethod(this.authConstants.errorMessage.paymentError, this.commonConstant.dialogType.failure, true);
      }
      ));
      this.payoutDialogBoxRef.close();
    }
  }

  /**
   * Navigates to different routes based on the provided path parameter.
   * @param {string} path - The destination route path.
   */

  navigateTo(path: string) {
    if (path === 'referral-code') {
      this.router.navigate(['app/referral']);
    } else if (path === 'account-details') {
      this.router.navigate(['app/account-list']);
    } else if (path === 'payout-logs') {
      this.router.navigate(['app/payoutlogs']);
    }
  }

  /**
   * Lifecycle hook: Angular OnDestroy.
   * Unsubscribes from the subscriptionObj to avoid memory leaks when the component is destroyed.
   */
  ngOnDestroy() {
    if (this.subscriptionObj) {
      this.subscriptionObj.unsubscribe();
    }
  }
}