/**
 * Author : R.Chandru
 * Import statements.
 */
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

/**
 * Component which is used for display the redeem gift card container.
 */
@Component({
  selector: 'phase-ii-redeem-gift-card',
  templateUrl: './redeem-gift-card.component.html',
  styleUrls: ['./redeem-gift-card.component.scss']
})

/**
 * Class which is used for display the redeem gift card container.
 */
export class RedeemGiftCardComponent implements OnInit {

  /**
   * used to store all gift cards details.
   */
  giftCardObject: any;

  /**
   * used to store applied gift card.
   */
  selectedCard: any;

  /**
  * Variable used to access methods from instance environment.
  */
  environment: any;

  /**
   * used to store websiteSettings.
   */
  curreny: any;

  /**
   * Component constructor to inject the required services.
   * @param dialogRef To open/close the dialog box.
   */
  constructor(@Inject('environment') environment,
    @Inject(MAT_DIALOG_DATA) data,
    private dialogRef: MatDialogRef<RedeemGiftCardComponent>,
  ) {
    this.giftCardObject = data?.data;
    this.curreny = data?.websiteSettings?.currency;
    this.environment = environment;
  }

  ngOnInit(): void {
  }

  /**
  * method used to close dialog box.
  */
  onClose() {
    this.dialogRef.close();
  }

  /**
  * method used to save selected giftcard.
  * @param giftCard gives selected giftcard details.
  */
  selectGiftCard(giftCard: any) {
    this.selectedCard = giftCard.coupenCode;
  }

  /**
  * method used to send selected giftcard.
  */
  submit() {
    if (this.selectedCard) {
      this.dialogRef.close(this.selectedCard);
    }
  }

}