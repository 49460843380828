import { Directive, ElementRef, HostListener, forwardRef, EventEmitter, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

/**
 * Directive to enable two-way binding for contenteditable elements.
 */
@Directive({
  selector: '[contentEditableModel]',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ContentEditableDirective),
      multi: true,
    },
  ],
})
export class ContentEditableDirective implements ControlValueAccessor {
  /**
  * Callback function to be called when the value changes. 
  */
  private onChange: (value: any) => void = () => { };
  /** 
  * Callback function to be called when the control is touched. 
  */
  private onTouched: () => void = () => { };
  /** 
  * Event emitter for content change events and click events.
  */
  @Output() contentChanged = new EventEmitter<string>();
  @Output() contentClicked = new EventEmitter<string>();

  constructor(private el: ElementRef) { }
  /**
  * Listen for input events and trigger the corresponding callback functions.
  * @param value - The innerHTML value of the input event target.
  */
  @HostListener('input', ['$event.target.innerHTML'])
  onInput(value: any) {
    this.onChange(value);
    this.contentChanged.emit(value);
  }
  /**
  * Listen for blur events and trigger the registered onTouched function.
  */
  @HostListener('blur')
  onBlur() {
    this.onTouched();
  }
  /**
  * Listen for click events and emit the contentClicked event with the current inner HTML.
  */
  @HostListener('click')
  onClick() {
    this.contentClicked.emit(this.el.nativeElement.innerHTML);
  }
  /**
   * Write value to the DOM element.
   * @param value - The value to be written to the DOM element.
   */
  writeValue(value: any): void {
    this.el.nativeElement.innerHTML = value;
  }
  /**
  * Register a callback function to be called when the value changes.
  * @param fn - The callback function to be registered.
  */
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  /**
  * Register a callback function to be called when the control is touched.
  * @param fn - The callback function to be registered.
  */
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
  /**
  * Set the disabled state of the DOM element.
  * @param isDisabled - A boolean value indicating whether the element should be disabled.
  */
  setDisabledState(isDisabled: boolean): void {
    this.el.nativeElement.contentEditable = !isDisabled;
  }
}
