import { Component, OnInit, Input, Output, EventEmitter, ViewChild, TemplateRef, Injector } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { FilterObject } from '../../models/filter-details';
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { cloneDeep } from 'lodash';
import { CommonConstants } from '../../constants/shared-constant';
import { MatDialog } from '@angular/material/dialog';
import { dataConfig, DialogService } from '@phase-ii/common';
import { Subscription } from 'rxjs';
import { FilterService } from '../../services/filter.service';
import { filter, mergeMap } from 'rxjs/operators';
import { CustomAsyncValidatorService, CommonService } from '@phase-ii/shared';
import { DatePipe } from '@angular/common';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MomentDateAdapter } from '@angular/material-moment-adapter';

export const MY_FORMATS = {
  parse: {
    dateInput: 'LL',
  },
  display: {
    dateInput: 'DD-MM-YYYY',
    monthYearLabel: 'YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'YYYY',
  },
};

/** 
 * AUTHOR: ESSAKKIAMMAL, VIGNESHWARAN , SANTHOSH and JOHN
 * Common Filter Component to display filtered values 
 */
@Component({
  selector: 'phase-ii-filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.scss'],
  providers: [
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
})

export class FilterComponent extends CommonConstants implements OnInit {

  /**
   * variable that is used to check the screen width changes (mobile view or web view).
   */
  mobileQuery: MediaQueryList;
  /**
   * 
   */
  @ViewChild(CdkVirtualScrollViewport) virtualScroll: CdkVirtualScrollViewport;
  /**
   * To get filter details
   */
  @Input() filters: FilterObject[];
  /**
   * To emit filter changes
   */
  @Output() filterPredicate = new EventEmitter();
  /**
   * To emit filter changes
   */
  @Output() filterClosed = new EventEmitter();
  /**
   * added  child of virtual scroll
   */
  @ViewChild(CdkVirtualScrollViewport) cdkVirtualScrollViewport: CdkVirtualScrollViewport;
  /**
   * Variable which is used to define the FormObject.
   */
  emitValue!: any;
  // /**
  //  * Variable to store the count of the filters applied
  //  * @type {number}
  //  */
  // count: number;
  /**
   * To store the filter form controls
   * @type {FormGroup}
   */
  filterForm: UntypedFormGroup = new UntypedFormGroup({});
  /**
   * Variable to save the applied filters
   * @type {number}
   */
  selectedFilters: number[] = [];
  /**
   * Variable to save the expanded filter's index
   * @type {number}
   */
  indexExpanded: number;
  /**
   * Variable to store the selected days filter
   * @type {any}
   */
  selectedValue: any;
  /**
   * Variable to store the selected {{filtername}} filter
   * @type {any}
   */
  selectedDateValue: any;
  /**
   * Variable to store the selected value of multiple selector filter
   * @type {any}
   */
  multipleselectorvalue: any;
  /**
   * Variable to store the today's date
   * @type {Date}
   */
  today: Date = new Date();
  /**
   * Variable to check whether checkbox is selected or not
   * @type {boolean}
   */
  isCheckBox: boolean = false;
  /**
   * variable that is used to show the saveFilter section
   */
  @ViewChild('saveDialog', { static: false }) saveDialog: TemplateRef<any>;
  /**
   * variable that is used to show the saveFilterName section
   */
  @ViewChild('SavedFiltersDialog', { static: false }) SavedFiltersDialog: TemplateRef<any>;
  /**
   * variable that is used to subscribe and unsubscribe.
   */
  subscriptionObj: Subscription = new Subscription();
  /**
   * In order to save the saveFilter form controls 
   * @type {FormGroup}
   */
  saveFilterForm: UntypedFormGroup;
  /**
   * Variable to display or hide the SaveFilter
   * @type {boolean}
   */
  @Input() isSavedFilter: boolean;
  /**
   * Variable to display or hide the Save Loader
   * @type {boolean}
   */
  isSaveLoader!: boolean;
  /**
   * To get the storeId variable 
   */
  @Input() storeId: number;
  /**
   * To get the filterCode variable
   */
  @Input() Code: string;
  /**
   * variable used to get all saved filters
   */
  getAllSavedFilters: any = [];
  /**
   * variable for storing savedFiltersNames 
   */
  savedFiltersName: any = [];
  /**
   * Saved Filter Unavailable Filter Message
   */
  savedFilterUnavailableMessage = 'No Record Found';
  /**
   * variable use of the "Show and Hide Save Filter Button"
   */
  isSaveButton: boolean = false;
  /**
   * variable use of the "Show and Hide Update Filter Button"
   */
  isUpdateButton: boolean = false;
  /**
   * variable use of "SavedFilter Applied" or "NOT"
   */
  isAppliedSavedFilter: boolean = false;
  /**
   * Variable for storing saved FilterIndex 
   */
  savedFilterIndex: number;
  /**
   * Variable for storing saved FilterField 
   */
  savedFilterField!: string;
  /**
   * variable use for "Show Delete icon button"
   */
  deleteFilterName!: string;
  /**
   * Variable use tO Check Check the box to confirm 
   */
  isVerifyCheckBox: boolean = false;
  /**
   * variable used to store selector type field value
   */
  selectorOptionValue?: any;
  /**
   * variable used to store whether or not a selector type field search is present 
   */
  isSelectorOptions!: boolean;
  /**
   * variable used to store selector type field filter options
   */
  selectorConstant?: any;
  /**
   * variable that is used to search selector control
   */
  selectorSearchFilter = new UntypedFormControl(null);
  /**
   * This variable is used to access the CommonService. 
   * @type {CommonService}
   */
  commonService: CommonService
  /**
   * Variable for displaying and hiding the input field 
   */
  isDisabled: boolean = false;
  /**
   * Variable use To apply or not apply a filter
   */
  isApplied: boolean = false;
  /**
   * Variable use To store the applied filter length
   */
  appliedFilterLength: number = 0;
  /**
   * Variable use To store the applied filter length
   */
  appliedFilters: number[] = [];
  /**
   * Variable use to store ApliedFilter Names
   */
  AppliedFilterName: any[] = [];
  /**
   * Variable use to store ApliedFilter Name List
   */
  AppliedFilterList!: any;
  /**
   * Component constructor for injecting needed services.
   * @param dialog is to access dialog
   * @param dialogService is to access dialogService
   * @param filterService is to access filterService
   */
  constructor(
    private dialog: MatDialog,
    private dialogService: DialogService,
    private filterService: FilterService,
    private injector: Injector,
    public datepipe: DatePipe
  ) {
    super();
    this.commonService = injector.get<CommonService>(CommonService);
  }
  /**
   * Component onInit lifecycle hook
   * @type {void}
   */

  ngOnInit(): void {
    const formDataObj: any = {};
    if (this.filters) {
      for (let i = 0; i < this.filters.length; i++) {
        //To add check box
        if (this.filters[i].type === 'checkbox') {
          for (const option of this.filters[i].options) {
            if (this.filters[i].valueName) {
              formDataObj[option[this.filters[i].valueName]] = new UntypedFormControl(null);
            }
            else {
              formDataObj[option] = new UntypedFormControl(null);
            }
          }
          this.filterForm.addControl(this.filters[i].field,
            new UntypedFormGroup(formDataObj));
        } else if (this.filters[i].type === 'numeric') {
          this.filterForm.addControl(this.filters[i].field,
            new UntypedFormGroup({
              from: new UntypedFormControl(null, [Validators.min(this.filters[i].minValue ? this.filters[i].minValue : 0), Validators.max(this.filters[i].maxValue ? this.filters[i].maxValue - 1 : 99999)]),
              to: new UntypedFormControl(this.filterForm?.controls['from']?.value ? this.filterForm.controls['from'].value + 1 : null, [Validators.min(this.filters[i].minValue ? this.filters[i].minValue + 1 : 1), Validators.max(this.filters[i].maxValue ? this.filters[i].maxValue : 100000)])
            }));
        } else if (this.filters[i].type === 'dateRange' || this.filters[i].type === 'customDate' || this.filters[i].type === 'customDatePicker') {
          this.filters[i].dateValue = null;
          this.filterForm.addControl(this.filters[i].field,
            new UntypedFormGroup({ begin: new UntypedFormControl(null), end: new UntypedFormControl(null) }));
        } else if (this.filters[i].type === 'text') {
          this.filterForm.addControl(this.filters[i].field,
            new UntypedFormControl(null, [Validators.pattern(dataConfig.patternValidators.spaceValidationPattern)]));
        }
        else {
          this.filterForm.addControl(this.filters[i].field, new UntypedFormControl(this.filters[i].defaultValue ? this.filters[i].defaultValue : null));
        }
        // filterName: new UntypedFormControl(null, [Validators.required, Validators.pattern(dataConfig.patternValidators.spaceValidationPattern), Validators.maxLength(30)]),
      }
    }
    this.formInitial();
    this.getSavedFilters();
  }

  /**
   * The method is to apply the date clicked filter.
   * @param filterIndex
   * @param type
   */
  setDateValue(filterIndex: any, type: string) {
    if (type == 'customDatePicker' && this.filterForm && this.filterForm.value && this.filters[filterIndex] && this.filters[filterIndex].field && this.filterForm.value[this.filters[filterIndex].field] && this.filterForm.value[this.filters[filterIndex].field].begin && this.filterForm.value[this.filters[filterIndex].field].end) {
      let begin_date = this.datepipe && this.datepipe.transform(this.filterForm.value[this.filters[filterIndex].field].begin, 'dd-MM-yyyy').split('-');
      let end_date = this.datepipe && this.datepipe.transform(this.filterForm.value[this.filters[filterIndex].field].end, 'dd-MM-yyyy').split('-');
      (+begin_date[2] && +end_date[2]) == new Date().getFullYear() ?
        (+begin_date[1] - +end_date[1]) == 0 && +begin_date[1] == new Date().getMonth() + 1 && +end_date[1] == new Date().getMonth() + 1 ?
          (+begin_date[0] == new Date().getDate()) && (+end_date[0] == new Date().getDate()) ? this.selectedDateValue = 'Today'
            : (+begin_date[0] == (new Date().getDate() - 1)) && (+end_date[0] == (new Date().getDate() - 1)) ? this.selectedDateValue = 'Yesterday'
              : (+end_date[0] == new Date().getDate()) && (+begin_date[0] == (new Date().getDate() - new Date().getDay())) ? this.selectedDateValue = 'This Week'
                : +begin_date[0] == 1 && +end_date[0] == new Date().getDate() ? this.selectedDateValue = 'This Month'
                  : this.selectedDateValue = null
          : +begin_date[0] == 1 && +end_date[0] == Number(new Date(new Date().getFullYear(), new Date().getMonth(), 0).getDate()) && +begin_date[1] == new Date().getMonth() && +end_date[1] == new Date().getMonth() ? this.selectedDateValue = 'Last Month'
            : +begin_date[0] == 1 && +end_date[0] == new Date().getDate() && +begin_date[1] == 1 && +end_date[1] == (new Date().getMonth() + 1) ? this.selectedDateValue = 'This Year' : this.selectedDateValue = null
        : +begin_date[0] == 1 && +end_date[0] == 31 && (+begin_date[1] - +end_date[1]) == -11 && (+begin_date[2] == new Date().getFullYear() - 1) && (+end_date[2] == new Date().getFullYear() - 1) ? this.selectedDateValue = 'Last Year' : this.selectedDateValue = null;
    }
  }
  /**
   * The method is to apply the date clicked filter.
   * @param day
   */
  daysClick(day: any, type?: any, field?: any): void {
    if (type == 'customDatePicker') {
      let dateGroup = [{ begin: new Date(), end: new Date() }];
      if (day == 'Today') {
        dateGroup[0].begin.setHours(0, 0, 0, 0);
        dateGroup[0].end.setHours(23, 59, 59, 999);
      } else if (day == 'Yesterday') {
        new Date(dateGroup[0].begin.setDate(dateGroup[0].begin.getDate() - 1)).setHours(0, 0, 0, 0);
        new Date(dateGroup[0].end.setDate(dateGroup[0].end.getDate() - 1)).setHours(23, 59, 59, 999);
      } else if (day == 'This Week') {
        new Date(dateGroup[0].begin.setDate(dateGroup[0].begin.getDate() - dateGroup[0].begin.getDay())).setHours(0, 0, 0, 0);
        dateGroup[0].end.setHours(23, 59, 59, 999);
      } else if (day == 'This Month') {
        new Date(dateGroup[0].begin.setDate(dateGroup[0].begin.getDate() - (dateGroup[0].begin.getDate() - 1))).setHours(0, 0, 0, 0);
        dateGroup[0].end.setHours(23, 59, 59, 999);
      } else if (day == 'Last Month') {
        new Date(dateGroup[0].end = new Date(new Date().getFullYear(), new Date().getMonth(), 0)).setHours(0, 0, 0, 0);
        new Date(dateGroup[0].begin = new Date(dateGroup[0].end.getFullYear(), dateGroup[0].end.getMonth(), 1)).setHours(23, 59, 59, 999);
      } else if (day == 'This Year') {
        new Date(dateGroup[0].begin = new Date(new Date().getFullYear(), 0, 1)).setHours(0, 0, 0, 0);
        dateGroup[0].end.setHours(23, 59, 59, 999);
      } else if (day == 'Last Year') {
        new Date(dateGroup[0].begin = new Date(new Date().getFullYear() - 1, 0, 1)).setHours(0, 0, 0, 0);
        new Date(dateGroup[0].end = new Date(new Date().getFullYear() - 1, 11, 31)).setHours(23, 59, 59, 999);
      }
      this.selectedDateValue = day;
      this.filterForm.get(field)?.setValue(dateGroup[0]);
    }
    else {
      this.selectedValue = day;
      this.filterForm.patchValue({ days: day });
    }
  }

  /**
   * Method to check the from and to values when a range filter is clicked
   * @param event - The event object triggered by the filter click
   * @param controlName - The name of the control for the range filter
   * @param index - The index of the filter
   */
  checkValue(event: any, controlName?: any, index?: any): void {
    const toFormControl = this.filterForm && this.filterForm.controls && this.filterForm.controls[controlName] && this.filterForm.controls[controlName].get('to');
    const fromFormControl = this.filterForm && this.filterForm.controls && this.filterForm.controls[controlName] && this.filterForm.controls[controlName].get('from');
    if ((toFormControl && toFormControl.value) && ((fromFormControl && fromFormControl.value) || (fromFormControl && fromFormControl.value === 0))) {
      if (toFormControl.value <= fromFormControl.value) {
        toFormControl.setErrors({ greaterValue: true });
      } else {
        if (!toFormControl.hasError('max')) {
          toFormControl.setErrors(null);
          if (toFormControl.value)
            this.onNumericChange(index, event?.target?.value?.length, (toFormControl && toFormControl.value))
        }
      }
    }
    else {
      this.onNumericChange(index, event?.target?.value?.length, (toFormControl && toFormControl.value));
    }
  }

  /**
   * To set the date filter
   * @param filter filter object
   * @param filterIndex to collect filter index
   */
  dateFilter(filter: any, filterIndex: number): void {
    if (this.filters && this.filters[filterIndex] && this.filters[filterIndex].field) {
      this.filters[filterIndex].dateVisible = (Number(filter.value) === 4) ? true : false;
      if (this.filterForm.controls && this.filterForm.controls[this.filters[filterIndex].field]) {
        switch (Number(filter.value)) {
          case 1:
            this.filterForm.controls[this.filters[filterIndex].field].setValue({
              begin: new Date(Date.now() - 30 * 24 * 60 * 60 * 1000),
              end: new Date()
            });
            break;
          case 2:
            this.filterForm.controls[this.filters[filterIndex].field].setValue({
              begin: new Date(Date.now() - 7 * 24 * 60 * 60 * 1000),
              end: new Date()
            });
            break;
          case 3:
            this.filterForm.controls[this.filters[filterIndex].field].setValue({
              begin: new Date(Date.now() - 1 * 24 * 60 * 60 * 1000), end: new Date()
            });
            break;
        }
      }
    }
  }

  /**
   * Method to clear a single filter
   * @param index its selected filter index
   * @param field its selected filter field
   */
  clearOneFilter(index: number, field: string, isSaved?: boolean, isEmit?: boolean): void {
    this.emitValue = null;
    this.onSelectorSearchFilterClose();
    this.deleteFilterName = ((!(this.deleteFilterName == "") && !(field == 'savedFilterName')) ? this.deleteFilterName : "");
    this.isDisabled = false;
    const filterIndex = this.selectedFilters.findIndex((element) => { return (element === index) });
    if (filterIndex >= 0) {
      this.selectedFilters.splice(filterIndex, 1);
    }
    const AppliedFilterIndex = this.appliedFilters.findIndex((element) => { return (element === index) });
    if (AppliedFilterIndex >= 0) {
      this.appliedFilters.splice(filterIndex, 1);
    }
    if (this.filters && this.filters[index] && this.filters[index].error) {
      this.filters[index].error = false;
    }
    if (this.filterForm && this.filterForm.get(field))
      this.filterForm.get(field).reset();
    if (this.filters && this.filters[index] && this.filters[index].type == 'customDatePicker')
      this.selectedDateValue = null;
    if (this.filters && this.filters[index] && this.filters[index].type == 'toggleGroup')
      this.selectedValue = null;
    this.isCheckBox = false;
    this.emitValue = cloneDeep(this.filterForm.value);
    // if (!isSaved) {
    // this.appliedFilters && this.appliedFilters.forEach(value => {
    //   if (value && this.filters && this.filters[value] && this.filters[value].type == 'checkbox') {
    //     this.isCheckBox = true;
    //     this.checkBoxData(this.filters[value]);
    //     this.isVerifyCheckBox = true;
    //   }
    // });
    // }
    if (!isSaved) {
      this.appliedFilters && this.appliedFilters.forEach(value => {
        if (this.filters && this.filters[value] && this.filters[value].type == 'checkbox') {
          this.isCheckBox = true;
          this.checkBoxData(this.filters[value]);
          // this.isVerifyCheckBox = true;
        }
      });
    }
    if (!isEmit) {
      if (this.appliedFilters && !this.appliedFilters.length) {
        this.filterPredicate.emit(null);
      } else {
        this.filterPredicate.emit(this.emitValue);
      }
      // this.appliedFilters = cloneDeep(this.selectedFilters);
      this.getAppliedFilterName(this.appliedFilters);
    }
  }

  /**
   * Method to toggle one panel while another is opened
   * @param index - The index of the panel to toggle
   * @param indexExpanded - The index of the currently expanded panel
   */
  togglePanels(index: number, indexExpanded: number): void {
    // Check if the index of the panel to toggle is different from the currently expanded panel index
    if (index !== indexExpanded) {
      // Set the index of the panel to toggle as the new expanded index
      this.indexExpanded = index;
    }
  }

  /**
   * Method to save the filter and calculate the count
   * @param index - The index of the filter
   * @param event - The event object triggered by the filter change
   * @param field - The field associated with the filter
   */
  onFilterChange(index: number, event?: any, field?: any, value?: any): void {
    this.isApplied = false;
    if (this.isSaveButton) {
      this.isSaveButton = false;
    }
    if (event) {
      if (this.filters && this.filters.length && this.filters[index] && this.filters[index].type == 'text') {
        let textValue: string = ((event.length != 0 || event == "") ? (event.trim()) : (event));
        this.filterForm && this.filterForm.controls[field] && this.filterForm.controls[field].setValue(textValue);
      }
      if (((this.filters && this.filters.length && this.filters[index] && this.filters[index].type == 'dateRange') && event.value) || (this.filters && this.filters.length && this.filters[index] && this.filters[index].type != 'dateRange')) {
        this.selectedFilters.push(index);
      }
      if ((this.filters && this.filters.length && this.filters[index] && this.filters[index].type == 'customDatePicker')) {
        let dayupdate
        this.filters[index].options.forEach(element => { if (element == event) { dayupdate = true } });
        if (event.value || dayupdate) {
          this.selectedFilters.push(index);
        }
      }
    } else {
      const filterIndex = this.selectedFilters.findIndex((element: any) => { return index == element });
      this.selectedFilters.splice(filterIndex, 1);
    }
    if (field && field === "savedFilterName") {
      this.savedFilterField = field;
      this.savedFilterIndex = index;
      this.deleteFilterName = event.value;
    }
    if (this.selectedFilters.length > 1) {
      this.removeDuplicates();
    }
    if (field && field === "multipleSelector") {
      if (this.filterForm && this.filterForm.controls && this.filterForm.controls[field] && this.filterForm.controls[field].value && this.filterForm.controls[field].value.length == 0) {
        let removeindex = this.selectedFilters.indexOf(index);
        if (removeindex !== -1) {
          this.selectedFilters.splice(removeindex, 1);
        }
      }
      this.filters[index] && this.filters[index].options.forEach(element => { if (element[this.filters[index].valueName] == value.value) { this.multipleselectorvalue = element[this.filters[index].displayName] } });
    }
  }

  /**
   * Filter applied when range filter is clicked
   * @param index - The index of the filter
   * @param event - The event object triggered by the filter change
   * @param value - The value associated with the filter
   */
  onNumericChange(index: number, event: any, value: any): void {
    this.isApplied = false;
    if (this.isSaveButton) {
      this.isSaveButton = false;
    }
    let rangeName: any = this.filters && this.filters.length && this.filters[index] && this.filters[index].field;
    const toFormControl = this.filterForm && this.filterForm.value && this.filterForm.value[rangeName] && this.filterForm.value[rangeName].to;
    const fromFormControl = this.filterForm && this.filterForm.value && this.filterForm.value[rangeName] && this.filterForm.value[rangeName].from;
    if ((event || !value) && (toFormControl) && (fromFormControl || fromFormControl === 0)) {
      this.selectedFilters.push(index);
    }
    // else {
    //   const filterIndex = this.selectedFilters.findIndex((element: any) => { return index == element });
    //   this.selectedFilters.splice(filterIndex, 1);
    //   const AppliedFilterIndex = this.appliedFilters.findIndex((element) => { return (element === index) });
    //   this.appliedFilters.splice(filterIndex, 1);
    // }

    if (this.selectedFilters.length > 1)
      this.removeDuplicates();
  }

  /**
   * Filter applied when checkbox is clicked
   * @param index - The index of the filter
   * @param value - The value associated with the filter
   */
  onCheckBoxChange(index: number, value: any): void {
    if (!this.isCheckBox) {
      this.isCheckBox = true;
      this.selectedFilters.push(index);
    }
    else {
      let filterFalse: boolean = false;
      if (this.filterForm && this.filterForm.value && this.filterForm.value[value]) {
        Object.keys(this.filterForm.value[value]).forEach((element: any) => {
          if (this.filterForm.value[value][element]) {
            filterFalse = true;
          }
        });
        if (!filterFalse) {
          this.isCheckBox = false;
          const filterIndex = this.selectedFilters.findIndex((element: any) => { return index == element });
          this.selectedFilters.splice(filterIndex, 1);
        }
      }
      if (this.selectedFilters && this.selectedFilters.length == 0 && this.appliedFilters && this.appliedFilters.length == 0)
        this.filterPredicate.emit(this.filterForm && this.filterForm.value);
    }
  }

  /**
   * Method to remove the duplicate filters applied
   */
  removeDuplicates(): void {
    const newFilter = [...new Set(this.selectedFilters)];
    this.selectedFilters = newFilter;
  }

  /**
   * Method to find the applied filter index
   * @param index 
   * @returns index of the applied filter
   */
  findAppliedFilter(index: number): number {
    const filterIndex = this.appliedFilters.findIndex((element: any) => { return index == element });
    return filterIndex;
  }

  /**
   * Method to find the selected filter index
   * @param index 
   * @returns index of the selected filter
   */
  findselectedFilter(index: number): number {
    const filterIndex = this.selectedFilters.findIndex((element: any) => { return index == element });
    return filterIndex;
  }

  /**
   * Method to find the Error filter index
   * @param index 
   * @returns index of the Error filter
   */
  finderrorFilter(filter: any): any {
    if (filter) {
      return this.filterForm.get(filter && filter.field)?.hasError('pattern') || this.filterForm && this.filterForm.controls[filter.field]?.get('from')?.hasError('min') || this.filterForm.controls[filter.field]?.get('from')?.hasError('max') ||
        this.filterForm.controls[filter.field]?.get('to')?.hasError('min') || this.filterForm.controls[filter.field]?.get('to')?.hasError('max') || this.filterForm.controls[filter.field]?.get('to')?.hasError('greaterValue')
    }
  }

  /**
   * Method which is used to display slider changes.
   * @param value refers the dragged value of the slider.
   * @return value
   */
  formatLabel(value: number): number {
    if (value >= 101) {
      return Math.round(value / 100);
    }
    return value;
  }

  /**
   * To emit filter values
   */
  onFilter(): void {
    let count = 0;
    this.emitValue = null;
    let findSavedFilter: any = null;
    this.emitValue = cloneDeep(this.filterForm.value);
    this.isApplied = true;
    this.isSaveButton = this.selectedFilters && this.selectedFilters.length >= 1 ? true : false;
    this.isAppliedSavedFilter = this.emitValue && this.emitValue.savedFilterName ? true : false;
    if (this.isAppliedSavedFilter && this.emitValue && this.emitValue.savedFilterName && this.selectedFilters && this.selectedFilters.length >= 1) {
      // this.isAppliedSavedFilter = true;
      this.isSaveButton = false;
      this.isDisabled = true;
      cloneDeep(this.selectedFilters).forEach((item: any) => {
        if ((item && this.filters[item] && this.filters[item].field !== "savedFilterName")) {
          let isCheckboxField: boolean = this.filters[item] && this.filters[item].type == 'checkbox' ? true : false;
          this.clearOneFilter(item, this.filters[item] && this.filters[item].field, isCheckboxField, true);
          this.isDisabled = true;
        }
      });
      //Find And Display the Saved Filter filterPredicate Value
      findSavedFilter = this.getAllSavedFilters && this.getAllSavedFilters.filter((element: any) => {
        return element.filterName === this.emitValue.savedFilterName;
      });
      this.emitValue = findSavedFilter && findSavedFilter[0] && findSavedFilter[0].filterPredicate;
    }
    this.appliedFilters = cloneDeep(this.selectedFilters);
    setTimeout(() => {
      for (const filter of this.filters) {
        if (!this.isVerifyCheckBox && filter.type === 'checkbox') {
          this.checkBoxData(filter);
        }
        if (filter.type === 'numeric') {
          const { field } = filter;
          const fromFormControlValue = this.filterForm.controls[field] && this.filterForm.controls[field].value && this.filterForm.controls[field].value.from;
          const toFormControlValue = this.filterForm.controls[field] && this.filterForm.controls[field].value && this.filterForm.controls[field].value.to;
          // if (!toFormControlValue && !(fromFormControlValue || fromFormControlValue === 0)) {
          // count = count + 1;
          const appliedFiltersCopy = [...this.appliedFilters];
          appliedFiltersCopy.forEach((item) => {
            if (this.filters[item] && this.filters[item].field === field) {
              if (toFormControlValue && !(fromFormControlValue || (fromFormControlValue === 0))) {
                count = count + 1;
                this.filterForm.controls[field].patchValue({ from: filter.minValue || 0 });
                this.onFilter();
              }
              if (!toFormControlValue && (fromFormControlValue || fromFormControlValue === 0)) {
                count = count + 1;
                this.filterForm.controls[field].patchValue({ to: filter.maxValue || 100000 });
                this.onFilter();
              }
              if (!toFormControlValue && !(fromFormControlValue || fromFormControlValue === 0)) {
                count = count + 1;
                this.clearOneFilter(item, field);
              }
            }
          });
          // }
        }
        else if (filter.type === 'dateRange' || filter.type === 'customDatePicker') {
          if ((this.filterForm.controls[filter.field] && this.filterForm.controls[filter.field].value) && this.filterForm.controls[filter.field].value.begin) {
            filter.error = true;
            count = count + 1;
            if ((this.filterForm.controls[filter.field] && this.filterForm.controls[filter.field].value) && this.filterForm.controls[filter.field].value.end) {
              filter.error = false;
              count = count - 1;
            }
          }
        }
      }
      if (count === 0) {
        this.getAppliedFilterName(this.appliedFilters);
        this.filterPredicate.emit(this.emitValue);
        this.isVerifyCheckBox = false;
      }
    }, 100);
  }

  /**
   * Method to get Applied Saved Filter Names
   * @param appliedFilters - its Applied Filter Index
   */
  getAppliedFilterName(appliedFilters: number[]) {
    if (this.storeId && this.Code) {
      this.AppliedFilterName = [];
      let filter: any = this.filters;
      cloneDeep(appliedFilters).forEach((item: any) => {
        let field = filter[item] && filter[item].field;
        let displayName = filter[item] && filter[item].displayName;
        let valueName = filter[item] && filter[item].valueName;
        let optionsValue = filter[item] && filter[item].options;
        let Title = filter[item] && filter[item].title;
        let Type = filter[item] && filter[item].type;
        if ((displayName || optionsValue) && Type != "customDatePicker") {
          optionsValue && optionsValue.forEach((value: any, i: number) => {
            if (this.emitValue && Array.isArray(this.emitValue[field]) && this.emitValue[field].length > 0) {
              this.emitValue && this.emitValue[field].forEach((emitField: any, index: any) => {
                if (emitField === value[valueName] || emitField === value) {
                  this.emitValue[field] === value ? (this.AppliedFilterName.push({ "Title": Title, "Value": value })) : (this.AppliedFilterName.push({ "Title": Title, "Value": value[displayName] }));
                }
              });
            } else {
              if (this.emitValue && this.emitValue[field] === value[valueName] || this.emitValue && this.emitValue[field] === value || this.emitValue && this.emitValue[field] && this.emitValue[field][i] === value[valueName]) {
                this.emitValue[field] === value ? (this.AppliedFilterName.push({ "Title": Title, "Value": value })) : (this.AppliedFilterName.push({ "Title": Title, "Value": value[displayName] }));
              }
            }
          });
        } else {
          switch (Type) {
            case 'text':
              this.AppliedFilterName.push({ "Title": Title, "Value": this.emitValue[field] });
              break;
            case 'numeric':
              let value = this.emitValue[field].from + " " + "to" + " " + this.emitValue[field].to;
              this.AppliedFilterName.push({ "Title": Title, "Value": value });
              break;
            case 'dateRange':
            case 'customDatePicker':
              let begin_date = this.datepipe.transform(this.emitValue[field].begin, 'dd-MM-yyyy');
              let end_date = this.datepipe.transform(this.emitValue[field].end, 'dd-MM-yyyy');
              let date = begin_date + " " + "to" + " " + end_date;
              this.AppliedFilterName.push({ "Title": Title, "Value": date });
              break;
            default:
              this.AppliedFilterName.push({ "Title": Title, "Value": Title })
              break;
          }
        }
      });
    }
  }

  /**
   * Filter applied when check box is clicked
   * @param filter 
   */
  checkBoxData(filter: any): void {
    const data: any = [];
    if (this.emitValue && this.emitValue[filter.field]) {
      for (const key in this.emitValue[filter.field]) {
        if (this.emitValue[filter.field][key]) {
          if (typeof (filter.options && filter.options[0] && filter.options[0][filter.valueName]) === 'number') {
            data.push(parseInt(key));
          }
          else {
            data.push(key);
          }
        }
      }
      this.emitValue[filter.field] = data;
    }
  }

  /**
   * To reset filter form
   */
  reset(): void {
    this.appliedFilters = [];
    this.isDisabled = false;
    this.deleteFilterName = '';
    this.onSelectorSearchFilterClose();
    this.filterForm.reset();
    this.selectedFilters = [];
    this.selectedValue = null;
    this.selectedDateValue = null;
    this.isCheckBox = false;
    for (const filter of this.filters) {
      if (filter.dateValue) {
        filter.dateValue = null;
        filter.dateVisible = false;
      }
      if (filter.error) {
        filter.error = false;
      }
    }
    this.filterPredicate.emit(null);
  }

  /**
   * Method to close the filter
   */
  closeFilter(): void {
    this.filterClosed.emit();
  }

  /**
   * Method to clear the date error when proper date is given
   * @param index 
   */
  clearDateError(index: any): void {
    if (this.filters[index] && this.filters[index].error) {
      this.filters[index].error = false;
    }
  }

  // Below Functions Used For Saved Filters
  /**
   * Method to form initialization
   */
  // formInitial(): void {
  //   if (this.storeId && this.Code) {
  //     this.saveFilterForm = new UntypedFormGroup({
  //       filterName: new UntypedFormControl(null, {
  //         validators: [Validators.required, Validators.pattern(dataConfig.patternValidators.spaceValidationPattern), Validators.maxLength(30)],
  //         asyncValidators: CustomAsyncValidatorService.asyncCheckDuplicateValidation(
  //           this.commonService, 'savedFilters', 'filterName',
  //           this.storeId,
  //           null
  //         )
  //       }),
  //     });
  //   }
  // }
  formInitial(): void {
    // Check if 'storeId' and 'Code' values exist
    if (this.storeId && this.Code) {
      // Initialize the saveFilterForm with UntypedFormGroup and UntypedFormControl
      const { required, pattern, maxLength } = Validators;
      const { spaceValidationPattern } = dataConfig.patternValidators;
      let validators = [required, pattern(spaceValidationPattern), maxLength(30)];
      const asyncValidators = CustomAsyncValidatorService.asyncCheckDuplicateValidation(
        this.commonService, 'savedFilters', 'filterName', this.storeId, null
      );

      this.saveFilterForm = new UntypedFormGroup({
        filterName: new UntypedFormControl(null, { validators, asyncValidators }),
      });
    }
  }

  /**
   * Method to Open the Saved Filter Dialog Box
   */
  onSave(): void {
    this.dialog.open(this.saveDialog, { disableClose: true, autoFocus: false, width: '500px' });
  }

  /**
   * Retrieves the saved filters for the specified store and code.
   * Updates the 'getAllSavedFilters' and 'savedFiltersName' arrays with the retrieved data.
   */
  getSavedFilters(): void {
    if (this.storeId && this.Code) {
      this.savedFiltersName = [];
      this.getAllSavedFilters = [];

      this.subscriptionObj.add(this.filterService.getSavedFilterByCode(this.storeId, this.Code).subscribe(
        (res: any) => {
          if (res && res.savedFilters && res.savedFilters.length) {
            this.getAllSavedFilters = res.savedFilters;
            // Iterate through each saved filter and add filterName to savedFiltersName array if it exists
            res.savedFilters.forEach((element: any) => {
              if (element.filterName && element.savedFilterNames) {
                this.savedFiltersName && this.savedFiltersName.push(element.filterName);
              }
            });
          }
        },
        (err: any) => {
          // Handle error and display appropriate dialog message
          this.dialogService.dialogMethod(this.dialogMessages.getSavedFilterField, this.dialogType.failure, true);
        }
      ));
    }
  }

  /**
   * Method to close the dialog box and reset the form in the dialog box
   */
  dialogClose(): void {
    if (this.saveFilterForm && this.saveFilterForm.dirty) {
      const dialogRef = this.dialogService.dialogMethod(this.errorMessage.unsavedChanges, this.dialogType.confirmation, true);
      this.subscriptionObj.add(dialogRef.afterClosed().subscribe((result: any) => {
        if (result) {
          this.dialog.closeAll();
          this.saveFilterForm.reset();
        }
      }));
    } else {
      this.dialog.closeAll();
      this.saveFilterForm.reset();
    }
  }

  /**
   * Save the filter using an API call and display appropriate dialogs based on the response
   */
  saveFilter(): void {
    if (this.saveFilterForm.value && this.saveFilterForm.value.filterName && this.saveFilterForm.valid) {
      this.emitValue = cloneDeep(this.filterForm.value);
      this.emitValue['savedFilterName'] = this.saveFilterForm.value.filterName;
      let data: any = {
        "filterName": this.saveFilterForm.value.filterName,
        "code": this.Code,
        "filterPredicate": this.emitValue,
        "savedFilterNames": this.AppliedFilterName,
      }
      this.isSaveLoader = true;
      this.subscriptionObj.add(this.filterService.createSavedFilterByCode(this.storeId, data).subscribe((res: any) => {
        if (res) {
          const dialogRef = this.dialogService.dialogMethod(this.dialogMessages.saveFilterSuccess, this.dialogType.success, true);
          // this.subscriptionObj.add(dialogRef.afterClosed().subscribe((result: any) => {
          //   if (result) {
          this.dialog.closeAll();
          this.saveFilterForm.reset();
          this.isSaveLoader = false;
          //   }
          // }));
          this.getSavedFilters();
        }
        this.isSaveLoader = false;
      }, (err: any) => {
        this.dialogService.dialogMethod(this.dialogMessages.saveFilterFailed, this.dialogType.failure, true);
        this.isSaveLoader = false;
      }));
    }
  }

  // onUpdate(): void {
  //   let data = {
  //     "filterName": this.saveFilterForm.value.filterName,
  //     "code": this.Code,
  //     "filterPredicate": this.emitValue,
  //   }
  //   this.subscriptionObj.add(this.filterService.updateSavedFilterByCode(this.storeId, data).subscribe((res) => {
  //     if (res) {
  //       const dialogRef = this.dialogService.dialogMethod(this.dialogMessages.updateFilterSuccess, this.dialogType.success, true);
  //       this.subscriptionObj.add(dialogRef.afterClosed().subscribe(result => {
  //         if (result) {
  //           this.dialog.closeAll();
  //           this.saveFilterForm.reset();
  //           this.isSaveLoader = false;
  //         }
  //       }));
  //       this.getSavedFilters();
  //     }
  //     this.isSaveLoader = true;
  //   }, (err) => {
  //     this.dialogService.dialogMethod(this.dialogMessages.updateFilterFailed, this.dialogType.failure, true);
  //     this.isSaveLoader = false;
  //   }));
  // }

  /**
   * Method used to delete the particular saved filter
   * @param filterName
   */
  deleteActionClick(filterName: any): void {
    let filterId: number;
    if (filterName) {
      this.getAllSavedFilters && this.getAllSavedFilters.forEach((element: any) => {
        if (element.filterName === filterName) {
          filterId = element.id;
        }
      });
    }
    const dialogRef = this.dialogService.dialogMethod('Are you sure you want to remove this Filter?', this.dialogType.confirmation);
    this.subscriptionObj.add(dialogRef.afterClosed().pipe(filter((result: boolean) => { return result; }),
      mergeMap((res: any) => {
        return this.filterService.deleteSavedFilterByCode(this.storeId, filterId);
      })
      //  mergeMap((res: any) => {
      //   if (res) {
      //     // return this.dialogService.dialogMethod('Filter deleted Successfully.', this.dialogType.success, true).afterClosed();
      //     return this.dialogService.dialogMethod('Filter deleted Successfully.', this.dialogType.success, true);
      //   }
      // })
    ).subscribe((res: any) => {
      if (res) {
        this.dialogService.dialogMethod('Filter deleted Successfully.', this.dialogType.success, true);
        this.clearOneFilter(this.savedFilterIndex, this.savedFilterField);
        this.getSavedFilters();
      }
    }, (err: any) => {
      this.dialogService.dialogMethod('Filter deletion failure.', this.dialogType.failure);
    }));
  }

  /**
   * function used to search data in the selector field
   * @param value - To search value
   * @param filter - To emitfilter object
   */
  onSelectorSearchFilter(value?: string, filter?: any): void {
    if (value && value.length) {
      this.isSelectorOptions = true;
      let filterValue: string = value.toString();
      filterValue = filterValue.trim().toLowerCase();
      this.selectorConstant = filter && filter.options;
      let filterkey: string = filter && filter.displayName;
      const filteredArray = this.selectorConstant.filter((selectorValue: any) => selectorValue[filterkey].toLowerCase().includes(filterValue));
      this.selectorOptionValue = filteredArray;
    }
    else {
      this.isSelectorOptions = false;
      this.selectorSearchFilter.setValue(null);
      this.selectorOptionValue = [];
      // this.selectorOptionValue = filter?.options;
    }
  }

  /**
   * Method that is used to close the category filter
   */
  onSelectorSearchFilterClose(): void {
    this.isSelectorOptions = false;
    this.selectorSearchFilter.setValue(null);
    this.selectorOptionValue = [];
    // this.selectorOptionValue = filter?.options;
  }

  /**
   * Open the dialog to view the applied filter
   * @param filterName - The name of the filter to view
   */
  // onView(filterName: any): void {
  //   if (filterName) {
  //     this.getAllSavedFilters && this.getAllSavedFilters.filter((element: any) => {
  //       if (element.filterName === filterName) {
  //         this.AppliedFilterList = element.savedFilterNames;
  //       }
  //     });
  //     this.dialog.open(this.SavedFiltersDialog, { disableClose: true, autoFocus: false, width: '500px' });
  //   }
  // }
  onView(filterName: any): void {
    if (filterName) {
      const matchedFilter = this.getAllSavedFilters.find((element: any) => element.filterName === filterName);

      if (matchedFilter) {
        this.AppliedFilterList = matchedFilter.savedFilterNames;
      }

      this.dialog.open(this.SavedFiltersDialog, { disableClose: true, autoFocus: false, width: '500px' });
    }
  }


  /**
   * Method which is used to close onCloseViewFilterName  dialog.
   */
  onCloseViewFilterName(): void {
    this.dialog && this.dialog.closeAll();
  }

  /**
   * method used to unsubscribe the subscription arrays
   */
  ngOnDestroy(): void {
    this.subscriptionObj.unsubscribe();
  }
}
