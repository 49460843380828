export interface ButtonInfo {
    /**
     * Import name to display for in the button info.
     */
    name: string,
    /**
     * Import class to display for in the button info.
     */
    class: string,
    /**
     * Import method to display for in the button info.
     */
    method?: string,
    /**
     * Import link to display for in the button info.
     */
    link?: string,
    /**
     * Import icon to display for in the button info.
     */
    icon?: string,
    /**
     * Import disabled to display for in the button info.
     */
    disabled?: boolean,
    /**
     * Import condition to display for in the button info.
     */
    condition?: boolean,
    /**
     * Import iconClass for icons in the button info.
     */
    iconClass?: string
    /**
     * Import type for menu button in the button info.
     */
    type?: string,
    /**
     * Import options for menu button in the button info.
     */
    options?: any[],
    /**
    * iconToolTip used to show tooltip for button and menu in the button info.
    */
    iconToolTip?: string,
}

export interface Heading {
    /**
     * Import title to display title in page.
     */
    title: string,
    /**
     * Import description to display description in page.
     */
    description: string,
    /**
     * Which is used to display Link Icon by default which gets navigated to the help document
     */
    isHeaderIcon?: boolean,
    /**
     * Which is used to display help icon will after the title.
     */
    iconName?: string,
    /**
     * Which is used to navigate to the specific help document page.
     */
    iconLink?: string,
    /**
     * Which is used to display tool tip textwhen you hover on the icon
     */
    iconToolTip?: string,
    /**
     * Variable is used to display search bar in heading
     */
    isSearch?: Boolean,
    /**
     * Variable is used to display search placeholder in heading
     */
    searchPlaceHolder?: string
}

export interface content {
    /**
     * Data should contain content to display in center
     */
    data: string;
    /**
     * type should contain content type like warning,success or failure
     */
    type: string
}
/**
 * Variable used to display button toggle information
 */
export interface buttonToggleInfo {
    /**
     * Import name to display for in the button info.
     */
    name: string,
    /**
     * Import class to display for in the button info.
     */
    class: string,
    /**
     * Import method to display for in the button info.
     */
    method?: string,
    /**
     * Import active to check active button info
     */
    active: boolean
}

