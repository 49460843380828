import { Component, ElementRef, OnDestroy, OnInit, QueryList, TemplateRef, ViewChild, ViewChildren } from '@angular/core';
import { FormControl, FormGroup, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { AuthService, CustomAsyncValidatorService, CustomValidatorService } from '@phase-ii/auth';
import { dataConfig } from '@phase-ii/common';
import { DialogService } from 'libs/common/src/lib/services/dialog.service';
import { AffiliateAuthService } from '../../services/auth.service';
import { AuthConstants } from '../../constants/authConstant';
import { CommonConstants, CommonService } from '@phase-ii/shared';
import { Observable, Subscription, of } from 'rxjs';
import { Router } from '@angular/router';
import { CanComponentDeactivate } from '../../services/can-deactivate-guard.service';
import { mergeMap } from 'rxjs/operators';

@Component({
  selector: 'phase-ii-admin-profile',
  templateUrl: './admin-profile.component.html',
  styleUrls: ['./admin-profile.component.scss'],
})

export class AdminProfileComponent extends AuthConstants implements OnInit, OnDestroy, CanComponentDeactivate {
  /**
   * Flag indicating whether input fields are disabled.
   */
  isInputDisabled = true;
  /**
   * Flag indicating whether email input field is disabled.
   */
  inInputEmailDisabled = true;
  /**
   * Flags controlling current password visibility.
   */
  currentPasswordHidden = true;
  /**
  * Flags controlling new password visibility.
  */
  newPasswordHidden = true;
  /**
  * Flags controllingconfirm  password visibility.
  */
  confirmPasswordHidden = true;
  /**
   * Flag indicating loader state.
   */
  loader = true;
  /**
   * Password Change Status Flag
   */
  ispasswordLoader = false;
  /**
   * Password container button Status Flag
   */
  ispasswordButtonDiable = false;
  /**
   * Profile container button Status Flag
   */
  isProfiledButtonDiable = false;
  /**
   * FormGroup for the change password form.
   */
  changePasswordForm: UntypedFormGroup;
  /**
   * Subscription to manage observable subscriptions.
   */
  subscriptionObj: Subscription = new Subscription();
  /**
   * Variable to store user email.
   */
  userEmail: any;
  /**
  * Variable to store user ID 
  */
  userid: any;
  /**
  * Variable to store user name.
  */
  userName: any;
  /**
  * Variable to store contact Number.
  */
  contactNumber: any;
  /**
  * Variable to store user address line one.
  */
  userAddressOne: any;
  /**
  * Variable to store user address line two.
  */
  userAddressTwo: any;
  /**
  * Variable to store user city.
  */
  userCity: any;
  /**
  * Variable to store user country.
  */
  userCountry: any;
  /**
  * Variable to store user state.
  */
  userState: any;
  /**
  * Variable to store user postal code.
  */
  userZipCode: any;
  /**
  * Flag indicating whether the login mail ID refers to a user or a superAdmin.
  */
  isSuperAdmin: any;
  /**
  * Flag indicating the deactivated account.
  */
  deactivatedAccount: any;
  /**
  * Variable to store user token.
  */
  token: any;
  /**
   * Dialog box references.
   */
  savePasswordConfirmDialogBox: any;
  /**
   * Form groups for user profile details.
   */
  userForm: FormGroup;
  /**
   * variable to store list of countries
   */
  countryArray: any[] = [];
  /**
   * variable for filter the country
   */
  countries: any;
  /**
   * variable to store list of states
   */
  states: any[] = [];
  /**
   * variable for filter the country
   */
  statesArray: any;
  /**
   * variable to store class name for form control
   */
  getControlStateClass: any;
  /**
   * Confirmation Dialog box references.
   */
  confirmationDialog: any;
  /**
   * Cancel edit profile dialog box references.
   */
  cancelProfileEditDialog: any;
  /**
   * Variable which is used to search country 
   * @type {FormControl}
   */
  countrySearchFilter = new UntypedFormControl(null);
  /**
   * Variable which is used to search country 
   * @type {FormControl}
   */
  stateSearchFilter = new UntypedFormControl(null);
  /*
  * Common constants used in the component. An instance of the CommonConstants class is created for reusable constants.
  */
  commonConstant = new CommonConstants();
  /*
  *  Auth constants used in the component. An instance of the AuthConstants class is created for reusable constants.
  */
  authConstants = new AuthConstants();
  /**
   * Array to store log-in/out activity for display purposes.
   */
  logInOutActivityArray: any[];
  /**
    * Variable to hold the detailed log-in/out activity data.
    */
  logInOutActivity: any;
  /**
    * Variable to keep track of the current navigation index for slider items
    */
  currentNavIndex = 0;
  /**
  * Variable used to keep track of  expanded state of mat-expansion-panel 
  */
  step = 0;
  /**
   * ViewChildren decorator for accessing the slider items in the component's template.
   * It is used to dynamically manage and interact with elements with the 'sliderItem' template reference variable.
   */
  @ViewChildren('sliderItem') sliderItems: QueryList<ElementRef>;

  /**
   * Constructor for the AdminProfileComponent.
   * @param {Router} router - Angular router service.
   * @param {AffiliateAuthService} authService - Affiliate authentication service.
   * @param {MatDialog} dialog - Angular Material dialog service.
   * @param {MatSnackBar} snackBar - Angular Material snackbar service.
   * @param {CommonService} commonService - Common service for shared functionalities.
   * @param {AuthService} commonAuthService - Common authentication service.
   * @param {DialogService} dialogService - Dialog service for displaying messages.
   */
  constructor(
    private router: Router,
    private authService: AffiliateAuthService,
    private commonService: CommonService,
    private commonAuthService: AuthService,
    private dialogService: DialogService
  ) {
    super();
  }

  /**
   * ViewChild decorator for the confirmation dialog box template reference.
   */
  @ViewChild('routeToNextPageConfirmDialogBox', { static: true }) routeToNextPageConfirmDialogBox!: TemplateRef<any>;

  /**
  * Lifecycle hook: Angular OnInit.
  * Initializes the component.
  */
  ngOnInit(): void {
    this.toAssignProfileDetails();
  }

  /**
  * Retrieves and assigns user profile details.
  * Subscribes to observables for user details and country data.
  */
  toAssignProfileDetails() {
    this.subscriptionObj.add(this.authService.user.pipe(mergeMap((res: any) => {
      if (res) {
        this.token = res.token;
        this.userid = res.id;
        this.userName = res.name;
        this.userEmail = res.email;
        this.contactNumber = res.mobile;
        this.userAddressOne = res.address && res.address.address1;
        this.userAddressTwo = res.address && res.address.address2;
        this.userCity = res.address && res.address.city;
        this.userCountry = res.address && res.address.country;
        this.userState = res.address && res.address.state;
        this.userZipCode = res.address && res.address.pinCode;
        this.isSuperAdmin = res.isSuperadmin;
        this.deactivatedAccount = res.isSuspended;
        return this.commonService.getCountries(true);
      }
      else {
        return of(null);
      }
    }), mergeMap((res: any) => {
      if (res) {
        this.countryArray = res['country'];
        this.countries = this.countryArray;
        this.userCountry = this.countryArray.filter((country) => country.name === this.userCountry);
        this.userCountry = this.userCountry[0];
        return this.commonService.getStates(this.userCountry.id);
      }
      else {
        return of(null);
      }
    }), mergeMap((res: any) => {
      if (res) {
        this.states = this.statesArray = res['states']
        this.userState = this.states.filter((state) => state.name === this.userState);
        this.userState = this.userState[0];
        this.subscriptionObj.add(this.stateSearchFilter && this.stateSearchFilter.valueChanges.subscribe(res => {
          if (res) {
            this.states = res ? this.statesArray.filter(val =>
              res && val && (val['name']) && ((val['name'] + '').toLowerCase().includes((res + '').toLowerCase()) ||
                (val['state'] + '').includes(res + '') || ('+' + val['state'] + '').includes(res + ''))
            ) : this.statesArray;
          }
        }));
        return this.authService.getLoginActivity(this.userid, { offset: 0, limit: 12 })
      }
      else {
        return of(null);
      }
    })).subscribe((res: any) => {
      if (res) {
        if (res.result && res.result.rows && res.result.rows.length === 0) {
          this.logInOutActivityArray = [];
          this.step = 1;
        }
        else if (res.result && res.result.rows && res.result.rows.length <= 3) {
          this.logInOutActivityArray = [0];
        }
        else if (res.result && res.result.rows && res.result.rows.length <= 6) {
          this.logInOutActivityArray = [0, 1];
        }
        else if (res.result && res.result.rows && res.result.rows.length <= 9) {
          this.logInOutActivityArray = [0, 1, 2];
        }
        else if (res.result && res.result.rows && res.result.rows.length <= 12) {
          this.logInOutActivityArray = [0, 1, 2, 3];
        }
        this.toCreateProfileForm();
        this.setChangePasswordForm();
        this.logInOutActivity = res.result.rows
      }
    }, (err) => {
      this.dialogService.dialogMethod(this.authConstants.errorMessage.generalError, this.commonConstant.dialogType.failure, true);
    }))
    this.subscriptionObj.add(this.countrySearchFilter && this.countrySearchFilter.valueChanges.subscribe(res => {
      if (res) {
        this.countryArray = res ? this.countries.filter(val =>
          res && val && (val['name'] || val['country']) && ((val['name'] + '').toLowerCase().includes((res + '').toLowerCase()) ||
            (val['country'] + '').includes(res + '') || ('+' + val['country'] + '').includes(res + ''))
        ) : this.countries;
      }
    }, (err) => {
      this.dialogService.dialogMethod(this.authConstants.errorMessage.generalError, this.commonConstant.dialogType.failure, true);
    }));
  }

  /**
   * Creates the user profile form.
   */
  toCreateProfileForm() {
    this.userForm = new FormGroup({
      name: new FormControl({ value: this.userName, disabled: this.isInputDisabled }, [Validators.required, Validators.pattern(this.validator.acceptOnlyAlphabets), Validators.maxLength(25)]),
      email: new FormControl({ value: this.userEmail, disabled: this.inInputEmailDisabled }, {
        validators: [Validators.required, Validators.pattern(this.validator.emailValidationPattern)],
        asyncValidators: CustomAsyncValidatorService.asyncNoEmailValidationForAdminFromLambda(this.authService)
      }),
      state: new FormControl({ value: this.userState, disabled: this.isInputDisabled }, [Validators.required]),
      country: new FormControl({ value: this.userCountry, disabled: this.isInputDisabled }, [Validators.required]),
      addressLine1: new FormControl({ value: this.userAddressOne, disabled: this.isInputDisabled }, [Validators.required, Validators.maxLength(100)]),
      addressLine2: new FormControl({ value: this.userAddressTwo, disabled: this.isInputDisabled }, [Validators.maxLength(100)]),
      city: new FormControl({ value: this.userCity, disabled: this.isInputDisabled }, [Validators.required, Validators.maxLength(20), Validators.pattern(this.validator.acceptOnlyAlphabets)]),
      contactNumber: new FormControl({ value: this.contactNumber, disabled: this.isInputDisabled }, {
        validators: [Validators.required, Validators.maxLength(20), Validators.pattern(this.validator.acceptOnlyNumbers)],
        asyncValidators: [CustomAsyncValidatorService.checkPhoneAlreadyExists(this.commonAuthService, this.userid ? this.userid : null),]
      }),
      zipCode: new FormControl({ value: this.userZipCode, disabled: this.isInputDisabled }, [Validators.required, Validators.maxLength(15)]),
    }, CustomValidatorService.formValidation.bind(this));
    this.loader = false;
  }

  /**
  * Resets the country search filter.
  */
  toResetCountrySearchFilter() {
    this.countryArray = this.countries;
    this.countrySearchFilter.reset();
  }

  /**
  * Matches states with the user selected country.
  */
  toMatchStatesWithCountry() {
    this.userForm.get('state').reset();
    this.states = this.statesArray = null
    this.loader = false;
    if (this.userForm.get('country').value) {
      this.subscriptionObj.add(this.commonService.getStates(this.userForm.get('country').value.id).subscribe((res) => {
        if (res) {
          this.states = this.statesArray = res['states']
          this.userState = this.states.filter((state) => state.name === this.userState.name);
          if (this.userState.length === 0) {
            this.userForm.get('state').reset();
          } else {
            this.userState = this.userState[0];
          }
        }
      }, (err) => {
        this.dialogService.dialogMethod(this.authConstants.errorMessage.generalError, this.commonConstant.dialogType.failure, true);
      }));
    }
  }

  /**
   * Resets the state search filter.
   */
  toResetStateSearchFilter(): void {
    this.states = this.statesArray;
    this.stateSearchFilter.reset();
  }

  /**
   * Toggles password visibility.
   * @param {string} passwordField - The password field state to toggle.
   */
  togglePasswordVisibility(passwordField: string) {
    if (passwordField === 'currentPasswordHidden') {
      this.currentPasswordHidden = !this.currentPasswordHidden;
    }
    if (passwordField === 'newPasswordHidden') {
      this.newPasswordHidden = !this.newPasswordHidden;
    }
    if (passwordField === 'confirmPasswordHidden') {
      this.confirmPasswordHidden = !this.confirmPasswordHidden;
    }
  }

  /**
   * Toggles the state of input fields.
   */
  toggleInputState() {
    const controls = this.userForm.controls;
    Object.keys(controls).forEach(controlName => {
      const control = this.userForm.get(controlName);
      if (control) {
        if (!this.isInputDisabled) {
          this.getControlStateClass = 'disabled-text'
          control.disable();
        } else {
          control.enable();
          this.getControlStateClass = 'enabled-text'
          const emailControl = this.userForm.get('email');
          if (emailControl) {
            emailControl.disable();
          }
        }
      }
    });
    this.isInputDisabled = !this.isInputDisabled;
    this.isProfiledButtonDiable = false;
  }

  /**
   * Sets up the change password form.
   */
  setChangePasswordForm() {
    this.changePasswordForm = new UntypedFormGroup({
      currentPassword: new UntypedFormControl(null, {
        validators: [
          Validators.required],
        asyncValidators: [
          CustomAsyncValidatorService.asyncOldPasswordValidationForAdmin(this.authService, this.userEmail),
        ]
      }),
      password: new UntypedFormControl(null, {
        validators: [
          Validators.required,
          Validators.pattern(dataConfig.patternValidators.passwordValidationPattern)]
      }
      ),
      confirmPassword: new UntypedFormControl(null, [Validators.required])
    }, CustomValidatorService.formValidation.bind(this)
    );
  }

  /**
  * Sets an error for confirm password if passwords do not match.
  * @param {string} event - The event value.
  */
  setErrorForConfirmPassword(event) {
    if ((event && event.length) && (event !== (this.changePasswordForm && this.changePasswordForm.value && this.changePasswordForm.value.password))) {
      this.changePasswordForm && this.changePasswordForm.get('confirmPassword') && this.changePasswordForm.get('confirmPassword').setErrors({ 'passwordMismatch': true });
    }
    else if (this.changePasswordForm.get('confirmPassword').hasError('passwordMismatch')) {
      this.changePasswordForm && this.changePasswordForm.get('confirmPassword') && this.changePasswordForm.get('confirmPassword').setErrors(null);
    }
  }

  /**
   * Resets the change password form.
   */
  resetPasswordForm() {
    this.changePasswordForm.reset();
  }

  /**
  * Resets the user profile form.
  */
  resetProfileForm() {
    // this.toAssignProfileDetails();
    this.authService.getCurrentUser().subscribe((res: any) => {
      if (res) {
        this.toggleInputState();
      }
      else {
        this.dialogService.dialogMethod(this.authConstants.errorMessage.dataRetrievalError, this.commonConstant.dialogType.failure, true);
      }
    }, (err) => {
      this.dialogService.dialogMethod(this.authConstants.errorMessage.generalError, this.commonConstant.dialogType.failure, true);
    })
  }

  /**
   * Handles change password action.
   * @param {any} token - The user token.
   */
  onChangePassword(token: any) {
    this.authService.getBase64String(null, this.changePasswordForm.value.password, 'affiliateMarketing/changePassword', 'Registration');
    this.subscriptionObj.add(this.authService.changePassword(token).subscribe((res: any) => {
      if (res) {
        this.ispasswordLoader = false;
        this.ispasswordButtonDiable = false;
        this.authService.logout();
        this.resetPasswordForm();
        this.router.navigate(['signin']);
        this.dialogService.dialogMethod(this.authConstants.successMessages.passwordUpdated, this.commonConstant.dialogType.success, true);
      } else {
        this.ispasswordLoader = false;
        this.ispasswordButtonDiable = false;
        this.dialogService.dialogMethod(this.authConstants.errorMessage.dataRetrievalError, this.commonConstant.dialogType.failure, true);
      }
    }, (err) => {
      this.ispasswordLoader = false;
      this.ispasswordButtonDiable = false;
      this.dialogService.dialogMethod(this.authConstants.errorMessage.generalError, this.commonConstant.dialogType.failure, true);
    }))
  }

  /**
   * Handles the cancel action for profile editing.
   */
  onEditCancel() {
    if (this.userForm && this.userForm.dirty) {
      const dialogRef = this.dialogService.dialogMethod(this.authConstants.confirmMessages.cancelEdit, this.commonConstant.dialogType.confirmation, true);
      this.subscriptionObj.add(
        dialogRef.afterClosed().subscribe((result: boolean) => {
          if (result) {
            this.resetProfileForm();
          }
        })
      );
    } else {
      this.toggleInputState();
    }
  }

  /**
   * Initiates the save action for profile editing.
   */
  onProfileEditSave() {
    const dialogRef = this.dialogService.dialogMethod(this.authConstants.confirmMessages.updateProfile, this.commonConstant.dialogType.confirmation, true);
    this.subscriptionObj.add(
      dialogRef.afterClosed().subscribe((result: boolean) => {
        if (result) {
          this.isProfiledButtonDiable = true;
          this.onEditProfile();
        }
      })
    );
  }

  /**
   * Initiates the save action for password editing.
   */
  onPasswordEditSave() {
    const dialogRef = this.dialogService.dialogMethod(this.authConstants.confirmMessages.updatePassword, this.commonConstant.dialogType.confirmation, true);
    this.subscriptionObj.add(
      dialogRef.afterClosed().subscribe((result: boolean) => {
        if (result) {
          this.ispasswordLoader = true;
          this.ispasswordButtonDiable = true;
          this.onChangePassword(this.token);
        } else {
          this.resetPasswordForm();
        }
      })
    );
  }

  /**
   * Handles the profile editing action.
   */
  onEditProfile() {
    const data = {
      name: this.userForm && this.userForm.value && this.userForm.value.name,
      mobile: this.userForm && this.userForm.value && this.userForm.value.contactNumber,
      address: {
        city: this.userForm && this.userForm.value && this.userForm.value.city,
        pinCode: this.userForm && this.userForm.value && this.userForm.value.zipCode,
        stateId: this.userForm && this.userForm.value && this.userForm.value.state.id,
        state: this.userForm && this.userForm.value && this.userForm.value.state.name,
        countryId: this.userForm && this.userForm.value && this.userForm.value.country.id,
        country: this.userForm && this.userForm.value && this.userForm.value.country.name,
        address1: this.userForm && this.userForm.value && this.userForm.value.addressLine1,
        address2: this.userForm && this.userForm.value && this.userForm.value.addressLine2,
      },
      countryIsoCode: this.userForm && this.userForm.value && this.userForm.value.country.countryIsoCode,
      currencyId: this.userForm && this.userForm.value && this.userForm.value.country.currency.id
    }
    this.subscriptionObj.add(this.authService.putAffiliateUserDetails(data, this.userid).pipe(mergeMap((res: any) => {
      if (res) {
        return this.authService.getCurrentUser();
      } else {
        this.dialogService.dialogMethod(this.authConstants.errorMessage.dataRetrievalError, this.commonConstant.dialogType.failure, true);
        return of(null);
      }
    })).subscribe((res: any) => {
      if (res) {
        this.dialogService.dialogMethod(this.authConstants.successMessages.profileUpdated, this.commonConstant.dialogType.success, true);
      } else {
        this.dialogService.dialogMethod(this.authConstants.errorMessage.dataRetrievalError, this.commonConstant.dialogType.failure, true);
      }
    }, (err) => {
      this.dialogService.dialogMethod(this.authConstants.errorMessage.generalError, this.commonConstant.dialogType.failure, true);
    }));
    this.toggleInputState();
  }

  /**
   * Deactivates the component, allowing navigation away.
   * Checks if the profile or password forms are dirty before allowing navigation.
   * Prompts the user for confirmation if there are unsaved changes.
   */
  canDeactivate(): Observable<boolean> | Promise<boolean> | boolean {
    if ((this.userForm && this.userForm.dirty) || (this.changePasswordForm && this.changePasswordForm.dirty)) {
      return new Observable<boolean>((observer) => {
        const dialogRef = this.dialogService.dialogMethod(this.authConstants.confirmMessages.leavePage, this.commonConstant.dialogType.confirmation, true);
        this.subscriptionObj.add(
          dialogRef.afterClosed().subscribe((result: boolean) => {
            if (result) {
              observer.next(true);
              observer.complete();
            } else {
              observer.next(false);
              observer.complete();
            }
          })
        );
      })
    } else {
      return new Observable<boolean>((observer) => {
        observer.next(true);
        observer.complete();
      })
    }
  }

  /**
   * Method to slide the slider to the specified index in the carousel.
   * @param {any} currentSlideIndex - The index to which the slider should move.
   */
  slideToCurrentIndex(currentSlideIndex: any): void {
    this.currentNavIndex = currentSlideIndex;
    const containerWidth = this.sliderItems && this.sliderItems.first && this.sliderItems.first.nativeElement && this.sliderItems.first.nativeElement.clientWidth;
    const transformValue = -containerWidth * currentSlideIndex;
    this.sliderItems.forEach(item => {
      item.nativeElement.style.transform = `translateX(${transformValue}px)`;
    });
  }

  /**
   * Method to expand the current mat-expansion-panel.
   * @param {number} index - The index representing the current expanded panel.
   */
  setStep(index: number) {
    this.step = index;
  }

  /**
   * Method to deactivate the user account based on the provided event.
   * @param {any} event - The event that triggers the deactivation action.
   */
  DeactivateAccount(event: any) {
    const data = {
      suspend: event.checked
    }
    this.deactivatedAccount = event.checked;
    if (this.deactivatedAccount) {
      const dialogRef = this.dialogService.dialogMethod(this.authConstants.confirmMessages.deactivateAccount, this.commonConstant.dialogType.confirmation, true);
      this.subscriptionObj.add(
        dialogRef.afterClosed().subscribe((result: boolean) => {
          if (result) {
            this.authService.DeactivateAccount(this.userid, data).subscribe((res: any) => {
              if (res) {
                this.authService.logout();
                this.router.navigate(['signin']);
                this.dialogService.dialogMethod((this.userEmail + this.authConstants.successMessages.deactivatedAccound), this.commonConstant.dialogType.success, true);
              } else {
                this.dialogService.dialogMethod(this.authConstants.errorMessage.dataRetrievalError, this.commonConstant.dialogType.failure, true);
              }
            }, (err) => {
              this.dialogService.dialogMethod(this.authConstants.errorMessage.generalError, this.commonConstant.dialogType.failure, true);
            });
          } else {
            this.deactivatedAccount = false;
          }
        })
      );
    }
  }

  /**
  * Lifecycle hook executed when the component is destroyed.
  * Unsubscribes from the subscription object to prevent memory leaks.
  */
  ngOnDestroy() {
    if (this.subscriptionObj) {
      this.subscriptionObj.unsubscribe();
    }
  }
}