<cen-loader-vii *ngIf="loader"></cen-loader-vii>
<div class="main-container" fxLayout="row" fxLayoutAlign="center center" *ngIf="!loader">
  <div class="sub-container" fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="center start"
    fxLayoutAlign.lt-md="center center" fxLayoutGap="2%" fxLayoutGap.lt-md="4%">
    <!-- left container -->
    <div class="left-container">
      <div class="left-sub-container" fxLayout="column" fxLayoutAlign="start center">
        <!-- profile container header -->
        <div class="container-header" fxLayout="row" fxLayoutAlign="space-between center">
          <div>My Profile</div>
          <button mat-icon-button *ngIf="isInputDisabled" (click)="toggleInputState()">
            <mat-icon class="profile-edit-icon">edit</mat-icon>
          </button>
        </div>
        <!-- Profile container form -->
        <div class="profile-details-container" fxLayout="column" [formGroup]="userForm">
          <!-- Name input field -->
          <div class="profile-details-sub-container">
            <div fxLayout="row" fxLayoutGap="10%">
              <div class="label-conatiner" fxFlex="30%">
                Name
              </div>
              <div fxFlex="60%">
                <input class="input-container" [ngClass]="getControlStateClass" placeholder="-" formControlName="name">
                <mat-error *ngIf="userForm?.get('name')?.hasError('required')">
                  {{errorMessage?.ERROR?.NAME?.required }}
                </mat-error>
                <mat-error data-control=" validName" *ngIf="userForm?.get('name')?.hasError('pattern')">
                  {{errorMessage?.ERROR?.NAME?.pattern}}
                </mat-error>
                <mat-error data-control="nameError"
                  *ngIf="userForm?.get('name')?.hasError('maxlength') && !userForm?.get('name')?.hasError('pattern')">
                  {{errorMessage?.ERROR?.NAME?.maxlength}}
                </mat-error>
              </div>
            </div>
            <div class="divider"></div>
          </div>
          <!-- Email input field -->
          <div class="profile-details-sub-container">
            <div fxLayout="row" fxLayoutGap="10%">
              <div class="label-conatiner" fxFlex="30%">
                Email
              </div>
              <input class="input-container" placeholder="-" fxFlex="60%" formControlName="email">
            </div>
            <div class="divider"></div>
          </div>
          <!-- Contact number input field -->
          <div class="profile-details-sub-container">
            <div fxLayout="row" fxLayoutGap="10%">
              <div class="label-conatiner" fxFlex="30%">
                Contact Number
              </div>
              <div fxFlex="60%">
                <input class="input-container" [ngClass]="getControlStateClass" placeholder="-"
                  formControlName="contactNumber">
                <mat-error *ngIf="userForm?.get('contactNumber')?.hasError('required')">
                  {{errorMessage?.ERROR?.CONTACT?.required}}
                </mat-error>
                <mat-error
                  *ngIf="userForm?.get('contactNumber')?.hasError('pattern') && !userForm?.get('contactNumber')?.errors?.maxlength">
                  {{errorMessage?.ERROR?.CONTACT?.pattern}}
                </mat-error>
                <mat-error data-control="contactNumberAlreadyExist"
                  *ngIf="userForm?.get('contactNumber')?.hasError('mobileNumberAlreadyExists')">
                  {{errorMessage?.ERROR?.CONTACT?.mobileNumberAlreadyExists}}
                </mat-error>
                <mat-error data-control="contactLengthError" *ngIf="userForm?.get('contactNumber')?.errors?.maxlength">
                  {{errorMessage?.ERROR?.CONTACT?.maxlength}}
                </mat-error>
              </div>
            </div>
            <div class="divider"></div>
          </div>
          <!-- Address line 1 input field -->
          <div class="profile-details-sub-container">
            <div fxLayout="row" fxLayoutGap="10%">
              <div class="label-conatiner" fxFlex="30%">
                Address Line 1
              </div>
              <div fxFlex="60%">
                <textarea class="input-container no-scrollbar" [ngClass]="getControlStateClass" cdkTextareaAutosize
                  placeholder="-" formControlName="addressLine1"></textarea>
                <mat-error data-control="addressLine1Error" *ngIf="userForm?.get('addressLine1')?.hasError('required')">
                  {{errorMessage?.ERROR?.ADDRESS?.required}}
                </mat-error>
                <mat-error data-control="addressLine1Error"
                  *ngIf="userForm?.get('addressLine1')?.hasError('maxlength') && !userForm?.get('addressLine1')?.hasError('pattern')">
                  {{errorMessage?.ERROR?.ADDRESS?.maxlength}}
                </mat-error>
              </div>
            </div>
            <div class="divider"></div>
          </div>
          <!-- Address Line 2 input field -->
          <div class="profile-details-sub-container">
            <div fxLayout="row" fxLayoutGap="10%">
              <div class="label-conatiner" fxFlex="30%">
                Address Line 2
              </div>
              <div fxFlex="60%">
                <textarea class="input-container no-scrollbar" [ngClass]="getControlStateClass" cdkTextareaAutosize
                  placeholder="-" formControlName="addressLine2"></textarea>
                <mat-error data-control="addressLine2Error"
                  *ngIf="userForm?.get('addressLine2')?.hasError('maxlength') && !userForm?.get('addressLine2')?.hasError('pattern')">
                  {{errorMessage?.ERROR?.ADDRESS?.maxlength}}
                </mat-error>
              </div>
            </div>
            <div class="divider"></div>
          </div>
          <!-- City input field -->
          <div class="profile-details-sub-container">
            <div fxLayout="row" fxLayoutGap="10%">
              <div class="label-conatiner" fxFlex="30%">
                City
              </div>
              <div fxFlex="60%">
                <input class="input-container" [ngClass]="getControlStateClass" placeholder="-" formControlName="city">
                <mat-error data-control="cityError" *ngIf="userForm?.get('city')?.hasError('required')">
                  {{errorMessage?.ERROR?.CITY?.required}}
                </mat-error>
                <mat-error data-control="cityError" *ngIf="userForm?.get('city')?.hasError('pattern')">
                  {{errorMessage?.ERROR?.CITY?.pattern}}
                </mat-error>
                <mat-error data-control="cityError" *ngIf="userForm?.get('city')?.hasError('maxlength')">
                  {{errorMessage?.ERROR?.CITY?.maxLength}}
                </mat-error>
              </div>
            </div>
            <div class="divider"></div>
          </div>
          <!-- Country input field -->
          <div class="profile-details-sub-container">
            <div fxLayout="row" fxLayoutGap="10%">
              <div class="label-conatiner" fxFlex="30%">
                Country
              </div>
              <div fxFlex="60%">
                <mat-select class="input-container" [ngClass]="getControlStateClass" placeholder="-" (selectionChange)="toMatchStatesWithCountry()"
                  (openedChange)="$event===false?toResetCountrySearchFilter():null" formControlName="country">
                  <div class="search">
                    <div fxLayout="row" class="search-country" fxLayoutAlign="center center">
                      <input matInput placeholder="Search country" data-control="searchCountry"
                        [formControl]="countrySearchFilter"
                        (keydown)=" ($event.keyCode === 32 ) ?  $event.stopPropagation() : null" />
                      <mat-icon class="cursor-pointer" *ngIf="countrySearchFilter?.value"
                        (click)="toResetCountrySearchFilter()" data-control="closeIconCountry">
                        close
                      </mat-icon>
                    </div>
                    <mat-divider></mat-divider>
                  </div>
                  <mat-option *ngIf="countryArray?.length == 0 || !countryArray">--No Countries Found--
                  </mat-option>
                  <mat-option [value]="country" *ngFor="let country of countryArray">{{country['name']}}</mat-option>
                </mat-select>
                <mat-error *ngIf="userForm?.get('country')?.hasError('required')">
                  {{errorMessage?.COUNTRY}}
                </mat-error>
                <mat-error *ngIf="userForm?.get('country')?.value===null">
                  {{errorMessage?.COUNTRY}}
                </mat-error>
              </div>
            </div>
            <div class="divider"></div>
          </div>
          <!-- State input field -->
          <div class="profile-details-sub-container">
            <div fxLayout="row" fxLayoutGap="10%">
              <div class="label-conatiner" fxFlex="30%">
                State
              </div>
              <div fxFlex="60%">
                <mat-select class="input-container" [ngClass]="getControlStateClass" placeholder="-" formControlName="state"
                  (openedChange)="$event===false?toResetStateSearchFilter():null">
                  <div class="search">
                    <div fxLayout="row" class="search-country" fxLayoutAlign="center center">
                      <input data-control="searchstate" placeholder="Search state" matInput
                        [formControl]="stateSearchFilter"
                        (keydown)=" ($event.keyCode === 32 ) ?  $event.stopPropagation() : null" />
                      <mat-icon class="cursor-pointer" *ngIf="stateSearchFilter?.value"
                        (click)="toResetStateSearchFilter()" data-control="closeIconState">
                        close
                      </mat-icon>
                    </div>
                    <mat-divider></mat-divider>
                  </div>
                  <mat-option [value]="stateOption"
                    *ngFor="let stateOption of states">{{stateOption['name']}}</mat-option>
                  <mat-option *ngIf="states?.length === 0 || !(states)">--No states found--
                  </mat-option>
                </mat-select>
                <mat-error
                  *ngIf="userForm?.get('state')?.hasError('required') && userForm?.get('country')?.value!==null">
                  {{errorMessage?.ERROR?.STATE?.required}}
                </mat-error>
              </div>
            </div>
            <div class="divider"></div>
          </div>
          <!-- Zip code input field -->
          <div class="profile-details-sub-container">
            <div fxLayout="row" fxLayoutGap="10%">
              <div class="label-conatiner" fxFlex="30%">
                Postal/Zip Code
              </div>
              <div fxFlex="60%">
                <input class="input-container" [ngClass]="getControlStateClass" placeholder="-"
                  formControlName="zipCode">
                <mat-error data-control="zipCodeError" *ngIf="userForm?.get('zipCode')?.hasError('required')">
                  {{errorMessage?.ERROR?.ZIPCODE?.required}}
                </mat-error>
                <mat-error data-control="zipCodeLengthError" *ngIf="userForm?.get('zipCode')?.errors?.maxlength">
                  {{errorMessage?.ERROR?.ZIPCODE?.maxlength}}
                </mat-error>
              </div>
            </div>
            <div class="divider"></div>
          </div>
          <!-- Profile container save button -->
          <div class="profile-container-button" fxLayout="row" fxLayoutAlign="center center">
            <button class="profile-cancel-button" *ngIf="!isInputDisabled" mat-stroked-button
              (click)="onEditCancel()">Cancel</button>
            <button class="profile-save-button"
              [disabled]="!(userForm?.dirty && userForm?.valid) || isProfiledButtonDiable" *ngIf="!isInputDisabled"
              mat-raised-button (click)="onProfileEditSave()">Save<span class="password-loader"
                *ngIf="isProfiledButtonDiable"><em class="fas fa-circle-notch fa-spin"></em></span></button>
          </div>
        </div>
      </div>
    </div>
    <!-- right container -->
    <div class="right-container" fxLayout="column" fxLayoutAlign="start center">
      <!-- right top container -->
      <div class="right-sub-top-container" fxLayout="column" fxLayoutAlign="start start">
        <mat-expansion-panel [expanded]="step === 0" (opened)="setStep(0)">
          <mat-expansion-panel-header>
            <div class="right-sub-top-container-header">Login Activity</div>
          </mat-expansion-panel-header>
          <div class="login-activity-wrap-container" #sliderItem fxLayout="row">
            <div class="slider-container" *ngFor="let container of logInOutActivityArray; let i = index">
              <div class="login-activity-container"
                *ngFor="let activity of logInOutActivity?.slice(i * 3, i * 3 + 3); let j = index" fxLayout="column"
                fxLayoutAlign="start start">
                <div class="login-activity-sub-wrap-container" fxLayout="row">
                  <div class="device-name-conatiner">
                    {{activity.appDeviceHistory.deviceName}}
                  </div>
                  <div class="login-activity-sub-container" fxLayout="row" fxLayoutAlign="space-between start">
                    <div class="login-time-container" fxLayout="row">
                      <span class="material-symbols-outlined log-in-icon">
                        login
                      </span>
                      <div class="log-in-out-content">
                        {{activity.loginTime | date:'yyyy-MM-dd HH:mm:ss'}}
                      </div>
                    </div>
                    <div class="logout-time-container" fxLayout="row">
                      <span class="material-symbols-outlined log-out-icon">
                        logout
                      </span>
                      <div class="log-in-out-content">
                        {{activity.loggedOutTime === null? "N/A" : activity.loggedOutTime | date:'yyyy-MM-dd HH:mm:ss'}}
                      </div>
                    </div>
                  </div>
                </div>
                <div class="divider"></div>
              </div>
            </div>
          </div>
          <div class="dots-container" *ngIf="logInOutActivityArray.length !== 1" fxLayout="row"
            fxLayoutAlign="center center">
            <span class="dot-nav" [class.active]="dotIndex===currentNavIndex"
              *ngFor="let dotIndex of logInOutActivityArray" (click)="slideToCurrentIndex(dotIndex)">
            </span>
          </div>
        </mat-expansion-panel>
      </div>
      <!-- right bottom container -->
      <div class="right-sub-bottom-container" fxLayout="column" fxLayoutAlign="start center">
        <!-- right bottom container header -->
        <mat-expansion-panel [expanded]="step === 1" (opened)="setStep(1)">
          <mat-expansion-panel-header>
            <div class="container-header" fxLayout="row" fxLayoutAlign="space-between center">
              <div class="container-header-text">
                Change Password
              </div>
            </div>
          </mat-expansion-panel-header>
          <div class="password-container" *ngIf="!expandPasswordContainer" [formGroup]="changePasswordForm">
            <!-- current password container -->
            <div fxLayout="row" fxLayoutGap="10%">
              <div class="label-conatiner" fxFlex="30%">
                Current Password
              </div>
              <div fxLayout="column" fxFlex="60%">
                <div class="password-sub-container" fxLayout="row">
                  <input class="input-container input-container-margin password-input-container"
                    autocomplete="new-password" type="{{ currentPasswordHidden ? 'password' : 'text' }}"
                    formControlName="currentPassword" placeholder="Enter password" fxFlex="75%">
                  <div class="icon-div" fxFlex="20%">
                    <mat-icon class="view-icon" (click)="togglePasswordVisibility('currentPasswordHidden')"
                      *ngIf="!currentPasswordHidden">visibility</mat-icon>
                    <mat-icon class="view-icon" (click)="togglePasswordVisibility('currentPasswordHidden')"
                      *ngIf="currentPasswordHidden">visibility_off</mat-icon>
                  </div>
                </div>
                <div class="formstatus"
                  *ngIf="changePasswordForm?.pending && changePasswordForm?.get('currentPassword')?.pending && (changePasswordForm?.get('currentPassword')?.dirty || changePasswordForm?.get('currentPassword')?.touched)">
                  <span> Validating your current password
                    <em class="fas fa-circle-notch fa-spin"></em></span>
                </div>
                <mat-error data-control="passwordIcone2"
                  *ngIf=" changePasswordForm?.get('currentPassword')?.hasError('required') && changePasswordForm?.get('currentPassword')?.touched ">
                  Current password is required
                </mat-error>
                <mat-error data-control="passwordIcone1"
                  *ngIf="changePasswordForm?.get('currentPassword')?.hasError('passwordMatched') || changePasswordForm?.get('currentPassword')?.hasError('passwordNotMatched')">
                  Current password mismatch
                </mat-error>
              </div>
            </div>
            <div class="divider"></div>
            <!-- new password container -->
            <div fxLayout="row" fxLayoutGap="10%">
              <div class="label-conatiner" fxFlex="30%">
                New Password
              </div>
              <div fxLayout="column" fxFlex="60%">
                <div class="password-sub-container" fxLayout="row">
                  <input class="input-container input-container-margin password-input-container"
                    type="{{ newPasswordHidden ? 'password' : 'text' }}" formControlName="password"
                    placeholder="Enter new password" fxFlex="75%">
                  <div class="icon-div" fxFlex="20%">
                    <mat-icon class="view-icon" (click)="togglePasswordVisibility('newPasswordHidden')"
                      *ngIf="!newPasswordHidden">visibility</mat-icon>
                    <mat-icon class="view-icon" (click)="togglePasswordVisibility('newPasswordHidden')"
                      *ngIf="newPasswordHidden">visibility_off</mat-icon>
                  </div>
                </div>
                <mat-error data-control="passworde1"
                  *ngIf="changePasswordForm?.get('password')?.hasError('oldNewPasswordMatch')">
                  New password should not be your old password
                </mat-error>
                <mat-error data-control=" passworde2" *ngIf="changePasswordForm?.get('password')?.hasError('pattern')">
                  Password should have at least one number, lowercase, uppercase, special character,
                  minimum length of 6 and a maximum length of 20
                </mat-error>
                <mat-error data-control="passworde3"
                  *ngIf=" changePasswordForm?.get('password')?.hasError('required') && changePasswordForm?.get('password')?.touched">
                  New password is required
                </mat-error>
                <div class="formstatus"
                  *ngIf="changePasswordForm?.pending &&changePasswordForm?.get('password')?.pending && (changePasswordForm?.get('password')?.dirty || changePasswordForm?.get('password')?.touched)">
                  <span> Validating your new password
                    <em class="fas fa-circle-notch fa-spin"></em></span>
                </div>
              </div>
            </div>
            <div class="divider"></div>
            <!-- confirm password container -->
            <div fxLayout="row" fxLayoutGap="10%">
              <div class="label-conatiner" fxFlex="30%">
                Confirm Password
              </div>
              <div fxLayout="column" fxFlex="60%">
                <div class="password-sub-container" fxLayout="row">
                  <input class="input-container input-container-margin password-input-container"
                    type="{{ confirmPasswordHidden ? 'password' : 'text' }}" formControlName="confirmPassword"
                    placeholder="Re-enter new password" (input)="setErrorForConfirmPassword($event.target.value)"
                    fxFlex="75%">
                  <div class="icon-div" fxFlex="20%">
                    <mat-icon class="view-icon" (click)="togglePasswordVisibility('confirmPasswordHidden')"
                      *ngIf="!confirmPasswordHidden">visibility</mat-icon>
                    <mat-icon class="view-icon" (click)="togglePasswordVisibility('confirmPasswordHidden')"
                      *ngIf="confirmPasswordHidden">visibility_off</mat-icon>
                  </div>
                </div>
                <mat-error data-control="setErrorForPassworde1"
                  *ngIf="changePasswordForm?.get('confirmPassword')?.hasError('required') && changePasswordForm?.get('confirmPassword')?.touched">
                  Re-enter password
                </mat-error>
                <mat-error *ngIf="changePasswordForm?.get('confirmPassword')?.hasError('passwordMismatch')==true">
                  {{errorMessage?.passwordMismatch}}
                </mat-error>
              </div>
            </div>
            <div class="divider"></div>
            <!-- change password button container -->
            <div class="password-container-button" fxLayout="row" fxLayoutAlign="center center">
              <button class="password-save-button" mat-raised-button
                [disabled]="!changePasswordForm?.valid || ispasswordButtonDiable"
                (click)="onPasswordEditSave()">Save<span class="password-loader" *ngIf="ispasswordLoader"><em
                    class="fas fa-circle-notch fa-spin"></em></span>
              </button>
            </div>
          </div>
        </mat-expansion-panel>
      </div>
      <!-- right bottom mini container -->
      <div class="right-sub-bottom-mini-container" *ngIf="!isSuperAdmin" fxLayout="row"
        fxLayoutAlign="space-between center">
        <div class="right-sub-bottom-mini-container-header">Deactivate Account</div>
        <mat-slide-toggle (change)="DeactivateAccount($event)" [checked]="deactivatedAccount"></mat-slide-toggle>
      </div>
    </div>
  </div>
</div>