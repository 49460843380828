import { Component, Input, OnInit } from '@angular/core';
import { CommonService } from 'libs/shared/src/lib/services/common.service';
import { ThemesModuleConstants } from 'libs/themes/shared-theme/src/lib/constants/shared-theme-constants';
@Component({
  selector: 'phase-ii-location-template2',
  templateUrl: './location-template2.component.html',
  styleUrls: ['./location-template2.component.scss'],
})
export class LocationTemplate2Component extends ThemesModuleConstants implements OnInit {
  @Input() aboutLocation: any;
  @Input() locationDetails: any;
  awsUrl: any;
  weekDays: Array<any> = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
  locationDescription: Array<any> = [];
  constructor(private commonService: CommonService) { super(); }

  ngOnInit(): void {
    this.awsUrl = this.commonService && this.commonService.environment && this.commonService.environment.AWSImageUrl;
    if (this.locationDetails && this.locationDetails.length > 0) {
      let index = 0;
      this.locationDetails.forEach(res => {
        res['backgroundImage'] = this.aboutLocation && this.aboutLocation.locationBackground && this.aboutLocation.locationBackground.length && this.aboutLocation.locationBackground[index];
        index++;
      });
    }
  }
}

