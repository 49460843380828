/**
 * Service used for async validation to check the correct format to the field for auth service.
 */
import {
  AbstractControl,
  AsyncValidatorFn,
  ValidationErrors,
} from '@angular/forms';
import { Observable, timer, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { AuthService } from './auth.service';
/**
 * Service used for async validation to check the correct format to the field for auth service.
 */
export class CustomAsyncValidatorService {
  /**
   * Static method for custom validation
   * Check whether the email exists in db or not
   */

  static asyncEmailValidation(authService: AuthService, storeId): AsyncValidatorFn {
    return (
      control: AbstractControl
    ):
      | Promise<ValidationErrors | null>
      | Observable<ValidationErrors | null> => {
      return timer(500).pipe(
        switchMap(() => {
          return authService.getUserEmail(control.value, storeId).pipe(
            map((users) => {
              return users && users['emailExists']
                ? { emailAlreadyExists: true }
                : null;
            })
          );
        })
      );
    };
  }


  /**
   * Static method for custom validation
   * Check whether the email exists in db or not for admin
   */
  static asyncEmailValidationForAdmin(
    authService: AuthService
  ): AsyncValidatorFn {
    return (
      control: AbstractControl
    ):
      | Promise<ValidationErrors | null>
      | Observable<ValidationErrors | null> => {
      return timer(500).pipe(
        switchMap(() => {
          return authService.getUserEmailForAdmin(control.value).pipe(
            map((users) => {
              return users && users['emailExists']
                ? { emailAlreadyExists: true }
                : null;
            })
          );
        })
      );
    };
  }
  /**
   * Static method for custom validation
   * Check whether the email exists in db or not for admin from lambda
   */
  static asyncEmailValidationForAdminFromLambda(
    authService
  ): AsyncValidatorFn {
    return (
      control: AbstractControl
    ):
      | Promise<ValidationErrors | null>
      | Observable<ValidationErrors | null> => {
      return timer(500).pipe(
        switchMap(() => {
          return authService.checkMailAlreadyExist((control.value).toLowerCase()).pipe(
            map((users) => {
              return users && !users['emailExists']
                ? { emailAlreadyExists: true }
                : null;
            })
          );
        })
      );
    };
  }
  /**
   * Static method for custom validation
   * Check whether the email exists in db or not for admin from lambda
   */
  static asyncNoEmailValidationForAdminFromLambda(
    authService
  ): AsyncValidatorFn {
    return (
      control: AbstractControl
    ):
      | Promise<ValidationErrors | null>
      | Observable<ValidationErrors | null> => {
      return timer(500).pipe(
        switchMap(() => {
          return authService.checkMailAlreadyExist((control.value).toLowerCase()).pipe(
            map((users) => {
              return users && users['emailExists']
                ? { emailAlreadyExists: false }
                : null;
            })
          );
        })
      );
    };
  }
  /**
* Method which is used to validate the exisiting password.
*/
  static asyncExistsPasswordValidation(authService: AuthService): AsyncValidatorFn {
    return (control: AbstractControl): Promise<ValidationErrors | null> | Observable<ValidationErrors | null> => {
      return timer(400).pipe(switchMap(() => {
        if (control.value && control.value.length > 6) {
          const data = {
            password: control.value
          };
          return authService.checkPasswordMatch(data).pipe(map((res: { result: { isExists: boolean } }) => {
            return res.result.isExists ? null : { passwordNotMatched: true };
          }));
        } else {
          return of({ passwordNotMatched: true });
        }
      }));
    };
  }
  /**
* Method which is used to validate the exisiting password.
*/
  static asyncExistsPasswordValidationForAdmin(authService, email: string): AsyncValidatorFn {
    return (control: AbstractControl): Promise<ValidationErrors | null> | Observable<ValidationErrors | null> => {
      return timer(400).pipe(switchMap(() => {
        if (control.value && control.value.length >= 6) {
          const data = {
            email: email,
            password: control.value
          };
          return authService.checkPasswordMatchForAdmin(data).pipe(map((res: { passwordExists: boolean }) => {
            return !res.passwordExists ? null : { passwordMatched: true };
          }));
        } else {
          return of({ passwordNotMatched: true });
        }
      }));
    };
  }

  /**
* Method which is used to validate the exisiting password.
*/
  static asyncOldPasswordValidationForAdmin(authService, email: string): AsyncValidatorFn {
    return (control: AbstractControl): Promise<ValidationErrors | null> | Observable<ValidationErrors | null> => {
      return timer(400).pipe(switchMap(() => {
        if (control.value && control.value.length >= 6) {
          const data = {
            email: email,
            password: control.value
          };
          return authService.checkPasswordMatchForAdmin(data).pipe(map((res: { passwordExists: boolean }) => {
            return res.passwordExists ? null : { passwordMatched: true };
          }));
        } else {
          return of({ passwordNotMatched: true });
        }
      }));
    };
  }
  /**
* Method which is used to validate the exisiting display name.
*/
  static asyncDisplayNameValidation(authService): AsyncValidatorFn {
    return (control: AbstractControl): Promise<ValidationErrors | null> | Observable<ValidationErrors | null> => {
      return timer(400).pipe(switchMap(() => {
        const data = {
          displayName: control.value
        };
        return authService.displayNameExists(data).pipe(map((res: { nameExists: boolean }) => {
          return !res.nameExists ? null : { nameExists: true };
        }));
      }));
    };
  }
  /**
   * Method which is used to validate the exisiting password.
   * @param authService used to access the methods in auth service.
   * @returns the boolean value.
   */
  static signinEmailAndMobileNumberValidation(authService: AuthService): AsyncValidatorFn {
    return (control: AbstractControl): Promise<ValidationErrors | null> | Observable<ValidationErrors | null> => {
      return timer(400).pipe(switchMap(() => {
        const data = control.value.toString().trim();

        if ((data.length <= 20) && (data.match(/^[0-9]+$/) !== null)) {

          return of(null);

        } else if ((data.match('^[_A-Za-z0-9-\\+]+(\\.[_A-Za-z0-9-]+)*@[A-Za-z0-9-]+(\\.[A-Za-z0-9]+)*(\\.[A-Za-z]{2,})$') !== null)) {

          return of(null);

        } else {

          return of({ emailOrMobilePatternMissmatched: true });

        }

      }));
    };
  }
  /**
   * Method which is used to validate the exisiting contact number.
   * @param authService 
   * @param storeId 
   * @param role 
   * @returns boolean value
   */
  static asyncContactNumberValidationForStorFront(authService: AuthService, storeId, role?): AsyncValidatorFn {
    return (
      control: AbstractControl
    ):
      | Promise<ValidationErrors | null>
      | Observable<ValidationErrors | null> => {
      return timer(500).pipe(
        switchMap(() => {
          return authService.getUserContactNumber(control.value, storeId, role).pipe(
            map((users) => {
              return users && users['phoneExists']
                ? { contactNumberAlreadyExists: true }
                : null;
            })
          );
        })
      );
    };
  }
  /**
   * Method which is used to validate the exisiting email.
   * @param authService 
   * @param storeId 
   * @param role 
   * @returns boolean value
   */
  static asyncEmailValidationForStorFront(authService: AuthService, storeId, role?): AsyncValidatorFn {
    return (
      control: AbstractControl
    ):
      | Promise<ValidationErrors | null>
      | Observable<ValidationErrors | null> => {
      return timer(500).pipe(
        switchMap(() => {
          return authService.getCustomerEmailForValidation(control.value, storeId, role).pipe(
            map((users) => {
              return users && users['emailExists']
                ? { emailAlreadyExists: true }
                : null;
            })
          );
        })
      );
    };
  }
  /**
* Static method for custom validation
* Check whether the email exists in db or not for superadmin form users table
*/
  static asyncEmailValidationForSuperadminstaff(
    authService
  ): AsyncValidatorFn {
    return (
      control: AbstractControl
    ):
      | Promise<ValidationErrors | null>
      | Observable<ValidationErrors | null> => {
      return timer(500).pipe(
        switchMap(() => {
          return authService.checkSuperadminMailAlreadyExist((control.value).toLowerCase()).pipe(
            map((users) => {
              return users && users['emailExists']
                ? { emailAlreadyExists: true }
                : null;
            })
          );
        })
      );
    };
  }

  static asyncDomainNameValidation(settingService, storeId): AsyncValidatorFn {
    return (control: AbstractControl):
      | Promise<ValidationErrors | null>
      | Observable<ValidationErrors | null> => {
      return timer(500).pipe(
        switchMap(() => {
          return settingService.checkActivationStatus(storeId, control.value).pipe(
            map((response: any) => {
              if (response && response.result && response.result.statusCode === 200) {
                return { domainAlreadyExists: true }
              }
              else {
                return null
              }
            })
          );
        })
      );
    };
  }

  /**
   * Static method for custom validation
   * Check whether the account number exists in db or not
   */
  static asyncAccountValidation(authService: AuthService, id?: number): AsyncValidatorFn {
    return (
      control: AbstractControl
    ):
      | Promise<ValidationErrors | null>
      | Observable<ValidationErrors | null> => {
      return timer(500).pipe(
        switchMap(() => {
          return authService.getAccount(control.value, id).pipe(
            map((users) => {
              return users && users['isExists']
                ? { accountAldreadyexist: true }
                : null;
            })
          );
        })
      );
    };
  }

  /**
   * Static method for custom validation
   * Check whether the phone number exists in db or not
   */
  static checkPhoneAlreadyExists(authService, id?: any): AsyncValidatorFn {
    return (
      control: AbstractControl
    ):
      | Promise<ValidationErrors | null>
      | Observable<ValidationErrors | null> => {
      return timer(500).pipe(
        switchMap(() => {
          return authService.checkPhoneAlreadyExists(('' + control.value).replace(/\D/g, ""),
            id).pipe(
              map((users) => {
                return users && users['isExists']
                  ? { mobileNumberAlreadyExists: true }
                  : null;
              })
            );
        })
      );
    }
  }
}

