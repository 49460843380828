import { Component, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ButtonInfo, ColumnsRef, CommonConstants, CommonDataService, CommonService, Heading, ListProperties, PaginationService } from '@phase-ii/shared';
import { addEditUserConstant } from '../../constants/users-constant';
import { Subscription } from 'rxjs';
import { UsersService } from '../../services/users.service';
import { Router } from '@angular/router';
import { DialogService } from '@phase-ii/common';
import { filter, mergeMap } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'phase-ii-user-list',
  templateUrl: './user-list.component.html',
  styleUrls: ['./user-list.component.scss'],
})
export class UserListComponent implements OnInit, OnDestroy {

  /**
  * Variable to access the user constant class
  * @type {any}
  */
  addEditUserConstant: addEditUserConstant
  /**
* Variable to user to store account details
* @type {any}
*/
  accountDetails: any;
  /**
  * Variable to user to store JSON parse of accountdetails
  * @type {any}
  */
  value: any;

  /**
  * To open the dialog box for showing the log details.
  * @type {ViewChild}
  */
  @ViewChild('viewAccountDetails', { static: false })
  /**
   * Variable which is used to view the job details.
   * @type {ViewChild}
   */
  viewAccountDetails: TemplateRef<any>;

  /**
  * Variable which is used to subscribe and unsubscribe.
  * @type {Subscription}
  */
  subscriptionObj = new Subscription();

  /**
  * Variable which is used to store pagination Details.
  */
  paginationDetails: {
    offset: number,
    pageSize: number;
    itemsCount?: number;
    limit?: number;
  } = {
      pageSize: new CommonConstants().paginator.defaultPageSize,
      offset: 0,
      itemsCount: null,
      limit: 10
    };

  /**
* Variable which is used to have all the needed variables.
* @type {object}
*/
  pageDetails: {
    heading: Heading,
    buttonInfo: ButtonInfo[],
    columnRef: ColumnsRef[],
    isLoader: boolean,
    filterData: any,
    isFilter: boolean,
    headerAction: any,
    searchData: any,
    UserListFilter: any,
  } = {
      heading: null,
      buttonInfo: null,
      columnRef: null,
      isLoader: false,
      filterData: null,
      isFilter: false,
      headerAction: [],
      searchData: null,
      UserListFilter: [],
    };

  /**
  * Variable which is used to store property needed property of card list
  * @type {ListProperties}
  */

  listProperty: ListProperties = {
    globalSearch: true,
    columns: 5,
    suffixText: true,
    searchPlaceHolder: 'Search by name, email',
    localSearchFields: ['name'],
    isNewImage: {
      isNew: false,
      url: './assets/empty-user-list.png',
      text: 'No users have been addded yet.Start creating user details here',
    },
    cardBorderCondition: {
      field: 'Status',
      condition: {
        'Active': 'greenBorder',
        'Inactive': 'redBorder',
        'Suspended': 'pendingBorder',
      }
    },
  };

  /**
 *constructor which is used to inject the required services.
 * @param usersService used to get user details
 * @param router used to navigate to specific route
 * @param paginationService to access methods from pagination
 * @param commonDataService is used to get encrypted params
 * @param commonService is used common service objects 1
 * @param dialog is used to access dialog methods
 */
  constructor(
    private paginationService: PaginationService,
    private router: Router,
    private usersService: UsersService,
    private commonService: CommonService,
    private dialogService: DialogService,
    private commonDataService: CommonDataService,
    private dialog: MatDialog,
  ) {
    this.addEditUserConstant = new addEditUserConstant();
  }

  /**
   * Angular life cycle hooks
   */
  ngOnInit(): void {
    this.variableInitialization();
    this.getAllusersList(0, 2 * this.paginationDetails.pageSize, true, this.pageDetails.searchData, this.pageDetails.filterData);
  }

  /**
  *variableInitialization method user to initiaze.
  * @type {object}
  */
  variableInitialization() {
    this.pageDetails.heading = {
      title: this.addEditUserConstant.userheading.userlistTitle,
      description: this.addEditUserConstant.userheading.userlistDescryption,
    };
    this.pageDetails.buttonInfo = [{ name: '+ Add User', class: 'primary-button', method: 'addUser', disabled: false },];
    this.pageDetails.columnRef = this.addEditUserConstant.columnsForUserDetails;
    this.pageDetails.headerAction = [{ name: 'filter_list', value: 'Filter', toolTip: 'Filter', isConditionApplied: true, conditionType: 'DATA_EMPTY' }];
    this.pageDetails.UserListFilter = [{ title: "Status", type: "radio", field: "isActive", options: this.addEditUserConstant.UserBasedoption }];
  }

  /**
   * Method is used to get all customers
   * @param offset To get data from index
   * @param limit To get data upto the limit
   * @param isNew To refresh data
   * @param searchText To get searched text
   * @param filterData To get filter the data
   */
  getAllusersList(offset: number, limit: number, isNew: boolean, searchText?: any, filterData?: string): void {
    this.pageDetails.isLoader = true;
    const data = {
      offset: offset ? offset : 0,
      limit: limit ? limit : 5,
      filterData: filterData ? JSON.stringify(filterData) : null,
      searchText: (searchText) ? searchText : '',
    };
    this.subscriptionObj.add(this.usersService.getAllUserDetails(data).subscribe((res: any) => {
      if (res && res.affiliateUserList && res.affiliateUserList.rows) {
        this.pageDetails.isLoader = false;
        this.processListDataSource(res, isNew);
      }
    }, err => {
      this.paginationService.listPaginatorData.next({
        rows: [],
        count: 0,
        new: true
      });
      this.pageDetails.isLoader = false;
      this.dialogService.dialogMethod(this.addEditUserConstant.dialogMessages.failedToLoad, this.addEditUserConstant.dialogType.failure, true)
    }
    ))
  }

  /**
  * Function which is used to process the list data  of common card
  * @param response To get the response of get all affiliateUserList
  * @param isNew Used to load a list newly
  */
  processListDataSource(response: any, isNew?: boolean) {
    if (response && response.affiliateUserList && response.affiliateUserList.rows) {
      const sourceData = response.affiliateUserList.rows;
      for (let i = 0; i < sourceData.length; i++) {
        sourceData[i].mobile = sourceData[i].mobile.replace(/\D/g, '');
        sourceData[i].mobile = '+' + sourceData[i].countryCode + ' ' + sourceData[i].mobile;
        if (sourceData[i].isSuspended == true) {
          sourceData[i].Status = 'Suspended';
          sourceData[i].rowActionsIcon = [
            { iconName: 'edit', method: 'onEdit', toolTip: 'Edit User', iconColor: '#ff8517' },
            { iconName: 'supervisor_account', method: 'viewCustomersDetails', toolTip: 'View Customer List', iconColor: 'rgb(0, 0, 153);' },
            { iconName: 'account_circle', method: 'OnViewAccountDetails', toolTip: 'View Account Details', iconColor: '#068eef' },
          ];
        }
        else if (sourceData[i].isActive) {
          sourceData[i].Status = 'Active';
          sourceData[i].rowActionsIcon = [
            { iconName: 'edit', method: 'onEdit', toolTip: 'Edit User', iconColor: '#ff8517' },
            { iconName: 'highlight_off', method: 'onDeactive', toolTip: 'Inactive User', iconColor: '#f72121' },
            { iconName: 'supervisor_account', method: 'viewCustomersDetails', toolTip: 'View Customer List', iconColor: 'rgb(0, 0, 153);' },
            { iconName: 'account_circle', method: 'OnViewAccountDetails', toolTip: 'View Account Details', iconColor: '#068eef' },
          ];
        }
        else if (!sourceData[i].isActive) {
          sourceData[i].Status = 'Inactive';
          sourceData[i].rowActionsIcon = [
            { iconName: 'edit', method: 'onEdit', toolTip: 'Edit User', iconColor: '#ff8517' },
            { iconName: 'check_circle', method: 'onActive', toolTip: 'Active User', iconColor: '#03c04a' },
            { iconName: 'supervisor_account', method: 'viewCustomersDetails', toolTip: 'View Customer List', iconColor: 'rgb(0, 0, 153);' },
            { iconName: 'account_circle', method: 'OnViewAccountDetails', toolTip: 'View Account Details', iconColor: "'#068eef'" },
          ];
        }
        sourceData[i].rowMoreActionsIcon = sourceData[i].isSuspended === true ? [
          { displayName: 'Resume User ', event: 'navigate', values: 'onSuspendResume' }] : [
          { displayName: 'suspend User', event: 'navigate', values: 'onSuspendResume' }
        ]
      }
      if (!this.pageDetails.searchData && !this.pageDetails.filterData) {
        this.listProperty.isNewImage.isNew = response && response['affiliateUserList'] && response['affiliateUserList'].count == 0 ? true : false;
      }
      this.paginationService.listPaginatorData.next({
        rows: sourceData,
        count: response.affiliateUserList.count,
        new: isNew,
      });
    }
  }


  /**
  * To deactivate the user
   */
  onDeactive(event: any): void {
    if (event && event.data && event.data.id) {
      const dialogRef = this.dialogService.openDialog({
        header: this.addEditUserConstant.dialogHeader.confirmation,
        message: this.addEditUserConstant.dialogMessages.deactivateUser,
        actionType: this.addEditUserConstant.dialogType.confirmation,
        button: { right: "Yes", left: "No" }
      });
      this.subscriptionObj.add(dialogRef.afterClosed().pipe(filter((result: boolean) => {
        return result;
      }), mergeMap((result) => {
        const data = {
          isActive: false,
        }
        if (result) {
          this.pageDetails.isLoader = true;
          return this.usersService.updateUserDetailStatus(data, event.data.id);
        }
      })).subscribe((res) => {
        if (res) {
          this.pageDetails.isLoader = false;
          this.dialogService.dialogMethod(this.addEditUserConstant.dialogMessages.deactivateSuccess,
            this.addEditUserConstant.dialogType.success, true)
          this.commonService.searchField.setValue(null);
          this.pageDetails.searchData = null;
          this.getAllusersList(0, 2 * this.paginationDetails.pageSize, true, this.pageDetails.searchData, this.pageDetails.filterData);
        }
      }, (err) => {
        if (err) {
          this.pageDetails.isLoader = false;
          this.dialogService.dialogMethod(this.addEditUserConstant.dialogMessages.failedToLoad,
            this.addEditUserConstant.dialogType.failure);
        }
      }));
    }
  }

  /**
  * To activate the user
  */
  onActive(event: any) {
    if (event && event.data && event.data.id) {
      const dialogRef = this.dialogService.dialogMethod(this.addEditUserConstant.dialogMessages.ActivateUserdetails,
        this.addEditUserConstant.dialogType.confirmation, true);
      this.subscriptionObj.add(dialogRef.afterClosed().pipe(filter((result: boolean) => {
        return result;
      }), mergeMap((result) => {
        const data = {
          isActive: true,
        }
        if (result) {
          this.pageDetails.isLoader = true;
          return this.usersService.updateUserDetailStatus(data, event.data.id);
        }
      })).subscribe((res) => {
        if (res) {
          this.pageDetails.isLoader = false;
          this.dialogService.dialogMethod(this.addEditUserConstant.dialogMessages.activateSuccess,
            this.addEditUserConstant.dialogType.success, true)
          this.commonService.searchField.setValue(null);
          this.pageDetails.searchData = null;
          this.getAllusersList(0, 2 * this.paginationDetails.pageSize, true, this.pageDetails.searchData, this.pageDetails.filterData);
        }
      }, (err) => {
        if (err) {
          this.pageDetails.isLoader = false;
          this.dialogService.dialogMethod(this.addEditUserConstant.dialogMessages.failedToLoad,
            this.addEditUserConstant.dialogType.failure);
        }
      }));
    }
  }


  /**
  * Function which is used to suspended or resume the user
  */
  onSuspendResume(event: any) {
    if (event) {
      const data = event.isSuspended == false ? {
        suspend: true,
      } : {
        suspend: false,
      };
      this.subscriptionObj.add(this.usersService.getSuspendResumeUser(data, event.id).subscribe((res: any) => {
        if (res) {
          event.isSuspended == true ? this.dialogService.dialogMethod(this.addEditUserConstant.dialogMessages.resumeUser, this.addEditUserConstant.dialogType.success, true) : this.dialogService.dialogMethod(this.addEditUserConstant.dialogMessages.suspendUserSuccess, this.addEditUserConstant.dialogType.success, true);
          this.commonService.searchField.setValue(null);
          this.pageDetails.searchData = null;
          this.getAllusersList(0, 2 * this.paginationDetails.pageSize, true, this.pageDetails.searchData, this.pageDetails.filterData);
        }
      }, err => {
        this.pageDetails.isLoader = false;
        event.isSuspended == true ? this.dialogService.dialogMethod(this.addEditUserConstant.dialogMessages.failResumed, this.addEditUserConstant.dialogType.success, true) : this.dialogService.dialogMethod(this.addEditUserConstant.dialogMessages.failSuspend, this.addEditUserConstant.dialogType.failure, true);
      }))
    }
  }

  /**
   * Method which is triggered function while action button click
   * @param event To get method name to call
   */
  listAction(event: any): void {
    if (event === 'Filter')
      this.fieldForFilter();
    else if (event.method) {
      this[event.method](event);
    }
    else if (event && event.eventData && event.eventData.values) {
      this[event.eventData.values](event.rowData);
    }
  }


  /**
  * Method which is used to show filter drawer.
  */
  fieldForFilter() {
    this.pageDetails.isFilter = !this.pageDetails.isFilter;
    if (!this.pageDetails.isFilter && this.pageDetails.filterData) {
      this.closeFilter();
    }
  }

  /**
  * Function which is triggered while close the filter 
  */
  closeFilter(): void {
    this.commonService.searchField.setValue(null);
    this.pageDetails.isFilter = false;
    this.pageDetails.filterData = null;
    this.pageDetails.searchData = null;
    this.getAllusersList(0, 2 * this.paginationDetails.pageSize, true, this.pageDetails.searchData, this.pageDetails.filterData);
  }


  /**
   * Method to filter list
   * @param event which holds the current event
   */
  onFilterSelected(event: any) {
    this.pageDetails.filterData = event;
    this.getAllusersList(0, 2 * this.paginationDetails.pageSize, true, this.pageDetails.searchData, this.pageDetails.filterData);
  }


  /**
  * Method which is used to get the pagination changes
  * @param event which holds the event data
  */
  getChangedData(event: any) {
    if (event) {
      this.paginationDetails.offset = event.offset;
      this.paginationDetails.limit = event.limit;
      this.getAllusersList(this.paginationDetails.offset, this.paginationDetails.limit, false, this.pageDetails.searchData, this.pageDetails.filterData);
    }
  }

  /**
   * Method used to paginate the user list
   * @param event to get event
   */
  getPaginatorData(event: any) {
    if (event) {
      this.paginationDetails.limit = event.pageSize;
      this.paginationDetails.offset = event.pageIndex * event.pageSize;
    }
  }


  /**
   * Method used to add user details
   */
  addUser() {
    this.router.navigate(['app/addedituser']);
  }

  /**
  * Method used to trigger the function.
  * @param event method name
  * @type {string}
  */
  buttonAction(event: any) {
    if (event) {
      this[event]();
    }
  }


  /**
  * To redirect to add edit user component
  * @param tableData To store the table data
  */
  onEdit(tableData: any): void {
    if (tableData && tableData.data && tableData.data.id) {
      const data: any = this.commonDataService.setParam(tableData.data.id);
      this.router.navigate(['app/addedituser', data]);
    }
  }

  /**
   * Method used to search user list
   * @param id to get id
   */
  search(event) {
    this.pageDetails.searchData = event;
    this.getAllusersList(0, 2 * this.paginationDetails.pageSize, true, this.pageDetails.searchData, this.pageDetails.filterData);
  }

  /**
  * Method used to show account details in user list
  */
  OnViewAccountDetails(event: any): void {
    const data = {
      "isActive": true,
    }
    this.subscriptionObj.add(this.usersService.getAccountDetails(event && event.data && event.data.id, data).subscribe((res: any) => {
      if (res && res.result !== 'null') {
        this.pageDetails.isLoader = false;
        this.accountDetails = this.commonDataService.decryptDetails(res.result);
        this.value = JSON.parse(this.accountDetails);
        if (this.value) {
          this.dialog.open(this.viewAccountDetails, {
            autoFocus: false,
            disableClose: true,
            width: '550px',
          });
        }
        else {
          const dialogRef = this.dialogService.dialogMethod(this.addEditUserConstant.dialogMessages.noAccountDetails, this.addEditUserConstant.dialogType.alert, true);
        }
      }
    }, err => {
      this.pageDetails.isLoader = false;
      this.dialogService.dialogMethod(this.addEditUserConstant.dialogMessages.failedToLoad, this.addEditUserConstant.dialogType.failure, true)
    }
    ))

  }

  /**
  * To redirect to customersList component
  */
  viewCustomersDetails(event) {
    if (event && event.data && event.data.id) {
      const data: any = this.commonDataService.setParam(event.data.id);
      this.router.navigate(['app/referral-customer-list', data], { queryParams: { userCommission: true } });
    }
  }


  /**
  * Component ondestroy life cycle hook.
  * All subscriptions are unsubscribe here.
  */
  ngOnDestroy(): void {
    this.paginationService.listPaginatorData.next(null);
    this.subscriptionObj.unsubscribe();
    this.commonService.searchSubject.next(null);
  }
}
