import { Injectable } from '@angular/core';
import { API } from 'libs/common/src/lib/constants/api-routes';
import { HttpRoutingService } from 'libs/common/src/lib/services/httpRouting.service';

@Injectable({
  providedIn: 'root'
})
export class AccountDetailsService {

  constructor(private httpservice: HttpRoutingService) { }

  /**
 * Method which is used to get details to show account list.
 * @param data holds data.
 * @returns http response.
 */
  getAccountDetails(data?: any) {
    return this.httpservice.getMethod(API.GET_ALL_ACCOUNT_DETAILS, data);

  }

  /**
 * Method which is used to create account details.
 * @param data holds data.
 * @returns http response.
 */
  createAccountDetails(data?: any) {
    return this.httpservice.postMethod(API.CREATE_ACCOUNT_DETAILS, data);
  }

  /**
  * Method which is used to delete account details.
  * @param customerId holds data.
  * @returns http response.
  */
  deleteAccountDetails(customerId) {
    const url = this.httpservice.replaceUrlParam(API.AFFILIATE_ACCOUNT_DETAILS, { id: customerId });
    return this.httpservice.deleteMethod(url);
  }

  /**
* Method which is used to get single account detail while editing.
* @param customerId holds data.
* @returns http response.
*/
  getOneDetails(customerId: any) {
    const url = this.httpservice.replaceUrlParam(API.AFFILIATE_ACCOUNT_DETAILS, { id: customerId });
    return this.httpservice.getMethod(url);
  }

  /**
* Method which is used to update account details after edit.
* @param customerId holds data.
* @returns http response.
*/
  updateAccountDetails(customerId: any, data: any) {
    const url = this.httpservice.replaceUrlParam(API.AFFILIATE_ACCOUNT_DETAILS, { id: customerId });
    return this.httpservice.putMethod(url, data);
  }

}
