import { Injectable } from '@angular/core';
import { AuthService } from 'libs/auth/src/lib/services/auth.service';
import { HttpRoutingService } from './httpRouting.service';
import { API } from './../constants/api-routes';
/**
 * This service used to perform CRUD operations for menus.
 */
@Injectable()
export class MenuService {
  /**
   * A common variable used to store and proccess menus
   */
  menuList;
  // isReadOnly: boolean;
  // isReadOnly = new BehaviorSubject<any>(null);
  /**
   * Constructor for inject required services.
   * @param httpService this service is used for request & response of backend api
   * @param authService this service is used to access common functions & objects.
   */
  constructor(private httpService: HttpRoutingService, private authService: AuthService) {
  }



  /**
  * Method which is used to get all menu details.
  *  @param storeid unique store id
  *  @param isAdmin boolean for getting all menu categories
  *  @param env envirnment
  *  @param uuid unique uuid for getting featured count
  * @returns Returns menu details.
  */
  getMenuDetails(storeid?, isAdmin?, env?, uuid?) {
    const storeId = storeid ? storeid : 'DEFAULT';
    // if (env) {
    //   this.setHeadersForSubscriptionLambda("menus/" + storeId, env);
    // }
    if (isAdmin) {
      return this.httpService.getMethod('stores/' + storeId + '/menus' + '?isAdmin=' + isAdmin);
    } else {
      if (uuid)
        return this.httpService.getMethod('stores/' + storeId + '/menus', { uuid: uuid ? uuid : 'DEFAULT' });
      else
        return this.httpService.getMethod('stores/' + storeId + '/menus');

    }
  }
  /**
   * this method used to get all menu category for plan creation
   * @returns 
   */
  getMenuForAdmin() {
    return this.httpService.getMethod('stores/'+'DEFAULT'+'/menus?isAdmin=' + true);
  }
  /**
   * Method which is used to get cart details
   * @param storeId unique store id
   * @param zoneId unique zone id
   * @param cartProducts products added in cart
   */
  getCartProducts(storeId, zoneId, cartProducts) {
    // /store/:storeId/zone/:zoneId/cartdiscounts
    return this.httpService.postMethod('store/' + storeId + 'zone/' + zoneId + '/cartdiscounts', cartProducts);
  }
  /**
   * this method used to check weather the navigating url can be accessed by the current user.
   * @param rawUrl currently navigating url
   * @param params paramters in current url
   * @param querParams query parameters in current url
   * @returns 
   */
  checkPermissions(rawUrl, params, querParams) {
    let permission = false;
    // console.log("paramsssssssss", querParams, params, Object.keys(querParams).length);
    // if (url.indexOf('%') !== -1) {

    //   const sub = String(url.substring(url.indexOf('%'), url.indexOf('%') + 3));

    //   url = url &&url.toString() && url.toString().replaceAll(sub, ' ');
    // }
    // console.log("url..........b......",url,typeof url);

    let url= decodeURIComponent(rawUrl);
    // console.log("url.............a...",url,typeof url);
    if (this.menuList && this.menuList.menus) {
      this.menuList.menus.forEach(element => {
        let both = true, isParams = true,
          changedLink = element.subMenu && element.subMenu.length === 0 ? element.pageCustomLink : null;
        if (Object.keys(params).length > 0 || Object.keys(querParams).length > 0) {
          for (const proms in params) {
            if (proms && element.subMenu && element.subMenu.length === 0) {
              if (isParams) {
                isParams = false;
                changedLink =element.pageCustomLink &&  element.pageCustomLink.replace(':' + proms, params[proms]);
                // console.log("paramsssssssss....1", changedLink);
              }
              else {
                changedLink =changedLink && changedLink.replace(':' + proms, params[proms]);

              }

            }
          }
          // } 
          // else if (Object.keys(querParams).length > 0) {
          // console.log("paramsssssssss..................");
          for (const qparams in querParams) {
            if (qparams && element.subMenu && element.subMenu.length === 0) {
              if (both) {
                both = false;
                changedLink = changedLink + '?' + qparams + '=' + querParams[qparams];
                // console.log("paramsssssssss...2", changedLink);
              }
              else {
                changedLink = changedLink + '&' + qparams + '=' + querParams[qparams];
              }
            }
          }
        }
        else {
          if (element.pageCustomLink && (element.pageCustomLink.includes(':') ||
            url.includes('?'))) {
            const urlSplitData = url.split('/');
            const dbUrlSplit = element.pageCustomLink.split('/');
            if (urlSplitData.length === dbUrlSplit.length) {
              if (element.pageCustomLink.includes(':')) {

                dbUrlSplit.find((data, index) => {
                  if (data.includes(':')) {
                    dbUrlSplit[index] = urlSplitData[index];
                  }
                });
                changedLink = dbUrlSplit.join('/');
              } else if (url.includes('?')) {
                changedLink = url.split('?')[0];
              }

            }
          }
        }
        if (element.subMenu && element.subMenu.length) {
          for (let i = 0; i < element.subMenu.length; i++) {
            if (element.subMenu[i].pageCustomLink === url && !(element.subMenu[i].hasOwnProperty('notOnlyTopNav'))) {
              // console.log("in submenu...");
              if (element.subMenu[i].isReadOnly)
                this.authService.isReadOnly.next(element.subMenu[i].isReadOnly);
              else
                this.authService.isReadOnly.next(false);
              permission = true;
            }
          }
        }
        else if (changedLink === url && !(element.hasOwnProperty('notOnlyTopNav'))) {
          // console.log("not submenu...");
          if (element.isReadOnly)
            this.authService.isReadOnly.next(element.isReadOnly);
          else
            this.authService.isReadOnly.next(false);


          permission = true;
        }
          // console.log("changedLink...",changedLink,"url,,,,,,,,,,,,,,,,,",url);

      });
      // this.authService.isReadOnly.subscribe((res) => {
      //    console.log("isReadonly.........1....", res,permission);
      // })
      // console.log("isReadonly..........2...", this.isReadOnly);

      return permission;
    }
  }
  /**
   * this method used for subscription  plan creation
   * @param data plan details
   * @returns 
   */
  createPlanCategory(data) {
    
    return this.httpService.postMethod(API.PLAN_CATEGORY, data);
  }
  /**
   * this method used to update existing subscription  plan
   * @param data plan details
   * @returns 
   */
  updatePlanCategory(data) {
    return this.httpService.putMethod(API.PLAN_CATEGORY, data);
  }
  /**
   * this method used to fetch details of a subscription  plan
   * @param planId unique subscription plan id
   * @returns 
   */
  getOnePlanCategory(planId: any) {
    const url = this.httpService.replaceUrlParam(API.GET_PLAN_CATEGORY, { planId: planId })
    return this.httpService.getMethod(url);
  }
    /**
   * this method used to get all limits based on plans
   * @returns 
   */
    getLimitsAdmin() {
      return this.httpService.getMethod(API.GET_STORE_LIMTS);
    }
}
