/**
 * Component which is used to list payout request
 * Author: Rajeswari P
 */
import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ActionIcon, ColumnsRef,  CommonDataService,  Heading, ListProperties, PaginationService } from '@phase-ii/shared';
import { payoutRequestConstant } from '../../constants/payout-request-constant';
import { Subscription } from 'rxjs';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { AccountDetails, DecryptedAccountDetails, EventDetails, FilterData, OnpaymentCompleteDetails, PayoutStatusDetails, RequestDetails, StatusData, UpdatePayoutRequest } from '../../models/payout-request.model';
import { DialogService } from '@phase-ii/common';
import { PayoutRequestService } from '../../services/payout-request.service';
import { UsersService } from '../../../users/services/users.service';
@Component({
  selector: 'phase-ii-payout-request-list',
  templateUrl: './payout-request-list.component.html',
  styleUrls: ['./payout-request-list.component.scss'],
})
export class PayoutRequestListComponent implements OnInit {
  /**
 * Variable used to store details regarding the payout request
 * @type {Object}
 */
  requestDetails: {
    heading: Heading,
    listProperties: ListProperties,
    columnsRef: ColumnsRef[],
    actionIcon: ActionIcon[],
    searchText: string,
    status: Array<StatusData>,
    id: number,
    isLoader: boolean,
    actions: any,
    accountDetails: DecryptedAccountDetails
  } = {
      heading: null,
      listProperties: {
        globalSearch: true,
        columns: 4,
        rowElementClicked: false,
        isLocalPagination: false,
        serverSidePagination: true,
        disableLocalSearch: false,
        searchPlaceHolder: 'Search by user name',
        localSearchFields: ['affiliateUser.name'],
        isNewImage: {
          isNew: false,
          url: './assets/no-payout-request.png',
          text: 'No payout request found'
        },
        cardBorderClass: 'greenBorder',
        cardBorderCondition: {
          field: 'status',
          condition: {
            'Yet to Begin': "pendingBorder",
            'Payment Initiated': "purpleBorder",
            'Payment Completed': "greenBorder"
          }
        }
      },
      columnsRef: null,
      actionIcon: [],
      searchText: null,
      status: [],
      id: null,
      isLoader: false,
      actions: null,
      accountDetails: null
    }

  /**
    * Variable which is used to view the actions in dialog
    * @type {any}
    */
  dialogRef: any;
  /**
    * Variable which is used to check whether the payment is completed or not
    * @type {any}
    */
  isPaymentCompleted: boolean;
  /**
   * variable used to store const datas
   */
  constant = payoutRequestConstant;
  /**
   * Variable which is used to add transaction id, description fields to the form
   * @type {any}
   */
  isPaymentForm: boolean;
  /**
  * Variable used to check whether the loader is enables or not
  * @type {any}
  */
  isButtonLoader: boolean;
  /**
* To open the dialog box for showing the log details.
* @type {ViewChild}
*/
  @ViewChild('viewAccountDetails', { static: false }) viewAccountDetails: TemplateRef<any>;

  /**
  * Variable which is used to list the products in viewchild properties
  * @type {TemplateRef}
 */
  @ViewChild('requestStatus', { static: true }) requestStatus: TemplateRef<any>;
  /**
   * Variable which is used to check filter is clicked or not.
  */
  isFilter: boolean;
  /**
 * Variable which is used to store filter values.
*/
  FilterData: FilterData;
  /**
* Declaring form group
* @type {FormGroup}
*/
  requestForm: UntypedFormGroup;
  /**
   * Variable which is used to subscribe and unsubscribe the subscriptions.
   * @type {Subscription}
   */
  subscriptionObject: Subscription = new Subscription();
  /**
* Variable which is used to store the status for filter.
*/
  statusFilter = [];
  /**
* Variable which is used to store the values for filter.
*/
  filterValues = [];
  /**
   * Variable which is used to store pagination Details.
   */
  paginationDetails: {
    pageSize: number,
  } = {
      pageSize: payoutRequestConstant.PAGINATOR.DEFAULT_PAGE_SIZE
    };
  /**
* component constructor which is used to inject the required services and initialise the variables.
*  @param PayoutRequestService To get and use the functions in payoutRequestService.
*  @param PaginationService To get and use the functions in paginationService.
*  @param {MatDialog} dialog - Angular Material dialog for UI interaction.
*  @param DialogService To get and use the functions in dialogService.
*  @param UsersService To get and use the functions in usersService.
*  @param CommonDataService To get and use the functions commonDataService.
*/
  constructor(
    private payoutRequestService: PayoutRequestService,
    private paginationService: PaginationService,
    private dialog: MatDialog,
    private dialogService: DialogService,
    private usersService: UsersService,
    private commonDataService: CommonDataService,
  ) {
  }
  /*
 * Component OnInit life cycle hook 
 */
  ngOnInit(): void {
    this.requestDetails.heading = {
      title: payoutRequestConstant.HEADING.TITLE,
      description: payoutRequestConstant.HEADING.DESCRIPTION
    };
    this.requestDetails.columnsRef = payoutRequestConstant.PAYOUT_REQUEST_COLUMNS;
    this.requestDetails.actionIcon = [{ iconName: 'edit', method: 'onEdit', toolTip: 'Edit Payment Status', iconColor: '#03c04a' },
    { iconName: 'visibility', method: 'OnViewAccountDetails', toolTip: 'View Account Details', iconColor: '#068eef' }];
    this.requestDetails.actions =
      [
        { name: 'filter_list', value: 'filter', toolTip: "Filter", isConditionApplied: true, conditionType: 'DATA_EMPTY' }
      ];
    this.subscriptionObject.add(this.payoutRequestService.getAllPayoutStatus().subscribe({next: (res: PayoutStatusDetails) => {
        if (res) {
          this.requestDetails.status = res.result && res.result.propertyValues;
          res.result.propertyValues.forEach((element: StatusData) => {
            this.filterValues.push(element && element.value);
          });
      }
        else {
          this.dialogService.dialogMethod(payoutRequestConstant.DIALOG_MESSAGES.PAYMENT_STATUS_ERROR, payoutRequestConstant.DIALOG_TYPE.FAILURE, true);
      }
      },
      error: (error) => {
        this.dialogService.dialogMethod(payoutRequestConstant.DIALOG_MESSAGES.PAYMENT_STATUS_ERROR, payoutRequestConstant.DIALOG_TYPE.FAILURE, true);
      }
    }));
    this.getAllRequestDetails(0, 2 * this.paginationDetails.pageSize, true, this.requestDetails.searchText, this.FilterData);
  }
  /**
 * Method which is used to get payout request List details.
 */
  getAllRequestDetails(offset: number, limit: number, isNew: boolean, searchText?: string, filterData?: FilterData):void {
    this.requestDetails.isLoader = true;
    const data = {
      offset: offset ? offset : 0,
      limit: limit ? limit : 5,
      searchText: searchText ? searchText : '',
      filterData: filterData ? JSON.stringify(filterData) : null
    };
    this.subscriptionObject.add(this.payoutRequestService.getAllpayoutRequest(data).subscribe({
      next: (res: RequestDetails) => {
        if (res && res.payoutLogDetails && res.payoutLogDetails.rows) {
          this.requestDetails.isLoader = false;
          if (!this.requestDetails.searchText && !this.FilterData)
            this.requestDetails.listProperties.isNewImage.isNew = res.payoutLogDetails.rows.length == 0 ? true : false;
          this.paginationService.listPaginatorData.next(
            {
              new: isNew,
              rows: res.payoutLogDetails.rows,
              count: res.payoutLogDetails.count,
            });
        }
        else {
          this.dialogService.dialogMethod(payoutRequestConstant.DIALOG_MESSAGES.FAILED, payoutRequestConstant.DIALOG_TYPE.FAILURE, true);
          this.requestDetails.isLoader = false;
        }
      },
      error: (error) => {
        this.dialogService.dialogMethod(payoutRequestConstant.DIALOG_MESSAGES.FAILED, payoutRequestConstant.DIALOG_TYPE.FAILURE, true);
        this.requestDetails.isLoader = false;      }
    }));
  }
  /**
* Method which is used to get changed page data.
* @param event To get offset and limit.
* @type {Object}
*/
  getChangedData(event: any): void {
    if (event) {
      this.getAllRequestDetails(event.offset, event.limit, false, this.requestDetails.searchText, this.FilterData);
    }
  }
  /**
  * Method which is used to show filter drawer.
  */
  filter(): void {
    this.isFilter = !this.isFilter;
    this.statusFilter = [
      { title: "Status", type: "radio", field: "status", options: this.filterValues },
    ];
    if (!this.isFilter && this.FilterData) {
      this.onFilterClose();
    }
  }
  /**
 * Method used to show output of fliter component.
 * @param event To get the selected event
 */
  onFilterSelected(event: FilterData): void {
    this.FilterData = event;
    this.getAllRequestDetails(0, 2 * this.paginationDetails.pageSize, true, this.requestDetails.searchText, this.FilterData)
  }
  /**
* Method which is used to close filter
*/
  onFilterClose(): void {
    this.FilterData = null;
    this.isFilter = false;
    this.getAllRequestDetails(0, 2 * this.paginationDetails.pageSize, true, this.requestDetails.searchText, this.FilterData);
  }
  /**
* @param event id of selected row
* method for action button click
*/
  listAction(event: EventDetails): void {
    if (event && event.method) {
      this[event.method](event);
    }
  }
  /**
 * Method which is used to action for the list.
 */
  cardActions(event: string): void {
    if (event) {
      this[event]();
    }
  }
  /**
  * Method which is used for update frequency
  */
  onEdit(event: EventDetails):void {  
    if (event && event.data && event.data.id) {
      this.requestDetails.id = event.data.id;
      this.requestDetails.status.forEach(element => {
        if (event.data.status ==='Payment Initiated') {
          element['disableStatus'] = element && element.value == 'Yet to Begin' ? true :false;
        }
        else if (event.data.status === "Payment Completed") {
          this.isPaymentForm = true;
          element['disableStatus'] = ['Yet to Begin', 'Payment Initiated'].includes(element && element.value);
        }
        else {
          element['disableStatus'] = false;
        }
      });
      this.formInitialize(event);
      this.dialogRef = this.dialog.open(this.requestStatus, { disableClose: true, width: '450px' });
    }
  }
  /**
* Method which is used to initialize form.
*/
  formInitialize(value?: EventDetails): void {
    const statusId = this.requestDetails.status.find(ele => ele.value == value.data.status);
    this.requestForm = new UntypedFormGroup({
      userName: new UntypedFormControl(value && value.data && value.data.affiliateUser && value.data.affiliateUser.name),
      status: new UntypedFormControl(statusId)
    });
    if (this.isPaymentForm) {
      this.onpaymentComplete(value);
    }
  }

  /**
  * Method which is used for  Payment Completed
  */
  onpaymentComplete(data: OnpaymentCompleteDetails): void {
    if (data && data.value && data.value.value === 'Payment Completed' || this.isPaymentForm == true) {
      this.isPaymentCompleted = true;
      this.requestForm.addControl('transactionId', new UntypedFormControl(data && data.data && data.data.transactionId ? data.data.transactionId : null, [Validators.required, Validators.minLength(10), Validators.maxLength(30)]));
      this.requestForm.addControl('description', new UntypedFormControl(data && data.data && data.data.description ? data.data.description : null, Validators.maxLength(50)));
    }
    else {
      this.isPaymentCompleted = false;
      this.requestForm.removeControl('transactionId');
      this.requestForm.removeControl('description');
    }
  }
  /**
* Function used to save payout request status detail
*/
  onSave():void {
    this.isButtonLoader = true;
    if (this.requestForm && this.requestForm.valid && !this.requestForm.pristine) {
      let data = {
        status: this.requestForm.value.status.id
      }
      if (this.isPaymentCompleted) {
        data = Object.assign(data, {
          transactionId: this.requestForm.value.transactionId,
          description: this.requestForm.value.description
        })
      }
      this.subscriptionObject.add(this.payoutRequestService.updatePayoutRequest(data, this.requestDetails.id).subscribe({
        next: (res: UpdatePayoutRequest) => {
          if (res && res.success) {
            this.dialogService.openDialog({
              header: payoutRequestConstant.DIALOG_HEADER.SUCCESS,
              message: payoutRequestConstant.DIALOG_MESSAGES.UPDATE_SUCCESS,
              actionType: payoutRequestConstant.DIALOG_TYPE.SUCCESS,
              button: { right: "Okay" },
              disableClose: true
            });
            this.isButtonLoader = false;
            this.requestForm.reset();
            this.dialogRef.close();
            this.isPaymentCompleted = false;
            this.isPaymentForm = false;
            this.getAllRequestDetails(0, 2 * this.paginationDetails.pageSize, true, this.requestDetails.searchText, this.FilterData);
          }
          else {
            this.isButtonLoader = false;
            this.dialogService.dialogMethod(payoutRequestConstant.DIALOG_MESSAGES.UPDATE_FAILED, payoutRequestConstant.DIALOG_TYPE.FAILURE, true);
          }
        },
        error: (error) => {
          this.isButtonLoader = false;
          this.dialogService.dialogMethod(payoutRequestConstant.DIALOG_MESSAGES.UPDATE_FAILED, payoutRequestConstant.DIALOG_TYPE.FAILURE, true);        }
      }));
    }
    else {
      if (this.requestForm && !(this.requestForm.valid)) {
        this.dialogService.dialogMethod(payoutRequestConstant.DIALOG_MESSAGES.MANDATORY_FIELDS, payoutRequestConstant.DIALOG_TYPE.ALERT, true);
        this.requestForm.markAllAsTouched();
      }
      else {
        this.dialogService.dialogMethod(payoutRequestConstant.DIALOG_MESSAGES.NO_CHANGE_ALERT, payoutRequestConstant.DIALOG_TYPE.ALERT, true);
      }
      this.isButtonLoader = false;
    }
  }
  /**
* Method which is used for close the dialog box and reset the form.
*/
  onClose():void {
    if (!this.requestForm.pristine) {
      const dialogRef1 = this.dialogService.openDialog({
        header: payoutRequestConstant.DIALOG_HEADER.CONFIRMATION,
        message: payoutRequestConstant.DIALOG_MESSAGES.UNSAVED_DIALOG_CLOSE,
        actionType: payoutRequestConstant.DIALOG_TYPE.CONFIRMATION,
        button: { right: "Yes", left: "No" },
        disableClose: true
      });
      this.subscriptionObject.add(
        dialogRef1.afterClosed().subscribe((result: boolean) => {
          if (result) {
            this.requestForm.reset();
            this.dialogRef.close();
            this.isPaymentCompleted = false;
            this.isPaymentForm = false;
          }
        })
      );
    }
    else {
      this.requestForm.reset();
      this.dialogRef.close();
      this.isPaymentCompleted = false;
      this.isPaymentForm = false;
    }
  }
  /**
 * Method which is used to change records based on search result.
 */
  search(event: string): void {
    this.requestDetails.searchText = event.trim();
    this.getAllRequestDetails(0, 2 * this.paginationDetails.pageSize, false, this.requestDetails.searchText, this.FilterData);
  }
  /**
  * Method used to show account details in user list
  */
  OnViewAccountDetails(event: EventDetails): void {
      this.subscriptionObject.add(this.usersService.getAccountDetails(event.data.userId, {"isActive": true}).subscribe({
      next: (res: AccountDetails) => {
        if (res && res.result) {
          const data = this.commonDataService.decryptDetails(res.result);
          this.requestDetails.accountDetails = JSON.parse(data);
          this.dialog.open(this.viewAccountDetails, {
            autoFocus: false,
            disableClose: true,
            width: '550px',
          });
        }
        else {
          this.dialogService.dialogMethod(payoutRequestConstant.DIALOG_MESSAGES.NO_ACCOUNT, payoutRequestConstant.DIALOG_TYPE.ALERT, true);
        }
      },
      error: (error) => {
        this.dialogService.dialogMethod(payoutRequestConstant.DIALOG_MESSAGES.ACCOUNT_FAILED, payoutRequestConstant.DIALOG_TYPE.FAILURE, true)
      }
    }));
  }

  /**
* Method used for unsubscribe the request
*/
  ngOnDestroy(): void {
    this.subscriptionObject.unsubscribe();
    this.paginationService.listPaginatorData.next(null);
  }
}
