/**
* Author : kirthick vijay.R
* Component used for mobile subscription plan's payment
*/
import { Component, OnDestroy, OnInit } from '@angular/core';
import { CommonConstants } from 'libs/shared/src/lib/constants/shared-constant';
import { forkJoin, of, Subscription } from 'rxjs';
import { AuthService } from '@phase-ii/auth';
import { CommonService } from 'libs/shared/src/lib/services/common.service';
import { CommonDataService } from 'libs/shared/src/lib/services/common-data.service';
import { mergeMap } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { DialogService } from '@phase-ii/common';
import { ButtonInfo, Heading } from '@phase-ii/shared';
import { currentMobilePlanDetails, storeadminSettingsDetails, mobilePlanSettings, paramsDetail, generateMobileDetails, setThemeDetails } from '../../models/mobile-plan-payment.model';

@Component({
  selector: 'phase-ii-mobile-plan-payment',
  templateUrl: './mobile-plan-payment.component.html',
  styleUrls: ['./mobile-plan-payment.component.scss'],
})
export class MobilePlanPaymentComponent extends CommonConstants implements OnInit, OnDestroy {
  /**
   * Array used to push all the subscriptions for subscribe and unsubscribe
   * @type {Array}
   */
  subscriptionObj = new Subscription();
  /**
   * Variable used to store storeid
   */
  storeId: number;
  /**
  * Variable used to store the mobile plan details for payment
  */
  pageDetails: {
    isLoading: boolean,
    buttonInfo: ButtonInfo[],
    heading: Heading,
    selectedPlanDetails: any,
    showPayment: boolean,
    isUpgrade: boolean,
    clientId: string,
    mobileThemeCode: string,
    themeCode: string,
    isGenerateSuccess :boolean,
    storeName:string,
    isTestAccount:boolean
  } = {
      isLoading: false,
      buttonInfo: null,
      heading: null,
      selectedPlanDetails: {},
      showPayment: false,
      isUpgrade: false,
      clientId: null,
      mobileThemeCode: null,
      themeCode: null,
      isGenerateSuccess:false,
      storeName:null,
      isTestAccount:false
    };
  /**
  * Component constructor to inject the required services.
  * @param commonService variable to access all the API calls within the common service
  * @param commonDataService variable to access the functions for accessing the get/set params function
  * @param authService to get the user details
  * @param dialogService variable to open and close the dialog box
  * @param router variable to store the function to navigate to other pages
  * @param dialogService this service is used for dialog box display
  * @param authService variable to access all the functions of the auth service
  */
  constructor(
    private commonService: CommonService,
    private commonDataService: CommonDataService,
    private authService: AuthService,
    private route: ActivatedRoute,
    private router: Router,
    private dialogService: DialogService) {
    super();
  }
  /**
  * Angular life cycle begins
  * @type {void}
  */
  ngOnInit(): void {
    this.pageDetails.isLoading = true;
    this.pageDetails.selectedPlanDetails['isMobilePage'] = true;
    this.pageDetails.heading = {
      title: "Mobile Plans",
      description: "Purchase your plan here"
    };
    this.pageDetails.buttonInfo = [
      {
        name: 'Cancel', class: 'secondary-button', method: 'navigateToMemberPage'
      },
    ];
    this.subscriptionObj.add(this.authService.user.pipe(mergeMap((res: any) => {
      this.pageDetails.selectedPlanDetails['isSuperAdmin'] = (res && res.storeId === null) ? true : false;
      this.pageDetails.isUpgrade = res && res.store && res.store.isMobileApp ? res.store.isMobileApp : false;
      this.pageDetails.clientId = res && res.clientId;
      this.pageDetails.mobileThemeCode = res && res.store && res.store.mobileThemeCode;
      this.pageDetails.storeName=res&&res.store&&res.store.name;
      this.pageDetails.isTestAccount=res&&res.store&&res.store.isTestAccount;
      this.storeId = res && res.storeId;
      return this.route.params;
    }), mergeMap((res: paramsDetail) => {
      if (res && res.storeId) {
        this.pageDetails.selectedPlanDetails['storeId'] = res.storeId ? res.storeId : null;
        return forkJoin([this.authService.getMobilePlanSettings(this.pageDetails.selectedPlanDetails['storeId']),
        this.commonService.getStoreadminSettings('DEFAULT_MOBILE_THEME')
        ]);
      }
      return of(null);
    }), mergeMap((res: [mobilePlanSettings, storeadminSettingsDetails]) => {
      if (res && res.length) {
        if (res[0] as mobilePlanSettings && res[0].detail) {
          this.pageDetails.selectedPlanDetails = { ...this.pageDetails.selectedPlanDetails, ...res[0].detail };
        }
        if (res[1] as storeadminSettingsDetails) {
          const decryptValue = res[1].details && res[1].details.value ? JSON.parse(this.authService.decryptionCode(res[1].details.value)) : null;
          this.pageDetails.themeCode = decryptValue && decryptValue[0] && decryptValue[0].valueField ? decryptValue[0].valueField : null;
        }
        return this.authService.getCurrentMobilePlan(this.pageDetails.clientId);
      }
      else {
        this.pageDetails.isLoading = false;
        this.navigateToMemberPage();
        this.dialogService.dialogMethod('Failed to load plan details', this.dialogType.failure, true);
      }
      return of(null);
    })
    ).subscribe((res: currentMobilePlanDetails) => {
      if (res && res.activePlan && res.activePlan && res.activePlan.result && res.activePlan.result.length < 2) {
        this.pageDetails.isLoading = false;
      } else {
        this.pageDetails.isLoading = false;
        this.dialogService.dialogMethod(this.dialogMessages.alreadyRespond, this.dialogType.alert, true);
        this.router.navigate(['/signin']);
      }
    }, (err) => {
      if (err) {
        this.pageDetails.isLoading = false;
        this.dialogService.dialogMethod('Failed to load plan details', this.dialogType.failure, true);
        this.navigateToMemberPage();
      }
    }));
  }
  /**
  * Method which is used to save mobile plan
  * @param event 
  */
  savePlan(event): void {
    this.pageDetails.isLoading = true;
    this.pageDetails.showPayment = false;
    const data={
      data: event,
      mailContent: {
        PlanName:this.pageDetails.selectedPlanDetails&& this.pageDetails.selectedPlanDetails.PlanName,
        FrequencyType: this.pageDetails.selectedPlanDetails && this.pageDetails.selectedPlanDetails.FrequencyType,
        storeName: this.pageDetails.storeName,
        isTestAccount: this.pageDetails.isTestAccount
      }
    }
    const newSubscriptionData = this.commonDataService.encryptDetails(JSON.stringify( data ));
    this.commonService.generateMobileSubscriptionDetails({ data: newSubscriptionData, storeId: this.pageDetails.selectedPlanDetails.storeId }).pipe(mergeMap((res: generateMobileDetails ) => {
      if (res && res.success) {
        this.dialogService.dialogMethod('Plan chosen successfully!', this.dialogType.success, true);
        if (this.pageDetails.mobileThemeCode == null && this.pageDetails.themeCode) {
          this.pageDetails.isGenerateSuccess = true;
          return this.commonService.updateMobileTheme(this.storeId,{ themeCode: this.pageDetails.themeCode});
        }
        return of(res);
      }
    })).subscribe((res: generateMobileDetails | setThemeDetails) => {
      if (res && res.success) {
        this.pageDetails.isLoading = false;
        this.navigateToMemberPage();
         }
    }, (err) => {
      if (err && err.error && err.error.error && err.error.error.payment) {
        this.dialogService.dialogMethod(this.dialogMessages.paymentProcess, this.dialogType.failure, true);
      }
      else if (this.pageDetails.isGenerateSuccess) {
        this.dialogService.dialogMethod('Store page was not fully setup', this.dialogType.failure, true);
      }
      else {
        this.dialogService.dialogMethod('Error in plan purchasing. Please try again', this.dialogType.failure, true);
      }
      this.pageDetails.isLoading = false;
      this.navigateToMemberPage();
    })
  }
  /**
  * Method to navigate to the subscriptions page
  * @type {void}
  */
  navigateToMemberPage(): void {
    if (this.pageDetails.selectedPlanDetails.isSuperAdmin) {
      const id = this.commonDataService.setParam(this.pageDetails.selectedPlanDetails.storeId);
      this.router.navigate(['/app/subscriptionhistory', id]);
    } else {
      this.router.navigate(['/app/subscriptionhistory']);
    }
  }

  /**
   * Method used to trigger the function.
   * @param event is the event.
   * @type {void}
   */
  functionTriggered(event): void {
    if (event) {
      this[event]();
    }
  }

  /**
  * A lifecycle hook to unsubscribe all details.
  */
  ngOnDestroy() {
    if (this.subscriptionObj) {
      this.subscriptionObj.unsubscribe();
    }
  }
}
