import { Directive, ElementRef, Inject, Input, Renderer2 } from '@angular/core';

@Directive({
  selector: '[phaseIiBadgeListDesign]',
})
export class BadgeListDesignDirective {
  @Input('phaseIiBadgeListDesign') badgeDetails;
  environment: any;
  constructor(
    private el: ElementRef, private renderer: Renderer2,
    @Inject('environment') environment
  ) { 
    this.environment = environment;
  }
  ngOnInit() {
    if (this.badgeDetails && this.badgeDetails.isTextTemplate && this.badgeDetails.template) {
      this.renderer.setProperty(this.el.nativeElement, 'innerHTML', this.badgeDetails.template);
    } else if (this.badgeDetails && (this.badgeDetails.isImageTemplate || this.badgeDetails.isImageUpload) && this.badgeDetails.imageUrl) {
      const imgElement = this.renderer.createElement('img');
      imgElement.alt = 'image template';
      if (this.badgeDetails.class && this.badgeDetails.class.badgeUnitType && this.badgeDetails.class.badgeUnitType == 'percentage') {
        imgElement.style = `height:${this.badgeDetails.height ? this.badgeDetails.height : 30}%;width:${this.badgeDetails.width ? this.badgeDetails.width : 30}%;opacity:${this.badgeDetails.transparancy ? this.badgeDetails.transparancy : 1}`;
      }
      else {
        imgElement.style = `height:${this.badgeDetails.height ? this.badgeDetails.height : 70}px;width:${this.badgeDetails.width ? this.badgeDetails.width : 70}px;opacity:${this.badgeDetails.transparancy ? this.badgeDetails.transparancy : 1}`;
      }
      imgElement.src = this.environment.AWSImageUrl + this.badgeDetails.imageUrl;
      this.renderer.setProperty(imgElement, 'id', 'default-template-item-id' + this.badgeDetails.id);
      this.renderer.appendChild(this.el.nativeElement, imgElement);
    }
    if (this.badgeDetails && this.badgeDetails.templateRawCss) {
      const styleElement = this.renderer.createElement('style');
      if (!document.querySelector('#default-template-item-id' + this.badgeDetails.id)) {
        this.renderer.setProperty(styleElement, 'id', 'default-template-item-id' + this.badgeDetails.id);
        this.renderer.appendChild(styleElement, this.renderer.createText(this.badgeDetails.templateRawCss));
        this.renderer.appendChild(document.querySelector('head'), styleElement);
      }
    }
  }
}
