<div>
  <div *ngIf="timerDesign?.messageAboveTimer && templateName!=null" class="timer-message"
    [ngStyle]="{'text-align':timerDesign?.isStoreFront?(timerDesign?.messagePosition?timerDesign?.messagePosition: 'left'): (timerDesign?.messagePosition?timerDesign?.messagePosition:'center')}">
    <span
      [ngStyle]="{'color':timerDesign?.messageFontColor?timerDesign.messageFontColor:'#000000','font-size':timerDesign?.messageFontSize?timerDesign.messageFontSize+'px':'20px','font-weight':timerDesign?.messageBold?'bold':'normal','font-family':timerDesign?.messageFontFamily?timerDesign.messageFontFamily:'Montserrat'}">{{timerDesign?.messageAboveTimer}}</span>
  </div>
  <div *ngIf="templateName=='Basic Timer'">
    <div fxLayout="row" fxLayoutAlign="center center"
      [ngStyle]="{'background':timerDesign?.timerBackgroundColor?timerDesign.timerBackgroundColor:'none','box-shadow':timerDesign?.isStoreFront?'0px 0px 3px 1px rgba(177, 176, 176, 0.75)':'none'}"
      class="timer-container">
      <!-- Days -->
      <div *ngIf="remainingDays>0" fxLayout="column" fxLayoutAlign="center center"
        [ngStyle]="{'margin-right':timerDesign?.spaceBetweenDaysAndTime?timerDesign.spaceBetweenDaysAndTime + 'px':'20px', 'background-color': timerDesign?.timeAndDayBackgroundColor ? timerDesign.timeAndDayBackgroundColor : 'white'}"
        class="basic-timer-div">
        <span
          [ngStyle]="{'color':timerDesign?.timeAndDayColor?timerDesign.timeAndDayColor:'#000000','font-size':timerDesign?.timeAndDayFontSize?timerDesign.timeAndDayFontSize+'px':'25px','font-family':timerDesign?.timeAndDayFontFamily?timerDesign.timeAndDayFontFamily:'Montserrat','font-weight':timerDesign?.timeandDayFontBold?'bold':'normal'}">{{remainingDays}}</span>
        <span
          [ngStyle]="{'color':timerDesign?.timeAndDayHeadingColor?timerDesign.timeAndDayHeadingColor:'#808080','font-size':timerDesign?.timeAndDayHeadingSize?timerDesign.timeAndDayHeadingSize+'px':'14px','font-family':timerDesign?.timeAndDayHeadingFontFamily?timerDesign.timeAndDayHeadingFontFamily:'Montserrat','font-weight':timerDesign?.timeAndDayHeadingBold?'bold':'normal'}">{{timerDesign?.timerDayHeading?timerDesign?.timerDayHeading:'Days'}}</span>
      </div>
      <!-- Time -->
      <div fxLayout="row" [fxLayoutGap]="timerDesign?.spaceBetweenTime?timerDesign.spaceBetweenTime + 'px':'20px'">
        <div fxLayout="column" fxLayoutAlign="center center"
          [ngStyle]="{'background-color': timerDesign?.timeAndDayBackgroundColor ? timerDesign.timeAndDayBackgroundColor : 'white'}"
          class="basic-timer-div">
          <span
            [ngStyle]="{'color':timerDesign?.timeAndDayColor?timerDesign.timeAndDayColor:'#000000','font-size':timerDesign?.timeAndDayFontSize?timerDesign.timeAndDayFontSize+'px':'25px','font-family':timerDesign?.timeAndDayFontFamily?timerDesign.timeAndDayFontFamily:'Montserrat','font-weight':timerDesign?.timeandDayFontBold?'bold':'normal'}">{{remainingHours}}</span>
          <span
            [ngStyle]="{'color':timerDesign?.timeAndDayHeadingColor?timerDesign.timeAndDayHeadingColor:'#808080','font-size':timerDesign?.timeAndDayHeadingSize?timerDesign.timeAndDayHeadingSize+'px':'14px','font-family':timerDesign?.timeAndDayHeadingFontFamily?timerDesign.timeAndDayHeadingFontFamily:'Montserrat','font-weight':timerDesign?.timeAndDayHeadingBold?'bold':'normal'}">{{timerDesign?.timerHourHeading?timerDesign?.timerHourHeading:'Hours'}}</span>
        </div>
        <span *ngIf=" timerDesign?.applyColon "
          [ngStyle]="{'padding-top':'10px','color':timerDesign?.timeAndDayColor?timerDesign.timeAndDayColor:'#000000','font-size':timerDesign?.timeAndDayFontSize?timerDesign.timeAndDayFontSize+'px':'25px','font-family':timerDesign?.timeAndDayFontFamily?timerDesign.timeAndDayFontFamily:'Montserrat','font-weight':timerDesign?.timeandDayFontBold?'bold':'normal'}">:</span>
        <div fxLayout="column" fxLayoutAlign="center center"
          [ngStyle]="{'background-color': timerDesign?.timeAndDayBackgroundColor ? timerDesign.timeAndDayBackgroundColor : 'white'}"
          class="basic-timer-div">
          <span
            [ngStyle]="{'color':timerDesign?.timeAndDayColor?timerDesign.timeAndDayColor:'#000000','font-size':timerDesign?.timeAndDayFontSize?timerDesign.timeAndDayFontSize+'px':'25px','font-family':timerDesign?.timeAndDayFontFamily?timerDesign.timeAndDayFontFamily:'Montserrat','font-weight':timerDesign?.timeandDayFontBold?'bold':'normal'}">{{remainingMinutes}}</span>
          <span
            [ngStyle]="{'color':timerDesign?.timeAndDayHeadingColor?timerDesign.timeAndDayHeadingColor:'#808080','font-size':timerDesign?.timeAndDayHeadingSize?timerDesign.timeAndDayHeadingSize+'px':'14px','font-family':timerDesign?.timeAndDayHeadingFontFamily?timerDesign.timeAndDayHeadingFontFamily:'Montserrat','font-weight':timerDesign?.timeAndDayHeadingBold?'bold':'normal'}">{{timerDesign?.timerMinuteHeading?timerDesign?.timerMinuteHeading:'Minutes'}}</span>
        </div>
        <span *ngIf=" timerDesign?.applyColon "
          [ngStyle]="{'padding-top':'10px','color':timerDesign?.timeAndDayColor?timerDesign.timeAndDayColor:'#000000','font-size':timerDesign?.timeAndDayFontSize?timerDesign.timeAndDayFontSize+'px':'25px','font-family':timerDesign?.timeAndDayFontFamily?timerDesign.timeAndDayFontFamily:'Montserrat','font-weight':timerDesign?.timeandDayFontBold?'bold':'normal'}">:</span>
        <div fxLayout="column" fxLayoutAlign="center center"
          [ngStyle]="{'background-color': timerDesign?.timeAndDayBackgroundColor ? timerDesign.timeAndDayBackgroundColor : 'white'}"
          class="basic-timer-div">
          <span
            [ngStyle]="{'color':timerDesign?.timeAndDayColor?timerDesign.timeAndDayColor:'#000000','font-size':timerDesign?.timeAndDayFontSize?timerDesign.timeAndDayFontSize+'px':'25px','font-family':timerDesign?.timeAndDayFontFamily?timerDesign.timeAndDayFontFamily:'Montserrat','font-weight':timerDesign?.timeandDayFontBold?'bold':'normal'}">{{remainingSeconds}}</span>
          <span
            [ngStyle]="{'color':timerDesign?.timeAndDayHeadingColor?timerDesign.timeAndDayHeadingColor:'#808080','font-size':timerDesign?.timeAndDayHeadingSize?timerDesign.timeAndDayHeadingSize+'px':'14px','font-family':timerDesign?.timeAndDayHeadingFontFamily?timerDesign.timeAndDayHeadingFontFamily:'Montserrat','font-weight':timerDesign?.timeAndDayHeadingBold?'bold':'normal'}">{{timerDesign?.timerSecondsHeading?timerDesign?.timerSecondsHeading:'Seconds'}}</span>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="templateName=='Radial Timer'">
    <div fxLayout="row" fxLayoutAlign="center center"
      [ngStyle]="{'background':timerDesign?.timerBackgroundColor?timerDesign.timerBackgroundColor:'none','box-shadow':timerDesign?.isStoreFront?'0px 0px 3px 1px rgba(177, 176, 176, 0.75)':'none'}"
      class="timer-container">
      <!-- Days -->
      <div *ngIf="remainingDays>0" fxLayout="column" fxLayoutAlign="center center"
        [ngStyle]="{'margin-right':timerDesign?.spaceBetweenDaysAndTime?timerDesign.spaceBetweenDaysAndTime + 'px':'20px'}">
        <div fxLayout="row" fxLayoutAlign="center center"
          [ngStyle]="{'background':timerDesign?.radialTimerSecondaryColor?timerDesign?.radialTimerSecondaryColor:'#cadcff','height':timerDesign?.radialCircleDiameter?timerDesign?.radialCircleDiameter+'px':'80px','width':timerDesign?.radialCircleDiameter?timerDesign?.radialCircleDiameter+'px':'80px'}"
          class="circular-progress" id="circular-progress-days">
          <div
            [ngStyle]="{'color':timerDesign?.timeAndDayColor?timerDesign.timeAndDayColor:'#000000','font-size':timerDesign?.timeAndDayFontSize?timerDesign.timeAndDayFontSize+'px':'25px','font-family':timerDesign?.timeAndDayFontFamily?timerDesign.timeAndDayFontFamily:'Montserrat','font-weight':timerDesign?.timeandDayFontBold?'bold':'normal'}"
            class="value-container">
            <span>{{remainingDays}}</span>
          </div>
        </div>
        <span
          [ngStyle]="{'color':timerDesign?.timeAndDayHeadingColor?timerDesign.timeAndDayHeadingColor:'#808080','font-size':timerDesign?.timeAndDayHeadingSize?timerDesign.timeAndDayHeadingSize+'px':'14px','font-family':timerDesign?.timeAndDayHeadingFontFamily?timerDesign.timeAndDayHeadingFontFamily:'Montserrat','font-weight':timerDesign?.timeAndDayHeadingBold?'bold':'normal'}">{{timerDesign?.timerDayHeading?timerDesign?.timerDayHeading:'Days'}}</span>
      </div>
      <div fxLayout="row" fxLayoutAlign="center center"
        [fxLayoutGap]="timerDesign?.spaceBetweenTime?timerDesign.spaceBetweenTime + 'px':'20px'">
        <!-- Hours -->
        <div fxLayout="column" fxLayoutAlign="center center">
          <div fxLayout="row" fxLayoutAlign="center center"
            [ngStyle]="{'background':timerDesign?.radialTimerSecondaryColor?timerDesign?.radialTimerSecondaryColor:'#cadcff','height':timerDesign?.radialCircleDiameter?timerDesign?.radialCircleDiameter+'px':'80px','width':timerDesign?.radialCircleDiameter?timerDesign?.radialCircleDiameter+'px':'80px'}"
            class="circular-progress" id="circular-progress-hours">
            <div
              [ngStyle]="{'color':timerDesign?.timeAndDayColor?timerDesign.timeAndDayColor:'#000000','font-size':timerDesign?.timeAndDayFontSize?timerDesign.timeAndDayFontSize+'px':'25px','font-family':timerDesign?.timeAndDayFontFamily?timerDesign.timeAndDayFontFamily:'Montserrat','font-weight':timerDesign?.timeandDayFontBold?'bold':'normal'}"
              class="value-container">
              <span>{{remainingHours}}</span>
            </div>
          </div>
          <span
            [ngStyle]="{'color':timerDesign?.timeAndDayHeadingColor?timerDesign.timeAndDayHeadingColor:'#808080','font-size':timerDesign?.timeAndDayHeadingSize?timerDesign.timeAndDayHeadingSize+'px':'14px','font-family':timerDesign?.timeAndDayHeadingFontFamily?timerDesign.timeAndDayHeadingFontFamily:'Montserrat','font-weight':timerDesign?.timeAndDayHeadingBold?'bold':'normal'}">{{timerDesign?.timerHourHeading?timerDesign?.timerHourHeading:'Hours'}}</span>
        </div>
        <!-- Minutes -->
        <div fxLayout="column" fxLayoutAlign="center center">
          <div fxLayout="row" fxLayoutAlign="center center"
            [ngStyle]="{'background':timerDesign?.radialTimerSecondaryColor?timerDesign?.radialTimerSecondaryColor:'#cadcff','height':timerDesign?.radialCircleDiameter?timerDesign?.radialCircleDiameter+'px':'80px','width':timerDesign?.radialCircleDiameter?timerDesign?.radialCircleDiameter+'px':'80px'}"
            class="circular-progress" id="circular-progress-minutes">
            <div
              [ngStyle]="{'color':timerDesign?.timeAndDayColor?timerDesign.timeAndDayColor:'#000000','font-size':timerDesign?.timeAndDayFontSize?timerDesign.timeAndDayFontSize+'px':'25px','font-family':timerDesign?.timeAndDayFontFamily?timerDesign.timeAndDayFontFamily:'Montserrat','font-weight':timerDesign?.timeandDayFontBold?'bold':'normal'}"
              class="value-container">
              <span>{{remainingMinutes}}</span>
            </div>
          </div>
          <span
            [ngStyle]="{'color':timerDesign?.timeAndDayHeadingColor?timerDesign.timeAndDayHeadingColor:'#808080','font-size':timerDesign?.timeAndDayHeadingSize?timerDesign.timeAndDayHeadingSize+'px':'14px','font-family':timerDesign?.timeAndDayHeadingFontFamily?timerDesign.timeAndDayHeadingFontFamily:'Montserrat','font-weight':timerDesign?.timeAndDayHeadingBold?'bold':'normal'}">{{timerDesign?.timerMinuteHeading?timerDesign?.timerMinuteHeading:'Minutes'}}</span>
        </div>
        <!-- Seconds -->
        <div fxLayout="column" fxLayoutAlign="center center">
          <div fxLayout="row" fxLayoutAlign="center center"
            [ngStyle]="{'background':timerDesign?.radialTimerSecondaryColor?timerDesign?.radialTimerSecondaryColor:'#cadcff','height':timerDesign?.radialCircleDiameter?timerDesign?.radialCircleDiameter+'px':'80px','width':timerDesign?.radialCircleDiameter?timerDesign?.radialCircleDiameter+'px':'80px'}"
            class="circular-progress" id="circular-progress-seconds">
            <div
              [ngStyle]="{'color':timerDesign?.timeAndDayColor?timerDesign.timeAndDayColor:'#000000','font-size':timerDesign?.timeAndDayFontSize?timerDesign.timeAndDayFontSize+'px':'25px','font-family':timerDesign?.timeAndDayFontFamily?timerDesign.timeAndDayFontFamily:'Montserrat','font-weight':timerDesign?.timeandDayFontBold?'bold':'normal'}"
              class="value-container">
              <span>{{remainingSeconds}}</span>
            </div>
          </div>
          <span
            [ngStyle]="{'color':timerDesign?.timeAndDayHeadingColor?timerDesign.timeAndDayHeadingColor:'#808080','font-size':timerDesign?.timeAndDayHeadingSize?timerDesign.timeAndDayHeadingSize+'px':'14px','font-family':timerDesign?.timeAndDayHeadingFontFamily?timerDesign.timeAndDayHeadingFontFamily:'Montserrat','font-weight':timerDesign?.timeAndDayHeadingBold?'bold':'normal'}">{{timerDesign?.timerSecondsHeading?timerDesign?.timerSecondsHeading:'Seconds'}}</span>
        </div>
      </div>
    </div>
  </div>

  <div fxLayout="column" fxLayoutAlign="center center" *ngIf="templateName=='Digital Timer'">
    <div fxLayout="row" fxLayoutAlign="center center" class="timer-container" [ngStyle]="{'background':timerDesign?.timerBackgroundColor?timerDesign.timerBackgroundColor:'none',
      'box-shadow':timerDesign?.isStoreFront?'0px 0px 3px 1px rgba(177, 176, 176, 0.75)':'none'}">
      <!-- Days -->
      <div *ngIf="digitalDays>0" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="5px"
        [ngStyle]="{'margin-right':timerDesign?.spaceBetweenDaysAndTime?timerDesign.spaceBetweenDaysAndTime + 'px':'20px'}">
        <span class="timer-sectionWidth">
          <div class="digit days"
            [ngStyle]="{'height':timerDesign?.timeAndDayFontHeight?timerDesign?.timeAndDayFontHeight+'px':'33px','width':timerDesign?.timeAndDayFontWidth?timerDesign?.timeAndDayFontWidth+'px':'16px'}">
            <div class="segment" [ngStyle]="{'background':timerDesign?.timeAndDayColor}"></div>
            <div class="segment" [ngStyle]="{'background':timerDesign?.timeAndDayColor}"></div>
            <div class="segment" [ngStyle]="{'background':timerDesign?.timeAndDayColor}"></div>
            <div class="segment" [ngStyle]="{'background':timerDesign?.timeAndDayColor}"></div>
            <div class="segment" [ngStyle]="{'background':timerDesign?.timeAndDayColor}"></div>
            <div class="segment" [ngStyle]="{'background':timerDesign?.timeAndDayColor}"></div>
            <div class="segment" [ngStyle]="{'background':timerDesign?.timeAndDayColor}"></div>
          </div>

          <div class="digit days"
            [ngStyle]="{'height':timerDesign?.timeAndDayFontHeight?timerDesign?.timeAndDayFontHeight+'px':'33px','width':timerDesign?.timeAndDayFontWidth?timerDesign?.timeAndDayFontWidth+'px':'16px'}">
            <div class="segment" [ngStyle]="{'background':timerDesign?.timeAndDayColor}"></div>
            <div class="segment" [ngStyle]="{'background':timerDesign?.timeAndDayColor}"></div>
            <div class="segment" [ngStyle]="{'background':timerDesign?.timeAndDayColor}"></div>
            <div class="segment" [ngStyle]="{'background':timerDesign?.timeAndDayColor}"></div>
            <div class="segment" [ngStyle]="{'background':timerDesign?.timeAndDayColor}"></div>
            <div class="segment" [ngStyle]="{'background':timerDesign?.timeAndDayColor}"></div>
            <div class="segment" [ngStyle]="{'background':timerDesign?.timeAndDayColor}"></div>
          </div>
        </span>
        <span
          [ngStyle]="{'color':timerDesign?.timeAndDayHeadingColor?timerDesign.timeAndDayHeadingColor:'#808080','font-size':timerDesign?.timeAndDayHeadingSize?timerDesign.timeAndDayHeadingSize+'px':'14px','font-family':timerDesign?.timeAndDayHeadingFontFamily?timerDesign.timeAndDayHeadingFontFamily:'Montserrat','font-weight':timerDesign?.timeAndDayHeadingBold?'bold':'normal'}">{{timerDesign?.timerDayHeading?timerDesign?.timerDayHeading:'Days'}}</span>
      </div>


      <!-- Time -->
      <div fxLayout="row" [fxLayoutGap]="timerDesign?.spaceBetweenTime?timerDesign.spaceBetweenTime + 'px':'20px'">
        <div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="5px">
          <span class="timer-sectionWidth"
            [ngStyle]="{'color':timerDesign?.timeAndDayColor?timerDesign.timeAndDayColor:'#000000','font-size':timerDesign?.timeAndDayFontSize?timerDesign.timeAndDayFontSize+'px':'25px','font-family':timerDesign?.timeAndDayFontFamily?timerDesign.timeAndDayFontFamily:'Montserrat','font-weight':timerDesign?.timeandDayFontBold?'bold':'normal'}">
            <div class="digit hours"
              [ngStyle]="{'height':timerDesign?.timeAndDayFontHeight?timerDesign?.timeAndDayFontHeight+'px':'33px','width':timerDesign?.timeAndDayFontWidth?timerDesign?.timeAndDayFontWidth+'px':'16px'}">
              <div class="segment" [ngStyle]="{'background':timerDesign?.timeAndDayColor}"></div>
              <div class="segment" [ngStyle]="{'background':timerDesign?.timeAndDayColor}"></div>
              <div class="segment" [ngStyle]="{'background':timerDesign?.timeAndDayColor}"></div>
              <div class="segment" [ngStyle]="{'background':timerDesign?.timeAndDayColor}"></div>
              <div class="segment" [ngStyle]="{'background':timerDesign?.timeAndDayColor}"></div>
              <div class="segment" [ngStyle]="{'background':timerDesign?.timeAndDayColor}"></div>
              <div class="segment" [ngStyle]="{'background':timerDesign?.timeAndDayColor}"></div>
            </div>

            <div class="digit hours"
              [ngStyle]="{'height':timerDesign?.timeAndDayFontHeight?timerDesign?.timeAndDayFontHeight+'px':'33px','width':timerDesign?.timeAndDayFontWidth?timerDesign?.timeAndDayFontWidth+'px':'16px'}">
              <div class="segment" [ngStyle]="{'background':timerDesign?.timeAndDayColor}"></div>
              <div class="segment" [ngStyle]="{'background':timerDesign?.timeAndDayColor}"></div>
              <div class="segment" [ngStyle]="{'background':timerDesign?.timeAndDayColor}"></div>
              <div class="segment" [ngStyle]="{'background':timerDesign?.timeAndDayColor}"></div>
              <div class="segment" [ngStyle]="{'background':timerDesign?.timeAndDayColor}"></div>
              <div class="segment" [ngStyle]="{'background':timerDesign?.timeAndDayColor}"></div>
              <div class="segment" [ngStyle]="{'background':timerDesign?.timeAndDayColor}"></div>
            </div>
          </span>
          <span
            [ngStyle]="{'color':timerDesign?.timeAndDayHeadingColor?timerDesign.timeAndDayHeadingColor:'#808080','font-size':timerDesign?.timeAndDayHeadingSize?timerDesign.timeAndDayHeadingSize+'px':'14px','font-family':timerDesign?.timeAndDayHeadingFontFamily?timerDesign.timeAndDayHeadingFontFamily:'Montserrat','font-weight':timerDesign?.timeAndDayHeadingBold?'bold':'normal'}">{{timerDesign?.timerHourHeading?timerDesign?.timerHourHeading:'Hours'}}</span>
        </div>
        <span
          [ngStyle]="{'color':timerDesign?.timeAndDayColor?timerDesign.timeAndDayColor:'#000000','font-size':'30px','font-weight':'bold'}">:</span>
        <div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="5px">
          <span class="timer-sectionWidth"
            [ngStyle]="{'color':timerDesign?.timeAndDayColor?timerDesign.timeAndDayColor:'#000000','font-size':timerDesign?.timeAndDayFontSize?timerDesign.timeAndDayFontSize+'px':'25px','font-family':timerDesign?.timeAndDayFontFamily?timerDesign.timeAndDayFontFamily:'Montserrat','font-weight':timerDesign?.timeAndDayFontBold?'bold':'normal'}">
            <div class="digit minutes"
              [ngStyle]="{'height':timerDesign?.timeAndDayFontHeight?timerDesign?.timeAndDayFontHeight+'px':'33px','width':timerDesign?.timeAndDayFontWidth?timerDesign?.timeAndDayFontWidth+'px':'16px'}">
              <div class="segment" [ngStyle]="{'background':timerDesign?.timeAndDayColor}"></div>
              <div class="segment" [ngStyle]="{'background':timerDesign?.timeAndDayColor}"></div>
              <div class="segment" [ngStyle]="{'background':timerDesign?.timeAndDayColor}"></div>
              <div class="segment" [ngStyle]="{'background':timerDesign?.timeAndDayColor}"></div>
              <div class="segment" [ngStyle]="{'background':timerDesign?.timeAndDayColor}"></div>
              <div class="segment" [ngStyle]="{'background':timerDesign?.timeAndDayColor}"></div>
              <div class="segment" [ngStyle]="{'background':timerDesign?.timeAndDayColor}"></div>
            </div>

            <div class="digit minutes"
              [ngStyle]="{'height':timerDesign?.timeAndDayFontHeight?timerDesign?.timeAndDayFontHeight+'px':'33px','width':timerDesign?.timeAndDayFontWidth?timerDesign?.timeAndDayFontWidth+'px':'16px'}">
              <div class="segment" [ngStyle]="{'background':timerDesign?.timeAndDayColor}"></div>
              <div class="segment" [ngStyle]="{'background':timerDesign?.timeAndDayColor}"></div>
              <div class="segment" [ngStyle]="{'background':timerDesign?.timeAndDayColor}"></div>
              <div class="segment" [ngStyle]="{'background':timerDesign?.timeAndDayColor}"></div>
              <div class="segment" [ngStyle]="{'background':timerDesign?.timeAndDayColor}"></div>
              <div class="segment" [ngStyle]="{'background':timerDesign?.timeAndDayColor}"></div>
              <div class="segment" [ngStyle]="{'background':timerDesign?.timeAndDayColor}"></div>
            </div>
          </span>
          <span
            [ngStyle]="{'color':timerDesign?.timeAndDayHeadingColor?timerDesign.timeAndDayHeadingColor:'#808080','font-size':timerDesign?.timeAndDayHeadingSize?timerDesign.timeAndDayHeadingSize+'px':'14px','font-family':timerDesign?.timeAndDayHeadingFontFamily?timerDesign.timeAndDayHeadingFontFamily:'Montserrat','font-weight':timerDesign?.timeAndDayHeadingBold?'bold':'normal'}">{{timerDesign?.timerMinuteHeading?timerDesign?.timerMinuteHeading:'Minutes'}}</span>
        </div>
        <span
          [ngStyle]="{'color':timerDesign?.timeAndDayColor?timerDesign.timeAndDayColor:'#000000','font-size':'30px','font-weight':'bold'}">:</span>
        <div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="5px">
          <span class="timer-sectionWidth"
            [ngStyle]="{'color':timerDesign?.timeAndDayColor?timerDesign.timeAndDayColor:'#000000','font-size':timerDesign?.timeAndDayFontSize?timerDesign.timeAndDayFontSize+'px':'25px','font-family':timerDesign?.timeAndDayFontFamily?timerDesign.timeAndDayFontFamily:'Montserrat','font-weight':timerDesign?.timeAndDayFontBold?'bold':'normal'}">
            <div class="digit seconds"
              [ngStyle]="{'height':timerDesign?.timeAndDayFontHeight?timerDesign?.timeAndDayFontHeight+'px':'33px','width':timerDesign?.timeAndDayFontWidth?timerDesign?.timeAndDayFontWidth+'px':'16px'}">
              <div class="segment" [ngStyle]="{'background':timerDesign?.timeAndDayColor}"></div>
              <div class="segment" [ngStyle]="{'background':timerDesign?.timeAndDayColor}"></div>
              <div class="segment" [ngStyle]="{'background':timerDesign?.timeAndDayColor}"></div>
              <div class="segment" [ngStyle]="{'background':timerDesign?.timeAndDayColor}"></div>
              <div class="segment" [ngStyle]="{'background':timerDesign?.timeAndDayColor}"></div>
              <div class="segment" [ngStyle]="{'background':timerDesign?.timeAndDayColor}"></div>
              <div class="segment" [ngStyle]="{'background':timerDesign?.timeAndDayColor}"></div>
            </div>

            <div class="digit seconds"
              [ngStyle]="{'height':timerDesign?.timeAndDayFontHeight?timerDesign?.timeAndDayFontHeight+'px':'33px','width':timerDesign?.timeAndDayFontWidth?timerDesign?.timeAndDayFontWidth+'px':'16px'}">

              <div class="segment" [ngStyle]="{'background':timerDesign?.timeAndDayColor}"></div>
              <div class="segment" [ngStyle]="{'background':timerDesign?.timeAndDayColor}"></div>
              <div class="segment" [ngStyle]="{'background':timerDesign?.timeAndDayColor}"></div>
              <div class="segment" [ngStyle]="{'background':timerDesign?.timeAndDayColor}"></div>
              <div class="segment" [ngStyle]="{'background':timerDesign?.timeAndDayColor}"></div>
              <div class="segment" [ngStyle]="{'background':timerDesign?.timeAndDayColor}"></div>
              <div class="segment" [ngStyle]="{'background':timerDesign?.timeAndDayColor}"></div>
            </div>
          </span>
          <span
            [ngStyle]="{'color':timerDesign?.timeAndDayHeadingColor?timerDesign.timeAndDayHeadingColor:'#808080','font-size':timerDesign?.timeAndDayHeadingSize?timerDesign.timeAndDayHeadingSize+'px':'14px','font-family':timerDesign?.timeAndDayHeadingFontFamily?timerDesign.timeAndDayHeadingFontFamily:'Montserrat','font-weight':timerDesign?.timeAndDayHeadingBold?'bold':'normal'}">{{timerDesign?.timerSecondsHeading?timerDesign?.timerSecondsHeading:'Seconds'}}</span>
        </div>
      </div>

    </div>
  </div>

  <div fxLayout="column" fxLayoutAlign="center center" *ngIf="templateName=='Matrix Timer'">
    <div fxLayout="row" fxLayoutAlign="center center" class="timer-container" [ngStyle]="{'background':timerDesign?.timerBackgroundColor?timerDesign.timerBackgroundColor:'none',
      'box-shadow':timerDesign?.isStoreFront?'0px 0px 3px 1px rgba(177, 176, 176, 0.75)':'none'}">
      <!-- Days -->
      <div *ngIf="matrixDays>0" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="5px"
        [ngStyle]="{'margin-right':timerDesign?.spaceBetweenDaysAndTime?timerDesign.spaceBetweenDaysAndTime + 'px':'20px'}">
        <span class="timer-sectionWidth "
          [ngStyle]="{'color':timerDesign?.timeAndDayColor?timerDesign.timeAndDayColor:'#000000','font-size':timerDesign?.timeAndDayFontSize?timerDesign.timeAndDayFontSize+'px':'25px','font-family':timerDesign?.timeAndDayFontFamily?timerDesign.timeAndDayFontFamily:'Montserrat','font-weight':timerDesign?.timeAndDayFontBold?'bold':'normal'}">
          <div class="matrix-main matrix-days" id="main" fxLayout="column">
            <div class="matrix-row" fxLayout="row">
              <div class="matrix-col" [ngStyle]="{'background':timerDesign?.timeAndDayColor}"></div>
              <div class="matrix-col" [ngStyle]="{'background':timerDesign?.timeAndDayColor}"></div>
              <div class="matrix-col" [ngStyle]="{'background':timerDesign?.timeAndDayColor}"></div>
            </div>
            <div class="matrix-row" fxLayout="row">
              <div class="matrix-col" [ngStyle]="{'background':timerDesign?.timeAndDayColor}"></div>
              <div class="matrix-col" [ngStyle]="{'background':timerDesign?.timeAndDayColor}"></div>
              <div class="matrix-col" [ngStyle]="{'background':timerDesign?.timeAndDayColor}"></div>
            </div>
            <div class="matrix-row" fxLayout="row">
              <div class="matrix-col" [ngStyle]="{'background':timerDesign?.timeAndDayColor}"></div>
              <div class="matrix-col" [ngStyle]="{'background':timerDesign?.timeAndDayColor}"></div>
              <div class="matrix-col" [ngStyle]="{'background':timerDesign?.timeAndDayColor}"></div>
            </div>
            <div class="matrix-row" fxLayout="row">
              <div class="matrix-col" [ngStyle]="{'background':timerDesign?.timeAndDayColor}"></div>
              <div class="matrix-col" [ngStyle]="{'background':timerDesign?.timeAndDayColor}"></div>
              <div class="matrix-col" [ngStyle]="{'background':timerDesign?.timeAndDayColor}"></div>
            </div>
            <div class="matrix-row" fxLayout="row">
              <div class="matrix-col" [ngStyle]="{'background':timerDesign?.timeAndDayColor}"></div>
              <div class="matrix-col" [ngStyle]="{'background':timerDesign?.timeAndDayColor}"></div>
              <div class="matrix-col" [ngStyle]="{'background':timerDesign?.timeAndDayColor}"></div>
            </div>
          </div>

          <div class="matrix-main matrix-days" id="main" fxLayout="column">
            <div class="matrix-row" fxLayout="row">
              <div class="matrix-col" [ngStyle]="{'background':timerDesign?.timeAndDayColor}"></div>
              <div class="matrix-col" [ngStyle]="{'background':timerDesign?.timeAndDayColor}"></div>
              <div class="matrix-col" [ngStyle]="{'background':timerDesign?.timeAndDayColor}"></div>
            </div>
            <div class="matrix-row" fxLayout="row">
              <div class="matrix-col" [ngStyle]="{'background':timerDesign?.timeAndDayColor}"></div>
              <div class="matrix-col" [ngStyle]="{'background':timerDesign?.timeAndDayColor}"></div>
              <div class="matrix-col" [ngStyle]="{'background':timerDesign?.timeAndDayColor}"></div>
            </div>
            <div class="matrix-row" fxLayout="row">
              <div class="matrix-col" [ngStyle]="{'background':timerDesign?.timeAndDayColor}"></div>
              <div class="matrix-col" [ngStyle]="{'background':timerDesign?.timeAndDayColor}"></div>
              <div class="matrix-col" [ngStyle]="{'background':timerDesign?.timeAndDayColor}"></div>
            </div>
            <div class="matrix-row" fxLayout="row">
              <div class="matrix-col" [ngStyle]="{'background':timerDesign?.timeAndDayColor}"></div>
              <div class="matrix-col" [ngStyle]="{'background':timerDesign?.timeAndDayColor}"></div>
              <div class="matrix-col" [ngStyle]="{'background':timerDesign?.timeAndDayColor}"></div>
            </div>
            <div class="matrix-row" fxLayout="row">
              <div class="matrix-col" [ngStyle]="{'background':timerDesign?.timeAndDayColor}"></div>
              <div class="matrix-col" [ngStyle]="{'background':timerDesign?.timeAndDayColor}"></div>
              <div class="matrix-col" [ngStyle]="{'background':timerDesign?.timeAndDayColor}"></div>
            </div>
          </div>
        </span>
        <span
          [ngStyle]="{'color':timerDesign?.timeAndDayHeadingColor?timerDesign.timeAndDayHeadingColor:'#808080','font-size':timerDesign?.timeAndDayHeadingSize?timerDesign.timeAndDayHeadingSize+'px':'14px','font-family':timerDesign?.timeAndDayHeadingFontFamily?timerDesign.timeAndDayHeadingFontFamily:'Montserrat','font-weight':timerDesign?.timeAndDayHeadingBold?'bold':'normal'}">{{timerDesign?.timerDayHeading?timerDesign?.timerDayHeading:'Days'}}</span>
      </div>



      <!-- Time -->
      <div fxLayout="row" [fxLayoutGap]="timerDesign?.spaceBetweenTime?timerDesign.spaceBetweenTime + 'px':'20px'">
        <div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="5px">
          <span class="timer-sectionWidth"
            [ngStyle]="{'color':timerDesign?.timeAndDayColor?timerDesign.timeAndDayColor:'#000000','font-size':timerDesign?.timeAndDayFontSize?timerDesign.timeAndDayFontSize+'px':'25px','font-family':timerDesign?.timeAndDayFontFamily?timerDesign.timeAndDayFontFamily:'Montserrat','font-weight':timerDesign?.timeAndDayFontBold?'bold':'normal'}">
            <div class="matrix-main matrix-hours" id="main" fxLayout="column">
              <div class="matrix-row" fxLayout="row">
                <div class="matrix-col" [ngStyle]="{'background':timerDesign?.timeAndDayColor}"></div>
                <div class="matrix-col" [ngStyle]="{'background':timerDesign?.timeAndDayColor}"></div>
                <div class="matrix-col" [ngStyle]="{'background':timerDesign?.timeAndDayColor}"></div>
              </div>
              <div class="matrix-row" fxLayout="row">
                <div class="matrix-col" [ngStyle]="{'background':timerDesign?.timeAndDayColor}"></div>
                <div class="matrix-col" [ngStyle]="{'background':timerDesign?.timeAndDayColor}"></div>
                <div class="matrix-col" [ngStyle]="{'background':timerDesign?.timeAndDayColor}"></div>
              </div>
              <div class="matrix-row" fxLayout="row">
                <div class="matrix-col" [ngStyle]="{'background':timerDesign?.timeAndDayColor}"></div>
                <div class="matrix-col" [ngStyle]="{'background':timerDesign?.timeAndDayColor}"></div>
                <div class="matrix-col" [ngStyle]="{'background':timerDesign?.timeAndDayColor}"></div>
              </div>
              <div class="matrix-row" fxLayout="row">
                <div class="matrix-col" [ngStyle]="{'background':timerDesign?.timeAndDayColor}"></div>
                <div class="matrix-col" [ngStyle]="{'background':timerDesign?.timeAndDayColor}"></div>
                <div class="matrix-col" [ngStyle]="{'background':timerDesign?.timeAndDayColor}"></div>
              </div>
              <div class="matrix-row" fxLayout="row">
                <div class="matrix-col" [ngStyle]="{'background':timerDesign?.timeAndDayColor}"></div>
                <div class="matrix-col" [ngStyle]="{'background':timerDesign?.timeAndDayColor}"></div>
                <div class="matrix-col" [ngStyle]="{'background':timerDesign?.timeAndDayColor}"></div>
              </div>
            </div>

            <div class="matrix-main matrix-hours" id="main" fxLayout="column">
              <div class="matrix-row" fxLayout="row">
                <div class="matrix-col" [ngStyle]="{'background':timerDesign?.timeAndDayColor}"></div>
                <div class="matrix-col" [ngStyle]="{'background':timerDesign?.timeAndDayColor}"></div>
                <div class="matrix-col" [ngStyle]="{'background':timerDesign?.timeAndDayColor}"></div>
              </div>
              <div class="matrix-row" fxLayout="row">
                <div class="matrix-col" [ngStyle]="{'background':timerDesign?.timeAndDayColor}"></div>
                <div class="matrix-col" [ngStyle]="{'background':timerDesign?.timeAndDayColor}"></div>
                <div class="matrix-col" [ngStyle]="{'background':timerDesign?.timeAndDayColor}"></div>
              </div>
              <div class="matrix-row" fxLayout="row">
                <div class="matrix-col" [ngStyle]="{'background':timerDesign?.timeAndDayColor}"></div>
                <div class="matrix-col" [ngStyle]="{'background':timerDesign?.timeAndDayColor}"></div>
                <div class="matrix-col" [ngStyle]="{'background':timerDesign?.timeAndDayColor}"></div>
              </div>
              <div class="matrix-row" fxLayout="row">
                <div class="matrix-col" [ngStyle]="{'background':timerDesign?.timeAndDayColor}"></div>
                <div class="matrix-col" [ngStyle]="{'background':timerDesign?.timeAndDayColor}"></div>
                <div class="matrix-col" [ngStyle]="{'background':timerDesign?.timeAndDayColor}"></div>
              </div>
              <div class="matrix-row" fxLayout="row">
                <div class="matrix-col" [ngStyle]="{'background':timerDesign?.timeAndDayColor}"></div>
                <div class="matrix-col" [ngStyle]="{'background':timerDesign?.timeAndDayColor}"></div>
                <div class="matrix-col" [ngStyle]="{'background':timerDesign?.timeAndDayColor}"></div>
              </div>
            </div>
          </span>
          <span
            [ngStyle]="{'color':timerDesign?.timeAndDayHeadingColor?timerDesign.timeAndDayHeadingColor:'#808080','font-size':timerDesign?.timeAndDayHeadingSize?timerDesign.timeAndDayHeadingSize+'px':'14px','font-family':timerDesign?.timeAndDayHeadingFontFamily?timerDesign.timeAndDayHeadingFontFamily:'Montserrat','font-weight':timerDesign?.timeAndDayHeadingBold?'bold':'normal'}">{{timerDesign?.timerHourHeading?timerDesign?.timerHourHeading:'Hours'}}</span>
        </div>
        <span
          [ngStyle]="{'color':timerDesign?.timeAndDayColor?timerDesign.timeAndDayColor:'#000000','font-size':'30px','font-weight':'bold'}">:</span>
        <div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="5px">
          <span class="timer-sectionWidth"
            [ngStyle]="{'color':timerDesign?.timeAndDayColor?timerDesign.timeAndDayColor:'#000000','font-size':timerDesign?.timeAndDayFontSize?timerDesign.timeAndDayFontSize+'px':'25px','font-family':timerDesign?.timeAndDayFontFamily?timerDesign.timeAndDayFontFamily:'Montserrat','font-weight':timerDesign?.timeAndDayFontBold?'bold':'normal'}">
            <div class="matrix-main matrix-minutes" id="main" fxLayout="column">
              <div class="matrix-row" fxLayout="row">
                <div class="matrix-col" [ngStyle]="{'background':timerDesign?.timeAndDayColor}"></div>
                <div class="matrix-col" [ngStyle]="{'background':timerDesign?.timeAndDayColor}"></div>
                <div class="matrix-col" [ngStyle]="{'background':timerDesign?.timeAndDayColor}"></div>
              </div>
              <div class="matrix-row" fxLayout="row">
                <div class="matrix-col" [ngStyle]="{'background':timerDesign?.timeAndDayColor}"></div>
                <div class="matrix-col" [ngStyle]="{'background':timerDesign?.timeAndDayColor}"></div>
                <div class="matrix-col" [ngStyle]="{'background':timerDesign?.timeAndDayColor}"></div>
              </div>
              <div class="matrix-row" fxLayout="row">
                <div class="matrix-col" [ngStyle]="{'background':timerDesign?.timeAndDayColor}"></div>
                <div class="matrix-col" [ngStyle]="{'background':timerDesign?.timeAndDayColor}"></div>
                <div class="matrix-col" [ngStyle]="{'background':timerDesign?.timeAndDayColor}"></div>
              </div>
              <div class="matrix-row" fxLayout="row">
                <div class="matrix-col" [ngStyle]="{'background':timerDesign?.timeAndDayColor}"></div>
                <div class="matrix-col" [ngStyle]="{'background':timerDesign?.timeAndDayColor}"></div>
                <div class="matrix-col" [ngStyle]="{'background':timerDesign?.timeAndDayColor}"></div>
              </div>
              <div class="matrix-row" fxLayout="row">
                <div class="matrix-col" [ngStyle]="{'background':timerDesign?.timeAndDayColor}"></div>
                <div class="matrix-col" [ngStyle]="{'background':timerDesign?.timeAndDayColor}"></div>
                <div class="matrix-col" [ngStyle]="{'background':timerDesign?.timeAndDayColor}"></div>
              </div>
            </div>

            <div class="matrix-main matrix-minutes" id="main" fxLayout="column">
              <div class="matrix-row" fxLayout="row">
                <div class="matrix-col" [ngStyle]="{'background':timerDesign?.timeAndDayColor}"></div>
                <div class="matrix-col" [ngStyle]="{'background':timerDesign?.timeAndDayColor}"></div>
                <div class="matrix-col" [ngStyle]="{'background':timerDesign?.timeAndDayColor}"></div>
              </div>
              <div class="matrix-row" fxLayout="row">
                <div class="matrix-col" [ngStyle]="{'background':timerDesign?.timeAndDayColor}"></div>
                <div class="matrix-col" [ngStyle]="{'background':timerDesign?.timeAndDayColor}"></div>
                <div class="matrix-col" [ngStyle]="{'background':timerDesign?.timeAndDayColor}"></div>
              </div>
              <div class="matrix-row" fxLayout="row">
                <div class="matrix-col" [ngStyle]="{'background':timerDesign?.timeAndDayColor}"></div>
                <div class="matrix-col" [ngStyle]="{'background':timerDesign?.timeAndDayColor}"></div>
                <div class="matrix-col" [ngStyle]="{'background':timerDesign?.timeAndDayColor}"></div>
              </div>
              <div class="matrix-row" fxLayout="row">
                <div class="matrix-col" [ngStyle]="{'background':timerDesign?.timeAndDayColor}"></div>
                <div class="matrix-col" [ngStyle]="{'background':timerDesign?.timeAndDayColor}"></div>
                <div class="matrix-col" [ngStyle]="{'background':timerDesign?.timeAndDayColor}"></div>
              </div>
              <div class="matrix-row" fxLayout="row">
                <div class="matrix-col" [ngStyle]="{'background':timerDesign?.timeAndDayColor}"></div>
                <div class="matrix-col" [ngStyle]="{'background':timerDesign?.timeAndDayColor}"></div>
                <div class="matrix-col" [ngStyle]="{'background':timerDesign?.timeAndDayColor}"></div>
              </div>
            </div>
          </span>
          <span
            [ngStyle]="{'color':timerDesign?.timeAndDayHeadingColor?timerDesign.timeAndDayHeadingColor:'#808080','font-size':timerDesign?.timeAndDayHeadingSize?timerDesign.timeAndDayHeadingSize+'px':'14px','font-family':timerDesign?.timeAndDayHeadingFontFamily?timerDesign.timeAndDayHeadingFontFamily:'Montserrat','font-weight':timerDesign?.timeAndDayHeadingBold?'bold':'normal'}">{{timerDesign?.timerMinuteHeading?timerDesign?.timerMinuteHeading:'Minutes'}}</span>
        </div>
        <span
          [ngStyle]="{'color':timerDesign?.timeAndDayColor?timerDesign.timeAndDayColor:'#000000','font-size':'30px','font-weight':'bold'}">:</span>
        <div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="5px">
          <span class="timer-sectionWidth"
            [ngStyle]="{'color':timerDesign?.timeAndDayColor?timerDesign.timeAndDayColor:'#000000','font-size':timerDesign?.timeAndDayFontSize?timerDesign.timeAndDayFontSize+'px':'25px','font-family':timerDesign?.timeAndDayFontFamily?timerDesign.timeAndDayFontFamily:'Montserrat','font-weight':timerDesign?.timeAndDayFontBold?'bold':'normal'}">
            <div class="matrix-main matrix-seconds" id="main" fxLayout="column">
              <div class="matrix-row" fxLayout="row">
                <div class="matrix-col" [ngStyle]="{'background':timerDesign?.timeAndDayColor}"></div>
                <div class="matrix-col" [ngStyle]="{'background':timerDesign?.timeAndDayColor}"></div>
                <div class="matrix-col" [ngStyle]="{'background':timerDesign?.timeAndDayColor}"></div>
              </div>
              <div class="matrix-row" fxLayout="row">
                <div class="matrix-col" [ngStyle]="{'background':timerDesign?.timeAndDayColor}"></div>
                <div class="matrix-col" [ngStyle]="{'background':timerDesign?.timeAndDayColor}"></div>
                <div class="matrix-col" [ngStyle]="{'background':timerDesign?.timeAndDayColor}"></div>
              </div>
              <div class="matrix-row" fxLayout="row">
                <div class="matrix-col" [ngStyle]="{'background':timerDesign?.timeAndDayColor}"></div>
                <div class="matrix-col" [ngStyle]="{'background':timerDesign?.timeAndDayColor}"></div>
                <div class="matrix-col" [ngStyle]="{'background':timerDesign?.timeAndDayColor}"></div>
              </div>
              <div class="matrix-row" fxLayout="row">
                <div class="matrix-col" [ngStyle]="{'background':timerDesign?.timeAndDayColor}"></div>
                <div class="matrix-col" [ngStyle]="{'background':timerDesign?.timeAndDayColor}"></div>
                <div class="matrix-col" [ngStyle]="{'background':timerDesign?.timeAndDayColor}"></div>
              </div>
              <div class="matrix-row" fxLayout="row">
                <div class="matrix-col" [ngStyle]="{'background':timerDesign?.timeAndDayColor}"></div>
                <div class="matrix-col" [ngStyle]="{'background':timerDesign?.timeAndDayColor}"></div>
                <div class="matrix-col" [ngStyle]="{'background':timerDesign?.timeAndDayColor}"></div>
              </div>
            </div>

            <div class="matrix-main matrix-seconds" id="main" fxLayout="column">
              <div class="matrix-row" fxLayout="row">
                <div class="matrix-col" [ngStyle]="{'background':timerDesign?.timeAndDayColor}"></div>
                <div class="matrix-col" [ngStyle]="{'background':timerDesign?.timeAndDayColor}"></div>
                <div class="matrix-col" [ngStyle]="{'background':timerDesign?.timeAndDayColor}"></div>
              </div>
              <div class="matrix-row" fxLayout="row">
                <div class="matrix-col" [ngStyle]="{'background':timerDesign?.timeAndDayColor}"></div>
                <div class="matrix-col" [ngStyle]="{'background':timerDesign?.timeAndDayColor}"></div>
                <div class="matrix-col" [ngStyle]="{'background':timerDesign?.timeAndDayColor}"></div>
              </div>
              <div class="matrix-row" fxLayout="row">
                <div class="matrix-col" [ngStyle]="{'background':timerDesign?.timeAndDayColor}"></div>
                <div class="matrix-col" [ngStyle]="{'background':timerDesign?.timeAndDayColor}"></div>
                <div class="matrix-col" [ngStyle]="{'background':timerDesign?.timeAndDayColor}"></div>
              </div>
              <div class="matrix-row" fxLayout="row">
                <div class="matrix-col" [ngStyle]="{'background':timerDesign?.timeAndDayColor}"></div>
                <div class="matrix-col" [ngStyle]="{'background':timerDesign?.timeAndDayColor}"></div>
                <div class="matrix-col" [ngStyle]="{'background':timerDesign?.timeAndDayColor}"></div>
              </div>
              <div class="matrix-row" fxLayout="row">
                <div class="matrix-col" [ngStyle]="{'background':timerDesign?.timeAndDayColor}"></div>
                <div class="matrix-col" [ngStyle]="{'background':timerDesign?.timeAndDayColor}"></div>
                <div class="matrix-col" [ngStyle]="{'background':timerDesign?.timeAndDayColor}"></div>
              </div>
            </div>
          </span>
          <span
            [ngStyle]="{'color':timerDesign?.timeAndDayHeadingColor?timerDesign.timeAndDayHeadingColor:'#808080','font-size':timerDesign?.timeAndDayHeadingSize?timerDesign.timeAndDayHeadingSize+'px':'14px','font-family':timerDesign?.timeAndDayHeadingFontFamily?timerDesign.timeAndDayHeadingFontFamily:'Montserrat','font-weight':timerDesign?.timeAndDayHeadingBold?'bold':'normal'}">{{timerDesign?.timerSecondsHeading?timerDesign?.timerSecondsHeading:'Seconds'}}</span>
        </div>
      </div>

    </div>
  </div>
  <div class="timer-container" fxLayout="column" fxLayoutAlign="center center" *ngIf="templateName=='Flipper Timer'"
    [ngStyle]="{'background-color': timerDesign?.timerBackgroundColor ? timerDesign.timerBackgroundColor : '#FFFFFF',
  'box-shadow':timerDesign?.isStoreFront?'0px 0px 3px 1px rgba(177, 176, 176, 0.75)':'none'}">
    <!-- date and Time -->
    <div fxFlex.gt-sm="42%" class="align-flip-timer" fxLayout="row"
      [fxLayoutGap]="timerDesign?.spaceBetweenTime?timerDesign.spaceBetweenTime + 'px':'20px'">
      <div fxLayout="column" *ngIf="fliperDay>0">
        <div class="flip-clock">
          <span class="flip-clock__piece">
            <span
              [ngStyle]="{'font-family':timerDesign?.timeAndDayFontFamily ? timerDesign.timeAndDayFontFamily : 'Montserrat'}"
              class="flip-clock__card flip-card" id="days">
              <strong class="flip-card__top">{{fliperDay?fliperDay:'00'}}</strong>
              <strong class="flip-card__bottom_time" attr.data-value="{{fliperDay?fliperDay:'00'}}"></strong>
              <strong class="flip-card__back_time" attr.data-value="{{fliperDay?fliperDay:'00'}}"></strong>
              <strong class="flip-card__back-bottom" attr.data-value="{{fliperDay?fliperDay:'00'}}"></strong>
            </span>
          </span>
        </div>
        <span
          [ngStyle]="{'color':timerDesign?.timeAndDayHeadingColor ? timerDesign.timeAndDayHeadingColor : '#000000',
        'font-size': timerDesign?.timeAndDayHeadingSize ? timerDesign.timeAndDayHeadingSize +'px' : '14px',
      'font-weight':timerDesign?.timeAndDayHeadingBold ? 'bold':'normal', 'font-family':timerDesign?.timeAndDayHeadingFontFamily ? timerDesign.timeAndDayHeadingFontFamily : 'Montserrat'}"
          class="time_label">{{timerDesign?.timerDayHeading?timerDesign?.timerDayHeading:'DD'}}</span>
      </div>
      <div fxLayout="column">
        <div class="flip-clock">
          <span class="flip-clock__piece">
            <span
              [ngStyle]="{'font-family':timerDesign?.timeAndDayFontFamily ? timerDesign.timeAndDayFontFamily : 'Montserrat'}"
              class="flip-clock__card flip-card" id="hours">
              <strong class="flip-card__top">{{fliperHours?fliperHours:'00'}}</strong>
              <strong class="flip-card__bottom_time" attr.data-value="{{fliperHours?fliperHours:'00'}}"></strong>
              <strong class="flip-card__back_time" attr.data-value="{{fliperHours?fliperHours:'00'}}"></strong>
              <strong class="flip-card__back-bottom" attr.data-value="{{fliperHours?fliperHours:'00'}}"></strong>
            </span>
          </span>
        </div>
        <span
          [ngStyle]="{'color':timerDesign?.timeAndDayHeadingColor ? timerDesign.timeAndDayHeadingColor : '#000000',
        'font-size': timerDesign?.timeAndDayHeadingSize ? timerDesign.timeAndDayHeadingSize +'px' : '14px',
      'font-weight':timerDesign?.timeAndDayHeadingBold ? 'bold':'normal', 'font-family':timerDesign?.timeAndDayHeadingFontFamily ? timerDesign.timeAndDayHeadingFontFamily : 'Montserrat'}"
          class="time_label">{{timerDesign?.timerHourHeading?timerDesign?.timerHourHeading:'HH'}}</span>
      </div>
      <div fxLayout="column">
        <div class="flip-clock">
          <span class="flip-clock__piece">
            <span
              [ngStyle]="{'font-family':timerDesign?.timeAndDayFontFamily ? timerDesign.timeAndDayFontFamily : 'Montserrat'}"
              class="flip-clock__card flip-card" id="minutes">
              <strong class="flip-card__top">{{fliperMinutes?fliperMinutes:'00'}}</strong>
              <strong class="flip-card__bottom_time" attr.data-value="{{fliperMinutes?fliperMinutes:'00'}}"></strong><!-- -->
              <strong class="flip-card__back_time" attr.data-value="{{fliperMinutes?fliperMinutes:'00'}}"></strong>
              <strong class="flip-card__back-bottom" attr.data-value="{{fliperMinutes?fliperMinutes:'00'}}"></strong><!-- -->
            </span>
          </span>
        </div>
        <span
          [ngStyle]="{'color':timerDesign?.timeAndDayHeadingColor ? timerDesign.timeAndDayHeadingColor : '#000000',
        'font-size': timerDesign?.timeAndDayHeadingSize ? timerDesign.timeAndDayHeadingSize +'px' : '14px',
      'font-weight':timerDesign?.timeAndDayHeadingBold ? 'bold':'normal', 'font-family':timerDesign?.timeAndDayHeadingFontFamily ? timerDesign.timeAndDayHeadingFontFamily : 'Montserrat'}"
          class="time_label">{{timerDesign?.timerMinuteHeading?timerDesign?.timerMinuteHeading:'MM'}}</span>
      </div>
      <div fxLayout="column">
        <div class="flip-clock">
          <span class="flip-clock__piece">
            <span
              [ngStyle]="{'font-family':timerDesign?.timeAndDayFontFamily ? timerDesign.timeAndDayFontFamily : 'Montserrat'}"
              class="flip-clock__card flip-card" [ngClass]="{'toggle': (fliperSeconds && fliperSeconds>0)||(fliperMinutes && fliperMinutes>0)||(fliperHours && fliperHours>0)||(fliperDay && fliperDay>0) }" id="Seconds">
              <strong class="flip-card__top">{{fliperSeconds?fliperSeconds:'00'}}</strong>
              <strong class="flip-card__bottom" attr.data-value="{{fliperSeconds? fliperSeconds:'00'}}"></strong><!-- -->
              <strong class="flip-card__back" attr.data-value="{{fliperSeconds? fliperSeconds:'00'}}"></strong>
              <strong class="flip-card__back-bottom" attr.data-value="{{fliperSeconds? fliperSeconds:'00'}}"></strong><!-- -->
            </span>
          </span>
        </div>
        <span
          [ngStyle]="{'color':timerDesign?.timeAndDayHeadingColor ? timerDesign.timeAndDayHeadingColor : '#000000',
        'font-size': timerDesign?.timeAndDayHeadingSize ? timerDesign.timeAndDayHeadingSize +'px' : '14px',
      'font-weight':timerDesign?.timeAndDayHeadingBold ? 'bold':'normal', 'font-family':timerDesign?.timeAndDayHeadingFontFamily ? timerDesign.timeAndDayHeadingFontFamily : 'Montserrat'}"
          class="time_label">{{timerDesign?.timerSecondsHeading?timerDesign?.timerSecondsHeading:'SS'}}</span>
      </div>
    </div>
  </div>
</div>