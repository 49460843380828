import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { REFERRAL_CODE_CONSTANT } from '../../constants/referral-code-constant';
import { ButtonInfo, ColumnsRef, CommonConstants, CommonDataService, Heading, ListProperties, PaginationService } from '@phase-ii/shared';
import { ReferralCodeService } from '../../services/referral-code.service';
import { ActivatedRoute, Params } from '@angular/router';
import { DatePipe, Location } from '@angular/common';
import { AffiliateAuthService } from '../../../auth/services/auth.service';
import { DialogService } from '@phase-ii/common';
import { CommissionResponse, GetChangedData } from '../../models/referralcode.model';
import { AffiliateUserDetails } from '../../../auth/models/auth.model';
@Component({
  selector: 'phase-ii-referral-commission-list',
  templateUrl: './referral-commission-list.component.html',
  styleUrls: ['./referral-commission-list.component.scss']
})
export class ReferralCommissionListComponent implements OnInit, OnDestroy {

  /**
  * Variable which is used to subscribe and unsubscribe.
  * @type {Subscription}
  */
  subscriptionObj = new Subscription();

  /**
  * Variable which is used to have all the needed variables.
  * @type {object}
  */
  pageDetails: {
    heading: Heading,
    buttonInfo: ButtonInfo[],
    columnRef: ColumnsRef[],
    isLoader: boolean,
    listLoader: boolean
    userdetailsId: number,
    currencySymbol: string;
    listProperty: ListProperties,
  } = {
      heading: null,
      buttonInfo: [{ name: 'Back', class: 'primary-button', method: 'navigationBack', disabled: false }],
      columnRef: null,
      isLoader: false,
      listLoader: false,
      userdetailsId: null,
      currencySymbol: null,
      listProperty: {
        columns: 4,
        dataOnly: true,
        rowElementClicked: false,
        isLocalPagination: false,
        serverSidePagination: true,
        suffixText: true,
        isNewImage: {
          isNew: false,
          url: './assets/no-commission-log-image.png',
          text: 'No commissions found'
        }
      }
    };

  /**
 * Object which is used for pagination details
 */
  paginationDetails: {
    offset: number,
    pageSize: number;
    itemsCount?: number;
    limit?: number;
  } = {
      pageSize: new CommonConstants().paginator.defaultPageSize,
      offset: 0,
      itemsCount: null,
      limit: 10
    };


  /**
  * It initalize object before component is loaded
  * @param referralCodeService used to get customer list
  * @param dialogService contains dialog related informartion
  * @param commonDataService used to detect changes
  **/
  constructor(private location: Location,
    private referralCodeService: ReferralCodeService,
    private route: ActivatedRoute,
    private commonDataService: CommonDataService,
    private paginationService: PaginationService,
    private affiliateAuth: AffiliateAuthService,
    private datePipe: DatePipe,
    private dialogService: DialogService
  ) {
  }

  /**
  * Angular onInit life cyle starts
  */
  ngOnInit() :void{
    this.pageDetails.isLoader = true;
    this.subscriptionObj.add(this.affiliateAuth.user.subscribe({
      next: (res: AffiliateUserDetails) => {
        if (res && res.id) {
          this.pageDetails.currencySymbol = res && res.currencyData && res.currencyData.currency && res.currencyData.currency.currencySymbol;
        }
      }
    }));

    this.subscriptionObj.add(this.route.params.subscribe((param: Params) => {
      if (param && param.id) {
        this.pageDetails.userdetailsId = this.commonDataService.getParam(param.id);
        this.getCommissionDetails(0, 2 * this.paginationDetails.pageSize, true);
      }
    }));
    this.variableInitialization();
  }


  /**
  *variableInitialization method user to initiaze.
  * @type {object}
  */
  variableInitialization():void {
    this.pageDetails.heading = {
      title: REFERRAL_CODE_CONSTANT && REFERRAL_CODE_CONSTANT.COMMISSION_HEADING && REFERRAL_CODE_CONSTANT.COMMISSION_HEADING.TITLE,
      description: REFERRAL_CODE_CONSTANT && REFERRAL_CODE_CONSTANT.COMMISSION_HEADING && REFERRAL_CODE_CONSTANT.COMMISSION_HEADING.DESCRIPTION
    }
    this.pageDetails.columnRef = REFERRAL_CODE_CONSTANT && REFERRAL_CODE_CONSTANT.COLUMNS_COMMISSION;
  }

  /**
  * getReferralCustomerlist method is used to get commission details
  * @type {object}
  */
  getCommissionDetails(offset?: number, limit?: number, isNew?: boolean): void {
    this.pageDetails.listLoader = true;
    const requestData = {
      offset: offset ? offset : 0,
      limit: limit ? limit : 5,
      id: this.pageDetails.userdetailsId
    };
    this.subscriptionObj.add(
      this.referralCodeService.getreferralCommission(requestData).subscribe({
        next: (res: CommissionResponse) => this.handleCommissionResponse(res, isNew),
        error: () => {
          this.pageDetails.isLoader = false;
          this.pageDetails.listLoader = false;
          this.dialogService.dialogMethod(
            REFERRAL_CODE_CONSTANT.DIALOG_MESSAGES.FAILED_COMMISSION_DETAILS,
            REFERRAL_CODE_CONSTANT.DIALOG_TYPE.FAILURE,
            true
          );
        }
      })
    );
  }

  /**
   * 
   * @param res Method to handle the commission response from the service
   * @param isNew 
   */
  handleCommissionResponse(res: CommissionResponse, isNew: boolean): void {
    if (res && res.commissionDetails[0] && res.commissionDetails[0].rows === null) {
      res.commissionDetails[0].rows = [];
    }
    if (res && res.commissionDetails[0] && res.commissionDetails[0].rows) {
      res.commissionDetails[0].rows.forEach(element => {
        if (element) {
          element.amount = element.amount ? this.formatCurrency(element.amount) : 'N/A';
          element.PlanAmount = element.PlanAmount ? this.formatCurrency(element.PlanAmount) : 'N/A';
          element.date = this.formatDate(element.date);
        }
      });

      this.paginationService.listPaginatorData.next({
        new: isNew,
        rows: res.commissionDetails[0].rows,
        count: res.commissionDetails[0].count
      });
    }

    this.pageDetails.listProperty.isNewImage.isNew = res&&res.commissionDetails[0]&&Number(res.commissionDetails[0].count )==0?true:false;
    this.pageDetails.isLoader = false;
    this.pageDetails.listLoader = false;
  }

  /**
   * 
   * @param amount  Method to format the currency amount
   * @returns 
   */
  formatCurrency(amount: string): string {
    return this.pageDetails.currencySymbol + Number(amount).toFixed(2);
  }

  /**
   * @param date 
   * @returns 
   * Method to format the date
   */
  formatDate(date: string): string | null {
    return this.datePipe.transform(date, 'yyyy-MM-dd HH:mm:ss');
  }

  /**
  * Method which is used for trigger the function of header buttons
  * @param event To get the function name 
  */
  buttonAction(event: string): void {
    if (event) {
      this[event]();
    }
  }

  /**
  * Method used to move to next or previous page in referralCode list
  * @param event hold the pagination details to get referralCode list
  */
  getChangedData(event: GetChangedData): void {
    if (event) {
      this.paginationDetails.offset = event.offset;
      this.paginationDetails.limit = event.limit;
      this.getCommissionDetails(this.paginationDetails.offset, this.paginationDetails.limit, false);
    }
  }

  /**
  * Method used to navigate the previous page
  */
  navigationBack():void {
    this.location.back();
  }

  /**
  * Component OnDestroy life cycle hook.
  * And unsubscribe all the subscriptions in the component.
  */
  ngOnDestroy():void {
    if (this.subscriptionObj) {
      this.paginationService.listPaginatorData.next(null);
      this.subscriptionObj.unsubscribe();
    }
  }
}
