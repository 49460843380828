import { Component, TemplateRef, ViewChild } from '@angular/core';
import { referralCodeConstant } from '../../constants/referral-code-constant';
import { ActionIcon, ButtonInfo, ColumnsRef, CustomAsyncValidatorService, CommonConstants, CommonService, Heading, ListProperties, PaginationService, CommonDataService } from '@phase-ii/shared';
import { Subscription } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { DialogService } from '@phase-ii/common';
import { ReferralCodeService } from '../../services/referral-code.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AffiliateAuthService } from '../../../auth/services/auth.service';
import { Router } from '@angular/router';


@Component({
  selector: 'phase-ii-referral-code-list',
  templateUrl: './referral-code-list.component.html',
  styleUrls: ['./referral-code-list.component.scss'],
})
export class ReferralCodeListComponent {
  /**
  * Variable which is used to subscribe and unsubscribe the subscriptions.
  * @type {Subscription}
  */
  subscriptionObj: Subscription = new Subscription();

  /**commonConstant used to acccess the common constant values. */

  commonConstant = new CommonConstants();

  /**referralCodeConstant used to acccess the referral code constant values. */

  referralCodeConstant = new referralCodeConstant();
  /**
  * Variable which is used to have all the needed variables.
  * @type {object}
  */
  pageDetails: {
    heading: Heading,
    columnsRef: ColumnsRef[],
    actions: any,
    listProperty: ListProperties,
    buttonInfo: ButtonInfo[],
    isLoader: boolean,
    filterData: any,
    isFilter: boolean,
    referralCodeListFilter: any,
    referralId: number,
    listLoader: boolean,
    actionIcons: ActionIcon[],
    customerId: number,
  } = {
      heading: null,
      columnsRef: null,
      actions: null,
      listProperty: {
        globalSearch: true,
        columns: 2,
        dataOnly: true,
        rowElementClicked: false,
        isLocalPagination: false,
        serverSidePagination: true,
        disableLocalSearch: false,
        searchPlaceHolder: 'Referral code',
        localSearchFields: ['referralCode'],
        isNewImage: {
          isNew: false,
          url: './assets/empty-referral-code.png',
          text: 'No referral code found',
        },
        cardBorderClass: 'purpleBorder',
        cardBorderCondition: {
          field: 'userCount',
          condition: {
            '0 User(s)': "pendingBorder"
          }
        },
        toggleConfirmationMessage: { on: "Are you sure you want to activate this referral code?", off: "Are you sure you want to deactivate this referral code?" }
      },
      buttonInfo: [{
        name: 'Add Referral Code', class: 'primary-button', method: 'addReferralCode', icon: 'add'
      }],
      isLoader: null,
      referralId: null,
      filterData: null,
      isFilter: false,
      referralCodeListFilter: [],
      listLoader: false,
      actionIcons: [],
      customerId: null,
    }

  /**
  * Object which is used for pagination details
  */
  paginationDetails: {
    offset: number,
    pageSize: number;
    itemsCount?: number;
    limit?: number;
  } = {
      pageSize: new CommonConstants().paginator.defaultPageSize,
      offset: 0,
      itemsCount: null,
      limit: 10
    };

  /**
  * Variable which is used to load button loader
  */
  isButtonLoader: boolean;

  /**
  * Variable used to store the searched information
  * @type {string}
  */
  searchText!: string;

  /**
  * Variable which is used to show the update text in button
  */
  isUpdate: boolean;

  /**
  * Variable to find toggle failure
  */
  toggleFailed = false;

  /**
  * used to display filter options
  */
  filterOption = ['Active', 'Inactive']

  /**
  * Variable which is used to view the actions in dialog
  * @type {any}
  */
  dialogRef: any;

  /**
  * Declaring form group
  * @type {FormGroup}
  */
  referralForm: UntypedFormGroup;

  /**
  * Variable which is used to list the products in viewchild properties
  * @type {TemplateRef}
  */
  @ViewChild('addeditReferral', { static: true }) addeditReferral: TemplateRef<any>;

  /**
   * @param affiliateAuth To aceess method from Affiliate auth service
   * @param dialog To access dialog
   * 
   * @param referralCodeService To access methods from referral code service 
   * @param paginationService To access methods from pagination service
   * @param dialogService To access methods from dialog service
   * @param _snackBar To access snackbar
   * @param commonService To access methods from common service
   */
  constructor(
    private dialog: MatDialog,
    private referralCodeService: ReferralCodeService,
    private paginationService: PaginationService,
    private dialogService: DialogService,
    private router: Router,
    private _snackBar: MatSnackBar,
    private commonService: CommonService,
    private affiliateAuth: AffiliateAuthService,
    private commonDataService: CommonDataService,

  ) {
  }

  /**
  * Component OnInit life cycle hook 
  */
  ngOnInit() {
    this.pageDetails.isLoader = true;
    this.subscriptionObj.add(this.affiliateAuth.user.subscribe((res: any) => {
      if (res && res.id) {
        this.pageDetails.customerId = res.id;
      }
    }));
    this.pageDetails.columnsRef = this.referralCodeConstant && this.referralCodeConstant.columnsForReferral;
    this.pageDetails.heading = {
      title: this.referralCodeConstant && this.referralCodeConstant.heading && this.referralCodeConstant.heading.title,
      description: this.referralCodeConstant && this.referralCodeConstant.heading && this.referralCodeConstant.heading.description,
    }
    this.pageDetails.actions = this.referralCodeConstant && this.referralCodeConstant.actions;
    this.pageDetails.referralCodeListFilter = this.referralCodeConstant && this.referralCodeConstant.referralCodeListFilter;
    this.pageDetails.actionIcons = this.referralCodeConstant && this.referralCodeConstant.actionIcons;
    this.getReferralCode(0, 2 * this.paginationDetails.pageSize, true, this.searchText, this.pageDetails.filterData);
  }

  /**
  * Method which is used to get referral code datas.
  */
  getReferralCode(offset?: number, limit?: number, isNew?: boolean, searchText?: any, filterData?: any) {
    this.pageDetails.listLoader = true;
    const data = {
      offset: offset ? offset : 0,
      limit: limit ? limit : 5,
      searchText: searchText ? searchText : '',
      filterData: filterData ? JSON.stringify(filterData) : null
    }
    this.subscriptionObj.add(this.referralCodeService.getReferralCode(data).subscribe((res: any) => {
      if (res && res.referralCodeData && res.referralCodeData.rows) {
        res.referralCodeData.rows.forEach(element => {
          if (element) {
            element['userCount'] = element.userCount ? element.userCount + ' User(s)' : 0 + ' User(s)';
          }
        });
        if (!this.searchText && !this.pageDetails.filterData)
          this.pageDetails.listProperty.isNewImage.isNew = res.referralCodeData.rows == 0 ? true : false;
        this.paginationDetails.itemsCount = res.referralCodeData.count;
        this.paginationService.listPaginatorData.next(
          {
            new: isNew,
            rows: res.referralCodeData.rows,
            count: this.paginationDetails.itemsCount
          });
      }
      else {
        this.dialogService.openDialog({
          header: this.commonConstant.dialogHeader.failure,
          message: this.referralCodeConstant.dialogMessages.referralCodeFailure,
          actionType: this.commonConstant.dialogType.failure,
          button: { right: this.commonConstant.buttonText.ok }
        });
      }
      this.pageDetails.listLoader = false;
      this.pageDetails.isLoader = false;
    }, (err) => {
      if (err) {
        this.pageDetails.listLoader = false;
        this.pageDetails.isLoader = false;
        this.dialogService.openDialog({
          header: this.commonConstant.dialogHeader.failure,
          message: this.referralCodeConstant.dialogMessages.referralCodeFailure,
          actionType: this.commonConstant.dialogType.failure,
          button: { right: this.commonConstant.buttonText.ok }
        });
      }
    }
    ));
  }

  /**
  * Method which is used to open add referral code dialog
  */
  addReferralCode() {
    this.isUpdate = false;
    this.formInitialize();
    this.dialogRef = this.dialog.open(this.addeditReferral, {
      width: '400px',
      disableClose: true
    });
  }

  /**
  * Method which is used to initialize form.
  */
  formInitialize() {
    this.referralForm = new UntypedFormGroup({
      referralCode: new UntypedFormControl(null,
        {
          validators: [Validators.required, Validators.maxLength(20), Validators.minLength(6)],
          asyncValidators: CustomAsyncValidatorService.asyncCheckDuplicateValidation(
            this.commonService, 'referralCode', 'referralCode', null, null, false)
        }
      ),
      isActive: new UntypedFormControl(true)
    })
  }

  /**
  * Method which is used for trigger the function of header buttons
  * @param event To get the function name 
  */
  buttonAction(event: any): void {
    if (event) {
      this[event]();
    }
  }

  /**
  * Method which can be handle the value emitted from common card list.
  * @param event To get the selected event
  */
  listAction(event: any): void {
    if (event === 'filter') {
      this.fieldForFilter();
    }
    else if (event && event.method) {
      this[event.method](event);
    }
  }

  /**
  * Method which is used to show filter drawer.
  */
  fieldForFilter() {
    this.pageDetails.isFilter = !this.pageDetails.isFilter;
    if (!this.pageDetails.isFilter && this.pageDetails.filterData) {
      this.closeFilter();
    }
  }

  /**
  * method using toggle used to change the status
  */
  toggleChanged(event: any) {
    if (event && event.data && event.data.id) {
      this.pageDetails.referralId = event.data.id;
      const data = {
        isActive: !event.data.isActive
      };
      this.subscriptionObj.add(this.referralCodeService.updateReferralCode(this.pageDetails.referralId, data).subscribe((response: any) => {
        if (response) {
          this.dialogService.dialogMethod(event.data.isActive ? this.referralCodeConstant.snackbarMessages.successDeactivate : this.referralCodeConstant.snackbarMessages.successActivate, this.referralCodeConstant.dialogType.success, true);
          if (this.searchText) {
            this.commonService.searchField.setValue(this.searchText);
          }
          this.getReferralCode(0, 2 * this.paginationDetails.pageSize, true, this.searchText, this.pageDetails.filterData);
        }
      }, (err) => {
        if (err) {
          this.toggleFailed = true;
          this.dialogService.dialogMethod(event.data.isActive ? this.referralCodeConstant.snackbarMessages.failureDeactivate : this.referralCodeConstant.snackbarMessages.failureActivate, this.referralCodeConstant.dialogType.failure, true);
          setTimeout(() => {
            this.toggleFailed = false;
          }, 200);
        }
      }));
    }
  }

  /**
  * Function which is triggered while close the filter 
  */
  closeFilter(): void {
    this.commonService.searchField.setValue(null);
    this.pageDetails.isFilter = false;
    this.pageDetails.filterData = null;
    this.searchText = null;
    this.getReferralCode(0, 2 * this.paginationDetails.pageSize, true, this.searchText, this.pageDetails.filterData);
  }

  /**
  * Method used to show output of filter component.
  * @param event To get the selected event
  */
  onFilterSelected(event: any): void {
    this.pageDetails.filterData = event;
    this.getReferralCode(0, 2 * this.paginationDetails.pageSize, true, this.searchText, this.pageDetails.filterData);
  }

  /**
  * Method used to move to next or previous page in referralCode list
  * @param event hold the pagination details to get referralCode list
  */
  getChangedData(event: any): void {
    if(event){   
      this.paginationDetails.offset = event.offset;
      this.paginationDetails.limit = event.limit;
      this.getReferralCode(event.offset, event.limit, false, this.searchText, this.pageDetails.filterData);
    }
  }

  /**
  * Method which is used for search in referral code list
  *  @param event to store search text
  */
  search(event: any): void {
    this.searchText = event.trim();
    this.getReferralCode(0, 2 * this.paginationDetails.pageSize, true, this.searchText, this.pageDetails.filterData);
  }

  /**
  * Used to generate referral code
  * @param length 
  */
  generateReferralCode(length: number) {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
    let code = '';

    for (let i = 0; i < length; i++) {
      const random_index = Math.floor(Math.random() * characters.length);
      code += characters[random_index]
    }
    if (this.referralForm.get('referralCode')) {
      this.referralForm.get('referralCode').setValue(code);
      this.referralForm.get('referralCode').markAsDirty();
    }
  }

  /**
  * Function used to save referral code details while save and update referral code.
  */
  onSaveReferral() {
    this.isButtonLoader = true;
    if (this.referralForm && this.referralForm.valid) {
      const data = {
        isActive: this.referralForm.value.isActive,
        referralCode: this.referralForm.value.referralCode,
        userId: this.pageDetails.customerId
      };
      this.subscriptionObj.add(this.referralCodeService.createReferralCode(data).subscribe((res: any) => {
        if (res) {
          this.dialogRef.close();
          this.isButtonLoader = false;
          this.dialogService.dialogMethod(res.message, this.commonConstant.dialogType.success, true);
          this.getReferralCode(0, 2 * this.paginationDetails.pageSize, true, this.searchText, this.pageDetails.filterData);
        }
      }, (err) => {
        this.isButtonLoader = false;
        this.dialogRef.close();
        this.dialogService.dialogMethod(this.referralCodeConstant.dialogMessages.referralSave, this.commonConstant.dialogType.failure, true);
      }))
    }
  }

  /**
  * method which is used to copy the referral code
  * @param event to get the referral code details which is used to copy referral code
  */
  onCopy(event: any) {
    const el = document.createElement('textarea');
    el.value = event && event.data && event.data.referralCode;
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
    this.dialogService.dialogMethod(this.referralCodeConstant.dialogMessages.copyReferralCode, this.referralCodeConstant.dialogType.success, true);
  }

  /**
  * Method which is used to navigate to referral code customer list page
  * @param event to get referral code related details
  */
  viewCustomersDetails(event) {
    if (event && event.data && event.data.id) {
      const data: any = this.commonDataService.setParam(event.data.id);
      this.router.navigate(['app/referral-customer-list', data]);
    }
  }

  /**
  * method which is used to close the snackbar
  */
  dismiss() {
    this._snackBar.dismiss();
  }

  /**
  * Method which is used for close all the dialog box and reset the form.
  */
  onClose(): void {
    if (!this.referralForm.pristine) {
      const dialogRef1 = this.dialogService.openDialog({
        header: this.commonConstant.dialogHeader.confirmation,
        message: this.referralCodeConstant.dialogMessages.referralDialogClose,
        actionType: this.commonConstant.dialogType.confirmation,
        button: { right: "Okay", left: "Cancel" },
        disableClose: true
      });
      this.subscriptionObj.add(
        dialogRef1.afterClosed().subscribe((result: boolean) => {
          if (result) {
            this.pageDetails.referralId = null;
            this.isUpdate = false;
            this.referralForm.reset();
            this.dialogRef.close();
          }
        })
      );
    }
    else {
      this.pageDetails.referralId = null;
      this.isUpdate = false;
      this.referralForm.reset();
      this.dialogRef.close();
    }
  }

  /**
  * Component OnDestroy life cycle hook.
  * And unsubscribe all the subscriptions in the component.
  */
  ngOnDestroy() {
    if (this.subscriptionObj) {
      this.paginationService.listPaginatorData.next(null);
      this.subscriptionObj.unsubscribe();
    }
  }
}
