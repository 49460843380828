<!-- <div style="margin: 5%;"> -->
<mat-card class="mat-elevation-z2">
    <img *ngIf="!storeName?.value" src="./assets/down.png" class="align-center" style="height: 200px;"
        alt="noStoreSelected">

    <p class="text-align" fxLayout="row" fxLayoutAlign="center center" *ngIf="!storeName?.value">
        No store is chosen here. Kindly choose one store to proceed further.
    </p>
    <div fxLayout="row" fxLayoutAlign="center center">
        
        <mat-form-field fxFlex="55%">
            <mat-label>Select store</mat-label>
        
            <mat-select [formControl]="storeName" data-control="storeName" (closed)="onStoreFilterClose()">

                <div fxLayout="row" class="search">
                    <input matInput style="height: 20px;padding: 12px;margin-top: 8px;" fxFlex="90%"
                        fxFlex.lt-md="92%" fxFlex.lt-sm="85%" data-control="searchStore"
                        placeholder="Search Store" [formControl]="storeSearchFilter"
                        (keyup)="onStoreSearchFilter($event.target.value)"
                        (keydown.space)="$event.stopPropagation();">
                    <mat-icon class="search-close-icon" *ngIf="storeSearchFilter?.value" fxFlex="10%"
                        fxFlex.lt-md="8%" fxFlex.lt-sm="15%" (click)="onStoreFilterClose()"
                        data-control="closeIconStore">
                        close
                    </mat-icon>
                </div>
                <mat-divider></mat-divider>
                <mat-option  data-control="no-Store"
                    *ngIf="storeDetails?.filteredStore?.length === 0" [disabled]="true">No Store
                    Found
                </mat-option>
                <mat-option *ngFor="let info of storeDetails?.filteredStore" [value]="info">

                    {{info?.name}}
                </mat-option>
            </mat-select>

        </mat-form-field>
    </div><br>
</mat-card>
<!-- </div> -->