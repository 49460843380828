<cen-loader-vii *ngIf="pageDetails?.listLoader || pageDetails?.isLoader">
</cen-loader-vii>
<div *ngIf="!pageDetails?.isLoader">
    <phase-ii-common-store-select *ngIf="isSuperAdmin" (storeInfo)="selectedStore($event)">
    </phase-ii-common-store-select><br>
    <phase-ii-header-page [heading]="pageDetails?.heading">
    </phase-ii-header-page>
    <div fxLayout="row" fxLayoutAlign="center center" class="common-page-container">
        <mat-card fxFlex="100" *ngIf="storeId">
            <phase-ii-common-card-v-ii [needPaginator]="true" [paginatorVersion]="2"
                [errorHandler]="pageDetails?.errorHandler" [listProperties]="pageDetails?.listProperty"
                [columnsRef]="pageDetails?.columnRef" (actionClicked)="listAction($event)"
                (valueChanged)="getChangedData($event)" (iconClicked)="cardActions($event)"
                (paginatorData)="getPaginatorData($event)" [rightTopFilter]="pageDetails?.options"
                (rightFilterChanged)="changeStatus($event)">
            </phase-ii-common-card-v-ii>
        </mat-card>
    </div>
    <ng-template #reason let-data>
        <mat-card fxLayout="column">
            <div fxLayout="row" fxFlex="100">
                <div fxFlex="95">
                    <h3>Reason</h3>
                </div>
                <div fxFlex="5">
                    <mat-icon class="closeIcon" (click)="closeReasonDisplay()" data-control="image-close">clear
                    </mat-icon>
                </div>
            </div>
            <div fxLayout="row" fxFlex="100">
                <h5>{{data.reasonForFailure?data.reasonForFailure:"No Reason Available"}}</h5>
            </div>
        </mat-card>
    </ng-template>
</div>