import { BehaviorSubject, of } from 'rxjs';
import { HeaderService } from '@phase-ii/auth';
import { catchError, map } from 'rxjs/operators';
import { Inject, Injectable } from '@angular/core';
import { BroadcastChannel } from 'broadcast-channel';
import { API, HttpRoutingService } from '@phase-ii/common';

@Injectable({
  providedIn: 'root'
})
export class AffiliateAuthService {
  /**
   * BehaviorSubject used to get the user details.
   */
  user = new BehaviorSubject<any>(null);
  /**
   * Component constructor to inject the required services
   */
  environment: any;
  /**
   * Variable to store the access tokens,refresh token and lambda access token
   */
  tokens = { accessToken: null, refreshToken: null };
  /**
   * Variable user to send Data between Two Browser Tabs
   */
  broadCast = new BroadcastChannel('authenticate');
  /**
   * Component constructor to inject the required services.
   * @param environment 
   * @param headerService 
   * @param httpRoutingService 
   */
  constructor(
    @Inject('environment') environment,
    private headerService: HeaderService,
    private httpRoutingService: HttpRoutingService
  ) {
    this.environment = environment;
  }
  /**
   * Method is used to set tokens in local storage
   * @param data 
   */
  setLocalStorage(data) {
    this.headerService.setHeaders('default', 'Authorization', data.accessToken);
    localStorage.setItem('jwt_token', data.accessToken);
    localStorage.setItem('isSuperadmin', data.user.isSuperadmin);
  }
  /**
   * Method used to get base 64 string.
   * @param userName holds the user name.
   * @param password holds the password.
   * @param url holds the url.
   * @param key holds the key.
   */
  getBase64String(userName, password?, url?, key?,version:string ='v1') {
    let authdata;
    if (password) {
      authdata = 'Basic ' + window.btoa(userName + ':' + password);
    } else {
      authdata = 'Basic ' + window.btoa(userName);
    }
    const apiUrl=this.httpRoutingService.generateUrl(url,version);
    this.headerService.setHeaders(apiUrl, key, authdata);
  }
  /**
   * Method to verify User
   * @param data
   * @returns the response
   */
  verifyUser(data) {
    return this.httpRoutingService.postMethod(API.AFFILIATE_VERIFY_USER, data);
  }
  /**
  * Method which is used make to API call for signin.
  * @param data holds the signin details.
  * @returns the response.
  */
  signin(data) {
    const key = 'Registration';
    this.getBase64String(data.email, data.password, API.AFFILIATE_SIGNIN, key);
    delete data.email;
    delete data.password;
    return this.httpRoutingService.postMethod(API.AFFILIATE_SIGNIN, data).pipe(map((res: any) => {
      if (res.result) {
        this.setLocalStorage(res.result);
        this.tokens.accessToken = res.result.accessToken;
        this.user.next(res.result.user);
      }
      return res;
    }))
  }
  /**
   * Method which is used to register new user.
   * @param userInfo 
   * @returns the response
   */
  registerUser(userInfo: any) {
    this.getBase64String(userInfo.email, userInfo.password, API.AFFILIATE_SIGNUP, 'Registration');
    delete userInfo.email
    delete userInfo.password
    return this.httpRoutingService.postMethod(API.AFFILIATE_SIGNUP, userInfo);
  }
  /**
   * Method used to check mail Existence
   * @param email 
   * @returns the response
   */
  checkMailAlreadyExist(email: string) {
    return this.httpRoutingService.getMethod(API.AFFILIATE_CHECK_MAIL, { email: email }).pipe(map((res) => {
      if (res['mailCheck']) {
        res['emailExists'] = true;
      }
      return res;
    }),
      catchError((err) => {
        return of({ error: true });
      }))
  }
  /**
   * Method to send mail
   * @param data 
   * @returns the response
   */
  sendEmail(data) {
    return this.httpRoutingService.postMethod(API.AFFILIATE_FORGOT_PASSWORD, data);
  }
  /**
   * Method to configure password
   * @param data 
   * @returns the response
   */
  resetPassword(data) {
    return this.httpRoutingService.postMethod(API.AFFILIATE_RESET_PASSWORD, { token: data });
  }
  /**
   * Method to verify token for admin
   * @param data
   * @returns the response
   */
  verifyToken(data) {
    return this.httpRoutingService.postMethod(API.AFFILIATE_VERIFY_PASSWORD_TOKEN, data);
  }
  /**
   * Method which is used to API call for check password.
   * @param data 
   * @returns the response
   */
  checkPasswordMatchForAdmin(data) {
    const key = 'Registration';
    this.getBase64String(data.email, data.password, API.AFFILIATE_CHECK_PASSWORD_EXISTS,
      key
    );
    return this.httpRoutingService.getMethod(API.AFFILIATE_CHECK_PASSWORD_EXISTS).pipe(map((res) => {
      return res;
    }))
  }
  /**
   * Method to get the current user details
   * @returns the current user details
   */
  getCurrentUser() {
    const token = localStorage.getItem('jwt_token');
    this.headerService.setHeaders('default', 'Authorization', token);
    return this.httpRoutingService.getMethod(API.AFFILIATE_USER_DETAILS).pipe(map((res: any) => {
      if (res && res.currentUser) {
        localStorage.setItem('jwt_token', res.currentUser.accessToken);
        this.headerService.setHeaders('default', 'Authorization', res.currentUser.accessToken);
        this.user.next(res.currentUser.user);
      } else {
        this.logout()
      }
      return res;
    }),
      catchError((err) => {
        this.logout();
        throw new Error(err);
      })
    )
  }
  /**
   * Method which is used to API call for get the token.
   */
  getToken(): string {
    const token = localStorage.getItem('jwt_token');
    return token;
  }
  /**
   * Method used to check whether user is authenticated or not
   */
  isAuthenticated() {
    if (this.getToken() && this.user.value) {
      return true;
    } else {
      return false;
    }
  }
  /**
   * Method which is used to API call for logout the user.
   */
  logout() {
    if (this.user.value)
      this.httpRoutingService.postMethod(API.AFFILIATE_SIGNOUT, { infoId: this.user.value && this.user.value.infoId }).subscribe(res => {
      });
    this.user.next(null);
    this.broadCast.postMessage("Logout");
    localStorage.removeItem('jwt_token');
    localStorage.removeItem('isSuperadmin');
    this.headerService.clearHeaders('default', 'Authorization');
    return true;
  }

  /**
    * 
    * @param data
    * @return the response object
    */
  changePassword(data) {
    return this.httpRoutingService.postMethod(API.AFFILIATE_CHNAGE_PASSWORD, data);
  }

  /**
   * Method to fetch details of the affiliate user.
   * @returns Observable with the API response containing affiliate user details.
   */
  getAffiliateUserDetails() {
    return this.httpRoutingService.getMethod(API.AFFILIATE_USER_DASHBOARD_DETAILS);
  }

  /**
   * Method to fetch payout details for a specific affiliate user.
   * @param userid - User ID for whom payout details are requested.
   * @returns Observable with the API response containing payout details.
   */
  getPayoutDetails(userid) {
    const url = this.httpRoutingService.replaceUrlParam(API.AFFILIATE_PAYOUT_LOG_DETAILS, { userId: userid });
    return this.httpRoutingService.getMethod(url);
  }

  /**
  * Method to update the user details.
  * @param data - User form data.
  * @param userid - User ID.
  * @returns Observable with the API response.
  */
  putAffiliateUserDetails(data, userid) {
    const url = this.httpRoutingService.replaceUrlParam(API.AFFILIATE_USER_UPDATED_DETAILS, { id: userid })
    return this.httpRoutingService.putMethod(url, {
      generalDetails: data
    });
  }

  /**
   * Method to retrieve bank account details for a specific user.
   * @param {any} userid - The user ID for which to fetch bank account details.
   * @returns {Observable} An Observable with the HTTP request to fetch user bank account details.
   */
  getUserBankAccountDetails(userid: any) {
    const url = this.httpRoutingService.replaceUrlParam(API.AFFILIATE_USER_BANK_ACC_DETAILS, { id: userid });
    return this.httpRoutingService.getMethod(url);
  }

  /**
   * Method to retrieve payout log details based on the provided data.
   * @param {any} data - The data object containing information for the payout log request.
   * @returns {Observable} An Observable with the HTTP request to fetch payout log details.
   */
  getPayoutLogDetails(data: any) {
    return this.httpRoutingService.postMethod(API.GET_PAYOUT_LOG, data);
  }

  /**
   * Method to retrieve login activity history for a specific user.
   * @param {any} userId - The user ID for which to fetch login activity history.
   * @param {any} data - Additional data for refining the login activity request.
   * @returns {Observable} An Observable with the HTTP request to fetch user login activity.
   */
  getLoginActivity(userId: any, data: any) {
    const url = this.httpRoutingService.replaceUrlParam(API.GET_AFFILIATE_USER_LOGIN_HISTORY, { id: userId })
    return this.httpRoutingService.getMethod(url, data)
  }

  /**
   * Method to deactivate a user account based on the provided user ID and data.
   * @param {any} userID - The user ID for which to deactivate the account.
   * @param {any} data - Additional data for the deactivation request.
   * @returns {Observable} An Observable with the HTTP request to deactivate the user account.
   */
  DeactivateAccount(userID, data) {
    const url = this.httpRoutingService.replaceUrlParam(API.DEACTIVATE_AFFILIATE_ACCOUNT, { id: userID })
    return this.httpRoutingService.putMethod(url, data);
  }
  /**
   * Method used to get policies
   * @returns response
   */
  getTerms() {
    return this.httpRoutingService.getMethod(API.AFFILIATE_GET_SETTINGS);
  }
}