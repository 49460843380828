import { Component, OnInit, PLATFORM_ID, Inject } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
/**
 * This component used to scroll top and bottom of the page.
 */
@Component({
  selector: 'phase-ii-scroll-top-bottom',
  templateUrl: './scroll-top-bottom.component.html',
  styleUrls: ['./scroll-top-bottom.component.scss']
})
export class ScrollTopBottomComponent implements OnInit {
  /**
    * Variable used to display the scroll to top fab icon
    * @type {boolean}
    */
  showTopScroll: boolean;
  /**
   * Variable used to display the scroll to bottom fab icon
   * @type {boolean}
   */
  showBottomScroll: boolean;
  /**
   * component constructor which is used to inject the required services.
   */
  constructor(@Inject(PLATFORM_ID) private platformId: object) { }
  /**
   * component onInit life cycle hook.
   */
  ngOnInit(): void {
    if (isPlatformBrowser(this.platformId))
      window.addEventListener('scroll', this.scrollEvent);
  }
  /**
   * Method which is used to get the scroll height dynamically inorder to change the stick-background UI.
   * @param event which holds scroll height.
   */
  scrollEvent = (event: any): void => {
    this.showTopScroll = event.srcElement.scrollingElement.scrollTop >= 300 ? true : false;
    this.showBottomScroll = event.srcElement.scrollingElement.scrollTop < 300 ? true : false;
  };
  /**
   * Method used to scroll to top and bottom.
   */
  scrollToCommon(): void {
    if (isPlatformBrowser(this.platformId)) {
      if (this.showTopScroll) {
        (function smoothscroll() {
          const currentScroll = document.documentElement.scrollTop || document.body.scrollTop;
          if (currentScroll > 0) {
            window.requestAnimationFrame(smoothscroll);
            window.scrollTo(0, currentScroll - currentScroll / 5);
          }
        })();
      } else if (this.showBottomScroll) {
        window.scrollTo(0, document.documentElement.scrollHeight || document.body.scrollHeight);
      }
    }
  }
}
