<cen-loader-vii *ngIf="isLoading"></cen-loader-vii>
<div *ngIf="!isLoading">
  <phase-ii-header-page [heading]="policyHeading"> </phase-ii-header-page>
  <div class="page-container">
    <ng-container *ngIf="terms;else elseBlock">
      <div class="policy" [innerHtml]="terms"></div>
    </ng-container>
    <ng-template #elseBlock>
      <div class="imgcenter">
        <img src="./assets/no-records-found.png" alt="noImage" />
        <p> The service agreement will be updated soon.</p>
      </div>
    </ng-template>
  </div>
</div>