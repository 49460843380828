import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
// import { DialogService } from '@phase-ii/common';
import { DialogService } from 'libs/common/src/lib/services/dialog.service';
import { AuthService } from '@phase-ii/auth';
import { Subscription, of } from 'rxjs';
import { filter, mergeMap } from 'rxjs/operators';
import { ColumnsRef, CommonConstants, ListProperties, ActionIcon, ButtonInfo, CommonService, PaginationService } from '@phase-ii/shared';
import { MatDialog } from '@angular/material/dialog';
import { startCase, camelCase } from 'lodash';
import { CommonAdminShardConstants } from '../../constants/shared-constant';
/**
 * MailLog, component which is used to list all the mail logs.
 *  */
@Component({
  selector: 'phase-ii-mail-logs',
  templateUrl: './mail-logs.component.html',
  styleUrls: ['./mail-logs.component.scss']
})
export class MailLogsComponent implements OnInit {
  /**
 * object of datas to be displayed
 */
  pageDetails: {
    heading: any,
    columnsRef: ColumnsRef[],
    listProperty: ListProperties,
    actions: any,
    isRestore: boolean,
    showDateRange: boolean,
    buttonInfo: ButtonInfo[],
    isLoader: boolean;
    sortArray: any,
    errorHandler: boolean,
    sortOptions: any,
    showFilterAndSort: boolean,
    listDataSource: any,
    productFilter: any,
    status: any,
    actionIcons: ActionIcon[],
    languageOptions: any
  } = {
      isLoader: false,
      heading: null,
      columnsRef: null,
      listProperty: null,
      actions: null,
      isRestore: false,
      showDateRange: false,
      buttonInfo: null,
      sortArray: [],
      errorHandler: false,
      sortOptions: null,
      showFilterAndSort: false,
      listDataSource: null,
      productFilter: null,
      status: null,
      actionIcons: null,
      languageOptions: null
    }
  /**
   * Variable which is used for set/reset page loader
   */
  isPageLoader: Boolean = false;
  /**
    * Variable which is used for find active tab index
    */
  activeTabIndex!: number;
  /**
  * To open the dialog box for showing the job details.
  * @type {ViewChild}
  */
  @ViewChild('preview', { static: true })
  /**
   * Variable which is used to view the job details.
   * @type {ViewChild}
   */
  preview!: TemplateRef<any>;
  /**
  * Variable which is used for getting product constants
  */
  CommonConstant = new CommonConstants();

  /**
   * variable used to reset data loader
   */
  dataLoader: boolean = false;

  /**
     * Variable which is used for store selected location id.
     */
  content!: any;
  /**
    * Variable which is used for subscription .
    */
  subscriptionObject: Subscription = new Subscription();
  /**
  * variable which is used for initiate pagination page size
  */
  paginationDetails: {

    offset: number,
    pageSize: number;
    itemsCount?: number;
    limit?: number;
  } = {

      pageSize: new CommonConstants().paginator.defaultPageSize,
      offset: 0,
      itemsCount: null,
      limit: 10
    };
  /**
* variable which is used for store the search text value
*/
  searchText!: string;
  /**
   * Array which is used for define material tabs
   */
  tabs = [{ id: 0, label: 'General mail logs' }, { id: 1, label: 'Custom mail logs' }];
  /**
   * Variable which is used for set location filter data (rightTopFilter).
   */
  options!: any;
  /**
   * Variable used to store the find the storeadmin value
   */
  isStoreAdmin: boolean = false;
  /**
* Variable which is used to store.
*/
  stores !: number;
  /**
* Variable which is used for Filter.
*/
  newFilter: boolean = false;
  /**
* Variable which is used for store the storeid while applying topright filter.
*/
  selectedStoreId: number = 0;
  /**
   * Variable which is used for store the selected language while applying topright language filter.
   */
  selectedLanguage: string = 'en';
  /**
 * Variable which is used to store filter values.
*/
  filterData: any = {};
  /**
* Variable which is used to store the value.
*/
  sharedConstant = new CommonAdminShardConstants();
  /**
   * Variable which is used to store template Name
   */
  templateFilter : any = [];
    /**
     * used to get the log values and template name
     */
  getOneTemplate:any;
  /**
   * Initiate when component loading.
   * @param paginationService paginationService
   * @param dialogService dialogService
   * @param commonService commonService
   * @param authService authService
   * @param dialog dialog
   */
  constructor(
    private paginationService: PaginationService,
    private dialogService: DialogService,
    private commonService: CommonService,
    private authService: AuthService,
    private dialog: MatDialog,
  ) {

  }
  /**
    *  Component OnInit life cycle hook .
    * */
  ngOnInit(): void {
    this.pageDetails.isLoader = true;
    this.subscriptionObject.add(this.authService.user.pipe(filter((res: any) => {
      if (res && res.storeId) {
        this.selectedStoreId = res.storeId;
        this.isStoreAdmin = true;
      }
      return true;
    }),
    mergeMap(() => {
      return this.selectedStoreId ? this.commonService.getAllStoreLanguages(this.selectedStoreId) : this.commonService.getAllLanguages();
    }), mergeMap((res) => {
      if ((res['allLanguages'] && res['allLanguages'].rows) || (res['allStoreLanguages'] && res['allStoreLanguages'].rows)) {

        this.pageDetails.languageOptions = {
          values: this.selectedStoreId ? res['allStoreLanguages'].rows : res['allLanguages'].rows, 
          matLabelField: "langName", 
          matValueField: 'langCode',
          label: 'Select language',
          defaultOption: this.selectedLanguage
        };
      }
      return this.commonService.getAllDefaultTemplates();
    }), filter((res) => {
      if (res && res['response'] && res['response'].rows) {
        this.templateFilter = res['response'].rows;
        this.templateFilter.forEach(value => {
          value.templateName = startCase(camelCase(value.templateName));
        })
        if (this.isStoreAdmin) {
          this.activeTabIndex = 0;
          this.variableInitialization();
          this.getAllMailLogs(0, 2 * this.paginationDetails.pageSize, true);
        }
        return true;
      } else {
        this.pageDetails.isLoader = false;
        this.paginationService.listPaginatorData.next(
          {
            rows: [],
            count: 0,
            new: true
          });
        this.pageDetails.errorHandler = true;
        return false;
      }
    }), mergeMap(() => {
      if (!this.isStoreAdmin) {
        return this.commonService.getAllStoreNames();
      }
      return of({});
    })).subscribe((res) => {
      if (res && res['stores'] && res['stores'].length) {
        this.stores = res['stores'];
        this.options = {
          values: this.stores,
          label: 'Select store',
          defaultOption: 0,
          matValueField: 'id',
          matLabelField: 'name'
        };
        this.options.values.unshift({ id: 0, name: 'All' });
        this.activeTabIndex = 0;
        this.variableInitialization();
        this.getAllMailLogs(0, 2 * this.paginationDetails.pageSize, true);
      }
      else {
        this.activeTabIndex = 0;
        // this.pageDetails.isLoader = false;
        if (!this.isStoreAdmin) {
          this.dialogService.dialogMethod(this.sharedConstant.dialogMessages.storeGetsFailed, this.sharedConstant.dialogType.failure, true);
        }

      }
    }, (err) => {
      this.pageDetails.isLoader = false;
      this.dialogService.dialogMethod(this.sharedConstant.dialogMessages.getFailed, this.sharedConstant.dialogType.failure, true);
    }))
  }
  /**
 * Function which is used for intialize all variables of component
 */
  variableInitialization(): void {
    this.pageDetails.actionIcons = [
      {
        iconName: 'visibility',
        method: 'onViewPreview',
        toolTip: 'Preview',
        iconColor: '#0492c2',
      },
    ];
    this.pageDetails.status = [
      { displayName: "Success", value: 'success' },
      { displayName: "Failed", value: 'failed' },
    ];
    this.pageDetails.heading = {
      title: this.sharedConstant.mailLog.title,
      description: this.sharedConstant.mailLog.description,
      isHeaderIcon: true,
      iconName: 'link',
      iconLink: 'https://help.getzenbasket.com/mail_logs.html',
      iconToolTip: 'Help',
    };
    this.onColumn();
    this.pageDetails.listProperty = {
      columns: 3,
      dataOnly: true,
      rowElementClicked: true,
      searchPlaceHolder: this.activeTabIndex === 0 ? 'To, Template name' : 'To',
      globalSearch: true
    };
    this.pageDetails.actions =
      [
        { name: 'filter_list', value: 'filter', toolTip: "Filter", isConditionApplied: true, conditionType: 'DATA_EMPTY' }
      ];
    this.pageDetails.sortOptions = {
      toolTip: 'Sort MailLog',
      isConditionApplied: true,
      conditionType: 'DATA_EMPTY',
      values: [
        { label: 'Recently send first', column: 'createdAt', order: 'DESC' },
        { label: 'Recently send last', column: 'createdAt', order: 'ASC' },
        { label: 'Template name  A to Z', column: 'templateName', order: 'ASC' },
        { label: 'Template name  Z to A', column: 'templateName', order: 'DESC' }
      ]
    };
  }
  /**
  * Method used to get the next offset and limit.
  * @param event return offset and limit.
  */
  getChangedData(event: any): void {
    this.paginationDetails.offset = event.offset;
    this.paginationDetails.limit = event.limit;
    this.dataLoader = true;
    this.getAllMailLogs(this.paginationDetails.offset, this.paginationDetails.limit, false);
  }
  /**
  * Method used to get the next offset and limit.
  * @param event return offset and limit.
  */
  getPaginatorData(event: any): void {
    this.paginationDetails.limit = event.pageSize;
    this.paginationDetails.offset = event.pageIndex * event.pageSize;
  }

  /**
   * Function which is used for open filter.
   * @param event 
   */
  openFilter(event: any): void {
    // this.pageDetails.isLoader = true;
    const iconClicked = this.CommonConstant && this.CommonConstant.icons;
    if (event === iconClicked.filter) {
      this.filterInitiate();
    }
  }
  /**
  * Function which is used for initiate filter variables.
  */
  filterInitiate(): void {
    if (this.pageDetails.showFilterAndSort) {
      this.closeFilter();
    }
    else {
      this.pageDetails.productFilter = [
        { title: "Custom Date", type: "dateRange", field: "customDateRange", isMaxDateToday: true },
        { title: "Status", type: "radio", field: "status", options: this.pageDetails.status, displayName: "displayName", valueName: "value" },
      ];
      if (this.activeTabIndex === 0) {
        this.pageDetails.productFilter.unshift({ title: "Template Name", type: "selector", field: "templateUuid", options: this.templateFilter, displayName: "templateName", valueName: "uuid" })
      };
      this.pageDetails.showFilterAndSort = true;
      // this.pageDetails.isLoader = false;
    }
  }
  /**
   * Function which is used for getting log details while changing tab.
   * @param event 
   */
  changeTab(event: any): void {
    this.filterData = {};
    this.isPageLoader = true;
    this.dataLoader = true;
    this.searchText = null;
    this.commonService.searchField.setValue(null);
    if (!this.isStoreAdmin) {
      this.options.defaultOption = 0;
      this.selectedStoreId = 0;
    }

    if(this.pageDetails.languageOptions){
      this.pageDetails.languageOptions.defaultOption = this.selectedLanguage;
    }

    if (event !== null) {
      this.activeTabIndex = (event === 0) ? 0 : 1;
      if (this.pageDetails.sortOptions.values) {
        this.pageDetails.sortOptions.values = this.activeTabIndex === 0 ? [
          { label: 'Recently send first', column: 'createdAt', order: 'DESC' },
          { label: 'Recently send last', column: 'createdAt', order: 'ASC' },
          { label: 'Template name  A to Z', column: 'templateName', order: 'ASC' },
          { label: 'Template name  Z to A', column: 'templateName', order: 'DESC' }] :
          [{ label: 'Recently send first', column: 'createdAt', order: 'DESC' },
          { label: 'Recently send last', column: 'createdAt', order: 'ASC' }
          ];
      }
      this.onColumn();
      if (this.pageDetails.showFilterAndSort)
        this.pageDetails.showFilterAndSort = !this.pageDetails.showFilterAndSort;
      this.getAllMailLogs(0, 2 * this.paginationDetails.pageSize, true);
    }
  }
  /**
   * Function which is used for getting data while seraching.
   * @param event returns serach text value.
   */
  onSearch(event: any): void {
    if (event && event.length) {
      this.subscriptionObject.add(this.commonService.searchSubject
        .pipe(
          filter((value: any) => {
            return (this.searchText != value) ? value : false;
          })
        )
        .subscribe((searchText: any) => {
          this.searchText = searchText;
          this.dataLoader = true;
          this.getAllMailLogs(
            0,
            2 * this.paginationDetails.pageSize,
            true
          );
        }));
    } else {
      this.searchText = null;
      this.getAllMailLogs
        (
          0,
          2 * this.paginationDetails.pageSize,
          true,
          ''
        );
    }
  }
  /**
     * Function which is used for trigger quantity updation function.
     * @param event returns method of edit action.
     */
  editAction(event: any): void {
    if (event.method) {
      this[event.method](event);
    }
  }
  /**
   * Function which is used for getting columnsRef while switching the tab.
   * @param event returns the respective one.
   */
  onColumn(): void {
    this.pageDetails.columnsRef = (this.activeTabIndex === 0) ? this.sharedConstant.columnsForMailLog : this.sharedConstant.columnsForMailLog1;
  }
  /**
    * Function which is used for view action.
    * @param event 
    */

  onViewPreview(event: any): void {
    this.dataLoader = true;
    this.subscriptionObject.add(this.commonService.getOneMailLogs( event?.data?.id).subscribe((res: any) => {
      this.getOneTemplate = res && res.response;
      this.getOneTemplate.templateName = event?.data?.templateName;
      this.dialog.open(this.preview, {
        disableClose: true,
        width: '700px',
        autoFocus: false
      });
      document.getElementById('content').innerHTML = this.getOneTemplate?.maillogstatus[0]?.content;
      this.dataLoader = false;
    }, (err) => {
      this.dialogService.dialogMethod(this.sharedConstant.dialogMessages.getOneMail, this.sharedConstant.dialogType.failure, true);
      this.dataLoader = false;
    }));
  }

  /**
   * Function which is used for close filter and destroy filter variable values.
   */
  closeFilter(): void {
    this.dataLoader = true;
    if (this.pageDetails.showFilterAndSort) {
      this.pageDetails.showFilterAndSort = false;
      this.filterData = {};
      this.getAllMailLogs(0, 2 * this.paginationDetails.pageSize, true,
        ((this.searchText !== null) ? this.searchText : ''));

    }
  }
  /**
   * Function which is used for get product details of changed location.
   * @param event returns location id while change location.
   */
  changeLocation(event: any): void {
    if (!this.isStoreAdmin) {
      this.options.defaultOption = event;
    }
    // this.isPageLoader = true;
    this.dataLoader = true;
    this.selectedStoreId = event;
    this.pageDetails.sortArray = [];
    this.pageDetails.listDataSource = [];
    if (this.selectedStoreId >= 0) {
      this.getAllMailLogs(0, 2 * this.paginationDetails.pageSize, true,
      );
    }
  }

  /**
   * Function used to retrieve mail log details after a language change.
   * @param event returns language details upon change language.
   */
  onLanguageChange(event: any): void {
    if (event) {
      this.selectedLanguage = event;
      this.getAllMailLogs(0, 2 * this.paginationDetails.pageSize, true);
    }
  }

  /**
     * Function which is used for pagination.
     */
  processListDataSource(res: any, count: any, isNew?: boolean) {
    if (res) {
      this.pageDetails.listDataSource = res;
      this.pageDetails.listDataSource.forEach((item: any) => {
        if (item) {
          item.templateName = (item.mailtemplate && item.mailtemplate.templateName) ? startCase(camelCase(item.mailtemplate.templateName)) : 'N/A';
          item.from = item.from ? item.from : 'N/A';
          item.to = item.to ? item.to : 'N/A';
          item.status = item.isSuccess ? 'Success' : 'Failed';
          item.date = item.createdAt ? (item.createdAt) : '';
          item.time = item.createdAt ? (item.createdAt) : '';
        }
      });
      this.paginationService.listPaginatorData.next({
        rows: this.pageDetails.listDataSource,
        count: count,
        new: isNew
      });
    }
  }
  /**
 * Method which is used to get all mail logs.
 * @param data has offset,limit .
 * @returns response.
 */
  getAllMailLogs(
    offset: number,
    limit: number,
    isNew: boolean,
    filterData?: string
  ): void {
    this.pageDetails.errorHandler = false;
    this.pageDetails.listProperty = {
      columns: 3,
      dataOnly: true,
      rowElementClicked: true,
      searchPlaceHolder: this.activeTabIndex === 0 ? 'To, Template Name' : 'To',
      globalSearch: true
    };
    this.filterData.lanCode = this.activeTabIndex === 0 ? this.selectedLanguage.toUpperCase() :  '';
    const data: any = {
      offset: offset ? offset : 0,
      limit: limit ? limit : 10,
      filterData: this.filterData ? JSON.stringify(this.filterData) : null,
      order: this.pageDetails.sortArray && this.pageDetails.sortArray.length ? JSON.stringify(this.pageDetails.sortArray) : JSON.stringify([['createdAt', 'Desc']]),
      searchText: this.searchText ? this.searchText : "",
      isCustom: (this.activeTabIndex === 0) ? false : true,
      storeId: this.selectedStoreId ? this.selectedStoreId : null,
    };

    this.subscriptionObject.add(this.commonService.getAllMailLogs(data, this.selectedStoreId).subscribe((res: any) => {
      if (res && res.response && res.response['rows']) {
        this.pageDetails.isLoader = false;
        this.dataLoader = false;
        this.isPageLoader = false;
        this.processListDataSource(res.response['rows'], res.response.count, isNew);

      }
      else {
        this.pageDetails.isLoader = false;
        this.dataLoader = false;
        this.isPageLoader = false;
        this.paginationService.listPaginatorData.next(
          {
            rows: [],
            count: 0,
            new: true
          });
      }
    },
      (err) => {
        if (err) {
          this.pageDetails.isLoader = false;
          this.dataLoader = false;
          this.isPageLoader = false;
          this.paginationService.listPaginatorData.next(
            {
              rows: [],
              count: 0,
              new: true
            });
          this.pageDetails.errorHandler = true;
        }
      }));
  }

  /**
    * Method used to set sort order.
    * @param value sort value.
    */
  setSortOrder(value: any): void {
    this.dataLoader = true;
    this.pageDetails.sortArray = [];
    if (value.column && value.order)
      this.pageDetails.sortArray.push(Object.values(value));
    this.getAllMailLogs(0, this.paginationDetails.pageSize * 2, true);
  }
  /**
* Method used to show output of fliter component.
* @param event To get the selected event.
*/
  onFilterSelected(event): void {
    this.dataLoader = true;
    if (event && event.customDateRange && event.customDateRange.begin && event.customDateRange.end) {
      event.customDateRange.begin = new Date(event.customDateRange.begin);
      event.customDateRange.end = new Date(event.customDateRange.end);
      event['customDateRange']['begin'].setHours(0, 0, 0, 0);
      event['customDateRange']['end'].setHours(23, 59, 59, 999);
    }
    if (event && event.status) {
      event['isSuccess'] = (event.status === 'success') ? true : false;
    }
    this.filterData = event ?? {};
    this.newFilter = true;
    this.getAllMailLogs(0, 2 * this.paginationDetails.pageSize, true);
  }
  /**
    * Component ondestroy life cycle hook.
    * All subscriptions are unsubscribe here.
    */
  ngOnDestroy(): void {
    this.subscriptionObject.unsubscribe();
    this.paginationService.listPaginatorData.next(null);
  }
}

