<div class="container" [ngClass.xs]="'container-mob'" [ngClass.sm]="'container-ipad'">
    <!-- mat Dialog Title -->
    <div fxLayout="column" style="padding:0px">
        <h5 mat-dialog-title style="font-weight: bold;">
            <span [ngClass.xs]="'example-spacer'">{{contentData?.title}}</span>
            <mat-icon class="show-cursor close-icon" data-control="export-close" [mat-dialog-close]="false">
                close
            </mat-icon>
        </h5>
    </div>
    <!-- mat Dialog Content -->
    <div mat-dialog-content fxLayout="column" fxLayoutGap="3%">
        <div class="upload-container">
            <h3>Pick your export option </h3>
            <mat-radio-group class="example-radio-group" [formControl]="exportType">
                <mat-radio-button [attr.data-control]="export?.name" class="example-radio-button"
                    *ngFor="let export of exportOption" [value]="export?.id">
                    {{export?.name}}
                </mat-radio-button>
            </mat-radio-group>
            <!-- <mat-error *ngIf="!exportType.value">Please choose any one option</mat-error> -->
        </div>
        <div fxLayoutAlign="center center">
            <cen-loader *ngIf="loading" [type]="'page'" [name]="'cen-line-spinner'">
            </cen-loader>
        </div>
    </div>
    <!-- mat-dialog-actions -->
    <div mat-dialog-actions fxLayoutAlign="end">
        <button class="secondary-button" [mat-dialog-close]="false" data-control="export-cancel" mat-raised-button>Close
        </button>
        <button class="primary-button" data-control="export" [disabled]="!exportType?.value" mat-raised-button
            (click)="exportFile()">
            Start Export
        </button>
    </div>
</div>
