import { CommonAdminModuleModule } from 'libs/common-admin-module/src/lib/common-admin-module.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '@phase-ii/shared';
import { RouterModule } from '@angular/router';
import { ReactiveFormsModule } from '@angular/forms';
import { AffiliateMarketingHomeComponent } from './components/affiliate-marketing-home/affiliate-marketing-home.component';
import { CoreModule } from '@phase-ii/common';

@NgModule({
  declarations: [
    AffiliateMarketingHomeComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    CoreModule,
    RouterModule,
    ReactiveFormsModule,
    CommonAdminModuleModule
  ],
})
export class AffiliateSharedModule { }
