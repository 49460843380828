export interface WebsiteSettingsModel {

  discountMaxUsePercent?: number,
  additionalCharge?: number,
  isShopClosed: boolean,
  logoUrl?: string,
  storeId: number,
  isMultiLocationOn?: boolean,
  faceBook: string,
  twitter: string,
  youtube: string,
  instagram: string,
  pinterest: string,
  linkedIn: string,
  favIconUrl: string,
  siteUnderConstructionMessage?: string,
  seoKeyword?: string,
  seoDescription?: string,
  countryIsoCode: string,
  currency: CurrencyModel,
  store: StoreModel,
  allowGuestUsers: boolean,
  isManualOrderAllowed?: boolean,
  isWishListEnabled: boolean,
  isWaitListEnabled: boolean,
  isOptionsVariantsEnabled: boolean,
  isSpecificationEnabled: boolean,
  isQuickOrderPagePluginEnabled: boolean,
  isCartQuickViewEnabled: boolean,
  isSubscription: boolean,
  isDigitalProduct: boolean,
  isBlog: boolean,
  isPreorder: boolean,
  isGiftcardEnabled: boolean,
  isLoyaltyPluginEnabled: boolean,
  isWholeSaleEnabled: boolean,
  isCashOnDelivery: boolean,
  isSquareEnabled: boolean,
  isStripeEnabled: boolean,
  isRazorpayEnabled: boolean,
  isPaypalEnabled: boolean,
  isAuthorizeNetEnabled: boolean,
  isCheckoutNetEnabled: boolean,
}

export interface CurrencyModel {
  currencyCode: string,
  currencySymbol: string
}

export interface StoreModel {
  id: number,
  name: string,
  displayName: string,
  storeId: string,
  smsVerification: boolean,
  mailVerification: boolean,
  websiteUrl: string
}

export interface UserModel {
  id: number,
  email: string,
  storeId: number,
  clientId: string,
  roleId: number,
  countryCode: number,
  phone: string,
  firstName: string,
  lastName: string,
  store: StoreDetailsModel,
  customerId: number,
  isWholesale: boolean,
  isApproved?: boolean,
  isAllowManualOrder: boolean,
  address: AddressModel[],
  isUserVerified: boolean,
  isSmsVerified: boolean,
  isSuperadmin: boolean,
  dateOfBirth: Date,
  roleCode: string,
  shopappUser?: boolean,
  userLoginHistoryId?: number,
  role: RoleModel
}


export interface AddressModel {
  country: number,
  state: number,
  zipCode: string
}

export interface RoleModel {
  id: number,
  role: string,
  code: string
}

export interface StoreDetailsModel {
  industryId: number,
  themeId: number,
  storeName: string,
  currencyData: UserCurrencyModel
}

export interface UserCurrencyModel extends CurrencyModel {
  currencyName: string;
  createdAt: string | null;
  modifiedAt: string | null;
}

export interface LocationDetails {
  id: number;
  locationName: string;
  storeName: string;
  contactPerson: string;
  contactNumber: string;
  contactEmail: string;
  website: string;
  locationPrefix: string;
  timeZone?: string;
  locationSuffix: string;
  isLocationCustomOrderIdPrefixSuffix: boolean;
}

interface CartProductModifier {
  modifierId: number;
}

interface ModifierArrayItem {
  modifierSetMappingId: number;
  modifierId: number;
  optionValueName: string;
  price: number;
}

interface ProductModifiers {
  [key: string]: {
    modifierSetId: number;
    maxModifiers?: number;
    minModifiers?: number;
    modifierArray: ModifierArrayItem[];
  };
}

interface MultiLocationVariantStock {
  id: number;
  qtyOnHold?: number;
  isStock?: boolean;
  stock: number;
  readyInHours?: number;
  variantSkuId: number;
}

interface Variant {
  id: number;
  price: number;
  variantName: string;
  wholeSalePrice?: number;
  weight?: number;
  weightCount: number;
  weightUnitId?: number;
  variantAvailability: boolean;
  taxCode: string;
  sku?: string;
}

interface Product {
  id: number;
  name: string;
  shippingType?: string;
  fixedRateType?: string;
  fixedRate?: number;
  isVariants: boolean;
  orderQuantityMinimum: string;
  orderQuantityMaximum: string;
  isReplaceable: boolean;
  isTaxable: boolean;
  weightUnit?: string;
  multiLocationVariantStocks: MultiLocationVariantStock[];
  featuredImage?: string;
}

interface TaxZoneMapping {
  taxPercent: string;
  zipCode: string;
  zoneId: number;
}

interface TaxConfiguration {
  isEnabledAllProducts: boolean;
  id: number;
  taxFor: string;
  otherZoneRate: string;
  isIncludeTaxInProduct: boolean;
  name: string;
  taxZonesMappings: TaxZoneMapping[];
}

interface BuyProduct {
  name: string;
  id: number;
  image?: string;
}

interface DiscountedProduct extends BuyProduct {
  percentage: number;
}

interface Discount {
  buy: BuyProduct[];
  isCategory: boolean;
  discountId: number;
  title: string;
  get: DiscountedProduct[];
}

export interface CartProducts {
  quantity: number;
  id: number | string;
  combinationName?: string;
  instruction?: string;
  isReplaceOrder?: boolean;
  productId: number;
  variantId: number;
  cartId: number;
  discountId?: number;
  discountProductId?: number;
  fbtId?: number;
  salesDiscountId?: number;
  createdAt: string;
  cartProductModifiers: CartProductModifier[];
  modifierIds: number[];
  isVariants: boolean;
  isMenuClosed: boolean;
  productModifiers: ProductModifiers;
  isModifierNotAvailable: boolean;
  variant: Variant;
  taxCode?: string;
  productPrice: number;
  brandId?: number;
  product: Product;
  isCategoryAvailable: boolean;
  depositAmount?: number;
  productTaxMapping: TaxConfiguration[];
  isDisabled: boolean;
  qtyUpdateAlert?: string;
  disableQtyUpdate: boolean;
  categoryId: number[];
  optionsPrice: number;
  productWithOptionPrice: number;
  optionQty: number;
  productSubTotal: number;
  modifierPrice: number;
  buyXgetYDiscounts: Discount[];
  taxAmount: number;
  isProductNotAvailable?: boolean;
}

interface CartData {
  isDeleted: boolean;
}

export interface RemoveCartProduct {
  cartData: CartData;
  code: string;
  message: string;
  success: boolean;
}

interface AlreadyExist {
  isAddedToWishlist: boolean
}

export interface AddToWishlist {
  wishList: AlreadyExist;
  code: string;
  message: string;
  success: boolean;
}

interface AddonImageData {
  imageUrl: string;
  imageType: string;
  id: number;
  price: string;
  productAddonId: number;
}

interface CustomizeProductData {
  customizeImageLink: string;
  fileUrl: string;
  storeId: number;
  uploadedImage?: string;
}

export interface GuestUserProduct {
  productId: number;
  quantity: number;
  instruction?: string;
  variantId: number;
  combinationName: string;
  weightUnit?: string;
  weightCount: number;
  imageType: string;
  addonImageData: AddonImageData[];
  id: string;
  isProductCustomizable: boolean;
  salesDiscountId: number | null;
  customizeProductData: CustomizeProductData[];
}

export interface SubscriptionDetails {
  Status: ProductSubscription;
}

interface ProductSubscription {
  featureAvailability: boolean 
}