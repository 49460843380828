import { Route } from '@angular/router';
import { CanDeactivateGuard } from '@phase-ii/shared';
import { RouteGaurdService } from './auth/services/route-gaurd.service';
import { UserListComponent } from './users/components/user-list/user-list.component';
import { AddEditUserComponent } from './users/components/add-edit-user/add-edit-user.component';
import { ViewCustomerListComponent } from './users/components/view-customer-list/view-customer-list.component';
import { AffiliateMarketingHomeComponent } from './shared/components/affiliate-marketing-home/affiliate-marketing-home.component';
import { AccountDetailsListComponent } from './account-details/components/account-details-list/account-details-list.component';
import { AddEditAccountDetailsComponent } from './account-details/components/add-edit-account-details/add-edit-account-details.component';
import { ReferralCodeListComponent } from './referral-code/components/referral-code-list/referral-code-list.component';
import { PayoutRequestListComponent } from './payout-request/components/payout-request-list/payout-request-list.component';
import { AdminProfileComponent } from './auth/components/admin-profile/admin-profile.component';
import { AdminDashboardComponent } from './auth/components/admin-dashboard/admin-dashboard.component';
import { PayoutLogsListComponent } from './payout-logs/components/payout-logs-list/payout-logs-list.component';
import { AffiliateMarketingSigninComponent } from './auth/components/affiliate-marketing-signin/affiliate-marketing-signin.component';
import { AffiliateSettingsComponent } from './settings/components/affiliate-settings/affiliate-settings.component';
import { CanDeactivateGuardService } from './auth/services/can-deactivate-guard.service';
import { ReferralCustomerListComponent } from './referral-code/components/referral-customer-list/referral-customer-list.component';
import { LogoutGuardService } from './auth/services/logout-guard.service';
import { ReferralCommissionListComponent } from './referral-code/components/referral-commission-list/referral-commission-list.component';
import { TermsAndServiceComponent } from './auth/components/terms-and-service/terms-and-service.component';
export const appRoutes: Route[] = [
    // { path: '', redirectTo: 'signin', pathMatch: 'full' },
    { path: 'termsofservice', component: TermsAndServiceComponent },
    { path: 'signin', component: AffiliateMarketingSigninComponent, canActivate: [LogoutGuardService] },
    { path: 'signin/:token', component: AffiliateMarketingSigninComponent, canActivate: [LogoutGuardService] },
    { path: 'signup', component: AffiliateMarketingSigninComponent, canDeactivate: [CanDeactivateGuard] },
    { path: 'forgotpassword', component: AffiliateMarketingSigninComponent },
    { path: 'changepassword', component: AffiliateMarketingSigninComponent },
    { path: 'changepassword/:token', component: AffiliateMarketingSigninComponent },
    {
        path: 'app', component: AffiliateMarketingHomeComponent,
        canActivateChild: [LogoutGuardService],
        canActivate: [RouteGaurdService],
        children: [
            { path: 'userlist', component: UserListComponent, },
            {
                path: 'addedituser', component: AddEditUserComponent, canDeactivate: [CanDeactivateGuard],
            },
            {
                path: 'addedituser/:id', component: AddEditUserComponent, canDeactivate: [CanDeactivateGuard],
            },
            {
                path: 'customerslist/:id', component: ViewCustomerListComponent,
            },
            { path: 'account-list', component: AccountDetailsListComponent },
            { path: 'add-edit-account', component: AddEditAccountDetailsComponent, canDeactivate: [CanDeactivateGuard] },
            { path: 'add-edit-account/:id', component: AddEditAccountDetailsComponent },
            {
                path: 'referral', component: ReferralCodeListComponent,
            },
            {
                path: 'referral-customer-list/:id', component: ReferralCustomerListComponent
            },
            {
                path: 'referral-commission-list/:id', component: ReferralCommissionListComponent
            },
            {
                path: 'payoutrequest', component: PayoutRequestListComponent
            },
            {
                path: 'settings', component: AffiliateSettingsComponent, canDeactivate: [CanDeactivateGuard]
            },
            {
                path: 'dashboard', component: AdminDashboardComponent
            },
            {
                path: 'profile', component: AdminProfileComponent, canDeactivate: [CanDeactivateGuardService]
            },
            {
                path: 'payoutlogs', component: PayoutLogsListComponent
            }

        ]
    }



];

