import { Component, Input, OnInit, SimpleChanges, TemplateRef, ViewChild } from '@angular/core';
import { Subscription, fromEvent } from 'rxjs';
import { CommonService } from 'libs/shared/src/lib/services/common.service';
import { mergeMap } from 'rxjs/operators';
import { CommonPageService } from 'libs/shared/src/lib/services/common-page.service';
import { CommonDataService } from 'libs/shared/src/lib/services/common-data.service';
import { ThemesModuleConstants } from 'libs/themes/shared-theme/src/lib/constants/shared-theme-constants';
import { SharedService } from '../../services/shared.service';
import { LoadTranslationService } from '@phase-ii/common';
/**
 * This component is used to display footer for all components.
 */
@Component({
  selector: 'phase-ii-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent extends ThemesModuleConstants implements OnInit {
  /**
 * Variable used to store aws url.
 * @type {string}
 */
  awsUrl: string;
  /**
   * Variable used to store menu heading details.
   * @type {Array}
   */
  menuHeading: Array<any> = [];
  /**
   * Variable used to store menu response.
   * @type {Array}
   */
  menuDetails: Array<any> = [];
  /**
   * Variable used to store custom menu details
   * @type {Array}
   */
  customMenuHeading: Array<any> = [];
  /**
   * Variable used to store subscription array details.
   * @type {Array}
   */
  subscriptionArray: Subscription[] = [];
  /**
   * Input variable used to get the footer details.
   * @type {any}
   */
  @Input() footerDetails: any;
  /**
   * Variable used to store subscription data
   * @type {object}
   */
  subscriptionObj = new Subscription();
  /**
   * Variable which is used to store location details.
   * @type {any}
   */
  locationDetail: any;
  /**
   * Variable which is used to store store details.
   * @type {any}
   */
  storeDetails: any;
  /**
   * Variable used to inject the environment file.
   * @type {object}
   */
  environment: object;
  @ViewChild('footerTemplate') footerTemplate: TemplateRef<any>;
  @ViewChild('solid') solid: TemplateRef<any>;
  @ViewChild('gradient') gradient: TemplateRef<any>;
  @ViewChild('about') about: TemplateRef<any>;
  @ViewChild('otherMenus') otherMenus: TemplateRef<any>;
  @ViewChild('information') information: TemplateRef<any>;
  @ViewChild('contact') contact: TemplateRef<any>;
  /**
   * Variable used to get current year
   */
  currentYear: Number;
  footerData: any;
  storePolicies: any;
  footerBackgroundDetails: any;
  language = "en";
  /**
   * Constructor is used to inject services.
   * @param sharedService used to access methods in shared service.
   * @param commonService To access the methods and observables in the common service.
   */
  constructor(
    private sharedService: SharedService,
    private commonService: CommonService,
    private commonPageService: CommonPageService,
    private commonDataService: CommonDataService,
    private translate: LoadTranslationService
    //   @Inject('environment')
    // environment
  ) {
    super();
    // this.environment = environment;
  }
  /**
    * Angular life cyle hook that initiates the component.
  */
  ngOnInit(): void {
    this.awsUrl = this.commonService.environment.AWSImageUrl;
    this.language = localStorage.getItem('language') ?? 'en';
    this.subscriptionArray.push(this.sharedService.customizedStoreDetails.pipe(mergeMap((res: any) => {
      if (res && res.footerMenu) {
        this.menuHeading = [];
        this.menuDetails = res.footerMenu;
        this.sharedService.commonWebsiteDetails.subscribe((pluginData) => {
          if (pluginData) {
            const isQuickOrderPagePluginEnabled = pluginData?.isQuickOrderPagePluginEnabled;
            this.customMenuHeading = this.menuDetails.filter(res => {
              const menuLink = res?.menuCustomLink;
              if (res && res.linkTypeId) {
                if (menuLink === 'quickorderproduct') {
                  return isQuickOrderPagePluginEnabled
                }
                else {
                  return true
                }
              }
            })
          }
        });
        this.menuHeading = this.menuDetails.filter(item => item && !item.parentMenuId && !item.linkTypeId);
      }
      return this.commonPageService.getRequiredData('getAllLocation', this);
    }), mergeMap((res: any) => {
      if (res) {
        this.locationDetail = res.filter(item => item && item.isDefault);
      }
      return this.sharedService.commonWebsiteDetails;
    }), mergeMap((res: any) => {
      if (res) {
        this.storeDetails = res;
      }
      return this.commonService.footerDetails;
    })
    ).subscribe((res: any) => {
      if (res) {
        // this.footerDetails = Object.assign(this.footerDetails, res);
        this.footerBackgroundDetails = res;
      }
    }, (err) => {
      if (err) {
        return err;
      }
    }));
    this.subscriptionObj.add(this.sharedService.getPoliciesForStorefront(this.sharedService.storeId).subscribe((res: any) => {
      if (res) {
        this.storePolicies = res.availablePolicies;
        this.sharedService.storePolicies.next(this.storePolicies);
      }
    }));
    this.currentYear = new Date().getFullYear();
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes) {
      this.language = localStorage.getItem('language') ?? 'en';
    }
  }
  /**
   * Method used to navigate to other pages on click.
   * @param page route for the page.
   */
  navigate(page: any): void {
    if (page.includes('/') && page.includes('productdetails')) {
      const path = page.split('/');
      if (path.length === 2) {
        this.sharedService.navigateTo(path[0], null, { productId: path[1] });
      } else {
        this.sharedService.navigateTo(page);
      }
    } else {
      this.sharedService.navigateTo(page);
    }
  }
  getTemplateName(name): TemplateRef<any> {
    if (name) {
      return this[name]
    }
  }
  navigateTo(id) {
    const data = 'store/policies/' + this.commonDataService.setParam(id);
    this.sharedService.navigateTo(data);
  }
  getTranslatedTitle(title: string): string {
    if (title) {
      const text = this.translate.getTranslation('SHARED.' + title?.replace(/\s+/g, '_').toUpperCase());
      return text ?? title;
    }
  }
  /**
  * Method used to unsubscribe the subcribtion arrays
  */
  ngOnDestroy(): void {
    if (this.subscriptionArray && this.subscriptionArray.length) {
      this.subscriptionArray.forEach((item) => item.unsubscribe());
    }
  }

}

