/**
 * Component which is used to display the menus of particular store as top toolbar and side nav
 */
import { HostListener, Input, OnDestroy, TemplateRef, Component, OnInit, ViewChild, OnChanges, SimpleChanges, Inject, ElementRef, Renderer2, Injector, Output, EventEmitter, PLATFORM_ID, ChangeDetectorRef } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { fromEvent, of, Subscription } from 'rxjs';
import { SharedThemeConstants, ThemesModuleConstants } from '../../constants/shared-theme-constants';
import { AuthService } from 'libs/auth/src/lib/services/auth.service';
import { filter, mergeMap } from 'rxjs/operators';
import { DialogService } from 'libs/common/src/lib/services/dialog.service';
import { UsersConstants } from 'libs/users/src/lib/constants/users.constants';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatMenuTrigger } from '@angular/material/menu';
import * as _ from 'lodash';
import { FlatTreeControl } from '@angular/cdk/tree';
import { MatTreeFlatDataSource, MatTreeFlattener } from '@angular/material/tree';
import { CommonService, FILE_EXTENSION } from '@phase-ii/shared';

import { isPlatformBrowser } from '@angular/common';
import { LoadTranslationService } from '@phase-ii/common';

import { SharedService } from '../../services/shared.service';
/**
 * Interface which is used to specify categories.
 */
interface Categories {
  /**
   * Variable which is used to store category name.
   * @type {string}
   */
  categoryName: string,
  /**
  * Variable which is used to store category id.
  * @type {number}
  */
  id: number,
  /**
  * Variable which is used to store sub category.
  * @type {any}
  */
  subCategory?: any
}
/**
 * Interface which is used to specify flat node
 */
interface ExampleFlatNode {
  /**
  * Variable which is used to store expanble property
  * @type {boolean}
  */
  expandable: boolean;
  /**
  * Variable which is used to store flat node name.
  * @type {string}
  */
  name: string;
  /**
  * Variable which is used to store the level.
  * @type {number}
  */
  level: number;
}
/**
 * Component which is used to display the menus of particular store as top toolbar and side nav
 */
@Component({
  selector: 'phase-ii-top-nav-bar',
  templateUrl: './top-nav-bar.component.html',
  styleUrls: ['./top-nav-bar.component.scss']
})
export class TopNavBarComponent extends ThemesModuleConstants implements OnInit, OnDestroy, OnChanges {
  /**
   * Variable which is used to define the mobileview or not.
   * @type {boolean}
   */
  mobileView: boolean;
  /**
   * Variable used to store the company information.
   * @type {object}
   */
  websiteSettings: any;
  /**
   * Variable which is used to store menu item for corresponding user Role.
   * @type {Array}
   */
  menuItems: Array<any>;
  /**
   * Variable used to store the location.
   * @type {Array}
   */
  locations: Array<any> = [];
  /**
   * Array which is used to store all the Subscriptions.
   * @type {Subscription}
   */
  subscriptionObj: Subscription[] = [];
  /**
   * Variable which is used to store shared constant values.
   * @type {object}
   */
  sharedConstant: object;
  /**
   * Object use to store the location name.
   * @type {object}
   */
  location: any = null;
  /**
   * Variable which is used to count the no of items available in cart.
   * @type {number}
   */
  cartCount: number;
  dummyCartCount: number;
  /**
   * Variable use to store the current role.
   * @type {string}
   */
  userRole: string;
  /**
   * Variable used to hold navbar details
   * @type {any}
   */
  @Input() navBarDetails: any;

  /**
    * Variable used to display order menu.
    * @type {boolean}
    */
  displayOrderMenu: boolean;

  /**
   * template used to choose the locations.
   * @type {TemplateRef}
   */
  @ViewChild('chooseLocation', { static: true }) chooseLocation: TemplateRef<any>;
  /**
   * Used to display the sidenav.
   * @type {TemplateRef}
   */
  @ViewChild('leftSideNav') leftSideNav;
  /**
 * Used to display the sidenav.
 * @type {TemplateRef}
 */
  @ViewChild('leftSideNav2') leftSideNav2;
  /**
   * variable used to store the current user
   * @type {object}
   */
  user: any;
  /**
   * Variable used to display floating cart icon
   * @type {boolean}
   */
  @Input() isFloatingCartIcon: boolean;
  /**
   * Used to store the store's Id.
   * @type {number}
   */
  storeId: number;
  /**
   * Variable used to store count of discount.
   * @type {object}
   */
  dealsCount: any;
  /**
   * Variable which is used to store current user is customer or not.
   * @type {boolean}
   */
  showCategoryNavbar: boolean = false;
  /**
   * Variable which is used to show attention banner.
   * @type {boolean}
   */
  showAttentionBanner: boolean = false;
  /**
   * Variable which is used to store current user is customer or not.
   */
  @Output() showCategoryNavbarEmit = new EventEmitter();
  /**
   * Variable which is used to store current user is customer or not.
   */
  @Output() mobileViewEmit = new EventEmitter();
  /**
   * Variable is used to store the selected menu details
   * @type {object}
   */
  selectedMenu: any;
  /**
   * Variable is used to check side nav is open or not.
   * @type {boolean}
   */
  isOpened: boolean = false;
  /**
   * Variable is used to store the fav icon of particular store.
   * @type {HTMLLinkElement}
   */
  favIcon: HTMLLinkElement;
  /**
   * Variable used to store guest user's role code.
   * @type {string}
   */
  guestRoleCode: any;
  /**
   * Variable which is used to show the closedShop template.
   * @type {TemplateRef}
   */
  @ViewChild('closedShop', { static: true }) closedShop: TemplateRef<any>;
  /**
   * Variable used to hold scroll event.
   * @type {boolean}
   */
  isTransparentScroll: boolean = false;
  /**
   * Variable used to get the details from storeadmin.
   * @type {boolean}
   */
  @Input() isEditor: boolean;
  /**
   * Variable used to store transparent move.
   * @type {boolean}
   */
  @Input() mustRemoveTranspanrent: boolean;
  /**
   * Variable used tostore menu list.
   * @type {Array}
   */
  @Input() menuList: Array<any>;
  /**
   * Form used to get search text and selected category
   * @type {UntypedFormGroup}
   */
  searchForm: UntypedFormGroup = new UntypedFormGroup({});
  /**
   * Variable used to hold the selected category
   * @type {any}
   */
  selectedCategory: any;
  /**
   * Variable used to hold the list of categories.
   * @type {Array}
   */
  @Input() categoryList!: Array<any>;
  /**
   * Variable used to store profile menus
   */
  @Input() menus!: any;
  /**
   * variable used to store categories
   * @type {Array}
   */
  categories!: any[];
  /**
   * Variable used to hold the element reference of the search input.
   * @type {ElementRef}
   */
  @ViewChild('search') searchElement!: ElementRef;
  /**
   * Variable used to hold the element reference of the search input.
   * @type {ElementRef}
   */
  @ViewChild('searchFormDiv') searchFormElement!: ElementRef;
  /**
   * Variable used to hold the withCategory template reference.
   * @type {TemplateRef}
   */
  @ViewChild('withCategory', { static: false }) withCategory: TemplateRef<any>;
  /**
  * Variable used to hold the withCategory2 template reference.
  * @type {TemplateRef}
  */
  @ViewChild('withCategory2', { static: false }) withCategory2: TemplateRef<any>;
  /**
   * Variable used to store the value of quick order page if its available
   * @type {any} 
   */
  isQuickOrder!: any;
  /**
   * Variable used to store the value of contact us page if its available
   * @type {any} 
   */
  isContactUs!: any;
  /**
   * Variable used to store the value of login page if its available
   * @type {any} 
   */
  isLoginPage!: any;
  /**
   * Variable used to store the value of cart page if its available
   * @type {any} 
   */
  isCart!: any;
  /**
   * Variable used to store the value of deals page if its available
   * @type {any} 
   */
  isDeals!: any;
  /**
   * Variable used to store the value of profile page if its available
   * @type {any} 
   */
  isProfile: any;
  /**
   * Variable used to store environment injected details.
   */
  environment: any;
  /**
  * Variable used to hold the selected subcategory.
  * @type {any}
  */
  selectedSubCategory!: any;
  /**
  * Variable used to hold the selected category id.
  * @type {number}
  */
  selectedCategoryId: number = -1;
  /**
     * Variable which is used to store category IDs.
     * @type {array}
     */
  categoryIds: Array<any> = [];
  /**
  * Variable which is used to store tree path in array.
  * @type {array}
  */
  treePath: Array<any> = [];
  /**
  * Variable used to hold properties of renderer.
  */
  renderer: Renderer2;
  /**
 * Variable used for common service import.
 */
  commonService: CommonService;
  /**
 * Variable used to hold plugin boolean value whether it is enable or disable
 */
  showRewardMenu: boolean;
  language = "en";
  subscriptionObject = new Subscription();
  /**
  * Variable used to hold the logo template reference.
  * @type {TemplateRef}
  */
  @ViewChild('logoTemplate', { static: true }) logoTemplate: TemplateRef<any>;
  /**
  * Variable used to hold the location template reference.
  * @type {TemplateRef}
  */
  @ViewChild('locationTemplate', { static: true }) locationTemplate: TemplateRef<any>;
  /**
  * Variable used to hold the quick access template reference.
  * @type {TemplateRef}
  */
  @ViewChild('quickAccessTemplate', { static: true }) quickAccessTemplate: TemplateRef<any>;
  /**
  * Variable used to hold the product search bar template reference.
  * @type {TemplateRef}
  */
  @ViewChild('searchBarTemplate', { static: true }) searchBarTemplate: TemplateRef<any>;
  /**
  * Variable used to hold the my accounts template reference.
  * @type {TemplateRef}
  */
  @ViewChild('myAccountTemplate', { static: true }) myAccountTemplate: TemplateRef<any>;
  /**
  * Variable used to hold the contact template reference.
  * @type {TemplateRef}
  */
  @ViewChild('contactTemplate', { static: true }) contactTemplate: TemplateRef<any>;
  /**
  * Variable used to hold the custom menu template reference.
  * @type {TemplateRef}
  */
  @ViewChild('customMenuTemplate', { static: true }) customMenuTemplate: TemplateRef<any>;
  /**
  * Variable used to hold the cart template reference.
  * @type {TemplateRef}
  */
  @ViewChild('cartTemplate', { static: true }) cartTemplate: TemplateRef<any>;
  /**
* Variable used to hold the Language template reference.
* @type {TemplateRef}
*/
  @ViewChild('languageTemplate', { static: true }) languageTemplate: TemplateRef<any>;
  /**
  * Variable used to hold the wishlist template reference.
  * @type {TemplateRef}
  */
  @ViewChild('wishlistTemplate', { static: true }) wishlistTemplate: TemplateRef<any>;
  /**
    * Method which is used for transform the node in tree structure
    * @param node which used to get the value of selected category
    * @param level which used to get the level value of current node 
    */
  private _transformer = (node: Categories, level: number) => {
    return {
      expandable: !!node.subCategory && node.subCategory.length > 0,
      name: node.categoryName,
      id: node.id,
      level: level,
      parentCategoryId: node.subCategory
    };
  }
  /**
   * Variable which is used to form the tree structure for parent node
   */
  treeControl = new FlatTreeControl<ExampleFlatNode>(
    node => node.level, node => node.expandable);
  /**
   * Variable which is used to form the tree structure for child node
   */
  treeFlattener = new MatTreeFlattener(
    this._transformer, node => node.level, node => node.expandable, node => node.subCategory);
  /**
   * Variable which is used to store the data source value for tree structure
   */
  dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);
  /**
   * Variable used to hold sub sub category of the the selected category.
   */
  subSubCategory: any;
  /**
  * Variable used to identify whether the focus is inside the search div or not.
  */
  onFocus = false;
  /**
  * Variable which is used for store url of aws
  * @type {any}
  */
  awsUrl: any;
  /**
   * Variable which is used to store whether the cart value is increased or not.
   * @type {boolean}
   */
  isCartValueIncreased!: boolean;
  /**
   * Variable which is used to store category list based on its level.
   * @type {any}
   */
  imageCategory!: any;
  /**
   * Variable which is used to store parent category details.
   * @type {any}
   */
  parentCategory!: any;
  /**
   * Variable used to define whether the side nav is in open state or not.
   * @type {boolean}
   */
  isSideNavOpened: boolean = false;
  /**
   * Varable holds whether the selected theme is desi neighbor or not.
   * @type {boolean}  
   */
  isDesiNeighborhood: boolean;
  /**
   * Varable holds whether the selected theme is desi neighbor or not.
   * @type {boolean}  
   */
  isSmallScaleShop: boolean;
  /**
  * Variable used to identify whether the current page is quickorder or not.
  * @type {boolean}
  */
  inQuickOrderPage: boolean;
  /**
   * Variable used to store the custom menu details.
   * @type {Array} 
   */
  customMenus: Array<any> = [];
  /**
   * Variable used to hold the discount ID.
   * @type {any}
   */
  discountId: any;
  /**
   * Variable used to store the order ID.
   * @type {any}
   */
  orderId: any;
  /**
   * Variable that holds the url of the current page.
   * @type {string[]}
   */
  url: string[];
  /**
   * Variable that holds the previously selected category Id.
   * @type {any}
   */
  previousCategoryId: any;
  /**
   * Variable that holds the previously selected search text.
   * @type {any}
   */
  previousSearchText: any;
  /**
   * Variable that holds the previous scroll position.
   * @type {any}
   */
  prevScrollpos: any;
  /**
   * Variable that used to store the cart product details.
   * @type {any}
   */
  cartDetails: any;
  /**
   * Variable that used to emit the cart details. 
   */
  @Output() cartDetailsEmit = new EventEmitter();
  /**
   * Variable used to emit the current location details.
   */
  @Output() locationDetails = new EventEmitter();
  /**
   * Variable used to identify the link is from storeadmin or not.
   * @type {boolean}
   */
  isStoreAdmin: boolean = false;
  /**
   * Variable used to store current category
   */
  currentCategory: any;
  navbarcategoryTemplate: TemplateRef<any>;
  /**
   * Variable used to store the current language 
   * @type {string}
   */
  selectedValue: string;
  /**
  * Variable used to store the store languages
  * @type {any}
  */
  languages: any;
  /**
* Variable used to show the language menu
* @type {boolean}
*/
  showLanguageMenu: boolean;
  badgeSize: string;
  /**
    * Variable which is used to store the value of loyalty plugin enabled or not.
    * @type {boolean}
    */
  isLoyaltyPluginEnabled: boolean;
  /**
   * Variable which is used to store the value of subscription plugin enabled or not.
   * @type {boolean}
   */
  isSubscriptionPlugin: boolean = false;

  /**
 * variable used to disabled the language selected before route change
 */
  isLanguageSelected: boolean
  /*
   * Variable which is used to store the value of review plugin enabled or not.
   * @type {boolean}
   */
  isReviewPluginEnabled: boolean;
  /**
  * Variable used to store the image extension
  */
  imageExtension = FILE_EXTENSION;
  /**
   * variable used to store the store name
   * @type {string}
   */
  storeName: string;
  /**
    * Constructor which is used to injects the services.
    * @param dialog To open the dialog box for select location
    * @param sharedService To access shared service methods
    * @param authService To access auth service methods
    * @param router To navigate to other page
    * @param dialogService To display the reponse message as dialogbox
    * @param route To get the params details of navigated route
    */
  constructor(private dialog: MatDialog,
    private sharedService: SharedService,
    private authService: AuthService,
    private router: Router,
    private dialogService: DialogService,
    public route: ActivatedRoute,
    private injector: Injector,
    @Inject('environment') environment,
    @Inject(PLATFORM_ID) private platformId: object,
    private cdr: ChangeDetectorRef,
    private translationService: LoadTranslationService
  ) {
    super();
    this.sharedConstant = new SharedThemeConstants();
    this.favIcon = document.querySelector('#appIcon');
    this.guestRoleCode = new UsersConstants().roleCode;
    this.environment = environment;
    this.commonService = injector.get<CommonService>(CommonService);
    this.renderer = injector.get<Renderer2>(Renderer2)
    if (isPlatformBrowser(this.platformId))
      this.renderer.listen('window', 'click', (e: Event) => {
        if (!(this.searchFormElement && this.searchFormElement.nativeElement && this.searchFormElement.nativeElement.contains(e.target)))
          this.onFocus = false;
      });
  }
  /**
  * Method which is used for check the child node in tree structure
  * @param _ which used to get the value of selected category
  * @param node which used to get the value of selected category
  */
  hasChild = (_: number, node: ExampleFlatNode) => node.expandable;
  /**
    * Angular life cyle hook that initiates the component.
    */
  ngOnInit(): void {
    this.isLanguageSelected = true;
    this.selectedValue = localStorage.getItem('language') ? localStorage.getItem('language') : 'en';
    this.storeName = this.sharedService && this.sharedService.storeName;
    this.prevScrollpos = isPlatformBrowser(this.platformId) ? window.scrollY : 0;
    this.awsUrl = this.environment.AWSImageUrl;
    this.subscriptionObject.add(fromEvent(window, 'component?.selectedLanguage').subscribe((event: any) => {
      if (event && event.detail) {
        this.language = event?.detail?.langCode;
      }
    }));
    this.onResize();
    if (isPlatformBrowser(this.platformId))
      window.addEventListener('scroll', this.scrollEvent);
    let url = this.router.url && this.router.url.split('/');
    this.subscriptionObj.push(this.route && this.route.queryParams && this.route.queryParams.subscribe((res) => {
      this.url = this.router && this.router.url && this.router.url.split('/');
      if (res) {
        this.discountId = res.discountId ? res.discountId : null;
        this.orderId = res.orderId ? res.orderId : null;
        res.categoryId ? this.previousCategoryId = res.categoryId : this.previousCategoryId = null;
        res.searchText ? this.previousSearchText = res.searchText : this.previousSearchText = null;
        if (res?.hasOwnProperty('isPreview') && res?.hasOwnProperty('isStoreAdmin')) {
          // sessionStorage.setItem('isStoreAdmin', res['isStoreAdmin']);
          this.isStoreAdmin = JSON.parse(res['isStoreAdmin']) ? JSON.parse(res['isStoreAdmin']) : false;
        }
        else {
          // if (!localStorage.getItem('location'))
          // localStorage.removeItem('location')
          // sessionStorage.removeItem('isStoreAdmin');
          this.isStoreAdmin = false;
        }
      }
    }));
    this.subscriptionObj.push(this.sharedService.customizedStoreDetails.pipe(mergeMap((headerMenus: any) => {
      if ((headerMenus && headerMenus.store && headerMenus.store.theme && headerMenus.store.theme.code) === (this.themeCode && this.themeCode.desineighbor))
        this.isDesiNeighborhood = true;
      else if ((headerMenus && headerMenus.store && headerMenus.store.theme && headerMenus.store.theme.code) === (this.themeCode && this.themeCode.smallScaleShop))
        this.isSmallScaleShop = true;
      if (headerMenus && headerMenus.headerMenu) {
        this.menuItems = headerMenus.headerMenu;
        this.customMenus = []
        this.setMenuItems();
      }
      if (url && url.length > 0) {
        this.setSelectedMenuId(url);
      }
      return this.router.events;
    })).subscribe((res) => {
      if (res) {
        url = [];
        if (res instanceof NavigationEnd) {
          let url = res.urlAfterRedirects.split('/');
          this.setShowCategoryNavbar(url);
          this.setSelectedMenuId(url);
        }
      }
    }));
    this.userRole = 'Guest User';
    this.subscriptionObj.push(this.sharedService.getAllLocation(this.sharedService.storeId, '', '', this.sharedService.industryName).pipe(filter((res: any) => {
      this.locations = res && res.storeLocation ? res.storeLocation : [];
      this.sharedService.activeStoreLocation.next(this.locations);
      if (!(this.locations && this.locations.length)) {
        this.dialog.open(this.closedShop, {
          width: '400px',
          disableClose: true
        });
      }
      // if (this.isEditor || this.isStoreAdmin) {
      //   if (this.locations && this.locations.length) {
      //     const singleLocation = this.locations.find((element => element && element.isDefault));
      //     this.locations = [];
      //     this.locations.push(singleLocation);
      //     return true;
      //   }
      // }
      return true;
    }), mergeMap(() => {
      return of(localStorage.getItem('location'));
    }), mergeMap((locationData: any) => {
      const value = locationData !== "undefined" ? JSON.parse(locationData) : false;
      if (value) {
        this.location = this.locations.find(
          (loc) =>
            loc && loc.id === value.id
        );
        if (!this.location) {
          localStorage.removeItem('location');
          if (this.locations && this.locations.length === 1 && ((this.location && this.location.id !== this.locations[0].id) || this.location === null || this.location === undefined)) {
            this.location = this.locations[0];
          } else if (this.locations && this.locations.length > 1) {
            this.location = this.locations.find((element => element && element.isDefault));
          }
        }
      } else {
        if (locationData === "undefined") localStorage.removeItem('location');
        if (this.locations && (this.locations.length === 1 || (this.isEditor || this.isStoreAdmin)) && ((this.location && this.location.id !== this.locations[0].id) || this.location === null || this.location === undefined)) {
          this.location = this.locations[0];
        } else if (this.locations && this.locations.length > 1) {
          this.location = this.locations.find((element => element && element.isDefault));
        }
      }
      if (this.location) {
        localStorage.setItem('location', JSON.stringify(this.location));
        this.sharedService.locationDetails.next(this.location);
        this.sharedService.emitCustomEvent('sharedService?.locationDetails', this.location);
      }
      return this.sharedService.locationDetails;
    }), filter((details) => {
      return details && details.id;
    }), mergeMap((res) => {
      return this.authService.user;
    }), filter((user) => {
      this.user = user;
      return ((user !== null && user.roleCode !== this.ROLECODE.GUEST) || (user === null && !this.authService.isAuthenticated()));
    }),
      mergeMap((user) => {
        if (this.user && this.user['id'] && this.user['customerId'] && this.user.roleCode !== this.ROLECODE.GUEST) {
          const value = JSON.parse(localStorage.getItem('cart'));
          if (value && value['cartProduct'] && value['cartProduct'].products && value['cartProduct'].products.length) {
            return this.sharedService.addBulkProductsInCart({ cartProducts: value['cartProduct'].products }, this.user.storeId, this.user.customerId);
          }
        }
        return of({});
      }),
      mergeMap(() => {
        return this.sharedService.commonWebsiteDetails;
      }), mergeMap((websiteSettings) => {
        if (websiteSettings) {
          this.websiteSettings = websiteSettings
          if (this.websiteSettings.isQuickOrderPagePluginEnabled) {
            this.displayOrderMenu = true;
          }
          if (this.websiteSettings.isMultiLanguageEnabled) {
            this.languages = this.translationService?.languageArray;
            this.showLanguageMenu = (this.languages && this.languages.length > 1) ? true : false;
            if (!this.navBarDetails.navbarMenus.some((res) => res.templateName === 'languageTemplate')) {
              this.navBarDetails.navbarMenus.splice(this.navBarDetails.navbarMenus.length - 2, 0, {
                "name": "Multilanguage",
                "templateName": "languageTemplate"
              })
            }
          }
          if (this.favIcon && this.websiteSettings && this.websiteSettings.favIconUrl && this.environment.AWSImageUrl) {
            this.favIcon.href = this.environment.AWSImageUrl + this.websiteSettings.favIconUrl;
          }
          this.setShowCategoryNavbar(this.router.url && this.router.url.split('/'));
          if (websiteSettings.isSubscription && this.user && this.user.customerId && this.user.storeId) {
            return this.sharedService.getCartSubscriptionDetails(this.user.storeId, this.user.customerId, { locationId: this.location && this.location.id, timeZone: this.location && this.location.timeZone });
          }
          return of({});
        }
        return of({});
      }), mergeMap((res) => {
        if (res && res.cartSubscriptionDetails && res.cartSubscriptionDetails.count) {
          this.sharedService.subscriptionCartCountValue.next(res.cartSubscriptionDetails.count ? res.cartSubscriptionDetails.count : 0);
          this.sharedService.emitCustomEvent('sharedService?.subscriptionCartCountValue', res.cartSubscriptionDetails.count ? res.cartSubscriptionDetails.count : 0);
        }
        if (this.user && this.user.customerId && this.user.roleCode !== this.ROLECODE.GUEST && !this.user.shopappUser) {
          localStorage.removeItem('cart');
          return this.sharedService.getVerifiedCartProducts(this.user.customerId, { storeId: this.user.storeId, locationId: this.location.id, isProductDetails: true, isCount: true, isCart: true }, this.user.storeId);
        } else {
          const value = JSON.parse(localStorage.getItem('cart'));
          if (value && value['cartProduct'] && value['cartProduct'].products && value['cartProduct'].products.length) {
            return this.sharedService.getGuestCartProducts(this.sharedService.storeId, JSON.parse(localStorage.getItem('cart')), { locationId: this.location.id, isProductDetails: true, isCount: true, isCart: true });
          }
        }
        return of(null);
      }), mergeMap((res: any) => {
        if (res && res.cartProducts) {
          this.sharedService.cartCountValue.next(res.cartProducts.cartCount ? res.cartProducts.cartCount : 0);
          this.sharedService.emitCustomEvent('sharedService?.cartCountValue', res.cartProducts.cartCount ? res.cartProducts.cartCount : 0);
        }
        return this.sharedService.cartCountValue;
      }), mergeMap((res: any) => {
        this.dummyCartCount = res ? Number(res) : 0;
        return this.sharedService.subscriptionCartCountValue;
      })).subscribe((res) => {
        this.cartCount = this.dummyCartCount + Number(res);
        document.getElementById('shake') && document.getElementById('shake').classList && document.getElementById('shake').classList.remove('face');
        this.isCartValueIncreased = false;
        setTimeout(() => {
          this.isCartValueIncreased = true;
        }, 1);
      }, (err) => {
        if (err) {
          this.dialogService.openSnackBar(this.translationService.getTranslation('SHARED.ERROR_SNACKBAR'));
        }
      }));
    this.subscriptionObj.push(this.sharedService.currentDeals.subscribe((res) => {
      if (res) {
        this.dealsCount = res;
      }
    }));
    this.authService.getLoyaltyPlugin(this.sharedService.storeId).subscribe((res: any) => {
      if (res && res.result && res.result.featureAvailability) {
        this.isLoyaltyPluginEnabled = true;
      }
    }, (err) => {
      if (err) {
        this.dialogService.openSnackBar(this.translationService.getTranslation('SHARED.ERROR_SNACKBAR'));
      }
    });
    this.subscriptionObj.push(this.sharedService.getReviewPluginDetails(this.sharedService.storeId).subscribe((res: any) => {
      if (res && res.pluginDetails && res.pluginDetails.featureAvailability) {
        this.isReviewPluginEnabled = true;
      } else {
        this.isReviewPluginEnabled = false;
      }
    }, (err) => {
      if (err) {
        this.dialogService.openSnackBar(this.translationService.getTranslation('SHARED.ERROR_SNACKBAR'));
      }
    }));
    this.authService.getSubscriptionPluginDetails(this.sharedService.storeId).subscribe({
      next: (res: any) => {
        // if (res && res.Status && res.Status.plugin && res.Status.plugin.storePluginMappings && res.Status.plugin.storePluginMappings.length > 0 && res.Status.plugin.storePluginMappings[0].isActive) {
        //   this.isSubscriptionPlugin = res.Status.plugin.storePluginMappings[0].isActive;
        // }
        if (res && res.Status && res.Status.featureAvailability) {
          this.isSubscriptionPlugin = res.Status.featureAvailability;
        }
      },
      error: (err) => {
        if (err) {
          this.dialogService.openSnackBar(this.errorMessages && this.errorMessages.FailureError);
        }
      }
    });
    this.formInitialize();
    this.subscriptionObject.add(this.commonService.topNavbarDetails.subscribe((res: any) => {
      if (res) {
        this.navBarDetails = res;
      }
    }));
  }
  ngAfterViewInit() {
    this.navbarcategoryTemplate = this[this.navBarDetails?.navbarCategory];
    this.cdr.detectChanges();
  }
  /**
   * Component onChanges life cycle
   * @param changes which indicate changes
   */
  ngOnChanges(changes: SimpleChanges): void {
    if (changes) {
      if (changes.hasOwnProperty('navBarDetails')) {
        this.navBarDetails = changes.navBarDetails?.currentValue;
        if (this.navBarDetails) {
          var bodyStyles = document && document.documentElement && document.documentElement.style;
          if (bodyStyles) {
            bodyStyles.setProperty('--navbar-text-hover-color', this.navBarDetails.hoverTextColor);
            bodyStyles.setProperty('--navbar-background-hover-color', this.navBarDetails.hoverBackgroundColor);
            bodyStyles.setProperty('--navbar-text-color', this.navBarDetails.textColor);
            bodyStyles.setProperty('--navbar-background-color', this.navBarDetails.backgroundColor);
            bodyStyles.setProperty('--navbar-icon-color', this.navBarDetails.iconColor);
            bodyStyles.setProperty('--navbar-cart-icon-color', this.navBarDetails.cartIconColor);
            bodyStyles.setProperty('--navbar-wishlist-icon-color', this.navBarDetails.wishlistIconColor);
            bodyStyles.setProperty('--navbar-cart-badge-color', this.navBarDetails.cartBadgeColor);
            bodyStyles.setProperty('--navbar-search-category-text-color', this.navBarDetails.searchCategoryTextColor);
            bodyStyles.setProperty('--navbar-search-category-background-color', this.navBarDetails.searchCategoryBackgroundColor);
            bodyStyles.setProperty('--navbar-menu-icon-color', this.navBarDetails?.menuIconColor ? this.navBarDetails.menuIconColor : 'var(--primary-color)');
            bodyStyles.setProperty('--navbar-menu-color', this.navBarDetails?.menuColor ? this.navBarDetails.menuColor : 'black');
            bodyStyles.setProperty('--navbar-category-color', this.navBarDetails?.categoryColor ? this.navBarDetails?.categoryColor : 'black');
            bodyStyles.setProperty('--navbar-category-color-hover', this.navBarDetails?.categoryHover ? this.navBarDetails?.categoryHover : 'var(--primary-color)');
            bodyStyles.setProperty('--navbar-category-background-color', this.navBarDetails?.categoryBackground ? this.navBarDetails?.categoryBackground : 'white');
            bodyStyles.setProperty('--navbar-scroll-thumb-color', this.navBarDetails.scrollThumbColor ? this.navBarDetails.scrollThumbColor : '#9fa1a1');
            bodyStyles.setProperty('--navbar-category-image-radius', this.navBarDetails.categoryImageRadius >= 0 ? this.navBarDetails.categoryImageRadius + '%' : '50%');
          }
          this.navbarcategoryTemplate = this[this.navBarDetails?.navbarCategory];
        }
      }
      if (changes.hasOwnProperty('isEditor')) {
        this.isEditor = changes.isEditor?.currentValue;
      }
      if (changes.categoryList) {
        this.categoryList = changes.categoryList?.currentValue;
        this.selectedCategory = this.categoryList && this.categoryList.length && this.categoryList[0] ? _.cloneDeep(this.categoryList[0]) : { category: { name: this.translationService.getTranslation('SHARED.ALL_PRODUCTS') } };
        this.getCategories();
      }
      if (changes.menus) {
        this.menus = changes.menus?.currentValue;
      }
    }
  }
  /**
    * Method which is used to get the scroll height dynamically inorder to change the stick-background UI.
    * @param event which holds scroll elements.
    */
  scrollEvent = (event: any): void => {
    this.isTransparentScroll = (event && event.srcElement && event.srcElement.scrollingElement && event.srcElement.scrollingElement.scrollTop >= 1) ? true : false;
  };
  /**
  * Method used to set showCategorynavbar.
  * @param routeParams holds the route details.
  */
  setShowCategoryNavbar(routeParams: any): any {
    if ((routeParams && routeParams[routeParams.length - 1]?.includes('home')) || (routeParams && routeParams[routeParams.length - 1] && routeParams && routeParams[routeParams.length - 1]?.includes('products'))) {
      if ((routeParams && routeParams[routeParams.length - 1] && routeParams && routeParams[routeParams.length - 1].includes('products'))) {
        this.showCategoryNavbar = true;
      }
      else {
        this.isSmallScaleShop ? this.showCategoryNavbar = false : this.showCategoryNavbar = true;
      }
      this.showAttentionBanner = true;
      this.inQuickOrderPage = false;
    }
    else if (routeParams && routeParams[routeParams.length - 1] && routeParams && routeParams[routeParams.length - 1].includes('quickorderproduct')) {
      this.showAttentionBanner = false;
      this.showCategoryNavbar = false;
      this.inQuickOrderPage = true;
    }
    else {
      this.showCategoryNavbar = false;
      this.showAttentionBanner = true;
      this.inQuickOrderPage = false;
      this.sharedService.filterSearchDetails.next({ categoryId: 0, searchText: null })
      this.sharedService.emitCustomEvent('sharedService?.filterSearchDetails', { categoryId: 0, searchText: null });
    }
    this.showCategoryNavbarEmit.emit({ value: this.showCategoryNavbar, attentionbanner: this.showAttentionBanner, isAttentionBannerEnabled: this.navBarDetails && this.navBarDetails.showAttentionBanner });
    this.getCategories();
  }
  /**
   * Method used to set selected menu id.
   * @param routeParams holds the route details.
   */
  setSelectedMenuId(routeParams: any): any {
    const menuIndex = this.menuItems && this.menuItems.findIndex((element) => {
      if (element)
        return routeParams.includes(element.menuCustomLink);
    });
    if (menuIndex >= 0) {
      this.selectedMenu = this.menuItems[menuIndex].id;
    }
    else {
      this.selectedMenu = null;
      if (this.menuItems && this.menuItems.length) {
        for (const element of this.menuItems) {
          if (element && element.subMenu && element.subMenu.length) {
            const subMenuIndex = element.subMenu.findIndex((subMenu) => {
              return routeParams.includes(subMenu.menuCustomLink);
            });
            if (subMenuIndex >= 0) {
              this.selectedMenu = element.subMenu[subMenuIndex].id;
              break;
            }
          }
        }
      }
    }
  }
  /**
   * Method used to changes the current store locations.
   */
  changeLocation(): void {
    if (!this.isOpened && !this.isEditor) {
      this.isOpened = true;
      const child = this.route.firstChild;
      const routePaths = this.router?.url?.split('/');
      const isCheckoutPage = ((child && child.snapshot && child.snapshot.routeConfig && child.snapshot.routeConfig.path) && ((child.snapshot.routeConfig.path === 'checkout') || (child.snapshot.routeConfig.path === 'digitalproductcheckout') || (child.snapshot.routeConfig.path === 'subscripiton' && routePaths[routePaths?.length - 1].includes('checkout')))) ? true : false;
      if (isCheckoutPage) {
        this.dialogService.openDialog({
          header: this.translationService.getTranslation('SHARED.ALERT'),
          message: this.translationService.getTranslation('SHARED.PAYMENT_LOCATION'),
          actionType: this.dialogType.alert,
          button: { right: this.translationService.getTranslation('SHARED.OK') }
        });
        this.isOpened = false;
      } else {
        this.dialog.open(this.chooseLocation, {
          autoFocus: false,
          disableClose: true
        }).afterClosed().subscribe(() => { this.isOpened = false });
      }
    }
  }
  /**
   * Method used to select the location and store in local storage. 
   * @param location has the location name.
   */
  selectLocation(location: any): any {
    if ((this.location && this.location.id && location && location.id !== this.location.id) || this.location === null || this.location === undefined) {
      localStorage.setItem('location', JSON.stringify(location));
      this.location = location;
      this.sharedService.locationDetails.next(location);
      this.sharedService.emitCustomEvent('sharedService?.locationDetails', location);
    }
    this.isOpened = false;
  }
  /**
  * Method which is used check application running in mobileview or not.
  * @param event {any} To get the width of the current window.
  */
  @HostListener('window:resize', ['$event'])
  onResize(event?: any): void {
    if (isPlatformBrowser(this.platformId)) {
      const innerWidth = event && event.target && event.target.innerWidth ? event.target.innerWidth : window.innerWidth;
      if (innerWidth) {
        this.mobileView = innerWidth <= 960 ? true : false;
        this.mobileViewEmit.emit(this.mobileView);
        if (!this.mobileView) {
          this.badgeSize = "medium";
          if (this.navBarDetails && this.navBarDetails.navbarCategory)
            this.navBarDetails.navbarCategory == 'withCategory' ? this.leftSideNav && this.leftSideNav.close() : this.leftSideNav2 && this.leftSideNav2.close();
        }
        else {
          this.badgeSize = "small";
        }
      }
    }
  }
  /**
   * Methos which is used to close the side nav bar based on the responsiveness
   */
  onClose(): void {
    this.isOpened = false;
  }
  /**
   * Method which is used to navigate to the pages
   * @param page which holds the page name
   * @param id id to navigate in params of route
   */
  navigateTo(page: any, id?: number): void {
    this.sharedService.commonLoader.next(false);
    this.sharedService.emitCustomEvent('sharedService?.commonLoader', false);
    this.selectedMenu = id;
    if (page === 'login') {
      if (this.user) {
        const historyData = localStorage.getItem('history_id');
        this.subscriptionObj.push(this.authService.saveLogoutDetails(this.user.id, historyData).subscribe((res) => {
        }));
      }
      this.showCategoryNavbar = false;
      this.sharedService.navigateTo(page);
    } else if (page && page.includes('/') && page.includes('productdetails')) {
      const path = page.split('/');
      if (path?.length === 2) {
        this.sharedService.navigateTo(path[0], null, { productId: path[1] });
      }
    }
    else {
      if (page === 'home') {
        this.isSmallScaleShop ? this.showCategoryNavbar = false : this.showCategoryNavbar = true;
      }
      else if (page === 'products') {
        this.showCategoryNavbar = true
      }
      else {
        this.showCategoryNavbar = false
      }
      this.sharedService.navigateTo(page);
    }
  }
  /**
   * Method used to construct the form and set the values.
   */
  formInitialize(): void {
    this.searchForm = new UntypedFormGroup({
      category: new UntypedFormControl(0),
      search: new UntypedFormControl('')
    });
  }
  /**
   * Method used to store the menu details for the navbar.
   */
  setMenuItems() {
    if (this.menuItems) {
      this.isQuickOrder = this.menuItems.find((menu) => menu && menu.menuName === 'Order');
      this.isContactUs = this.menuItems.find((menu) => menu && menu.menuName === 'Contact Us');
      this.isLoginPage = this.menuItems.find((menu) => menu && menu.menuCustomIcon === 'account_circle');
      this.isCart = this.menuItems.find((menu) => menu && menu.menuCustomIcon === 'shopping_cart');
      this.isDeals = this.menuItems.find((menu) => menu && menu.menuName === 'Deals');
      if (this.isLoginPage && this.isLoginPage.subMenu) {
        this.isProfile = this.isLoginPage.subMenu.find((res) => res.menuName === 'Profile')
      }
      this.sharedService.commonWebsiteDetails.subscribe((pluginData) => {
        if (pluginData) {
          const isQuickOrderPagePluginEnabled = pluginData?.isQuickOrderPagePluginEnabled;
          this.customMenus = this.menuItems.filter(res => {
            const menuLink = res.menuCustomLink;
            if (res && res.linkTypeId) {
              if (menuLink === 'quickorderproduct') {
                return isQuickOrderPagePluginEnabled
              }
              else {
                return true
              }
            }
          })
        }
      });
    }
  }
  /**
   * Method used to store the selected category.
   * @param selectedCategory holds the selected category details.
   */
  onCategorySelect(selectedCategory: any): void {
    if (selectedCategory) {
      if ((this.selectedCategory && this.selectedCategory.category && this.selectedCategory.category.name || this.selectedCategory && this.selectedCategory.categoryName) !== selectedCategory.categoryName) {
        if (this.isDesiNeighborhood)
          this.searchForm.get('category').setValue(0);
        else
          this.searchForm.get('category').setValue(selectedCategory.id);
        this.searchForm.get('search').setValue(null);
        if (this.selectedCategory && this.selectedCategory.category && this.selectedCategory.category.name) {
          this.selectedCategory.category.name = selectedCategory.categoryName;
        }
        else if (this.selectedCategory && this.selectedCategory.categoryName) {
          this.selectedCategory.categoryName = selectedCategory.categoryName;
        }
        // this.selectedCategoryId = selectedCategory.id;
      }
      this.imageCategory = this.categories && this.categories.length ? this.categories : [];
      // this.isSideNavOpened = true;
    }
    setTimeout(() => {
      this.searchElement && this.searchElement.nativeElement && this.searchElement.nativeElement.focus();
      this.onFocus = true;
    }, 0)
  }
  /**
   * Method used to get the list of categories.
   */
  getCategories(): void {
    if (this.categoryList) {
      this.categories = _.cloneDeep(this.categoryList);
      this.categories && this.categories.splice(0, 1);
      if (!this.categories?.length) {
        if ((this.navBarDetails?.navbarCategory === 'withCategory' && this.mobileView) || this.navBarDetails?.navbarCategory === 'withCategory2')
          this.showCategoryNavbarEmit.emit({ value: false, attentionbanner: this.showAttentionBanner, isAttentionBannerEnabled: this.navBarDetails && this.navBarDetails.showAttentionBanner });
      }
      this.dataSource.data = this.categories && this.categories.length > 0 ? this.categories : [];
      this.imageCategory = this.imageCategory ? this.imageCategory : this.categories;
      this.subscriptionObj.push(this.route && this.route.queryParams && this.route.queryParams.subscribe((res) => {
        let url = this.router && this.router.url && this.router.url.split('/');
        if (url && url[url.length - 1] && url[url.length - 1].includes('products') || (url && url[url.length - 1] && url[url.length - 1].includes('productdetails'))) {
          if (res && res.categoryId) {
            const categoryIndex = this.sharedService.findCategoryIndexForAllLevels(this.categoryList, res.categoryId);
            let selectedCategory;
            if (categoryIndex) {
              selectedCategory = this.sharedService.categoryIndexFind(this.categoryList, categoryIndex?.i, categoryIndex?.j, categoryIndex?.k);
            } else {
              selectedCategory = this.sharedService.categoryIndexFind(this.categoryList, 1, null, null);
            }
            if (this.categoryList.find((category) => category.id == res.categoryId))
              this.parentCategory = this.categoryList.find((category) => category.id == res.categoryId)
            else {
              this.parentCategory = this.categoryList[categoryIndex?.i];
            }
            this.currentCategory = selectedCategory;
            this.selectedCategoryId = selectedCategory && selectedCategory.category && selectedCategory.category.id;
            this.searchForm.get('search').setValue(res.searchText ? res.searchText : null);
          }
          else if (res && res.isFeaturedProducts) {
            this.sharedService.filterSearchDetails.next({ isFeaturedProducts: true });
            this.sharedService.emitCustomEvent('sharedService?.filterSearchDetails', { isFeaturedProducts: true });
            this.selectedCategoryId = 0;
            this.currentCategory = this.parentCategory = { categoryName: this.translationService.getTranslation('SHARED.ALL_PRODUCTS') };
            this.searchForm && this.searchForm.get('search') && this.searchForm.get('search').setValue(null);
          }
          else {
            if (!res?.categoryId && url && url[url.length - 1] && url[url.length - 1].includes('productdetails')) {
              this.sharedService.filterSearchDetails.next({ isFeaturedProducts: false });
              this.sharedService.emitCustomEvent('sharedService?.filterSearchDetails', { isFeaturedProducts: false });
              this.currentCategory = this.parentCategory = _.cloneDeep(this.categoryList[0]);
              this.selectedCategoryId = this.categoryList[0]?.id;
              this.searchForm.get('search').setValue(res.searchText);
            }
            else {
              this.sharedService.filterSearchDetails.next({ isFeaturedProducts: false });
              this.sharedService.emitCustomEvent('sharedService?.filterSearchDetails', { isFeaturedProducts: false });
              if (this.categories && this.categories.length && this.categories[0]) {
                this.currentCategory = this.parentCategory = _.cloneDeep(this.categoryList[0]);
                this.selectedCategoryId = _.cloneDeep(this.categoryList[0].id);
              }
              else {
                this.selectedCategoryId = 0;
                this.currentCategory = this.parentCategory = { categoryName: this.translationService.getTranslation('SHARED.ALL_PRODUCTS') };
              }
              this.searchForm.get('search').setValue(res.searchText);
            }
          }
        }
        else {
          this.selectedCategoryId = -1;
          this.currentCategory = this.parentCategory = { categoryName: this.translationService.getTranslation('SHARED.ALL_PRODUCTS') };
          this.imageCategory = this.categories;
          this.sharedService.filterSearchDetails.next({ categoryId: 0, searchText: null, isFeaturedProducts: false })
          this.sharedService.emitCustomEvent('sharedService?.filterSearchDetails', { categoryId: 0, searchText: null, isFeaturedProducts: false });
          url && url[url.length - 1] && url[url.length - 1].includes('quickorderproduct') ? this.searchForm && this.searchForm.get('search') && this.searchForm.get('search').setValue(res?.searchText) : this.searchForm && this.searchForm.get('search') && this.searchForm.get('search').setValue(null);
        }
      }));
    }
  }
  /**
 * Method which is used to navigate the corresponding menu
 * @param route
 */
  routeNavigate(route) {
    this.router.navigate([route]);
  }
  /**
   * Method  used to navigate to the selected category.
   * @param category holds the current category details.
   * @param menuTrigger holds which matmenu needs to be triggeres.
   * @param fromTemplate2 used to identify whether the fn called from category template 2
   */
  navigateToCategory(category: any, menuTrigger?: MatMenuTrigger, fromTemplate2?: boolean): void {
    if (fromTemplate2) {
      this.isSideNavOpened = true;
    }
    else {
      this.isSideNavOpened = false;
      this.imageCategory = this.categories && this.categories.length ? this.categories : [];
    }
    if (category && !category.parentCategoryId) {
      this.parentCategory = category;
      if (category.subCategory && !category.subCategory.length) {
        this.imageCategory = this.categories && this.categories.length ? this.categories : [];
      }
    }
    if (category && category.subCategory && category.subCategory.length) {
      if (fromTemplate2) {
        this.imageCategory = category.subCategory
      } else {
        this.selectedSubCategory = category.subCategory;
      }
    }
    else {
      if (category && !category.parentCategoryId) {
        if (fromTemplate2)
          this.leftSideNav2.close();
      }
      fromTemplate2 = false;
    }
    if (menuTrigger) {
      category && category.subCategory && category.subCategory.length ? menuTrigger.openMenu() : menuTrigger.closeMenu();
    }
    else {
      let index = this.categoryList && this.categoryList.findIndex(res => res.id === category.id);
      if (index >= 0) {
        this.currentCategory = _.cloneDeep(this.categoryList[index]);
      }
      this.searchForm.get('search').setValue(null);
      if (category && category.id != this.previousCategoryId) {
        if (fromTemplate2) {
          this.selectedCategoryId = this.imageCategory && this.imageCategory[0] && this.imageCategory[0].id;
          this.sharedService && this.sharedService.filterSearchDetails && this.sharedService.filterSearchDetails.next({ categoryId: this.imageCategory && this.imageCategory[0] && this.imageCategory[0].id, searchText: '', isFeaturedProducts: false });
          this.sharedService.emitCustomEvent('sharedService?.filterSearchDetails', { categoryId: this.imageCategory && this.imageCategory[0] && this.imageCategory[0].id, searchText: '', isFeaturedProducts: false });
          if (!this.orderId)
            this.sharedService.navigateTo('products', null, { categoryId: this.imageCategory && this.imageCategory[0] && this.imageCategory[0].id });
        }
        else {
          this.selectedCategoryId = category && category.id;
          this.sharedService && this.sharedService.filterSearchDetails && this.sharedService.filterSearchDetails.next({ categoryId: category && category.id, searchText: '', isFeaturedProducts: false, discountId: null });
          if (!this.orderId)
            this.sharedService.navigateTo('products', null, { categoryId: category && category.id });
        }
      }
      this.leftSideNav2.close();
    }
  }
  /**
   * Method used to navigate back to the parent category.
   */
  backToParentCategory(): void {
    if (this.currentCategory && this.currentCategory.subSubCategory) {
      this.imageCategory = this.parentCategory && this.parentCategory.subCategory;
      this.sharedService && this.sharedService.filterSearchDetails && this.sharedService.filterSearchDetails.next({ categoryId: this.currentCategory.subCategory && this.currentCategory.subCategory.id, searchText: '', isFeaturedProducts: false });
      this.sharedService.emitCustomEvent('sharedService?.filterSearchDetails', { categoryId: this.currentCategory.subCategory && this.currentCategory.subCategory.id, searchText: '', isFeaturedProducts: false });
      this.currentCategory.subSubCategory = null;
    } else if (this.currentCategory && this.currentCategory.subCategory) {
      this.imageCategory = this.categories && this.categories.length ? this.categories : [];
      this.sharedService && this.sharedService.filterSearchDetails && this.sharedService.filterSearchDetails.next({ categoryId: this.currentCategory.category && this.currentCategory.category.id, searchText: '', isFeaturedProducts: false });
      this.sharedService.emitCustomEvent('sharedService?.filterSearchDetails', { categoryId: this.currentCategory.category && this.currentCategory.category.id, searchText: '', isFeaturedProducts: false });
      this.currentCategory.subCategory = null;
    } else {
      this.imageCategory = this.categories && this.categories.length ? this.categories : [];
    }
  }
  /**
   * Method used to apply or remove style to the body based on the category sidenav.
   * @param event holds whether the sidenav is opened or not.
   */
  onOpenedChange(event: any): void {
    if (event) {
      document.body.style.overflow = 'hidden';
    }
    else {
      document.body.style.overflow = 'auto';
    }
  }
  /**
   * Method which is used to set the selected category details.
   * @param node has the selected category.
   */
  categoryClick(node: any): void {
    let category;
    this.categoryIds = [];
    if (node && node.id > 0) {
      this.categoryIds.push(node.id);
      if (node.parentCategoryId && node.parentCategoryId.length) {
        category = { id: node.id, name: node.name };
        node.parentCategoryId.forEach(element => {
          if (element.subCategory && element.subCategory.length) {
            element.subCategory.forEach(element1 => {
              if (element1.subCategory && element1.subCategory.length > 0) {
                element1.subCategory.forEach(innerSubCategory => {
                  this.categoryIds.push(innerSubCategory.id);
                });
              }
              this.categoryIds.push(element1.id);
            });
          }
          this.categoryIds.push(element.id);
        });
      }
      this.treeControl.collapseAll();
      this.changePath(node);
      this.searchForm.get('search').setValue(null);
      if (this.selectedCategoryId != node.id) {
        this.selectedCategoryId = node.id;
        this.sharedService.filterSearchDetails && this.sharedService.filterSearchDetails.next({ categoryId: node.id, searchText: '', isFeaturedProducts: false });
        this.sharedService.emitCustomEvent('sharedService?.filterSearchDetails', { categoryId: node.id, searchText: '', isFeaturedProducts: false });
        if (!this.orderId && !this.discountId)
          this.sharedService.navigateTo('products', null, { categoryId: node.id });
      }
      this.leftSideNav && this.leftSideNav.toggle()
    } else {
      this.categoryIds = this.categoryList && this.categoryList[0].categoryIdArray;
      this.treeControl.collapseAll();
      this.changePath(node);
      this.searchForm.get('search').setValue(null);
      if (this.selectedCategoryId != ((node && node.id >= 0) ? node.id : null)) {
        this.selectedCategoryId = node.id;
        this.sharedService.filterSearchDetails && this.sharedService.filterSearchDetails.next({ categoryId: (node && node.id >= 0) ? node.id : null, searchText: '', isFeaturedProducts: false });
        this.sharedService.emitCustomEvent('sharedService?.filterSearchDetails', { categoryId: (node && node.id >= 0) ? node.id : null, searchText: '', isFeaturedProducts: false });
        if (!this.orderId && !this.discountId)
          this.sharedService.navigateTo('products', null, { categoryId: node.id });
      }
      this.leftSideNav && this.leftSideNav.toggle()
    }
  }
  /**
   * Method which is used for change the path for tree structure
   * @param node which used to get the value of selected category
   */
  changePath(node: any): void {
    if (node.level < this.treePath?.length) {
      this.treePath.splice(node.level, this.treePath?.length - node.level);
    }
    const index = this.treeControl && this.treeControl.dataNodes && this.treeControl.dataNodes.indexOf(node);
    this.treePath.push(index);
    const isDelete = node.level < this.treePath?.length ? false : true;
    this.expandTreeOnSpecifiedNode(isDelete);
  }
  /**
   * Method which is used for expand the particular node in tree structure
   * @param isDelete which holds the boolean value for remove the recent path
   */
  expandTreeOnSpecifiedNode(isDelete: boolean): void {
    if (isDelete) {
      this.treePath.pop();
    }
    if (this.treePath && this.treePath.length) {
      this.treePath.forEach(id => {
        if (this.treeControl && this.treeControl.dataNodes)
          this.treeControl.expand(this.treeControl.dataNodes[id]);
      });
    }
  }
  /**
  * Method used to store search form value.
  */
  searchText(): void {
    this.onFocus = false;
    if (!this.isDesiNeighborhood) {
      if (this.inQuickOrderPage) {
        this.sharedService.filterSearchDetails && this.sharedService.filterSearchDetails.next({ categoryId: this.searchForm.get('category').value, searchText: this.searchForm.get('search').value ? this.searchForm.get('search').value : '', isFeaturedProducts: false });
        this.sharedService.emitCustomEvent('sharedService?.filterSearchDetails', { categoryId: this.searchForm.get('category').value, searchText: this.searchForm.get('search').value ? this.searchForm.get('search').value : '', isFeaturedProducts: false });
        this.sharedService.navigateTo('quickorderproduct', null, { searchText: this.searchForm.get('search').value });
      }
      else {
        if (this.searchForm.get('category').value != this.previousCategoryId) {
          this.sharedService.filterSearchDetails && this.sharedService.filterSearchDetails.next({ categoryId: this.searchForm.get('category').value, searchText: this.searchForm.get('search').value ? this.searchForm.get('search').value : '', isFeaturedProducts: false });
          this.sharedService.emitCustomEvent('sharedService?.filterSearchDetails', { categoryId: this.searchForm.get('category').value, searchText: this.searchForm.get('search').value ? this.searchForm.get('search').value : '', isFeaturedProducts: false });
          if (!this.discountId && !this.orderId) {
            this.sharedService.navigateTo('products', null, { categoryId: this.searchForm.get('category').value, searchText: this.searchForm.get('search').value });
          }
          else if (this.orderId && (this.url && this.url[this.url.length - 1] && this.url[this.url.length - 1].includes('productdetails'))) {
            this.sharedService.navigateTo('products', null, { categoryId: this.searchForm.get('category').value, searchText: this.searchForm.get('search').value, orderId: this.orderId });
          }
          else if (this.discountId) {
            this.sharedService.navigateTo('products', null, { categoryId: this.searchForm.get('category').value, searchText: this.searchForm.get('search').value });
          }
        }
        else if (this.searchForm.get('search').value != this.previousSearchText) {
          this.sharedService.filterSearchDetails && this.sharedService.filterSearchDetails.next({ categoryId: this.searchForm.get('category').value, searchText: this.searchForm.get('search').value ? this.searchForm.get('search').value : '', isFeaturedProducts: false, discountId: this.discountId });
          this.sharedService.emitCustomEvent('sharedService?.filterSearchDetails', { categoryId: this.searchForm.get('category').value, searchText: this.searchForm.get('search').value ? this.searchForm.get('search').value : '', isFeaturedProducts: false, discountId: this.discountId });
          this.sharedService.navigateTo('products', null, { categoryId: this.searchForm.get('category').value, searchText: this.searchForm.get('search').value, discountId: this.discountId, orderId: this.orderId });
        }
        else {
          if (this.url && this.url[this.url.length - 1] && this.url[this.url.length - 1].includes('productdetails') || this.discountId || this.orderId) {
            this.sharedService.filterSearchDetails && this.sharedService.filterSearchDetails.next({ categoryId: this.searchForm.get('category').value, searchText: this.searchForm.get('search').value ? this.searchForm.get('search').value : '', isFeaturedProducts: false, discountId: this.discountId });
            this.sharedService.emitCustomEvent('sharedService?.filterSearchDetails', { categoryId: this.searchForm.get('category').value, searchText: this.searchForm.get('search').value ? this.searchForm.get('search').value : '', isFeaturedProducts: false, discountId: this.discountId });
            this.sharedService.navigateTo('products', null, { categoryId: this.searchForm.get('category').value, searchText: this.searchForm.get('search').value, orderId: this.orderId, discountId: this.discountId });
          }
        }
      }
    }
    else {
      if (this.inQuickOrderPage) {
        this.sharedService.filterSearchDetails && this.sharedService.filterSearchDetails.next({ categoryId: this.searchForm.get('category').value, searchText: this.searchForm.get('search').value ? this.searchForm.get('search').value : '', isFeaturedProducts: false });
        this.sharedService.emitCustomEvent('sharedService?.filterSearchDetails', { categoryId: this.searchForm.get('category').value, searchText: this.searchForm.get('search').value ? this.searchForm.get('search').value : '', isFeaturedProducts: false });
        this.sharedService.navigateTo('quickorderproduct', null, { searchText: this.searchForm.get('search').value });
      }
      else {
        const text = this.searchForm && this.searchForm.get('search') && this.searchForm.get('search').value && this.searchForm.get('search').value.trim();
        if (this.searchForm?.get('search')?.value === '' || text === '') {
          this.searchForm.get('search').setValue(null);
          this.sharedService.filterSearchDetails && this.sharedService.filterSearchDetails.next({ categoryId: null, searchText: this.searchForm.get('search').value ? this.searchForm.get('search').value : '', isFeaturedProducts: false });
          this.sharedService.emitCustomEvent('sharedService?.filterSearchDetails', { categoryId: null, searchText: this.searchForm.get('search').value ? this.searchForm.get('search').value : '', isFeaturedProducts: false });
          this.sharedService.navigateTo('products');
        } else {
          this.sharedService.filterSearchDetails && this.sharedService.filterSearchDetails.next({ categoryId: null, searchText: this.searchForm.get('search').value ? this.searchForm.get('search').value : '', isFeaturedProducts: false });
          this.sharedService.emitCustomEvent('sharedService?.filterSearchDetails', { categoryId: null, searchText: this.searchForm.get('search').value ? this.searchForm.get('search').value : '', isFeaturedProducts: false });
          this.sharedService.navigateTo('products', null, { searchText: this.searchForm.get('search').value })
        }
      }
    }
  }
  /**
   * This method is used to navigate.
   * @param navigationLink contains navigation link.
   */
  navigateToProfile(navigationLink: any): void {
    if (navigationLink !== null) {
      this.sharedService.pageNavigation(navigationLink);
    }
  }
  /**
 * Method which is used detect scroll event.
 * @param event {any} To get the width of the current window.
 */
  @HostListener('window:scroll', ['$event'])
  onScroll(event?) {
    let navbarCategory, top;
    let currentScrollPos = isPlatformBrowser(this.platformId) ? window.scrollY : 0;
    if (document.getElementById("navbarWithoutImage")) {
      navbarCategory = document.getElementById("navbarWithoutImage");
      top = '-60px';
    }
    else {
      navbarCategory = document.getElementById("navbarWithImage");
      top = '-150px';
    }
    if (navbarCategory && !this.isEditor && !this.mobileView) {
      if (this.prevScrollpos > currentScrollPos) {
        document.getElementById("navBar") && document.getElementById("navBar").classList && document.getElementById("navBar").classList.remove('shadow');
        document.getElementById("navbar-mobile") && document.getElementById("navbar-mobile").classList && document.getElementById("navbar-mobile").classList.remove('shadow');
        if (navbarCategory.style)
          navbarCategory.style.top = "0";
        navbarCategory.classList && navbarCategory.classList.add('shadow');
      } else {
        if (!(this.showAttentionBanner && this.navBarDetails?.showAttentionBanner)) {
          document.getElementById("navBar") && document.getElementById("navBar").classList && document.getElementById("navBar").classList.add('shadow');
          document.getElementById("navbar-mobile") && document.getElementById("navbar-mobile").classList && document.getElementById("navbar-mobile").classList.add('shadow');
        }
        if (navbarCategory.style)
          navbarCategory.style.top = top;
        navbarCategory.classList && navbarCategory.classList.remove('shadow');
      }
    }
    else {
      if (currentScrollPos > 60 && !(this.showAttentionBanner && this.navBarDetails?.showAttentionBanner)) {
        document.getElementById("navBar") && document.getElementById("navBar").classList && document.getElementById("navBar").classList.add('shadow');
        document.getElementById("navbar-mobile") && document.getElementById("navbar-mobile").classList && document.getElementById("navbar-mobile").classList.add('shadow');
      }
      else {
        document.getElementById("navBar") && document.getElementById("navBar").classList && document.getElementById("navBar").classList.remove('shadow');
        document.getElementById("navbar-mobile") && document.getElementById("navbar-mobile").classList && document.getElementById("navbar-mobile").classList.remove('shadow');
      }
    }
    this.prevScrollpos = currentScrollPos;
  }
  /**
   * Method used to get sub sub category list.
   * @param subcategory {any} selected subcategory details.
   */
  getSubSubCategory(subcategory: any) {
    if (subcategory && subcategory.subCategory && subcategory.subCategory.length > 0) {
      this.subSubCategory = subcategory.subCategory;
    }
  }
  /**
   * Method used to change onFocus value when search gets clicked.
   */
  toggleSearch(): void {
    this.onFocus = true;
  }
  /**
   * Method used get the selected template value.
   * @param templateName holds the selected template name.
   */
  getTemplate(templateName: string): any {
    return this[templateName]
  }
  /**
   *  Method used to logout the site after confirmation.
   */
  onClickLogout() {
    const dialog = this.dialogService.openDialog({
      header: this.translationService.getTranslation('SHARED.ARE_YOU_SURE'),
      message: this.translationService.getTranslation('SHARED.STOREFRONT_LOGOUT'),
      actionType: this.dialogType.confirmation,
      button: { left: this.translationService.getTranslation('SHARED.NO'), right: this.translationService.getTranslation('SHARED.YES') },
      disableClose: true
    });
    this.subscriptionObj.push(dialog.afterClosed().subscribe((res: any) => {
      if (res) {
        if (this.user?.shopappUser) {
          this.authService.shopAppLogout();
        }
        else {
          if (this.user) {
            const historyData = localStorage.getItem('history_id');
            this.subscriptionObj.push(this.authService.saveLogoutDetails(this.user.id, historyData).subscribe((res) => {
            }));
          }
          this.sharedService.navigateTo('login');
        }
      }
    }));
  }
  /**
   * Method used for the language selection
   * @param selectedLanguageName holds the selected language name
   */
  onLanguageSelect(seletedLanguageCode: string) {
    if (this.selectedValue != seletedLanguageCode) {
      this.selectedValue = seletedLanguageCode;
      this.isLanguageSelected = false;
      localStorage.setItem('language', this.selectedValue)
      this.sharedService.emitCustomEvent('component?.selectedLanguage', { langCode: this.selectedValue });
      const segments = this.router.url.split('/');
      const codeIndex = segments.indexOf(this.translationService.languageCode);
      const paramSplit = codeIndex !== -1 ? segments.slice(codeIndex + 1) : !segments.includes(this.sharedService.storeDisplayName) ? segments.slice(1) : segments.slice(3);
      const finalRoute = paramSplit.join('/').split('?')[0];
      const queryParams = this.route.snapshot.queryParams;
      this.translationService.isNavigateWithLangCode = true;
      this.translationService.languageCode = this.selectedValue;
      this.translationService.changeTranslationObj();
      this.sharedService.emitCustomEvent('loadTranslationService', { languageChange: true })
      this.sharedService.pageNavigation(finalRoute, '', queryParams);
    }
  }
  navigateToWishlist(): void {
    let page: string;
    if (!this.user || this.user?.roleCode === this.guestRoleCode?.guest) {
      page = "login";
    }
    else {
      const wishlistMenu = this.menus.find(menu => menu?.pageCustomLink?.includes('wishlist'));
      page = wishlistMenu && wishlistMenu.pageCustomLink
    }
    try {
      if (!!page) {
        this.navigateTo(page);
      }
      else {
        throw new Error(this.translationService.getTranslation('SHARED.ERROR_SNACKBAR'));
      }
    } catch (err) {
      if (err) {
        this.dialogService.openSnackBar(this.translationService.getTranslation('SHARED.ERROR_SNACKBAR'));
      }
    }
  }
  getTranslatedMenu(menu: string): string {
    if (menu)
      return this.translationService.getTranslation('SHARED.' + menu?.replace(/\s+/g, '_').toUpperCase());
  }
  /**
   * Component OnDestroy life cycle hook.
   */
  ngOnDestroy(): void {
    if (this.subscriptionObj) {
      this.subscriptionObj.forEach(item => {
        if (item)
          item.unsubscribe()
      });
    }
  }
}