<cen-loader-vii *ngIf="pageDetails?.isLoader||dataLoader">
</cen-loader-vii>
<div *ngIf="!pageDetails?.isLoader">
    <phase-ii-header-page [heading]="pageDetails?.heading">
    </phase-ii-header-page>
  <div class="page-container"  fxLayout="row" fxLayoutGap="10px" fxLayout.xs="column">

    <div *ngIf="pageDetails?.showFilterAndSort" fxLayout="column">
      <phase-ii-filter [filters]="pageDetails?.productFilter"
        (filterClosed)="closeFilter(); filterConstrain=null"
        (filterPredicate)="onFilterSelected($event)">
      </phase-ii-filter>
    </div>

    <mat-card fxFlex="100%" fxLayout="column">
      <mat-tab-group mat-stretch-tabs (selectedIndexChange)="changeTab($event)" class="tab-group-height">
        <ng-container *ngFor="let tab of tabs; let id=index">
          <mat-tab [label]="tab?.label">
            <br>
            <phase-ii-common-card-v-ii *ngIf="!isPageLoader" [needPaginator]="true" [paginatorVersion]="2"
              [actions]="pageDetails?.actions" [errorHandler]="pageDetails?.errorHandler"
              [rightTopSort]="pageDetails?.sortOptions" [listProperties]="pageDetails?.listProperty"
              [columnsRef]="pageDetails?.columnsRef" (sortEventClicked)="setSortOrder($event)" [isLeftAlign]="false"
              [rightTopFilter]="options" [rightTopLanguageFilter]=" id ? null : pageDetails?.languageOptions " (rightLanguageFilterChanged)="onLanguageChange($event)" (rightFilterChanged)="changeLocation($event)"
              [actionsIcon]="pageDetails?.actionIcons" (actionClicked)="editAction($event)"
              (valueChanged)="getChangedData($event)" (paginatorData)="getPaginatorData($event)"
              (iconClicked)="openFilter($event)" (searchedText)="onSearch($event)" [isLeftAlign]="false">
            </phase-ii-common-card-v-ii>

          </mat-tab>
        </ng-container>
      </mat-tab-group>
    </mat-card>
  </div>
</div>
<ng-template #preview>
  <div class="dialog-content" fxFlex="100%">
    <h5 mat-dialog-title fxLayout="row" fxLayoutAlign="space-between center">
      <span *ngIf="!activeTabIndex">[Preview] - {{getOneTemplate.templateName}}</span>
      <span *ngIf="activeTabIndex">Preview</span>
      <mat-icon class="mat-icon-style" data-control="closeIcon" matTooltip="Close" matDialogClose>close</mat-icon>
    </h5>
    <div mat-dialog-content class="dialog-sub-content">
      <div fxLayout="column" class="margin top">
        <div *ngIf="getOneTemplate?.maillogstatus[0]?.reason" fxLayout="column" fxFlex="100%" class="mat-elevation-z8 reason-box" fxLayoutAlign="center center">
          <p><strong>Reason</strong></p> 
          <p fxLayoutAlign="center center"><span>{{getOneTemplate?.maillogstatus[0]?.reason}}</span> </p>
        </div>
        <div class="subject-box" fxLayout="row" *ngIf="getOneTemplate?.maillogstatus[0]?.subject">
          <p fxFlex="15%" fxLayoutAlign="start center"><strong>Subject :</strong></p> 
          <p fxFlex="85%" fxLayoutAlign="start center" class="left">{{getOneTemplate?.maillogstatus[0]?.subject}}</p>
        </div>
      </div>
      <div id="content" class="content-panel"> </div>
    </div>
  </div>
</ng-template>