import { Component, OnInit, OnDestroy } from '@angular/core';
import { DatePipe } from '@angular/common';
import * as  _ from 'lodash';
import { filter, mergeMap } from 'rxjs/operators';
import { of, Subscription } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { ReportsService } from '../../services/reports.service';
import { CommonService } from '../../../../../shared/src/lib/services/common.service';
import { ColumnsRef, CommonAdminShardConstants, ListProperties } from '../../constants/shared-constant';
import { Router } from '@angular/router';
import { ButtonInfo, Heading } from '../../../../../shared/src/lib/models/header-page';
import { AuthService } from '@phase-ii/auth';
import { PaginationService } from '../../../../../shared/src/lib/services/pagination.service';
import { DialogService } from '@phase-ii/common';
import { ExportComponent } from '../export/export.component';
import { ExportObject } from '../../models/export-details';
import { dateObject } from '../../models/common-admin-model';
import * as moment from 'moment';
/**
 * Component which is used to view/export order report.
 */
@Component({
  selector: 'phase-ii-order-report',
  templateUrl: './order-report.component.html',
  styleUrls: ['./order-report.component.scss']
})
export class OrderReportComponent implements OnInit, OnDestroy {
  /**
  * Variable which is used to subscribe and unsubscribe.
  * @type {Subscritpion}
  */
  subscriptionObj: Subscription = new Subscription();
  /**
   * Variable which is used to store the to date of the report.
   * @type {Date}
   */
  toDate!: Date;
  /**
   * Variable which is used to store the from date of the report.
   * @type {Date}
   */
  fromDate!: Date;
  /**
   * Variable which is used to define the pagesize
   * @type {number}
   */
  pageSize: number = new CommonAdminShardConstants().paginator.defaultPageSize;
  /**
   * Variable which is used to store the selected location.
   * @type {number}
   */
  location_id!: any;
  /**
   * Object which can be set to the properties and values for the average order report chart.
   * @type {Object}
   */
  chartOptions!: Object;
  /**
   * Variable which is used to store the sort object, which has the field and direction to be sorted.
   * @type {Object}
   */
  sort!: Object;
  /**
   * Variable which is used to store the average sales response.
   * @type {Object}
   */
  data!: Object;
  /**
* Variable which is used to setup page details
*/
  pageDetails: {
    heading: any,
    buttonInfo: ButtonInfo[],
    listProperty: ListProperties,
    columnRef: ColumnsRef[],
    isLoader: boolean,
    listDataSource: any,
    showFilterAndSort: boolean,
    actions: any,
    filterConstrain: any,
    checker: any,
    errorHandler: boolean,
    currencySymbol: any
    paginator: any
    chart: boolean,
    tab: boolean,
    storeList: any,
    storeId: number,
    roleId: number
  } = {
      heading: null,
      buttonInfo: null,
      listProperty: null,
      columnRef: null,
      isLoader: null,
      listDataSource: null,
      showFilterAndSort: false,
      actions: [],
      filterConstrain: null,
      checker: null,
      errorHandler: false,
      currencySymbol: null,
      paginator: null,
      chart: true,
      tab: false,
      storeList: null,
      storeId: null,
      roleId: null
    };
  /**
* paginator data for export
*/
  paginatorValue = { limit: null, offset: null };
  /**
  * Variable which is used to define the table property for average order.
  */
  tableProperty = { search: false, title: 'Order', chart: true };
  /**
 * Variable which is used to store sales data.
 */
  salesData!: any;
  /**
 * Variable which contains store id.
 */
  storeId!: any;
  /**
 * Variable which is used to store current user details.
 */
  currentUser!: any;
  /**
 * Variable which is used to store list of available stores.
 */
  storeList: any[] = [];
  /**
 * Variable which is used to define the boolean value based on whether response is retrieved or not.
 */
  isRetrieve: boolean = false;
  /**
 * Variable which is used to store limit value of paginator for export.
 */
  paginatorLimit: any;
  /**
 * Variable which is used to store offset value of paginator for export.
 */
  paginatorOffset!: number;
  /**
 * Variable which is used to store current role details.
 */
  currentRole!: string;
  /**commonConstant used to acccess the common constant values. */
  commonConstant = new CommonAdminShardConstants();
  /**
 * Variable which is used to store the created date of the store.
 * @type {Date}
 */
  createdDate!: Date;
  /**
* component constructor which is used to inject the required services.
* @param reportService  To access methods inside ReportService.
* @param authService  To access methods inside AuthService.
* @param displayDialog  To access methods inside MatDialog.
* @param paginatorService  To access methods inside PaginationService.
* @param commonService  To access methods inside CommonService.
* @param datePipe  To access methods inside DatePipe.
* @param router  To access methods inside Router.
*/
  constructor(private reportService: ReportsService,
    private authService: AuthService,
    private displayDialog: MatDialog,
    private paginatorService: PaginationService,
    private commonService: CommonService,
    private dialogService: DialogService,
    private datePipe: DatePipe) { }
  /**
   * Angular life cycle hook that initiates the component
   */
  ngOnInit(): void {
    // this.toDate = new Date();
    // this.fromDate = new Date(Number(this.toDate));
    this.location_id = 'All';
    this.subscriptionObj.add(this.authService.user.pipe(filter((res) => {
      if (res) {
        return res;
      }
    }), mergeMap((user: any) => {
      if (user) {
        this.currentUser = user;
        this.currentRole = this.currentUser.roleCode ? this.currentUser.roleCode : this.currentUser.role.code;
        this.pageDetails.roleId = this.currentUser?.roleId;
        this.pageDetails.currencySymbol = user.store && user.store.currencyData && user.store.currencyData.currencySymbol;
      }
      if (this.currentRole && this.currentRole === 'SUPERADMIN') {
        return this.commonService.getAllStoresName();
      } else {
        this.pageDetails.storeId = this.currentUser?.storeId ? this.currentUser.storeId : null;
        return of({});
      }
    }), mergeMap((store: any) => {
      if (store && store['stores']) {
        this.storeList.push({ name: "All", id: "All" });
        store['stores'].forEach((element) => {
          this.storeList.push({
            id: element?.id,
            name: element?.name
          });
        });
        this.storeId = this.storeList[0]?.id;
        this.pageDetails.storeList = this.storeList;
      }
      return this.reportService.getStoreData(this.pageDetails.storeId);;
    }), mergeMap((data: any) => {
      if (data) {
        this.createdDate = data.settings && data.settings.createdAt;
      }
      return this.authService.dashboardFilter;
    })).subscribe((value: dateObject) => {
      if (value) {
        if (value.filterText == 'Lifetime') {
          this.fromDate = this.createdDate;
        }
        else {
          this.fromDate = value.fromDate ? value.fromDate : new Date();
          this.fromDate.setHours(0, 0, 0, 0);
        }
      }
      else {
        this.fromDate = new Date();
        this.fromDate.setHours(0, 0, 0, 0);
      }
      this.toDate = new Date();
      this.toDate.setHours(23, 59, 59, 999);
      this.storeId = this.currentUser?.storeId ? this.currentUser.storeId : null;
      this.getAverageOrder(0, 2 * this.pageSize, true);
    }, (err) => {
      this.pageDetails.isLoader = false;
    }));

    this.pageDetails.listProperty = {
      globalSearch: false,
      columns: 2,
      dataOnly: true,
      suffixText: true,
      checkBox: false,
      isImageDisplay: false
    };
    this.pageDetails.checker = false;
    this.pageDetails.isLoader = false;
    this.pageDetails.heading = {
      title: 'Order Report',
      description: 'Generates report based on placed orders',
      isHeaderIcon: true,
      iconName: 'link',
      iconToolTip: 'Help',
      iconLink: 'https://help.getzenbasket.com/order_report.html'
    };
    this.pageDetails.buttonInfo =
      [
        {
          name: 'Back', method: 'back()', class: 'secondary-button'
        },
        {
          name: 'Export Report', class: 'primary-button', method: 'exportexcel()'
        }
      ];
  }
  /**
   * Function which is used to get the report details for table.
   * @param offset To get the offset/starting value.
   * @param limit defines the limit/count of data to be get.
   */
  getAverageOrder(offset: number, limit: number, isNew: boolean): void {
    this.paginatorOffset = offset;
    this.paginatorLimit = this.pageSize;
    const start_date: Date = this.fromDate ? _.cloneDeep(this.fromDate) : null;
    const to_date: Date = this.toDate ? this.toDate : null;
    let data: any = {
      offset: offset,
      limit: limit ? limit : this.pageSize,
      location: this.location_id ? this.location_id : null,
      store: this.storeId ? this.storeId : null,
      from_date: moment.utc(this.datePipe.transform(start_date, 'yyyy-MM-dd')).utcOffset('+0:00').format('YYYY-MM-DD 00:00:00.000 +00:00'),
      to_date: moment.utc(this.datePipe.transform(to_date, 'yyyy-MM-dd')).utcOffset('+0:00').format('YYYY-MM-DD 23:59:59.000 +00:00'),
      sort: this.sort,
      isOrder: true
    };
    if (this.storeId === 'All') {
      this.subscriptionObj.add(this.reportService.getAllSalesOverTime(data).subscribe((res: Object) => {
        if (res && res['salesData']) {
          this.isRetrieve = true;
          res['salesData'].forEach(item => {
            item.total_income = item.amount_paid ? Number(item.amount_paid) : 0;
            item.average_income = (
              (item.order_charge ? Number(item.order_charge) : 0) + (item.tax ? Number(item.tax) : 0) + (item.tip ? Number(item.tip) : 0) +
              (item.total_amount ? Number(item.total_amount) : 0) - (item.discount ? Number(item.discount) : 0)) / Number(item.orderscount);
          });
          this.pageDetails.columnRef = [
            { column: 'availabledate', type: 'Date', suffixText: 'Ordered Date' },
            { column: 'orderscount', type: 'Number', suffixText: 'No. of Orders' },
            { column: 'total_income', type: 'Amount', suffixText: 'Total Orders Income', currency: this.pageDetails.currencySymbol }
          ];
          this.pageDetails.listDataSource = res['salesData'] ? res['salesData'] : [];
          this.pageDetails.paginator = {
            new: isNew,
            rows: this.pageDetails.listDataSource,
            count: res['salesCount'],
          }
          this.paginatorService.listPaginatorData.next(this.pageDetails.paginator);
          const date: any = [];
          const income: any = [];
          let dateValue: any;
          for (const i = start_date; i <= to_date; i.setDate(start_date.getDate() + 1)) {
            dateValue = this.datePipe.transform(i, 'yyyy-MM-dd');
            date.push(dateValue);
            if (res['salesData'].length !== 0) {
              const index: any = res['salesData'].findIndex(x => x.availabledate === dateValue);
              const salesData: any = res['salesData'];
              if (index >= 0) {
                salesData[index].average_income ? income.push(Math.round(salesData[index]?.average_income)) : income.push(0);
              } else {
                income.push(0);
              }
            } else {
              income.push(0);
            }
          }
          this.chartOptions = {
            chart: {
              type: "line"
            },
            title: {
              text: "Order Report"
            },
            xAxis: {
              categories: date
            },
            yAxis: {
              title: {
                text: "Average Income"
              }
            },
            series: [{
              name: 'Average Order',
              data: income
            }]
          };
        }
      }, (err) => {
        this.pageDetails.isLoader = false;
      }));
    } else if (this.storeId !== null) {
      this.subscriptionObj.add(this.reportService.getSalesOverTime(this.storeId, data).subscribe((res: Object) => {
        if (res && res['salesData']) {
          this.isRetrieve = true;
          res['salesData'].forEach(item => {
            item.total_income = item.amount_paid ? Number(item.amount_paid) : 0;
            item.average_income = (
              (item.order_charge ? Number(item.order_charge) : 0) + (item.tax ? Number(item.tax) : 0) + (item.tip ? Number(item.tip) : 0) +
              (item.total_amount ? Number(item.total_amount) : 0) - (item.discount ? Number(item.discount) : 0)) / Number(item.orderscount);
          });
          this.pageDetails.columnRef = [
            { column: 'availabledate', type: 'Date', suffixText: 'Date' },
            { column: 'orderscount', type: 'Number', suffixText: 'No. of Orders' },
            { column: 'total_income', type: 'Amount', suffixText: 'Total Orders Income', currency: this.pageDetails.currencySymbol }
          ];
          this.pageDetails.listDataSource = res['salesData'] ? res['salesData'] : [];
          this.pageDetails.paginator = {
            new: isNew,
            rows: this.pageDetails.listDataSource,
            count: res['salesCount'],
          }
          this.paginatorService.listPaginatorData.next(this.pageDetails.paginator);
          const date: any = [];
          const income: any = [];
          let dateValue: string;
          for (const i = start_date; i <= to_date; i.setDate(start_date.getDate() + 1)) {
            dateValue = this.datePipe.transform(i, 'yyyy-MM-dd');
            date.push(dateValue);
            if (res['salesData'].length !== 0) {
              const index: any = res['salesData'].findIndex(x => x.availabledate === dateValue);
              const salesData: any = res['salesData'];
              if (index >= 0) {
                salesData[index].average_income ? income.push(Math.round(salesData[index]?.average_income)) : income.push(0);
              } else {
                income.push(0);
              }
            } else {
              income.push(0);
            }
          }
          this.chartOptions = {
            chart: {
              type: "line"
            },
            title: {
              text: "Order Report"
            },
            xAxis: {
              categories: date
            },
            yAxis: {
              title: {
                text: "Average Income"
              }
            },
            series: [{
              name: 'Average Order',
              data: income
            }]
          };
        }
      }, (err) => {
        this.pageDetails.isLoader = false;
      }));
    } else {
      this.dialogDisplay(this.commonConstant &&
        this.commonConstant.errorMessage
        && this.commonConstant.errorMessage.errorMessage,
        this.commonConstant.dialogType.failure);
    }

  }
  /**
   * Function which gets called during pagination events in common report.
   * @param event To get the event value.
   */
  onDataChange(event: any): void {
    if (event) {
      this.getAverageOrder(event.offset, event.limit, false);
    }
  }
  /**
* Method which is used to get changed page data.
* @param event To get offset and limit.
*/
  changedData(event: any): void {
    if (event) {
      this.paginatorValue.limit = event.limit ? event.limit : null;
      this.paginatorValue.offset = event.offset ? event.offset : 0;
    }
    this.getAverageOrder(event.offset, event.limit, false);
  }
  /**
   * Method which is used to get paginator data.
   * @param event To get offset and limit.
   */
  paginatorData(event: any): void {
    this.paginatorValue.limit = event?.pageSize;
    this.paginatorValue.offset = event?.pageIndex * event?.pageSize;
    this.paginatorLimit = event?.pageSize;
    this.paginatorOffset = event?.pageIndex * event?.pageSize;
    this.pageSize = event?.pageSize;
  }
  /**
   * Function which gets called during location, from date, to date and sort change events in common report.
   * @param event To get the event value.
   */
  filterAction(event: any): void {
    this.location_id = event?.location_id ? event.location_id : 'All';
    this.storeId = event?.store_id ? event.store_id : null;
    this.fromDate = event?.from_date ? event.from_date : this.fromDate;
    this.toDate = event?.to_date ? event.to_date : this.toDate;
    this.paginatorValue.limit = event?.limit ? event.limit : null;
    this.paginatorValue.offset = event?.offset ? event.offset : 0;
    this.sort = event?.sort;
    if (event.exportexcel === true) {
      this.exportAverageSales(event.offset, event.limit);
    } else {
      this.getAverageOrder(event.offset, event.limit, true);
    }
  }
  /**
   * Function which gets called during export is true in common report.
   */
  exportAverageSales(offset: any, limit: any): void {
    if (this.pageDetails && this.pageDetails.listDataSource && this.pageDetails.listDataSource.length > 0) {
      let filterConstrain: any = {
        from_date: this.datePipe.transform(this.fromDate, 'yyyy-MM-dd'),
        to_date: this.datePipe.transform(this.toDate, 'yyyy-MM-dd'),
        locationId: this.location_id ? this.location_id : null
      }
      const data: ExportObject = {
        title: 'Export ',
        exportApiUrl: 'stores/' + this.storeId + '/export/report/order',
        limit: this.paginatorLimit ? this.paginatorLimit : this.paginatorValue.limit ? this.paginatorValue.limit : this.pageSize,
        offset: this.paginatorOffset ? this.paginatorOffset : this.paginatorValue.offset ? this.paginatorValue.offset : 0,
        type: false,
        fileName: 'order',
        expansion: true,
        filterData: filterConstrain ? filterConstrain : null,
        selectedData: []
      };
      const dialogRefData: any = this.displayDialog.open(ExportComponent, { disableClose: true });
      if (dialogRefData && dialogRefData.componentInstance) {
        dialogRefData.componentInstance.contentData = data;
      }
    } else {
      this.dialogDisplay(this.commonConstant &&
        this.commonConstant.dialogMessages
        && this.commonConstant.dialogMessages.noDataExport,
        this.commonConstant.dialogType.alert);
    }
  }
  /**
* Method used to display the dialog
* @param message has the message
* @param type has the dialog type.
*/
  dialogDisplay(message: string, type: string): any {
    return this.dialogService.dialogMethod(message, type, true);
  }
  /**
   * Angular life cycle hook which is used to unsubscribe the subscriptions.
   */
  ngOnDestroy(): void {
    if (this.subscriptionObj) {
      this.subscriptionObj.unsubscribe();
    }
  }
}
