/**
 * HttpRoutingService used to handle the routes.
 */
import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
/**
 * HttpRoutingService used to handle the routes.
 */
@Injectable({
  providedIn: 'root',
})
/**
 * HttpRoutingService used to handle the routes.
 */
export class HttpRoutingService {
  /**
   * environment used to store the environment datas.
   */
  environment: any;

  /**
   * Constructor  which is used to inject services.
   * @param httpClient Service to send the api request to the server.
   */
  constructor(
    private httpClient: HttpClient,
    @Inject('environment')
    environment: any
  ) {
    this.environment = environment;
  }
  /**
   * Method Which is used to send the get api request to the server
   * @param url Define api request url
   * @param queryParams Defines the other optional parameters.
   * @param isLambda is to store the lambda is enabled or not.
   */
  getMethod(url: string, queryParams?: any, isLambda?: boolean,version:string ='v1') {
    url = url.replace(/#/g, "%23");
    const languageCode = this.getLanguageCode();
    if (!queryParams) {
      queryParams = {};
    }
    if (languageCode) {
      queryParams['langCode'] = languageCode;
    }
    if (isLambda) {
      // this.headerService.setHeaders(this.environment.lambdaUrlDb + url, 'Authorization', localStorage.getItem('lambda_jwt_token'));
    }

    const apiUrl= this.generateUrl(url,version)
    return this.httpClient.get(apiUrl, {
      params: queryParams
    });
  }
  /**
   * Method Which is used to send the get api request to the server
   * @param url Define api request url
   */
  getMethodShopApp(url: string, queryParams?: any,version:string ='v1') {
    url = url.replace(/#/g, "%23");
    const apiUrl= this.generateUrl(url,version)
    return this.httpClient.get(apiUrl, {
      params: queryParams
    });
  }
  /**
   * Method Which is used to send the post api request to the server
   * @param url Define api request url
   * @param data Define the data
   * @param queryParams Defines the other optional parameters
   * @param isLambda is to store the lambda is enabled or not.
   */
  postMethod(url: string, data: any, queryParams?: any, isLambda?: boolean,version:string ='v1') {
    url = url.replace(/#/g, "%23");
    const apiUrl= this.generateUrl(url,version);
    return this.httpClient.post(apiUrl, data, {
      params: queryParams,
    });
  }

  /**
 * Method Which is used to send the post api request to the server
 * @param url Define api request url
 * @param data Define the data
 */
  postMethodShopApp(url: string, data: any, queryParams?: any,version:string ='v1') {
    if (this.environment && this.environment.isZen) {
      data.isZen = this.environment && this.environment.isZen;
    }
    const apiUrl= this.generateUrl(url,version)
    return this.httpClient.post(apiUrl, data, {
      params: queryParams,
    });
  }
  /**
   * Method Which is used to send the post api request to the server
   * @param url Define api request url
   * @param data Define the data
   */
  postMethodWithId(url: string, data: any, queryParams?: any,version:string ='v1') {

    if (data) {
      data.unique_id = data.unique_id ? data.unique_id : data.isZen ? null : this.environment.uniqueId ? this.environment.uniqueId : localStorage.getItem('uniqueId');
    }
    const apiUrl= this.generateUrl(url,version)
    return this.httpClient.post(apiUrl, data, {
      params: queryParams,
    });
  }
  /**
   * Method getJsonData used to fetch the json data.
   * @param url is the url.
   * @returns url route.
   */
  getJsonData(url: string) {
    // console.log("http://localhost:4208",url)
    return this.httpClient.get(url);
  }
  /**
  * Method putMethod Which is used to send the put api request to the server.
  * @param url Define api request url.
  * @param data Define the data.
  * @param queryParams Defines the other optional parameters.
  * @param isLambda is to store the lambda is enabled or not.
  */
  putMethod(url: string, data: any, queryParams?: any, isLambda?: boolean,version:string ='v1') {
    url = url.replace(/#/g, "%23");
    const apiUrl= this.generateUrl(url,version)
    return this.httpClient.put(apiUrl, data, {
      params: queryParams,
    });
  }
  /**
   * Method deleteMethod Which is used to send the delete api request to the server.
   * @param url Define api request url.
   * @param isLambda is to store the lambda is enabled or not.
   */
  deleteMethod(url: string, queryParams?: any, isLambda?: boolean,version:string ='v1') {
    url = url.replace(/#/g, "%23");
      const apiUrl= this.generateUrl(url,version)
    return this.httpClient.delete(apiUrl, { params: queryParams });
  }
  /**
 * Method which is used to replace url params.
 * @param url To get url.
 * @param obj To get params object.
 * @returns returns replaced url.
 */
  replaceUrlParam(url: any, obj: any) {
    for (const key in obj) {
      let replaceText = '{' + key + '}';
      url = url.replace(replaceText, obj[key]);
    }
    return url;
  }
  /**
  * Method which is used to send the post api request to the server for export
  * @param apiUrl Define the export url in env
  * @param data Define the data
  */
  postMethodForExport(apiUrl: any, data: any) {
    return this.httpClient.post(apiUrl, data);
  }
  /**
   * Method to fetch the language code from local storage
   * @returns language code 
   */
  private getLanguageCode() {
    return localStorage.getItem('language') || null;
  }
    /**
 * Method which is used to generate full  url path.
 * @param url To get url.
 * @param version To get version type.
 * @returns returns full url.
 */
    generateUrl(url: any, version: any) {
      const apiUrl=[this.environment.apiUrlDb ,version, url].join('/');
      return apiUrl;
    }
}
