<div [class]="isPreview?'dialog-scroll':''" *ngIf="blogDetails" fxLayout="row" fxLayoutAlign="center start"
    ngClass.gt-md="blog-container-gt-medium" ngClass.lt-md="blog-container-lt-medium" ngClass.md="blog-container-medium"
    fxLayoutGap="20px" fxLayoutGap.lt-sm="0px">
    <h1 *ngIf="!isPreview" fxLayoutAlign.gt-md="center start">
        <mat-icon data-control="back" ngClass.gt-md="back-icon-gt-md" ngClass.lt-md="back-icon-lt-md"
            ngClass.md="back-icon-lt-md" ngClass.lt-sm="back-icon-lt-sm" class="back-icon" (click)="backToList()">keyboard_backspace</mat-icon>
    </h1>
    <div [ngClass.gt-md]="!isPreview?'size':'preview-size'" fxLayoutAlign.gt-md="center start" class="blog-content"
        fxLayoutGap="10px" fxLayout="column">
        <div class="close-dialog" [mat-dialog-close]="true" *ngIf="isPreview"><mat-icon>close</mat-icon></div>
        <div fxLayout="row" fxLayoutGap="5px">
            <h1 class="title">{{blogDetails?.title}}</h1>
        </div>
        <div fxLayout="row" fxLayoutGap="20px" class="img-height" fxFlex="20">
            <img fxLayoutAlign="center center" class="profile-img"
                [actualImageURL]="blogDetails?.profileImage?awsImage+blogDetails?.profileImage:'./assets/user.png'"
                [imageExtension]="imageExtension?.AVIF_FORMAT" [onErrorEvent]="{src:'./assets/user.png'}"
                alt="no author image">
            <div fxLayout="column" fxLayoutGap="2px">
                <span>{{blogDetails?.authorName}}</span>
                <span>{{blogDetails?.createdAt}}</span>
            </div>
        </div>
        <div fxLayoutAlign="center center" class="img-width">
            <img ngClass.gt-md="img-class" ngClass.lt-md="img-class" ngClass-md="img-class"
                ngClass.lt-sm="img-class-adjust" ngClass.lt-lg="img-class-adjust"
                [actualImageURL]="blogDetails?.bannerUrl?  awsImage+blogDetails?.bannerUrl :'./assets/no-image.png'"
                [imageExtension]="imageExtension?.AVIF_FORMAT" [onErrorEvent]="{src:'./assets/no-image.png'}"
                alt="no-blog-image">
        </div>
        <div fxFlex="80" *ngIf="htmlContent" fxLayout.lt-md="row wrap" class="ck-editor-content-preview">
            <p [innerHTML]="htmlContent"> </p>
        </div>
        <br>
        <div fxLayout="column" ngClass.gt-md="img-width"
            *ngIf="(blogDetails?.blogCategory?.commentSettings=== 'Pending' || blogDetails?.blogCategory?.commentSettings=== 'Auto Published')">
            <div fxLayout="column" fxLayoutGap="12px" *ngIf="!isPreview&&commentDetails&&commentDetails.length">
                <strong> {{commentDetails?.length > 1 ? (commentDetails?.length +' ' + ('BLOG.COMMENTS' | translate)):('
                    '+commentDetails?.length + ('BLOG.COMMENTHEADING' | translate) )}}</strong>
                <div *ngFor="let item of commentDetails;let i=index">
                    <div *ngIf="!isShowMore&&i<=4" fxLayout="column" fxLayoutGap="1.3%" class="comment-section">
                        <div fxLayout="row" fxLayoutGap="20px" fxFlex="20">
                            <img fxLayoutAlign="center center" class="comment-sec" [src]="'./assets/user.png'"
                                alt="no author image">
                            <div fxLayout="column" fxLayoutGap="8px" class="comment-text">
                                <span><strong>{{item?.name}}</strong></span>
                                <span>{{item?.comment}}</span>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="isShowMore" class="box-shadow" fxLayout="column" fxLayoutGap="1.3%"
                        class="comment-section">
                        <div fxLayout="row" fxLayoutGap="20px" fxFlex="20">
                            <img fxLayoutAlign="center center" class="comment-sec" [src]="'./assets/user.png'"
                                alt="no author image">
                            <div fxLayout="column" fxLayoutGap="8px" class="comment-text">
                                <span><strong>{{item?.name}}</strong></span>
                                <span>{{item?.comment}}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div *ngIf="commentDetails?.length>5" fxLayout="row" fxLayoutAlign="end start">
                    <a class="show-more" data-control="show-more" (click)="showMore();">{{isShowMore?"Show Less":"Show
                        More"}}</a>
                </div>
            </div>
            <p>{{'BLOG.LEAVE_COMMENT' | translate}}</p>
            <form [formGroup]="commantForm" *ngIf="commantForm" fxLayout="column">
                <div fxLayout="row" fxLayoutGap.gt-md="2%" fxLayoutGap.md="2%" fxLayout.lt-md="column"
                    fxLayoutAlign="space-evenly center">
                    <mat-form-field appearance="outline" fxFlex="50%" ngClass.lt-md="img-width">
                        <mat-label>{{'BLOG.NAMELABEL' | translate}}</mat-label>
                        <input [readOnly]="isPreview?true:false" matInput data-control="name" formControlName="name"
                            required>
                        <mat-error data-control="nameRequiredError"
                            *ngIf="commantForm?.get('name')?.hasError('required')">
                            {{ 'BLOG.NAME_REQUIRED' | translate}}
                        </mat-error>
                        <mat-error data-control="nameMaxLengthError"
                            *ngIf="commantForm?.get('name')?.hasError('maxlength')&&!commantForm?.get('name')?.hasError('pattern')">
                            {{ 'BLOG.NAME_MAXLENGTH' | translate}}
                        </mat-error>
                        <mat-error data-control="namePatternError"
                            *ngIf="commantForm?.get('name')?.hasError('pattern')">
                            {{ 'BLOG.NAME_PATTERN' | translate}}
                        </mat-error> </mat-form-field>
                    <mat-form-field appearance="outline" fxFlex="50%" ngClass.lt-md="img-width">
                        <mat-label>{{'BLOG.EMAILLABEL' | translate}}</mat-label>
                        <input [readOnly]="isPreview?true:false" matInput data-control="email" formControlName="email"
                            required>
                        <mat-error data-control="emailRequiredError"
                            *ngIf="commantForm?.get('email')?.hasError('required')">
                            {{ 'BLOG.EMAIL_REQUIRED' | translate}}
                        </mat-error>
                        <mat-error data-control="emailPatternError"
                            *ngIf="commantForm?.get('email')?.hasError('pattern')">
                            {{ 'BLOG.EMAIL_PATTERN' | translate}}
                        </mat-error>
                    </mat-form-field>
                </div>
                <mat-form-field appearance="outline">
                    <mat-label>{{'BLOG.COMMENTLABEL' | translate}}</mat-label>
                    <textarea [readOnly]="isPreview?true:false" matInput data-control="comment"
                        formControlName="comment" required trimVal> </textarea>
                    <mat-error data-control="commentRequiredError"
                        *ngIf="commantForm?.get('comment')?.hasError('required')">
                        {{ 'BLOG.COMMENT_REQUIRED' | translate}}
                    </mat-error>
                    <mat-error data-control="commentPatternError"
                        *ngIf="commantForm?.get('comment')?.hasError('maxlength')">
                        {{ 'BLOG.COMMENT_MAXLENGTH' | translate}}
                    </mat-error> </mat-form-field>
            </form>
            <span class="comment-pending"
                *ngIf="blogDetails?.blogCategory?.commentSettings=== 'Pending'">{{'BLOG.DISABLED_COMMENT' |
                translate}}</span>
            <div class="post-comment-btn" *ngIf="!isPreview">
                <button mat-raised-button color="warn" data-control="postComment"
                    (click)="postComment()">{{'BLOG.POST_COMMENT' | translate}}</button>
            </div>
            <br>
        </div>
        <div *ngIf="isPreview && blogComment" fxLayout="column" class="commentPreview">
            <p>COMMENT : </p>
            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="20px" fxFlex="20">
                <img fxLayoutAlign="center center" class="comment-sec" [src]="'./assets/user.png'"
                    alt="no author image">
                <div fxLayout="column" fxLayoutGap="8px" class="comment-text">
                    <span><strong>{{blogComment?.name}}</strong></span>
                    <span>{{blogComment?.comment}}</span>
                </div>
            </div>
        </div>
    </div>
</div>