<div *ngIf="type === 'storefront';else elseBlock">
    <!-- *ngIf="loaderDetails?.loaderType === 'custom'; else defaultLoader" -->
    <div *ngIf="loaderDetails?.loaderType === 'custom'; else defaultLoader" class="storefront-page-loader">
        <div class="storefront-card" [ngClass]="{'loader-customization': zoom}"
            [ngStyle]="{'background-color': loaderDetails?.backgroundColor? loaderDetails?.backgroundColor : 'white'}"
            fxLayout="column" fxLayoutAlign="center center">
            <!-- <div > -->
            <img *ngIf="loaderDetails?.loaderImage"
                [src]="loaderDetails?.loaderImage?.includes('base64') && loaderDetails?.loaderImage?.includes(',') ? loaderDetails?.loaderImage : awsUrl+loaderDetails?.loaderImage"
                class="image-container" [alt]="loaderDetails?.loaderImage">
            <p [dynamicFontResizer]="language" *ngIf="loaderDetails?.loaderText"
                [ngStyle]="{'margin-top': loaderDetails?.loaderImage? '0': '40%', 'font-size': loaderDetails?.textFontSize ? loaderDetails?.textFontSize+'px' : '20px', 'color': loaderDetails?.textColor ? loaderDetails?.textColor : 'var(--primary-color)', 'font-family': loaderDetails?.textFontFamily ? loaderDetails?.textFontFamily : 'Montserrat', 'font-weight': loaderDetails?.textStyle?.includes('B') ? 'bold' : 'normal', 'font-style': loaderDetails?.textStyle?.includes('I') ? 'italic' : 'normal', 'text-decoration' : loaderDetails?.textStyle?.includes('U') ? 'underline' : 'none'}"
                class="storefront-loader-text" [ngClass.xs]="'storefront-loader-text-xs'">
                {{loaderDetails?.loaderText | convertLanguage:language}}</p>
            <!-- </div> -->
        </div>
    </div>
    <ng-template #defaultLoader>
        <mat-progress-bar mode="indeterminate"></mat-progress-bar>
    </ng-template>
</div>


<ng-template #elseBlock>
    <mat-progress-bar mode="indeterminate" [ngStyle]="{ 'color': '#000000' }"></mat-progress-bar>
    <!-- <div class="page-loader">
        <div class="back-card" fxLayoutAlign="center"> -->
    <!-- <div class="multi-spinner-container">
                <div class="outer">
                </div>
                <div class="inner">
                </div>
                <p class="loader-text">Loading</p>
            </div> -->
    <!-- <div class="loadingio-spinner-double-ring-zfl08n2dy7j">
                <div class="ldio-g9kv9h41tzi" style="margin-top: -4px;">
                    <div></div>
                    <div></div>
                    <div>
                        <div></div>
                    </div>
                    <div>
                        <div></div>
                    </div>
                </div> -->
    <!-- <p class="">Loading</p> -->
    <!-- </div> -->
    <!-- <p class="loadingFont">
                Loading...
            </p> -->
    <!-- </div>
    </div> -->

</ng-template>