<!-- <cen-loader-vii *ngIf="isLoading">
</cen-loader-vii> -->
<!-- <div fxLayout="row" fxLayoutAlign="center " fxLayoutGap="6%" fxLayout.lt-md="column"> -->
<!-- <div fxLayout="column"> -->
<!-- <div fxLayout="column" class="outer-card" mat-elevation-z11>
            <div fxLayout="row">
                <p class="heading">Card Details</p>
                <img width="40px" height="35px" style="margin-block-start:-5px;margin-left:5px"
                    src="../assets/credit-card.png" alt="pay" />
            </div><br>
            <mat-divider class="line1"></mat-divider>
            <div style="padding: 0px 10px;" fxLayout="column" fxFlex="100%">

                <mat-divider class="line">
                </mat-divider>
                <div fxLayout="row" fxLayoutAlign="center ">
                    <div fxLayout="column"
                        [fxFlex]="storeDetails?.store?.currencyData?.currencyUnit === 'INR' ? '70%':'100%' "
                        fxFlex.lt-md="100%">
                        <div>
                            <p fxFlex="50" class="card-field-name"> Amount To Pay </p>
                            <p fxFlex="50" class="plan-value">
                                {{storeDetails?.store?.currencyData?.currencySymbol}}{{(selectedPlanDetails?.amountToPay
                                ? selectedPlanDetails?.amountToPay:0)|number :'1.2-2'}}</p>
                        </div>
                        <div fxLayoutAlign="center" *ngIf="storeDetails?.store?.currencyData?.currencyUnit === 'INR'"
                            style="margin-bottom: 10px;">
                            <button mat-flat-button class="primary-button payButton" type="button"
                                [disabled]="isButtonLoading">
                                <div *ngIf="!isButtonLoading" (click)="createOrderInRazorpay()"
                                    data-control="razorpay-pay-button" style="font-weight: 500;">
                                    Pay Now
                                </div>
                                <cen-loader style="width: 10px;" *ngIf="isButtonLoading" [type]="'content'"
                                    [name]="'cen-dot-spinner'">
                                </cen-loader>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <mat-divider class="line1"></mat-divider>
        </div> -->
<div fxLayout="column" class="outer-card" [ngClass]="!isLoading?'show':'hide'">
    <div *ngIf="storeDetails?.purchasedCurrency?.currencyUnit !== 'INR'">
        <div fxLayout="column">
            <div fxLayout="row">
                <div fxLayout="row" fxFlex="60">
                    <div fxFlex="55">
                        <p class="heading">{{paymentType&&((paymentType === paymentConstant?.paymentOption?.PAYPAL) ?
                            'Payment Options': 'Card Details')}}</p>
                    </div>
                    <div fxLayoutAlign.lt-md="top center" fxFlex="5">
                        <img width="40px" height="35px" style="margin-block-start:-5px;margin-left:5px"
                            src="../assets/credit-card.png" alt="pay" />
                    </div>


                </div>
                <div fxFlex="40" fxLayoutAlign="end end">
                    <button mat-raised-button [ngClass]="isButtonLoading?'disabled-button':'primary-button'"
                        [disabled]="isButtonLoading" *ngIf="!isEdit && customerCardDetails" type="button"
                        (click)="editCard()" data-control="cardEdit"> Edit </button>
                    <button mat-stroked-button type="button" *ngIf="isEdit " [disabled]="isButtonLoading"
                        (click)="cancelCard()" data-control="cancelCard">
                        Cancel
                    </button>
                </div>
            </div>
            <br>
            <mat-divider class="line1"></mat-divider>

        </div><br>
        <div *ngIf="!isLoading&& !isEdit && customerCardDetails">
            <div>
                <p fxFlex="40" class="card-field-name"> Card Holder Name </p>
                <p fxFlex=5>:</p>
                <p fxFlex="55">{{customerCardDetails?.name ? customerCardDetails?.name : '-'}}</p>
            </div>
            <div>
                <p fxFlex="40" class="card-field-name"> Card Number </p>
                <p fxFlex=5>:</p>
                <p fxFlex="55">**** **** **** {{customerCardDetails?.last4}}</p>
            </div>
            <div>
                <p fxFlex="40" class="card-field-name"> CVC </p>
                <p fxFlex=5>:</p>
                <p fxFlex="55" *ngIf="customerCardDetails?.cvc_check === 'pass'">***</p>
                <!-- <p fxFlex="55">***</p> -->
            </div>
            <div>
                <p fxFlex="40" class="card-field-name"> Expired on </p>
                <p fxFlex=5>:</p>
                <p fxFlex="55">{{customerCardDetails?.exp_month}} / {{customerCardDetails?.exp_year}}</p>
            </div>
        </div>
        <div id="cardInfoGroup" data-control="stripeCard">
        </div>
        <form
            *ngIf="(isEdit || !customerCardDetails) && paymentType&&(paymentType=== paymentConstant?.paymentOption?.CHECKOUT) && !isLoading"
            id="payment-form">
            <div class="one-liner">
                <div class="card-frame"></div>
            </div>
            <p class="error-message"></p>
        </form>
        <div class="paypal-button" data-control="paypalButton"
            *ngIf="paymentType&&(paymentType===paymentConstant?.paymentOption?.PAYPAL) && !isLoading">
            <ngx-paypal [config]="payPalConfig"></ngx-paypal>
            <br>
        </div>
        <!-- <form [formGroup]="billingInfo" *ngIf="(isEdit || !customerCardDetails)">
             -->
        <form [formGroup]="billingInfo" *ngIf="billingInfo &&!isLoading && (isEdit || !customerCardDetails) &&
        paymentType&&(paymentType !== paymentConstant?.paymentOption?.PAYPAL)">
            <mat-form-field class="top-space full-width" appearance="outline" *ngIf="billingInfo?.get('name')">
                <mat-label>Name on Card</mat-label>
                <input matInput type="text" size="20" autocomplete="off" formControlName="name" required
                    data-control="cardName" />
                <mat-error *ngIf="billingInfo?.get('name')?.errors && billingInfo?.get('name')?.touched">
                    <span *ngFor="let k of billingInfo?.get('name')?.errors | keyvalue"
                        [attr.data-control]="('card Name'+ k?.key)|dataControl">
                        {{ (errorMessage?.ERROR)['NAME'][k.key] }}</span>
                </mat-error>
            </mat-form-field>

            <h2 class="heading">Billing Address</h2>
            <br>
            <div fxLayout="column" fxLayoutGap="15px">
                <div fxLayout="row" fxLayoutGap="15px" fxLayoutGap.xs='0' fxLayout.xs="column" class="billing-address">
                    <mat-form-field fxFlex="31%" appearance="outline" *ngIf="billingInfo?.get('streetName')">
                        <mat-label>Address Line 1</mat-label>
                        <input matInput required type="text" size="20" autocomplete="off" formControlName="streetName"
                            data-control="address1" />
                        <!-- <mat-error
                        *ngIf="billingInfo?.get('streetName')?.errors &&billingInfo?.get('streetName')?.touched">
                        <span *ngFor="let k of billingInfo?.get('streetName')?.errors | keyvalue"
                            [attr.data-control]="'address 1 '+ k?.key">
                            {{ (errorMessage?.ERROR)['STREET_NAME'][k.key] }}</span>
                    </mat-error> -->
                        <mat-error
                            *ngIf="billingInfo?.get('streetName')?.errors &&billingInfo?.get('streetName')?.touched&&billingInfo?.get('streetName')?.errors?.required"
                            data-control="addressTwoValidError">
                            {{ (errorMessage?.ERROR)['STREET_NAME']['required']}}
                        </mat-error>
                        <mat-error
                            *ngIf="billingInfo?.get('streetName')?.errors &&billingInfo?.get('streetName')?.hasError('maxlength')&&!billingInfo?.get('streetName')?.hasError('pattern')"
                            data-control="address1ValidError">
                            {{ (errorMessage?.ERROR)['STREET_NAME']['maxlength']}}
                        </mat-error>
                        <mat-error
                            *ngIf="billingInfo?.get('streetName')?.errors &&billingInfo?.get('streetName')?.hasError('pattern')"
                            data-control="address1ValidError">
                            {{ (errorMessage?.ERROR)['STREET_NAME']['pattern']}}
                        </mat-error>
                    </mat-form-field>
                    <mat-form-field fxFlex="32%" appearance="outline" *ngIf="billingInfo?.get('doorNo')">
                        <mat-label>Address Line 2</mat-label>
                        <input matInput type="text" size="20" autocomplete="off" formControlName="doorNo"
                            data-control="address2" />
                        <mat-error
                            *ngIf="billingInfo?.get('doorNo')?.errors &&billingInfo?.get('doorNo')?.hasError('pattern')"
                            data-control="address2ValidError">
                            {{ (errorMessage?.ERROR)['DOOR_NO']['pattern']}}
                        </mat-error>
                        <mat-error
                            *ngIf="billingInfo?.get('doorNo')?.errors &&!billingInfo?.get('doorNo')?.hasError('pattern')&&billingInfo?.get('doorNo')?.hasError('maxlength')"
                            data-control="address2ValidError">
                            {{ (errorMessage?.ERROR)['DOOR_NO']['maxlength']}}
                        </mat-error>
                    </mat-form-field>
                    <mat-form-field fxFlex="31%" appearance="outline" *ngIf="billingInfo?.get('city')">
                        <mat-label>City</mat-label>
                        <input matInput type="text" size="20" autocomplete="off" formControlName="city" required
                            data-control="city" />
                        <mat-error data-control="cityRequiredError"
                            *ngIf="billingInfo?.get('city')?.errors &&billingInfo?.get('city')?.hasError('required')">
                            {{ (errorMessage?.ERROR)['CITY']['required'] }}
                        </mat-error>
                        <mat-error data-control="cityPatternError"
                            *ngIf="billingInfo?.get('city')?.errors &&billingInfo?.get('city')?.hasError('pattern')">
                            {{ (errorMessage?.ERROR)['CITY']['pattern'] }}
                        </mat-error>
                        <mat-error data-control="cityMaxLengthError"
                            *ngIf="billingInfo?.get('city')?.errors &&billingInfo?.get('city')?.touched&&!billingInfo?.get('city')?.hasError('pattern')&&billingInfo?.get('city')?.hasError('maxlength')">
                            {{ (errorMessage?.ERROR)['CITY']['maxlength'] }}
                        </mat-error>
                    </mat-form-field>
                </div>
                <div fxLayout="row" fxLayoutGap="15px" fxLayoutGap.xs='0' fxLayout.xs="column" class="billing-address">
                    <mat-form-field fxFlex="31%" appearance="outline" *ngIf="billingInfo?.get('country')">
                        <mat-label>Country</mat-label>
                        <mat-select (openedChange)="$event===false?onCountryFilterClose():null" required
                            (selectionChange)="getStates()" formControlName="country" data-control="country">
                            <div fxLayout="row" class="search-country">
                                <input matInput fxFlex="90%" fxFlex.lt-md="92%" fxFlex.lt-sm="85%"
                                    placeholder="Search Country" [formControl]="countrySearchFilter"
                                    (keyup)="onCountrySearchFilter($event?.target?.value)" data-control="countrySearch">
                                <mat-icon style="margin-top: 7px;cursor: pointer;" *ngIf="countrySearchFilter?.value"
                                    fxFlex="10%" fxFlex.lt-md="8%" fxFlex.lt-sm="15%" (click)="onCountryFilterClose()"
                                    data-control="closeIconCountry">
                                    close
                                </mat-icon>
                            </div>
                            <mat-divider></mat-divider>
                            <mat-option *ngIf="searchCountries?.length == 0" data-control="noCountry">No Countries
                                Found
                            </mat-option>
                            <mat-option *ngFor="let country of searchCountries" [value]="country"
                                [attr.data-control]="('country'+country?.name)|dataControl">
                                {{country?.name}}
                            </mat-option>
                        </mat-select>
                        <mat-error *ngIf=" billingInfo?.get('country')?.errors &&billingInfo?.get('country')?.touched">
                            <span *ngFor="let k of billingInfo?.get('country')?.errors | keyvalue"
                                [attr.data-control]="('country'+k?.key)|dataControl">
                                {{ (errorMessage?.ERROR)['COUNTRY'][k.key] }}</span>
                        </mat-error>
                    </mat-form-field>
                    <mat-form-field fxFlex="32%" appearance="outline" *ngIf="billingInfo?.get('state')">
                        <mat-label>State</mat-label>
                        <mat-select (openedChange)="$event===false?clearStateFilter():null" required
                            [disabled]="!billingInfo?.value?.country" formControlName="state" data-control="state">
                            <div fxLayout="row" class="search-country">
                                <input matInput fxFlex="90%" placeholder="Search State"
                                    [formControl]="stateSearchFilter" data-control="stateSearch"
                                    (keyup)="onStateSearchFilter($event?.target?.value)" autocomplete="off">
                                <mat-icon style="margin-top: 7px;cursor: pointer;" fxFlex="10%"
                                    (click)="clearStateFilter()" *ngIf="stateSearchFilter?.value"
                                    data-control="stateSearchClose">
                                    close
                                </mat-icon>
                            </div>
                            <mat-divider></mat-divider>
                            <mat-option *ngIf="searchStates?.length == 0" data-control="noState">No State Found
                            </mat-option>
                            <mat-option *ngFor="let state of searchStates" [value]="state"
                                [attr.data-control]="('state '+state?.name)|dataControl">
                                {{state?.name}}
                            </mat-option>
                        </mat-select>
                        <mat-error *ngIf="billingInfo?.get('state')?.errors &&billingInfo?.get('state')?.touched">
                            <span *ngFor="let k of billingInfo?.get('state')?.errors | keyvalue"
                                [attr.data-control]="('state'+k?.key)|dataControl">
                                {{ (errorMessage?.ERROR)['STATE'][k.key] }}</span>
                        </mat-error>
                    </mat-form-field>
                    <mat-form-field fxFlex="31%" appearance="outline" *ngIf="billingInfo?.get('zipCode')">
                        <mat-label>Zip Code</mat-label>
                        <input trimVal matInput type="text" size="20" autocomplete="off" formControlName="zipCode"
                            required data-control="zipcode" />
                        <mat-error data-control="zipCodeError" *ngIf="billingInfo?.get('zipCode')?.hasError('required')"
                            data-control="zipcodeRequiredError">
                            Zip Code is required!
                        </mat-error>
                        <mat-error data-control="maxLength" *ngIf="billingInfo?.get('zipCode')?.errors?.maxlength">
                            Zip code should not exceed 15 characters.
                        </mat-error>
                    </mat-form-field>
                </div>
            </div>
            <mat-checkbox [formControl]="isSaveCard" data-control="autoRenewal"> Save Card </mat-checkbox>
        </form>
        <!-- <mat-checkbox *ngIf="isEdit || !customerCardDetails" [formControl]="isSaveCard" data-control="save-card"> Save
            Card </mat-checkbox> -->

        <div *ngIf="!isLoading &&paymentType&&(paymentType!== paymentConstant?.paymentOption?.PAYPAL)"
            fxLayoutGap="10px" fxLayoutAlign="center center">
            <!-- <button mat-raised-button class="primary-button" *ngIf="!isEdit && customerCardDetails" type="button"
                (click)="editCard()" data-control="card-edit"> Edit </button>

            <button mat-stroked-button type="button" *ngIf="isEdit" [disabled]="isButtonLoading" (click)="cancelCard()"
                data-control="cancel card">
                Cancel
            </button> -->
            <!-- [ngClass]="( customerCardDetails)?'primary-button payButton':'primary-button newPayButton'" -->
            <button class="primary-button newPayButton" mat-flat-button type="button" [disabled]="isButtonLoading"
                (click)="(isEdit || !customerCardDetails) ? onSubmit(): payment()" data-control="stripePayButton">
                <div fxLayout="row" *ngIf="!isButtonLoading" class="pay-button-in-checkout">
                    <span fxLayoutAlign="start center" fxFlex="80">Pay Now</span>
                    <span fxLayoutAlign="end center" fxFlex="20">
                        <mat-icon class="shopping-cart-icon">
                            shopping_cart
                        </mat-icon></span>
                </div>
                <cen-loader *ngIf="isButtonLoading" [type]="'content'" [name]="'cen-dot-spinner'">
                </cen-loader>
            </button>
        </div>
    </div>

    <div *ngIf="storeDetails?.purchasedCurrency?.currencyUnit === 'INR'">
        <div fxLayoutAlign="center center">
            <img alt="checkout-for-credits" style="width: 79%;
                height: 337px;
                border: solid 2px #ede3e3;
                padding: 2px;" src="../../../assets/checkout-credit.jpg">
        </div>
        <br>
        <div fxLayoutAlign="center" style="margin-bottom: 10px;">
            <button class="primary-button newPayButton" mat-flat-button type="button" [disabled]="isButtonLoading"
                data-control="razorpayPayButton" (click)="createOrderInRazorpay()">
                <div fxLayout="row" *ngIf="!isButtonLoading"
                    class="pay-button-in-checkout">
                    <span fxLayoutAlign="start center" fxFlex="80">Pay Now</span>
                    <span fxLayoutAlign="end center" fxFlex="20">
                        <mat-icon class="shopping-cart-icon">
                            shopping_cart
                        </mat-icon></span>
                </div>
                <cen-loader *ngIf="isButtonLoading" [type]="'content'" [name]="'cen-dot-spinner'">
                </cen-loader>
            </button>
        </div>
    </div>
</div>

<!-- <div fxFlex="68%" mat-elevation-z11 class="outer-card" fxLayoutAlign="center center" fxLayout="column"
            class="planOverrelayContent" *ngIf="storeDetails?.store?.currencyData?.currencyUnit !== 'INR'">

            <button mat-flat-button class="primary-button payButton" type="button" [disabled]="isButtonLoading"
                (click)="(isEdit || !customerCardDetails) ? saveCard(): payment()" data-control="stripe pay button">
                <div *ngIf="!isButtonLoading" style="font-weight: 500;">
                    Proceed
                </div>
                <cen-loader style="width: 10px;" *ngIf="isButtonLoading" [type]="'content'" [name]="'cen-dot-spinner'">
                </cen-loader>
            </button>
        </div> -->
<!-- </div> -->
<!-- </div> -->