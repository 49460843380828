//component for home menu drawer
import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SharedConstant } from 'libs/common/src/lib/constants/shared-constant';
//component for home menu drawer
@Component({
  selector: 'phase-ii-home-menu-drawer',
  templateUrl: './home-menu-drawer.component.html',
  styleUrls: ['./home-menu-drawer.component.scss']
})
//component for home menu drawer
export class HomeMenuDrawerComponent extends SharedConstant implements OnInit {
  /**
   * input given for menus
   */
  @Input() menus;
  /**
   * input given for drawer 
   */
  @Input() drawer;
  /**
   * Variable which is used to show submenu related codes.
   */
  showSubmenu: boolean;
  /**
   * Variable which is used to store menu id for show submenu.
   */
  menuId: number;
  /**
   * Varibable used to set the current user.
   */
  @Input() currentUser;
  /**
   * variable to store the previous menu id
   * @type {number}
   */
  previousMenuId: number;
  /**
   * Component constructor to inject required services.
   * @param route To get current route.
   * @param router To navigate desired route.
   */
  constructor(private route: ActivatedRoute,
    private router: Router) {
    super();
  }
  /**
   * Component OnInit life cycle hook
   */
  ngOnInit(): void {
    this.showSubmenu = false;
  }
  /**
   * Method which is used to navigate to route while clicking submenu.
   * @param routerLink To get router.
   */
  onSubMenuClick(routerLink) {
    this.router.navigate([routerLink]);
  }
  /**
   * Method which is used to navigate to route while click menu.
   * @param menu To get particular menu detail.
   * @returns Returns true if menu having submenu.
   */
  onRouterNavigation(menu) {
    if (menu && menu.subMenu && menu.subMenu.length > 0 && !this.previousMenuId !== menu.id) {
      this.showSubmenu = !this.showSubmenu;
      this.menuId = menu.id;
      if (this.previousMenuId !== menu.id) {
        this.router.navigate([menu.subMenu[0].pageCustomLink]);
        this.showSubmenu = true;
      }
      this.previousMenuId = menu.id;
    } else if (menu && menu.pageCustomLink) {
      this.router.navigate([menu.pageCustomLink]);
      this.showSubmenu = false;
    }
  }
  /**
   * Method which is used to check current menu activation.
   * @param routerLink To get routerlink.
   * @returns Returns true if current route matches with side menu list.
   */
  checkMenu(routerLink) {
    if (this.route && this.route.snapshot && this.route.snapshot['_routerState'] && this.route.snapshot['_routerState'].url === routerLink) {
      return true;
    }
  }

}

