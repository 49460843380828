import { Component, OnInit, PLATFORM_ID, Inject } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
/**
 * Component used to display the scoll top 
 */
@Component({
  selector: 'phase-ii-scroll-top',
  templateUrl: './scroll-top.component.html',
  styleUrls: ['./scroll-top.component.scss']
})
export class ScrollTopComponent implements OnInit {
  /**
   * Variable used to display the scroll to top fab icon
   * @type {boolean}
   */
  showScroll: boolean;
  /**
   * component constructor which is used to inject the required services.
   */
  constructor(@Inject(PLATFORM_ID) private platformId: object) { }
  /**
   * component onInit life cycle hook.
   */
  ngOnInit(): void {
    if (isPlatformBrowser(this.platformId))
      window.addEventListener('scroll', this.scrollEvent);
  }
  /**
   * Method which is used to get the scroll height dynamically inorder to change the stick-background UI.
   * @param event which holds scroll height.
   */
  scrollEvent = (event: any): void => {
    this.showScroll = event.srcElement.scrollingElement.scrollTop >= 300 ? true : false;
  };
  /**
   * Method used to scroll to top and bottom.
   */
  scrollToTop(): void {
    if (isPlatformBrowser(this.platformId)) {
      (function smoothscroll() {
        let currentScroll = document.documentElement.scrollTop || document.body.scrollTop;
        if (currentScroll > 0) {
          window.requestAnimationFrame(smoothscroll);
          window.scrollTo(0, currentScroll - currentScroll / 5);

        }
      })();
    }
  }
}