/**
 * which is used to export the commonly used terms.
 */
export const dataConfig = {
  secretKey: 'c2d0f75d679b71855e3bb4d0e3d90307912f83ab',
  patternValidators: {
    domainValidationPattern: '^www\.[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$',
    emailValidationPattern: '^[_A-Za-z0-9-\\+]+(\\.[_A-Za-z0-9-]+)*@[A-Za-z0-9-]+(\\.[A-Za-z0-9]+)*(\\.[A-Za-z]{2,})$',
    emailValidationPatternForSignIn: /^(([^<>()[\]\\.,;:@"]+[a-zA-Z\-0-9](\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,})[\s]{0,})$/,
    contactValidationPattern: /^[\(][2-9]\d{2}[\)][\s]\d{3}[\-]\d{4}$/,
    passwordValidationPattern: /^(?=^.{6,20}$)((?=.*\d)(?=.*\W+))(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/,
    noSpaceValidationPattern: '.*\\S.*[a-zA-z0-9]*',
    beginingSpaceValidationPattern: '\\S.*[a-zA-z0-9]*',
    exact10NumberValidationPattern: /^[2-9]\d{2}\d{3}\d{4}$/,
    acceptsCharacterWithSpaces: /^([a-zA-Z]+\s)*[a-zA-Z]+$/,
    acceptsCharacterOnly: '^[-a-zA-Z]+(\\s+[-a-zA-Z]+)*$',
    acceptsCharacterNumberWithSpaces: /^([a-zA-Z0-9]+\s)*[a-zA-Z0-9]+$/,
    acceptsAlphabetsWithSpecialCharacters: /^([a-zA-Z0-9&%]+\s)*[a-zA-Z0-9&%]+$/,
    noSpaceInBegAndEnd: /^[^\s]+(\s+[^\s]+)*$/,
    acceptOnlyCharacters: '^([-a-zA-Z])+(\\s+[-a-zA-Z]+)*$',
    acceptOnlyCharactersWithOptionalSpace: /^\s*([-a-zA-Z]+(?:\s+[-a-zA-Z]+)*)\s*$/,
    acceptOnlyNumbers: '^[0-9]*$',
    taxIdValidation: /[0-9]{11}$/,
    amountwithTwoDecimalPoint: '^[0-9]+(\\.[0-9][0-9]?)?',
    emptySpace: '(?!^ +$)^.+$',
    emptyString: '^(?!^ +$)^.+$',
    displayNameValidation: '^[a-zA-Z0-9_]+$',
    // acceptsNumberswithSpecialCharacters: '^[0-9_@./#&+-]*$/',
    urlValidation: '(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?',
    amountValidationPattern: /^(\d+(?:[\.]\d+)?|\.\d+)$/,
    zipcodeValidationPattern: /^((\d{5}-\d{4})|(\d{5})|([A-Z]\d[A-Z]\s\d[A-Z]\d))$/,
    stateValidationPattern: '[A-Za-z]{2}',
    specialcharacterValidatorPattern: /^[\w\s]+$/,
    cardValidationPattern: '[0-9]{13,16}',
    cvvValidationPattern: '[0-9]{3,4}',
    acceptOnlyAlphabets: '^[a-zA-Z\/\s\'-().\-]+(\\s[a-zA-Z\/\s\'-().]+)*$',
    phoneNumberPattern: '^\\d{3}[\\-]\\d{3}[\\-]\\d{4}$',
    acceptOnlyNumberAndDecimal: '^[0-9]+(?:\.[0-9]{1,2})?$',
    mobileNumberPattern: '[2-9][0-9]{9}',
    numbersFromOne: '^[1-9]*$',
    acceptIntegerWithoutSymbols: /^[0-9]\d*$/,
    taxIdPattern: '^\\d{2}[\\-]\\d{7}$',
    // acceptsAlphabetsWithSpecialCharacters: /^[a-z._%+-]+\\.[a-z.-]+\\.[a-z]{2,4}$/,
    spaceValidationPattern: '.*\\S.*[a-zA-z0-9]*',
    messageValidationPattern: '^[^\s].+[^\s]$',
    pricePattern: '[0-9]+(\\.[0-9][0-9]?)?',
    acceptThreeDecimalPointPattern: '^[0-9]+(?:\.[0-9]{1,3})?$',
    maxReturnDaysPattern: '[0-9]{0,3}',
    acceptNumbersAndAlphabets: '^[a-zA-Z0-9]*$',
    acceptNumbersFromOneAndAlphabets: '^[a-zA-Z1-9]*$',
    // addressValidationPattern: '^[-a-zA-Z0-9,.]+(\\s+[-a-zA-Z0-9.,]+)*$',
    addressValidationPattern: '^[-a-zA-Z0-9,.\'@#&()"/]+(\\s+[-a-zA-Z0-9.,\'@#&()"/]+)*$',
    // DoorNoValidationPattern: '^[-a-zA-Z0-9/,]+(\\s+[-a-zA-Z0-9/,]+)*$',
    DoorNoValidationPattern: '^[-a-zA-Z0-9,.\'@#&()"/]+(\\s+[-a-zA-Z0-9.,\'@#&()"/]+)*$',
    categoryAndSubCategoryName: /^[A-Z@~`!@#$%^&*()_=+\\\\';: \"\\/?>.<,-]*$/i,
    acceptNumbersFromOne: '^[1-9]+([0-9]*$)',
    latitudePattern: /^(\+|-)?(?:90(?:(?:\.0{1,6})?)|(?:[0-9]|[1-8][0-9])(?:(?:\.[0-9]{1,6})?))$/,
    longitudePattern: /^(\+|-)?(?:180(?:(?:\.0{1,6})?)|(?:[0-9]|[1-9][0-9]|1[0-7][0-9])(?:(?:\.[0-9]{1,6})?))$/,
    zipcodesValidationPattern: /^\d{5}(?:-\d{4})?(?:,\s*\d{5}(?:-\d{4})?)+$/,
    pricePatterns: '[1-9]+(\\.[0-9][0-9]?)?',
    urlValidatonPattern: '(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?',
    // acceptLettersNumbersAndSpace: '\\S.*[a-zA-z0-9 ]*',
    acceptLettersNumbersAndSpace: /^([a-zA-Z0-9]+\s)*[a-zA-Z0-9]+$/,
    acceptCharacterAndComma: /^([a-zA-Z0-9&$@#%*!,]+\s)*[a-zA-Z0-9&&$@#%*,]+$/,
    messagesPattern: '^(?! )(?!.* $)[a-zA-Z0-9 ().?_%$!,\'@#"-]+$',
    acceptsBothDecimalAndNumbers: '[0-9]+(\\.[0-9][0-9]?)?',
    acceptsNumbersCharactersandSomeSpecialCharacters: /^[A-Za-z0-9.:\-;_,\](/|\[)]*$/,
    acceptSmallLetterandUnderscore: /^[a-z]+(_*[a-z]+)*$/,
    skuValidation: /^[a-zA-Z0-9,_\-\.]+$/,
    facebookLinkValidation: '(((https?:\/\/)?(www\.)?facebook\.com\/))?(.*\/)?([a-zA-Z0-9.]*)($|\\?.*)',
    youtubeLinkValidation: '((?:https?:)?\/\/)?((?:www|m)\.)??(?:www\.)?((?:youtube(-nocookie)?\.com|youtu.be))?(.*\/)?($|\\?.*)',
    instagramLinkValidation: '((?:https?:)?\/\/)?((?:www|m)\.)?(?:www\.)?(?:instagram\.com)\/([A-Za-z0-9-_\.]+)',
    contactValidationPatternonlyNumeric: /^[0-9]+$/,
    acceptLessthanFiveWords: /^(\S+\s*){0,4}$/
  },
  storeAdminCode: 'STOREADMIN',
  superAdminCode: 'SUPERADMIN',
  paymentOption: {
    stripe: "Stripe",
    square: "Square",
    razorpay: "Razorpay",
    STRIPE: "STRIPE",
    SQUARE: "SQUARE",
    RAZORPAY: "RAZORPAY",
    PAYPAL: "PAYPAL",
    CHECKOUT: "CHECKOUT",
    AUTHORIZEDOTNET: "AUTHORIZE.NET",
    CHECKOUTDOTCOM: "CHECKOUT.COM"
  },
  encryptionSecretKey: "uOTD2R7B8mXQeVKNffUgXFahfioXqn",
  /**
   * Variable used to display text while adding wishlist items.
   * @type {string}
   */
  addToWishlistTooltip: "Add to Wishlist",
  /**
   * Variable used to display text while removing wishlist items.
   * @type {string}
   */
  removeFromWishlistTooltip: "Remove from Wishlist",
  /**
   * Variable used to check for top left ribbon.
   * @type {string}
   */
  topLeftText: 'topLeft',
  /**
 * Variable used to check for top right ribbon.
 * @type {string}
 */
  topRightText: 'topRight',
  /**
 * Variable used to check for bottom left ribbon.
 * @type {string}
 */
  bottomLeftText: 'bottomLeft',
  /**
 * Variable used to check for bottom right ribbon.
 * @type {string}
 */
  bottomRightText: 'bottomRight',
  beverage: 'Beverage',
  imageUrl: 'https://centizencart.s3.us-west-2.amazonaws.com/images/zenbasket',
  zenbaskets3Folder: 'zenbasket',
  data: {
    forgotPassword: {
      emailSent: 'We just emailed you a link',
      checkEmail: 'Please check your email and click the secure link',
    },
    mail: 'shopping@gmail.com',
  },
  S3BucketUrl: "https://centizencart.s3.us-west-2.amazonaws.com/images/",
  templateNames: {
    enduserRequestTemplate: 'Enduser Request',
  },
  //Mixpanel events
  orderSuccessEvent: 'Order Success',
  orderFaliureEvent: 'Order Failed',
  // industry types
  grocery: 'Grocery',
  restaurant: 'Restaurant',
  retail: 'Retail',
  farm: 'Farm',
  coffeeShop: 'Coffee Shop',
  bakery: 'Bakery',
  //template names
  withoutImageTemplate: 'Without Image Template',
  mixPanelKey: '1ee7c32550341a343ecc1d485e351275',
  stripeKey:
    'pk_test_51Is9pzK4cKXTpH6I6VrdFaMUh8evCD211SXEK4451KQNEsz2mymNdVGebakmaPhaJ9ViAioR0TfYw4SotxTLdZkr00dANAsGHj',
  stripeProductionKey: 'pk_live_51Is9pzK4cKXTpH6IpUWrKOIThosheWrHdiONZGzlKIsCm0AREf0lP4QudrG8mLwVMjt23DdEPP05rRr8ofusZsPt005cbIZ2S1',
  checkoutPublicApiKey: 'pk_sbox_jpcunhav3cjhz2cvpqrnvejuvqk',
  checkoutProductionPublicApiKey: 'pk_sbox_jpcunhav3cjhz2cvpqrnvejuvqk',
  paypalPublicApiKey: 'ASQ9sJZq1TzDapxswWOt1ieb-L0R7jgCa6QYzzUlm4eXorvDq-j7wJft1tzQu5tXPfGV03g2chDyAm2H',
  paypalProductionPublicApiKey: 'ASQ9sJZq1TzDapxswWOt1ieb-L0R7jgCa6QYzzUlm4eXorvDq-j7wJft1tzQu5tXPfGV03g2chDyAm2H',
  stripeOption: 'Stripe',
  squareOption: 'Square',
  paypalOption: 'Paypal',
  amazonpayOption: 'Amazonpay',
  razorpayOption: 'Razorpay',
  authorizenet: 'Authorize.net',
  codOption: 'payByCash',
  localDeliverybyStore: 'Local Delivery by Store',
  validator: {
    emailValidationPattern: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,3}))$/,
    passwordValidationPattern: /^(?=^.{7,20}$)((?=.*\d)|(?=.*\W+))(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/,
    spaceValidationPattern: '.*\\S.*[a-zA-z0-9]*',
    // messageValidationPattern: '^[^\s].+[^\s]$',
    // messageValidationPattern: /^[0-9.,?_%$!@#a-zA-Z \n]*$/,
    messageValidationPattern: /^[^-\s][a-zA-Z0-9@%#!$?_,.\s-]*$/,
    contactValidationPattern: /^[\(][2-9]\d{2}[\)][\s]\d{3}[\-]\d{4}$/,
    exact10NumberValidationPattern: /^[2-9]\d{2}\d{3}\d{4}$/,
    acceptOnlyCharacters: '^[-a-zA-Z]+(\\s+[-a-zA-Z]+)*$',
    acceptOnlyNumbers: '^[0-9]*$',
    // zipcodeValidationPattern: /^((\d{5}-\d{4})|(\d{5})|([A-Z]\d[A-Z]\s\d[A-Z]\d))$/,
    zipcodeValidationPattern: /^((\d{6}-\d{5})|(\d{6})|(\d{5})|([A-Z]\d[A-Z]\s\d[A-Z]\d))$/,
    stateValidationPattern: '[A-Za-z]{2}',
    cardValidationPattern: '[0-9]{13,16}',
    cvvValidationPattern: '[0-9]{3,4}',
    pricePattern: '[0-9]+(\\.[0-9][0-9]?)?',
    acceptThreeDecimalPointPattern: '^[0-9]+(?:\.[0-9]{1,3})?$',
    maxReturnDaysPattern: '[0-9]{0,3}',
    // addressValidationPattern: '^[-a-zA-Z0-9,.]+(\\s+[-a-zA-Z0-9.,]+)*$',
    addressValidationPattern: '^[-a-zA-Z0-9,.\'@#&()"/]+(\\s+[-a-zA-Z0-9.,\'@#&()"/]+)*$',
    // DoorNoValidationPattern: '^[-a-zA-Z0-9/,]+(\\s+[-a-zA-Z0-9/,]+)*$',
    DoorNoValidationPattern: '^[-a-zA-Z0-9,.\'@#&()"/]+(\\s+[-a-zA-Z0-9.,\'@#&()"/]+)*$',
    categoryAndSubCategoryName: /^[A-Z0-9@~`!@#$%^&*()_=+\\\\';: \"\\/?>.<-]*$/i,
    acceptNumbersFromOne: '^[1-9]+([0-9]*$)',
    latitudePattern: /^(\+|-)?(?:90(?:(?:\.0{1,6})?)|(?:[0-9]|[1-8][0-9])(?:(?:\.[0-9]{1,6})?))$/,
    longitudePattern: /^(\+|-)?(?:180(?:(?:\.0{1,6})?)|(?:[0-9]|[1-9][0-9]|1[0-7][0-9])(?:(?:\.[0-9]{1,6})?))$/,
    zipcodesValidationPattern: /^\d{5}(?:-\d{4})?(?:,\s*\d{5}(?:-\d{4})?)+$/,
    specialcharacterValidatorPattern: /^[\w\s]+$/,
    pricePatterns: '[1-9]+(\\.[0-9][0-9]?)?',
    numbersFromOne: '^[1-9]*$',
    urlValidation: '(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?',
    // zeroValidator: '/^-?([1-9]\d*)(\.\d+)?$/',
    spaceNewLineValidationPattern: '.*\\S.*[a-zA-z0-9\n]*',
    newEmailValidationPattern: /^\s*(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,3}))\s*$/,
  },
  actionNames: {
    viewAction: 'View',
    editAction: 'Edit',
    deleteAction: 'Delete',
  },
  confirmationforstatusChange: 'Are you sure you want to change status as ',
  successStatusChangeMessage: 'Successfully status changed as status ',
  failedStatusChangeMessage: 'Failed to change status as  ',
  warning: 'Warning',
  transferred: 'Transferred',
  success: 'Success',
  pending: 'Pending',
  failure: 'Failure',
  all: 'All',
  new: 'New',
  authorized: 'Authorized',
  paid: 'Paid',
  unpaid: 'Unpaid',
  refunded: 'Refunded',
  cash_refund: 'Cash Refund',
  cancelled: 'Cancelled',
  partial_refund: 'Partial Refund',
  paid_manually: 'Paid Manually',
  not_assigned: 'N/A',
  topNavBarRoles: ['User', 'Guest User'],
  guestUserRole: 'Guest User',
  userRole: 'User',
  approved: 'Approved',
  denied: 'Denied',
  weekDays: [
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
  ],
  pageSize: 10,
  SuperAdminRoleId: 1,
  AdminRoleId: 2,
  StaffsRoleId: 3,
  UserRoleId: 4,
  CustomSuperAdminRoleId: 5,
  disabledUserStatusId: 3,
  VerifiedUserId: 2,
  UnverifiedUserId: 1,
  ActivateUserStatusId: 1,
  CancelTemplateId: 13,
  superAdminMenuItems: [
    {
      name: 'Dashboard',
      link: 'dashboard',
      iconName: 'dashboard',
    },
    {
      name: 'General',
      link: 'general',
      iconName: 'settings',
    },
    {
      name: 'Store Industry Type',
      link: 'store-industry',
      iconName: 'store',
    },
    {
      name: 'All Companies',
      link: 'all-customer',
      iconName: 'people_outline',
    },
    {
      name: 'User Management',
      link: 'user-management',
      iconName: 'people',
    },
    {
      name: 'Image Collection',
      link: 'image-collection',
      iconName: 'add_a_photo',
    },
    {
      name: 'Image Category',
      link: 'image-category',
      iconName: 'photo_camera',
    },
    {
      name: 'Subscription Plan',
      link: 'subscription-plan',
      iconName: 'attach_money',
    },
    {
      name: 'Invoices',
      link: 'subscription-invoice-list',
      iconName: 'receipt',
    },
    {
      name: 'Tax Management',
      link: 'tax-management',
      iconName: 'attach_money',
    },
    // {
    //   name: 'Delivery Option',
    //   link: 'delivery-option',
    //   iconName: 'directions_bus',
    // },
    {
      name: 'Plugins Management',
      link: 'plugins-management',
      iconName: 'add_circle',
    },
    {
      name: 'Plugins Category',
      link: 'plugin-category',
      iconName: 'dashboard',
    },
    {
      name: 'Weight Units',
      link: 'weight-units',
      iconName: 'line_weight',
    },
    {
      name: 'Store Page Menu',
      link: 'store-page-menu',
      iconName: 'menu',
    },
    {
      name: 'Error Log',
      link: 'error-log',
      iconName: 'error',
    },
    {
      name: 'Imports',
      link: 'import',
      iconName: 'add_circle',
    },
  ],
  priceModifier: ['+', '-'],
  TableBasedOn: [{ name: 'Subtotal', code: 'SUBTOTAL' }, { name: 'Weight', code: 'WEIGHT' }],
  RateBasedOn: [{ name: 'Range Only', code: 'RANGE_ONLY' }, { name: 'Range, subtotal, weight', code: 'RANGE_SUBTOTAL_WEIGHT' }],
  ShippingRegionType: [
    { name: 'All destinations', code: 'ALL_DESTINATION', description: 'Select this option if you ship worldwide with this method.' },
    { name: 'Advance settings', code: 'ADVANCED_SETTINGS', description: 'Set delivery zone by country, Zip/postal code range, or state if you deliver to specific regions.' }
  ],
  ShippingRateType: [
    { id: 1, code: 'FREE_SHIPPING' },
    { id: 2, code: 'CONDITIONAL_FREE_SHIPPING' },
    { id: 3, code: 'FLAT_RATE' },
    { id: 4, code: 'CUSTOM_RATE' },
    { id: 5, code: 'GOOGLE_DISTANCE' }
  ],
  TimeZones: [
    { label: '(GMT-12:00) International Date Line West', value: 'Etc/GMT+12' },
    { label: '(GMT-11:00) Midway Island, Samoa', value: 'Pacific/Midway' },
    { label: '(GMT-10:00) Hawaii', value: 'Pacific/Honolulu' },
    { label: '(GMT-09:00) Alaska', value: 'US/Alaska' },
    {
      label: '(GMT-08:00) Pacific Time (US & Canada)',
      value: 'America/Los_Angeles',
    },
    { label: '(GMT-08:00) Tijuana, Baja California', value: 'America/Tijuana' },
    { label: '(GMT-07:00) Arizona', value: 'US/Arizona' },
    {
      label: '(GMT-07:00) Chihuahua, La Paz, Mazatlan',
      value: 'America/Chihuahua',
    },
    { label: '(GMT-07:00) Mountain Time (US & Canada)', value: 'US/Mountain' },
    { label: '(GMT-06:00) Central America', value: 'America/Managua' },
    { label: '(GMT-06:00) Central Time (US & Canada)', value: 'US/Central' },
    {
      label: '(GMT-06:00) Guadalajara, Mexico City, Monterrey',
      value: 'America/Mexico_City',
    },
    { label: '(GMT-06:00) Saskatchewan', value: 'Canada/Saskatchewan' },
    {
      label: '(GMT-05:00) Bogota, Lima, Quito, Rio Branco',
      value: 'America/Bogota',
    },
    { label: '(GMT-05:00) Eastern Time (US & Canada)', value: 'US/Eastern' },
    { label: '(GMT-05:00) Indiana (East)', value: 'US/East-Indiana' },
    { label: '(GMT-04:00) Atlantic Time (Canada)', value: 'Canada/Atlantic' },
    { label: '(GMT-04:00) Caracas, La Paz', value: 'America/Caracas' },
    { label: '(GMT-04:00) Manaus', value: 'America/Manaus' },
    { label: '(GMT-04:00) Santiago', value: 'America/Santiago' },
    { label: '(GMT-03:30) Newfoundland', value: 'Canada/Newfoundland' },
    { label: '(GMT-03:00) Brasilia', value: 'America/Sao_Paulo' },
    {
      label: '(GMT-03:00) Buenos Aires, Georgetown',
      value: 'America/Argentina/Buenos_Aires',
    },
    { label: '(GMT-03:00) Greenland', value: 'America/Godthab' },
    { label: '(GMT-03:00) Montevideo', value: 'America/Montevideo' },
    { label: '(GMT-02:00) Mid-Atlantic', value: 'America/Noronha' },
    { label: '(GMT-01:00) Cape Verde Is.', value: 'Atlantic/Cape_Verde' },
    { label: '(GMT-01:00) Azores', value: 'Atlantic/Azores' },
    {
      label: '(GMT+00:00) Casablanca, Monrovia, Reykjavik',
      value: 'Africa/Casablanca',
    },
    {
      label:
        '(GMT+00:00) Greenwich Mean Time : Dublin, Edinburgh, Lisbon, London',
      value: 'Etc/Greenwich',
    },
    {
      label: '(GMT+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna',
      value: 'Europe/Amsterdam',
    },
    {
      label: '(GMT+01:00) Belgrade, Bratislava, Budapest, Ljubljana, Prague',
      value: 'Europe/Belgrade',
    },
    {
      label: '(GMT+01:00) Brussels, Copenhagen, Madrid, Paris',
      value: 'Europe/Brussels',
    },
    {
      label: '(GMT+01:00) Sarajevo, Skopje, Warsaw, Zagreb',
      value: 'Europe/Sarajevo',
    },
    { label: '(GMT+01:00) West Central Africa', value: 'Africa/Lagos' },
    { label: '(GMT+02:00) Amman', value: 'Asia/Amman' },
    {
      label: '(GMT+02:00) Athens, Bucharest, Istanbul',
      value: 'Europe/Athens',
    },
    { label: '(GMT+02:00) Beirut', value: 'Asia/Beirut' },
    { label: '(GMT+02:00) Cairo', value: 'Africa/Cairo' },
    { label: '(GMT+02:00) Harare, Pretoria', value: 'Africa/Harare' },
    {
      label: '(GMT+02:00) Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius',
      value: 'Europe/Helsinki',
    },
    { label: '(GMT+02:00) Jerusalem', value: 'Asia/Jerusalem' },
    { label: '(GMT+02:00) Minsk', value: 'Europe/Minsk' },
    { label: '(GMT+02:00) Windhoek', value: 'Africa/Windhoek' },
    { label: '(GMT+03:00) Kuwait, Riyadh, Baghdad', value: 'Asia/Kuwait' },
    {
      label: '(GMT+03:00) Moscow, St. Petersburg, Volgograd',
      value: 'Europe/Moscow',
    },
    { label: '(GMT+03:00) Nairobi', value: 'Africa/Nairobi' },
    { label: '(GMT+03:00) Tbilisi', value: 'Asia/Tbilisi' },
    { label: '(GMT+03:30) Tehran', value: 'Asia/Tehran' },
    { label: '(GMT+04:00) Abu Dhabi, Muscat', value: 'Asia/Muscat' },
    { label: '(GMT+04:00) Baku', value: 'Asia/Baku' },
    { label: '(GMT+04:00) Yerevan', value: 'Asia/Yerevan' },
    { label: '(GMT+04:30) Kabul', value: 'Asia/Kabul' },
    { label: '(GMT+05:00) Yekaterinburg', value: 'Asia/Yekaterinburg' },
    {
      label: '(GMT+05:00) Islamabad, Karachi, Tashkent',
      value: 'Asia/Karachi',
    },
    {
      label: '(GMT+05:30) Chennai, Kolkata, Mumbai, New Delhi',
      value: 'Asia/Calcutta',
    },
    { label: '(GMT+05:30) Sri Jayawardenapura', value: 'Asia/Calcutta' },
    { label: '(GMT+05:45) Kathmandu', value: 'Asia/Katmandu' },
    { label: '(GMT+06:00) Almaty, Novosibirsk', value: 'Asia/Almaty' },
    { label: '(GMT+06:00) Astana, Dhaka', value: 'Asia/Dhaka' },
    { label: '(GMT+06:30) Yangon (Rangoon)', value: 'Asia/Rangoon' },
    { label: '(GMT+07:00) Bangkok, Hanoi, Jakarta', value: 'Asia/Bangkok' },
    { label: '(GMT+07:00) Krasnoyarsk', value: 'Asia/Krasnoyarsk' },
    {
      label: '(GMT+08:00) Beijing, Chongqing, Hong Kong, Urumqi',
      value: 'Asia/Hong_Kong',
    },
    {
      label: '(GMT+08:00) Kuala Lumpur, Singapore',
      value: 'Asia/Kuala_Lumpur',
    },
    { label: '(GMT+08:00) Irkutsk, Ulaan Bataar', value: 'Asia/Irkutsk' },
    { label: '(GMT+08:00) Perth', value: 'Australia/Perth' },
    { label: '(GMT+08:00) Taipei', value: 'Asia/Taipei' },
    { label: '(GMT+09:00) Osaka, Sapporo, Tokyo', value: 'Asia/Tokyo' },
    { label: '(GMT+09:00) Seoul', value: 'Asia/Seoul' },
    { label: '(GMT+09:00) Yakutsk', value: 'Asia/Yakutsk' },
    { label: '(GMT+09:30) Adelaide', value: 'Australia/Adelaide' },
    { label: '(GMT+09:30) Darwin', value: 'Australia/Darwin' },
    { label: '(GMT+10:00) Brisbane', value: 'Australia/Brisbane' },
    {
      label: '(GMT+10:00) Canberra, Melbourne, Sydney',
      value: 'Australia/Canberra',
    },
    { label: '(GMT+10:00) Hobart', value: 'Australia/Hobart' },
    { label: '(GMT+10:00) Guam, Port Moresby', value: 'Pacific/Guam' },
    { label: '(GMT+10:00) Vladivostok', value: 'Asia/Vladivostok' },
    {
      label: '(GMT+11:00) Magadan, Solomon Is., New Caledonia',
      value: 'Asia/Magadan',
    },
    { label: '(GMT+12:00) Auckland, Wellington', value: 'Pacific/Auckland' },
    {
      label: '(GMT+12:00) Fiji, Kamchatka, Marshall Is.',
      value: 'Pacific/Fiji',
    },
    { label: "(GMT+13:00) Nuku'alofa", value: 'Pacific/Tongatapu' },
  ],
  extraAmount:
    'When you place your order, instead of immediately charging your card, we place a temporary authorization hold for an amount slightly higher than your order amount at checkout. Once the order is completed, the hold will drop off, and your card will be charged for the final total as shown in the email receipt.',
  title: {
    tax: "Tax",
    customer: "Customer",
    averageOrder: "Average Order",
    salesOverTime: "Sales Over Time",
    discount: "Offer",
    product: "Product"
  },
  pageConfig: {
    product: "PRODUCT",
    inventory: "INVENTORY",
    category: "CATEGORY",
    tags: "TAGS",
    offers: "OFFERS",
    orders: "ORDERS",
    routing: "ROUTING",
    abandoned_cart: "ABANDONED_CART",
    general: "GENERAL",
    location: "LOCATION",
    notification: "NOTIFICATION",
    account_management: "ACCOUNT_MANAGEMENT",
    payments: "PAYMENTS",
    legal_forms: "LEGAL_FORMS",
    taxes: "TAXES",
    faq: "FAQ",
    zone_management: "ZONE_MANAGEMENT",
    all_integrations: "ALL_INTEGRATIONS",
    my_integration: "MY_INTEGRATIONS",
    user_enquiry: "USER_ENQUIRY",
    transaction_log: "TRANCSACTION_LOG",
    shipping: "SHIPPING",
  },
  locEnv: 'loc',
  uatEnv: 'uat',
  prodEnv: 'mas',

  /**
    * variable which is used to store dialog type.
    */
  // dialogType: { confirmation: 'confirmation', information: 'information', success: 'success', failure: 'failure' },
  /**
   * variable which is used to store the styles of the dialog.
   */
  // dialogStyle: [{ name: 'success', color: 'hsl(162deg 57% 49%)' }, { name: 'failure', color: '#ff5349' }, { name: 'information', color: 'gray' }, { name: 'confirmation', color: 'gray' }],

  successHeader: [
    'Success!',
    'Adding offer',
    'Delete offer'
  ],
  failureHeader: [
    'Failure!',
    'Error',
    'Template creation failed'
  ],
  confirmationHeader: [
    'Confirmation!',
    'Removed!',
    'Cancel!',
    'Enable!',
    'Accept!',
    'Decline!',
    'Disable!',
    'Restore!',
    'Adding Tag',
    'DELETE_STORE_HEADER',
    'ADD_STORE_HEADER',
    'Remove tag',
    'Close Account?',
  ]
};
