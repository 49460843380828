import { NgModule } from '@angular/core';
import { CanDeactivateGuard } from './services/can-deactivate-guard.service';

import { PaginatorComponent } from './components/paginator/paginator.component';
import { ExportComponent } from './components/export/export.component';
import { FilterComponent } from './components/filter/filter.component';

// import { CustomizedFormComponent } from './components/customized-form/customized-form.component';
import { PaymentDetailsComponent } from './components/payment-details/payment-details.component';
import { CommonCardViiComponent } from './components/common-card-vii/common-card-vii.component';
import { CenLoaderComponent } from './components/cen-loader/cen-loader.component';
import { PaginatorViiComponent } from './components/paginator-vii/paginator-vii.component';
import { HighchartsChartModule } from 'highcharts-angular';
import { CoreModule } from 'libs/common/src/lib/common.module';
import { NgxPayPalModule } from 'ngx-paypal';
// import { CommonSliderComponent } from './components/common-slider/common-slider.component';
// import { SizeChartComponent } from '../../../common-admin-module/src/lib/components/size-chart/size-chart.component';
import { RedeemCouponCodeComponent } from './components/redeem-coupon-code/redeem-coupon-code.component';
import { CartDiscountsComponent } from './components/cart-discounts/cart-discounts.component';
import { SharerMethod } from 'ngx-sharebuttons';
import { ShareModule } from 'ngx-sharebuttons';
import { ShareButtonsComponent } from './components/share-buttons/share-buttons.component';
import { BlogDetailComponent } from './components/blog-detail/blog-detail.component';
import { DataControlPipe } from './pipes/data-control.pipe';
import { customTextEditorComponent } from './components/custom-text-editor/custom-text-editor.component';
import { LoadTranslationService } from '@phase-ii/common';
// import { MAT_DATE_LOCALE } from '@angular/material/core';
import { ColorPickerModule } from 'ngx-color-picker';
import { ContentEditableDirective } from './directive/content-editable.directive';
import { MultiLanguagePipe } from 'libs/themes/shared-theme/src/lib/pipes/multi-language.pipe';

@NgModule({
  imports: [
    CoreModule,
    HighchartsChartModule,
    NgxPayPalModule,
    ShareModule.withConfig({
      sharerMethod: SharerMethod.Window,
      windowWidth: 800,
      windowHeight: 500,
    }),
    // AuthModule
    // gjghkhl
    ColorPickerModule,
    MultiLanguagePipe
  ],
  declarations: [
    PaginatorComponent,
    ExportComponent,
    // CustomizedFormComponent,
    FilterComponent,
    PaymentDetailsComponent,
    CommonCardViiComponent,
    // CommonStartEndTimeComponent
    CenLoaderComponent,
    PaginatorViiComponent,
    // CommonSliderComponent,
    // SizeChartComponent,
    RedeemCouponCodeComponent,
    CartDiscountsComponent,
    ShareButtonsComponent,
    DataControlPipe,
    BlogDetailComponent,
    DataControlPipe,
    customTextEditorComponent,
    ContentEditableDirective
  ],
  exports: [
    CoreModule,
    // AuthModule,
    // CustomizedFormComponent,
    PaginatorComponent,
    ExportComponent,
    FilterComponent,
    PaymentDetailsComponent,
    CommonCardViiComponent,
    CenLoaderComponent,
    PaginatorViiComponent,
    HighchartsChartModule,
    // CommonSliderComponent,
    // SizeChartComponent,
    RedeemCouponCodeComponent,
    CartDiscountsComponent,
    ShareButtonsComponent,
    BlogDetailComponent,
    DataControlPipe,
    customTextEditorComponent,
    ContentEditableDirective
  ],
  // entryComponents: [ExportComponent],
  providers: [
    CanDeactivateGuard,
    // { provide: MAT_DATE_LOCALE, useValue: 'en-GB' }
  ],
})
export class SharedModule {
  // constructor(private loadTranslate: LoadTranslationService) {
  //   // this.loadTranslate.multiLoadTranslation(['common_card','paginator']);
  // }
}
