/**
 * Import statements used for the custom service validators.
 */
import { Validators, AbstractControl, ValidatorFn, ValidationErrors, UntypedFormArray } from '@angular/forms';
/**
 * Service to control custom validator
 */
export class CustomValidators extends Validators {
    static checkDuplicateValues(control: AbstractControl): any {
        let count = 0; let duplicateFound = false, index = [];
        for (let i = 0; i < control.value.length; i++) {
            for (let j = i + 1; j < control.value.length; j++) {
                if (control?.value[i] && control?.value[j]) {
                    if ((control.value[i] === control.value[j])) {
                        duplicateFound = true; index.push(j);
                        (control as UntypedFormArray)['controls'][j].setErrors({ isDuplicate: true });
                    }
                }
            }
            count++;
        }
        if (count === control.value.length) {
            (control as UntypedFormArray)['controls'].forEach((element: any) => {
                if (element?.errors?.isDuplicate)
                    element.setErrors(null);
            });
            if (index.length > 0) {
                index.forEach((res: any) => {
                    (control as UntypedFormArray)['controls'][res].setErrors({ isDuplicate: true });
                });
            }
            return null;
        }
    }
    /**
  * Static method pickupTimeValidation used for pickup time validation.
  * @param control is the form control.
  * @returns validators.
  */
    static pickupTimeValidation(control: AbstractControl) {
        let To;
        let From;
        let ToMin;
        let FromMin;
        if (control && control.value) {
            if (control && control.value && control.value.endTime) {
                const indexTo = control.value.endTime.toString().indexOf(':');
                To = Number(control.value.endTime.slice(0, indexTo));
                if (control.value.endTime.toString().indexOf('PM') >= 0) {
                    To = To === 12 ? To : To + 12;
                } else {
                    if (To === 12) {
                        To = 0;
                    }
                }
                ToMin = control.value.endTime.slice(indexTo + 1, control.value.endTime.length - 2);
            }
            if (control && control.value && control.value.startTime) {
                const indexFrom = control.value.startTime.toString().indexOf(':');
                From = Number(control.value.startTime.slice(0, indexFrom));
                if (control.value.startTime.toString().indexOf('PM') >= 0) {
                    From = From === 12 ? From : From + 12;
                } else {
                    if (From === 12) {
                        From = 0;
                    }
                }
                FromMin = control.value.startTime.slice(indexFrom + 1, control.value.startTime.length - 2);
            }
            if (From > To) {
                control.get('endTime').setErrors({ ToTimeExceeds: true });
            } else if (FromMin && From === To && FromMin === ToMin) {
                control.get('endTime').setErrors({ ToTimeExceeds: true });
            } else if (From === To && FromMin > ToMin) {
                control.get('endTime').setErrors({ ToTimeExceeds: true });
            } else {
                const errorObject = control.get('endTime') && control.get('endTime').errors;
                if (errorObject && errorObject.hasOwnProperty('ToTimeExceeds')) {
                    if (errorObject && Object.keys(errorObject).length > 1) {
                        delete errorObject.ToTimeExceeds;
                        control.get('endTime').setErrors(errorObject);
                    } else {
                        control.get('endTime').setErrors(null);
                    }
                }
            }
            // if (To === "12 AM") {
            //     control.get('endTime').setErrors({ ToTimeExceeds: true });
            // }
        } else {
            return null;
        }
    }
}


