<div *ngIf="data?.actionType !== dialogType?.earningPoints">
  <!-- Header Section -->
  <div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="20px" [style.background-color]="data?.color">
    <div class="svg-box"
      *ngIf="data?.actionType!==dialogType?.information && data?.actionType!==dialogType?.confirmation">
      <!-- Circle Animation -->
      <svg class="circular">
        <circle stroke="white" class="path" cx="70" cy="70" r="35" fill="none" stroke-width="4"
          stroke-miterlimit="10" />
      </svg>
      <!-- Checkmark Animation -->
      <svg class="checkmark" *ngIf="data?.actionType==dialogType?.success">
        <g transform="matrix(0.79961,8.65821e-32,8.39584e-32,0.79961,-489.57,-205.679)" stroke="white">
          <path class="checkmark__check" fill="none" d="M616.306,283.025L634.087,300.805L673.361,261.53" />
        </g>
      </svg>
      <!-- Cross Animation -->
      <svg class="cross" *ngIf="data?.actionType===dialogType?.failure">
        <g transform="matrix(0.79961,8.65821e-32,8.39584e-32,0.79961,-502.652,-204.518)" stroke="white">
          <path class="first-line" d="M634.087,300.805L673.361,261.53" fill="none" />
        </g>
        <g transform="matrix(-1.28587e-16,-0.79961,0.79961,-1.28587e-16,-204.752,543.031)" stroke="white">
          <path class="second-line" d="M634.087,300.805L673.361,261.53" />
        </g>
      </svg>
      <!-- Alert Animation -->
      <svg class="alert-sign" *ngIf="data?.actionType===dialogType?.alert">
        <g transform="matrix(1,0,0,1,-615.516,-257.346)" stroke="#f2d422">
          <g transform="matrix(0.56541,-0.56541,0.56541,0.56541,93.7153,495.69)">
            <path class="line" d="M634.087,300.805L673.361,261.53" fill="none" />
          </g>
          <g transform="matrix(2.27612,-2.46519e-32,0,2.27612,-792.339,-404.147)">
            <circle class="dot" cx="621.52" cy="316.126" r="1.318" />
          </g>
        </g>
      </svg>
    </div>
    <!-- Information/Confirmation Animation -->
    <svg *ngIf="data?.actionType===dialogType?.information || data?.actionType===dialogType?.confirmation"
      class="dialog-content-icon">
      <use attr.xlink:href="assets/symbol-definition.svg#{{data?.actionType}}"></use>
    </svg>
    <div class="dialog-header">{{data?.header}}</div>
  </div>
  <!-- Middle Section (Dialog Content)-->
  <div mat-dialog-content
    [ngStyle]="{'border-top':(data?.actionType!==dialogType?.success && data?.actionType!==dialogType?.failure) ? '5px solid #f2d422' : 'none' }"
    fxLayout="row" fxLayoutAlign="center center">
    <div class="dialog-sub-content">{{data?.message}}</div>
  </div>
  <!-- Footer Section (Action Icons)-->
  <div mat-dialog-actions fxLayout="row" fxLayoutAlign="center center">
    <button *ngIf="data?.button?.left" mat-button matDialogClose data-control="leftbutton"
      [style.color]="data?.color">{{data?.button?.left}}</button>
    <button *ngIf="data?.button?.right" mat-raised-button fxFlex="50" data-control="rightbutton" fxLayout="row"
      fxLayoutAlign="center center" [style.background-color]="data?.color" class="dialog-action-icon mat-elevation-z4"
      mat-dialog-close="true">
      <div style="display:flex;color: white;">
        <span style="margin-top: 2px;">
          {{data?.button?.right}}</span> &nbsp;<em *ngIf="!data?.arrowNotRequired"
          class="material-icons-outlined">arrow_right_alt
        </em>
      </div>
    </button>
  </div><br>
</div>
<div *ngIf="data?.actionType === dialogType?.earningPoints">
  <div fxLayoutAlign="center center" class="congratulations-dialog-header" fxLayout="column">
    <div fxLayoutAlign="center center" class="congratulations-dialog-image"></div>
    <div fxLayoutAlign="center center" class="congratulations-ribbon"></div>
    <p class="congratulations-dialog-title">{{'COMMON.CONGRATULATIONS' |translate}}</p>
    <div class="congratulations-subheader">
      <div class="congratulations-message">{{data?.message}}</div>
      <div class="congratulations-points">{{'COMMON.YOU_HAVE_EARNED' |translate}} <span><mat-icon class="star-icon">stars</mat-icon></span>
        <span class="highlight-points"> {{data?.noOfPoints}}</span> {{'COMMON.POINTS' |translate}}
      </div>
    </div>
    <button mat-raised-button fxLayoutAlign="center center" class="reedeem-button-dialog"
      mat-dialog-close="true">{{data?.button?.right}}</button>
  </div>
</div>