<div [ngClass]="{'filterTemplateEditor':isEditor}">
    <div *ngIf="filterArray?.length>0 || sortArray?.length>0"
        [ngTemplateOutlet]="filterTemplate?.length>0?getTemplateRefForFilter(filterTemplate):getTemplateRefForFilter('filterTemplateOne')">
    </div>
</div>
<ng-template #filterTemplateOne>
    <div>
        <div [formGroup]="filterFormGroup" [ngClass]="{'gaming-theme':isNoScrollbar}"
            [ngClass.gt-md]="{'filter-content filter-card':true ,'gaming-theme filter-card':isNoScrollbar}"
            class="sticky" ngStyle.lt-md="max-height:none;">
            <span fxHide.md fxHide.gt-md class="material-symbols-outlined close-icon"
                (click)="closeTemplateSheetMenu()">
                close
            </span>
            <!-- Product sorting -->
            <ng-container *ngIf="isSort">
                <div data-control="sort-panel" class="filter-header bold-class">
                    <span class="filter-text">SORT BY </span>
                    <mat-radio-group fxLayout="column" [formControl]="sortOption" (change)="sortChange()"
                        class="radio-container">
                        <ng-container *ngFor="let sort of sortArray">
                            <mat-radio-button *ngIf="sort?.isVisible" [value]="sort" class="sort-radio"
                                [attr.data-control]="sort?.display">
                                <div>{{sort?.display}}</div>
                            </mat-radio-button>
                        </ng-container>
                    </mat-radio-group>
                </div>
                <mat-divider fxHide.lt-sm></mat-divider>
            </ng-container>
            <!-- Applied Filters -->
            <ng-container *ngIf="showFilter">
                <div class="applied-filter-container">
                    <div class="applied-filters">
                        <p class="bold-class">
                            {{commonProductDetails?.filtersApplied|uppercase}}</p>
                        <span (click)="clearFilter()" data-control="clear-filter">
                            <p class="bold-class left-content ">
                                {{commonProductDetails?.clearFilter|uppercase}}</p>
                        </span>
                    </div>
                    <br>
                    <mat-chip-listbox #chipList aria-label="Filters applied">
                        <ng-container
                            *ngFor="let filter of filterFormGroup?.get('filterFormArray')['controls'];let i=index">

                            <ng-container *ngIf="!(filter.get('type').value=='slider')">
                                <ng-container
                                    *ngFor="let checkedFilter of filterFormGroup?.get('filterFormArray')['controls'][i]?.get('options')['controls'];let j=index">
                                    <mat-chip-option *ngIf="checkedFilter?.get('optionChecked')?.value" removable
                                        (removed)="clearFilter(i,j)">
                                        {{checkedFilter?.get('optionName')?.value}}
                                        <mat-icon matChipRemove
                                            [attr.data-control]="i+' '+checkedFilter?.get('optionName')?.value+' '+j">cancel
                                        </mat-icon>
                                    </mat-chip-option>
                                </ng-container>
                            </ng-container>
                            <mat-chip-option *ngIf="filterFormGroup?.get('filterFormArray')['controls'][i]?.get('type')?.value === 'date-picker' && 
                    filterFormGroup?.get('filterFormArray')['controls'][i]?.get('appliedFilterCount')?.value > 0 "
                                removable (removed)="clearFilter(i)">
                                {{filterFormGroup?.get('filterFormArray')['controls'][i]?.get('filterName')?.value}} :
                                {{filterFormGroup?.get('filterFormArray')['controls'][i]?.get('date')?.value?.begin |
                                date:
                                'dd/MM/yyyy'}} -
                                {{filterFormGroup?.get('filterFormArray')['controls'][i]?.get('date')?.value?.end |
                                date:
                                'dd/MM/yyyy'}}
                                <mat-icon matChipRemove attr.data-control="{{filterFormGroup?.get('filterFormArray')['controls'][i]?.get('date')?.value?.begin | date:
                        'dd/MM/yyyy'}} -
                        {{filterFormGroup?.get('filterFormArray')['controls'][i]?.get('date')?.value?.end | date:
                        'dd/MM/yyyy'}}">cancel</mat-icon>
                            </mat-chip-option>
                        </ng-container>
                    </mat-chip-listbox>
                </div>
                <br>
                <mat-divider fxHide.lt-sm></mat-divider>
            </ng-container>
            <!-- Product Filter options -->
            <div *ngIf="showFilterOption" formArrayName="filterFormArray">
                <div *ngFor="let filter of filterFormGroup?.get('filterFormArray')['controls'];let id=index"
                    [formGroupName]="id" [attr.data-control]="'filter-panel '+id">
                    <div *ngIf="filter?.value?.isVisible" style="padding: 10px 0px 10px 10px">
                        <div fxLayout="row" class="product-list-fiter" fxLayoutAlign="space-between center">
                            <span class="filter-text bold-class">{{filter?.value?.filterName|uppercase}}</span>
                            <div *ngIf=" filter?.value?.appliedFilterCount" style="font-size: 14px"
                                class="left-content">
                                {{filter?.value?.appliedFilterCount}} applied
                            </div>
                        </div>
                        <ng-container *ngIf="filter?.value?.type==='check-box' && filter?.value?.isVisible">
                            <div fxLayout="column"
                                *ngFor="let filterOption of filterFormGroup?.get('filterFormArray')['controls'][id]?.get('options')['controls'];let optionId=index"
                                formArrayName="options">
                                <ng-container [formGroupName]="optionId">
                                    <mat-checkbox class="filter-option" (change)="filterChange(id,$event)"
                                        formControlName="optionChecked"
                                        [attr.data-control]="filter?.value?.filterName+' '+filterOption?.value?.optionName">
                                        <div fxLayout="row">
                                            <div *ngIf="filter?.value?.showColors">
                                                <div [ngStyle]="{'background-color': filterOption?.value?.color}"
                                                    class="display-color"></div>
                                            </div>
                                            <div class="filter-option-text" style="word-break: break-word;">
                                                {{filterOption?.value?.optionName}}</div>
                                        </div>
                                    </mat-checkbox>
                                </ng-container>
                            </div>
                        </ng-container>
                        <mat-radio-group fxLayout="column" fxLayoutGap="5px"
                            *ngIf="filter?.value?.type==='radio-option' && filter?.value?.isVisible"
                            (change)="filterChange(id,$event)" formArrayName="options" formControlName="appliedFilters">
                            <mat-radio-button [formGroupName]="optionId"
                                *ngFor="let filterOption of filterFormGroup?.get('filterFormArray')['controls'][id]?.get('options')['controls'];let optionId=index"
                                class="filter-option" [value]="filterOption?.value"
                                [checked]="filterOption?.value?.optionChecked"
                                [attr.data-control]="filter?.value?.filterName+' '+filterOption?.value?.optionName">
                                <div class="filter-option-text">{{filterOption?.value?.optionName}}</div>
                            </mat-radio-button>
                        </mat-radio-group>
                        <ng-container *ngIf="filter?.value?.type==='date-picker' && filter?.value?.isVisible">
                            <form [formGroup]="filter.get('date')">
                                <mat-form-field appearance="fill">
                                    <mat-label>Enter a date range</mat-label>
                                    <mat-date-range-input [rangePicker]="picker">
                                        <input matStartDate placeholder="Start date" formControlName="begin"
                                            [attr.data-control]="filter?.value?.filterName+' date picker begin'">
                                        <input matEndDate placeholder="End date" formControlName="end"
                                            [attr.data-control]="filter?.value?.filterName+' date picker end'"
                                            (dateChange)="filterChange(id,$event)">
                                    </mat-date-range-input>
                                    <mat-datepicker-toggle
                                        [attr.data-control]="filter?.value?.filterName+' date picker toggle'" matSuffix
                                        [for]="picker"></mat-datepicker-toggle>
                                    <mat-date-range-picker #picker></mat-date-range-picker>
                                </mat-form-field>
                            </form>
                        </ng-container>
                    </div>
                </div>
            </div>

        </div>
    </div>
</ng-template>
<ng-template #filterTemplateTwo>
    <div [style]="{'--background-color':filterTemplateBackgroundColor ? filterTemplateBackgroundColor : '#000000','--text-color':filterTemplateTextColor ? filterTemplateTextColor : '#ffffff'} "
        class="templateTwo">
        <div fxLayout="row" class="filter-heading" fxLayoutGap="3px" fxLayoutAlign="center center">
            <div class="title-icon" fxLayoutAlign="center center">
                <span class="material-symbols-outlined">
                    tune
                </span>
            </div>
            <div>
                Filter
            </div>
        </div>
        <div [formGroup]="filterFormGroup" [ngClass.gt-md]="{'filter-content filter-card':true}" class="sticky"
            ngStyle.lt-md="max-height:none;">
            <span fxHide.md fxHide.gt-md class="material-symbols-outlined close-icon"
                (click)="closeTemplateSheetMenu()">
                close
            </span>
            <!-- Product sorting -->
            <ng-container *ngIf="isSort">
                <mat-expansion-panel [expanded]="isSortOpen" data-control="sort-panel" class="expansion-panel"
                    hideToggle (click)="isSortOpenClicked()">
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            <span class="filter-text">SORT BY </span>
                        </mat-panel-title>
                        <mat-icon class="expand-icon">
                            {{isSortOpen ?'expand_more':'expand_less'}}
                        </mat-icon>
                    </mat-expansion-panel-header>
                    <mat-panel-description>
                        <mat-radio-group fxLayout="column" [formControl]="sortOption" (change)="sortChange()"
                            class="radio-container">
                            <ng-container *ngFor="let sort of sortArray">
                                <mat-radio-button *ngIf="sort?.isVisible" [value]="sort" class="sort-radio"
                                    [attr.data-control]="sort?.display">
                                    <div>{{sort?.display}}</div>
                                </mat-radio-button>
                            </ng-container>
                        </mat-radio-group>
                    </mat-panel-description>
                </mat-expansion-panel>
                <hr class="endLine">
            </ng-container>
            <!-- Applied Filters -->
            <ng-container *ngIf="showFilter">
                <div class="applied-filter-container">
                    <div class="applied-filters">
                        <p class="bold-class">
                            {{commonProductDetails?.filtersApplied|uppercase}}</p>
                        <span (click)="clearFilter()" data-control="clear-filter">
                            <p class="bold-class left-content ">
                                {{commonProductDetails?.clearFilter|uppercase}}</p>
                        </span>
                    </div>
                    <br>
                    <mat-chip-listbox #chipList aria-label="Filters applied">
                        <ng-container
                            *ngFor="let filter of filterFormGroup?.get('filterFormArray')['controls'];let i=index">
                            <!-- Price Siler filter applied  -->
                            <ng-container
                                *ngIf="filter.get('type').value=='slider'&&(!(sliderMinValue==sliderStartValue&& sliderMaxValue==sliderEndValue))">
                                <mat-chip-option removable (removed)="clearFilter(i)">
                                    {{sliderMinValue | currency:
                                    websiteSettings?.currency?.currencySymbol}}-{{sliderMaxValue | currency:
                                    websiteSettings?.currency?.currencySymbol}}
                                    <mat-icon matChipRemove
                                        [attr.data-control]="'{{sliderMinValue | currency: websiteSettings?.currency?.currencySymbol}}-{{sliderMaxValue | currency: websiteSettings?.currency?.currencySymbol}}'">cancel 
                                    </mat-icon>
                                </mat-chip-option>
                            </ng-container>
                            <!-- Price Siler filter applied  -->
                            <ng-container *ngIf="!(filter.get('type').value=='slider')">
                                <ng-container
                                    *ngFor="let checkedFilter of filterFormGroup?.get('filterFormArray')['controls'][i]?.get('options')['controls'];let j=index">
                                    <mat-chip-option *ngIf="checkedFilter?.get('optionChecked')?.value" removable
                                        (removed)="clearFilter(i,j)">
                                        {{checkedFilter?.get('optionName')?.value}}
                                        <mat-icon matChipRemove
                                            [attr.data-control]="i+' '+checkedFilter?.get('optionName')?.value+' '+j">cancel
                                        </mat-icon>
                                    </mat-chip-option>
                                </ng-container>
                            </ng-container>
                            <mat-chip-option *ngIf="filterFormGroup?.get('filterFormArray')['controls'][i]?.get('type')?.value === 'date-picker' && 
                    filterFormGroup?.get('filterFormArray')['controls'][i]?.get('appliedFilterCount')?.value > 0 "
                                removable (removed)="clearFilter(i)">
                                {{filterFormGroup?.get('filterFormArray')['controls'][i]?.get('filterName')?.value}} :
                                {{filterFormGroup?.get('filterFormArray')['controls'][i]?.get('date')?.value?.begin |
                                date:
                                'dd/MM/yyyy'}} -
                                {{filterFormGroup?.get('filterFormArray')['controls'][i]?.get('date')?.value?.end |
                                date:
                                'dd/MM/yyyy'}}
                                <mat-icon matChipRemove attr.data-control="{{filterFormGroup?.get('filterFormArray')['controls'][i]?.get('date')?.value?.begin | date:
                        'dd/MM/yyyy'}} -
                        {{filterFormGroup?.get('filterFormArray')['controls'][i]?.get('date')?.value?.end | date:
                        'dd/MM/yyyy'}}">cancel</mat-icon>
                            </mat-chip-option>
                        </ng-container>
                    </mat-chip-listbox>
                </div>
                <br>
                <mat-divider fxHide.lt-sm></mat-divider>
            </ng-container>
            <!-- Product Filter options -->
            <div *ngIf="showFilterOption" formArrayName="filterFormArray">
                <div *ngFor="let filter of filterFormGroup?.get('filterFormArray')['controls'];let id=index"
                    [formGroupName]="id" [attr.data-control]="'filter-panel '+id">
                    <mat-expansion-panel expanded="{{filterExapansionPanel&&filterExapansionPanel[id] ? true:false}}"
                        hideToggle (click)="clicking(id)" *ngIf="filter?.value?.isVisible" class="expansion-panel">
                        <mat-expansion-panel-header class="product-list-fiter">
                            <div fxLayout="row" fxLayoutAlign="space-between center" style="width: 100%;">
                                <div class="filter-text bold-class">{{filter?.value?.filterName|uppercase}}</div>
                                <div *ngIf="filter?.value?.appliedFilterCount" class="applied-text">
                                    {{filter?.value?.appliedFilterCount}} applied
                                </div>
                                <mat-icon class="expand-icon">
                                    {{filterExapansionPanel&&filterExapansionPanel[id]?'expand_more':'expand_less'}}
                                </mat-icon>
                            </div>
                        </mat-expansion-panel-header>
                        <mat-panel-description (click)="$event.stopPropagation()" fxLayout="column"
                            fxLayoutAlign="center start">
                            <ng-container *ngIf="filter?.value?.type==='check-box' && filter?.value?.isVisible">
                                <div fxLayout="column"
                                    *ngFor="let filterOption of filterFormGroup?.get('filterFormArray')['controls'][id]?.get('options')['controls'];let optionId=index"
                                    formArrayName="options">
                                    <ng-container [formGroupName]="optionId">
                                        <mat-checkbox class="filter-option" (change)="filterChange(id,$event)"
                                            formControlName="optionChecked"
                                            [attr.data-control]="filter?.value?.filterName+' '+filterOption?.value?.optionName">
                                            <div fxLayout="row">
                                                <div *ngIf="filter?.value?.showColors">
                                                    <div [ngStyle]="{'background-color': filterOption?.value?.color}"
                                                        class="display-color"></div>
                                                </div>
                                                <div class="filter-option-text" style="word-break: break-word;">
                                                    {{filterOption?.value?.optionName}}</div>
                                            </div>
                                        </mat-checkbox>
                                    </ng-container>
                                </div>
                            </ng-container>
                            <mat-radio-group fxLayout="column" fxLayoutGap="5px"
                                *ngIf="filter?.value?.type==='radio-option' && filter?.value?.isVisible"
                                (change)="filterChange(id,$event)" formArrayName="options"
                                formControlName="appliedFilters">
                                <mat-radio-button [formGroupName]="optionId"
                                    *ngFor="let filterOption of filterFormGroup?.get('filterFormArray')['controls'][id]?.get('options')['controls'];let optionId=index"
                                    class="filter-option" [value]="filterOption?.value"
                                    [checked]="filterOption?.value?.optionChecked"
                                    [attr.data-control]="filter?.value?.filterName+' '+filterOption?.value?.optionName">
                                    <div class="filter-option-text">{{filterOption?.value?.optionName}}</div>
                                </mat-radio-button>
                            </mat-radio-group>
                            <ng-container *ngIf="filter?.value?.type==='date-picker' && filter?.value?.isVisible">
                                <form [formGroup]="filter.get('date')">
                                    <mat-form-field appearance="fill">
                                        <mat-label>Enter a date range</mat-label>
                                        <mat-date-range-input [rangePicker]="picker">
                                            <input matStartDate placeholder="Start date" formControlName="begin"
                                                [attr.data-control]="filter?.value?.filterName+' date picker begin'">
                                            <input matEndDate placeholder="End date" formControlName="end"
                                                [attr.data-control]="filter?.value?.filterName+' date picker end'"
                                                (dateChange)="filterChange(id,$event)">
                                        </mat-date-range-input>
                                        <mat-datepicker-toggle
                                            [attr.data-control]="filter?.value?.filterName+' date picker toggle'"
                                            matSuffix [for]="picker"></mat-datepicker-toggle>
                                        <mat-date-range-picker #picker></mat-date-range-picker>
                                    </mat-form-field>
                                </form>
                            </ng-container>
                            <ng-container *ngIf="filter?.value?.type==='slider' && filter?.value?.isVisible">
                                <div class="pf-checkbox" style="width:100%" formArrayName="options"
                                    *ngFor="let filterOption of filterFormGroup?.get('filterFormArray')['controls'][id]?.get('options')['controls'];let optionId=index">
                                    <mat-slider [min]="sliderStartValue" [max]="sliderEndValue"
                                        [step]="sliderStepsCount" (change)="sliderFilterChange(id,$event)"
                                        fxLayoutAlign="center center">
                                        <input [value]="sliderMinValue" matSliderStartThumb (input)="minValue($event)">
                                        <input [value]="sliderMaxValue" matSliderEndThumb (input)="maxValue($event)">
                                    </mat-slider>
                                    <div fxLayout="row" fxLayoutAlign="space-between center">
                                        <div class="pf-MinMaxvalue">
                                            {{sliderMinValue | currency: websiteSettings?.currency?.currencySymbol}}
                                        </div>
                                        <div style="color:var(--text-color)">
                                            to
                                        </div>
                                        <div class="pf-MinMaxvalue">
                                            {{sliderMaxValue | currency: websiteSettings?.currency?.currencySymbol}}
                                        </div>
                                    </div>
                                </div>
                            </ng-container>
                        </mat-panel-description>
                    </mat-expansion-panel>
                    <hr *ngIf="filter?.value?.isVisible" class="endLine">
                </div>
            </div>
        </div>
    </div>
</ng-template>
<ng-template #filterTemplateThree>
    <div class="Pf-scroll"
        [style]="{'--background-color':filterTemplateBackgroundColor ? filterTemplateBackgroundColor : '#000000','--text-color':filterTemplateTextColor ? filterTemplateTextColor : '#ffffff'} ">
        <div [formGroup]="filterFormGroup" ngClass.gt-md="filter-content filter-card" class="sticky"
            ngStyle.lt-md="max-height:none;">
            <span fxHide fxHide.gt-md class="material-symbols-outlined close-icon" (click)="closeTemplateSheetMenu()">
                close
            </span>
            <!-- Product sorting -->
            <ng-container *ngIf="isSort">
                <div data-control="sort-panel" class="filter-header bold-class">
                    <span class="filter-text">SORT BY </span>
                    <mat-radio-group fxLayout="column" [formControl]="sortOption" (change)="sortChange()"
                        class="radio-container">
                        <ng-container *ngFor="let sort of sortArray">
                            <mat-radio-button *ngIf="sort?.isVisible" [value]="sort" class="sort-radio"
                                [attr.data-control]="sort?.display">
                                <div>{{sort?.display}}</div>
                            </mat-radio-button>
                        </ng-container>
                    </mat-radio-group>
                </div>
                <mat-divider fxHide.lt-sm></mat-divider>
            </ng-container>
            <!-- Applied Filters -->
            <ng-container *ngIf="showFilter">
                <div class="applied-filter-container">
                    <div class="applied-filters">
                        <p class="bold-class pf-filter-applied ">
                            {{commonProductDetails?.filtersApplied|uppercase}}</p>
                        <span (click)="clearFilter()" data-control="clear-filter">
                            <p class="bold-class left-content pf-bold-class">
                                {{commonProductDetails?.clearFilter|uppercase}}</p>
                        </span>
                    </div>
                    <br>
                    <mat-chip-listbox #chipList aria-label="Filters applied" class="pf-chipbox">
                        <ng-container
                            *ngFor="let filter of filterFormGroup?.get('filterFormArray')['controls'];let i=index">
                            <!-- Price Siler filter applied  -->
                            <ng-container
                                *ngIf="filter.get('type').value=='slider'&&(sliderMinValue>0 || sliderMaxValue<10000)">
                                <mat-chip-option removable (removed)="clearFilter(i)">
                                    {{sliderMinValue | currency:
                                    websiteSettings?.currency?.currencySymbol}}-{{sliderMaxValue | currency:
                                    websiteSettings?.currency?.currencySymbol}}
                                    <mat-icon matChipRemove
                                        [attr.data-control]="'{{sliderMinValue | currency: websiteSettings?.currency?.currencySymbol}}-{{sliderMaxValue | currency: websiteSettings?.currency?.currencySymbol}}'">cancel
                                    </mat-icon>
                                </mat-chip-option>
                            </ng-container>
                            <!-- Price Siler filter applied  -->
                            <ng-container *ngIf="!(filter.get('type').value=='slider') ">
                                <ng-container
                                    *ngFor="let checkedFilter of filterFormGroup?.get('filterFormArray')['controls'][i]?.get('options')['controls'];let j=index">
                                    <mat-chip-option *ngIf="checkedFilter?.get('optionChecked')?.value" removable
                                        (removed)="clearFilter(i,j)">
                                        {{checkedFilter?.get('optionName')?.value}}
                                        <mat-icon matChipRemove
                                            [attr.data-control]="i+' '+checkedFilter?.get('optionName')?.value+' '+j">cancel
                                        </mat-icon>
                                    </mat-chip-option>
                                </ng-container>
                            </ng-container>
                            <mat-chip-option *ngIf="filterFormGroup?.get('filterFormArray')['controls'][i]?.get('type')?.value === 'date-picker' && 
                    filterFormGroup?.get('filterFormArray')['controls'][i]?.get('appliedFilterCount')?.value > 0 "
                                removable (removed)="clearFilter(i)">
                                {{filterFormGroup?.get('filterFormArray')['controls'][i]?.get('filterName')?.value}} :
                                {{filterFormGroup?.get('filterFormArray')['controls'][i]?.get('date')?.value?.begin |
                                date:
                                'dd/MM/yyyy'}} -
                                {{filterFormGroup?.get('filterFormArray')['controls'][i]?.get('date')?.value?.end |
                                date:
                                'dd/MM/yyyy'}}
                                <mat-icon matChipRemove attr.data-control="{{filterFormGroup?.get('filterFormArray')['controls'][i]?.get('date')?.value?.begin | date:
                        'dd/MM/yyyy'}} -
                        {{filterFormGroup?.get('filterFormArray')['controls'][i]?.get('date')?.value?.end | date:
                        'dd/MM/yyyy'}}">cancel</mat-icon>
                            </mat-chip-option>
                        </ng-container>
                    </mat-chip-listbox>
                </div>
                <br>
                <mat-divider fxHide.lt-sm></mat-divider>
            </ng-container>
            <!-- Product Filter options -->
            <div *ngIf="showFilterOption" formArrayName="filterFormArray" style="width:80%">
                <div *ngFor="let filter of filterFormGroup?.get('filterFormArray')['controls'];let id=index"
                    [formGroupName]="id" [attr.data-control]="'filter-panel '+id">
                    <div *ngIf="filter?.value?.isVisible " style="padding: 10px 0px 10px 10px">
                        <div fxLayout="row" class="pf-product-list-fiter" fxLayoutAlign="space-between center">
                            <span class="pf-filter-text">{{filter?.value?.filterName|uppercase}}</span>
                            <div *ngIf=" filter?.value?.appliedFilterCount" style="font-size: 14px"
                                class="left-content">
                                {{filter?.value?.appliedFilterCount}} applied
                            </div>
                        </div>
                        <ng-container *ngIf="filter?.value?.type==='check-box' && filter?.value?.isVisible">
                            <div class="pf-checkbox"
                                *ngFor="let filterOption of filterFormGroup?.get('filterFormArray')['controls'][id]?.get('options')['controls'];let optionId=index"
                                formArrayName="options">
                                <ng-container [formGroupName]="optionId">
                                    <mat-checkbox class="pf-filter-option" (change)="filterChange(id,$event)"
                                        formControlName="optionChecked"
                                        [attr.data-control]="filter?.value?.filterName+' '+filterOption?.value?.optionName">
                                        <div fxLayout="row" class="find">
                                            <div *ngIf="filter?.value?.showColors">
                                                <div [ngStyle]="{'background-color': filterOption?.value?.color}"
                                                    class="display-color"></div>
                                            </div>
                                            <div class="pf-optionname" style="word-break: break-word;">
                                                {{filterOption?.value?.optionName}}</div>
                                        </div>
                                    </mat-checkbox>
                                </ng-container>
                            </div>
                        </ng-container>
                        <ng-container *ngIf="filter?.value?.type==='slider' && filter?.value?.isVisible">
                            <div class="pf-checkbox"
                                *ngFor="let filterOption of filterFormGroup?.get('filterFormArray')['controls'][id]?.get('options')['controls'];let optionId=index"
                                formArrayName="options">
                                <mat-slider [min]="sliderStartValue" [max]="sliderEndValue"
                                    (change)="sliderFilterChange(id,$event)">
                                    <input [value]="sliderMinValue" matSliderStartThumb (input)="minValue($event)">
                                    <input [value]="sliderMaxValue" matSliderEndThumb (input)="maxValue($event)">
                                </mat-slider>
                                <div fxLayout="row" fxLayoutAlign="space-between center">
                                    <div>
                                        <span class="pf-slider-MinMax">MIN:</span><span
                                            class="pf-MinMaxvalue">{{sliderMinValue | currency:
                                            websiteSettings?.currency?.currencySymbol}}</span>
                                    </div>
                                    <div>
                                        <span class="pf-slider-MinMax">MAX:</span><span
                                            class="pf-MinMaxvalue">{{sliderMaxValue | currency:
                                            websiteSettings?.currency?.currencySymbol}}</span>
                                    </div>
                                </div>
                            </div>
                        </ng-container>
                        <mat-radio-group fxLayout="column" fxLayoutGap="5px" class="pf-radio-button"
                            *ngIf="filter?.value?.type==='radio-option' && filter?.value?.isVisible"
                            (change)="filterChange(id,$event)" formArrayName="options" formControlName="appliedFilters">
                            <mat-radio-button [formGroupName]="optionId"
                                *ngFor="let filterOption of filterFormGroup?.get('filterFormArray')['controls'][id]?.get('options')['controls'];let optionId=index"
                                class="pf-filter-option" [value]="filterOption?.value"
                                [checked]="filterOption?.value?.optionChecked"
                                [attr.data-control]="filter?.value?.filterName+' '+filterOption?.value?.optionName">
                                <div class="pf-optionname">{{filterOption?.value?.optionName}}</div>
                            </mat-radio-button>
                        </mat-radio-group>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>