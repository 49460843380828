import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { Component, OnInit } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { AffiliateAuthService } from '../../../auth/services/auth.service';
import { DialogService } from '@phase-ii/common';
import { SharedConstant } from '../../constants/shared-constant';
@Component({
  selector: 'phase-ii-affiliate-marketing-home',
  templateUrl: './affiliate-marketing-home.component.html',
  styleUrls: ['./affiliate-marketing-home.component.scss'],
})
export class AffiliateMarketingHomeComponent implements OnInit {

  /**referralCodeConstant used to acccess the referral code constant values. */

  sharedConstant = new SharedConstant();
  /**
   * Variable which is used to check whether it is mobile view or not.
   */
  isHandset: Observable<BreakpointState> = this.breakpointObserver.observe(['(max-width: 768px)']);
  /**
 * Variable which is used to store the current user details.
 */
  currentUser: any;

  /**
* subscriptionObject used to store the subscribed values.
* @type {Subscription}
*/
  subscriptionObject: Subscription = new Subscription();


  /**
   * Array Variable which is used to store tool icon details.
   */
  menu_tool = [
    {
      "isDefault": true,
      "pageCustomIcon": "account_circle",
      "pageCustomLink": null,
      "pageCustomTitle": null,
      "subMenu": [
        {
          "pageCustomTitle": "Logout",
          "pageCustomLink": "/signin",
          "isReadOnly": false
        },
      ]
    },
  ];
  /**
   * Variable which is used to check whether it is superadmin or not.
   */
  isSuperAdmin: boolean;
  /**
   * Array Variable which is used to store menu details.
   */
  menus = [];
  /**
 * Variable which is used to store the user name.
 */
  userName: any;
  /**
   * Variable which is used to check whether it is affiliate user or not.
   */
  isAffiliateUser = true;
  /**
   * Variable to display or hide the loader
   * @type {boolean}
   */
  isLoader: boolean;

  /**
   * Component constructor to inject required services.
   * @param breakpointObserver To check if it mobileview or webview. 
   * @param route To get current route.
   * @param authService To access methods from auth service
   * @param commonService which is used to get common service functionalities
   * @param sharedService which is used to get the shared service functionalities
   */

  constructor(
    private authServices: AffiliateAuthService,
    private breakpointObserver: BreakpointObserver,
    private dialogService: DialogService,
  ) {
  }

  ngOnInit() {
    this.isLoader = true;
    this.subscriptionObject.add(this.authServices.user.subscribe((res: any) => {
      if (res) {
        this.isLoader = false;
        this.currentUser = res;
        this.isSuperAdmin = this.currentUser.isSuperadmin;
        this.userName = this.currentUser.name;
        this.menus = this.isSuperAdmin ? [
          {
            isDefault: true,
            pageCustomIcon: "checkbook",
            pageCustomLink: "/app/payoutrequest",
            pageCustomTitle: "Payout Request List"
          },
          {
            isDefault: true,
            pageCustomIcon: "supervisor_account",
            pageCustomLink: "/app/userlist",
            pageCustomTitle: "Users"
          },
          {
            isDefault: true,
            pageCustomIcon: "settings",
            pageCustomLink: "/app/settings",
            pageCustomTitle: "Settings"
          },
          {
            isDefault: true,
            pageCustomIcon: "account_circle",
            pageCustomLink: "/app/profile",
            pageCustomTitle: "Profile"
          }
        ] : [
          {
            isDefault: true,
            pageCustomIcon: "dashboard",
            pageCustomLink: "/app/dashboard",
            pageCustomTitle: "Dashboard"
          },
          {
            isDefault: true,
            pageCustomIcon: "subtitles",
            pageCustomLink: "/app/referral",
            pageCustomTitle: "Referral Code"
          },
          {
            isDefault: true,
            pageCustomIcon: "badge",
            pageCustomLink: "/app/account-list",
            pageCustomTitle: "Account Details"
          },
          {
            isDefault: true,
            pageCustomIcon: "payment",
            pageCustomLink: "/app/payoutlogs",
            pageCustomTitle: "Payout Logs"
          },
          {
            isDefault: true,
            pageCustomIcon: "account_circle",
            pageCustomLink: "/app/profile",
            pageCustomTitle: "Profile"
          }
        ];
      }
    }
    ));
  }
}
