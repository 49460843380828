import { BackendConfigService } from "@phase-ii/common";

/**
 * initializeAppFactory function used to get backend server url when initialize the app
 * @param backendConfigService 
 * @returns 
 */
export function initializeAppFactory(backendConfigService: BackendConfigService): () => Promise<boolean> {
  const region = localStorage.getItem('region');
  return () => backendConfigService.getBackendUrl(region ? { info: region } : null);
}