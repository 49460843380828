export interface ExportObject {
  /**
   * Export title to display for that Dailoge.
   */
  title: string;
  /**
   * exportApiUrl to make a apicall for export data.
   */
  exportApiUrl: string;
   /**
   * ThirdPartyValue to make a apicall for export data. 
   */
   ThirdPartyApiPrefix?:string;
  // expansion to decide whether it has pagination or not
  expansion?: boolean;
  /**
   * Limit to set the limit from backend.
   */
  limit: number;
  /**
   * offset to set the limit from backend.
   */
  offset: number;
  /**
   * type to get data from different field
   */
  type: boolean;
  /**
    * selectedData to get only select data for export
    */
  selectedData: any;
  /**
  * filterData to get only filtered data for export
  */
  filterData?: any;
  /**
  * filterData to get only filtered data for export
  */
  searchData?: any;
  /**
 * categoryId to get only filtered category data for export
 */
  categoryId?: any;
  /**
  * fileName to get the filename for export
  */
  fileName?: any;
  /**
   * sort order of data
   */
  sortOrder?: any;
  /**
     * object for additinal information
     */
  exportData?: object;
  /**
   * object for additinal information
   */
  locationId?: any;
  /**
    * object for product availability
    */
  productAvailability?: any;
  /**
   * object for stock restriction
   */
  stock?: any
/**
   * boolean for email marketing
   */
  isEmailMarketing?:boolean
}