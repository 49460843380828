/**
 * Service which is used to get the backend server url when initialize the app
 */
import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { of } from 'rxjs';
import { LoadTranslationService } from './load-translation.service';

@Injectable({
    providedIn: 'root',
})
export class BackendConfigService {
    /**
  * Variable used to store environment details
  */
    environment: any;
    /**
     * Constructor which is used to inject the required services
     * @param http 
     * @param environment 
     */
    constructor(private http: HttpClient, @Inject('environment') environment, private loadTranslate: LoadTranslationService) {
        this.environment = environment;
    }
    /**
      * Method used to get the backend server url
      */
    getBackendUrl(infoValue) {
        if (this.environment.app !== "local" && this.environment.app !== "test") {
            return this.http
                .get(this.environment.backendServerConfigUrl, {
                    params: infoValue
                })
                .toPromise()
                .then((response: any) => {
                    if (response) {
                        this.environment = Object.assign(this.environment, response.result);
                        // console.log(this.environment);
                        localStorage.setItem("region", this.environment.countryCode);
                        this.loadTranslate.multiLoadTranslation(['common']);
                    }
                    return true;
                });
        } else {
            return new Promise<boolean>((resolve, reject) => {
                const data = {
                    "apiUrlDb": "http://localhost:5000",
                    "AWSImageUrl": "https://zenbasket-dev.s3.us-west-2.amazonaws.com/",
                    "assetUrl": "https://dev.components.getzenbasket.com/",
                    "storefrontUrl": "http://localhost:4400/in/",
                    "countryCode": "IN",
                    "RegionUrl": "in",
                    "AWSregion": "us-west-2",
                    "bucketName": "zenbasket-dev",
                    "rollbarKey": "4ba2acb16ea049b0b546b74534f44bea",
                    "hostUrl": "http://localhost:4200/",
                    "exportOrderUrl": "https://api.centizenapps.com/orderexport-local",
                    "ZenbasketAWSImageUrl": "https://zenbasket-dev.s3.us-west-2.amazonaws.com/",
                    "AccessKey": "ZEN_ELNOVC#IRN"
                };
                this.environment = Object.assign(this.environment, data);
                localStorage.setItem("region", this.environment.countryCode);
                this.loadTranslate.multiLoadTranslation(['common']);
                resolve(true); // Resolving with a boolean value
            });
        }
    }
    /**
    ** Method used to check the domain url
    */
    domainChecking() {
        const hostUrl = window.location.origin.includes('www.') ? window.location.origin.replace('www.', '') : window.location.origin;
        let infoValue = (this.environment.storefrontUrl !== (hostUrl + '/')) ? hostUrl
            : (window.location.pathname && window.location.pathname.split('/')[1] && window.location.pathname.split('/')[1].toUpperCase());
        if (infoValue === 'SHOPAPP') {
            infoValue = localStorage.getItem('region');
        }
        return this.getBackendUrl(infoValue ? { info: infoValue } : null);

    }
}
