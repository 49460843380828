

export class ElementsContant {
  serviceVariables = {
    sharedService: [
      'storeId',
      'storeDisplayName',
      'commonWebsiteDetails',
      'locationDetails',
      'categoryList',
      'changedNewOrders',
      'commonLoader',
      'updateCartDetails',
      'storeContactDetails',
      'cartDetails',
      'subscriptionCartCountValue',
      'storePolicies',
      'filterSearchDetails',
      'storeName',
      'imageData',
      'cartCountValue'
    ],
    authService: [
      'user',
    ],
    commonService: [
      'environment',
      'topNavbarDetails'
    ],
    component: [
      'route',
      'router',
      'selectedLanguage'
    ],
    headerService: [
      'headers'
    ]
  };
}
