import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AffiliateSettingsComponent } from './components/affiliate-settings/affiliate-settings.component';
import { SharedModule } from '@phase-ii/shared';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CommonAdminModuleModule } from '@phase-ii/common-admin-module';

@NgModule({
  declarations: [AffiliateSettingsComponent],
  imports: [CommonModule,
    SharedModule,
    CommonAdminModuleModule,
    BrowserAnimationsModule],
})
export class SettingsModule {}
