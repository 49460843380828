import { DatePipe } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import * as  _ from 'lodash';
// import { AuthService } from '../../../../../auth/src/lib/services/auth.service';
import { of, Subscription } from 'rxjs';
import { filter, mergeMap } from 'rxjs/operators';
import { ReportsService } from '../../services/reports.service';
import { CommonService } from '../../../../../shared/src/lib/services/common.service';
import { ColumnsRef, CommonAdminShardConstants, ListProperties } from '../../constants/shared-constant';
import { ButtonInfo, Heading } from '../../../../../shared/src/lib/models/header-page';
import { AuthService } from '@phase-ii/auth';
import { PaginationService } from '../../../../../shared/src/lib/services/pagination.service';
import { DialogService } from '@phase-ii/common';
import { ExportComponent } from '../export/export.component';
import { ExportObject } from '../../models/export-details';
import * as moment from 'moment';

/**
 * Component which is used to view/export offer report.
 */
@Component({
  selector: 'phase-ii-offer-report',
  templateUrl: './offer-report.component.html',
  styleUrls: ['./offer-report.component.scss']
})
export class OfferReportComponent implements OnInit, OnDestroy {
  /**
   * Variable which is used to subscribe and unsubscribe.
   * @type {Subscritpion}
   */
  subscriptionObj: Subscription = new Subscription();
  /**
   * Variable which is used to store the to date of the report.
   * @type {Date}
   */
  toDate!: Date;
  /**
   * Variable which is used to store the from date of the report.
   * @type {Date}
   */
  fromDate!: Date;
  /**
   * Variable which is used to define the pagesize
   * @type {number}
   */
  pageSize: number = new CommonAdminShardConstants().paginator.defaultPageSize;
  /**
   * Variable which is used to store the text to be searched.
   * @type {String}
   */
  searchText!: String;
  /**
   * Variable which is used to store the selected location.
   * @type {number}
   */
  location_id: any;
  /**
   * Variable which is used to store the sort object, which has the field and direction to be sorted.
   * @type {Object}
   */
  sort: Object;
  /**
   * Variable which is used to store the discount response.
   * @type {Object}
   */
  data!: Object;
  /**
* paginator data for export
*/
  paginatorValue = { limit: null, offset: null };
  /**
* object of datas to be displayed
*/
  pageDetails: {
    heading: any,
    buttonInfo: ButtonInfo[],
    listProperty: ListProperties,
    columnRef: ColumnsRef[],
    isLoader: boolean,
    listDataSource: any,
    showFilterAndSort: boolean,
    actions: any,
    filterConstrain: any,
    checker: any,
    errorHandler: boolean,
    currencySymbol: any
    paginator: any,
    storeList: any,
    storeId: number,
    roleId: number,
    offer: boolean
  } = {
      heading: null,
      buttonInfo: null,
      listProperty: null,
      columnRef: null,
      isLoader: null,
      listDataSource: null,
      showFilterAndSort: false,
      actions: [],
      filterConstrain: null,
      checker: null,
      errorHandler: false,
      currencySymbol: null,
      paginator: null,
      storeList: null,
      storeId: null,
      roleId: null,
      offer: true
    };
  /**
  * Variable which is used to define the sales data for discount.
  */
  salesData: any;
  /**
 * Variable which is used to store the id of store.
 */
  storeId: any;
  /**
 * Variable which is used to store total offer amount.
 */
  totalAmount: number = 0;
  /**
 * Variable which is used to store offer response.
 */
  offerDetails: any;
  /**
 * Variable which is used to define whether the data is exported.
 */
  isExport: boolean = false;
  /**
* Variable which is used to store current user details.
*/
  currentUser: any;
  /**
* Variable which is used to store available store list.
*/
  storeList: any[] = [];
  /**
* Variable which is used to define whether the data is retrieved or not.
*/
  isRetrieve: boolean = false;
  /**
* Variable which is used to store limit value of paginator for export.
*/
  paginatorLimit!: number;
  /**
* Variable which is used to store offset value of paginator for export.
*/
  paginatorOffset: number;
  /**
* Variable which is used to store current role details.
*/
  currentRole!: string;
  /**commonConstant used to acccess the common constant values. */
  commonConstant = new CommonAdminShardConstants();
  /**
* Variable which is used to store current offset value.
*/
  offset: number;
  /**
* Variable which is used to store current limit value.
*/
  limit: number;

  /**
   * Variable which is used to store the created date of the store.
   * @type {Date}
   */
  createdDate!: Date;

  /**
* component constructor which is used to inject the required services.
* @param reportService  To access methods inside ReportService.
* @param authService  To access methods inside AuthService.
* @param displayDialog  To access methods inside MatDialog.
* @param paginatorService  To access methods inside PaginationService.
* @param commonService  To access methods inside CommonService.
* @param datePipe  To access methods inside DatePipe.
* @param router  To access methods inside Router.
*/
  constructor(private reportService: ReportsService,
    private authService: AuthService,
    private paginatorService: PaginationService,
    private displayDialog: MatDialog,
    private commonService: CommonService,
    private dialogService: DialogService,
    private datePipe: DatePipe) { }
  /**
   * Angular life cycle hook that initiates the component
   */
  ngOnInit(): void {
    // this.toDate = new Date();
    // this.fromDate = new Date(Number(this.toDate));
    this.location_id = 'All';
    this.subscriptionObj.add(this.authService.user.pipe(filter((res: any) => {
      if (res) {
        return res;
      }
    }), mergeMap((user: any) => {
      if (user) {
        this.currentUser = user;
        this.currentRole = this.currentUser.roleCode ? this.currentUser.roleCode : this.currentUser.role.code;
        this.pageDetails.roleId = this.currentUser?.roleId;
      }
      if (this.currentRole && this.currentRole === 'SUPERADMIN') {
        return this.commonService.getAllStoresName();
      } else {
        this.pageDetails.storeId = this.currentUser?.storeId ? this.currentUser.storeId : null;
        return of({});
      }
    }), mergeMap((store: any) => {
      if (store && store['stores']) {
        this.storeList.push({ name: "All", id: "All" });
        store['stores'].forEach((element) => {
          this.storeList.push({
            id: element?.id,
            name: element?.name
          });
        });
        this.storeId = this.storeList[0]?.id;
        this.pageDetails.storeList = this.storeList;
      }
      return this.reportService.getStoreData(this.pageDetails.storeId);
    }), mergeMap((data: any) => {
      if (data) {
        this.createdDate = data.settings && data.settings.createdAt;
      }
      return this.authService.dashboardFilter;
    })).subscribe((value: any) => {
      if (value) {
        if (value.filterText == 'Lifetime') {
          this.fromDate = this.createdDate;
        }
        else {
          this.fromDate = value.fromDate ? value.fromDate : new Date();
          this.fromDate.setHours(0, 0, 0, 0);
        }
      }
      else {
        this.fromDate = new Date();
        this.fromDate.setHours(0, 0, 0, 0);
      }
      this.toDate = new Date();
      this.toDate.setHours(23, 59, 59, 999);
      this.storeId = this.currentUser?.storeId ? this.currentUser.storeId : null;
      this.getDiscountReport(0, 2 * this.pageSize, true);
    }, (err) => {
      this.pageDetails.isLoader = false;
    }));
    this.subscriptionObj.add(this.authService.user.pipe(filter((res: any) => {
      if (res && res.storeId) {
        this.storeId = res.storeId;
        this.pageDetails.currencySymbol = res.store && res.store.currencyData && res.store.currencyData.currencySymbol;
        return res;
      } else {
        return false;
      }
    })).subscribe((res: any) => {
      this.pageDetails.isLoader = false;
    }, (err) => {
      this.pageDetails.isLoader = false;
    }));
  }
  /**
   * Function which is used to get the report details for table.
   * @param offset To get the offset/starting value.
   * @param limit defines the limit/count of data to be get.
   */
  getDiscountReport(offset: number, limit: number, isNew: boolean): void {
    this.paginatorOffset = offset;
    this.paginatorLimit = this.pageSize;
    const start_date: Date = this.fromDate ? _.cloneDeep(this.fromDate) : null;
    const to_date: Date = this.toDate ? this.toDate : null;
    let data: any = {
      offset: offset ? offset : 0,
      limit: limit ? limit : this.pageSize,
      location: this.location_id ? this.location_id : null,
      store: this.storeId ? this.storeId : null,
      from_date: moment.utc(this.datePipe.transform(start_date, 'yyyy-MM-dd')).utcOffset('+0:00').format('YYYY-MM-DD 00:00:00.000 +00:00'),
      to_date: moment.utc(this.datePipe.transform(to_date, 'yyyy-MM-dd')).utcOffset('+0:00').format('YYYY-MM-DD 23:59:59.000 +00:00'),
      filterText: this.searchText ? this.searchText.trim() : '',
      sort: this.sort ? this.sort : null
    };
    if (this.storeId === 'All') {
      this.subscriptionObj.add(this.reportService.getAllOfferReport(data).subscribe((res) => {
        if (res && res['Discount']) {
          this.offerDetails = res['Discount'];
          this.offerDetails['manualDiscount']['totalOfferAmount'] = 0;
          // this.offerDetails['automaticDiscount']['totalOfferAmount'] = 0;
          this.offerDetails['manualDiscount'].forEach((element) => {
            this.offerDetails['manualDiscount']['totalOfferAmount'] += element?.totalAmount ? Number(element.totalAmount) : 0;
          });
          // this.offerDetails['automaticDiscount'].forEach((element) => {
          //   this.offerDetails['automaticDiscount']['totalOfferAmount'] += element?.totalAmount ? Number(element.totalAmount) : 0;
          // });
          this.tabDataRetrieve(isNew);
          this.pageDetails.heading = {
            title: this.commonConstant.offerReport.manual,
            description: this.commonConstant.offerReport.manualDescription,
            isHeaderIcon: true,
            iconName: 'link',
            iconToolTip: 'Help',
            iconLink: 'https://help.getzenbasket.com/offer_report.html'
          };
          this.pageDetails.buttonInfo =
            [
              {
                name: 'Back', method: 'back()', class: 'secondary-button'
              },
              {
                name: 'Export Report', class: 'primary-button', method: 'exportexcel()'
              }
            ];
          this.pageDetails.listProperty = {
            globalSearch: false,
            columns: 2,
            dataOnly: true,
            suffixText: true,
            checkBox: false,
            isImageDisplay: false
          };
          this.pageDetails.checker = false;
          this.pageDetails.isLoader = false;
        }
      }, (err) => {
        this.pageDetails.isLoader = false;
      }));
    } else if (this.storeId !== null) {
      this.subscriptionObj.add(this.reportService.getOfferReport(this.storeId, data).subscribe((res: any) => {
        if (res && res['Discount']) {
          this.offerDetails = res['Discount'];
          this.offerDetails['manualDiscount']['totalOfferAmount'] = 0;
          // this.offerDetails['automaticDiscount']['totalOfferAmount'] = 0;
          this.offerDetails['manualDiscount'].forEach((element) => {
            this.offerDetails['manualDiscount']['totalOfferAmount'] += element?.totalAmount ? Number(element.totalAmount) : 0;
          });
          // this.offerDetails['automaticDiscount'].forEach((element) => {
          //   this.offerDetails['automaticDiscount']['totalOfferAmount'] += element?.totalAmount ? Number(element.totalAmount) : 0;
          // });
          this.offset = offset;
          this.limit = limit;
          this.pageDetails.listDataSource = this.offerDetails && this.offerDetails['manualDiscount'] ? this.offerDetails['manualDiscount'] : [];
          this.tabDataRetrieve(isNew);
          this.pageDetails.heading = {
            title: this.commonConstant.offerReport.manual,
            description: this.commonConstant.offerReport.manualDescription,
            isHeaderIcon: true,
            iconName: 'link',
            iconToolTip: 'Help',
            iconLink: 'https://help.getzenbasket.com/offer_report.html'
          };
          this.pageDetails.buttonInfo =
            [
              {
                name: 'Back', method: 'back()', class: 'secondary-button'
              },
              {
                name: 'Export Report', class: 'primary-button', method: 'exportexcel()'
              }
            ];
          this.pageDetails.listProperty = {
            globalSearch: false,
            columns: 2,
            dataOnly: true,
            suffixText: true,
            checkBox: false,
            isImageDisplay: false
          };
          this.pageDetails.checker = false;
          this.pageDetails.isLoader = false;
        }
      }, (err) => {
        this.pageDetails.isLoader = false;
      }));
    } else {
      this.dialogDisplay(this.commonConstant &&
        this.commonConstant.errorMessage
        && this.commonConstant.errorMessage.errorMessage,
        this.commonConstant.dialogType.failure);
    }

  }

  /**
  * Function which is used to retrieve the data related to selected tab.
  * @param value To get the value for paginator.
  */
  tabDataRetrieve(value: boolean): void {
    if (!this.isExport) {
      this.pageDetails.listDataSource = this.offerDetails && this.offerDetails['manualDiscount'] ? this.offerDetails['manualDiscount'] : [];
      this.pageDetails.paginator = {
        new: value,
        rows: this.pageDetails.listDataSource,
        count: this.pageDetails && this.pageDetails.listDataSource && this.pageDetails.listDataSource.length,
      }
      this.pageDetails.listDataSource = this.offerDetails && this.offerDetails['manualDiscount'].slice(this.offset, this.offset + this.limit);
      this.pageDetails.paginator.rows = this.pageDetails.listDataSource;
      this.pageDetails.columnRef = [
        { column: 'promoCode', type: 'Text', suffixText: 'Promo Code' },
        { column: 'count', type: 'Number', suffixText: 'No. of orders' },
        { column: 'totalAmount', type: 'Amount', suffixText: 'Total Amount', currency: this.pageDetails.currencySymbol }
      ];
      this.data = {
        totalOfferAmount: this.offerDetails && this.offerDetails['manualDiscount'] && this.offerDetails['manualDiscount'].totalOfferAmount
      };
      this.isRetrieve = true;
      this.paginatorService.listPaginatorData.next(this.pageDetails.paginator);
    }
    else {
      this.pageDetails.paginator = {
        new: value,
        rows: this.pageDetails.listDataSource,
        count: this.pageDetails && this.pageDetails.listDataSource && this.pageDetails.listDataSource.length,
      }
      this.isRetrieve = true;
      this.paginatorService.listPaginatorData.next(this.pageDetails.paginator);
      this.pageDetails.listDataSource = this.offerDetails && this.offerDetails['manualDiscount'] ? this.offerDetails['manualDiscount'].slice(this.offset, this.offset + this.limit) : [];
    }
  }

  /**
  * Function which is used to get index of selected tab.
  * @param event To get event triggered when tab is changed.
  */
  tabIndex(event: { export: boolean; store_id: any; }): void {
    this.isExport = event?.export;
    if (event?.store_id) {
      this.storeId = event.store_id;
    }
    if (!this.isExport) {
      this.tabDataRetrieve(true);
    } else {
      this.tabDataRetrieve(false);
    }
  }

  /**
* Method which is used to get changed page data.
* @param event To get offset and limit.
*/
  onDataChange(event: any): void {
    this.getDiscountReport(event?.offset, event?.limit, false);
  }
  /**
  * Method which is used to get changed page data.
  * @param event To get offset and limit.
  */
  changedData(event: any): void {
    if (event) {
      this.paginatorValue.limit = event?.limit ? event.limit : null;
      this.paginatorValue.offset = event?.offset ? event.offset : 0;
    }
    this.getDiscountReport(event?.offset, event?.limit, false);
  }
  /**
   * Method which is used to get paginator data.
   * @param event To get offset and limit.
   */
  paginatorData(event: any): void {
    this.paginatorLimit = event?.pageSize;
    this.paginatorOffset = event?.pageIndex * event?.pageSize;
    this.paginatorValue.limit = event?.pageSize;
    this.paginatorValue.offset = event?.pageIndex * event?.pageSize;
    this.pageSize = event?.pageSize;
  }

  /**
   * Function which gets called during location, searchText, from date, to date and sort change events in common report.
   * @param event To get the event value.
   */
  filterAction(event: any): void {
    this.location_id = event?.location_id ? event.location_id : null;
    this.storeId = event?.store_id ? event.store_id : null;
    this.fromDate = event.from_date ? event.from_date : this.fromDate;
    this.toDate = event.to_date ? event.to_date : this.toDate;
    this.searchText = event?.searchText ? event.searchText : '';
    this.paginatorValue.limit = event?.limit ? event.limit : null;
    this.paginatorValue.offset = event?.offset ? event.offset : 0;
    this.sort = event?.sort;
    if (event?.exportexcel) {
      this.exportOffer();
    } else {
      this.getDiscountReport(event?.offset, event?.limit, true);
    }
  }
  /**
   * Function which gets called during export is true in common report.
   */
  exportOffer(): void {
    let filterConstrain: any = {
      from_date: this.fromDate ? this.datePipe.transform(this.fromDate, 'yyyy-MM-dd') : null,
      to_date: this.toDate ? this.datePipe.transform(this.toDate, 'yyyy-MM-dd') : null,
      locationId: this.location_id ? this.location_id : null,
    }
    if (this.offerDetails && this.offerDetails['manualDiscount'] && this.offerDetails['manualDiscount'].length > 0) {
      const data: ExportObject = {
        title: 'Export ',
        exportApiUrl: 'stores/' + this.storeId + '/export/report/manualoffer',
        limit: this.paginatorLimit ? this.paginatorLimit : this.paginatorValue.limit ? this.paginatorValue.limit : this.limit ? this.limit : this.pageSize,
        offset: this.paginatorOffset ? this.paginatorOffset : this.paginatorValue.offset ? this.paginatorValue.offset : this.offset ? this.offset : 0,
        type: false,
        fileName: 'manual offer',
        expansion: true,
        filterData: filterConstrain ? filterConstrain : null,
        selectedData: []
      };
      const dialogRefData: any = this.displayDialog.open(ExportComponent, { disableClose: true });
      if (dialogRefData && dialogRefData.componentInstance) {
        dialogRefData.componentInstance.contentData = data;
      }
    } else {
      this.dialogDisplay(this.commonConstant &&
        this.commonConstant.dialogMessages
        && this.commonConstant.dialogMessages.noDataExport,
        this.commonConstant.dialogType.alert);
    }
  }
  /**
* Method used to display the dialog
* @param message has the message
* @param type has the dialog type.
*/
  dialogDisplay(message: string, type: string): any {
    return this.dialogService.dialogMethod(message, type, true);
  }
  /**
   * Angular life cycle hook which is used to unsubscribe the subscriptions.
   */
  ngOnDestroy(): void {
    if (this.subscriptionObj) {
      this.subscriptionObj.unsubscribe();
    }
  }

}
