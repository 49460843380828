/** 
 * ActivityLogsComponent component used to view users login histories. 
 * Author: Chermaselvi T
 * */
import { Component, Injector, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '@phase-ii/auth';
import { SharedConstant } from '../../../../../common/src/lib/constants/shared-constant';
import { DialogService } from '../../../../../common/src/lib/services/dialog.service';
import { Subscription } from 'rxjs';
import { filter, mergeMap, switchMap } from 'rxjs/operators';
import { ListProperties, ColumnsRef, } from '../../constants/shared-constant';
import { ActionIcon, ButtonInfo, CommonConstants, CommonDataService, CommonService, Heading, PaginationService } from '@phase-ii/shared';
@Component({
  selector: 'phase-ii-activity-logs',
  templateUrl: './activity-logs.component.html',
  styleUrls: ['./activity-logs.component.scss']
})
/** ActivityLogsComponent component used to view users login histories. */
export class ActivityLogsComponent extends SharedConstant implements OnInit, OnDestroy {
  /**commonConstant used to access commonConstants
  * @type {CommonConstants}
  */
  commonConstant: SharedConstant;
  /**paginatorConstant used to access commonConstants
  * @type {CommonConstants}
  */
  paginatorConstant = new CommonConstants();
  /** PageDetails object is used to store the variables used inside this component. */
  pageDetails: {
    heading: Heading,
    buttonInfo: ButtonInfo[],
    listProperty: ListProperties,
    columnRef: ColumnsRef[],
    actionIcons: ActionIcon[],
    pageSize: number,
    offset: number,
    limit: number,
    isLoader: boolean,
    itemsCount: number,
    showDateRange: boolean,
    loginHistories: any,
    actions: any,
    paginatorLoader: boolean,
  } = {
      heading: null,
      buttonInfo: null,
      listProperty: null,
      columnRef: null,
      actionIcons: null,
      pageSize: this.paginatorConstant.paginator.defaultPageSize,
      offset: null,
      limit: null,
      isLoader: null,
      itemsCount: null,
      showDateRange: false,
      loginHistories: null,
      actions: null,
      paginatorLoader: false,
    };
  /**
   * userLogsDetails used to store the user logs details. 
   * */
  userLogsDetails = {
    storeId: null,
    userId: null,
    name: null,
    location: null,
    role: null,
    lastActive: null,
    profileName: null,
    startDate: null,
    endDate: null
  }
  /**
  * subscription object
  * @type {Subscription}
  */
  subscriptionObject: Subscription = new Subscription();
  /**
   * filterOption used to assign history filter options.
   * */
  filterOption: any;
  /**
   * searchedText used to store the searched data.
   */
  searchedText: any;
  // /**
  //  * filterInputs used to store the filter option data.
  //  */
  // filterInputs = {
  //   firstDay: null,
  //   lastDay: null,
  //   firstdayOfWeek: null,
  //   todaysDate: null,
  //   lastdayOfWeek: null,
  //   firstdayOfYear: null,
  //   lastdayOfYear: null
  // }
  /**
   * filteredText used to filter the data.
   */
  filteredText: any;
  /**
   * historyLength used to store the history data length.
   */
  maxDate = new Date();
  /**
   * previousRoute used to store the previous route url.
   */
  previousRoute: any;
  /**
   * authService used to access authService.
   * @type {AuthService}
   */
  authService: AuthService;
  /**
   * route used to access route.
   * @type {ActivatedRoute}
   */
  route: ActivatedRoute;
  /**
   * commonDataService used to access commonDataService.
   * @type {CommonDataService}
   */
  commonDataService: CommonDataService;
  /**
   * storeSettingService used to access storeSettingService.
   * @type {CommonService}
   */
  storeSettingService: CommonService;
  /**
   * paginationService used to access PaginationService.
   * @type {PaginationService}
   */
  paginationService: PaginationService;
  /**
   * dialogService used to access dialogService.
   * @type {DialogService}
   */
  dialogService: DialogService;
  /**
   * router used to access Router.
   * @type {Router}
   */
  router: Router;
  /**
   * commonService used to access commonService.
   * @type {CommonService}
   */
  commonService: CommonService;
  /**
  * Variable which is used to store the customer for filter.
  */
  filterOptions: any;
  /**
    * Variable which is used to check filter is clicked or not.
   */
  isFilter: boolean = false;
  /**
   * Variable filterData used to store the filtered condition for user history data.
   */
  filterData: any;
  /**
   * Component constructor to inject the required services.
   * @param injector is to access injector.
   */
  constructor(private injector: Injector) {
    super();
    this.authService = injector.get<AuthService>(AuthService);
    this.route = injector.get<ActivatedRoute>(ActivatedRoute);
    this.commonDataService = injector.get<CommonDataService>(CommonDataService);
    this.storeSettingService = injector.get<CommonService>(CommonService);
    this.paginationService = injector.get<PaginationService>(PaginationService);
    this.dialogService = injector.get<DialogService>(DialogService);
    this.router = injector.get<Router>(Router);
    this.commonService = injector.get<CommonService>(CommonService);
    this.commonConstant = new SharedConstant();
  }
  /**
   * Component OnInit life cycle hook 
   * */
  ngOnInit(): void {
    this.pageDetails.heading = {
      title: this.commonConstant.historyDetailsTitle,
      description: ''
    };
    this.pageDetails.isLoader = true;
    this.onSearch();
    this.filterOption = this.commonConstant.historyFilterOption;
    this.getUserHistories(0, 2 * this.pageDetails.pageSize, true, '', this.filterData, true);
    this.variableIntialization();
  }
  /**
   * getUserHistories used to fetch all user history details
   * @param offset is the offset
   * @param limit is the limit
   * @param isNew is isnew value
   * @param filterText is filterText
   * @param filterData is filterData
   * @param isStatusCheck used for displaying last seen status.
   */
  getUserHistories(offset: number, limit: number, isNew: boolean, filterText?: any, filterData?: any, isStatusCheck?: boolean) {
    const data: any = {
      offset: offset,
      limit: limit,
      searchText: filterText ? filterText : ''
    };
    let roleCode;
    this.pageDetails.paginatorLoader = true;

    //  else if (filterData && filterData.startTime) {
    //   data.startDate = filterData.startTime;
    // }
    // if (filterData && filterData.deviceName) data.deviceName = filterData.deviceName;
    const filterVal = JSON.stringify(filterData);
    // console.log('filterva', filterVal)
    if (filterVal) data.filterData = filterVal;
    this.subscriptionObject.add(this.authService.user.pipe(filter((res: any) => {
      if (res && res.storeId) {
        roleCode = res.roleCode;
        this.userLogsDetails.storeId = res.storeId;
        return res;
      }
      return true;
    }),
      mergeMap(() => {
        return this.route.queryParams;
      }),
      mergeMap((res) => {
        if (res) {
          // data.type = res.type;
          if (roleCode === this.commonConstant.storeAdminCode) {
            if (res.isEmailMarketing) {
              this.previousRoute = '/app/emailmarketing/contacts';
            } else {
              this.previousRoute = '/app/customer/customerlist';
            }

          } else if (res && res['isShopApp']) {
            this.previousRoute = '/app/users/shopapp/userlist';
          }
          else {
            this.previousRoute = '/app/users/customerlist';
          }
          this.pageDetails.buttonInfo = [
            {
              name: 'Back', class: 'secondary-button', link: this.previousRoute
            }
          ];
          this.userLogsDetails.userId = this.commonDataService.getParam(res.id);
          if (res && !res['isShopApp']) {
            this.userLogsDetails.storeId = this.userLogsDetails.storeId ? this.userLogsDetails.storeId : this.commonDataService.getParam(res.store);
          } else {
            this.userLogsDetails.storeId = +res['storeId'];
          }
          // data.clientId = res.client;
        }
        return this.storeSettingService.getUserHistory(this.userLogsDetails.userId, this.userLogsDetails.storeId, data);
      }),
    ).subscribe((res: any) => {
      this.pageDetails.isLoader = false;
      this.pageDetails.paginatorLoader = false;
      if (res && res.result && Object.keys(res['result']['userData']).length > 0) {
        this.processListDataSource(res, isNew, isStatusCheck);
      }
      else {
        this.pageDetails.isLoader = false;
        this.pageDetails.paginatorLoader = false;
        this.router.navigate(['/app/emailmarketing/contacts']);
        this.dialogService.dialogMethod(this.commonConstant.dialogMessages.loadUserHistoryDataFailed,
          this.dialogType.failure);
      }
    }, (err) => {
      this.pageDetails.isLoader = false;
      this.pageDetails.paginatorLoader = false;
      this.dialogService.dialogMethod(this.commonConstant.dialogMessages.loadUserHistoryDataFailed,
        this.dialogType.failure);
    }));
  }

  /**variableIntialization used to initialize the variables */
  variableIntialization() {
    this.pageDetails.columnRef = [
      { column: 'ipAddress', type: 'Text', suffixText: 'Ip Address' },
      // { column: 'name', type: 'Text' },
      { column: 'deviceName', type: 'Text', suffixText: 'Device' },
      // { column: 'createdAt', type: 'Date', suffixText: 'Created Date' },
      // { column: 'platform', type: 'Text' },
      // { column: 'location', type: 'Text', suffixText: 'Location' },
      { column: 'loginTime', type: 'DateTime', format: 'MMM d, y, h:mm a', suffixText: 'Logged In' },
      { column: 'loggedOutTime', type: 'DateTime', format: 'MMM d, y, h:mm a', suffixText: 'Logged Out' },
      // { column: 'lastActive', type: 'InnerHtml' },
    ];
    this.pageDetails.listProperty = {
      globalSearch: true,
      columns: 4,
      dataOnly: true,
      rowElementClicked: false,
      suffixText: true,
      searchPlaceHolder: 'Device'
    };
    this.pageDetails.actions =
      [
        { name: 'refresh', value: 'onFilterClose', toolTip: "Refresh" },

        { name: 'filter_list', value: 'filter', toolTip: "Filter", isConditionApplied: true, conditionType: 'DATA_EMPTY' }
      ];
  }
  /**
  * Method filter which is used to show filter drawer.
  */
  filter(): void {
    this.filterOptions = [
      { title: "Custom Date", type: "dateRange", field: "customDateRange", isMaxDateToday: true },
      { title: "Device Name", type: "radio", field: "deviceName", options: ['mobile', 'desktop'] },
      { title: "Days", type: "toggleGroup", options: this.commonConstant.historyFilterOption, field: 'days' },
    ];
    this.isFilter = true;
  }
  /**
  * Method onFilterClose which is used to close filter section.
  */
  onFilterClose(): void {
    this.filterData = null;
    this.isFilter = false;
    this.getUserHistories(0, 2 * this.pageDetails.pageSize, true, this.searchedText);
  }
  /**
  * Method used to show output of fliter component.
  * @param event To get the selected event
  */
  onFilterSelected(event: any): void {
    if (event && event.days) {
      // if (event.days == 'Today') {
      //   // event['startTime'] = new Date();
      //   (event['startTime']).setHours(0, 0, 0, 0);
      //   (event['endTime']).setHours(23, 59, 59, 999);
      //   console.log('event 1', event);
      // } else if (event.days === 'This Month') {
      //   event['startTime'] = new Date(new Date().getFullYear(), new Date().getMonth(), 1);
      //   event['startTime'] = event['startTime'].setHours(0, 0, 0, 0);
      //   event['endTime'] = new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0);
      //   event['endTime'] = event['endTime'].setHours(23, 59, 59, 999);

      // } else if (event.days === 'This Week') {
      //   const first = event['startTime'].getDate() - event['startTime'].getDay();
      //   const lastDay = first + 6;
      //   (event['startTime']).setDate(first);
      //   event['startTime'].setHours(0, 0, 0, 0);
      //   // (event['endTime']).setDate(lastDay);
      //   event['endTime'].setHours(23, 59, 59, 999);

      // } else if (event.days === 'This Year') {
      //   event['startTime'] = new Date(new Date().getFullYear(), 0, 1);
      //   event['startTime'] = event['startTime'].setHours(0, 0, 0, 0);
      //   event['endTime'] = new Date(new Date().getFullYear(), 11, 31);
      //   event['endTime'] = event['endTime'].setHours(23, 59, 59, 999);
      // }
      event.toggleDateRange = {
        begin: new Date(),
        end: new Date()
      };
      if (event.days == 'Today') {
        event['toggleDateRange']['begin'].setHours(0, 0, 0, 0);
        event['toggleDateRange']['end'].setHours(23, 59, 59, 999);
      }
      else if (event.days == 'Yesterday') {
        event['toggleDateRange']['begin'].setDate(event['toggleDateRange']['begin'].getDate() - 1);
        event['toggleDateRange']['begin'].setHours(0, 0, 0, 0);
        event['toggleDateRange']['end'].setDate(event['toggleDateRange']['end'].getDate() - 1);
        event['toggleDateRange']['end'].setHours(23, 59, 59, 999);
      } else if (event.days == 'This Week') {
        event['toggleDateRange']['begin'].setDate(event['toggleDateRange']['begin'].getDate() - event['toggleDateRange']['begin'].getDay());
        event['toggleDateRange']['begin'].setHours(0, 0, 0, 0);
        event['toggleDateRange']['end'].setHours(23, 59, 59, 999);
      } else if (event.days == 'This Month') {
        event['toggleDateRange']['begin'].setDate(event['toggleDateRange']['begin'].getDate() - (event['toggleDateRange']['begin'].getDate() - 1));
        event['toggleDateRange']['begin'].setHours(0, 0, 0, 0);
        event['toggleDateRange']['end'].setHours(23, 59, 59, 999);
      }
      // else if (event.days === 'This Year') {
      //   event['toggleDateRange']['begin'] = (new Date(new Date().getFullYear(), 0, 1)).setHours(0, 0, 0, 0);
      //   event['toggleDateRange']['begin'] = new Date(event['toggleDateRange']['begin']);
      //   // console.log('year', event['startTime']
      //   event['toggleDateRange']['end'] = (new Date(new Date().getFullYear(), 11, 31)).setHours(23, 59, 59, 999);
      //   //   event['endTime'] = event['endTime'].setHours(23, 59, 59, 999);
      //   event['toggleDateRange']['end'] = new Date(event['toggleDateRange']['end']);

      // }
      delete event.days;
    }
    if (event && event.customDateRange && event.customDateRange.begin && event.customDateRange.end) {
      event.customDateRange.begin = new Date(event.customDateRange.begin);
      event.customDateRange.end = new Date(event.customDateRange.end);
      event['customDateRange']['begin'].setHours(0, 0, 0, 0);
      event['customDateRange']['end'].setHours(23, 59, 59, 999);
    }
    this.filterData = event;
    this.getUserHistories(0, 2 * this.pageDetails.pageSize, true, this.searchedText, this.filterData);
  }
  /**
  * calculateLastSeen
  * @param date is the timstamp
  * @returns last seen .
  */
  calculateLastSeen(date) {
    const time = (new Date()).getTime() - date.getTime();
    const seconds = Math.floor(time / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);
    const months = Math.floor(days / 30);
    const years = Math.floor(months / 12);
    if (time === 0) {
      return 'Just now';
    } else if (seconds < 60) {
      if (seconds === 1) {
        return 'Last Seen ' + seconds + ' second Ago';
      }
      return 'Last Seen ' + seconds + ' seconds Ago';
    } else if (minutes < 60) {
      if (minutes === 1) {
        return 'Last Seen ' + minutes + ' minute Ago';
      }
      return 'Last Seen ' + minutes + ' minutes Ago';
    } else if (hours < 24) {
      if (hours === 1) {
        return 'Last Seen ' + hours + ' hr Ago';
      }
      return 'Last Seen ' + hours + ' hours Ago';
    } else if (days < 30) {
      if (days === 1) {
        return 'Last Seen ' + days + ' day Ago';
      }
      return 'Last Seen ' + days + ' days Ago';
    } else if (months < 12) {
      if (months === 1) {
        return 'Last Seen ' + months + ' month Ago';
      }
      return 'Last Seen ' + months + ' months Ago';
    } else {
      if (years === 1) {
        return 'Last Seen ' + years + ' year Ago';
      }
      return 'Last Seen ' + years + ' years Ago';
    }
  }
  // /**
  //  * onSubmit function used to filter particular time.
  //  * @param event return filter option.
  //  */
  // onSubmit(event) {
  //   this.filteredText = event;
  //   if (event === 'Custom Date') {
  //     this.pageDetails.showDateRange = true;
  //   } else if (event === 'Today') {
  //     this.pageDetails.showDateRange = false;
  //     this.filterInputs.todaysDate = new Date();
  //     this.getUserHistories(0, 2 * this.pageDetails.pageSize, true, this.searchedText, this.filterInputs.todaysDate);
  //   } else if (event === 'This Month') {
  //     this.pageDetails.showDateRange = false;
  //     this.filterInputs.firstDay = new Date(new Date().getFullYear(), new Date().getMonth(), 1);
  //     this.filterInputs.lastDay = new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0);
  //     this.getUserHistories(0, 2 * this.pageDetails.pageSize, true, this.searchedText, this.filterInputs.firstDay, this.filterInputs.lastDay);
  //   } else if (event === 'This Week') {
  //     this.pageDetails.showDateRange = false;
  //     const first = new Date().getDate() - new Date().getDay();
  //     const lastDay = first + 6;
  //     this.filterInputs.firstdayOfWeek = new Date(new Date().setDate(first));
  //     this.filterInputs.lastdayOfWeek = new Date(new Date().setDate(lastDay));
  //     this.getUserHistories(0, 2 * this.pageDetails.pageSize, true, this.searchedText, this.filterInputs.firstdayOfWeek, this.filterInputs.lastdayOfWeek);
  //   } else if (event === 'This Year') {
  //     this.pageDetails.showDateRange = false;
  //     this.filterInputs.firstdayOfYear = new Date(new Date().getFullYear(), 0, 1);
  //     this.filterInputs.lastdayOfYear = new Date(new Date().getFullYear(), 11, 31);
  //     this.getUserHistories(0, 2 * this.pageDetails.pageSize, true, this.searchedText, this.filterInputs.firstdayOfYear, this.filterInputs.lastdayOfYear);
  //   } else if (event === 'Device') {
  //     this.getUserHistories(0, 2 * this.pageDetails.pageSize, true, this.searchedText, null, null,);
  //   } else {
  //     this.getUserHistories(0, 2 * this.pageDetails.pageSize, true, this.searchedText);
  //     this.pageDetails.showDateRange = false;
  //   }
  // }
  /**
   * Method used to trigger the function.
   */
  // functionTriggered(event) {
  //   if (event) {
  //     this[event]();
  //   }
  // }
  // /**
  //  * customDate method is called when we select custom date range.
  //  * @param date is the start or end date.
  //  * @param isStart which returns either true or false.
  //  */
  // customDate(date: Date, isStart: boolean) {
  //   this.userLogsDetails[isStart ? 'startDate' : 'endDate'] = date;
  //   if (this.userLogsDetails.startDate && this.userLogsDetails.endDate) {
  //     this.getUserHistories(0, 2 * this.pageDetails.pageSize, true, this.searchedText, this.userLogsDetails.startDate, this.userLogsDetails.endDate);
  //   }
  // }
  /**
  * @param event id of selected row
  * method for action button click
  */
  listAction(event: any) {
    if (event && event.method) {
      this[event.method](event);
    }
  }
  /**
  * Method which is used to action for the list.
  */
  cardActions(event: any): void {
    if (event) {
      this[event]();
    }
  }
  /**
   * Method used to paginate the customer list
   * @param event to get event
   */
  getPaginatorData(event) {
    this.pageDetails.pageSize = event.pageSize;
    this.pageDetails.limit = event.pageSize;
    this.pageDetails.offset = event.pageIndex * event.pageSize;
  }
  /**
  * Method which is used to get changed page data.
  * @param event  To get offset and limit.
  */
  getChangedData(event: any) {
    if (event) {
      // if (this.filteredText === 'Today') {
      //   this.getUserHistories(event.offset, event.limit, false, this.searchedText, this.filterInputs.todaysDate);
      // } else if (this.filteredText === 'This Month') {
      //   this.getUserHistories(event.offset, event.limit, false, this.searchedText, this.filterInputs.firstDay, this.filterInputs.lastDay);
      // } else if (this.filteredText === 'This Week') {
      //   this.getUserHistories(event.offset, event.limit, false, this.searchedText, this.filterInputs.firstdayOfWeek, this.filterInputs.lastdayOfWeek);
      // } else if (this.filteredText === 'This Year') {
      //   this.getUserHistories(event.offset, event.limit, false, this.searchedText, this.filterInputs.firstdayOfYear, this.filterInputs.lastdayOfYear);
      // } else if (this.filteredText === 'Custom Date') {
      //   this.getUserHistories(event.offset, event.limit, false, this.searchedText, this.userLogsDetails.startDate, this.userLogsDetails.endDate);
      // }
      // else
      this.getUserHistories(event.offset, event.limit, false, this.searchedText, this.filterData);
    }

  }
  /**
   * Method which is used to changed records based on search.
   * @param event To get searchtext.
   */
  onSearch() {
    let data;
    this.subscriptionObject.add(this.commonService.searchSubject.pipe(filter(res => res !== null),
      //  debounceTime(1000),
      switchMap(response => {
        // if (this.filteredText === 'Today') {
        //   data = {
        //     offset: 0, limit: 2 * this.pageDetails.pageSize,
        //     searchText: response,
        //     this.filterData
        //   }
        // } else if (this.filteredText === 'This Month') {
        //   data = {
        //     offset: 0, limit: 2 * this.pageDetails.pageSize,
        //     searchText: response,
        //     startDate: this.filterInputs.firstDay,
        //     endDate: this.filterInputs.lastDay
        //   }
        // } else if (this.filteredText === 'This Week') {
        //   data = {
        //     offset: 0, limit: 2 * this.pageDetails.pageSize,
        //     searchText: response,
        //     startDate: this.filterInputs.firstdayOfWeek,
        //     endDate: this.filterInputs.lastdayOfWeek
        //   }
        // } else if (this.filteredText === 'This Year') {
        //   data = {
        //     offset: 0, limit: 2 * this.pageDetails.pageSize,
        //     searchText: response,
        //     startDate: this.filterInputs.firstdayOfYear,
        //     endDate: this.filterInputs.lastdayOfYear
        //   }
        // } else if (this.filteredText === 'Custom Date') {
        //   data = {
        //     offset: 0, limit: 2 * this.pageDetails.pageSize,
        //     searchText: response,
        //     startDate: this.userLogsDetails.startDate,
        //     endDate: this.userLogsDetails.endDate
        //   }
        // } else {
        data = {
          offset: 0, limit: 2 * this.pageDetails.pageSize,
          searchText: response
        };
        if (this.filterData && this.filterData.startTime && this.filterData.endTime) {
          data.startDate = this.filterData.startTime;
          data.endDate = this.filterData.endTime;
        } else if (this.filterData && this.filterData.startTime) {
          data.startDate = this.filterData.startTime;
        }
        if (this.filterData && this.filterData.deviceName) data.deviceName = this.filterData.deviceName;
        // }
        this.searchedText = response;
        const filterVal = JSON.stringify(this.filterData);
        if (filterVal) data.filterData = filterVal;
        return this.storeSettingService.getUserHistory(this.userLogsDetails.userId, this.userLogsDetails.storeId, data);
      })).subscribe((res: any) => {
        if (res && res.result) {
          this.processListDataSource(res, true);
        }
      }, err => {
        this.pageDetails.isLoader = false;
        this.dialogService.dialogMethod(this.commonConstant.dialogMessages.loadUserHistoryDataFailed,
          this.dialogType.failure);
      }));
  }
  /**
  * Function which is used to process the list data  of common card
  * @param response To get the response of get all invitations
  * @param isNew Used to load a list newly
  * @param isStatusCheck used for displaying last seen status.
  */
  processListDataSource(res: any, isNew?: boolean, isStatusCheck?: boolean) {
    if (res && res.result && res.result.userData) {
      this.userInfoDetails(res);
    }
    if (res && res.result && res.result.userLogs && res.result.userLogs.rows) {
      this.loginHistoryDetails(res, isStatusCheck);
      this.paginationService.listPaginatorData.next(
        {
          new: isNew,
          rows: this.pageDetails.loginHistories,
          count: this.pageDetails.itemsCount,
        });
    }
    this.pageDetails.isLoader = false;
  }
  /**
   * Method loginHistoryDetails uses to reconstruct list user login history datas.
   * @param res is the user history response.
   * @param isStatusCheck used for displaying last seen status.
   */
  loginHistoryDetails(res, isStatusCheck) {
    if (res && res.result && res.result && res.result.userLogs) {
      this.pageDetails.itemsCount = res.result.userLogs.count;
      this.pageDetails.loginHistories = res.result.userLogs.rows;
      this.pageDetails.loginHistories.forEach((item, index) => {
        if (item && item.user) {
          item.name = item.user.firstName + ' ' + item.user.lastName;
          item.ipAddress = item.ipAddress ? item.ipAddress : '---';
          this.userLogsDetails.location = item.location;
          // if (item.user && item.user.role) {
          //   this.userLogsDetails.role = item.user.role.role;
          // }

          if (item.appDeviceDetail) {
            item.deviceName = item.appDeviceDetail.deviceName;
            item.platform = item.appDeviceDetail.platform;
            if (index == 0 && isStatusCheck) {
              // item.lastActive = ((item.appDeviceDetail.lastActive === null) ||
              //   new Date(item.appDeviceDetail.lastActive) < new Date(item.loginTime)) ? ' Active Now' :
              //   this.calculateLastSeen(new Date(item.appDeviceDetail.lastActive));
              item.lastActive = ((item.appDeviceDetail.lastActive === null) ||
                item.loggedOutTime === null) ? ' Active Now' :
                this.calculateLastSeen(new Date(item.loggedOutTime));
              this.userLogsDetails.lastActive = item.lastActive;
            }
          }
        }
      });
    }
  }
  /**
   * Method userInfoDetails used to list the user information.
   * @param res is the response.
   */
  userInfoDetails(res) {
    if (res && res.result && res.result.userData) {
      this.userLogsDetails.profileName = res.result.userData.firstName && res.result.userData.lastName && (res.result.userData.firstName.charAt(0) + res.result.userData.lastName.charAt(0));
      this.userLogsDetails.name = res.result.userData.firstName && res.result.userData.lastName && (res.result.userData.firstName + ' ' + res.result.userData.lastName);
      this.userLogsDetails.location = res.result.userData.firstName && res.result.userData.lastName && (res.result.userData.firstName + ' ' + res.result.userData.lastName);
      this.userLogsDetails.role = res.result.userData.role;
    }
  }
  /**
  * Component OnDestroy life cycle hook.
  * And unsubscribe all the subscriptions in the component.
  */
  ngOnDestroy(): void {
    if (this.subscriptionObject) {
      this.subscriptionObject.unsubscribe();
    }
    this.commonService.searchSubject.next(null);
  }
}
