/**
 * Component to display the sorting and product filter functionality
 * Author: S.P Priya
 */
import { Component, Input, Output, EventEmitter, SimpleChanges, ViewEncapsulation, ViewChild, TemplateRef } from '@angular/core';
import { UntypedFormGroup, UntypedFormArray, UntypedFormControl, Validators } from '@angular/forms';
import { ThemesModuleConstants } from '../../constants/shared-theme-constants';
import * as _ from 'lodash';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { SharedService } from '@phase-ii/shared-theme';

/**
 * Component to display the sorting and product filter functionality
 */
@Component({
  selector: 'phase-ii-product-filter',
  templateUrl: './product-filter.component.html',
  styleUrls: ['./product-filter.component.scss']
})
export class ProductFilterComponent extends ThemesModuleConstants {

  /**
  * Variable which is used to get the filter array from parent component
  * @type {Array}
  */
  @Input() filterArray: Array<any>;
  /**
   * Variable used to get the show filter option from parent component.
   * @type {object}
   */
  @Input() showFilterOption: object;
    /**
   * Variable which is used to get the show filter option for Play Feast form parent component
   * @type {object}
   */
    @Input() playFeastshowFilterOption:object

  /**
   * Custom date to store
   * @type {*}
   */
  @Input() customizationData: any;
  /**
  * Variable which is used to get template name from parent component
  * @type {string}
  */
  @Input() filterTemplate: string;
  /**
  * Variable which is used to get template background color from parent component
  * @type {string}
  */
  @Input() filterTemplateBackgroundColor: string;
  /**
  * Variable which is used to get template text color from parent component
  * @type {string}
  */
  @Input() filterTemplateTextColor: string;
  /**
  * Variable which is used to get slider step count from parent component
  * @type {number}
  */
  @Input() sliderStepsCount: number;
  /**
  * Variable which is used to get isEditor or not from parent component
  * @type {boolean}
  */
  @Input() isEditor: boolean;

  /**
   * Variable to hise/show scrollbar in filter
   * @type {boolean}
   */
  isNoScrollbar = false;
  /**
   * Variable used to store the filter array values into form array
   * @type {FormGroup}
   */
  filterFormGroup = new UntypedFormGroup({
    filterFormArray: new UntypedFormArray([])
  });

  /**
   * Variable used to show / hide the applied filters chip list
   * @type {boolean}
   */
  showFilter: boolean;

  /**
   * Variable which is used to emit filter result of the product
   * @type {EventEmitter}
   */
  @Output() filterResult = new EventEmitter();

  /**
   * Variable used to set the sorting panel to open or close
   * @type {boolean}
   */
  expandPanel: boolean;

  /**
  * Variable which is used to get the sort array from parent component
  * @type {Array}
  */
  @Input() sortArray: Array<object>;

  /**
  * Variable which is used to show / hide the sort section from parent component
  * @type {boolean}
  */
  @Input() isSort: boolean;
  /**
   * Variable used to get the selected fiter details from parent component
   * @type {any}
   */
  @Input() emittedFilterData: any = null;
  /**
   * Variable which is used to show the Slider Minimum Value 
   * @type {number}
   */
  sliderMinValue: number; 
  /**
   * Variable which is used to show the Slider Maximum Value
   * @type {number} 
   */
  sliderMaxValue: number; 
  /**
   * variable which is used to  acess the Price in the type of Slider
   * @type {boolean}
   */
  
  sliderCheck:boolean
  /**
   * Variable which is used to  store the CommonWebsiteDetails
   * @type {any}
   */
  websiteSettings:any 
  /**
   * Variable used to store the currenty selected sort option
   * @type {FormControl}
   */
  sortOption = new UntypedFormControl(null);
  /**
   * Array to store the options to filter
   * @type {Array}
   */
  filteredOptionsArray: Array<any> = [];
  /**
   * Array to store the combination of options
   * @type {Array}
   */
  variantCombination: Array<any> = []; 
  /**
  * Variable used to display first template for filter component.
  * @type {TemplateRef}
  */
  @ViewChild('filterTemplateOne', { static: false }) filterTemplateOne: TemplateRef<any>;
  /**
   * Variable used to display second template for filter component.
   * @type {TemplateRef}
   */
  @ViewChild('filterTemplateTwo', { static: false }) filterTemplateTwo: TemplateRef<any>;
   /**
   * Variable used to display thrid template for filter component.
   * @type {TemplateRef}
   */
   @ViewChild('filterTemplateThree', { static: false }) filterTemplateThree: TemplateRef<any>;
  /**
   * Variable used to store start value of a slider.
   * @type {number}
   */
  sliderStartValue: number;
  /**
  * Variable used to store end value of a slider.
  * @type {number}
  */
  sliderEndValue: number;
  /**
   * Array used to store open or close value of filter array contents.
   * @type {Array}
   */
  filterExapansionPanel: Array<any> = [];
  /**
  * Variable used to store open or close value of sort array contents.
  * @type {boolean}
  */
  isSortOpen: boolean;

  /**
   * component constructor which is used to inject the required services.
   * @param sharedService To access the sharedservice
   */
  constructor(
    public bottomSheetRef: MatBottomSheet,
    private sharedService: SharedService,
  ) {
    super();
  }
  /**
  * Component OnInit life cycle hook.
  * @type {void}
  */
  ngOnInit():void{   
    this.sharedService.commonWebsiteDetails.subscribe((res) => { 
      if (res)
        this.websiteSettings = res;
    });
    if (this.filterExapansionPanel&& this.filterExapansionPanel.length === 0 && this.filterArray && this.filterArray.length && this.filterArray.length > 0) {
      for (let i = 0; i < this.filterArray.length; i++) {
        this.filterExapansionPanel[i] = true;
        this.isSortOpen = true;
        if ((this.filterTemplate === 'filterTemplateTwo'|| this.filterTemplate === 'filterTemplateThree') && this.filterArray[i].fieldName === 'price' && this.filterArray[i].type === 'slider' && this.filterArray[i].displayContent) {
          this.sliderStartValue = this.filterArray[i].displayContent.start;
          this.sliderEndValue = this.filterArray[i].displayContent.end;
        }
      }
    }
  }
  /**
   * component on changes life cycle hook
   * @param changes defines the input changes
   */
  ngOnChanges(changes: SimpleChanges): void { 
    if (this.customizationData) {
      this.isNoScrollbar = this.customizationData?.cardDesign == 'gamingProductList'
    }
    if (changes && changes.hasOwnProperty('filterArray')) {
      let valueSet;
      this.filterFormGroup = new UntypedFormGroup({
        filterFormArray: new UntypedFormArray([])
      });
      if (this.filterArray && this.filterArray.length) {
        if (this.emittedFilterData && this.sortArray) {
          const array = this.sortArray.find((sort) => _.isEqual(sort && sort['value'], this.emittedFilterData.sort));
          this.sortOption.setValue(array);
        }

        for (let i = 0; i < this.filterArray.length; i++) {
          if (this.filterArray[i]) {
            (<UntypedFormArray>this.filterFormGroup.get('filterFormArray')).push(new UntypedFormGroup({
              filterName: new UntypedFormControl(this.filterArray[i].filterName),
              type: new UntypedFormControl(this.filterArray[i].type),
              options: new UntypedFormArray([]),
              appliedFilters: new UntypedFormControl(null),
              isVisible: new UntypedFormControl(this.filterArray[i].isVisible),
              appliedFilterCount: new UntypedFormControl(0),
              fieldName: new UntypedFormControl(this.filterArray[i].fieldName),
              optionType: new UntypedFormControl(this.filterArray[i].optionType),
              showColors: new UntypedFormControl(this.filterArray[i].showColors)
            }));
            if (this.filterArray[i].options && this.filterArray[i].options.length) {
              for (let j = 0; j < this.filterArray[i].options.length; j++) {
                let selectedValue;
                if (this.emittedFilterData && this.emittedFilterData.hasOwnProperty(this.filterArray[i].fieldName) &&
                  this.emittedFilterData[this.filterArray[i].fieldName] && this.emittedFilterData[this.filterArray[i].fieldName].length > 0) {
                  selectedValue = this.emittedFilterData[this.filterArray[i].fieldName].find((item) => _.isEqual(item, this.filterArray[i].options[j].value));
                  if(this.filterArray[i].type =="slider" && this.emittedFilterData.price.length ){ 
                    selectedValue=this.emittedFilterData.price[0] 
                  }
                }
                (<UntypedFormArray>this.filterFormGroup.get('filterFormArray')['controls'][i].get('options')).push(new UntypedFormGroup({
                  optionName: new UntypedFormControl(this.filterArray[i].options[j].display),
                  value: new UntypedFormControl(this.filterArray[i].options[j].value),
                  color: new UntypedFormControl(this.filterArray[i].options[j].color),
                  optionChecked: new UntypedFormControl(selectedValue ? true : false)
                })); 
                if (this.filterArray[i].type === "slider" && !this.emittedFilterData?.hasOwnProperty('price')&&(this.filterTemplate === 'filterTemplateTwo' || this.filterTemplate === 'filterTemplateThree')) {
                  this.sliderMinValue = this.filterArray[i].displayContent.start;
                  this.sliderMaxValue = this.filterArray[i].displayContent.end;
                }
                 if(_.isEmpty(this.emittedFilterData)){
                  this.sliderCheck=false
                 } 
                 if(this.emittedFilterData && selectedValue && this.filterArray[i].type ==='slider'){  
                  valueSet=true,this.sliderCheck=true
                   this.sliderMinValue = selectedValue.begin;
                   this.sliderMaxValue = selectedValue.end;
                  this.sliderFilterChange(i,selectedValue,true)
                }
                if ((this.filterTemplate === 'filterTemplateTwo' || this.filterTemplate === 'filterTemplateThree') && this.filterArray[i].type === 'slider' && this.isEditor) {
                  this.sliderMinValue = this.filterArray[i].options[0].value.begin;
                  this.sliderMaxValue = this.filterArray[i].options[0].value.end;
                  this.sliderStartValue = this.filterArray[i].options[0].value.begin;
                  this.sliderEndValue = this.filterArray[i].options[0].value.end;
                  this.clearFilter();
                }
                if (this.emittedFilterData && selectedValue) { 
                  valueSet = true;
                  if (this.filterArray[i].type === 'radio-option' && this.filterFormGroup && this.filterFormGroup.get('filterFormArray') && this.filterFormGroup.get('filterFormArray')['controls'] && this.filterFormGroup.get('filterFormArray')['controls'][i]) {
                    this.filterFormGroup.get('filterFormArray')['controls'][i].get('appliedFilters').setValue(this.filterFormGroup.get('filterFormArray')['controls'][i].get('options')['controls'][j].value);
                  }
                  this.filterChange(i, this.filterArray[i].type === 'radio-option' ? { value: { optionName: this.filterArray[i].options[j].display } } : { checked: true }, true)
                }
              }
            }
            if (this.filterArray[i].type === "date-picker" && this.filterFormGroup && this.filterFormGroup.get('filterFormArray') && this.filterFormGroup.get('filterFormArray')['controls'] && this.filterFormGroup.get('filterFormArray')['controls'][i]) {
              this.filterFormGroup.get('filterFormArray')['controls'][i].addControl(
                'date', new UntypedFormGroup({
                  begin: new UntypedFormControl(null, Validators.required),
                  end: new UntypedFormControl(null, Validators.required)
                })
              );
            }
          }

        }
      }
      if (!valueSet)
        this.showFilter = false;
    }
  }
  /**
   * Method which is used to apply and remove filters
   * @param id defines index of the filter type
   * @param event defines the event of the filter
   * @param setEmittedData defines the value set or not
   */
  filterChange(id: number, event: any, setEmittedData?: any): void {  
    if (event && this.filterFormGroup && this.filterFormGroup.get('filterFormArray') && this.filterFormGroup.get('filterFormArray')['controls'] &&
      this.filterFormGroup.get('filterFormArray')['controls'][id] && this.filterFormGroup.get('filterFormArray')['controls'][id].get('type')) { 
      if (this.filterFormGroup.get('filterFormArray')['controls'][id].get('type').value === 'radio-option') { 
        if (this.filterFormGroup.get('filterFormArray')['controls'][id].get('appliedFilters') && this.filterFormGroup.get('filterFormArray')['controls'][id].get('appliedFilters').value) { 
          this.filterFormGroup.get('filterFormArray')['controls'][id].get('appliedFilterCount').setValue(1);
          for (let filterOption of this.filterFormGroup.get('filterFormArray')['controls'][id].get('options')['controls']) {
            if (event.value.optionName === filterOption.get('optionName').value) { 
              filterOption.get('optionChecked').setValue(true);
            } else { 
              filterOption.get('optionChecked').setValue(false);
            }
          }
        }
      } else if (this.filterFormGroup.get('filterFormArray')['controls'][id].get('type').value === 'check-box') { 
        if (event.checked) {     
          this.filterFormGroup.get('filterFormArray')['controls'][id].get('appliedFilterCount').setValue(this.filterFormGroup.get('filterFormArray')['controls'][id].get('appliedFilterCount').value + 1);
        } else {
          this.filterFormGroup.get('filterFormArray')['controls'][id].get('appliedFilterCount').setValue(this.filterFormGroup.get('filterFormArray')['controls'][id].get('appliedFilterCount').value - 1);
        }
      }
      else if (this.filterFormGroup && this.filterFormGroup.get('filterFormArray') && this.filterFormGroup.get('filterFormArray')['controls'] && this.filterFormGroup.get('filterFormArray')['controls'][id] && this.filterFormGroup.get('filterFormArray')['controls'][id].get('type') && this.filterFormGroup.get('filterFormArray')['controls'][id].get('type').value && this.filterFormGroup.get('filterFormArray')['controls'][id].get('type').value === 'date-picker') { 
        if (!(this.filterFormGroup.get('filterFormArray')['controls'][id].get('date') && this.filterFormGroup.get('filterFormArray')['controls'][id].get('date').valid)) {
          return;
        }
        this.filterFormGroup.get('filterFormArray')['controls'][id].get('appliedFilterCount').setValue(1);
      }
      this.showOrHideFilter();
      this.objectFrame(setEmittedData);
    }
  }
  /**
   * Method which is used to apply and remove filters for Slider
   * @param id defines index of the filter type
   * @param event defines the event of the filter
   * @param setEmittedData defines the value set or not
   */
  sliderFilterChange(id:number,event:any,setEmittedData?: any):void{ 
    if (event && this.filterFormGroup && this.filterFormGroup.get('filterFormArray') && this.filterFormGroup.get('filterFormArray')['controls'] &&
    this.filterFormGroup.get('filterFormArray')['controls'][id] && this.filterFormGroup.get('filterFormArray')['controls'][id].get('type')){
      if (this.filterFormGroup.get('filterFormArray')['controls'][id].get('type').value === 'slider') {
        this.filterFormGroup.get('filterFormArray')['controls'][id].get('appliedFilterCount').setValue(1);
      }
      if (this.sliderMinValue == this.sliderStartValue && this.sliderMaxValue == this.sliderEndValue) {
        this.filterFormGroup.get('filterFormArray')['controls'][id].get('appliedFilterCount').setValue(0)
      }
    }
    this.showOrHideFilter()
    this.objectFrame(setEmittedData,event); 
  }
  /**
   * Method which is used to clear the specific/all filters
   * @param i defines the index of the filter array
   * @param j defines the index of the filter's options array
   */
  clearFilter(i?: number, j?: number): void {  
    if (i >= 0 && j >= 0) {
      this.filterFormGroup && this.filterFormGroup.get('filterFormArray') && this.filterFormGroup.get('filterFormArray')['controls'] && this.filterFormGroup.get('filterFormArray')['controls'][i] && this.filterFormGroup.get('filterFormArray')['controls'][i].get('options') && this.filterFormGroup.get('filterFormArray')['controls'][i].get('options')['controls'] && this.filterFormGroup.get('filterFormArray')['controls'][i].get('options')['controls'][j] && this.filterFormGroup.get('filterFormArray')['controls'][i].get('options')['controls'][j].get('optionChecked') && this.filterFormGroup.get('filterFormArray')['controls'][i].get('options')['controls'][j].get('optionChecked').setValue(false);
      this.filterFormGroup && this.filterFormGroup.get('filterFormArray') && this.filterFormGroup.get('filterFormArray')['controls'] && this.filterFormGroup.get('filterFormArray')['controls'][i] && this.filterFormGroup.get('filterFormArray')['controls'][i].get('appliedFilters') && this.filterFormGroup.get('filterFormArray')['controls'][i].get('appliedFilters').setValue(null);
      this.filterFormGroup && this.filterFormGroup.get('filterFormArray') && this.filterFormGroup.get('filterFormArray')['controls'] && this.filterFormGroup.get('filterFormArray')['controls'][i] && this.filterFormGroup.get('filterFormArray')['controls'][i].get('appliedFilterCount') && this.filterFormGroup.get('filterFormArray')['controls'][i].get('appliedFilterCount').setValue(this.filterFormGroup.get('filterFormArray')['controls'][i].get('appliedFilterCount').value - 1); 
    }
    else if (i >= 0) { 
      if (this.filterFormGroup && this.filterFormGroup.get('filterFormArray') && this.filterFormGroup.get('filterFormArray')['controls'] &&
        this.filterFormGroup.get('filterFormArray')['controls'][i] && this.filterFormGroup.get('filterFormArray')['controls'][i].get('type') &&
        this.filterFormGroup.get('filterFormArray')['controls'][i].get('type').value && this.filterFormGroup.get('filterFormArray')['controls'][i].get('type').value === 'date-picker') {
        this.filterFormGroup.get('filterFormArray')['controls'][i].get('date') && this.filterFormGroup.get('filterFormArray')['controls'][i].get('date')['controls'] && this.filterFormGroup.get('filterFormArray')['controls'][i].get('date')['controls'].begin && this.filterFormGroup.get('filterFormArray')['controls'][i].get('date')['controls'].begin.setValue(null);
        this.filterFormGroup.get('filterFormArray')['controls'][i].get('date') && this.filterFormGroup.get('filterFormArray')['controls'][i].get('date')['controls'] && this.filterFormGroup.get('filterFormArray')['controls'][i].get('date')['controls'].end && this.filterFormGroup.get('filterFormArray')['controls'][i].get('date')['controls'].end.setValue(null);
        this.filterFormGroup && this.filterFormGroup.get('filterFormArray') && this.filterFormGroup.get('filterFormArray')['controls'] && this.filterFormGroup.get('filterFormArray')['controls'][i] && this.filterFormGroup.get('filterFormArray')['controls'][i].get('appliedFilterCount') && this.filterFormGroup.get('filterFormArray')['controls'][i].get('appliedFilterCount').setValue(null);
        
      }
      if(this.filterFormGroup&&this.filterFormGroup.get('filterFormArray')&&this.filterFormGroup.get('filterFormArray')['controls']&&
         this.filterFormGroup.get('filterFormArray')['controls'][i].get('type').value && this.filterFormGroup.get('filterFormArray')['controls'][i].get('type').value==='slider'){
        if (this.filterTemplate === 'filterTemplateTwo' || this.filterTemplate === 'filterTemplateThree') {
          this.sliderMinValue = this.filterArray[i].displayContent.start;
          this.sliderMaxValue = this.filterArray[i].displayContent.end;
        }
          this.filterFormGroup && this.filterFormGroup.get('filterFormArray') && this.filterFormGroup.get('filterFormArray')['controls'] && this.filterFormGroup.get('filterFormArray')['controls'][i] && this.filterFormGroup.get('filterFormArray')['controls'][i].get('appliedFilterCount') && this.filterFormGroup.get('filterFormArray')['controls'][i].get('appliedFilterCount').setValue(this.filterFormGroup.get('filterFormArray')['controls'][i].get('appliedFilterCount').value - 1);
         }
    }
    else {
      for (let filter of this.filterFormGroup.get('filterFormArray')['controls']) {
        for (let filterOption of filter.get('options')['controls']) {
          filterOption.get('optionChecked').setValue(false);
        }
        filter.get('appliedFilters').setValue(null);
        filter.get('appliedFilterCount').setValue(0);  
        if(filter.get('type').value=='slider'){ 
          if (this.filterTemplate === 'filterTemplateTwo'|| this.filterTemplate === 'filterTemplateThree') {
            this.sliderMinValue = this.sliderStartValue;
            this.sliderMaxValue = this.sliderEndValue;
          }
        }
      } 
    } 
    this.showOrHideFilter();
    this.objectFrame();
  }

  /**
   * Method which is used to show/hide the clear filters section
   */
  showOrHideFilter(): void {
    if (this.filterFormGroup && this.filterFormGroup.get('filterFormArray') && this.filterFormGroup.get('filterFormArray')['controls'] && this.filterFormGroup.get('filterFormArray')['controls'].length) { 
      this.showFilter = this.filterFormGroup.get('filterFormArray')['controls'].find(data => data.get('appliedFilterCount') && data.get('appliedFilterCount').value) ? true : false;
    }
  }

  /**
   * Method used to call object frame method and emit the filter object .
   * called by sort option change.
   */
  sortChange(): void {
    this.expandPanel = false;
    this.objectFrame();
  }

  /**
   * Method used to frame the filter object and emit to the parent component.
   * @param fieldName defines the field name of the filter
   * @param filterObject  defines the filterobject of the filter
   * @param filterCount defines the filterCount of the filter
   * @returns  To  return the filterObject
   */
     objectFrameSlider(fieldName?:any,filterObject?:any,filterCount?:any,setEmittedData?:any,sliderEvent?:any):void{
      const price={begin:'',end:''} 
      if(filterCount==0){ 
        return filterObject
      }
      else if(setEmittedData && sliderEvent){ 
        filterObject.price[0]=sliderEvent
        this.sliderMinValue=sliderEvent.begin
        this.sliderMaxValue=sliderEvent.end
        return filterObject
      }
      else if(!setEmittedData){  
        if(!this.sliderCheck){
        filterObject[fieldName].push(price) 
      }  
      if(_.isEmpty(filterObject[fieldName])){
        filterObject[fieldName].push(price)  
      } 
        filterObject[fieldName][0].begin=+this.sliderMinValue
        filterObject[fieldName][0].end=+this.sliderMaxValue 
        return filterObject   
      } 
     
    }

  /**
   * Method used to frame the filter object and emit to the parent component.
   * @param setEmittedData defines the value set or not
   */
  objectFrame(setEmittedData?: any,sliderEvent?:any): void {
    const filterObject = {};
    this.filteredOptionsArray = [];
    if (this.sortOption && this.sortOption.value)
      filterObject['sort'] = this.sortOption.value.value;
    for (const filter of this.filterFormGroup.get('filterFormArray').value) {
      if (filter && filter.appliedFilterCount > 0) {
        if (filter.optionType === "Variant Options") {
          const options = [];
          for (const temp of filter.options) {
            if (temp && temp.optionChecked) {
              options.push(temp);
            }
          }
          if (options && options.length > 0) {
            this.filteredOptionsArray.push({ options: options });
          }
        }
        filterObject[filter.fieldName] = []; 
        for (const op of filter.options) {
          if (op.optionChecked) {
            if (filter.type === 'check-box') {
              filterObject[filter.fieldName].push(op.value);
            }
            else {
              filterObject[filter.fieldName][0] = op.value;
            }
          }
        } 
      }
      if ((filter && filter.type === 'date-picker') && (filter && filter.date && filter.date.begin && filter.date.end)) {
        filterObject[filter.fieldName] = [filter.date];
      }
      if(filter&&filter.fieldName ==='price'&&filter.type ==='slider'){  
         this.objectFrameSlider(filter.fieldName,filterObject,filter.appliedFilterCount,setEmittedData,sliderEvent);
      }
    }
    this.variantCombination = [];
    this.onFindVariantCombination(0, '');
    if (this.variantCombination && this.variantCombination.length) { 
      filterObject['variantObj'] = this.variantCombination;
    }  
    if (!setEmittedData) { 
      this.filterResult.emit(filterObject); 
    }
  }
  /**
* Method used to close bottom sheet.
*/
  closeTemplateSheetMenu(): void {
    this.bottomSheetRef.dismiss();
  }
  /**
   * Function used to create the combination of options
   * @param index index of the options
   * @param optionName currently created combination name based of option value ids
   */
  onFindVariantCombination(index: number, optionName: string): void {
    if (this.filteredOptionsArray && this.filteredOptionsArray[index] && this.filteredOptionsArray[index].options && this.filteredOptionsArray[index].options.length) {
      for (let filterOption of this.filteredOptionsArray[index].options) {
        const name = optionName + (optionName ? ',' : '') + (filterOption && filterOption.value);
        if (index !== (this.filteredOptionsArray && this.filteredOptionsArray.length - 1)) {
          this.onFindVariantCombination(index + 1, name);
        } else {
          this.variantCombination.push(name);
        }
      }
    }
  }
  /**
   * Method used to assign Sider Minmum value
   * @param event defines the event for the filter
   */
minValue(event:any){ 
  this.sliderMinValue=event.target.value 
}
/**
 * Method used to assign  Slider Maximun Value
 * @param event defines the event for the filter
 */
maxValue(event:any){ 
  this.sliderMaxValue=event.target.value 
}
  /**
   * Method used to get template ref for a particular template.
   * @param design holds the template name.
   * @returns template ref for a particular template name.
   */
  getTemplateRefForFilter(design: string): TemplateRef<any> {
    return this[design];
  }
  /**
 * Method used to change and store opened content index.
 * @param index holds content index.
 */
  clicking(index: number):void {
    this.filterExapansionPanel[index] = !this.filterExapansionPanel[index];
  }
  /**
 * Method used to change and store opened sort content index.
 */
  isSortOpenClicked():void {
    this.isSortOpen = !this.isSortOpen;
  }  
}
