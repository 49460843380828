/**
 * Common constant files.
 */
export class CommonAdminShardConstants {
    /**
    * Variable transactionHeadings used to store the transaction headings.
    */
    transactionHeadings = {
        transactionHistory: "Transaction History",
        transactionDescription: "Look at your transaction history here...",
        transactionDocLink: 'https://help.getzenbasket.com/transaction_history_logs.html'

    }
    /**
     * Constants used for show the template error messages
     */
    templateErrorMessage: any = {
        "ERROR": {
            "template": {
                "required": "Template name is required",
                "maxlength": "Maximum character limit reached.",
                "alreadyExists": "Template name already exists.",
                "pattern": 'should not allow space!',
            }
        }
    }
    /**To display the error messages */
    errorMessage = {
        mandatoryFieldFill: 'Please fill the Mandatory fields properly!',
        titleAlreadyExists: 'Configuration Question already exists!',
        oneFormArray: "Atleast one Configuration control required,If you don't need any questions, Please uncheck the checkbox above.",
        oneValueArray: 'Atleast one value control is required',
        deleteConfigurationQuestionConfirmation: 'Are you sure you want to delete this question?',
        errorMessage: "Oops !! Something went wrong.. Please try again later or Contact Administrator",
        imgErrorMessage: "Make sure to upload a valid image and try again.",
        imgSizeErrorMessage: "Make sure to upload a file less than 2 MB and try again."
    };

    /**
    * variable which is used to store dialog type.
    */
    dialogType = { alert: 'alert', confirmation: 'confirmation', information: 'information', success: 'success', failure: 'failure' };
    /**
    * variable dialogMessages used to store constant dialog message values.
    */
    dialogMessages = {
        noChangeAlert: "You have no changes to save",
        noDataExport: 'No data to export',
        storeGetsFailed: " Failed to get store names",
        getFailed: "Failed to get data",
        failedToLoad: 'Failed to load store details',
        titleRemove: 'Title should contain at least one value',
        valueRemove: 'Should contain atleast one value',
        titleExceeds: 'You reach maximum length of title',
        FormValid: 'Please fill out the previous details!',
        getOnePlugin: 'Failed to get Product size chart plugin details',
        getOneMail: "Failed to get mail log details",
        failureHeader: 'Failure',
        updateFailed: "Failed to update plugin details",
        getPluginFailed: "Failed to get plugin details",
    };
    mailLog = {
        title: 'Mail Logs',
        description: 'View mail logs here'
    };
    shopappUsers = {
        title: 'ShopApp Customers',
        description: 'View shopapp users here'
    }
    columnsForMailLog = [
        { column: 'templateName', suffixText: 'Template name', fxFlex: 22, type: 'Text', sort: false },
        { column: 'from', suffixText: 'From', type: 'Text', fxFlex: 20, sort: false },
        { column: 'to', suffixText: 'To ', type: 'Text', fxFlex: 20, sort: false },
        { column: 'status', suffixText: 'Status', type: 'Chip', fxFlex: 10, backgroundColor: 'green', classCondition: { 'Failed': 'draftRedChip' }, sort: false },
        { column: 'date', suffixText: 'Date', type: 'Date', fxFlex: 10, sort: false },
        { column: 'time', suffixText: 'Time', type: 'Time', fxFlex: 10, sort: false },

    ];
    columnsForMailLog1 = [
        { column: 'from', suffixText: 'From', type: 'Text', fxFlex: 25, sort: false },
        { column: 'to', suffixText: 'To ', type: 'Text', fxFlex: 25, sort: false },
        { column: 'status', suffixText: 'Status', type: 'Chip', backgroundColor: 'green', fxFlex: 13, classCondition: { 'Failed': 'draftRedChip' }, sort: false },
        { column: 'date', suffixText: 'Date', type: 'Date', fxFlex: 13, sort: false },
        { column: 'time', suffixText: 'Time', type: 'Time', fxFlex: 13, sort: false },

    ];
    /**
      * variable which is used to store all the messages to be displayed.
      */
    message = {
        cancel: "Are you sure you want to close?",
    };

    /**
* Variable which is used for paginator
* @type {object}
*/
    paginator = {
        pageLengthOptions: [5, 10, 20, 30, 40],
        defaultPageSize: 10
    };


    columnsForShopappUsers = [
        { column: 'name', suffixText: 'Name', type: 'Text' },
        { column: 'status', type: 'Chip', suffixText: 'Status', backgroundColor: 'green', classCondition: { 'InActive': 'draftRedChip', 'Pending': 'orangeChip' } },
        // { column: 'isWholesale', type: 'Chip', suffixText: 'Wholesale Status', classCondition: { 'Declined': 'inActiveChip', 'Approved': 'requiredChip' } },
        // // { column: 'email', suffixText: 'Email', type: 'Text' },
        // { column: 'roles', suffixText: 'Type', type: 'Chip', classCondition: { 'Customer': 'orangeChip', 'Guest': '', 'Wholesale': 'wholeSaleChip' } },
        { column: 'phone', suffixText: 'Phone', type: 'Text' },
        { column: 'createdAt', type: 'Date', dateFormat: 'MMM d y ', suffixText: 'Created at' },

        // { column: 'acceptsMarketing', suffixText: 'Allow Marketing', type: 'Text' },
    ];
    revenueReportCalculationInfo = [
        {
            key: "Gross Sales",
            value: "Product Price x Quantity (before taxes for both inclusive and exclusive, refunds, shipping, discounts)"
        },
        {
            key: "Net Sales",
            value: "Gross sales - Discounts - Gift Card Amount"
        },
        {
            key: "Total Sales",
            value: "Gross Sale - Discounts - Gift Card Amount - Refunds + Taxes + Delivery Charges + Extra Amount"
        },
        {
            key: "Average Order Value",
            value: "(Gross Sale - Discounts - Gift card Amount)/ No Of Orders"
        },
        {
            key: "Pending Amount",
            value: "Amount needs to be paid by the customer"
        },
        {
            key: "Received Amount",
            value: "Amount paid by the customer"
        },
        {
            key: "Discounts",
            value: "Other discounts + Coupon discounts + Loyalty Points"
        },
        {
            key: "Refunds",
            value: "Amount Refund + Gift Card Refund + Loyalty Points Refund"
        },
        {
            key: "Gift Card Amount",
            value: "Total amount spent using gift card."
        },
        {
            key: "Tax",
            value: "Both inclusive and exclusive tax"
        },
        {
            key: "Delivery Charge",
            value: "Delivery Charge + Pickup Charge"
        },
        {
            key: "Extra Amount",
            value: "Tip + Handling Fee + Additional charge + COD amount"
        },
       ]

    /**
     * Variable offerReport used to store the different offer report names.
     */
    offerReport = {
        manual: "Manual Offer Report",
        manualDescription: "Generates report based on manual offer applied on orders",
        automatic: "Automatic Offer Report",
        automaticDescription: "Generates report based on automatic offer applied on orders",
        buyXgetY: "Buy X Get Y Offer Report",
        buyXgetYDescription: "Generates report based on buy x get y offer applied on orders",
        payXgetFree: "Pay X Get Free Offer Report",
        payXgetFreeDescription: "Generates report based on pay x get free offer applied on orders"

    }
    /**
     * Variable offerReport used to store the different offer report names.
     */
    reportData = {
        allLocation: "All",
        superadmin: "SUPERADMIN",
        lastSevenDays: "Last 7 Days",
        lastThirtyDays: "Last 30 Days",
        month: "This Month",
        year: "This Year",
        lifeTime: "Lifetime",
        yesterday: "Yesterday"
    }
    /**
     * Variable for subscribed plugin list
     */
    subscribedPlugin = {
        title: "Subscribed Plugins",
        description: 'List of subscribed plugins'
    }
}


/**
 * Interface which is used to have properties of common card list
 * checkBox - To decide whether checkbox to display or not and it is optional property
 * profileImage - To decide whether profile circle to display or not and its optional one
 * dateOnly - To display all columns as data
 * columns - To decide number of columns to display data. Its mandatory
 * rowElementClicked - To card element clicked. Its also optional
 * globalSearch - To decide search box needed or not
 */
export interface ListProperties {
    checkBox?: boolean,
    profileImage?: boolean,
    columns: number,
    dataOnly?: boolean,
    rowElementClicked?: boolean,
    globalSearch?: boolean,
    suffixText?: boolean,
    isImageDisplay?: boolean,
    inputAppearance?: string,
    serverSidePagination?: boolean,
    displayTopRightButtons?: boolean,
    localSearchFields?: any,
    cardMinHeight?: string,
    searchPlaceHolder?: string,
    cardHeaderFontColor?: string,
    isLocalPagination?: boolean,
    badgeCondition?: { field?: string, value?: any, displayText?: string },
    cardBorderClass?: string,
    cardBorderCondition?: { field: string, condition: any },
    noPagination?: boolean,
    radioButton?: boolean,
    toggleConfirmationMessage?: { on: string, off: string },
    disableLocalSearch?: boolean,
    noRecordFound?: { image?: string, text?: string },
    isNewImage?: { isNew: boolean, url: string, text: string },
    customMoreActionIcon?: string,
    customMatMenuTrigger?: string,
    customMatMenuIcon?: string,
    customMatMenuIsDisplay?: boolean,
}
/**
* Interface which is used to have action icon details of common card list
* iconName - To get icon name to display in card list
* toolTip - To display tool tip of displayed icon
* method - To call a function while click action icon
*/
export interface ActionIcon {
    iconName: string,
    toolTip: string,
    method: string,
    field?: string,
    value?: string | boolean | number,
    iconColor?: string,

}
/**
* Interface which is used to have columns reference of common card list
* column - To get required columns name to display
* type - To get type of column
* dateFormat - To get a desired date format to display and it is optional
* suffixText -To get suffix text to displayed columns
* isTitleCase -To set title case pipe for particular column Its optional one
*/
export interface ColumnsRef {
    column: string,
    type: string,
    dateFormat?: string,
    suffixText?: string,
    isTitleCase?: boolean,
    imageCount?: number,
    icon?: string,
    iconToolTip?: string,
    class?: string,
    subText?: any,
    backgroundColor?: string,
    currency?: string,
    style?: string,
    isFloat?: boolean,
    format?: string,
    iconColor?: string,
    classCondition?: any,
    iconCondition?: any,
    toolTipCondition?: any,
    fxFlex?: any,
    header?: any,
    sort?: any,
    toggleCondition?: any,
    isColumnClick?: boolean,
    isToggleDisabled?: boolean,
    customRibbon?: {
        isDisplay: boolean,
        ribbonText: string,
        field: any,
        value: any,
        backgroundColor?: string,
        isAlignLeft?: boolean
        subCustomRibbon?: { // Include the subCustomRibbon property here
            isDisplay: boolean;
            ribbonText: string;
            field: any;
            value: any;
            backgroundColor?: string;
            isAlignLeft?: boolean
        };
    },
    imageSize?: string,
}
/**
 * Constant messages fr excel constant
 */
export class excelConstant {
    /**Shows the import count of the sheet */
    importCount = 2500;
    /**Hols the column name of for validations */
    validationColumnName = {
        ContactNumber: 'contactNumber',
        ZipCode: 'zipCode',
        // Email:'email'
    };
    /**Holds the excel sheet column validation constants */
    excelColumnValidation = [
        {
            label: 'contactNumber',
            type: 'custom',
            allowBlank: false,
            visible: true,
            showInputMessage: true,
            showErrorMessage: true,
            errorStyle: 'error',
            errorTitle: 'Error',
            error: 'Invalid contact number format',
            promptTitle: 'Contact Number format',
            prompt: 'Should be less than 12 digit',
        },
        {
            label: 'zipCode',
            type: 'custom',
            allowBlank: false,
            visible: true,
            showInputMessage: true,
            showErrorMessage: true,
            errorStyle: 'error',
            errorTitle: 'Error',
            error: 'Invalid zipcode format',
            promptTitle: 'ZipCode format',
            prompt: 'Should be 6 digits',
        },
        //   {
        //     label: 'email',
        //     type: 'custom',
        //     allowBlank: false,
        //     visible: true,
        //     showInputMessage: true,
        //     showErrorMessage: true,
        //     errorStyle: 'error',
        //     errorTitle: 'Error',
        //     error: 'Invalid Email format',
        //     promptTitle: 'Email format',
        //     prompt: 'Should be in email format',
        //   },
    ];

    /**Holds the possible export options */
    exportOptions = [
        {
            id: 1,
            name: "Export only selected data"
        },
        {
            id: 2,
            name: "Export only filtered data"
        },
        {
            id: 3,
            name: "Export all the data in current page"
        },
        {
            id: 4,
            name: "Export all the data"
        }
    ];
};






