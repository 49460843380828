<div class="main-container">
  <div class="editor-toolbar">
    <button [disabled]="isDisable" matTooltipPosition="above" matTooltip="Bold (Ctrl+B)" (click)="addTag('b',isBold)" [class.active]="isBold">
      <mat-icon>format_bold</mat-icon>
    </button>
    <button [disabled]="isDisable" matTooltipPosition="above" matTooltip="Italic (Ctrl+I)" (click)="addTag('i',isItalic)" [class.active]="isItalic">
      <mat-icon>format_italic</mat-icon>
    </button>
    <button [disabled]="isDisable" matTooltipPosition="above" matTooltip="Underline (Ctrl+U)" (click)="addTag('u',isUnderline)" [class.active]="isUnderline">
      <mat-icon>format_underlined</mat-icon>
    </button>
    <button [disabled]="isDisable" matTooltipPosition="above" matTooltip="Strike" (click)="addTag('s',isStrike)" [class.active]="isStrike">
      <mat-icon>strikethrough_s</mat-icon>
    </button>
    <button [disabled]="isDisable" matTooltipPosition="above" matTooltip="Unorder list" (click)="toggleListStyle('unordered')">
      <span class="material-symbols-outlined">format_list_bulleted</span>
    </button>
    <button [disabled]="isDisable" matTooltipPosition="above" matTooltip="Order list" (click)="toggleListStyle('ordered')">
      <span class="material-symbols-outlined">format_list_numbered</span>
    </button>
    <button [disabled]="isDisable" matTooltipPosition="above" matTooltip="Align left" (click)="setAlignment('left')">
      <mat-icon>format_align_left</mat-icon>
    </button>
    <button [disabled]="isDisable" matTooltipPosition="above" matTooltip="Align center" (click)="setAlignment('center')">
      <mat-icon>format_align_center</mat-icon>
    </button>
    <button [disabled]="isDisable" matTooltipPosition="above" matTooltip="Align right" (click)="setAlignment('right')">
      <mat-icon>format_align_right</mat-icon>
    </button>
    <button [disabled]="isDisable" matTooltipPosition="above" matTooltip="Align justify" (click)="setAlignment('justify')">
      <mat-icon>format_align_justify</mat-icon>
    </button>
    <button [disabled]="isDisable" matTooltipPosition="above" matTooltip="Font family" [matMenuTriggerFor]="fontFamilyMenu">
      <span class="material-symbols-outlined">brand_family</span>
    </button>
    <mat-menu #fontFamilyMenu="matMenu">
      <button mat-menu-item *ngFor="let option of fontFamily"
        (click)="applyTextStyle(option.value,undefined,undefined)">
        <span [ngStyle]="{'font-family': option.value,'font-size':16+'px'}">
          {{ option.name }}</span>
      </button>
    </mat-menu>
    <button [disabled]="isDisable" matTooltipPosition="above" matTooltip="Font size" [matMenuTriggerFor]="fontSizeMenu">
      <span class="material-symbols-outlined">format_size</span>
    </button>
    <mat-menu #fontSizeMenu="matMenu">
      <ng-container *ngFor="let option of fontSizeOptions">
        <button mat-menu-item (click)="applyTextStyle(undefined,option.value,option.weight)">
          <span [ngStyle]="{'font-size': option.value, 'font-weight': option.name !== 'Paragraph' ? 'bold' : 'normal'}">
            {{ option.name }}</span>
        </button>
      </ng-container>
    </mat-menu>
    <button [disabled]="isDisable" matTooltipPosition="above" matTooltip="Font color" (click)="triggerColorPicker(false)">
      <mat-icon>format_color_text</mat-icon>
      <input class="color-input" #textColorPicker (colorPickerSelect)="changeColor($event,false)" 
      [cpOKButton]="true"
      [cpPosition]="'left'"
      [cpCancelButton]="true"
      [colorPicker]="'#000'">
    </button>
    <button [disabled]="isDisable" matTooltipPosition="above" matTooltip="Font background color" (click)="triggerColorPicker(true)">
      <mat-icon>format_color_fill</mat-icon>
      <input class="color-input" #textBgColorPicker (colorPickerSelect)="changeColor($event,true)" 
      [cpOKButton]="true"
      [cpPosition]="'left'"
      [cpCancelButton]="true"
      [colorPicker]="'#000'">
    </button>
    <button [disabled]="isDisable" matTooltipPosition="above" matTooltip="Link" (click)="createLink()" [class.active]="isLink">
      <mat-icon>insert_link</mat-icon>
    </button>
    <button [disabled]="isDisable" matTooltipPosition="above" matTooltip="Undo" (click)="undo()">
      <mat-icon>undo</mat-icon>
    </button>
    <button [disabled]="isDisable" matTooltipPosition="above" matTooltip="Redo" (click)="redo()">
      <mat-icon>redo</mat-icon>
    </button>
    <button [disabled]="isDisable" matTooltipPosition="above" matTooltip="Clear All Formatting" (click)="clearAllFormatting()">
      <mat-icon>clear</mat-icon>
    </button>
  </div>
  <form [formGroup]="form">
    <div class="editor-content" contenteditable="true" #editor contentEditableModel formControlName="editorContent"
      (contentClicked)="emitData()" (contentChanged)="emitData()"></div>
  </form>
</div>

<ng-template #linkDialog>
  <div class="dialog-container">
    <h3>Enter the Url</h3>
    <mat-form-field appearance="outline">
      <input matInput placeholder="Type Url here" #linkInput [value]="isEditLink?hrefValue:null">
    </mat-form-field>
    <div class="button-container">
      <button *ngIf="!isEditLink" mat-stroked-button color="primary" matDialogClose>Close</button>
      <button *ngIf="isEditLink" mat-stroked-button color="primary" mat-dialog-close="unlink">Unlink
        <mat-icon>link_off</mat-icon></button>
      <button [disabled]="linkInput.value.length<1" mat-raised-button color="primary"
        [mat-dialog-close]="isEditLink?{link:linkInput.value,isEdit:true}:{link:linkInput.value,isEdit:false}">
        {{isEditLink?'Edit Link':'Add Link'}}
      </button>
    </div>
  </div>
</ng-template>