import { Inject, Injectable } from '@angular/core';
import { HttpRoutingService } from './httpRouting.service';
import { catchError, retry } from 'rxjs/operators';
import { of, throwError } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
/**
 * service which is used to load translation dynamically for storefront
 * Author PRAVEEN A
 */
@Injectable({
  providedIn: 'root'
})
export class LoadTranslationService {
  /**
   * Variable used to store environment details
   */
  environment: { [key: string]: string };
  /**
   * variable that holds the translated values
   */
  translationObj: { [key: string]: any } = {};
  /**
   * Stores the store language code
   */
  languageCode: string;
  /**
   * Variable that stores the store language list
   */
  languageArray: { langCode: string, langName: string }[];
  /**
   * Variable that is used for navigation
   */
  isNavigateWithLangCode: boolean;
  /**
   * Variable that is used to store the translation path
   */
  translationFolder: string[] = [];
  /**
   * service constructor which is used to load translation file
   * @param environment to get the variables of storefront environment
   * @param httpRoutingService to use the functions in httpRoutingService Service.
   */
  constructor(@Inject('environment') environment, private httpRoutingService: HttpRoutingService) {
    this.environment = environment;
  }
  /**
   * Method which is used to load multiple translation file 
   * @param files filepath
   */
  multiLoadTranslation(files: string[]): void {
    if (files && files.length) {
      files.forEach(file => {
        const value = file.toUpperCase();
        this.loadTranslationFiles(value);
      });
    }
  }
  /**
   * Method which is used load translations
   * @param files filepath
   * @param lang language
   */
  loadTranslationFiles(file: string): Promise<any> {
    return new Promise((resolve, reject) => {
      const lang = this.getLanguageCode();
      const folderName = file.toLowerCase();
      if (!this.translationFolder.includes(folderName)) {
        this.translationFolder.push(folderName);
        // const translationPath = './assets/i18n/' + folderName + '/' + lang + '.json';
        const translationPath = this.environment.AWSImageUrl + 'i18n/' + folderName + '/' + lang + '.json';

        this.httpRoutingService.getJsonData(translationPath).pipe(
          catchError((error) => {
            if (error.status === 0 || error instanceof HttpErrorResponse) {
              // If it's any network error, including CORS, do not retry
              return throwError(() => new Error(error)); // Throw the error without retrying
            }
            if (lang !== 'en') {
              // const retryApiPath = './assets/i18n/' + folderName + '/en.json';
              const retryApiPath = this.environment?.AWSImageUrl + 'i18n/' + folderName + '/en.json';
              return this.httpRoutingService.getJsonData(retryApiPath).pipe(retry(1));
            }
            return of(false);
          })
        ).subscribe({
          next: (translations) => {
            if (translations) {
              this.translationObj[file] = translations;
            }
            resolve(translations); // Resolve the promise with the translations
          },
          error: (error) => {
            console.error('Error loading translations', error);
            reject(error); // Reject the promise if there's an error
          }
        });
      } else {
        // If the folder is already included, resolve immediately with the existing translation
        resolve(this.translationObj[file]);
      }
    });
  }
  /**
   * method use to get the translated content for themes
   */
  async getTranslationFiles(file: string) {
    const fileName = file.toUpperCase();
    if (this.translationObj[fileName]) {
      return this.translationObj[fileName];
    }
    else {
      const result = await this.loadTranslationFiles(fileName);
      return result;
    }
  }
  /**
   * Method used to get the single translated value
   * @param file file path
   * @returns translated value
   */
  getTranslation(key: string): string {
    const keys = key?.includes('.') ? key.split('.') : [];
    const value = keys[0] ? this.translationObj[keys[0]] : null;
    if (value !== undefined && value !== null) {
      return keys[1] ? value[keys[1]] ?? value : value;
    }
    return key;
  }
  /**
   * Method used for the pipe to get the translation object
   * @returns translation object
   */
  getTranslationObj(): { [key: string]: any } {
    return this.translationObj;
  }
  /**
   * Method used to get the language code from the local storage
   * @returns language code
   */
  private getLanguageCode(): string {
    const langCode = localStorage.getItem('language') ?? 'en';
    return langCode;
  }
  /**
   * method to change the translation object when the language changes
  */
  changeTranslationObj(): void {
    const keys = Object.keys(this.translationObj);
    this.translationFolder = [];
    this.multiLoadTranslation(keys);
  }

  /**
   * AUTHOR: SUJIN S S (CEN250) - FF
   * Method used to translate static messages with dynamic contents.
   * @param key contains key for language JSON.
   * @param values contains dynamic contents.
   * @returns translated text.
   */
  dynamicMessageTranslation(key: any, values: any): string {
    let msg: string;
    if (key) {
      msg = this.getTranslation(key);
      if (msg && values && values.length) {
        values.forEach((res: any, index: number) => msg = (typeof msg == 'string') ? msg.replace('${' + index + '}', res) : msg);
      }
    }
    return msg ?? null;
  }
}
