/**
 * Component used to display social share buttons
 * AUTHOR: SUJIN S S (CEN250)
 */
import { AfterViewInit, ChangeDetectorRef, Component, Input } from '@angular/core';
import { DialogService } from '@phase-ii/common';

/**
 * Component used to display social share buttons
 */
@Component({
  selector: 'phase-ii-share-buttons',
  templateUrl: './share-buttons.component.html',
  styleUrls: ['./share-buttons.component.scss'],
})
export class ShareButtonsComponent implements AfterViewInit {
  /**
   * Variable used to get social share icons.
   */
  @Input() include: any;
  /**
   * Variable used to check share icon text.
   */
  @Input() showText: any;
  /**
   * Variable used to get url.
   */
  @Input() url: any;
  /**
   * Variable used to get title.
   */
  @Input() title: any;
  /**
   * Variable used to get description.
   */
  @Input() description: any;
  /**
   * Variable used to get image.
   */
  @Input() image: any;

  /**
   * Component constructor which is used to inject the required services.
   * @param changeDetector detect the changes that occurs.
   */
  constructor(
    private changeDetector: ChangeDetectorRef,
    private dialogService: DialogService
  ) { }

  /**
   * Method used to capitalize the first letter of social share button text. 
   * @param sentence holds button text.
   */
  capitalizeFirstLetter(sentence: string): string {
    if (sentence && sentence.length) {
      return sentence.charAt(0).toUpperCase() + sentence.slice(1);
    }
    return '';
  }

  /**
   * Component AfterViewInit life cycle hook.
   */
  ngAfterViewInit(): void {
    this.changeDetector.detectChanges();
  }

  /**
   * Method used to open snackbar while clicking copy.
   * @param button holds button text.
   */
  onButtonClick(button: string): void {
    if (button === 'copy') {
      this.dialogService.openSnackBar('Copied to clipboard');
    }
  }
}
