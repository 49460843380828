import { DatePipe } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { DialogService } from 'libs/common/src/lib/services/dialog.service';
import { ColumnsRef, CommonConstants, Heading, ListProperties, PaginationService } from '@phase-ii/shared';
import { Subscription } from 'rxjs';
import { PayoutLogConstants } from '../../constants/payout-logs-constants';
import { PayoutLogsService } from '../../services/payout-logs.service';
import { AffiliateAuthService } from '../../../auth/services/auth.service';

@Component({
  selector: 'phase-ii-payout-logs-list',
  templateUrl: './payout-logs-list.component.html',
  styleUrls: ['./payout-logs-list.component.scss'],
})
export class PayoutLogsListComponent implements OnInit, OnDestroy {
  /**
   * Variables for currency symbol.
   */
  currencySymbol: any;
  /*
  * Subscription object to manage and unsubscribe from RxJS subscriptions.
  * It is initialized as a new instance of the Subscription class.
  */
  subscriptionObj: Subscription = new Subscription();
  /*
  * Constants for Payout Logs. An instance of the PayoutLogConstants class is created to encapsulate related constants.
  */
  payoutLogConstants: PayoutLogConstants = new PayoutLogConstants();
  /*
  * Common constants used in the component. An instance of the CommonConstants class is created for reusable constants.
  */
  commonConstant = new CommonConstants();
  /**
 * Details for header component
 */
  pageDetails: {
    userId: any,
    heading: Heading,
    columnRef: ColumnsRef[],
    isLoader: boolean,
    listLoader: boolean
  } = {
      userId: null,
      heading: null,
      columnRef: null,
      isLoader: false,
      listLoader: false
    };
  /**
   * Variable which is used to store property needed property of card list
   * @type {ListProperties}
   */
  listProperty: ListProperties = {
    columns: 5,
    isNewImage: {
      isNew: false,
      url: './assets/Payout-log.png',
      text: 'No payout logs found',
    },
    cardBorderClass: 'greenBorder',
    cardBorderCondition: {
      field: 'status',
      condition: {
        'Yet to Begin': "pendingBorder",
        'Payment Initiated': "purpleBorder",
        'Payment Completed': "greenBorder"
      }
    }
  };
  /**
   * Object which is used for pagination details.
   */
  paginationDetails: {
    offset: number,
    pageSize: number;
    itemsCount?: number;
    limit?: number;
  } = {
      pageSize: new CommonConstants().paginator.defaultPageSize,
      offset: 0,
      itemsCount: null,
      limit: 10
    };

  /*
  * Constructor of the PayoutLogsListComponent class.
  * It takes in several Angular services as dependencies:
  * - httpRoutingService: Service for handling HTTP routing and requests.
  * - paginationService: Service for managing pagination functionality.
  * - datePipe: Angular's built-in service for date formatting.
  * - dialogService: Service for displaying dialog messages.
  * These services are injected into the component for use throughout its lifecycle.
  */
  constructor(
    private paginationService: PaginationService,
    private datePipe: DatePipe,
    private dialogService: DialogService,
    private payoutLogsService: PayoutLogsService,
    private authService: AffiliateAuthService,
  ) { }

  /*
   * Lifecycle hook called after Angular has initialized all data-bound properties.
   * Initializes component properties, sets up the initial state, and fetches data.
   */
  ngOnInit(): void {
    this.pageDetails.isLoader = true;
    this.pageDetails.heading = {
      title: 'Payout Logs List',
      description: '',
    }
    this.pageDetails.columnRef = [
      { column: 'totalAmount', type: 'Text', suffixText: 'Amount' },
      { column: 'transactionId', type: 'Text', suffixText: 'Transaction ID' },
      { column: 'createdAt', type: 'Text', suffixText: 'Requested Date' },
      { column: 'updatedAt', type: 'Text', suffixText: 'Credited Date' },
      { column: 'status', type: 'Chip', suffixText: 'Status', classCondition: { "Yet to Begin": "orangeChip", "Payment Initiated": "purpleChip", 'Payment Completed': "greenChip" } }
    ];
    this.subscriptionObj.add(this.authService.user.subscribe((res: any) => {
      if (res) {
        this.currencySymbol = res.currencyData && res.currencyData.currency && res.currencyData.currency.currencySymbol;
        this.getPayoutLogsList(0, 2 * this.paginationDetails.pageSize, true);
      }
    }, (err) => {
      this.dialogService.dialogMethod(this.payoutLogConstants.failureMessage, this.commonConstant.dialogType.failure, true);
    }
    ))
  }

  /**
  * Fetches the Payout Logs list from the server.
  * @param offset - Offset for pagination
  * @param limit - Limit for pagination
  * @param isNew - Indicates whether it's a new fetch
  */
  getPayoutLogsList(offset?: number, limit?: number, isNew?: boolean) {
    this.pageDetails.listLoader = true;
    const data = {
      status: 'Payment Completed',
      offset: offset ? offset : 0,
      limit: limit ? limit : 5
    }
    this.subscriptionObj.add(
      this.payoutLogsService.getPayoutLogsListData(data).subscribe((res: any) => {
        if (res && res.payoutLogDetails && res.payoutLogDetails.rows) {
          res.payoutLogDetails.rows.forEach(element => {
            if (element) {
              element['totalAmount'] = this.currencySymbol + parseFloat(element.totalAmount).toFixed(2);
              element['createdAt'] = this.datePipe.transform(element.createdAt, 'yyyy-MM-dd HH:mm:ss');
              element['updatedAt'] = this.datePipe.transform(element.updatedAt, 'yyyy-MM-dd HH:mm:ss');
            }
          });
          this.paginationDetails.itemsCount = res.payoutLogDetails.count;
          this.listProperty.isNewImage.isNew = res.payoutLogDetails.rows == 0 ? true : false;
          this.paginationService.listPaginatorData.next({
            new: isNew, rows: res.payoutLogDetails.rows, count: this.paginationDetails.itemsCount
          });
          this.pageDetails.listLoader = false;
          this.pageDetails.isLoader = false;
        } else {
          this.pageDetails.listLoader = false;
          this.pageDetails.isLoader = false;
          this.dialogService.dialogMethod(this.payoutLogConstants.failureMessage, this.commonConstant.dialogType.failure, true);
        }
      }, (err) => {
        if (err) {
          this.pageDetails.listLoader = false;
          this.pageDetails.isLoader = false;
          this.dialogService.dialogMethod(this.payoutLogConstants.failureMessage, this.commonConstant.dialogType.failure, true);
        }
      })
    );
  }

  /**
 * Handles pagination changes and fetches the corresponding data.
 * @param event - Event containing pagination details
 */
  getChangedData(event: any): void {
    this.paginationDetails.offset = event && event.offset;
    this.paginationDetails.limit = event && event.limit;
    this.getPayoutLogsList(event.offset, event.limit, false);
  }

  /*
   * Lifecycle hook called just before Angular destroys the component.
   * Unsubscribes from RxJS subscriptions to prevent memory leaks.
   */
  ngOnDestroy() {
    if (this.subscriptionObj) {
      this.subscriptionObj.unsubscribe();
      this.paginationService.listPaginatorData.next(null);
    }
  }
}