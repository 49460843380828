import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Location } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { filter, mergeMap } from 'rxjs/operators';
import { AuthConstants } from '../../constant/auth-constant';
import { AuthService } from '../../services/auth.service';
import { CustomAsyncValidatorService } from '../../services/customAsyncValidators.service';
import { CustomValidatorService } from '../../services/customValidators.service';
import { DialogService } from 'libs/common/src/lib/services/dialog.service';
import { SharedService } from '@phase-ii/shared-theme';
/**
 * Component which is used to change the password.
 */
@Component({
  selector: 'phase-ii-common-change-password',
  templateUrl: './common-change-password.component.html',
  styleUrls: ['./common-change-password.component.scss']
})
export class CommonChangePasswordComponent extends AuthConstants implements OnInit, OnDestroy {
  /**
 * Variable which is used to store the campaign group details
 * @type {FormGroup}
 */
  changePasswordForm: UntypedFormGroup;
  /**
   * Varable which is used to handle show or hide the password.
   * @type{object}}
   */
  showPassword: {
    showCurrentPassword: boolean,
    showNewPassword: boolean,
    showConfirmPassword: boolean
  } = {
      showCurrentPassword: false,
      showNewPassword: false,
      showConfirmPassword: false
    };

  /**
   * Variable which is used to subscribe and unsubscribe
   * @type{Subscription}
   */
  subscriptionObj: Subscription = new Subscription();
  /**
   * Variable which is used to access the token.
   * @type{string}
   */
  token: any;
  /**
 * Variable which is used to store the storeid.
 */
  StorId!: number;
  /**
   * Variable which is used to assign the token is verified or not.
   * @type{boolean}
   */

  isVerifiedToken: boolean;
  /**
  * Variable which is used to handle the loader.
  * @type{boolean}
  */
  loading: boolean;
  /**
  * Variable which is used to assign the heading.
  * @type{any}
  */
  heading: string;
  /**
  * Variable which is used to assign the button name.
  * @type{string}
  */
  buttonName: string;
  /**
  * Variable which is used to store the path.
  * @type{any}
  */
  path: any;
  /**
 * Variable which is used to store the headers details.
 * @type{any}
 */
  header = {
    title: 'Change Password', description: 'Here you can change your Credentials'
  }
  /**
  * Variable which is used to store the roleId.
  */
  roleId: any;
  /**
   * Variable used to success of change password.
   */
  isChangePassword: boolean = true;
  /**
 * Variable which is used for emitting the value
 */
  @Output() emittedValue = new EventEmitter();

  /**
   * Variable which is used for emitting loader vlaue
   */
  @Output() emitLoader = new EventEmitter();

  /**
    * Component constructor to inject the required services.
    * @param route To get the url path.
    * @param authService To verify the authentication details
    * @param location To move to th back
    * @param router To provides navigation
    * @param sharedService To get the store details
    * @param dialogService To show the dialog message
    * @param commonService To get the role name
    * 
    */
  constructor(private route: ActivatedRoute, private authService: AuthService,
    private location: Location, private sharedService: SharedService,
    private dialogService: DialogService, private router: Router) {
    super();
  }
  /**
     * Component OnInit life cycle hook
     */
  ngOnInit(): void {
    this.loading = true;
    this.StorId = this.sharedService.storeId;
    this.changePasswordForm = new UntypedFormGroup({
      password: new UntypedFormControl(null, [Validators.required, Validators.pattern(this.validator.commonChangePasswordPattern)]),
      confirmPassword: new UntypedFormControl(null, [Validators.required, Validators.pattern(this.validator.commonChangePasswordPattern)]),
    },
      CustomValidatorService.formValidation.bind(this)
    );
    this.path = (this.authService.urlDecode(this.route.snapshot['_routerState'].url)).split('/');
    this.subscriptionObj.add(this.route.params.pipe(filter((res: any) => {
      this.loading = false;
      if (res) {
        this.token = res.token;
        this.roleId = res.roleId;
        if (!this.token) {
          this.changePasswordForm.addControl('currentPassword', new UntypedFormControl(null, {
            validators: [Validators.required, Validators.pattern(this.validator.commonChangePasswordPattern)],
            asyncValidators: [CustomAsyncValidatorService.asyncExistsPasswordValidation(this.authService)]
          }))
        }
        return this.token;
      }
    }),
      mergeMap(() => {
        this.loading = true;
        return this.authService.verifyToken({ storeId: this.StorId, token: this.token })
      })).subscribe(res => {
        if (res) {
          this.loading = false;
          this.isVerifiedToken = true;
        }
      }, (err) => {
        this.loading = false;
      }));
    const reset = this.token ? ' Reset Password' : ' Change Password';
    const name = (this.token && this.roleId) ? 'Set Password' : reset;
    this.heading = name;
    this.buttonName = name;
    this.changePasswordForm.valueChanges.subscribe((res) => {
      this.emittedValue.emit({ 'changePasswordFormPristine': this.changePasswordForm.pristine, 'isPasswordChanged': false })
    });

  }
  /**
   * Method which is used to change the password.
   */
  changePassword() {
    if (this.changePasswordForm && this.changePasswordForm.valid) {
      this.loading = true;
      this.emitLoader.emit({ isLoader: true });
      let url, setPassword;
      const name = this.token ? 'configurepassword' : 'customer/changepassword';
      url = (this.token && this.roleId) ? 'customer/setpassword' : name;
      this.authService.getBase64String(null, this.changePasswordForm.value.password, url, 'Registration');
      delete this.changePasswordForm.value.password;
      delete this.changePasswordForm.value.confirmPassword;
      this.changePasswordForm.value['token'] = this.token ? this.token : '';
      const role = this.roleId ? this.roleId : null;
      const isResetOrChange = this.token ? this.authService.resetPassword(this.changePasswordForm.value) : this.authService.changePassword(this.changePasswordForm.value);
      setPassword = (this.token && this.roleId) ? this.authService.setPassword(this.changePasswordForm.value, role) : isResetOrChange;
      this.subscriptionObj.add(setPassword.subscribe(res => {
        if (res && res['message']) {
          this.changePasswordResponse(res);
        }
      },
        (err) => {
          this.loading = false;
          this.emitLoader.emit({ isLoader: false } );
          this.dialogService.openDialog({
            header: this.errorMessage.failureHeader,
            message: this.errorMessage.REQUEST_FAILED,
            actionType: this.dialogType.failure,
            button: { right: this.buttonText.ok }
          });
        }
      ));
    }
    else {
      this.dialogService.openDialog({
        header: this.errorMessage.alertHeader,
        message: this.errorMessage.mandatory_feild_required,
        actionType: this.dialogType.alert,
        button: { right: this.buttonText.ok }
      });
      this.changePasswordForm.markAllAsTouched();
    }
  }
  /**
  * Method which is used to handle reponse of change password.
  * @param res used to get the response 
  */
  changePasswordResponse(res) {
    this.changePasswordForm.reset();
    Object.keys(this.showPassword).forEach((key) => {
      this.showPassword[key] = false;
    });
    this.isChangePassword = true;
    this.loading = false;
    this.emitLoader.emit({ isLoader: false });
    this.dialogService.openDialog({
      header: this.errorMessage.successHeader,
      message: this.message.passwordChangedSuccessfully,
      actionType: this.dialogType.success,
      button: { right: this.buttonText.ok },
      disableClose:true
    })
    // .afterClosed().subscribe(response => {
    //   if (response) {
        this.emittedValue.emit({ 'changePasswordFormPristine': false, 'isPasswordChanged': true });
        if (this.path) {
          this.sharedService.navigateTo('login');
        }
    //   }
    // });
  }
  /**
  * this method used for reset for form
  */
  checkForm() {
    if (this.changePasswordForm && this.changePasswordForm.dirty) {
      this.dialogService.openDialog({
        header: this.dialogType.confirmation,
        message: this.message.unsaved,
        actionType: this.dialogType.confirmation,
        button: { left: this.buttonText.no, right: this.buttonText.ok }
      })
      // .afterClosed().subscribe(res => {
      //   if (res) {
          this.changePasswordForm.reset();
          this.isChangePassword = true
      //   }
      // })
    }
    else {
      this.isChangePassword = true
    }
  }
  /**
  * Method which is used to move to the back.
  */
  onBack() {
    this.location.back();
  }
  /**
 * Method which is used to define whether to display the password in text format or not.
 * @param property hold the current form of the password.
 */
  changeInputType(property: string) {
    if (this.showPassword) {
      this.showPassword[property] = !this.showPassword[property];
    }
  }
  /**
 * Method which is used to alert the user while navigating to other page
 * without saving the current page data's.
 */
  canDeactivate(): boolean {
    if (this.changePasswordForm && this.changePasswordForm.touched) {

      return this.changePasswordForm && !this.changePasswordForm.touched;
    } else {
      return true;
    }
  }
  /**
   * Method used for unsubscribe the request
   */
  ngOnDestroy(): void {
    this.subscriptionObj.unsubscribe();
  }
}
