/**
* Class which is used to default values for storefront editor module components.
*/
export class StorefrontEditorConstants {
  /**
   * Variable used to store form control values.
   * @type {object}
   */
  formControls = {
    radio: 'Radio', slider: 'Slider', checkbox: 'Checkbox', input: 'Input', textarea: 'Text Area', sliderToggle: 'Slider Toggle',
    dropdown: 'Dropdown', imageInput: 'Image', colorPicker: 'Color Picker', facebook: 'Facebook', instagram: 'Instagram',
    twitter: 'Twitter', linkedIn: 'Linked In', bannerLayout: 'Banner Layout', textEditor: 'Text Editor', pinterest: 'Pinterest',
    menuLayout: 'Menu Layout', testimonial: 'Testimonial', socialMedia: 'Social Media', filter: 'Filter', customQuestion: 'Custom Question',
    arrayOfObjects: 'ArrayOfObjects', Advertisement: 'Advertisement', imageBanner: 'Image Banner', aboutusDetails: 'aboutusDetails'
    , happyCustomer: 'Happy Customer', tripleBannerSettings: "Triple Banner", fontStyle: 'Font Style', productList: 'productList', icon: 'Icon',
    menuName: 'Menu Name', videoInput: "Video", cafeBanner: "Cafe Banner", bar: "Bar", imageGallery: "Image Gallery", number: 'Number',
    commonSliderControls: 'Common Slider Controls', artBanner: 'Art Banner', artsExtraDetails: 'Extra Details', loginPage: "Login Page",
    contactForm: "Contact Form", imageHotspotDetails: "Image Hotspot", artBannerII: 'Art Banner II', hiddenControl: 'Hidden Control', ourStoryDetails: "Our Story", button: "Button", commonHeading: 'Common Heading',
    horizontalSorting: 'Horizontal Sorting', gamingAboutus: "Gaming Aboutus",
    productListControls: 'Product List Controls', note: 'Note', singleProduct: 'Single Product', gridLayout: 'Grid Layout', candellaBanner: 'Candella Banner', commonBlogs: 'Common Blogs',
    productDetailsControls: "Product Details Controls", imageSlider: "Image Slider", timer: "Timer", serviceDetails: "Service Details", cafeMenu: "Cafe Menu", audioControl: "Audio Control", datePicker: 'Date', recentlyViewedControls: 'Recently Viewed Controls', overlayColor: 'Overlay Color', customIcon: 'Custom Icon'
  };
  /**
   * Variable used to store template values for product list page.
   * @type {array}
   */
  productListTemplate = [
    {
      "name": "Bakery Friendly Design",
      "value": "bakeryProductList"
    },
    {
      "name": "Retail Friendly Design",
      "value": "retailProductList"
    },
    {
      "name": "Restaurant Friendly Design",
      "value": "restaurantProductList"
    },
    {
      "name": "Retail Friendly Design 2",
      "value": "retailFriendlyDesign2"
    },
    {
      "name": "Image Less Design",
      "value": "noImageThemeProductList"
    },
    {
      "name": "Grocery Friendly Design",
      "value": "groceryFriendlyDesign"
    },
    {
      "name": "Tiles Friendly Design",
      "value": "tilesFriendlyDesign"
    },
    {
      "name": "Chocolate Friendly Design",
      "value": "chocolateProductList"
    },
    {
      "name": "Furniture Friendly Design",
      "value": "furnitureProductList"
    },
    {
      "name": "Electronics Friendly Design",
      "value": "electronicFriendlyDesign"
    },
    {
      "name": "Book Friendly Design",
      "value": "bookProductList"
    },
    {
      "name": "Painting Friendly Design",
      "value": "paintingProductList"
    },
    {
      "name": "Cafe Friendly Design",
      "value": "cafeThemeProductList"
    },
    {
      "name": "Pork Theme Product Design",
      "value": "porkThemeProductDesign"
    },
    {
      "name": "Finary Theme Product Design",
      "value": "finaryThemeProductList"
    },
    {
      "name": "Cosmetic Friendly Design",
      "value": "cosmeticProductList"
    },
    {
      "name": "Arts & Crafts Friendly Design",
      "value": "artsThemeProductList"
    },
    {
      "name": "Comforto Interior Design",
      "value": "comfortoProductList"
    }, {
      "name": "Sporting Goods Theme",
      "value": "SportingGoodsProductList"
    },
    {
      "name": "Candy Theme Product List",
      "value": "candyThemeProductList"
    },
    {
      "name": "Health & Wellness Friendly Design",
      "value": "healthAndWellnessThemeProductList"
    },
    {
      "name": "Cascade Friendly Design",
      "value": "cascadeProductList"
    },
    {
      "name": "Women Accessories Theme Product Design",
      "value": "womenAccessoriesProductList"
    },
    {
      "name": "Xclusive Glow Design",
      "value": "xclusiveGlowProductList"
    },
    {
      "name": "Carnivore Cart Theme Product Design",
      "value": "carnivoreCartProductList"
    },
    {
      "name": "Radiance Theme Product Design",
      "value": "RadianceProductList"
    },
    {
      "name": "Candella Theme Product Design",
      "value": "CandellaProductList"
    },
    {
      "name": "Jewellery Theme product Design",
      "value": "jewelleryThemeProductList"
    },
    {
      "name": "Palettify Theme product Design",
      "value": "palettifyThemeProductList"
    },
    {
      "name": "Babycado Theme",
      "value": "babycadoProductList"
    },
    {
      "name": "Kiddo heaven product design",
      "value": "childrenProductList"
    },
    {
      "name": "Handcraft Harbor Theme Design",
      "value": "handcraftHarborList"
    },
    {
      "name": "Gaming Product Design",
      "value": "gamingProductList"
    },
    {
      "name": "Bijoux Box Product Design",
      "value": "bijuoxProductList"
    },
    {
      "name": "Gift Product Design",
      "value": "giftProductList"
    },
    {
      "name": "Digi Arora Product Design",
      "value": "digiAroraDesign"
    },
    {
      "name": "Santa Surprise Product Design",
      "value": "santaSurpriseProductList",
      "hint": "Ribbons and Badges cannot be applied for this template"
    },
    {
      "name": "Furr Vogue Theme Product Design",
      "value": "furrVogueFriendlyDesign"
    },
    {
      "name": "Gadget Nex Theme Product Design",
      "value": "gadgetnexProductlist"
    }
  ];
  exclusiveProductListDesigns = [
    {
      "name": "Play Feast Product Design",
      "value": "petsuppliesProductList"
    }
  ]
  /**
   * link types in custom menu.
   * @type {object}
   */
  linkTypeOptions = {
    product: {
      id: null,
      resDataName: 'product',
      resIdName: 'id',
      urlPath: 'productdetails',
      isPaginationUsed: false,
      path: '',
      displayProperty: 'name'
    },
    themePages: {
      id: null,
      resDataName: 'pages',
      resIdName: 'id',
      isPaginationUsed: false,
      path: '',
      displayProperty: null,
      options: []
    },
    blog: {
      id: null,
      resDataName: 'blogCategories',
      urlPath: 'bloglist',
      resIdName: 'id',
      isPaginationUsed: false,
      path: '',
      displayProperty: 'name',
      options: []
    },
    externalUrl: {
      id: null,
      resDataName: '',
      urlPath: '',
      resIdName: '',
      isPaginationUsed: false,
      path: '',
      displayProperty: '',
      options: []
    }
  };
  /**
   * Custom menus.
   * @type {object}
   */
  menuList = {
    title: 'Add Custom Menu',
    description: 'Create your custom menu here.',
    addButtonText: 'Add Custom Menus',
    backButtonText: 'Back',
    addButtonLink: '/app/products/addribbon',
    addMenuList: 'Create ribbon',
    editMenuList: 'Edit ribbon',
    addMenuDescription: 'Create a new ribbon here...',
    editMenuDescription: 'Edit existing ribbon data...',
    header: 'Header',
    themePages: 'Theme Pages',
    products: 'Products',
    blogs: 'Blogs',
    externalUrl: 'External Url',
    listDescription: 'List of custom menus',
    listTitle: 'Custom Menu List',
    editMenu: 'Edit Custom Menu',
    editDescriptiontext: 'Edit your custom menu here.',
    cancelText: 'Cancel',
    saveText: 'Save',
    sortText: 'Sort Menu'
  };
  /**
   * Variable used to store button values for theme dashboard component.
   * @type {array}
   */
  themeDashboard = [
    {
      cardTitle: "Pick a Theme",
      image: './assets/storefrontEditor/selectTheme.png', buttonText: 'Select Theme',
      text: 'Need to give your website a fresh look? Simply click on the "Select Theme" button to choose a new theme.',
      route: 'app/design/themes', disabled: false
    },
    {
      cardTitle: "Start Editing",
      image: './assets/storefrontEditor/startEditing.png', buttonText: 'Start Editing',
      text: 'Seeking to personalize the current theme? Use the "Start editing" button to edit your website.',
      route: 'themes', disabled: true
    },
    {
      cardTitle: "Build Custom Menus",
      image: './assets/storefrontEditor/customMenus.png', buttonText: 'Create Custom Menus ',
      text: 'Want to design new menus for your website? Simply click the "Create Custom Menus" button to make new menus..',
      route: 'app/design/custommenu', disabled: true
    }
  ];
  /**
   * Variable used to store theme dashboard page header details.
   * @type {object}
   */
  themeDashboardHeading = {
    title: 'Customize your site!',
    description: 'Here you can customize your site and menus',
    isHeaderIcon: true,
    iconName: 'link',
    iconLink: 'https://help.getzenbasket.com/design.html',
    iconToolTip: 'Help'
  };
  /**
   * Variable used to store font family types.
   * @type {array}
   */
  font = {
    fontFamily: [
      { name: 'ABeeZee', value: "'ABeeZee', sans-serif" },
      { name: 'Amatic SC', value: "'Amatic SC', sans-serif" },
      { name: 'AR One Sans', value: "'AR One Sans', sans-serif" },
      { name: 'Archivo', value: "'Archivo', sans-serif" },
      { name: 'Arima', value: "'Arima', system-ui" },
      { name: 'Cormorant', value: "'Cormorant', serif" },
      { name: 'Crimson Text', value: "'Crimson Text', serif" },
      { name: 'Dancing Script', value: "'Dancing Script', serif" },
      { name: 'EB Garamond', value: "'EB Garamond', serif" },
      { name: 'Exo 2', value: "'Exo 2', sans-serif" },
      { name: 'Handlee', value: "'Handlee', cursive" },
      { name: 'Heebo', value: "'Heebo', sans-serif" },
      { name: 'Inconsolata', value: "'Inconsolata', monospace" },
      { name: 'Instrument Sans', value: "'Instrument Sans', sans-serif" },
      { name: 'Inter', value: "'Inter', sans-serif" },
      { name: 'Jost', value: "'Jost', sans-serif" },
      { name: 'Kalam', value: "'Kalam', cursive" },
      { name: 'Lato', value: "'Lato', sans-serif" },
      { name: 'Libre Baskerville', value: "'Libre Baskerville', serif" },
      { name: 'Lora', value: "'Lora', serif" },
      { name: 'monospace', value: 'monospace' },
      { name: 'Montserrat', value: "'Montserrat', sans-serif" },
      { name: 'Nunito', value: "'Nunito', sans-serif" },
      { name: 'Open sans', value: "'Open Sans', sans-serif" },
      { name: 'Oswald', value: "'Oswald', sans-serif" },
      { name: 'Playfair Display', value: "'Playfair Display', serif" },
      { name: 'Poppins', value: "'Poppins', sans-serif" },
      { name: 'Press Start 2P', value: "'Press Start 2P', cursive" },
      { name: 'Raleway', value: "'Raleway', sans-serif" },
      { name: 'Reem Kufi', value: "'Reem Kufi', sans-serif" },
      { name: 'Roboto', value: "'Roboto', sans-serif" },
      { name: 'Roboto Condensed', value: "'Roboto Condensed', sans-serif" },
      { name: 'Roboto Mono', value: "'Roboto Mono', monospace" },
      { name: 'Satisfy', value: "'Satisfy', cursive" },
      { name: 'Sofia', value: "'Sofia', sans-serif" },
      { name: 'Sorts Mill Goudy', value: "'Sorts Mill Goudy', serif" },
      { name: 'Tangerine', value: "'Tangerine', cursive" }
    ]
  };
  /**
   * Variable used to store control values for gallery component.
   * @type {object}
   */
  gallery = {
    bookAboutUs: 'bookAboutUs',
    bookDetails: 'bookDetails',
    aboutusData: 'aboutusData',
    counterInfowithImage: 'counterInfowithImage',
    bookStack: 'bookStack',
    bookDeals: 'bookDeals',
    bookAuthors: 'bookAuthors',
    awardDetail: 'awardDetail',
    teamDetails: 'teamDetails',
    melodiamTeam: 'melodiamTeam',
    luxeliterature: 'luxeliterature',
    whoWeAreContent: 'whoWeAreContent',
    addImage: "Add Image to Gallery",
    newtype: "Add New Type",
    addMissionDetail: "Add Mission Details",
    missionDetail: "missionDetail",
    story: "story",
    galleryImages: "galleryImages",
    tags: "tags",
    bannerTags: "bannerTags",
    advertisements: "advertisements",
    aboutUsContent: "aboutUsContent",
    workingProcess: "workingProcess",
    neighborhoodHome: "neighborhoodHome",
    specialAdContent: "specialAdContent",
    valuesInfo: "valuesInfo",
    counterInfo: "counterInfo",
    galaryImage: "galaryImage",
    cosmeticPhilosophy: "cosmeticPhilosophy",
    timelineDetails: "timelineDetails",
    achievements: "achievements",
    locationDescription: "locationDescription",
    locationBackground: "locationBackground",
    shopLocations: "shopLocations",
    trending: "trending",
    bouquetContent: "bouquetContent",
    comfortoBannerDetail: "comfortoDetail",
    InstrumentContent: "InstrumentContent",
    steps: "steps",
    carParts: "carParts",
    slumberillowoffer: "slumberillowoffer",
    getCard: "getCard",
    getGallery: "getGallery",
    professionals: "professionals",
    toolsExperts: "toolsExperts",
    accessoriesTimeline: "accessoriesTimeline",
    newTimeLine: "Add New TimeLine",
    OurStoryData: 'OurStoryData',
    tabDetails: "getcontentdetails",
    TeamDescriptionCard: "getManagementDetails",
    fashionTeam: "fashionTeam",
    petlist: "list",
    petTeamDetails: "petTeamDetails",
    childrensThemeAbout: 'childrensThemeAbout',
    blogList: "blogList",
    PetsFoodDetail: "PetsFoodDetail",
    petAwards: "petAwards",
    petExpertsInfo: "petExpertsInfo",
    petPoints: "petPoints",
    WorkSection: "workDetails",
    handcraftArtist: "ArtistSection",
    ChooseUsSection: "ChooseUsSection",
    growton: "growton",
    luxeLiteratureDetails: "luxeLiteratureDetails",
    whyUs: 'whyUs',
    paintingTimeline: "paintingTimeline",
    outfitAboutusTeamCard: "outfitAboutusTeamCard",
    gardenSage: 'gardenSage',
    greenTeam: "greenTeam",
    timeline: "timeline",
    groceryHubBanner: "groceryHubBanner",
    groceryHub: "groceryHub",
    ourCollections: "ourCollections",
    eliteBanner: "eliteBanner",
    newArrival: "newArrival",
    bijouxBenefits: "bijouxBenefits",
    jewelCollection: "jewelCollection",
    jewelHistory: "jewelHistory",
    bijouxImages: "bijouxImages",
    giftBanner: "GiftBannerImages",
    giftMobileInfo: "giftMobileInfo",
    oplineTilesCounter: "oplineTilesCounter",
    pureplateSubheadings: "pureplateSubheadings",
    bagTeam: "bagTeam",
    scrollText: "scrollText",
    meetOurTeam: "meetOurTeam",
    furniflareBanner: "furniflareBanner",
    topCategory: 'topCategory',
    whyChooseUs: 'whyChooseUs',
    elysianBanner: 'elysianBanner',
    woodyNestTeam: "woodyNestTeam",
    woodycounterInfo: "woodycounterInfo",
    woodyNestProducts: "woodyNestProducts",
    teamMembersInfo: 'teamMembersInfo',
    dellizziosaServices: "dellizziosaServices",
    freshFlareGrocersTimeline: "freshFlareGrocersTimeline",
    getProductsByCategory: "getProductsByCategory",
    sellingStoryDetails: "sellingStoryDetails",
    grindGroveTimeline: "grindGroveTimeline",
    grindGroveFaqs: "grindGroveFaqs",
    grindBanner: "grindBanner",
    dermaGlowBanner: "dermaGlowBanner",
    grindSocialMedia: "grindSocialMedia",
    handHeldHubMilestone: 'handHeldHubMilestone',
    handHeldHubMobileVariant: 'handHeldHubMobileVariant',
    ourProcess: "ourProcess"
  };
  /**
  * Variable used to store dialog messages based on control values for gallery component.
  * @type {object}
  */
  galleryDialogTitles = {
    awardDetail: 'Add Award Details',
    accessoriesTimeline: 'Add Timeline Content',
    bookDetails: 'Add Book Details',
    aboutusData: 'Add About Us Details',
    bookAboutUs: 'Add Team Details',
    bookStack: 'Add Book Stack Details',
    bookDeals: 'Add Book Deal Details',
    bookAuthors: 'Add Book Author Details',
    teamDetails: 'Add Team Details',
    whoWeAreContent: 'Add Notes From Desk',
    missionDetail: "Add Mission Details",
    story: "Add Story",
    galleryImages: "Add Image to Gallery",
    companyInfoDetails: "Company Info",
    tags: "Add Tag Details",
    bannerTags: "Add Banner Tag Details",
    advertisements: "Add Advertisement Details",
    aboutUsContent: "Add About Us Content",
    knowAboutUs: "Add About Us Data",
    workingProcess: "Add Working process details",
    specialAdContent: "Add Special AdContent",
    neighborhoodHome: "Add Image details",
    aboutusDetails: "Add About Us Details",
    OurStoryData: 'Add About Us Story Details',
    childrensThemeAbout: "Add About Us Details",
    blogList: "Add Blog List Details",
    whyUs: "Add List Details",
    gardenSage: 'Add Section Details',
    greenTeam: "Add New Team Details",
    groceryHubBanner: "Add New Grocery Post",
    groceryHub: "Add Resons For Why Choose Us",
    outfitAboutusTeamCard: "Add Team Member Details",
    eliteBanner: "Add New Banner Content",
    bagTeam: "Add New Team Details",
    woodyNestTeam: 'Add New Team Details',
    counterInfo: "Add Counter Details",
    pureplateSubheadings: "Add Sub Header",
    professionals: "Add Professional Details",
    timelineDetails: "Timeline Details",
    bouquetContent: "Add Content",
    fashionTeam: "Add Team Member",
    PetsFoodDetail: "Add Content",
    petAwards: "Add Awards",
    petExpertsInfo: "Add Expert",
    jewelHistory: "Add Jewel Details",
    bijouxBenefits: "Add Benefits",
    tabDetails: "Add Image Details",
    TeamDescriptionCard: "Add Member Details",
    growton: "Add New Banner Details",
    topCategory: "Add New Category",
    whyChooseUs: "Add Why Choose Us Details",
    teamMembersInfo: "Add Team Member Details",
    carParts: "Add Review",
    dellizziosaServices: "Add Dellizziosa Services Details",
    grindGroveTimeline: "Add New Timeline Details",
    grindGroveFaqs: "Add New Question And Answers",
    grindBanner: "Add New Banner Details",
    dermaGlowBanner: "Add New Banner Content",
    handHeldHubMobileVariant: "Add New Variant",
    handHeldHubMilestone: "Add New Milestone"
  };
  /**
   * Variable used to store the linktypes order
   * @type {object}
   */
  storeMenuLinkTypes = {
    discounts: 20,
    externalUrl: 17,
    products: 19,
    blogs: 18,
    themePages: 21
  };
  /**
   * Variable used to store banner property types.
   * @type {array}
   */
  bannerProperties = [
    {
      id: 1,
      name: 'Image',
    }, {
      id: 2,
      name: 'Slider',
    }, {
      id: 3,
      name: 'Video',
    }, {
      id: 4,
      name: 'Solid Color',
    }, {
      id: 5,
      name: 'Gradient Color'
    }, {
      id: 6,
      name: 'Painting Theme'
    }, {
      id: 7,
      name: 'Cafe Theme'
    },
    {
      id: 8,
      name: 'Carousel'
    },
    {
      id: 9,
      name: 'Sporting Goods Theme'
    },
    {
      id: 10,
      name: 'Pets Canine'
    },
    {
      id: 11,
      name: 'Chocolate Theme Three'
    },
    {
      id: 12,
      name: 'Digital Product'
    },
    {
      id: 13,
      name: 'Women Accessories Theme'
    },
    {
      id: 14,
      name: 'Bliss Theme'
    },
    {
      id: 15,
      name: 'Painting Theme Three'
    },
    {
      id: 16,
      name: 'Radiance Theme'
    },
    {
      id: 17,
      name: 'Jewellery Theme Two'
    }
  ];
  /**
   * Variable used to store grid property types.
   * @type {array}
   */
  gridProperties = [
    {
      id: 1,
      name: 'Radiance Theme',
      count: 7
    },
    {
      id: 2,
      name: 'Tools2 Theme',
      count: 5
    }
  ];
  /**
   * Variable used to store banner text alignement options.
   * @type {array}
   */
  textAlignOptions = [{
    id: 1,
    name: 'Center',
  }, {
    id: 2,
    name: 'Left',
  }, {
    id: 3,
    name: 'Right',
  }, {
    id: 4,
    name: 'Top Center',
  }, {
    id: 5,
    name: 'Top Left'
  }, {
    id: 6,
    name: 'Top Right'
  }, {
    id: 7,
    name: 'Bottom Center'
  }, {
    id: 8,
    name: 'Bottom Left'
  }, {
    id: 9,
    name: 'Bottom Right'
  }];
  /**
   * Variable used to store banner text alignement options in Image banner.
   * @type {array}
   */
  textAlignOptionsForImage = [{
    name: 'Top',
    value: 'Top Center',
  }, {
    name: 'Center',
    value: 'Center',
  }, {
    name: 'Bottom',
    value: 'Bottom Center'
  }];
  /**
   * Variable used to store banner text animation options.
   * @type {array}
   */
  animations = [{
    id: 1,
    name: 'Flicker Animation',
    keyword: 'flickerTemplate'
  }, {
    id: 2,
    name: 'Box Animation',
    keyword: 'boxTemplate'
  }, {
    id: 3,
    name: 'Slide From Line Animation',
    keyword: 'slideFromLine'
  }, {
    id: 4,
    name: 'Slide In Text Animation',
    keyword: 'slideInText'
  },
  {
    id: 6,
    name: 'Shining Animation',
    keyword: 'shiningTemplate'
  },
  {
    id: 7,
    name: 'No Animation',
    keyword: 'noAnimationTemplate'
  }];
  /**
   * Variable used to store messaages to be displayed in dialog.
   * @type {object}
   */
  dialogMessages = {
    unsavedChanges: 'You have unsaved changes. Are you sure you want to leave this page?',
    changedThemePageError: 'Failed to load page details',
    getThemePagesError: 'Failed to load theme pages',
    getSingleStorePageError: 'Failed to get selected page details!',
    createStorePageSetupSuccess: 'Store page setup details has been created successfully!',
    updateStorePageSetupSuccess: 'Store page setup details has been updated successfully!',
    createStorePageSetupFailed: 'Failed to create store page setup details.',
    updateStorePageSetupFailed: 'Failed to update store page setup details.',
    fetchAttachmentFailed: 'Failed to load image gallery details.',
    deleteData: 'Delete',
    attachmentDeleted: 'Deleted Successfully',
    Failure: 'Failed',
    addSuccess: 'Details Added Successfully',
    addFailed: 'Details not Added successfully',
    updateSuccess: 'Details updated Successfully.',
    updatedFailed: 'Details not Updated Successfully',
    getBase64StringFailed: 'Failed to retrieve base 64 string of the image.',
    deleteConfirmation: 'Are you sure you want to remove?',
    deleteTestimonialsConfirmation: 'Are you sure you want to remove the selected testimonial(s)?',
    deleteTestimonialConfirmation: 'Are you sure you want to remove the selected testimonial?',
    deleteTestimonial: 'Are you sure you want to remove this testimonial?',
    editTestimonialError: 'Failed to update testimonial Details.',
    createTestimonialError: 'Failed to create a testimonial.',
    deleteTestimonialError: 'Failed to remove testimonial.',
    getTestimonialError: 'Failed to retrieve testimonials.',
    menuDetailsError: 'Error in retrieving menu details.',
    createMenuError: 'Error in creating the menu!',
    editMenuError: 'Error in updating menu details.',
    deleteMenuError: 'Error in removing menu details',
    getUserError: 'Error in retrieving user details',
    getThemesError: 'Failed to get themes from the server.',
    uploadBannerImageError: 'Failed to upload banner image.',
    createTestimonialSuccess: 'Testimonial details created successfully .',
    editTestimonialSuccess: 'Testimonial updated successfully.',
    deleteTestimonialSuccess: 'Testimonial removed successfully.',
    bulkDeleteTestimonialSuccess: 'Testimonial(s) removed successfully.',
    createMenuSuccess: 'Menu details has been created successfully.',
    editMenuSuccess: 'Menu details has been updated successfully.',
    deleteMenuSuccess: 'Menu details has been removed successfully.',
    propertyValuesError: 'Error in retrieving property values.',
    menuCategoriesError: 'Error in retrieving menu category details.',
    formValueChangeError: 'Error in detecting form value change.',
    mandatoryFieldFill: "Please fill out the mandatory fields.",
    noChangeAlert: 'You have no changes to save.',
    noContentAlert: 'You have no content to save.',
    sectionListFailed: "Failed to load section list.",
    addSectionAlert: "Select any option to add new section.",
    removeBannerImage: "Are you sure you want to remove the image from the slider?",
    saveChangesEditor: "Are you sure you want to Save the changes? If you save, the changes you made will not be reflected in your site.",
    publishChangesEditor: "Are you sure you want to Publish the changes?",
    noChangeAlertEditor: "You have no changes to Save/Publish!",
    removeButtonControls: "Are you sure you want to remove the button from the banner?",
    pageCreateClose: 'Are you sure you don\'t want to create a page?',
    cancelPage: 'Are you sure you want to cancel?',
    noDefaultRoles: 'There are no guest or customer roles configured for this store.',
    optionAddSuccess: 'Option added successfully.',
    optionUpdateSuccess: 'Option updated successfully.',
    optionRemoveSuccess: 'Option removed successfully.',
    unpublishedPageAlert: `You have not published your saved changes. So it is not yet reflected on your site. If you wish to publish the changes, click 'Publish, otherwise click 'Continue'.`,
    fetchMenuFailure: 'Failed to get menus.',
    removeColumnImageBannerEditor: "Are you sure you want to remove the image from the current Column?",
    removeControls: "Are you sure to remove the controls?",
    removeRowImageBannerEditor: "Are you sure you want to remove the image from the current row?",
    removeTripleBannerImage: "Are you sure you want to remove the image?",
    fileFormatInvalidForImage: 'Invalid file format. Only images with jpg, jpeg, png, gif and webp extensions are allowed.',
    fileFormatInvalidForVideo: 'Invalid file format. Only videos with "mp4" extensions are allowed.',
    addNewSection: 'This section will be added at the end of the page.',
    testimonialFailed: 'Failed to load testimonial details.',
    successActivateTestimonial: 'Testimonial published successfully.',
    successDeactivateTestimonial: 'Testimonial unpublished successfully.',
    failureActivateTestimonial: 'Failed to publish testimonial.',
    failureDeactivateTestimonial: 'Failed to unpublish testimonial.',
    uploadFileSize: 'The upload file size should be less than',
    zenbasketImageUploadFailure: 'Failed to upload images.',
    pageDeleteConfirmation: 'Are you sure you want to delete? If this page is linked with any buttons, it will also be deleted.',
    pageDeletionSuccess: 'Page deleted successfully.',
    pageDeletionFailure: 'Failed to delete page.',
    updateCurrentPageDetails: 'Failed to update recently changed details.',
    setPageDetailsFailure: 'Failed to set page details.',
    convertFileFormatFailure: 'Failed to convert files to binary format.',
    failedToSetNavbarDetails: 'Failed to set navbar details.',
    errorMessage: 'Error! Please try again.',
    failedToRemoveImage: 'This section must have at least one image.',
    unableToAddImage: 'Please upload the above image first.',
    translateSectionFailed: 'Failed to translate section.',
    languageFetchFailed: 'Failed to fetch store languages.',
    languageChangeConfirmation: 'You have unsaved changes in the selected language.Are you sure you want to change other language?',
    sectionTranslateConfirmation: 'You have unsaved changes in the selected language.Are you sure you want to translate without saving the changes?'
  };
  /**
   * Variable used to store the custom question success / failure messages.
   * @type {object}
   */
  customQuestionMessages = {
    questionRequired: "Question is required",
    answerTypeRequired: "Answer Type is required",
    answerOptionHint: "Press enter (or) comma to give multiple answers",
    answerOptionRequired: "Give atleast one option for answer",
    okButtonText: "Ok",
    yesButtonText: "Yes",
    noButtonText: "No",
    deleteQuestionConfirmation: "Do you want to delete this question?",
    getFormControlsFailed: "Failed to get the form controls",
    questionCreationSuccess: "Question created successfully",
    questionCreationFailed: "We failed to create your question. Please try again after some time",
    editQuestionSuccess: "Question updated successfully",
    editQuestionFailed: "We failed to update your question. Please try again after some time",
    deleteQuestionSuccess: "Question deleted successfully",
    deleteQuestionFailed: "We failed to delete your question. Please try agian after some time"
  };
  /**
   * Variable used to store object name.
   * @type {object}
   */
  objectText = {
    isVisible: 'isVisible', isClicked: 'isClicked', bannerImage: 'bannerImage', bannerVideo: 'bannerVideo', link: 'link', video: 'Video',
    white: 'white', index: 'index', testimonial: 'testimonial', formGroupData: 'formGroupData', bannerSelector: 'phase-ii-banner', bannerWithCategory: 'phase-ii-fashion-home-banner',
    header: 'Header', homePageId: 'homePageId', value: 'value', id: 'id', savedPageContent: 'savedPageContent', imageControlName: 'imageControlName',
    sampleData: 'sampleData', testimonials: 'testimonials', maxLength: 'maxlength', minlength: "minlength", required: 'required', pattern: 'pattern', imageIndex: 'imageIndex', controlIndex: 'controlIndex',
    updateResponseDetails: { response: 'res.updatePage[0]', methodName: 'updateStorePageSetup', successMessage: 'updateStorePageSetupSuccess', failureMessage: 'updateStorePageSetupFailed' },
    createResponseDetails: { response: 'res.pageSetupData', methodName: 'createStorePageSetup', successMessage: 'createStorePageSetupSuccess', failureMessage: 'createStorePageSetupFailed' }, imageBannerSelector: 'phase-ii-image-banner', chocolateThemeSelector: 'phase-ii-chocolate-theme-happy-customer', paintingThemeSelector: 'phase-ii-painting-theme-about-us-contents', cafeBannerSelector: 'phase-ii-cafe-theme-banner',
    max: 'max', min: 'min', imageUrl: 'imageUrl', homeDecorSelector: 'phase-ii-decorator-home', childrenItemMedia: 'children-media', singleProductDetails: 'phase-ii-single-product-display', gridBannerSelector: 'phase-ii-common-grid', jewelleryGallery: 'phase-ii-jewellery-theme-contactus-gallery', paintingArtworks: 'phase-ii-painting-theme-iii-artworks', paintingOriginalWorks: 'phase-ii-painting-theme-iii-original-works', commonBlogsSelector: 'phase-ii-common-blogs',
    productDetails: 'phase-ii-common-product-details'
  };
  /**
   * Variable used to store default color picker color.
   * @type {string}
   */
  defaultColor = 'var(--primary-color)';
  /**
   * Variable which is used to store the header of the dialog.
   * @type {object}
   */
  dialogHeader = {
    confirmation: 'Confirmation',
    information: 'Information',
    alert: 'Alert',
    warning: 'Warning',
    success: 'Success',
    failure: 'Failure',
    areYouSure: 'Are you sure?'
  };
  /**
   * Variable which is used to store dialog type.
   * @type {object}
   */
  dialogType = { alert: 'alert', confirmation: 'confirmation', information: 'information', success: 'success', failure: 'failure' };
  /**
   * Variable which is used to store the button name.
   * @type {object}
   */
  buttonText = {
    ok: 'Okay',
    yes: 'Yes',
    no: 'No',
    cancel: 'Cancel',
    submit: 'Submit',
    save: 'Save',
    leave: 'Leave',
    stay: 'Stay',
    continue: 'Continue'
  };
  /**
   * Variable used to store filter option types.
   * @type {object}
   */
  filterOptionTypes = {
    range: "Range",
    condition: "Condition",
    simple: "Simple",
    dateRange: "DateRange",
    rating: "rating",
    brands: "brandId",
    variantsOption: 'Variant Options'
  };
  /**
   * Variable used to store the property type.
   * @type {array}
   */
  propertyType = ['LINK_TYPE', 'MENU_TYPES'];
  /**
   * Variable used to get property values of checkout questions.
   * @type {string}
   */
  propertyName = 'Checkout Questions';
  /**
   * Variable used to store menu category data.
   * @type {array}
   */
  categoryArray = ['Signed in Customers', 'Guest Customers'];
  /**
   * Variable used to store role name
   * @type {array}
   */
  roleName = ['Customer', 'Guest'];
  /**
   * Variable used to store error messages
   * @type {object}
   */
  menuError = {
    imageRequired: 'Image is Required.',
    nameRequired: 'Name is required.',
    countRequired: 'Count is required.',
    titleRequired: 'The title is required.',
    subtitleRequired: 'A subtitle is required.',
    descriptionrequired: 'A description is required.',
    contentRequired: 'Content is required.',
    authorContentMaxLength: "Content should not exceed 200 characters!",
    titleMaximumLength: 'The title should not exceed 20 characters!',
    bookContentMaxLength: 'Content should not exceed 380 characters.',
    titleMaxLength: 'Title Exceeded Max Length',
    contentMaxLength: 'Content Exceeding Max Length',
    professionRequired: 'A profession is required.',
    menuName: 'Menu Name is required.',
    validName: 'Name accepts only characters.',
    validProfession: 'The profession accepts only characters.',
    validCaption: 'Caption accepts only characters.',
    menuType: 'Apply To is required.',
    linkType: 'Link type is required.',
    value: 'Page is required.',
    url: 'URL is required.',
    blogCategories: 'Blog Categories is required.',
    product: 'Product is required.',
    requiredMessage: 'This field is required.',
    captionRequired: 'A caption is required.',
    subCaptionRequired: 'A subtitle is required.',
    mainTextRequired: 'Main-text is required.',
    categories: 'Customer Type is required.',
    menuCharacterOnly: 'Menu name accepts only characters.',
    urlCharacterOnly: 'Please enter a valid URL.',
    menuExistError: 'Menu name already exists in storefront.',
    menuLength: "Menu Name should not exceed 25 characters!",
    successMessage: "Data saved successfully!",
    successHeader: "Success!",
    name_pattern: "Please enter valid input!",
    unSavedChanges: "You have unsaved changes. Are you sure you want to leave this page?",
    minimumImages: 'Please add at least 6 images  to complete the banner section setup.',
    titleMaxiLength: 'Title should not exceed 50 characters!',
    ContentMaxLength: 'Content should not exceed 400 characters',
    titleMaxLimit: 'Title should not exceed 30 characters!',
    subtitleMaxlength: 'Subtitle should not exceed 25 characters!',
    nameMaxlength: 'Name should not exceed 20 characters!',
    contentMaxLimit: 'Content should not exceed 300 characters',
    subtitleMaxLength: 'Subtitle should not exceed 100 characters',
    titleAboutUsMax: 'Title value exceeded max value limit!',
    contentMaxValue: 'Content should not exceed 300 characters',
    DescriptionMaxLength: 'Description should not exceed 160 characters',
    NameMaxLimit: 'Name should not exceed 50 characters!',
    NameMaxLength: 'Name should not exceed 20 characters',
    nameMaxLength: 'Name should not exceed 50 characters!',
    professionMaxLength: 'Profession should not exceed 50 characters!',
    WidthIsRequired: 'Width is Required',
    WidthMaxLength: 'Width value should be 10 to 1500',
    captionLength: 'Caption should not exceed 80 characters!',
    comfortoCaptionLength: 'Caption should not exceed 100 characters.',
    headingMaxLength: "Heading should not exceed 50 characters!",
    ImagenameMaxLength: "Name should not exceed 20 characters!",
    subHeadingMaxLength: "Sub heading should not exceed 30 characters!",
    sliderBackgroundColorRequired: 'Slider background color is required',
    minimumCardLength: 'To complete the banner section layout, please include at least two Card Details.',
    maximumCardLength: 'The Maximum number of cards has been reached!',
    titleAboutUsMin: 'Title value exceed minimum value limit!',
    titleAboutUspattern: 'Title value must be a number!',
    storeEventRequired: 'Event date is required',
    contentRequiredError: 'Content field is required!',
    offerTypeRequired: 'Offer type field is required',
    iconRequired: "Icon type is required!",
    titleSageMaxLength: 'Title should not exceed 70 characters',
    buttonContentRequired: 'The button content is required.',
    buttonContentMaxlength: 'Button content should not exceed 20 characters',
    teamTitleLength: 'Content should not exceed 15 characters!',
    teamSubtitleLength: 'Content should not exceed 15 characters!',
    teamDescriptionLength: 'Content should not exceed 85 characters!',
    footTitleLength: 'Title Length exceeded maximum Limit',
    footContentLength: 'Content Length exceeded maximum Limit',
    hubTitleMaxLength: 'Title should not exceed 40 characters',
    hubSubTitleMaxLength: 'Sub Title should not exceed 30 characters',
    groceryHubContentMaxLength: 'Content should not exceed 250 characters',
    maxLengthName: 'Name should not exceed 30 characters!',
    acceptNumber: 'Year value must be a number!',
    yearRequiredLength: 'Year value must contain 4 digits only!',
    timelineDescriptionLength: 'Description length exceeded maximum limit',
    bijouxBenefitsNameMax: "Name should not exceed 60 characters!",
    jewelTitleLength: 'Title should not exceeded 30 characters',
    professionLength: 'Profession should not exceed 25 characters',
    jewelHistoryContent: 'Content should not exceed 150 characters!',
    jewelHistoryMinmumContent: 'Content value must exceed 80 characters!',
    maxLengthTeamName: 'Name should not exceed 20 characters',
    maxLengthTeamProfession: 'Designation should not exceed 20 characters',
    whyUsMaxLength: "Caption should not exceed 25 characters!",
    countMaxLength: 'Maximum Limit exceeds!',
    titleSellingStoryMaximumLength: 'The title should not exceed 25 characters!',
    noSpaceValidationPattern: 'This field does not accept empty spaces!',
    professionMaximumLength: 'Profession should not exceed 30 characters',
    validMessage: 'Name accepts only characters!',
    finaryCaptionLength: 'Scroll Text should not exceed 20 characters!',
    descriptionMaximumLength: "Description should not exceed 200 characters!",
    descriptionLength: "Description should not exceed 500 characters!",
    eliteBannerButtonText: "Button text should not exceed 25 characters!",
    eliteBannerDescription: "Description should not exceed 250 characters!",
    ourTeamDescriptionLength: "Description should not exceed 120 characters!",
    titleMaximunLength: 'Title should not exceed 50 characters!',
    descriptionMaximunLength: 'Description should not exceed 80 characters!',
    buttonMaximunLength: 'Button text should not exceed 15 characters!',
    furniflareHeading: 'Heading should not exceed 30 characters',
    furniflareSubheading: 'Sub Heading should not exceed 40 characters',
    furniflareButtonText: 'Button Text should not exceed 20 characters',
    maxLengthTopCategoryCaption: 'Caption should not exceed 20 characters',
    whyChooseUsMaximunLength: 'Description should not exceed 300 characters!',
    elysianBannerTitleMaximunLength: 'Title should not exceed 30 characters!',
    elysianBannerDescriptionMaximunLength: 'Description should not exceed 100 characters!',
    elysianBannerButtonMaximunLength: 'Button Text should not exceed 15 characters!',
    designationMaximumLength: 'Profession should not exceed 20 characters',
    buttonMaxLength: 'Button should not exceed 20 characters!',
    maximumHeadingLengthCarParts: 'Heading should not exceed 25 characters',
    maximumDescriptionLengthCarParts: 'Description should not exceed 200 characters',
    compactTitleMaximumLength: 'The title should not exceed 100 characters!',
    MINICONSIZE: 'Value must be at least 20.',
    MAXICONSIZE: 'Value must be at most 90.',
    MAXICONBORDER: 'Value must be less than 30.',
    UPLOADAUDIO: 'Please upload an audio file.',
    freshFlareTitleMaxLength: 'Title should not exceed 60 characters!',
    freshFlareDescriptionMaxLength: 'Description should not exceed 850 characters!',
    graindTitleMaxLength: 'Title should not exceed 100 characters',
    colorRequired: 'You must be choose a color',
    questionRequired: 'Question is required!',
    answerRequired: 'Answer is required!',
    questionMaxLength: 'Question must be less than 100 characters!',
    answerMaxLength: 'Answer must be less than 200 characters!',
    grindTitleLength: 'Title should not exceed 30 characters',
    grindDescriptionLength: 'Content should not exceed 150 characters.',
    grindButtonLength: 'Button Content should not exceed 15 characters!',
    valueLessThanFiveWords: 'Title must be less than 5 words',
    grindCountLength: "Count should not be more than 10 characters!",
    grindUsersLength: "Users length has reached the maximum limit!",
    usersRequired: "User is Required",
    artBannerHeadingLength: 'Content should not exceed 30 characters.',
    artBannerSubHeadingLength: 'Content should not exceed 50 characters.',
    handHeldHubMilestoneMonthMaxlength: 'Month should not exceed 10 characters',
    handHeldHubMilestoneContentMaxlength: 'Content should not exceed 300 characters',
    yearMaxLength: 'Year should not exceed 20 characters',
    handHeldHubMobileVariantHeading: 'Heading should not exceed 30 characters',
    handHeldHubMobileVariantDescription: "Description should not exceed 500 characters!",
    quoteTextMaxLength: 'quote should not exceed 30 characters.',
    countMinLength: 'Minimum Limit exceeds!',
    invalidYearError:'Year is invalid.',
    melodiamCaptionLength: 'Caption should not exceed 200 characters.'
  };
  /**
   * Variable used to store default global theme.
   * @type {object}
   */
  defaultStyleValues = {
    "primary-store-color": "#005374",
    "secondary-store-color": "#FF8517",
    "tertiary-color": "#d92727",
    "primary-button-store-color": "#016f9b",
    "light-store-color": "white",
    "secondary-font-store-color": "#818181",
    "navbar-color": "white",
    "primary-color": "#005374",
    "primary-button-color": "#016f9b",
    "secondary-color": "#FF8517",
    "star-color": "#ffd700",
    "grey-shadow-color": "rgba(116, 74, 29, 0.6)",
    "background-color": "rgba(116, 74, 29)",
    "light-color": "white",
    "secondary-font-color": "#818181",
    "dark-color": "black",
    "font-size": "1rem",
    "footer-background-color": "#131a22",
    "primary-heading-size": "26px",
    "primary-heading-weight": "400",
    "primary-heading-color": "#333333",
    "page-secondary-text-size": "13px",
    "page-secondary-text-color": "#313131",
    "tertiary-font-color": "whitesmoke",
    "page-body-text-size": "16px",
    "page-body-text-color": "#313131",
    "dialog-header-text-size": "20px",
    "dialog-header-text-color": "#313131",
    "dialog-body-text-size": "16px",
    "dialog-body-text-color": "#313131",
    "primary-font-size": "25px",
    "secondary-font-size": "14px",
    "special-font-family": "Montserrat",
    "note-font-size": "12px",
    "note-font-color": "grey",
    "mobile-primary-color": "white",
    "mobile-secondary-color": "#744a1d",
    "mobile-primary-font-color": "#313131",
    "mobile-secondary-font-color": "rgb(121, 120, 120)",
    "mobile-font-size": "1rem",
    "mobile-active-color": "rgba(235, 232, 233, 0.58)"
  };
  /**
   * Variable used to store error messages.
   * @type {object}
   */
  errorMessages = {
    required: 'This field is required.',
    minlenght: 'This field should greater than requiredLength characters!',
    maxlength: 'This field should not exceed the requiredLength characters!',
    noSpaceValidationPattern: 'This field does not accept empty spaces!',
    noSpaceInBegAndEnd: 'This field does not accept empty spaces at the beginning or end',
    acceptsCharacterWithSpaces: 'This field accepts only characters.',
    acceptOnlyNumbers: 'Please enter a valid number.',
    max: 'Value exceeds the maximum limit.',
    min: 'Value less than the minimum limit.',
    facebookLinkValidation: 'Please enter a valid facebook link address.',
    youtubeLinkValidation: 'Please enter a valid youtube link address.',
    instagramLinkValidation: 'Please enter a valid instagram link address.',
    buttonUrlName: 'Name required!',
    buttonLink: 'Button Url required!',
    buttonTextNoSpace: 'Button Text should not accept empty spaces at the beginning and end!',
    buttonLinkNoSpace: 'Page to redirect should not accepts empty space in beginning and end!',
    emptyString: 'This field does not accept empty spaces!'
  };
  /**
   * Variable used to store page name.
   * @type {array}
   */
  pagesName = ['Footer', 'Cart', 'Checkout', 'Blogs', 'Blog Categories', 'Blog Details', 'Global Theme', 'Review Details', 'Global theme', 'Global Settings', 'Product Details', 'Discount Details'];

  /**
   * Variable is used to store banner button layout.
   * @type {array}
   */
  buttonLayout = ['row', 'column'];
  /**
   * Variable used to store banner image background sze type.
   * @type {array}
   */
  imageBackgroundSizeTypes = [{ name: 'Cover', value: 'cover' }, { name: 'Fill (100% 100%)', value: '100% 100%' }];
  /**
   * Variable used to store image banner  background size type.
   * @type {Array}
   */
  imageBannerBackgroundSizeTypes: Array<any> = [{ name: 'Cover', value: 'cover', icon: 'crop_free' }, { name: 'Fill (100% 100%)', value: '100% 100%', icon: 'aspect_ratio' }];
  /**
   * Variable used to store image banner  button animation types.
   * @type {Array}
   */
  imageBannerHeadingDesign: Array<any> = [
    { id: false, name: 'None' },
    { id: 1, name: 'Design 1' },
    { id: 2, name: 'Design 2' },
    { id: 3, name: 'Design 3' },
    { id: 4, name: 'Design 4' }
  ]
  /**
   * Variable used to store image banner  button animation types.
   * @type {Array}
   */
  imageBannerBadgePosition: Array<any> = [
    { name: 'Top left', value: 'start start' },
    { name: 'Top right', value: 'end start' },
    { name: 'Center left', value: 'start center' },
    { name: 'Center right', value: 'end center' },
    { name: 'Bottom left', value: 'start end' },
    { name: 'Bottom right', value: 'end end' }
  ]
  /**
   * Variable to store constant values for section details in storefront editor.
   * @type {Object}
   */
  messages = {
    existingSection: 'existingSection',
    unLayeredSection: 'unLayeredSection',
    addSection: 'Add Section',
    sectionName: 'Section Name',
    sectionNameRequired: 'Section Name is Required',
    characterOnly: 'Section name accepts only character.',
    minLength: 'Section Name ',
    goButton: 'Go',
    backButton: 'Back',
    addExistingSection: 'Add a section from our existing section templates',
    addNewSection: ' Create your own section',
    editSection: 'Change Section Name',
    updateSectionName: 'Update Section Name',
    createNewPage: 'Create a Page',
    requiredMessage: ' is required.',
    maxLengthMessage: ' should not exceed 35 characters!',
    pageTitleMaxLength: 'Page Title should not exceed 15 characters!',
    spacePattern: ' does not accepts whitespace!',
    alreadyExist: ' already exists!',
    pageLinkText: 'Page Link',
    pageTitleText: 'Page Title',
    sectionTypeText: 'Section Type',
    pageLinkValidator: "Page link should not accept empty space and special characters, use '_' instead."
  };
  /**
   * Variable used to store week days
   */
  weekDays = [
    'Sun',
    'Mon',
    'Tue',
    'Wed',
    'Thu',
    'Fri',
    'Sat'
  ];
  /**
   * Variable used to store top navbar json details.
   * @type {Object}
   */
  topnavbarDetails = {
    "id": 0,
    "sectionName": "Top Nav Bar",
    "isExistingComponent": true,
    "selectorDetails": {
      "selector": "phase-ii-top-nav-bar",
      "inputData": [
        {
          "selectorInput": "isTransparent",
          "handlerInput": "previewDetails.storePageSetup.savedPageContent"
        },
        {
          "selectorInput": "color",
          "handlerInput": "previewDetails.storePageSetup.savedPageContent"
        },
        {
          "selectorInput": "navbarColor",
          "handlerInput": "previewDetails.storePageSetup.savedPageContent"
        },
        {
          "selectorInput": "isEditor",
          "handlerInput": "sampleData.isEditor"
        }
      ]
    },
    "sampleData": { "isEditor": true }
  };
  /**
   * Variable used to store component selector details.
   * @type {Object}
   */
  selectorDetails = {
    topNavbar: 'phase-ii-top-nav-bar',
    productDetails: 'phase-ii-common-product-details',
    reviewDetails: 'phase-ii-reviews-ratings-details',
    discountDetails: 'phase-ii-buyx-gety-list',
    chocolateRecentlyViewed: 'phase-ii-chocolate-theme-feature-contents',
    recentlyViewedProducts: 'phase-ii-recently-viewed-products',
    frequentlyBoughtTogether: 'phase-ii-frequently-bought-together'
  };
  /**
   * Variable used to store guest role name.
   * @type {string}
   */
  guest = 'Guest';
  /**
   * Variable used to store contact us selector details.
   * @type {Array}
   */
  contactUsSelector = [
    "phase-ii-common-form",
    "phase-ii-retail-contactus-form",
    "phase-ii-cafe-home-contact-us",
    "phase-ii-restaurant-theme2-contactus-imagecard",
    "phase-ii-grocery-contact-us-page",
    "phase-ii-bakery-theme2-contactus-form",
    "phase-ii-desi-neiborhood-contactus",
    "phase-ii-small-scale-shop-theme-contactus",
    "phase-ii-chocolate-contactus",
    "phase-ii-painting-theme-contactus",
    "phase-ii-cafe-theme-contact-card",
    "phase-ii-fashion-contactus-form",
    "phase-ii-bouquet-contactus-form",
    "phase-ii-children-item-message",
    "phase-ii-women-accessories-contactus",
    "phase-ii-beverage-contact-us",
    "phase-ii-retail-theme3-contact-us",
    "phase-ii-decorator-contact-us",
    "phase-ii-children-item-contact-us",
    "phase-ii-art-contact-card",
    "inkless-library-contactus",
    "phase-ii-furniflare-contactus-form",
    "phase-ii-growton-contactus",
    "inkless-library-contactus",
    "phase-ii-furniture-theme-contact-us",
    "phase-ii-book-contact-us",
    "phase-ii-cosmetic-theme-contact-us",
    "phase-ii-boutique-contactus",
    'phase-ii-grocery-hub-contactus-form',
    "phase-ii-jewellery-contactus-form",
    "phase-ii-greensage-contactus-form",
    'phase-ii-hh-contactus-form',
    'phase-ii-gadgeted-theme-contact-us',
    'oceanic-contactus',
    'quillzipper-contactus-form',
    'phase-ii-jewellery-theme-two-contact-us',
    'phase-ii-painting-theme-iii-contactus-formsection',
    'phase-ii-health-and-wellness-contactus',
    'phase-ii-radiance-contactus-form',
    'phase-ii-candella-contactus-form',
    'phase-ii-contactus-message',
    'phase-ii-storefront-sign-in',
    'phase-ii-bakery-theme2-contactus-form',
    'phase-ii-coffee-contact-glass-effect-banner',
    'phase-ii-dellizziosa-contactus-form',
    'phase-ii-cascade-contactus',
    'phase-ii-motor-marvel-contact-us',
    'phase-ii-retail-contact-us',
    'phase-ii-pine-perfection-contactus-form',
    'phase-ii-pork-theme-contact-us',
    'phase-ii-pure-plate-pantry-contactus-form',
    'phase-ii-electronics-contact-us',
    'phase-ii-bliss-contactusform',
    'phase-ii-metics-contactus-form',
    'phase-ii-machoman-contactus',
    'phase-ii-green-viva-contactusform',
    'phase-ii-nascent-contact-us',
    'phase-ii-decoratin-contactus-form',
    'phase-ii-carnivore-cart-contactus-form',
    'phase-ii-tools2-contactus-form',
    'phase-ii-jewellery-contactus-slider',
    'phase-ii-pork-theme-our-categories',
    'phase-ii-restaurant-contactus-imagecard',
    'grocery9-contactus-form',
    'phase-ii-camera-theme-message'
  ];
  /**
   * Variable used to store contact us selector details.
   * @type {Array}
   */
  dynamicDataSelectors = [
    "phase-ii-common-home-page-brand-list",
    "phase-ii-coffee-home-details",
    "phase-ii-home-product",
    "phase-ii-retail-aboutus-testimonials",
    "phase-ii-desi-neighbor-categories",
    "phase-ii-restaurant-theme2-top-category",
    "phase-ii-restaurant-product-category",
    "phase-ii-bakery-theme2-paragraph",
    "phase-ii-bakery-category",
    "phase-ii-bakery-home-category-list",
    "phase-ii-retail-home-product-banner",
    "phase-ii-retail-home-about-us",
    "phase-ii-small-shop-testimonials",
    "phase-ii-retail-theme3-blogs",
    "phase-ii-common-slider",
    "phase-ii-retail-theme3-testimonials",
    "phase-ii-retail-theme2-feature-products1",
    "phase-ii-retail-theme2-featured-categories1",
    "phase-ii-grocery-theme2-offers",
    "phase-ii-grocery-theme2-testimonial",
    "phase-ii-grocery-theme2-featured-products",
    "phase-ii-grocery-theme2-featured-categories",
    "phase-ii-chocolate-theme-feature-contents",
    "phase-ii-painting-inspiring-art",
    "phase-ii-cafe-theme-testimonial",
    "phase-ii-cafe-best-seller",
    "phase-ii-art-theme-banner",
    "phase-ii-art-and-handcraft-featured-products",
    "phase-ii-beverage-aboutus-timeline",
    "phase-ii-beverage-products-category",
    "phase-ii-beverage-feautured-category",
    "phase-ii-sporting-goods-testimonial",
    "phase-ii-sporting-goods-categories",
    "phase-ii-music-instrument-homeslider",
    "phase-ii-electronic-testimonial",
    "phase-ii-pastry-testimonial",
    "phase-ii-nascent-blogs",
    "phase-ii-metics-image-hotspot",
    "phase-ii-accessories-testimonials",
    "phase-ii-about-us-mission",
    "phase-ii-decorator-theme-products",
    "phase-ii-children-item-home",
    "phase-ii-children-item-contact-us",
    "phase-ii-children-item-about-us",
    "phase-ii-children-item-feautured-products",
    "phase-ii-children-item-products-category",
    "phase-ii-children-item-testimonials",
    "phase-ii-decorator-theme-products",
    "phase-ii-cascade-image-hotspots",
    "phase-ii-daffodil-image-gallery",
    "phase-ii-dellizziosa-special-menu",
    "phase-ii-machoman-product-details",
    "phase-ii-machoman-testimonials",
    "phase-ii-macho-home-categories",
    "phase-ii-nascent-testimonial",
    "phase-ii-metics-home-featuredcategories",
    "phase-ii-lens-loom-testimonial",
    "phase-ii-lens-catagories",
    "phase-ii-beverage-home-blogs",
    'phase-ii-nascent-featured-products',
    "phase-ii-nascent-location-details",
    "phase-ii-play-feast-slider",
    "phase-ii-casecade-products",
    "phase-ii-men-accessories-testimonials",
    "phase-ii-woman-accessories-featuredcategory",
    "phase-ii-woman-accessoris-location",
    'phase-ii-woman-accessories-testimonial',
    "phase-ii-casecade-products",
    "phase-ii-crust-delights-home-menu",
    'phase-ii-outfit-aboutus-team',
    'phase-ii-outfit-contactus-footer',
    "phase-ii-growton-aboutus-testimonial",
    "phase-ii-growton-categories",
    "phase-ii-growton-home-blogs",
    "phase-ii-dellizziosa-about-us-chefs",
    "phase-ii-furniflare-home-blogs",
    "phase-ii-car-parts-cove-contact-us-location-details",
    "phase-ii-furniflare-about-us-testimonial",
    "phase-ii-furniflare-home-products",
    "phase-ii-car-parts-cove-about-us-testimonials",
    "phase-ii-car-parts-cove-home-featuredproductslist",
    "phase-ii-car-parts-cove-home-category",
    "phase-ii-car-parts-cove-home-brands",
    "phase-ii-carnivore-cart-why-us",
    "phase-ii-carnivore-cart-categories",
    "phase-ii-carnivore-cart-testimonials",
    "phase-ii-jewellery-theme2-home-testimonials",
    "phase-ii-jewellwery-theme2-home-featuredcategories",
    'phase-ii-pure-plate-pantry-testimonial',
    'phase-ii-radiance-featured-categories',
    'phase-ii-candella-featured-products',
    "phase-ii-jewellery-theme-home-exclusiveproduct",
    "phase-ii-jewellery-theme-two-featured-products",
    "phase-ii-painting-theme-iii-category",
    "phase-ii-casecade-products",
    'phase-ii-hh-home-testimonial',
    'phase-ii-hh-featured-categories',
    'phase-ii-hh-featured-product',
    "phase-ii-outfit-home-testimonial",
    "phase-ii-outfit-home-product",
    "phase-ii-outfit-home-collections",
    "phase-ii-aboutus-our-team-is-here",
    "phase-ii-pine-perfection-testimonials",
    "phase-ii-play-and-feast-product-list",
    "phase-ii-play-feast-testimonial",
    "phase-ii-manufacturing-process",
    "phase-ii-children-item-blogs",
    "phase-ii-bliss-theme-our-collections",
    "aesthetic-feature-product",
    "aesthetic-category-product",
    "meat-category-list",
    "app-product-slider",
    "oceanic-featured-products",
    "quillzipper-aboutus-testimonial",
    "quillzipper-home-featureproducts",
    "phase-ii-woodenwebstore-aboutus-testimonial",
    "phase-ii-chocolate3-contactus",
    "phase-ii-tools-testimonial",
    "phase-ii-grocery-hub-aboutus-why-choose-us",
    "phase-ii-motor-marvel-home-testimonials",
    "phase-ii-bijoux-aboutus-brands",
    "phase-ii-bijoux-aboutus-testimonials",
    "phase-ii-common-blogs",
    "phase-ii-hh-aboutus-work",
    "phase-ii-hh-aboutus-artists",
    "phase-ii-hh-aboutus-choose-us",
    "phase-ii-gift-home-grid",
    "phase-ii-gift-blog-testimonials",
    "phase-ii-gift-aboutus-collab",
    "phase-ii-common-parent-product-list",
    "phase-ii-painting-theme-product-list",
    "phase-ii-health-and-wellness-products",
    'phase-ii-electronic-products',
    "phase-ii-handcraft-home-blog",
    'phase-ii-women-accessories-product',
    'phase-ii-bliss-home-featured-categories',
    'phase-ii-play-feast-contactus-form',
    'phase-ii-retail5-contact-us',
    'phase-ii-digital-product-contactus',
    'phase-ii-garden-contact-form',
    'phase-ii-electronics-contact-us',
    'phase-ii-digital-product-testimonial',
    'phase-ii-digital-product-list',
    'phase-ii-digital-prod-popular-books',
    'phase-ii-digital-product-books-counts',
    'phase-ii-mosaicko-contactus',
    'phase-ii-garden-featured-categories',
    'phase-ii-garden-product',
    'phase-ii-garden-testimonials',
    'phase-ii-handy-paintings-home-banner',
    'phase-ii-crust-delights-home-banner',
    'phase-ii-grocery-theme-three-home-banner',
    'phase-ii-jewellery-contactus-slider',
    'phase-ii-comforto-feature-category',
    'phase-ii-retail-theme-brands',
    'phase-ii-reatil-theme-banner',
    'phase-ii-retail-theme-v-top-products',
    'phase-ii-retail-theme-brands',
    'phase-ii-testimonials',
    'phase-ii-retail-theme-aboutus3',
    'phase-ii-category-section',
    'phase-ii-testimonial',
    "phase-ii-home-sculpture-about-us-testimonials",
    "home-sculpture-contact-us-location-details",
    "phase-ii-cars-parts-cove-about-us-testimonial",
    "phase-ii-grocery-theme-three-home-banner",
    'phase-ii-candella-featured-categories',
    'phase-ii-candella-featured-products',
    "phase-ii-aboutus-testimonials",
    "phase-ii-radiance-testimonial-section",
    "phase-ii-candella-aboutus-testimonials",
    'inkless-library-product-lists',
    'inkless-library-popular-books',
    'phase-ii-comforto-banner',
    'phase-ii-comforto-home-view-more',
    'phase-ii-comforto-aboutus-features',
    'phase-ii-comforto-interior-team',
    'phase-ii-slumberillow-offer',
    'phase-ii-slumberillow-testimonial',
    'phase-ii-melodiam-ourteam',
    'phase-ii-melodiam-category',
    'phase-ii-luxe-literature-aboutus-features',
    'phase-ii-luxe-literature-store-events',
    'phase-ii-luxe-literature-testimonial',
    'phase-ii-xclusive-home-banner',
    'phase-ii-xclusive-how-we-work',
    'phase-ii-xclusive-glow-testimonials',
    'phase-ii-mosaicko-aboutus-experience',
    'phase-ii-xclusive-glow-ourclients',
    'phase-ii-slumberillow-aboutus-features',
    'phase-ii-mosaicko-aboutus-projects',
    'phase-ii-mosaicko-aboutus-client',
    'phase-ii-mosaicko-categories',
    'phase-ii-mosaicko-how-we-work',
    'phase-ii-mosaicko-home-banner',
    'phase-ii-mosaicko-featured-products',
    'phase-ii-mosaicko-ourteam',
    'phase-ii-melodiam-aboutus-features',
    'phase-ii-melodiam-aboutus-ourbrand',
    'phase-ii-melodiam-aboutus-testimonial',
    'phase-ii-mosaicko-testimonial',
    "phase-ii-grocery-theme-three-aboutus-testimonials",
    "phase-ii-cosmetic-theme-testimonial",
    "phase-ii-cosmetic-theme-contact-us-philosophy",
    "phase-ii-cosmetic-theme-banner-category",
    "phase-ii-cosmetics-theme-offer-banner",
    "phase-ii-cosmetic-theme-offer-zone",
    "phase-ii-book-authors",
    "phase-ii-book-store-aboutus2",
    "phase-ii-book-stack",
    "phase-ii-book-about-us",
    "phase-ii-furniture-theme-testimonials",
    "phase-ii-furniture-theme-brands",
    "phase-ii-boutique-home-category",
    "phase-ii-boutique-about-us-locations",
    "phase-ii-boutique-home-trending",
    "phase-ii-handcraft-home-banner",
    "phase-ii-handcraft-aboutus-testimonials",
    "phase-ii-handcraft-home-featured-products",
    "phase-ii-walk-in-vogue-home-offer",
    "phase-ii-walk-in-vogue-contactus-details",
    "phase-ii-walk-in-vogue-aboutus-testimonials",
    "phase-ii-walk-in-vogue-aboutus-timeline",
    "phase-ii-walk-in-vogue-home-blog",
    "phase-ii-walk-in-vogue-home-featured-products",
    "phase-ii-walk-in-vogue-featured-categories",
    "phase-ii-grocery-hub-home-featured-products",
    "phase-ii-grocery-hub-home-banner",
    "phase-ii-grocery-hub-contactus-form",
    "phase-ii-grocery-hub-home-featuredcategories",
    "phase-ii-grocery-hub-aboutus-testimonials",
    "phase-ii-jewellery-collection",
    "phase-ii-jewellery-testimonails",
    "phase-ii-jewellery-branding",
    'phase-ii-jewellery-contactus-slider',
    "phase-ii-greensage-home-banner",
    "phase-ii-greensage-home-featured-products-categories",
    "phase-ii-greensage-aboutus-team",
    "phase-ii-greensage-aboutus-testimonials",
    "phase-ii-greensage-aboutus-our-show",
    "phase-ii-mosaicko-expertise",
    "phase-ii-common-product-details",
    "phase-ii-babycado-offer",
    'phase-ii-outfit-home-product',
    'phase-ii-outfit-home-testimonial',
    'phase-ii-outfit-contactus-footer',
    'phase-ii-outfit-aboutus-team',
    'phase-ii-gaming-categories',
    'phase-ii-gaming-product',
    'phase-ii-carnivore-cart-our-team',
    'phase-ii-woody-nest-contactus-location-details',
    'phase-ii-woody-nest-home-testimonial',
    'phase-ii-jewellery-contactus-slider',
    'phase-ii-bijoux-product-list',
    'phase-ii-gift-product-list',
    "phase-ii-bijoux-home-benefits",
    "phase-ii-bijoux-home-featured-category",
    "phase-ii-bijoux-home-featured-products",
    "phase-ii-bouquet-home-products",
    "phase-ii-categories-slider",
    "phase-ii-beverages3-productlist",
    "phase-ii-beverages3-testimonials",
    "phase-ii-contact-location-map",
    "phase-ii-purexara-featured-categories",
    "phase-ii-woody-nest-home-testimonial",
    "grocery9-aboutus-deal-of-the-week",
    "grocery9-home-product",
    "grocery9-home-product-review",
    "phase-ii-painting-theme-iii-aboutus-testimonials",
    "phase-ii-radiance-contactus-locaionlist",
    "phase-ii-fresh-flare-grocers-testimonial",
    "phase-ii-fresh-flare-grocers-category",
    "phase-ii-fresh-flare-grocers-contactus-information",
    "phase-ii-digi-arora-category",
    "phase-ii-digi-arora-feature-products",
    "phase-ii-digi-arora-our-story",
    "phase-ii-digi-arora-shop-now",
    "phase-ii-alleyway-artisans-contactus-gallery",
    "phase-ii-alleyway-artisans-categories",
    "phase-ii-alleyway-artisans-counter",
    "phase-ii-alleyway-artisans-aboutus-features",
    "phase-ii-alleyway-artisans-aboutus-history",
    "phase-ii-alleyway-artisans-featured-products",
    "phase-ii-alleyway-artisans-home-banner",
    "phase-ii-alleyway-artisans-gallery",
    "phase-ii-fresh-delivers-featured-product",
    "phase-ii-fresh-delivers-how-we-work",
    "phase-ii-lumi-glow-aboutus-testimonials",
    "phase-ii-lumi-glow-skincare",
    "phase-ii-santa-surprise-testimonials",
    "phase-ii-grind-grove-home-banner",
    "phase-ii-grind-grove-home-bestsellers",
    "phase-ii-dermaglow-contactus-store-locations",
    "phase-ii-derma-aboutus-testimonials",
    "phase-ii-grind-grove-aboutus-timeline",
    "phase-ii-grind-grove-aboutus-faqs",
    "phase-ii-grind-grove-aboutus-testimonials",
    "phase-ii-derma-glow-home-banner",
    "phase-ii-derma-glow-home-featured-categories",
    "phase-ii-derma-glow-home-featured-products",
    "phase-ii-hand-held-hub-home-testimonial",
    "phase-ii-santa-surprise-feature-products",
    "phase-ii-gadget-nex-brands",
    "phase-ii-purexara-aboutus-testimonial",
    "phase-ii-recently-viewed-products",
    "phase-ii-delights-testimonial",
    "phase-ii-frequently-bought-together",
    "phase-ii-green-viva-testimonial",
    "phase-ii-green-viva-featured-categories",
    "phase-ii-green-viva-featured-products",
    "phase-ii-tools2-aboutus-testimonials",
    "phase-ii-tool2-featured-product",
    "phase-ii-reatil-theme-top-categories",
    "phase-ii-hand-held-hub-contactus-social-media"
  ];
  /**
   *  Variable used to store product list selector details.
   * @type {Array}
  */
  productListPage = [
    'phase-ii-painting-theme-product-list',
    'phase-ii-common-parent-product-list',
    'phase-ii-gaming-product-list',
    'phase-ii-play-and-feast-product-list',
    'phase-ii-bijoux-product-list',
    'phase-ii-gift-product-list'
  ];
  /**
   * Variable used to store image banner property types.
   * @type {array}
   */
  imageBannerProperties = [{
    id: 1,
    name: 'image',
    toolTip: 'Background image'
  }, {
    id: 2,
    name: 'format_color_fill',
    toolTip: 'Background color'
  }];
  /**
   * Variable used to store image banner property types.
   * @type {array}
   */
  happyCustomerProperties = [{
    id: 1,
    name: 'image',
    toolTip: 'Background Image'
  }, {
    id: 2,
    name: 'format_color_fill',
    toolTip: 'Background Color'
  }];
  /**
   * Variable used to store Image banner heading text alignement options.
   * @type {array}
   */
  headingTextAlignOptions = [{
    id: 1,
    name: 'format_align_left',
    value: 'Left'
  }, {
    id: 2,
    name: 'format_align_center',
    value: 'Center'
  }, {
    id: 3,
    name: 'format_align_right',
    value: 'Right'
  }];
  /**
   * Variable used to store theme code details.
   */
  themeCode = {
    desineighbor: 'DESINEIGHBOR',
    painting: 'PAINTING',
    cascade: 'CASCADE_THEME',
    opulence: 'OPULENCETHEME',
    palettify: 'PALETTIFYTHEME',
    vitalfit: 'VITALFITTHEME',
    radiance: 'RADIANCETHEME',
    candella: 'CANDELLATHEME',
    metics: 'THEME_METICS',
    purePlatePantry: 'PURE_PLATE_PANTRY',
    juzzcandy: 'JUZZ_CANDY',
    waveSurfers: 'WAVEZ_SURFER',
    handypainting: 'HANDY_PAINTING',
    gaming: 'GAMING_THEME',
    playAndFeast: 'PLAY_AND_FEAST',
    bijouxBox: 'BIJOUX_BOX',
    gift: 'GIFT',
    metal: 'METAL_THEME',
    opalineTiles: 'OPALINE_TILES',
    santaSurprise: 'SANTA_SURPRISE_THEME',
    electronics: 'ELECTRONICS',
    digiArora: 'DIGI_ARORA'
  };
  /**
   * Variable used to store the file format details.
   */
  fileExtensions = ['jpg', 'png', 'jpeg', 'gif', 'webp'];
  /**
   * Variable used to store the file format details.
   */
  videoExtensions = ['mp4'];
  /**
   * Variable used to hold font style values.
   */
  fontStyleValues = {
    bold: 'B',
    italic: 'I',
    underline: 'U'
  };
  /**
   * Variable used to hold font style values.
   */
  borderPositionValues = {
    top: 't',
    bottom: 'b',
    left: 'l',
    right: 'r'
  };
  /**
   * Variable used to store font families.
   */
  fontFamily = [
    { name: 'ABeeZee', value: "'ABeeZee', sans-serif" },
    { name: 'Amatic SC', value: "'Amatic SC', sans-serif" },
    { name: 'AR One Sans', value: "'AR One Sans', sans-serif" },
    { name: 'Archivo', value: "'Archivo', sans-serif" },
    { name: 'Arima', value: "'Arima', system-ui" },
    { name: 'Cormorant', value: "'Cormorant', serif" },
    { name: 'Crimson Text', value: "'Crimson Text', serif" },
    { name: 'Dancing Script', value: "'Dancing Script', serif" },
    { name: 'EB Garamond', value: "'EB Garamond', serif" },
    { name: 'Exo 2', value: "'Exo 2', sans-serif" },
    { name: 'Handlee', value: "'Handlee', cursive" },
    { name: 'Heebo', value: "'Heebo', sans-serif" },
    { name: 'Inconsolata', value: "'Inconsolata', monospace" },
    { name: 'Instrument Sans', value: "'Instrument Sans', sans-serif" },
    { name: 'Inter', value: "'Inter', sans-serif" },
    { name: 'Jost', value: "'Jost', sans-serif" },
    { name: 'Kalam', value: "'Kalam', cursive" },
    { name: 'Lato', value: "'Lato', sans-serif" },
    { name: 'Libre Baskerville', value: "'Libre Baskerville', serif" },
    { name: 'Lora', value: "'Lora', serif" },
    { name: 'Mitr', value: "'Mitr', sans-serif" },
    { name: 'monospace', value: 'monospace' },
    { name: 'Montserrat', value: "'Montserrat', sans-serif" },
    { name: 'Nunito', value: "'Nunito', sans-serif" },
    { name: 'Open sans', value: "'Open Sans', sans-serif" },
    { name: 'Oswald', value: "'Oswald', sans-serif" },
    { name: 'Playfair Display', value: "'Playfair Display', serif" },
    { name: 'Poppins', value: "'Poppins', sans-serif" },
    { name: 'Press Start 2P', value: "'Press Start 2P', cursive" },
    { name: 'Questrial', value: "'Questrial', sans-serif" },
    { name: 'Quicksand', value: "'Quicksand', serif" },
    { name: 'Raleway', value: "'Raleway', sans-serif" },
    { name: 'Reem Kufi', value: "'Reem Kufi', sans-serif" },
    { name: 'Roboto', value: "'Roboto', sans-serif" },
    { name: 'Roboto Condensed', value: "'Roboto Condensed', sans-serif" },
    { name: 'Roboto Mono', value: "'Roboto Mono', monospace" },
    { name: 'Satisfy', value: "'Satisfy', cursive" },
    { name: 'Sofia', value: "'Sofia', sans-serif" },
    { name: 'Sorts Mill Goudy', value: "'Sorts Mill Goudy', serif" },
    { name: 'Tangerine', value: "'Tangerine', cursive" }
  ];

  /**
   * Variable used to store the file format details.
   */
  imageFileFormat = ['jpg', 'png'];
  /**
   * Variable used to store additional page details
   */
  additionalPages = [
    { page: { pageLink: "deals", pageTitle: "Deals Page" } },
    { page: { pageLink: "cart", pageTitle: "Cart Page" } },
    { page: { pageLink: "store/faq", pageTitle: "FAQ Page" } },
    { page: { pageLink: "checkout", pageTitle: "Checkout Page" } },
    { page: { pageLink: "brandlist", pageTitle: "Brands Page" } }
  ];
  commonSliderConstant = {
    /**
   * variable used to store card styles
   */
    featuredProducts: {
      cardDisplayStyle: [
        { imageURL: './assets/common-slider-images/card-display-1.jpg', name: 'Style 1', id: 'CardStyle1' },
        { imageURL: './assets/common-slider-images/card-display-2.jpg', name: 'Style 2', id: 'CardStyle2' },
        { imageURL: './assets/common-slider-images/card-display-3.jpg', name: 'Style 3', id: 'CardStyle3' },
        { imageURL: './assets/common-slider-images/card-display-8.png', name: 'Style 4', id: 'retailTemplate' },
        { imageURL: './assets/common-slider-images/card-display-6.png', name: 'Style 5', id: 'retailTemplate2' },
        { imageURL: './assets/common-slider-images/card-display-11.png', name: 'Style 6', id: 'furniture' },
        { imageURL: './assets/common-slider-images/card-display-8.png', name: 'Style 7', id: 'chocolateTheme' },
        { imageURL: './assets/common-slider-images/card-display-7.png', name: 'Style 8', id: 'grocerytheme2' },
        { imageURL: './assets/common-slider-images/card-display-11.png', name: 'Style 9', id: 'bookTheme' },
        { imageURL: './assets/common-slider-images/card-display-9.png', name: 'Style 10', id: 'cafeTheme' },
        { imageURL: './assets/common-slider-images/card-display-5.png', name: 'Style 11', id: 'cosmeticsTheme' },
        { imageURL: './assets/common-slider-images/card-display-8.png', name: 'Style 12', id: 'electronicsProduct' },
        { imageURL: './assets/common-slider-images/fashionnewproductstyle.png', name: 'Style 13', id: 'fashionNewProducts' },
        { imageURL: './assets/common-slider-images/card-display-5.png', name: 'Style 14', id: 'womenAccessoriesProducts' },
        { imageURL: './assets/common-slider-images/card-display-9.png', name: 'Style 15', id: 'meticsTheme' },
        { imageURL: './assets/common-slider-images/card-display-30.gif', name: 'Style 16', id: 'PetsCanineProducts' },
        { imageURL: './assets/common-slider-images/card-display-1.jpg', name: 'Style 17', id: 'handypaintingtheme' },
        { imageURL: './assets/common-slider-images/card-display-1.jpg', name: 'Style 18', id: 'slumberillowProducts' },
        { imageURL: './assets/common-slider-images/card-display-1.jpg', name: 'Style 19', id: 'handstohandsgrocerytemplate' },
        { imageURL: './assets/common-slider-images/card-display-7.png', name: 'Style 20', id: 'motorMarvelTheme' },
        { imageURL: './assets/common-slider-images/fashionnewproductstyle.png', name: 'Style 21', id: 'carnivoreCart' },
        { imageURL: './assets/common-slider-images/card-display-2.jpg', name: 'Style 22', id: 'lensTheme' },
        { imageURL: './assets/common-slider-images/card-display-14.png', name: 'Style 23', id: 'compactgrocerytheme' },
        { imageURL: './assets/common-slider-images/card-display-27.gif', name: 'Style 24', id: 'babyCadoProducts' },
        { imageURL: './assets/common-slider-images/card-display-25.gif', name: 'Style 25', id: 'daffodilsTheme' },
        { imageURL: './assets/common-slider-images/card-display-9.png', name: 'Style 26', id: 'pinePerfectTheme' },
        { imageURL: './assets/common-slider-images/card-display-9.png', name: 'Style 27', id: 'metalTheme' },
        { imageURL: './assets/common-slider-images/card-display-1.jpg', name: 'Style 28', id: 'opalineTiles' },
        { imageURL: './assets/common-slider-images/card-display-9.png', name: 'Style 29', id: 'homeSculptureProduct' },
        { imageURL: './assets/common-slider-images/card-display-1.jpg', name: 'Style 30', id: 'vividProducts' },
        { imageURL: '../assets/common-slider-images/card-display-8.png', name: 'Style 31', id: 'DigitalProductCategory_TRENDS' },
        { imageURL: '../assets/common-slider-images/card-display-8.png', name: 'Style 32', id: 'DigitalProductCategory_PRODUCT' },
        { imageURL: './assets/common-slider-images/card-display-9.png', name: 'Style 33', id: 'greenViva' },
        { imageURL: './assets/common-slider-images/card-display-8.png', name: 'Style 34', id: 'freshflareProduct' },
        { imageURL: './assets/common-slider-images/card-display-8.png', name: 'Style 35', id: 'woodyNestProduct' },
        { imageURL: '../assets/common-slider-images/card-display-8.png', name: 'Style 36', id: 'elysianFeaturedProducts' },
        { imageURL: './assets/common-slider-images/card-display-31.gif', name: 'Style 37', id: 'handHeldHubFeaturedProducts' },
      ]
    },
    featuredCategory: {
      cardDisplayStyle: [
        { imageURL: './assets/common-slider-images/card-display-1.jpg', name: 'Style 1', id: 'CardStyle1' },
        { imageURL: './assets/common-slider-images/card-display-2.jpg', name: 'Style 2', id: 'CardStyle2' },
        { imageURL: './assets/common-slider-images/card-display-3.jpg', name: 'Style 3', id: 'CardStyle3' },
        { imageURL: './assets/common-slider-images/card-display-2.jpg', name: 'Style 4', id: 'bakeryCategory' },
        { imageURL: './assets/common-slider-images/card-display-4.gif', name: 'Style 5', id: 'bookCategory' },
        { imageURL: './assets/common-slider-images/card-display-11.png', name: 'Style 6', id: 'groceryTheme2Category' },
        { imageURL: './assets/common-slider-images/card-display-10.png', name: 'Style 7', id: 'retailTheme2Category' },
        { imageURL: './assets/common-slider-images/card-display-8.png', name: 'Style 8', id: 'furnitureThemeCategory' },
        { imageURL: './assets/common-slider-images/card-display-9.png', name: 'Style 9', id: 'chocolateTheme' },
        { imageURL: './assets/common-slider-images/card-display-9.png', name: 'Style 10', id: 'retailCategory' },
        { imageURL: './assets/common-slider-images/card-display-9.png', name: 'Style 11', id: 'restaurantTheme1Category' },
        { imageURL: './assets/common-slider-images/card-display-2.jpg', name: 'Style 12', id: 'fashionTrendyCollections' },
        { imageURL: './assets/common-slider-images/card-display-9.png', name: 'Style 13', id: 'chocolateThemeThree' },
        { imageURL: './assets/common-slider-images/card-display-9.png', name: 'Style 14', id: 'artTheme' },
        { imageURL: './assets/common-slider-images/card-display-9.png', name: 'Style 15', id: 'furniflareCategory' },
        { imageURL: './assets/common-slider-images/card-display-9.png', name: 'Style 16', id: 'slumberillowCategory' },
        { imageURL: './assets/common-slider-images/card-display-3.jpg', name: 'Style 17', id: 'pureplatepantryTheme' },
        { imageURL: './assets/common-slider-images/card-display-18.gif', name: 'Style 18', id: 'babyCado' },
        { imageURL: './assets/common-slider-images/card-display-9.png', name: 'Style 19', id: 'woodynestCategory' },
        { imageURL: './assets/common-slider-images/card-display-9.png', name: 'Style 20', id: 'homeSculptureFeaturedCategories' },
        { imageURL: './assets/common-slider-images/card-display-9.png', name: 'Style 21', id: 'tools2' },
        { imageURL: './assets/common-slider-images/card-display-21.gif', name: 'Style 22', id: 'grindGroveTheme' },
        { imageURL: '../assets/common-slider-images/card-display-8.png', name: 'Style 23', id: 'elysianFeaturedCategory' },
        { imageURL: './assets/common-slider-images/card-display-24.jpg', name: 'Style 24', id: 'candellaCategory' }
      ]
    },
    getBrands: {
      cardDisplayStyle: [
        { imageURL: './assets/common-slider-images/card-display-1.jpg', name: 'Style 1', id: 'CardStyle1' },
        { imageURL: './assets/common-slider-images/card-display-2.jpg', name: 'Style 2', id: 'CardStyle2' },
        { imageURL: './assets/common-slider-images/card-display-3.jpg', name: 'Style 3', id: 'CardStyle3' },
        { imageURL: './assets/common-slider-images/card-display-12.png', name: 'Style 4', id: 'chocolateBrand' },
        { imageURL: './assets/common-slider-images/card-display-13.png', name: 'Style 5', id: 'cosmeticsBrand' },
        { imageURL: './assets/common-slider-images/card-display-8.png', name: 'Style 6', id: 'retailBrand' },
        { imageURL: './assets/common-slider-images/card-display-29.jpg', name: 'Style 7', id: 'PetsCanineBrand' }
      ]
    },
    specificCategory: {
      cardDisplayStyle: [
        { imageURL: './assets/common-slider-images/card-display-1.jpg', name: 'Style 1', id: 'CardStyle1' },
        { imageURL: './assets/common-slider-images/card-display-2.jpg', name: 'Style 2', id: 'CardStyle2' },
        { imageURL: './assets/common-slider-images/card-display-3.jpg', name: 'Style 3', id: 'CardStyle3' },
        { imageURL: './assets/common-slider-images/card-display-8.png', name: 'Style 4', id: 'retailTemplate' },
        { imageURL: './assets/common-slider-images/card-display-6.png', name: 'Style 5', id: 'retailTemplate2' },
        { imageURL: './assets/common-slider-images/card-display-11.png', name: 'Style 6', id: 'furniture' },
        { imageURL: './assets/common-slider-images/card-display-8.png', name: 'Style 7', id: 'chocolateTheme' },
        { imageURL: './assets/common-slider-images/card-display-7.png', name: 'Style 8', id: 'grocerytheme2' },
        { imageURL: './assets/common-slider-images/card-display-11.png', name: 'Style 9', id: 'bookTheme' },
        { imageURL: './assets/common-slider-images/card-display-9.png', name: 'Style 10', id: 'cafeTheme' },
        { imageURL: './assets/common-slider-images/card-display-5.png', name: 'Style 11', id: 'cosmeticsTheme' },
        { imageURL: './assets/common-slider-images/card-display-8.png', name: 'Style 12', id: 'electronicsProduct' },
        { imageURL: './assets/common-slider-images/card-display-12.png', name: 'Style 13', id: 'chocolateBrand' },


      ]
    },
    topProducts: {
      cardDisplayStyle: [
        { imageURL: './assets/common-slider-images/card-display-1.jpg', name: 'Style 1', id: 'opalineTiles' }
      ]
    },
    /**
     * variable used to store card animations on hover
     */
    onHover: [
      { imageURL: './assets/common-slider-images/no-animation.png', name: 'Nothing', id: 'Nothing' },
      { imageURL: './assets/common-slider-images/zoom-animation.gif', name: 'Zoom', id: 'Zoom' },
      { imageURL: './assets/common-slider-images/border-animation.gif', name: 'Border', id: 'Border' },
      { imageURL: './assets/common-slider-images/border-animation.gif', name: 'Half border', id: 'HalfBorder' },
      { imageURL: './assets/common-slider-images/border-animation.gif', name: 'Box shadow', id: 'BoxShadow' },

    ],
  }

  navbarColorEditor = [
    "color",
    "navbarColor",
    "navbarCategory",
    "backgroundColor",
    "textColor",
    "hoverBackgroundColor",
    "hoverTextColor",
    "cartIconColor",
    "cartBadgeColor",
    "iconColor",
    "attentionBannerBackgroundColor",
    "attentionBannerTextColor",
    "wishlistIconColor"
  ];
  testimonialError = {
    customerNameReq: 'Customer name is required.',
    customerNameMax: ' Customer name should be less than 50 characters.',
    categoryMax: 'Designation should be less than 120 characters.',
    feedBackReq: 'Feedback is required.',
    feedBackMax: 'Feedback should be less than 250 characters.'
  };
  testimonialRatings = [
    { name: 'None', value: 0 },
    { name: '1 Star', value: 1 },
    { name: '2 Star', value: 2 },
    { name: '3 Star', value: 3 },
    { name: '4 Star', value: 4 },
    { name: '5 Star', value: 5 },

  ]
  columnsForTestimonials = [
    { column: 'imageUrl', header: 'Image', type: 'Image', sort: false, suffixText: 'Image', imageSize: 'large' },
    { column: 'author', header: 'Customer Name', type: 'Text', sort: false, suffixText: 'Customer Name' },
    { column: 'ratings', header: 'Ratings', type: 'Ratings', sort: false, suffixText: 'Ratings' },
    { column: 'createdAt', header: 'Date', type: 'Date', sort: false, suffixText: 'Date' },
    { column: 'publish', header: 'Publish', type: 'Toggle', sort: false, suffixText: 'Publish', iconCondition: { true: 'done', false: 'close' } },
  ];
  testimonialDialogMessages = {
    getTestimonialFailed: 'Failed to get testimonial.',
    noChangesAlert: 'You have no changes to save!',
    mandatoryFields: 'Please fill out the mandatory fields properly!',
    testimonialCreatedSuccessfully: 'Testimonial created successfully.',
    testimonialCreationFailed: 'Failed to create testimonial.',
    testimonialUpdationSuccessfully: 'Testimonial updated successfully.',
    testimonialUpdationFailure: 'Failed to update testimonial.',
    maximumFileUpload: 'Maximum file size (5 MB) exceeded. Please upload a smaller file!',
    fileUploadFormat: 'Please select a valid file format like ',
    testimonialPluginOffMessage: 'Testimonial plugin is turned off. No further action can be taken.',

  }
  fontFamilyControls = [
    'textFontFamily',
    'subtextFontFamily',
    'valueTitleFontFamily',
    'awardHeadingFontFamily',
    'contactUsPageTitleFontFamily',
    'addressLabelFontFamily',
    'contentFontFamily',
    'aboutUsTextFontFamily',
    'videoTitleFontFamily',
    'titleFontFamily',
    'buttontextFontFamily',
    'cardImageTextFontFamily',
    'sectionFontFamily',
    'descriptiontextFontFamily',
    'textTwoFontFamily',
    'textThreeFontFamily',
    'textFourFontFamily',
    'textFiveFontFamily',
    'textSixFontFamily',
    'textOneFontFamily',
    'discounttextFontFamily',
    'textSevenFontFamily',
    'textEightFontFamily',
    'textNineFontFamily',
    'textTenFontFamily',
    'textElevenFontFamily',
    'textTwelveFontFamily',
    'textThirteenFontFamily',
    'textFourteenFontFamily',
    'textFifteenFontFamily',
    'textSixteenFontFamily',
    'requiredFieldFontFamily',
    'numberFontFamily',
    'addressHeadingFontFamily',
    'phoneNoHeadingFontFamily',
    'mailHeadingFontFamily',
    'workingHoursHeadingFontFamily',
    'authorFontFamily',
    'testimonailHeadingFontFamily',
    'testimonailContentFontFamily',
    'testimonialTextFontFamily',
    'bannerContentFontFamily',
    'subtitleFontFamily',
    'SubtexttextFontFamily',
    'priceFontFamily',
    'copyrightTextFontFamily',
    'disclaimerTextFontFamily',
    'secondTitleFontFamily',
    'descriptionStyle'
  ];
  fileSize = 5097152;
  alertForDocuments = {
    image: 'Accepted formats jpg, jpeg, png, gif & webp. Use your own photos instead of downloading images from the internet. Images that are not genuine may be rejected by the system.',
  }
  commonButtonType = [
    {
      name: "Raised",
      value: "raised"
    },
    {
      name: "Normal",
      value: "normal"
    }
  ];
  /**
   * Variable used to store additional page details
   */
  commonButtonAdditionalPages = [
    { page: { pageLink: "deals", pageTitle: "Deals Page" } },
    { page: { pageLink: "cart", pageTitle: "Cart Page" } },
    { page: { pageLink: "store/faq", pageTitle: "FAQ Page" } },
    { page: { pageLink: "checkout", pageTitle: "Checkout Page" } },
    { page: { pageLink: "brandlist", pageTitle: "Brands Page" } },
    { page: { pageLink: "blogs", pageTitle: "Blogs Page" } },
    { page: { pageLink: "featuredProducts", pageTitle: "Featured Products Page" } }
  ];
  /**
   * Variable used to store hover controls.
   * @type {Array}
   */
  hoverControls: Array<string> = ['buttonHoverColor', 'buttonHoverTextColor', 'buttonBorderHoverColor', 'iconHoverColor'];
  /**
   * Variable used to store border controls.
   * @type {Array}
   */
  borderControls: Array<string> = ['buttonBorderThickness', 'buttonBorderPositions', 'buttonBorderColor', 'borderRadiusPositions', 'borderStyle', 'borderPadding'];
  /**
   * Variable used to store color controls.
   * @type {Array}
   */
  colorControls: Array<string> = ['buttonHoverColor', 'buttonHoverTextColor', 'buttonBorderHoverColor', 'buttonBorderColor', 'iconHoverColor', 'buttonShadowColor'];
  /**
   * Variable used to store integer controls.
   * @type {Array}
   */
  integerControls: Array<string> = ['buttonBorderThickness', 'borderPadding'];
  /**
   * Variable used to store button border styles
   * @type {Array}
   */
  buttonBorderStyles: Array<string> = ['solid', 'dashed', 'dotted', 'double', 'groove', 'inset', 'outset', 'ridge']
  commonButtonIcons = {
    iconLayout: [
      { imageURL: './assets/common-slider-images/icon position 2.png', name: 'Right', id: 'row' },
      { imageURL: './assets/common-slider-images/icon position 1.png', name: 'Left', id: 'row-reverse' }
    ],
    row: [
      { imageURL: './assets/common-slider-images/Alignment 1.png', name: 'Left', id: 'start' },
      { imageURL: './assets/common-slider-images/Alignment 2.png', name: 'Center', id: 'center' },
      { imageURL: './assets/common-slider-images/Alignment 4.png', name: 'Right', id: 'end' },
      { imageURL: './assets/common-slider-images/Alignment 3.png', name: 'Justify', id: 'space-between' }
    ],
    'row-reverse': [
      { imageURL: './assets/common-slider-images/Alignment 1.png', name: 'Left', id: 'end' },
      { imageURL: './assets/common-slider-images/Alignment 2.png', name: 'Center', id: 'center' },
      { imageURL: './assets/common-slider-images/Alignment 4.png', name: 'Right', id: 'start' },
      { imageURL: './assets/common-slider-images/Alignment 3.png', name: 'Justify', id: 'space-between' }
    ],
    hoverAnimations: [
      { imageURL: './assets/common-slider-images/hover 1.gif', name: 'Hover 1', id: 'hover1' },
      { imageURL: './assets/common-slider-images/hover 2.gif', name: 'Hover 2', id: 'hover2' },
      { imageURL: './assets/common-slider-images/hover 3.gif', name: 'Hover 3', id: 'hover3' },
      { imageURL: './assets/common-slider-images/hover 4.gif', name: 'Hover 4', id: 'hover4' },
      { imageURL: './assets/common-slider-images/hover 5.gif', name: 'Hover 5', id: 'hover5' },
      { imageURL: './assets/common-slider-images/hover 6.gif', name: 'Hover 6', id: 'hover6' },
      { imageURL: './assets/common-slider-images/hover 7.gif', name: 'Hover 7', id: 'hover7' },
      { imageURL: './assets/common-slider-images/hover 8.gif', name: 'Hover 8', id: 'hover8' },
      { imageURL: './assets/common-slider-images/hover 9.gif', name: 'Hover 9', id: 'hover9' },
      { imageURL: './assets/common-slider-images/hover 10.gif', name: 'Hover 10', id: 'hover10' },
      { imageURL: './assets/common-slider-images/hover 11.gif', name: 'Hover 11', id: 'hover11' },
      { imageURL: './assets/common-slider-images/hover 12.gif', name: 'Hover 12', id: 'hover12' }
    ]
  };
  BannerIcons = {
    imageAnimation: [
      { imageURL: './assets/common-slider-images/no-animation.png', name: 'None', id: 'none' },
      { imageURL: './assets/common-slider-images/zoom-animation.gif', name: 'Zoom', id: 'zoom' },
      { imageURL: './assets/common-slider-images/parallex.gif', name: 'Parallex', id: 'parallex' }
    ],
    iconLayout: [
      { imageURL: './assets/common-slider-images/icon position 2.png', name: 'Right', id: 'row' },
      { imageURL: './assets/common-slider-images/icon position 1.png', name: 'Left', id: 'row-reverse' }
    ],
    spacingModes: [
      { imageURL: './assets/common-slider-images/Alignment 1.png', name: 'Left', id: 'start' },
      { imageURL: './assets/common-slider-images/Alignment 2.png', name: 'Center', id: 'center' },
      { imageURL: './assets/common-slider-images/Alignment 4.png', name: 'Right', id: 'end' },
      { imageURL: './assets/common-slider-images/Alignment 3.png', name: 'Justify', id: 'space-between' },
    ],
    hoverAnimations: [
      { imageURL: './assets/common-slider-images/hover 1.gif', name: 'Hover 1', id: 'hover1' },
      { imageURL: './assets/common-slider-images/hover 2.gif', name: 'Hover 2', id: 'hover2' },
      { imageURL: './assets/common-slider-images/hover 3.gif', name: 'Hover 3', id: 'hover3' },
      { imageURL: './assets/common-slider-images/hover 4.gif', name: 'Hover 4', id: 'hover4' },
      { imageURL: './assets/common-slider-images/hover 5.gif', name: 'Hover 5', id: 'hover5' },
      { imageURL: './assets/common-slider-images/hover 6.gif', name: 'Hover 6', id: 'hover6' }
    ]
  };
  bannerLayout = [{
    "name": "Left",
    "value": "row-reverse"
  },
  {
    "name": "Right",
    "value": "row"
  }];
  imageDesignType = [{
    "name": "Design1",
    "value": "one"
  },
  {
    "name": "Design2",
    "value": "two"
  }];
  buttonType = [
    {
      name: "Flat",
      value: "flat"
    },
    {
      name: "Raised",
      value: "raised"
    },
    {
      name: "Stroked",
      value: "stroked"
    },
    {
      name: "Normal",
      value: "normal"
    }
  ];
  cafeBannerLayout = [
    {
      name: "Vertical",
      value: "row"
    },
    {
      name: "Horizontal",
      value: "column"
    }
  ];
  controlsForImageUpload = [
    "bannerImage",
    "cafeDetails"
  ];
  buttonTextAlignment = [
    {
      name: 'Left',
      value: 'start'
    },
    {
      name: 'Center',
      value: 'center'
    },
    {
      name: 'Right',
      value: 'end'
    },
  ];
  displayProductsOption = [
    {
      "name": "Featured Product",
      "value": "featuredProducts"
    },
    {
      "name": "Featured Category",
      "value": "featuredCategory"
    },
    {
      "name": "Brands",
      "value": "getBrands"
    },
    {
      "name": "Specific Category",
      "value": "specificCategory"
    },
    {
      "name": "Top Products",
      "value": "topProducts"
    }
  ]
  templateFieldNames = {
    CardStyle1: { names: ["textFontFamily", "textFontStyle", "textColor", "onHover", "productInfoAlignment", "textFontSize", "priceFontSize", "priceColor", "imageBackgroundColor", "borderSize", "borderColor", "borderDesign"], values: ["'Montserrat',sans-serif", [], 'black', "Nothing", "center", 14, 14, "#000000", "#ffffff", 0, "#ffffff", "none"] },
    CardStyle2: { names: ["textFontFamily", "textFontStyle", "textColor", "onHover", "productInfoAlignment", "priceColor"], values: ["'Montserrat',sans-serif", [], 'black', "Nothing", "center", "#000000"] },
    CardStyle3: { names: ["textFontFamily", "textFontStyle", "textColor", "onHover", "productInfoAlignment"], values: ["'Montserrat',sans-serif", [], 'black', "Nothing", "center"] },
    fashionNewProducts: { names: ["textFontFamily", "textFontStyle", "textColor", "priceAlignment", "productInfoAlignment"], values: ["'Montserrat',sans-serif", [], 'black', "center", "center"] },
    fashionTrendyCollections: { names: ["textFontFamily", "textFontStyle", "textColor", "productInfoAlignment"], values: ["'Montserrat',sans-serif", [], 'black', "center"] },
    retailTemplate: { names: ["lowStockAvailableText", "stockAvailableText", "buttonText1"], values: ["Out of Stock", "In Stock", "View Details"] },
    retailTemplate2: { names: ["backgroundColor", "textColor", "buttonText1"], values: ["#e1d77b", "#2a1404", "SHOP NOW"] },
    chocolateTheme: { names: ["textColor", "textFontFamily", "textFontSize"], values: ["#968383", "'Garamond',serif", 20] },
    grocerytheme2: { names: [], values: [] },
    bookTheme: { names: ["textColor", "textFontFamily", "textFontSize", "textFontStyle"], values: ["#131317", "'Montserrat',sans-serif", 16, ["B"]] },
    cafeTheme: { names: ["textColor", "textFontFamily", "textFontSize", "textFontStyle", "isBoxShadow", "BoxShadowColor", "isPurexara", "borderSize", "beforeBorderColor", "afterBorderColor"], values: ["#fff", "'Montserrat',sans-serif", 16, ["B"], false, "#ffffff", false, 0, "#ffffff", "#ffffff"] },
    daffodilsTheme: { names: ["textColor", "textFontFamily", "textFontSize", "textFontStyle"], values: ["#fff", "'Montserrat',sans-serif", 16, ["B"]] },
    cosmeticsTheme: { names: ["description", "buttonText1"], values: ["Handmade Products", "Add To Cart"] },
    bookCategory: { names: ["description", "buttonText1"], values: ["Shop Category", "Shop Now"] },
    furnitureThemeCategory: { names: ["textColor", "textFontFamily", "textFontSize", "backgroundColor"], values: ["white", "'Georgia,sans-serif'", 22, '#000000b3'] },
    chocolateBrand: { names: ["textColor", "textFontFamily", "textFontSize"], values: ["#968383", "'Arial',sans-serif", 20] },
    cosmeticsBrand: { names: ["textColor", "textFontFamily", "textFontSize"], values: ["#373737", "'Arial',sans-serif", 24] },
    electronicsProduct: { names: ["textColor", "textFontFamily", "textFontSize"], values: ["#000000", "'Tahoma', sans-serif", 18] },
    retailCategory: { names: ["buttonText1", "imageSize"], values: ["See More", "cover"] },
    PetsCanineProducts: { names: ["onHover", "textColor", "textFontSize", "textFontStyle", "textFontFamily", "buttonText1", "backgroundColor"], values: ["Zoom", 'black', 24, ["B"], "'Montserrat',sans-serif", "Add To Cart", "#ed6436"] },
    PetsCanineBrand: { names: ["onHover"], values: ["Zoom"] },
    handypaintingtheme: { names: ["textFontFamily", "textFontStyle", "textColor", "onHover", "productInfoAlignment"], values: ["'Montserrat',sans-serif", [], 'white', "HalfBorder", "left"] },
    meticsTheme: { names: ["textColor", "textFontFamily", "textFontSize", "textFontStyle"], values: ["rgb(0,0,0,0.8)", "'Montserrat',sans-serif", 15, ["B"]] },
    lensTheme: { names: ["textFontFamily", "textFontStyle", "textColor", "onHover", "productInfoAlignment"], values: ["'Montserrat',sans-serif", [], 'black', "Nothing", "center"] },
    slumberillowProducts: { names: ["textColor", "textFontFamily", "textFontSize", "priceFontSize", "backgroundColor", "imageBackgroundColor", "buttonText1", "knowmorebuttonColor"], values: ["#61817c", "'Montserrat',sans-serif", 14, 17, "white", "#f9f9f9", "KNOW MORE", "#e99f26d4"] },
    artTheme: { names: ["textColor", "textFontFamily", "textFontStyle", "circleColor", "arrowColor"], values: ["#131317", "'Palatino-linotype',serif", ["B"], "#406e74", "#ffffff"] },
    slumberillowCategory: { names: ["textColor", "textFontSize", "textFontFamily", "buttonText1", "backgroundColor"], values: ['black', 20, "'Montserrat',sans-serif", "View More", "#f4f4f4"] },
    babyCadoProducts: { names: ["textColor", "textFontFamily", "textFontSize", "textFontStyle", "backgroundColor"], values: ["#131317", "'Palatino Linotype',serif", 20, ["B"], "#f8c4b9"] },
    babyCado: { names: ["isBorder", "isPoppers", "productCardColor1", "productCardColor2", "productCardColor3", "productCardColor4", "textColor", "textFontFamily", "textFontSize", "textFontStyle", "babycadoBorderType", "babycadoBorderColor", "babycadoBorderSize", "isShadow"], values: [false, false, "#26a6a1", "#ff5249", "#75c137", "#5f7999", "#000", "'Garamond',serif", 20, [], 'none', "#f4b11c", 4, false] },
    handstohandsgrocerytemplate: { names: ["textFontFamily", "homeSculpturePriceColor", "textFontStyle", "textColor", "textFontSize", "description", "cardBackgroundImage", "buttonText1", "backgroundColor", "onHover"], values: ["'Montserrat',sans-serif", "#ffad53", [], 'black', 16, "Get upto Enjoy 50% Off On your Daily Needs", "themes/grocery2/GRO-IMG-13.jpg", "Add To Cart", "#b4a4d4", "BoxShadow"] },
    carnivoreCart: { names: ["textFontFamily", "textFontSize", "textFontStyle", "buttonText1"], values: ["'Times New Roman',serif", 25, ["B"], "ADD"] },
    pureplatepantryTheme: { names: ["textColor", "textFontFamily", "textFontSize", "backgroundColor", "textFontStyle"], values: ["#000000", "'Georgia,sans-serif'", 20, '#ffffff', ["B"]] },
    motorMarvelTheme: { names: ["textColor", "textFontFamily", "textFontSize", "textFontStyle", "backgroundColor", "buttonText1", "textFontStyle1"], values: ["#ffffff", "'Montserrat',sans-serif", 15, ["B"], "rgb(63, 64, 67)", "Shop Now", []] },
    compactgrocerytheme: { names: ["cardContentBorderRadius"], values: [2] },
    pinePerfectTheme: { names: ["textFontFamily", "textFontSize", "textFontStyle", "buttonText1", "textColor"], values: ["'Montserrat',sans-serif", 15, [], "Add To Cart", "#fff"] },
    metalTheme: { names: ["textColor", "backgroundColor", "textFontFamily", "textFontSize", "textFontStyle"], values: ["#E4492E", "#1C1C1C", "'Montserrat',sans-serif", 20, ["B"]] },
    homeSculptureProduct: { names: ["textColor", "homeSculpturePriceColor", "homeSculpturepriceFontSize", "homeSculptureTextFontSize", "homeSculptureDesignHeight", "textFontStyle", "textFontFamily", "backgroundColor", "onHover"], values: ['#dddcdd', '#cdcbcc', 16, 19, 90, ["B"], "'Montserrat',sans-serif", "#595262", "Zoom"] },
    homeSculptureFeaturedCategories: { names: ["textColor", "backgroundColor", "textFontFamily", "textFontSize", "textFontStyle", "onHover", "imageSize"], values: ["#ffffff", "#595262", "Cambria", 20, ["B"], "Zoom", "cover"] },
    vividProducts: { names: ["textFontFamily"], values: ["'Archivo', sans-serif"] },
    greenViva: { names: ["buttonTxtColor", "textColor", "backgroundColor", "cardBorderColor", "textFontFamily", "textFontSize", "textFontStyle", "buttonText1"], values: ["#0C4916", "#0C4916", "#0C4916", "#0C4916", "'Montserrat',sans-serif", 20, ["B"], "Add To Cart"] },
    tools2: { names: ["textFontFamily", "textFontSize", "textFontStyle"], values: ["'Heebo',sans-serif", 20, ["B"]] },
    furniflareCategory: { names: ["textColor", "textFontFamily", "textFontSize", "textFontStyle", "imageSize"], values: ["#ffffff", "'Montserrat',sans-serif", 18, ["B"], "cover"] },
    elysianFeaturedProducts: { names: ["onHover", "textColor", "textFontSize", "textFontStyle", "textFontFamily", "buttonText1", 'homeSculpturePriceColor', 'cardBorderColor', "backgroundColor"], values: ["Zoom", 'black', 18, ["B"], "'Montserrat',sans-serif", "Shop Now", '#000000', '#000000', '#ffffff'] },
    elysianFeaturedCategory: { names: ["onHover", "textColor", "textFontSize", "textFontStyle", "textFontFamily", 'cardBorderColor', "backgroundColor"], values: ["Zoom", 'black', 18, ["B"], "'Montserrat',sans-serif", '#000000', '#ffffff'] },
    woodyNestProduct: { names: ["textColor", 'homeSculpturePriceColor', "homeSculpturepriceFontSize", "homeSculptureTextFontSize", "homeSculptureDesignHeight", "textFontStyle", "textFontFamily", "backgroundColor", "onHover"], values: ['#000000', '#454545', 16, 19, 90, ["B"], "'Montserrat',sans-serif", "#ffffff", "Zoom"] },
    woodynestCategory: { names: ["textColor", "backgroundColor", "textFontStyle", "textFontFamily", "homeSculptureTextFontSize", "subtextFontStyle", "subtextFontSize", "subtextColor"], values: ["#000000", "#e6e2d7", ["B"], "'Montserrat',sans-serif", 16, [""], 15, "#000000"] },
    grindGroveTheme: { names: ["textFontSize", "textColor", "textFontFamily", "textFontStyle", "backgroundColor", "beforeBorderColor", "afterBorderColor"], values: [20, "#ffffff", "'Times New Roman',serif", ["B"], "#842c31", "#ffffff", "#842c31"] },
    handHeldHubFeaturedProducts: { names: ["textColor", "textFontSize", "textFontStyle", "textFontFamily", "buttonText1", "homeSculpturePriceColor", "backgroundColor"], values: ['black', 18, ["B"], "'Montserrat',sans-serif", "Shop Now", "#000000", "#ffffff"] },
    candellaCategory: { names: ["textColor", "textFontFamily", "textFontSize", "textFontStyle", "priceFontSize", "priceColor"], values: ["#000000", "'EB Garamond', serif", 20, [], 23, "#000000"] }
  }

  loginOptions = {
    headingPosition: [
      { name: 'Over card', value: true },
      { name: 'Over content', value: false }
    ],
    imagePosition: [
      { name: 'Foreground', value: 'center' },
      { name: 'Background', value: 'fill' },
      { name: 'Semi-Sphere', value: 'hemisphere' }
    ],
    imageSize: [
      { name: 'Cover', value: 'cover' },
      { name: 'Contain', value: 'contain' },
    ],
    imageAlignment: [
      { name: 'Top', value: 'top' },
      { name: 'Bottom', value: 'bottom' }
    ],
    boxAlignment: [
      { name: 'Top', value: 'start' },
      { name: 'Center', value: 'center' },
      { name: 'Bottom', value: 'end' }
    ],
    horizontalAlignment: [
      { name: "Left", value: "start" },
      { name: 'Center', value: 'center' },
      { name: 'Right', value: 'end' }
    ],
    formAlignment: [
      { name: 'Template 1', value: 'row', imageUrl: "./assets/login-editor/right.jpg" },
      { name: 'Template 2', value: 'center', imageUrl: "./assets/login-editor/center.jpg" },
      { name: 'Template 3', value: 'row-reverse', imageUrl: "./assets/login-editor/left.jpg" },
    ],
    appearance: [
      { name: 'Underlined', value: 'fill' },
      { name: 'Outlined', value: 'outline' },
      { name: 'Rounded', value: 'rounded' }
    ],
    iconPosition: [
      { name: 'Outside', value: 'outside' },
      { name: 'Inside', value: 'inside' }
    ],
    floatPosition: [
      { name: 'Left', value: 'left' },
      { name: 'Right', value: 'right' }
    ],
    animation: [
      { imageURL: './assets/common-slider-images/no-animation.png', name: 'None', id: 'none' },
      { imageURL: './assets/common-slider-images/tear-up.gif', name: 'Tear up', id: 'tear' }
    ],
    loginTemplates: [
      {
        id: 1,
        name: 'commonLogin'
      },
      {
        id: 2,
        name: 'juzzCandy'
      },
      {
        id: 3,
        name: 'babyCado'
      },
      {
        id: 4,
        name: 'daffodilDelight'
      },
      {
        id: 5,
        name: 'radiance'
      },
      {
        id: 6,
        name: 'opulence'
      },
      {
        id: 7,
        name: 'candella'
      },
      {
        id: 8,
        name: 'homeSculpture'
      },
      {
        id: 9,
        name: 'dermaglow'
      }
    ],
    borderDirection: [
      {
        name: "All",
        value: "all"
      },
      {
        name: "Top",
        value: "top"
      },
      {
        name: "Left",
        value: "left"
      },
      {
        name: "Right",
        value: "right"
      },
      {
        name: "Bottom",
        value: "bottom"
      }
    ]
  }
  hideAspectRatio = [
    'paintingProductList',
    'porkThemeProductDesign'
  ];
  aspectRatioControls = [
    "aspectHeight",
    "aspectWidth"
  ]
  /**
   * constant used to store types of control of common form editor.
   * @type {object}
   */
  contactFormConstents = {
    alignment: [
      {
        name: "Left",
        value: "left",
        valueAl: "start"
      },
      {
        name: "Center",
        value: "center",
        valueAl: "center"
      },
      {
        name: "Right",
        value: "right",
        valueAl: "end"
      }
    ],
    backgroundType: [
      {
        name: "None",
        value: "none"
      },
      {
        name: "Solid Color",
        value: "color"
      },
      {
        name: "Image",
        value: "image"
      }
    ],
    imageFit: [
      {
        name: "Cover",
        value: "cover"
      },
      {
        name: "Contain",
        value: "contain"
      },
      {
        name: "Fill",
        value: "100% 100%"
      }
    ],
    apperance: [
      {
        name: "Outlined",
        value: "outline"
      },
      {
        name: "Underlined",
        value: "fill"
      }
    ],
    design: [
      {
        name: "Default",
        value: "none"
      },
      {
        name: "Boxed",
        value: "boxed"
      },
      {
        name: "Rounded",
        value: "rounded"
      }
    ],
    buttonType: [
      {
        name: "Flat",
        value: "flat"
      },
      {
        name: "Raised",
        value: "raised"
      },
      {
        name: "Stroked",
        value: "stroked"
      }
    ],
    templateName: [
      {
        name: 'Template - 1',
        value: 'templateOne',
        imageURL: './assets/contact-form-editor/template1.jpg'
      },
      {
        name: 'Template - 2',
        value: 'templateTwo',
        imageURL: './assets/contact-form-editor/template2.jpg'
      },
      {
        name: 'Template - 3',
        value: 'templateThree',
        imageURL: './assets/contact-form-editor/template3.png'
      },
      {
        name: 'Template - 4',
        value: 'templateFour',
        imageURL: './assets/contact-form-editor/template4.png'
      }
    ]
  };
  /*
   * Variable used to show button position types
   * @type {Array}
   */
  buttonPositionOptions = [
    {
      "name": "Top Left",
      "value": "topLeft"
    },
    {
      "name": "Top Center",
      "value": "topCenter"
    },
    {
      "name": "Top Right",
      "value": "topRight"
    },
    {
      "name": "Bottom Left",
      "value": "bottomLeft"
    },
    {
      "name": "Bottom Center",
      "value": "bottomCenter"
    },
    {
      "name": "Bottom Right",
      "value": "bottomRight"
    }
  ];

  designLayout = [
    {
      "name": "Start",
      "value": "start center"
    },
    {
      "name": "Center",
      "value": "center center"
    }
  ]
  /**
 * Variable used to show button text position types
 * @type {Array}
 */
  buttonTextPosition = [
    {
      "name": "Left",
      "value": "left"
    },
    {
      "name": "Center",
      "value": "center"
    },
    {
      "name": "Right",
      "value": "right"
    }
  ];
  /**
   * Variable used to show button animation types
   * @type {Array}
   */
  buttonAnimation = [
    {
      "name": "None",
      "value": "none"
    },
    {
      "name": "Animation",
      "value": "animationType1"
    }
  ];
  /**
   * Variable used to show button icon types
   * @type {Array}
   */
  buttonIconType = [
    {
      "name": "Arrows",
      "value": "normal"
    },
    {
      "name": "Rounded Arrows",
      "value": "round"
    }
  ];
  /**
  * Variable used to show slider icon types
  * @type {Array}
  */
  iconType = [
    {
      "name": "Arrows",
      "value": "normal"
    },
    {
      "name": "Rounded arrows",
      "value": "round"
    },
    {
      "name": "Arrow with text",
      "value": "button"
    },
    {
      "name": "Square arrow",
      "value": "square"
    },
    {
      "name": "Arrow on bottom",
      "value": "bottomArrow"
    }
  ];
  /**
 * Variable used to show slider types
 * @type {Array}
 */
  sliderType = [
    {
      "name": "Slider",
      "value": "slider"
    },
    {
      "name": "Without slider",
      "value": "withoutSlider"
    }

  ];

  navigationType = [
    {
      "name": "Arrows",
      "value": "normal"
    },
    {
      "name": "Scroll bar",
      "value": "scrollbar"
    },
    {
      "name": "None",
      "value": "none"
    }
  ];
  /**
* Variable used to show slider types
* @type {Array}
*/
  dividerposition = [
    {
      "name": "Left",
      "value": "start center"
    },
    {
      "name": "Center",
      "value": "center center"
    },
    {
      "name": "Right",
      "value": "end center"
    }
  ];
  /**
 * Variable used to show image types
 * @type {Array}
 */
  imageSize = [
    {
      "name": "Cover",
      "value": "cover"
    },
    {
      "name": "Fill (100% 100%)",
      "value": "100% 100%"
    }
  ];
  /**
   * Variable used to show automatic types
   * @type {Array}
   */
  automaticType = [
    {
      "name": "Manual slider",
      "value": "manualSlider"
    },
    {
      "name": "With seconds",
      "value": "withSeconds"
    },
    {
      "name": "Without seconds",
      "value": "withoutSeconds"
    }
  ];
  backgroundType = [
    {
      "name": "Background image",
      "value": "backgroundImage"
    },
    {
      "name": "Solid color",
      "value": "solidColor"
    },
    {
      "name": "Gradient color",
      "value": "gradientColor"
    }
  ];
  colorPosition = [
    {
      "name": "Top to bottom",
      "value": "bottom"
    },
    {
      "name": "Bottom to top",
      "value": "top"
    },
    {
      "name": "Left to right",
      "value": "right"
    },
    {
      "name": "Right to left",
      "value": "left"
    },
  ];
  /**
   * Object which is used to show the messages
   */
  imageHotspotMessages = {
    hintMessage: 'Drag and Drop the Hotspots',
    imageHotspotEmptyDisclaimer: 'Click the add spot to show the product.',
    imageHotspotTextSizeAlert: 'Font size of title and description can not be changed.',
    imageHotspotLimitExceeds: 'Please add more products in featured products',
    defaultVariantShown: 'The default variant is displayed because this product has more than one variant',
    duplicateProductShown: 'You have already chosen this product before!',
    topHotspotRequired: 'Top hotspot positioning is required.',
    leftHotspotRequired: 'Left hotspot positioning is required.',
    productRequired: 'Product is required.',
    titleRequired: 'Title is required',
    descriptionRequired: 'Description is required',
    searchProduct: 'Search a product to select',
    noProductFound: 'No products found',
    firstVariantShown: 'The first variant is displayed because this product has more than one variant, and the default variant is disabled.',
    titleMaxLength: 'Title should not exceed 30 characters!',
    descriptionMaxLength: 'Description should not exceed 180 characters!',
    matSelectLabel: 'Choose product',
    positionTopLabel: 'Position from top',
    positionLeftLabel: 'Position from left',
    featuredProductLabel: 'Featured products',
    allProductsLabel: 'All products',
    catalogueViewLabel: 'Catalogue view',
    tooltipViewLabel: 'Tooltip view',
    displayOptionLabel: 'Image hotspots display options',
    productSelectionLabel: 'Product selection options',
    titleLabel: 'Title',
    descriptionLabel: 'Description',
    addSpotLabel: 'Add spot'
  }
  storefrontRouteDetails = {
    pageLink: [
      'home',
      'contactus',
      'aboutus',
      'products',
      'productdetails',
      'login',
      'cart',
      'discountdetails',
      'checkout',
      'footer',
      'quickorderproduct',
      'reviewdetails',
      'changepassword',
      'unsubscribe',
      'setpassword',
      'subscripiton',
      'deals',
      'store',
      'orderdetail',
      'customersubscriptionorder',
      'customersubscriptionupcomingorder',
      'commonproductlist',
      'digitalproductcheckout',
      'blogs',
      'account',
      'user',
      'giftcards',
      'brandlist',
      'signup',
      'forgotpassword',
      'policies',
      'shopapp'
    ],
    pageTitle: [
      'Change Password',
      'Unsubscribe',
      'Reset Password',
      'Checkout',
      'Deals',
      'FAQ',
      'Policy',
      'Order details',
      'Subscription Orders',
      'Digital Product Checkout',
      'Blogs',
      'Blog Details',
      'Profile',
      'Wish List',
      'Address',
      'Orders',
      'Subscription',
      'Change Password',
      'Purchased Gift Cards',
      'Purchased Gift Cards Logs',
      'Gift Card',
      'Gift Card Checkout',
      'Gift Card Details',
      'Cart',
      'Brand',
      'Home',
      'About Us',
      'Contact Us',
      'Products',
      'Quick Order Product',
      'Review Details',
      'Discount Details',
      'Login',
      'Registration',
      'Change Password',
      'Forgot Password',
    ]
  }

  productListControlsErrorMessages = {
    required: "This field is required.",
    productCountMax: "Value should not exceed 30.",
    productCountMin: "Value should not be less than 20.",
    aspectMax: "Aspect value should not exceed 16.",
    aspectMin: "Aspect value should not be less than 1.",
    textMaxLength: 'Maximum character length for this field is ',
    textNoSpace: 'White spaces are not allowed in this context.'
  }

  sections: Array<any> = [
    {
      name: "General Settings",
      templateName: "generalSettings"
    },
    {
      name: "Header Settings",
      templateName: "headerSettings"
    }
  ];

  customSection: Array<any> = [
    {
      name: "Text Settings",
      templateName: "textSettings"
    },
    {
      name: "Button Settings",
      templateName: "buttonSettings"
    },
    {
      name: "Background Text Color Settings",
      templateName: "backgroundTextColorSettings"
    },
    {
      name: "Image Settings",
      templateName: "imageSettings"
    },
    {
      name: "Slider Settings",
      templateName: "imageSettings"
    },
    {
      name: "Video Settings",
      templateName: "videoSettings"
    },
    {
      name: "Solid Color Settings",
      templateName: "solidColorSettings"
    },
    {
      name: "Gradient Settings",
      templateName: "gradientSettings"
    },
    {
      name: "Carousal Settings",
      templateName: "carousalSettings"
    },
    {
      name: "Multiple Image Settings",
      templateName: "cafeSettings"
    },
    {
      name: "Banner Text Settings",
      templateName: "otherTextControls"
    },
    {
      name: "Banner Button Settings",
      templateName: "otherButtonControls"
    }
  ];

  sectionMapping: any = {
    1: [3, 0, 1, 2],
    2: [4],
    3: [5, 0, 1],
    4: [6, 0, 1],
    5: [7, 0, 1],
    6: [3, 0, 1, 2],
    7: [9, 0, 1],
    8: [8],
    9: [9, 0, 1],
    10: [3, 0],
    11: [3, 9, 0, 1],
    12: [9, 0, 1],
    13: [9, 0, 1],
    16: [9, 10, 11],
    17: [9, 10, 11],
    15: [4],
    14: [4]
  }
  sliderPositions = [...this.loginOptions.horizontalAlignment,
  { name: "Space between", value: "space-between" },
  { name: 'Space around', value: 'space-around' },
  { name: 'Space evenly', value: 'space-evenly' }
  ]
  /**
 * Object Used for Common Heading
 */
  commonHeadingConstants = {
    borderType: [
      {
        name: "Default border",
        value: "defaultBorder",
        imageUrl: "themes/commonHeading/defaultBorder.jpg"
      },
      {
        name: "Custom border",
        value: "customBorder",
        imageUrl: "themes/commonHeading/customBorder.jpg"

      }
    ],
    customBorder: [
      {
        name: "Horizondal border 1",
        value: "horizondalBorder",
        imageUrl: "themes/commonHeading/horizondalBorder1.jpg"
      },
      {
        name: "Horizondal border 2",
        value: "horizondalBorder2",
        imageUrl: "themes/commonHeading/horizondalBorder2.jpg"
      },
      {
        name: "Horizondal border 3",
        value: "horizondalBorder3",
        imageUrl: "themes/commonHeading/horizondalBorder3.jpg"
      },
      {
        name: "Vertical border",
        value: "verticalBorder",
        imageUrl: "themes/commonHeading/verticalBorder.jpg"
      },
      {
        name: "Diagonal border 1",
        value: "diagonalBorder",
        imageUrl: "themes/commonHeading/diagonal.jpg"
      },
      {
        name: "Diagonal border 2",
        value: "diagonalBorder2",
        imageUrl: "themes/commonHeading/diagonal2.jpg"
      }
    ],
    textTransform: [
      {
        name: "Upper Case",
        value: "uppercase"
      },
      {
        name: "Lower Case",
        value: "lowercase"

      },
      {
        name: "Capitalize",
        value: "capitalize"
      },
      {
        name: "Custom",
        value: "none"
      }
    ],
    borderStyle: [
      {
        name: "Solid",
        value: "solid"
      },
      {
        name: "Dashed",
        value: "dashed"
      },
      {
        name: "Dotted",
        value: "dotted"
      },
      {
        name: "Double",
        value: "double"
      },
      {
        name: "Groove",
        value: "groove"
      },
      {
        name: "Inset",
        value: "inset"
      },
      {
        name: "Outset",
        value: "outset"
      },
      {
        name: "Ridge",
        value: "ridge"
      }
    ],
    borderLinePositon: [
      {
        name: "Left",
        value: "center start"
      },
      {
        name: "Center",
        value: "center center"

      },
      {
        name: "Right",
        value: "center end"
      }
    ],
  }
  gamingTheme = {
    sectionData: [
      { name: 'Acheivements', value: 'acheivements' },
      { name: 'Testimonial', value: 'testimonial' },
      { name: 'Location', value: 'location' }],
    imageSizing: [
      {
        name: 'Crop',
        value: 'cover'
      },
      {
        name: 'Fit',
        value: 'contain'
      }
    ]
  }
  /**
   * Variable used to store the messages for show and hide in mobile and desktop view.
   */
  desktopMobileMessages = {
    showInDesktop: 'Show in Desktop View',
    hideInDesktop: 'Hide in Desktop View',
    showInMobile: 'Show in Mobile View',
    hideInMobile: 'Hide in Mobile View'
  };

  singleProductSections: Array<any> = [
    {
      name: "Template Settings",
      templateName: "templateSettings"
    },
    {
      name: "Header Settings",
      templateName: "headerSettings"
    },
    {
      name: "Text Settings",
      templateName: "textSettings"
    },
    {
      name: "Button Settings",
      templateName: "buttonSettings"
    },
    {
      name: "Image Settings",
      templateName: "imageSettings"
    },
    {
      name: "Product Settings",
      templateName: "productSettings"
    },
    {
      name: "Animation Settings",
      templateName: "animationSettings"
    }
  ]

  singleProductTemplate: Array<any> = [
    {
      name: 'Template - 1',
      value: 'singleProductTemplateOne',
      imageURL: './assets/contact-form-editor/template1.jpg'
    },
    {
      name: 'Template - 2',
      value: 'singleProductTemplateTwo',
      imageURL: './assets/contact-form-editor/template2.jpg'
    },
    {
      name: 'Template - 3',
      value: 'singleProductTemplateThree',
      imageURL: './assets/contact-form-editor/template1.jpg'
    },
    {
      name: 'Template - 4',
      value: 'santaSingleProduct',
      imageURL: './assets/storefrontEditor/single_product_santa.jpg'
    }
  ]

  singleProductSectionIndex: any = {
    singleProductTemplateOne: [1, 3, 5],
    singleProductTemplateTwo: [1, 2, 3, 4, 5],
    singleProductTemplateThree: [5],
    santaSingleProduct: [3, 4, 5, 6],
  }
  /**
   * Variable used to show image types
   * @type {Array}
   */
  singleProductImageSize = [
    {
      "name": "Crop",
      "value": "cover"
    },
    {
      "name": "Fit",
      "value": "contain"
    }
  ];
  /**
   * Variable used import sheet header for testimonials
   */
  testimonialImportHeader = [
    [
      { header: 'Customer Name', key: 'customerName', width: '30', sheetNo: 1, sampleValue: 'sample1' },
      { header: 'Designation', key: 'designation', width: '30', sheetNo: 1, sampleValue: 'sample2' },
      { header: 'Content', key: 'content', width: '30', sheetNo: 1, sampleValue: 'sample3' },
      { header: 'Ratings', key: 'ratings', width: '30', sheetNo: 1, sampleValue: 5 },
      { header: 'Publish', key: 'publish', width: '30', sheetNo: 1, sampleValue: true }
    ],
  ];

  /**
    * Variable used to show image types
    * @type {Array}
    */
  borederType = [
    {
      name: "None",
      value: "none"
    },
    {
      name: "Solid",
      value: "solid"
    },
    {
      name: "Dashed",
      value: "dashed"
    },
    {
      name: "Dotted",
      value: "dotted"
    },
    {
      name: "Double",
      value: "double"
    },
    {
      name: "Groove",
      value: "groove"
    },
    {
      name: "Inset",
      value: "inset"
    },
    {
      name: "Outset",
      value: "outset"
    },
    {
      name: "Ridge",
      value: "ridge"
    }
  ];

  /** Common Blogs Templates */
  blogsTemplates = [
    {
      "id": 1,
      "name": "Dellizziosa",
      "value": "dellizziosaBlogs",
      "requiredControls": [
        { name: 'Heading Settings', templateName: 'headingSettings' },
        { name: 'Title Settings', templateName: 'title', control: ['color', 'fontSize', 'textFamily', 'fontStyle', 'textAlign'] },
        { name: 'Author Settings', templateName: 'author', control: ['color', 'fontSize', 'textFamily', 'fontStyle'] },
        { name: 'Category Settings', templateName: 'category', control: ['color', 'fontSize', 'textFamily', 'fontStyle', 'textAlign'] },
        { name: 'Date Settings', templateName: 'date', control: ['format', 'color', 'fontSize', 'textFamily', 'fontStyle'] },
        { name: 'Image Settings', templateName: 'image', control: ['imagefit'] },
        { name: 'Card Button', templateName: 'buttonDetails', isButtonAlign: true },
        { name: 'View More Button', templateName: 'blogButton', isButtonAlign: true }
      ]
    },
    {
      "id": 2,
      "name": "Decoratin",
      "value": "decoratinBlogs",
      "requiredControls": [
        { name: 'Heading Settings', templateName: 'headingSettings' },
        { name: 'Title Settings', templateName: 'title', control: ['color', 'fontSize', 'textFamily', 'fontStyle', 'textAlign'] },
        { name: 'Category Settings', templateName: 'category', control: ['color', 'fontSize', 'textFamily', 'fontStyle', 'textAlign'] },
        { name: 'Date Settings', templateName: 'date', control: ['format', 'color', 'fontSize', 'textFamily', 'fontStyle', 'textAlign'] },
        { name: 'Image Settings', templateName: 'image', control: ['imagefit'] },
        { name: 'Navigation Settings', templateName: 'navigation', control: ['iconDesign', 'iconColor', 'iconBorder', 'roundBorder'] },
        { name: 'Card Button', templateName: 'buttonDetails', isButtonAlign: true }
      ]
    },
    {
      "id": 3,
      "name": "Nascent",
      "value": "nascentBlogs",
      "requiredControls": [
        { name: 'Heading Settings', templateName: 'headingSettings' },
        { name: 'Title Settings', templateName: 'title', control: ['color', 'fontSize', 'textFamily', 'fontStyle', 'textAlign'] },
        { name: 'Date Settings', templateName: 'date', control: ['format', 'color', 'fontSize', 'textFamily', 'fontStyle', 'iconEnable', 'iconColor'] },
        { name: 'Image Settings', templateName: 'image', control: ['imagefit'] },
        { name: 'Navigation Settings', templateName: 'navigation', control: ['iconDesign', 'iconColor', 'iconBorder', 'roundBorder'] },
        { name: 'Card Button', templateName: 'buttonDetails', isButtonAlign: false },
        { name: 'Addition Settings', templateName: 'additional', control: ['lineEnable', 'linesize', 'lineColor'] }
      ]
    },
    {
      "id": 4,
      "name": "GemGlitz",
      "value": "gemGlitzBlogs",
      "requiredControls": [
        { name: 'Heading Settings', templateName: 'headingSettings' },
        { name: 'Title Settings', templateName: 'title', control: ['color', 'fontSize', 'textFamily', 'fontStyle', 'textAlign'] },
        { name: 'Date Settings', templateName: 'date', control: ['format', 'color', 'fontSize', 'textFamily', 'textAlign', 'fontStyle'] },
        { name: 'Image Settings', templateName: 'image', control: ['imagefit'] },
        { name: 'Navigation Settings', templateName: 'navigation', control: ['iconDesign', 'iconColor', 'iconBorder', 'roundBorder'] },
        { name: 'Card Button', templateName: 'buttonDetails', isButtonAlign: true },
        { name: 'Addition Settings', templateName: 'additional', control: ['lineEnable', 'linesize', 'lineColor'] }
      ]
    },
    {
      "id": 5,
      "name": "GrowTon",
      "value": "growtonBlogs",
      "requiredControls": [
        { name: 'Heading Settings', templateName: 'headingSettings' },
        { name: 'Title Settings', templateName: 'title', control: ['color', 'fontSize', 'textFamily', 'fontStyle', 'textAlign'] },
        { name: 'Date Settings', templateName: 'date', control: ['format', 'color', 'fontSize', 'textFamily', 'fontStyle', 'iconEnable', 'iconColor'] },
        { name: 'Image Settings', templateName: 'image', control: ['imagefit'] },
        { name: 'View More Button', templateName: 'blogButton', isButtonAlign: true }
      ]
    },
    {
      "id": 6,
      "name": "DigitalProd",
      "value": "digitalProdBlogs",
      "requiredControls": [
        { name: 'Heading Settings', templateName: 'headingSettings' },
        { name: 'Title Settings', templateName: 'title', control: ['color', 'fontSize', 'textFamily', 'fontStyle', 'textAlign'] },
        { name: 'Author Settings', templateName: 'author', control: ['color', 'fontSize', 'textFamily', 'fontStyle'] },
        { name: 'Category Settings', templateName: 'category', control: ['color', 'fontSize', 'textFamily', 'fontStyle', 'textAlign'] },
        { name: 'Date Settings', templateName: 'date', control: ['format', 'color', 'fontSize', 'textFamily', 'fontStyle'] },
        { name: 'Image Settings', templateName: 'image', control: ['imagefit'] },
        { name: 'Card Button', templateName: 'buttonDetails', isButtonAlign: true },
        { name: 'View More Button', templateName: 'blogButton', isButtonAlign: true }
      ]
    },
    {
      "id": 7,
      "name": "Footwear",
      "value": "footwearBlogs",
      "requiredControls": [
        { name: 'Heading Settings', templateName: 'headingSettings' },
        { name: 'Title Settings', templateName: 'title', control: ['color', 'fontSize', 'textFamily', 'fontStyle', 'textAlign'] },
        { name: 'Date Settings', templateName: 'date', control: ['format', 'color', 'fontSize', 'textFamily', 'fontStyle', 'textAlign', 'iconEnable', 'iconColor'] },
        { name: 'Image Settings', templateName: 'image', control: ['imagefit'] },
        { name: 'Addition Settings', templateName: 'additional', control: ['lineEnable', 'linesize', 'lineColor'] },
        { name: 'View More Button', templateName: 'blogButton', isButtonAlign: true }
      ]
    },
    {
      "id": 8,
      "name": "Pine Perfection",
      "value": "pineBlogs",
      "requiredControls": [
        { name: 'Heading Settings', templateName: 'headingSettings' },
        { name: 'Title Settings', templateName: 'title', control: ['color', 'fontSize', 'textFamily', 'fontStyle', 'textAlign'] },
        { name: 'Category Settings', templateName: 'category', control: ['color', 'fontSize', 'textFamily', 'fontStyle', 'textAlign'] },
        { name: 'Author Settings', templateName: 'author', control: ['color', 'fontSize', 'textFamily', 'fontStyle'] },
        { name: 'Date Settings', templateName: 'date', control: ['format', 'color', 'fontSize', 'textFamily', 'fontStyle'] },
        { name: 'Image Settings', templateName: 'image', control: ['imagefit'] },
        { name: 'Navigation Settings', templateName: 'navigation', control: ['iconDesign', 'iconColor', 'iconBorder', 'roundBorder'] }
      ]
    },
    {
      "id": 9,
      "name": "Handy Painting",
      "value": "handyPaintingBlogs",
      "requiredControls": [
        { name: 'Heading Settings', templateName: 'headingSettings' },
        { name: 'Title Settings', templateName: 'title', control: ['color', 'fontSize', 'textFamily', 'fontStyle'] },
        { name: 'Author Settings', templateName: 'author', control: ['color', 'fontSize', 'textFamily', 'fontStyle'] },
        { name: 'Date Settings', templateName: 'date', control: ['format', 'color', 'fontSize', 'textFamily', 'fontStyle'] },
        { name: 'Image Settings', templateName: 'image', control: ['imagefit'] },
        { name: 'View More Button', templateName: 'blogButton', isButtonAlign: true }
      ]
    },
    {
      "id": 10,
      "name": "Kiddo Heaven",
      "value": "kiddoBlogs",
      "requiredControls": [
        { name: 'Heading Settings', templateName: 'headingSettings' },
        { name: 'Title Settings', templateName: 'title', control: ['color', 'fontSize', 'textFamily', 'fontStyle', 'textAlign'] },
        { name: 'Author Settings', templateName: 'author', control: ['color', 'fontSize', 'textFamily', 'fontStyle', 'textAlign'] },
        { name: 'Date Settings', templateName: 'date', control: ['format', 'color', 'fontSize', 'textFamily', 'fontStyle', 'textAlign', 'iconEnable', 'iconColor'] },
        { name: 'Image Settings', templateName: 'image', control: ['imagefit'] },
        { name: 'View More Button', templateName: 'blogButton', isButtonAlign: true },
        { name: 'Keywords Settings', templateName: 'keywords', control: ['color', 'textFamily', 'fontStyle'] },
      ]
    },
    {
      "id": 11,
      "name": "Beverage 3",
      "value": "beverage3Blogs",
      "requiredControls": [
        { name: 'Heading Settings', templateName: 'headingSettings' },
        { name: 'Title Settings', templateName: 'title', control: ['color', 'fontSize', 'textFamily', 'fontStyle', 'textAlign'] },
        { name: 'Date Settings', templateName: 'date', control: ['format', 'color', 'fontSize', 'textFamily', 'fontStyle'] },
        { name: 'Image Settings', templateName: 'image', control: ['imagefit'] },
        { name: 'View More Button', templateName: 'blogButton', isButtonAlign: true },
        { name: 'Navigation Settings', templateName: 'navigation', control: ['iconDesign', 'iconColor', 'iconBorder', 'roundBorder'] }
      ]
    },
    {
      "id": 12,
      "name": "FurniFlare",
      "value": "furniflareBlogs",
      "requiredControls": [
        { name: 'Heading Settings', templateName: 'headingSettings' },
        { name: 'Title Settings', templateName: 'title', control: ['color', 'fontSize', 'textFamily', 'fontStyle', 'textAlign'] },
        { name: 'Category Settings', templateName: 'category', control: ['color', 'fontSize', 'textFamily', 'fontStyle', 'textAlign'] },
        { name: 'Date Settings', templateName: 'date', control: ['format', 'color', 'fontSize', 'textFamily', 'fontStyle', 'iconEnable', 'iconColor'] },
        { name: 'Image Settings', templateName: 'image', control: ['imagefit', 'overlaySettings'] },
        { name: 'Slider Arrow Settings', templateName: 'slider arrow', control: ['iconDesign', 'iconColor', 'iconBorder', 'roundBorder'] },
        { name: 'Additional Settings', templateName: 'additional', control: ['lineEnable', 'linesize', 'lineColor'] },
      ]
    },
    {
      "id": 13,
      "name": "FreshDelivers",
      "value": "freshDelivers",
      "requiredControls": [
        { name: 'Heading Settings', templateName: 'headingSettings' },
        { name: 'Title Settings', templateName: 'title', control: ['color', 'fontSize', 'textFamily', 'fontStyle', 'textAlign'] },
        { name: 'Date Settings', templateName: 'date', control: ['format', 'color', 'fontSize', 'textFamily', 'fontStyle'] },
        { name: 'Image Settings', templateName: 'image', control: ['imagefit'] },
        { name: 'View More Button', templateName: 'blogButton', isButtonAlign: true },
        { name: 'Addition Settings', templateName: 'additional', control: ['lineEnable', 'linesize', 'lineColor'] },
      ]
    },
    {
      "id": 14,
      "name": "Furr Vogue",
      "value": "furrVogueBlogs",
      "requiredControls": [
        { name: 'Heading Settings', templateName: 'headingSettings' },
        { name: 'Sub Heading Settings', templateName: 'subheading', control: ['input', 'color', 'fontSize', 'textFamily', 'fontStyle', 'textAlign'] },
        { name: 'Title Settings', templateName: 'title', control: ['color', 'fontSize', 'textFamily', 'fontStyle', 'textAlign'] },
        { name: 'Author Settings', templateName: 'author', control: ['color', 'fontSize', 'textFamily', 'fontStyle'] },
        { name: 'Category Settings', templateName: 'category', control: ['color', 'fontSize', 'textFamily', 'fontStyle'] },
        { name: 'Date Settings', templateName: 'date', control: ['format', 'color', 'fontSize', 'textFamily', 'fontStyle'] },
        { name: 'Image Settings', templateName: 'image', control: ['imagefit'] },
        { name: 'Card Button', templateName: 'buttonDetails', isButtonAlign: true },
        { name: 'View More Button', templateName: 'blogButton', isButtonAlign: true }
      ]
    }
  ];
  /**
   * Variable used to store image banner  editor controls.
   * @type {Array}
   */
  imageBannerEditorControls: Array<any> = [
    { name: 'Background Settings', templateName: 'backgroundSettings' },
    { name: 'Header Settings', templateName: 'headingControls' },
    { name: 'Subheader Settings', templateName: 'subHeadingControls' },
    { name: 'Column Settings', templateName: 'column' },
  ]
  invalidSectionName = 'invalid-section-name';
  productDetailsConstants = {
    layoutOptions: [
      {
        name: 'Layout - 1',
        value: 'layoutOne',
        imageUrl: './assets/product-details-editor/layout1.jpg'
      },
      {
        name: 'Layout - 2',
        value: 'layoutTwo',
        imageUrl: './assets/product-details-editor/layout2.jpg'
      }
      // {
      //   name: 'Layout - 3',
      //   value: 'layoutThree',
      //   imageUrl: './assets/product-details-editor/layout3.jpg'
      // },
      // {
      //   name: 'Layout - 4',
      //   value: 'layoutFour',
      //   imageUrl: './assets/product-details-editor/layout4.jpg'
      // }
    ],
    thumbnailOptions: [
      {
        name: 'Layout - 1',
        value: 'vertical',
        imageUrl: './assets/product-details-editor/gallery1.jpg'
      },
      {
        name: 'Layout - 2',
        value: 'horizontal',
        imageUrl: './assets/product-details-editor/gallery2.jpg'
      },
      {
        name: 'Layout - 3',
        value: 'fullSizeImages',
        imageUrl: './assets/product-details-editor/gallery3.gif'
      }
    ],
    textMaxLengthMessage: 'This field should not exceed 50 characters!',
    buttonMaxLengthMessage: 'This field should not exceed 30 characters!',
    validMessage: 'Please enter a valid text!'
  }
  productDetailsOrder = [
    {
      "name": "Product Rating",
      "templateName": "productRating",
      "isVisible": true
    },
    {
      "name": "Product SKU",
      "templateName": "productSKU",
      "isVisible": true
    },
    {
      "name": "Product Description",
      "templateName": "productDescription",
      "isVisible": true
    },
    {
      "name": "Price and Weight Unit",
      "templateName": "productPrice",
      "isVisible": true
    },
    {
      "name": "Stock",
      "templateName": "productStock",
      "isVisible": true
    },
    {
      "name": "Sale Timer",
      "templateName": "productSaleTimer",
      "isVisible": true
    },
    {
      "name": "Product Variant",
      "templateName": "productVariant",
      "isVisible": true
    },
    {
      "name": "Modifier",
      "templateName": "productModifier",
      "isVisible": true
    },
    {
      "name": "Add On Image",
      "templateName": "productAddOnImage",
      "isVisible": true
    },
    {
      "name": "Notify",
      "templateName": "productNotify",
      "isVisible": true
    },
    {
      "name": "Add To Cart",
      "templateName": "productAddToCart",
      "isVisible": true
    },
    {
      "name": "Purchase Type",
      "templateName": "productPurchaseType",
      "isVisible": true
    },
    {
      "name": "Product Size Chart",
      "templateName": "productProductSizeChart",
      "isVisible": true
    },
    {
      "name": "Product Add To Bag Button",
      "templateName": "productAddToBagButton",
      "isVisible": true
    },
    {
      "name": "Product Policy",
      "templateName": "productPolicy",
      "isVisible": true
    },
    {
      "name": "Product Digital Button",
      "templateName": "productDigitalButton",
      "isVisible": true
    },
    {
      "name": "Product Unavailable",
      "templateName": "productUnavailable",
      "isVisible": true
    },
    {
      "name": "Product Share Button",
      "templateName": "productShareButton",
      "isVisible": true
    },
    {
      "name": "Product Automatic Discount",
      "templateName": "productAutomaticDiscount",
      "isVisible": true
    },
    {
      "name": "Product Instruction",
      "templateName": "productInstruction",
      "isVisible": true
    },
    {
      "name": "Product Wishlist",
      "templateName": "wishlistButton",
      "isVisible": true
    }
  ]
  visibilityTemplateName = [
    {
      formControl: 'showRatingsCount',
      templateName: "productRating"
    },
    {
      formControl: 'showPrice',
      templateName: "productPrice"
    },
    {
      formControl: 'showSku',
      templateName: "productSKU"
    },
    {
      formControl: 'showProductDescription',
      templateName: "productDescription"
    },
    {
      formControl: 'showAvailabilityText',
      templateName: "productStock"
    },
    {
      formControl: 'showProductManyInStockText',
      templateName: "productStock"
    },
    {
      formControl: 'showProductOutOfStockText',
      templateName: "productStock"

    },
    {
      formControl: 'showProductUnavailabilityText',
      templateName: "productStock"

    },
    {
      formControl: 'showMenuNotAvailableText',
      templateName: "productStock"

    },
    {
      formControl: 'showWeightUnit',
      templateName: "productPrice"

    }
  ]

  loaderType = [
    {
      name: "Default",
      value: "default"
    },
    {
      name: "Custom",
      value: "custom"
    }
  ]
  mobileColumnNo: any = [
    { imageURL: './assets/product-list-editor-images/single-column.jpg', name: 'Show single card', id: '1' },
    { imageURL: './assets/product-list-editor-images/double-column.jpg', name: 'Show two cards', id: '2' }
  ]
  /**
   * Variable used for button border type controls.
   * @type {Array}
   */
  buttonBorderConstants: Array<{ name: string, icon: string, value: string }> = [
    { name: "Border top", icon: "border_top", value: "T" },
    { name: "Border bottom", icon: "border_bottom", value: "B" },
    { name: "Border left", icon: "border_left", value: "L" },
    { name: "Border right", icon: "border_right", value: "R" },
  ]
  /**
   * Variable used for button border type controls.
   * @type {Array}
   */
  iconSize: Array<{ name: string, value: string }> = [
    { name: "Small", value: "small" },
    { name: "Large", value: "large" },
    { name: "X-Large", value: "x-large" },
    { name: "XX-Large", value: "xx-large" },
    { name: "XXX-Large", value: "xxx-large" }
  ]
  playPauseIcon: object = [
    { offOption: 'volume_off', onOption: 'volume_up' },
    { offOption: 'music_off', onOption: 'music_note' }
  ]
  backgroundColorType = [
    {
      name: "Solid",
      value: "solid"
    },
    {
      name: "Gradient",
      value: "gradient"
    }
  ]

  offerType = [
    {label:"Percentage",value:'percentage'},
    {label:"Fixed",value:'fixed'}
  ]
}

export const NavbarOptions = {
  navbarCategory: [
    {
      name: "Category without image",
      value: "withCategory"
    },
    {
      name: "Category with image",
      value: "withCategory2"
    },
    {
      name: "No categories",
      value: "noCategory"
    }
  ]
}



