import { Component, EventEmitter, HostListener, Inject, Input, NgZone, OnDestroy, OnInit, Output } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { dataConfig, DialogService } from '@phase-ii/common';
import { StripeCardElement, loadStripe } from '@stripe/stripe-js';
import { Subscription } from 'rxjs';
import { CommonConstants, CommonDataService, CommonService } from '@phase-ii/shared';
import { IPayPalConfig, ICreateOrderRequest } from 'ngx-paypal';
import { NavigationStart, Router, Event as NavigationEvent } from '@angular/router';
/**
 * Variable used to load the razorpay pyament form
 */
declare var Razorpay: any;
/**
 * Varaible holds the checkout frames.
 */
declare var Frames: any
@Component({
  selector: 'phase-ii-admin-payment',
  templateUrl: './admin-payment.component.html',
  styleUrls: ['./admin-payment.component.scss'],
})
export class AdminPaymentComponent extends CommonConstants implements OnInit, OnDestroy {

  /**
   * Variable subscriptionObject used to store the subscribed datas.
   * @type {Subscription}
   */
  subscriptionObject: Subscription = new Subscription();
  /**
   * Variable used to store the saved card detail of the store
   * @type {any}
   */
  customerCardDetails: any;
  /**
   * Variable used to load the card elements
   * @type {StripeCardElement}
   */
  cardElement: StripeCardElement;
  /**
   * Variable used to load the stripe
   * @type {any}
   */
  stripe: any;
  /**
   * FormGroup used to store the billing address details
   * @type {FormGroup}
   */
  billingInfo: UntypedFormGroup;
  /**
* variable used to stop loader inside a paynow button
*/
  @Input() disablePayButton;
  /**
   * Variable used to decide to edit the card or not
   * @type {boolean}
   */
  isEdit: boolean;
  /**
   * Variable used to show or hide the loader
   * @type {boolean}
   */
  isLoading: boolean;
  /**
 * Variable used to define isProd or not for loading payment scripts.
 * @type {boolean}
 */
  isProd = false;

  /**
   * Variable used to store the list of countries from db
   * @type {Array}
   */
  countries: Array<any>;
  /**
   * Variable used to store the list of states relevant to the country from db
   * @type {Array}
   */
  states = [];
  /**
   * Variable used to get the plan details from parent component
   * @type {object}
   */
  @Input() planDetails: any;
  /**
   * Variable used to emit when the payment was successfully done
   * @type {EventEmitter}
   */
  @Output() paymentDone = new EventEmitter<any>();
  /**
   * Variable used to emit when the payment was processing on
   * @type {EventEmitter}
   */
  @Output() paymentProcessing = new EventEmitter<any>();
  /**
   * Variable used to check or uncheck the save card option
   * @type {FormControl}
   */
  isSaveCard = new UntypedFormControl(false);
  /**
   * Variable used to store the store details
   * @type {any}
   */
  @Input() storeDetails: any;
  /**
   * Variable used to store the searched countries
   *  @type {any}
   */
  searchCountries: any;
  /**
   * Variable used to store the searched states
   *  @type {any}
   */
  searchStates: any;
  /**
   * Variable which is used to search country 
   * @type {FormControl}
   */
  countrySearchFilter = new UntypedFormControl(null);
  /**
   * Variable which is used to search state 
   * @type {FormControl}
   */
  stateSearchFilter = new UntypedFormControl(null);
  /**
   * Variable used for button loading during payment
   *  @type {boolean}
   */
  isButtonLoading: boolean;
  /**
   * Variable used to store payment log details.
   * @type {Number}
   */
  paymentCount: number;
  /**
   * variable used to store environment details\
   * @type {any}
   */
  environment: any
  /**
   * Variable used to store the subscription payment type details.
   */
  paymentType: string;
  /**
   * Variable used to define paypal config details
   */
  public payPalConfig?: IPayPalConfig;
  /**
   * Variable used to set subscription payment type.
   */
  paymentConstant: any = dataConfig;
  /**
 * Variable used to store the billing info form is displayed on not
 * @type {any}
 */
  @Input() isClosed;
  /**
 * Variable used to store the back button conformation
 * @type {any}
 */
  @Input() isBack;
  /**
 * Variable used to emit form status
 * @type {EventEmitter}
 */
  @Output() formStatus = new EventEmitter<any>();
  /**
   * Variable used to get plugin info
   */
  @Input() pluginInfo;
  /**
* Variable used to emit form status
* @type {EventEmitter}
*/
  @Output() loaderStatus = new EventEmitter<boolean>();
  /**
   * Variable used to store pre process id.
   */
  @Input() preProcessId: number;
  /**
   * Variable used to store transaction type.
   */
  @Input() transactionType: string;
  /**
   * Variable used to check if razorpay or paypal clicked.
   * @type {boolean}
   */
  isPaymentClicked: boolean;

  /**
   * Component constructor to inject the required services.
   * @param commonService to use the function calls in common service
   * @param dialogService to use the dialog functions
   * @param authService to get the user details
   * @param zone to re run our zone 
   * @param commonDataService to encrypt and decrypt details
   */
  constructor(private commonService: CommonService,
    private dialogService: DialogService,
    private zone: NgZone,
    private commonDataService: CommonDataService,
    private router: Router,
    @Inject('environment') environment) {
    super();
    this.environment = environment;
  }

  /**
   * Component on init life cycle hook
   */
  ngOnInit(): void {
    this.isLoading = true;
    this.isProd = this.environment && this.environment.app && (this.environment.app === 'prod' || this.environment.app === 'us-prod') ? (this.storeDetails && this.storeDetails.store && this.storeDetails.store.isTestAccount ? false : true) : false;
    // this.storeDetails['store'].currencyData.currencyUnit = 'us';
    this.storeDetails.address = this.storeDetails && this.storeDetails.address && (typeof (this.storeDetails.address) == 'string') ?
      JSON.parse(this.storeDetails.address) : this.storeDetails.address;
    if (this.storeDetails && this.storeDetails.purchasedCurrency && this.storeDetails.purchasedCurrency.currencyUnit === "INR") {
      this.loadRazorpayScript();
      this.paymentType = 'RAZORPAY';
      this.isLoading = false;
    } else {
      this.getCountries();
      this.getSavedCardDetails();
    }
    this.preventNavigation();

    // this.subscriptionObject.add(this.authService.user.pipe(mergeMap((res: any) => {
    //   console.log('res', res)
    //   if (res) {
    //     this.storeDetails = res;
    //     return of(true);
    //   }
    //   return of(null);
    //   // else if (!res && !this.planDetails.isSuperAdmin) {
    //   //   return this.authService.getCurrentUser(dataConfig.storeAdminCode);
    //   // } else if (this.planDetails.isSuperAdmin) {
    //   //   return this.authService.getAdminDetails(this.planDetails.storeId);
    //   // }
    // }),
    //   //  mergeMap((res: any) => {
    //   //   if (res && this.planDetails.isSuperAdmin) {
    //   //     this.storeDetails = res.currentUser;
    //   //     this.storeDetails.address = JSON.parse(this.storeDetails.address);
    //   //   }
    //   //   this.planAmountWithoutDiscount = this.planDetails.Price ? this.planDetails.Price : 0.00;
    //   //   if (this.planDetails.Price + this.planDetails.SetUpCost > 0) {
    //   //     let taxData = {
    //   //       amount: this.planDetails.Price + this.planDetails.SetUpCost + this.planDetails.additionalCharge,
    //   //       country: this.storeDetails.address && this.storeDetails.address.country && this.storeDetails.address.country.toLowerCase(),
    //   //       state: this.storeDetails.address && this.storeDetails.address.state
    //   //     };
    //   //     return this.commonService.calculateTaxForSubscription(taxData);
    //   //   } else {
    //   //     return of(false);
    //   //   }
    //   // }),
    //   filter((res) => {
    //     console.log('ressss', res);
    //     this.storeDetails = { store: { currencyData: { currencyUnit: 'we' } } }
    //     if (this.storeDetails && this.storeDetails.store && this.storeDetails.store.currencyData && this.storeDetails.store.currencyData.currencyUnit === "INR") {
    //       console.log('inside')
    //       this.loadRazorpayScript();
    //       this.isLoading = false;
    //       return false;
    //     } else {
    //       this.getCountries();
    //       return true;
    //     }
    //     // return true;

    //   }), mergeMap(() => {
    //     return this.commonService.getSavedCardDetails(this.storeDetails && this.storeDetails.store && this.storeDetails.store.storeId);
    //   })).subscribe(response => {
    //     console.log('response', response);
    //     if (response && response['savedCardDetail']) {
    //       this.customerCardDetails = response['savedCardDetail'];
    //       this.isLoading = false;
    //     } else {
    //       this.isLoading = false;
    //       this.load();
    //     }
    //   }, err => {
    //     this.isLoading = false;
    //     this.load();
    //   }));
  }

  /**
   * Method used to prevent navigation.
   */
  preventNavigation(): void {
    let isNavigating: boolean = false;
    this.subscriptionObject.add(this.router.events.subscribe((event: NavigationEvent) => {
      if (event instanceof NavigationStart && !isNavigating && this.isPaymentClicked) {
        const confirmNavigation = confirm('You have unsaved changes. Do you really want to leave?');
        if (!confirmNavigation) {
          isNavigating = true
          this.router.navigateByUrl(this.router.url).then(() => {
            isNavigating = false;
          });
        }
      }
    }));
  }

  /**
   * Method used to prevent page from reloading.
   * @param {BeforeUnloadEvent} $event to get event details
   */
  @HostListener('window:beforeunload', ['$event'])
  unloadNotification($event: BeforeUnloadEvent): void {
    if (this.isPaymentClicked) {
      $event.preventDefault();
      $event.returnValue = 'Are you sure you want to leave? Changes you made may not be saved.';
    }
  }
  /**
   * Method used to prevent keyboard combinations from reloading.
   * @param {KeyboardEvent} event to get keyboard events.
   */
  @HostListener('window:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    if (event.key === 'F5' || ((event.ctrlKey && event.key === 'r')) || (event.ctrlKey && event.shiftKey && event.key === 'R')) {
      if (this.isPaymentClicked)
        event.preventDefault();
    }
  }

  /**
   * Method getSavedCardDetails used to fetch the saved card details.
   */
  getSavedCardDetails() {
    this.paymentProcessing.emit(true);
    this.subscriptionObject.add(this.commonService.getSavedCardDetails(this.storeDetails && this.storeDetails.store && this.storeDetails.store.storeId).subscribe((response: any) => {
      // response['savedCardDetail'] = null;
      if (response && response.details) {
        this.isLoading = false;
        this.loaderStatus.emit(false);
        this.paymentType = response.details.paymentType;
        this.customerCardDetails = response.details.cardDetails;
        let data = { 'isDisable': true, 'isDisplay': true }
        this.paymentProcessing.emit(data);
      }
      if (this.paymentType && this.paymentConstant && (this.paymentType === (this.paymentConstant.paymentOption &&
        this.paymentConstant.paymentOption.CHECKOUT)) && !this.customerCardDetails) {
        this.loadCheckoutScript().then(() => {
          this.loadCheckoutElements();
        });
      } else if (!this.customerCardDetails && this.paymentType && this.paymentConstant && (this.paymentType === (this.paymentConstant.paymentOption &&
        this.paymentConstant.paymentOption.STRIPE))) {
        this.load();
      } else if (this.paymentType && this.paymentConstant && (this.paymentType === (this.paymentConstant.paymentOption &&
        this.paymentConstant.paymentOption.PAYPAL))) {
        this.initConfig();
      }
      // if (response && response['savedCardDetail']) {
      //   this.customerCardDetails = response['savedCardDetail'];
      //   this.isLoading = false;
      // } else {
      //   this.isLoading = false;
      //   this.load();
      //   // this.loadCheckoutScript().then(() => {
      //   //   this.loadCheckoutElements();
      //   // });
      // }
    }, (err) => {
      this.isLoading = false;
      this.loaderStatus.emit(false);
      // this.load();
      // this.loadCheckoutScript().then(() => {
      //   this.loadCheckoutElements();
      // });
    }));
  }
  /**
   * Function used to load the stripe
   */
  async load() {
    if (!this.cardElement) {
      this.stripe = await loadStripe(this.isProd ? dataConfig.stripeProductionKey : dataConfig.stripeKey);
      this.loadStripeElements();
      this.onNewCard();
    }
  }
  /**
   * Function used to load the razorpay script
   */
  loadRazorpayScript(): void {
    let razorpayScript = document.createElement("script");
    razorpayScript.setAttribute("src", "https://checkout.razorpay.com/v1/checkout.js");
    razorpayScript.setAttribute("type", "text/javascript");
    document.head.appendChild(razorpayScript);
  }
  /**
  * Function used to load the checkout script
  */
  // loadCheckoutScript() {
  //   return new Promise((resolve, reject) => {
  //     try {
  //       let script = document.getElementsByTagName('script');
  //       let index = [].findIndex.call(script, (src) => src.src === 'https://cdn.checkout.com/js/framesv2.min.js');
  //       if (index !== -1) {
  //         Frames.removeAllEventHandlers(Frames.Events.CARD_VALIDATION_CHANGED);
  //         Frames.removeAllEventHandlers(Frames.Events.FRAME_VALIDATION_CHANGED);
  //         Frames.removeAllEventHandlers(Frames.Events.CARD_TOKENIZED);
  //         Frames.removeAllEventHandlers(Frames.Events.CARD_TOKENIZATION_FAILED);
  //         script[index].remove();
  //       }
  //       let checkoutScript = document.createElement("script");
  //       checkoutScript.setAttribute("src", "https://cdn.checkout.com/js/framesv2.min.js");
  //       checkoutScript.setAttribute("type", "text/javascript");
  //       document.head.appendChild(checkoutScript);
  //       this.onNewCard();
  //       checkoutScript.onload = function () {
  //         resolve(true);
  //       }
  //     }
  //     catch (err) {
  //       reject(false);
  //     }
  //   })
  // }
  loadCheckoutScript(): any {
    return new Promise((resolve, reject) => {
      try {
        let script = document.getElementsByTagName('script');
        if (script && script.length > 0) {
          let index = [].findIndex.call(script, (src) => src && src.src && src.src === 'https://cdn.checkout.com/js/framesv2.min.js');
          if (index !== -1) {
            script[index] && script[index].remove();
            if (Frames !== null) {
              Frames && Frames.Events && Frames.removeAllEventHandlers(Frames.Events.CARD_VALIDATION_CHANGED);
              Frames && Frames.Events && Frames.removeAllEventHandlers(Frames.Events.FRAME_VALIDATION_CHANGED);
              Frames && Frames.Events && Frames.removeAllEventHandlers(Frames.Events.CARD_TOKENIZED);
              Frames && Frames.Events && Frames.removeAllEventHandlers(Frames.Events.CARD_TOKENIZATION_FAILED);
            }
          }
        }
        let checkoutScript = document.createElement("script");
        checkoutScript.setAttribute("src", "https://cdn.checkout.com/js/framesv2.min.js");
        checkoutScript.setAttribute("type", "text/javascript");
        document.head.appendChild(checkoutScript);
        this.onNewCard();
        checkoutScript.onload = function () {
          resolve(true);
        };
      }
      catch (err) {
        reject(false);
      };
    })
  }
  /**
   * getCountries method used to fetch the country details 
   */
  getCountries(): void {
    this.subscriptionObject.add(this.commonService.getCountries().subscribe((res: any) => {
      if (res && res.country && res.country.length) {
        this.countries = res.country;
        this.searchCountries = res.country;
      }
    }));
  }
  /**
  * Function used to get the states of particular country
  */
  getStates(): void {
    if (this.billingInfo && this.billingInfo.value && this.billingInfo.value.country && this.billingInfo.value.country.id) {
      this.subscriptionObject.add(this.commonService.getStates(this.billingInfo.value.country.id).subscribe((response: any) => {
        if (response && response.states) {
          this.states = response.states;
          this.searchStates = response.states;
        }
      }));
    }
  }
  /**
   * Function used to get the searched countries result
   * @param value defines the country name
   */
  onCountrySearchFilter(value: string): void {
    if (value && value.length) {
      this.searchCountries = this.searchCountry(value);
    } else {
      this.countrySearchFilter.setValue(null);
      this.searchCountries = [];
      this.searchCountries = this.countries;
    }
  }
  /**
   * Function used to get the searched states
   * @param value defines the state name
   */
  onStateSearchFilter(value: string): void {
    if (value && value.length)
      this.searchStates = this.searchState(value);
    else {
      this.stateSearchFilter.setValue(null);
      this.searchStates = [];
      this.searchStates = this.states;
    }
  }
  /**
   * Function used to search the country from the country list
   * @param value defines the name of the country
   * @returns the selected countries based on the given name
   */
  searchCountry(value: string): Array<any> {
    const filterValue = value && value.toLowerCase();
    if (this.countries && this.countries.length)
      return this.countries.filter(option => option && option.name && option.name.toLowerCase().startsWith(filterValue));
  }
  /**
   * Function used to search the state from the state list
   * @param value defines the name of the state
   * @returns the selected states based on the given name
   */
  searchState(value: string): Array<any> {
    const filterValue = value && value.toLowerCase();
    if (this.states && this.states.length)
      return this.states.filter(option => option && option.name && option.name.toLowerCase().startsWith(filterValue));
  }
  /**
   * Function used to close the searched result for countries
   */
  onCountryFilterClose(): void {
    this.countrySearchFilter.setValue(null);
    this.searchCountries = [];
    this.searchCountries = this.countries;
  }
  /**
   * Function which is used for clear the state filter values
   */
  clearStateFilter(): void {
    this.stateSearchFilter.setValue(null);
    this.searchStates = [];
    this.searchStates = this.states;
  }

  /**
   * Function used to create new card
   */
  onNewCard(): void {
    // this.loadStripeElements();
    this.billingInfo = new UntypedFormGroup({
      name: new UntypedFormControl(null, [
        Validators.required,
        Validators.pattern(dataConfig.patternValidators.acceptOnlyAlphabets),
      ]),
      doorNo: new UntypedFormControl(null, [
        Validators.maxLength(50),
        Validators.pattern(dataConfig.patternValidators.DoorNoValidationPattern),
      ]),
      streetName: new UntypedFormControl(null, [
        Validators.required,
        Validators.maxLength(50),
        Validators.pattern(dataConfig.patternValidators.addressValidationPattern),
      ]),
      zipCode: new UntypedFormControl(null, [
        Validators.required, Validators.maxLength(15)
      ]),
      city: new UntypedFormControl(null, [
        Validators.required,
        Validators.maxLength(50),
        Validators.pattern(dataConfig.patternValidators.acceptOnlyAlphabets),
      ]),
      state: new UntypedFormControl(null, [
        Validators.required
      ]),
      country: new UntypedFormControl(null, [
        Validators.required
      ])
    });
  }
  /**
   * Function used to load the stripe card elements
   */
  loadStripeElements(): void {
    const rootNode = document.getElementById('cardInfoGroup');
    const cardWrapper = document.createElement('div');
    rootNode.appendChild(cardWrapper);
    const elements = this.stripe && this.stripe.elements();
    // setup card Element
    this.cardElement = elements && elements.create('card', {
      style: {
        base: {
          color: 'currentColor',
          lineHeight: '40px',
          fontSize: '18px'
        },
      },
      hidePostalCode: true,
    });
    this.cardElement && this.cardElement.mount(cardWrapper);
  }
  /**
 * Function used to load the checkout card elements
 */
  // async loadCheckoutElements() {
  //   var errorStack = [], errorMessage, errorMessageElement = document.querySelector(".error-message");
  //   let publicApiKey = this.environment && this.environment.app && (this.environment.app === 'prod' || this.environment.app === 'us-prod') ? dataConfig.checkoutProductionPublicApiKey : dataConfig.checkoutPublicApiKey;
  //   Frames.init({ publicKey: publicApiKey, modes: [Frames.modes.DISABLE_COPY_PASTE] });
  //   Frames.addEventHandler(Frames.Events.CARD_VALIDATION_CHANGED, () => {
  //     errorMessageElement.textContent = !Frames.isCardValid() ? 'Your card number is invalid!' : ''
  //     // if (!Frames.isCardValid()) {
  //     //   errorMessageElement.textContent = 'Your card number is invalid!';
  //     // }
  //   }
  //   );
  //   Frames.addEventHandler(Frames.Events.FRAME_VALIDATION_CHANGED, onValidationChanged);
  //   Frames.addEventHandler(Frames.Events.CARD_TOKENIZATION_FAILED, () => {
  //     Frames.enableSubmitForm();
  //     this.isButtonLoading = false;
  //     this.paymentProcessing.emit(this.isButtonLoading);
  //   }
  //   );
  //   Frames.addEventHandler(Frames.Events.CARD_TOKENIZED, (event) => {
  //     this.zone.run(() => {
  //       this.saveCard(event);
  //     })
  //   });

  //   /**
  //    * Function used for CheckoutDotCom Payment validation
  //    * @param event Parameters pass validation details
  //    */
  //   function onValidationChanged(event: any): any {
  //     if (event && event.element) {
  //       if (!event.isValid && !event.isEmpty) {
  //         errorStack.push(event.element);
  //       } else {
  //         errorStack = errorStack && errorStack.filter(function (element) {
  //           return element !== event.element;
  //         });
  //       }
  //       errorMessage = errorStack.length ? getErrorMessage(errorStack[errorStack.length - 1]) : event.isFormValid === false ? 'Card is not valid..!' : '';
  //       if (errorMessageElement) {
  //         errorMessageElement.textContent = errorMessage;
  //       }
  //     }
  //   }
  //   /**
  //    * Function used to choose CheckoutDotCom payment error message
  //    * @param element Parameters pass error element name
  //    */
  //   function getErrorMessage(element: string): any {
  //     var errors = {
  //       "card-number": "Please enter a valid card number",
  //       "expiry-date": "Please enter a valid expiry date",
  //       "cvv": "Please enter a valid cvv code",
  //     };
  //     return errors[element];
  //   }
  // }
  async loadCheckoutElements(): Promise<void> {
    var errorStack = [], errorMessage, errorMessageElement = document.querySelector(".error-message");
    let publicApiKey = this.isProd ? dataConfig.checkoutProductionPublicApiKey : dataConfig.checkoutPublicApiKey;
    Frames.init({ publicKey: publicApiKey, modes: [Frames.modes.DISABLE_COPY_PASTE] });
    Frames.addEventHandler(Frames.Events.FRAME_VALIDATION_CHANGED, onValidationChanged);
    Frames.addEventHandler(Frames.Events.CARD_TOKENIZED, (event) => {
      this.zone.run(() => {
        this.saveCard(event);
      })
    });
    Frames.addEventHandler(Frames.Events.CARD_VALIDATION_CHANGED, () => {
      errorMessageElement.textContent = !Frames.isCardValid() ? 'Card is not valid..!' : '';
    }
    );
    Frames.addEventHandler(Frames.Events.CARD_TOKENIZATION_FAILED, () => {
      Frames.enableSubmitForm();
      this.isButtonLoading = false;
    });

    /**
     * Function used for CheckoutDotCom Payment validation
     * @param event Parameters pass validation details
     */
    function onValidationChanged(event: any): any {
      if (event && event.element) {
        if (!event.isValid && !event.isEmpty) {
          errorStack.push(event.element);
        } else {
          errorStack = errorStack && errorStack.filter((element) => {
            return element !== event.element;
          });
        }
        errorMessage = errorStack.length ? getErrorMessage(errorStack[errorStack.length - 1]) : event.isFormValid === false ? 'Card is not valid..!' : '';
        if (errorMessageElement) {
          errorMessageElement.textContent = errorMessage;
        }
      }
    }
    /**
     * Function used to choose CheckoutDotCom payment error message
     * @param element Parameters pass error element name
     */
    function getErrorMessage(element: string): any {
      var errors = {
        "card-number": "Please enter a valid card number",
        "expiry-date": "Please enter a valid expiry date",
        "cvv": "Please enter a valid cvv code",
      };
      return errors[element];
    }
  }
  /**
   * This function is used to submit checkout payment form.
   */
  // async onSubmit() {
  //   try {
  //     this.isButtonLoading = true;
  //     this.paymentProcessing.emit(this.isButtonLoading);
  //     if (this.billingInfo && this.billingInfo.valid && this.billingInfo.value) {
  //       Frames.cardholder = {
  //         billingAddress: {
  //           addressLine1: this.billingInfo.value.doorNo,
  //           addressLine2: this.billingInfo.value.streetName,
  //           zip: this.billingInfo.value.zipCode,
  //           city: this.billingInfo.value.city,
  //           state: this.billingInfo.value.state && this.billingInfo.value.state.code,
  //           country: this.billingInfo.value.country && this.billingInfo.value.country.countryIsoCode
  //         }
  //       };
  //       await Frames.submitCard();
  //     } else {
  //       this.isButtonLoading = false;
  //       this.paymentProcessing.emit(this.isButtonLoading);
  //       this.billingInfo.markAllAsTouched();
  //     }
  //   } catch (err) {
  //     this.isButtonLoading = false;
  //     this.paymentProcessing.emit(this.isButtonLoading);
  //     Frames.enableSubmitForm();
  //     this.dialogService.openDialog({
  //       header: this.errorMessage.failureHeader,
  //       message: err && err.message ? err.message : err,
  //       actionType: this.dialogType.failure,
  //       button: { right: this.buttonText.ok }
  //     });
  //   }
  // }
  async onSubmit(): Promise<void> {
    if (this.billingInfo && this.billingInfo.valid && this.billingInfo.value) {
      if (this.paymentType && this.paymentConstant && (this.paymentType === (this.paymentConstant.paymentOption &&
        this.paymentConstant.paymentOption.CHECKOUT))) {
        try {
          this.isButtonLoading = true;
          this.paymentProcessing.emit(this.isButtonLoading);
          Frames.cardholder = {
            billingAddress: {
              addressLine1: this.billingInfo.value.doorNo,
              addressLine2: this.billingInfo.value.streetName,
              zip: this.billingInfo.value.zipCode,
              city: this.billingInfo.value.city,
              state: this.billingInfo.value.state && this.billingInfo.value.state.code,
              country: this.billingInfo.value.country && this.billingInfo.value.country.countryIsoCode
            }
          };
          await Frames.submitCard();
        } catch (err) {
          this.isButtonLoading = false;
          this.paymentProcessing.emit(this.isButtonLoading);
          Frames.enableSubmitForm();
          this.dialogService.openDialog({
            header: this.errorMessage.failureHeader,
            message: err && err.message ? err.message : err,
            actionType: this.dialogType.failure,
            button: { right: this.buttonText.ok }
          });
        }
      } else {
        this.isButtonLoading = true;
        this.paymentProcessing.emit(this.isButtonLoading);
        await this.stripe && this.stripe.createToken(this.cardElement, {
          name: this.billingInfo.value.name,
          address_line1: this.billingInfo.value.doorNo + ' ' + this.billingInfo.value.streetName,
          address_line2: this.billingInfo.value.doorNo + ' ' + this.billingInfo.value.streetName,
          address_city: this.billingInfo.value.city,
          address_state: this.billingInfo.value.state && this.billingInfo.value.state.name,
          address_zip: this.billingInfo.value.zipCode,
          address_country: this.billingInfo.value.country && this.billingInfo.value.country.countryISOCode
        }).then((obj) => {
          if (obj && obj['error']) {
            this.dialogService.openDialog({
              header: this.errorMessage.failureHeader,
              message: obj['error'].message,
              actionType: this.dialogType.failure,
              button: { right: this.buttonText.ok }
            });
            this.isButtonLoading = false;
            this.paymentProcessing.emit(this.isButtonLoading);
          } else if (obj && obj.token && obj.token.id) {
            this.saveCard(obj);
          }
          // this.isButtonLoading = false;
          // this.paymentProcessing.emit(this.isButtonLoading);
        });
      }
    } else {
      this.isButtonLoading = false;
      this.paymentProcessing.emit(this.isButtonLoading);
      this.billingInfo.markAllAsTouched();
    }

  }
  /**
   * Function called when you want to edit your card details
   */
  editCard() {
    this.isEdit = true;
    if (this.paymentType && this.paymentConstant && (this.paymentType === (this.paymentConstant.paymentOption &&
      this.paymentConstant.paymentOption.CHECKOUT))) {
      this.loadCheckoutScript().then(() => {
        this.loadCheckoutElements();
      });
    } else if (this.paymentType && this.paymentConstant && (this.paymentType === (this.paymentConstant.paymentOption &&
      this.paymentConstant.paymentOption.STRIPE))) {
      this.load();
    }
    // this.isLoading = true;
    // this.load();
    // this.loadCheckoutScript().then(() => {
    //   this.loadCheckoutElements();
    // });
    // this.isLoading = false;
  }

  /**
 * Component changes life cycle hook.
 * @param changes To store changed input value.
 */
  ngOnChanges(changes) {
    if (changes.hasOwnProperty('disablePayButton')) {
      this.isButtonLoading = changes && changes.currentValue;
    }
    if (this.isBack && this.isBack.back) {
      if ((this.billingInfo && this.billingInfo.dirty) || (this.isSaveCard && this.isSaveCard.dirty)) {
        let data = { 'isDirty': true }
        this.formStatus.emit(data);
      }
      else {
        let data = { 'isDirty': false }
        this.formStatus.emit(data);
      }
    }
    else if (this.isClosed) {
      this.isEdit = false;
      this.billingInfo.reset();
      // if (this.billingInfo && this.billingInfo.get('country') && this.billingInfo.get('country').value) {
      this.searchStates = null;
      // }
      if (this.isSaveCard && this.isSaveCard.dirty) {
        this.isSaveCard.setValue(false);
        this.isSaveCard.markAsPristine();
      }
      if (this.paymentType && this.paymentConstant && (this.paymentType === (this.paymentConstant.paymentOption &&
        this.paymentConstant.paymentOption.STRIPE))) {
        this.cardElement = null;
        const rootNode = document.getElementById('cardInfoGroup');
        if (rootNode) {
          rootNode.removeChild(rootNode.firstChild);
        }
      }
    }
  }
  /**
   * Function called when cancel the card  edit option
   */
  cancelCard(): void {
    if (this.billingInfo.dirty || (this.isSaveCard && this.isSaveCard.dirty)) {
      let data = { isDirty: 'true' }
      this.paymentProcessing.emit(data);
    }
    else {
      this.isEdit = false;
      this.billingInfo.reset();
      if (this.paymentType && this.paymentConstant && (this.paymentType === (this.paymentConstant.paymentOption &&
        this.paymentConstant.paymentOption.STRIPE))) {
        this.cardElement = null;
        const rootNode = document.getElementById('cardInfoGroup');
        if (rootNode) {
          rootNode.removeChild(rootNode.firstChild);
        }
      }
    }
  }
  /**
   * Function used to get the token for new card payment
   * @param event holds the event value.
   */
  saveCard(event?: any): void {
    // if (this.planDetails && this.planDetails.amount) {
    //   if (this.billingInfo && this.billingInfo.valid) {
    //     this.isButtonLoading = true;
    //     this.paymentProcessing.emit(this.isButtonLoading);
    //     this.stripe && this.stripe.createToken(this.cardElement, {
    //       name: this.billingInfo.value.name,
    //       address_line1: this.billingInfo.value.doorNo + ' ' + this.billingInfo.value.streetName,
    //       address_line2: this.billingInfo.value.doorNo + ' ' + this.billingInfo.value.streetName,
    //       address_city: this.billingInfo.value.city,
    //       address_state: this.billingInfo.value.state && this.billingInfo.value.state.name,
    //       address_zip: this.billingInfo.value.zipCode,
    //       address_country: this.billingInfo.value.country && this.billingInfo.value.country.countryISOCode
    //     }).then((obj) => {
    //       if (obj && obj['error']) {
    //         this.dialogService.openDialog({
    //           header: this.errorMessage.failureHeader,
    //           message: obj['error'].message,
    //           actionType: this.dialogType.failure,
    //           button: { right: this.buttonText.ok }
    //         });
    //       } else if (obj && obj.token && obj.token.id) {
    //         let data = {
    //           token: obj.token.id,
    //           isCardEntry: true,
    //           // isAutoRenewal: this.isSaveCard.value,
    //           isSaveCard: this.isSaveCard.value,
    //           name: this.storeDetails && this.storeDetails.store && this.storeDetails.store.name
    //         }
    //         //     this.payment(data);
    //         //   }
    //         //   this.isButtonLoading = false;
    //         //   this.paymentProcessing.emit(this.isButtonLoading);
    //         // });
    //         // } else {
    //         // this.billingInfo.markAllAsTouched();
    //         // }
    //         // let data = {
    //         //   isCardEntry: true,
    //         //   email: this.storeDetails && this.storeDetails.email,
    //         //   isSaveCard: this.isSaveCard.value,
    //         //   type: 'token',
    //         //   token: event && event.token,
    //         //   storeName: this.storeDetails && this.storeDetails.store && this.storeDetails.store.name,
    //         //   name: this.billingInfo && this.billingInfo.value && this.billingInfo.value.name
    //         // }
    //         this.payment(data);
    //       }
    //       this.isButtonLoading = false;
    //       this.paymentProcessing.emit(this.isButtonLoading);
    //     });
    //   } else {
    //     this.billingInfo.markAllAsTouched();
    //   }
    // } else {
    //   this.payment();
    // }
    if (this.planDetails && this.planDetails.amount) {
      let data;
      this.isButtonLoading = true;
      this.paymentProcessing.emit(this.isButtonLoading);

      if (this.paymentType && this.paymentConstant && (this.paymentType === (this.paymentConstant.paymentOption &&
        this.paymentConstant.paymentOption.CHECKOUT))) {

        data = {
          isCardEntry: true,
          email: this.storeDetails && this.storeDetails.email,
          isSaveCard: this.isSaveCard.value,
          type: 'token',
          token: event && event.token,
          storeName: this.storeDetails && this.storeDetails.store && this.storeDetails.store.name,
          name: this.billingInfo && this.billingInfo.value && this.billingInfo.valid && this.billingInfo.value.name
        }
      } else {
        data = {
          token: event && event.token && event.token.id,
          email: this.storeDetails && this.storeDetails.email,
          isSaveCard: this.isSaveCard.value,
          isCardEntry: true,
          name: this.storeDetails && this.storeDetails.store && this.storeDetails.store.name
        }
      }
      this.payment(data);
      // this.isButtonLoading = false;
      // this.paymentProcessing.emit(this.isButtonLoading);
    } else {
      this.payment();
    }
  }
  /**
   * Function used to make payment for the plan
   * @param data argument which will have data required for payment
   */
  payment(data?: any): void {
    if (!(this.pluginInfo && this.pluginInfo.isPlugin)) {
      this.isButtonLoading = true;
      // data = data ? data : {};
      this.paymentProcessing.emit(this.isButtonLoading);
      if (this.planDetails && this.planDetails.amount &&
        this.storeDetails && this.storeDetails.purchasedCurrency && this.storeDetails.purchasedCurrency.currencyUnit !== "INR") {
        // if (this.customerCardDetails && !this.isEdit) {
        //   data['type'] = 'id';
        //   data['token'] = this.customerCardDetails && this.customerCardDetails.customer;
        //   data['isSaveCard'] = this.isSaveCard.value
        // } else {
        //   Frames.enableSubmitForm();
        // }
        if (!data && this.paymentType && this.paymentConstant && (this.paymentType === (this.paymentConstant.paymentOption &&
          this.paymentConstant.paymentOption.CHECKOUT))) {
          data = { type: 'id', token: this.customerCardDetails && this.customerCardDetails.customer };
        } else if (!data && this.paymentType && this.paymentConstant && (this.paymentType === (this.paymentConstant.paymentOption &&
          this.paymentConstant.paymentOption.STRIPE))) {
          data = { customerId: this.customerCardDetails && this.customerCardDetails.customer };
        }
        data = {
          ...data, ...{
            currency: this.storeDetails && this.storeDetails.purchasedCurrency && this.storeDetails.purchasedCurrency.currencyUnit,
            isTestStore: this.storeDetails && this.storeDetails.store && this.storeDetails.store.isTestAccount,
            isSaveCard: this.isSaveCard.value, email: this.storeDetails && this.storeDetails.email,
            paymentType: this.paymentType
          }
        }
        // data['isSaveCard'] = this.isSaveCard.value;
        // data['email'] = this.storeDetails && this.storeDetails.email;
        // data['paymentType'] = this.paymentType;

      }
      else {
        this.isButtonLoading = false;
      }
      if (data) {
        data['paymentType'] = this.paymentType;
      }
      this.isPaymentClicked = false;
      this.paymentDone.emit({ data: data });
      // this.isButtonLoading = false;
      this.paymentProcessing.emit(this.isButtonLoading);
      if (data && data.isCardEntry && this.paymentType && this.paymentConstant && (this.paymentType === (this.paymentConstant.paymentOption &&
        this.paymentConstant.paymentOption.CHECKOUT))) {
        Frames.enableSubmitForm();
      }
    } else {
      this.isButtonLoading = true;
      this.getPluginPaymentDetails('payment', data ? data : null);
    }
  }
  /**
   * Function used to create the order in razorpay
   */
  createOrderInRazorpay(): void {
    if (this.planDetails && this.planDetails.amount && !(this.pluginInfo && this.pluginInfo.isPlugin)) {
      const currentAmount = this.planDetails.amount;
      this.isButtonLoading = true;
      this.paymentProcessing.emit(this.isButtonLoading);
      // this.subscriptionObject.add(this.commonService.getPaymentLog().subscribe((res: any) => {
      //   if (res) {
      //     this.paymentCount = res.logs;
      //     if (this.paymentCount > 0) {
      //       const dialofRef = this.dialogService.dialogMethod(this.dialogMessages.paymentProcess, this.dialogType.alert);
      //       dialofRef.afterClosed().subscribe((res) => {
      //         if (res) {
      //           this.isButtonLoading = false;
      //           this.paymentProcessing.emit(this.isButtonLoading);

      //           this._location.back();
      //         }
      //       });
      //     } else {
      const option = {
        amount: this.planDetails.amount >= 0 && this.commonDataService.encryptDetails(this.planDetails.amount.toFixed(2)),
        currency: this.storeDetails && this.storeDetails.purchasedCurrency && this.storeDetails.purchasedCurrency.currencyUnit,
        storeId: this.storeDetails && this.storeDetails.storeId,
        transactionType: this.transactionType,
        preProcessOrderId: this.preProcessId
      }

      this.subscriptionObject.add(this.commonService.createOrderInRazorpay(option).subscribe({
        next: (response: any) => {
          if (response && response['order'] && response['order'].id) {
            this.payWithRazor(response['order'], response['paymentOrderId']);
          } else {
            this.payment({ errorMessage: "Failed to purchase the credits in razorpay" });
          }
        }, error: (err) => {
          this.payment({ errorMessage: (err && err.error && err.error.error) ? err.error.error.details : "Failed to purchase the credits in razorpay" });
        }
      }));
    } else if (this.planDetails && this.planDetails.amount && this.pluginInfo && this.pluginInfo.isPlugin) {
      this.isButtonLoading = true;
      this.getPluginPaymentDetails('createOrderInRazorpay');
    } else {
      this.payment();
    }
  }
  /**
   * Method is used check if the plugin has an existing plan.
   */
  getPluginPaymentDetails(event: any, data?: any) {
    if (this.pluginInfo && this.pluginInfo.pluginId && this.storeDetails && this.storeDetails.storeId) {
      this.subscriptionObject.add(this.commonService.getExpiredPlugin(this.pluginInfo.pluginId, this.pluginInfo.planId, this.storeDetails.storeId).subscribe((res: any) => {
        if (res && res.isPluginPlanExpired) {
          this.isButtonLoading = false;
          this.pluginInfo.isPlugin = false;
          this[event](data);
        } else if (res && !(res.isPluginPlanExpired)) {
          this.isButtonLoading = false;
          this.dialogService.dialogMethod('Already plan exceeds!', this.dialogType.alert, true);
        } else {
          this.isButtonLoading = false;
          this.dialogService.dialogMethod(this.dialogMessages.pluginPlanDetailsFetchFailed, this.dialogType.failure, true);
          return false;
        }
      }, (err) => {
        this.isButtonLoading = false;
        this.dialogService.dialogMethod(this.dialogMessages.pluginPlanDetailsFetchFailed, this.dialogType.failure, true);
      }))
    }
  }
  /**
   * Method which is used to call razor fucntion for payment
   * @param val parameter used for razorpay credentials
   */
  payWithRazor(val: any, paymentOrderId: number): void {
    if (val) {
      this.paymentProcessing.emit(false);
      const options: any = {
        key: val.keyId,
        amount: this.planDetails.amount && this.planDetails.amount.toFixed(2),
        currency: val.currency,
        order_id: val.id,
        modal: {
          // We should prevent closing of the form when esc key is pressed.
          escape: false,
        },
        theme: {
          color: '#0c238a'
        },
        notes: {
          preProcessOrderId: this.preProcessId,
          paymentOrderId,
          storeId: this.storeDetails?.storeId,
          AccessKey: this.environment.AccessKey,
          transactionType: this.transactionType,
          storeDetails: JSON.stringify({
            email: this.storeDetails?.email,
            name: this.storeDetails?.firstName,
            storeName: this.storeDetails?.store?.name,
            phone: this.storeDetails?.phone,
            isTestStore: this.storeDetails && this.storeDetails.store && this.storeDetails.store.isTestAccount
          })
        }
      };
      options.handler = ((response: any) => {
        this.zone.run(() => {
          if (response && response.razorpay_payment_id) {
            this.isPaymentClicked = false;
            // this.payment({ orderCode: response.razorpay_payment_id });
            this.paymentDone.emit({ paymentSuccess: true });
          } else if (response && response.error) {
            this.payment({ errorMessage: "Error during razorpay payment" });
          }
        });
      });
      options.modal.ondismiss = (() => {
        this.zone.run(() => {
          this.isPaymentClicked = false;
          // handle the case when user closes the form while transaction is in progress
          this.payment({ errorMessage: "Your transaction has been cancelled" });
        });
      });
      const rzp = new Razorpay(options);
      rzp.on('payment.failed', (response) => {
        this.payment({ errorMessage: "Error during razorpay payment" });
      });
      this.isPaymentClicked = true;
      rzp.open();
    }
  }

  /**
* Method used to initialize paypal button function
*/
  private initConfig(): void {
    try {
      this.isLoading = true;
      this.payPalConfig = {
        currency: this.storeDetails && this.storeDetails.purchasedCurrency && this.storeDetails.purchasedCurrency.currencyUnit,
        clientId: this.isProd ? dataConfig.paypalProductionPublicApiKey : dataConfig.paypalPublicApiKey,
        onClick: (): void => {
          this.isPaymentClicked = true;
        },
        createOrderOnClient: (data: any): any => <ICreateOrderRequest>{
          intent: 'CAPTURE',
          purchase_units: [
            {
              amount: {
                currency_code: this.storeDetails && this.storeDetails.purchasedCurrency && this.storeDetails.purchasedCurrency.currencyUnit,
                value: this.planDetails && this.planDetails.amount,
                breakdown: {
                  item_total: {
                    currency_code: this.storeDetails && this.storeDetails.purchasedCurrency && this.storeDetails.purchasedCurrency.currencyUnit,
                    value: this.planDetails && this.planDetails.amount,
                  }
                }
              }
            }
          ],
          application_context: {
            shipping_preference: 'NO_SHIPPING'
          }
        },
        style: {
          label: 'paypal',
          layout: 'vertical',
          color: 'silver'
        },
        onApprove: (data: any, actions: any): void => {
          if (actions && actions.order) {
            let funcName = 'capture';
            actions.order[funcName]().then(details => {
              this.zone.run(() => {
                if (details && details.purchase_units && details.purchase_units[0] && details.purchase_units[0].payments.captures &&
                  details.purchase_units[0].payments.captures[0] && details.purchase_units[0].payments.captures[0].id && details.purchase_units[0].payments.captures[0].status &&
                  (details.purchase_units[0].payments.captures[0].status == 'COMPLETED' || details.purchase_units[0].payments.captures[0].status == 'PENDING')) {
                  this.payment({
                    orderCode: details && details.purchase_units && details.purchase_units[0] && details.purchase_units[0].payments.captures &&
                      details.purchase_units[0].payments.captures[0] && details.purchase_units[0].payments.captures[0].id
                  });
                } else
                  this.dialogService.openSnackBar('Card details invalid! Provide valid details');
              });
            }).catch(err => {
              this.zone.run(() => {
                this.payment({ errorMessage: err && err.message ? err.message : 'Error in paypal payment' });
              });
            });
          }
        },
        onCancel: (): void => {
          this.isPaymentClicked = false;
        },
        onError: (err: any): void => {
          this.payment({ errorMessage: err && err.message ? err.message : 'Error in paypal payment' });
        }
      };
    } catch (err) {
      this.payment({ errorMessage: err && err.message ? err.message : 'Error in paypal payment' });
    }
    this.isLoading = false;
  }

  /**
   * To indicate during leaving of the page
   * @returns the form gets dirty or not
   */
  canDeactivate(): boolean {
    return this.billingInfo && !this.billingInfo.dirty && this.billingInfo.valid;
  }
  /**
   * Component ondestroy life cycle hook.
   * All subscriptions are unsubscribe here.
   */
  ngOnDestroy(): void {
    if (this.subscriptionObject) this.subscriptionObject.unsubscribe();
    let script = document.getElementsByTagName('script');
    if (script && script.length > 0 && this.paymentType && this.paymentConstant && (this.paymentType === (this.paymentConstant.paymentOption &&
      this.paymentConstant.paymentOption.CHECKOUT))) {
      let index = [].findIndex.call(script, (src) => src && src.src && src.src === 'https://cdn.checkout.com/js/framesv2.min.js');
      if (index !== -1) {
        script[index] && script[index].remove();
        if (Frames !== null) {
          Frames && Frames.Events && Frames.removeAllEventHandlers(Frames.Events.CARD_VALIDATION_CHANGED);
          Frames && Frames.Events && Frames.removeAllEventHandlers(Frames.Events.FRAME_VALIDATION_CHANGED);
          Frames && Frames.Events && Frames.removeAllEventHandlers(Frames.Events.CARD_TOKENIZED);
          Frames && Frames.Events && Frames.removeAllEventHandlers(Frames.Events.CARD_TOKENIZATION_FAILED);
        }
      }
    }
  }
}
