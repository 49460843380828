<div [attr.id]="notifyDivId"
    *ngIf="isEnabled && notificationMessage && allowNotification && (slideInClass || slideOutClass) && count > 0">
    <mat-icon *ngIf="isCloseNotification" [class]="closeIconClass" (click)="onCloseNotification(true)"> close</mat-icon>
    <div class="notificationDiv">
        <div class="left-div">
            <mat-icon class="cartIcon">add_shopping_cart</mat-icon>
        </div>
        <div class="right-div">
            <p class="notificationPara" [dynamicFontResizer]="languageCode" [innerHtml]="notificationMessage"></p>
        </div>
    </div>
</div>