import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { AffiliateAuthService } from './auth.service';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
/**
 * LogoutGuardService used for logout quard activity.
 */
export class LogoutGuardService {
  /**
   * To store jwt token 
   */
  token = localStorage.getItem('jwt_token');
  /**
   * Component constructor to inject the required services.
   * @param router 
   * @param authService To get the auth service.
   */
  constructor(private authService: AffiliateAuthService, private router: Router) { }
  /**
   * Method which is used to route for login.
   * @param route To get the current url.
   * @param currentState To get route params.
   */
  canActivate(route: ActivatedRouteSnapshot, currentState: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    // To find the current user authentication.
    if (this.authService.isAuthenticated()) {
      return this.authService.logout();
    } else {
      return true;
    }
  }
  /**
   * Method which is used to check permission for current route.
   */
  canActivateChild() {
    this.token = localStorage.getItem('jwt_token');
    if (!this.token) {
      this.router.navigate(['/signin']);
    }
  }
}
