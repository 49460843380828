import { Component, ElementRef, EventEmitter, Input, Output, Renderer2, TemplateRef, ViewChild, forwardRef } from "@angular/core";
import { FormControl, FormGroup, NG_VALUE_ACCESSOR } from "@angular/forms";
import { StorefrontEditorConstants } from "../../../../../../apps/storeadmin/src/app/storefront-editor/constants/storefront-editor-constants";
import { MatDialog } from "@angular/material/dialog";

@Component({
  selector: 'custom-angular-editor',
  templateUrl: './custom-text-editor.component.html',
  styleUrls: ['./custom-text-editor.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => customTextEditorComponent),
      multi: true,
    }
  ]
})

export class customTextEditorComponent extends StorefrontEditorConstants {
  /**
   * Boolean used to hide controls when other language selected
   */
  isDisable: boolean;

  /**
   * The ID of the section where the editor is located
   */
  @Input() sectionId: number;

  /**
   * The data to be displayed in the editor, with an unknown type
   */
  @Input() editorData: any;

  /**
   * The name of the form control associated with the editor
   */
  @Input() formControlName: string;

  /**
   * Event emitter for changes in the content, emits form group data, section ID, and group control name
   */
  @Output() contentChange = new EventEmitter<{ formGroupData: any, sectionId: number, groupControlName: string }>();

  /**
   * The form group associated with the editor
   */
  form: FormGroup;

  /**
   * Reference to the editor element in the DOM
   */
  @ViewChild('editor', { static: true }) editor!: ElementRef<HTMLDivElement>;

  /**
   * Reference to the link dialog template element in the DOM
   */
  @ViewChild('linkDialog') linkDialog!: TemplateRef<HTMLDivElement>;

  /**
   * Reference to the text color picker input element in the DOM
   */
  @ViewChild('textColorPicker') textColorPicker!: ElementRef<HTMLInputElement>;

  /**
   * Reference to the text background color picker input element in the DOM
   */
  @ViewChild('textBgColorPicker') textBgColorPicker!: ElementRef<HTMLInputElement>;

  /**
   * Boolean indicating whether bold formatting is applied
   */
  isBold: boolean = false;

  /**
   * Boolean indicating whether italic formatting is applied
   */
  isItalic: boolean = false;

  /**
   * Boolean indicating whether underline formatting is applied
   */
  isUnderline: boolean = false;

  /**
   * Boolean indicating whether strikethrough formatting is applied
   */
  isStrike: boolean = false;

  /**
   * Boolean indicating whether a link is being inserted
   */
  isLink: boolean = false;

  /**
   * Boolean indicating whether link insertion is disabled
   */
  isLinkDisable: boolean = true;

  /**
   * Boolean indicating whether color selection is disabled
   */
  isColorDisable: boolean = true;

  /**
   * Boolean indicating whether undo action is disabled
   */
  isUndoDisable: boolean = true;

  /**
   * Boolean indicating whether redo action is disabled
   */
  isRedoDisable: boolean = true;

  /**
   * The URL value for the link being inserted
   */
  hrefValue: string = '';

  /**
   * Boolean indicating whether an existing link is being edited
   */
  isEditLink: boolean = false;
  /**
   * Font family options for the editor
   */
  fontFamily: { name: string; value: string; }[] = this.fontFamily;

  /**
   * Font size options for the editor
   */
  fontSizeOptions = [
    { name: 'Heading 1', value: '32px', weight: 'bold' },
    { name: 'Heading 2', value: '24px', weight: 'bold' },
    { name: 'Heading 3', value: '18.72px', weight: 'bold' },
    { name: 'Heading 4', value: '16px', weight: 'bold' },
    { name: 'Heading 5', value: '13.28px', weight: 'bold' },
    { name: 'Heading 6', value: '10.72px', weight: 'bold' },
    { name: 'Paragraph', value: '16px', weight: 'normal' }
  ];
  /**
   * Array to store the history of changes for undo/redo functionality
   */
  history: string[] = [];
  /**
   * Index indicating the current position in the history for undo/redo
   */
  historyIndex: number = -1;
  /**
   * Variable used to store the selected language.
   */
  language = 'en';
  defaultLanguage = 'en';
  // Constructor to inject dependencies
  constructor(
    private renderer: Renderer2,
    private dialog: MatDialog) {
    super();
  }

  /**
   * Lifecycle hook called after Angular has initialized all data-bound properties of a directive.
   * Initializes form controls and captures the initial state.
   */
  ngOnInit(): void {
    this.language = localStorage.getItem('language') ?? 'en';
    if (this.language !== 'en') {
      this.isDisable = true;
    }
    const initialValue = this.formControlName && this.editorData && this.editorData[this.formControlName] ? this.editorData[this.formControlName] : null;
    this.form = new FormGroup({
      editorContent: new FormControl(
        initialValue
          ? initialValue[this.language] !== undefined ? initialValue[this.language] : initialValue[this.defaultLanguage] ? initialValue[this.defaultLanguage] : initialValue
          : null
      )
    });
    // Capture the initial state
    this.captureHistory();
  }

  /**
   * Lifecycle hook called after Angular has fully initialized a component's view.
   * Listens for selection changes to update active states.
   */
  ngAfterViewInit(): void {
    this.renderer.listen('document', 'selectionchange', () => {
      this.updateActiveStates();
    });
  }

  /**
   * Lifecycle hook that is called when Angular dirty checks a directive.
   * Updates the state of various flags based on the editor's selection and history.
   */
  ngDoCheck(): void {
    const selection: Selection | null = this.getEditorSelection();
    let selectedText = ''
    if (selection && selection.rangeCount > 0) {
      selectedText = selection.toString();
      this.isLinkDisable = false;
      if (selectedText.length > 0) {
        this.isColorDisable = false;
      }
      else {
        this.isColorDisable = true;
      }
    }
    else {
      this.isLinkDisable = true;
      this.isColorDisable = true;
    }

    this.isUndoDisable = this.historyIndex > 1 ? false : true;
    this.isRedoDisable = this.historyIndex < this.history.length - 1 ? false : true;
  }

  /**
   * Emit data when content changes
   */
  emitData(): void {
    const htmlContent = this.editor.nativeElement.innerHTML;
    if (this.language && this.language !== 'en' && this.formControlName && this.editorData[this.formControlName]?.[this.language]) {
      this.editorData[this.formControlName][this.language] = htmlContent;
    } else if (this.language === this.defaultLanguage) {
      this.editorData[this.formControlName][this.defaultLanguage] = htmlContent;
    } else {
      this.editorData[this.formControlName] = htmlContent;
    }
    this.contentChange.emit({
      formGroupData: this.editorData,
      sectionId: this.sectionId,
      groupControlName: this.formControlName
    });
    // Capture the new state in history
    this.captureHistory();
  }

  /**
   * Capture the current state in the history
   */
  captureHistory(): void {
    const currentContent = this.editor.nativeElement.innerHTML;

    // If the new content is the same as the last one in history, do nothing
    if (this.history[this.historyIndex] === currentContent) {
      return;
    }

    // If we are not at the end of the history stack, remove all future states
    if (this.historyIndex < this.history.length - 1) {
      this.history = this.history.slice(0, this.historyIndex + 1);
    }

    // Add the new content to the history and update the index
    this.history.push(currentContent);
    this.historyIndex = this.history.length - 1;
  }

  /**
   * Undo the last action
   */
  undo(): void {
    if (this.historyIndex > 1) {
      this.historyIndex--;
      this.restoreHistory();
    }
  }

  /**
   * Redo the last undone action
   */
  redo(): void {
    if (this.historyIndex < this.history.length - 1) {
      this.historyIndex++;
      this.restoreHistory();
    }
  }

  /**
   * Restore history state
   */
  restoreHistory(): void {
    const content = this.history[this.historyIndex];
    this.editor.nativeElement.innerHTML = content;
    this.emitData();
  }

  /**
   * Get current selection in the editor
   */
  getEditorSelection(): Selection | null {
    const editorElement = this.editor.nativeElement;
    if (!editorElement) return null;
    const selection = window.getSelection();
    if (!selection || selection.rangeCount === 0) return null;

    const range = selection.getRangeAt(0);
    if (!editorElement.contains(range.commonAncestorContainer)) return null;

    return selection;
  }

  /**
   * Update active states based on current selection
   */
  updateActiveStates(): void {
    const selection: Selection | null = this.getEditorSelection();
    if (!selection || selection.rangeCount === 0) {
      this.isBold = false;
      this.isItalic = false;
      this.isUnderline = false;
      this.isStrike = false;
      this.isLink = false;
      return;
    }
    this.isLink = this.getHref(selection).length > 0;
    const range: Range = selection.getRangeAt(0);
    const startContainer: Node = range.startContainer;

    // Find the appropriate parent element of the first character
    const firstCharacterParent: HTMLElement = (startContainer.nodeType === Node.TEXT_NODE) ? startContainer.parentNode as HTMLElement : startContainer as HTMLElement;

    // Helper function to check if a parent element has a specific style
    const hasStyle = (element: HTMLElement | null, style: keyof CSSStyleDeclaration, value: string): boolean => {
      while (element) {
        if (element.nodeType === Node.ELEMENT_NODE && window.getComputedStyle(element)[style] === value) {
          return true;
        }
        element = element.parentNode as HTMLElement;
      }
      return false;
    };

    this.isBold = hasStyle(firstCharacterParent, 'fontWeight', '700') || hasStyle(firstCharacterParent, 'fontWeight', 'bold');
    this.isItalic = hasStyle(firstCharacterParent, 'fontStyle', 'italic');
    this.isUnderline = hasStyle(firstCharacterParent, 'textDecorationLine', 'underline');
    this.isStrike = hasStyle(firstCharacterParent, 'textDecorationLine', 'line-through');
  }

  /**
   * Create or edit a link
   */
  createLink(): void {
    const selection: Selection | null = this.getEditorSelection();
    let range: Range;
    let selectedText = '';
    if (selection && selection.rangeCount > 0) {
      range = selection.getRangeAt(0)
      selectedText = range.toString();
      this.hrefValue = this.getHref(selection);
      this.isEditLink = this.hrefValue.length > 0;
      const dialogRef = this.dialog.open(this.linkDialog);
      dialogRef.afterClosed().subscribe((res) => {
        if (res) {
          this.hrefValue = '';
          this.isEditLink = false;
          if (res === 'unlink') {
            this.unlink(range);
          } else if (res.isEdit) {
            this.editLink(range, res.link);
          } else if (!res.isEdit) {
            const anchor = this.renderer.createElement('a');
            this.renderer.setAttribute(anchor, 'href', res.link);
            if (selectedText.length > 0)
              range.surroundContents(anchor);
            else {
              anchor.appendChild(document.createTextNode(res.link));
              range.insertNode(anchor);
            }
          }
          this.emitData();
          this.isLinkDisable = true;
        }
      })
    }
  }

  /**
   * Retrieves the href attribute of the nearest anchor (<a>) element within the current selection.
   * @param selection - The current text selection.
   * @returns The href attribute of the nearest anchor element, or an empty string if no anchor is found.
   */
  getHref(selection: Selection): string {
    let hrefValue = '';
    const range = selection.getRangeAt(0);
    const container = range.commonAncestorContainer;

    if (container.nodeType === Node.TEXT_NODE) {
      const parentElement = (container.parentElement as HTMLElement | null);
      if (parentElement) {
        const parentAnchor = parentElement.closest('a');
        if (parentAnchor) {
          hrefValue = parentAnchor.href;
        }
      }
    } else if (container.nodeType === Node.ELEMENT_NODE) {
      const containerElement = container as HTMLElement;
      const anchorTag = containerElement.querySelector('a');
      if (anchorTag) {
        hrefValue = anchorTag.href;
      }
    }

    return hrefValue;
  }

  /**
   * Updates the href attribute of the nearest anchor (<a>) element within the specified range to a new URL.
   * @param range - The Range object representing the current selection range.
   * @param newHref - The new URL to set as the href attribute.
   */
  editLink(range: Range, newHref: string): void {
    const container = range.commonAncestorContainer;
    let parentAnchor: HTMLAnchorElement | null = null;

    if (container.nodeType === Node.TEXT_NODE) {
      const parentElement = container.parentElement as HTMLElement | null;

      if (parentElement) {
        parentAnchor = parentElement.closest('a');
      }
    } else if (container.nodeType === Node.ELEMENT_NODE) {

      const containerElement = container as HTMLElement;
      parentAnchor = containerElement.querySelector('a');
    }

    if (parentAnchor) {
      this.renderer.setAttribute(parentAnchor, 'href', newHref);
    }
  }

  /**
   * Removes the nearest anchor (<a>) element within the specified range, keeping its child nodes in place.
   * @param range - The Range object representing the current selection range.
   */
  unlink(range: Range): void {
    const container = range.commonAncestorContainer;
    let parentAnchor: HTMLAnchorElement | null = null;

    if (container.nodeType === Node.TEXT_NODE) {
      const parentElement = container.parentElement as HTMLElement | null;
      if (parentElement) {
        parentAnchor = parentElement.closest('a');
      }
    } else if (container.nodeType === Node.ELEMENT_NODE) {
      const containerElement = container as HTMLElement;
      parentAnchor = containerElement.querySelector('a');
    }

    if (parentAnchor && parentAnchor.parentNode) {
      const parent = parentAnchor.parentNode;
      while (parentAnchor.firstChild) {
        parent.insertBefore(parentAnchor.firstChild, parentAnchor);
      }
      parent.removeChild(parentAnchor);
    }
  }

  /**
   * Triggers a click event on the color picker input element.
   * If isBgColor is true, it triggers the background color picker;
   * otherwise, it triggers the text color picker.
   * @param isBgColor - A boolean indicating whether to trigger the background color picker.
   */
  triggerColorPicker(isBgColor: boolean): void {
    if (isBgColor)
      this.textBgColorPicker.nativeElement.click();
    else
      this.textColorPicker.nativeElement.click();
  }

  /**
   * Changes the text or background color based on the event triggered by the color picker.
   * @param event - The Event object triggered by the color picker input.
   * @param isBgColor - A boolean indicating whether to change the background color (true) or the text color (false).
   */
  changeColor(event: Event, isBgColor: boolean): void {
    this.applyTextStyle(undefined, undefined, undefined, { color: event.toString(), isBgColor: isBgColor });
  }

  /**
   * Applies various text styles (font family, font size, font weight, and colors) to the currently selected text in the document.
   * @param fontFamily - The font family to apply to the selected text. Optional.
   * @param fontSize - The font size to apply to the selected text. Optional.
   * @param fontWeight - The font weight to apply to the selected text. Optional.
   * @param fontColor - An object containing the color and a boolean indicating if it's a background color.
   */
  applyTextStyle(fontFamily?: string, fontSize?: string, fontWeight?: string, fontColor?: { color: string, isBgColor: boolean }): void {
    const selection = window.getSelection();
    if (!selection || selection.rangeCount === 0) return;

    const range = selection.getRangeAt(0);
    const selectedText = range.toString();
    if (!selectedText) return;

    // Extract contents from the range
    const extractedContents = range.extractContents();

    // Create a document fragment to hold the new content
    const fragment = document.createDocumentFragment();

    // Helper function to apply styles to a node
    const applyStyles = (node: HTMLElement) => {
      if (fontSize) node.style.fontSize = fontSize;
      if (fontWeight) node.style.fontWeight = fontWeight;
      if (fontFamily) node.style.fontFamily = fontFamily;
      if (fontColor && !fontColor.isBgColor) node.style.color = fontColor.color;
      if (fontColor && fontColor.isBgColor) node.style.backgroundColor = fontColor.color;
    };

    // Iterate through the nodes in the extracted contents
    extractedContents.childNodes.forEach(node => {
      if (node.nodeType === Node.TEXT_NODE) {
        // For text nodes, wrap in a span and apply styles
        const textSpan = document.createElement('span');
        applyStyles(textSpan);
        textSpan.textContent = node.textContent;
        fragment.appendChild(textSpan);
      } else if (node.nodeType === Node.ELEMENT_NODE) {
        // For element nodes, preserve existing tags and styles
        const element = node as HTMLElement;
        const clonedElement = element.cloneNode(true) as HTMLElement;

        // Apply styles to the cloned element
        applyStyles(clonedElement);

        // Iterate over child nodes to apply styles recursively
        const walker = document.createTreeWalker(clonedElement, NodeFilter.SHOW_ELEMENT | NodeFilter.SHOW_TEXT, null);
        let currentNode: Node | null;
        while ((currentNode = walker.nextNode())) {
          if (currentNode.nodeType === Node.ELEMENT_NODE) {
            applyStyles(currentNode as HTMLElement);
          }
        }

        fragment.appendChild(clonedElement);
      }
    });

    // Insert the new content into the range
    range.insertNode(fragment);
    this.emitData();
    this.isColorDisable = true;
    selection.removeAllRanges();

    // Adjust the selection to the newly styled content if fragment has children
    if (fragment.childNodes.length > 0) {
      selection.removeAllRanges();
      const newRange = document.createRange();
      newRange.setStartBefore(fragment.firstChild!);
      newRange.setEndAfter(fragment.lastChild!);
      selection.addRange(newRange);
    }
  }

  /**
   * Toggles the list style of the selected content between unordered (bulleted) and ordered (numbered) lists.
   * @param type - The type of list style to toggle: 'unordered' for bulleted lists, 'ordered' for numbered lists.
   */
  toggleListStyle(type: 'unordered' | 'ordered'): void {
    const selection = window.getSelection();
    if (!selection?.rangeCount) return;

    const range = selection.getRangeAt(0);
    const listParent = this.findListParent(range.commonAncestorContainer);

    const newListElement = document.createElement(type === 'unordered' ? 'ul' : 'ol');

    // Get the selected content
    const documentFragment = range.cloneContents();
    // Create a temporary div to hold the cloned contents
    const tempDiv = document.createElement('div');
    tempDiv.appendChild(documentFragment);

    // Split the text content by full stops and create list items
    const textContent = tempDiv.innerHTML || '';
    const sentences = textContent.split('.').filter(sentence => sentence.trim() !== '');

    sentences.forEach(sentence => {
      const listItem = document.createElement('li');
      const span = document.createElement('span');
      span.innerHTML = sentence.trim() + '.';
      listItem.appendChild(span);
      newListElement.appendChild(listItem);
      this.emitData();
    });

    if (listParent) {
      const parentList = listParent.parentNode as HTMLElement;
      const emptyDivs = Array.from(parentList.querySelectorAll('div')).filter(div => div.textContent?.trim() === '');
      emptyDivs.forEach(emptyDiv => emptyDiv.remove());

      if (listParent.nodeName !== newListElement.nodeName) {
        const listItems = Array.from(listParent.children);
        newListElement.innerHTML = '';
        listItems.forEach(item => {
          const listItem = document.createElement('li');
          while (item.firstChild) {
            listItem.appendChild(item.firstChild);
          }
          newListElement.appendChild(listItem);
        });
        parentList.replaceChild(newListElement, listParent);
        this.emitData();
      } else {
        // Revert to the original content
        if (parentList) {
          const originalContent = document.createDocumentFragment();
          while (listParent.firstChild) {
            const child = listParent.firstChild;
            if (child.nodeName === 'LI') {
              while (child.firstChild) {
                originalContent.appendChild(child.firstChild);
              }
            } else {
              originalContent.appendChild(child);
            }
            listParent.removeChild(child);
          }
          parentList.replaceChild(originalContent, listParent);
          this.emitData();
        }
      }
    } else {
      // No existing list, so create a new list at the selection
      range.deleteContents();
      range.insertNode(newListElement);
      this.emitData();
    }

    // Cleanup: Adjust the selection to the newly created list item
    selection.removeAllRanges();
    const newRange = document.createRange();
    newRange.selectNodeContents(newListElement);
    selection.addRange(newRange);
    selection.removeAllRanges();
  }

  /**
   * Recursively searches for the nearest parent node that is an unordered list (UL) or ordered list (OL).
   * @param node - The current node to start the search from.
   * @returns The nearest parent node that is an UL or OL element, or null if not found.
   */
  findListParent(node: Node | null): HTMLElement | null {
    if (!node) return null;
    if (node.nodeName === 'UL' || node.nodeName === 'OL') {
      return node as HTMLElement;
    }
    return this.findListParent(node.parentNode);
  }

  /**
   * Sets the text alignment (left, center, right, justify) for the selected block element.
   * @param align - The desired text alignment value ('left', 'center', 'right', 'justify').
   */
  setAlignment(align: string): void {
    const selection: Selection | null = this.getEditorSelection();
    if (selection && selection.rangeCount > 0) {
      const range: Range = selection.getRangeAt(0);
      let startParentElement: HTMLElement | null = range?.startContainer.parentElement
      while (startParentElement) {
        if (this.isBlockElement(startParentElement)) {
          startParentElement.style.textAlign = align
          this.emitData();
          break
        }
        else {
          startParentElement = startParentElement?.parentElement
        }
      }
    }
  }

  /**
   * Checks if the given HTML element is a block-level element.
   * @param element - The HTML element to check.
   * @returns A boolean indicating whether the element is a block-level element.
   */
  isBlockElement(element: HTMLElement): boolean {
    const display: string = window.getComputedStyle(element).display;
    return display === 'block' || display === 'flex' || display === 'table' || display === 'list-item' || display === 'grid';
  }

  /**
   * Adds or removes a specified HTML tag to/from the selected content and toggles the corresponding state.
   * @param tag - The HTML tag to add or remove (e.g., 'b' for <strong>, 'i' for <em>).
   * @param isTagApplied - A boolean indicating whether the tag is already applied to the selected content.
   */
  addTag(tag: string, isTagApplied: boolean): void {
    const selection: Selection | null = this.getEditorSelection();
    if (selection && selection.rangeCount > 0) {
      const range: Range = selection.getRangeAt(0);
      if (isTagApplied)
        this.removeTagFromSelection(tag, range);
      else
        this.addTagToSelection(tag, range);
      if (tag === 'b') {
        this.isBold = !this.isBold;
      } else if (tag === 'i') {
        this.isItalic = !this.isItalic
      } else if (tag === 'u') {
        this.isUnderline = !this.isUnderline
      } else if (tag === 's') {
        this.isStrike = !this.isStrike
      }
      this.emitData();
      selection.removeAllRanges();
    }
  }

  /**
   * Wraps the selected content in a specified HTML tag and inserts it into the document.
   * @param tag - The HTML tag to wrap the selected content with.
   * @param range - The Range object representing the selected content.
   */
  addTagToSelection(tag: string, range: Range): void {
    const element: HTMLElement = document.createElement(tag);
    element.appendChild(range.extractContents());
    range.insertNode(element);
  }

  /**
   * Removes the specified HTML tag from the selected content and inserts the modified content back into the document.
   * @param tag - The HTML tag to remove from the selected content.
   * @param range - The Range object representing the selected content.
   */
  removeTagFromSelection(tag: string, range: Range): void {
    const selectedContent: DocumentFragment = range.extractContents();
    const container: HTMLDivElement = document.createElement('div');
    container.appendChild(selectedContent);

    const tagElements: NodeListOf<Element> = container.querySelectorAll(tag);
    tagElements.forEach((element: Element) => {
      const parent = element.parentNode;
      if (parent) {
        while (element.firstChild) {
          parent.insertBefore(element.firstChild, element);
        }
        parent.removeChild(element);
      }
    });

    const fragment = document.createDocumentFragment();
    while (container.firstChild) {
      fragment.appendChild(container.firstChild);
    }

    range.insertNode(fragment);
  }

  /**
   * Clears all formatting from the content within the editor by removing HTML tags and updating the active states.
   */
  clearAllFormatting(): void {
    const editorElement = this.editor.nativeElement;
    if (editorElement) {
      editorElement.innerHTML = editorElement.textContent || '';
      this.emitData();
    }
  }
  /**
   * This method is triggered when the input value changes.
   * It is part of the ControlValueAccessor interface to handle input changes.
   */
  onInput(): void {
    // Implement input change handling logic here if needed.
  }

  /**
   * This method writes a new value to the element.
   * It is part of the ControlValueAccessor interface and is called when the form control's value is updated programmatically.
   * @param value The value to be written to the editor.
   */
  writeValue(value: any): void {
    // Implement value writing logic here if needed.
  }

  /**
   * Registers a callback function that should be called when the control's value changes in the UI.
   * This method is part of the ControlValueAccessor interface.
   * @param fn The callback function to register.
   */
  registerOnChange(fn: any): void {
    // Implement change callback registration here if needed.
  }

  /**
   * Registers a callback function that should be called when the control is touched.
   * This method is part of the ControlValueAccessor interface.
   * @param fn The callback function to register.
   */
  registerOnTouched(fn: any): void {
    // Implement touch callback registration here if needed.
  }

  /**
   * This method is called by the forms API when the control status changes to or from "DISABLED".
   * Depending on the parameter value, it enables or disables the form control.
   * @param isDisabled A boolean indicating whether the control should be disabled.
   */
  setDisabledState?(isDisabled: boolean): void {
    // Implement control enable/disable logic here if needed.
  }

}