import { Injectable } from '@angular/core';
import { AbstractControl, AsyncValidatorFn, UntypedFormControl, ValidationErrors } from '@angular/forms';
import { AuthService } from '@phase-ii/auth';
import { Observable, of, timer } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { CommonService } from './common.service';
/**
 * CustomAsyncValidatorService used to set validator for control name which already exists.
 */
@Injectable({
  providedIn: 'root'
})
/**
 * CustomAsyncValidatorService used to set validator for control value which already exists.
 */
export class CustomAsyncValidatorService {
  /**
     * variables used to proccess zipcode
     */
  pincodeList: any;
  /**
     * variables used to proccess zipcode
     */
  checkAlpha: any;
  /**
   * Constructor  which is used to inject services.
   */
  constructor() { }
  /**
  * Method which is used to check the dublicate value.
  */
  static asyncCheckDuplicateValidation(commonService: CommonService, tableName: string, columnName: string,
    storeId: any, id?: any, hasStoreId?: any, isGuest?: any, isForCustomer?: boolean,
    includeTable?: any, isContactNumber?: any, langColumnName?:string ,langCode?: string): AsyncValidatorFn {
    return (control: AbstractControl): Promise<ValidationErrors | null> | Observable<ValidationErrors | null> => {
      return timer(500).pipe(switchMap(() => {
        if (!control.pristine && control.value) {
          return commonService.checkDuplicate({
            value: isContactNumber ? ('' + control.value).replace(/\D/g, "") : control.value.trim(),
            tableName: tableName, columnName: columnName, id: id ? id : null, storeId: hasStoreId === false ? hasStoreId : true, isForCustomer: isForCustomer, includeTable: includeTable, langCode: langCode, langColumnName: langColumnName
          }, storeId ? storeId : null, isGuest).pipe(map((response: any) => {
            return response && response.result && response.result['alreadyExists'] ? { 'alreadyExists': true } : (response.result['err'] ? { 'errors': true } : null);
          }));
        }
        return of(null);
      }));
    };
  }

  /**
    * This method used to validate zipcode seperated by commas
    * @param pincodes zipcode datas
    * @returns 
    */
  static validatezipCodeSeperatedByCommas(pincodes) {
    const pincodeList = pincodes.split(',');
    if (pincodeList.length && pincodeList.length > 0) {
      for (const value of pincodeList) {
        const checkAlpha = value;
        if (!((checkAlpha.toString().length === value.length) && (value.length  <=15))) {
          return { zipcodeError: true };
        }
      }
      return null;
    }
  }
  /**
   * This method used to validate zipcode
   * @param pincodes zipcode data
   * @returns 
   */
  static validateZipcode(pincodes) {
    const pincodeList = pincodes.toString();
    const checkAlpha = pincodeList;
    if (!((checkAlpha.toString().length === pincodeList.length) && (pincodeList.length <=15))) {
      return { zipcodeError: true };
    }

    return null;
  }
  /**
   * this method used to validate zipcode
   * @param formControl zipcode formcontrol
   * @returns 
   */
  static zipcodeCommaValidation(formControl: UntypedFormControl): { [key: string]: boolean } | null {
    /**
     * variable to store zipcode values
     */
    const pincodes = formControl.value;
    if (pincodes === '') {
      return null;
    }
    if (pincodes !== null && pincodes !== undefined) {

      const comma = pincodes && pincodes.indexOf(',');
      if (comma === -1) {
        return CustomAsyncValidatorService.validateZipcode(pincodes);
      }
      else {
        return CustomAsyncValidatorService.validatezipCodeSeperatedByCommas(pincodes);
      }
    }

  }
  /**
 * Method used to check whether the email exists in db or not.
 * @param CommonService used to access the methods in auth service.
 * @param storeId holds the store id.
 * @returns the email existing details.
 */
  static shopappAsyncEmailValidation(CommonService: CommonService, storeId: number, CustomerId: number, columnName: string, isContactNumber?: boolean): AsyncValidatorFn {
    return (
      control: AbstractControl
    ):
      | Promise<ValidationErrors | null>
      | Observable<ValidationErrors | null> => {
      return timer(500).pipe(
        switchMap(() => {
          if (!control.pristine && control.value) {
            let value = { value: isContactNumber ? ('' + control.value).replace(/\D/g, "") : control.value.trim(), storeId: storeId, columnName: columnName };
            if (CustomerId) value['id'] = CustomerId;
            return CommonService.getUserEmail(value).pipe(
              map((users) => {
                return users && users['alreadyExists']
                  ? { alreadyExists: true }
                  : null;
              })
            );
          }
          return of(null);
        })
      );
    }
  }
  /**
   * Method used to validate whether the page link already exists.
   * @param routeArray holds the page link details.
   * @returns response
   */
  static valueExistsValidator(routeArray: string[]) {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const value = control && control.value ? control.value.toLowerCase() : null;
      if (value) {
        const exists = routeArray.find(item => item && item.toLowerCase() === value);
        if (exists) {
          return { 'valueExists': true };
        } else {
          return null;
        }
      } else {
        return null;
      }
    };
  }
}
