import { Injectable } from '@angular/core';
/**
 * Class service which is used to set,get and clear the headers for specific url request.
 */
@Injectable({
    providedIn: 'root'
})
export class HeaderService {
    /**
     * Variable which is used to define the header for url.
     */
    headers: { [url: string]: { [key: string]: string } } = {};
    /**
     * Method which is used to set the request headers
     * @param url define the url
     * @param key define the key
     * @param value define the value
     */
    public setHeaders(url: string, key: string, value: string) {
        // To check the headers have given url as property
        url = url.replace(/#/g, "%23");
        if (value != null) {
            if (this.headers && this.headers.hasOwnProperty(url)) {
                this.headers[url][key] = value;
            } else {
                this.headers[url] = { [key]: value };
            }
        }

    }
    /**
     * Method which is used to clear the request headers
     * @param url define the url
     * @param key define the key
     */
    public clearHeaders(url: string, key: string): any {
        // To check the headers have given url as property and key
        if (this.headers && this.headers.hasOwnProperty(url) && this.headers[url].hasOwnProperty(key)) {
            const val = this.headers[url][key];
            delete this.headers[url][key];
            return val;
        }
    }
    /**
     * Method which is used to get the request headers
     * @param url define the url
     */
    public getHeaders(url: string) {
        const newLocal = 'default';
        let header = this.headers[newLocal] ? this.headers[newLocal] : {};
        if (!localStorage.getItem('jwt_token') && !sessionStorage.getItem('GusetUserJWT') && header && header.hasOwnProperty('Authorization')) {
            delete header.Authorization;
        }
        // To check the headers have given url as property
        if (this.headers && this.headers.hasOwnProperty(url)) {
            header = Object.assign({}, this.headers[url], header);
        }
        return header;
    }
    /**
     * Method which is used to verify  the request headers
     * @param url define the url
     * @param key define the key
     */
    public verifyHeader(url: string, key: string) {
        // To check the headers have given url as property
        if (this.headers && this.headers.hasOwnProperty(url) && this.headers[url].hasOwnProperty(key)) {
            return true;
        } else {
            return false;
        }
    }
}
