import { Pipe, PipeTransform } from '@angular/core';
import { LoadTranslationService } from '../services/load-translation.service';

@Pipe({
  name: 'translate',
  pure: false
})
export class TranslatePipe implements PipeTransform {

  constructor(private translate: LoadTranslationService) { }

  transform(key: string): { [key: string]: string } | string {
    const translationObj = this.translate.getTranslationObj();
    const keys = key?.includes('.') ? key.split('.') : [];
    const value = keys[0] ? translationObj[keys[0]] : null;
    if (value !== undefined && value !== null) {
      return keys[1] ? value[keys[1]] ?? value : value;
    }
    return key;
  }
}
