<!-- <cen-loader *ngIf="loading" [type]="'page'" [name]="'cen-cube-loader'">
</cen-loader> -->

<div fxLayout="column">
    <div *ngIf="!isChangePassword" fxLayout="row" fxFlex="100%">
        <div fxLayout="column" fxFlex="85%" fxFlex.lt-md="70%">
            <p class="profile-heading">{{header?.title}}</p>
        </div>

    </div>
    <div *ngIf="isChangePassword" fxLayout="column">
        <div *ngIf="!token" fxLayout="column" fxFlex="85%" fxLayoutGap="-3px">
            <p class="profile-heading" data-control="changePasswordHeading">{{header?.title}}</p>
            <p class="profile-sub-heading" data-control="changePasswordSubHeading">{{header?.description}}</p>
        </div>
        <div style="color: aliceblue;">
            <div *ngIf="isVerifiedToken ||!token;else elseBlock" style="color: #f0f8ff;">
                <h2 class="heading-align" *ngIf="token">{{heading}}</h2>
                <form [formGroup]="changePasswordForm" style="margin-bottom: 10px" fxLayout="column"
                    fxLayout.lt-sm="column">
                    <div fxLayout="row">
                        <mat-form-field *ngIf="!token" fxFlex="100%" fxFlexFill appearance="outline"
                            class="field-width">
                            <mat-label data-control="currentPasswordLabel" class="field-heading">Current password
                            </mat-label>
                            <input matInput [type]="showPassword?.showCurrentPassword ? 'text' : 'password'"
                                formControlName="currentPassword" data-control="currentPassword" />
                            <mat-icon matSuffix class="icon" data-control="showCurrentPasswordIcon" 
                                (click)="changeInputType('showCurrentPassword')">
                                {{showPassword?.showCurrentPassword ? 'visibility' :
                                'visibility_off'}} </mat-icon>
                            <mat-error data-control="currentPasswordRequired"
                                *ngIf="changePasswordForm?.get('currentPassword')?.hasError('required')">Password is
                                required
                            </mat-error>
                            <mat-error data-control="validPasswordError"
                                *ngIf="changePasswordForm?.get('currentPassword')?.hasError('pattern')&&changePasswordForm?.get('currentPassword')?.touched">
                                Password should be a valid password
                            </mat-error>
                            <mat-error data-control="passwordMismatch"
                                *ngIf="changePasswordForm?.get('currentPassword')?.hasError('passwordNotMatched') && (changePasswordForm.touched || changePasswordForm.dirty) ">
                                Given password does not match your current password
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutGap="3%">
                        <div fxFlex="50%"
                            [ngStyle.lt-md]="{'margin-bottom':changePasswordForm?.get('password')?.hasError('pattern')? '20px' : '0px'}">
                            <mat-form-field fxFlexFill appearance="outline" class="field-width">
                                <mat-label data-control="newPasswordLabel" class="field-heading">New password
                                </mat-label>
                                <input matInput [type]="showPassword?.showNewPassword ? 'text' : 'password'"
                                    formControlName="password" data-control="newPassword"  />
                                <mat-icon matSuffix class="icon" (click)="changeInputType('showNewPassword')" data-control="showNewPasswordIcon">
                                    {{showPassword?.showNewPassword ? 'visibility' :
                                    'visibility_off'}} </mat-icon>
                                <mat-error data-control="newPasswordRequired"
                                    *ngIf="changePasswordForm?.get('password')?.hasError('required')">New Password is
                                    required
                                </mat-error>
                                <mat-error data-control="validNewPasswordError"
                                    *ngIf="changePasswordForm?.get('password')?.hasError('pattern')&&changePasswordForm?.get('password')?.touched">
                                    Password is invalid
                                </mat-error>
                                <mat-error data-control="newPasswordSameAsOld"
                                    *ngIf="changePasswordForm?.get('password')?.hasError('oldNewPasswordMatch')&&changePasswordForm?.get('password')?.touched">
                                    New Password should not be your old password
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <mat-form-field fxFlex="50%" fxFlexFill appearance="outline" class="field-width">
                            <mat-label data-control="confirmPasswordLabel" class="field-heading">Confirm Password
                            </mat-label>
                            <input matInput [type]="showPassword?.showConfirmPassword ? 'text' : 'password'"
                                data-control="confirmPassword" formControlName="confirmPassword" />
                            <mat-icon matSuffix class="icon" (click)="changeInputType('showConfirmPassword')" data-control="showConfirmPasswordIcon">
                                {{showPassword?.showConfirmPassword ? 'visibility' :
                                'visibility_off'}} </mat-icon>
                            <mat-error data-control="confirmPasswordRequired"
                                *ngIf="changePasswordForm?.get('confirmPassword')?.hasError('required')">Confirm
                                Password
                                is
                                required
                            </mat-error>
                            <mat-error data-control="confirmPasswordMisMatched"
                                *ngIf="changePasswordForm?.get('confirmPassword')?.hasError('passwordMismatch')">
                                Confirm password mismatch
                            </mat-error>
                            <mat-error data-control="confirmPasswordValid"
                                *ngIf="changePasswordForm?.get('confirmPassword')?.hasError('pattern')&&changePasswordForm?.get('confirmPassword')?.touched">
                                Password is invalid
                            </mat-error>
                        </mat-form-field>
                    </div>
                </form>
                <div fxLayoutAlign="center end" fxLayoutGap="10px">
                    <button mat-button class="button-align" data-control="changePassword"
                        (click)="changePassword()">{{buttonName}}</button>
                </div>
            </div>
            <ng-template #elseBlock>
                <mat-card class="link-expired-card" [class.mat-elevation-z8]="true" fxLayoutAlign="center center">
                    <div fxLayout="column" fxLayoutAlign="center center">
                        <div>
                            <img src="../assets/sad1.png" alt="sadimage" />
                        </div>
                        <div style="text-align: center;color: red;">
                            <h3>Oops! Your link has been expired</h3>
                        </div>
                    </div>
                </mat-card>
            </ng-template>
        </div>
    </div>
</div>
<br />