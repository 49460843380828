import { Injectable } from '@angular/core';
import { API } from 'libs/common/src/lib/constants/api-routes';
import { HttpRoutingService } from 'libs/common/src/lib/services/httpRouting.service';

@Injectable({
  providedIn: 'root'
})
export class SettingsService {
  /**
   * @param commonService To access service from httprouting service
   */
  constructor(private httpService: HttpRoutingService) { }
  /**
  * Method used to get details to show settings details.
  * @returns http response.
  */
  getSettings() {
    return this.httpService.getMethod(API.AFFILIATE_GET_SETTINGS);
  } 
  /**
  * Method used to get details to update settings details.
  * @param myid hold id.
  * @param data holds data.
  * @returns http response.
  */
  updateSettings(myid, data) {
    const url = this.httpService.replaceUrlParam(API.AFFILIATE_UPDATE_SETTINGS, { id: myid });
    return this.httpService.putMethod(url, data)
  }
}
