<div id="onlineVisitorsNotifyId" [ngClass]="onlineVisitorsNotify" *ngIf="isEnabled && visitorsCount && visitorsCount > 0">
    <mat-icon *ngIf="notificationData && notificationData.isCloseNotification"
        (click)="closeNotification($event)" [ngClass]="closeIconClass"> close</mat-icon>
    <div class="notifyCard">
        <div class="left-div">
            <mat-icon class="groupIcon">group</mat-icon>
        </div>
        <div class="right-div">
            <p class="notifyMsg" [dynamicFontResizer]="languageCode" [innerHtml]="displayNotificationMessage"></p>
        </div>
    </div>
</div>