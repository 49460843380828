
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { DialogService, LoadTranslationService } from '@phase-ii/common';
import { CommonService } from '@phase-ii/shared';
import { AuthService } from '@phase-ii/auth';
import { HeaderService } from 'libs/auth/src/lib/services/header.service';
import { SharedConstant } from 'libs/common/src/lib/constants/shared-constant';
import { MenuService } from 'libs/common/src/lib/services/menu.service';
import * as _ from 'lodash';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
/**
* Guard which is used to set the headers and get the menu details.
*/
@Injectable({
  providedIn: 'root'
})
/**
* Guard which is used to set the headers and get the menu details.
*/
export class AuthGuard extends SharedConstant implements CanActivate {

  /**
   * Component constructor to inject required services.
   * @param authService if authenticated and token check.
   * @param headerService to set the token in request header.
   * @param router used to navigate to signin.
   * @param menuService used to get the menu details.
   */

  constructor(private authService: AuthService, private menuService: MenuService, private headerService: HeaderService,
    private router: Router, private dialogService: DialogService,
    private commonService: CommonService, private translateService: LoadTranslationService) {
    super();

  }
  /**
   * Method which is used for set the header and get the menu details.
   * @param data Parameter which is used to hold the data to encrypt.
   */
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    if (this.authService.isAuthenticated()) {
      const token = this.authService.getToken();
      if (token) {
        if (!this.headerService.verifyHeader('default', 'Authorization')) {
          this.headerService.setHeaders('default', 'Authorization', token);
        }
        try {
          return this.menuService.getMenuDetails().pipe(map((res: any) => {
            if (res) {
              this.commonService.menuList = _.cloneDeep(res);
              this.menuService.menuList = res;
              // this.menuService.menuList.menus = this.menuService.menuList.menus.concat(this.menu);
              this.menuService.menuList.menus = this.menuService.menuList.menus.concat(this.topMenu);
              this.menuService.menuList.menus.splice(0, 0, this.dashboardMenu);
              if (res.limitAccess) {
                this.menuService.getLimitsAdmin().subscribe((limits: any) => {
                  if (limits && limits['limits']) {
                    this.setLimit(limits['limits']);
                  }

                }, (eff) => {
                  this.dialogService.openDialog({
                    header: this.dialogHeader.failure,
                    message: this.dialogMessages && this.dialogMessages.limitsFailed,
                    actionType: this.dialogType.failure,
                    button: { right: this.buttonText.ok },
                  });
                })
              }
            }
            // this.authService.user.subscribe((user: any) => {
            //   if (user?.['storeId'] !== null && user?.['storeId'] !== undefined) {
            if (localStorage.getItem('language')) {
              localStorage.removeItem('language');
              this.translateService.changeTranslationObj();
            }
            return true;
          }), catchError((err) => {
            // throwError(err);
            this.dialogService.openDialog({
              header: this.dialogHeader.failure,
              message: this.dialogMessages && this.dialogMessages.fetchMenuFailed,
              actionType: this.dialogType.failure,
              button: { right: this.buttonText.ok },
            });
            return of(false);
          }));
        } catch (err) {
          // console.log(err, "errrrr");
          return false;
        }

      }
    } else {
      this.router.navigate(['/signin']);
      return false;
    }

  }
  /**
 * Method which is used to set the limit to on behavier subject
 */
  setLimit(limit): void {
    this.commonService.featureLimit.next(limit);
  }
}
