import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { Observable } from 'rxjs';
/**
 * LogoutGuardService used for logout quard activity.
 */
@Injectable({
  providedIn: 'root'
})
/**
 * LogoutGuardService used for logout quard activity.
 */
export class LogoutGuardService {
  /**
    * Component constructor to inject the required services.
    * @param authService To get the auth service.
    */
  constructor(private authService: AuthService) { }
  /**
   * Method which is used to route for login.
   * @param route To get the current url.
   * @param currentState To get route params.
   */
  canActivate(route: ActivatedRouteSnapshot, currentState: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    // To find the current user authentication.
    if (this.authService.isAuthenticated()) {
      return this.authService.logout();
    } else {
      return true;
    }
  }
}
