/**
 * Component which is used for genrating buy X get Y offer report.
 */
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription, of } from 'rxjs';
import { ColumnsRef, CommonAdminShardConstants, ListProperties } from '../../constants/shared-constant';
import { ReportsService } from '../../services/reports.service';
import { DatePipe } from '@angular/common';
// import { DialogService } from '@phase-ii/common';
import { MatDialog } from '@angular/material/dialog';
import { filter, mergeMap } from 'rxjs/operators';
import * as _ from 'lodash';
import { ExportObject } from '../../models/export-details';
import { ExportComponent } from '../export/export.component';
import { CurrentUserData, HeadingObject, dateObject, discountObject, getDiscountReportObject, getStoreObject, listDataSourceObject, paginatorObject } from '../../models/common-admin-model';
import { AuthService } from 'libs/auth/src/lib/services/auth.service';
import { DialogService } from 'libs/common/src/lib/services/dialog.service';
import { PaginationService } from 'libs/shared/src/lib/services/pagination.service';
import { CommonService } from 'libs/shared/src/lib/services/common.service';
import { ButtonInfo } from '@phase-ii/shared';
import * as moment from 'moment';
// import { AuthService } from '@phase-ii/auth';
// import { ButtonInfo, CommonService, PaginationService } from '@phase-ii/shared';

@Component({
  selector: 'phase-ii-buyx-gety-report',
  templateUrl: './buyx-gety-report.component.html',
  styleUrls: ['./buyx-gety-report.component.scss'],
})

export class BuyxGetyReportComponent implements OnInit, OnDestroy {
  /**
   * Variable which is used to subscribe and unsubscribe.
   * @type {Subscritpion}
   */
  subscriptionObj: Subscription = new Subscription();

  /**
   * Variable which is used to store the to date of the report.
   * @type {Date}
   */
  toDate: Date;

  /**
   * Variable which is used to store the from date of the report.
   * @type {Date}
   */
  fromDate: Date;

  /**
   * Variable which is used to define the pagesize.
   * @type {number}
   */
  pageSize: number = new CommonAdminShardConstants().paginator.defaultPageSize;

  /**
   * Variable which is used to store the text to be searched.
   * @type {String}
   */
  searchText!: String;

  /**
   * Variable which is used to store the sort object, which has the field and direction to be sorted.
   * @type {boolean}
   */
  sortData: boolean;

  /**
   * Variable which is used to store the discount response.
   * @type {Object}
   */
  totalOffer: {
    totalOfferAmount: number
  };

  /**
   * Variable which is used to store paginator data for export.
   */
  paginatorValue: {
    offset: number,
    limit: number,
    currentoffsetValue: number,
    currentlimitValue: number,
    paginatorOffset: number,
    paginatorLimit: number
  } = {
      offset: null,
      limit: null,
      currentoffsetValue: null,
      currentlimitValue: null,
      paginatorOffset: null,
      paginatorLimit: null
    };

  /**
   * Object which is used to store offer report page details.
   */
  pageDetails: {
    heading: HeadingObject,
    buttonInfo: ButtonInfo[],
    listProperty: ListProperties,
    columnRef: ColumnsRef[],
    isLoader: boolean,
    listDataSource: listDataSourceObject[],
    showFilterAndSort: boolean,
    checker: boolean,
    errorHandler: boolean,
    currencySymbol: string
    paginator: paginatorObject,
    storeId: number,
    roleId: number,
    offer: boolean,
    storeIdValue: any,
    currentRole: String,
    locationId: any
  } = {
      heading: null,
      buttonInfo: null,
      listProperty: null,
      columnRef: null,
      isLoader: null,
      listDataSource: null,
      showFilterAndSort: false,
      checker: null,
      errorHandler: false,
      currencySymbol: null,
      paginator: null,
      storeId: null,
      roleId: null,
      offer: true,
      storeIdValue: null,
      currentRole: null,
      locationId: null
    };

  /**
   * Variable which is used to store current store list data.
   */
  currentStoreList: [{
    name: string,
    id: any
  }]

  /**
   * Variable which is used to bind store list data.
   */
  storeList: [{
    name: string,
    id: any
  }]

  /**
   * Variable which is used to store total offer amount.
   * @type {number}
   */
  totalAmount: number = 0;

  /**
   * Variable which is used to store offer response.
   * @type {object}
   */
  offerDetails: discountObject;

  /**
   * Variable which is used to define whether the data is exported.
   * @type {boolean}
   */
  isExport: boolean = false;

  /**
   * Variable which is used to store current user details.
   * @type {object}
   */
  currentUser: CurrentUserData;

  /**
   * Variable which is used to define whether the data is retrieved or not.
   * @type {boolean}
   */
  isRetrieve: boolean = false;

  /**
   * CommonConstant used to acccess the common constant values. 
   * @type {object}
   */
  commonConstant = new CommonAdminShardConstants();

  /**
   * Variable which is used to store the created date of the store.
   * @type {Date}
   */
  createdDate!: Date;

  /**
   * Component constructor which is used to inject the required services.
   * @param reportService  To access methods inside ReportService.
   * @param authService  To access methods inside AuthService.
   * @param paginatorService  To access methods inside PaginationService.
   * @param displayDialog  To access methods inside MatDialog.
   * @param commonService  To access methods inside CommonService.
   * @param dialogService  To access methods inside DialogService.
   * @param datePipe  To access methods inside DatePipe.
   */
  constructor(private reportService: ReportsService,
    private authService: AuthService,
    private paginatorService: PaginationService,
    private displayDialog: MatDialog,
    private commonService: CommonService,
    private dialogService: DialogService,
    private datePipe: DatePipe) { }

  /**
   * Angular life cycle hook that initiates the component
   */
  ngOnInit(): void {
    this.pageDetails.locationId = this.commonConstant && this.commonConstant.reportData && this.commonConstant.reportData.allLocation;
    this.subscriptionObj.add(this.authService.user.pipe(filter((res: CurrentUserData) => {
      if (res) {
        this.currentUser = res;
        this.pageDetails.storeIdValue = this.currentUser && this.currentUser.storeId ? this.currentUser.storeId : null;
        this.pageDetails.currencySymbol = this.currentUser && this.currentUser.store && this.currentUser.store.currencyData && this.currentUser.store.currencyData.currencySymbol ? this.currentUser.store.currencyData.currencySymbol : null;
        this.pageDetails.currentRole = this.currentUser && this.currentUser.roleCode ? this.currentUser.roleCode : this.currentUser.role.code ? this.currentUser.role.code : null;
        this.pageDetails.roleId = this.currentUser && this.currentUser.roleId ? this.currentUser.roleId : null;
      }
      return true;
    }), mergeMap(() => {
      if (this.pageDetails.currentRole && this.pageDetails.currentRole === this.commonConstant?.reportData?.superadmin) {
        return this.commonService.getAllStoresName();
      } else {
        this.pageDetails.storeId = this.currentUser && this.currentUser.storeId ? this.currentUser.storeId : null;
        return of({});
      }
    }), mergeMap((store: getStoreObject) => {
      if (store && store['stores']) {
        this.currentStoreList.push({ name: this.commonConstant?.reportData?.allLocation, id: this.commonConstant?.reportData?.allLocation });
        store['stores'].forEach((element) => {
          if (element) {
            this.currentStoreList.push({
              id: element.id,
              name: element.name
            });
          }
        });
        this.pageDetails.storeIdValue = this.currentStoreList[0]?.id;
        this.storeList = this.currentStoreList;
      }
      return this.reportService.getStoreData(this.pageDetails.storeId);;
    }), mergeMap((data: any) => {
      if (data) {
        this.createdDate = data.settings && data.settings.createdAt;
      }
      return this.authService.dashboardFilter;
    })).subscribe({
      next: (value: dateObject) => {
        if (value) {
          if (value.filterText == 'Lifetime') {
            this.fromDate = this.createdDate;
          }
          else {
            this.fromDate = value.fromDate ? value.fromDate : new Date();
            this.fromDate.setHours(0, 0, 0, 0);
          }
        }
        else {
          this.fromDate = new Date();
          this.fromDate.setHours(0, 0, 0, 0);
        }
        this.toDate = new Date();
        this.toDate.setHours(23, 59, 59, 999);
        this.pageDetails.storeIdValue = this.currentUser && this.currentUser.storeId ? this.currentUser.storeId : null;
        this.getDiscountReport(0, 2 * this.pageSize, true);
      },
      error: (err) => {
        if (err) {
          this.pageDetails.isLoader = false;
          this.dialogService.dialogMethod(this.commonConstant &&
            this.commonConstant.errorMessage
            && this.commonConstant.errorMessage.errorMessage,
            this.commonConstant.dialogType?.failure);
        }
      }
    }));
  }

  /**
   * Method which is used to get the report details for table.
   * @param offset To get the offset/starting value.
   * @param limit defines the limit/count of data to be get.
   * @param isNew defines the page is newly loaded or a specific page
   */
  getDiscountReport(offset: number, limit: number, isNew: boolean): void {
    this.paginatorValue.paginatorOffset = offset;
    this.paginatorValue.paginatorLimit = this.pageSize;
    const start_date: Date = this.fromDate ? _.cloneDeep(this.fromDate) : null;
    const to_date: Date = this.toDate ? this.toDate : null;
    let data = {
      offset: offset ? offset : 0,
      limit: limit ? limit : this.pageSize,
      location: this.pageDetails.locationId ? this.pageDetails.locationId : null,
      store: this.pageDetails.storeIdValue ? this.pageDetails.storeIdValue : null,
      from_date: moment.utc(this.datePipe.transform(start_date, 'yyyy-MM-dd')).utcOffset('+0:00').format('YYYY-MM-DD 00:00:00.000 +00:00'),
      to_date: moment.utc(this.datePipe.transform(to_date, 'yyyy-MM-dd')).utcOffset('+0:00').format('YYYY-MM-DD 23:59:59.000 +00:00'),
      filterText: this.searchText ? this.searchText.trim() : '',
      sort: this.sortData ? this.sortData : null,
      buyXgetY: true
    };
    if (this.pageDetails.storeIdValue === 'All') {
      this.subscriptionObj.add(this.reportService.getAllOfferReport(data).subscribe({
        next: (res: getDiscountReportObject) => {
          if (res && res['Discount']) {
            this.offerDetails = res['Discount'];
            this.offerDetails['buyXgetYDiscount']['totalOfferAmount'] = 0;
            this.offerDetails['buyXgetYDiscount'].forEach((element) => {
              if (element) {
                this.offerDetails['buyXgetYDiscount']['totalOfferAmount'] += element.totalAmount ? Number(element.totalAmount) : 0;
              }
            });
            this.tabDataRetrieve(isNew);
            this.pageDetails.heading = {
              title: this.commonConstant?.offerReport?.buyXgetY,
              description: this.commonConstant?.offerReport?.buyXgetYDescription,
              // isHeaderIcon: true,
              // iconName: 'link',
              // iconToolTip: 'Help',
              // iconLink: 'https://help.getzenbasket.com/offer_report.html'
            };
            this.pageDetails.buttonInfo =
              [
                {
                  name: 'Back', method: 'back()', class: 'secondary-button'
                },
                {
                  name: 'Export Report', class: 'primary-button', method: 'exportexcel()'
                }
              ];
            this.pageDetails.listProperty = {
              globalSearch: false,
              columns: 2,
              dataOnly: true,
              suffixText: true,
              checkBox: false,
              isImageDisplay: false
            };
            this.pageDetails.checker = false;
            this.pageDetails.isLoader = false;
          }
        },
        error: (err) => {
          if (err) {
            this.pageDetails.isLoader = false;
            this.dialogService.dialogMethod(this.commonConstant &&
              this.commonConstant.errorMessage
              && this.commonConstant.errorMessage.errorMessage,
              this.commonConstant?.dialogType?.failure);
          }
        }
      }));
    } else if (this.pageDetails.storeIdValue !== null) {
      this.subscriptionObj.add(this.reportService.getOfferReport(this.pageDetails.storeIdValue, data).subscribe({
        next: (res: getDiscountReportObject) => {
          if (res && res['Discount']) {
            this.offerDetails = res['Discount'];
            this.offerDetails['buyXgetYDiscount']['totalOfferAmount'] = 0;
            this.offerDetails['buyXgetYDiscount'].forEach((element) => {
              if (element) {
                this.offerDetails['buyXgetYDiscount']['totalOfferAmount'] += element.totalAmount ? Number(element.totalAmount) : 0;
              }
            });
            this.paginatorValue.currentoffsetValue = offset;
            this.paginatorValue.currentlimitValue = limit;
            this.pageDetails.listDataSource = this.offerDetails && this.offerDetails['buyXgetYDiscount'] ? this.offerDetails['buyXgetYDiscount'] : [];
            this.tabDataRetrieve(isNew);
            this.pageDetails.heading = {
              title: this.commonConstant?.offerReport?.buyXgetY,
              description: this.commonConstant?.offerReport?.buyXgetYDescription,
              // isHeaderIcon: true,
              // iconName: 'link',
              // iconToolTip: 'Help',
              // iconLink: 'https://help.getzenbasket.com/offer_report.html'
            };
            this.pageDetails.buttonInfo =
              [
                {
                  name: 'Back', method: 'back()', class: 'secondary-button'
                },
                {
                  name: 'Export Report', class: 'primary-button', method: 'exportexcel()'
                }
              ];
            this.pageDetails.listProperty = {
              globalSearch: false,
              columns: 2,
              dataOnly: true,
              suffixText: true,
              checkBox: false,
              isImageDisplay: false
            };
            this.pageDetails.checker = false;
            this.pageDetails.isLoader = false;
          }
        },
        error: (err) => {
          if (err) {
            this.pageDetails.isLoader = false;
            this.dialogService.dialogMethod(this.commonConstant &&
              this.commonConstant.errorMessage
              && this.commonConstant.errorMessage.errorMessage,
              this.commonConstant?.dialogType?.failure);
          }
        }
      }));
    } else {
      this.dialogService.dialogMethod(this.commonConstant &&
        this.commonConstant.errorMessage
        && this.commonConstant.errorMessage.errorMessage,
        this.commonConstant?.dialogType?.failure);
    }
  }

  /**
   * Method which is used to retrieve the data related to selected tab.
   * @param value To get the value for paginator.
   */
  tabDataRetrieve(value: boolean): void {
    if (!this.isExport) {
      this.pageDetails.listDataSource = this.offerDetails && this.offerDetails['buyXgetYDiscount'] ? this.offerDetails['buyXgetYDiscount'] : [];
      this.pageDetails.paginator = {
        new: value,
        rows: this.pageDetails.listDataSource,
        count: this.pageDetails && this.pageDetails.listDataSource && this.pageDetails.listDataSource.length,
      };
      this.pageDetails.listDataSource = this.offerDetails && this.offerDetails['buyXgetYDiscount'].slice(this.paginatorValue.currentoffsetValue, this.paginatorValue.currentoffsetValue + this.paginatorValue.currentlimitValue);
      this.pageDetails.paginator.rows = this.pageDetails.listDataSource;
      this.pageDetails.columnRef = [
        { column: 'title', type: 'Text', suffixText: 'Title' },
        { column: 'count', type: 'Number', suffixText: 'No. of orders' },
        { column: 'totalAmount', type: 'Amount', suffixText: 'Total Amount', currency: this.pageDetails.currencySymbol }
      ];
      this.totalOffer = {
        totalOfferAmount: this.offerDetails && this.offerDetails['buyXgetYDiscount'] && this.offerDetails['buyXgetYDiscount'].totalOfferAmount
      };
      this.isRetrieve = true;
      this.paginatorService.listPaginatorData.next(this.pageDetails.paginator);
    }
    else {
      this.pageDetails.paginator = {
        new: value,
        rows: this.pageDetails.listDataSource,
        count: this.pageDetails && this.pageDetails.listDataSource && this.pageDetails.listDataSource.length
      };
      this.isRetrieve = true;
      this.paginatorService.listPaginatorData.next(this.pageDetails.paginator);
      this.pageDetails.listDataSource = this.offerDetails && this.offerDetails['buyXgetYDiscount'] ? this.offerDetails['buyXgetYDiscount'].slice(this.paginatorValue.currentoffsetValue, this.paginatorValue.currentoffsetValue + this.paginatorValue.currentlimitValue) : [];
    }
  }

  /**
   * Method which is used to get changed page data.
   * @param event To get offset and limit.
   */
  onDataChange(event: { offset: number, limit: number }): void {
    if (event) {
      this.getDiscountReport(event.offset, event.limit, false);
    }
  }

  /**
   * Method which is used to get changed page data.
   * @param event To get offset and limit.
   */
  changedData(event: { offset: number, limit: number }): void {
    if (event) {
      this.paginatorValue.limit = event.limit ? event.limit : null;
      this.paginatorValue.offset = event.offset ? event.offset : 0;
      this.getDiscountReport(event.offset, event.limit, false);
    }

  }

  /**
   * Method which is used to get paginator data.
   * @param event To get offset and limit.
   */
  paginatorData(event: { pageSize: number, pageIndex: number }): void {
    if (event) {
      this.paginatorValue.paginatorLimit = event.pageSize;
      this.paginatorValue.paginatorOffset = event.pageIndex * event.pageSize;
      this.paginatorValue.limit = event.pageSize;
      this.paginatorValue.offset = event.pageIndex * event.pageSize;
      this.pageSize = event.pageSize;
    }
  }

  /**
   * Method which gets called during location, searchText, from date, to date and sort change events in common report.
   * @param event To get the event value.
   */
  filterAction(event: { location_id: number, store_id: number, from_date: Date, to_date: Date, limit: number, offset: number, searchText: string, sort: boolean, exportexcel: boolean }): void {
    if (event) {
      this.pageDetails.locationId = event.location_id ? event.location_id : null;
      this.pageDetails.storeIdValue = event.store_id ? event.store_id : null;
      this.fromDate = event.from_date ? event.from_date : this.fromDate;
      this.toDate = event.to_date ? event.to_date : this.toDate;
      this.searchText = event.searchText ? event.searchText : '';
      this.paginatorValue.limit = event.limit ? event.limit : null;
      this.paginatorValue.offset = event.offset ? event.offset : 0;
      this.sortData = event.sort;
      if (event.exportexcel) {
        this.exportOffer();
      } else {
        this.getDiscountReport(event.offset, event.limit, true);
      }
    }
  }

  /**
   * Method which gets called during export is true in common report.
   */
  exportOffer(): void {
    let filterConstrain = {
      from_date: this.fromDate ? this.datePipe.transform(this.fromDate, 'yyyy-MM-dd') : null,
      to_date: this.toDate ? this.datePipe.transform(this.toDate, 'yyyy-MM-dd') : null,
      locationId: this.pageDetails.locationId ? this.pageDetails.locationId : null
    };
    if (this.offerDetails && this.offerDetails['buyXgetYDiscount'] && this.offerDetails['buyXgetYDiscount'].length > 0) {
      const data: ExportObject = {
        title: 'Export ',
        exportApiUrl: 'stores/' + this.pageDetails.storeIdValue + '/export/report/buyxgetyoffer',
        limit: this.paginatorValue.paginatorLimit ? this.paginatorValue.paginatorLimit : this.paginatorValue.limit ? this.paginatorValue.limit : this.paginatorValue.currentlimitValue ? this.paginatorValue.currentlimitValue : this.pageSize,
        offset: this.paginatorValue.paginatorOffset ? this.paginatorValue.paginatorOffset : this.paginatorValue.offset ? this.paginatorValue.offset : this.paginatorValue.currentoffsetValue ? this.paginatorValue.currentoffsetValue : 0,
        type: false,
        fileName: 'buy x get y offer',
        expansion: true,
        filterData: filterConstrain ? filterConstrain : null,
        selectedData: [],
        buyXgetY: true
      };
      const dialogRefData = this.displayDialog.open(ExportComponent, { disableClose: true });
      if (dialogRefData && dialogRefData.componentInstance) {
        dialogRefData.componentInstance.contentData = data;
      }
    } else {
      this.dialogService.dialogMethod(this.commonConstant &&
        this.commonConstant.dialogMessages
        && this.commonConstant.dialogMessages.noDataExport,
        this.commonConstant?.dialogType?.alert);
    }
  }

  /**
   * Angular life cycle hook which is used to unsubscribe the subscriptions.
   */
  ngOnDestroy(): void {
    if (this.subscriptionObj) {
      this.subscriptionObj.unsubscribe();
    }
  }

}
