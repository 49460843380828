import { SelectionModel } from '@angular/cdk/collections';
import { Component, OnInit, Input, Output, EventEmitter, AfterViewInit, ChangeDetectorRef, OnDestroy, OnChanges, SimpleChanges, HostListener, ElementRef, ViewChild } from '@angular/core';
import { FormControl, FormGroup, UntypedFormControl, Validators } from '@angular/forms';
// import { FormControl } from '@angular/forms';
import { DialogService } from 'libs/common/src/lib/services/dialog.service';
import { Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { ListProperties, MoreActions } from '../../constants/shared-constant';
import { CommonService } from '../../services/common.service';
import { PaginationService } from '../../services/pagination.service';
import { cloneDeep } from 'lodash';
import { MatMenu } from '@angular/material/menu';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { FILE_EXTENSION } from '../../constants/compressed-image-display-constant';
import { LoadTranslationService } from '@phase-ii/common';

export const MY_FORMATS = {
  parse: {
    dateInput: 'LL',
  },
  display: {
    dateInput: 'DD-MM-YYYY',
    monthYearLabel: 'YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'YYYY',
  },
};
/**
 * This is common card component version 2 used to display common card in various component.
 * It is used to render data the dynamically in card view
 */
@Component({
  selector: 'phase-ii-common-card-v-ii',
  templateUrl: './common-card-vii.component.html',
  styleUrls: ['./common-card-vii.component.scss'],
  providers: [
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
})
export class CommonCardViiComponent implements OnInit, AfterViewInit, OnChanges, OnDestroy {

  /**
   * List Data variable which is used to store list data for local pagination
   * @type { any }
   */
  @Input() listData: any = [];
  /**
   * Input variable which is used to hold the Properties of table and its related actions
   * @type { ListProperties }
   */
  @Input() listProperties: ListProperties;
  /**
   * Input variable which is used to store the details about each column in tables and its related type and conditions and designs
   * @type { any }
   */
  @Input() columnsRef: any;
  /**
   * Input variable which is used to store the Actions icon of each row in that card which is common to all rows in that card
   * @type { any[] }
   */
  @Input() actionsIcon: any[];
  /**
  * Input variable which is used to store the Actions icon of the right top sections in the card(it may be a icons or buttons)
  * @type { any }
  */
  @Input() actions: any
  /**
   * Input variable which is used to get the paginatoe version from the user
   * @type { number }
   */
  @Input() paginatorVersion: number = 1;
  /**
   * Input variable which to used to get whether New Filter apply or not.
   * @type { any }
   */
  @Input() newFilterApply: any = false;
  /**
   * Input variable which is used to decide whether the loader is displaying or not in some cases
   * @type { b }
   */
  @Input() displayLoader: boolean = false;
  /**
   * Input variable which is used to display the error message in table
   * @type { any }
   */
  @Input() errorHandler: boolean = false;
  /**
   * Input variable which is used to decide whether the Paginatoin is needed or no to this page.
   * @type { any }
   */
  @Input() needPaginator: boolean = false;
  /**
   * Input variable which is used to indimate the filter is reset from parent or filter component
   * @type { any }
   */
  @Input() isFilterReset: boolean = false;
  /**
   * Input variable which is used to store the data for right side top filter.
   * @type { any }
   */
  @Input() rightTopFilter: any;
  /**
   * Input variable which is used to decide the data alignment in data card(if it is true all the data are algined in left side or its aligned canter)
   * @type { any }
   */
  @Input() isLeftAlign: boolean = null;
  /**
   * Input variable which is used to get the selected array data from the parent component
   * @type { any }
   */
  @Input() selectionArray: any[] = [];
  /**
   * Input variable which is used to intimate that the last toggle oparation was failed, So that the toggle is reset to the old state(On / Off)
   * @type { any }
   */
  @Input() toggleFailed: boolean = false;
  /**
   * Input variable which is used to decide whether the page is readonly to the user
   * @type { any }
   */
  @Input() clickable: boolean = false;
  /**
   * Input variable which is used to store the data which is needed for right side top sort
   * @type { any }
   */
  @Input() rightTopSort: any;
  /**
   * Input variable which is used to get the single selected data for radio select
   * @type { any }
   */
  @Input() singleItemSelect: any;
  /**
   * Input variable which is used to clear the selected array in some cases 
   * @type { boolean }
   */
  @Input() clearSelectedData: boolean = false;
  /**
   * Input Variable used to display date filter
   * @type { any }
   */
  @Input() isTopRightDateFilter: any;

  // @Input() searchText : string = '';
  /**
   * Variable which us used to store the current display data for the page
   * @type { any }
   */
  displayData: any = null;
  // subject: Subject<any> = new Subject();
  // searchField = new FormControl('');
  // @Input() listProperties: any = {
  //   globalSearch: true,
  //   rowElementClicked: false,
  //   checkBox: true,
  //   inputAppearance: 'outline',
  //   serverSidePagination: true,
  //   displayTopRightButtons: false,
  //   localSearchFields: ['name', 'createdAt'],
  //   cardMinHeight: '250px'
  //    badgeCondition: { field: isView, value:true}
  // };
  // @Input() actionsIcon: any[] = [
  //   { iconName: 'edit', method: 'onRowSelect', toolTip: 'Edit', iconColor: '#16cf1a' },
  //   { iconName: 'clear', method: 'listAction', toolTip: 'Delete', iconColor: 'red' }
  // ];
  // @Input() displayData: any = [
  //   { name: "1 KG Atta", qty: 100, price: 500, createdAt: "2022-02-17T17:48:22.839", stockStatus: "Available", featuredImage: "https://www.w3schools.com/howto/img_avatar.png", htmlContent: '<a href="google.com">hello</a>', ratings: 4, availability: true },
  //   { name: "5 KG Atta", qty: 100, price: 500, createdAt: "2022-02-17T17:48:22.839", stockStatus: "Available", featuredImage: "https://www.w3schools.com/howto/img_avatar.png", htmlContent: '<a href="google.com">hello</a>', ratings: 5, availability: true },
  //   { name: "10 KG Atta", qty: 100, price: 500, createdAt: "2022-02-17T17:48:22.839", stockStatus: "Available", featuredImage: "https://www.w3schools.com/howto/img_avatar.png", htmlContent: '<a href="google.com">hello</a>', ratings: 2, availability: true }
  // ];
  // @Input() columnsRef: any = [
  //   { column: 'featuredImage', suffixText: 'Product Image', type: 'Image', class: null, icon: null, style: 'circle', subText: { column: 'ratings', type: 'Ratings', suffixText: 'Product Name', class: "text", icon: null } },
  //   { column: 'name', type: 'Text', suffixText: 'Product Name', class: "redText", icon: "person", subText: { column: 'name', type: 'Text', suffixText: 'Product Name', class: "text", icon: null } },
  //   { column: 'qty', type: 'Number', suffixText: 'Sku', class: null, icon: null, isFloat: true, subText: { column: 'htmlContent', type: 'InnerHtml', suffixText: 'Created Date', class: null, icon: null } },
  //   { column: 'price', type: 'Amount', suffixText: 'Price', class: null, icon: "monetization_on", currency: '$', subText: { column: 'name', type: 'Link', suffixText: 'Product Name', class: "text", icon: null, linkText: "Invoice Bill", newTab: true } },
  //   { column: 'createdAt', type: 'Date', suffixText: 'Created Date', class: null, icon: null, format: 'dd/MM/yyyy', subText: { column: 'createdAt', type: 'Time', suffixText: 'Created Date', class: null, icon: null } },
  //   { column: 'stockStatus', type: 'Chip', suffixText: 'Stock Status', class: null, backgroundColor: 'green', icon: null, subText: { column: 'createdAt', type: 'DateTime', suffixText: 'Created Date', class: null, icon: null } },
  //   { column: 'availability', type: 'Toggle', suffixText: 'Availability', class: null, icon: "monetization_on", currency: '$', },
  // ];
  // @Input() rightTopFilter: any = {
  //   values: [
  //     { id: 1, category: 'T-Shirts' },
  //     { id: 2, category: 'Top Wears' },
  //     { id: 3, category: 'Bottom Wears' },
  //   ],
  //   label: 'Choose Category',
  //   defaultOption: 2,
  //   matValueFiled: 'id',
  //   matLabelField: 'category',
  // }
  // @Input() actions: any = [{ name: 'restore', value: 'deselectAll', toolTip: 'Restore Order', iconColor: 'blue', isConditionApplied: true, conditionType : "DELETE" }, { name: 'file_download', value: 'importFunction', toolTip: 'Import' },
  // { name: 'file_upload', value: 'exportCall', toolTip: 'Export' }];
  // @Input() rightTopSort : any = {
  //   toolTip: 'sort products',
  //   values: [
  //         { label: 'Name Asc', column:'name', order:'asc'  },
  //         { label: 'Name Desc', column:'name', order:'asc' },
  //         { label: 'SKU Asc', column:'sku', order:'asc' },
  //       ],
  // }
  /**
   * 
   *  @type { MoreActions }
   * Variable which is used to store the more actions for each row(which is displayed in options (3 dots))
   */
  @Input() moreActions: MoreActions[];
  //  = [{ displayName: 'Options', event: 'navigate', values: {}  }, { displayName: 'Modifiers', event: 'navigate', values: {} }];
  /**
   * Event Emitter which is used to emit the filtered data to the parent component
   * @type { any }
   */
  @Output() filteredData = new EventEmitter<any>();
  /**
   * Event Emitter which is used to emit the search text input value to the parent component.
   * @type { any }
   */
  @Output() searchedText = new EventEmitter<any>();
  /**
   * Event Emitter which is used to emit the paginator details to the parent component.
   * @type { any }
   */
  @Output() paginatorData = new EventEmitter<any>();
  /**
  * Event Emitter which is used to emit a single row data while clicking any actions on the row
  * @type { any }
  */
  @Output() actionClicked = new EventEmitter<any>();
  /**
  * Event Emitter which is used to emit the row data and toggle state(On / Off) to the 
  * @type { any }
  */
  @Output() toggleClicked = new EventEmitter<any>();
  /**
  * Event Emitter which is used to emit the right filter changes to the 
  * @type { any }
  */
  @Output() rightFilterChanged = new EventEmitter<any>();
  /**
  * Event Emitter which is used to emit the selected data to the parent component.
  * @type { any }
  */
  @Output() selectedData = new EventEmitter<any>();
  /**
 * Event Emitter which is used to emit the clicked row data and its icon action to the parent component.
 * @type { any }
 */
  @Output() iconClicked = new EventEmitter<any>();
  /**
 * Event Emitter which is used to emit the new current display data to the parent component during pagination changes.
 * @type { any }
 */
  @Output() valueChanged = new EventEmitter<any>();
  /**
 * Event Emitter which is used to emit the clicked row and checkbox state(On/Off) to the parent component.
 * @type { any }
 */
  @Output() checkBoxChanged = new EventEmitter<any>();
  /**
 * Event Emitter which is used to emit the sort related data to the parent component.
 * @type { any }
 */
  @Output() sortEventClicked = new EventEmitter<any>();
  /**
   * Event Emitter which is used to emit the more actions related data to the parent component.
   * @type { any }
   */
  @Output() moreActionsClicked = new EventEmitter<any>();
  /**
   * Output Event Emitter which is used to emit the Date values to the parent component
   */
  @Output() topRightDateFilterEvent = new EventEmitter<any>();
  /**
    * Output Event Emitter which is used to emit the Date values to the parent component when column clicked
    */
  @Output() columnItemClicked = new EventEmitter<any>();
  /**
   * Subscription Object which is used to store the all the subscriptions for unsubscription. 
   * @type { Subscription }
   */
  subscriptionObj: Subscription = new Subscription();
  /**
   * Variable which is used to hold the master toggle value
   * @type { boolean }
   */
  masterToggleValue: boolean = false;
  /**
   * Variable which is used to decide the fxFlex value based on columns count
   * @type { number }
   */
  fxFlex: number = 15;
  /**
   * Variable which is used to hide the search bar in some cases
   * @type { boolean }
   */
  hideSearch: boolean = false;
  /**
   * Variable which is used to decide whether the isSelectAll clciked or not
   * @type { boolean }
   */
  isSelectAll: boolean = false;
  /**
   * Variable(array) which is used to store the row data in multiselect case.
   * @type { any }
   */
  MultipleSelect: any = [];
  /**
  * Variable(array) which is used to store the selected row data in for emitting.
  * @type { any }
  */
  selectedArray: any = [];
  /**
   * Selection Model which is used in checkbox multiselect to store the multiselect
   * @type { any }
   */
  selection = new SelectionModel<any>(true, []);
  /**
   * Variable which is used to decide whether is it first time loading or not.
   * @type { boolean }
   */
  isFirst: boolean = true;
  /**
   * Boolean  variable which is used to decide whether the master toggle clicked or not(master checkbox selected or not)
   * @type { boolean }
   */
  isMasterToggle: boolean = false;
  /**
  * Boolean variable which is used to store the whether each row has different actions()
  * @type { boolean }
  */
  isRowActionIcons: boolean = false;
  /**
   * Variable which is used to store the paginator details
   * @type { any }
   */
  paginatorDetails: any;
  /**
   * variable array which is used to store the unchecked rows in an array
   * @type { any }
   */
  unCheckedArray: any[] = [];
  /**
   * Vaiable which is used to store the search text.
   * @type { string }
   */
  searchText: string = '';
  /**
   * Variabel which is used to store the selected sort details for styilng and emitting purpose
   * @type { any }
   */
  @Input() selectedSort: any;
  @Input() customMatMenu: MatMenu;
  /**
  * Form field which is used to get the date values for date filter
  */
  dateFilterForm = new FormGroup({
    startDate: new FormControl(null, Validators.required),
    endDate: new FormControl(null, Validators.required)
  });
  /**
   * Variable which is used to search right top filter search control.
   */
  rightTopFilterSearchField = new UntypedFormControl(null);
  /**
   * Array to hold the data to display the values in right top filter filtered data 
   */
  rightTopFilterFilteredData: any = [];
  /**
   * Input variable which is used to store the data for right side Language Filter.
   * @type { any }
   */
  @Input() rightTopLanguageFilter: any;
  /**
   * Event Emitter which is used to emit the Language filter changes to the parent component
   * @type { any }
   */
  @Output() rightLanguageFilterChanged: any = new EventEmitter<any>();
  /**
   * Data representing the language options for the right-top filter
   * @type { any[] }
   */
  rightTopFilterLanguageData: any = [];
  /**
   * Variable which is used to search right top language filter search control.
   */
  rightTopLanguageFilterSearchField = new UntypedFormControl(null);  /**
   * Boolean  variable which is used to decide whether the master toggle clicked or not(master checkbox disable or not)
   * @type { boolean }
   */
  isMasterToggleDisable: boolean;
  /**
   * Variable used to store the image extension
   */
  imageExtension = FILE_EXTENSION;
  /**
   * 
   * @param changeDetectorRef  - Which is used to call detectChanges() function after content changes in TS file.
   * @param paginationService  - Which is used to set data to paginator.
   * @param dialogService - Which is used to call dialog methods
   * @param commonSevice - which is used to access searchField and searchSubject from common service.
   */
  constructor(public changeDetectorRef: ChangeDetectorRef,
    private paginationService: PaginationService, private dialogService: DialogService,
    public commonSevice: CommonService,
    private translate: LoadTranslationService,
  ) {
    // this.translate.multiLoadTranslation(['common_card']);
  }
  /**
  * 1) In this onInit we subscribe listPaginatorData to get the display data fot this card.
  * 2) we call setDisplayData() to set up display data.
  * 3) It will subscripe to this.selection.changed and this.commonSevice.searchField.valueChanges functions
  */
  ngOnInit(): void {
    // let columnLength = ((this.actionsIcon && this.actionsIcon.length))?  this.columnsRef.length+1 : this.columnsRef.length;
    // this.actionsIcon = [
    //   { iconName: 'edit', method: 'onRowSelect', toolTip: 'Edit', iconColor: '#16cf1a' },
    //   { iconName: 'delete', method: 'listAction', toolTip: 'Delete', iconColor: 'red' }];
    // if(this.actionsIcon && this.actionsIcon.length){
    //   this.columnsRef.push({ column: 'action', type: 'Actions',})
    // }
    this.subscriptionObj.add(this.paginationService.listPaginatorData.subscribe((data) => {
      // console.log(data);
      // console.log(data.count == 0, data.searchText !== undefined, data.searchedText !== null, data.searchedText.length === 0)
      if (data && (data.count == 0 && data.searchedText !== undefined && data.searchedText !== null
        && data.searchedText.length === 0)) {
        this.hideSearch = true;
        this.displayData = [];
      } else {
        this.hideSearch = false;
      }
      if (data && data.isDeleted != null && data.isDeleted !== undefined && data.isDeleted) {
        this.selectedArray = [];
      }

    }));
    this.setDisplayData();
    if (this.selectionArray && this.selectionArray.length && this.isFirst && this.listProperties && this.listProperties.noPagination == true) {
      this.selectedArray.push(...this.selectionArray);
      this.selection.select(...this.selectionArray);
      if (this.displayData && this.displayData.length) {
        this.displayData.map(element => {
          element['checked'] = this.checkForSelection(element);
        });
      }
      this.isFirst = false;
    }
    // if (this.listProperties) {
    //   this.columns = window.innerWidth <= 560 ? 1 : (window.innerWidth <= 1150) && !this.listProperties.dataOnly && this.listProperties.columns <= 2 ? 1 : (window.innerWidth <= 1300) && this.listProperties.dataOnly ? 2 : this.listProperties.columns;
    //   this.rowHeight = (window.innerWidth <= 760 && this.listProperties.suffixText) ? 100 : (this.listProperties.suffixText) ? 80 : 50;
    // }
    // console.log('column Data', this.columnsRef);
    // console.log(this.fxFlex);
    this.selection.changed.subscribe((res: any) => {
      // console.log(res);
      // console.log('before', this.selection.selected);
      // const ids = this.selection.selected.map(o => o.id);
      this.selectionDataEmit();
      // this.selection.clear();
      // this.selection.select(...data);
    });
    this.fxFlex = 100 / (this.columnsRef && this.columnsRef.length);
    if (this.isLeftAlign == null) {
      this.isLeftAlign = this.columnsRef && this.columnsRef.length && this.columnsRef.length <= 2;
    }
    // console.log(this.paginatorVersion);
    // this.subject.pipe(debounceTime(400)).subscribe(res => {
    // console.log(this.searchText)
    // this.searchedText.emit(this.searchText);
    // });
    this.commonSevice.searchField.valueChanges.pipe(debounceTime(500)).subscribe(value => {
      // console.log(value);
      const filterText = value && value.trim() ? value.trim() : null;
      if ((filterText && filterText.length > 0) || value == null || value == '') {
        // console.log('setting value', value);
        // console.log(this.listProperties, 'local pagination', this.listProperties.isLocalPagination, 'server side', this.listProperties.serverSidePagination );
        // if(this.listProperties && !this.listProperties.isLocalPagination && !this.listProperties.serverSidePagination){
        // this.commonSevice.searchSubject.next(value);
        // }
        // if(this.listProperties && (this.listProperties.isLocalPagination == undefined || this.listProperties.serverSidePagination == undefined)){
        this.commonSevice.searchSubject.next(value);
        // }  
      }
    });

    if (this.clearSelectedData) {
      this.selectedArray = [];
      this.selection.clear();
    }
    // console.log(this.listProperties);
    // this.searchField.setValue(this.searchText);

    // this.commonSevice.searchSubject.subscribe((res) => {
    //   console.log(res);
    //   this.searchField.setValue(res);
    // });
    if (this.isTopRightDateFilter) {
      if (this.isTopRightDateFilter.defaultStartDate) {
        this.dateFilterForm.patchValue({ startDate: this.isTopRightDateFilter.defaultStartDate });
      }
      if (this.isTopRightDateFilter.defaultEndDate) {
        this.dateFilterForm.patchValue({ endDate: this.isTopRightDateFilter.defaultEndDate });
      }
    }
    this.setRightTopFilterData();
    if (this.rightTopFilter && this.rightTopFilter.values) {
      this.rightTopFilterFilteredData = this.rightTopFilter.values;
    }
    this.setRightTopLanguageFilterData()
    if (this.rightTopLanguageFilter && this.rightTopLanguageFilter.values) {
      this.rightTopFilterLanguageData = this.rightTopLanguageFilter.values;
    }

    // console.log(this.customMatMenu);
  }
  /**
   * Metohd which is used to emit selected data
   * It will emit through checkBoxChanged and selectedData emitters
   */
  selectionDataEmit(): void {
    const ids = this.selectedArray.map(o => o.id);
    // console.log('ids', ids);
    // const arr = this.selection.selected;

    // Removing rows duplicates from selected and reassgin that to selected array
    const arr = this.selectedArray;
    const data = arr.filter(({ id }, index) => !ids.includes(id, index + 1));
    // console.log('subscribe changes', data);
    // console.log('after changes',  this.selection.selected);
    this.selectedArray = data;
    this.checkBoxChanged.emit(data);
    this.selectedData.emit(data);
  }
  /**
   * 
   * @param changes changes in this component will pass through this
   * displayData is reset if the toggle function is failed
   */
  ngOnChanges(changes: SimpleChanges) {
    // console.log("changes called", this.customMatMenu);
    this.setDisplayData();
    if (this.toggleFailed) {
      let data = this.displayData;
      this.displayData = null;
      this.changeDetectorRef.detectChanges();
      // console.log('data', data, 'display data', this.displayData);
      this.displayData = data;
      this.toggleFailed = false;

    }
    if (this.clearSelectedData) {
      this.selectedArray = [];
      this.selection.clear();
    }
    if (changes.rightTopLanguageFilter) {
      this.setRightTopLanguageFilterData();
    }
    // this.selection.clear();
    // console.log(this.selection.hasValue());
    // this.searchField.setValue(this.searchText);
  }
  /**
   * Set data to listpaginator subject if the table has local pagination
   */
  setDisplayData(): void {
    // console.log(this.listData);
    if (this.needPaginator && this.listProperties && this.listProperties.isLocalPagination && this.listData) {
      this.displayData = this.listData;
      // this.selection.select(...this.displayData);
      // this.selection.select(...this.displayData);
      // console.log('displayData Data', this.displayData);
    } else if (this.listProperties && this.listProperties.noPagination) {
      this.displayData = this.listData;
    }

    if (this.listProperties && this.listProperties.isLocalPagination != undefined && this.listProperties.isLocalPagination && this.listData) {
      this.paginationService.listPaginatorData.next({
        rows: this.listData,
        count: this.listData.length,
        new: true,
        searchText: ''
      });
      if (this.listData.length == 0) {
        this.hideSearch = true;
      }
    }

  }
  /**
   * detectChanges() function called to detect changes in UI
   */
  ngAfterViewInit(): void {
    this.changeDetectorRef.detectChanges();
    // if (this.selectionArray && this.selectionArray.length) {
    //   this.selection.select(...this.selectionArray);
    //   this.selectedArray.push(...this.selectionArray);
    // }
  }
  /**
   * 
   * @param event - Event from search input changes
   * It performs 2 operations
   *    1) Local search for local pagination
   *    2) Emit data to serchedText for server side pagination 
   */
  searchKey(event: any): string | any {
    // console.log(event.target.value, event.key );
    // if(event && event.target &&  event.target.value){
    this.searchText = event.target.value;
    // } 
    // console.log(this.searchText);
    if (this.listProperties.disableLocalSearch) {
      this.searchedText.emit(this.searchText);
      // this.commonSevice.searchSubject.next(this.searchText );
    } else {
      if (event && event.key !== ' ' && event.target && event.target.value && event.target.value.length) {
        if (this.listProperties.serverSidePagination || this.listProperties.serverSidePagination == undefined) {
          // this.subject.next(event.target.value);
          // this.commonSevice.searchSubject.next(event.target.value);
          this.searchedText.emit(event.target.value);
        } else {
          // console.log(this.listData);
          if (this.listProperties && this.listProperties.localSearchFields && this.listProperties.localSearchFields.length > 0) {
            let searchString = event.target.value.trim().toLowerCase();
            this.displayData = this.listData.filter((item: any) => {
              let query = this.formSearchQuery(item, searchString);
              // let query = '';
              // console.log(query);
              return eval(query);
            });
          }
          // this.commonSevice.searchSubject.next(event.target.value);
          // this.subject.next(event.target.value);
          this.searchedText.emit(event.target.value);
          this.filteredData.emit(this.displayData);
          this.paginationService.listPaginatorData.next({
            rows: this.displayData,
            count: this.displayData.length,
            new: true,
            searchText: ''
          });
        }
      } else {
        if (this.listProperties.serverSidePagination || this.listProperties.serverSidePagination == undefined) {
          //  if(event.target && event.target.value && (event.target.value).trim().length) {
          this.searchedText.emit(event.target.value);
          //  }
          // this.subject.next(event.target.value);
          // this.commonSevice.searchSubject.next(event.target.value);
        } else {
          this.displayData = this.listData;
          // this.commonSevice.searchSubject.next(event.target.value);
          // this.subject.next(event.target.value);
          // if(event.target && event.target.value && (event.target.value).trim().length) {
          this.searchedText.emit(event.target.value);
          // }
          this.filteredData.emit(this.displayData);
          this.paginationService.listPaginatorData.next({
            rows: this.displayData,
            count: this.displayData.length,
            new: true,
            searchText: ''
          });
        }
      }
    }
  }


  /**
   * 
   * @param data it will pass row data of each data
   * @param event it will emit the click event
   * This function performs select and deselect operation for the selected array.
   */
  selectChange(data?: any, event?: any): void {
    // console.log('select change', data, data.isSelected);
    // this.isAllSeleceted();
    if (this.newFilterApply) {
      this.MultipleSelect = [];
    }
    if (this.actions && this.actions.length && this.actions.findIndex(item => item.value === 'deselectAll') >= 0) {
      this.isSelectAll = true;
    }
    if (data) {
      // console.log('beofre', data.isSelected);
      data.isSelected = !data.isSelected;
      // console.log('after', data.isSelected);

      if (this.listProperties && !this.listProperties.rowElementClicked) {
        if (this.isSelectAll) {
          this.multiSelectValueChange(data, false);
          this.selectedData.emit(this.MultipleSelect);
        }
        else {
          this.multiSelectValueChange(data, true);
          this.selectedData.emit(this.MultipleSelect);
        }
      } else {
        this.selectedData.emit(data);
      }
    }
  }
  /**
   * 
   * @param event this will pass click event
   * @param icon it will pass icon obejct with name, method.
   * @param data it will emit row data of clicked card
   * actionClicked event will emit the icon and data
   */
  actionClick(event: any, icon: any, data: any): void {
    event.stopPropagation(); // need to why this is used
    this.actionClicked.emit(Object.assign({}, icon, { data: data }));
  }

  /**
   * 
   * @param event  this will pass toggle event
   * @param data it will pass the row data of clicked card
   * @param column it will pass the column of clicked card
   * this function performs toggle click and its confirmation operation
   */
  toggleChange(event: any, data: any, column: any): void | any {
    // console.log(event);
    // this.listProperties.toggleConfirmationMessage = { on : "do u want to on" , off: "do u want to ff"}
    let message;
    if (this.listProperties.toggleConfirmationMessage) {
      if (event.checked) {
        message = this.listProperties && this.listProperties.toggleConfirmationMessage && this.listProperties.toggleConfirmationMessage.on && this.listProperties.toggleConfirmationMessage.on.length > 0 ? this.listProperties.toggleConfirmationMessage.on : 'Do you want change?';
      } else {
        message = this.listProperties && this.listProperties.toggleConfirmationMessage && this.listProperties.toggleConfirmationMessage.off && this.listProperties.toggleConfirmationMessage.off.length > 0 ? this.listProperties.toggleConfirmationMessage.off : 'Do you want change?';
      }
    }
    const dialogRef = this.dialogService.openDialog({
      header: "Confirmation",
      message: message ? message : 'Do you want change?',
      actionType: "confirmation",
      button: { right: "Yes", left: "No" },
      disableClose: false
    });
    this.subscriptionObj.add(dialogRef.afterClosed().subscribe((res) => {
      // console.log(res);
      if (res) {
        this.toggleClicked.emit(Object.assign({}, column, { data: data }));
      } else {
        // console.log(event);
        let data = this.displayData;
        this.displayData = null;
        this.changeDetectorRef.detectChanges();
        // console.log('data', data, 'display data', this.displayData);
        this.displayData = data;
        return;
      }
    }))
  }
  /**
   * 
   * @param event this will pass click event of select with select value
   */
  rightFilterOptionChanges(event: any): any {
    // console.log(event);
    this.rightFilterChanged.emit(event.value);
  }
  /**
   * Method Which is used to Perform action during row element click
   * @param data Which emits the row elements from the card - it will emit all the data from the given array
   */
  rowClick(data: any): void {
    if (this.listProperties && this.listProperties.rowElementClicked) {
      this.selectedData.emit(data);
    }
  }

  /**
  * Method which is used to get selected values in multi select
  * @param value To get select change value
  * @param selectAll For change the array value
  */
  multiSelectValueChange(value: any, selectAll: boolean): void {
    if (value && value.isSelected === selectAll) {
      this.MultipleSelect.push(value);
    }
    else {
      const removeIndex = this.MultipleSelect.findIndex((val: any) => val && val.id === value.id)
      if (removeIndex >= 0)
        this.MultipleSelect.splice(removeIndex, 1);
      // this.MultipleSelect.forEach((element, i) => {
      //   console.log('multipleseec', element.isSelected, this.MultipleSelect.indexOf(value));
      //   const val = this.MultipleSelect.splice(i, 1, value);
      //   console.log('val', val, element.isSelected);
      //   if (element && element.isSelected === !selectAll) {
      //     console.log('multi value else inside if');
      //     this.MultipleSelect.splice(i, 1)
      //   }
      // });
    }
    // console.log(this.MultipleSelect);
  }

  /**
  * Function is used to get the pagination emit value
  * @param event For get display data values
  */
  onDataEmit(event: any): void {
    this.isRowActionIcons = false;
    // console.log('Data emit', event);
    // this.selection.clear();
    // this.displayData = [];
    // if(event && event.length){
    this.displayData = event;
    this.commonSevice.displayData = event;
    // this.listData = this.displayData;
    // }
    // console.log(this.singleItemSelect);
    if (this.selectionArray && this.selectionArray.length && this.isFirst) {
      // console.log('first time only');
      // this.selectionArray.forEach((item: any)=> {
      // let arr = this.displayData.filter((item: any)=> {
      //     this.selectionArray.find((ele)=> {
      //       JSON.stringify(item) == JSON.stringify(ele);
      //     });
      // });
      // console.log('arr', arr);
      // this.selectionArray.forEach(element => {
      // console.log(element,this.selection.isSelected(element) )
      // if(!this.selection.isSelected(element)) {
      // this.selection.select(element);
      // }
      // });
      // let a = this.selectionArray;
      this.selectedArray.push(...this.selectionArray);
      this.selection.select(...this.selectionArray);
      this.isFirst = false;
      // a= [];
      // console.log('selection', this.selection.selected);
      // console.log('selectionArray', this.selectionArray);
      // });
      // this.changeDetectorRef.detectChanges();
    }
    // console.log('selected array', this.selectedArray);
    // console.log('selected', this.selection.selected);
    if (this.displayData && this.displayData.length) {
      let disableCheckBoxLen = this.displayData.filter((item: { isHideCheckBox: boolean; }) => item.isHideCheckBox === true);
      this.isMasterToggleDisable = disableCheckBoxLen && disableCheckBoxLen.length === this.displayData.length;
      this.displayData.map(element => {
        // console.log('val', val);
        // if (element['checked']) {
        //   this.selectedArray.push(element);
        //   this.selection.select(...this.selectionArray);
        // }
        element['checked'] = this.checkForSelection(element);
        // console.log(val);
      });
    }
    // console.log('data', this.displayData);
    // console.log('selected array', this.selectedArray);
    if (this.displayData && this.displayData[0] && (this.displayData[0].rowActionsIcon || this.displayData[0].rowMoreActionsIcon)) {
      this.isRowActionIcons = true;
    } else {
      this.isRowActionIcons = false;
    }
    if (this.isSelectAll === true && this.displayData && this.displayData.length > 0) {
      this.displayData.map((element: any) => {
        const index = this.MultipleSelect.findIndex((item: any) => item && element && item.id === element.id);
        if (index >= 0) {
          element.isSelected = !this.isSelectAll;
        }
        else
          element.isSelected = this.isSelectAll;
      });
    }
    this.changeDetectorRef.detectChanges();
    // console.log('master toggle', this.isMasterToggle);
    // if (this.isMasterToggle) {
    //   let arr = this.selection.selected;
    //   this.displayData.forEach((item) => {
    //     arr.push(item);
    //   });
    //   arr = arr.filter((arrItem)=> {
    //     return !this.unCheckedArray.find((unCheckItem)=> {
    //      return JSON.stringify(arrItem) == JSON.stringify(unCheckItem);
    //     });
    //   });
    //   this.selection.clear();
    //   this.selection.select(...arr);
    // }

  }
  /**
   * 
   * @param data - it will pass the row data
   * @returns - it will return true or false based on existing os selectedArray
   */
  checkForSelection(data): boolean {
    // this.selectedArray.forEach((item) => {
    //   if(item.id == data.id){
    //     return true;
    //     console.log(item.id, data.id)
    //   }
    // });
    // return false;
    let isExist = this.selectedArray.find((item) => item.id == data.id);
    return isExist ? true : false;
  }

  /**
   * Methos which is trigger whenever header icons is hovered
   * @param iconEvent To get the hovered icon details
   */
  headerIconFocused(iconEvent: any): void {
    if (this.actions.find(icon => icon && icon.value === 'deselectAll')) {
      this.isSelectAll = true;
      this.MultipleSelect = [];
    }
    if (iconEvent && iconEvent.value && iconEvent.value.includes('CurrentPaginationValues')) {
      if (this.displayData.filter((val: any) => val.isSelected === true).length === this.displayData.length) {
        iconEvent.value = 'deselectCurrentPaginationValues';
        iconEvent.toolTip = 'Deselect current page customers';
      }
      else {
        iconEvent.value = 'selectCurrentPaginationValues';
        iconEvent.toolTip = 'Select current page customers';
      }
    }
    else if (this.isSelectAll && iconEvent.value.includes('All')) {
      if (this.MultipleSelect && !this.MultipleSelect.length) {
        iconEvent.value = 'deselectAll';
        iconEvent.toolTip = 'Deselect All';

      }
      else {
        iconEvent.value = 'selectAll';
        iconEvent.toolTip = 'Select All';
      }
    }
  }

  /**
   * Method which to emit the method while click the action icons to parent component
   * @param value To get selected icon value
   */
  iconEvent(value: any, icon?: any): void {
    if (this.actions && this.actions.length && this.actions.findIndex(item => item.value === 'deselectAll') >= 0)
      this.isSelectAll = true;
    switch (value) {
      case 'selectCurrentPaginationValues':
        if (icon) {
          icon.value = 'deselectCurrentPaginationValues'
          icon.toolTip = 'Deselect current page customers'
        }
        this.displayData.map((element: any) => {
          if (element) {
            element.isSelected = true;
            if (this.isSelectAll)
              this.multiSelectValueChange(element, false);
          }
        });
        if (this.isSelectAll)
          this.selectedData.emit(this.MultipleSelect);
        else
          this.iconClicked.emit(this.displayData);
        break;
      case 'deselectCurrentPaginationValues':
        if (icon) {
          icon.value = 'selectCurrentPaginationValues'
          icon.toolTip = 'Select current page customers'
        }
        this.displayData.forEach((element: any) => {
          if (element) {
            element.isSelected = false;
            this.MultipleSelect.push(element)
          }
        });
        if (this.isSelectAll)
          this.selectedData.emit(this.MultipleSelect);
        else
          this.iconClicked.emit(this.displayData);
        break;
      case 'selectAll':
        if (icon) {
          icon.value = 'deselectAll'
          icon.toolTip = 'Deselect All'
        }
        this.isSelectAll = true;
        this.MultipleSelect = [];
        if (this.displayData && this.displayData.length > 0) {
          this.displayDataChange(true);
        }
        this.iconClicked.emit(this.isSelectAll);
        break;
      case 'deselectAll':
        if (icon) {
          icon.value = 'selectAll'
          icon.toolTip = 'Select All'
        }
        this.isSelectAll = false;
        this.MultipleSelect = [];
        this.displayDataChange(false);
        this.iconClicked.emit(false);
        break;
      default:
        this.iconClicked.emit(value);
      // this.selection.clear();
      // this.unCheckedArray = [];
      // console.log(this.selection.hasValue());

      // this.isAllSeleceted();
    }
    // if(icon && icon.conditionType && icon.conditionType == "DELETE") {
    //   this.selectedArray = [];
    // }
  }

  /**
   * 
   * @param selected - boolean value to set element selected or not
   */
  displayDataChange(selected: any) {
    this.displayData.map((element: any) => {
      if (element)
        element.isSelected = selected;
    });
  }

  /**
  * Function used to get pagination details
  * @param event For get display page details
  */
  getPaginatorData(event: any) {
    // this.selection.clear();
    // console.log('paginator data called', event);
    this.paginatorDetails = event;
    this.paginatorData.emit(event);
    // if (this.isMasterToggle) {
    //   let arr = this.selection.selected;
    //   this.displayData.forEach((item) => {
    //     arr.push(item);
    //   });
    //   arr = arr.filter((arrItem)=> {
    //     return !this.unCheckedArray.find((unCheckItem)=> {
    //      return JSON.stringify(arrItem) == JSON.stringify(unCheckItem);
    //     });
    //   });
    //   this.selection.clear();
    //   this.selection.select(...arr);
    // }
  }

  /**
  * pagination datas
  * @param event limit,offset
  */
  getChangedData(event: any): void {
    // this.selection.clear();
    // if (this.isMasterToggle) {
    //   let arr = this.selection.selected;
    //   this.displayData.forEach((item) => {
    //     arr.push(item);
    //   });
    //   console.log('array', arr);
    //   this.selection.clear();
    //   this.selection.select(...arr);
    //   console.log(this.selection);
    // }
    // if (this.isMasterToggle) {
    //   this.selection.clear();
    //   let arr = this.selection.selected;
    //   this.displayData.forEach((item) => {
    //     arr.push(item);
    //   });
    //   arr = arr.filter((arrItem)=> {
    //     return !this.unCheckedArray.find((unCheckItem)=> {
    //      return JSON.stringify(arrItem) == JSON.stringify(unCheckItem);
    //     });
    //   });
    //   this.selection.clear();
    //   this.selection.select(...arr);
    // }
    this.valueChanged.emit(event);
  }

  /**
  * Method which to process the value for the nested properties in objects.
  * @param index The value of the current data in loop.
  * @param key The property which used to get the data.
  * @returns Processed value
  */
  getValue(index: any, key: any): any {
    const processedKey = key.split('.');
    if (processedKey.length === 2) {
      if (this.displayData[index] && this.displayData[index][processedKey[0]]) {
        return this.displayData[index][processedKey[0]][processedKey[1]];
      }
    } else if (processedKey.length === 3) {
      if (this.displayData[index] && this.displayData[index][processedKey[0]] &&
        this.displayData[index][processedKey[0]][processedKey[1]]) {
        return this.displayData[index][processedKey[0]][processedKey[1]][processedKey[2]];
      }
    }
  }

  // masterCheckBox() {

  //   let opearation;
  //   console.log(this.isAllSeleceted());
  //   if (this.isAllSeleceted()) {
  //     opearation = 'deselectCurrentPaginationValues';
  //     this.masterToggleValue = false;
  //   } else {
  //     opearation = 'selectCurrentPaginationValues';
  //     this.masterToggleValue = true;
  //   }
  //   this.iconEvent(opearation);
  // }



  /**
   * 
   * @returns - return to stop the function 
   * Function called when the user clicks on master check box and it will push the data into selected array and removes from seleted array
   */
  masterToggle(): any {
    if (this.isMasterToggle) {
      // if (this.selection.hasValue()) {
      if (this.selectedArray && this.selectedArray.length > 0) {
        this.isMasterToggle = !this.isMasterToggle;
      }
    } else {
      this.isMasterToggle = !this.isMasterToggle;
    }
    // console.log('master toggle', this.isMasterToggle, 'selection value', this.selection.hasValue(), 'isall selected' ,this.isAllSelected());
    // if(this.isMasterToggle && this.selection.hasValue() && !this.isAllSelected()){
    //   this.selection.clear();
    //   this.unCheckedArray = [];
    // } else if (this.isAllSelected() || !this.isMasterToggle) {
    //   this.selection.clear();
    //   this.unCheckedArray = [];
    //   return;
    // }
    // this.selectedData.emit(this.isMasterToggle);
    // this.unCheckedArray = [];
    if (this.isAllSelected()) {
      // console.log('All selected');
      const selectedData = this.displayData.filter((data: { isHideCheckBox: any; }) => !data.isHideCheckBox);
      selectedData.map((data: { checked: boolean; id: any; }) => {
        data.checked = false;
        this.selectedArray.splice(this.selectedArray.findIndex(item => item.id === data.id), 1);
        this.unCheckedArray.push(data);
      });
      this.selection.deselect(...selectedData);
      this.selectionDataEmit();
      return;
    } else {
      // console.log('else part of all selected');
      const selectedData = this.displayData.filter((data: { isHideCheckBox: any; }) => !data.isHideCheckBox);
      selectedData.forEach(item => { item.checked = true; });
      this.selectedArray.push(...selectedData);
      this.selection.select(...selectedData);
      // console.log(this.selectedArray);
      // console.log(this.selection.selected);
      // this.displayData.forEach(element => {
      // console.log(this.selectedArray);
      // });
      // console.log(this.displayData);
      // this.changeDetectorRef.detectChanges();
      return;
    }
    // this.selection.select(...this.displayData);

    // console.log(this.selection);
  }

  /**
   * 
   * @returns it will return boolan value based on all seleted items in that display
   * 
   */
  isAllSelected() {
    // const numSelected = this.selection.selected.length;
    // let numRows = 0;
    // if(this.paginatorDetails && this.paginatorDetails.pageIndex && this.paginatorDetails.pageSize) {
    //    numRows =  this.paginatorDetails.pageIndex * this.paginatorDetails.pageSize;
    // } else {
    //   numRows = this.displayData.length;
    // }
    // console.log('selected array',   this.selectedArray);
    const selectedData = this.displayData.filter((data: { isHideCheckBox: any; }) => !data.isHideCheckBox);
    // let arr = this.displayData && this.displayData.length ? this.displayData : [];
    let arr = selectedData && selectedData.length ? selectedData : [];
    arr = arr.filter((item: any) => {
      // console.log(this.checkForSelection(item));
      // console.log(this.selection.isSelected(item));
      return this.checkForSelection(item);
    });
    // return arr.length === this.displayData.length;
    return arr.length === selectedData.length;
  }

  /**
   * 
   * @param row - it pass the row data
   * @returns - it returns the label for the checkbox based on the state
   */
  checkboxLabel(row?: any): string {
    if (!row) {
      return `${this.isAllSelected() ? 'deselect' : 'select'} all`;
    }
    return `${this.checkForSelection(row) ? 'deselect' : 'select'} row ${row.position + 1}`;
  }

  /**
   * 
   * @param item - It pass the row object for search
   * @param searchString - user entered search string to frame a search query
   * @returns it return a search query as a string. ex: person.firstName.toLowerCase().includes('carmel') || person.lastName.toLowerCase().includes('carmel')
   */
  formSearchQuery(item: any, searchString: string): string {
    let query = '';
    if (item && this.listProperties && this.listProperties.localSearchFields) {
      this.listProperties.localSearchFields.forEach((value: any, id: any) => {
        query += `(item?.${value})?.toLowerCase()?.includes('${searchString}')`;
        // let  s =  String(item.value);
        // query += `${s.toLowerCase().includes('searchString')}`;
        if (id != (this.listProperties.localSearchFields.length - 1)) {
          query += '||'
        }
      });
      // console.log('s', s);
      // console.log('quesry', query);
      return query;
    }
  }

  /**
   * 
   * @param value - it will pass the icon value from the icon Object
   * @param conditionType - it pass the condition type to enable or disable button
   * @returns - return boolean value to enable or disable action icons or buttons
   */
  actionConditionCheck(value: any, conditionType?: any): boolean {
    // console.log(value, conditionType);
    if (value != undefined) {
      // console.log(value);
      if (value && (conditionType == undefined || !conditionType || conditionType == 'DELETE')) {
        return this.selectedArray.length == 0;
      } else if (value && conditionType && conditionType == 'DATA_EMPTY') {
        return this.displayData && this.displayData.length == 0;
      } else {
        return false;
      }
    }
  }
  /**
   * 
   * @param condition - Conditions to apply classes based on different conditions
   * @param data - the data value in that column to get proper class for that
   * @returns return the class name for particular class for the column
   */
  getClassForColumn(condition: any, data: any): string {
    return condition[data] ? condition[data] : '';
  }
  /**
   * 
   * @param condition - Conditions to get icon based on different conditions
   * @param data - the data value in that column to get proper icon for that
   * @param defaultIcon - default icon if no conditions are matched
   * @returns it returns the icon for the coloumn based on conditions
   */
  getIconForColumn(condition: any, data: any, defaultIcon: any): string {
    // console.log(condition, data,condition[data], defaultIcon);
    let icon = condition[data] ? condition[data] : defaultIcon;
    return (icon && icon != undefined) ? icon : 'label';
  }
  /**
   * 
   * @param condition - Conditions to get tooltip based on different conditions
   * @param data - the data value in that column to get proper tooltip for that column.
   * @param defaultToolTip - default tooltip if no conditions are matched
   * @returns it returns the tooltip for the coloumn based on conditions
   */
  getToolTipForColumn(condition: any, data: any, defaultToolTip: any): string {
    // console.log(condition, data,condition[data], defaultIcon);
    let toolTip = condition[data] ? condition[data] : defaultToolTip;
    return (toolTip && toolTip != undefined) ? toolTip : '';
  }
  /**
   * 
   * @param event - click event for checkbox click
   * @param data  - it passes the row data of clicked row
   */
  singleCheckBoxClick(event, data): void {
    // console.log('single checkbox click;',this.selection.selected, event.checked, data);
    if (!event.checked) {
      this.unCheckedArray.push(data);
      while (this.selectedArray.findIndex(item => item.id === data.id) != -1) {
        this.selectedArray.splice(this.selectedArray.findIndex(item => item.id === data.id), 1);
      }
      while (this.selection.selected.findIndex(item => item.id === data.id) != -1) {
        this.selection.selected.splice(this.selection.selected.findIndex(item => item.id === data.id), 1);
      }
      this.displayData[this.displayData.findIndex(item => item.id === data.id)].checked = false;
      this.selection.deselect(data);
      this.selectionDataEmit();
      // console.log(this.selection.selected, data);
      // console.log(this .selection.selected.findIndex(item => item.id === data.id));
    } else {
      this.unCheckedArray.splice(this.unCheckedArray.findIndex(item => item.id === data.id), 1);
      this.displayData[this.displayData.findIndex(item => item.id === data.id)].checked = true;
      this.selectedArray.push(data);
      this.selection.select(data);

    }
    // console.log(this.selection.selected);
    // console.log(this.selectedArray);

    // if(this.isAllSelected(data))
  }

  /**
   * 
   * @param data - it passes the row data of each row
   * @returns - it returns ! or empty sring based on badgeCondition.field
   */
  isBadgeDisplay(data: any): string {
    // console.log('badge',data);
    // console.log(data[this.listProperties.badgeCondition.field], this.listProperties.badgeCondition.value);
    if (this.listProperties && this.listProperties.badgeCondition) {
      return (data[this.listProperties.badgeCondition.field] == this.listProperties.badgeCondition.value) ? ((this.listProperties.badgeCondition && this.listProperties.badgeCondition.displayText) ? this.listProperties.badgeCondition.displayText : this.translate.getTranslation('COMMON.BADGE_TEXT')) : '';
    }
  }

  isRibbonDisplay(data: any, column: any, isSubRibbon?: any): any {
    // console.log(data, column);
    // if (data && column && column.customRibbon && !isSubRibbon) {
    //   return (data[column.customRibbon.field] == column.customRibbon.value) ? column.customRibbon.ribbonText : false;
    // }
    // else if (data && column && column.customRibbon && column.customRibbon.subCustomRibbon && isSubRibbon) {
    //   return (data[column.customRibbon.subCustomRibbon.field] == column.customRibbon.subCustomRibbon.value) ? column.customRibbon.subCustomRibbon.ribbonText : false;
    // }
    if (data && column && column.customRibbon) {
      const customRibbon = isSubRibbon ? column.customRibbon.subCustomRibbon : column.customRibbon;
      return data[customRibbon.field] === customRibbon.value ? customRibbon.ribbonText : false;
    }
  }

  /**
   * 
   * @param data - it passes the row data of each row
   * @param cardBorderClass - card border class values based on data field in that column.
   * @returns - it returns the border class for that card
   */
  getClassForBorderCard(data, cardBorderClass): string {
    // console.log(data);
    // if(this.listProperties.cardBorderCondition) {
    //   let color = this.listProperties.cardBorderCondition.condition[data[this.listProperties.cardBorderCondition.field]];
    //   console.log('color', color);
    //   let style = color ? `{'border-left': '1px solid ${color}'}` : `{'border-left': '1px solid blue'}`;
    //   console.log(style);
    //   return style;
    // } else if(this.listProperties.cardBorderClass){
    //   return {border: `1px solid ${this.listProperties.cardBorderClass}`};
    // }
    return this.listProperties.cardBorderCondition.condition[data[this.listProperties.cardBorderCondition.field]] ? this.listProperties.cardBorderCondition.condition[data[this.listProperties.cardBorderCondition.field]] : cardBorderClass ? cardBorderClass : 'defaultBorder';
  }
  /**
   * 
   * @param data - it passes the row data of selected row 
   * it will emit the selectedData of the seleted radio button
   */
  radioButtonSelect(data): void {
    // console.log(data);
    if (data) {
      this.singleItemSelect = data;
      this.selectedData.emit(data);
    }
  }
  /**
   * 
   * @param sort - it will pass the sort object from the user selection, example: sort: { label: 'Name Asc', column:'name', order:'asc'  }
   * it will emit the sort object to the parent component thirugh sortEventClicked.
   */
  sortClick(sort: any): void {
    //console.log(sortKey);
    if (this.selectedSort && this.selectedSort.column == sort.column && this.selectedSort.order == sort.order) {
      this.selectedSort = {};
      this.sortEventClicked.emit({ resetSort: true });
    } else {
      this.selectedSort = sort;
      this.sortEventClicked.emit({ column: sort.column, order: sort.order });
    }
  }
  /**
   * @param value - it will pass the value of the more action row
   * @param rowData - it will pass the value of the row data
   */
  moreActionClick(eventData: any, rowData: any): void {
    // console.log(eventData, rowData);
    this.moreActionsClicked.emit({ eventData, rowData });
  }
  /**
   * Function which is used to emit the Filter Date values to the parent component
   * @param event
   */
  onDateFilterChange(): void {
    // console.log('date',this.dateFilterForm.value);
    this.topRightDateFilterEvent.emit({ date: this.dateFilterForm.value });
  }

  isRowWiseToggleEnabled(data: any, column: any): boolean {
    if (column && column.type && column.type == "Toggle" && column.toggleCondition && column.toggleCondition.fieldName) {
      return data[column.toggleCondition.fieldName] == column.toggleCondition.value ? true : false;
    } else {
      return false;
    }
  }

  onColumnClick(column: any, rowData: any): void {
    // console.log(column, rowData);
    if (column && column.isColumnClick) {
      this.columnItemClicked.emit({ column, rowData });
    }
  }

  rightTopFilterSearch(value?: string): void {
    // console.log(value);
    // console.log(this.rightTopFilter.values);
    if (value && this.rightTopFilter && this.rightTopFilter.values) {
      const filterValue = value.trim().toLowerCase();
      this.rightTopFilterFilteredData = this.rightTopFilter.values.filter(value => {
        if (value[this.rightTopFilter.matLabelField]) {
          return value[this.rightTopFilter.matLabelField].toLowerCase().includes(filterValue)
        }
        // if (brandValue && brandValue.name) {
        //   return brandValue.name.toLowerCase().includes(filterValue)
        // }
      }
      );

      // if (this.rightTopFilterFilteredData.length === 0) {
      // this.setRightTopFilterData();
      // }

    }
    else {
      this.rightTopFilterSearchField.setValue(null);
      this.setRightTopFilterData();
    }
  }

  rightTopFilterClose(): void {
    //   // this.addProductForm.get('general').get('brandId').setValue(null)
    //   this.brandSearchFilter.setValue(null);
    //   this.filteredData.brandFilteredData = [];
    //   this.filteredData.brandFilteredData = this.options.brands;
    //   this.toSetBrandValue();
    this.rightTopFilterSearchField.setValue(null);
    this.setRightTopFilterData();
  }

  setRightTopFilterData() {
    if (this.rightTopFilter && this.rightTopFilter.values) {
      this.rightTopFilterFilteredData = cloneDeep(this.rightTopFilter.values);
    }
  }

  rightTopFilterSearchFieldChange(event) {
    if (!event) {
      this.rightTopFilterSearchField.setValue(null);
      this.setRightTopFilterData();
    }
  }

  // Method to handle language change
  rightTopLanguageFilterOptionChanges(event: any): void {
    if (event) {
      this.rightLanguageFilterChanged.emit(event.value);
    }
  }

  // Function to handle language filter search field change
  // rightTopLanguageFilterSearchFieldChange(event: any): void {
  //   if (!event) {
  //     this.rightTopLanguageFilterSearchField.setValue(null);
  //     this.setRightTopLanguageFilterData();
  //   }
  // }

  // Function to filter language based on search value
  // rightTopLanguageFilterSearch(value?: string): void {
  //   if (value && this.rightTopLanguageFilter && this.rightTopLanguageFilter.values) {
  //     const filterValue = value.trim().toLowerCase();
  //     this.rightTopFilterLanguageData = this.rightTopLanguageFilter.values.filter((lang: { [x: string]: string; }) => {
  //       if (lang[this.rightTopLanguageFilter.displayName]) {
  //         return lang[this.rightTopLanguageFilter.displayName].toLowerCase().includes(filterValue);
  //       }
  //     });
  //   } else {
  //     this.rightTopLanguageFilterSearchField.setValue(null);
  //     this.setRightTopLanguageFilterData();
  //   }
  // }

  // Function to set initial language filter data
  setRightTopLanguageFilterData(): void {
    if (this.rightTopLanguageFilter && this.rightTopLanguageFilter.values) {
      this.rightTopFilterLanguageData = cloneDeep(this.rightTopLanguageFilter.values);
    }
  }

  // Function to reset language filter
  // rightTopLanguageFilterClose(): void {
  //   this.rightTopLanguageFilterSearchField.setValue(null);
  //   this.setRightTopLanguageFilterData();
  // }

  ngOnDestroy(): void {
    // console.log('destory');
    this.commonSevice.searchField.setValue(null);
    if (this.subscriptionObj) {

      this.subscriptionObj.unsubscribe();
    }
    this.selection.changed.unsubscribe();
    // this.paginationService.listPaginatorData.next({
    //   rows: null,
    //   count: 0,
    //   new: true,
    //   searchText: ''
    // });
    this.selectedArray = [];
    this.unCheckedArray = [];
    this.displayData = [];
    this.commonSevice.displayData = [];
    this.isRowActionIcons = false;
  }
}
