export const REFERRAL_CODE_CONSTANT = {
  /**
   * variable which is used for referral code list heading
   * @type {object}
   */
  REFERRAL_HEADING: {
    TITLE: 'Referral Code',
    DESCRIPTION: ''
  },
  /**
   * variable which is used for referral customer list heading
   * @type {object}
   */
  CUSTOMER_HEADING: {
    TITLE: 'Customer List',
    DESCRIPTION: ''
  },
  /**
  * variable which is used for referral commission list heading
  * @type {object}
  */
  COMMISSION_HEADING: {
    TITLE: 'Commission List',
    DESCRIPTION: ''
  },
  /**
   * Variable used to store referral code list details
   */
  COLUMNS_REFERRAL: [
    { column: 'referralCode', type: 'Text', suffixText: 'Referral Code' },
    { column: 'userCount', type: 'Chip', suffixText: 'Users Count', backgroundColor: 'rgb(158, 52, 117)', classCondition: { '0 User(s)': "nonValueChip" } },
    { column: 'isActive', type: 'Toggle', sort: false, suffixText: 'Status', iconCondition: { true: 'done', false: 'close' } }
  ],
  /** 
   * Variable used to store referral code customer details
   */
  COLUMNS_CUSTOMER: [
    { column: 'Name', suffixText: 'Name', type: 'Text' },
    { column: 'Email', suffixText: 'Email', type: 'Text' },
    { column: 'CommissionAmount', suffixText: 'Commission Amount', type: 'Text' },
    { column: 'ContactNumber', suffixText: 'Contact Number', type: 'Text' },
  ],
  /** 
   * Variable used to store referral code commission details
   */
  COLUMNS_COMMISSION: [
    { column: 'PlanName', suffixText: 'Plan Name', type: 'Text' },
    { column: 'PlanAmount', suffixText: 'Plan Amount', type: 'Text' },
    { column: 'amount', suffixText: 'Amount', type: 'Text' },
    { column: 'date', suffixText: 'Date', type: 'Text' },
  ],
  /**
   * Variable used to store referral code list icon details
   */
  ACTIONICONS_REFERRAL: [
    { iconName: 'content_copy', method: 'onCopy', toolTip: 'Copy Referral', iconColor: '#464646' },
    { iconName: 'supervisor_account', method: 'viewCustomersDetails', toolTip: 'View Customer List', iconColor: '#000099' },
  ],
  /**
   * Variable used to store referral customer list icon details
   */
  ACTIONICONS_CUSTOMER: [
    { iconName: 'visibility', method: 'onView', toolTip: 'View Commission Details', iconColor: 'red' }
  ],
  /**
   *  used to display filter options
   */
  FILTER_OPTION: ['Active', 'Inactive'],
  /**
   * filter details
   */
  ACTIONS: [
    { name: 'filter_list', value: 'filter', toolTip: 'Filter', isConditionApplied: true, conditionType: 'DATA_EMPTY' },
  ],
  /**
   * filter contents
   */
  REFERRALCODE_LIST_FILTER: undefined as any,

  /**
   * Variable used to store error messages
   */
  ERROR_MESSAGES: {
    REFERRAL_CODE_REQUIRED: 'Referral code is required',
    REFERRAL_CODE_MAX_LIMIT: 'Referral code should not exceed 20 characters',
    REFERRAL_CODE_MIN_LIMIT: 'Referral code should not be less than 6 characters',
    REFERRAL_CODE_ALREADYEXISTS: 'Referral code already exists'
  },
  /**
   * Variable used to store dialog messages
   */
  DIALOG_MESSAGES: {
    REFERRAL_DELETE: 'Are you sure you want to delete this referral?',
    REFERRAL_DELETE_FAILURE: 'Failed to delete referralCode',
    REFERRAL_CODE_FAILURE: 'Failed to get referral code data',
    REFERRAL_SAVE_FAILURE: 'Failed to save referral code',
    REFERRAL_DIALOG_CLOSE: 'You have unsaved changes. Are you sure you want to close this dialog?',
    FAILED_CUSTOMER_DETAILS: "Failed to load customers details",
    FAILED_USER_DETAILS: 'Failed to get user details',
    FAILED_PARAM_DETAILS: 'Cannot get customer id details',
    FAILED_COMMISSION_DETAILS: 'Cannot get commission details',
    COPY_REFERRAL_CODE: 'Referral code copied',
  },
  /**
   * Variable used to store snackbar messages
   */
  SNACKBAR_MESSAGES: {
    REFERRAL_COPIED: 'Referral code copied',
    SUCCESS_DEACTIVATE: 'Code deactivated successfully.',
    FAILURE_DEACTIVATE: 'Code deactivation failed.',
    SUCCESS_ACTIVATE: 'Code activated successfully.',
    FAILURE_ACTIVATE: 'Code activation failed.',
  },
  /**
   * Variable used to store dialog type.
   */
  DIALOG_TYPE: {
    ALERT: 'alert',
    CONFIRMATION: 'confirmation',
    INFORMATION: 'information',
    SUCCESS: 'success',
    FAILURE: 'failure',
  },
  /**
   * Variable used to store dialog header
   */
  DIALOG_HEADER: {
    FAILURE: 'failure',
    CONFIRMATION: 'Confirmation',
    STORE_GETS_FAILED: "Failed to get store names",
    SUCCESS: 'Success'
  },
  /**
   * Variable used to store pagination details
   */
  PAGINATOR: {
    PAGE_LENGTH_OPTIONS: [5, 10, 20, 30, 40],
    DEFAULT_PAGE_SIZE: 10
  },
}
REFERRAL_CODE_CONSTANT.REFERRALCODE_LIST_FILTER = [
  { title: 'Status', type: 'radio', field: 'isActive', options: REFERRAL_CODE_CONSTANT.FILTER_OPTION },
];

export class referralCodeConstant {
  /**
   * variable which is used for referral code list heading
   * @type {object}
   */
  heading = {
    title: 'Referral Code',
    description: ''
  }
  /**
  * variable which is used for referral customer list heading
  * @type {object}
  */
  customerHeading = {
    title: 'Customer List',
    description: ''
  }
  /**
  * variable which is used for referral commission list heading
  * @type {object}
  */
  commissionHeading = {
    title: 'Commission List',
    description: ''
  }
  /**
   * Details for list component
   */
  columnsForReferral = [
    { column: 'referralCode', type: 'Text', suffixText: 'Referral Code' },
    { column: 'userCount', type: 'Chip', suffixText: 'Users Count', backgroundColor: 'rgb(158, 52, 117)', classCondition: { '0 User(s)': "nonValueChip" } },
    { column: 'isActive', type: 'Toggle', sort: false, suffixText: 'Status', iconCondition: { true: 'done', false: 'close' } },

  ];
  /**
   * used to display filter options
   */
  filterOption = ['Active', 'Inactive']
  /**
   * filter details
   */
  actions = [
    { name: 'filter_list', value: 'filter', toolTip: 'Filter', isConditionApplied: true, conditionType: 'DATA_EMPTY' },
  ];

  /**
   * filter contents
   */
  referralCodeListFilter = [
    { title: "Status", type: "radio", field: "isActive", options: this.filterOption },
  ];
  /**
   * List icons for referral code page
   */
  actionIcons = [
    { iconName: 'content_copy', method: 'onCopy', toolTip: 'Copy Referral', iconColor: '#464646' },
    { iconName: 'supervisor_account', method: 'viewCustomersDetails', toolTip: 'View Customer List', iconColor: '#000099' },
  ];
  /**
   * Details about the type
   */
  dialogType = { alert: 'alert', confirmation: 'confirmation', information: 'information', success: 'success', failure: 'failure' };
  /**
   * Error messages for Referral code page
   */
  errorMessages = {
    'REFERRAL_CODE_REQUIRED': 'Referral code is required',
    'REFERRAL_CODE_MAX_LIMIT': 'Referral code should not exceed 20 characters',
    'REFERRAL_CODE_MIN_LIMIT': 'Referral code should not be less than 6 characters',
    'REFERRAL_CODE_ALREADYEXISTS': 'Referral code already exists'
  }
  /**
   * Dialog messages for Referral code page
   */
  dialogMessages = {
    'referralDelete': 'Are you sure you want to delete this referral?',
    'referralDeleteFailure': 'Failed to delete referralCode',
    'referralCodeFailure': 'Failed to get referral code data.',
    'deleteReferral': 'Failed to delete referral Code',
    'referralSave': 'Failed to save referral code details',
    'referralDialogClose': 'You have unsaved changes. Are you sure you want to close this dialog?',
    'failedCustomerDetails': "Failed to load customers details",
    'failedUserDetails': 'Failed to get user details',
    'failedParamDetails': 'Cannot get customer id details',
    'failedCommissionDetails': 'Cannot get commission details',
    'copyReferralCode': 'Referral code copied',

  }
  /**
   * Snackbar messages for referral code page
   */
  snackbarMessages = {
    'REFERRAL_COPIED': 'Referral code copied',
    'successDeactivate': 'Code deactivated successfully.',
    'failureDeactivate': 'Code deactivation failed.',
    'successActivate': 'Code activated successfully.',
    'failureActivate': 'Code activation failed.',
  }
  /**
   * Details for customer list component
   */
  customersDetails = [
    { column: 'Name', suffixText: 'Name', type: 'Text' },
    { column: 'Email', suffixText: 'Email', type: 'Text' },
    { column: 'CommissionAmount', suffixText: 'Commission Amount', type: 'Text' },
    { column: 'ContactNumber', suffixText: 'Contact Number', type: 'Text' },
  ];
  /**
   * List icons for referral customer list page
   */
  actionIcons1 = [
    { iconName: 'visibility', method: 'onView', toolTip: 'View Commission Details', iconColor: 'red' }
  ];
  /**
   * Details for commission list component
   */
  commissionDetails = [
    { column: 'PlanName', suffixText: 'Plan Name', type: 'Text' },
    { column: 'PlanAmount', suffixText: 'Plan Amount', type: 'Text' },
    { column: 'amount', suffixText: 'Amount', type: 'Text' },
    { column: 'date', suffixText: 'Date', type: 'Text' },
  ]
}
