/**
 * Component which is display Online visitors notification in storefront.
 * AUTHOR: Surya T - CEN254
 */
import { Component, HostListener, HostBinding } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { DomSanitizer } from '@angular/platform-browser';
import { OnlineVisitorsCount, OnlineVisitorsData, GetOnlineVisitorsData, Route } from '../../models/live-notification.model'
import { SharedService } from '@phase-ii/shared-theme';
import { LoadTranslationService } from '@phase-ii/common';

/**
 * Component which is display Online visitors notification in storefront.
 */
@Component({
  selector: 'phase-ii-display-online-visitors-notification',
  templateUrl: './display-online-visitors-notification.component.html',
  styleUrls: ['./display-online-visitors-notification.component.scss'],
})
export class DisplayOnlineVisitorsNotificationComponent {
  /**
   * Variable which is used to handle the http call and make it to unsubscribe.
   * @type {Subscription}
   */
  subscriptionObj: Subscription = new Subscription();
  /**
   * Variable which is used to store the storeId.
   * @type {number}
   */
  storeId: number;
  /**
   * Variable which is used to store the onlinevisitors response data.
   */
  notificationData: GetOnlineVisitorsData;
  /**
   * Variable which is used to store the onlinevisitors count data.
   * @type {number}
   */
  visitorsCount: number = 0;
  /**
   * Variables which is used to store the display message for notification.
   * @type {any}
   */
  displayNotificationMessage: any;
  displayNotificationMessage2: any;
  /**
   * Variable which is used to store the current route.
   * @type {String}
   */
  currentRoute: String;
  /**
   * Variables which is used to set initial timeout for displaying notification.
   * @type {NodeJS.Timeout}
   */
  initialTimeOut: NodeJS.Timeout;
  /**
   * Variables which is used to set closeNotification timeout for displaying notification.
   * @type {NodeJS.Timeout}
   */
  closeNotificationTimeOut: NodeJS.Timeout;
  /**
   * Variable which is used to display online visitors notification.
   * @type {NodeJS.Timeout}
   */
  displayOnlineVisitors: NodeJS.Timeout;
  /**
   * Variable which is used to display next notification.
   * @type {NodeJS.Timeout}
   */
  displayNext: NodeJS.Timeout;
  /**
   * Variable which is used to count displayed notification.
   * @type {number}
   */
  dispNotificationCount: number = 0;
  /**
   * Variable which is used to check the whether the notification can be diplayed or not.
   * @type {boolean}
   */
  isDisplayNotification: boolean = false;
  /**
   * Variable which is used to check the notification can be diplayed in current page.
   * @type {Array}
   */
  specificPages: Array<any>;
  /**
   * Variable which is used to allow the notification.
   * @type {boolean}
   */
  allowNotification: boolean = false;
  /**
   * Variable which is used to store timing details for notification.
   */
  timingDetails: { initialDelay: number; maximumNotification: number; delayNotification: number; displayTime: number; };
  /**
   * Variable which is used to check notification enabled for this store.
   * @type {boolean}
   */
  isEnabled: boolean = false;
  /**
   * Variable which is used to store details of desktop and mobile view.
   */
  deviceView: { mobileView: any; desktopView: any; };
  /**
   * Variable which is used check the current device width.
   * @type {string}
   */
  display: string = null;
  /**
   * Variable which is used show the notification based on desktop and mobile view enabled.
   * @type {boolean}
   */
  allowNotificationBySize: boolean;
  /**
   * Variable which is used store animation for notification.
   */
  slideAnimation: { slideIn: string; slideOut: string; } = null;
  /**
   * Variable which is used store class name for notification.
   * @type {string}
   */
  onlineVisitorsNotify: string;
  /**
   * Variable which is used store closeicon class name for notification.
   * @type {string}
   */
  closeIconClass: string;
  /**
   * Variable which is used store slideout class name for notification.
   * @type {string}
   */
  slideOutClass: string;
  /**
   * Variable which is used store slidein class name for notification.
   * @type {string}
   */
  slideInClass: string;
  /**
   * Variable which is used to store language code.
   */
  languageCode: string;
  /**
   * This css variable is used to store the left pixels based on the screen size and animation type.
   *  @type {string}
   */
  @HostBinding('style.--dscLeft') dscLeft: string;
  /**
   * This css variable is used to store the bottom pixels based on the screen size and animation type and enabled status of online visitors notification.
   *  @type {string}
   */
  @HostBinding('style.--dscBottom') dscBottom: string;
  /**
   * This css variable is used to store the opacity based on the screen size and animation type.
   *  @type {number}
   */
  @HostBinding('style.--dscOpacity') dscOpacity: number;
  /**
   * This css variable is used to store the visibility based on the screen size and animation type.
   *  @type {string}
   */
  @HostBinding('style.--dscVisibility') dscVisibility: string = 'visible';
  /**
   * This css variable is used to store the animation pixel for mobile.
   *  @type {string}
   */
  @HostBinding('style.--slideRightAnimation') slideRightAnimation: string;
  /**
   * Component constructor to inject the required services.
   * @param sharedService To access methods from shared service.
   * @param router To navigate to routes.
   * @param sanitizer To access methods inside DomSanitizer.
   * @param translationService To access methods from translation service
   */
  constructor(
    private router: Router,
    private sharedService: SharedService,
    private sanitizer: DomSanitizer,
    private translationService: LoadTranslationService,

  ) {
  }
  /**
   * Angular life cycle hook 
   */
  ngOnInit(): void {
    this.storeId = this.sharedService && this.sharedService.storeId;
    this.languageCode = localStorage?.getItem('language') ?? 'en';
    this.subscriptionObj.add(this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe((route: Route) => {
      if (route) {
        this.currentRoute = route?.url;
        this.checkShowablePages(true);
        // this.processResData();
      }
    }));
    this.subscriptionObj.add(this.sharedService.getOnlineVisitorsNotification(this.storeId).subscribe((res: OnlineVisitorsData) => {
      if (res && res.onlineVisitorsNotification && res.onlineVisitorsNotification.isEnabled) {
        this.isEnabled = res.onlineVisitorsNotification.isEnabled;
        this.notificationData = res.onlineVisitorsNotification;
        this.displayNotificationMessage2 = this.notificationData && (this.notificationData.message ?? "{number_people} people visited in last {time} {format}");
        this.specificPages = this.notificationData && this.notificationData.specificPages && this.notificationData.specificPages.length ? this.notificationData.specificPages : ['home', 'products', 'productdetails', 'cart'];
        this.deviceView = {
          desktopView: this.notificationData && (this.notificationData.desktopView ?? null),
          mobileView: this.notificationData && (this.notificationData.mobileView ?? null)
        };
        if (this.notificationData && this.notificationData.timingDetails) {
          this.timingDetails = {
            initialDelay: this.notificationData.timingDetails.initialDelay ?? 3,
            displayTime: this.notificationData.timingDetails.displayTime ?? 6,
            delayNotification: this.notificationData.timingDetails.delayNotification ?? 5,
            maximumNotification: this.notificationData.timingDetails.maximumNotification ?? 20
          };
        }
        this.checkShowablePages();
        this.onResize();
        this.processResData();
      }
    }));
  }
  /**
   * This function is used to check notification can be allowed in current page and device and call the visitors count api.
   */
  processResData(): void {
    if (this.allowNotification && this.allowNotificationBySize) {
      this.getOnlineVisitorsCount();
    }
  }
  /**
   * This function is used to get the visitors count if automatic is enabled else manual count will be applied.
   */
  getOnlineVisitorsCount(): void {
    let data = {
      name: 'GOOGLE ANALYTICS',
      date: this.notificationData && this.notificationData.lookBackTime && this.notificationData.timeFormat == 'DAYS' ? this.notificationData.lookBackTime : null,
      hours: this.notificationData && this.notificationData.lookBackTime && this.notificationData.timeFormat == 'HOURS' ? this.notificationData.lookBackTime : null,
      weeks: this.notificationData && this.notificationData.lookBackTime && this.notificationData.timeFormat == 'WEEKS' ? this.notificationData.lookBackTime : null,
      minutes: this.notificationData && this.notificationData.lookBackTime && this.notificationData.timeFormat == 'MINUTES' ? this.notificationData.lookBackTime : null,
    }
    if (this.notificationData && this.notificationData.isAutomatic) {
      this.subscriptionObj.add(this.sharedService.getOnlineVisitorsCount(this.storeId, data).subscribe((res: OnlineVisitorsCount) => {
        if (res && res.onlineVisitorsCount) {
          // this.visitorsCount = res.onlineVisitorsCount.activeUsers ?? (res.onlineVisitorsCount.visitorDetails && res.onlineVisitorsCount.visitorDetails.totalUsers && (res.onlineVisitorsCount.visitorDetails.totalUsers ?? 0));
          this.visitorsCount = res.onlineVisitorsCount ?? 0;
          this.displayMessage();
        }
      }))
    }
    else {
      this.visitorsCount = this.notificationData && (this.notificationData.noOfVisitors ?? 0);
      this.displayMessage();

    }
  }
  /**
   * This function is used to set the display message for notification and further call the show notification for displaying .
   */
  displayMessage(): void {

    this.displayNotificationMessage =
      this.displayNotificationMessage2.replaceAll('{number_people}', `<span style="color: var(--primary-color);font-weight:bold">${this.visitorsCount}</span>`)
        .replaceAll('{time}', `<span style="color: var(--primary-color);font-weight:bold">${this.notificationData.lookBackTime}</span>`)
        .replaceAll('{format}', `<span style="color: var(--primary-color);font-weight:bold">${this.translationService.getTranslation(`SHARED.${this.notificationData.timeFormat}`)}</span>`).toLowerCase();


    this.displayNotificationMessage = this.sanitizer.bypassSecurityTrustHtml(this.displayNotificationMessage);

    if (this.visitorsCount) {
      this.initialTimeOut = setTimeout(() => {
        this.isDisplayNotification = true;
        this.showNotification();
      }, this.timingDetails.initialDelay * 1000);
    }
  }
  /**
   * This function is used to check the notification can be displayed in current page .
   */
  checkShowablePages(event?: boolean): void {
    this.allowNotification = false;
    if (this.currentRoute) {
      if (this.specificPages?.length) {
        this.specificPages.forEach((item: string) => {
          if (item) {
            let result = this.currentRoute.split('/')[((this.currentRoute.split('/').length) - 1)].includes(item) &&
              !(this.currentRoute.split('/')[((this.currentRoute.split('/').length) - 1)].includes('type=cart'));
            if (result) {
              this.allowNotification = result;
            }
          }
        })
      }
    }
    else {
      if (this.specificPages?.length) {
        this.specificPages.forEach((item: string) => {
          if (item && this.router && this.router.url) {
            let result = this.router.url.split('/')[((this.router.url.split('/').length) - 1)].includes(item) &&
              !(this.router.url.split('/')[((this.router.url.split('/').length) - 1)].includes('type=cart'));
            if (result) {
              this.allowNotification = result;
            }
          }
        })
      }
    }
    if (event && this.visitorsCount >= 0) {
      this.closeNotification();
      if (this.allowNotification) {
        this.dispNotificationCount = 0;
        this.processResData();
      }
    }
  }
  /**
   * This function is used to close the notification and once it is closed it will be invoked after an hour.
   */
  closeNotification(event?: any): void {
    clearTimeout(this.initialTimeOut);
    clearTimeout(this.displayOnlineVisitors);
    clearTimeout(this.displayNext);
    document.getElementById('onlineVisitorsNotifyId') && document.getElementById('onlineVisitorsNotifyId').classList && document.getElementById('onlineVisitorsNotifyId').classList.remove(this.slideAnimation.slideIn);
    document.getElementById('onlineVisitorsNotifyId') && document.getElementById('onlineVisitorsNotifyId').classList && document.getElementById('onlineVisitorsNotifyId').classList.add(this.slideAnimation.slideOut);
    if (event) {
      this.closeNotificationTimeOut = setTimeout(() => {
        this.checkShowablePages();
        this.dispNotificationCount = 0;
        this.processResData();
      }, 3600000);
    }
  }
  /**
   * This function is used to show the notification based on maximum notification for a page is given.
   */
  showNotification(): void {
    ++this.dispNotificationCount;
    document.getElementById('onlineVisitorsNotifyId') && document.getElementById('onlineVisitorsNotifyId').classList && document.getElementById('onlineVisitorsNotifyId').classList.remove(this.slideAnimation.slideOut);
    document.getElementById('onlineVisitorsNotifyId') && document.getElementById('onlineVisitorsNotifyId').classList && document.getElementById('onlineVisitorsNotifyId').classList.add(this.slideAnimation.slideIn);
    this.displayOnlineVisitors = setTimeout(() => {
      document.getElementById('onlineVisitorsNotifyId') && document.getElementById('onlineVisitorsNotifyId').classList && document.getElementById('onlineVisitorsNotifyId').classList.remove(this.slideAnimation.slideIn);
      document.getElementById('onlineVisitorsNotifyId') && document.getElementById('onlineVisitorsNotifyId').classList && document.getElementById('onlineVisitorsNotifyId').classList.add(this.slideAnimation.slideOut);
      this.displayNext = setTimeout(() => {
        if (this.timingDetails && (this.timingDetails.maximumNotification > this.dispNotificationCount)) {
          this.showNotification();
        }
        else {
          clearTimeout(this.displayOnlineVisitors);
          clearTimeout(this.displayNext);
        }
      }, this.timingDetails.delayNotification * 1000);
    }, this.timingDetails.displayTime * 1000);
  }
  /**
   * This function is used to change the notification animation based on the screen size.
   * @param event contains data about the screen size.
   */
  @HostListener("window:resize", ['$event'])
  onResize(event?: { target: { innerWidth: any; } }): void {
    const innerWidth = event && event.target && event.target.innerWidth ? event.target.innerWidth : window.innerWidth;
    const display = this.display;
    if (innerWidth <= 600) {
      this.allowNotificationBySize = this.deviceView?.mobileView != null ? true : false;
      this.display = 'MOBILE';
    }
    else {
      this.allowNotificationBySize = this.deviceView?.desktopView != null ? true : false;
      this.display = 'DESKTOP';
    }
    this.changeClassStyle();
    if (this.display != display && event) {
      this.closeNotification();
      this.dispNotificationCount = 0;
      this.processResData();
    }
  }
  /**
   * This function is used to process all the css data based on screen size and animation type.
   */
  changeClassStyle(): void {
    if (this.onlineVisitorsNotify) {
      if (document.getElementById('onlineVisitorsNotifyId')) {
        document.getElementById('onlineVisitorsNotifyId').classList.remove(this.slideAnimation.slideIn);
        document.getElementById('onlineVisitorsNotifyId').classList.remove(this.slideAnimation.slideOut);
      }
    }
    if (this.allowNotificationBySize && this.display == 'DESKTOP') {
      if (this.deviceView && this.deviceView.desktopView && this.deviceView.desktopView.desktopAnimation == "Slide from Left") {
        this.slideAnimation = {
          slideIn: 'slideRightDesk',
          slideOut: 'slideLeftDesk'
        }
        this.closeIconClass = 'closeIconDesk';
        this.onlineVisitorsNotify = "ovdesktop"
        this.dscLeft = '-860px';
        this.dscBottom = '20px';
        this.dscOpacity = 2;
        this.dscVisibility = 'visible';
      }
      else if (this.deviceView && this.deviceView.desktopView && this.deviceView.desktopView.desktopAnimation == "Slide from Bottom") {
        this.slideAnimation = {
          slideIn: 'slideUp',
          slideOut: 'slideDown'
        }
        this.closeIconClass = 'closeIconDesk';
        this.onlineVisitorsNotify = "ovdesktop"
        this.dscLeft = '10px';
        this.dscBottom = '-430px';
        this.slideInClass = 'slideUp';
        this.slideOutClass = 'slideDown';
        this.dscOpacity = 2;
        this.dscVisibility = 'visible';
      }
      else {
        this.slideAnimation = {
          slideIn: 'fadeIn',
          slideOut: 'fadeOut'
        }
        this.closeIconClass = 'closeIconDesk';
        this.onlineVisitorsNotify = "ovdesktop"
        this.dscLeft = '10px';
        this.dscBottom = '20px';
        this.slideInClass = 'fadeIn';
        this.slideOutClass = 'fadeOut';
        this.dscOpacity = 0;
        this.dscVisibility = 'hidden';
      }
    }
    else if (this.allowNotificationBySize && this.display == 'MOBILE') {

      if (this.deviceView && this.deviceView.mobileView && this.deviceView.mobileView.mobileAnimation == "Slide from Left") {
        this.slideAnimation = {
          slideIn: 'slideRightMob',
          slideOut: 'slideLeftMob'
        }
        this.closeIconClass = 'closeIconMob';
        this.onlineVisitorsNotify = "ovmobile"
        this.dscLeft = '-860px';
        this.dscBottom = '20px';
        this.dscOpacity = 2;
        this.dscVisibility = 'visible';
        this.slideRightAnimation = this.notificationData && (this.notificationData.isCloseNotification ? '-12px' : '0px');
      }
      else if (this.deviceView && this.deviceView.mobileView && this.deviceView.mobileView.mobileAnimation == "Slide from Bottom") {
        this.slideAnimation = {
          slideIn: 'slideUp',
          slideOut: 'slideDown'
        }
        this.closeIconClass = 'closeIconMob';
        this.onlineVisitorsNotify = "ovmobile"
        this.dscBottom = '-430px';
        this.dscLeft = this.notificationData && (this.notificationData.isCloseNotification ? '-12px' : '0px');
        this.dscOpacity = 2;
        this.dscVisibility = 'visible';

      }
      else {
        this.slideAnimation = {
          slideIn: 'fadeIn',
          slideOut: 'fadeOut'
        }
        this.closeIconClass = 'closeIconMob';
        this.onlineVisitorsNotify = "ovmobile"
        this.dscLeft = this.notificationData && (this.notificationData.isCloseNotification ? '-12px' : '0px');
        this.dscBottom = '20px';
        this.dscOpacity = 0;
        this.dscVisibility = 'hidden';
      }
    }
  }
  /**
   * Angular life cycle hook 
   */
  ngOnDestroy(): void {
    if (this.subscriptionObj) {
      this.subscriptionObj.unsubscribe();
    }
  }
}