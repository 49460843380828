<cen-loader-vii *ngIf="isLoading">
</cen-loader-vii>
<div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="6%" fxLayout.lt-md="column" fxLayoutAlign.lt-md="center">
    <div fxFlex="50%" fxLayout="column" fxLayoutGap="30px" fxHide.lt-md
        *ngIf="storeDetails?.purchasedCurrency?.currencyUnit === 'INR' && !selectedPlanDetails?.isMobilePage">
        <div fxLayout="row" fxLayoutAlign=" end">
            <div class="image-container">
                <img src="assets/zenbasketlogo.png" alt="logo">

            </div>
            <div fxLayout="column" class="subscription-heading-container">
                <h1 class="plan-heading">Rethinking Ecommerce for you!</h1>
                <p class="plan-description">ZenBasket is your one-stop solution to run your business at a very
                    affordable price.
                </p>
            </div>
        </div>
        <span data-control="selectedPlan">You have selected &nbsp;<strong>"{{selectedPlanDetails?.PlanName}}"</strong>.&nbsp;These are the features you will get for this plan,</span>
        <table *ngIf="dataSource" mat-table [dataSource]="dataSource" class="mat-elevation-z8" aria-describedby="features">
            <ng-container matColumnDef="name">
                <th mat-header-cell hidden *matHeaderCellDef></th>
                <td mat-cell *matCellDef="let element">
                    {{element.name}}
                </td>
            </ng-container>
            <ng-container matColumnDef="value">
                <th mat-header-cell hidden *matHeaderCellDef></th>
                <td mat-cell *matCellDef="let element">
                    <div *ngIf="element?.type === 'boolean' && element?.value ">
                        <mat-icon [ngClass]="{'active-class':element?.value}" fontIcon="check"></mat-icon>
                    </div>
                    <div *ngIf="element?.type === 'number' && element?.value > 0 ">
                        <h3 class="column-heading">{{element?.value}}</h3>
                    </div>
                    <div *ngIf="element?.type === 'number' && element?.value === -1 ">
                        <h3 class="column-heading">Unlimited</h3>
                    </div>
                </td>
            </ng-container>

            <tr mat-row *matRowDef="let row; columns: displayColumns;"></tr>
        </table>
    </div>
    <div fxLayout.lt-md="column" fxLayout="row"
        [fxFlex]="storeDetails?.purchasedCurrency?.currencyUnit === 'INR' ? (!fromUpdatePlan ? (this.selectedPlanDetails.isMobilePage ? '60%' : '30%') : '400px'):'100%'  "
        fxFlex.lt-md="100%">
        <div fxLayout="column" [fxFlex]="storeDetails?.purchasedCurrency?.currencyUnit !== 'INR' ? '30%':'100%' "
            class="outer-card payment-details-container"
            [fxFlex.lt-md]="storeDetails?.purchasedCurrency?.currencyUnit !== 'INR' ? '100%' : '80%'"
            [fxFlex.lt-md]="'100%'" mat-elevation-z11>
            <div class="invoice-container" fxLayout="row" [fxFlex]="!fromUpdatePlan ? '15%': 'auto'"
                fxLayoutAlign="center center">
                <span class="invoice">Payment Details</span>
            </div>
            <mat-divider class="line1"></mat-divider>
            <br />
            <div fxLayout="column" fxFlex="100%" class="plan-details">
                <div class="plan-details-container" fxLayout="row" fxLayoutAlign="center ">
                    <div fxLayout="column" fxFlex="75%" class="plan-details-wrapper"
                        [fxFlex]="storeDetails?.purchasedCurrency?.currencyUnit === 'INR' ? '95%':'100%' "
                        fxFlex.lt-md="100%">
                        <div data-control="planName">
                            <p fxFlex="50" class="invoice-title"> Plan Name </p>
                            <p fxFlex="50" class="plan-value">{{selectedPlanDetails?.PlanName}}</p>
                        </div>
                        <div data-control="planType">
                            <p fxFlex="50" class="invoice-title"> Plan Type </p>
                            <p fxFlex="50" class="plan-value">{{selectedPlanDetails?.FrequencyType}}</p>
                        </div>
                        <div data-control="planAmount">
                            <p fxFlex="50" class="invoice-title"> Plan Amount </p>
                            <p fxFlex="50" class="plan-value">
                                {{storeDetails?.purchasedCurrency?.currencySymbol}}{{(selectedPlanDetails?.Price ?
                                selectedPlanDetails?.Price:0)|number :'1.2-2'}}</p>
                        </div>
                        <div *ngIf="selectedPlanDetails?.isMobilePage" data-control="planRenewal">
                            <p fxFlex="50" class="invoice-title"> Renewal Percentage </p>
                            <p fxFlex="50" class="plan-value">{{(selectedPlanDetails?.renewalPercentage ?
                                selectedPlanDetails?.renewalPercentage +'%': 0)}}</p>
                        </div>
                        <div *ngIf="selectedPlanDetails?.DiscountAmount" data-control="discountAmount">
                            <p fxFlex="50" class="invoice-title"> Discount</p>
                            <p fxFlex="50" class="plan-value">
                                -{{storeDetails?.purchasedCurrency?.currencySymbol}}{{selectedPlanDetails?.DiscountAmount|number
                                :'1.2-2'}}</p>
                        </div>
                        <mat-divider *ngIf="selectedPlanDetails?.DiscountAmount"></mat-divider>
                        <div *ngIf="selectedPlanDetails?.DiscountAmount" data-control="discountAmountTotal">
                            <p fxFlex="50" class="invoice-title"> Total Amount</p>
                            <p fxFlex="50" class="plan-value">
                                {{storeDetails?.purchasedCurrency?.currencySymbol}}{{(selectedPlanDetails?.DiscountAmount
                                ?
                                selectedPlanDetails?.DiscountedPlanAmount: selectedPlanDetails?.Price)|number :'1.2-2'}}
                            </p>
                        </div>
                        <div *ngIf="selectedPlanDetails?.PluginAmount" data-control="pluginAmount">
                            <p fxFlex="50" class="invoice-title"> Plugin Amount </p>
                            <p fxFlex="50" class="plan-value">
                                {{storeDetails?.purchasedCurrency?.currencySymbol}}{{(selectedPlanDetails?.PluginAmount
                                ? selectedPlanDetails?.PluginAmount:0)|number :'1.2-2'}}</p>
                        </div>
                        <div *ngIf="selectedPlanDetails?.SetUpCost" data-control="setupCost">
                            <p fxFlex="50" class="invoice-title"> Set up Cost </p>
                            <p fxFlex="50" class="plan-value">
                                {{storeDetails?.purchasedCurrency?.currencySymbol}}{{(selectedPlanDetails?.SetUpCost ?
                                selectedPlanDetails?.SetUpCost:0)|number :'1.2-2'}}</p>
                        </div>
                        <div *ngIf="selectedPlanDetails?.AdditionalCharge && selectedPlanDetails?.IsAdditionalCharge"
                            data-control="additionalCharge">
                            <p fxFlex="50" class="invoice-title"> {{selectedPlanDetails?.AdditionalChargeLabel}} </p>
                            <p fxFlex="50" class="plan-value">
                                {{storeDetails?.purchasedCurrency?.currencySymbol}}{{(selectedPlanDetails?.AdditionalCharge
                                ?
                                selectedPlanDetails?.AdditionalCharge:0)|number :'1.2-2'}}</p>
                        </div>
                        <ng-container *ngIf="selectedPlanDetails?.taxDetails?.length > 0">
                            <div *ngFor="let tax of selectedPlanDetails?.taxDetails" [atrr.data-control]="tax?.taxName">
                                <p fxFlex="50" class="invoice-title"> {{tax?.taxName}} ({{tax?.taxValue}}%)
                                </p>
                                <p fxFlex="50" class="plan-value">
                                    {{storeDetails?.purchasedCurrency?.currencySymbol}}{{tax?.taxAmount | number
                                    :'1.2-2'}}</p>
                            </div>
                        </ng-container>
                        <div *ngIf="selectedPlanDetails?.UpgradeDiscountAmount" data-control="existingPlanAmount">
                            <p fxFlex="50" class="invoice-title"> Existing Plan Amount </p>
                            <p fxFlex="50" class="plan-value">
                                {{storeDetails?.purchasedCurrency?.currencySymbol}}{{(selectedPlanDetails?.UpgradeDiscountAmount
                                ?
                                selectedPlanDetails?.UpgradeDiscountAmount:0)|number :'1.2-2'}}</p>
                        </div>
                        <div *ngIf="(selectedPlanDetails.DiscountCode && selectedPlanDetails?.DiscountAmount) || (!selectedPlanDetails.DiscountCode && !selectedPlanDetails?.DiscountAmount)"
                            fxLayout="column" fxLayoutGap.lt-md="10px" fxLayoutAlign.lt-md="center none"
                            class="discount-container">
                            <br>
                            <div *ngIf="!selectedPlanDetails?.DiscountCode" fxLayout="row" class="full-width">
                                <input placeholder="Coupon Code" data-control="couponCode" class="discount-input"
                                    [(ngModel)]="couponCode" [value]="couponCode"
                                    (change)="$event?.target?.value ?couponCode=$event?.target?.value:couponCode=''"
                                    type="text" (keydown.enter)="checkDiscountExists(couponCode)"
                                    [disabled]="isButtonLoading || isLoading">
                                <button data-control="checkCouponCode"
                                    [disabled]="!couponCode || isButtonLoading || isLoading"
                                    (click)="checkDiscountExists(couponCode)" class="apply-button">APPLY</button>
                            </div>
                            <div *ngIf="selectedPlanDetails?.DiscountCode" fxLayout="row">
                                <div class="cancel-coupon"><strong>{{couponCode}}</strong></div>
                                <div fxLayoutAlign="end end"><button data-control="removeCouponCode"
                                        [disabled]="isButtonLoading || isLoading" class="remove-applied-discount"
                                        (click)="checkDiscountExists()"><mat-icon fontIcon="close"></mat-icon></button>
                                </div>
                            </div><br>
                        </div>
                    </div>
                </div>

                <mat-divider class="line">
                </mat-divider>
                <div fxLayout="row" fxLayoutAlign="center ">
                    <div fxLayout="column"
                        [fxFlex]="storeDetails?.purchasedCurrency?.currencyUnit === 'INR' ? '95%':'100%' "
                        fxFlex.lt-md="100%">
                        <div data-control="amountToPay">
                            <p fxFlex="50" class="invoice-title"> Amount to pay </p>
                            <p fxFlex="50" class="plan-value">
                                {{storeDetails?.purchasedCurrency?.currencySymbol}}{{(selectedPlanDetails?.currentAmount
                                ? selectedPlanDetails?.currentAmount:0)|number :'1.2-2'}}</p>
                        </div>
                        <div fxLayout="column" fxLayoutAlign="center center"
                            *ngIf="storeDetails?.purchasedCurrency?.currencyUnit === 'INR'">
                            <br>
                            <button mat-flat-button class="primary-button payButton" type="button"
                                [disabled]="isButtonLoading || isLoading" (click)="createOrderInRazorpay()">
                                <ng-container *ngIf="!isButtonLoading">
                                    Pay
                                </ng-container>
                                <cen-loader class="cen-spinner" *ngIf="isButtonLoading" [type]="'content'"
                                    [name]="'cen-dot-spinner'">
                                </cen-loader>
                            </button>
                            <br>
                        </div>
                    </div>
                </div>
            </div>
            <mat-divider *ngIf="!selectedPlanDetails?.isMobilePage" class="line1"></mat-divider>
            <br>
            <div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="16px" class="browse-plans-text"
                *ngIf="!selectedPlanDetails?.isMobilePage" data-control="browsePlansText">
                <span>Not sure whether this plan suits you?</span>
                <mat-chip-listbox data-control="backToPlans" [disabled]="isButtonLoading || isLoading">
                    <mat-chip-option class="backToPlans" [disabled]="isButtonLoading || isLoading"
                        (click)="navigateToPlans()"><span fxLayoutAlign="center center">Browse All
                            Plans</span></mat-chip-option>
                </mat-chip-listbox>
            </div>
        </div>
        <div fxFlex="68%" fxFlex.lt-md="100" mat-elevation-z11 class="outer-card card-container"
            *ngIf="storeDetails?.purchasedCurrency?.currencyUnit !== 'INR'"
            [fxHide]="!selectedPlanDetails?.currentAmount" fxLayout="column">
            <div fxLayout="row" *ngIf="!isLoading">
                <h2 class="heading">{{paymentType === constants?.paymentOption?.PAYPAL ? 'Payment Options'
                    : 'Billing Information'}}
                </h2>
                <span fxFlex></span>
                <div fxLayoutAlign="none center">
                    <button mat-raised-button class="primary-button" *ngIf="!isEdit && customerCardDetails"
                        type="button" (click)="editCard()" data-control="cardEdit"> Edit </button>
                </div>
            </div>
            <div *ngIf="!isEdit && customerCardDetails && !isLoading && selectedPlanDetails?.currentAmount>0"
                data-control="savedCardContainer">
                <div data-control="customerName">
                    <p fxFlex="40" class="invoice-title"> Card Holder Name </p>
                    <p fxFlex=5>:</p>
                    <p fxFlex="55">{{customerCardDetails?.name ? customerCardDetails?.name : '-'}}</p>
                </div>
                <div data-control="cardNumber">
                    <p fxFlex="40" class="invoice-title"> Card Number </p>
                    <p fxFlex=5>:</p>
                    <p fxFlex="55">**** **** **** {{customerCardDetails?.last4}}</p>
                </div>
                <div data-control="cvc">
                    <p fxFlex="40" class="invoice-title"> CVC </p>
                    <p fxFlex=5>:</p>
                    <p fxFlex="55">***</p>
                </div>
                <div data-control="expiryDate">
                    <p fxFlex="40" class="invoice-title"> Expired on </p>
                    <p fxFlex=5>:</p>
                    <p fxFlex="55">{{customerCardDetails?.exp_month}} / {{customerCardDetails?.exp_year}}</p>
                </div>
            </div>
            <div id="cardInfoGroup" data-control="stripeCard">
            </div>
            <form
                *ngIf="(isEdit || !customerCardDetails) && paymentType===constants?.paymentOption?.CHECKOUT && !isLoading"
                id="payment-form">
                <div class="one-liner">
                    <div class="card-frame"></div>
                </div>
                <p class="error-message"></p>
            </form>
            <div class="paypal-button" data-control="paypalButton" fxLayoutAlign="center none"
                *ngIf="paymentType===constants?.paymentOption?.PAYPAL && !isLoading">
                <ngx-paypal [config]="payPalConfig" class="paypal-container"></ngx-paypal>
                <br>
            </div>
            <form [formGroup]="billingInfo"
                *ngIf="(isEdit || !customerCardDetails) && !isLoading && selectedPlanDetails?.currentAmount>0 && paymentType !== constants?.paymentOption?.PAYPAL">
                <mat-form-field class="top-space full-width" appearance="outline" *ngIf="billingInfo?.get('name')">
                    <mat-label>Name on Card</mat-label>
                    <input matInput placeholder="Name On Card" type="text" size="20" autocomplete="off"
                        formControlName="name" required data-control="cardName" trimVal />
                    <mat-error *ngIf="billingInfo?.get('name')?.errors && billingInfo?.get('name')?.touched">
                        <span *ngFor="let k of billingInfo?.get('name')?.errors | keyvalue"
                            [attr.data-control]="'card name '+ k?.key | dataControl">
                            {{ (errorMessage?.ERROR)['NAME'][k.key] }}</span>
                    </mat-error>
                </mat-form-field>
                <h2 class="heading">Billing Address</h2>
                <div fxLayout="row" fxLayoutGap="15px" fxLayoutGap.xs='0' fxLayout.xs="column" class="billing-address">
                    <mat-form-field fxFlex="38%" appearance="outline" *ngIf="billingInfo?.get('streetName')">
                        <mat-label>Address Line 1</mat-label>
                        <input matInput placeholder="Address Line 1" required type="text" size="20" autocomplete="off"
                            formControlName="streetName" data-control="addressLineOne" trimVal />
                        <mat-error
                            *ngIf="billingInfo?.get('streetName')?.errors &&billingInfo?.get('streetName')?.touched">
                            <span *ngFor="let k of billingInfo?.get('streetName')?.errors | keyvalue"
                                [attr.data-control]="'address line one '+ k?.key | dataControl">
                                {{ (errorMessage?.ERROR)['STREET_NAME'][k.key] }}</span>
                        </mat-error>
                    </mat-form-field>
                    <mat-form-field fxFlex="25%" appearance="outline" *ngIf="billingInfo?.get('doorNo')">
                        <mat-label>Address Line 2</mat-label>
                        <input matInput placeholder="Address Line 2" type="text" size="20" autocomplete="off"
                            formControlName="doorNo" data-control="addressLineTwo" trimVal />
                        <mat-error *ngIf="billingInfo?.get('doorNo')?.errors &&billingInfo?.get('doorNo')?.touched"
                            data-control="addressLineTwoValidError">
                            Please enter a valid address.
                        </mat-error>
                    </mat-form-field>
                    <mat-form-field fxFlex="31%" appearance="outline" *ngIf="billingInfo?.get('city')">
                        <mat-label>City</mat-label>
                        <input matInput placeholder="City" type="text" size="20" autocomplete="off"
                            formControlName="city" required data-control="city" trimVal />
                        <mat-error *ngIf="billingInfo?.get('city')?.errors &&billingInfo?.get('city')?.touched">
                            <span *ngFor="let k of billingInfo?.get('city')?.errors | keyvalue"
                                [attr.data-control]="'city '+k?.key | dataControl">
                                {{ (errorMessage?.ERROR)['CITY'][k.key] }}</span>
                        </mat-error>
                    </mat-form-field>
                </div>
                <div fxLayout="row" fxLayoutGap="15px" fxLayoutGap.xs='0' fxLayout.xs="column" class="billing-address">
                    <mat-form-field fxFlex="31%" appearance="outline" *ngIf="billingInfo?.get('country')">
                        <mat-label>Country</mat-label>
                        <mat-select required (selectionChange)="getStates()" formControlName="country"
                            hideSingleSelectionIndicator data-control="country">
                            <div fxLayout="row" class="search-country">
                                <input fxFlex="90%" fxFlex.lt-md="92%" fxFlex.lt-sm="85%"
                                    data-control="searchCountryFl" placeholder="Search Country"
                                    [formControl]="countrySearchFilter"
                                    (keyup)="onCountrySearchFilter($event.target.value)" data-control="country search">
                                <mat-icon class="close-icon" *ngIf="countrySearchFilter.value"
                                    fxFlex="10%" fxFlex.lt-md="8%" fxFlex.lt-sm="15%" (click)="onCountryFilterClose()"
                                    data-control="closeIconCountry" data-control="country search close" fontIcon="close">
                                </mat-icon>
                            </div>
                            <mat-divider></mat-divider>
                            <mat-option *ngIf="searchCountries?.length === 0" data-control="no country">No Countries
                                Found
                            </mat-option>
                            <mat-option *ngFor="let country of searchCountries" [value]="country"
                                [attr.data-control]="'country '+country?.name | dataControl">
                                {{country?.name}}
                            </mat-option>
                        </mat-select>
                        <mat-error *ngIf=" billingInfo?.get('country')?.errors &&billingInfo?.get('country')?.touched">
                            <span *ngFor="let k of billingInfo?.get('country')?.errors | keyvalue"
                                [attr.data-control]="'country '+k?.key | dataControl">
                                {{ (errorMessage?.ERROR)['COUNTRY'][k.key] }}</span>
                        </mat-error>
                    </mat-form-field>
                    <mat-form-field fxFlex="32%" appearance="outline" *ngIf="billingInfo?.get('state')">
                        <mat-label>State</mat-label>
                        <mat-select required formControlName="state" data-control="state" hideSingleSelectionIndicator>
                            <div fxLayout="row" class="search-country">
                                <input fxFlex="90%" placeholder="Search State"
                                    [formControl]="stateSearchFilter" data-control="stateSearch"
                                    (keyup)="onStateSearchFilter($event.target.value)" autocomplete="off"
                                    data-control="state search">
                                <mat-icon class="close-icon" fxFlex="10%"
                                    (click)="clearStateFilter()" data-control="closeiconState"
                                    *ngIf="stateSearchFilter.value" data-control="state search close" fontIcon="close">
                                </mat-icon>
                            </div>
                            <mat-divider></mat-divider>
                            <mat-option *ngIf="searchStates?.length === 0" data-control="no state">No State Found
                            </mat-option>
                            <mat-option *ngFor="let state of searchStates" [value]="state"
                                [attr.data-control]="'state '+state?.name | dataControl">
                                {{state?.name}}
                            </mat-option>
                        </mat-select>
                        <mat-error *ngIf="billingInfo?.get('state')?.errors &&billingInfo?.get('state')?.touched">
                            <span *ngFor="let k of billingInfo?.get('state')?.errors | keyvalue"
                                [attr.data-control]="'state '+k?.key | dataControl">
                                {{ (errorMessage?.ERROR)['STATE'][k.key] }}</span>
                        </mat-error>
                    </mat-form-field>
                    <mat-form-field fxFlex="31%" appearance="outline" *ngIf="billingInfo?.get('zipCode')">
                        <mat-label>Zip Code</mat-label>
                        <input matInput placeholder="Zip Code" type="text" size="20" autocomplete="off"
                            formControlName="zipCode" required data-control="zipcode" trimVal />
                        <mat-error data-control="zipCodeError"
                            *ngIf="billingInfo?.get('zipCode')?.hasError('required')">
                            A zip code is required.
                        </mat-error>
                        <mat-error data-control="zipCodeErrorMaxlength"
                            *ngIf="billingInfo?.get('zipCode')?.hasError('maxlength')">
                            Zipcode should not exceed 15 characters.
                        </mat-error>
                    </mat-form-field>
                </div>
            </form>
            <div fxLayoutGap="10px" fxLayoutAlign="center center"
                *ngIf="!isLoading && selectedPlanDetails?.currentAmount>0 && paymentType!==constants?.paymentOption?.PAYPAL">
                <button mat-stroked-button type="button" *ngIf="isEdit" [disabled]="isButtonLoading"
                    (click)="cancelCard()" data-control="cancelCard">
                    Cancel
                </button>
                <button mat-flat-button class="primary-button payButton" type="button" [disabled]="isButtonLoading || isLoading"
                    (click)="(isEdit || !customerCardDetails) ? onSubmit(): payment()" data-control="payButton">
                    <ng-container *ngIf="!isButtonLoading">
                        Pay
                    </ng-container>
                    <cen-loader class="cen-spinner" *ngIf="isButtonLoading" [type]="'content'"
                        [name]="'cen-dot-spinner'">
                    </cen-loader>
                </button>
            </div>
        </div>
        <div fxFlex="68%" fxFlex.lt-md="100" mat-elevation-z11 fxLayoutAlign="center center" fxLayout="column"
            class="planOverrelayContent outer-card"
            *ngIf="storeDetails?.purchasedCurrency?.currencyUnit !== 'INR'&& !selectedPlanDetails?.currentAmount &&!isLoading">
            <div fxLayout="column">
                <span>Hurray! Enjoy the benefits of <strong>"{{selectedPlanDetails?.PlanName}}"</strong> with no
                    additional cost!</span>
                <span>Proceed to upgrade the plan.</span>
            </div>
            <button mat-flat-button class="primary-button payButton" type="button"
                [disabled]="isButtonLoading || isLoading" (click)="payment()" data-control="pay button">
                <ng-container *ngIf="!isButtonLoading">
                    Proceed
                </ng-container>
                <cen-loader class="cen-spinner" *ngIf="isButtonLoading" [type]="'content'" [name]="'cen-dot-spinner'">
                </cen-loader>
            </button>
        </div>
    </div>
</div>