import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class PaginationService {
    /**
     * To check whether the scroll reversed
     */
    isOrderChange: boolean;
    /**
     * To check whether the time to next batch request reached
     */
    isReachEnd: boolean;
    /**
     * records per page
     */
    itemLimit = 10;

    /**
     * to store prev offset value
     */
    prevOffset;
    /**
     * To store the emitted response value of the components for display.
     */
    listPaginatorData = new BehaviorSubject<any>([]);

    constructor() {

    }
    /**
     * To set Offset for scroll view
     * @param totalLimit Overall count of records
     * @param event Scroll Event which is having scroll view start position, end position and count
     * @param offset current offset value
     */
    setOffset(totalLimit: number, event, offset: number) {
        // scroll tracks last position or end offset of records
        const end = event.getRenderedRange()['end']; // 9
        // scroll tracks start position or start offset of records
        const start = event.getRenderedRange()['start']; // 0
        // Count of available records from last server call
        const total = event.getDataLength(); // 10
        // To check whether 5 pages record available
        const length = Math.ceil(total / this.itemLimit);
        // If reached end of available records should give api call for next set of records
        if (end === total) {
            // if length=5, last offset 0=> (5*10)+0=> new offset=>50
            // const startoffset = (length * this.itemLimit);
            let startoffset;
            if (offset !== 0) {
                startoffset = offset + this.itemLimit;
            }
            else {
                startoffset = total;
            }

            // Flag will be true if 5 th page reached
            this.isReachEnd = length >= 5 ? true : false;
            // If some more records available in db next to current offset
            if (!totalLimit || startoffset < totalLimit) {
                this.isOrderChange = true;
                // set value to be added for next request offset as 10 if length>50 or
                // if record count < 50 no need to send request
                if (startoffset === this.prevOffset) {
                    return null;
                }
                this.prevOffset = startoffset;
                return { offset: startoffset, value: 0 };
            }
        } else if (start === 0 && offset !== 0) {
            // If scrolling in reverse direction
            this.isOrderChange = false;
            if (offset < total) {
                offset -= 10;
            }
            else {
                offset -= 50;
            }
            if (offset === this.prevOffset || total === totalLimit) {
                return null;
            }
            this.prevOffset = offset;
            return { offset: offset, value: - 0 };
        }
        return null;
    }
    /**
     * To remove excess records from scrollview if more than 5 pages record avilable
     * @param paginatorData array of available record in scroll view
     * @param resData array of records fetched from last request
     */
    appendNewData(paginatorData: any[], resData: any[]) {
        // If moving forward
        if (this.isOrderChange) {
            // If already records of 5 pages available remove first page records before pushing new records
            if (this.isReachEnd) {
                paginatorData = paginatorData.slice(10);
            }
            // push newly fetched records at last
            paginatorData = [...paginatorData, ...resData];
        } else {
            // If scrolling reverse
            if (this.isReachEnd) {
                // If already records of 5 pages available remove last page records
                paginatorData = paginatorData.slice(0, 4 * this.itemLimit);
            }
            // push newly fetched records at front
            paginatorData = [...resData, ...paginatorData];
        }
        // this.isOrderChange = false;
        return paginatorData;
    }

}