import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, OnDestroy, ChangeDetectorRef, ChangeDetectionStrategy } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatSelectChange } from '@angular/material/select';
import { Sort } from '@angular/material/sort';
// import * as Highcharts from 'highcharts';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { AuthService } from '../../../../../auth/src/lib/services/auth.service';
import { CommonAdminShardConstants } from '../../constants/shared-constant';
import { dateObject } from '../../models/common-admin-model';
import { ReportsService } from '../../services/reports.service';
import { filter, mergeMap } from 'rxjs/operators';
declare const Highcharts: any;

/**
 * Component which is used for common functionality of all reports in dashboard.
 */
@Component({
  selector: 'phase-ii-common-report',
  templateUrl: './common-report.component.html',
  styleUrls: ['./common-report.component.scss'],
})
export class CommonReportComponent implements OnInit, OnChanges, OnDestroy {
  /**
  * Variable which is used to define the table loader
  * @type {Boolean}
  */
  isTableLoading: boolean;
  /**
  * Variable which is used to subscribe and unsubscribe.
  * @type {Subscritpion}
  */
  subscriptionObj: Subscription = new Subscription();
  /**
 * Variable which is used display the error message
 * @type {string}
 */
  errorMessages: { [key: string]: string };
  /**
  * Variable which is used to define the page config data
  */
  pageConfigData: any;
  /**
  * Variable which is used to define the page config data
  * @type {string}
  */
  storeCurrency: string;
  /**
  * Variable used to create reactive form to get the date
  * @type {FormGroup}
  */
  dateForm: UntypedFormGroup;
  /**
  * Variable used to store the columns to be displayed
  * @type {string[]}
  */
  displayedColumns: string[];
  /**
  * Variable which is used to get the displayed columns values from parent component
  */
  @Input() columns;
  /**
  * Variable which is used to store all inventory location details
  */
  locations = [];
  /**
   * Variable which is used to store the selected location 
   * @type {Location}
   */
  location: any;
  /**
   * Variable which is used to define the pagesize
   * @type {number}
   */
  pageSize: number = new CommonAdminShardConstants().paginator.defaultPageSize;
  /**
   * Variable which is used to store table DataList length
   * @type {number}
   */
  dataLength: number = 0;
  /**
  * Variable which is used to store table DataSource start position
  * @type {number}
  */
  startPosition: number = 0;
  /**
   * Varibale which is used to store the offset/starting value.
   * @type {number}
   */
  offset: number;
  /**
   * Variable which is used to emit data change value
   */
  @Output() onDataChange = new EventEmitter();
  /**
   * Variable which is used for filter
   * @type {boolean}
   */
  isFilter: boolean;
  /**
   * Variable which is used to emit filter action
   */
  @Output() filterAction = new EventEmitter();
  /**
 * Variable which is used to emit paginator data
 */
  @Output() changedData = new EventEmitter();
  /**
* Variable which is used to emit paginator data
*/
  @Output() paginatorData = new EventEmitter();
  /**
* Variable which is used to emit paginator data
*/
  @Output() changedDataForAutomatic = new EventEmitter();
  /**
* Variable which is used to emit paginator data
*/
  @Output() paginatorDataForAutomatic = new EventEmitter();
  /**
        * Variable which is used to emit paginator data
        */
  @Output() changedDataForManual = new EventEmitter();
  /**
* Variable which is used to emit paginator data
*/
  @Output() paginatorDataForManual = new EventEmitter();
  /**
   * Variable which is used to store the to date of the report.
   * @type {Date}
   */
  toDate: Date;
  /**
   * Variable which is used to store the from date of the report.
   * @type {Date}
   */
  fromDate: Date;
  /**
   * Variable which is used to store the sort object, which has the field and direction to be sorted.
   * @type {Sort}
   */
  sort: Sort;
  /**
   * Variable which is used to store the search text.
   * @type {string}
   */
  searchText: string;
  /**
 * output event emitter for paginator data.
 */
  @Output() onDataEmit = new EventEmitter<any>();
  /**
* output event emitter for export.
*/
  @Output() exportIcon = new EventEmitter();
  /**
  * Variable which is used to get the table properties from parent component
  */
  @Input() tableProperty;
  /**
  * Variable to set the maximum date for from-date
  * @type {Date}
  */
  maxDate = new Date();
  /**
* Variable to set the minimum date for from-date
* @type {Date}
*/
  minDate = new Date();
  /**
  * Variable to set the maximum date for to-date
  * @type {Date}
  */
  toMaxDate = new Date();
  /**
  * Variable which is used to get the data value from parent component
  */
  @Input() dataList;
  /**
  * Variable which is used to define the loader
  * @type {Boolean}
  */
  isLoader: boolean;
  /**
* Variable which is used to define the loader
* @type {Boolean}
*/
  listLoader: boolean;
  /**
   * Object which can be set to the properties and values from the parent component.
   */
  @Input() chartOptions;
  /**
* Variable which is used for Highcharts.
*/
  highcharts = Highcharts;
  /**
* Variable which is used to get page details as input.
*/
  @Input() pageDetails;
  /**
  * Variable which is used to get report calucation info as input.
  */
  @Input() reportCalculationInfo;
  /**
* Variable which is used for page resizing.
*/
  breakpoint: number;
  /**
* Variable which is used to store color code for card.
*/
  cardColor = ['#1f156f', '#f96a05', '#a569bd', '#fa3e67', '#02beed', '#795c0b', '#24c264', '#edae0d'];
  /**
* Variable which is used to store current user details.
*/
  currentUser: any;
  /**
* Variable which is used to store paginator value.
*/
  paginatorValue = { limit: null, offset: null };
  /**
* Variable which is used to store selected tab index.
*/
  currentTab: any;
  /**
* Variable which contains list of available stores.
*/
  storeList = [];
  /**
* Variable which contains store id value.
*/
  storeId: number;
  /**
* Variable which contains current role details.
*/
  currentRole: string;
  /**
* Variable which contains list of summary amount displayed in first row.
*/
  listSummaryData: any[] = [];
  /**
* Variable which contains list of summary amount displayed in second row.
*/
  listSummary: any[] = [];
  /**
* Variable which contains all the report names.
*/
  reports = [];
  /**
* Variable which contains selected report name.
*/
  reportName: string;
  commonConstants: CommonAdminShardConstants;

  /**
   * Variable which is used to identify offer report.
   */
  isReport: boolean;

  /**
 * Variable which is used to store the created date of the store.
 * @type {Date}
 */
  createdDate!: Date;

  /**
  * component constructor which is used to inject the required services.
  * @param authService  To access methods inside AuthService.
  * @param router  To access methods inside Router.
  * @param cdRef  To access methods inside ChangeDetectorRef.
  */
  constructor(
    private router: Router,
    private authService: AuthService,
    private cdRef: ChangeDetectorRef,
    private reportService: ReportsService
  ) {
    this.commonConstants = new CommonAdminShardConstants();
  }
  /**
  * Angular life cycle hook that initiates the component
  */
  ngOnInit(): void {
    this.isLoader = true;
    this.location = 'All';
    this.reports = [this.commonConstants.offerReport.manual, this.commonConstants.offerReport.automatic, this.commonConstants.offerReport.buyXgetY, this.commonConstants.offerReport.payXgetFree];
    this.setOfferReportFilter();

    this.subscriptionObj.add(this.authService.user.pipe(filter((res: any) => {
      if (res) {
        return res;
      }
    }), mergeMap((user: any) => {
      if (user) {
        this.currentUser = user;
        this.currentRole = this.currentUser.roleCode ? this.currentUser.roleCode : this.currentUser.role.code;
      }
      return this.reportService.getStoreData(this.pageDetails.storeId);
    }), mergeMap((data: any) => {
      if (data) {
        this.createdDate = data.settings && data.settings.createdAt;
      }
      return this.authService.dashboardFilter;
    })).subscribe((value: dateObject) => {
      if (value) {
        if (value.filterText == 'Lifetime') {
          this.fromDate = this.createdDate;
        }
        else {
          this.fromDate = value.fromDate ? value.fromDate : new Date();
          this.fromDate.setHours(0, 0, 0, 0);
        }
      }
      else {
        this.fromDate = new Date();
        this.fromDate.setHours(0, 0, 0, 0);
      }
      this.toDate = new Date();
      this.toDate.setHours(23, 59, 59, 999);
      this.dateForm = new UntypedFormGroup({
        from: new UntypedFormControl(this.fromDate),
        to: new UntypedFormControl(this.toDate)
      });
    }, (err) => {
      this.pageDetails.isLoader = false;
    }));
    this.currentTab = 0;
    if (this.pageDetails) {
      if (this.currentRole && this.currentRole === 'SUPERADMIN') {
        this.storeId = this.pageDetails.storeList && this.pageDetails.storeList[0] && this.pageDetails.storeList[0].id;
      } else {
        this.storeId = this.pageDetails.storeId ? this.pageDetails.storeId : null;
        this.getLocation(this.storeId);
        this.getReport(this.reportName);
      }
    }

    // this.isLoader = false;
    this.cdRef.detectChanges();
  }


  /**
   * Method which is called during paginator change events
   * @param event To get the paginator changed values
   */
  onChange(event: { pageSize: number, dataLength: number, startPosition: number, offset: number, limit: number }) {
    this.isTableLoading = true;
    if (event) {
      this.pageSize = event.pageSize ? event.pageSize : this.pageSize;
      this.dataLength = event.dataLength ? event.dataLength : 0;
      this.startPosition = event.startPosition ? event.startPosition : 0;
      this.offset = event.offset ? event.offset : 0;
      if (event.offset && event.offset >= 0 && event.limit) {
        this.onDataChange.emit({
          offset: event.offset ? event.offset : 0,
          limit: event.pageSize && event.limit ? event.limit : this.pageSize * 2
        });
      }
    }
    this.isTableLoading = false;
  }

  /**
 * Method which emits data to parent component
 * @param event To get the emitted data values
 */
  dataEmit(event) {
    this.onDataEmit.emit(event);
  }
  /**
  * Function which is used to get the inventory detail based on selected location.
  * @param event To get mat select event value for location
  */
  selectLocation(event: MatSelectChange) {
    if (event) {
      this.location = event.value && event.value === "All" ? 'All' : event.value ? event.value : null;
      this.isFilter = true;
      this.isTableLoading = true;
      this.listLoader = true;
      this.dataLength = 0;
      this.startPosition = 0;
      this.offset = 0;
      this.filterAction.emit({
        location_id: this.location ? this.location : 'All',
        store_id: this.storeId ? this.storeId : null,
        searchText: this.searchText ? this.searchText : '',
        from_date: this.fromDate ? this.fromDate : null,
        to_date: this.toDate ? this.toDate : null,
        sort: this.sort ? this.sort : null,
        offset: 0,
        limit: this.pageSize * 2
      });
      this.location = event.value ? event.value : null;
      this.isTableLoading = false;
      if (!this.dataList && !this.chartOptions) {
        this.isLoader = false;
        this.listLoader = false;
      }
    }
  }
  /**
 * Function which is used to get the inventory detail based on selected location.
 * @param event To get mat select event value for location
 */
  selectStore(event: MatSelectChange) {
    if (event) {
      this.storeId = event.value ? event.value : null;
      this.isFilter = true;
      this.isTableLoading = true;
      this.dataLength = 0;
      this.startPosition = 0;
      this.offset = 0;
      this.filterAction.emit({
        location_id: (this.location && this.location !== 'All') ? this.location : null,
        store_id: this.storeId ? this.storeId : null,
        searchText: this.searchText ? this.searchText : null,
        from_date: this.fromDate ? this.fromDate : null,
        to_date: this.toDate ? this.toDate : null,
        sort: this.sort ? this.sort : null,
        offset: 0,
        limit: this.pageSize * 2
      });
      this.getLocation(this.storeId);
      this.location = event.value ? event.value : null;
    }
  }
  /**
 * Method which is used to get locations based on selected store in super admin
 * @param selectedStore To get selected store value
 */
  getLocation(selectedStore) {
    this.locations = [];
    this.subscriptionObj.add(this.authService.getStoreCurrencyDetails(selectedStore).subscribe((res) => {
      if (res && res['storeCurrency'] && res['storeCurrency'].currency) {
        this.isLoader = false;
        this.storeCurrency = res['storeCurrency'].currency.currencyCode;
      }
    }));
    if (selectedStore !== 'All') {
      this.subscriptionObj.add(this.authService.getUserBasedInventoryLocations(selectedStore).subscribe((res) => {
        this.locations.push({ location_name: "All", location_id: "All" });
        for (let i = 0; i < res['inventoryLocation'].length; i++) {
          this.locations.push({
            location_name: res['inventoryLocation'][i].locationName,
            location_id: res['inventoryLocation'][i].id
          });
        }
        this.location = this.locations && this.locations[0] && this.locations[0].location_id;
      }));
    }
    // this.isLoader = false;
  }

  /**
* Method which is used to set loaded report name in reports filter
*/
  setOfferReportFilter() {
    if (this.pageDetails && this.pageDetails.heading && this.pageDetails.heading.title && this.pageDetails.heading.title === this.commonConstants.offerReport.manual) {
      this.reportName = this.commonConstants.offerReport.manual;
    } else if (this.pageDetails && this.pageDetails.heading && this.pageDetails.heading.title && this.pageDetails.heading.title === this.commonConstants.offerReport.automatic) {
      this.reportName = this.commonConstants.offerReport.automatic;
    } else if (this.pageDetails && this.pageDetails.heading && this.pageDetails.heading.title && this.pageDetails.heading.title === this.commonConstants.offerReport.buyXgetY) {
      this.reportName = this.commonConstants.offerReport.buyXgetY;
    } else if (this.pageDetails && this.pageDetails.heading && this.pageDetails.heading.title && this.pageDetails.heading.title === this.commonConstants.offerReport.payXgetFree) {
      this.reportName = this.commonConstants.offerReport.payXgetFree;
    }
  }

  /**
* Method which is used to get locations based on selected report
* @param selectedReport To get selected report value
*/
  getReport(selectedReport) {
    if ((selectedReport === this.commonConstants.offerReport.manual) || (selectedReport && selectedReport.value === this.commonConstants.offerReport.manual)) {
      this.isReport = true;
      this.router.navigate(['app/offer-report']);
    } else if ((selectedReport === this.commonConstants.offerReport.automatic) || (selectedReport && selectedReport.value === this.commonConstants.offerReport.automatic)) {
      this.isReport = true;
      this.router.navigate(['app/automatic-offer-report']);
    } else if ((selectedReport === this.commonConstants.offerReport.buyXgetY) || (selectedReport && selectedReport.value === this.commonConstants.offerReport.buyXgetY)) {
      this.isReport = true;
      this.router.navigate(['app/buyx-gety-offer-report']);
    } else if ((selectedReport === this.commonConstants.offerReport.payXgetFree) || (selectedReport && selectedReport.value === this.commonConstants.offerReport.payXgetFree)) {
      this.isReport = true;
      this.router.navigate(['app/payx-get-free-offer-report']);
    }
    else {
      // this.router.navigate(['app/offer-report']);
      this.isReport = false;
    }
    // this.isLoader = false;
  }


  /**
  * Function which is called to export the data in the excel sheet.
  */
  exportexcel(event) {
    if (event === 'exportexcel()') {
      this.filterAction.emit({
        searchText: this.searchText ? this.searchText : null,
        location_id: (this.location && this.location !== 'All') ? this.location : null,
        store_id: this.storeId ? this.storeId : null,
        from_date: this.fromDate ? this.fromDate : null,
        to_date: this.toDate ? this.toDate : null,
        sort: this.sort ? this.sort : null,
        exportexcel: true,
        offset: 0,
        limit: null
      });
    } else {
      this.router.navigate(['app/dashboard']);
    }
  }
  /**
  * Function which is used to emit the filterAction, when the searchText was changed.
  * @param event To get the search text
  */
  searchData(event) {
    this.dataLength = 0;
    this.startPosition = 0;
    this.isFilter = true;
    this.searchText = event?.target?.value;
    this.offset = 0;
    this.filterAction.emit({
      searchText: this.searchText,
      store_id: this.storeId ? this.storeId : null,
      location_id: this.location ? this.location : null,
      from_date: this.fromDate ? this.fromDate : null,
      to_date: this.toDate ? this.toDate : null,
      sort: this.sort ? this.sort : null,
      offset: 0,
      limit: this.pageSize * 2
    });
  }
  /**
   * Method which is used to convert the date in a format (ex: 2020-01-01).
   * @param str defines the date to be convert.
   */
  toDateConvert(str) {
    const date = new Date(str), month = ("0" + (date.getMonth() + 1)).slice(-2), day = ("0" + date.getDate()).slice(-2);
    return [date.getFullYear() + 1, month, day].join("-");
  }
  /**
  * Method which is used to emit the filterAction, when the date gets changed.
  */
  dateChange() {
    this.listLoader = true;
    if (this.dateForm.valid && this.dateForm.value.from && this.dateForm.value.to) {
      this.fromDate = this.dateForm.value.from;
      this.toDate = this.dateForm.value.to;
      this.isFilter = true;
      this.dataLength = 0;
      this.startPosition = 0;
      this.offset = 0;
      this.filterAction.emit({
        from_date: this.fromDate ? this.fromDate : null,
        to_date: this.toDate ? this.toDate : null,
        store_id: this.storeId ? this.storeId : null,
        location_id: this.location ? this.location : null,
        searchText: this.searchText ? this.searchText : null,
        sort: this.sort ? this.sort : null,
        offset: 0,
        limit: this.pageSize * 2
      });
      this.toMaxDate = new Date();
      if (!this.dataList && !this.chartOptions) {
        this.isLoader = false;
        this.listLoader = false;
      }
    }
  }
  /**
  * Method which is used to navigate to the dashboard.
  */
  goBack() {
    this.router.navigate(['app/dashboard']);
  }
  /**
   * Angular life cycle hook which is called during SimpleChanges.
   * @param data defines the simple data changes
   */
  ngOnChanges(data: SimpleChanges) {
    this.isLoader = true;
    this.isTableLoading = true;
    if (this.dataList && this.dataList.constructor === Object && Object.keys(this.dataList).length > 0) {
      this.listSummaryData = [];
      this.listSummary = [];
      for (let i = 0; i < Object.keys(this.dataList).length; i++) {
        if (i < 5) {
          let details = {
            key: Object.keys(this.dataList)[i],
            value: this.dataList[Object.keys(this.dataList)[i]]
          }
          this.assignInfoData(i, details);
          this.listSummaryData.push(details);
        } else {
          let details = {
            key: Object.keys(this.dataList)[i],
            value: this.dataList[Object.keys(this.dataList)[i]]
          }
          this.assignInfoData(i, details);
          this.listSummary.push(details);
        }
      }
      if (this.pageDetails || this.chartOptions) {
        this.isFilter = false;
        this.isLoader = false;
        this.isTableLoading = false;
        this.listLoader = false;
      }
      this.cdRef.detectChanges();
      // this.isTableLoading = false;
    }
    else {
      this.isLoader = false;
      this.listLoader = false;
    }
  }

  /**
 * Method which is used to resize the width
 * @param event To get the data for resizing the page
 */
  onResize(event) {
    this.breakpoint = (event.target.innerWidth <= 990) ? 3 : 4;
  }
  /**
 * Method which is used to get changed value for paginator
 * @param event To get the paginator changed values
 */
  getChangedData(event) {
    this.isTableLoading = true;
    if (event) {
      this.changedData.emit(event);
    }
    this.isTableLoading = false;
  }

  /**
 * Method which is used to get paginator data.
 * @param event To get offset and limit.
 */
  getPaginatorData(event) {
    this.isTableLoading = true;
    this.paginatorData.emit(event);
    this.isTableLoading = false;
  }

  assignInfoData(index:number, details:{key: string, value: string}){
    if (this.reportCalculationInfo && this.reportCalculationInfo[index] && details && this.reportCalculationInfo[index].key ==  details.key){
      Object.assign(details, { info: this.reportCalculationInfo[index] && this.reportCalculationInfo[index].value});
    }
  }
  /**
   * Angular life cycle hook which is used to unsubscribe the subscriptions.
   */
  ngOnDestroy() {
    this.subscriptionObj.unsubscribe();
  }
}


