/**
 * CustomValidatorService used for common custom validators for form controls.
 */
import {
  Validators,
  AbstractControl,
  ValidatorFn,
  ValidationErrors,
  UntypedFormGroup,
  AsyncValidatorFn,
} from '@angular/forms';
import * as _ from 'lodash';
/**
 * CustomValidatorService used for common custom validators for form controls.
 */
export class CustomValidatorService extends Validators {
  /**
   * Static method formValidation for custom validation
   * @param control To define the custom form contols.
   */
  static formValidation(control: AbstractControl) {
    if (control && control.value) {
      if (control.value.currentPassword && control.value.password) {
        // If password not match then set error for confirm password field else set error as null.
        if (control.value.password && control.value.currentPassword && control.value.password === control.value.currentPassword) {
          control.get('password').setErrors({ oldNewPasswordMatch: true });
        } else {
          if (control?.get('password')?.errors?.oldNewPasswordMatch) {
            const errorObject = control.get('password')?.errors;
            if (errorObject && errorObject.hasOwnProperty('oldNewPasswordMatch'))
              delete errorObject.oldNewPasswordMatch
            if (Object.keys(control.get('password')?.errors).length === 0) {
              control.get('password').setErrors(null);
            }
          }
        }
      }
      if (control.value.password && control.value.confirmPassword) {
        // If password not match then set error for confirm password field else set error as null.
        control
          .get('confirmPassword')
          .setErrors(
            control.value.password === control.value.confirmPassword
              ? null
              : { passwordMismatch: true }
          );
      }
      if (control.value.accountNumber && control.value.confirmAccountNumber) {
        control
          .get('confirmAccountNumber')
          .setErrors(
            control.value.accountNumber === control.value.confirmAccountNumber
              ? null
              : { AccountNotMatch: true }
          );
      }
      if (control.value.taxId && control.get('taxId').invalid && control.value.taxId.length < 10) {
        const match = ('' + control.value.taxId).replace(/\D/g, '').match(/^(\d{2})(\d{7})$/);
        if (match) {
          if (match.length > 1) {
            control.patchValue({
              taxId: match[1] + '-' + match[2]
            });
          }
        }
      }
    }
  }
  static contactFormValidation(control: AbstractControl) {
    // Format the phone number to us format
    if (control && control.value && control.value.toString().length === 10) {
      const match = ('' + control.value).replace(/\D/g, '').match(/^(\d{3})(\d{3})(\d{4})$/);
      if (match) {
        control.setValue('(' + match[1] + ') ' + match[2] + '-' + match[3]);
      }
    }
  }
  /**
    * Static method localDeliveryAddressValidation for validate delivery address
    * @param zipcodes To define the allowed zip code details.
    * @param locationDetails To define the city,state and country details
    */
  static localDeliveryAddressValidation(zipcodes: any[], locationDetails): ValidatorFn {
    return (control): ValidationErrors | null => {
      control = control as UntypedFormGroup;
      if (control && control.value) {
        if (control.value.zip_code && control.value.zip_code.length === 5 &&
          zipcodes &&
          zipcodes.length > 0 &&
          zipcodes.indexOf(control.value.zip_code) === -1
        ) {
          control
            .get('zip_code')
            .setErrors({ deliveryAddressError: true });
        }
        if (control.value.city && locationDetails.city && control.value.city.toLowerCase() !== locationDetails.city.toLowerCase()) {
          control
            .get('city')
            .setErrors({ deliveryAddressError: true });
        }
        if (control.value.state && control.value.state !== locationDetails.state) {
          control
            .get('state')
            .setErrors({ deliveryAddressError: true });
        }
        if (control.value.country && control.value.country !== locationDetails.country) {
          control
            .get('country')
            .setErrors({ deliveryAddressError: true });
        }
      }
      return null;
    };
  }
  /**
  * Static method checkCountryAndStateExist for check country and state is exists or not.
  * @param countryState to define the country state.
  * @param withCountryCode to define the CountryCode.
  * @param onlyCountryCode to handle onlyCountryCode or not.
  * @param notRequired to handle required or not.
  */
  static checkCountryAndStateExist(countryState, withCountryCode?: any, onlyCountryCode?: any, notRequired?: boolean): ValidatorFn {
    return (control): ValidationErrors | null => {
      const value = control.value;
      let dataFound;
      if (value) {
        if (countryState && countryState.length && typeof control.value === 'string') {
          const controlValue = (withCountryCode && value.includes(' ') && (value.indexOf(' ') < 4)) ? (value.slice(value.indexOf(' '))).trim() : value;
          if (onlyCountryCode) {
            dataFound = countryState.find(item => item && (item.countryCode === Number(controlValue)));
            dataFound = dataFound && dataFound.countryCode;
          }
          else {
            dataFound = countryState.find(item => item && (item.name.toLowerCase() === controlValue.toLowerCase()));
          }
          if (!dataFound) {
            return { invalidInput: true }
          }
          else if (dataFound && withCountryCode) {
            const countryCode = (value.includes(' ') && (value.indexOf(' ') < 4)) ? value.slice(0, value.indexOf(' ')) : null
            if (countryCode && dataFound.countryCode !== Number(countryCode)) {
              return { invalidInput: true }
            }
          }
        }
      } else {
        return notRequired ? null : { required: true };
      }
      if (dataFound && typeof control.value === 'string')
        control.setValue(dataFound ? dataFound : control.value);
      return null;
    }
  }
  /**
   * Static method zipCodeErrorMessage used to zipcode error message validators.
   * @param country is the country.
   * @returns validators.
   */
  static zipCodeErrorMessage(country): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (country) {

        if (country === 'US' || country === 'United States') {
          if (control.value) {
            const match = (control.value).toString().match(/^((\d{5}-\d{4})|(\d{5})|([A-Z]\d[A-Z]\s\d[A-Z]\d))$/);
            if (!match) {
              return { USZipCode: true };
            }
          }
        } else if (country === 'IN' || country === 'India') {
          if (control.value) {
            const match = (control.value).toString().match(/^(\d{6})$/);
            if (!match) {
              return { INZipCode: true };
            }
          }
        }
      }
      return null;
    };
  }
  /**
   * Static method deliveryAddressValidation used to delivery address error message validators.
   * @param zipcodes is the zipcodes.
   * @returns validators.
   */
  static deliveryAddressValidation(zipcodes: any[]): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (control && control.value && control.value.length === 5) {
        if (
          zipcodes &&
          zipcodes.length > 0 &&
          zipcodes.indexOf(control.value) === -1
        ) {
          return { deliveryAddressError: true };
        }
      }
      return null;
    };
  }
  /**
   * Method which is used to format the time
   * @param time which hold the time to format
   * @returns formated time response
   */
  static timeFormat(time) {
    let TimeMin, Time;
    if (time) {
      let index = time.indexOf(':');
      Time = Number(time.slice(0, index));
      if (time.indexOf('PM') >= 0) {
        Time = Time === 12 ? Time : Time + 12;
      } else {
        if (Time === 12) {
          Time = 0;
        }
      }
      TimeMin = time.slice(index + 1, time.length - 2);
    }
    return { Time: Time, TimeMin: TimeMin };
  }
  /**
   * Static method pickupTimeValidation used for pickup time validation.
   * @param control is the form control.
   * @returns validators.
   */
  static pickupTimeValidation(control: AbstractControl) {
    let t = control;
    let To;
    let From;
    let ToMin;
    let FromMin;
    if (t && t.value) {
      if (t.value.to) {
        const formatedTime = CustomValidatorService.timeFormat(t.value.to);
        To = formatedTime.Time;
        ToMin = formatedTime.TimeMin;
      }
      if (t.value.from) {
        const formatedTime = CustomValidatorService.timeFormat(t.value.to);
        From = formatedTime.Time;
        FromMin = formatedTime.TimeMin;
      }
      if (From > To) {
        control.get('to').setErrors({ ToTimeExceeds: true });
      }
      if (From === To && FromMin > ToMin) {
        control.get('to').setErrors({ ToTimeExceeds: true });
      }
    } else {
      return null;
    }
  }
  /**
   * Static method timeOverlap used for time validation.
   * @param control is the form control.
   * @returns validators.
   */
  static timeOverlap(control: AbstractControl) {
    let time = _.cloneDeep(control);
    let timings = time.value.timings;
    if (time && time.value && time.value.timings && time.value.timings.length > 0) {
      timings.map((item) => {
        if (item) {
          item.from = item.from ? CustomValidatorService.convert12To24(item.from) : null;
          item.to = item.to ? CustomValidatorService.convert12To24(item.to) : null;
        }
      });
      for (let i = 0; i < timings.length; i++) {
        if (timings[i].from && timings[i].to && (Number(timings[i].from) >= Number(timings[i].to))) {
          control.get('timings')['controls'][i].get('to').setErrors({ min: true });
        }
      }
      if (timings.length > 1) {
        for (let i = 0; i < timings.length - 1; i++) {
          for (let j = i + 1; j < timings.length; j++) {
            if (timings[i].from && timings[i].to && timings[j].from) {
              if (Number(timings[j].from) >= Number(timings[i].from) && Number(timings[j].from) < Number(timings[i].to)) {
                control.get('timings')['controls'][j].get('from').setErrors({ TimeOverlap: true });
              } else if (Number(timings[j].to)) {
                if ((Number(timings[j].from) < Number(timings[i].from)) && (Number(timings[j].to) > Number(timings[i].from))) {
                  control.get('timings')['controls'][j].get('to').setErrors({ TimeOverlap: true });
                }
                if ((Number(timings[j].to) >= Number(timings[i].from)) && (Number(timings[j].to) < Number(timings[j].from))) {
                  control.get('timings')['controls'][j].get('to').setErrors({ ToTimeExceeds: true });
                }
              }
            }
          }
        }
      }
    } else {
      return null;
    }
  }
  /**
   * Static method convert12To24 used to convert time 12 to 24.
   * @param data is the data.
   * @returns validators.
   */
  static convert12To24(data) {
    if (data) {
      const [time, modifier] = data.split(" ");
      let [hours, minutes] = time.split(":");
      if (hours === "12") {
        hours = "00";
      }
      if (modifier === "PM") {
        hours = parseInt(hours, 10) + 12;
      }
      return `${hours}.${minutes}`;
    }
  }
}
