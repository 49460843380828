import { Injectable } from '@angular/core';
import { API } from 'libs/common/src/lib/constants/api-routes';
import { HttpRoutingService } from 'libs/common/src/lib/services/httpRouting.service';
// import { API, HttpRoutingService } from '@phase-ii/common';


@Injectable({
  providedIn: 'root'
})
export class PayoutLogsService {

  constructor(
    private httpRoutingService: HttpRoutingService
  ) { }

  /**
    * Method to fetch details of the Payout logs.
    * @returns Observable with the API response containing Payout logs.
  */
  getPayoutLogsListData(data: any) {
    return this.httpRoutingService.getMethod(API.GET_PAYOUT_LOG, data);
  }
}
