/**
 * Class used to store the constant in users page.
 */
export class UsersConstants {
    /**
    * Variable which is used to store permission related controls and name
    * @type {object}
    */
    permissions = [
        // { name: 'Accept Marketing', controlName: 'acceptsMarketing' },
        { name: 'Accept Manual Order', controlName: 'isAllowManualOrder' },
        // { name: 'Mark as private', controlName: 'isPrivateInfo' },
        // { name: 'Mark as loyal customer', controlName: 'isLoyal' },
        // { name: 'Allow ACS and review mails', controlName: 'acceptReviewAcs' },
        // { name: 'Exempt free shipping', controlName: 'acceptsFreeShipping' },
        // { name: 'Exempt store credit', controlName: 'exemptStoreCreditReward' },
        // { name: 'Tax exempt', controlName: 'isTaxExempted' },
        //{ name: 'Force password reset on next login', controlName: 'isForceResetPassword' }

    ];
    /**
     * Variable is used to store the create/edit customer group page heading
     * @type {object}
     */
    createCustomerGroupHeading = {
        title: 'Create customer group',
        // description: 'Create your customer group here'
    };
    /**
     * Variable is used to store the create customer page heading
     * @type {object}
     */
    createCustomerHeading = {
        title: 'Create customer',
        description: 'Create your customer here'
    };
    /**
     * Variable is used to store the edit customer page heading
     * @type {object}
     */
    editCustomerHeading = {
        title: 'Edit customer',
        description: 'Edit your customer here'
    };
    /**
     * Variable is used to store the View customer page heading
     * @type {object}
     */
    ViewCustomerHeading = {
        title: 'View customer',
        description: 'View your customer here'
    };
    /**
     * Variable is used to store the create/edit contacts page heading
     * @type {object}
     */
    createContactsHeading = {
        title: 'Create contact',
        description: 'Create your contact here'
    }
    /**
     * Variable is used to store the add/edit user page heading
     * @type {object}
     */
    addUserHeading = {
        title: 'Add Staff',
        description: 'Add your staff here'
    };
    staffLoginHistory = {
        title: 'Staff Login History',
        description: 'View staff login history here'
    };
    adminLoginHistory = {
        title: 'Login History',
        description: 'View login history here'
    };
    daysFilter = ['Today', 'Yesterday', 'This Week', 'This Month'];
    columnsForLogin = [
        { column: 'ipAddress', suffixText: 'IP Address', type: 'Text', sort: false },
        { column: 'appDeviceDetail.deviceName', suffixText: 'Device Name', type: 'Object', sort: false },
        { column: 'loginTime', suffixText: 'Login Time', type: 'DateTime', sort: false },
        { column: 'loggedOutTime', suffixText: 'Logout Time', type: 'DateTime', sort: false },
        { column: 'appDeviceDetail.browser', suffixText: 'Medium', type: 'Object', sort: false },
    ]
    /**
   * Login hostory filters.
   * @type {array}
   */
    historyFilterOption: Array<any> = [
        'Today',
        'This Week',
        'This Month',
        'This Year',
        'Custom Date',
    ];
    /**
     * Variable is used to store the add/edit user page heading
     * @type {object}
     */
    inviteUserHeading = {
        title: 'Invite Staff',
        description: 'Invite staff to a defined role'
    };
    /**
     * Variable is used to store the add/edit user page heading
     * @type {object}
     */
    invitedUsersListHeading = {
        title: 'Invited Staff',
        description: '',
        isHeaderIcon: true,
        iconName: 'link',
        iconToolTip: 'Help',
        iconLink: 'https://help.getzenbasket.com/invited_staff.html'
    };
    cardBorderConditionValues = {
        'Pending': 'pendingBorder',
        'Removed Staff': 'redBorder',
        'Removed Role': 'picked-up-border'
    }
    invitedUsersListValues = {
        isNewImageText: 'No staff has been invited yet..!',
        cardBorderConditionField: 'status',
        findRouteValues: 'storeinvitedusers',
        customerApprovedStatus: 'Staff Approved',
        buttonValues: ["Yes", "No"],
        searchPlaceHolderValue: 'Email',
        isNewImageUrl: './assets/Invited-Staff.png',
        createdAtPattern: 'yyyy-MM-dd',


    }
    /**
     * Variable which is used to store tab names in add/edit customer page
     * @type {object}
     */
    addEditCustomerTabNames = ['General Information', 'Contact Information'];
    /**
     * Variable which is used to store tab names in add/edit contact page
     * @type {object}
     */
    addEditContactTabNames = ['General Information', 'Residential Information'];
    /**
     * Variable used to store the customer type filter options
     */
    customerTypeFilterOption = ['Wholesale customer', 'Customer'];
    /**
     * dialog messages
     */
    dialogMessages = {
        faliedEmail: 'Failed to send email',
        failureMessage: 'Failed to load login details.',
        deviceNameFailure: 'Failed to load device name details.',
        browserNameFailure: 'Failed to load browser details.',
        confirmationHeader: 'Confirmation',
        alertHeader: 'Alert',
        warningHeader: 'Warning',
        successHeader: 'Success',
        failureHeader: 'Failure',
        areYouSureHeader: 'Are you sure?',
        noContactInfoAlert: 'You must have atleast one contact information',
        defaultAddressChangeAlert: 'You must have atleast one address as default address',
        defaultAddressChangeAlertWhileEdit: 'Are you sure you want to make this your default address?',
        defaultAddressDelete: 'Default addresses cannot be deleted, Set another address as the default for further processing.',
        fillFieldAlert: 'Please fill the mandatory fields properly',
        failedToSave: 'Failed to save details.',
        failedToLoad: 'Failed to load details.',
        noChangeAlert: 'You have no changes to save.',
        smsVerification: 'Please verify your contact number.',
        fillFieldAlertWhileSave: 'To proceed ,kindly check and  fill out the fields correctly in both tabs',
        linkExpired: 'This url is expired. Contact your admin to proceed further',
        alreadyRespond: 'You have already joined in this role',
        mandatoryFieldFill: "Please fill out the mandatory fields.",
        termsFiled: "Please accept the terms and conditions",
        selectCustomersAlert: 'Please select customers to create group',
        createRoleSucceeded: "Role saved successfully..!",
        createRoleFail: "Failed to save role",
        updateRoleFail: "Failed to update role",
        updateRoleSucceeded: "Role updated successfully..!",
        getFailed: "Failed to get data.",
        deleteRoleList: "Do you want to delete this role ?,It may be assigned to a users.",
        deleteRoleListSuccess: "Role deleted successfully..!",
        deleteRoleListFailure: 'Failed to delete role.',
        deleteInvitationFailure: 'Failed to delete invitation.',
        deleteInvitationSuccess: "Invitation deleted successfully!",
        deleteInvitationConfirmation: 'Are you sure you want to delete this invitation',
        activateConfirmation: 'Are you sure you want to activate this Staff?',
        deactivateConfirmation: 'Are you sure you want to deactivate this Staff?',
        activateUserSuccess: 'Staff activated successfully!',
        deactivateUserSuccess: 'Staff deactivated successfully!',
        activateUserFailure: 'Failed to activate the Staff.',
        deactivateUserFailure: 'Failed to deactivate the Staff.',
        deleteConfirmation: 'Are you sure you want to remove this Staff?',
        deleteUserSuccess: 'Staff removed successfully!',
        deleteUserFailure: 'Failed to remove this Staff.',
        deleteConfirmationAlert: "Are you sure you want to remove this customer?",
        subscriptionCustomer: "This customer has an active subscription plan. Are you sure you want to remove this customer?",
        giftCardCustomer: "This customer has an active gift card. Are you sure you want to remove this customer?",
        subscriptionAndGiftCardCustomer: "This customer has an active gift card and subscription plan. Are you sure you want to remove this customer?",
        deleteCustomerSuccess: "Customer was removed successfully.",
        deleteConfirmationAlertContact: "Are you sure you want to remove this contact?",
        deleteCustomerSuccessContact: "Contact was removed successfully.",
        fetchCustomerFailedContact: 'Failed to load contact details.',
        deleteCustomerFailureContact: "Failed to remove contact details.",
        deleteCustomerGroupSuccess: "Customer group removed successfully.",
        deleteCustomerFailure: "Failed to remove customer details",
        deleteCustomerGroupFailure: "Failed to remove customer group details",
        fetchCustomerFailed: 'Failed to load customer details.',
        activateCustomerConfirmation: 'Are you sure you want to activate this customer?',
        deactivateContactConfirmation: 'Are you sure you want to deactivate this contact?',
        deactivateCustomerConfirmation: 'Are you sure you want to deactivate this customer?',
        activateCustomerSuccess: "Customer activated successfully.",
        activateCustomerFailure: "Failed to activate the customer",
        deactivateCustomerSuccess: "Customer deactivated successfully.",
        deactivateCustomerFailure: "Failed to deactivate the customer",
        deactivateContactSuccess: "Contact deactivated successfully.",
        deactivateContactFailure: "Failed to deactivate the contact.",
        addressFormValid: 'Please fill out the previous address details!',
        limitExceedInformation: 'Your staffs limit has exceeded. Please upgrade your plan to add more staffs.',
        userFetchFailed: 'Failed to fetch staff details.',
        userLimitExceeds: 'Staff limit exceeds. Please contact your admin.',
        staffEmailAlert: 'Wait until the provided mail are being processed',
        inviteUserAlert: 'Wait until the provided mails are being processed',
        addressRemovedSucess: 'Address index was removed successfully.',
        requestFailed: "Please try again after some time.",
        somethingWrong: "Something went wrong. Please contact the administrator.",
        emailUpdatedMailFail: 'The customer updated successfully, but something went wrong in the mail.',
        mailFailure: 'Something went wrong while sending mail.',
        password: 'Zenbasket@123',
        mailVerificationSuccess: 'Customer verification mail sent successfully.',
        mailUpdate: 'Customer updated and verification mail send successfully.',
        OTPAttemptCount: 'You have made three unsuccessful attempts. Please try again later.',
        DIALOG_MESSAGE_TIMEEXPIRED:'OTP Time is expired',
        customerSuccess: 'Customer created successfully.',
        customerFailure: 'Failed to create the customer.',
        close: 'Are you sure you want to close?',
        cancel: 'Are you sure you want to cancel?',
        verificationSuccess: 'Your contact number is verified.',
        locationFetch: 'Failed to fetch location details',
        guest: 'Are you sure you want to change as a customer?',
        yesButton: 'Yes',
        noButton: "No",
        okButton: 'Okay',
        removeContactsFailed: "Failed to remove contacts from the selected tag.",
        removeContactsSuccess: "Contacts removed from the tag successfully.",
        tagAlert: "Choose at least one tag to add the contact(s).",
        addTagFailed: "Failed to add contacts to the selected tags.",
        tagSuccess: "Contacts were successfully added to the selected tags.",
        tagExistingAlert: "Kindly apply another existing tag filter to remove the tag.",
        tagAnyAlert: 'Kindly apply any tag filter to remove the tag.',
        removeTagAlert: 'Choose at least one contact to remove the tag.',
        oneCustomerAlert: 'Choose at least one contact to add tag(s).',
        noTagAlert: "Kindly apply the No tags filter to add tag(s).",
        unsubscribeAlert: 'Choose atleast one customer to unsubscribe.',
        unsubscribeAlertCon: 'Choose at least one contact to unsubscribe.',
        fetchDiscountFailed: 'Failed to load discount details.',
        noMail: "Email id is needed, otherwise guest can't be changed into a customer",
        mailNotSend: "Something went wrong while sending mail,so can't be changed into a customer",
        shopapp: {
            fetchError: "Oops! Something went wrong",
            failedToSave: 'Failed to create customer',
            mandatoryFieldFill: "Please fill out the mandatory fields",
            noChangeAlert: 'You have no changes to save',
            addressFormValid: 'Please fill out the previous address details',
            failedToLoad: 'Failed to load details',
            defaultAddressDelete: 'Default addresses cannot be deleted, set another address as the default for further processing',
            addressRemovedSucess: 'Address index was removed successfully.',
            failedToUpdate: 'Failed to update customer details',
            verifyMail: "Kindly check your email to confirm your registration",
            verifyMailSendSuccess: "Verification mail send successfully.",
            customerCreated: 'Customer created successfully.',
            customerCreateMail: 'Customer created and verification mail sent successfully.',
            updateCutomer: 'Customer details updated successfully.',
            updateCustomerMailSend: 'Customer details updated and verification mail sent successfully.',
            updateCutomerMailSendFail: 'Customer details updated successfully ,but something went wrong in the mail.',
            customerCreatedMailSendFail: 'Customer created successfully ,but something went wrong in the mail.',
            createMailAlreadyExists: 'Failed to create customer, Email already Exists',
            updateMailAlreadyExists: 'Failed to update customer, Email already Exists'
        },
        adminStaff: "You cannot function this storeadmin staff.",
        loyaltySuccess: 'Customer Points updated successfully.',
        loyaltyFailure: 'Failed to update points',
        earningPointsFailed: 'Failed to fetch earning points',
        redeemPointsFailed: 'Failed to fetch redeem points',
        referralActivityFailed: 'Failed to fetch referral activity',
        bulkDeleteCustomerList: "Are you sure you want to remove the selected customer(s)?",
        bulkDeleteCustomerListSuccess: "Selected customer(s) removed successfully.",
        bulkDeleteCustomerListFailure: "Failed to remove selected customer(s).",
        resetMailSentSuccess: "Mail sent successfully.",
        RoleRemoved: "Role unavailable",
        invitationFailed: "The invitation link has expired. Please contact the admin or check your recent emails."
    };
    paginator = {
        pageLengthOptions: [5, 10, 20, 30, 40],
        defaultPageSize: 10
    };
    dialogType = { alert: 'alert', confirmation: 'confirmation', warningHeader: 'Warning', information: 'information', success: 'success', failure: 'failure' };

    /**
     * validators
     */
    validators = {
        hintToAddEmail: 'Separate the multiple staff emails using commas. For Example: staff2@example.com,staff3@example.com',
        contactNumberPattern: "Contact number must contain 10 digits, and the first number cannot be 0 or 1.",
        phoneNumberPattern: "Contact number is invalid!",
        phoneNumberLength: "Contact Number must contain 20 digits",
        contactAlreadyExist: "Contact Number already exists.",
        passwordValidation: 'Password should be between 6-20 characters with at least one number, special character, lowercase and uppercase',
        nameMaxLength: "First name should not exceed 40 characters.",
        fullNameMaxLength: 'Full name should not exceed 50 characters.',
        INZipCodePattern: "Zip code is invalid.",
        spaceAloneValidation: 'This field does not accept space alone',
        taxIdMaxLengthReached: "Tax identification number should not exceed 15 characters.",
        phoneNumberMaxLength: 'Contact number should not exceed 20 characters.',
        contactNumber: 'Contact number accepts only integer',
        phoneNumberInvalid: 'Please enter valid contact number',
        // emailExists: "Email already exist",
        // invalidEmail: "Please enter a valid email",
        fieldRequired: "This field is required",
        maxLenExceed: "Maximum length exceeds",
        roleAlreadyExist: "This role name already exist",
        charactersOnly: "This field accepts characters only.",
        noRecords: "No Records Found!",
        fetchError: "Oops !! Something went wrong...",
        groupNameLength: 'Group name should not exceed 30 characters.',
        emailPattern: "Email address is invalid!",
        emailAlreadyExists: "Email already exists!",
        firstNamePattern: "First Name accepts the characters only.",
        addressMaxLength: 'Address line 1 should not exceed 100 characters.',
        zipCodePattern: 'Invalid zip code',
        acceptNumberOnly: 'This field accept number only',
        websiteAddressPattern: 'Invalid website address',
        descriptionMaxLengthExceeds: 'Description should not exceed 120 characters.',
        invalidCountry: 'Country is invalid',
        inviteUserlimit: 'Your staff limit has exceeded',
        emailCheck: 'Processing email',
        contactNumberCheck: "Processing Contact Number",
        mailVerification: 'Customer will receive order notification only if their email is verified.',
        isForceResetPassword: 'isForceResetPassword',
        landmarkMaxLength: 'Landmarks should not exceed 60 characters.',
        landmarkPattern: "Invalid landmark",
        otherAddressMaxLength: 'Addresses should not exceed 60 characters.',
        otherAddressLinePattern: "Invalid address",
        shopappError: {
            fieldRequired: "This field is required",
            nameMaxLength: "First name should not exceed 40 characters",
            firstNamePattern: "First name accepts the characters only",
            emailPattern: "Email is invalid",
            emailAlreadyExists: "Email already exists",
            contactNumberPattern: "Contact number must contain only digits",
            contactNumberMaxlenght: "Contact number should not exceed 20 digits",
            contactAlreadyExist: "Contact Number already exists",
            addressMaxLength: 'Address line 1 should not exceed 100 characters',
            charactersOnly: "This field accepts character only",
            INZipCodePattern: "Zip code is invalid",
            landmarkMaxLength: 'Landmarks should not exceed 60 characters',
        },
        phoneNumberExceeds: 'Contact number should not exceed 20 digits.',
        nameMaximumLength: "Name should not exceed 30 characters.",
    };
    /**
    * Variable used to store address type default values.
    */
    addressType = ['Home', 'Office', 'Others'];
    /**
     * Variable used to store the user action labels.
     */
    usersList = {
        editUser: 'Edit Staff',
        viewUser: 'View Staff',
        deleteUser: 'Remove Staff',
        viewUserHistory: 'View Staff History',
        activateUser: 'Activate Staff',
        inactiveUser: 'Deactivate Staff',
        importUser: 'Import Staff',
        exportUser: 'Export Staff'
    };
    /**
     * Variable used for the filter option of marketing.
     */
    marketingAllowed = [
        { name: 'Yes', value: true }, { name: 'No', value: false }];
    /**
     * Variable used for store the read only icons for user list
     */
    iconsForReadOnly = [
        { iconName: 'visibility', method: 'onEdit', toolTip: this.usersList.viewUser, iconColor: '#03c04a' },
    ];
    /**
     * Variable used for store the active icons for user list
     */
    iconsForActiveUser = [
        { iconName: 'edit', method: 'onEdit', toolTip: this.usersList.editUser, iconColor: '#03c04a' },
        { iconName: 'delete', method: 'onDelete', toolTip: this.usersList.deleteUser, iconColor: '#f72121' },
        { iconName: 'highlight_off', method: 'onDeactivate', toolTip: this.usersList.inactiveUser, iconColor: '#f72121' },
        { iconName: ' restore', method: 'onLoginHistory', toolTip: this.usersList.viewUserHistory, iconColor: 'rgb(6, 142, 239)' },

    ];
    /**
    * Variable used for store the in active icons for user list
    */
    iconsForInactiveUser = [
        { iconName: 'edit', method: 'onEdit', toolTip: this.usersList.editUser, iconColor: '#03c04a' },
        { iconName: 'delete', method: 'onDelete', toolTip: this.usersList.deleteUser, iconColor: '#f72121' },
        { iconName: 'check_circle_outline', method: 'onActive', toolTip: this.usersList.activateUser, iconColor: '#03c04a' },
        { iconName: ' restore', method: 'onLoginHistory', toolTip: this.usersList.viewUserHistory, iconColor: 'rgb(6, 142, 239)' },

    ];
    /**
    * Variable used for store the header of user list page
    */
    userHeading = {
        title: 'Staff',
        // description: 'Look at your staff here',
        isHeaderIcon: true,
        iconName: 'link',
        iconToolTip: 'Help',
        iconLink: 'https://help.getzenbasket.com/staff.html'
    };
    /**
    * Variable used for store the add user button
    */
    userButton = [
        {
            name: 'Add Staff', class: 'primary-button', icon: 'add', method: 'onNavigate', disabled: false
        }
    ];
    /**
     * Variable used for store the columns for user
     */
    columnsForUsers = [
        { column: 'firstName', suffixText: 'Name', type: 'Text' },
        { column: 'email', suffixText: 'Email', type: 'Text' },
        { column: 'role', suffixText: 'Role', type: 'Text' },
        { column: 'phone', suffixText: 'Phone', type: 'Text' },
    ];
    /**
    * Variable used for store the add customer button
    */
    contactsButton = [
        {
            name: 'Remove Tag', method: 'bulkTagRemove', class: 'delete-button', icon: 'delete', iconClass: "deleteIcon"
        },
        {
            name: 'Add Tag', class: 'readyMade-button', icon: 'add', iconClass: "readyMadeIcon", method: 'bulkTagUpdation'
        },
        {
            name: 'Unsubscribe', method: 'bulkUnsubscribe', class: 'delete-button', icon: 'exit_to_app', iconClass: "deleteIcon"
        },
        {
            name: 'Add Contact', class: 'primary-button', link: '/app/customer/addeditcustomer', icon: 'add'
        }
    ];
    /**
     * Variable used for store the add customer button
     */
    customerButton = [
        {
            name: 'Add Customer', class: 'primary-button', link: '/app/customer/addeditcustomer', icon: 'add'
        },
        {
            name: 'Remove', class: 'delete-button', iconColor: '#f72121', method: 'bulkDelete', icon: ' delete_outline'
        }
    ];
    /**
     * Variable used for store the add customer group button
     */
    customerGroupButton = [
        {
            name: 'Add Customer Group', class: 'primary-button', link: '/app/customer/addeditcustomergroup'
        }
    ];
    /**
     * Variable used for store the header of customer list page
     */
    customerlistHeading = {
        title: 'Customers',
        description: null,
        // description: 'Customers of your store are listed here',
        isHeaderIcon: true,
        iconName: 'link',
        iconToolTip: 'Help',
        iconLink: 'https://help.getzenbasket.com/customers.html',
    };

    /**
    * Variable used for store the header of contact list page
    */
    contactListHeading = {
        title: 'Contacts',
        description: 'Lists of customers who have accepted marketing and have a valid email',
        isHeaderIcon: true,
        iconName: 'link',
        iconToolTip: 'Help',
        iconLink: 'https://help.getzenbasket.com/contacts.html',
    };
    /**
    * Variable used for store the header of customer group list page
    */
    customergrouplistHeading = {
        title: 'Customer Groups',
        description: '',
        isHeaderIcon: true,
        iconName: 'link',
        iconLink: 'https://help.getzenbasket.com/customer_group.html',
        iconToolTip: 'Help',
    };
    /**
     * Variable used for store the columns for customer group list
     */
    columnsForCustomerGroup = [
        { column: 'groupName', type: 'Text', suffixText: 'Group Name' },
        // { column: 'plugin', type: 'InnerHtml', suffixText: '' },
        // { column: 'description', type: 'Text', suffixText: 'Description' },
        { column: 'createdAt', type: 'Date', dateFormat: 'MMM d y ', suffixText: 'Created On' },
        { column: 'customersCount', type: 'Text', suffixText: 'No of Customers' },
    ];
    dialogueMessages = {
        confirmation: "Are you sure you want turn off the subscription of the selected contact(s)?",
        success: "Selected contact(s) unsubscribed successfully.",
        failure: "Failed to unsubscribe seleted contact(s).",
        removeTagConfirmation: "Are you sure you want to remove the selected contact(s) from the filtered tag?",
    }
    /**
     * Variable used to store the customer status.
     */
    customerStatus = ['Pending', 'Active'];
    wholeSaleStatus = ['Pending', 'Approved', 'Declined'];
    inActiveCustomerStatus = ['Approved', 'Declined'];
    inActivdCustomerRoles = [
        {
            "id": 12,
            "name": "Customer",
            "code": "CUSTOMER",
            "role": {
                "id": 12,
                "value": false
            }
        },
        {
            "id": 12,
            "name": "Wholesale",
            "role": {
                "id": 12,
                "value": true
            }
        }
    ];
    /**
     * Variable used to store the customer page labels.
     */
    customerList = {
        pending: 'Pending',
        active: 'Active',
        inActive: 'InActive',
        editCustomer: 'Edit Customer',
        deleteCustomer: 'Remove Customer',
        viewCustomerHistory: 'View Customer History',
        exportCustomer: 'Export Customers',
        importCustomer: 'Import Customers',
        viewCustomer: 'View Guest Details',
        viewOrders: 'View Orders',
        exportContact: 'Export Contacts',
    };
    /**
     * Variable used for store the columns for customer list
     */
    columnsForCustomer = [
        { column: 'name', suffixText: 'Name', type: 'Text' },
        { column: 'status', type: 'Chip', suffixText: 'Status', classCondition: { 'Active': 'requiredChip', 'InActive': 'inActiveChip', 'Pending': 'orangeChip' } },
        { column: 'isWholesale', type: 'Chip', suffixText: 'Wholesale Status', classCondition: { 'Declined': 'inActiveChip', 'Approved': 'requiredChip' } },
        // { column: 'email', suffixText: 'Email', type: 'Text' },
        { column: 'roles', suffixText: 'Type', type: 'Chip', classCondition: { 'Customer': 'orangeChip', 'Guest': '', 'Wholesale': 'wholeSaleChip' } },
        { column: 'phone', suffixText: 'Phone', type: 'Text' },
        { column: 'createdAt', type: 'Date', dateFormat: 'MMM d y ', suffixText: 'Created At' },

        // { column: 'acceptsMarketing', suffixText: 'Allow Marketing', type: 'Text' },
    ];
    /**
     * Variable used for store the columns for customer list
     */
    columnsForCustomerMarketing = [
        { column: 'name', suffixText: 'Name', type: 'Text' },
        { column: 'roles', suffixText: 'Type', type: 'Chip', classCondition: { 'Customer': 'orangeChip', 'Guest': '', 'Wholesale': 'wholeSaleChip' } },
        { column: 'email', suffixText: 'Email', type: 'Text' },
        { column: 'phone', suffixText: 'Phone', type: 'Text' },
        { column: 'tagsCount', suffixText: 'Tags', type: 'Text' },
        // { column: 'acceptsMarketing', suffixText: 'Allow Marketing', type: 'Text' },
    ];
    /**
     * Variable used to store the feature code of staff for admin
     */
    featureCode = 'STAFF';
    /**
     * Variable used to store the property values of inivitation status code
     */
    propertyValues = {
        invitationStatusCode: 'INVITATION_STATUS'
    };
    /**
     * Variable used to store the role code of the guest.
     */
    roleCode = {
        guest: 'GUEST'
    };
    /**
     * Variable which is used to store plugin codes
     * @type {object}
     */
    pluginCodes = {
        wholesale: 'WHOLESALE',
        loyalty: 'LOYALTY'
    };
    formInput = ['one', 'two', 'three', 'four'];

    shopappUserButton = [
        {
            name: 'Add User', class: 'primary-button', link: '/app/users/shopapp/addcustomer', icon: 'add'
        }
    ];
    /**
   * Variable which is used to store tab names in add/edit customer page
   * @type {object}
   */
    shopappCustomerTabNames = ['General information', 'Contact information'];
    adjestmentTypes = ['Increment', 'Decrement']
    /**
     * Variable which is used to store customer groups details
     */
    CUSTOMER_GROUP_DATA = {
        NO_RECORDS: "Customer groups have not been added yet. Once created, they will be listed here."
    }
}
/**
 * Interface which is used to get the Moreaction details for each row
 */
export interface MoreActions {
    displayName: string,
    event: string,
    values: any,
}