import { Component, EventEmitter, Inject, Input, OnChanges, OnInit, Output, PLATFORM_ID, SimpleChanges } from '@angular/core';
import html2canvas from 'html2canvas';
import { FormControl, Validators, UntypedFormControl } from '@angular/forms';

import { cloneDeep } from 'lodash';
import { dataConfig } from '@phase-ii/common';
import { CommonService } from 'libs/shared/src/lib/services/common.service';
// import { environment } from 'apps/superadmin/src/environments/environment';
import { CommonAdminShardConstants } from '../../constants/shared-constant';
import { isPlatformBrowser } from '@angular/common';
import { CustomAsyncValidatorService } from 'libs/shared/src/lib/services/custom-async-validator.service';

@Component({
  selector: 'phase-ii-email-marketing-preview',
  templateUrl: './email-marketing-preview.component.html',
  styleUrls: ['./email-marketing-preview.component.scss'],
})
export class EmailMarketingPreviewComponent implements OnInit, OnChanges {
  /**
   * Used to get config data for iframe from parent component.
   */
  @Input() data: any;

  /**
   * Used to get template content.
   */
  @Input() templateContent: any;

  /**
   * Input variable which is used to store the data for right side top filter.
   * @type { any }
   */
  // @Input() rightTopFilter: any;
  /**
   * Input variable which is used to store language changes.
   * @type { any }
   */
  @Input() translator: any;
  /**
   * Used to close the dialog.
   */
  @Output('close') close = new EventEmitter();
  /**
  * Event Emitter which is used to emit the right filter changes to the 
  * @type { any }
  */
  // @Output() rightFilterChanged = new EventEmitter<any>();

  /**
   * Variable used for getting Contacts Constants.
   */
  constants: CommonAdminShardConstants = new CommonAdminShardConstants();

  /**
   * Used to get template name.
   */
  templateName: FormControl;

  /**
   * Variable which is used to search right top filter search control.
   */
  rightTopFilterSearchField = new UntypedFormControl(null);
  /**
   * Regular expression to find if application is opened on mobile devices or not.
   */
  regexp = /android|iphone|kindle|ipad|tablet|mobile/i;

  /**
   * Used to know application is opened on mobile devices or not.
   */
  isMobileDevice: boolean = this.regexp.test(navigator?.userAgent);

  /**
   * Used to set the height for iframe element.
   */
  iframeHeight: string = '500px';

  /**
   * Array to hold the data to display the values in right top filter filtered data 
   */
  // rightTopFilterFilteredData: any = [];
  /**
   * Options for template preview.
   */
  previewOptions :any;

  /**
   * Used to hold the iframe document.
   */
  iframeDocs: Document;

  /**
   * Used to restrict the redundant execution of displayContent method.
   */
  isNgOnChange = false;

  /**
   * Used to display loader on content section to set width based on scrollbar size.
   */
  iframeLoader = true;

  /**
   * component constructor which is used to inject the required services.
   * @param commonService used to check duplicate template name.
   */
  constructor(
    private commonService: CommonService,
    // private translateService: DynamicTranslationService,
    @Inject(PLATFORM_ID) private platformId: object  
  ) { }


  ngOnInit(): void {
    // this.translateService.loadTranslations(["email-marketing-preview"],"ta");
    if (isPlatformBrowser(this.platformId)) {
    this.previewOptions ={
      desktop: {
        width: '850px',
        icon: 'desktop_mac',
        condition: !this.isMobileDevice,
        dialogWidth: '940px',
        tooltip: "Desktop view (850 x 500)"
      },
      tablet: {
        width: (window.innerWidth > 790) ? '700px' : '80vw',
        icon: 'tablet_mac',
        condition: (window.innerWidth > 600),
        dialogWidth: (window.innerWidth > 790) ? '790px' : 'calc(100vw - 60px)',
        tooltip: "Tablet view (700 x 500)"
      },
      mobile: {
        width: '320px',
        icon: 'phone_iphone',
        condition: true,
        dialogWidth: (window.innerWidth > 690) ? '650px' : '80vw',
        tooltip: "Mobile view (320 x 500)"
      },
      selected: 'desktop'
    }
  }
    this.previewOptions.selected = Object.keys(this.previewOptions).find((key: string) => this.previewOptions[key].condition);
    if (this.data.isEditor) {
      this.templateName = new FormControl<string>((this.data.isEdit ||this.data.hasOwnProperty('isSuperAdmin')) && this.data.templateName ? this.data.templateName : null, {
        validators: [Validators.required, Validators.maxLength(100), Validators.pattern(dataConfig.patternValidators.spaceValidationPattern)],
        // asyncValidators: [
        //   CustomAsyncValidatorService.asyncCheckDuplicateValidation(this.commonService, 'markettingTemplates', 'templateName', this.data.storeId, this.data.templateId ?? null, false)
        // ]
      });
      this.addDynamicValidator()
    }
    // if (this.rightTopFilter && this.rightTopFilter.values) {
    //   this.rightTopFilterFilteredData = cloneDeep(this.rightTopFilter.values);
    // }
  }

  addDynamicValidator(value?:boolean) {
    this.templateName.addAsyncValidators(CustomAsyncValidatorService.asyncCheckDuplicateValidation(this.commonService, 'markettingTemplates', 'templateName', this.data?.storeId, this.data?.templateId ?? null, true, null, null, null, null, 'langCode' ,this.data.langCode ));
    // if(this.data?.hasOwnProperty('isSuperAdmin')){
      this.templateName.markAsDirty();
      this.templateName.updateValueAndValidity();
    // }
  }
  /**
   * Method used to write iframe content while changes in template content. 
   * @param changes holds the change event on input properties.
   */
  ngOnChanges(changes: SimpleChanges): void {
    if (!changes.templateContent?.firstChange && changes.templateContent?.previousValue !== changes.templateContent?.currentValue) {
      this.isNgOnChange = true;
      this.iframeLoader = true;
      this.iframeDocs.open();
      this.iframeDocs.write('<div style="pointer-events:none">' + this.templateContent + '</div>');
      this.iframeDocs.close();
    }
    else if (!changes.data?.firstChange) {
      this.setIframeWidth();
    }
  }

  /**
   * Used to display exported html in preview page.
   */
  displaycontent(iframeElement: HTMLIFrameElement): void {
    this.iframeDocs ??= iframeElement.contentWindow.document;
    if (this.iframeDocs) {
      if (!this.isNgOnChange) {
        this.iframeDocs.open();
        this.iframeDocs.write('<div style="pointer-events:none">' + this.templateContent + '</div>');
        this.iframeDocs.close();
        this.isNgOnChange = false;
      }
      setTimeout(() => {
        this.setIframeWidth(iframeElement);
        if (this.data.isEditor) {
          const div = Object.assign(document.createElement('div'), { innerHTML: this.templateContent, id: "temp", style: 'width: 900px' });
          document.getElementById('iframeWrapper').appendChild(div);
        }
      }, 500);

    }
  }

  /**
   * Method used to set width of iframe element.
   * @param iframeElement holds the reference of iframe.
   */
  setIframeWidth(iframeElement?: HTMLIFrameElement): void {
    iframeElement ??= document.getElementById("iframeElement") as HTMLIFrameElement;
    const scrollBarWidth = iframeElement.getBoundingClientRect().width - this.iframeDocs?.querySelector('html')?.getBoundingClientRect().width;

    if (!this.isMobileDevice && scrollBarWidth) {
      if (this.data.isEditor)
        Object.keys(this.previewOptions).forEach((key: string) => {
          if (this.previewOptions[key].condition)
            this.previewOptions[key].iframeWidth = `calc(${this.previewOptions[key].width} + ${Math.ceil(Math.abs(scrollBarWidth))}px)`;
        });
      else {
        this.data.iframeWidth = `calc(${this.data.width} + ${scrollBarWidth}px)`;
      }
    }
    setTimeout(()=>{
      this.iframeLoader = false;
    }, 500);
  }

  /**
   * Method used to restrict the auto sort behavior of keyvalue pipe.
   * @returns 0.
   */
  returnZero(): number {
    return 0;
  }

  /**
   * Used to generate the base64 image content of the template.
   */
  async functionTrigger(): Promise<void> {
    this.data.isButtonLoader = true;
    let base64Content: string;
    const tempElement = document.getElementById("temp");
    if (tempElement) {
      await html2canvas(tempElement, { proxy: this.data?.apiUrl + '/v1/'}).then((canvas) => {
        base64Content = canvas.toDataURL("image/png");
      });
      document.getElementById('iframeWrapper').removeChild(tempElement);
    }
    this.data.isButtonLoader = false;
    this.onClose({
      imageContent: base64Content,
      templateName: this.templateName.value
    });
  }

  /**
   * 
   * @param event this will pass click event of select with select value
   */
  // rightFilterOptionChanges(event: any): any {
  //   this.templateName.clearAsyncValidators();
  //   this.data.storeId = event.value ? event.value : null;    
  //   this.addDynamicValidator( this.data.storeId ? true :false);
  //   this.rightFilterChanged.emit(event.value);
  // }

  /**
   * 
   * @param event this will pass click event of select with select value
   */
  // rightTopFilterSearchFieldChange(event) {
  //   if (!event) {
  //     this.rightTopFilterSearchField.setValue(null);
  //     this.setRightTopFilterData();
  //   }
  // }
  /**
   * 
   *  this will set click event of select with select value
   */
  // setRightTopFilterData() {
  //   if (this.rightTopFilter && this.rightTopFilter.values) {
  //     this.rightTopFilterFilteredData = cloneDeep(this.rightTopFilter.values);
  //   }
  // }
  /**
   * 
   *  this will set click event of select with rightTopFilterSearch select value
   */
  // rightTopFilterSearch(value?: string): void {
  //   if (value && this.rightTopFilter && this.rightTopFilter.values) {
  //     const filterValue = value.toLowerCase();
  //     this.rightTopFilterFilteredData = this.rightTopFilter.values.filter(value => {
  //       if (value[this.rightTopFilter.matLabelField]) {
  //         return value[this.rightTopFilter.matLabelField].toLowerCase().includes(filterValue)
  //       }
  //     }
  //     );
  //   }
  //   else {
  //     this.rightTopFilterSearchField.setValue(null);
  //     this.setRightTopFilterData();
  //   }
  // }
  /**
   * 
   *  this will close rightTopFilterClose dialog.
   */
  // rightTopFilterClose(): void {
  //   this.rightTopFilterSearchField.setValue(null);
  //   this.setRightTopFilterData();
  // }
  /**
   * Method used to close the dialog.
   * @param data has image content and name of the template.
   */
  onClose(data?: { templateName: string, imageContent: string }): void {
    this.close.emit(data);
  }
}
