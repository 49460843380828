import { Component, Inject, OnInit, inject } from '@angular/core';
import { MAT_SNACK_BAR_DATA, MatSnackBarRef } from '@angular/material/snack-bar';
/**
 * Common SnackBar Component
 * Author A.Praveen
 */
@Component({
  selector: 'phase-ii-snackbar',
  templateUrl: './snackbar.component.html',
  styleUrls: ['./snackbar.component.scss'],
})
export class SnackbarComponent implements OnInit {
  /**
 * Variable used for the language translation
 * @type {any}
 */
  translate: any;
  /**
   * Variable used to inject the snackBarRef
   */
  snackBarRef = inject(MatSnackBarRef);
  /**
   * Variable used for Styling the snackbar.
   */
  large: boolean
  /**
   * Variable used for Styling the snackbar.
   */
  ellipse: boolean = false;
  /**
   * Component constructor which is used to inject the required services.
   * @param data The data which is used for displaying the message in Snack Bar.
   * @param translatService used for the translation
   */
  constructor(@Inject(MAT_SNACK_BAR_DATA) public data: any) { }
  /**
   * Component OnInit life cycle hook.
   */
  ngOnInit(): void {
    this.large = (this.data && this.data.msg && this.data.msg.length > 50) ? true : false;
    this.ellipse = (this.data && this.data.msg && this.data.msg.length > 100) ? true : false;
  }
}
