import { Pipe, PipeTransform } from '@angular/core';
/**
 * Pipe used to transform data-control name
 * Author: Balu R
 */
@Pipe({
  name: 'dataControl',
})
export class DataControlPipe implements PipeTransform {
  /**
   * Function which converts the format of a string into camel case.
   * @param value string must be formatted.
   * @param removeChar an array of characters used to split a string. 
   * @returns formatted string.
   */
  transform(value: string, removeChar?: Array<string>): string {
    let dataControlName = value, splitArray = [], regex: RegExp;
    if (!!value) {
      // Create a regex based on the remove character array used to split a string.
      if (removeChar && removeChar.length) {
        if (!removeChar.find((res: string) => (res == ' ' || res == '\\s'))) {
          removeChar.push('\\s');
        }
        regex = new RegExp('[' + removeChar.join('') + ']+');
      } else {
        regex = new RegExp(/\s+/);
      }
      dataControlName = value.trim();
      splitArray = dataControlName.split(regex).map((value: string) => value.toLowerCase()); //It splits the string based on regex and returns an array of lowercase strings.
      if (splitArray && splitArray.length) {
        dataControlName = '';
        splitArray.forEach((element: string) => {
          if (!!element) {
            dataControlName = !dataControlName ? element : dataControlName + element[0].toUpperCase() + element.slice(1);
          }
        });
      }
    }
    return dataControlName;
  }
}
