<div>
  <h4
    [ngStyle]="{'font-family': aboutLocation?.textFontFamily? aboutLocation?.textFontFamily : 'Jost, sans-serif', 'font-weight': aboutLocation?.titleTextStyle?.includes(fontStyleValues?.bold) ? 'bold' : 'normal', 'font-style': aboutLocation?.titleTextStyle?.includes(fontStyleValues?.italic) ? 'italic' : 'normal', 'text-decoration' : aboutLocation?.titleTextStyle?.includes(fontStyleValues?.underline) ? 'underline' : 'none','font-size': aboutLocation?.titleTextSize+'px', 'color': aboutLocation?.titleTextColor}"
    class="location-title">{{aboutLocation?.sectionTitle}}</h4>
  <h2
    [ngStyle]="{'font-family': aboutLocation?.subtextFontFamily? aboutLocation?.subtextFontFamily : 'Jost, sans-serif', 'font-weight': aboutLocation?.subtitleTextStyle?.includes(fontStyleValues?.bold) ? 'bold' : 'normal', 'font-style': aboutLocation?.subtitleTextStyle?.includes(fontStyleValues?.italic) ? 'italic' : 'normal', 'text-decoration' : aboutLocation?.subtitleTextStyle?.includes(fontStyleValues?.underline) ? 'underline' : 'none','font-size': aboutLocation?.subTitleTextSize+'px', 'color': aboutLocation?.subTitleTextColor}"
    class="location-sub-title">{{aboutLocation?.sectionSubTitle}}</h2>
  <div fxLayout="column" *ngFor="let address of locationDetails; let i = index">
    <div *ngFor="let location of aboutLocation.locationDescription; let index = index">
      <div *ngIf="i === index"
        [ngStyle.gt-sm]="{'margin-left': (i % 2 === 0) ? '5%' : '10%', 'margin-right': (i % 2 === 0) ? null : '-8%'}"
        class="location-details-container" fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="5%">
        <div fxFlex="50%" [fxFlexOrder]="(i % 2 === 0) ? '1': '2'" fxFlexOrder.lt-md="2">
          <img class="location-description-img" alt="Location Image"
            [src]="location?.imageUrl.includes('base64') && location?.imageUrl?.includes(',') ? location?.imageUrl : awsUrl+location?.imageUrl">
        </div>
        <div fxLayout="column" fxFlex="32%" fxLayoutAlign="center none" [fxFlexOrder]="(i % 2 === 0) ? '2': '1'"
          fxFlexOrder.lt-md="1">
          <h4 class="established-year">{{location?.title}}</h4>
          <h1 class="description-store-name">{{location?.subtitle}}</h1>
          <p class="description">{{location?.description}}</p>
        </div>
      </div>
    </div>
    <div fxLayout="row" fxLayoutAlign="center center" class="location-background">
      <img class="background-img"
        [src]="address?.backgroundImage?.imageUrl.includes('base64') && address?.backgroundImage?.imageUrl.includes(',') ? address?.backgroundImage?.imageUrl : awsUrl+address?.backgroundImage?.imageUrl"
        alt="Location Background Image">
      <div class="location-card">
        <h3 style="margin-top: 0px">{{address?.locationName}}</h3>
        <div fxLayout="column" fxLayoutGap="3%">
          <span fxLayout="row">
            <mat-icon>location_city</mat-icon>
            <span style="margin-left: 8px">
              <span>{{address?.address?.addressLine2 ? address?.address?.addressLine1+", " :
                address?.address?.addressLine1}}</span><br>
              <span *ngIf="address?.address?.addressLine2">{{address?.address?.city ?
                address?.address?.addressLine2+',': address?.address?.addressLine2}}<br></span>
              <span *ngIf="address?.address?.city"> {{address?.address?.stateDetail?.name ? address?.address?.city+', '
                :
                address?.address?.city}}{{address?.address?.countryDetail?.name ?
                address?.address?.stateDetail?.name+', ':
                address?.address?.stateDetail?.name}}</span><br>
              <span *ngIf="address?.address?.countryDetail?.name">{{address?.address?.zipCode ?
                address?.address?.countryDetail?.name + ' - ':
                address?.address?.countryDetail?.name}}{{address?.address?.zipCode ?
                address?.address?.zipCode : ''}}</span>
            </span>
          </span>
          <span *ngIf="address?.address?.mobileNumber" fxLayout="row">
            <mat-icon>phone</mat-icon>
            <span style="margin-left: 8px">+{{address?.address?.countryCode}}&nbsp; {{ address?.address?.mobileNumber
              }}</span>
          </span>
          <span *ngIf="address?.address?.email" fxLayout="row">
            <mat-icon>email</mat-icon>
            <span style="margin-left: 8px">{{ address?.address?.email }}</span>
          </span>
        </div>
        <ng-container>
          <ng-container *ngIf="address?.closingHoursData && address?.closingHoursData.length > 0">
            <h4 style="margin-bottom: 10px">Working Hours:
            </h4>
            <ng-container *ngFor="let day of address?.closingHoursData">
              <div style="margin-top:5px"> {{day.days}}:&nbsp;{{day.time}}</div>
            </ng-container>
          </ng-container>
          <ng-container *ngIf="address?.shopClosedDays && address?.shopClosedDays.length > 0">
            <h4 style="margin-bottom: 10px">Shop Closed On:
            </h4>
            <ng-container *ngFor="let day of address?.shopClosedDays;let closedDayIndex = index; let lastIndex = last;">
              <span>{{!lastIndex ? weekDays[day] + ', ' :
                weekDays[day]}}</span>
            </ng-container>
          </ng-container>
        </ng-container>
      </div>
    </div>
  </div>
</div>