/**
 * Component which is used for import
 */
import { Component, ElementRef, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SharedConstant } from 'libs/common/src/lib/constants/shared-constant';
import { dataConfig } from 'libs/common/src/lib/services/config';
import { DialogService } from 'libs/common/src/lib/services/dialog.service';
import { Subscription } from 'rxjs';
import { ExcelService } from '../../services/excel.service';
import { UsersService } from '../../services/users.service';
/**
 * Component which is used for import
 */
@Component({
  selector: 'phase-ii-import',
  templateUrl: './import.component.html',
  styleUrls: ['./import.component.scss']
})
/**
 * Component which is used for import
 */
export class ImportComponent extends SharedConstant implements OnInit, OnDestroy {
  /**
   * Array which is used to store all the Subscriptions
  */
  subscriptionObj: Subscription = new Subscription();
  /**
* View child property used to display the get order status.
* @type {TemplateRef}
*/
  @ViewChild('file', { static: false }) file: ElementRef;
  /**
 *Form group used for the refund form.
 */
  importForm!: UntypedFormGroup;
  /**
* Variable which is used to store import file details.
* @type{boolean}
*/
  importFileFormat!: boolean;
  /**
* Variable which is used to store validators pattern details.
* @type{string}
*/
  validator = dataConfig.patternValidators;
  /**
*Variable which is used to store the key value.
*/
  contentData!: { [key: string]: any };
  /**
*variable to store the files to upload.
*/
  filesToUpload: any = [];
  /**
*variable to store the loading value.
*/
  loading: boolean = false;
  /**
*variable to store the upload button value.
*/
  uploadbtn: boolean = true;
  /**
*variable to store the errordata.
*/
  errorData!: any;
  /**
*variable to store the import progress.
*/
  importInProgress = false;
  /**
*variable to store the required error.
*/
  requiredError!: any;
  /**
* Variable to store the template name.
*/
  templateName = 'excel.xlsx';
  /**
*variable to store the successful rows count .
*/
  successCount!: any;
  /**
    * Variable for loader.
  */
  isLoader: boolean = false;
  /**
   * Variable used to check only child components allowed files are upload
   */
  isFileTypeVerified: boolean = true;
  /**
     * Component constructor to inject the required services.
     * @param userService for bulk operation related changes.
     * @param dialogRef for products related service.
     * @param data data which is taken from mat dialog.
     * @param excelSerive for excel related service.
     * @param dialogService To open dialog.
     */
  constructor(
    private userService: UsersService,
    public dialogRef: MatDialogRef<ImportComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    private excelSerive: ExcelService,
    private dialogService: DialogService
  ) {
    super();
  }
  /**
     * Angular life cycle hooks
     */
  ngOnInit(): void {
    this.importForm = new UntypedFormGroup({
      description: new UntypedFormControl(null, [Validators.pattern(this.validator.spaceValidationPattern), Validators.maxLength(250)]),
    });
    this.data.fileName = this.data.fileName ? this.data.fileName + '_sample_template.xlsx' : this.templateName;
    if (this.data && this.data.dropDownDetails)
      this.excelDropdown();
  }
  /**
   * Method to find and the drop down values.
   */
  excelDropdown(): void {
    let dropdownHeaders;
    if (this.data && this.data.dropDownDetails) {
      dropdownHeaders = Object.keys(this.data.dropDownDetails);
    }
    if (this.data && this.data.importHeader && this.data.importHeader.length) {
      for (let i = 0; i < this.data.importHeader.length; i++) {
        this.data.importHeader[i].forEach((item: any) => {
          const dropDownValue = [];
          if (item && !item.dropDownValues) {
            if (dropdownHeaders && dropdownHeaders.length) {
              dropdownHeaders.forEach(dropItem => {
                if (item.key === dropItem) {
                  if (this.data && this.data.dropDownDetails && this.data.dropDownDetails[dropItem] && this.data.dropDownDetails[dropItem].length)
                    this.data.dropDownDetails[dropItem].forEach(responseItem => {
                      dropDownValue.push({
                        name: responseItem[item.key]
                      });
                    });
                }
                item['dropDownValues'] = dropDownValue;
              });
            }
          }
        });
      }
    }
  }
  /**
   * Method to get the sample template text.
   */
  getsampleTemplate(): void {
    this.isLoader = true;
    setTimeout(() => {
      this.excelSerive.import(this.data.importHeader, this.data.fileName, this.data.sheetsName, this.data.title).then((excelResponse) => {
        const blob = new Blob([excelResponse], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64," });
        this.isLoader = false;
        this.excelSerive.downloadFile(blob, this.data.fileName);
      });
    }, 50);
  }
  /**
   * Method to call when click on add file
   */
  onFileSelected(event: any): void {
    this.importFileFormat = false;
    if (event && event.target && event.target.files) {
      for (let i = 0; i < event.target.files.length; i++) {
        if (event.target.files[i] && event.target.files[i].name && this.filesToUpload.indexOf(<File>{ name: event.target.files[i].name }) === -1
          && (this.contentData && (!this.contentData.maxFileCount || this.contentData.maxFileCount > this.filesToUpload.length))) {
          this.filesToUpload.push(event.target.files[i]);
        }
        else {
          this.dialogService.openDialog({
            header: this.dialogHeader.failure,
            message: this.message.maxFileCount.split('{{count}}').join(this.contentData.maxFileCount),
            actionType: this.dialogType.failure,
            button: { right: this.buttonText.ok }
          });
          this.filesToUpload = [];
          this.dialogRef.close(false);
          break;
        }
      }
    }
    this.file.nativeElement.value = '';
  }
  /**
   * Method to call when click on import icon.
   */
  importFile(): void {
    let uploadedFiles = [];
    this.isFileTypeVerified = true;
    if (this.filesToUpload && this.filesToUpload.length) {
      for (let i = 0; i < this.filesToUpload.length; i++) {
        if (this.filesToUpload[i] && this.filesToUpload[i].name && (this.filesToUpload[i].name.split('.').pop() === this.fileFormat.jpg || this.filesToUpload[i].name.split('.').pop() === this.fileFormat.png
          || this.filesToUpload[i].name.split('.').pop() === this.fileFormat.jpeg || this.filesToUpload[i].name.split('.').pop() === this.fileFormat.csv ||
          this.filesToUpload[i].name.split('.').pop() === this.fileFormat.svg || this.filesToUpload[i].name.split('.').pop() === this.fileFormat.xlsx)) {
          //Hanlde uploaded file format is same as chid component given format
          if (this.data && this.data.fileFormat && this.data.fileFormat.length) {
            let findFormat = this.data.fileFormat.find((format: String) => format === this.filesToUpload[i].name.split('.').pop());
            if (!findFormat) {
              this.isFileTypeVerified = false;
            }
            else {
              uploadedFiles.push(this.filesToUpload[i]);
            }
          }
          else {
            uploadedFiles.push(this.filesToUpload[i]);
          }
        } else {
          this.importFileFormat = true;
        }
      }
      if (this.importForm.valid && this.isFileTypeVerified) {
        this.importForm.value.description = this.importForm.value.description && ('' + this.importForm.value.description).trim().length > 0 ? ('' + this.importForm.value.description).trim() : null;
        let formValue = this.importForm.value;
        formValue['imageType'] = this.contentData.imageType;
        formValue['storeId'] = this.contentData && this.contentData.importData && this.contentData.importData.storeId ? Number(this.contentData.importData.storeId) : null;
        formValue['clientId'] = this.contentData && this.contentData.importData && this.contentData.importData.clientId ? this.contentData.importData.clientId : null;
        if ((this.contentData && this.contentData.importApiUrl) && uploadedFiles && uploadedFiles.length > 0) {
          this.loading = true;
          this.subscriptionObj.add(
            this.userService
              .importData(this.contentData.importApiUrl, uploadedFiles, 'postMethod', formValue)
              .subscribe((res: any) => {
                if (res && res['errorLog']) {
                  this.importFileFormat = false;
                  this.loading = false;
                  this.filesToUpload = [];
                  this.uploadbtn = false;
                  this.errorData = res['errorLog'];
                  this.successCount = res['successCount'];
                  this.importInProgress = res['importInProgress'] ? res['importInProgress'] : false
                }
              },
                (err) => {
                  if (err && err.error && err.error.error && err.error.error.message) {
                    this.requiredError = err.error.error.message;
                  }
                  else {
                    this.requiredError = this.dialogMessages.sheetMissing;
                    this.dialogService.dialogMethod(this.dialogMessages.somethingWentWrong, this.dialogType.alert, true);
                  } this.loading = false;
                  this.uploadbtn = false;
                })
          );
        }
      } else if (this.isFileTypeVerified) {
        this.dialogService.dialogMethod(this.dialogMessages.enterValidData, this.dialogType.alert, true);
      }
    }
  }
  /**
   * Method to delete the file.
   */
  onDelete(file: any): void {
    if (this.filesToUpload && this.filesToUpload.indexOf(file) !== -1) {
      this.filesToUpload.splice(this.filesToUpload.indexOf(file), 1);
      this.isFileTypeVerified = true;
    }
    if (this.filesToUpload && this.filesToUpload.length) {
      let fileFormat = this.filesToUpload.find((item) => item && item.name && (item.name.split('.').pop() !== this.fileFormat.jpg &&
        item.name.split('.').pop() !== this.fileFormat.png
        && item.name.split('.').pop() !== this.fileFormat.jpeg
        && item.name.split('.').pop() !== this.fileFormat.csv && item.name.split('.').pop() !== this.fileFormat.svg && item.name.split('.').pop() !== this.fileFormat.xlsx))
      if (fileFormat) {
        this.importFileFormat = true;
      } else {
        this.importFileFormat = false;
      }
    } else {
      this.importFileFormat = false;
    }
  }
  /**
   * Method for ngondestroy.
   */
  ngOnDestroy(): void {
    if (this.subscriptionObj) {
      this.subscriptionObj.unsubscribe();
    }
  }
}
