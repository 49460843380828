<div
    *ngIf="paymentAccountDetails?.pluginCode === dataConfig?.paymentOption?.SQUARE || paymentAccountDetails?.pluginCode === dataConfig?.paymentOption?.STRIPE || paymentAccountDetails?.pluginCode === dataConfig?.paymentOption?.CHECKOUTDOTCOM">
    <div>
        <mat-card class="mat-card-style">
            <div class="mat-dialog-title">
                <div fxLayout="row">
                    <p class="heading">{{'PAYMENT_DETAILS.CARD_DETAILS_INFO' | translate}}</p>
                    <img width="40px" height="35px" src="../assets/credit-card.png" alt="pay" />
                </div>
            </div>
            <mat-card-content class="contant-change" fxLayout="column">
                <ng-container
                    *ngIf="(isManualOrder || !isNewCard) && (paymentAccountDetails?.pluginCode === dataConfig?.paymentOption?.SQUARE || paymentAccountDetails?.pluginCode === dataConfig?.paymentOption?.STRIPE || paymentAccountDetails?.pluginCode === dataConfig?.paymentOption?.CHECKOUTDOTCOM)">
                    <mat-radio-group>
                        <ng-container *ngFor="let card of cardInfo;let i = index">
                            <p class="address-text" fxLayoutAlign="none center">
                                <mat-radio-button [value]="card" [checked]="card?.isDefault"
                                    (change)="selectedCard = card" [attr.data-control]="'card '+card?.id">
                                    <div fxLayoutAlign="none center">
                                        <span *ngIf="cardDetails ">
                                            {{ card?.name }}</span><br>
                                        <span *ngIf="cardDetails ">
                                            xxxx-xxxx-xxxx-{{ card?.card?.lastDigit }},
                                            {{ card?.card?.expMonth }} </span>
                                        <span *ngIf="cardDetails ">
                                            /{{ card?.card?.expYear}},{{card?.card?.brand}} </span>&nbsp;&nbsp;
                                    </div>
                                </mat-radio-button>
                                <span *ngIf="!isManualOrder" class="cursor-pointer">
                                    <mat-icon (click)="deleteSavedCard(i);" data-control="delete card"
                                        class="paydetails-del-card">delete</mat-icon>
                                </span> &nbsp;&nbsp;
                            </p>
                        </ng-container>
                    </mat-radio-group>
                    <div fxLayout="row" *ngIf="!isManualOrder" class="paydetails-not-manorder">
                        <div fxLayout="row" (click)="newCardClicked()" class="man-order-cardclicked">
                            <a data-control="add new card">{{'PAYMENT_DETAILS.ADD_NEW_CARD' | translate}}</a>
                            <mat-icon class="paydetails-add-circle">add_circle</mat-icon>
                        </div>
                    </div>
                    <div class="emptyCard" *ngIf="isManualOrder && isNewCard">
                        {{'PAYMENT_DETAILS.NO_SAVED_CARD' | translate}}
                    </div>
                </ng-container>
                <ng-container *ngIf="!isManualOrder">
                    <div
                        [ngClass]="isNewCard && paymentAccountDetails?.pluginCode === dataConfig?.paymentOption?.STRIPE?'pay-details-display-block':'pay-details-display-none'">
                        <mat-form-field appearance="outline" class="form-width">
                            <mat-label>{{'PAYMENT_DETAILS.CARD_NAME' | translate}}</mat-label>
                            <input matInput [formControl]="cardHoldeName" data-control="stripeCardName" trimVal>
                            <mat-error *ngIf="cardHoldeName?.hasError('required')"
                                data-control="stripeCardNameRequiredError">
                                {{'PAYMENT_DETAILS.CARD_NAME_REQUIRED' | translate}}
                            </mat-error>
                            <mat-error *ngIf="cardHoldeName?.hasError('pattern')"
                                data-control="stripeCardNameInvalidError">
                                {{'PAYMENT_DETAILS.CARD_NAME_INVALID' | translate}}
                            </mat-error>
                        </mat-form-field>
                        <div id="cardInfoGroup" data-control="stripeCardInfo"></div>
                        <mat-error *ngIf="cardErr" data-control="stripe card info error">
                            {{ cardErr }}
                        </mat-error>
                    </div>
                    <div [ngClass]="isNewCard && paymentAccountDetails?.pluginCode === dataConfig?.paymentOption?.SQUARE?'pay-details-display-block':'pay-details-display-none'"
                        fxLayoutGap="15px">
                        <div id="payment-form"
                            *ngIf="paymentAccountDetails?.pluginCode === dataConfig?.paymentOption?.SQUARE">
                            <div id="card-container"></div>
                        </div>
                    </div>
                    <div
                        [ngClass]="isNewCard && paymentAccountDetails?.pluginCode === dataConfig?.paymentOption?.CHECKOUTDOTCOM?'pay-details-display-block':'pay-details-display-none'">
                        <form id="payment-form" fxLayoutGap="15px">
                            <div class="one-liner">
                                <div class="card-frame"></div>
                            </div>
                            <p class="error-message"></p>
                        </form>
                    </div>
                    <div *ngIf="currentUser?.roleCode !== 'GUEST' && isNewCard">
                        <mat-checkbox [formControl]="isSavedCard" data-control="saveCard"> {{'PAYMENT_DETAILS.SAVE_CARD'
                            | translate}}
                        </mat-checkbox><br *ngIf="isSavedCard?.value">
                        <mat-checkbox *ngIf="isSavedCard?.value" (change)="checkDefault($event)" [checked]="defaultCard"
                            data-control="defaultCard">
                            {{'PAYMENT_DETAILS.SET_DEFAULT_CARD' | translate}}
                        </mat-checkbox><br>
                    </div>
                    <ng-container
                        *ngIf="isNewCard && paymentAccountDetails?.pluginCode === dataConfig?.paymentOption?.STRIPE">
                        <div fxLayout="column">
                            <div>
                                <mat-checkbox data-control="billing-address" (change)="setBillingAddress($event)"
                                    [checked]="checkedShippingAddress" data-control="deliveryBillingSame"
                                    *ngIf="shippingType === 'DELIVERY' && shippingAddress?.name">
                                    {{'PAYMENT_DETAILS.SAME_AS_SHIPPING_ADDRESS' | translate}}
                                </mat-checkbox>
                            </div>
                            <div fxLayout="row" (click)="addressDialog()" class="paydetails-ship-addr"
                                *ngIf="!checkedShippingAddress">
                                <a data-control="addCardAddress">{{shippingAddressForm?.valid ? ('PAYMENT_DETAILS.EDIT'
                                    | translate) : ('PAYMENT_DETAILS.ADD' | translate)}}
                                    {{'PAYMENT_DETAILS.CARD_ADRESS' | translate}}</a>
                                <mat-icon class="paydetails-ship-addr-add">add_circle</mat-icon>
                            </div>
                        </div>
                        <p class="paydetails-addr-valid" *ngIf="shippingAddressForm?.valid">
                            {{ shippingAddressForm?.get("addressLine1")?.value
                            + ', ' + shippingAddressForm?.get("city")?.value
                            + ', ' + shippingAddressForm?.get("state")?.value?.name
                            + ', ' + shippingAddressForm?.get("country")?.value?.name
                            + ' - ' + shippingAddressForm?.get("zipCode")?.value + ' ,Mobile : +'
                            + shippingAddressForm?.get("country")?.value?.countryCode + ' '
                            + shippingAddressForm?.get("mobileNumber")?.value }}
                        </p>
                        <p class="paydetails-addr-valid" *ngIf="!shippingAddressForm?.valid">
                            {{'PAYMENT_DETAILS.CARD_ADDRESS_NOT_SPECIFIED' | translate}}
                        </p>
                    </ng-container>
                </ng-container>
            </mat-card-content>
            <ng-container *ngIf="!isManualOrder">
                <div fxLayoutAlign="end end" *ngIf="isNewCard && cardInfo?.length">
                    <button data-control="cancel-1" mat-raised-button
                        (click)="isNewCard = false;isSavedCard?.setValue(false);cardSelected()">
                        {{'PAYMENT_DETAILS.CANCEL' | translate}}
                    </button>&nbsp;&nbsp;
                </div>
                <div *ngIf="paymentAccountDetails?.googlePay">
                    <p class="gpay-or" fxLayoutAlign="center center"> {{'PAYMENT_DETAILS.OR' | translate}} </p>
                </div>
                <div *ngIf="paymentAccountDetails?.googlePay" fxLayoutAlign="center center">
                    <div id="payment-form" data-control="gpay"
                        *ngIf=" paymentAccountDetails?.pluginCode === dataConfig?.paymentOption?.SQUARE">
                        <div id="google-pay-button" (click)="gpayPayment()"></div>
                    </div>
                </div>
                <br>
            </ng-container>
        </mat-card>
    </div>
</div><br>
<div *ngIf="paymentAccountDetails?.pluginCode === dataConfig?.paymentOption?.PAYPAL" class="paypal-button"
    data-control="paypalButton">
    <ngx-paypal [config]="payPalConfig"></ngx-paypal>
    <br>
</div>
<div *ngIf="paymentAccountDetails?.pluginCode === dataConfig?.paymentOption?.AUTHORIZEDOTNET" class="authorize-dot-net"
    fxLayoutAlign="center center">
    <form id="paymentForm">
        <input type="hidden" name="dataValue" id="dataValue" />
        <input type="hidden" name="dataDescriptor" id="dataDescriptor" />
        <button mat-raised-button type="button" data-control="authorize-dot-net" class="AcceptUI"
            data-billingAddressOptions='{"show":true, "required":false}' (click)="authorizeDotNetPayments()"
            [attr.data-apiLoginID]="paymentAccountDetails?.appLoginId"
            [attr.data-clientKey]="paymentAccountDetails?.publicClientKey" data-acceptUIFormBtnTxt="Submit"
            data-acceptUIFormHeaderTxt="Card Information"
            data-responseHandler="authorizeHandler">{{'PAYMENT_DETAILS.PAY' | translate}}
        </button>
    </form>
</div>
<ng-template #payAddress class="pay-template-addr">
    <h3 mat-dialog-title>
        {{'PAYMENT_DETAILS.CARD_TITLE' | translate}}
        <mat-icon class="icon-align" data-control="matIconClose" type="button" (click)="onClose()">close</mat-icon>
    </h3>
    <div mat-dialog-content class="dialog-content-align">
        <form [formGroup]="shippingAddressForm">
            <div fxLayout="row">
                <mat-form-field fxFlex="48%">
                    <mat-label>{{'PAYMENT_DETAILS.NAME_TITLE' | translate}}</mat-label>
                    <input data-control="nameRequired" matInput formControlName="fullName" required trimVal />
                    <mat-error data-control="name-err-1"
                        *ngIf="shippingAddressForm?.get('fullName')?.hasError('required')">
                        {{'PAYMENT_DETAILS.NAME_TITLE' | translate}}{{'PAYMENT_DETAILS.REQUIRED' | translate}}
                    </mat-error>
                    <mat-error data-control="name-err-2"
                        *ngIf="shippingAddressForm?.get('fullName')?.hasError('pattern')">
                        {{'PAYMENT_DETAILS.NAME_TITLE' | translate}}{{'PAYMENT_DETAILS.INVALID' | translate}}
                    </mat-error>
                    <mat-error data-control="name-err-3"
                        *ngIf="shippingAddressForm?.get('fullName')?.hasError('maxlength') && !shippingAddressForm?.get('fullName')?.hasError('pattern')">
                        {{'PAYMENT_DETAILS.NAME_TITLE' | translate}}{{'PAYMENT_DETAILS.EXCEEDS' | translate}}
                    </mat-error>
                </mat-form-field>
                <div fxFlex="48%" fxFlexOffset="5">
                    <mat-form-field fxFlex="40">
                        <mat-label>{{'PAYMENT_DETAILS.COUNTRY_CODE_TITLE' | translate}}</mat-label>
                        <input matInput value="+" class="paydetails-countrcode-input" readonly="true" />
                        <input data-control="countryCode" placeholder="Code" matInput class="paydetails-country-value"
                            [value]="shippingAddressForm.get('country')?.value?.countryCode" readonly="true" />
                    </mat-form-field>
                    <mat-form-field fxFlex="60" fxFlexOffset="5">
                        <mat-label>{{'PAYMENT_DETAILS.MOBILE_TITLE' | translate}}</mat-label>
                        <input data-control="mobileNumRequired" matInput formControlName="mobileNumber" required
                            trimVal />
                        <mat-error data-control="mobile-err-1"
                            *ngIf="shippingAddressForm?.get('mobileNumber')?.hasError('required')">
                            {{'PAYMENT_DETAILS.MOBILE_TITLE' | translate}}{{'PAYMENT_DETAILS.REQUIRED' | translate}}
                        </mat-error>
                        <mat-error data-control="mobile-err-2" *ngIf="shippingAddressForm?.get('mobileNumber')?.hasError('pattern')
                        ||shippingAddressForm.get('mobileNumber').hasError('maxlength')">
                            {{'PAYMENT_DETAILS.MOBILE_TITLE' | translate}}{{'PAYMENT_DETAILS.INVALID' | translate}}
                        </mat-error>
                    </mat-form-field>
                </div>
            </div>
            <div fxLayout="row">
                <mat-form-field fxFlex="48%">
                    <mat-label>{{'PAYMENT_DETAILS.COUNTRY_TITLE' | translate}}</mat-label>
                    <mat-select required (selectionChange)="getStates()" formControlName="country"
                        data-control="countryState" (closed)="onCountryFilterClose()">
                        <div fxLayout="row" class="search-country paydetails-search-country1">
                            <input matInput fxFlex="90%" fxFlex.lt-md="92%" fxFlex.lt-sm="85%"
                                class="paydetails-country-filter" data-control="searchCountryFl"
                                placeholder="Search country" [formControl]="countrySearchFilter"
                                (keydown)="$event?.stopPropagation()"
                                (input)="onCountrySearchFilter($event?.target?.value)" trimVal>
                            <mat-icon class="paydetails-filter-close" *ngIf="countrySearchFilter?.value" fxFlex="10%"
                                fxFlex.lt-md="8%" fxFlex.lt-sm="15%" (click)="onCountryFilterClose()"
                                data-control="closeIconCountry">close</mat-icon>
                        </div>
                        <mat-divider></mat-divider>
                        <mat-option data-control="no-country" *ngIf="searchCountries?.length === 0">
                            {{'PAYMENT_DETAILS.NO_COUNTRY' | translate}}
                        </mat-option>
                        <mat-option data-control="has-country" *ngFor="let country of searchCountries"
                            [value]="country">{{country?.name}}</mat-option>
                    </mat-select>
                    <mat-error data-control="country-err-1"
                        *ngIf="shippingAddressForm?.get('country')?.hasError('required')">
                        {{'PAYMENT_DETAILS.COUNTRY_TITLE' | translate}}{{'PAYMENT_DETAILS.REQUIRED' | translate}}
                    </mat-error>
                    <mat-error data-control="country-err-2"
                        *ngIf="shippingAddressForm?.get('country')?.hasError('pattern')">
                        {{'PAYMENT_DETAILS.COUNTRY_TITLE' | translate}}{{'PAYMENT_DETAILS.INVALID' | translate}}
                    </mat-error>
                    <mat-error data-control="country-err-3" *ngIf="shippingAddressForm?.get('country')?.hasError('invalidInput') && 
                !shippingAddressForm?.get('country')?.hasError('required')">
                        {{'PAYMENT_DETAILS.COUNTRY_TITLE' | translate}}{{'PAYMENT_DETAILS.INVALID' | translate}}
                    </mat-error>
                </mat-form-field>
                <mat-form-field fxFlexOffset="5" fxFlex="48%">
                    <mat-label>{{'PAYMENT_DETAILS.STATE_TITLE' | translate}}</mat-label>
                    <mat-select required class="paydetails-state-country" data-control="stateCountry"
                        (closed)="clearStateFilter()"
                        [attr.disabled]="shippingAddressForm?.get('country')?.value ? false:true"
                        (selectionChange)="clearStateFilter()" formControlName="state">
                        <div fxLayout="row" class="search-country paydetails-search-country2">
                            <input matInput class="pay-details-state-search" fxFlex="90%" placeholder="Search state"
                                [formControl]="stateSearchFilter" data-control="stateSearch"
                                (keydown)="$event?.stopPropagation()"
                                (input)="onStateSearchFilter($event?.target?.value)" autocomplete="off" trimVal>
                            <mat-icon class="pay-clear-state-filter" fxFlex="10%" (click)="clearStateFilter()"
                                data-control="closeiconState" *ngIf="stateSearchFilter?.value">close</mat-icon>
                        </div>
                        <mat-divider></mat-divider>
                        <mat-option data-control="no-state" *ngIf="searchStates?.length === 0">
                            {{'PAYMENT_DETAILS.NO_STATE' | translate}}
                        </mat-option>
                        <mat-option data-control="has-state" *ngFor="let state of searchStates" [value]="state">
                            {{state?.name}}</mat-option>
                    </mat-select>
                    <mat-error data-control="state-err-1"
                        *ngIf="shippingAddressForm?.get('state')?.hasError('required')">
                        {{'PAYMENT_DETAILS.STATE_TITLE' | translate}}{{'PAYMENT_DETAILS.REQUIRED' | translate}}
                    </mat-error>
                    <mat-error data-control="state-err-2"
                        *ngIf="shippingAddressForm?.get('state')?.hasError('pattern')">
                        {{'PAYMENT_DETAILS.STATE_TITLE' | translate}}{{'PAYMENT_DETAILS.INVALID' | translate}}
                    </mat-error>
                    <mat-error data-control="country-err-3" *ngIf="shippingAddressForm?.get('state')?.hasError('invalidInput') && 
                !shippingAddressForm?.get('state')?.hasError('required')">
                        {{'PAYMENT_DETAILS.STATE_TITLE' | translate}}{{'PAYMENT_DETAILS.INVALID' | translate}}
                    </mat-error>
                </mat-form-field>
            </div>
            <div fxLayout="row">
                <mat-form-field fxFlex="48%">
                    <mat-label>{{'PAYMENT_DETAILS.CITY_TITLE' | translate}}</mat-label>
                    <input data-control="cityRequired" matInput formControlName="city" required trimVal />
                    <mat-error data-control="city-err-1" *ngIf="shippingAddressForm?.get('city')?.hasError('required')">
                        {{'PAYMENT_DETAILS.CITY_TITLE' | translate}}{{'PAYMENT_DETAILS.REQUIRED' | translate}}
                    </mat-error>
                    <mat-error data-control="city-err-2" *ngIf="shippingAddressForm?.get('city')?.hasError('pattern')">
                        {{'PAYMENT_DETAILS.CITY_TITLE' | translate}}{{'PAYMENT_DETAILS.INVALID' | translate}}
                    </mat-error>
                    <mat-error data-control="city-err-3"
                        *ngIf="shippingAddressForm?.get('city')?.hasError('maxlength') && !shippingAddressForm?.get('city')?.hasError('pattern')">
                        {{'PAYMENT_DETAILS.CITY_TITLE' | translate}}{{'PAYMENT_DETAILS.EXCEEDS' | translate}}
                    </mat-error>
                </mat-form-field>
                <mat-form-field fxFlex="48%" fxFlexOffset="5">
                    <mat-label>{{'PAYMENT_DETAILS.ZIP_CODE_TITLE' | translate}}</mat-label>
                    <input data-control="codeRequired" matInput formControlName="zipCode" required trimVal />
                    <mat-error data-control="code-err-1"
                        *ngIf="shippingAddressForm?.get('zipCode')?.hasError('required')">
                        {{'PAYMENT_DETAILS.ZIP_CODE_TITLE' | translate}}{{'PAYMENT_DETAILS.REQUIRED' | translate}}
                    </mat-error>
                    <mat-error data-control="code-err-2" *ngIf="shippingAddressForm?.get('zipCode')?.hasError('pattern')
                        ||shippingAddressForm.get('zipCode').hasError('maxlength')">
                        {{'PAYMENT_DETAILS.ZIP_CODE_TITLE' | translate}}{{'PAYMENT_DETAILS.INVALID' | translate}}
                    </mat-error>
                    <mat-error data-control="zipCodeError1"
                        *ngIf="shippingAddressForm?.get('zipCode')?.hasError('USZipCode') ">
                        {{'PAYMENT_DETAILS.ZIP_CODE_TITLE' | translate}}{{'PAYMENT_DETAILS.INVALID' | translate}}
                    </mat-error>
                    <mat-error data-control="zipCodeError2"
                        *ngIf="shippingAddressForm?.get('zipCode')?.hasError('INZipCode')">
                        {{'PAYMENT_DETAILS.ZIP_CODE_TITLE' | translate}}{{'PAYMENT_DETAILS.INVALID' | translate}}
                    </mat-error>
                </mat-form-field>
            </div>
            <div fxLayout="row">
                <mat-form-field fxFlexFill>
                    <mat-label>{{'PAYMENT_DETAILS.ADDRESS_TITLE' | translate}}</mat-label>
                    <textarea data-control="addressRequired" matInput formControlName="addressLine1" required
                        trimVal></textarea>
                    <mat-error data-control="address1-err-1"
                        *ngIf="shippingAddressForm?.get('addressLine1')?.hasError('required')">
                        {{'PAYMENT_DETAILS.ADDRESS_TITLE' | translate}}{{'PAYMENT_DETAILS.REQUIRED' | translate}}
                    </mat-error>
                    <mat-error data-control="address1-err-2"
                        *ngIf="shippingAddressForm?.get('addressLine1')?.hasError('pattern')">
                        {{'PAYMENT_DETAILS.ADDRESS_TITLE' | translate}}{{'PAYMENT_DETAILS.INVALID' | translate}}
                    </mat-error>
                    <mat-error data-control="address1-err-3"
                        *ngIf="shippingAddressForm?.get('addressLine1')?.hasError('maxlength')">
                        {{'PAYMENT_DETAILS.ADDRESS_TITLE' | translate}}{{'PAYMENT_DETAILS.EXCEEDS' | translate}}
                    </mat-error>
                </mat-form-field>
            </div>
            <div fxLayout="row">
                <div fxFlex="80" fxLayoutAlign="center center">
                </div>
                <div fxFlex="30" fxLayoutAlign="center center">
                    <button mat-raised-button data-control="saveButton" (click)="savePaymentAddress()"
                        [ngClass]="{'fill-btn-color':shippingAddressForm?.valid}"
                        [disabled]="!shippingAddressForm?.valid">
                        {{billingAddress?.fullName?
                        ('PAYMENT_DETAILS.UPDATE' | translate):('PAYMENT_DETAILS.SAVE' | translate)}}</button>
                </div>
            </div>
        </form>
    </div>
</ng-template>