/**
 * List of subscribed plugins
 * Author: Balasubramanian M
 */
import { Subscription } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { AuthService } from '@phase-ii/auth';
import { DialogService } from '@phase-ii/common';
import { ButtonInfo, CommonService } from '@phase-ii/shared';
import { Component, EventEmitter, Output } from '@angular/core';
import { CommonAdminShardConstants } from '../../constants/shared-constant'
import { pluginList, pluginListByPlanName, updatePlugin } from '../../models/subscribed-plugin-list';

@Component({
  selector: 'phase-ii-subscribed-plugin-list',
  templateUrl: './subscribed-plugin-list.component.html',
  styleUrls: ['./subscribed-plugin-list.component.scss'],
})
export class SubscribedPluginListComponent {
  /**
   * Variable to store what buttons to display 
   * @type {ButtonInfo}
   */
  buttonInfo: ButtonInfo[] =
    [
      {
        name: 'Cancel', class: 'secondary-button', method: 'cancel'
      },
      {
        name: 'Next', class: 'primary-button', method: 'onSave'
      },
    ];
  /**
   * Variable used to emit the value to parent component
   */
  @Output() pluginChoosen = new EventEmitter<boolean>();
  /**
   * Variable used to store currency unit
   * @type {string}
   */
  currency: string;
  /**
   * Variable used to store assets url
   * @type {string}
   */
  assetsUrl: string;
  /**
   * Subscription object
   * @type {Subscription}
   */
  subscriptionObj = new Subscription();
  /**
   * Variable used to show or hide the loader
   * @type {boolean}
   */
  isLoader: boolean = false;
  /**
   * Variable used to store the constant
   */
  constant = new CommonAdminShardConstants();
  /**
   * Variable used to store pluginDetails
   * @type {pluginListByPlanName}
   */
  pluginArray: pluginListByPlanName;
  /**
   * Variable used to get store id
   * @type {number}
   */
  storeId: number;
  /**
   * Variable used to store plugin plans
   * @type {Array<string>}
   */
  planCount: Array<string>;

  /**
   * Component constructor to inject the required services.
   * @param authService 
   * @param commonService
   * @param commonService
   */
  constructor(
    private authService: AuthService,
    private dialogService: DialogService,
    private commonService: CommonService
  ) { }
  /**
   * Angular lifecycle begins
   */
  ngOnInit(): void {
    this.isLoader = true;
    this.assetsUrl = this.commonService && this.commonService.environment && this.commonService.environment.AWSImageUrl;
    this.getPluginDetails();
  }
  /**
   * Method used to get subscription plugin details
   */
  getPluginDetails(): void {
    this.subscriptionObj.add(this.authService.user.pipe(mergeMap((res: any) => {
      this.storeId = res && res.storeId;
      this.currency = res && res.purchasedCurrency && res.purchasedCurrency.currencySymbol;
      return this.commonService.getPluginSubscriptionDetails(this.storeId);
    },)).subscribe({
      next: (res: pluginList) => {
        if (res && res.pluginSubscriptionDetails && res.pluginSubscriptionDetails.count > 0) {
          let response = res && res.pluginSubscriptionDetails && res.pluginSubscriptionDetails.rows;
          this.planCount = [...new Set(response.map(name => name.pluginPlan.pluginPlanType.planName))].sort();
          response.sort((a, b) => a.plugin.pluginName.localeCompare(b.plugin.pluginName));
          this.planCount.forEach((planName: string) => {
            this.pluginArray = { ...this.pluginArray, [planName]: response.filter(plugin => plugin.pluginPlan.pluginPlanType.planName === planName) };
          });
          this.isLoader = false;
        } else {
          this.onSave();
          this.isLoader = false;
        }
      },
      error: (err => {
        this.isLoader = false;
        this.dialogService.openDialog({
          header: this.constant.dialogMessages.failureHeader,
          message: this.constant.dialogMessages.getPluginFailed,
          actionType: this.constant.dialogType.failure,
          button: { right: 'Okay' }
        });
      })
    }))
  }
  /**
   * Method for calculate plugin amount
   * @param id 
   * @param planName 
   */
  calculate(id: number, planName: string): void {
    if (id >= 0 && planName) {
      this.isLoader = true;
      const data = {
        storeId: this.storeId,
        id: this.pluginArray[planName][id].id,
        isRenewal: !this.pluginArray[planName][id].isRenewal
      };
      this.subscriptionObj.add(this.commonService.calculatePluginSubscription(data).subscribe({
        next: (res: updatePlugin) => {
          if (res && res.success) {
            this.getPluginDetails();
          }
        },
        error: (err) => {
          this.getPluginDetails();
          this.dialogService.openDialog({
            header: this.constant.dialogType.failure,
            message: this.constant.dialogMessages.updateFailed,
            actionType: this.constant.dialogType.failure,
            button: { right: 'Okay' },
          });
        }
      }));
    };
  }
  /**
   * Method used to call the respective button function
   * @param event
   */
  functionTriggered(event: string): void {
    if (event)
      this[event]();
  }
  /**
   * Method used to save the subscribed plugin details
   */
  onSave(): void {
    this.pluginChoosen.emit(true);
  }
  /**
   * Method to cancel plugin subscription
   */
  cancel(): void {
    this.pluginChoosen.emit(false);
  }
  /**
   * Unsubscribe all the subscriptions
   */
  ngOnDestroy(): void {
    this.subscriptionObj.unsubscribe();
  }
}
