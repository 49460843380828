import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PayoutLogsListComponent } from './components/payout-logs-list/payout-logs-list.component';
import { SharedModule } from '@phase-ii/shared';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CommonAdminModuleModule } from '@phase-ii/common-admin-module';

@NgModule({
  declarations: [PayoutLogsListComponent],
  imports: [
    CommonModule,
    // CoreModule,
    BrowserAnimationsModule,
    CommonAdminModuleModule,
    SharedModule
  ]
})
export class PayoutLogsModule { }

