<cen-loader-vii *ngIf="pageDetails?.isLoader">
</cen-loader-vii>
<phase-ii-header-page [buttonInfo]="pageDetails?.buttonInfo" (methodClicked)="buttonAction($event)"
    [heading]="pageDetails?.heading">
</phase-ii-header-page>
<form class="page-container" [formGroup]="userForm" *ngIf="userForm" fxLayout="column" fxLayoutGap="40px">
    <mat-card fxLayout="column" fxLayoutGap="12px">
        <div fxFlex="60" *ngIf="!pageDetails?.userId" fxFlex.lt-md="100" class="general-details">
            <strong>General Details</strong>
        </div>
        <div fxLayout="row" *ngIf="pageDetails?.userId" fxLayout.lt-md="column" fxLayoutAlign="center">
            <div fxFlex="60" fxFlex.lt-md="100" class="genaral-user">
                <strong>General Details</strong>
            </div>
            <div fxFlex="40" fxFlex.lt-md="100" fxLayoutAlign="end center" class="Active" fxLayoutGap="10px">
                <mat-label>Active</mat-label>
                <mat-slide-toggle data-control="Active" formControlName="Active"></mat-slide-toggle>
            </div>
        </div>
        <div fxLayoutAlign="center" fxLayout="column" *ngIf="userForm" fxLayoutGap="10px" fxLayoutAlign.lt-md=" center">
            <div class="mat-elevation-z0" [ngClass.lt-md]="'addressDetails-card-two'"
                [ngClass.gt-md]="'addressDetails-card'" [ngClass.md]="'addressDetails-card-two'">
                <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap.gt-md="10px" fxLayoutGap.md="20px">
                    <div fxLayout="column" fxFlex="90%">
                        <div fxLayout="row" fxFlex="90%" fxLayoutAlign="center center">
                            <mat-form-field fxFlex="90%">
                                <mat-label>User name</mat-label>
                                <input data-control="userName" matInput formControlName="username" required />
                                <mat-error data-control="userNamealreadyExist"
                                    *ngIf="userForm.controls?.username?.hasError('pattern')&&!userForm?.controls?.username?.hasError('maxlength') ">
                                    {{addEditUserConstant?.validators?.pattern}}
                                </mat-error>
                                <mat-error data-control="userNameRequiredKey"
                                    *ngIf="userForm?.controls?.username?.errors?.required">
                                    {{addEditUserConstant?.validators?.userNameRequired}}
                                </mat-error>
                                <mat-error data-control="userNameLengthKey"
                                    *ngIf="userForm?.controls?.username?.hasError('maxlength')">
                                    {{addEditUserConstant?.validators?.nameLengthExceeds}}
                                </mat-error>
                            </mat-form-field>
                        </div>
                    </div>
                    <div fxFlex="90%" fxLayout="row" fxLayoutAlign="center center">
                        <mat-form-field fxFlex="90%">
                            <mat-label>Email</mat-label>
                            <input data-control="email" [readonly]=pageDetails?.userId matInput formControlName="email"
                                required />
                            <mat-error data-control="emailRequired" *ngIf="userForm?.controls?.email?.errors?.required">
                                {{addEditUserConstant?.validators?.fieldRequired.replace('This field','Email')}}
                            </mat-error>
                            <mat-error data-control="emailInvalid" *ngIf="userForm?.controls?.email?.errors?.pattern">
                                {{addEditUserConstant?.validators?.email_pattern}}
                            </mat-error>
                            <mat-error data-control="emailExist"
                                *ngIf="userForm?.controls?.email?.errors?.emailAlreadyExists===false">
                                {{addEditUserConstant?.validators?.emailAlreadyExists}}
                            </mat-error>
                        </mat-form-field>
                    </div>
                </div>
                <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap.gt-md="10px" fxLayoutGap.md="20px">
                    <div fxFlex="90%" fxLayout="row" fxLayoutAlign="center center">
                        <mat-form-field fxFlex="90%">
                            <mat-label>Address line1</mat-label>
                            <input data-control="addressLine1" matInput formControlName="addressLine1" />
                            <mat-error data-control="addressLine1RequiredError"
                                *ngIf="userForm?.get('addressLine1')?.hasError('required')">
                                {{addEditUserConstant?.validators?.addressRequired}}
                            </mat-error>
                            <mat-error data-control="addressLine1MaxlengthError"
                                *ngIf="userForm?.get('addressLine1')?.hasError('maxlength') && !userForm?.get('addressLine1')?.hasError('pattern')">
                                {{addEditUserConstant?.validators?.addressMaxlength1}}
                            </mat-error>
                            <mat-error data-control="addressLine1PatternError"
                                *ngIf="userForm?.get('addressLine1')?.hasError('pattern')">
                                {{addEditUserConstant?.validators?.addressInvalid}}
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div fxFlex="90%" fxLayout="row" fxLayoutAlign="space-evenly start">
                        <mat-form-field fxFlex="90%">
                            <mat-label>Address line2</mat-label>
                            <input data-control="addressLine2" matInput formControlName="addressLine2" />
                            <mat-error data-control="addressLine2MaxlengthError"
                                *ngIf="userForm?.get('addressLine2')?.hasError('maxlength') && !storeLocationForm?.get('addressLine2')?.hasError('pattern')">
                                {{addEditUserConstant?.validators?.addressMaxlength2}}
                            </mat-error>
                            <mat-error data-control="addressLine2PatternError"
                                *ngIf="userForm?.get('addressLine2')?.hasError('pattern')">
                                {{addEditUserConstant?.validators?.addressInvalid}}
                            </mat-error> </mat-form-field>
                    </div>
                </div>
                <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap.gt-md="10px" fxLayoutGap.md="20px">
                    <div fxFlex="90%" fxLayout="row" fxLayoutAlign="center center">
                        <mat-form-field fxFlex="90%">
                            <mat-label>Country</mat-label>
                            <mat-select data-control="country" (openedChange)="$event===false?clearSearch():null"
                                required formControlName="country"
                                (selectionChange)="getStates($event?.source?.value?.id,i,$event?.source?.value)">
                                <div fxLayout="row" class="dropdown-search">
                                    <input matInput placeholder="Search country" data-control="filterCountry"
                                        [formControl]="filterCountryState"
                                        (keydown)="$event?.stopPropagation();filterCountryAndState($event?.target?.value,'countryArray','name')" />
                                    <mat-icon matSuffix data-control="countryCloseIcon"
                                        *ngIf="filterCountryState?.value" class="close-icon"
                                        (click)="clearSearch()">close
                                    </mat-icon>
                                </div>
                                <mat-divider></mat-divider>
                                <div fxLayoutAlign="center center">
                                    <p *ngIf="pageDetails?.countryArray?.length == 0">No country was
                                        found.
                                    </p>
                                </div>
                                <mat-option [value]="country"
                                    *ngFor="let country of pageDetails?.countryArray;index as k">
                                    {{country?.name}}</mat-option>
                            </mat-select>
                            <mat-error data-control="countryError"
                                *ngIf="userForm?.get('country')?.hasError('required')">
                                {{addEditUserConstant?.validators?.countryRequired}}
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div fxFlex="90%" fxLayout="row" fxLayoutAlign="space-evenly start">
                        <mat-form-field fxFlex="90%">
                            <mat-label>State</mat-label>
                            <mat-select data-control="state" required formControlName="state">
                                <div fxLayout="row" class="dropdown-search">
                                    <input matInput data-control="filterCountryState" placeholder="Search state"
                                        [formControl]="filterCountryState"
                                        (keydown)="$event?.stopPropagation();filterCountryAndState($event?.target?.value,'stateArray','name')" />
                                    <mat-icon matSuffix class="close-icon" data-control="stateCloseIcon"
                                        *ngIf="filterCountryState?.value" (click)="clearSearch()">close
                                    </mat-icon>
                                </div>
                                <mat-divider></mat-divider>
                                <div fxLayoutAlign="center center">
                                    <p *ngIf="pageDetails?.stateArray?.length == 0">No state was found.
                                    </p>
                                </div>
                                <mat-option [value]="state" [attr.data-control]="'state'+state?.name"
                                    *ngFor="let state of pageDetails?.stateArray;index as k">
                                    {{state?.name}}</mat-option>
                            </mat-select>
                            <mat-error data-control="stateError" *ngIf="userForm?.get('state')?.hasError('required')">
                                {{addEditUserConstant?.validators?.stateRequired}}
                            </mat-error>
                        </mat-form-field>
                    </div>
                </div>
                <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap.gt-md="10px" fxLayoutGap.md="20px">
                    <div fxFlex="90%" fxLayout="row" fxLayoutAlign="center center">
                        <mat-form-field fxFlex="90%">
                            <mat-label>City</mat-label>
                            <input data-control="city" matInput formControlName="city" />
                            <mat-error data-control="cityRequiredError"
                                *ngIf="userForm?.get('city')?.hasError('required')">
                                {{addEditUserConstant?.validators?.cityRequired}}
                            </mat-error>
                            <mat-error data-control="cityPatternError"
                                *ngIf="userForm?.get('city')?.hasError('pattern')">
                                {{addEditUserConstant?.validators?.cityInvalid}}
                            </mat-error>
                            <mat-error data-control="cityMaxlengthError" *ngIf="userForm?.get('city')?.hasError('maxlength') &&
                                                !userForm?.get('city')?.hasError('pattern')">
                                {{addEditUserConstant?.validators?.cityMaxlength}}
                            </mat-error> </mat-form-field>
                    </div>
                    <div fxFlex="90%" fxLayout="row" fxLayoutAlign="space-evenly start">
                        <mat-form-field fxFlex="90%">
                            <mat-label>Postal code</mat-label>
                            <input data-control="zipCode" matInput formControlName="zipCode" />
                            <mat-error data-control="zipCodeRequiredError"
                                *ngIf="userForm?.get('zipCode')?.hasError('required')">
                                {{addEditUserConstant?.validators?.zipcodeRequired}}
                            </mat-error>
                            <mat-error data-control="zipCodeError-maxlength"
                                *ngIf="userForm?.get('zipCode')?.hasError('maxlength')">
                                {{addEditUserConstant?.validators?.zipcodeMaxLengthExceed}}
                            </mat-error>
                        </mat-form-field>
                    </div>
                </div>
                <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap.gt-md="10px" fxLayoutGap.md="20px">
                    <div fxFlex="50%" fxLayout="row" fxLayoutAlign="center center">
                        <mat-form-field fxFlex="90%">
                            <mat-label>Contact number</mat-label>
                            <input data-control="contactNumber" matInput formControlName="contactNumber" />
                            <mat-error data-control="contactNumberError"
                                *ngIf="userForm?.get('contactNumber')?.hasError('pattern')">
                                {{addEditUserConstant?.validators?.invalidMobileNumber}}
                            </mat-error>
                            <mat-error data-control="contactNumberrequiredError"
                                *ngIf="userForm?.get('contactNumber')?.hasError('required')">
                                {{addEditUserConstant?.validators?.ContactNumber}}
                            </mat-error>
                            <mat-error data-control="contactNumberAlreadyExist"
                                *ngIf="userForm?.get('contactNumber')?.hasError('mobileNumberAlreadyExists')">
                                {{addEditUserConstant?.validators?.ContactNumberAlreadyExist}}
                            </mat-error>
                            <mat-error data-control="contactLengthError"
                                *ngIf="userForm?.get('contactNumber')?.errors?.maxlength && !userForm?.get('contactNumber')?.hasError('pattern')">
                                {{addEditUserConstant?.validators?.phoneNumberExceeds}}
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div fxFlex="90%" fxLayout="row" fxLayoutAlign="space-evenly start" *ngIf="!pageDetails.userId">
                        <mat-form-field fxFlex="90%">
                            <mat-label>Password</mat-label>
                            <input data-control="password" autocomplete="new-password"
                                [readonly]="pageDetails?.isReadOnly" appearance="legacy"
                                [type]="pageDetails?.isPasswordVisible?'text':'password'" matInput
                                formControlName="password" required />
                            <mat-icon matSuffix class="cursor-pointer visibility-icon" data-control="passwordVisible"
                                (click)="pageDetails.isPasswordVisible=!pageDetails.isPasswordVisible">
                                {{pageDetails?.isPasswordVisible?'visibility' : 'visibility_off'}}</mat-icon>
                            <mat-error data-control="passwordRequired"
                                *ngIf="userForm?.get('password')?.hasError('required')">
                                {{addEditUserConstant?.validators?.fieldRequired.replace('This field','Password')}}
                            </mat-error>
                            <mat-error data-control="PasswordError"
                                *ngIf="userForm?.get('password')?.hasError('pattern')">
                                {{addEditUserConstant?.validators?.passwordValidation}}
                            </mat-error> </mat-form-field>
                    </div>
                </div>
            </div><br>
        </div><br>
    </mat-card>
</form>
<form class="page-container" [formGroup]="commissionDetailsForm" *ngIf="commissionDetailsForm" fxLayout="column"
    fxLayoutGap="30px">
    <mat-card fxLayout="column" fxLayoutGap="20px">
        <div fxLayout="row" fxFlex="50" fxFlex.lt-md="100" fxLayoutGap="10px" class="other-details">
            <strong class="Other_details">Commission details</strong>
            <mat-icon matSuffix class="show-cursor" matTooltip="Configure your commission preferences here">info
            </mat-icon>
        </div>
        <div fxLayoutAlign="center" fxLayout="column" *ngIf="commissionDetailsForm" fxLayoutGap="10px"
            fxLayoutAlign.lt-md="center">
            <div class="mat-elevation-z0" [ngClass.lt-md]="'addressDetails-card-two'"
                [ngClass.gt-md]="'addressDetails-card'" [ngClass.md]="'addressDetails-card-two'">
                <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap.gt-md="10px" fxLayoutGap.md="20px">
                    <div fxLayout="column" fxFlex="90%">
                        <div fxLayout="row" fxFlex="90%" fxLayoutAlign="center center"> <mat-form-field fxFlex="90%">
                                <mat-label>Commission Type</mat-label>
                                <mat-select (selectionChange)="changeValueCommission()" data-control="commissionType"
                                    formControlName="commissionType">
                                    <mat-option [attr.data-control]="status" *ngFor="let status of Types"
                                        [value]="status.name">
                                        {{status.name}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </div>
                    <div fxFlex="90%" fxLayout="row" fxLayoutAlign="center center">
                        <mat-form-field fxFlex="90%">
                            <mat-label>Commission Value</mat-label>
                            <input type="number" data-control="commissionValue" matInput
                                formControlName="commissionValue" required />
                            <mat-error data-control="commissionRequiredError"
                                *ngIf="commissionDetailsForm?.get('commissionValue')?.hasError('required')">
                                {{addEditUserConstant?.validators?.commissionRequired}}
                            </mat-error>
                            <mat-error data-control="commissiontMinError"
                                *ngIf="commissionDetailsForm?.get('commissionValue')?.hasError('min')">
                                {{commissionDetailsForm?.get('commissionType')?.value==='Fixed'?
                                addEditUserConstant?.validators?.commissionMinFixed:
                                addEditUserConstant?.validators?.commissionMinPercentage
                                }}
                            </mat-error>
                            <mat-error data-control="commissionMaxError"
                                *ngIf="this.commissionDetailsForm?.get('commissionValue')?.hasError('max')">
                                {{commissionDetailsForm?.get('commissionType')?.value==='Fixed'?
                                addEditUserConstant?.validators?.commissionMaxFixed:
                                addEditUserConstant?.validators?.commissionMaxPercentageValue
                                }}
                            </mat-error>
                            <mat-error data-control="holdAmountPatternError"
                                *ngIf="this.commissionDetailsForm?.get('commissionValue')?.hasError('pattern') &&
                                                                        !this.commissionDetailsForm?.get('commissionValue')?.hasError('max') &&
                                                                         !commissionDetailsForm?.get('commissionValue')?.hasError('min')">
                                {{commissionDetailsForm?.get('commissionType')?.value==='Fixed'?
                                addEditUserConstant?.validators?.commissionPatternfixed:
                                addEditUserConstant?.validators?.commissionminPatternPercentage
                                }}
                            </mat-error>
                        </mat-form-field>
                    </div>
                </div>
                <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap.gt-md="10px" fxLayoutGap.md="20px">
                    <div fxLayout="column" fxFlex="50%">
                        <div fxLayout="row" fxLayoutAlign="center center" fxFlex="100%">
                            <mat-form-field fxFlex="90%">
                                <mat-label>Commission Withdrawal Threshold</mat-label>
                                <input data-control="thresholdValue" matInput formControlName="thresholdValue"
                                    required />
                                <mat-error data-control="thresholdRequired"
                                    *ngIf="this.commissionDetailsForm?.get('thresholdValue')?.errors?.required">
                                    {{addEditUserConstant?.validators?.thersholdRequired}}
                                </mat-error>
                                <mat-error data-control="thresholdMaxError"
                                    *ngIf="this.commissionDetailsForm?.get('thresholdValue')?.hasError('max')">
                                    {{addEditUserConstant?.validators?.thersholdLesser}}
                                </mat-error>
                                <mat-error data-control="thresholdMinError"
                                    *ngIf="this.commissionDetailsForm?.get('thresholdValue')?.hasError('min')">
                                    {{addEditUserConstant?.validators?.thersholdGreater}}
                                </mat-error>
                                <mat-error data-control="thresholdpatternError" *ngIf="this.commissionDetailsForm?.get('thresholdValue')?.hasError('pattern') &&
                                            !this.commissionDetailsForm?.get('thresholdValue')?.hasError('max') &&
                                            !this.commissionDetailsForm?.get('thresholdValue')?.hasError('min')">
                                    {{addEditUserConstant?.validators?.thresholdPattern}}
                                </mat-error>
                            </mat-form-field>
                        </div>
                    </div>
                </div>
            </div><br>
        </div><br>
    </mat-card>
</form>

<form class="page-container" [formGroup]="commissionDetailsForm" *ngIf="commissionDetailsForm && pageDetails?.discount"
    fxLayout="column" fxLayoutGap="30px">
    <mat-card class="card-style" fxLayout="column" fxLayoutGap="20px">
        <div fxLayout="row" fxLayoutGap="10px" fxFlex="50" fxFlex.lt-md="100" class="other-details">
            <strong class="Other_details">Discount Details</strong>
            <mat-icon matSuffix class="show-cursor" matTooltip="Set the discount amount for  customers referred by affiliate users. As the super admin, you can define the discount rate that applies when a customer joins the ZenBasket through an affiliate.">info
            </mat-icon>
        </div>
        <div fxLayoutAlign="center" fxLayout="column" *ngIf="commissionDetailsForm" fxLayoutGap="10px"
            fxLayoutAlign.lt-md="center">
            <div class="mat-elevation-z0" [ngClass.lt-md]="'addressDetails-card-two'"
                [ngClass.gt-md]="'addressDetails-card'" [ngClass.md]="'addressDetails-card-two'">
                <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap.gt-md="10px" fxLayoutGap.md="20px">
                    <div fxLayout="column" fxFlex="90%">
                        <div fxLayout="row" fxFlex="90%" fxLayoutAlign="center center"> <mat-form-field fxFlex="90%">
                                <mat-label>Discount Type</mat-label>
                                <mat-select (selectionChange)="changeValueDiscount()" data-control="discountType"
                                    formControlName="discountType">
                                    <mat-option [attr.data-control]="status" *ngFor="let status of discountTypes"
                                        [value]="status.name">
                                        {{status.name}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </div>
                    <div fxFlex="90%" fxLayout="row" fxLayoutAlign="center center">
                        <mat-form-field fxFlex="90%">
                            <mat-label>Discount Value</mat-label>
                            <input type="number" data-control="discountValue" matInput formControlName="discountValue"
                                [readonly]="pageDetails?.NoneField">
                            <mat-error data-control="discountRequiredError"
                                *ngIf="commissionDetailsForm?.get('discountValue')?.hasError('required')">
                                {{addEditUserConstant?.validators?.DiscountRequired}}
                            </mat-error>
                            <mat-error data-control="discountMinError"
                                *ngIf="commissionDetailsForm?.get('discountValue')?.hasError('min')">
                                {{commissionDetailsForm?.get('discountType')?.value==='Fixed'?
                                addEditUserConstant?.validators?.DiscountMinFixed:
                                addEditUserConstant?.validators?.DiscountMinPercentage
                                }}
                            </mat-error>
                            <mat-error data-control="discountMaxError"
                                *ngIf="this.commissionDetailsForm?.get('discountValue')?.hasError('max')">
                                {{commissionDetailsForm?.get('discountType')?.value==='Fixed'?
                                addEditUserConstant?.validators?.DiscountMaxFixed:
                                addEditUserConstant?.validators?.DiscountMaxPercentage
                                }}
                            </mat-error>
                            <mat-error data-control="holdAmountPatternError"
                                *ngIf="this.commissionDetailsForm?.get('discountValue')?.hasError('pattern') &&
                                                        !this.commissionDetailsForm?.get('discountValue')?.hasError('max') &&
                                                         !commissionDetailsForm?.get('discountValue')?.hasError('min')">
                                {{commissionDetailsForm?.get('discountType')?.value==='Fixed'?
                                addEditUserConstant?.validators?.DiscountPatternFixed:
                                addEditUserConstant?.validators?.DiscountPatternPercentage
                                }}
                            </mat-error>
                        </mat-form-field>
                    </div>
                </div>
            </div><br>
        </div><br>
    </mat-card>
</form>