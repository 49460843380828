<cen-loader-vii *ngIf="pageDetails?.isLoader || pageDetails?.paginatorLoader" [type]="'page'"
    [name]="'cen-cube-loader'">
</cen-loader-vii>

<div class="login-card-container" *ngIf="!pageDetails?.isLoader">
    <phase-ii-header-page [heading]="pageDetails?.heading" [buttonInfo]="pageDetails?.buttonInfo">
    </phase-ii-header-page>
    <div class="page-container" fxLayout="row" fxLayoutAlign="center center">
        <mat-card class="mat-elevation-z4 activity-logs-page-wrapper" fxFlex.lt-md="100" fxFlex.md="60"
            fxFlex.gt-md="60" fxLayoutGap.lt-md="20px" fxLayout="row" fxLayout.lt-sm="column">
            <div fxLayoutAlign="center center" class="profile-section" fxFlex="40">
                <div class='profile-image'>
                    {{userLogsDetails?.profileName | uppercase}}</div>
            </div>
            <div fxLayoutAlign="center center" fxFlex="60">
                <div fxLayout="column" fxLayoutGap="10px">
                    <div fxLayout="row" fxLayoutGap="20px">
                        <div>
                            <mat-icon class="activity-logs-icon">person</mat-icon>
                        </div>
                        <div>
                            <span class="heading-style">{{userLogsDetails?.name | uppercase}}</span>
                        </div>
                    </div>
                    <div fxLayout="row" fxLayoutGap="20px">
                        <div>
                            <mat-icon class="activity-logs-icon">person_pin</mat-icon>
                        </div>
                        <div>
                            <span>{{userLogsDetails?.role|titlecase}}</span>
                        </div>
                    </div>
                    <div *ngIf="userLogsDetails?.lastActive" fxLayout="row" fxLayoutGap="20px">
                        <div>
                            <mat-icon class="activity-logs-icon">schedule</mat-icon>
                        </div>
                        <div>
                            <span class="lastActive-style">{{userLogsDetails?.lastActive }}</span>
                        </div>
                    </div>
                </div>
            </div>
        </mat-card>
    </div><br>
    <div fxLayout="row" fxLayoutGap="10px" fxLayout.xs="column">
        <div fxLayout="column">
            <phase-ii-filter *ngIf="isFilter" [filters]="filterOptions" (filterClosed)="onFilterClose()"
                (filterPredicate)="onFilterSelected($event)"></phase-ii-filter>
        </div>
        <mat-card fxFlex="100">
            <phase-ii-common-card-v-ii [needPaginator]="true" [paginatorVersion]="2" (iconClicked)="cardActions($event)"
                [listProperties]="pageDetails?.listProperty" [columnsRef]="pageDetails?.columnRef"
                [actionsIcon]="pageDetails?.actionIcons" [actions]="pageDetails?.actions"
                (actionClicked)="listAction($event)" (valueChanged)="getChangedData($event)"
                (paginatorData)="getPaginatorData($event)">
            </phase-ii-common-card-v-ii>
        </mat-card>
    </div>
</div>