<cen-loader-vii *ngIf="pageDetails?.isLoading">
</cen-loader-vii>
<div *ngIf="!pageDetails?.isLoading">
  <phase-ii-header-page [heading]="pageDetails?.heading" [buttonInfo]="pageDetails?.buttonInfo"
    (methodClicked)="functionTriggered($event)">
  </phase-ii-header-page>
  <div class="page-container">
    <phase-ii-subscription-plan-payment [selectedPlanDetails]="pageDetails?.selectedPlanDetails"
      (paymentDone)="savePlan($event)">
    </phase-ii-subscription-plan-payment>
  </div>
</div>