<mat-nav-list class="bgColor">
    <div *ngIf="menus" fxLayout="column" fxLayoutAlign="center center" class="bgColor">
        <img src="./assets/zblogo.png" alt="logo" class="logo" />
        <div *ngIf="currentUser?.firstName" fxLayout="column" class="userName">
            <div class="user-name">Hello, {{currentUser?.firstName}}
                !</div>
            <br />
            <!-- <div class="email">{{currentUser?.email}}</div> -->
        </div>
    </div>
    <!-- <mat-divider style="margin-top: -10px;" *ngIf="currentUser?.firstName && currentUser?.lastName"></mat-divider> -->
    <div *ngIf="!(currentUser?.firstName)">
        <br>
    </div>
    <ng-container *ngFor="let menu of menus">
        <ng-container *ngIf="menu?.pageCustomTitle && menu?.isDefault">
            <mat-list-item class="menuList" (click)="onRouterNavigation(menu)"
                [attr.data-control]="menu?.pageCustomTitle | dataControl"
                [ngClass]="{'selected':checkMenu(menu?.pageCustomLink), 'admin-menu-list-item': true}">
                <div class="menu-header-div">
                    <mat-icon mat-list-icon class="menu-icon">{{menu?.pageCustomIcon}}</mat-icon>
                    <span class="menu-name" [ngClass]="{'full-width':menu?.subMenu?.length>0}">{{menu?.pageCustomTitle |
                        menuPipe}}</span>
                    <sup *ngIf="menu?.isBeta" class="beta">
                        Beta
                    </sup>
                    <mat-icon class="menu-button" *ngIf="menu?.subMenu?.length>0"
                        [ngClass]="{'rotated' : showSubmenu && (menuId === menu?.id)}">
                        expand_more</mat-icon>
                </div>
            </mat-list-item>
            <ng-container *ngIf="showSubmenu  && (menuId === menu?.id) ">
                <ng-container *ngFor="let submenu of menu?.subMenu">
                    <mat-list-item (click)="onSubMenuClick(submenu?.pageCustomLink)" class="menuList"
                        [attr.data-control]="'sub menu '+submenu?.pageCustomTitle | dataControl"
                        [ngClass]="{'selected':checkMenu(submenu?.pageCustomLink), 'admin-menu-list-item': true}">
                        <div class="menu-header-div">
                            <mat-icon class="sub-menu-icon">
                                {{submenu?.pageCustomIcon}}
                            </mat-icon> &nbsp;
                            <span class="menu-name">{{submenu?.pageCustomTitle | menuPipe }}</span>
                            <sup *ngIf="submenu?.isBeta" class="beta">
                                Beta
                            </sup>
                        </div>
                    </mat-list-item>
                </ng-container>
            </ng-container>
        </ng-container>
    </ng-container>

</mat-nav-list>