/**
 * Component which is used for payment details.
 * S.P Priya & Mohamed Sirajudeen
 */
import { Component, EventEmitter, HostListener, Inject, Input, NgZone, OnDestroy, OnInit, Output, PLATFORM_ID, SimpleChanges, TemplateRef, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { loadStripe, StripeCardElement } from '@stripe/stripe-js';
import { CustomValidatorService } from 'libs/auth/src/lib/services/customValidators.service';
import { SharedConstant } from 'libs/common/src/lib/constants/shared-constant';
import { dataConfig } from 'libs/common/src/lib/services/config';
import { DialogService } from 'libs/common/src/lib/services/dialog.service';
import { SharedService } from 'libs/themes/shared-theme/src/lib/services/shared.service';
import { filter, Subscription } from 'rxjs';
import { CommonDataService } from '../../services/common-data.service';
import { CommonService } from '../../services/common.service';
import { IPayPalConfig, ICreateOrderRequest } from 'ngx-paypal';
import { isPlatformBrowser } from '@angular/common';
import { NavigationStart, Router } from '@angular/router';
import { ThemesService } from '../../services/themes.service';
import { LoadTranslationService } from '@phase-ii/common';
/**
 * Varaible holds the square payment form
 */
declare var Square: any;
/**
 * Varaible holds the checkoutDotCom payment form
 */
declare var Frames: any;
/**
 * Variable holds the window object 
 */
declare var window;
/**
 * Variable holds the razorpay payment form
 */
declare var Razorpay: any;
/**
 * Component which is used for payment details.
 */
@Component({
  selector: 'phase-ii-payment-details',
  templateUrl: './payment-details.component.html',
  styleUrls: ['./payment-details.component.scss']
})
/**
 * Component which is used for payment details.
 */
export class PaymentDetailsComponent extends SharedConstant implements OnInit, OnDestroy {
  /**
   * Variable used to get the template reference for billing address form
   */
  @ViewChild('payAddress') payAddress: TemplateRef<any>;
  /**
   * Variable used to define paypal config details
   */
  public payPalConfig?: IPayPalConfig;
  /**
   * variable used for storing error object.
   */
  errorObject: {
    componentName: any,
    message: any
  } = {
      componentName: 'payment-details',
      message: null
    }
  /**
   * Varaible holds the gpay response
   */
  gpay;
  /**
   * Variable used to card information
   */
  @Input() cardInfo;
  /**
   * Variable used to store the third party payment account details
   */
  @Input() paymentAccountDetails;
  /**
   * Variable used to handle the card address for gift card checkout.
   */
  @Input() isGiftCard;
  /**
   * Variable which is used to get shipping type.
   */
  @Input() shippingType;
  /**
   * Emit on payment card details on processing.
   */
  @Output() onPaymentProcessed = new EventEmitter();
  /**
   * Emit the user saved card details
   */
  @Output() cardDetails = new EventEmitter();
  /**
    * Variable used to store the manual order or not.
    */
  @Input() isManualOrder;
  /**
   * Variable storeId used to store storeId.
   */
  @Input() storeId;
  /**
   * Variable which is used access the patternsValidators from the common service.
   */
  pattern = dataConfig.patternValidators;
  /**
   * Variable to detact the default card.
   */
  defaultCard = false;
  /**
   * Form Control to save the card details
   */
  isSavedCard = new UntypedFormControl(false);
  /**
   * Subscription object to unsubscribe the api call
   */
  subscriptionObj: Subscription = new Subscription();
  /**
   * Variable to load the card error message
   */
  cardErr = null;
  /**
   * Variable used to incomplete card
   */
  incompleteCard: boolean = false
  /**
   * Form Control for the card holder name.
   */
  cardHoldeName = new UntypedFormControl(null, [Validators.required, Validators.pattern(this.pattern.acceptOnlyAlphabets)]);
  /**
   * To load the stripe element
   */
  stripe;
  /**
   * Variable used to store the payment related details
   */
  paymentDetails;
  /**
   * Variable used to handle the new card check.
   */
  isNewCard: boolean;
  /**
   * Object used to the address details
   */
  addressDetails: {
    countries: any,
    states: any

  } = {
      countries: [],
      states: []
    }
  /**
  * Variable which is used to store searched items based on filterText of country
  */
  searchCountries = [];
  /**
   * Variable which is used to store searched items based on filterText of country code
   */
  searchCountriesCode = [];
  /**
   * Variable which is used to search country 
   * @type {FormControl}
   */
  countrySearchFilter = new UntypedFormControl(null);
  /**
   * Variable which is used to search state 
   * @type {FormControl}
   */
  stateSearchFilter = new UntypedFormControl(null);
  /**
 * Variable which is used search the country code in the list of countries.
 * @type {FormControl}
 */
  countryCodeSearchFilter = new UntypedFormControl(null);
  /**
   * Variable which is used to store the searched items based on filterText of states
   *  @type {any[]}
   */
  searchStates: any[] = [];
  /**
   * Variable which is used to store the billing address form
   * @type {FormGroup}
   */
  shippingAddressForm: UntypedFormGroup;
  /**
   * Variable used to define stripe card element
   */
  cardElement: StripeCardElement;
  /**
   * Variable used to get the current user from parent
   */
  @Input() currentUser;
  /**
   * Variable used to store the data configs
   */
  dataConfig = dataConfig;
  /**
   * Variable used to store the selected card details
   */
  selectedCard;
  /**
   * Variable used to get the shipping address from the parent component.
   */
  @Input() shippingAddress;
  /**
   * Variable used to checked the shipping address
   */
  checkedShippingAddress: boolean;
  /**
* used to store the billing address
*/
  billingAddress = {
    addressLine1: null,
    city: null,
    country: null,
    fullName: null,
    mobileNumber: null,
    state: null,
    zipCode: null
  }
  /**
   * this variable is used to store card details from square payment
   */
  card: any;
  /**
   * this variable is used to store checkoutDotcom payment errors 
   */
  errorStack = [];
  /**
   * this variable is used to store current pre process data.
   */
  @Input() currentPreProcessData;
  /**
   * access env variables
   */
  environment: any = this.commonService.environment;
  /**
   * this variable is used to store store details.
   */
  storeDetails: any;
  /** 
   * this variable is used to store user response.
   */
  shouldConfirm = false;
  /**
   * this variable is used to store payment routes .
   */
  paymentUrls = ['CHECKOUT']
  /**
   * this variable is used to store checkoutDotcom payment errors 
   */
  websiteSettings: any;
  /** 
  * this variable is used to store user response.
  */
  shouldNavigate = true;
  /**
   * Component constructor to inject the required services.
   * @param commonService used to make the api call
   * @param dialog used to display the dialog
   * @param dialogService used to open and close the dialog box
   * @param commonDataService used to access methods from common Data service
   * @param sharedService used to get shared service methods
   * @param zone used to get specific zone - inventory location data
   * @param translateService used to call the getTranslation function from the LoadTranslationService.
   */
  constructor(private commonService: CommonService,
    private dialog: MatDialog,
    private dialogService: DialogService,
    private commonDataService: CommonDataService,
    private sharedService: SharedService,
    private zone: NgZone,
    private router: Router,
    private translateService: LoadTranslationService,
    @Inject(PLATFORM_ID) private platformId: object) {
    super();
  }
  @HostListener('window:beforeunload', ['$event'])
  unloadNotification($event: any): void {
    if (!this.shouldNavigate) {
      $event.returnValue = 'Are you sure you want to leave? Changes you made may not be saved.';
    }
  }
  @HostListener('window:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    if (event.key === 'F5' || ((event.ctrlKey && event.key === 'r')) || (event.ctrlKey && event.shiftKey && event.key === 'R')) {
      event.preventDefault();
    }
  }
  /**
   * Oninit life cycle hooks
   */
  ngOnInit(): void {
    try {
      this.subscriptionObj.add(this.router.events.pipe(filter((event: any) => event instanceof NavigationStart)).subscribe((event: NavigationStart) => {
        const currentUrl = this.router.url;
        const isPaymentUrl = this.paymentUrls.some(urlPart => currentUrl.toLowerCase().includes(urlPart.toLowerCase()));
        if (event.url !== currentUrl && isPaymentUrl && !this.shouldConfirm && !this.shouldNavigate) {
          this.shouldConfirm = true;
          const confirmLeave = confirm("Are you sure you want to leave? Changes you made may not be saved.");
          if (!confirmLeave) {
            this.router.navigateByUrl(currentUrl);
            this.shouldConfirm = false;
          }
        }
      }));
      this.subscriptionObj.add(this.sharedService.commonWebsiteDetails.subscribe({
        next: res => {
          this.websiteSettings = res;
        }
      }
      ))
      this.isNewCard = this.cardInfo && this.cardInfo.length ? false : true;
      this.cardSelected();
      this.subscriptionObj.add(this.sharedService.storeContactDetails.subscribe((res) => {
        this.storeDetails = res;
      }));
      this.subscriptionObj.add(this.commonService.currentPaymentDetails.subscribe((res) => {
        if (res) {
          this.paymentDetails = res;
          if (this.paymentDetails && this.paymentAccountDetails && this.paymentAccountDetails.pluginCode && !this.paymentDetails.gpay) {
            if (this.paymentAccountDetails.pluginCode === dataConfig.paymentOption.STRIPE) {
              if (this.selectedCard || this.isNewCard) {
                this.onPayment(res);
              } else {
                this.onPaymentProcessed.emit({ isLoading: { value: false } });
                this.dialogService.dialogMethod(this.translateService.getTranslation('PAYMENT_DETAILS.CARD_DETAILS'), this.dialogType.alert, true);
              }
            } else if (this.paymentAccountDetails.pluginCode === dataConfig.paymentOption.SQUARE) {
              if (this.isNewCard) {
                this.onSquarePayment();
              } else if (this.selectedCard) {
                this.createPayment();
              } else {
                this.dialogService.dialogMethod(this.translateService.getTranslation('PAYMENT_DETAILS.CARD_DETAILS'), this.dialogType.alert, true);
              }
            } else if (this.paymentAccountDetails.pluginCode === dataConfig.paymentOption.RAZORPAY) {
              this.loadRazorpayScript().then(() => {
                this.onCreateOrderRazoypay();
              });
            } else if (this.paymentAccountDetails.pluginCode === dataConfig.paymentOption.PAYPAL) {
              this.initConfig();
            } else if (this.paymentAccountDetails.pluginCode === dataConfig.paymentOption.AUTHORIZEDOTNET) {
              this.authorizedotnetScripts();
            } else if (this.paymentAccountDetails.pluginCode === dataConfig.paymentOption.CHECKOUTDOTCOM) {
              if (this.isNewCard) {
                this.onCheckoutDotComPayment();
              } else if (this.selectedCard) {
                this.checkoutDotComAuthorize();
              } else {
                this.dialogService.dialogMethod(this.translateService.getTranslation('PAYMENT_DETAILS.CARD_DETAILS'), this.dialogType.alert, true);
              }
            }
          }
        }
      },
        (err) => {
          this.dialogService.dialogMethod(this.translateService.getTranslation('PAYMENT_DETAILS.FAILED_TO_GET'), this.dialogType.failure,
            true
          );
        }));
    } catch (err) {
      this.errorObject.message = 'Error in onInit: ' + err && err.message ? err.message : null;
      this.sharedService.errorEmailNotification(this.storeId, this.errorObject);
      this.dialogService.dialogMethod(this.translateService.getTranslation('PAYMENT_DETAILS.FAILED_TO_GET'), this.dialogType.failure,
        true
      );
    }
  }
  /**
   * Method is used to fetch value to country and state filed
   * @param id To fetch the value in country and state
   * @param property To get property name dynamically
   * @returns
   */
  countryAndStateValueFetch(id: any, property: string) {
    if (
      id &&
      property &&
      this.addressDetails &&
      this.addressDetails[property]
    ) {
      return (
        this.addressDetails[property]
      ).find((item) => item && item.id === id);
    }
  }
  /**
   * Component onChange life cycle hook
   * @param changes To define changes in input property
   */
  ngOnChanges(changes: SimpleChanges): void {
    this.checkedShippingAddress = false;
    this.shippingAddressForm?.reset();
    try {
      if (changes && changes.hasOwnProperty('paymentAccountDetails') && changes['paymentAccountDetails'].currentValue) {
        this.paymentAccountDetails = changes['paymentAccountDetails'].currentValue;
        if (changes['paymentAccountDetails'].currentValue.pluginCode === dataConfig.paymentOption.STRIPE) {
          this.loadStripeDetails();
          this.checkedShippingAddress = false;
          if (!this.shippingAddress?.name) {
            this.billingAddress = null;
            this.checkedShippingAddress = false;
            this.shippingAddressForm?.get('state')?.setValue(null);
            this.shippingAddressForm?.patchValue({
              fullName: null, mobileNumber: null, country: null, state: null,
              city: null, zipCode: null, addressLine1: null,
            });
          }
        } else if (changes['paymentAccountDetails'].currentValue.pluginCode === dataConfig.paymentOption.SQUARE && !this.isManualOrder) {
          this.loadSquareScript().then(() => {
            this.onSquare();
            if (this.paymentAccountDetails && this.paymentAccountDetails.googlePay &&
              this.paymentAccountDetails.locationId &&
              this.paymentAccountDetails.applicationId) {
              this.onSquareGpay();
            }
          });
        }
        else if (changes['paymentAccountDetails'].currentValue.pluginCode === dataConfig.paymentOption.CHECKOUTDOTCOM && !this.isManualOrder) {
          this.loadCheckoutDotComScript().then(() => {
            this.onCheckoutDotCom();
          });
        }
      }
    } catch (err) {
      this.errorObject.message = this.message.errorOnChanges + err && err.message ? err.message : null;
      this.sharedService.errorEmailNotification(this.storeId, this.errorObject);
      this.dialogService.dialogMethod(this.translateService.getTranslation('PAYMENT_DETAILS.FAILED_TO_GET'), this.dialogType.failure,
        true
      );
    }
  }
  /**
   * Method used to load the basic details for stripe
   */
  loadStripeDetails(): void {
    this.getCountries();
    this.shippingAddressForm = new UntypedFormGroup({
      fullName: new UntypedFormControl(null, [Validators.required,
      Validators.pattern(this.pattern.acceptOnlyAlphabets), Validators.maxLength(50)]),
      mobileNumber: new UntypedFormControl(null, [Validators.maxLength(20), Validators.required, Validators.pattern(this.pattern.acceptOnlyNumbers)]),
      country: new UntypedFormControl(null, [Validators.required, Validators.pattern(this.pattern.noSpaceValidationPattern),
      CustomValidatorService.checkCountryAndStateExist(this.addressDetails.countries)]),
      state: new UntypedFormControl(null, [Validators.required, Validators.pattern(this.pattern.noSpaceValidationPattern),
      CustomValidatorService.checkCountryAndStateExist(this.addressDetails.states)]),
      city: new UntypedFormControl(null, [Validators.required, Validators.pattern(this.pattern.acceptOnlyAlphabets), Validators.maxLength(50)]),
      zipCode: new UntypedFormControl(null, [Validators.maxLength(15), Validators.required]),
      addressLine1: new UntypedFormControl(null, [Validators.required, Validators.pattern(this.pattern.addressValidationPattern),
      Validators.maxLength(100)]),
    });
    this.load();
  }
  /**
   * Method used to load the stripe element.
   */
  async load() {
    this.stripe = await loadStripe((this.paymentAccountDetails && this.paymentAccountDetails.isProduction) ? this.dataConfig.stripeProductionKey : this.dataConfig.stripeKey, {
      stripeAccount: this.paymentAccountDetails.accountId
    });
    if (!this.isManualOrder)
      this.loadStripeElements();
  }

  /**
   * Method used to get the states related to seleted countries
   * @param address has the stored address.
   */
  getStates(address?: any): void {
    this.shippingAddressForm?.get('state')?.setValue(null);
    this.onCountryFilterClose();
    if (this.shippingAddressForm && this.shippingAddressForm.value && this.shippingAddressForm.value.country && this.shippingAddressForm.value.country.id) {
      this.subscriptionObj.add(this.commonService.getStates(this.shippingAddressForm.value.country.id).subscribe((response: any) => {
        this.addressDetails.states = response.states;
        this.searchStates = response.states;
        if (address) {
          let stateCode = address.state && address.state.id
            ? address.state
            : this.countryAndStateValueFetch(
              Number(address.state),
              'states',
            );
          this.shippingAddressForm.get('state').setValue(stateCode);
        }
      }, (err) => {
      }));
    }
  }
  /**
  * Method used to close the country filter.
  */
  onCountryFilterClose(): void {
    this.countrySearchFilter.setValue(null);
    this.searchCountries = [];
    this.searchCountries = this.addressDetails && this.addressDetails.countries;
  }
  /**
   * Method used to filter the country code search.
   */
  onCountrySearchFilterClose(): void {
    this.countryCodeSearchFilter.setValue(null);
    this.searchCountriesCode = [];
    this.searchCountriesCode = this.addressDetails && this.addressDetails.countries;
  }
  /**
   * MEthod used to country search filter
   * @param value 
   */
  onCountrySearchFilter(value: string): void {
    if (value && value.length) {
      this.searchCountries = this.searchCountry(value);
    }
    else {
      this.countrySearchFilter.setValue(null);
      this.searchCountries = [];
      this.searchCountries = this.addressDetails && this.addressDetails.countries;
    }
  }

  /**
   * MEthod used to state search filter
   * @param value has the value
   */
  onStateSearchFilter(value: string): void {
    if (value && value.length)
      this.searchStates = this.searchState(value);
    else {
      this.stateSearchFilter.setValue(null);
      this.searchStates = [];
      this.searchStates = this.addressDetails && this.addressDetails.states;
    }

  }
  /**
  * Method used to search country
  * @param value has the country value
  * @returns 
  */
  searchCountry(value: string): any {
    const filterValue = value.toLowerCase();
    return this.addressDetails && this.addressDetails.countries && this.addressDetails.countries.filter(option => option.name.toLowerCase().startsWith(filterValue));
  }

  /**
  * Method used to search state 
  * @param value has the value changes
  * @returns response
  */
  searchState(value: string): any {
    const filterValue = value.toLowerCase();
    return this.addressDetails && this.addressDetails.states && this.addressDetails.states.filter(option => option && option.name && option.name.toLowerCase().startsWith(filterValue));
  }
  /**
   * Function used to display the country and state name from object
   * @param value To get the selected value
   * @returns 
   */
  displayFn(value: any): string {
    return value && value.name ? value.name : '';
  }
  /**
   * Function used to get countries list
   */
  getCountries(): void {
    this.subscriptionObj.add(this.commonService.getCountries().subscribe((response: any) => {
      this.addressDetails.countries = response && response.country;
      this.searchCountries = response && response.country;
      this.searchCountriesCode = response && response.country;
    }));
  }

  /**
   * Function which is used for clear the state filter values
   */
  clearStateFilter(): void {
    this.stateSearchFilter.setValue(null);
    this.searchStates = [];
    this.searchStates = this.addressDetails && this.addressDetails.states;
  }
  /**
   * Method which is used to load stripe elements
   */
  loadStripeElements(): void {
    try {
      const rootNode = document.getElementById('cardInfoGroup');
      const cardWrapper = document.createElement('div');

      rootNode.appendChild(cardWrapper);
      const elements = this.stripe.elements();
      // setup card Element
      this.cardElement = elements.create('card', {
        style: {
          base: {
            color: 'currentColor',
            lineHeight: '40px',
            fontSize: '18px',
          },
        },
        hidePostalCode: true,
      });
      this.cardElement.mount(cardWrapper);
      const that = this;
      this.cardElement.on('change', function (event) {
        if (event) {
          that.cardErr = event.error && event.error.message ? event.error.message : null;
          that.incompleteCard = event.complete;
        }
      });
    } catch (err) {
      this.errorObject.message = this.message.loadStripeElementsError + err && err.message ? err.message : null;
      this.sharedService.errorEmailNotification(this.storeId, this.errorObject);
      this.dialogService.dialogMethod(this.translateService.getTranslation('PAYMENT_DETAILS.FAILED_TO_GET'), this.dialogType.failure,
        true
      );
    }
  }

  /**
   * Method used to delete the card details
   * @param index has the deleted card index
   */
  deleteSavedCard(index: number): void {
    try {
      const dialog = this.dialogService.dialogMethod(this.translateService.getTranslation('PAYMENT_DETAILS.CONFIRM_CARD_DELETE'), this.dialogType.confirmation, true);
      dialog.afterClosed().subscribe((result) => {
        if (result) {
          this.onPaymentProcessed.emit({ isLoading: { value: true } });
          let data: any = {
            paymentCustomerId: this.cardInfo[index] && this.cardInfo[index].customer && this.commonDataService.encryptDetails(this.cardInfo[index].customer),
            cardId: this.cardInfo[index].cardId && this.commonDataService.encryptDetails(this.cardInfo[index].cardId),
            customerId: this.currentUser && this.currentUser.customerId,
            isDefaultCardDeleted: this.cardInfo[index] && this.cardInfo[index].isDefault
          };
          if (this.currentUser && this.currentUser.isUrlCheckout) {
            data.isUrlCheckout = this.currentUser.isUrlCheckout;
          }
          this.subscriptionObj.add(this.commonService.deleteSavedCard(this.cardInfo[index] && this.cardInfo[index].id, data, this.storeId).subscribe((res) => {
            if (res) {
              this.dialogService.dialogMethod(this.translateService.getTranslation('PAYMENT_DETAILS.CARD_DELETE_SUCESS'), this.dialogType.success, true);
              this.onPaymentProcessed.emit({ isLoading: { value: false } });
              this.cardInfo.splice(index, 1);
              if (this.cardInfo.length) {
                if (data.isDefaultCardDeleted) {
                  this.cardInfo[0].isDefault = true;
                  this.selectedCard = this.cardInfo[0];
                } else {
                  this.selectedCard = null;
                }
              } else {
                this.isNewCard = true;
              }
              this.cardDetails.emit(this.cardInfo);
            }
          }, err => {
            this.onPaymentProcessed.emit({ isLoading: { value: false } });
            this.dialogService.dialogMethod(this.translateService.getTranslation('PAYMENT_DETAILS.CARD_DELETE_FAILURE'), this.dialogType.failure, true);
          }));
        }
      });
    } catch (err) {
      this.errorObject.message = 'Error while deleting saved card: ' + err && err.message ? err.message : null;
      this.sharedService.errorEmailNotification(this.storeId, this.errorObject);
      this.dialogService.dialogMethod(this.translateService.getTranslation('PAYMENT_DETAILS.CARD_DELETE_FAILURE'), this.dialogType.failure, true);
    }
  }
  /**
   * Method used to check the default card
   * @param event has the card event
   */
  checkDefault(event: any): void {
    try {
      this.defaultCard = event.checked;
      this.cardInfo.forEach((s) => {
        if (s.isDefault && event.checked) {
          const dialog = this.dialogService.dialogMethod(this.translateService.getTranslation('PAYMENT_DETAILS.DEFAULT_CARD_ALERT'), this.dialogType.confirmation, true);
          dialog.afterClosed().subscribe((res) => {
            if (!res) {
              this.defaultCard = false;
            }
          });
        }
      });
    } catch (err) {
      this.errorObject.message = 'Error in checkDefault: ' + err && err.message ? err.message : null;
      this.sharedService.errorEmailNotification(this.storeId, this.errorObject);
      this.dialogService.dialogMethod(this.translateService.getTranslation('PAYMENT_DETAILS.FAILED_TO_SAVE'), this.dialogType.failure, true);
    }
  }

  /**
   * Method used to set the billing address
   * @param event has the event as checked value
   */
  setBillingAddress(event: any): void {
    try {
      if (event && event.checked && this.shippingAddress) {
        this.onPaymentProcessed.emit({ isLoading: { value: true } });
        if (this.shippingAddress.country) {
          let countryData = this.shippingAddress.country.id
            ? this.shippingAddress.country
            : this.countryAndStateValueFetch(
              Number(this.shippingAddress.country),
              'countries'
            );
          this.shippingAddressForm?.get('country')?.setValue(countryData);
          this.getStates(this.shippingAddress);
        }
        this.checkedShippingAddress = true;
        this.shippingAddressForm?.patchValue({
          fullName: this.shippingAddress.name,
          mobileNumber: this.shippingAddress.mobileNumber,
          city: this.shippingAddress.city,
          zipCode: this.shippingAddress.zipCode,
          addressLine1: this.shippingAddress.addressLine1,
        });
        this.onPaymentProcessed.emit({ isLoading: { value: false } });
      }
      else {
        this.billingAddress = null;
        this.checkedShippingAddress = false;
        this.shippingAddressForm?.get('state')?.setValue(null);
        this.shippingAddressForm?.patchValue({
          fullName: null, mobileNumber: null, country: null, state: null,
          city: null, zipCode: null, addressLine1: null,
        });
        this.shippingAddressForm?.markAsUntouched();
      }
    } catch (err) {
      this.errorObject.message = this.message.setBillingAddressError + err && err.message ? err.message : null;
      this.sharedService.errorEmailNotification(this.storeId, this.errorObject);
      this.dialogService.dialogMethod(this.translateService.getTranslation('PAYMENT_DETAILS.FAILED_TO_SAVE'), this.dialogType.failure, true);
    }
  }

  /**
   * Method used to store the selected card for payment
   */
  cardSelected(): void {
    if (this.cardInfo && this.cardInfo.length) {
      const item = this.cardInfo.find(val => val && val.isDefault);
      this.selectedCard = item;
    }
  }

  /**
   * Method used to called when add new card is clicked
   */
  newCardClicked(): void {
    this.isNewCard = true;
    this.selectedCard = null;
  }

  /**
   * Method used to procced the payment.
  * @param paymentDetails hold paymement details data
   */
  onPayment(paymentDetails: any): void {
    this.shouldNavigate = false;
    try {
      if (paymentDetails && paymentDetails.totalAmount && this.paymentAccountDetails &&
        this.paymentAccountDetails.pluginId &&
        ((this.cardHoldeName.valid && this.incompleteCard && this.shippingAddressForm.valid) || (this.selectedCard && this.selectedCard.cardId))) {
        const paymentData: any = {
          isGiftCard: paymentDetails.isGiftCard ? paymentDetails.isGiftCard : false,
          currency: paymentDetails.currency,
          totalAmount: paymentDetails.totalAmount >= 0 && this.commonDataService.encryptDetails(paymentDetails.totalAmount),
          pluginId: this.paymentAccountDetails.pluginId,
          customerId: this.currentUser && this.currentUser.roleCode !== 'GUEST' ? this.currentUser.customerId : null,
          locationId: paymentDetails.locationId,
          autocomplete: true,
          customer: this.selectedCard && this.selectedCard?.customer && this.commonDataService.encryptDetails(this.selectedCard.customer),
          orderLimitAmount: paymentDetails.limitOrderAmount >= 0 && this.commonDataService.encryptDetails(paymentDetails.limitOrderAmount)
        }
        if (paymentDetails && paymentDetails.isUrlCheckout) {
          paymentData.isUrlCheckout = paymentDetails.isUrlCheckout;
          paymentData["user"] = paymentDetails.user;
          paymentData["customerId"] = paymentDetails.customerId;
        }
        this.subscriptionObj.add(this.commonService.paymentProcess(paymentData, this.currentUser?.shopappUser ? this.sharedService.storeId : this.storeId).subscribe((res) => {
          if (res && res['result'] && res['result']['paymentResult']) {
            const that = this;
            this.stripe.confirmCardPayment(this.commonDataService.decryptDetails(res['result']['paymentResult']), {

              payment_method: (this.selectedCard && this.selectedCard.cardId && !this.isNewCard ?
                this.selectedCard.cardId :
                {
                  type: 'card',
                  card: this.cardElement,
                  billing_details: {
                    name: this.cardHoldeName.value,
                    address: {
                      line1: this.shippingAddressForm.value.addressLine1,
                      postal_code: this.shippingAddressForm.value.zipCode,
                      city: this.shippingAddressForm.value.city,
                      state: this.shippingAddressForm.value.state.name,
                      country: this.shippingAddressForm.value.country.countryIsoCode,
                    }
                  }
                }),
              setup_future_usage: 'off_session'
            }).then(function (result) {
              that.shouldNavigate = true;
              if (result && result.paymentIntent && result.paymentIntent.payment_method) {
                that.onPaymentProcessed.emit({
                  saveCard: that.isSavedCard.value ? {
                    accountId: that.paymentAccountDetails.accountId && that.commonDataService.encryptDetails(that.paymentAccountDetails.accountId),
                    pluginId: that.paymentAccountDetails.pluginId,
                    paymentMethodId: result.paymentIntent.payment_method && that.commonDataService.encryptDetails(result.paymentIntent.payment_method),
                    isDefault: that.defaultCard
                  } : null,
                  paymentOrderId: res['result'] && res['result']['paymentOrderId']
                });
              } else {
                that.cardErr = result && result.error && result.error.message;
                that.onPaymentProcessed.emit({
                  result: result,
                  errorMsg: result && result.error && result.error.message,
                });
              }
            }).catch((err) => {
              that.onPaymentProcessed.emit({
                errorMsg: err.message
              });
            });
          } else {
            this.onPaymentProcessed.emit({
              errorMsg: res['error'] ? res['error'] : null
            });
          }
        }, (err) => {
          this.onPaymentProcessed.emit(
            (err && err.error && err.error.error) ? { errorMsg: err.error.error.details } : null
          );
        }));
      }
      else if (paymentDetails && paymentDetails.totalAmount && (this.shippingAddressForm.invalid || this.cardHoldeName.invalid || !this.incompleteCard)) {
        this.onPaymentProcessed.emit({ formValue: true });
      }
    } catch (err) {
      this.errorObject.message = this.message.paymentError + err && err.message ? err.message : null;
      this.sharedService.errorEmailNotification(this.storeId, this.errorObject);
      this.dialogService.dialogMethod(this.translateService.getTranslation('PAYMENT_DETAILS.FAILED_TO_SAVE'), this.dialogType.failure, true);
    }
  }

  /**
   * Method which is used to load square script dynamically
   */
  loadSquareScript() {
    return new Promise((resolve, reject) => {
      try {
        if (isPlatformBrowser(this.platformId)) {
          if (!(window && window.Square)) {
            let squareScript = document.createElement("script");
            squareScript.setAttribute("src", (this.paymentAccountDetails && this.paymentAccountDetails.isProduction) ? "https://web.squarecdn.com/v1/square.js" :
              "https://sandbox.web.squarecdn.com/v1/square.js");
            squareScript.setAttribute("type", "text/javascript");
            document.head.appendChild(squareScript);
            this.onPaymentProcessed.emit({ isLoading: { value: true } });
            squareScript.onload = function () {
              resolve(true);
            };
          } else {
            resolve(true);
          }
        }
      } catch (err) {
        reject(false);
        this.errorObject.message = 'Error in loadSquareScript: ' + err && err.message ? err.message : null;
        this.sharedService.errorEmailNotification(this.storeId, this.errorObject);
        this.dialogService.dialogMethod(this.translateService.getTranslation('PAYMENT_DETAILS.FAILED_TO_GET'), this.dialogType.failure,
          true
        );
      }
    });
  }
  /**
   * Method which is used to call square payment function
   */
  async onSquare() {
    if (this.paymentAccountDetails && this.paymentAccountDetails.applicationId && this.paymentAccountDetails.locationId) {
      const payments = Square.payments(this.paymentAccountDetails.applicationId, this.paymentAccountDetails.locationId);
      const card = await payments.card();
      await card && card.attach('#card-container');
      this.card = card;
    }
  }
  /**
   * Method which is used to make square payment function
  */
  async onSquarePayment() {
    try {
      const result = await this.card.tokenize();
      if (result.status === 'OK') {
        this.createPayment(result.token);
      } else {
        this.onPaymentProcessed.emit({ isLoading: { value: false } });
      }
    } catch (e) {
      this.onPaymentProcessed.emit({ isLoading: { value: false } });
      this.errorObject.message = 'Error in onSquarePayment: ' + e && e.message ? e.message : null;
      this.sharedService.errorEmailNotification(this.storeId, this.errorObject);
      this.dialogService.dialogMethod(this.translateService.getTranslation('PAYMENT_DETAILS.FAILED_TO_SAVE'), this.dialogType.failure,
        true
      );
    }
  }
  /**
   * Method which is used to call square gpay function
   */
  async onSquareGpay() {
    this.onPaymentProcessed.emit({ isLoading: { value: true } });
    this.gpay = null;
    if (this.paymentDetails && this.paymentAccountDetails && this.paymentAccountDetails.applicationId && this.paymentAccountDetails.locationId) {
      const payments = Square.payments(this.paymentAccountDetails.applicationId, this.paymentAccountDetails.locationId);
      const paymentRequest = payments.paymentRequest({
        countryCode: this.paymentDetails.countryIsoCode,
        currencyCode: this.paymentDetails.currency,
        total: {
          amount: this.paymentDetails.totalAmount && this.paymentDetails.totalAmount.toString(),
          label: 'Total',
        },
      });
      const googlePay = await payments.googlePay(paymentRequest);
      await googlePay.attach('#google-pay-button', { buttonType: 'pay', buttonSizeMode: 'fill' });
      this.onPaymentProcessed.emit({ isLoading: { value: false } });
      this.gpay = googlePay;
    }
  }
  /**
   * Method which is used to make square gpay payment
   */
  async gpayPayment() {
    this.shouldNavigate = false;
    try {
      const tokenResult = await this.gpay.tokenize();
      if (tokenResult && tokenResult.status === 'OK') {
        this.createPayment(tokenResult.token);
      } else {
        this.shouldNavigate = true;
        this.onPaymentProcessed.emit({ isLoading: { value: false } });
      }
    } catch (e) {
      this.onPaymentProcessed.emit({ isLoading: { value: false } });
      this.errorObject.message = 'Error in gpayPayment: ' + e && e.message ? e.message : null;
      this.sharedService.errorEmailNotification(this.storeId, this.errorObject);
      this.dialogService.dialogMethod(this.translateService.getTranslation('PAYMENT_DETAILS.FAILED_TO_SAVE'), this.dialogType.failure,
        true
      );
    }
  }
  /**
  * Method which is used to create payment for square
  * @param nonce parameter the card nonce for square payment
  */
  createPayment(nonce?: any): void {
    this.shouldNavigate = false;
    const payment: any = {
      newSaveCard: this.isNewCard && this.isSavedCard && this.isSavedCard.value,
      paymentCustomerId: this.cardInfo && this.cardInfo[0] && this.cardInfo[0].customer && this.commonDataService.encryptDetails(this.cardInfo[0].customer),
      customerCardId: !this.isNewCard && this.selectedCard && this.selectedCard.cardId && this.commonDataService.encryptDetails(this.selectedCard.cardId),
      nonce: nonce && this.commonDataService.encryptDetails(nonce),
      autocomplete: this.paymentDetails && this.paymentDetails.autoComplete,
      locationId: this.paymentDetails && this.paymentDetails.locationId,
      amount: this.paymentDetails && this.paymentDetails.totalAmount >= 0 && this.commonDataService.encryptDetails(this.paymentDetails.totalAmount.toFixed(2)),
      currency: this.paymentDetails && this.paymentDetails.currency,
      customerId: this.currentUser && this.currentUser.roleCode !== 'GUEST' ? this.currentUser.customerId : null,
      isDefault: this.defaultCard,
      isGpay: this.paymentDetails && this.paymentDetails.gpay ? true : false,
      orderLimitAmount: this.paymentDetails && this.paymentDetails.limitOrderAmount >= 0 && this.commonDataService.encryptDetails(this.paymentDetails.limitOrderAmount),
    }
    if (this.paymentDetails && this.paymentDetails.isUrlCheckout) {
      payment.userId = this.paymentDetails && this.paymentDetails.userId;
      payment.email = this.paymentDetails && this.paymentDetails.email;
      payment.isUrlCheckout = this.paymentDetails.isUrlCheckout;
      payment["user"] = this.paymentDetails.user;
      payment["customerId"] = this.paymentDetails.customerId;
    }

    try {
      this.subscriptionObj.add(this.commonService.createSquarePayment(payment, this.currentUser?.shopappUser ? this.sharedService.storeId : this.storeId).subscribe((res) => {
        if (res) {
          this.shouldNavigate = true;
          if (res['paymentOrderId']) {
            this.onPaymentProcessed.emit({
              paymentOrderId: res['paymentOrderId'],
            });
          } else if (res['error']) {
            this.onPaymentProcessed.emit({ isLoading: { value: false } });
            this.onPaymentProcessed.emit({ errorMsg: res['error'] });
          }
        } else {
          this.shouldNavigate = true;
          this.onPaymentProcessed.emit({ isLoading: { value: false } });
          this.onPaymentProcessed.emit(null);
        }
      }, (err) => {
        this.onPaymentProcessed.emit({ isLoading: { value: false } });
        this.onPaymentProcessed.emit((err && err.error && err.error.error) ? { errorMsg: err.error.error.details } : null);
      }));
    } catch (err) {
      this.onPaymentProcessed.emit({ isLoading: { value: false } });
      this.errorObject.message = 'Error in createPayment: ' + err && err.message ? err.message : null;
      this.sharedService.errorEmailNotification(this.storeId, this.errorObject);
      this.dialogService.dialogMethod(this.translateService.getTranslation('PAYMENT_DETAILS.FAILED_TO_SAVE'), this.dialogType.failure,
        true
      );
    }
  }
  /**
   * Function used to load the razorpay script
   */
  loadRazorpayScript() {
    return new Promise((resolve, reject) => {
      try {
        if (isPlatformBrowser(this.platformId)) {
          if (!(window && window.Razorpay)) {
            let razorpayScript = document.createElement("script");
            razorpayScript.setAttribute("src", "https://checkout.razorpay.com/v1/checkout.js");
            razorpayScript.setAttribute("type", "text/javascript");
            document.head && document.head.appendChild(razorpayScript);
            razorpayScript.onload = function () {
              resolve(true);
            };
          } else {
            resolve(true);
          }
        }
      } catch (err) {
        reject(false);
        this.errorObject.message = 'Error in loadRazorpayScript: ' + err && err.message ? err.message : null;
        this.sharedService.errorEmailNotification(this.storeId, this.errorObject);
        this.dialogService.dialogMethod(this.translateService.getTranslation('PAYMENT_DETAILS.FAILED_TO_GET'), this.dialogType.failure,
          true
        );
      }
    });
  }
  /**
   * Method which is used to create razorpay order
   */
  onCreateOrderRazoypay(): void {
    this.shouldNavigate = false;
    try {
      if (this.paymentDetails) {
        const option: any = {
          preProcessOrderId: this.currentPreProcessData?.payloadId,
          locationId: this.paymentDetails.locationId,
          currency: this.paymentDetails.currency,
          isGiftCard: this.isGiftCard,
          amount: this.paymentDetails.totalAmount >= 0 && this.commonDataService.encryptDetails(this.paymentDetails.totalAmount.toFixed(2)),
          orderLimitAmount: this.paymentDetails.limitOrderAmount >= 0 && this.commonDataService.encryptDetails(this.paymentDetails.limitOrderAmount),
        }
        if (this.paymentDetails && this.paymentDetails.isUrlCheckout) {
          option["userId"] = this.paymentDetails.userId;
          option["isUrlCheckout"] = true;
          option["user"] = this.paymentDetails.user;
          option["customerId"] = this.paymentDetails.customerId;
        }
        this.subscriptionObj.add(this.commonService.createRazorpayOrder(option, this.currentUser?.shopappUser ? this.sharedService.storeId : this.storeId).subscribe((response: object) => {
          if (response && response['razorpayOrder']) {
            this.payWithRazor(response['razorpayOrder']);
          } else {
            this.onPaymentProcessed.emit({ isLoading: { value: false } });
            this.onPaymentProcessed.emit(response && response['error'] ? { errorMsg: response['error'] } : null);
          }
        }, (err) => {
          this.onPaymentProcessed.emit({ isLoading: { value: false } });
          this.onPaymentProcessed.emit(err && err['error'] && err['error'].error && err['error'].error.details ? { errorMsg: err['error'].error.details } : null);
        }));
      }
    } catch (err) {
      this.onPaymentProcessed.emit({ isLoading: { value: false } });
      this.errorObject.message = 'Error in onCreateOrderRazoypay: ' + err && err.message ? err.message : null;
      this.sharedService.errorEmailNotification(this.storeId, this.errorObject);
      this.dialogService.dialogMethod(this.translateService.getTranslation('PAYMENT_DETAILS.FAILED_TO_SAVE'), this.dialogType.failure,
        true
      );
    }
  }
  /**
   * Method which is used to call razor fucntion for payment
   * @param val parameter used for razorpay credentials
   */
  payWithRazor(val: any): void {
    try {
      if (val) {
        const options: any = {
          key: val.order && val.order.keyId,
          currency: this.paymentDetails && this.paymentDetails.currency,
          amount: val.order && val.order.amount,
          order_id: val.order && val.order.id,
          ...(this.websiteSettings && this.websiteSettings.logoUrl && {
            image: this.commonService.environment.AWSImageUrl + this.websiteSettings.logoUrl
          }),
          modal: {
            escape: false,
          },
          theme: {
            color: '#0c238a'
          },
          notes: {
            storeDetails: JSON.stringify({
              email: this.storeDetails?.email,
              name: this.storeDetails?.firstName,
              storeName: this.websiteSettings?.store?.name,
              phone: this.storeDetails?.phone,
              countryCode: this.storeDetails?.countryCode
            }),
            pluginId: this.currentPreProcessData?.pluginId,
            preProcessOrderId: this.currentPreProcessData?.payloadId,
            paymentOrderId: val.paymentOrderId,
            customerId: this.currentPreProcessData?.customerId,
            storeId: this.storeId,
            AccessKey: this.environment.AccessKey
          }
        };
        options.handler = ((response: any) => {
          this.zone.run(() => {
            if (response && response.razorpay_payment_id) {
              this.shouldNavigate = true;
              this.onPaymentProcessed.emit({ paymentSuccess: true, customerId: this.currentPreProcessData?.customerId });
            } else if (response && response.error) {
              this.shouldNavigate = true;
              this.onPaymentProcessed.emit({ isLoading: { value: false } });
              this.onPaymentProcessed.emit(null);
            }
          });
        });
        options.modal.ondismiss = (() => {
          this.shouldNavigate = true;
          this.zone.run(() => {
            this.onPaymentProcessed.emit({ cancelRazorpay: true });
          });
        });
        const rzp = new Razorpay(options);
        rzp.open();
      }
    } catch (err) {
      this.onPaymentProcessed.emit({ isLoading: { value: false } });
      this.errorObject.message = 'Error in payWithRazor: ' + err && err.message ? err.message : null;
      this.sharedService.errorEmailNotification(this.storeId, this.errorObject);
      this.dialogService.dialogMethod(this.translateService.getTranslation('PAYMENT_DETAILS.FAILED_TO_SAVE'), this.dialogType.failure,
        true
      );
      this.onPaymentProcessed.emit(null);
    }
  }
  /**
  * Method used to initialize paypal button function
  */
  private initConfig(): void {
    this.shouldNavigate = false;
    try {
      this.payPalConfig = {
        currency: this.paymentDetails && this.paymentDetails.currency ? this.paymentDetails.currency : null,
        clientId: this.paymentAccountDetails && this.paymentAccountDetails.clientId,
        createOrderOnClient: (data: any): any => <ICreateOrderRequest>{
          intent: this.paymentDetails && this.paymentDetails.autoComplete ? 'CAPTURE' : 'AUTHORIZE',
          purchase_units: [
            {
              amount: {
                currency_code: this.paymentDetails && this.paymentDetails.currency ? this.paymentDetails.currency : null,
                value: this.paymentDetails && this.paymentDetails.totalAmount && this.paymentDetails.totalAmount.toFixed(2),
                breakdown: {
                  item_total: {
                    currency_code: this.paymentDetails && this.paymentDetails.currency ? this.paymentDetails.currency : null,
                    value: this.paymentDetails && this.paymentDetails.totalAmount && this.paymentDetails.totalAmount.toFixed(2),
                  }
                }
              },
              items: []
            }
          ]
        },
        advanced: {
          commit: 'true',
          extraQueryParams: [
            {
              name: 'intent',
              value: this.paymentDetails && this.paymentDetails.autoComplete ? 'capture' : 'authorize'
            }
          ]
        },
        style: {
          label: 'paypal',
          layout: 'vertical',
        },
        onApprove: (data: any, actions: any): void => {
          if (actions && actions.order) {
            this.onPaymentProcessed.emit({ isLoading: { value: true } });
            let funcName = this.paymentDetails && this.paymentDetails.autoComplete ? 'capture' : 'authorize';
            actions.order[funcName]().then(details => {
              this.zone.run(() => {
                if (isPlatformBrowser(this.platformId)) {
                  if (window) {
                    window.scrollTo({
                      top: window.pageYOffset + 10,
                      left: window.pageXOffset + 10,
                      behavior: 'smooth'
                    });
                  }
                }
                this.authorizePaymentOrder({
                  authorizationId: details && details.purchase_units && details.purchase_units[0]
                    && details.purchase_units[0].payments && details.purchase_units[0].payments.authorizations
                    && details.purchase_units[0].payments.authorizations[0] && details.purchase_units[0].payments.authorizations[0].id,
                  orderId: details && details.id
                });
              });
            }).catch(err => {
              this.onPaymentProcessed.emit({ isLoading: { value: false } });
              this.zone.run(() => {
                this.onPaymentProcessed.emit({
                  errorMsg: err && err.message,
                });
                this.commonService.createFailureTransactionLog({
                  pluginId: this.paymentAccountDetails && this.paymentAccountDetails.pluginId,
                  amount: this.paymentDetails && this.paymentDetails.totalAmount && this.paymentDetails.totalAmount.toFixed(2),
                  errorMessage: err && err.message,
                }, this.storeId);
              });
            });
          }
        },
        onCancel: (data: any, actions: any): void => {
          this.shouldNavigate = true;
          this.zone.run(() => {
            this.onPaymentProcessed.emit({ cancelPayment: true });
          });
        },
        onError: (err: any): void => {
          this.zone.run(() => {
            this.onPaymentProcessed.emit(null);
          });
        }
      };
    } catch (err) {
      this.onPaymentProcessed.emit({ isLoading: { value: false } });
      this.errorObject.message = 'Error in initConfig: ' + err && err.message ? err.message : null;
      this.sharedService.errorEmailNotification(this.storeId, this.errorObject);
      this.dialogService.dialogMethod(this.translateService.getTranslation('PAYMENT_DETAILS.FAILED_TO_SAVE'), this.dialogType.failure,
        true
      );
    }
  }
  /**
  * Method used to authorize paypal order
  * @param details Parameter passes the paypal authorization token
  */
  authorizePaymentOrder(details: any): void {
    try {
      this.onPaymentProcessed.emit({ isLoading: { value: true } });
      const payment: any = {
        token: details.orderId && this.commonDataService.encryptOrder(details.orderId),
        authorizationId: details.authorizationId && this.commonDataService.encryptOrder(details.authorizationId),
        paymentOrderId: details.paymentOrderId,
        amount: this.paymentDetails && this.paymentDetails.totalAmount >= 0 && this.commonDataService.encryptDetails(this.paymentDetails.totalAmount.toFixed(2)),
        locationId: this.paymentDetails && this.paymentDetails.locationId,
        autocomplete: this.paymentDetails && this.paymentDetails.autoComplete,
        userId: this.paymentDetails && this.paymentDetails.userId
      }
      if (this.paymentDetails && this.paymentDetails.isUrlCheckout) {
        payment.userId = this.paymentDetails.userId;
        payment.isUrlCheckout = this.paymentDetails.isUrlCheckout;
        payment["user"] = this.paymentDetails.user;
        payment["customerId"] = this.paymentDetails.customerId;
      }
      this.subscriptionObj.add(this.commonService.authorizePaymentOrder(payment, this.currentUser?.shopappUser ? this.sharedService?.storeId : this.storeId).subscribe((res) => {
        this.shouldNavigate = true;
        if (res && res['paymentOrderId']) {
          this.onPaymentProcessed.emit({
            paymentOrderId: res['paymentOrderId'],
          });
        } else {
          this.onPaymentProcessed.emit(res && res['error'] ? { errorMsg: res['error'] } : null);
        }
      }, (err) => {
        this.shouldNavigate = true;
        this.onPaymentProcessed.emit({ isLoading: { value: false } });
        this.onPaymentProcessed.emit((err && err.error && err.error.error) ? { errorMsg: err.error.error.details } : null);
      }));
    } catch (err) {
      this.onPaymentProcessed.emit({ isLoading: { value: false } });
      this.errorObject.message = 'Error in authorizePaymentOrder: ' + err && err.message ? err.message : null;
      this.sharedService.errorEmailNotification(this.storeId, this.errorObject);
      this.dialogService.dialogMethod(this.translateService.getTranslation('PAYMENT_DETAILS.FAILED_TO_SAVE'), this.dialogType.failure,
        true
      );
    }
  }
  /**
  * Function used to load the authorizedotnet script
  */
  authorizedotnetScripts(): void {
    let authorizedotnetscript = document.createElement("script");
    authorizedotnetscript.setAttribute("src", (this.paymentAccountDetails && this.paymentAccountDetails.isProduction) ? "https://js.authorize.net/v3/AcceptUI.js" :
      "https://jstest.authorize.net/v3/AcceptUI.js");
    authorizedotnetscript.setAttribute("type", "text/javascript");
    authorizedotnetscript.setAttribute("id", "authDotNet");
    document.head.appendChild(authorizedotnetscript);
  }
  /**
  * Method used for authorizedotnet payment
  *  @param response Parameters pass the response on payment.
  */
  public authorizeHandler = (response: object): void => {
    this.zone.run(() => {
      this.responseHandler(response);
      if (isPlatformBrowser(this.platformId)) {
        if (window.authorizeHandler) {
          window.authorizeHandler = (): void => {
            console.log("Authorize.Net order called.");
          };
        }
      }
    });
  }
  /**
  * Method used for assigning the authorizeHandler function to the window
  */
  authorizeDotNetPayments(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.shouldNavigate = false;
      window.authorizeHandler = this.authorizeHandler;
    }
  }
  /**
  * Method used to authorize authorize dot net order
  * @param response Parameters pass the response on payment.
  */
  responseHandler(response: any): void {
    try {
      if (response && response.messages && response.messages.resultCode === "Error" && response.messages.message && response.messages.message.length) {
        var i = 0, text = [];
        while (i < response.messages.message.length) {
          if (response.messages.message[i].text) {
            text.push(response.messages.message[i].text);
          }
          i = i + 1;
        }
        this.onPaymentProcessed.emit({
          errorMsg: text.join(',')
        });
      } else {
        const payment: any = {
          amount: this.paymentDetails && this.paymentDetails.totalAmount >= 0 && this.commonDataService.encryptDetails(this.paymentDetails.totalAmount.toFixed(2)),
          dataDescriptor: response && response.opaqueData && response.opaqueData.dataDescriptor && this.commonDataService.encryptDetails(response.opaqueData.dataDescriptor),
          dataValue: response && response.opaqueData && response.opaqueData.dataValue && this.commonDataService.encryptDetails(response.opaqueData.dataValue),
          autocomplete: this.paymentDetails && this.paymentDetails.autoComplete
        };
        if (this.paymentDetails && this.paymentDetails.isUrlCheckout) {
          payment.userId = this.paymentDetails.userId;
          payment.isUrlCheckout = this.paymentDetails.isUrlCheckout;
          payment.user = this.paymentDetails.user;
          payment.customerId = this.paymentDetails.customerId;
        }
        this.onPaymentProcessed.emit({ isLoading: { value: true } });
        this.subscriptionObj.add(this.commonService.createAuthorizeDotNet(payment, this.currentUser?.shopappUser ? this.sharedService.storeId : this.storeId).subscribe((res) => {
          if (res && res['paymentOrderId']) {
            this.shouldNavigate = true;
            this.onPaymentProcessed.emit({
              paymentOrderId: res['paymentOrderId'],
            });
          } else {
            this.shouldNavigate = true;
            this.onPaymentProcessed.emit(res && res['error'] ? { errorMsg: res['error'] } : null);
          }

        }, (err) => {
          this.shouldNavigate = true;
          this.onPaymentProcessed.emit({ isLoading: { value: false } });
          this.onPaymentProcessed.emit((err && err.error && err.error.error) ? { errorMsg: err.error.error.details } : null);
        }));
      }
    } catch (err) {
      this.onPaymentProcessed.emit({ isLoading: { value: false } });
      this.errorObject.message = 'Error in authorization.net - PaymentOption ' + err && err.message ? err.message : null;
      this.sharedService.errorEmailNotification(this.storeId, this.errorObject);
      this.dialogService.dialogMethod(this.translateService.getTranslation('PAYMENT_DETAILS.FAILED_TO_SAVE'), this.dialogType.failure,
        true
      );
    }
  }
  /**
   * Function used to load the CheckoutDotCom script
  */
  loadCheckoutDotComScript(): any {
    return new Promise((resolve, reject) => {
      try {
        if (isPlatformBrowser(this.platformId)) {
          if (!(window && window.Frames)) {
            let checkoutDotCom = document.createElement("script");
            checkoutDotCom.setAttribute("src", "https://cdn.checkout.com/js/framesv2.min.js");
            checkoutDotCom.setAttribute("type", "text/javascript");
            document.head.appendChild(checkoutDotCom);
            checkoutDotCom.onload = function () {
              resolve(true);
            };
          } else {
            resolve(true);
          }
        }
      }
      catch (err) {
        reject(false);
      }
    })
  }
  /**
    * Function used to load the CheckoutDotCom Payment
    */
  onCheckoutDotCom(): void {
    try {
      if (isPlatformBrowser(this.platformId)) {
        if (window && window.Frames) {
          Frames.removeAllEventHandlers(Frames.Events.FRAME_VALIDATION_CHANGED);
          Frames.removeAllEventHandlers(Frames.Events.CARD_TOKENIZATION_FAILED);
        }
      }
      Frames.init(this.paymentAccountDetails && this.paymentAccountDetails.publicAPIKey);
      Frames.addEventHandler(Frames.Events.FRAME_VALIDATION_CHANGED, (event) => {
        this.checkoutDotComonValidationChanged(event);
      });
      Frames.addEventHandler(Frames.Events.CARD_TOKENIZATION_FAILED, this.checkoutDotComTokenizationFailed);
    } catch (err) {
      this.onPaymentProcessed.emit({ isLoading: { value: false } });
      this.errorObject.message = 'Error in onCheckoutDotComPayment: ' + err && err.message ? err.message : null;
      this.sharedService.errorEmailNotification(this.storeId, this.errorObject);
      this.dialogService.dialogMethod(this.translateService.getTranslation('PAYMENT_DETAILS.FAILED_TO_SAVE'), this.dialogType.failure,
        true
      );
    }
  }
  /**
  * Function used for CheckoutDotCom Payment TokenizationFailed
  */
  checkoutDotComTokenizationFailed() {
    Frames.enableSubmitForm();
  }
  /**
  * Function used for CheckoutDotCom Payment validation
  * @param event Parameters pass validation details
  */
  checkoutDotComonValidationChanged(event: any): void {
    if (event && event.element) {
      let errorMessageElement = document && document.querySelector(".error-message");
      let hasError = !event.isValid && !event.isEmpty;
      if (hasError) {
        this.errorStack.push(event.element);
      } else {
        this.errorStack = this.errorStack.filter((element) => {
          return element !== event.element;
        });
      }
      var errorMessage = this.errorStack && this.errorStack.length ?
        this.translateService.getTranslation('PAYMENT_DETAILS.' + this.errorStack[this.errorStack.length - 1]) : "";
      if (errorMessageElement) {
        errorMessageElement.textContent = errorMessage;
      }
    }
  }

  /**
  * Function used for CheckoutDotCom Token generation and order
  */
  async onCheckoutDotComPayment(): Promise<void> {
    try {
      if (isPlatformBrowser(this.platformId)) {
        if (window.Frames) {
          await Frames.submitCard().then((res) => {
            if (res && res.token) {
              this.zone.run(() => {
                this.checkoutDotComAuthorize(res.token);
              });
            }
          });
        }
      }
    } catch (e: any) {
      this.onPaymentProcessed.emit({ isLoading: { value: false } });
      this.errorObject.message = 'Error in onCheckoutDotComPayment: ' + (e && e.message ? e.message : e);
      this.sharedService.errorEmailNotification(this.storeId, this.errorObject);
      this.onPaymentProcessed.emit({ errorMsg: e && e.message ? e.message : e });
    }
  }
  /**
   * Method used to authorize checkout dot com order
   * @param token Parameters pass the token.
   */
  checkoutDotComAuthorize(token?: string): void {
    this.shouldNavigate = false;
    try {
      let payment: any = {
        newSaveCard: this.isNewCard && this.isSavedCard && this.isSavedCard.value,
        paymentCustomerId: this.cardInfo && this.cardInfo[0] && this.cardInfo[0].customer && this.commonDataService.encryptDetails(this.cardInfo[0].customer),
        customerCardId: !this.isNewCard && this.selectedCard && this.selectedCard.cardId && this.commonDataService.encryptDetails(this.selectedCard.cardId),
        token: token && this.commonDataService.encryptDetails(token),
        autocomplete: this.paymentDetails && this.paymentDetails.autoComplete,
        locationId: this.paymentDetails && this.paymentDetails.locationId,
        isDefault: this.defaultCard,
        amount: this.paymentDetails && this.paymentDetails.totalAmount >= 0 && this.commonDataService.encryptDetails(this.paymentDetails.totalAmount.toFixed(2)),
        currency: this.paymentDetails && this.paymentDetails.currency,
        customerId: this.currentUser && this.currentUser.roleCode !== 'GUEST' ? this.currentUser.customerId : null,
      }
      if (this.paymentDetails && this.paymentDetails.isUrlCheckout) {
        payment.userId = this.paymentDetails.userId;
        payment.email = this.paymentDetails.email;
        payment.isUrlCheckout = this.paymentDetails.isUrlCheckout;
        payment["user"] = this.paymentDetails.user;
        payment["customerId"] = this.paymentDetails.customerId;
      }
      this.subscriptionObj.add(this.commonService.checkoutDotComOrder(payment, this.currentUser?.shopappUser ? this.sharedService.storeId : this.storeId).subscribe((res) => {
        this.shouldNavigate = true;
        if (res && res['paymentOrderId']) {
          this.onPaymentProcessed.emit({
            paymentOrderId: res['paymentOrderId'],
          });
        } else {
          Frames.enableSubmitForm();
          this.onPaymentProcessed.emit(res && res['error'] ? { errorMsg: res['error'] } : null);
        }
      }, (err) => {
        this.shouldNavigate = true;
        Frames.enableSubmitForm();
        this.onPaymentProcessed.emit((err && err.error && err.error.error) ? { errorMsg: err.error.error.details } : null);
      }));
    } catch (err) {
      Frames.enableSubmitForm();
      this.errorObject.message = 'Error in checkoutDotComOrder: ' + err && err.message ? err.message : null;
      this.sharedService.errorEmailNotification(this.storeId, this.errorObject);
      this.dialogService.dialogMethod(this.translateService.getTranslation('PAYMENT_DETAILS.FAILED_TO_SAVE'), this.dialogType.failure,
        true
      );
    }
  }
  /**
   * Method which is used to call billing address dialog box
   */
  addressDialog(): void {
    if (this.shippingAddressForm && this.shippingAddressForm.value && this.shippingAddressForm.value.state) {
      let stateData = this.countryAndStateValueFetch(this.shippingAddressForm.value.state.id, 'states');
      this.shippingAddressForm?.get('state')?.setValue(stateData);
    }
    this.dialog.open(this.payAddress, {
      autoFocus: false,
      disableClose: true,
    });
  }
  /**
   * Method which is used to close billing address dialog box
   */
  onClose(): void {
    if (this.shippingAddressForm && this.shippingAddressForm.dirty) {
      const dialog = this.dialogService.dialogMethod(this.translateService.getTranslation('PAYMENT_DETAILS.DIALOG_CLOSE'), this.dialogType.confirmation, true);
      dialog.afterClosed().subscribe((res) => {
        if (res) {
          if (this.billingAddress != null) {
            let countryData = this.countryAndStateValueFetch(this.billingAddress.country && this.billingAddress.country.id, 'countries');
            this.shippingAddressForm?.get('country')?.setValue(countryData);
            let stateData = this.countryAndStateValueFetch(this.billingAddress.state && this.billingAddress.state.id, 'states');
            this.shippingAddressForm?.get('state')?.setValue(stateData);
            if (this.shippingAddressForm.invalid) {
              this.getStates(this.billingAddress);
            }
            this.shippingAddressForm.patchValue({
              fullName: this.billingAddress.fullName,
              mobileNumber: this.billingAddress.mobileNumber,
              city: this.billingAddress.city,
              zipCode: this.billingAddress.zipCode,
              addressLine1: this.billingAddress.addressLine1,
            });
          }
          if (this.isGiftCard) {
            let countryData = this.countryAndStateValueFetch(this.billingAddress &&
              this.billingAddress.country && this.billingAddress.country.id ?
              this.billingAddress.country.id : null, 'countries');
            this.shippingAddressForm?.get('country')?.setValue(countryData);
            let stateData = this.countryAndStateValueFetch(this.billingAddress &&
              this.billingAddress.state && this.billingAddress.state.id ? this.billingAddress.state.id : null, 'states');
            this.shippingAddressForm?.get('state')?.setValue(stateData);
            if (this.shippingAddressForm.invalid) {
              this.getStates(this.billingAddress);
            }
            this.shippingAddressForm.patchValue({
              fullName: this.billingAddress && this.billingAddress.fullName ? this.billingAddress.fullName : null,
              mobileNumber: this.billingAddress && this.billingAddress.mobileNumber ? this.billingAddress.mobileNumber : null,
              city: this.billingAddress && this.billingAddress.city ? this.billingAddress.city : null,
              zipCode: this.billingAddress && this.billingAddress.zipCode ? this.billingAddress.zipCode : null,
              addressLine1: this.billingAddress && this.billingAddress.addressLine1 ? this.billingAddress.addressLine1 : null,
            });
          }
          this.shippingAddressForm.markAsPristine();
          this.shippingAddressForm.markAsUntouched();
          this.dialog.closeAll();
        }
      });
    } else {
      this.shippingAddressForm.markAsUntouched();
      this.dialog.closeAll();

    }
  }
  /**
   * Method which is used to save billing address data
   */
  savePaymentAddress(): void {
    if (this.shippingAddressForm && this.shippingAddressForm.valid && this.shippingAddressForm.dirty) {
      this.checkedShippingAddress = false;
      this.dialogService.dialogMethod(this.translateService.getTranslation(this.billingAddress?.fullName ? ('PAYMENT_DETAILS.CARD_ADDRESS_UPDATED') : ('PAYMENT_DETAILS.CARD_ADDRESS_ADDED')), this.dialogType.success, true);
      this.billingAddress = {
        addressLine1: this.shippingAddressForm?.get('addressLine1')?.value,
        city: this.shippingAddressForm?.get('city')?.value,
        country: this.shippingAddressForm?.get('country')?.value,
        fullName: this.shippingAddressForm?.get('fullName')?.value,
        mobileNumber: this.shippingAddressForm?.get('mobileNumber')?.value,
        state: this.shippingAddressForm?.get('state')?.value,
        zipCode: this.shippingAddressForm?.get('zipCode')?.value
      };
      this.shippingAddressForm.markAsPristine();
      this.dialog.closeAll();
    } else
      this.dialogService.dialogMethod(this.translateService.getTranslation('PAYMENT_DETAILS.NO_CHANGES'), this.dialogType.alert, true);
  }
  /**
   * Ondestroy to unsubscribe the api.
   */
  ngOnDestroy(): void {
    if (this.paymentAccountDetails && this.paymentAccountDetails.pluginCode) {
      if (this.paymentAccountDetails.pluginCode === dataConfig.paymentOption.AUTHORIZEDOTNET) {
        const { element1, element2 } = {
          element1: document.getElementById('AcceptUIContainer') as HTMLElement,
          element2: document.getElementById('AcceptUIBackground') as HTMLElement
        };
        if (element1 && element2) {
          element1.remove();
          element2.remove();
        }
      }
      if (this.paymentAccountDetails.pluginCode === dataConfig.paymentOption.RAZORPAY) {
        const { element1, element2 } = {
          element1: document.getElementById('backdrop') as HTMLElement,
          element2: document.getElementById('modal') as HTMLElement
        };
        if (element1 && element2) {
          element1.remove();
          element2.remove();
        }
      }
    }
    this.subscriptionObj.unsubscribe();
    if (document && document.getElementById("authDotNet")) {
      document.getElementById("authDotNet").remove();
    }
  }
}
