import { Directive, AfterViewInit, ElementRef, EventEmitter, Output, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[checkElipsis]'
})
export class HeaderCardDirective implements AfterViewInit {
  /**
   * EvenEmitter helps to check title elipsis is enable or not
   */
  @Output() titleElipsisEnabled = new EventEmitter<any>();
  /**
   * EvenEmitter helps to check description elipsis is enable or not
   */
  @Output() descElipsisEnabled = new EventEmitter<any>();
  /**
   * It gives current element name from parent component
   */
  @Input() elementName;
  /**
   * 
   * @param elementRef 
   */
  constructor(private elementRef: ElementRef) { }
  /**
   * Angular life-cycle hooks
   */
  ngAfterViewInit(): void {
    this.onResize();
  }
  /**
   * Function called every changes of screen and it check elipsis is activated or not.
   */
  @HostListener('window:resize', ['$event'])
  onResize() {
    if (this.elementName == 'title') {
      const element = this.elementRef.nativeElement;
      if (element.offsetHeight < element.scrollHeight) {
        this.titleElipsisEnabled.emit(true);
      }
      else {
        this.titleElipsisEnabled.emit(false);
      }
    }
    else if (this.elementName == 'description') {
      const element = this.elementRef.nativeElement;
      if (element.offsetHeight < element.scrollHeight) {
        this.descElipsisEnabled.emit(true);
      }
      else {
        this.descElipsisEnabled.emit(false);
      }
    }
  }

}
