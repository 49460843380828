/**
 * Pipe used to format image file name
 * Author: Ponmani B
 */
import { Pipe, PipeTransform } from '@angular/core';
/**
 * Pipe used to format image file name
 */
@Pipe({
  name: 'imageFileNameFormat'
})
export class ImageFileNameFormatPipe implements PipeTransform {
  /**
   * variable name used to store file name
   */
  formattedFileName: any;
  /**
   * variable used to store file extension
   */
  fileExtension  = {
    GIF_FORMAT: 'gif',
    WEPB_FORMAT: 'webp',
    AVIF_FORMAT: 'webp'
   };
  /**
   * Function which is used to format image file name.
   * @returns formatted file format.
   */
  transform(value: string, extension: string,imageWidth?: string): string {
    if (value && !value?.includes('base64') && !value?.includes(',') && !value?.includes('assets/')) {
      let path = value.slice(0, value.lastIndexOf('/') + 1);
      let fileName = value.slice(value.lastIndexOf('/') + 1, value.lastIndexOf('.'));
      let originalExtension = value.slice(value.lastIndexOf('.') + 1);
      extension = extension && (originalExtension === this.fileExtension.GIF_FORMAT && extension === this.fileExtension.AVIF_FORMAT) ? this.fileExtension.WEPB_FORMAT : extension;
      if(fileName && extension && imageWidth){
        this.formattedFileName = path + fileName + '-' + imageWidth + '.' + extension;
      }
      else if (fileName && extension) {
        this.formattedFileName = path + fileName + '.' + extension;
      }
    }
    return this.formattedFileName ? `url("${this.formattedFileName}"),url("${value}")` : `url("${value}")`;
  }

}
