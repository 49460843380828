<div [ngSwitch]="question?.controlTypeName" class="controls" [formGroup]="questionForm">
    <br>

    <ng-container *ngSwitchCase="'Radio'">
        <mat-label data-control="modifierName1Control">{{question?.modifierName}}</mat-label><br>
        <mat-radio-group [formControlName]="question?.checkoutQuestionId"
            (change)="customQuestionValues?.emit(questionForm?.value)" fxLayout="column" fxLayoutGap="5px">
            <mat-radio-button *ngFor="let option of question?.optionValues;let id=index" [value]="option"
                [attr.data-control]="'value1'+id">
                {{option}}
            </mat-radio-button>
        </mat-radio-group>
    </ng-container>

    <ng-container *ngSwitchCase="'Checkbox'">
        <mat-label data-control="modifierName2Control">{{question?.modifierName}}</mat-label><br>
        <div fxLayout="column" fxLayoutGap="5px" [formArrayName]="question?.checkoutQuestionId">
            <ng-container *ngFor="let opt of questionForm?.get([question?.checkoutQuestionId])?.controls;let id=index"
                [formGroupName]="id">
                <mat-checkbox [attr.data-control]="'opt2Name'+id"
                    (change)="customQuestionValues?.emit(questionForm?.value)" formControlName="optionChecked">
                    {{opt?.value?.optionName}}
                </mat-checkbox>
            </ng-container>
        </div>
    </ng-container>

    <ng-container *ngSwitchCase="'Input'">
        <mat-form-field appearance="fill" class="full-width">
            <mat-label data-control="modifierName3Control">{{question?.modifierName}}</mat-label>
            <input matInput data-control="ques3Control" [formControlName]="question?.checkoutQuestionId"
                (change)="customQuestionValues?.emit(questionForm?.value)">
        </mat-form-field>
    </ng-container>

    <ng-container *ngSwitchCase="'Text Area'">
        <mat-form-field appearance="fill" class="full-width">
            <mat-label data-control="modifierName4Control">{{question?.modifierName}}</mat-label>
            <textarea data-control="ques2Control" matInput [formControlName]="question?.checkoutQuestionId"
                (change)="customQuestionValues?.emit(questionForm?.value)"></textarea>
        </mat-form-field>
    </ng-container>

    <ng-container *ngSwitchCase="'Slider Toggle'">
        <mat-label data-control="modifierName5Control">{{question?.modifierName}}</mat-label> &nbsp;
        <mat-slide-toggle data-control="ques1Control" [formControlName]="question?.checkoutQuestionId"
            (change)="customQuestionValues?.emit(questionForm?.value)"></mat-slide-toggle>
    </ng-container>

    <ng-container *ngSwitchCase="'Dropdown'">
        <mat-form-field appearance="fill" class="full-width">
            <mat-label data-control="modifierName6Control">{{question?.modifierName}}</mat-label>
            <mat-select [formControlName]="question?.checkoutQuestionId"
                (selectionChange)="customQuestionValues?.emit(questionForm?.value)">
                <mat-option *ngFor="let option of question?.optionValues;let id=index" [attr.data-control]="'value1'+id"
                    [value]="option">{{option}}</mat-option>
            </mat-select>
        </mat-form-field>
    </ng-container>

</div>