import {
  Injectable,
  Inject,
  InjectionToken,
  ErrorHandler
} from '@angular/core';
import * as Rollbar from 'rollbar';

let rollbarConfig = {};


// export const RollbarService = new InjectionToken<Rollbar>('rollbar');

@Injectable()
export class RollbarErrorHandler implements ErrorHandler {
  environment: any;

  rollbar!: Rollbar;


  constructor(
    // @Inject(RollbarService) private rollbar: Rollbar, 
    @Inject('environment') environment) {
    this.environment = environment;
    rollbarConfig = {
      accessToken: environment.rollbarKey,
      captureUncaught: true,
      captureUnhandledRejections: true,
      environment: environment.app
    };
    this.rollbar = new Rollbar(rollbarConfig);
  }


  handleError(err: any): void {
    if (this.environment.app !== "local" && this.environment.app !== "test") {
      this.rollbar.error(err.message || err);
    } else {
      console.log(err);
    }
  }
}

// export function rollbarFactory() {
//   console.log("sample");
//   return new Rollbar(rollbarConfig);
//
// }