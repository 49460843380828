/**
 * Excel Service which is used to Export and import the given details.
 */
import { Injectable } from '@angular/core';
// import * as Excel from 'exceljs/dist/exceljs.min.js';
import { excelConstant } from '../constants/shared-constant';
// import { Workbook } from 'exceljs';
declare const ExcelJS  : any;
declare const Workbook : any;
/**
 * Excel Service which is used to Export and import the given details.
 */
@Injectable({
  providedIn: 'root'
})
/**
 * Excel Service which is used to Export and import the given details.
 */
export class ExcelService {

  // Excel =ExcelJS;
  /**
   * common variable used to store the constants file.
   */
  commonConstant!: any;
  /**
   * Component constructor to inject reqiured service.
   * Service constructor to inject the other needed services here.
   */
  constructor(
  ) {
    this.commonConstant = new excelConstant();
  }

  /**
   * Method which is used to import the data.
   * @param sheetHeader which is used to store the sheet headers.
   * @param templateName which is used to store the template name.
   * @param sheetsName which is used to store the sheets name.
   */
  import(sheetHeader: any[], templateName: string, sheetsName?: any, title?: any): any {
    const sheet2Header = [];
    const workbook = new ExcelJS.Workbook();
    let i, worksheet = [];
    //to create worksheets
    if (sheetHeader && sheetHeader.length) {
      for (i = 0; i < sheetHeader.length; i++) {
        worksheet[i] = workbook.addWorksheet((sheetsName && sheetsName[i]) ? sheetsName[i] : ('Sheet' + (i + 1)));
      }
      // To design the header of sheets
      for (i = 0; i < sheetHeader.length; i++) {
        worksheet[i].columns = sheetHeader[i];
        worksheet[i].getRow(1).eachCell((cell) => {
          cell.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: 'ADFF2F' },
            bgColor: { argb: 'FF0000FF' },
          };
          cell.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
        });
      }
      // to create the instruction sheet
      for (let j = 0; j < sheetHeader.length; j++) {
        for (let k = 0; k < sheetHeader[j].length; k++) {
          if (sheetHeader[j][k].instruction) {
            worksheet[sheetHeader.length + 1] = workbook.addWorksheet('Instructions');
            worksheet[sheetHeader.length + 1].getCell('A1').value = "Instructions";
            worksheet[sheetHeader.length + 1].getRow(1).eachCell((value) => {
              value.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: { argb: 'ADFF2F' },
                bgColor: { argb: 'FF0000FF' },
              };
              value.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
            });
            // for (let g = 0; g < sheetHeader[j][k].information.length; g++) {
            //   worksheet[sheetHeader.length + 1].getCell('A' + (g + 3)).value = sheetHeader[j][k].information[g];
            // }
            // for (let g of sheetHeader[j][k].information) {
            //   worksheet[sheetHeader.length + 1].getCell('A' + (g + 3)).value = g;
            // }
            if (sheetHeader[j][k].information && sheetHeader[j][k].information.length) {
              for (let l = 0; l < sheetHeader[j][k].information.length; l++) {
                worksheet[sheetHeader.length + 1].getCell('A' + (l + 2)).value = sheetHeader[j][k].information[l];
              }
            }
          }
        }
      }
      // To create the sample values in downloaded file.
      for (let j = 0; j < sheetHeader.length; j++) {
        for (let k = 0; k < sheetHeader[j].length; k++) {
          if (sheetHeader[j][k].sampleValue) {
            // const alpha = Array.from(Array(26)).map((e, l) => l + 65);
            // const alphabets = alpha.map((x) => String.fromCharCode(x));
            const alphabets = [];
            // Generate column headings and store them in the 'alphabets' array
            for (let i = 0; i < 60; i++) {
              alphabets.push(this.getColumnHeading(i));
            }
            // Print the 'alphabets' array
            let sampleArea = [], m = 0;
            alphabets.forEach((item) => {
              sampleArea[m] = item.concat('2');
              m++;
            })
            worksheet[j].getCell(sampleArea[k]).value = sheetHeader[j][k].sampleValue;
          }
        }
      }
    }
    // to create the sheet for the dropdown values
    if (sheetHeader && sheetHeader.length) {
      let j;
      for (j = 0; j < sheetHeader.length; j++) {
        for (let k = 0; k < sheetHeader[j].length; k++) {
          if (sheetHeader[j][k].dropDownValues && sheetHeader[j][k].dropDownValues.length > 0) {
            sheet2Header.push({
              header: sheetHeader[j][k].header,
              width: sheetHeader[j][k].width,
              key: sheetHeader[j][k].key,
              dropDownValues: sheetHeader[j][k].dropDownValues
            });
          } else if (sheetHeader[j][k].dropDownKey) {
            sheet2Header.push({
              width: sheetHeader[j][k].width,
              key: sheetHeader[j][k].key,
              dropDownFormula: sheetHeader[j][k].dropDownFormula,
              sheetInfo: sheetHeader[j][k].sheetInfo,
              dropDownValues: sheetHeader[j][k].dropDownValues
            });
          }
        }
      }
      // validation
      // for (let p = 0; p < sheetHeader.length; p++) {
      //   sheetHeader[p].find((item, index) => {
      //     this.commonConstant.excelColumnValidation.find((validateItem) => {
      //       if (item.key === validateItem.label) {
      //         let i;
      //         for (i = 1; i < this.commonConstant.importCount; i++) {
      //           let validationFormulae, j;
      //           worksheet[p].getColumn(index + 1).eachCell((cell) => {
      //             const row = cell.row + i;
      //             if (item.key === this.commonConstant.validationColumnName.ContactNumber) {
      //               validationFormulae = 'AND(OR(ISNUMBER(' + cell._column.letter + row + '),ISTEXT(' + cell._column.letter + row + '))' + ',' +
      //                 'LEN(TEXT(' + cell._column.letter + row + ',"0")) <= 20)';
      //             } else if (item.key === this.commonConstant.validationColumnName.ZipCode) {
      //               validationFormulae = 'AND(ISNUMBER(' + cell._column.letter + row + ')' + ',' +
      //                 'LEN(' + cell._column.letter + row + ')' + '<' + 16 + ')';
      //             }
      //             else if (item.key === this.commonConstant.validationColumnName.Email) {
      //               validationFormulae ='AND(FIND("@",A2),FIND(".",A2),ISERROR(FIND(" ",A2)))';
      //             validationFormulae = 'AND(FIND("@"' + cell._column.letter + row + ')' + ',' +
      //             'FIND("."' + cell._column.letter + row + ')' + ')';
      //             'AND(FIND(“@”,A2),FIND(“.”,A2),ISERROR(FIND(” “,A2)))'
      //             }
      //             worksheet[p].getCell(cell.row + i, cell.col).dataValidation = {
      //               type: validateItem.type,
      //               allowBlank: validateItem.allowBlank,
      //               visible: validateItem.visible,
      //               showInputMessage: validateItem.showInputMessage,
      //               showErrorMessage: validateItem.showErrorMessage,
      //               errorStyle: validateItem.errorStyle,
      //               errorTitle: validateItem.errorTitle,
      //               error: validateItem.error,
      //               promptTitle: validateItem.promptTitle,
      //               prompt: validateItem.prompt,
      //               formulae: validationFormulae ? [validationFormulae] : []
      //             };
      //           });
      //         }
      //       }
      //     });
      //   });
      // }
    }
    // to create dropdown sheet and to asssign the values
    if (sheet2Header && sheet2Header.length > 0) {
      if (templateName && templateName !== 'options_sample_template.xlsx') {
        worksheet[sheetHeader.length] = workbook.addWorksheet('Dropdown');
        worksheet[sheetHeader.length].columns = sheet2Header;
      }
      for (let j = 0; j < sheet2Header.length; j++) {
        const sheet2HeaderandValues = [];
        //pushing the heading and the values
        sheet2HeaderandValues.push(sheet2Header[j].header);
        // to set the dropdown values for the wriiten entries.
        if (sheet2Header[j] && sheet2Header[j].dropDownValues && sheet2Header[j].dropDownValues.length == 0) {
          let listDisplayAddress;
          listDisplayAddress = sheet2Header[j].dropDownFormula;
          sheet2Header[j].sheetInfo.forEach((item) => {
            const selectedSheet = workbook.getWorksheet(item);
            const selectedColumn = selectedSheet.getColumn(sheet2Header[j].key);
            let rowCount = 2500;
            // if (title === 'PRODUCTS' || 'MENUTIMING') {
            //   rowCount = 2000;
            // }
            for (let i = 1; i < rowCount; i++) {
              selectedColumn.eachCell(cell => {
                selectedSheet.getCell(cell.row + i, cell.col).dataValidation = {
                  type: 'list',
                  allowBlank: false,
                  visible: true,
                  showErrorMessage: true,
                  errorStyle: 'error',
                  errorTitle: 'Error',
                  error: 'Value must be in the list',
                  formulae: [listDisplayAddress]
                };
              })
            }
          })
        }
        if (sheet2Header[j] && sheet2Header[j].dropDownValues && sheet2Header[j].dropDownValues.length) {
          sheet2Header[j].dropDownValues.map(d => { sheet2HeaderandValues.push(d.name); });
          // assigning the values to columns in last sheet
          if (worksheet && worksheet[sheetHeader.length]) {
            worksheet[sheetHeader.length].getColumn(sheet2Header[j].key).values = sheet2HeaderandValues;
            //to dark the 1st line
            worksheet[sheetHeader.length].getRow(1).eachCell((cell) => { cell.font = { bold: true }; });
          }

          // to get the 1st...n column values and store
          const sheet2SelectedColumn = worksheet[sheetHeader.length] && worksheet[sheetHeader.length].getColumn(sheet2Header[j].key);
          // To set the dropdown values for the remaining sheets.
          for (let m = 0; m < sheetHeader.length; m++) {
            for (let g = 0; g < sheetHeader[m].length; g++) {
              // to check whether the heading matches with the last dropdwon coliumn name
              if (sheet2Header[j].key === sheetHeader[m][g].key) {
                // to find the match column 
                const sheet1SelectedColumn = workbook.worksheets[m].getColumn(sheet2Header[j].key);
                for (let i = 1; i < this.commonConstant.importCount; i++) {
                  if (sheet2Header[j].dropDownValues) {
                    sheet1SelectedColumn.eachCell(cell => {
                      const rowAddressLength = sheet2Header[j].dropDownValues.length + 1;
                      let listDisplayAddress = 'Dropdown!' + '$' + (sheet2SelectedColumn && sheet2SelectedColumn.letter) + '$' + 2 + ':' + '$' +
                        (sheet2SelectedColumn && sheet2SelectedColumn.letter) + '$' + rowAddressLength;
                      worksheet[m].getCell(cell.row + i, cell.col).dataValidation = {
                        type: 'list',
                        allowBlank: false,
                        visible: true,
                        showErrorMessage: true,
                        errorStyle: 'error',
                        errorTitle: 'Error',
                        error: 'Value must be in the list',
                        formulae: [listDisplayAddress]
                      };
                    });
                  }
                }
              }
            }
          }
        }
      }
    }
    return workbook.xlsx.writeBuffer();
  }
  /**
   * Method which is used to download the CSV and XLSX files.
   * @param blob which is used to store the blob.
   * @param templateName which is used to store the template file name.
   */
  downloadFile(blob: Blob, templateName: string): boolean {
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    /* 
    const a = document.createElement('a');
    a.setAttribute('display', 'none');
    a.href = downloadServiceUrl+"?id="+id";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    */
    link.download = templateName;
    link.click();
    link.remove();
    URL.revokeObjectURL(link.href);
    return true;
  }
  /**
    * Method which is used to get column heading.
    * @param columnIndex which is used to get the column index.
    */
  getColumnHeading(columnIndex) {
    let heading = "";
    while (columnIndex >= 0) {
      heading = String.fromCharCode(65 + (columnIndex % 26)) + heading;
      columnIndex = Math.floor(columnIndex / 26) - 1;
    }
    return heading;
  }

  /**
 * function for create xlsx
 * @param file which is used for get the href
 * @param fileName which is used to get the file name
 */
  createXLSX(file, fileName,fileExtension?) {
    const tempLink = document.createElement('a');
    tempLink.href = file;
    document.body.appendChild(tempLink);
    tempLink.download = (fileName ? ('export_' + fileName) : 'ExportFile') +(fileExtension? fileExtension :'.xlsx');
    tempLink.click();
    tempLink.remove();
    return true;
  }

  CreateExcelWithData(Data) {
    let workbook = new Workbook();
    let worksheet = workbook.addWorksheet(Data.SheetName);
    let header = Data.Header;
    worksheet.addRow(header);
    for (let x1 of Data.exportData) {
      let x2 = Object.keys(x1);
      let temp = []
      for (let y of x2) {
        temp.push(x1[y])
      }
      worksheet.addRow(temp)
    }
    workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      const fileURL = URL.createObjectURL(blob);
      this.createXLSX(fileURL, "Orders");
    });
  }
  /**
  * function for change buffer
  * @param s which is used for get the value
  */
  changeBuffer(s) {
    const buf = new ArrayBuffer(s.length); //convert s to arrayBuffer
    const view = new Uint8Array(buf);  //create uint8array as viewer
    // tslint:disable-next-line:no-bitwise
    for (let i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xFF; //convert to octet
    return view;
  }
  /**
  * function for send message
  * @param data which is used for get the data
  */
  whatsappMessage(data: any) {
    if (data && data.text && data.phoneNumber && data.countryCode) {
      const link = "https://web.whatsapp.com/send?text=" + data.text + "&phone=" + data.countryCode + data.phoneNumber;
      window.open(link, '_blank');
    } else {
      throw new Error("countryCode or text or phoneNumber value are missing!")
    }
  }

  getScreenSize(height: number, width: number) {
    if (height <= 800 && width <= 600) {
      return true;
    }
    else {
      return false;
    }
  }
}
