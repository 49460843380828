import { NgModule } from '@angular/core';
import { CommonChangePasswordComponent } from './components/common-change-password/common-change-password.component';
// import { AccessGuard } from '../../../common/src/lib/services/access-guard.service';
import { MessagingService } from './services/messaging.service';
import { CoreModule } from '@phase-ii/common';


@NgModule({
  declarations: [
    CommonChangePasswordComponent,
  ],
  providers: [
    // AccessGuard,
     MessagingService],
  imports: [ CoreModule],
  exports: [
    CommonChangePasswordComponent
  ],
})
export class AuthModule { 

}
