export const commonInfo = {
    "homeQuote": "An E-commerce platform to bring your business online",
    "homeHeader": "A Modern Way To Sell Your Products",
    "deliveryQuote": "Simple and convenient online ordering for delivery, pick-up or local shipping.",
    "businessQuote": "Grow your business and reach new customers by partnering with us.",
    "moneyQuote": "Your time, Your money Keep more of both ",
    "storeQuote1": "Own Your Store",
    "storeQuote2": "Control everything from a single dashboard.  ZenBasket allows you to upload unlimited products through our platform. ZenBasket allows growing your business by integrating with other third-party platforms.  Manage product and order details from ZenBasket Admin App.",
    "thinkQuote1": "Customize Your E-commerce Business",
    "thinkQuote2": "Like to sell Groceries, Beverages, Foods, Cakes and Coffees with a limited budget? No worries we have a ZenBasket shop to reach your local customers and sell your products online. Want to be a member at the ZenBasket shop?",
    "contactQuote1": "Reach More Audience",
    "contactQuote2": "We help you to reach your targeted customer to gain a better network and let them order your products by bringing your business online. We’ll let your brand be noticed by your customers with our ZenBasket shop for web and mobile.",
    "content1": "Want to own a separate domain like a single brand?",
    "content2": "Want to be a part of ZenBasket Shop?",
    "content3": "Want to have ZenBasket Subdomain?"
};