import { DatePipe } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
// import { AuthService } from '../../../../../auth/src/lib/services/auth.service';
import { of, Subscription } from 'rxjs';
import { filter, mergeMap } from 'rxjs/operators';
import { ReportsService } from '../../services/reports.service';
import { CommonService } from '../../../../../shared/src/lib/services/common.service';
import { ColumnsRef, CommonAdminShardConstants, ListProperties } from '../../constants/shared-constant';
import { ButtonInfo, Heading } from '../../../../../shared/src/lib/models/header-page';
import { AuthService } from '@phase-ii/auth';
import { PaginationService } from '../../../../../shared/src/lib/services/pagination.service';
import { DialogService } from '@phase-ii/common';
import { ExportComponent } from '../export/export.component';
import { ExportObject } from '../../models/export-details';
import { dateObject } from '../../models/common-admin-model';
import * as moment from 'moment';

/**
* Component which is used to view/export customer report.
*/
@Component({
  selector: 'phase-ii-customer-report',
  templateUrl: './customer-report.component.html',
  styleUrls: ['./customer-report.component.scss']
})
export class CustomerReportComponent implements OnInit, OnDestroy {
  /**
   * Variable which is used to store the end date of the report.
   * @type {Date}
   */
  endDate!: Date;
  /**
   * Variable which is used to store the start date of the report.
   * @type {Date}
   */
  startDate!: Date;
  /**
   * Variable which is used to define the pagesize
   * @type {number}
   */
  pageSize: number = new CommonAdminShardConstants().paginator.defaultPageSize;
  /**
   * Variable which is used to store the text to be searched.
   * @type {string}
   */
  searchText!: string;
  /**
   * Variable which is used to store the selected location.
   * @type {any}
   */
  locationId: any;
  /**
   * Variable which is used to store the sort object, which has the field and direction to be sorted.
   * @type {Object}
   */
  sort!: Object;
  /**
 * Variable which is used to subscribe and unsubscribe.
 * @type{Subscritpion}
 */
  subscriptionObj: Subscription = new Subscription();
  /**
   * Variable which is used to store the customer sales response.
   * @type {Object}
   */
  data!: Object;
  /**
* paginator data for export
*/
  paginatorValue = { limit: null, offset: null };

  /**
  * object of datas to be displayed
  */
  pageDetails: {
    heading: any,
    buttonInfo: ButtonInfo[],
    listProperty: ListProperties,
    columnRef: ColumnsRef[],
    isLoader: boolean,
    listDataSource: any,
    showFilterAndSort: boolean,
    actions: any,
    filterConstrain: any,
    checker: any,
    errorHandler: boolean,
    currencySymbol: any
    paginator: any,
    tab: any,
    storeList: any,
    storeId: number,
    roleId: number
  } = {
      heading: null,
      buttonInfo: null,
      listProperty: null,
      columnRef: null,
      isLoader: null,
      listDataSource: null,
      showFilterAndSort: false,
      actions: [],
      filterConstrain: null,
      checker: null,
      errorHandler: false,
      currencySymbol: null,
      paginator: null,
      tab: false,
      storeList: null,
      storeId: null,
      roleId: null
    };
  /**
  * Variable which contains store id value.
  */
  storeId: any;
  /**
 * Variable which is used to store current user details.
 */
  currentUser: any;
  /**
 * Variable which contains list of available stores.
 */
  storeList: any[] = [];
  /**
 * Variable which contains boolean value based on data retrieval from response.
 */
  isRetrieve: boolean = false;
  /**
 * Variable which is used to store page changing value.
 */
  isPageChange!: boolean;
  /**
 * Variable which is used to store limit value of paginator for export.
 */
  paginatorLimit: any;
  /**
 * Variable which contains offset value of paginator for export.
 */
  paginatorOffset!: number;
  /**
 * Variable which contains current role details.
 */
  currentRole!: string;
  /**commonConstant used to acccess the common constant values. */
  commonConstant = new CommonAdminShardConstants();
  /**
 * Variable which is used to store the created date of the store.
 * @type {Date}
 */
  createdDate!: Date;

  /**
* component constructor which is used to inject the required services.
* @param reportService  To access methods inside ReportService.
* @param authService  To access methods inside AuthService.
* @param displayDialog  To access methods inside MatDialog.
* @param paginatorService  To access methods inside PaginationService.
* @param commonService  To access methods inside CommonService.
* @param datePipe  To access methods inside DatePipe.
* @param router  To access methods inside Router.
*/
  constructor(private reportService: ReportsService,
    private paginatorService: PaginationService, private authService: AuthService, private displayDialog: MatDialog,
    private commonService: CommonService, private dialogService: DialogService,
    private datePipe: DatePipe) { }
  /**
   * Angular life cycle hook that initiates the component
   */
  ngOnInit(): void {

    // this.endDate = new Date();
    // this.startDate = new Date(Number(this.endDate));
    this.locationId = 'All';
    this.subscriptionObj.add(this.authService.user.pipe(filter((res) => {
      if (res) {
        return res;
      }
    }), mergeMap((user: any) => {
      if (user) {
        this.currentUser = user;
        this.currentRole = this.currentUser.roleCode ? this.currentUser.roleCode : this.currentUser.role.code;
        this.pageDetails.roleId = this.currentUser?.roleId;
        this.pageDetails.currencySymbol = user.store && user.store.currencyData && user.store.currencyData.currencySymbol;
      }
      if (this.currentRole && this.currentRole === 'SUPERADMIN') {
        return this.commonService.getAllStoresName();
      } else {
        this.pageDetails.storeId = this.currentUser?.storeId ? this.currentUser.storeId : null;
        return of({});
      }
    }), mergeMap((store: any) => {
      if (store && store['stores']) {
        this.storeList.push({ name: "All", id: "All" });
        store['stores'].forEach((element) => {
          this.storeList.push({
            id: element?.id,
            name: element?.name
          });
        });
        this.storeId = this.storeList[0]?.id;
        this.pageDetails.storeList = this.storeList;
      }
      return this.reportService.getStoreData(this.pageDetails.storeId);;
    }), mergeMap((data: any) => {
      if (data) {
        this.createdDate = data.settings && data.settings.createdAt;
      }
      return this.authService.dashboardFilter;
    })).subscribe({
      next: (value: dateObject) => {
        if (value) {
          if (value.filterText == 'Lifetime') {
            this.startDate = this.createdDate;
          }
          else {
            this.startDate = value.fromDate ? value.fromDate : new Date();
            this.startDate.setHours(0, 0, 0, 0);
          }
        }
        else {
          this.startDate = new Date();
          this.startDate.setHours(0, 0, 0, 0);
        }
        this.endDate = new Date();
        this.endDate.setHours(23, 59, 59, 999);
        this.storeId = this.currentUser?.storeId ? this.currentUser.storeId : null;
        this.getCustomerDetails(0, 2 * this.pageSize, true);
      },
      error: (err) => {
        this.pageDetails.isLoader = false;
      }
    }));
  }
  /**
   * Function which is used to get the customer report details for table.
   * @param offset To get the offset/starting value.
   * @param limit defines the limit/count of data to be get.
   */
  getCustomerDetails(offset: number, limit: number, isNew: boolean): void {
    this.paginatorOffset = offset;
    this.paginatorLimit = this.pageSize;
    const start_date = this.startDate ? this.startDate : null;
    const to_date = this.endDate ? this.endDate : null;
    let data = {
      offset: offset,
      limit: limit ? limit : this.pageSize,
      filterText: this.searchText ? this.searchText : '',
      location: this.locationId ? this.locationId : null,
      store: this.currentUser?.storeId ? this.currentUser.storeId : this.storeId ? this.storeId : null,
      dateFrom: moment.utc(this.datePipe.transform(start_date, 'yyyy-MM-dd')).utcOffset('+0:00').format('YYYY-MM-DD 00:00:00.000 +00:00'),
      dateTo: moment.utc(this.datePipe.transform(to_date, 'yyyy-MM-dd')).utcOffset('+0:00').format('YYYY-MM-DD 23:59:59.000 +00:00'),
      sort: this.sort ? this.sort : null
    };
    if (this.storeId === 'All') {
      this.subscriptionObj.add(this.reportService.getAllCustomerReport(data).subscribe((res: any) => {
        if (res && res['customer'] && res['customer'].rows && res['customer'].customer) {
          this.isRetrieve = true;
          for (let i = 0; i < res['customer'].rows.length; i++) {
            for (let j = 0; j < res['customer'].customer.length; j++) {
              if (res['customer']['rows'][i]?.customer_id === res['customer'].customer[j]?.id) {
                res['customer']['rows'][i].first_name = res['customer'].customer[j]?.user?.first_name + ' ' + res['customer'].customer[j]?.user?.last_name;
                res['customer']['rows'][i].email = res['customer'].customer[j]?.user?.email;
              }
            }
          }
          res['customer']['rows'].forEach((item) => {
            item.revenue = (item.extra_amount ? Number(item.extra_amount) : 0) +
              (item.order_charge ? Number(item.order_charge) : 0) + (item.tax ? Number(item.tax) : 0) + (item.tip ? Number(item.tip) : 0) +
              (item.total_amount ? Number(item.total_amount) : 0) - (item.discount ? Number(item.discount) : 0);
          });
          this.pageDetails.listDataSource = res['customer'].rows ? res['customer'].rows : [];
          this.pageDetails.paginator = {
            new: isNew,
            rows: this.pageDetails.listDataSource,
            count: res['customer'].count?.length,
            searchText: this.searchText ? this.searchText : null
          }
          this.pageDetails.heading = {
            title: 'Customer Report',
            description: 'Generates report based on customer',
            isHeaderIcon: true,
            iconName: 'link',
            iconToolTip: 'Help',
            iconLink: 'https://help.getzenbasket.com/customer_report.html'
          };
          this.pageDetails.buttonInfo =
            [
              {
                name: 'Back', method: 'back()', class: 'secondary-button'
              },
              {
                name: 'Export Report', class: 'primary-button', method: 'exportexcel()'
              }
            ];
          this.pageDetails.listProperty = {
            globalSearch: false,
            columns: 2,
            dataOnly: true,
            suffixText: true,
            checkBox: false,
            isImageDisplay: false
          };
          this.pageDetails.checker = false;
          this.pageDetails.isLoader = false;
          this.pageDetails.columnRef = [
            { column: 'first_name', type: 'Text', suffixText: 'Customer' },
            { column: 'email', type: 'Text', suffixText: 'Email' },
            { column: 'count', type: 'Number', suffixText: 'No of Orders' },
            { column: 'total_amount', type: 'Amount', suffixText: 'Revenue', currency: this.pageDetails.currencySymbol }
          ];
          this.paginatorService.listPaginatorData.next(this.pageDetails?.paginator);
        }
      }, (err) => {
        this.pageDetails.isLoader = false;
      }));
    } else if (this.storeId !== null) {
      this.subscriptionObj.add(this.reportService.getCustomerReport(this.storeId, data).subscribe((res: Object) => {
        if (res && res['customer'] && res['customer'].rows && res['customer'].customer) {
          this.isRetrieve = true;
          for (let i = 0; i < res['customer'].rows.length; i++) {
            for (let j = 0; j < res['customer'].customer.length; j++) {
              if (res['customer']['rows'][i]?.customer_id === res['customer'].customer[j]?.id) {
                res['customer']['rows'][i].first_name = res['customer'].customer[j]?.user?.first_name + ' ' + res['customer'].customer[j]?.user?.last_name;
                res['customer']['rows'][i].email = res['customer'].customer[j]?.user?.email;
              }
            }

          }
          res['customer']['rows'].forEach((item) => {
            item.revenue = (item.extra_amount ? Number(item.extra_amount) : 0) +
              (item.order_charge ? Number(item.order_charge) : 0) + (item.tax ? Number(item.tax) : 0) + (item.tip ? Number(item.tip) : 0) +
              (item.total_amount ? Number(item.total_amount) : 0) - (item.discount ? Number(item.discount) : 0);
          });
          this.pageDetails.listDataSource = res['customer'].rows ? res['customer'].rows : [];
          this.pageDetails.paginator = {
            new: isNew,
            rows: this.pageDetails.listDataSource,
            count: res['customer'].count?.length,
            searchText: this.searchText ? this.searchText : null
          }
          this.pageDetails.heading = {
            title: 'Customer Report',
            description: 'Generates report based on customer',
            isHeaderIcon: true,
            iconName: 'link',
            iconToolTip: 'Help',
            iconLink: 'https://help.getzenbasket.com/customer_report.html'
          };
          this.pageDetails.buttonInfo =
            [
              {
                name: 'Back', method: 'back()', class: 'secondary-button'
              },
              {
                name: 'Export Report', class: 'primary-button', method: 'exportexcel()'
              }
            ];
          this.pageDetails.listProperty = {
            globalSearch: false,
            columns: 2,
            dataOnly: true,
            suffixText: true,
            checkBox: false,
            isImageDisplay: false
          };
          this.pageDetails.checker = false;
          this.pageDetails.isLoader = false;
          this.pageDetails.columnRef = [
            { column: 'first_name', type: 'Text', suffixText: 'Customer' },
            { column: 'email', type: 'Text', suffixText: 'Email' },
            { column: 'count', type: 'Number', suffixText: 'No of Orders' },
            { column: 'total_amount', type: 'Amount', suffixText: 'Revenue', currency: this.pageDetails.currencySymbol }
          ];
          this.paginatorService.listPaginatorData.next(this.pageDetails.paginator);
        }
      }, (err) => {
        this.pageDetails.isLoader = false;
      }));
    }
    else {
      this.dialogDisplay(this.commonConstant &&
        this.commonConstant.errorMessage
        && this.commonConstant.errorMessage.errorMessage,
        this.commonConstant.dialogType.failure);
    }

  }
  /**
   * Function which gets called during pagination events in common report.
   * @param event To get the event value.
   */
  onDataChange(event: any): void {
    this.getCustomerDetails(event?.offset, event?.limit, false);
  }
  /**
* Method which is used to get changed page data.
* @param event To get offset and limit.
*/
  changedData(event: any): void {
    this.isPageChange = false;
    if (event) {
      this.paginatorValue.limit = event?.limit ? event.limit : null;
      this.paginatorValue.offset = event?.offset ? event.offset : 0;
    }
    this.getCustomerDetails(event?.offset, event?.limit, false);
  }
  /**
   * Method which is used to get paginator data.
   * @param event To get offset and limit.
   */
  paginatorData(event: any): void {
    this.isPageChange = true;
    this.paginatorLimit = event?.pageSize;
    this.paginatorOffset = event?.pageIndex * event?.pageSize;
    this.pageSize = event?.pageSize;
  }
  /**
   * Function which gets called during location, searchText, from date, to date and sort change events in common report.
   * @param event To get the event value.
   */
  filterAction(event: any): void {
    this.locationId = event?.location_id ? event.location_id : null;
    this.storeId = event?.store_id ? event.store_id : null;
    this.startDate = event.from_date ? event.from_date : this.startDate ? this.startDate : null;
    this.endDate = event.to_date ? event.to_date : this.endDate ? this.endDate : null;
    this.searchText = event?.searchText ? event.searchText : null;
    this.paginatorValue.limit = event?.limit ? event.limit : null;
    this.paginatorValue.offset = event?.offset ? event.offset : 0;
    this.sort = event?.sort ? event.sort : null;
    if (event?.exportexcel) {
      this.exportCustomer(event?.offset, event?.limit);
    } else {
      this.getCustomerDetails(event?.offset, event?.limit, true);
    }
  }
  /**
   * Function which gets called during export is true in common report.
   */
  exportCustomer(offset: number, limit: number): void {
    if (this.pageDetails && this.pageDetails.listDataSource && this.pageDetails.listDataSource.length > 0) {
      let filterConstrain = {
        from_date: this.startDate ? this.datePipe.transform(this.startDate, 'yyyy-MM-dd') : null,
        to_date: this.endDate ? this.datePipe.transform(this.endDate, 'yyyy-MM-dd') : null,
        locationId: this.locationId ? this.locationId : null,
        storeId: this.currentUser?.storeId ? this.currentUser.storeId : this.storeId ? this.storeId : null
      }
      const data: ExportObject = {
        title: 'Export ',
        exportApiUrl: 'stores/' + this.storeId + '/export/report/customer',
        limit: this.paginatorLimit ? this.paginatorLimit : this.paginatorValue.limit ? this.paginatorValue.limit : this.pageSize,
        offset: this.paginatorOffset ? this.paginatorOffset : this.paginatorValue.offset ? this.paginatorValue.offset : 0,
        type: false,
        fileName: 'customer',
        expansion: true,
        filterData: filterConstrain ? filterConstrain : null,
        selectedData: []
      };
      const dialogRefData = this.displayDialog.open(ExportComponent, { disableClose: true });
      if (dialogRefData && dialogRefData.componentInstance) {
        dialogRefData.componentInstance.contentData = data;
      }
    } else {
      this.dialogDisplay(this.commonConstant &&
        this.commonConstant.dialogMessages
        && this.commonConstant.dialogMessages.noDataExport,
        this.commonConstant.dialogType.alert);
    }
  }
  /**
* Method used to display the dialog
* @param message has the message
* @param type has the dialog type.
*/
  dialogDisplay(message: string, type: any): any {
    return this.dialogService.dialogMethod(message, type, true);
  }
  /**
   * Angular life cycle hook which is used to unsubscribe the subscriptions.
   */
  ngOnDestroy(): void {
    this.subscriptionObj.unsubscribe();
  }
}
