import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService, CustomAsyncValidatorService, CustomValidatorService } from '@phase-ii/auth';
import { DialogService, dataConfig } from '@phase-ii/common';
import { ButtonInfo, CommonDataService, CommonService, Heading } from '@phase-ii/shared';
import { addEditUserConstant } from '../../constants/users-constant';
import { UsersService } from '../../services/users.service';
import { Subscription, of } from 'rxjs';
import { SettingsService } from '../../../settings/services/settings.service';
import { mergeMap } from 'rxjs/operators';

@Component({
  selector: 'phase-ii-add-edit-user',
  templateUrl: './add-edit-user.component.html',
  styleUrls: ['./add-edit-user.component.scss'],
})
export class AddEditUserComponent implements OnInit, OnDestroy {
  /**
   * Form control to get the search value of country,state and country code
   */
  filterCountryState = new UntypedFormControl(null);
  /**
  * subscriptionObject used to store the subscribed values.
  * @type {Subscription}
  */
  subscriptionObject: Subscription = new Subscription();

  /**
  * addEditUserConstant used to acccess the addedituser Constants values.
  */
  addEditUserConstant: addEditUserConstant

  /**
  * Array object of datas to be displayed in option
  */
  discountTypes = [{ name: 'Percentage' }, { name: 'Fixed' },{ name: 'None' }];

  /**
  * Array object of datas to be displayed in option
  */
  Types = [{ name: 'Percentage' }, { name: 'Fixed' }];

  /**
  * Declaring form group
  * @type {FormGroup}
  */
  userForm: UntypedFormGroup;

  /**
 * Declaring form group
 * @type {FormGroup}
 */
  commissionDetailsForm: UntypedFormGroup;
  /**
   * Variable to save the currencies from the back end
   * @type {any}
   */
  currencies: any;
  /**
  * object of datas to be displayed
  */
  pageDetails: {
    userId: any,
    heading: Heading,
    buttonInfo: ButtonInfo[],
    isLoader: boolean,
    states: any,
    stateArray: any,
    countryArray: any,
    countries: any,
    isPasswordVisible: boolean,
    discount: boolean,
    settings: any,
    value: any,
    NoneField:boolean;
  } = {
      userId: null,
      heading: null,
      buttonInfo: null,
      isLoader: false,
      states: null,
      stateArray: [],
      countryArray: [],
      countries: [],
      isPasswordVisible: false,
      discount: false,
      settings: null,
      value: null,
      NoneField:null,
    };

  /**
  * Variable which is used access the patternsValidators from the common service.
  */
  pattern = dataConfig.patternValidators;

  constructor(
    private authService: AuthService,
    private router: Router,
    private usersService: UsersService,
    private commonDataService: CommonDataService,
    private commonService: CommonService,
    private dialogService: DialogService,
    private route: ActivatedRoute,
    private settingsService: SettingsService,
  ) {
    this.addEditUserConstant = new addEditUserConstant();
  }

  /*
 * Component OnInit life cycle hook 
 */
  ngOnInit(): void {
    this.pageDetails.isLoader = true;
    this.subscriptionObject.add(this.commonService.getCountries(true).pipe(mergeMap((res: any) => {
      this.pageDetails.countries = res && res.country;
      this.pageDetails.countryArray = res && res.country;
      return this.settingsService.getSettings();
    }),
      mergeMap((res: any) => {
        if (res && res.result) {
          this.pageDetails.discount = res.result.isDiscount;
          this.pageDetails.settings = res.result;
        }
        return this.route.params;
      }), mergeMap((data) => {
        if (data && data.id) {
          this.pageDetails.userId = this.commonDataService.getParam(data.id);
          return this.usersService.getOneUserDetails(this.pageDetails.userId);
        }
        return of(false);
      })).subscribe((res: any) => {
        if (res) {
          if (res && res.affiliateUser) {
            this.pageDetails.userId = res.affiliateUser.id;
            this.formInitialization(res.affiliateUser, '');
          }
        }
        else {
          this.formInitialization('', this.pageDetails.settings);
        }
      }, (err) => {
        this.throwError();
      }));
  }

  /**
  * Method which is used to throw error.
  */
  throwError() {
    this.pageDetails.isLoader = false;
    this.dialogService.dialogMethod(this.addEditUserConstant.dialogMessages.failedToLoad,
      this.addEditUserConstant.dialogType.failure);
  }

  /**
    * Method which is used to initialize form 
    * @param value To get the form values
    */
  formInitialization(value?, settings?) {
    this.pageDetails.buttonInfo = value ? [{ name: 'Cancel', class: 'secondary-button', method: 'onCancel', disabled: false },
    { name: 'Save', class: 'primary-button', method: 'onSave', disabled: false },
    ] :
      [{ name: 'Cancel', class: 'secondary-button', method: 'onCancel', disabled: false },
      { name: 'Save', class: 'primary-button', method: 'onSave', disabled: false },
      ]
    this.pageDetails.heading = {
      title: value && value.id ? this.addEditUserConstant.EditUserHeading.EditUserTitle : this.addEditUserConstant.AddUserHeading.AddUserTitle,
      description: value && value.id ? this.addEditUserConstant.EditUserHeading.EditUserDescryption : this.addEditUserConstant.AddUserHeading.AddUserDescryption,
    }
    this.userForm = new UntypedFormGroup({
      username: new UntypedFormControl(value && value.name ? value.name : null, [Validators.required, Validators.maxLength(25), Validators.pattern(this.pattern.acceptOnlyAlphabets)]),
      email: new UntypedFormControl(value && value.email ? value.email : null, { validators: Validators.pattern(this.pattern.emailValidationPattern), }),
      addressLine1: new UntypedFormControl(value && value.address && value.address.address1 ? value.address.address1 : null, [Validators.required, Validators.maxLength(100), Validators.pattern(this.pattern.noSpaceValidationPattern)]),
      addressLine2: new UntypedFormControl(value && value.address && value.address.address2 ? value.address.address2 : null, [Validators.maxLength(100), Validators.pattern(this.pattern.addressValidationPattern)]),
      city: new UntypedFormControl(value && value.address && value.address.city ? value.address.city : null, [Validators.required, Validators.maxLength(30), Validators.pattern(this.pattern.acceptOnlyAlphabets)]),
      zipCode: new UntypedFormControl(value && value.address && value.address.pinCode ? value.address.pinCode : null, [Validators.required, Validators.maxLength(15)]),
      contactNumber: new UntypedFormControl(value && value.mobile ? value.mobile : null, {
        validators: [Validators.required, Validators.maxLength(20), Validators.pattern(this.pattern.acceptOnlyNumbers)],
        asyncValidators: [CustomAsyncValidatorService.checkPhoneAlreadyExists(this.authService, value && value.id ? value.id : null),]
      }),
      state: new UntypedFormControl(null, [Validators.required]),
      country: new UntypedFormControl(value && value.address && value.address.country ? this.countryAndStateValueFetch(Number(value.address.countryId), 'countries') : null, [Validators.required]),
    },
      CustomValidatorService.formValidation.bind(this));

    if (this.pageDetails && !this.pageDetails.userId) {
      this.commissionDetails();
      this.userForm.get('email').setAsyncValidators(CustomAsyncValidatorService.asyncNoEmailValidationForAdminFromLambda(
        this.usersService));
      this.userForm.addControl('password', new UntypedFormControl(null, [Validators.required, Validators.pattern(this.pattern.passwordValidationPattern)]));
    }
    if (this.pageDetails && this.pageDetails.userId) {
      this.userForm.addControl('Active', new UntypedFormControl(value && value.isActive));
    }
    if (value) {
      this.commissionDetails(value);
      this.statepatch(value.address);
    }
    if (settings) {
      this.pageDetails.value = settings;
      this.commissionDetails('', this.pageDetails.value);
    }
    this.pageDetails.isLoader = false;
  }

  /**
  * Method commissionDetails used to set commissionDetailsForm
  * @param event is the event.
  */
  commissionDetails(value?: any, settings?) {
    this.pageDetails.isLoader = true;
    if (this.pageDetails && !this.pageDetails.discount) {
      this.commissionDetailsForm = new UntypedFormGroup({
        commissionType: new UntypedFormControl(this.Types[0].name),
        commissionValue: new UntypedFormControl(null, [Validators.required, Validators.max(100), Validators.min(1)]),
        thresholdValue: new UntypedFormControl(null, [Validators.required, Validators.pattern(this.pattern.acceptOnlyNumbers), Validators.max(10000), Validators.min(100)]),
      })
    }
    else {
      this.commissionDetailsForm = new UntypedFormGroup({
        commissionType: new UntypedFormControl(this.Types[0].name),
        commissionValue: new UntypedFormControl(null, [Validators.required, Validators.max(100), Validators.min(1)]),
        thresholdValue: new UntypedFormControl(null, [Validators.required, Validators.pattern(this.pattern.acceptOnlyNumbers), Validators.max(10000), Validators.min(100)]),
        discountType: new UntypedFormControl(this.discountTypes[0].name),
        discountValue: new UntypedFormControl(null, [Validators.max(100), Validators.min(1)]),
      })
    }
    if (value) {
      if (this.pageDetails && !this.pageDetails.discount) {
        this.commissionDetailsForm.patchValue({
          commissionType: value && value.affiliateSettings[0] && value.affiliateSettings[0].commissionType ? value.affiliateSettings[0].commissionType : this.discountTypes[0].name,
          commissionValue: value && value.affiliateSettings[0] && value.affiliateSettings[0].commissionValue ? Number(value.affiliateSettings[0].commissionValue) : null,
          thresholdValue: value && value.affiliateSettings[0] && value.affiliateSettings[0].thresholdValue ? Number(value.affiliateSettings[0].thresholdValue) : null,
        })
      }
      else {
        this.commissionDetailsForm.patchValue({
          commissionType: value && value.affiliateSettings[0] && value.affiliateSettings[0].commissionType ? value.affiliateSettings[0].commissionType : this.Types[0].name,
          commissionValue: value && value.affiliateSettings[0] && value.affiliateSettings[0].commissionValue ? Number(value.affiliateSettings[0].commissionValue) : null,
          thresholdValue: value && value.affiliateSettings[0] && value.affiliateSettings[0].thresholdValue ? Number(value.affiliateSettings[0].thresholdValue) : null,
          discountType: value && value.affiliateSettings[0] && value.affiliateSettings[0].discountType===null ? this.discountTypes[2].name : value.affiliateSettings[0].discountType,
          discountValue: value && value.affiliateSettings[0] && value.affiliateSettings[0].discountValue ? Number(value.affiliateSettings[0].discountValue) : null,
        });
      }
    }

    if (settings) {
      if (this.pageDetails && !this.pageDetails.discount) {
        this.commissionDetailsForm.patchValue({
          commissionType: settings.commissionType ? settings.commissionType : this.discountTypes[0].name,
          commissionValue: settings.commissionValue ? Number(settings.commissionValue) : null,
          thresholdValue: settings.thresholdValue ? Number(settings.thresholdValue) : null,
        })
      }
      else {
        this.commissionDetailsForm.patchValue({
          commissionType: settings.commissionType ? settings.commissionType : this.discountTypes[0].name,
          commissionValue: settings.commissionValue ? Number(settings.commissionValue) : null,
          thresholdValue: settings.thresholdValue ? Number(settings.thresholdValue) : null,
          discountType: settings.discountType ? settings.discountType : null,
          discountValue: settings.discountValue ? Number(settings.discountValue) : null,
        });
      }
    }
    this.CommissionDiscountTypeValidation();
  }

  /**
  * Method which is used to patch state  
  */
  statepatch(data) {
    if (data && data.stateId) {
      this.getStates(data.countryId, data.stateId, data);
    }
  }
  /**
  * Method changeValueDiscount used to set validation 
  * @param event is the event.
  */
  changeValueDiscount() {
    if (this.pageDetails.discount) {
      this.commissionDetailsForm.get("discountValue").setValue(null);
      this.CommissionDiscountTypeValidation();
    }
  }
  /**
  * Method changeValueCommission used to set validation 
  * @param event is the event.
  */
  changeValueCommission() {
    this.commissionDetailsForm.get("commissionValue").setValue(null);
    this.CommissionDiscountTypeValidation();
  }
  /**
  * Method discountTypeValidation used to set the validator for commissionValue.
  * @param event is the event.
  */
  CommissionDiscountTypeValidation() {
    if (this.commissionDetailsForm && this.commissionDetailsForm.get("commissionType")) {
      if (this.commissionDetailsForm.value && this.commissionDetailsForm.value.commissionType === 'Percentage') {
        this.commissionDetailsForm.get("commissionValue").setValidators([Validators.required, Validators.max(100), Validators.pattern(this.pattern.acceptOnlyNumbers), Validators.min(1)]);
        this.commissionDetailsForm.get("commissionValue").updateValueAndValidity();
      }
      else {
        this.commissionDetailsForm.get("commissionValue").setValidators([Validators.required, Validators.max(10000), Validators.pattern(this.pattern.amountwithTwoDecimalPoint), Validators.min(1)]);
        this.commissionDetailsForm.get("commissionValue").updateValueAndValidity();
      }
    }

    if (this.commissionDetailsForm && this.commissionDetailsForm.get("discountType") && this.pageDetails.discount) {
      if (this.commissionDetailsForm.value && this.commissionDetailsForm.value.discountType === 'Percentage') {
        this.pageDetails.NoneField=false;
        this.commissionDetailsForm.get("discountValue").setValidators([Validators.required, Validators.max(100), Validators.pattern(this.pattern.acceptOnlyNumbers), Validators.min(1)]);
        this.commissionDetailsForm.get("discountValue").updateValueAndValidity();
      }
      else if (this.commissionDetailsForm.value && this.commissionDetailsForm.value.discountType === 'Fixed') {
        this.pageDetails.NoneField=false;
        this.commissionDetailsForm.get("discountValue").setValidators([Validators.required, Validators.max(10000), Validators.pattern(this.pattern.amountwithTwoDecimalPoint), Validators.min(1)]);
        this.commissionDetailsForm.get("discountValue").updateValueAndValidity();

      }
      else {
        this.commissionDetailsForm.get("discountValue").setValidators(null);
        this.commissionDetailsForm.get("discountValue").updateValueAndValidity();
        this.pageDetails.NoneField= this.commissionDetailsForm.get("discountType").value === 'None';
      }
    }
  }

  /**
  * Function used to get the states of particular country
  * @param event To get country id from selected value
  * @param countryId For get countruy id
  */
  getStates(countryId?: number, formValue?: any, value?: any) {
    if (countryId) {
      this.subscriptionObject.add(this.commonService.getStates(countryId).subscribe((response: any) => {
        if (response) {
          this.pageDetails.states = response.states;
          this.pageDetails.stateArray = response.states;
          (this.userForm.get('state').setValue(formValue ? this.countryAndStateValueFetch(Number(formValue), 'stateArray') : null));
        }
      }, (err) => {
        this.pageDetails.isLoader = false;
      }));
    }
  }

  /**
  * Method is used to fetch value to country and state filed
  * @param id To fetch the value in country and state
  * @param property To get property name dynamically
  * @returns 
  */
  countryAndStateValueFetch(id: any, property: string, index?: number) {
    if (id && property && this.pageDetails[property]) {
      return (this.pageDetails[property]).find(item => item && item.id === id);
    }
  }

  /**
  * Function used to filter the country,state and country code
  * @param formValue Typed values in dropdown search
  * @param property To access the array dynamically
  * @param searchProperty To get the property for search
  * @param index To get the state index
  */
  filterCountryAndState(formValue?: string, property?: string, searchProperty?: string) {
    if (searchProperty) {
      if (property === 'countryArray')
        this.pageDetails.countryArray = formValue ? this.pageDetails.countries && this.pageDetails.countries.filter(val => formValue && val && val[searchProperty] && (((val[searchProperty] + '').toLowerCase()).startsWith((formValue + '').toLowerCase()))) : this.pageDetails.countries;
      else (property === 'stateArray')
      {
        this.pageDetails.stateArray = formValue ? this.pageDetails.states && this.pageDetails.states.filter(val => formValue && val && val[searchProperty] && (((val[searchProperty] + '').toLowerCase()).startsWith((formValue + '').toLowerCase()))) : this.pageDetails.states;
      }
    }
  }

  /**
  * Method which is used to clear the dropdown search
  * @param index To get the index of state
  */
  clearSearch() {
    this.filterCountryState.reset();
    this.pageDetails.stateArray = this.pageDetails.states;
    this.pageDetails.countryArray = this.pageDetails.countries;
  }

  /**
  * Function used to save user details while save and update user
  */
  onSave() {
    this.pageDetails.isLoader = true;
    if (this.userForm && this.userForm.valid && this.commissionDetailsForm && this.commissionDetailsForm.valid) {
      this.checkFormDirtyAndSave();
    }
    else {
      this.pageDetails.isLoader = false;
      const dialogRef = this.dialogService.dialogMethod(this.addEditUserConstant.dialogMessages.mandatoryFieldFill, this.addEditUserConstant.dialogType.alert, true);
      if (dialogRef) {
        this.userForm.markAllAsTouched();
        this.commissionDetailsForm.markAllAsTouched();
      }
    }
  }

  /**
  * Method checkFormDirtyAndSave used to check for form dirty and rearrange the form data and save.
  */
  checkFormDirtyAndSave() {
    if (this.userForm.pristine && this.commissionDetailsForm.pristine) {
      this.pageDetails.isLoader = false;
      this.dialogService.dialogMethod(this.addEditUserConstant.dialogMessages.noChangeAlert,this.addEditUserConstant.dialogType.alert,true);
    }

    else {
      if (this.pageDetails.userId) {
        this.updateuserDetails();
      }
      else {
        this.createuserDetails();
      }
    }
  }

  /**
   * Method updateuserDetails used to update the store user data.
   */
  updateuserDetails() {
    let data;
    if (this.commissionDetailsForm.get('discountType')) {
      this.commissionDetailsForm.get('discountType').setValue(this.commissionDetailsForm.get('discountType').value === "None" ? null : this.commissionDetailsForm.get('discountType').value)
    }
    if (this.userForm && !this.userForm.pristine && this.commissionDetailsForm && !this.commissionDetailsForm.pristine) {
      data = {
        generalDetails: {
          mobile: this.userForm.value.contactNumber,
          name: this.userForm.value.username,
          address: { state: this.userForm.value.state.name, stateId: this.userForm.value.state.id, city: this.userForm.value.city, pinCode: this.userForm.value.zipCode, country: this.userForm.value.country.name, countryId: this.userForm.value.country.id, address1: this.userForm.value.addressLine1, address2: this.userForm.value.addressLine2 },
          isFromPlatForm: false,
          isFromSuperadmin: true,
          isActive: this.userForm.value.Active,
          countryIsoCode: this.userForm.value.country && this.userForm.value.country.countryIsoCode,
          currencyId: this.userForm.value.country && this.userForm.value.country.currency && this.userForm.value.country.currency.id,
        },
        otherDetails: { ...this.commissionDetailsForm.value, ...{ isDiscount: this.pageDetails.discount ? (this.commissionDetailsForm.get('discountType').value === "Percentage" || this.commissionDetailsForm.get('discountType').value === "Fixed" ? true : false) : false } },
      }
    }
    else if (this.userForm && !this.userForm.pristine) {
      data = {
        generalDetails: {
          mobile: this.userForm.value.contactNumber,
          name: this.userForm.value.username,
          address: { state: this.userForm.value.state.name, stateId: this.userForm.value.state.id, city: this.userForm.value.city, pinCode: this.userForm.value.zipCode, country: this.userForm.value.country.name, countryId: this.userForm.value.country.id, address1: this.userForm.value.addressLine1, address2: this.userForm.value.addressLine2 },
          isFromPlatForm: false,
          isFromSuperadmin: true,
          isActive: this.userForm.value.Active,
          countryIsoCode: this.userForm.value.country && this.userForm.value.country.countryIsoCode,
          currencyId: this.userForm.value.country && this.userForm.value.country.currency && this.userForm.value.country.currency.id,
        },
      }
    }
    else if (this.commissionDetailsForm && !this.commissionDetailsForm.pristine) {
      data = {
        otherDetails: { ...this.commissionDetailsForm.value, ...{ isDiscount: this.pageDetails.discount ? (this.commissionDetailsForm.get('discountType').value === "Percentage" || this.commissionDetailsForm.get('discountType').value === "Fixed" ? true : false) : false } },
      }
    }
    this.subscriptionObject.add(this.usersService.updateUserDetails(data, this.pageDetails.userId).subscribe((res: any) => {
      if (res) {
        this.afterUserInfoResponse(res);
      }
    }, err => {
      this.pageDetails.isLoader = false;
      this.dialogService.dialogMethod(this.addEditUserConstant.dialogMessages.failedToSave, this.addEditUserConstant.dialogType.failure, true);
    }))
  }


  /**
  * Method createuserDetails used to create the store user data.
  */
  createuserDetails() {
    if (this.commissionDetailsForm.get('discountType')) {
      this.commissionDetailsForm.get('discountType').setValue(this.commissionDetailsForm.get('discountType').value === "None" ? null : this.commissionDetailsForm.get('discountType').value)
    }
    const data = {
      generalDetails: {
        mobile: this.userForm.value.contactNumber,
        name: this.userForm.value.username,
        address: { state: this.userForm.value.state.name, stateId: this.userForm.value.state.id, city: this.userForm.value.city, pinCode: this.userForm.value.zipCode, country: this.userForm.value.country.name, countryId: this.userForm.value.country.id, address1: this.userForm.value.addressLine1, address2: this.userForm.value.addressLine2 },
        isFromPlatForm: false,
        isFromSuperadmin: true,
        isActive: this.userForm.value.Active,
        countryIsoCode: this.userForm.value.country && this.userForm.value.country.countryIsoCode,
        currencyId: this.userForm.value.country && this.userForm.value.country.currency && this.userForm.value.country.currency.id,
      },
      otherDetails: { ...this.commissionDetailsForm.value, ...{ isDiscount: this.pageDetails.discount ? (this.commissionDetailsForm.get('discountType').value === "Percentage" || this.commissionDetailsForm.get('discountType').value === "Fixed" ? true : false) : false } },
      registration: {
        email: this.userForm.value.email,
        password: this.userForm.value.password
      }
    }
    this.subscriptionObject.add(this.usersService.createUser(data).subscribe((res: any) => {
      if (res) {
        this.afterUserInfoResponse(res);
      }
    }, err => {
      this.pageDetails.isLoader = false;
      this.dialogService.dialogMethod(this.addEditUserConstant.dialogMessages.failedToSave, this.addEditUserConstant.dialogType.failure, true);
    }))
  }

  /**
  * Method afterUserInfoResponse used to creste dialogMethod.
  */
  afterUserInfoResponse(res: any): void {
    this.dialogService.dialogMethod(res.message, this.addEditUserConstant.dialogType.success, true);
    this.userForm.markAsPristine();
    this.commissionDetailsForm.markAsPristine();
    this.pageDetails.isLoader = false;
    this.onNavigation();
  }

  /**
    * Function which is used to navigate back
    */
  onNavigation(): void {
    this.router.navigate(['app/userlist']);
  }

  /**
  * Method which is used for trigger the function of header buttons
  * @param event To get the function name 
  */
  buttonAction(event: any) {
    this[event]();
  }

  /**
   * Function which is used to navigate back
   */
  onCancel() {
    if (this.userForm && this.userForm.dirty || this.commissionDetailsForm && this.commissionDetailsForm.dirty) {
      const checkDialog = this.dialogService.dialogMethod(this.addEditUserConstant.dialogMessages.unSavedChanges,
        this.addEditUserConstant.dialogType.confirmation, true);
      this.subscriptionObject.add(checkDialog.afterClosed().subscribe(result => {
        if (result) {
          this.userForm.markAsPristine();
          this.commissionDetailsForm.markAsPristine();
          this.router.navigate(['app/userlist']);
        }
        else {
          this.userForm.markAllAsTouched();
          this.commissionDetailsForm.markAllAsTouched();
        }
      }));
    }
    else {
      this.router.navigate(['app/userlist']);
    }
  }

  /**
   * Method which is used to alert the user when he navigates to the page
   * without saving the current page data's.
   */
  canDeactivate() {
    return (this.userForm && this.userForm.pristine) && (this.commissionDetailsForm && this.commissionDetailsForm.pristine);
  }
  /**
  * A lifecycle hook to unsubscribe all details.
  */
  ngOnDestroy() {
    if (this.subscriptionObject) {
      this.subscriptionObject.unsubscribe();
    }
  }

}
