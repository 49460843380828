/**
 * Component which is used to display the sold count notification in storefront.
 * AUTHOR: Jestus A - CEN327.
 */
import { Component, HostListener, HostBinding, Input } from '@angular/core';
import { Subscription } from 'rxjs';
import { SharedService } from '@phase-ii/shared-theme';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { soldCountData, soldCountNotificationData, soldCountNotificationDetails } from '../../models/live-notification.model';
import { LoadTranslationService } from '@phase-ii/common';

@Component({
  selector: 'phase-ii-display-sold-count-notification',
  templateUrl: './display-sold-count-notification.component.html',
  styleUrls: ['./display-sold-count-notification.component.scss'],
})
export class DisplaySoldCountNotificationComponent {
  /**
   * Variable which is used to store the particular store's id
   * @type{number}
   */
  storeId: number;
  /**
   * This variable is used to store the enabled status of this feature.
   *  @type {Boolean}
   */
  isEnabled: Boolean;
  /**
   * This variable is used to store the enabled status of the close icon.
   *  @type {Boolean}
   */
  isCloseNotification: Boolean = false;
  /**
   * This variable is used to handle the http call and make it to unsubscribe.
   *  @type {Subscription}
   */
  subscriptionObj: Subscription = new Subscription();
  /**
   * This variable is used to store the delay between two notifications in milliseconds.
   *  @type {NodeJS.Timeout}
   */
  nextNotificationTimeOut: NodeJS.Timeout;
  /**
   * This variable is used to store the timer details of a timer which is used for notification slideout.
   *  @type {NodeJS.Timeout}
   */
  slideOutTimeOut: NodeJS.Timeout;
  /**
   * This variable is used to store the timer details of a timer which is used to call show notification function after the notification is closed by user.
   *  @type {NodeJS.Timeout}
   */
  afterCloseTimeOut: NodeJS.Timeout;
  /**
   * This variable is used to store the timer details of a timer which is used to call the showNotification function for the very first time.
   *  @type {NodeJS.Timeout}
   */
  initialTimeOut: NodeJS.Timeout;
  /**
   * This counter is used to store the maximum number of notifications can be displayed in a page and it is decremented every time after a notification is shown.
   *  @type {number}
   */
  counter: number;
  /**
   * This variable is used to store the route of the current page in storefront.
   *  @type {string}
   */
  currentRoute: string;
  /**
   * This variable is used to store the list of pages only in which notifications can be shown.
   *  @type {Array<string>}
   */
  specificPages: Array<string>;
  /**
   * This variable is used to store wheather the notification can be shown in this page or not as a boolean.
   *  @type {boolean}
   */
  allowNotification: boolean = false;
  /**
   * This variable is used to store the maximum number of notifications that can be shown in a page.
   *  @type {number}
   */
  maximumNotification: number;
  /**
   * This variable is used to store the initial delay of the notification in a page in milliseconds.
   *  @type {number}
   */
  initialDelay: number;
  /**
   * This variable is used to store the delay between two notifications in milliseconds.
   *  @type {number}
   */
  delayNotification: number;
  /**
   * This variable is used to store the display time of a notification in milliseconds.
   *  @type {number}
   */
  displayTime: number;
  /**
   * This variable is used to store the look back time.
   *  @type {number}
   */
  lookBackTime: number;
  /**
   * This variable is used to store the time format of the look back time.
   *  @type {string}
   */
  timeFormat: string;
  /**
   * This variable is used to store the notification message in which the variables are replaced by values.
   *  @type {SafeHtml | string | any}
   */
  notificationMessage: SafeHtml | string | any;
  /**
   * This variable is used to store wheather the sold count of a store shown in notification is live data or manual data.
   *  @type {boolean}
   */
  isAutomatic: boolean;
  /**
   * This variable is used to store the sold count of a store.
   *  @type {number}
   */
  noOfOrders: number;
  /**
   * This variable is used to store the class which is used to make the notification slide in based on the animation details given by store admin.
   *  @type {string}
   */
  slideInClass: string;
  /**
   * This variable is used to store the class which is used to make the notification slide out based on the animation details given by store admin.
   *  @type {string}
   */
  slideOutClass: string;
  /**
   * This variable is used to store wheather the size of the screen is desktop at the moment.
   *  @type {boolean}
   */
  isDesktop: boolean;
  /**
   * This variable is used to store wheather the size of the screen is mobile at the moment.
   *  @type {boolean}
   */
  isMobile: boolean;
  /**
   * This variable is used to store css data such as opacity, left, bottom etc.
   *  @type {Object}
   */
  cssData: {
    isDesktopView: boolean,
    isMobileView: boolean,
    desktopView: {
      desktopAnimation: string,
      desktopDisplay: string
    },
    mobileView: {
      mobileAnimation: string,
      mobileDisplay: string
    }
  };
  /**
   * This variable is used to store class of the notification based on the screen size.
   *  @type {string}
   */
  notifyDivId: string;
  /**
   * This variable is used to store class of the close icon based on the screen size.
   *  @type {string}
   */
  closeIconClass: string;
  /**
   * This variable is used to store the notification message which is used to reset the message in notificationMessage variable.
   *  @type {string}
   */
  constNotificationMessage: string;
  /**
   * This variable is used to store wheather the notification service is suspended for an hour or not.
   *  @type {boolean}
   */
  isNotificationSuspended: boolean;
  /**
   * This variable is used to store getSoldCount invterval details
   * @type {NodeJS.Timeout}
   */
  soldCountInterval: NodeJS.Timeout;
  /**
   * This variable is used to store the sold count of the store
   */
  count: number = 0;
  /**
   * This variable is used to store the interval seconds of sold count interval.
   */
  intervalTime: number;
  /**
   * This variable is used to store language code
   */
  languageCode: string;
  /**
   * This variable is used to store details of other live notifications.
   * * @type {object}
   */
  @Input() liveNotification: {
    liveSale?: boolean,
    onlineVisitors: boolean,
    onlineVisitorsViewMode: {
      isMobile: boolean,
      isDesktop: boolean
    },
    soldCount?: boolean
  };
  /**
   * This css variable is used to store the left pixels based on the screen size and animation type.
   *  @type {string}
   */
  @HostBinding('style.--dscLeft') dscLeft: string;
  /**
   * This css variable is used to store the bottom pixels based on the screen size and animation type and enabled status of online visitors notification.
   *  @type {string}
   */
  @HostBinding('style.--dscBottom') dscBottom: string;
  /**
   * This css variable is used to store the opacity based on the screen size and animation type.
   *  @type {number}
   */
  @HostBinding('style.--dscOpacity') dscOpacity: number;
  /**
   * This css variable is used to store the visibility based on the screen size and animation type.
   *  @type {string}
   */
  @HostBinding('style.--dscVisibility') dscVisibility: string = 'visible';
  /**
   * This css variable is used to store the bottom pixels of slide from bottom animation based on the screen size and enabled status of online visitors notification.
   */
  @HostBinding('style.--animationBottom') animationBottom: string;
  /**
   * This css variable is used to store the left pixels of slide from left animation based on the screen size and enabled status of online visitors notification.
   */
  @HostBinding('style.--animationLeft') animationLeft: string;
  /**
   * Component constructor to inject the required services.
   * @param sharedService To access methods from shared service.
   * @param router To navigate to routes.
   * @param sanitizer To access methods inside DomSanitizer.
   * @param translationService To access methods from translation service
   */
  constructor(
    private sharedService: SharedService,
    private router: Router,
    private sanitizer: DomSanitizer,
    private translationService: LoadTranslationService,
  ) {
  }
  /**
   * Angular life cycle hook 
   */
  ngOnInit(): void {
    this.storeId = this.sharedService && this.sharedService.storeId;
    this.languageCode = localStorage?.getItem('language') ?? 'en'
    this.subscriptionObj.add(this.sharedService.getSoldCountNotificationDetails(this.storeId).subscribe((res: soldCountNotificationDetails) => {
      if (res && res.data && res.data.isEnabled) {
        this.subscriptionObj.add(this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe((route: {
          id: number,
          type: number,
          url: string,
          urlAfterRedirects: string
        }) => {
          if (route) {
            if (this.allowNotification) {
              this.onCloseNotification(false);
            }
            if (this.soldCountInterval) {
              clearInterval(this.soldCountInterval);
              this.soldCountInterval = null;
            }
            this.currentRoute = route && route.url;
            this.allowNotification = false;
            this.checkShowablePages();
            this.onResize();
          }
        }));
        this.processData(res.data);
        this.getSoldCountData();
        this.onResize();
      }
    }));
  }
  /**
   * This function is used to check wheather the notification can be shown in the current page
   */
  checkShowablePages(): void {
    if (this.currentRoute) {
      this.specificPages.forEach((item: string) => {
        if (item) {
          let result = this.currentRoute.split('/')[((this.currentRoute.split('/').length) - 1)].includes(item) &&
            !(this.currentRoute.split('/')[((this.currentRoute.split('/').length) - 1)].includes('type=cart'));
          if (result) {
            this.allowNotification = result;
          }
        }
      })
    }
    else {
      this.specificPages.forEach((item: string) => {
        if (item && this.router && this.router.url) {
          let result = this.router.url.split('/')[((this.router.url.split('/').length) - 1)].includes(item) &&
            !(this.router.url.split('/')[((this.router.url.split('/').length) - 1)].includes('type=cart'));
          if (result) {
            this.allowNotification = result;
          }
        }
      });
    }
  }
  /**
   * This function is used to get the sold count of store from backend for every 25 seconds only if the notification type is automatic.
   */
  getSoldCountData(): void {
    if (this.isEnabled && this.isAutomatic && this.allowNotification) {
      if (!this.soldCountInterval) {
        this.beginSoldCountInterval();
      }
      this.subscriptionObj.add(this.sharedService.getSoldCountForStoreFront(this.storeId, { lookBackTime: this.lookBackTime, timeFormat: this.timeFormat }).subscribe((res: soldCountData) => {
        if (res) {
          this.count = res.count ? Number(res.count) : 0;
          if (this.count <= 0) {
            this.allowNotification = false;
          }
          this.processNotificationMessage(res.count ? res.count : 0);
        }
        else {
          this.allowNotification = false;
        }
      }));
    }
  }
  /**
   * This function is used to initiate sold count interval which makes API call to get sold count for storefront for every 25 seconds.
   */
  beginSoldCountInterval(): void {
    this.soldCountInterval = setInterval(() => {
      if (!this.isNotificationSuspended && this.allowNotification && this.counter > 0) {
        this.subscriptionObj.add(this.sharedService.getSoldCountForStoreFront(this.storeId, { lookBackTime: this.lookBackTime, timeFormat: this.timeFormat }).subscribe((res: soldCountData) => {
          if (res) {
            this.processNotificationMessage(res.count ? res.count : 0);
          }
          else {
            this.allowNotification = false;
          }
        }));
      }
    }, this.intervalTime);
  }
  /**
   * This function is used to process all the sold count notification related data recieved from the backend.
   * @param data contains sold count notification data.
   */
  processData(data: soldCountNotificationData): void {
    this.isEnabled = data?.isEnabled;
    this.isCloseNotification = data?.isCloseNotification;
    this.maximumNotification = data?.timingDetails?.maximumNotification;
    this.initialDelay = data?.timingDetails?.initialDelay * 1000;
    this.delayNotification = data?.timingDetails?.delayNotification * 1000;
    this.displayTime = data?.timingDetails?.displayTime * 1000;
    this.lookBackTime = data?.lookBackTime;
    this.timeFormat = data?.timeFormat;
    this.isAutomatic = data?.isAutomatic;
    this.specificPages = data?.specificPages?.length ? data.specificPages : ['home', 'products', 'productdetails', 'cart'];
    this.notificationMessage = data?.message;
    this.constNotificationMessage = data?.message;
    this.noOfOrders = data?.noOfOrders;
    this.cssData = {
      isDesktopView: data?.isDesktopView ?? false,
      isMobileView: data?.isMobileView ?? false,
      desktopView: data?.desktopView ?? null,
      mobileView: data?.mobileView ?? null
    }
    if ((this.delayNotification + this.displayTime) <= 27000) {
      this.intervalTime = 25000;
    }
    else {
      this.intervalTime = (this.delayNotification + this.displayTime) - 2;
    }
    if (!this.isAutomatic) {
      this.count = data?.noOfOrders;
      this.processNotificationMessage();
    }
    this.checkShowablePages();
  }
  /**
   * This function is used to process all the css data based on screen size and animation type.
   * @param data contains css data of sold count notification stored in database
   */
  processCssData(data: {
    isDesktopView: boolean,
    isMobileView: boolean,
    desktopView: {
      desktopAnimation: string,
      desktopDisplay?: string
    },
    mobileView: {
      mobileAnimation: string,
      mobileDisplay?: string
    }
  }): void {
    let isOnlineVisitors = this.liveNotification && this.liveNotification.onlineVisitors ? this.liveNotification.onlineVisitors : false;
    let onlineVisitorsViewMode = this.liveNotification && this.liveNotification.onlineVisitorsViewMode ? this.liveNotification.onlineVisitorsViewMode : null;
    if (this.notifyDivId) {
      this.onCloseNotification(false);
      if (document.getElementById(this.notifyDivId)) {
        document.getElementById(this.notifyDivId).classList.remove(this.slideOutClass);
      }
      this.notifyDivId = null;
      this.closeIconClass = null;
      this.slideOutClass = null;
      this.slideInClass = null;
      this.dscLeft = null;
      this.dscBottom = null;
      this.dscOpacity = null;
      this.dscVisibility = null;
    };
    if (data && data.isDesktopView && data.desktopView && data.desktopView.desktopAnimation == 'Slide from Left' && this.isDesktop) {
      this.closeIconClass = 'closeIconDesktop';
      this.notifyDivId = "desktop";
      this.dscLeft = '-860px';
      this.dscBottom = '20px';
      this.slideInClass = 'slideRightDesktop';
      this.slideOutClass = 'slideLeftDesktop';
      this.dscOpacity = 2;
      this.dscVisibility = 'visible';
    }
    else if (data && data.isDesktopView && data.desktopView && data.desktopView.desktopAnimation == 'Slide from Bottom' && this.isDesktop) {
      this.closeIconClass = 'closeIconDesktop';
      this.notifyDivId = "desktop";
      this.dscLeft = '10px';
      this.dscBottom = '-430px';
      this.slideInClass = 'slideUp';
      this.slideOutClass = 'slideDown';
      this.dscOpacity = 2;
      this.dscVisibility = 'visible';
      this.animationBottom = isOnlineVisitors && onlineVisitorsViewMode && onlineVisitorsViewMode.isDesktop ? '115px' : '20px';
    }
    else if (data && data.isDesktopView && data.desktopView && data.desktopView.desktopAnimation == 'Fade In-Out' && this.isDesktop) {
      this.closeIconClass = 'closeIconDesktop';
      this.notifyDivId = "desktop";
      this.dscLeft = '10px';
      this.dscBottom = '20px';
      this.slideInClass = 'fadeIn';
      this.slideOutClass = 'fadeOut';
      this.dscOpacity = 0;
      this.dscVisibility = 'hidden';
    }
    else if (data && data.isMobileView && data.mobileView && data.mobileView.mobileAnimation == 'Slide from Left' && this.isMobile) {
      this.closeIconClass = 'closeIconMobile';
      this.notifyDivId = "mobile";
      this.dscLeft = '-860px';
      this.dscBottom = '20px';
      this.slideInClass = 'slideRightMobile';
      this.slideOutClass = 'slideLeftMobile';
      this.dscOpacity = 2;
      this.dscVisibility = 'visible';
      this.animationLeft = this.isCloseNotification ? '-12px' : '0px';
    }
    else if (data && data.isMobileView && data.mobileView && data.mobileView.mobileAnimation == 'Slide from Bottom' && this.isMobile) {
      this.closeIconClass = 'closeIconMobile';
      this.notifyDivId = "mobile";
      this.dscBottom = '-430px';
      this.slideInClass = 'slideUp';
      this.slideOutClass = 'slideDown';
      this.dscLeft = this.isCloseNotification ? '-12px' : '0px';
      this.dscOpacity = 2;
      this.dscVisibility = 'visible';
      this.animationBottom = isOnlineVisitors && onlineVisitorsViewMode && onlineVisitorsViewMode.isMobile ? '115px' : '20px';
    }
    else if (data && data.isMobileView && data.mobileView && data.mobileView.mobileAnimation == 'Fade In-Out' && this.isMobile) {
      this.closeIconClass = 'closeIconMobile';
      this.notifyDivId = "mobile";
      this.dscLeft = this.isCloseNotification ? '-12px' : '0px';
      this.dscBottom = '20px';
      this.slideInClass = 'fadeIn';
      this.slideOutClass = 'fadeOut';
      this.dscOpacity = 0;
      this.dscVisibility = 'hidden';
    }
    if (isOnlineVisitors && ((this.isDesktop && onlineVisitorsViewMode && onlineVisitorsViewMode.isDesktop) || (this.isMobile && onlineVisitorsViewMode && onlineVisitorsViewMode.isMobile))) {
      this.dscBottom = 'calc(' + this.dscBottom + ' + 95px)';
    }
    if (!this.soldCountInterval) {
      this.getSoldCountData();
    }
    if (this.allowNotification && this.notifyDivId && this.closeIconClass && !this.isNotificationSuspended) {
      this.initialTimeOut = setTimeout(() => {
        this.counter = this.maximumNotification;
        this.showNotification();
      }, this.initialDelay);
    }
  }
  /**
   * This function is used to replace all the variables in the notification message by the respective values.
   * @param count contais sold count of the store fetched from database.
   */
  processNotificationMessage(count?: number): void {
    this.notificationMessage = this.constNotificationMessage;
    if (this.isAutomatic) {
      this.notificationMessage = this.notificationMessage?.replaceAll('{number_order}', `<span style="color: var(--primary-color);font-weight:bold">${count}</span>`)
        .replaceAll('{time}', `<span style="color: var(--primary-color);font-weight:bold">${this.lookBackTime}</span>`)
        .replaceAll('{format}', `<span style="color: var(--primary-color);font-weight:bold">${this.translationService.getTranslation(`SHARED.${this.timeFormat}`)}</span>`);
      this.notificationMessage = this.sanitizer.bypassSecurityTrustHtml(this.notificationMessage);
    }
    else {
      let count = this.noOfOrders ? this.noOfOrders : 0;
      this.notificationMessage = this.notificationMessage?.replaceAll('{number_order}', `<span style="color: var(--primary-color);font-weight:bold">${count}</span>`)
        .replaceAll('{time}', `<span style="color: var(--primary-color);font-weight:bold">${this.lookBackTime}</span>`)
        .replaceAll('{format}', `<span style="color: var(--primary-color);font-weight:bold">${this.translationService.getTranslation(`SHARED.${this.timeFormat}`)}</span>`);
      this.notificationMessage = this.sanitizer.bypassSecurityTrustHtml(this.notificationMessage);
    }
  }
  /**
   * This function is used to display notification.
   */
  showNotification(): void {
    if (this.initialTimeOut) {
      clearTimeout(this.initialTimeOut);
    }
    if (this.afterCloseTimeOut) {
      clearTimeout(this.afterCloseTimeOut);
    }
    this.counter--;
    if (this.counter >= 0) {
      if (this.notifyDivId && document && document.getElementById(this.notifyDivId) && document.getElementById(this.notifyDivId).classList) {
        document.getElementById(this.notifyDivId).classList.remove(this.slideOutClass);
        document.getElementById(this.notifyDivId).classList.add(this.slideInClass);
        this.slideOutTimeOut = setTimeout(() => {
          document.getElementById(this.notifyDivId).classList.remove(this.slideInClass);
          document.getElementById(this.notifyDivId).classList.add(this.slideOutClass);
          this.nextNotificationTimeOut = setTimeout(() => {
            this.showNotification();
          }, this.delayNotification);
        }, this.displayTime);
      }
    }
    else {
      clearTimeout(this.slideOutTimeOut);
      clearTimeout(this.nextNotificationTimeOut);
      clearInterval(this.soldCountInterval);
      this.soldCountInterval = null;
    }
  }
  /**
   * This function is used to close the currently displayed notification.
   * @param allowAfterCloseTimeOut contains data about wheather the afterCloseTimeOut timer should be applied or not.
   */
  onCloseNotification(allowAfterCloseTimeOut: boolean): void {
    if (this.notifyDivId && document.getElementById(this.notifyDivId) && this.closeIconClass) {
      document.getElementById(this.notifyDivId).classList.remove(this.slideInClass);
      document.getElementById(this.notifyDivId).classList.add(this.slideOutClass);
    }
    clearTimeout(this.slideOutTimeOut);
    clearTimeout(this.nextNotificationTimeOut);
    if (allowAfterCloseTimeOut) {
      this.isNotificationSuspended = true;
      clearInterval(this.soldCountInterval);
      this.soldCountInterval = null;
      this.afterCloseTimeOut = setTimeout(() => {
        this.checkShowablePages();
        this.beginSoldCountInterval();
        this.onResize({ isAfterSuspended: true });
      }, 3600000);
    }
    else if (!this.isNotificationSuspended) {
      clearTimeout(this.afterCloseTimeOut);
    }
  }
  /**
   * This function is used to change the notification animation based on the screen size.
   * @param event contains data about the screen size.
   */
  @HostListener('window:resize', ['$event'])
  onResize(event?: {
    isAfterSuspended?: boolean; target?: {
      innerWidth: number;
    };
  }): void {
    let width = event?.target?.innerWidth ?? window.innerWidth;
    let flag = this.isDesktop;
    if (width <= 600) {
      this.isMobile = true;
      this.isDesktop = false;
    }
    else {
      this.isDesktop = true;
      this.isMobile = false;
    }
    if (flag != this.isDesktop && event && this.allowNotification) {
      this.isNotificationSuspended = false;
      clearTimeout(this.afterCloseTimeOut);
      this.processCssData(this.cssData);
    }
    else if (!event && this.allowNotification) {
      this.isNotificationSuspended = false;
      clearTimeout(this.afterCloseTimeOut);
      this.processCssData(this.cssData);
    }
    else if (event && event.isAfterSuspended) {
      this.isNotificationSuspended = false;
      this.getSoldCountData();
      this.processCssData(this.cssData);
    }
  }
  /**
   * Angular life cycle hook 
   */
  ngOnDestory(): void {
    if (this.subscriptionObj) {
      this.subscriptionObj.unsubscribe();
    }
  }
}
