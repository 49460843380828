import { Injectable } from '@angular/core';
import { AffiliateAuthService } from './auth.service';
import { DialogService } from '@phase-ii/common';
import { AuthConstants } from '../constants/authConstant';

@Injectable({
  providedIn: 'root'
})
export class RouteGaurdService extends AuthConstants {

  /**
   * Component constructor to inject the required services.
   * @param authService To get the auth service.
   * @param dialogService 
   */
  constructor(
    private dialogService: DialogService,
    private authService: AffiliateAuthService,
  ) {
    super();
  }
  /**
   * Method which is used to check permission for current route.
   */
  canActivate() {
    let isSuperadmin = localStorage.getItem('isSuperadmin');
    let superadminPath = ['payoutrequest', 'userlist', 'settings', 'addedituser'];
    let both = ['profile', 'referral-customer-list', 'referral-commission-list'];
    let path = window.location.href.split(window.location.host)[1].split('/')[2];
    let app = superadminPath.indexOf(path);
    if ((both.indexOf(path) === -1) && window.location.href.split(window.location.host)[1].split('/')[1] === 'app')
      if ((isSuperadmin === 'false' && app != -1) || (isSuperadmin === 'true' && app === -1)) {
        this.dialogService.openDialog({
          message: this.errorMessage.permit,
          actionType: this.dialogType.failure
        })
        this.authService.logout();
      }
  }
}
