import { Component } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ButtonInfo, Heading } from '@phase-ii/shared';
import { Subscription } from 'rxjs';
import { affilateSettingsConstant } from '../../constants/settings-constant';
import { SettingsService } from '../../services/settings.service';
import { DialogService } from 'libs/common/src/lib//services/dialog.service';
import { dataConfig } from 'libs/common/src/lib/services/config';
@Component({
  selector: 'phase-ii-affiliate-settings',
  templateUrl: './affiliate-settings.component.html',
  styleUrls: ['./affiliate-settings.component.scss'],
})
export class AffiliateSettingsComponent {
  /**
  * Variable which is used to subscribe and unsubscribe the subscriptions.
  * @type {Subscription}
  */
  subscriptionObj: Subscription = new Subscription();

  /**commonConstant used to acccess the common constant values. */

  affiliateSettingsConstant = new affilateSettingsConstant();

  /**
  * Variable which is used to have all the needed variables.
  * @type {object}
  */
  pageDetails: {
    heading: Heading,
    buttonInfo: ButtonInfo[],
    isLoader: boolean,
  } = {
      heading: null,
      buttonInfo: [
        { name: 'Back', class: 'secondary-button', 'link': 'app/payoutrequest' },
        { name: 'Update', class: 'primary-button', method: 'manageCommissionSettings' }
      ],
      isLoader: null,
    };

  /**
  * Variable which is used to find the type
  */
  types = ['Fixed', 'Percentage'];

  /**
  * Variable which is used to save the details
  */
  commissionSettingsData: any;

  /** 
  * @param settingService to access methods from Settings service
  * @param dialogService to access methods from dialog service
  */
  constructor(private settingService: SettingsService, private dialogService: DialogService) {
  }

  /**
  * Declaring form group
  * @type {FormGroup}
  */
  settingsForm: UntypedFormGroup;

  /**  
  * Component OnInit life cycle hook 
  */
  ngOnInit() {
    this.pageDetails.isLoader = true;
    this.pageDetails.heading = {
      title: this.affiliateSettingsConstant && this.affiliateSettingsConstant.heading && this.affiliateSettingsConstant.heading.title,
      description: this.affiliateSettingsConstant && this.affiliateSettingsConstant.heading && this.affiliateSettingsConstant.heading.description
    };
    this.getSettings();
  }

  /**
  * Method which is used to get referral code datas.
  */
  getSettings() {
    this.subscriptionObj.add(this.settingService.getSettings().subscribe((res: any) => {
      this.pageDetails.isLoader = false;
      if (res && res.result) {
        this.commissionSettingsData = res.result;
        this.formInitial(res.result);
      }
      else {
        this.formInitial();
      }
    }, (err) => {
      if (err) {
        this.pageDetails.isLoader = false;
        this.dialogService.dialogMethod(this.affiliateSettingsConstant.dialogMessages.fetchSettingsFailure, this.affiliateSettingsConstant.dialogType.failure, true);
      }
    }
    ));
  }

  /**
  * Method which is used to initialize form.
  */
  formInitial(setting?) {
    this.settingsForm = new UntypedFormGroup({
      commissionType: new UntypedFormControl(setting && setting.commissionType ? setting.commissionType : null, Validators.required),
      commissionValue: new UntypedFormControl(setting && setting.commissionValue ? Number(setting.commissionValue) : null, [Validators.required, Validators.maxLength(30)]),
      thresholdValue: new UntypedFormControl(setting && setting.thresholdValue ? Number(setting.thresholdValue) : null, [Validators.required, Validators.maxLength(30), Validators.min(100), Validators.max(10000), Validators.pattern(dataConfig.patternValidators.amountwithTwoDecimalPoint)]),
      isDiscount: new UntypedFormControl(setting && setting.isDiscount ? setting.isDiscount : false),
      discountType: new UntypedFormControl(setting && setting.discountType ? setting.discountType : null),
      discountValue: new UntypedFormControl(setting && setting.discountValue ? Number(setting.discountValue) : null),
      isAllTime: new UntypedFormControl(setting && setting.isAllTime ? setting.isAllTime : null),
    });
  }

  /**
  *  Method which is used to handle updating commission datas 
  */
  manageCommissionSettings() {
    if (this.settingsForm && this.settingsForm.valid) {
      if (!this.settingsForm.dirty) {
        this.pageDetails.isLoader = false;
        this.dialogService.dialogMethod(this.affiliateSettingsConstant.dialogMessages.noChangesAlert, this.affiliateSettingsConstant.dialogType.alert, true);
      } else {
        this.saveSettingsChanges();
      }
    }
    else {
      this.pageDetails.isLoader = false;
      const dialogRef = this.dialogService.dialogMethod(this.affiliateSettingsConstant.dialogMessages.mandatoryFieldFill, this.affiliateSettingsConstant.dialogType.alert, true);
      if (dialogRef) {
        this.settingsForm && this.settingsForm.markAllAsTouched();
      }
    }
  }

  /**
  * Method which is used to update datas
  */
  saveSettingsChanges() {
    if (this.commissionSettingsData && this.commissionSettingsData.id) {
      this.pageDetails.isLoader = true;
      this.subscriptionObj.add(this.settingService.updateSettings(this.commissionSettingsData.id, this.settingsForm.value).subscribe((res: any) => {
        if (res) {
          this.pageDetails.isLoader = false;
          this.dialogService.dialogMethod(this.affiliateSettingsConstant.dialogMessages.commissionSettingsSuccess, this.affiliateSettingsConstant.dialogType.success, true);
          this.settingsForm.markAsPristine();
        }
      }, (err) => {
        if (err) {
          this.pageDetails.isLoader = false;
          this.dialogService.dialogMethod(this.affiliateSettingsConstant.dialogMessages.commissionSettingsFailure, this.affiliateSettingsConstant.dialogType.failure, true);
        }
      }));
    }
  }

  /**
  * Method which is used to handle commission and discount values
  * @param event used to send the data
  * @param type used to go to the respective validation
  */
  handleSettings(event: any, type?: string) {
    if (type === 'handlingCommission') {
      this.handlingCommissionValidation(event);
    }
    if (type === 'handlingDiscount') {
      this.handlingDiscountValidation(event);
    }

  }

  /**
  * Method which is used to handle the commission validation
  * @param event hold the type
  */
  handlingCommissionValidation(event) {
    this.settingsForm.get('commissionValue').setValue(null);
    if (event && event.value === 'Percentage') {
      this.settingsForm.get('commissionValue').setValidators([Validators.required, Validators.pattern(dataConfig.patternValidators.acceptOnlyNumbers),
      Validators.max(100), Validators.min(1)]);
      this.settingsForm.get('commissionValue').updateValueAndValidity();
    } else if (event && event.value === 'Fixed') {
      this.settingsForm.get('commissionValue').setValidators([Validators.required, Validators.pattern(dataConfig.patternValidators.amountwithTwoDecimalPoint),
      Validators.max(10000), Validators.min(1)]);
      this.settingsForm.get('commissionValue').updateValueAndValidity();
    } else {
      this.settingsForm.get('commissionValue').setValidators(null);
      this.settingsForm.get('commissionValue').updateValueAndValidity();
    }
  }

  /**
  * Method which is used to set the validation based on Fixed and Percentage
  * @param type which show the type 
  * @param event which show the destination input field
  */
  holdAndAdditionalAmountDataChanged(type, event) {
    if (this.settingsForm.get(type) && this.settingsForm.get(type).value &&
      this.settingsForm.get(event)) {
      if (this.settingsForm.get(type).value === 'Percentage') {
        this.settingsForm.get(event).setValidators([Validators.required,
        Validators.pattern(dataConfig.patternValidators.acceptOnlyNumbers), Validators.max(100),
        Validators.min(1)]);
      } else if (this.settingsForm.get(type).value === 'Fixed') {
        this.settingsForm.get(event).setValidators([Validators.required,
        Validators.pattern(dataConfig.patternValidators.amountwithTwoDecimalPoint), Validators.max(10000),
        Validators.min(1)]);
      }
      this.settingsForm.get(event).updateValueAndValidity();
    }
  }

  /**
  * Method which is used to set discount validation
  */
  handlingDiscountValidation(event) {
    this.settingsForm.get('discountValue').setValue(null);
    if (event && event.value === 'Percentage') {
      this.settingsForm.get('discountValue').setValidators([Validators.required, Validators.pattern(dataConfig.patternValidators.acceptOnlyNumbers),
      Validators.max(100), Validators.min(1)]);
      this.settingsForm.get('discountValue').updateValueAndValidity();
    } else if (event && event.value === 'Fixed') {
      this.settingsForm.get('discountValue').setValidators([Validators.required, Validators.pattern(dataConfig.patternValidators.amountwithTwoDecimalPoint),
      Validators.max(10000), Validators.min(1)]);
      this.settingsForm.get('discountValue').updateValueAndValidity();
    } else {
      this.settingsForm.get('discountValue').setValidators(null);
      this.settingsForm.get('discountValue').updateValueAndValidity();
    }
  }

  /**
  * Method which is used to initiate the update method
  */
  functionTriggered(event) {
    if (event) {
      this[event]();
    }
  }

  /**
  * Method which is used clear the validation for discount
  */
  discountValueChanged(event: boolean) {
    if (!event) {
      this.settingsForm.get('discountType').setValue(null);
      this.settingsForm.get('discountValue').setValue(null);
      this.settingsForm.get('discountValue').clearValidators();
      this.settingsForm.get('discountType').clearValidators();
      this.settingsForm.get('discountType').updateValueAndValidity();
      this.settingsForm.get('discountValue').updateValueAndValidity();
      this.settingsForm.markAsUntouched();
    } else {
      this.settingsForm.get('discountType').setValidators([Validators.required]);
      this.settingsForm.get('discountValue').setValidators([Validators.required]);
      this.settingsForm.updateValueAndValidity();
    }
  }

  /**
  * Method used to deactivate the canDeactivate guard when the form us dirty.
  * @returns dialog box
  */
  canDeactivate() {
    return this.settingsForm && !this.settingsForm.dirty;
  }

  /**
  * Component OnDestroy life cycle hook.
  * And unsubscribe all the subscriptions in the component.
  */
  ngOnDestroy() {
    if (this.subscriptionObj) {
      this.subscriptionObj.unsubscribe();
    }
  }
}



