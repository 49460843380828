import { Component } from '@angular/core';
import { ActionIcon, ButtonInfo, ColumnsRef, CommonConstants, CommonDataService, CommonService, Heading, ListProperties, PaginationService } from '@phase-ii/shared';
import { Subscription } from 'rxjs';
import { DialogService } from '@phase-ii/common';
import { AccountDetailsConstant } from '../../constants/account-details-constant';
import { AccountDetailsService } from '../../services/account-details.service';
import { Router } from '@angular/router';
import { AffiliateAuthService } from '../../../auth/services/auth.service';

@Component({
  selector: 'phase-ii-account-details-list',
  templateUrl: './account-details-list.component.html',
  styleUrls: ['./account-details-list.component.scss'],
})

export class AccountDetailsListComponent {

  /**
    * Variable which is used to have all the needed variables.
    * @type {object}
  */
  pageDetails: {
    heading: Heading,
    columnsRef: ColumnsRef[],
    listProperty: ListProperties,
    buttonInfo: ButtonInfo[],
    isLoader: boolean;
    listLoader: boolean;
    customerId: number
    getLength: boolean,
    formLength: number,
    actionIcons: ActionIcon[],
  } = {
      heading: null,
      columnsRef: null,
      listProperty: {
        globalSearch: true,
        columns: 5,
        dataOnly: true,
        rowElementClicked: false,
        suffixText: true,
        searchPlaceHolder: 'Search by bank name, account holder name',
        localSearchFields: ['bankName,accountHolderName'],
        disableLocalSearch: false,
        isNewImage: {
          isNew: false,
          url: './assets/Empty-page-image.png',
          text: 'No acccount details have been added yet'
        }
      },
      buttonInfo: [
        {
          name: 'Add Account', class: 'primary-button', method: 'navigateToPage', icon: 'add'
        }
      ],
      isLoader: false,
      listLoader: true,
      customerId: null,
      getLength: true,
      formLength: null,
      actionIcons: []
    }
  /**
    * Variable which is used to subscribe and unsubscribe the subscriptions.
    * @type {Subscription}
  */
  subscriptionObj: Subscription = new Subscription();

  /**
    * accountdetailsconstant used to acccess the AccountDetails constant values. 
  */
  accountDetailsConstant = new AccountDetailsConstant();

  /**
    * Object which is used for pagination details.
  */
  paginationDetails: {
    offset: number,
    pageSize: number;
    itemsCount?: number;
    limit?: number;
  } = {
      pageSize: new CommonConstants().paginator.defaultPageSize,
      offset: 0,
      itemsCount: null,
      limit: 10
    };

  /**
    * Variable which is used to store AccountDetails datas.
  */
  primaryAccount: any

  /**
    * Variable used to store the store the accountdetails length.
    * @type {number}
  */
  accountDetailsLength: number;

  /**
    * Variable used to store the searched information
    * @type {string}
  */
  searchText!: string;

  /**
    * Component constructor to inject the required services.
    * @param route To get the user id from params.
    * @param paginationService To access methods from PaginationService.
    * @param accountDetailService To access methods from AccountDetailsService .
    * @param dialogService To access methods from DialogService.
    * @param commonDataService To access methods from CommonDataService.
    * @param commonService To access methods from CommonService.
    * @param affiliateAuth To access methods from AffiliateAuthService.
  */
  constructor(
    private route: Router,
    private paginationService: PaginationService,
    private accountDetailService: AccountDetailsService,
    private dialogService: DialogService,
    private commonDataService: CommonDataService,
    private commonService: CommonService,
    private affiliateAuth: AffiliateAuthService
  ) {
  }

  /**
    *  Component OnInit life cycle hook.
  */
  ngOnInit() {
    this.subscriptionObj.add(this.affiliateAuth.user.subscribe((res: any) => {
      if (res && res.id) {
        this.pageDetails.customerId = res.id;
        this.variableIntialization();
      }
    }));
  }

  /**
    * The method variableIntialization used to initialize the variables.
  */
  variableIntialization() {
    this.pageDetails.heading = {
      title: this.accountDetailsConstant && this.accountDetailsConstant.AccountDetailsHeading && this.accountDetailsConstant.AccountDetailsHeading.title,
      description: '',
    };

    this.pageDetails.columnsRef = this.accountDetailsConstant.columnsForAccount;

    this.pageDetails.actionIcons = this.accountDetailsConstant.Actionicons;

    this.getAccountDetails(0, 2 * this.paginationDetails.pageSize, true);
  }

  /**
    * Method getAccountDetails used to fetch the Account details.
    * @param isNew is the new data.
  */
  getAccountDetails(offset?: number, limit?: number, isNew?: boolean, searchData?: any) {
    this.pageDetails.listLoader = true;
    const data = {
      id: this.pageDetails && this.pageDetails.customerId,
      offset: offset ? offset : 0,
      limit: limit ? limit : 5,
      searchData: searchData ? searchData : ''
    };
    this.subscriptionObj.add(this.accountDetailService.getAccountDetails(data).subscribe((res: any) => {
      if (res && res.result) {
        this.pageDetails.listLoader = false;
        const getEncrypt = this.commonDataService.decryptDetails(res.result);
        const decrypt = JSON.parse(getEncrypt);
        this.primaryAccount = decrypt && decrypt.rows;
        this.primaryAccount.forEach((element: any) => {
          if (element && element.accountNumber && element.accountNumber.length > 3) {
            const accNumberString = element.accountNumber.toString();
            const accountNumberFormatChange = '*'.repeat(accNumberString.length - 3) + accNumberString.substr(accNumberString.length - 3);
            element.accountNumber = accountNumberFormatChange;
          }
        });
        const primary = this.primaryAccount.find((active: any) => (active.isActive == true))
        if (primary) {
          const primary1 = this.primaryAccount.indexOf(primary);
          this.primaryAccount.splice(primary1, 1);
          this.primaryAccount.unshift(primary);
        }
        this.accountDetailsLength = this.primaryAccount.length;
        if (this.pageDetails && this.pageDetails.getLength) {
          this.pageDetails.formLength = this.accountDetailsLength;
          this.pageDetails.getLength = false;
        }
        this.paginationDetails.itemsCount = decrypt && decrypt.count
        if (!this.searchText) {
          this.pageDetails.listProperty.isNewImage.isNew = this.primaryAccount && this.primaryAccount.length > 0 ? false : true;
        }
        this.paginationService.listPaginatorData.next(
          {
            new: isNew,
            rows: this.primaryAccount,
            count: this.paginationDetails.itemsCount
          });
      }
      this.pageDetails.listLoader = false;
    }, (err) => {
      this.dialogService.dialogMethod(this.accountDetailsConstant.dialogmessages.failedToLoad, this.accountDetailsConstant.dialogType.failure, true);
      this.pageDetails.listLoader = false;
    }));
  }

  /**
    * Method which is used for trigger the function of header buttons.
    * @param event To get the function name.
  */
  buttonAction(event: any): void {
    if (event) {
      this[event]();
    }
  }

  /**
    * Method which is used to navigate to the Add account details page.
  */
  navigateToPage() {
    this.route.navigate(['app/add-edit-account'], { queryParams: { isActive: this.accountDetailsLength ? true : false, length: this.accountDetailsLength } });
  }

  /**
    * Method which is used for action button click.
  */
  listAction(event: any): void {
    if (event && event.method) {
      this[event.method](event);
    }
  }

  /**
    * Method which is used to get changed page data.
    * @param event To get offset and limit.
  */
  getChangedData(event: any): void {
    this.paginationDetails.offset = event && event.offset;
    this.paginationDetails.limit = event && event.limit;
    this.getAccountDetails(this.paginationDetails.offset, this.paginationDetails.limit, false, this.searchText);
  }

  /**
    * Method which is used to get data when the row of signature is clicked.
    * @param event to get the particular row's data in table.
  */
  editDetails(event: any) {
    if (event && event.data && event.data.id) {
      const data: any = this.commonDataService.setParam(event.data.id);
      this.route.navigate(['app/add-edit-account'], { queryParams: { id: data, isActive: this.accountDetailsLength ? true : false, length: this.accountDetailsLength } });
    }
  }

  /**
    * Method which can be delete the particular Account details form the list.
    * @param event To get the selected event data for delete.
  */
  deleteDetails(event: any) {
    if (event && event.data && event.data.id) {
      if ((this.pageDetails && this.pageDetails.formLength === 1)) {
        this.dialogService.dialogMethod(this.accountDetailsConstant.dialogmessages.oneDefaultAccountRequired, this.accountDetailsConstant.dialogType.alert, true);
      }
      else if (event.data.isActive) {
        this.dialogService.dialogMethod(this.accountDetailsConstant.dialogmessages.DisableDefaultAccount, this.accountDetailsConstant.dialogType.alert, true);
      }
      else {
        this.deleteAccount(event);
      }
    }
  }

  /**
  * Method which can be delete the particular Account details form the list.
  * @param event To get the selected event data for delete.
  */
  deleteAccount(event: any) {
    const deleteID = event && event.data && event.data.id;
    const dialogRef1 = this.dialogService.dialogMethod(this.accountDetailsConstant.dialogmessages.delete, this.accountDetailsConstant.dialogType.confirmation, true);
    this.subscriptionObj.add(dialogRef1.afterClosed().subscribe((res: any) => {
      if (res) {
        this.subscriptionObj.add(this.accountDetailService.deleteAccountDetails(deleteID).subscribe((result: any) => {
          if (result) {
            this.deleteAccountDetails();
          }
        }, (err) => {
          if (err) {
            this.dialogService.dialogMethod(this.accountDetailsConstant.dialogmessages.deleteFailed, this.accountDetailsConstant.dialogType.failure, true);
            this.pageDetails.isLoader = false;
            this.getAccountDetails(0, 2 * this.paginationDetails.pageSize, true);
          }
        }));
      }
    }));
  }

  /**
  * Method which can be delete the particular Account details form the list.
  * @param event To get the selected event data for delete.
  */
  deleteAccountDetails() {
    this.pageDetails.listLoader = true;
    const dialogRef = this.dialogService.dialogMethod(this.accountDetailsConstant.dialogmessages.accountDeletedSuccess, this.accountDetailsConstant.dialogType.success, true);
    if (dialogRef) {
      this.pageDetails.getLength = true;
      this.pageDetails.listLoader = false;
      this.commonService.searchField.setValue(null);
      this.getAccountDetails(0, 2 * this.paginationDetails.pageSize, true);
    }
  }

  /**
    * Method which is used for search in account details list.
    * @param event to store search text.
  */
  search(event: any): void {
    this.searchText = event.trim();
    this.getAccountDetails(0, 2 * this.paginationDetails.pageSize, true, this.searchText);
  }

  /*
    * Component OnDestroy life cycle hook.
    * And unsubscribe all the subscriptions in the component.
  */
  ngOnDestroy() {
    if (this.subscriptionObj) {
      this.paginationService.listPaginatorData.next(null);
      this.subscriptionObj.unsubscribe();
    }
  }
}
