export class AccountDetailsConstant {

  /**
  * Variable which is used to store the columns details.
    @type {object}
  */
  columnsForAccount = [
    {
      column: 'accountNumber', suffixText: 'Account Number', type: 'Text', customRibbon: {
        isDisplay: true, ribbonText: "Primary", field: 'isActive', value: true
      }
    },
    { column: 'accountHolderName', suffixText: 'Account Holder Name', type: 'Text' },
    { column: 'bankName', suffixText: 'Bank Name', type: 'Text' },
    { column: 'branchName', suffixText: 'Branch Name', type: 'Text' },
  ];

  /**
* Variable which is used for store the action icons
* @type {object}
*/
  Actionicons = [
    { iconName: 'edit', method: 'editDetails', toolTip: 'Edit Account Details', iconColor: '#03c04a' },
    { iconName: 'delete', method: 'deleteDetails', toolTip: 'Delete Account Details', iconColor: '#f72121' },
  ];

  /**
  * variable which is used for Account details list heading
  * @type {object}
  */
  AccountDetailsHeading = {
    title: 'Account Details',
    description: 'List of your account details',
  };

  /**
  * variable which is used for Add-edit account details heading
  * @type {object}
  */
  AddAccountHeading = {
    title: ' Account Details',
    description: 'Add your account details here',
  };

  /**
* Variable which is used for paginator
* @type {object}
*/
  paginator = {
    pageLengthOptions: [5, 10, 20, 30, 40],
    defaultPageSize: 10
  };

  /**
 * Variable which is used to get the validation error message
 * @type {object}
 */
  errorMessage = {
    "bank_name_required": "Bank name is required",
    "Bank_branch_required": "Branch name is required",
    "account_holder_name_required": "Account holder name is required",
    "account_number_required": "Account number is required",
    "IFSC_code_required": "IFS Code is required",
    "MICR_code_required": "MICR code is required",
    "Address_required": "Adress field is required",
    "VPA_Id_required": "VPA ID is required",
    "Accountexist": "Account number already exists",
    "account_number_confirmation_required": "Confirm account number is required",
    "AccountNotMatch": "Account number does not match",

    maxlength: {
      "bank_name_length": "Bank name should not exceed 35 characters",
      "branch_name_length": "Branch name should not exceed 35 characters",
      "account_number_length": "Account number should not exceed 17 digits",
      "holders_name_length": "Account holder name should not exceed 35 characters",
      "IFSC_length": "IFS Code should not exceed 11 digits",
      "MICR_length": "MICR Code should not exceed 9 digits",
      "VPA_length": "VPA ID should not exceed 30 digits"
    },
    pattern: {
      "acceptOnlyNumbers": '^[0-9]*$',
      "acceptOnlyAlphabets": '^[a-zA-Z\/\s\'-().\-]+(\\s[a-zA-Z\/\s\'-().]+)*$',
      "MCIRPattern": /^(?=^.{6,20}$)((?=.*\d)(?=.*\W+))(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/,
      "IFSCPattern": '^([A-Z]{4}[0][A-Z0-9]{6})$',
      "VPApattern": '^[0-9A-Za-z.-]{2,256}@[A-Za-z]{2,64}$',
      "MICRpattern": '^[0-9]{1,9}$',
      "bank_name_pattern": "Bank name accepts characters only",
      "branch_name_pattern": "Branch name accepts characters only",
      "holder_name_pattern": "Holder's name accepts characters only",
      "IFSC_pattern": "Please enter a valid IFS code",
      "VPA_pattern": "Please enter a valid VPA ID",
      "Account_number_pattern": "Account number accepts numbers only",
      "MICR_pattern": "Please enter a valid MICR code"
    }
  };

  /**
   * dialog box messages for Add and Edit account details.
     @type {object}
   */
  dialogmessages = {
    accountCreatedSuccess: "Account details saved successfully!",
    accountDeletedSuccess: "Account details deleted successfully!",
    addDetailsFailure: "Failed to create account details.",
    updateDetailsFailure: "Failed to update account details.",
    delete: 'Are you sure you want to remove this account?',
    deleteFailed: 'Failed to delete account details.',
    createFailed: 'Failed to save account details.',
    failedToLoad: 'Failed to load account details.',
    getUserFailed: 'Failed to get user details.',
    mandatoryField: 'Please fill out all the mandatory fields properly.',
    closeDomain: "You have unsaved changes. Are you sure you want to close?",
    userUpdate: 'Account details updated successfully!',
    formAlert: "You have no changes to save.",
    defaultSuccess: "Primary account added Successfully!",
    DisableDefaultAccount: 'The selected account is the primary one. Please set another account as the primary account to delete this account.',
    defaultConfirmation: 'Primary account already exists! Do you want to make this as your primary account?',
    oneDefaultAccountRequired: 'At least one primary account is required.',
    alertTochangeDefaultAccount: 'There must be at least one primary Account. ' + " " + 'Please select any other account as the primary account!',
  }

  /**
   * variable which is used to store dialog type.
   * @type {object}
   */
  dialogType: any = {
    alert: 'alert',
    confirmation: 'confirmation',
    information: 'information',
    success: 'success',
    failure: 'failure',
    warning: 'warning'
  };
}