<!-- <mat-card class="mat-elevation-z1" style="margin: 15px;"> -->
<div>
    <!-- Above Header Top section -->
    <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign="space-evenly center" fxLayoutGap="0px"
        fxLayoutGap.lt-sm="10px">
        <div fxLayout="column" fxFlex="30" fxLayoutAlign="center">
            <div *ngIf="sortlistProperties?.tableTitleHeader?.condition" class="tableTitleHeader"
                [ngStyle]="sortlistProperties?.tableTitleHeader?.color?{'color':sortlistProperties?.tableTitleHeader?.color}: {'color': 'var(--primarynormal)'}">
                {{sortlistProperties?.tableTitleHeader?.title}}
            </div>
        </div>
        <div fxFlex="70" fxLayoutAlign.gt-xs="end end">
            <!-- location Filter Right Side-->
            <!-- Date Filter Right Side -->
            <!-- Action Icon And Button Right Side -->
            <div *ngIf="headerActions" fxFlexOffset="4">
                <ng-container *ngIf="headerActions">
                    <ng-container *ngFor="let icon of headerActions">
                        <em *ngIf="icon.name && !icon.buttonText " class="material-icons-outlined rightTopIcons"
                            [attr.data-control]="icon?.value" (click)="iconEvent(icon?.value,icon)"
                            [matTooltip]="icon.toolTip"
                            [ngStyle]="icon.iconColor ?  {'color':icon.iconColor}: {'color': 'black'}">{{icon?.name}}</em>
                        <button *ngIf="icon.buttonText" mat-stroked-button [attr.data-control]="icon?.value"
                            [ngClass]="[icon.buttonType ? icon.buttonType: 'primary-button']"
                            (click)="iconEvent(icon?.value,icon)" class="topRightButtons">{{icon?.buttonText}}</button>
                    </ng-container>
                    <button *ngIf="sortlistProperties?.ShuffleButton?.condition" mat-stroked-button
                        data-control="ShuffleButton"
                        [ngClass]="[sortlistProperties?.ShuffleButton?.buttonType ? sortlistProperties?.ShuffleButton?.buttonType: 'primary-button']"
                        (click)="shuffleButtonClick()" class="topRightButtons">{{"Shuffle"}}</button>
                </ng-container>
            </div>
            <!-- Sorting Order Right Side -->
        </div>
    </div>
    <!-- empty Page content -->
    <ng-container *ngIf="listData?.length === 0 ">
        <mat-card class="mat-elevation-z1">
            <div fxLayout="row" fxLayoutAlign="center center">
                <br>
                <img src="./assets/no-records-found.png" class="empty-img" alt="no records found" />
            </div>
            <div *ngIf="!errorHandler" fxLayout="row" fxLayoutAlign="center center" class="empty-data-css"
                data-control="noRecordFound">
                No Records Found!
            </div>
            <div *ngIf="errorHandler" fxLayout="row" fxLayoutAlign="center center" class="empty-data-css"
                data-control="somethignWentWrong">
                Oops !! Something went wrong...
            </div>
        </mat-card>
    </ng-container>
    <div class="loader-height" fxLayout="row" fxLayoutAlign="center center" *ngIf="displayLoader">
        <cen-loader type="content" name="cen-cube-loader" data-control="loader"></cen-loader>
    </div>
    <!-- card Header -->
    <div *ngIf="sortColumnsRef">
        <div class="tableHeaderCard mat-elevation-z2" *ngIf="listData && listData?.length > 0 " fxLayout="row"
            fxLayoutAlign="space-evenly center" fxFlex="100" fxLayout.lt-sm="column" fxLayoutGap="0px"
            fxLayoutGap.lt-sm="10px"
            [ngStyle]="sortlistProperties.cardHeaderFontColor ?  {'color':sortlistProperties?.cardHeaderFontColor}: {'color': 'var(--primarynormal)'}">
            <div fxLayoutAlign="center center" fxFlex="5" fxFlex.lt-md="10" *ngIf="sortlistProperties?.checkBox"
                fxLayout.lt-sm="column">
                <mat-checkbox (change)="$event ? masterToggle() : null"
                    [checked]="selection.hasValue() && isAllSelected()"
                    [indeterminate]="selection.hasValue() && !isAllSelected()"
                    [ngClass]="clickable ? 'no-events': 'restore-events'" data-control="masterCheckBox">
                </mat-checkbox>
            </div>
            <div *ngFor="let column of sortColumnsRef" [ngClass]="isLeftAlign? 'leftAlign' : ''" class="cardHeader"
                [fxFlex]="column.fxFlex ? column.fxFlex : fxFlex">
                <ng-container *ngIf="column?.suffixText">
                    {{column?.suffixText}}
                </ng-container>
            </div>
            <div fxLayoutAlign="center center" class="cardHeader" fxFlex="15" fxFlex.lt-sm="10">
                Actions
            </div>
        </div>
    </div>
    <!-- card content -->
    <ng-container *ngIf="listData && listData?.length > 0 && sortColumnsRef">
        <div class="listBody" cdkDropList [cdkDropListData]="listData" (cdkDropListDropped)="dropAction($event)">
            <div *ngFor=" let data of listData;index as i" class="dragList" cdkDrag>
                <div fxLayout="column" class="card mat-elevation-z4"
                    [ngClass]="[sortlistProperties.cardBorderClass? sortlistProperties.cardBorderClass : 'defaultBorder' ]"
                    fxFlex="100"
                    [ngStyle]="sortlistProperties.cardMinHeight? {'min-height': sortlistProperties.cardMinHeight } : {}">
                    <div fxLayout="row" fxLayoutAlign="space-evenly center" fxLayout.lt-sm="column"
                        fxLayoutGap.lt-sm="12px" fxLayoutGap="0px">
                        <div fxLayoutAlign="center center" fxFlex="5" fxFlex.lt-md="10"
                            *ngIf="sortlistProperties?.checkBox">

                            <mat-checkbox (click)="$event.stopPropagation()"
                                (change)="$event ? singleCheckBoxClick($event,data) : null"
                                [checked]="selection.isSelected(data)"
                                [ngClass]="clickable ? 'no-events': 'restore-events'"
                                [attr.data-control]="'checkBox - ' + i">
                            </mat-checkbox>

                        </div>
                        <div *ngFor="let column of sortColumnsRef" [ngClass]="isLeftAlign? 'leftAlign' : 'default'"
                            [fxFlex]="column.fxFlex ? column.fxFlex : fxFlex" (click)="rowClick(data)"
                            [ngStyle]="sortlistProperties?.rowElementClicked ? {'cursor': 'pointer'} : {'cursor': 'default'}">
                            <ng-container
                                *ngTemplateOutlet="displayTemplate;context:{data: data, column: column, index:i}">
                            </ng-container>
                        </div>

                        <div fxLayoutAlign="center center" fxFlex="15" fxFlex.lt-sm="10">
                            <mat-icon class="icon" data-control="dragDropIcon" matTooltip="Drag & Drop"
                                style="color:#0492c2;cursor: move;" cdkDragHandle>
                                open_with
                            </mat-icon>
                            <ng-container *ngIf="data && data?.RowActionIcon && data?.RowActionIcon?.length>0">
                                <ng-container *ngFor="let icon of data?.RowActionIcon">
                                    <mat-icon class="icon" (click)="actionClick($event,icon,data);"
                                        [attr.data-control]="icon?.iconName + i" [matTooltip]="icon?.toolTip"
                                        [ngStyle]="icon.iconColor ?  {'color':icon?.iconColor}: {'color': 'black'}"
                                        cdkDragHandle>
                                        {{icon?.iconName}}
                                    </mat-icon>
                                </ng-container>
                            </ng-container>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </ng-container>
</div>
<!-- </mat-card> -->
<!-- card Field content -->
<ng-template #displayTemplate let-column="column" let-data="data" let-i="index">

    <!-- Image Type -->
    <div *ngIf="column?.type=='Image'" class="rowDiv">
        <img [src]="data[column?.column] " class="img " alt="row image"
            [ngClass]="{ 'imgCircle': column?.style=='circle' , 'imgSquare': column?.style=='square' } ">
    </div>

    <div class="element" [ngClass]="[column?.class? column?.class: 'default', isLeftAlign? '' : 'alignCenter']"
        *ngIf="sortlistProperties?.profileImage && column?.type=='ProfileImage'">
        <span class="profile-img">{{data[column?.column] | uppercase | slice:0:2}}</span>
    </div>

    <div *ngIf="column?.type=='Text'" class="element"
        [ngClass]="[column?.class? column?.class: 'default', isLeftAlign? '' : 'alignCenter']">
        <em fxFlex="5%" fxFlex.lt-md="7%" *ngIf="column?.icon || column?.iconCondition"
            class="material-icons-outlined rowIcon"
            [matTooltip]="column?.toolTipCondition ? getToolTipForColumn(column?.toolTipCondition, data[column?.column], column?.iconToolTip): column?.iconToolTip"
            [ngStyle]="column.iconColor ?  {'color':column?.iconColor}: {'color': 'black'}">{{column?.iconCondition ?
            getIconForColumn(column?.iconCondition, data[column?.column], column?.icon): column?.icon}}</em>
        <span class="rowContent textWrapElipsis" [matTooltip]="data[column?.column]"
            [matTooltipDisabled]="data[column?.column] && (data[column?.column]).length <= 30">{{data[column?.column]}}</span>
    </div>
    <span
        *ngIf="(!data[column?.column] && column?.type != 'Object' && column?.type !='Image' && data[column?.column] !=0 && column?.type !='InnerHtml' && column?.type !='Icon' && column?.type !='Amount' && column?.type !='Toggle' && column?.type !='Ratings'  ) ||  (data[column?.column] == null && column?.type !='Amount' && column?.type !='Object'  && column?.type !='Image'  && column?.type !='InnerHtml' && column?.type !='Icon' && column?.type !='Toggle' && column?.type !='Ratings' && data[column?.column] !=0 ) || (data[column?.column] == ''  && column?.type !='Amount' && column?.type !='Object'  && column?.type !='Image'  && column?.type !='InnerHtml' && column?.type !='Icon' && column?.type !='Toggle' && column?.type !='Ratings' && data[column?.column] !=0 )">
        N/A
    </span>

</ng-template>