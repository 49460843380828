import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ActionIcon, ButtonInfo, ColumnsRef, CommonConstants, CommonDataService, Heading, ListProperties, PaginationService } from '@phase-ii/shared';
import { ReferralCodeService } from '../../services/referral-code.service';
import { DialogService } from '@phase-ii/common';
import { REFERRAL_CODE_CONSTANT} from '../../constants/referral-code-constant';
import { Subscription, of } from 'rxjs';
import { AffiliateAuthService } from '../../../auth/services/auth.service';
import { mergeMap } from 'rxjs/operators';
import { CustomerListData, CustomerListRowDetails, GetChangedData, OnActionDetails, RequestData, UserCommissionParamsDetails, UserCommissionQueryDetails } from '../../models/referralcode.model';
import { AffiliateUserDetails } from '../../../auth/models/auth.model';

@Component({
  selector: 'phase-ii-referral-customer-list',
  templateUrl: './referral-customer-list.component.html',
  styleUrls: ['./referral-customer-list.component.scss'],
})
export class ReferralCustomerListComponent implements OnInit, OnDestroy {

  /**
  * Variable which is used to subscribe and unsubscribe.
  * @type {Subscription}
  */
  subscriptionObj = new Subscription();

   /**
  * Variable which is used to have all the needed variables.
  * @type {object}
  */
  pageDetails: {
    heading: Heading,
    buttonInfo: ButtonInfo[],
    columnRef: ColumnsRef[],
    isLoader: boolean,
    userdetailsId: number,
    listProperty: ListProperties,
    actionIcons: ActionIcon[],
    isQuerydata: boolean,
    currencySymbol: string

  } = {
      heading: null,
      buttonInfo: [],
      columnRef: null,
      isLoader: false,
      userdetailsId: null,
      listProperty: {
        columns: 4,
        suffixText: true,
        dataOnly: true,
        rowElementClicked: false,
        isLocalPagination: false,
        serverSidePagination: true,
        isNewImage: {
          isNew: false,
          url: './assets/empty-customer-list.png',
          text: 'No customers found'
        }
      },
      actionIcons: [],
      isQuerydata: false,
      currencySymbol: null,
    };

  /**
  * Object which is used for pagination details
  */
  paginationDetails: {
    offset: number,
    pageSize: number;
    itemsCount?: number;
    limit?: number;
  } = {
      pageSize: new CommonConstants().paginator.defaultPageSize,
      offset: 0,
      itemsCount: null,
      limit: 10
    };

  /**
  * It initalize object before component is loaded
  * @param referralCodeService used to get customer list
  * @param dialogService contains dialog related informartion
  * @param commonDataService used to detect changes
  **/
  constructor(
    private paginationService: PaginationService,
    private referralCodeService: ReferralCodeService,
    private dialogService: DialogService,
    private affiliateAuth: AffiliateAuthService,
    private route: ActivatedRoute,
    private router: Router,
    private commonDataService: CommonDataService,
  ) {
  }

  /**
  * Angular life cycle hooks
  */
  ngOnInit(): void {
    this.pageDetails.isLoader = true;
    this.subscriptionObj.add(this.affiliateAuth.user.subscribe({
      next: (res: AffiliateUserDetails) => {
        this.pageDetails.isLoader = false;
        if (res && res.id) {
          this.pageDetails.currencySymbol = res && res.currencyData && res.currencyData.currency && res.currencyData.currency.currencySymbol;
        }
      }
    }))
    this.variableInitialization();
    this.getReferralCustomerList(0, 2 * this.paginationDetails.pageSize, true);
  }

/**
*variableInitialization method user to initiaze.
* @type {object}
*/
  variableInitialization(): void {
    this.pageDetails.heading = {
      title: REFERRAL_CODE_CONSTANT &&REFERRAL_CODE_CONSTANT.COLUMNS_CUSTOMER && REFERRAL_CODE_CONSTANT.CUSTOMER_HEADING.TITLE,
      description: REFERRAL_CODE_CONSTANT && REFERRAL_CODE_CONSTANT.COLUMNS_CUSTOMER && REFERRAL_CODE_CONSTANT.CUSTOMER_HEADING.DESCRIPTION,
    };
    this.pageDetails.columnRef = REFERRAL_CODE_CONSTANT && REFERRAL_CODE_CONSTANT.COLUMNS_CUSTOMER;
    this.pageDetails.actionIcons = REFERRAL_CODE_CONSTANT && REFERRAL_CODE_CONSTANT.ACTIONICONS_CUSTOMER;
  }

  /**
  * getReferralCustomerlist method is used to get customerdetails
  * @type {object}
  */
  getReferralCustomerList(offset?: number, limit?: number, isNew?: boolean, queryParams?): void {
    this.pageDetails.isLoader = true;
    this.subscriptionObj.add(
      this.route.queryParams.pipe(
        mergeMap((res: UserCommissionQueryDetails) => {
          if (res && res.userCommission) {
            this.pageDetails.isQuerydata = true;
          }
          return this.route.params;
        }),
        mergeMap((res: UserCommissionParamsDetails) => {
          if (res && res.id) {
            this.setupPageDetailsButton(res.id);

            const requestData = this.createRequestData(offset, limit);
            return this.referralCodeService.getReferralCustomer(this.pageDetails.userdetailsId, requestData);
          }
          return of(null);
        })
      ).subscribe({
        next: (res: CustomerListData) => {
          if (res) {
            this.handleCustomerListResponse(res, isNew)
          }
        },
        error: (err) => this.handleCustomerListError(err)
      })
    );
  }


  /**
   * @param userId Function to setup page details button based on userId
   */
  setupPageDetailsButton(userId: string): void {
    this.pageDetails.userdetailsId = this.commonDataService.getParam(userId);
    this.pageDetails.buttonInfo = this.pageDetails.isQuerydata
      ? [{ name: 'Back', class: 'primary-button', link: 'app/userlist', disabled: false }]
      : [{ name: 'Back', class: 'primary-button', link: 'app/referral', disabled: false }];
  }

  /**
   * 
   * @param offset 
   * @param limit 
   * @returns 
   * Function to create request data
   */
  createRequestData(offset: number, limit: number): RequestData {
    return this.pageDetails.isQuerydata
      ? { offset, limit }
      : { offset, limit, withReferral: true };
  }

  /**
   * @param res 
   * @param isNew 
   * Function to handle response from customer list
   */
  handleCustomerListResponse(res: CustomerListData, isNew: boolean): void {
    this.pageDetails.isLoader = false;
    if (res && res.customerList[0]) {
      const customerList = res.customerList[0];
      if (!customerList.rows) {
        customerList.rows = [];
      }
      if (customerList.rows) {
        this.processCustomerListRows(customerList.rows);
        this.paginationService.listPaginatorData.next({
          new: isNew,
          rows: customerList.rows,
          count: customerList.count
        });
      }
      this.pageDetails.listProperty.isNewImage.isNew = (Number(customerList.count) === 0);
    }
  }

  /**
   * 
   * @param rows 
   * Function to process customer list rows
   */
  processCustomerListRows(rows: CustomerListRowDetails[]): void {
    rows.forEach((element: CustomerListRowDetails) => {
      if (element) {
        this.censorEmailIfPresent(element);
        this.formatPhoneNumber(element);
        this.formatCommissionAmount(element);
      }
    });
  }

  /**
   * 
   * @param element 
   *  Function to censor email if present
   */
  censorEmailIfPresent(element: CustomerListRowDetails): void {
    if (element.Email && element.Email.includes('@')) {
      element.Email = this.censorEmail(element.Email);
    }
  }

    /**
  * method to hide first half email using *
  * @param email 
  * @returns 
  */
    censorEmail(email: string): string {
      const [arr, domain] = email.split("@");
      return this.censorWord(arr) + "@" + domain;
    }

  /**
   * 
   * @param element 
   * Function to format phone number
   */
  formatPhoneNumber(element: CustomerListRowDetails): void {
    const phoneString = element.ContactNumber?.toString();
    if (phoneString) {
      element.ContactNumber = '*******' + phoneString.slice(-3);
    }
  }

  /**
   * 
   * @param element 
   * Function to format commission amount
   */
  formatCommissionAmount(element: CustomerListRowDetails): void {
    if (element.CommissionAmount) {
      element.CommissionAmount = this.pageDetails.currencySymbol + Number(element.CommissionAmount).toFixed(2);
    }
  }

  /**
   * 
   * @param err 
   * Function to handle error from customer list
   */
  handleCustomerListError(err: any): void {
    this.pageDetails.isLoader = false;
    this.dialogService.dialogMethod(
      REFERRAL_CODE_CONSTANT.DIALOG_MESSAGES.FAILED_CUSTOMER_DETAILS,
      REFERRAL_CODE_CONSTANT.DIALOG_TYPE.FAILURE,
      true
    );
  }

  /**
   * Method which is used to navigate to commission list page
   * @param event holds the referral customer related details 
   */
  onView(event: OnActionDetails): void {
    if (event && event.data && event.data.id) {
      const data = this.commonDataService.setParam(event.data.id);
      this.router.navigate(['app/referral-commission-list', data]);
    }
  }

  /**
  * Method which can be handle the value emitted from common card list.
  * @param event To get the selected event
  */
  listAction(event: OnActionDetails): void {
    if (event && event.method) {
      this[event.method](event);
    }
  }

  /**
  * method to hide name in email using *
  */
  censorWord(str: string): string {
    return str[0] + "*".repeat(str.length - 2) + str.slice(-1);
  }

  /**
  * Method used to move to next or previous page in referralCode list
  * @param event hold the pagination details to get referralCode list
  */
  getChangedData(event: GetChangedData): void {
    if (event) {
    this.paginationDetails.offset = event.offset;
    this.paginationDetails.limit = event.limit;
    this.getReferralCustomerList(event.offset, event.limit, false);
  }
}

  /**
  * Component OnDestroy life cycle hook.
  * And unsubscribe all the subscriptions in the component.
  */
  ngOnDestroy(): void {
    if (this.subscriptionObj)
      this.paginationService.listPaginatorData.next(null);
    this.subscriptionObj.unsubscribe();
  }
}
