<cen-loader-vii *ngIf="pageDetails?.isLoader ">
</cen-loader-vii>
<phase-ii-header-page [heading]="pageDetails?.heading" (methodClicked)="functionTriggered($event)"
  [buttonInfo]="pageDetails?.buttonInfo">
</phase-ii-header-page>
<mat-card class="page-container">
  <form [formGroup]="accountDetailsForm" fxLayout="column" [fxLayoutGap]="'1.0%'" fxLayoutGap.lt-md="3%">
    <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="4%" fxLayoutAlign="center">
      <div fxFlex="50%" fxFlex.lt-md="100%" class="card_header">
        <strong>Account Details</strong>
      </div>
      <div fxFlex="50%" fxFlex.lt-md="100%">
        <div fxFlex="100%" fxFlex.lt-md="100%" fxLayoutAlign="end center" fxLayoutGap=" 10px">
          <mat-label>Mark As Primary Account
          </mat-label>
          <mat-slide-toggle data-control="isActive" formControlName="isActive"
            (change)="enableAccount($event?.checked)">
          </mat-slide-toggle>
        </div>
      </div>
    </div>
    <!-- Account Holder's Name , Account Number -->
    <div fxLayout='row' fxLayout.lt-sm="column" fxLayout.lt-md="column" fxLayoutGap="4%">
      <mat-form-field fxFlex="50%" fxFlex.lt-sm="100%">
        <mat-label>Account holder's name</mat-label>
        <input data-control="accountHolderName" matInput formControlName="accountHolderName" required />
        <mat-error data-control="AccountholderNameRequired"
          *ngIf="accountDetailsForm?.get('accountHolderName')?.hasError('required')">
          {{accountDetailsConstant?.errorMessage?.account_holder_name_required}}
        </mat-error>
        <mat-error data-control="holderNameLengthExceed"
          *ngIf="accountDetailsForm?.get('accountHolderName')?.hasError('maxlength')">
          {{accountDetailsConstant?.errorMessage?.maxlength?.holders_name_length}}
        </mat-error>
        <mat-error data-control="holdersNameInvalid"
          *ngIf="!accountDetailsForm?.get('accountHolderName')?.hasError('maxlength') && accountDetailsForm?.get('accountHolderName')?.hasError('pattern')">
          {{accountDetailsConstant?.errorMessage?.pattern?.holder_name_pattern}}
        </mat-error>
      </mat-form-field>
      <mat-form-field fxFlex="50%" fxFlex.lt-sm="100%">
        <mat-label>VPA ID</mat-label>
        <input data-control="vpaId" matInput formControlName="vpaId"/>
        <mat-error data-control="VPAidRequired" *ngIf="accountDetailsForm?.get('vpaId')?.hasError('required')">
          {{accountDetailsConstant?.errorMessage?.VPA_Id_required}}
        </mat-error>
        <mat-error data-control="VPAidLengthExceed" *ngIf="accountDetailsForm?.get('vpaId')?.hasError('maxlength')">
          {{accountDetailsConstant?.errorMessage?.maxlength?.VPA_length}}
        </mat-error>
        <mat-error data-control="VPAidInvalid"
          *ngIf="!accountDetailsForm?.get('vpaId')?.hasError('maxlength') && accountDetailsForm?.get('vpaId')?.hasError('pattern')">
          {{accountDetailsConstant?.errorMessage?.pattern?.VPA_pattern}}
        </mat-error>
      </mat-form-field>
    </div>
    <!--UPI id -->
    <div fxLayout='row' fxLayout.lt-sm="column" fxLayout.lt-md="column" fxLayoutGap="4%">
      <mat-form-field fxFlex="50%" fxFlex.lt-sm="100%">
        <mat-label>Account number</mat-label>
        <input autocomplete="new-password" matInput type='password' data-control="accountNumber" formControlName="accountNumber" required>
        <mat-error data-control="AccountNumberRequired"
          *ngIf="accountDetailsForm?.get('accountNumber')?.hasError('required')">
          {{accountDetailsConstant?.errorMessage?.account_number_required}}
        </mat-error>
        <mat-error data-control="accountNumberLengthExceed"
          *ngIf="accountDetailsForm?.get('accountNumber')?.hasError('maxlength')">
          {{accountDetailsConstant?.errorMessage?.maxlength?.account_number_length}}
        </mat-error>
        <mat-error data-control="accountNumberInvalid"
          *ngIf="!accountDetailsForm?.get('accountNumber')?.hasError('maxlength') && accountDetailsForm?.get('accountNumber')?.hasError('pattern')">
          {{accountDetailsConstant?.errorMessage?.pattern?.Account_number_pattern}}
        </mat-error>
        <mat-error data-control="accountExist"
          *ngIf="accountDetailsForm?.get('accountNumber')?.hasError('accountAldreadyexist')">
          {{accountDetailsConstant?.errorMessage?.Accountexist}}
        </mat-error>
      </mat-form-field>
      <mat-form-field fxFlex="50%" fxFlex.lt-sm="100%">
        <mat-label>Confirm account number</mat-label>
        <input matInput data-control="confirmAccountNumber" formControlName="confirmAccountNumber" required>
        <mat-error data-control="AccountNumberRequired"
          *ngIf="accountDetailsForm?.get('confirmAccountNumber')?.hasError('required')">
          {{accountDetailsConstant?.errorMessage?.account_number_confirmation_required}}
        </mat-error>
        <mat-error data-control="accountExistt"
          *ngIf="accountDetailsForm?.get('confirmAccountNumber')?.hasError('AccountNotMatch')">
          {{accountDetailsConstant?.errorMessage?.AccountNotMatch}}
        </mat-error>
      </mat-form-field>
    </div>
  </form>
</mat-card>
<mat-card class="page-container">
  <form [formGroup]="accountDetailsForm" fxLayout="column" [fxLayoutGap]="'1.0%'" fxLayoutGap.lt-md="3%">
    <div fxFlex="50%" fxFlex.lt-md="100%" class="card_header">
      <strong>Branch Details</strong>
    </div>
    <!-- IFSC code , MICR code -->
    <div fxLayout='row' fxLayout.lt-sm="column" fxLayout.lt-md="column" fxLayoutGap="4%">
      <mat-form-field fxFlex="50%" fxFlex.lt-sm="100%">
        <mat-label>IFSC code</mat-label>
        <input data-control="IFSC_CODE" matInput formControlName="ifscCode"/>
        <mat-error data-control="IFSCodeRequired" *ngIf="accountDetailsForm?.get('ifscCode')?.hasError('required')">
          {{accountDetailsConstant?.errorMessage?.IFSC_code_required}}
        </mat-error>
        <mat-error data-control="IFSCLengthExceed" *ngIf="accountDetailsForm?.get('ifscCode')?.hasError('maxlength')">
          {{accountDetailsConstant?.errorMessage?.maxlength?.IFSC_length}}
        </mat-error>
        <mat-error data-control="IFSCodeInvalid"
          *ngIf="!accountDetailsForm?.get('ifscCode')?.hasError('maxlength') && accountDetailsForm?.get('ifscCode')?.hasError('pattern')">
          {{accountDetailsConstant?.errorMessage?.pattern?.IFSC_pattern}}
        </mat-error>
      </mat-form-field>
      <mat-form-field fxFlex="50%" fxFlex.lt-sm="100%">
        <mat-label>MICR code</mat-label>
        <input data-control="MCIR_CODE" matInput formControlName="micrCode" required />
        <mat-error data-control="MICRcodeRequired" *ngIf="accountDetailsForm?.get('micrCode')?.hasError('required')">
          {{accountDetailsConstant?.errorMessage?.MICR_code_required}}
        </mat-error>
        <mat-error data-control="MICRLengthExceed" *ngIf="accountDetailsForm?.get('micrCode')?.hasError('maxlength') && !accountDetailsForm?.get('micrCode')?.hasError('pattern')">
          {{accountDetailsConstant?.errorMessage?.maxlength?.MICR_length}}
        </mat-error>
        <mat-error data-control="MICRCodeInvalid"
          *ngIf="accountDetailsForm?.get('micrCode')?.hasError('pattern') ">
          {{accountDetailsConstant?.errorMessage?.pattern?.MICR_pattern}}
        </mat-error>
      </mat-form-field>
    </div>

    <!-- Bank Name , Branch Name -->
    <div fxLayout='row' fxLayout.lt-sm="column" fxLayout.lt-md="column" fxLayoutGap="4%">
      <mat-form-field fxFlex="50%" fxFlex.lt-sm="100%">
        <mat-label>Bank name</mat-label>
        <input data-control="bankName" matInput formControlName="bankName" required />
        <mat-error data-control="BankNameRequired" *ngIf="accountDetailsForm?.get('bankName')?.hasError('required')">
          {{accountDetailsConstant?.errorMessage?.bank_name_required}}
        </mat-error>
        <mat-error data-control="bankNameLengthExceed"
          *ngIf="accountDetailsForm?.get('bankName')?.hasError('maxlength')">
          {{accountDetailsConstant?.errorMessage?.maxlength?.bank_name_length}}
        </mat-error>
        <mat-error data-control="bankNameInvalid"
          *ngIf="!accountDetailsForm?.get('bankName')?.hasError('maxlength') && accountDetailsForm?.get('bankName')?.hasError('pattern')">
          {{accountDetailsConstant?.errorMessage?.pattern?.bank_name_pattern}}
        </mat-error>
      </mat-form-field>
      <mat-form-field fxFlex="50%" fxFlex.lt-sm="100%">
        <mat-label>Branch name</mat-label>
        <input data-control="branchName" matInput formControlName="branchName" required />
        <mat-error data-control="BranchNameRequired"
          *ngIf="accountDetailsForm?.get('branchName')?.hasError('required')">
          {{accountDetailsConstant?.errorMessage?.Bank_branch_required}}
        </mat-error>
        <mat-error data-control="branchNameLengthExceed"
          *ngIf="accountDetailsForm?.get('branchName')?.hasError('maxlength')">
          {{accountDetailsConstant?.errorMessage?.maxlength?.branch_name_length}}
        </mat-error>
        <mat-error data-control="branchNameInvalid"
          *ngIf="!accountDetailsForm?.get('branchName')?.hasError('maxlength') && accountDetailsForm?.get('branchName')?.hasError('pattern')">
          {{accountDetailsConstant?.errorMessage?.pattern?.branch_name_pattern}}
        </mat-error>
      </mat-form-field>
    </div>
  </form>
</mat-card>