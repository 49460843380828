/**
 * Component which is used for header page display
 */
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { OnChanges } from '@angular/core';
import { debounceTime } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { ButtonInfo, CommonService, Heading, buttonToggleInfo, content } from '@phase-ii/shared';

/**
 * Component which is used for header page display
 */
@Component({
  selector: 'phase-ii-header-page',
  templateUrl: './header-page.component.html',
  styleUrls: ['./header-page.component.scss']
})
/**
 * Component which is used for header page display
 */
export class HeaderPageComponent implements OnInit, OnChanges, OnDestroy {
  /**
   * Variable which is used for title and description information
   */
  @Input() heading!: Heading;
  /**
   * Variable which is used for button information
   */
  @Input() buttonInfo!: ButtonInfo[];
  /**
   * Variable which is used for button toggle information
   */
  @Input() buttonToggleInfo!: buttonToggleInfo[];
  /**
   * Variable which is used for emitting the value
   */
  @Output() methodClicked = new EventEmitter();
  /**
   * Variable which is used to display content in center. Type can be warning,success or failure
   */
  @Input() content!: content;
  /*
  * Variable which is used to set fxFlex based on content 
  */
  custromTitleSize: any = '50%';
  /**
   * Check description elipsis is enabled
   */
  descriptionElipsis: boolean;
  /**
   * Check title elipsis is enabled
   */
  titleElipsis: boolean;
  /**
   * Variable which is used to identify the the page is Beta or not
   * @type {boolean}
   */
  isBeta: boolean;
  /**
  * Variable which is used to subscribe and unsubscribe.
  * @type {Subscription}
  */
  subscriptionObj: Subscription = new Subscription();

  /**
  * Component constructor to inject the required services.
  */
  constructor(private router: Router, private commonService: CommonService) { }
  /**
  * Component onInit life cycle hook
  * @type {void}
  */
  ngOnInit(): void {
    this.subscriptionObj.add(this.commonService.searchField.valueChanges.pipe(debounceTime(500)).subscribe(value => {
      const filterText = value && value.trim() ? value.trim() : null;
      if ((filterText && filterText.length > 0) || value == null || value == '') {
        this.commonService.searchSubject.next(value);
      }
    }));
    this.customTitleFunct();
  }
  /**
    * Component onChange life cycle hook
    * @type {void}
    */
  ngOnChanges(): void {
    this.customTitleFunct();
  }
  /**
   * Method which is used to emit the event
   */
  buttonClicked(event: any): void {
    if (event && event.method) this.methodClicked.emit(event.method);
    else if (event && event.link) this.router.navigate([event.link]);
  }
  /**
   * Function used to set size for title content
   */
  customTitleFunct(): void {
    if (this.content && this.content.data && this.content.type && this.buttonInfo) {
      this.custromTitleSize = '30%';
    }
    else if ((this.buttonInfo && !(this.content && this.content.data && this.content.type)) || (!this.buttonInfo && this.content && this.content.data && this.content.type)) {
      this.custromTitleSize = '50%';
    }
    else {
      this.custromTitleSize = '100%';
    }
  }
  /**
   * Function used to check description elipsis is enable or not
   * @param event returns boolean value
   */
  isDescriptionEnable(event: any) {
    this.descriptionElipsis = event;
  }
  /**
   * Function used to check title elipsis is enable or not
   * @param event returns boolean value
   */
  isTitleEnable(event: any) {
    this.titleElipsis = event;
  }
  /**
   * Function called when header icon is clicked
   * @param event denotes icon link
   */
  headerIconNaviagate(event: any) {
    if (event && event.iconLink) {
      window.open(event.iconLink, "_blank");
    }
    else {
      window.open('https://help.getzenbasket.com/', "_blank");
    }
  }
  /**
   * Angular life cycle hooks, It calls when component destroyed
   */
  ngOnDestroy() {
    this.commonService.searchField.setValue(null);
    this.subscriptionObj.unsubscribe();
  }
}
