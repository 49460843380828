export class affilateSettingsConstant {
  /**
   * Variable which is holding the heading value
   */
  heading= {
    title: 'Settings',
    description: ''
  };
  /**
   * variable which is used to store dialog messages
   */
  dialogMessages = {
    'noChangesAlert': 'You have no changes to save.',
    'mandatoryFieldFill': 'Please fill out the mandatory fields.',
    'commissionSettingsSuccess': 'Settings updated successfully.',
    'commissionSettingsFailure': 'Failed to update global Settings details.',
    'fetchSettingsFailure': 'Failed to fetch global settings data.'
  }
  /**
   * variable which is used to store dialog messages
   */
  errorMessages = {
    'commissionTypeRequired': 'Commission type is required',
    'commissionValueRequired': 'Commission value is required',
    'commissionValueMinFixed': 'Commission value should range from 1 to 10000',
    'commissionValueMinPercentage': 'Commission value should range from 1 to 100',
    'commissionValueMaxFixed': 'Commission value should not exceed 10000',
    'commissionValueMaxPercentage': 'Commission value should not exceed 100',
    'commissionValuePatternFixed': 'Commission value accept up to two decimal places only',
    'commissionValuePatternPercentage': 'Commission value accepts only whole numbers',
    'thresholdValueRequired': 'Threshold value is required',
    'thresholdValueMin': 'Threshold value should range from 100 to 10000',
    'thresholdValueMax': 'Threshold value should not exceed 10000',
    'thresholdValuePattern': 'Threshold value accept up to two decimal places only',
    'discountTypeRequired': 'Discount type is required',
    'discountValueRequired': 'Discount value is required',
    'discountValueMinFixed': 'Discount value should range from 1 to 10000',
    'discountValueMinPercentage': 'Discount value should range from 1 to 100',
    'discountValueMaxFixed': 'Discount value should not exceed 10000',
    'discountValueMaxPercentage': 'Discount value should not exceed 100',
    'discountValuePatternFixed': 'Discount value accept up to two decimal places only',
    'discountValuePatternPercentage': 'Discount value accepts only whole numbers'
  }
  /**
  * variable which is used to store dialog type.
  * @type {object}
  */
  dialogType: any = {
    alert: 'alert',
    confirmation: 'confirmation',
    information: 'information',
    success: 'success',
    failure: 'failure',
    warning: 'warning'
  };
}