/**
 * component used for menu home toolbar
 */
import { Component, EventEmitter, Inject, Input, OnInit, Output, PLATFORM_ID, TemplateRef, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { isPlatformBrowser } from '@angular/common';
import { Subscription } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CommonService } from 'libs/shared/src/lib/services/common.service';

import { CommonConstants } from '@phase-ii/shared';
import { CustomValidatorService, DialogService, dataConfig } from '@phase-ii/common';
import { AuthService, CustomAsyncValidatorService } from '@phase-ii/auth';
/**
 * component used for menu home toolbar
 */
@Component({
  selector: 'phase-ii-home-menu-toolbar',
  templateUrl: './home-menu-toolbar.component.html',
  styleUrls: ['./home-menu-toolbar.component.scss']
})
/**
 * component used for menu home toolbar
 */
export class HomeMenuToolbarComponent implements OnInit {

  /**
 * Variable which is used to subscribe and unsubscribe the subscriptions.
 * @type {Subscription}
 */
  subscriptionObj: Subscription = new Subscription();
  /**
   * Variable which is used for emitting the value
   */
  @Output() methodClicked = new EventEmitter();

  /**
  * Input given for menus.
  */
  @Input() menus!: any;
  /**
   * Input given for drawer.
   */
  @Input() drawer!: any;
  /**
   * Input given for notify count.
   */
  @Input() notifyCount!: any;
  /**
 * Variable which is used for dialogRef .
*/
  dialogRef: any;

  // /**
  //  * Input given for expired order notify count.
  //  */
  // @Input() expiredOrderNotifyCount!: any;
  // /**
  //  * Input given for new preorder count.
  //  */
  // @Input() newPreorderCount!: any;
  // /**
  //  * Input given for user query count.
  //  */
  // @Input() userQueryCount!: any;
  // /**
  //  * Input given for total count.
  //  */
  // @Input() totalCount!: any;
  /**
   * Input given for showing failed orders.
   */
  @Input() isOrderFailed!: any;
  /**
   * Input given for store name.
   */
  @Input() storeName = '';
  /**
 * Input given for affiliate user or not
 */
  @Input() isAffiliateUser!: any;
  /**
* Variable which is used to store the add sub domain name
* @type {FormGroup}
*/
  addSubDomainNameForm: UntypedFormGroup = new UntypedFormGroup({});
  /**
 * Variable to open the dialog box for adding the sub domain name
 * @type {TemplateRef}
 */
  @ViewChild('addSubDomain', { static: false }) addSubDomain: TemplateRef<any>;

  /**
   * Input given for current user.
   */
  @Input() currentUser!: any;
  storeUserName = [];
  affiliateUserName = [];
  /**
   * Formcontrol to handle store open/Close.
   */
  storeClosed = new UntypedFormControl(null);
  /**
   * Formcontrol to handle close reason detail.
   */
  reasonMessage = new UntypedFormControl(null, [Validators.maxLength(150)]);
  /**
  * commonConstant used to access commonConstants
  */
  commonConstants = new CommonConstants();
  awsImageUrl: any
  /**
  * Variable which is used to show the reason dialog box
  */
  @ViewChild('reason', { static: false }) reason: TemplateRef<any>;

  /**
   * Variable used to store contact us link
   */
  contactUsLink: any
  /**
   * Component constructor to inject required services.
   * @param router Used for navigating desired routes.
   * @param commonService Used for access the methods of common service.
   * @param dialogService Used to open the success and failure dialog.
   * @param displayDialog To open/close the dialog box.
   * @param authService To access methods from auth service
   * @param environment has environment data.
   * @param dialog used to open and close the dialog box
   */
  constructor(private router: Router,
    private commonService: CommonService,
    private displayDialog: MatDialog,
    private dialogService: DialogService,
    private authService: AuthService,
    private snackBar: MatSnackBar,
    @Inject('environment') environment,
    @Inject(PLATFORM_ID) private platformId: object,
  ) {
    this.contactUsLink = environment?.contactUs;
    /**
* Variable which is used to access aws image url.
*/
    this.awsImageUrl = environment.AWSImageUrl;

  }
  /**
   * Component OnInit lifecycle hook.
   */
  ngOnInit(): void {
    this.storeUserNameFormat();
    if (this.currentUser?.storeId) {
      this.commonService.getStoreDetails(this.currentUser.storeId).subscribe((res) => {
        if (res?.['settings']?.hasOwnProperty('isShopClosed'))
          this.storeClosed.setValue(!res['settings']['isShopClosed']);
        // if (res['settings']['menuLinkData']) {
        //   for (let data of this.menus) {
        //     if (data?.pageCustomIcon === "account_circle") {
        //       for (let sub of data?.subMenu) {
        //         if (sub?.pageCustomTitle === "Help Center") {
        //           if (this.contactUsLink === 'local' || this.contactUsLink === 'prod' || this.contactUsLink === 'uat')
        //             res['settings']['menuLinkData'].splice(1, 1);
        //           else
        //             res['settings']['menuLinkData'].splice(2, 1);
        //           sub.subMenu = res['settings']['menuLinkData'];
        //         }
        //       }
        //     }
        //   }
        // }
      })
    }
    this.commonService.changeStoreDetails.subscribe((res) => {
      if (res !== null)
        this.storeClosed.setValue(!res);
    })
  }
  ngOnChanges() {
    this.storeUserNameFormat();
  }
  storeUserNameFormat() {
    if (this.isAffiliateUser && this.currentUser && this.currentUser.name) {
      this.affiliateUserName = this.currentUser.name.split(" ");
    }
    if (this.currentUser?.storeId) {
      this.storeUserName = this.currentUser.firstName.split(" ");
    }
  }
  /**
   * Method used to navigate to route when clicking submenu.
   * @param routerLink The router link to navigate to.
   */
  onSubMenuClick(routerLink): void {
    if (routerLink?.subMenu) {
      if (isPlatformBrowser(this.platformId)) {
        window.open(routerLink.link, "_blank");
      }
    } else if (routerLink == 'storeClose') {
      if (!this.storeClosed.value) {
        this.displayDialog.open(this.reason, { disableClose: true, autoFocus: false, width: '40%' });
      } else {
        this.snackBar.open('Store Opened Successfully!', 'x', { duration: 5000 });
        this.updateStoreData();
      }
    } else if (routerLink == '/app/users/userloginhistory') {
      this.router.navigate(['/app/users/userloginhistory/', this.currentUser.id, true]);
    } else {
      this.router.navigate([routerLink]);
    }
  }
  /**
   * Method used for navigation.
   * @param link The link to navigate to.
   */
  onNavigate(link: any): void {
    if (link.pageCustomIcon === "storefront" && this.currentUser.store.displayName) {
      if (isPlatformBrowser(this.platformId))
        window.open(link.pageCustomLink, '_blank');
    }
    else if (link.pageCustomIcon === "storefront" && !this.currentUser.store.displayName) {
      this.addSubDomainNameForm = new UntypedFormGroup({
        displayName: new UntypedFormControl(null,
          {
            validators: [Validators.required, Validators.pattern(dataConfig.patternValidators.displayNameValidation), Validators.maxLength(30)],

            asyncValidators: [CustomAsyncValidatorService.asyncDisplayNameValidation(this.authService)]
          }
        )
      },
        CustomValidatorService.formValidation.bind(this));
      this.dialogRef = this.displayDialog.open(this.addSubDomain, {
        width: '430px',
        height: 'auto',
        autoFocus: false,
        disableClose: true,
      });

    }
  }
  /**
   * this method used for cancel for form
   */
  onClose() {
    if (!this.addSubDomainNameForm.pristine) {
      this.dialogService.openDialog({
        header: this.commonConstants.dialogHeader.warning,
        message: this.commonConstants.errorMessage.cancel,
        actionType: this.commonConstants.dialogType.confirmation,
        button: { right: 'Yes', left: 'No' }
      }).afterClosed().subscribe(response => {
        if (response) {
          this.dialogRef.close();
          this.addSubDomainNameForm.reset();
        }
      });
    }
    else {
      this.dialogRef.close();
      this.addSubDomainNameForm.reset();
    }
  }
  /**
   * this method used to save the sub domain name changes
   */
  saveChanges(event: any) {
    if (this.addSubDomainNameForm.valid && !this.addSubDomainNameForm.pristine) {
      if (event) {
        const data = {
          value: event.value,
          dialog: this.dialogRef
        }
        this.methodClicked.emit(data);
      }
    }
  }

  /**
   * Method used to navigate when payment fails.
   */
  paymentFailedNotify(): void {
    this.router.navigate(["/app/orders/paymentlist", 'FAILED']);
  }
  /**
   * Method used to navigate.
   */
  // notify(): void {
  //   this.router.navigate(["/app/orders/orderlist", 'NEW']);
  // }

  /**
   * Method used to update store details.
   */
  updateStoreData() {
    const data = { isShopClosed: !this.storeClosed.value, siteUnderConstructionMessage: this.reasonMessage.value }
    this.commonService.updateStoreDetails(this.currentUser.storeId, data).subscribe((res) => {
      if (res)
        this.reasonMessage.setValue(null);
    }, (err) => {
      this.storeClosed.setValue(!this.storeClosed.value);
    })
  }
  /**
   * Method to save reason.
   */
  saveReason() {
    if (this.reasonMessage.valid && this.reasonMessage.value?.trim().length > 0) {
      this.displayDialog.closeAll();
      this.updateStoreData();
      this.snackBar.open('Store Closed Successfully!', 'x', { duration: 5000 });
    } else {
      this.dialogService.dialogMethod(this.commonConstants.errorMessage.saveAlert, 'alert', true);
    }
  }
  /**
  * Method close reasonMessage.
  */
  closeDialog() {
    if (this.reasonMessage.valid && this.reasonMessage.value?.trim().length > 0) {
      const dialog = this.dialogService.dialogMethod(this.commonConstants.errorMessage.cancel, 'confirmation', true);
      dialog.afterClosed().subscribe((res: any) => {
        if (res) {
          this.reasonMessage.setValue(null);
          this.displayDialog.closeAll();
          this.updateStoreData();
          this.snackBar.open('Store Closed Successfully!', 'x', { duration: 5000 });
        }
      })
    }
    else {
      this.reasonMessage.setValue(null);
      this.displayDialog.closeAll();
      this.updateStoreData();
      this.snackBar.open('Store Closed Successfully!', 'x', { duration: 5000 });
    }
  }
}