import { Injectable } from '@angular/core';
import { HttpRoutingService } from './httpRouting.service';
import { BehaviorSubject, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ProductService {
  collectionId = new BehaviorSubject<number>(null);
  constructor(private httpRoutingService: HttpRoutingService) { }
  deleteLogo = new BehaviorSubject<boolean>(false);

  uploadFiles(apiUrlDb, files, formField?, mainImage?) {
    const storageImage = [];
    const formData: any = new FormData();
    if (formField) {
      formData.append('data', JSON.stringify(formField));
    }
    if (mainImage) {
      formData.append("mainImage", mainImage, mainImage['name']);
    }
    for (let i = 0; i < files.length; i++) {
      // console.log('files i', files[i]);
      if (files[i] && files[i].name && files[i].name.substring(files[i].name.indexOf('.')) !== '.xlsx') {
        formData.append("images", files[i], files[i]['name']);
      } else if (files[i] && files[i].name && files[i].name.substring(files[i].name.indexOf('.')) === '.xlsx') {
        formData.append("xlsxFiles", files[i], files[i]['name']);
      }
      else {
        storageImage.push(files[i]);
      }
    }
    formData.append("storageImage", JSON.stringify(storageImage));
    return this.httpRoutingService.postMethod(apiUrlDb, formData)
  }
  getLocation() {
    return this.httpRoutingService.getMethod('location/getInventoryLocation');
  }
  getAnnouncements(data) {
    return this.httpRoutingService.postMethod('product/getAnnouncements',
      data);
  }
  deleteAnnouncement(data) {
    return this.httpRoutingService.postMethod('product/deleteAnnouncement',
      data);
  }
  createAnnouncement(data) {
    return this.httpRoutingService.postMethod('product/createAnnouncement', data);
  }
  updateAnnouncement(data) {
    return this.httpRoutingService.postMethod('product/updateAnnouncement', data);
  }
  getVariants() {
    return this.httpRoutingService.getMethod('product/getVariants');
  }
  getTag() {
    return this.httpRoutingService.getMethod('collection/getTag');
  }
  getCategory() {
    return this.httpRoutingService.getMethod('product/getAdminCategory');
  }
  getSubCategory() {
    return this.httpRoutingService.getMethod('product/getSubCategory');
  }
  getSubCategoryByCategory(data) {
    return this.httpRoutingService.postMethod(
      'product/getSubCategoryByCategory',
      data
    );
  }
  getCategorywithSubCategory() {
    return this.httpRoutingService.getMethod(
      'product/getCategorywithSubCategory'
    );
  }
  addSubCategory(data) {
    return this.httpRoutingService.postMethod(
      'product/createSubCategory',
      data
    );
  }
  updateSubCategory(data) {
    return this.httpRoutingService.postMethod(
      'product/updateSubCategory',
      data
    );
  }
  deleteSubCategory(data) {
    return this.httpRoutingService.postMethod(
      'product/isDeletedSubCategory',
      data
    );
  }
  addProduct(data) {
    return this.httpRoutingService.postMethod('product/addProduct', data);
  }
  getProductByIdForUser(data) {
    return this.httpRoutingService.postMethodWithId('product/getProductByIdForUser', data);
  }
  getProductVariantsAndoptions(data) {
    return this.httpRoutingService.postMethod('product/getProductVariantsAndoptions', data);
  }
  insertBulkTag(data) {
    return this.httpRoutingService.postMethod('collection/insertBulkTag', data);
  }
  getAllProducts(data) {
    return this.httpRoutingService.postMethod('product/getProductList', data);
  }
  getAllProduct(data) {
    return this.httpRoutingService.postMethod('product/getProductsList', data);
  }
  editProductDetails(data) {
    return this.httpRoutingService.postMethod(
      'product/updateProductDetails',
      data
    );
  }
  getProductDetails(data) {
    return this.httpRoutingService.postMethod(
      'product/getProductDetails',
      data
    );
  }
  updateQuickEdit(data) {
    return this.httpRoutingService.putMethod(
      'product/updateQuickEdit',
      data
    );
  }
  deleteProductDetails(data) {
    return this.httpRoutingService.postMethod(
      'product/deleteProductDetails',
      data
    );
  }
  deleteProduct(data) {
    return this.httpRoutingService.postMethod(
      'product/deleteProduct',
      data
    );
  }
  getInventoryDetails(data, storeId) {
    return this.httpRoutingService.getMethod(
      'stores/'+ storeId + '/locations/' + data.id + '/v3',
      data
    );
  }
  getInventoryStockDetails(data) {
    return this.httpRoutingService.postMethod(
      'location/getInventoryStockDetails',
      data
    );
  }
  setOrAddQuantity(data) {
    return this.httpRoutingService.postMethod(
      'location/updateProductQuantity',
      data
    );
  }
  setOrAddQuantityInStock(data) {
    return this.httpRoutingService.postMethod(
      'location/updateProductstockQuantity',
      data
    );
  }
  getCollections(data) {
    return this.httpRoutingService.postMethod('collection/getCollection', data);
  }
  getOneCollection(data) {
    return this.httpRoutingService.postMethod(
      'collection/getOneCollection',
      data
    );
  }
  deleteCollection(data) {
    return this.httpRoutingService.postMethod(
      'collection/isDeletedCollection',
      data
    );
  }
  getProductAndCollection(data) {
    return this.httpRoutingService.postMethod(
      'collection/getProductAndCollection',
      data
    );
  }
  getAllProductsWithImages(data) {
    return this.httpRoutingService.postMethod('product/getAllProductsWithImages', data);
  }
  mapCollectionAndProduct(data) {
    return this.httpRoutingService.postMethod(
      'product/mapCollectionAndProduct',
      data
    );
  }
  deleteCollectionAndProduct(data) {
    return this.httpRoutingService.postMethod(
      'product/deleteCollectionAndProduct',
      data
    );
  }
  getVariantDetails(data) {
    return this.httpRoutingService.postMethod(
      'product/getVariantDetails',
      data
    );
  }
  createCategory(data) {
    return this.httpRoutingService.postMethod('product/createCategory', data);
  }
  updateCategory(data) {
    return this.httpRoutingService.postMethod('product/updateCategory', data);
  }
  deleteCategory(data) {
    return this.httpRoutingService.postMethod(
      'product/isDeletedCategory',
      data
    );
  }
  createTag(data) {
    return this.httpRoutingService.postMethod('collection/createTag', data);
  }
  updateTag(data) {
    return this.httpRoutingService.putMethod('collection/updateTag', data);
  }
  deleteTag(id) {
    return this.httpRoutingService.deleteMethod('collection/isDeletedTag', id);
  }
  getImageCollection(data) {
    return this.httpRoutingService.postMethod('image/getImageCollection', data);
  }
  getImageCategory() {
    return this.httpRoutingService.getMethod('image/getImageCategory');
  }
  getCompanyIndustryType() {
    return this.httpRoutingService.getMethod('storeIndustryType/getCompanyIndustryType');
  }
  getProductsNotInCollection(data) {
    return this.httpRoutingService.postMethod(
      'product/productsNotInCollection',
      data
    );
  }
  getOneSubCategory(data) {
    return this.httpRoutingService.postMethod(
      'product/getOneSubCategory',
      data
    );
  }
  removeImage(data) {
    return this.httpRoutingService.postMethod('product/deleteImage', data);
  }
  /**
   * Method which can be used for adding new offers.
   */
  addOffer(data: any) {
    return this.httpRoutingService.postMethod('product/addOffer', data);
  }
  /**
   * Method which can be used for adding new offers.
   */
  getOneOffer(data: any) {
    return this.httpRoutingService.postMethod('product/getOneOffer', data);
  }
  /**
   * Method which can be used for getting offers
   */
  getOffers() {
    return this.httpRoutingService.getMethod('product/getOffers');
  }
  /**
   * Method which can be used for updating offers.
   */
  updateOffer(data: any) {
    return this.httpRoutingService.putMethod('product/updateOffer', data);
  }
  /**
   * Method which can be used for deleting offers.
   */
  deleteOffer(id: number) {
    // return this.httpRoutingService.deleteMethod('product/deleteOffer', id);
  }
  addIssueOffers(data: any) {
    return this.httpRoutingService.postMethod('product/addIssueOffer', data);
  }
  getIssueOffers() {
    return this.httpRoutingService.getMethod('product/getIssueOffers');
  }
  /**
   * Method which can be used for check whether the promocode already exists or not.
   * @param promocode defines the coupon code.
   * @param id defines the offer id.
   * @returns true or false. 
   */
  checkPromocodeExist(promocode: string, id?: any) {
    return this.httpRoutingService.postMethod('product/checkPromoCodeExists', {
      promo_codes: promocode,
      id: id,
    }).pipe(map((res) => {
      return res;
    }),
      catchError((err) => {
        return of({ error: true });
      }));
  }
  // /**
  //  * Method which is used to convert date as iso string format.
  //  * @param firstDay To get the first day of that selected month.
  //  * @param lastDay To get the last day of that selected month.
  //  */
  // getIsoString(firstDay, lastDay) {
  //   firstDay = moment(firstDay).startOf('day').toDate();
  //   lastDay = moment(lastDay).endOf('day').toDate();
  //   return {
  //     firstDay: this.formatDate(firstDay),
  //     lastDay: this.formatDate(lastDay),
  //   };
  // }
  /**
   * Method which can be used to retrieve the UTC datetime from local time.
   * @param start_Date Define the start date
   */
  formatDate(start_Date: Date) {
    start_Date = new Date(start_Date);
    start_Date.setMinutes(start_Date.getMinutes() - start_Date.getTimezoneOffset());
    return start_Date;
  }
  /**
   * Method which is used to format the date
   * @param start_Date Define the start date
   * @param end_Date Define the end date
   */
  formatDateByExceeded(start_Date: Date) {
    start_Date = new Date(start_Date);
    start_Date.setMinutes(
      start_Date.getMinutes() + start_Date.getTimezoneOffset()
    );
    return start_Date;
  }
  getOneCategory(data) {
    return this.httpRoutingService.postMethod('product/getOneCategory', data);
  }
  hasSubCategory(data) {
    return this.httpRoutingService.postMethod('product/hasSubCategory', data);
  }
  deleteCategoryWithSubCategory(data) {
    return this.httpRoutingService.postMethod(
      'product/deleteCategoryWithSubCategory',
      data
    );
  }
  checkProductAlreadyExist(product_name: string, productId?) {
    return this.httpRoutingService.postMethod('product/checkProductAlreadyExist', { product_name: product_name, productId: productId }).pipe(map((res) => {
      return res;
    }), catchError((err) => {
      return of({ 'error': true });
    }));
  }
  getAllProductCategories(data) {
    return this.httpRoutingService.postMethod('product/getAllProductCategories', data);
  }
  getProductsByCategories(data) {
    return this.httpRoutingService.postMethod('product/getAllProductsByCategories', data);
  }
  deleteProductCategory(data) {
    return this.httpRoutingService.postMethod('product/isDeletedProductCategory', data);
  }
  createProductCategory(data) {
    return this.httpRoutingService.postMethod('product/createProductCategory', data);
  }
  getAllProductsNotInCategory(data) {
    return this.httpRoutingService.postMethod('product/getAllProductsNotInCategories', data);
  }
  getProductShippingType() {
    return this.httpRoutingService.getMethod('product/getProductShippingType');
  }
  getAllTaxForProduct() {
    return this.httpRoutingService.getMethod('tax/getAllTaxForProduct');
  }
  getWebsiteSettingForProduct() {
    return this.httpRoutingService.getMethod('company/getWebsiteSettingForProduct');
  }
  deleteCategoryImage(data) {
    return this.httpRoutingService.postMethod('product/deleteCategoryImage', data);
  }
  exportProducts(data) {
    return this.httpRoutingService.postMethod('product/exportProducts', data)
  }
  addMenuTiming(data) {
    // console.log('data', data);
    return this.httpRoutingService.postMethod('product/addMenuTiming', data);
  }
  getMenuTiming(data) {
    return this.httpRoutingService.postMethod('product/getMenuTiming', data);
  }
  getMenuTimingDetail(data) {
    return this.httpRoutingService.postMethod('product/getMenuTimingDetail', data);
  }
  updateMenuTiming(data) {
    return this.httpRoutingService.putMethod('product/updateMenuTiming', data);
  }
  deleteMenuTiming(id) {
    return this.httpRoutingService.deleteMethod('product/deleteMenuTiming', id);
  }
  getMenuCategories(data) {
    return this.httpRoutingService.postMethod('product/getMenuCategories', data);
  }
  getAllCategories(data) {
    return this.httpRoutingService.postMethod('product/getAllCategories', data);
  }
  createMenuCategory(data) {
    return this.httpRoutingService.postMethod('product/createMenuCategory', data);
  }
  deleteMenuCategory(data) {
    return this.httpRoutingService.postMethod('product/deleteMenuCategory', data);
  }
  getLowStockProducts(data) {
    return this.httpRoutingService.postMethod('product/getLowStockProducts', data);
  }
  getLowStock(data) {
    return this.httpRoutingService.postMethod('product/getLowStock', data);
  }
  exportMenuTiming() {
    return this.httpRoutingService.getMethod('product/exportMenuTiming');
  }
  getAllTags(data) {
    return this.httpRoutingService.postMethod('collection/getAllTags', data);
  }
  getAllCategory(data?) {
    // return this.httpRoutingService.postMethodWithId(data && data.company_type == "Grocery" && !data.isZen ? 'product/getCategoryForProducts' : 'product/getCategory', data ? data : {});
    return this.httpRoutingService.postMethodWithId(data && (data.company_type == "Grocery" || data.company_type == "Retail") && !data.isZen ? 'product/getCategoryForProducts' : 'product/getCategory', data ? data : {});
  }
  getCategoriesAll() {
    return this.httpRoutingService.getMethod('product/getCategoriesAll');
  }
  getAdminCategory(data) {
    return this.httpRoutingService.postMethod('product/getCategoryAdminList', data);
  }
  deleteProductListInCategory(data) {
    return this.httpRoutingService.postMethod('product/deleteProductListInCategory', data);
  }
  getBrandList(data) {
    return this.httpRoutingService.postMethod('brand/getBrandlist', data);
  }
  createBrand(data) {
    return this.httpRoutingService.postMethod('brand/createBrand', data);
  }
  getOneBrand(data) {
    // // console.log('data', data);

    return this.httpRoutingService.postMethod('brand/getOneBrand', data);
  }
  getProductsByBrands(data) {
    return this.httpRoutingService.postMethod('brand/getAllProductsByBrands', data);
  }
  updateBrand(data) {
    return this.httpRoutingService.putMethod('brand/updateBrand', data);
  }
  getAllProductsNotInBrand(data) {
    return this.httpRoutingService.postMethod('brand/getAllProductsNotInBrands', data);
  }
  createProductBrand(data) {
    return this.httpRoutingService.postMethod('brand/createProductBrand', data);
  }
  deleteProductBrand(id) {
    return this.httpRoutingService.postMethod('brand/isDeletedProductBrand', id);
  }
  hasBrandProduct(data) {
    return this.httpRoutingService.postMethod('brand/hasBrandProduct', data);
  }
  deleteBrand(data) {
    return this.httpRoutingService.postMethod('brand/deleteBrand', data);
  }
  getAllBrands(data) {
    return this.httpRoutingService.postMethodWithId('product/getAllBrands', data);
  }
  getParentCategoryAndProductCount() {
    return this.httpRoutingService.getMethod('product/getParentCategoryAndProductCount');
  }
  exportCategory() {
    return this.httpRoutingService.getMethod('product/exportCategory')
  }
  exportBrand() {
    return this.httpRoutingService.getMethod('brand/exportBrand')
  }
  getParentCategory(data) {
    return this.httpRoutingService.postMethod('product/getParentCategory', data);
  }
  getAllOptions() {
    return this.httpRoutingService.getMethod('product/getAllOptions');
  }
  addOption(data) {
    return this.httpRoutingService.postMethod('product/addOption', data);
  }
  updateOption(data) {
    return this.httpRoutingService.postMethod('product/updateOption', data);
  }
  removeOption(data) {
    return this.httpRoutingService.postMethod('product/removeOption', data);
  }
  exportOptions() {
    return this.httpRoutingService.getMethod('variant/exportOptionAndOptionValues');
  }
  getMenuTimings(data) {
    return this.httpRoutingService.postMethod('product/getMenuTimings', data);
  }

  isProductAvailableInBrand(data) {
    return this.httpRoutingService.postMethod('brand/isProductAvailableInBrand', data);
  }

  isProductAvailableInCategory(data) {
    return this.httpRoutingService.postMethod('product/isProductAvailableInCategory', data);
  }

  isMenuAvailableInCategory(data) {
    return this.httpRoutingService.postMethod('product/isMenuAvailableInCategory', data);
  }



  getsampleTemplate() {
    return this.httpRoutingService.getMethod('farm/getsampleTemplate');
  }

  listAllProductsByCategory(data) {
    return this.httpRoutingService.postMethodWithId('product/listAllProducts', data);
  }
  getProductsByCategoryId(data) {
    return this.httpRoutingService.postMethodWithId('product/getProductsByCategoryId', data);
  }
  getSubcategoryByParentId(data) {
    return this.httpRoutingService.postMethodWithId('product/getSubcategoryByParentId', data);
  }
  getSearchProduct(data) {
    return this.httpRoutingService.postMethod('product/commonSearchProduct', data);
  }

  getUserHomeCategory(data?) {
    return this.httpRoutingService.postMethodWithId('product/getUserHomeCategory', data ? data : {});
  }
  getAllProductsCategory(data?) {
    return this.httpRoutingService.postMethodWithId('product/getCategoryForProducts', data ? data : {});
  }

  getStoreBranchAddressInfo(data) {
    return this.httpRoutingService.postMethodWithId('location/getStoreBranchAddressInfo', data);
  }

  getNewCollections() {
    return this.httpRoutingService.postMethodWithId('collection/getNewCollection', {});
  }

  getCollectionProducts(data) {
    return this.httpRoutingService.postMethodWithId('collection/getCollectionProducts', data);
  }

  getTaxCategoryForProduct(data) {
    return this.httpRoutingService.postMethod('tax/getTaxCategoryForProduct', data);
  }

  getdealoffer(data) {
    return this.httpRoutingService.postMethodWithId('product/getCommonOffers', data);
  }

  getWeightUnits() {
    return this.httpRoutingService.getMethod('product/getWeightUnits');
  }
  getSingleProductImages(data) {
    return this.httpRoutingService.postMethodWithId('product/getSingleProductImages', data);
  }
  /**
   * Method which can be used for get All Farm list
   */
  getAllFarmList(data) {
    return this.httpRoutingService.postMethodWithId('farm/getuserFarmList', data);
  }
  getUserPlanOrder(data) {
    return this.httpRoutingService.postMethod('farm/getUserorderList', data);
  }
  getUserPlanOrderwithId(data) {
    return this.httpRoutingService.postMethod('farm/getUserList', data);
  }
  requestOrder(data) {
    return this.httpRoutingService.postMethod('farm/requestorder', data);
  }

  getCategoryList() {
    return this.httpRoutingService.getMethod('product/getCategoryList');
  }
  sortCategory(data) {
    return this.httpRoutingService.postMethod('product/sortCategory', data);
  }
  getAnnouncementData(data) {
    return this.httpRoutingService.postMethodWithId('product/getAnnouncementData', data);
  }
}
