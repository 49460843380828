<mat-toolbar class="toolbar-style">
    <div fxLayout="row" fxFlex="100%">
        <div fxLayout="column" fxFlex="99%">
            <span fxLayout="row" fxLayoutAlign=" center">
                <button data-control="drawerToggle" type="button" fxLayout="column" aria-label="Toggle sidenav"
                    mat-icon-button (click)="drawer.toggle();">
                    <mat-icon class="menu">menu</mat-icon>
                </button>
                <div *ngIf="storeName" class="storeName" fxLayout="column" fxHide.lt-sm>
                    {{storeName }}
                </div>
                <div *ngIf="!storeName" class="storeName" fxLayout="column" fxHide.lt-sm>
                    ZenBasket Superadmin
                </div>
            </span>
        </div>
        <div fxLayout="column" fxFlex="1%" fxLayoutAlign="center ">
            <div fxLayout="row" fxLayoutAlign="end center">
                <ng-continer *ngFor="let menu of menus">
                    <ng-container *ngIf="!menu?.pageCustomTitle">
                        <ng-container
                            *ngIf="menu?.pageCustomIcon === 'account_circle' || menu?.pageCustomIcon === 'notifications_active'|| menu?.pageCustomIcon==='shopping_cart' || menu?.pageCustomIcon==='live_help';else otherIcon">
                            <ng-container *ngIf="menu?.pageCustomIcon === 'shopping_cart'" class="img-container">
                                <div data-control="cartNotifications" mat-icon-button class="nav-link iconView"
                                    (click)="paymentFailedNotify()">
                                    <span matBadgePosition="after" class="material-icons-outlined"
                                        style="font-size: 30px;"
                                        [matTooltip]="isOrderFailed?'Failed orders': 'No failed orders'">
                                        {{ menu?.pageCustomIcon }}</span>&nbsp;
                                    <mat-icon style="color: #dc3545;font-size: 23px;" *ngIf="isOrderFailed"
                                        class="alert">
                                        error
                                    </mat-icon>
                                </div>
                            </ng-container>
                            <ng-container *ngIf="menu?.pageCustomIcon === 'notifications_active'">
                                <div [matMenuTriggerFor]="notification_sub_menu" data-control="notifications"
                                    mat-icon-button class="nav-link iconView">
                                    <!-- <span matBadge="{{ notifyCount > 0 ? notifyCount : 0 }}" matBadgeSize="medium"
                                        matTooltip="New Order Notification" matBadgeColor="accent"
                                        matBadgePosition="after" class="material-icons-outlined"
                                        style="font-size: 30px; padding: 3px;">
                                        {{ menu?.pageCustomIcon }}</span> -->
                                    <span
                                        matBadge="{{ notifyCount.totalCount > 0 ? notifyCount.totalCount > 9 ? '9+' : notifyCount.totalCount : 0 }}"
                                        matTooltip="New Notification" class="material-icons-outlined"
                                        matTooltip="New Notification" class="material-icons-outlined"
                                        matBadgeColor="accent" matBadgePosition="after"
                                        style="font-size: 30px; padding: 3px;">{{ menu?.pageCustomIcon }}</span>
                                </div>
                            </ng-container>
                            <ng-container *ngIf="menu?.pageCustomIcon === 'account_circle'">
                                <div class="nav-link iconView" #menuTrigger="matMenuTrigger"
                                    [matMenuTriggerFor]="icon_sub_menu">
                                    <div *ngIf="currentUser?.profileImage" class="profile-img" matTooltip="User"
                                        style="margin-top: -2px !important;height: 34px !important;width: 36px !important;"
                                        fxLayoutAlign="center center">
                                        <img [src]="awsImageUrl + currentUser?.profileImage" class="circle-img"
                                            alt="Profile image" />
                                    </div>
                                    <div *ngIf="!currentUser?.profileImage && currentUser?.firstName && currentUser?.lastName"
                                        class="profile-img" matTooltip="User"
                                        style="margin-top: -2px !important;height: 34px !important;width: 36px !important;"
                                        fxLayoutAlign="center center">
                                        {{currentUser?.firstName | slice:0:1 | uppercase}}{{currentUser?.lastName |
                                        slice:0:1 | uppercase}}
                                    </div>
                                    <div *ngIf="!currentUser?.profileImage && currentUser?.firstName  && !currentUser?.lastName && storeUserName?.length"
                                        class="profile-img" matTooltip="User"
                                        style="margin-top: -2px !important;height: 34px !important;width: 36px !important;"
                                        fxLayoutAlign="center center">
                                        <ng-container *ngIf="storeUserName?.length===1;else elseBlock">
                                            {{storeUserName[0] | slice:0:2 | uppercase}}
                                        </ng-container>
                                        <ng-template #elseBlock>
                                            {{storeUserName[0] | slice:0:1 | uppercase}}{{storeUserName[1] |
                                            slice:0:1 | uppercase}}
                                        </ng-template>
                                    </div>
                                    <div *ngIf="!currentUser?.profileImage && !currentUser?.firstName && !currentUser?.lastName && currentUser?.name && affiliateUserName?.length"
                                        class="profile-img" matTooltip="User" fxLayoutAlign="center center">
                                        <ng-container *ngIf="affiliateUserName?.length===1;else displayAffiliate">
                                            {{affiliateUserName[0] | slice:0:2 | uppercase}}
                                        </ng-container>
                                        <ng-template #displayAffiliate>
                                            {{affiliateUserName[0] | slice:0:1 | uppercase}}{{affiliateUserName[1] |
                                            slice:0:1 | uppercase}}
                                        </ng-template>
                                    </div>
                                    <div *ngIf="!currentUser?.firstName && !currentUser?.lastName && !currentUser?.name"
                                        class="profile-img" matTooltip="User" fxLayoutAlign="center center">SA
                                    </div>
                                </div>
                            </ng-container>
                            <ng-container *ngIf="menu?.pageCustomIcon === 'live_help'">
                                <div [matMenuTriggerFor]="help_center_menu" data-control="helpcenter"
                                    style="margin: 5px 5px 0 0;" mat-icon-button class="nav-link iconView">
                                    <span matTooltip="Help and Support"
                                        class="material-icons-outlined material-symbols-outlined"
                                        style="font-size: 30px; padding: 3px;">{{ menu?.pageCustomIcon }}</span>
                                </div>
                            </ng-container>
                        </ng-container>
                        <ng-template #otherIcon>
                            <div class="nav-link" class="iconView">
                                <span data-control="onNavigate" class="material-icons-outlined" style="font-size: 30px;"
                                    *ngIf="menu?.pageCustomIcon" matTooltip="{{menu?.toolTip}}"
                                    (click)="onNavigate(menu)">
                                    {{menu?.pageCustomIcon}}</span>&nbsp;
                            </div>
                        </ng-template>
                        <mat-menu #notification_sub_menu="matMenu" class="dropDownWidth" overlapTrigger="false"
                            xPosition="before">
                            <ng-container *ngFor="let item of menu?.subMenu">
                                <div *ngIf="item?.pageCustomTitle != 'Pre-ordered Orders' || notifyCount.preOrder != false"
                                    fxLayout="row">
                                    <button [attr.data-control]="item?.pageCustomTitle" mat-menu-item
                                        (click)="onSubMenuClick(item?.pageCustomLink)">
                                        {{ item?.pageCustomTitle }}
                                        <span>&nbsp;</span>
                                        <span *ngIf="item?.pageCustomTitle === 'Orders'"
                                            matBadge="{{ notifyCount.newOrderCount > 0 ? notifyCount.newOrderCount > 9 ? '9+' : notifyCount.newOrderCount: 0 }}"
                                            matBadgeSize="medium" matTooltip="New Order Notification"
                                            matBadgeColor="accent" matBadgePosition="after"
                                            class="material-icons-outlined" style="font-size: 10px; padding: 3px;">
                                        </span>
                                        <span *ngIf="item?.pageCustomTitle === 'Expired Orders'"
                                            matBadge="{{ notifyCount.expiredOrder > 0 ? notifyCount.expiredOrder > 9 ? '9+' : notifyCount.expiredOrder: 0 }}"
                                            matBadgeSize="medium" matTooltip="Expired Order Notification"
                                            matBadgeColor="accent" matBadgePosition="after"
                                            class="material-icons-outlined" style="font-size: 10px; padding: 3px;">
                                        </span>
                                        <span *ngIf="item?.pageCustomTitle === 'Pre-ordered Orders'"
                                            matBadge="{{ notifyCount.preOrder > 0 ? notifyCount.preOrder > 9 ? '9+' : notifyCount.preOrder: 0 }}"
                                            matBadgeSize="medium" matTooltip="Preorder Notification"
                                            matBadgeColor="accent" matBadgePosition="after"
                                            class="material-icons-outlined" style="font-size: 10px; padding: 3px;">
                                        </span>
                                        <span *ngIf="item?.pageCustomTitle === 'Queries'"
                                            matBadge="{{ notifyCount.queriesCount > 0 ? notifyCount.queriesCount > 9 ? '9+' : notifyCount.queriesCount : 0 }}"
                                            matBadgeSize="medium" matTooltip="New Queries Notification"
                                            matBadgeColor="accent" matBadgePosition="after"
                                            class="material-icons-outlined" style="font-size: 10px; padding: 3px;">
                                        </span>
                                    </button>
                                </div>
                            </ng-container>
                        </mat-menu>
                        <mat-menu #help_center_menu="matMenu" class="dropDownWidth" overlapTrigger="false">
                            <ng-container *ngFor="let item of menu?.subMenu">
                                <button data-control="onSubMenuClick" mat-menu-item
                                    (click)="onSubMenuClick({subMenu:true,link:item?.pageCustomLink})">
                                    {{ item?.pageCustomTitle | titlecase }}
                                    <span>&nbsp;</span>
                                    <span *ngIf="item?.pageCustomTitle === 'Tutorials'" data-control="onSubMenu"
                                        class="material-icons-outlined" style="font-size: 10px; padding: 3px;">
                                    </span>
                                    <span *ngIf="item?.pageCustomTitle === 'Contact us'" data-control="contactUs"
                                        class="material-icons-outlined" style="font-size: 10px; padding: 3px;">
                                    </span>
                                </button>
                            </ng-container>
                        </mat-menu>
                        <mat-menu #icon_sub_menu="matMenu" class="dropDownWidth" overlapTrigger="false">
                            <div fxLayout="row" style="margin-left: -15px;">
                                <div *ngIf="currentUser?.profileImage" class="profile-img" matTooltip="User"
                                    style="height: 34px !important;width: 36px !important;"
                                    fxLayoutAlign="center center">
                                    <img [src]="awsImageUrl + currentUser?.profileImage" class="circle-img"
                                        alt="Profile image" />
                                </div>
                                <div *ngIf="!currentUser?.profileImage && currentUser?.firstName && currentUser?.lastName"
                                    class="profile-img" matTooltip="User" fxLayoutAlign="center center">
                                    {{currentUser?.firstName | slice:0:1 | uppercase}}{{currentUser?.lastName |
                                    slice:0:1 | uppercase}}
                                </div>
                                <div *ngIf="!currentUser?.profileImage && currentUser?.firstName && !currentUser?.lastName && storeUserName?.length"
                                    class="profile-img" matTooltip="User" fxLayoutAlign="center center">
                                    <ng-container
                                        *ngIf="storeUserName?.length===1;else firstNameDisplay">{{storeUserName[0] |
                                        slice:0:2 | uppercase}}</ng-container>
                                    <ng-template #firstNameDisplay>{{storeUserName[0] | slice:0:1 |
                                        uppercase}}{{storeUserName[1] |
                                        slice:0:1 | uppercase}}</ng-template>
                                </div>
                                <div *ngIf="!currentUser?.profileImage && !currentUser?.firstName && !currentUser?.lastName && currentUser?.name && affiliateUserName?.length"
                                    class="profile-img" matTooltip="User" fxLayoutAlign="center center">
                                    <ng-container *ngIf="affiliateUserName?.length===1;else displayAffiliateName">
                                        {{affiliateUserName[0] | slice:0:2 | uppercase}}
                                    </ng-container>
                                    <ng-template #displayAffiliateName>
                                        {{affiliateUserName[0] | slice:0:1 | uppercase}}{{affiliateUserName[1] |
                                        slice:0:1 | uppercase}}
                                    </ng-template>
                                </div>
                                <div *ngIf="!currentUser?.firstName && !currentUser?.lastName && !currentUser?.name"
                                    class="profile-img" matTooltip="User" fxLayoutAlign="center center">SA
                                </div>
                                <div fxLayout="column" fxLayoutGap="-20px">
                                    <p *ngIf="currentUser?.firstName && !currentUser?.lastName" class="menu-name">
                                        {{currentUser?.firstName | titlecase}}</p>
                                    <p *ngIf="currentUser?.firstName && currentUser?.lastName" class="menu-name">
                                        {{currentUser?.firstName | titlecase}}&nbsp;{{currentUser?.lastName |
                                        titlecase}}</p>
                                    <p *ngIf="!currentUser?.firstName && !currentUser?.lastName && currentUser?.name"
                                        class="menu-name">
                                        {{currentUser?.name | titlecase}}</p>
                                    <p *ngIf="!currentUser?.firstName && !currentUser?.lastName && !currentUser?.name"
                                        class="menu-name">
                                        Super Admin</p>
                                    <p class="menu-email">{{currentUser?.email}}</p>
                                </div>
                            </div>
                            <div *ngIf="storeName && !isAffiliateUser">
                                <mat-divider></mat-divider>
                                <div fxLayout="row" mat-menu-item class="profileMenu">
                                    Store status
                                    <label class="switch" (click)="$event.stopPropagation();" data-control="storeClose">
                                        <input type="checkbox" [formControl]="storeClosed"
                                            (change)=" onSubMenuClick('storeClose')" />
                                        <span class="slider round"></span>
                                    </label>
                                </div>
                                <mat-divider></mat-divider>
                            </div>
                            <ng-container *ngFor="let item of menu?.subMenu">
                                <ng-container>
                                    <button *ngIf="item?.pageCustomTitle !== 'Logout' && 
                                    item?.pageCustomTitle !== 'Admin Apps'" mat-menu-item
                                        (click)="onSubMenuClick(item?.pageCustomLink)">
                                        {{ item?.pageCustomTitle }}</button>
                                    <div *ngIf="item?.pageCustomTitle === 'Admin Apps'">
                                        <mat-divider></mat-divider>
                                        <div mat-menu-item fxLayout="row" class="profileMenu">
                                            <span>{{ item?.pageCustomTitle }}</span>
                                            <div fxLayout="row" fxLayoutGap="5px">
                                                <ng-container *ngFor="let i of item?.iconData">
                                                    <img style="height:30px;width:30px" [src]="i?.src" alt="icon"
                                                        (click)="onSubMenuClick({subMenu:true,link:i?.link})">
                                                </ng-container>
                                            </div>
                                        </div>
                                    </div>
                                    <div *ngIf="item?.pageCustomTitle === 'Logout'">
                                        <mat-divider></mat-divider>
                                        <div data-control="onSubMenuCustomLinkClick" fxLayout="row"
                                            fxLayoutAlign="center center" class="menu-logout"
                                            (click)="onSubMenuClick(item?.pageCustomLink)">
                                            <p>{{ item?.pageCustomTitle }}</p>&nbsp;
                                            <span class="material-icons-outlined icons">
                                                logout
                                            </span>
                                        </div>
                                    </div>
                                </ng-container>
                            </ng-container>
                        </mat-menu>
                    </ng-container>
                </ng-continer>
            </div>
        </div>
    </div>
</mat-toolbar>
<ng-template #reason>
    <p class="secondary-button" mat-dialog-title>
        Reason for store closing<mat-icon class="closeIcn" (click)="closeDialog()">close</mat-icon>
    </p>
    <div mat-dialog-content>
        <mat-form-field fxFlex="90%">
            <mat-label>Reason</mat-label>
            <textarea matInput data-control="reasonMessage" [formControl]="reasonMessage" trimVal>
            </textarea>
            <mat-error *ngIf="reasonMessage?.hasError('maxlength')" data-control="reasonMaxlen">
                {{commonConstants?.validator?.reasonMaxlength}}</mat-error>
        </mat-form-field>
        <div mat-dialog-actions fxLayoutAlign="center center">
            <button data-control="save-button" class="primary-button" mat-raised-button
                (click)="saveReason()">Save</button>
        </div>
    </div>
</ng-template>
<ng-template #addSubDomain>
    <p mat-dialog-title class="secondary-button new-configuration-title" fxLayout="row"
        fxLayoutAlign="space-between center">
        Add Sub Domain
        <mat-icon class="close-icon icon" (click)="onClose()">close</mat-icon>
    </p>
    <mat-card class="new-configuration-content">
        <form [formGroup]="addSubDomainNameForm" *ngIf="addSubDomainNameForm" fxLayout="column" fxLayoutGap="5px"
            fxFlex="100%" style="padding-inline: 16px !important; padding-block: 0px !important;">
            <mat-form-field class="full-width">
                <mat-label>Display Name</mat-label>
                <input required matInput data-control="domianName" formControlName="displayName" />
                <mat-icon class="info-icon" matSuffix style="cursor: pointer;"
                    matTooltip="This is the name that will be generated as your website URL. So, the name given here must be unique">
                    info</mat-icon>
                <mat-error data-control="nameError"
                    *ngIf="addSubDomainNameForm?.get('displayName')?.hasError('required')" class="domain-error-wrapper">
                    {{commonConstants?.errorMessage?.displayNameRequired}}
                </mat-error>
                <mat-error data-control="nameError"
                    *ngIf="addSubDomainNameForm?.get('displayName')?.hasError('maxlength')"
                    class="domain-error-wrapper">
                    {{commonConstants?.errorMessage?.displayNameLength}}
                </mat-error>
                <mat-error data-control="nameError"
                    *ngIf="addSubDomainNameForm?.get('displayName')?.hasError('pattern')" class="domain-error-wrapper">
                    {{commonConstants?.errorMessage?.displayNameInvalid}}
                </mat-error>
                <mat-error data-control="nameError"
                    *ngIf="addSubDomainNameForm?.get('displayName')?.hasError('nameExists')"
                    class="domain-error-wrapper">
                    {{commonConstants?.errorMessage?.displayNameExists}}
                </mat-error>
            </mat-form-field><br />
            <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="10px" fxFlex="100">
                <button mat-stroked-button type="button" data-control="cancel" (click)="onClose()"
                    class="domain-button-font-weight letter-spacing-0">
                    Cancel
                </button>
                <button mat-raised-button data-control="save"
                    class="primary-button domain-button-font-weight letter-spacing-0"
                    (click)="saveChanges(addSubDomainNameForm?.get('displayName'))">
                    Save
                </button>
            </div>
        </form>
        <br />
    </mat-card>

</ng-template>