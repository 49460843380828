import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { UntypedFormArray, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { DialogService } from '../../../../../common/src/lib/services/dialog.service';
import { NgxMaterialTimepickerTheme } from 'ngx-material-timepicker';
import { Subscription } from 'rxjs';
import { CommonConstants } from '../../../../../shared/src/lib/constants/shared-constant';
import { CustomValidators } from '../custom-validators/custom-validators';
/**
 * CommonStartEndTimeComponent used to provide working hours details. 
 */
@Component({
  selector: 'phase-ii-common-start-end-time',
  templateUrl: './common-start-end-time.component.html',
  styleUrls: ['./common-start-end-time.component.scss']
})
/**
 * CommonStartEndTimeComponent used to provide working hours details. 
 */
export class CommonStartEndTimeComponent extends CommonConstants implements OnInit, OnChanges, OnDestroy {
  /**
   * isLocation used to decide which is called from store location.
   * @type {FormGroup}
   */
  @Input() isLocation: boolean;
  /**
   * timingDetailsForm used to declare the form controls.
   * @type {FormGroup}
   */
  timingDetailsForm: UntypedFormGroup;
  // /**
  //  * addressDetailsIndex which is used to store the address details index.
  //  * @type {any}
  //  */
  // @Input() addressDetailsIndex: any;
  /**
   * timeValue which is used to store the working hour details.
   * @type {any}
   */
  @Input() timeValue: any;
  /**
   * timeHeading which is used to store the heading of time section.
   * @type {any}
   */
  @Input() timeHeading: any;
  /**
   * workingHourDays which is used to store the working hour details.
   * @type {any}
   */
  @Input() workingHourDays: any;
  // /**
  //  * invalidFormValue which is used to store the invalidFormValue details.
  //  * @type {any}
  //  */
  // @Input() invalidFormValue: boolean;
  /**
   * readWriteRestriction which is used to store the readWriteRestriction.
   * @type { Boolean }
   */
  @Input() readWriteRestriction: boolean;
  /**
   * subscriptionObject used to store the subscribed datas.
   * @type {subscription}
   */
  subscriptionObj: Subscription = new Subscription();
  // commonConstant: CommonConstants;
  /**
  * Variable workingHoursDetails which is used to emit the method to the parent component while change add or change the time details.
  * @type {any}
  */
  @Output() workingHoursDetails = new EventEmitter<any>(null);
  /**
   * darkTheme used to apply style for the time picker clock.
   * @type {NgxMaterialTimepickerTheme}
   */
  darkTheme: NgxMaterialTimepickerTheme = {
    container: {
      bodyBackgroundColor: 'whitesmoke',
      buttonColor: '#005374'
    },
    dial: {
      dialBackgroundColor: '#005374',
    },
    clockFace: {
      clockFaceBackgroundColor: '#005374',
      clockHandColor: '#9fbd90',
      clockFaceTimeInactiveColor: '#fff'
    }
  };
  /**
   * Component constructor to inject the required services.
   */
  constructor(private dialogService: DialogService) {
    super();
  }
  /**
   * Component OnInit life cycle hook
   */
  ngOnInit(): void {
    // if (this.timeValue && this.timeValue.length) {
    //   this.addWorkingHours(this.timeValue);
    // }
    this.formInitial();
  }
  /**
   * Method ngOnChanges is called when changes occurs in this component when other components used this component as child component.
   * @param change is the change event.
   */
  ngOnChanges(change: SimpleChanges) {
    // if (this.workingHourDays) {
    //   this.days = this.workingHourDays;
    // }
    this.days = this.workingHourDays ? this.workingHourDays : this.days;
    // if (!(change.workingHourDays && change.workingHourDays.currentValue) ||
    //   (change.workingHourDays && change.workingHourDays.currentValue &&
    //     change.timeValue && change.timeValue.currentValue)) {
    //   // if (this.timeValue && this.timeValue.length) {
    //   // this.formInitial();
    // }

    // if (this.invalidFormValue) {
    //   this.timingDetailsForm.markAllAsTouched();
    //   this.timingDetailsForm.updateValueAndValidity();
    // }
  }
  /**
   * Method formInitial method used to initialize the form.
   * @param settingsData  is the ordersData.
   */
  formInitial() {
    this.timingDetailsForm = new UntypedFormGroup({
      id: new UntypedFormControl(null),
      workingHours: new UntypedFormArray([]),
    });
    // if (this.timeValue && this.timeValue.length) {
    if (this.isLocation || (this.timeValue && this.timeValue.length)) this.addWorkingHours(this.timeValue);
    // }
  }
  /**
   * Method getWorkingDays method used to fetch the selected  working days.
   * @param object is the object.
   * @returns 
   */
  getWorkingDays(object) {
    const workingDays = [];
    for (const [key, value] of Object.entries(object)) {
      if (value === true) {
        // console.log(key, value);
        workingDays.push(key);
      }
    }
    return workingDays;
  }
  /**
  * Method addWorkingHours is called when we click the add working hours button.
  * @param index is the index.
  * @param data is the data.
  */
  addWorkingHours(data?) {
    if (data && data.length) {
      data.forEach((element, index) => {
        ((this.timingDetailsForm.get("workingHours") as UntypedFormArray)).push(new UntypedFormGroup({
          // id: new FormControl(element.id ? element.id : null),
          days: new UntypedFormControl(this.getWorkingDays(element) ? this.getWorkingDays(element) : null, [Validators.required]),
          startTime: new UntypedFormControl(element.startTime ? element.startTime : null, [Validators.required]),
          endTime: new UntypedFormControl(element.endTime ? element.endTime : null, [Validators.required])
        }, CustomValidators.pickupTimeValidation.bind(this)));
        (this.timingDetailsForm.get("workingHours") as UntypedFormArray).controls[index].markAsDirty();
      });
      this.workingHoursDetails.emit({ value: this.timingDetailsForm.get("workingHours").value, valid: this.timingDetailsForm.get("workingHours").valid });
    } else {
      if ((this.timingDetailsForm.get("workingHours") as UntypedFormArray).length !== 0 &&
        (!this.timingDetailsForm.valid)) {
        const dialog = this.dialogService.dialogMethod(this.dialogMessages.fillPreviousSectionAlert,
          this.dialogType.alert);
        // dialog.afterClosed().subscribe((res: any) => {
        // if (res) {
        this.timingDetailsForm.markAllAsTouched();
        // }
        // })
      } else {
        (this.timingDetailsForm.get("workingHours") as UntypedFormArray).push(new UntypedFormGroup({
          // id: new FormControl(null),
          days: new UntypedFormControl(null, [Validators.required]),
          startTime: new UntypedFormControl(null, [Validators.required]),
          endTime: new UntypedFormControl(null, [Validators.required])
        }, CustomValidators.pickupTimeValidation.bind(this)));
        this.workingHoursDetails.emit({ value: null, dirty: true, valid: this.timingDetailsForm.get('workingHours').valid });
      }
    }
    if (!this.timingDetailsForm.get('workingHours').valid) {
      this.timingDetailsForm.markAllAsTouched();
    }
    // (this.timingDetailsForm.get("workingHours") as FormArray).push(new FormGroup({
    //   days: new FormControl(null, [Validators.required]),
    //   startTime: new FormControl(null, [Validators.required]),
    //   endTime: new FormControl(null, [Validators.required])
    // }, CustomValidators.pickupTimeValidation.bind(this)));
    this.subscriptionObj.add(this.timingDetailsForm.get("workingHours").valueChanges.subscribe((res: any) => {
      if (!this.timingDetailsForm.get('workingHours').valid) {
        this.timingDetailsForm.markAllAsTouched();
      }
      this.workingHoursDetails.emit({ value: res, dirty: true, valid: this.timingDetailsForm.get('workingHours').valid });
    }));
  }

  /**
  * Method removeWorkingHours used to remove a form array when click cancel button.
  * @param data is the working hours form data.
  * @param i is index.
  */
  removeWorkingHours(data: any, index: number) {
    // this.pageDetails.clicked = false;
    if (((this.timingDetailsForm.get("workingHours") as UntypedFormArray).length === 1) && this.isLocation) {
      this.dialogService.dialogMethod(this.dialogMessages.oneTimingRequired,
        this.dialogType.alert);
    } else
      (this.timingDetailsForm.get("workingHours") as UntypedFormArray).removeAt(index);
  }

  // selectDayChanged(event, index) {
  //   if (event.value && event.value.length) {
  //     // (this.timingDetailsForm.get("workingHours") as FormArray).controls[index].get('startTime').setValidators(Validators.required);
  //     // (this.timingDetailsForm.get("workingHours") as FormArray).updateValueAndValidity();
  //     // (this.timingDetailsForm.get("workingHours") as FormArray).controls[index].get('endTime').setValidators(Validators.required);
  //     // (this.timingDetailsForm.get("workingHours") as FormArray).updateValueAndValidity();

  //     (this.timingDetailsForm.get("workingHours") as FormArray).controls.find((item, i) => {
  //       if (index === i) {
  //         item.get('startTime').setValidators([Validators.required]);
  //         item.get('endTime').setValidators([Validators.required]);
  //         item.get('startTime').updateValueAndValidity();
  //         item.get('endTime').updateValueAndValidity();
  //         return true;
  //       }
  //     });
  //     this.timingDetailsForm.updateValueAndValidity();
  //   }
  // }
  /**
* Component OnDestroy life cycle hook.
* And unsubscribe all the subscriptions in the component.
*/
  ngOnDestroy(): void {
    this.subscriptionObj.unsubscribe();
  }
}
