import { Injectable } from '@angular/core';
import { AuthService } from 'libs/auth/src/lib/services/auth.service';
import { HttpRoutingService } from 'libs/common/src/lib/services/httpRouting.service';
import { of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class UsersService {

  constructor(private httpService: HttpRoutingService,
    private authService: AuthService) { }


  /**
   * Method which is used to get all manual tax
   * @param offset - current listing page number
   * @param limit - limit of records to be fetch
   * @param filterText  - if any searched text for zone name or tax name 
   * @param searchText - search text
   * @returns  - fetched user information
   */
  getAllUserDetails(data) {
    return this.httpService.getMethod('affiliateuser', data);
  }


  /**
  * Method which is used to create user details.
  * @param data has request data.
  * @returns response.
  */
  createUser(data) {
    const key = 'registration';
    this.authService.getBase64String(data.registration.email, data.registration.password, 'affiliateuser', key);
    delete data.registration;
    return this.httpService.postMethod('affiliateuser', data);
  }

  /**
 * Method which is used to fetch one user details.
 * @param userId id of unique user
 * @returns fetched user data
 */
  getOneUserDetails(data) {
    const url = this.httpService.replaceUrlParam('affiliateuser/{id}', { id: data });
    return this.httpService.getMethod(url);
  }


  /**
  * Method which is used to update user details 
  * @param data - required update user detail information
  * @param userId  - user corresponding usrId
  * @returns - if process done return success message else return error
  */
  updateUserDetails(data, id) {
    const url = this.httpService.replaceUrlParam('affiliateuser/{id}', { id: id });
    return this.httpService.putMethod(url, data);
  }
  updateUserDetailStatus(data, id) {
    const url = this.httpService.replaceUrlParam('affiliateuser/status/{id}', { id: id });
    return this.httpService.putMethod(url, data);
  }

  /**
  * Method which is used get user account details
  * @param userId - corresponding user account details to fetch the records 
  * @returns - success response or error response
  */
  getAccountDetails(id, data) {
    const url = this.httpService.replaceUrlParam('accountDetails/active/{id}', { id: id });
    return this.httpService.getMethod(url, data);
  }


  /**
  * Method which is used get user account details
  * @param userId - corresponding user customer details to fetch the records 
  * @returns - success response or error response
  */
  getCustomerDetails(id) {
    const url = this.httpService.replaceUrlParam('affiliateuser/customer/{id}', { id: id });
    return this.httpService.getMethod(url);
  }

  checkMailAlreadyExist(email: string) {
    return this.httpService.getMethod('affiliateuser/mail', { email: email }).pipe(map((res) => {
      if (res['mailCheck']) {
        res['emailExists'] = true;
      }
      return res;
    }),
      catchError((err) => {
        return of({ error: true })
      }))
  }


  /**
* Method which is used get suspend or resume the user
* @param id - corresponding user  details to fetch the records
* @returns - success response or error response
*/
  getSuspendResumeUser(data, id) {
    const url = this.httpService.replaceUrlParam('affiliateuser/suspend/{id}', { id: id });
    return this.httpService.putMethod(url, data);
  }
}
