import { getMessaging, getToken, onMessage } from "firebase/messaging";
import { SharedConstant } from '@phase-ii/common';
import { DialogService } from 'libs/common/src/lib/services/dialog.service';
import { CommonService } from 'libs/shared/src/lib/services/common.service';
import { DeviceDetectorService } from 'ngx-device-detector';
import { BehaviorSubject, of } from 'rxjs'
import { AuthConstants } from '../constant/auth-constant';
import { mergeMap } from 'rxjs/operators';
import { AuthService } from './auth.service';
import { Injectable, Inject } from '@angular/core';

/**
 * Class service which is used to request, receive permission and add token for push notification.
 */
@Injectable({
    providedIn: 'root'
})
export class MessagingService extends AuthConstants {
    /**
    * Variable which is used to store payload data
    */
    currentMessage = new BehaviorSubject(null);
    /**
   * Variable which is used to store common constant
   */
    commonConstant: AuthConstants;
    /**
 * Variable which is used to store shared constant
 */
    sharedConstant: SharedConstant;
    /**
* Variable which is used to access environment data
 */
    environment: any;

    /**
     * service constructor which is used to inject the required services.
     * @param commonService  To access methods inside CommonService.
     * @param dialogService  To access methods inside DialogService.
     * @param deviceService  To access methods inside DeviceDetectorService.
     */
    constructor( private authService: AuthService, private commonService: CommonService, private dialogService: DialogService, private deviceService: DeviceDetectorService, @Inject('environment') environment) {
        super();
        this.commonConstant = new AuthConstants();
        this.sharedConstant = new SharedConstant();
        this.environment = environment;
    }
    /**
    * This method is used to request the browser permission whether notification is allowed or not.
    * @returns permission request
    */
    requestPermission(currentUser) {
        let roleCode;
        // Get the current FCM token
        // getToken(this._messaging)
        // .then((token) => {
        //   console.log('Token', token);
        //   // You can send this token to your server and store it there
        //   // You can also use this token to subscribe to topics
        // })
        // .catch((error) => console.log('Token error', error));
        let browser = this.deviceService && this.deviceService.browser ? (this.deviceService.browser).toLowerCase() : null;
        if ((currentUser) && (browser && browser !== (this.commonConstant && this.commonConstant.safari))) {
            let storeId = currentUser.storeId && currentUser.storeId !== null ? currentUser.storeId : this.sharedConstant?.message?.default;
            if (currentUser.roleCode) {
                this.commonService.getRoleName(storeId, { code: currentUser.roleCode }).pipe(mergeMap((res: any) => {
                    if (res && res['result'] && res['result'].code) {
                        roleCode = res['result'].code;
                        const messaging = getMessaging();
                        return getToken(messaging,
                            { vapidKey: this.environment.firebase.vapidKey });
                    } else {
                        return of(null);
                    }
                }), mergeMap((token: any) => {
                    if (token && token !== null && roleCode) {
                        let isSuperAdmin = false, isStore = false, isUser = false;
                        if (roleCode === this.commonConstant.RoleId.superAdmin) {
                            isSuperAdmin = true;
                        } else if (roleCode === this.commonConstant.RoleId.customer) {
                            isUser = true;
                        } else {
                            isStore = true;
                        }
                        const isMobileDevice = this.deviceService.isMobile();
                        const isDesktopDevice = this.deviceService.isDesktop();
                        let data = {
                            token: token,
                            userId: currentUser.userId,
                            storeId: currentUser.storeId,
                            isStore: isStore,
                            isUser: isUser,
                            isSuperAdmin: isSuperAdmin,
                            isWeb: isDesktopDevice,
                            isMobile: isMobileDevice
                        }
                        return this.authService.addToken(storeId, data);
                    }
                    else {
                        return of(null);
                    }
                })).subscribe((res: any) => {
                    if (res) {
                    }
                });
            }
        }
    }
    /**
    * This method is used to receive the browser permission whether notification is allowed or not.
    * @returns permission received
    */
    receiveMessage() {
        const messaging = getMessaging();
        onMessage(messaging, (payload) => {
            // console.log('Notification received. ', payload);
            this.dialogService.openSnackBar('New notification received');
            this.currentMessage.next(payload);
        });
    }
}
