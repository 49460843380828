/**
 * constant which serves api url and other common variables for production
 */
export const environment = {
  production: true,
  app: "us-uat",
  apiUrlDb: '',
  secretKey: '',
  RegionUrl: '',
  countryCode: "",
  backendServerConfigUrl: 'https://uat.useast1.api.getzenbasket.com/checkdomain-uat/v1/config',
  rollbarKey : "60de7f11d6a34b2d9cdeebf7414d9819"
};