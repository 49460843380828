import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReferralCodeListComponent } from './components/referral-code-list/referral-code-list.component';
import { SharedModule } from '@phase-ii/shared';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ReferralCustomerListComponent } from './components/referral-customer-list//referral-customer-list.component';
import { ReferralCommissionListComponent } from './components/referral-commission-list/referral-commission-list.component';
import { CommonAdminModuleModule } from '@phase-ii/common-admin-module';

@NgModule({
  declarations: [
    ReferralCodeListComponent,
    ReferralCustomerListComponent,
    ReferralCommissionListComponent,
  ],
  imports: [CommonModule, SharedModule, BrowserAnimationsModule,CommonAdminModuleModule],
})
export class ReferralCodeModule {}
