<cen-loader-vii *ngIf="pageDetails?.isLoader || pageDetails?.listLoader"></cen-loader-vii>
<phase-ii-header-page [heading]="pageDetails?.heading" [buttonInfo]="pageDetails?.buttonInfo"
  (methodClicked)="buttonAction($event)"></phase-ii-header-page>

<div class="page-container" fxLayout="row" fxLayoutGap="10px" fxLayout.xs="column" *ngIf="!pageDetails?.isLoader ">
  <div *ngIf="pageDetails?.isFilter" fxLayout="column" class="filter">
    <phase-ii-filter [filters]="pageDetails?.referralCodeListFilter" (filterClosed)="closeFilter()"
      (filterPredicate)="onFilterSelected($event)"></phase-ii-filter>
  </div>
  <mat-card fxFlex="100%">
    <phase-ii-common-card-v-ii [paginatorVersion]="2" [needPaginator]="true" [actions]="pageDetails?.actions"
      [actionsIcon]="pageDetails?.actionIcons" (searchedText)="search($event)" [listProperties]="pageDetails?.listProperty"
      [columnsRef]="pageDetails?.columnsRef" (actionClicked)="listAction($event)" (iconClicked)="listAction($event)" (valueChanged)="getChangedData($event)"
      (toggleClicked)="toggleChanged($event)" [toggleFailed]="toggleFailed">
    </phase-ii-common-card-v-ii>
  </mat-card>
</div>

<!--dialog box -->
<ng-template #addeditReferral class="whole-dialog-container">
  <h5 mat-dialog-title>
    Create Referral Code
    <mat-icon data-control="closeIcon" class="close-icon icon-color"
      (click)="onClose()">close</mat-icon>
  </h5>
  <div mat-dialog-content class="dialog-content-container">
    <form [formGroup]="referralForm">
      <div fxLayout="column" fxLayoutGap="3%" class="field-content">
        <mat-form-field fxFlex="100%" appearance="outline">
          <mat-label>Referral code</mat-label>
          <input data-control="referralCode" formControlName="referralCode"
            matInput trimVal required />
          <mat-icon class="icon-color" matSuffix (click)="generateReferralCode(6)" data-control="generateCodeIcon"
            matTooltip="Click here to generate the referral code">sync</mat-icon>
          <mat-error data-control="referralCodeReq" *ngIf="referralForm?.get('referralCode')?.hasError('required')">
            {{referralCodeConstant?.errorMessages?.REFERRAL_CODE_REQUIRED}}
          </mat-error>
          <mat-error data-control="referralCodeMaxLimit"
            *ngIf="referralForm?.get('referralCode')?.hasError('maxlength')">
            {{referralCodeConstant?.errorMessages?.REFERRAL_CODE_MAX_LIMIT}}
          </mat-error>
          <mat-error data-control="referralCodeMinLimit"
            *ngIf="referralForm?.get('referralCode')?.hasError('minlength')">
            {{referralCodeConstant?.errorMessages?.REFERRAL_CODE_MIN_LIMIT}}
          </mat-error>
          <mat-error data-control="referralCodeAlreadyExist"
            *ngIf="referralForm?.get('referralCode')?.hasError('alreadyExists')">
            {{referralCodeConstant?.errorMessages?.REFERRAL_CODE_ALREADYEXISTS}}
          </mat-error>
        </mat-form-field>
        <div class="formstatus" *ngIf="referralForm?.pending && (referralForm?.dirty || referralForm?.touched)"
          fxLayoutAlign="center center">
          <span> Processing referral code
            <em class="fas fa-circle-notch fa-spin"></em></span>
        </div>
        <div fxFlex="100%" fxLayout="row">
          <mat-slide-toggle formControlName="isActive" data-control="isActive">
            Active
          </mat-slide-toggle>
        </div>
      </div>
    </form>
  </div>
  <div class="mat-dialog-action-inside-form" mat-dialog-actions fxLayoutAlign="end end" fxLayoutGap="10px">
    <button mat-raised-button class='secondary-button' (click)="onClose()" data-control="cancel">
      Cancel
    </button>
    <button  data-control="okay"
      [ngClass]="{'primary-button':!(referralForm?.pristine || referralForm?.invalid || referralForm?.pending),'disabled-button':(referralForm?.pristine || referralForm?.invalid || referralForm?.pending)}"
      mat-raised-button (click)="onSaveReferral()"
      [disabled]="referralForm?.pristine || referralForm?.invalid || referralForm?.pending"
      class="saveButton">
      <div *ngIf="!isButtonLoader">
        {{isUpdate ? 'Update' :'Save'}}
      </div>
      <cen-loader *ngIf="isButtonLoader" [type]="'content'" [name]="'cen-dot-spinner'" 
        fxLayoutAlign="center center" class="loader">
      </cen-loader>
    </button>
  </div>
</ng-template>

