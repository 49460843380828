import { Inject, Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { API, HttpRoutingService } from '@phase-ii/common';
import { Subscription } from 'rxjs';
import { catchError, filter } from 'rxjs/operators';

/**
 * service which is used to load translation dynamically
 * Author Mahanagalakshmi
 */
@Injectable({
  providedIn: 'root'
})
/**
 * service which is used to load translation dynamically
 */
export class DynamicTranslationService {
  environment: any;
  /**
   * Stores the store language code
   */
  languageCode: any;
  /**
   * Variable which used to define the subscription value
   * @type {subscription}
   */
  subscriptionObj = new Subscription();
  /**
   * Variable that stores the store language list
   */
  languageArray: any;
  /**
   * Variable that is used for navigation
   */
  isNavigateWithLangCode: boolean;
  /**
   * Variable which is used to store the reload function value
   * @type {any{}}
   */
  private reloadMethod: { [key: string]: () => void } = {};
  /**
   * service constructor which is used to load translation file
   * @param http 
   * @param sharedService 
   * @param translate 
   */
  constructor(@Inject('environment') environment, private httpRoutingService: HttpRoutingService, private router: Router) {
    this.environment = environment;
    this.subscriptionObj.add(this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe(() => {
      this.executeReloadFunction();
    }));
  }
  /**
   * Method which is used Register a custom reload function for a specific component
   * @param key compoent name as key
   * @param reloadFunction functionname which is used to reload the content
   */
  registerReloadFunction(key: string, reloadFunction: () => void): void {
    this.reloadMethod = {};
    this.reloadMethod[key] = reloadFunction;
  }
  /**
   * Method which is used Execute all registered custom reload functions
   */
  private executeReloadFunction(): void {
    Object.values(this.reloadMethod).forEach(reloadFunction => reloadFunction());
  }
  /**
   * Method which is used to load multiple translation file 
   * @param files filepath
   */
  multiLoadTranslation(files: string[]) {
    let lang = localStorage.getItem('language') ? localStorage.getItem('language') : 'en';
    return this.loadTranslations(files, lang).then((res) => {
      if (res) {
        let translteArray = files.reduce((acc, file, index) => {
          acc[file] = res[index];
          return acc;
        }, {});
        // this.translate.setTranslation(lang, translteArray);
        // this.translate.use(lang);
        return translteArray;
      }
    })
  }
  /**
   * Method which is used load translations
   * @param files filepath
   * @param lang language
   * @returns promise as translations
   */
  private loadTranslations(files: string[], lang: string): Promise<any> {
    if (files && files.length) {
      const promises = files.map(file => this.loadTranslationFile(file, lang));
      return Promise.all(promises);
    }
  }
  /**
   * Method which is used to load translations
   * @param file filepath
   * @param lang language
   * @returns promise as translation
   */
  private loadTranslationFile(file: string, lang): Promise<any> {
    const translationPath = './assets/i18n/' + file + '/' + lang + '.json';
    // const translationPath =  this.environment.AWSImageUrl + file + '/' + lang + '.json';
    return new Promise((resolve, reject) => {
      this.httpRoutingService.getJsonData(translationPath).pipe(
        catchError(() => {
          const retryApiPath = './assets/i18n/' + file + '/en.json';
          // const retryApiPath = this.environment.AWSImageUrl + file + '/en.json';
          return this.httpRoutingService.getJsonData(retryApiPath);
        })
      ).subscribe(
        translations => {
          resolve(translations); // Resolve the promise when translations are loaded
        },
        error => {
          console.error('Error loading translations', error);
          reject(error); // Reject the promise if there's an error loading translations
        }
      );
    });
  }
  /**
   * Method that is used get the all langauges based on the store ID for storefront
   * @param storeId it holds the store id
   * @returns it returns the language code and some other properties 
   */
  getAllStoreLanguage(storeId) {
    const url = this.httpRoutingService.replaceUrlParam(API.GET_STORELANGUAGE, { storeId: storeId });
    return this.httpRoutingService.getMethod(url);
  }
  /**
   * Method that is used to get the all langauges For storefront
   * @param storeId it holds the store ID
   * @returns it returns the all the language code and language name
   */
  getAllLanguage(storeId) {
    const url = this.httpRoutingService.replaceUrlParam(API.GET_LANGUAGE, { storeId: storeId });
    return this.httpRoutingService.getMethod(url);
  }
}
