import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BulkOperationService } from '../../services/bulk-operation.service';
import { CommonDataService } from '../../services/common-data.service';
import { CommonConstants } from '../../constants/shared-constant';
import { Subscription } from 'rxjs';

@Component({
  selector: 'phase-ii-export',
  templateUrl: './export.component.html',
  styleUrls: ['./export.component.scss']
})
export class ExportComponent extends CommonConstants implements OnInit, OnDestroy {
  /**
   * Array which is used to store all the Subscriptions
  */
  subscriptionObj: Subscription = new Subscription();
  /**
  * Variable which is used to store the exportOption values.
  */
  exportOption = [];
  /**
  * Variable which is used to store the exportType values.
  */
  exportType = new UntypedFormControl();
  /**
  * Variable which is used to store the contentData values.
  */
  contentData: { [key: string]: any };
  /**
  * Variable which is used to store the exportData values.
  */
  exportData: any;
  /**
  * Variable which is used to store the loading values.
  */
  loading: boolean;
  /**
  * Variable which is used to store the selectedData values.
  */
  selectedData = false;
  /**
* Variable which is used to store the export order details.
*/
  orders = false;
  /**
  * Variable which is used to store the filterData values.
  */
  filterData = false;
  constructor(
    private bulkOperationService: BulkOperationService,
    public dialogRef: MatDialogRef<ExportComponent>, @Inject(MAT_DIALOG_DATA) public data,
    private commonDataService: CommonDataService) {
    super();
  }
  /**
   * Angular life cyle hook that initiates the component.
   */
  ngOnInit(): void {
    if (this.contentData) {
      this.exportOption = this.exportOptions;
      this.selectedData = this.contentData && this.contentData.selectedData && this.contentData.selectedData.length > 0 ? true : false;
      this.filterData = this.contentData && (this.contentData.filterData || this.contentData.categoryId || this.contentData.searchData || this.contentData.productAvailability == true || this.contentData.productAvailability == false || this.contentData.stock == 0 || this.contentData.stock == 1) ? true : false;
      this.orders = this.contentData && this.contentData.title == 'Export Orders' ? true : false;
    }
    if (this.orders) {
      this.exportOptions.splice(3, 1);
      this.exportOption = this.exportOptions;
    }
    if (!this.selectedData && !this.filterData) {
      this.exportOption = this.exportOptions.slice(2);
    } else if (this.selectedData && !this.filterData) {
      this.exportOptions.splice(1, 1);
      this.exportOption = this.exportOptions;
    } else if (!this.selectedData && this.filterData) {
      this.exportOption = this.exportOptions.slice(1);
    }
    else if (this.selectedData && this.filterData) {
      this.exportOption = this.exportOption;
    } 
    else if (this.contentData.expansion) {
      this.exportOption = this.exportOptions.slice(3);
    }
  }
  /**
   * Method used to export to data.
   */
  exportFile() {
    if (this.contentData.exportApiUrl && this.exportType.value) {
      this.loading = true;
      const exportData = this.contentData && this.contentData.exportData ? this.contentData.exportData : null
      switch (this.exportType.value) {
        case 1:
          this.exportData = {
            selectValue: this.contentData.selectedData,
            type: this.contentData.type,
            description: 'Exported only selected data',
            exportData: exportData,
            isEmailMarketing:this.contentData.isEmailMarketing==true?true:false
          };
          break;
        case 2:
          this.exportData = {
            type: this.contentData.type,
            filterValue: this.contentData.filterData,
            searchData: this.contentData.searchData,
            sortOrder: this.contentData.sortOrder,
            categoryId: this.contentData.categoryId,
            locationId: this.contentData.locationId,
            productAvailability: this.contentData.productAvailability ? this.contentData.productAvailability : this.contentData.productAvailability == false ? false : null,
            stock: this.contentData.stock ? this.contentData.stock : this.contentData.stock == 0 ? 0 : null,
            description: 'Exported only filtered data',
            exportData: exportData,
            isEmailMarketing:this.contentData.isEmailMarketing==true?true:false
          };
          break;
        case 3:
          this.exportData = {
            limit: this.contentData.limit,
            offset: this.contentData.offset,
            sortOrder: this.contentData.sortOrder,
            filterValue: this.contentData.filterData,
            searchData: this.contentData.searchData,
            categoryId: this.contentData.categoryId,
            locationId: this.contentData.locationId,
            productAvailability: this.contentData.productAvailability ? this.contentData.productAvailability : this.contentData.productAvailability == false ? false : null,
            stock: this.contentData.stock ? this.contentData.stock : this.contentData.stock == 0 ? 0 : null,
            type: this.contentData.type,
            description: 'Exported all the data in current page',
            exportData: exportData,
            isEmailMarketing:this.contentData.isEmailMarketing==true?true:false
          };
          break;
        case 4:
          this.exportData = {
            type: this.contentData.type,
            locationId: this.contentData.locationId,
            description: 'Exported all the data',
            exportData: exportData,
            isEmailMarketing:this.contentData.isEmailMarketing==true?true:false
          };
          break;
        default:
          this.exportData = {};
          break;
      }
      this.subscriptionObj.add(
        this.bulkOperationService
          .exportData(this.contentData.exportApiUrl, this.exportData)
          .subscribe((res: any) => {
            if (res && res['data']) {
              const file = new Blob([this.commonDataService.changeBuffer(res['data'])], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64," });
              const fileURL = URL.createObjectURL(file);
              this.commonDataService.createXLSX(fileURL, this.contentData.fileName ? this.contentData.fileName : '');
              this.dialogRef.close(true);
            }
            this.loading = false;
          },
            (err) => {
              this.loading = false;
              this.dialogRef.close(true);
              console.log('Error occured while exporting file ', err);
            }));
    }
  }
  /**
   * Component ondestroy life cycle hook.
   * All subscriptions are unsubscribe here.
   */
  ngOnDestroy() {
    if (this.subscriptionObj) {
      this.subscriptionObj.unsubscribe();
    }
  }
}
