<div fxLayout="row" fxLayoutAlign="space-between center">
    <div>
        <p class="imageHeading">Template</p>
        <p class="note" *ngIf="isSuperAdmin">Note:Please fill the details in both tabs</p>
        <p class="note" *ngIf="!isSuperAdmin"> To view the values in {{defaultMeasurement}} </p>
        <mat-hint class="note" *ngIf="displayBothSystem && !isSuperAdmin"><strong>Note:</strong> Size chart values must
            be whole
            or
            decimal
            numbers for auto
            conversion. Values with special symbols or characters won't be converted.
        </mat-hint>
        <br>
    </div>
    <div>
        <button *ngIf="displayBothSystem" class="view" data-control="view" mat-stroked-button
            (click)="viewMeasuremet()">
            View in {{defaultMeasurement1}}
        </button>
    </div>
</div>
<br>
<div fxLayout="row">

    <mat-tab-group fxFlex="100" mat-stretch-tabs class="mat-elevation-z4" (selectedTabChange)="onTabChanged($event);">
        <mat-tab
            *ngIf="!displayBothSystem && (defaultMeasurementSystem =='Metric (Centimeters)'|| defaultMeasurementSystem =='Imperial (Inches)')||displayBothSystem ||isSuperAdmin"
            data-control="Inches" label={{defaultMeasurement}} fxFlex="50">
            <ng-container *ngTemplateOutlet="sizeChartTemplate">
            </ng-container>
        </mat-tab>
        <mat-tab *ngIf="isSuperAdmin" data-control="Centimeters" label={{defaultMeasurement1}} fxFlex="50">
            <ng-container *ngTemplateOutlet="sizeChartTemplate">
            </ng-container>
        </mat-tab>
    </mat-tab-group>
</div>
<ng-template #sizeChartTemplate>
    <ng-container>
        <form [formGroup]="chartForm" [ngClass]="{'no-events':isReadOnly}">
            <div formArrayName={{tabName}} class="page-container" *ngIf="chartForm?.get(tabName)?.controls"
                fxLayout="column">
                <div fxLayout="row" fxFlex="100%" fxLayoutGap="5px" fxLayout.lt-md="column" fxLayoutGap.lt-md="10px"
                    class="flow">
                    <div *ngFor="let sizes of chartForm?.get(tabName)?.controls; let i = index">
                        <div [formGroupName]="i">
                            <mat-form-field appearance="outline" class="title">
                                <input [readonly]="isReadOnly" matInput placeholder="Title" formControlName="title"
                                    data-control="title" required trimVal />
                                <mat-error *ngIf="chartForm?.get(tabName)?.at(i)?.controls?.title?.errors?.required"
                                    data-control="titleRequired">
                                    Title is required.
                                </mat-error>
                                <mat-error *ngIf="chartForm?.get(tabName)?.at(i)?.controls?.title?.errors?.maxlength"
                                    data-control="titleMaximum">
                                    Title exceeds maximum length.
                                </mat-error>
                            </mat-form-field>

                            <mat-icon *ngIf="chartForm?.get(tabName)?.controls.length - 1 === i && !isReadOnly"
                                (click)="deleteTitle(i,ind)" class="deleteIcon" matTooltip="Delete Title"
                                data-control="deleteTitle">delete</mat-icon>
                            <mat-icon *ngIf="chartForm?.get(tabName)?.controls.length - 1 === i && !isReadOnly"
                                (click)="Inches(null,ind)" class="addIcon" matTooltip="Add Title"
                                data-control="addTitle">add</mat-icon>

                            <ng-container>
                                <div formArrayName="measurements">
                                    <div>
                                        <div
                                            *ngFor="let measure of sizes?.get('measurements')?.controls; let ind = index">
                                            <mat-form-field appearance="outline" class="value">
                                                <input [readonly]="isReadOnly" matInput placeholder="Value"
                                                    [formControlName]="ind" data-control="Value" required trimVal />

                                                <mat-error
                                                    *ngIf="sizes?.get('measurements')?.controls[ind].errors?.required"
                                                    data-control="valueRequired">
                                                    Value is required.
                                                </mat-error>
                                                <mat-error
                                                    *ngIf="sizes?.get('measurements')?.controls[ind].errors?.maxlength"
                                                    data-control="valuemaximum">
                                                    Value exceeds maximum length.
                                                </mat-error>
                                            </mat-form-field>

                                            <mat-icon
                                                *ngIf="(sizes?.get('measurements')?.controls.length - 1 === ind)&&(chartForm?.get(tabName)?.controls.length - 1 === i) && !isReadOnly"
                                                class="deleteIcon" (click)="deleteNewValue(i,ind)"
                                                matTooltip="Delete Value" data-control="deleteValue">delete</mat-icon>
                                            <mat-icon
                                                *ngIf="(sizes?.get('measurements')?.controls.length - 1 === ind)&&(chartForm?.get(tabName)?.controls.length - 1 === i) && !isReadOnly"
                                                (click)="addNewValue(null,null,null,null,null,'isHtml')" class="addIcon"
                                                matTooltip="Add Value" data-control="addValue">add</mat-icon>
                                        </div>
                                    </div>
                                </div>
                            </ng-container>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </ng-container>
</ng-template>
<!--  MeasurementDialog dialog box -->
<ng-template #measurementDialog>
    <ng-container>
        <h5 mat-dialog-title>
            {{defaultMeasurement1}}
            <mat-icon class='closeIcon' mat-dialog-close data-control="closeIcon" (click)="onCancel()">close</mat-icon>
        </h5>
        <div class="categoryContainer">
            <form [formGroup]="chartForm" [ngClass]="{'no-events':isReadOnly}">
                <div formArrayName={{defaultMeasurement1}} class="page-container"
                    *ngIf="chartForm?.get(defaultMeasurement1)?.controls" fxLayout="column">
                    <div fxLayout="row" fxFlex="100%" fxLayoutGap="5px" fxLayout.lt-md="column" fxLayoutGap.lt-md="10px"
                        class="flow">
                        <div *ngFor="let sizes of chartForm?.get(defaultMeasurement1)?.controls; let i = index">
                            <div [formGroupName]="i">
                                <mat-form-field appearance="outline" class="title">
                                    <input matInput placeholder="Title" formControlName="title" data-control="title"
                                        required trimVal readonly />
                                </mat-form-field>
                                <ng-container>
                                    <div formArrayName="measurements">
                                        <div>
                                            <div
                                                *ngFor="let measure of sizes?.get('measurements')?.controls; let ind = index">
                                                <mat-form-field appearance="outline" class="value">
                                                    <input matInput placeholder="Value" readonly [formControlName]="ind"
                                                        data-control="Value" required trimVal />
                                                </mat-form-field>
                                            </div>
                                        </div>
                                    </div>
                                </ng-container>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </ng-container>
</ng-template>