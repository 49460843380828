import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PayoutRequestListComponent } from './components/payout-request-list/payout-request-list.component';
import { SharedModule } from '@phase-ii/shared';
import { CommonAdminModuleModule } from '@phase-ii/common-admin-module';

@NgModule({
  declarations: [PayoutRequestListComponent],
  imports: [CommonModule,SharedModule,CommonAdminModuleModule],
})
export class PayoutRequestModule {}
