<div fxLayout="column">
  <mat-card class="coupon" fxLayoutAlign="center center" fxLayout="column">
    <div fxLayout="row">
      <h3 class="coupon-title">{{couponCodeObject?.title | titlecase}}</h3>
      <mat-icon class="close" data-control="redeemClose" (click)="onNoClick()" matTooltip="Close">close</mat-icon>
    </div>
    <ng-container *ngIf="couponCodeObject?.title !=='Claim Offer' || !couponCodeObject?.title">
      <mat-card-content fxLayoutAlign="center center" fxLayoutGap="2%">
        <div class="coupon-container" fxFlex.lt-sm="100%" fxLayoutAlign="center center">
          <span class="scissors">✂</span>
          <input class="coupon-code" data-control="couponCodeInput" [formControl]="couponCode" mat-input
            [placeholder]="'REDEEM_COUPON.ENTER_CODE' | translate">
        </div>
      </mat-card-content>
      <button mat-raised-button class="active-redeem" [disabled]="(!couponCode.valid)" data-control="redeemButton"
        (click)="submit()" [ngClass]="!couponCode.valid ? 'disable' : 'active-button'">{{
        'REDEEM_COUPON.REDEEM_COUPON' | translate }}</button>
    </ng-container>
    <ng-container *ngIf="couponCodeObject?.title ==='Claim Offer'">
      <mat-card-content fxLayoutAlign="center center" fxLayoutGap="2%">
        <div class="coupon-container" fxFlex="65%" fxFlex.lt-sm="100%" fxLayoutAlign="center center">
          <span class="scissors">✂</span>
          <input class="coupon-code" data-control="couponCodeInput" [formControl]="couponCode" mat-input
            placeholder="OFFER" readonly>
        </div>
      </mat-card-content>
      <button mat-raised-button class="active-redeem" [disabled]="(!couponCode.valid)" data-control="redeemButton"
        (click)="submit()" [ngClass]="!couponCode.valid ? 'disable' : 'active-button'">{{
        'REDEEM_COUPON.REDEEM_COUPON' | translate }}</button>
    </ng-container>
  </mat-card>
  <div fxLayout="column" class="coupons-card" [ngClass]="couponCodeObject?.coupons?.length>0 ? 'dialog-height' : ''">
    <h3 class="coupon-offer" *ngIf="couponCodeObject?.coupons?.length>0">{{ 'REDEEM_COUPON.AVAILABLE_OFFERS' | translate
      }}
    </h3>
    <div *ngIf="couponCodeObject?.coupons?.length<=0" fxLayout="column" fxLayoutAlign="center center">
      <img src="./assets/coupons.png" alt="" class="no-offer-image">
      <h1 fxLayout="row" fxLayoutAlign="center center" class="no-offer-heading">
        {{ 'REDEEM_COUPON.NO_COUPON_HEADER' | translate }}</h1>
      <p fxLayout="row" fxLayoutAlign="center center" class="no-offer-paragraph">
        {{ 'REDEEM_COUPON.NO_COUPON_DESCRIPTION' | translate }}</p>
    </div>
    <mat-card *ngFor="let coupon of couponCodeObject?.coupons;let i=index" (click)=" selectCouponCode(coupon, i)"
      data-control="offerCard" [ngClass]="(index === i) ? 'selected-redeem-coupon-card' : 'redeem-coupon-card'"
      fxLayout="row">
      <div fxFlex="20" fxLayoutAlign="center center">
        <mat-icon [ngClass.lt-md]="'icon-style'" class="icon-static-style" inline="true">
          local_offer</mat-icon>
      </div>
      <div fxFlex="80" [ngClass]="(coupon?.description || coupon?.title) ? 'promocode' : 'flex'">
        <div fxLayout="row" fxLayoutAlign="space-between center" class="full-width" fxLayoutGap="10px">
          <h4 class="text-wrap" fxLayoutAlign=" center">{{coupon?.promoCode}} </h4>
          <div class="discount-div" fxLayoutAlign="end end"
            *ngIf="couponCodeObject?.title !=='Claim Offer' || !couponCodeObject?.title">
            <p class="discount-paragraph"
              *ngIf="coupon?.discountType?.discountTypeCode === 'PERCENTAGE' && coupon?.maxAmount">
              {{ getTranslationContent('REDEEM_COUPON.PERCENTAGE_WITH_MAX_AMOUNT', [coupon?.discountValue?.toString()?.split('.')[0],
              coupon?.maxAmount | currency: couponCodeObject?.currencyCode]) }}
            </p>
            <p class="discount-paragraph" *ngIf="coupon?.discountType?.discountTypeCode === 'PERCENTAGE' && !coupon?.maxAmount">
              {{ getTranslationContent('REDEEM_COUPON.PERCENTAGE_WITHOUT_MAX_AMOUNT',
              [coupon?.discountValue?.toString()?.split('.')[0]]) }}
            </p>
            <p class="discount-paragraph" *ngIf="coupon?.discountType?.discountTypeCode === 'FIXED'">
              {{ getTranslationContent('REDEEM_COUPON.FIXED',
              [(coupon?.discountValue) | currency: couponCodeObject?.currencyCode]) }}
            </p>
          </div>
        </div>
        <p class="paragraph-style">{{coupon?.description ? coupon?.description : coupon?.title}} </p>
      </div>
    </mat-card>
    <br>
  </div>
</div>

<ng-template #categoryLevelProducts>
  <!-- Dialog Header -->
  <p mat-dialog-title>
    <span class="discount-title">Products</span>
    <mat-icon class="close-icons" matDialogClose>close</mat-icon>
  </p>
  <!-- Dialog Body -->
  <div mat-dialog-content class="discount-content">
    <div *ngIf="categoryProducts?.length">
      <mat-radio-group aria-labelledby="example-radio-group-label" class="radio-group" [formControl]="productId">
        <mat-radio-button class="radio-button" *ngFor="let item of categoryProducts" [value]="item">
          <div *ngIf="item?.variantName">
            <span [ngClass]="{'nextLine':couponCodeObject.singleOrder}" class="courier-name">{{item?.product?.name}} -
              {{item?.variantName}} -
              {{(item?.price)|currency :
              couponCodeObject?.currency}}</span>
          </div>
          <div *ngIf="!item?.variantName">
            <span class="courier-name">{{item?.product?.name}} - {{(item?.price)|currency : couponCodeObject?.currency}}
            </span>
          </div>
        </mat-radio-button>
      </mat-radio-group>
    </div>
    <div fxLayoutAlign="center center" *ngIf="!categoryProducts?.length" class="warning">
      <strong>No products found</strong>
    </div>

  </div>
  <!-- Dialog Button -->
  <div class="bottom-padding" *ngIf="categoryProducts?.length" fxLayout="row" fxLayoutAlign="center center"
    fxLayoutGap="20px">
    <div><button [disabled]="productId?.value==null" mat-raised-button color="primary" data-control="cancelBtn"
        matDialogClose (click)="ChosenCategoryProduct()">Okay</button></div>
    <!-- <div><button mat-raised-button color="primary" data-control="updateBtn" (click)="ChosenCategoryProduct()">
        <span>Save</span>
      </button>
    </div> -->
  </div>
</ng-template>