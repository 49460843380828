import { Component, ElementRef, EventEmitter, HostBinding, HostListener, Input, Output, Renderer2 } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { LiveNotificationRoute, LiveSaleData, LiveSaleNotificationDetails, LiveSaleOrderList, OrderDetails } from '../../models/live-notification.model';
import { SharedService } from '@phase-ii/shared-theme';
import { CommonService, FILE_EXTENSION } from '@phase-ii/shared';
import { LoadTranslationService } from '@phase-ii/common';


@Component({
  selector: 'phase-ii-display-live-sale-notification',
  templateUrl: './display-live-sale-notification.component.html',
  styleUrls: ['./display-live-sale-notification.component.scss'],
})
export class DisplayLiveSaleNotificationComponent {
  /**
   * Variable used to store the image extension
   */
  imageExtension = FILE_EXTENSION;
  /**
   * Variable which is used to handle the http call and make it to unsubscribe.
   *  @type {Subscription}
   */
  subscriptionObj: Subscription = new Subscription();

  /**
   * Variable which is used to store the particular store's id
   * @type {number}
   */
  storeId: number;

  /**
   * Variable to store notification data
   *  @type {LiveSaleData}
   */
  notificationData: LiveSaleData;

  /**
   * Variable to store timing data
   */
  timingData: {
    initialDelay: number;
    maximumNotification: number;
    delayNotification: number;
    displayTime: number;
  }

  /**
   * Variable to store template data
   * @type {SafeHtml}
   */
  template: SafeHtml;

  /**
   * Variable to store style
   */
  style: {
    fontStyle: string;
    textColor: string;
    textFontSize: string;
    backGroundColor: string;
    timeAgoColor: string;
    productLinkColor: string;
    productLinkFontSize: string;
    popupOverlayColor: string;
  };

  /**
   *Variable to store aws image
   @type {string}
   */
  awsImage: string;

  /**
   * Variable to store display live sale setTimeout
   * @type {NodeJS.Timeout}
   */
  displayLiveSale: NodeJS.Timeout;

  /**
   * Variable to store display next setTimeout
   * @type {NodeJS.Timeout}
   */
  displayNext: NodeJS.Timeout;

  /**
   * Variable to store display delay setTimeout
   * @type {NodeJS.Timeout}
   */
  displayDelay: NodeJS.Timeout;

  /**
   * Variable to store close timer setTimeout
   * @type {NodeJS.Timeout}
   */
  closeTimer: NodeJS.Timeout;

  /**
   * Variable to store display number
   * @type {number}
   */
  displaynumber: number = 0;

  /**
   * Variable to store order number
   * @type {number}
   */
  orderNumber: number = 0;

  /**
   * Variable to store order length
   * @type {number}
   */
  orderLength: number = 0;

  /**
   * Variable to check close notification
   * @type {boolean}
   */
  isCloseNotification: boolean = false;

  /**
   * Variable to store order details
   * @type {OrderDetails} 
   */
  orderDetails: OrderDetails;

  /**
   * Variable to live sale class
   * @type {string}
   */
  liveSaleClass: string;

  @Input() pluginDetails;

  /**
   * Variable used to navigate to particular product/category.
   * @type {EventEmitter}
   */
  @Output() pageToNavigate = new EventEmitter();

  /**
   * Variable to store slideanimation 
   */
  slideAnimation: {
    slideIn: string;
    slideOut: string;
  };

  /**
   * Variable to store animation left style
   * @type {string}
   */
  @HostBinding("style.--animationLeftLS") animationLeftLS: string = '';

  /**
   * Variable to store animation right style
   * @type {string}
   */
  @HostBinding("style.--animationRigthLS") animationRigthLS: string = '';

  /**
   * Variable to store desktop view details
   */
  desktopView: {
    desktopDisplay: string;
    desktopDirection: string;
    desktopPosition: string;
    desktopAnimation: string;
  };

  /**
   * Variable to store mobile view details
   */
  mobileView: {
    mobilePosition: string;
    mobileAnimation: string;
  };

  /**
   * Variable to show notification by size
   * @type {boolean}
   */
  showNotificationBySize: boolean;

  /**
   * Variable to show notification by route
   * @type {boolean}
   */
  showNotificationByRoute: boolean = false;

  /**
   * Variable to store display type 
   * @type {string}
   */
  display: string;

  /**
   * Variable to store postion style
   */
  position: {
    top: string;
    bottom: string;
    right: string;
    left: string;
  };

  /**
   * Variable to store specific page list
   * @type {Array<string>}
   */
  specificPages: Array<string> = [];

  /**
   * Variable to check to display 
   * @type {boolean}
   */
  toDisplay: boolean = false;

  /**
   * Component constructor which is used to inject the required services.
   * @param router to navigate to routes.
   * @param sharedService to access SharedService.
   * @param commonService to access CommonService.
   * @param sanitizer To access DomSanitizer.
   * @param elementRef to access elements on the DOM
   * @param renderer to add style property
   */
  constructor(private router: Router,
    private sharedService: SharedService,
    private commonService: CommonService,
    private translationService: LoadTranslationService,
    private sanitizer: DomSanitizer,
    private elementRef: ElementRef,
    private renderer: Renderer2,
  ) {

  }

  /**
   * Component onInit life cycle.
   */
  ngOnInit(): void {
    this.storeId = this.sharedService?.storeId;
    this.awsImage = this.commonService?.environment?.AWSImageUrl;
    this.subscriptionObj.add(this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe((route: LiveNotificationRoute) => {
      this.checkRoute(route);
    }));

    this.subscriptionObj.add(this.sharedService.getOneNotification(this.storeId, true).subscribe({
      next: (res: LiveSaleNotificationDetails) => {
        if (res && res.data && res.data.isEnabled) {
          this.notificationData = res.data;
          if (this.pluginDetails && this.pluginDetails.hasOwnProperty('ADVANCED_SETTING')) {
            this.notificationData.isCloseNotification = this.pluginDetails.ADVANCED_SETTING ? this.notificationData.isCloseNotification : false
          }
          this.timingData = JSON.parse(this.notificationData.timingDetails);
          this.template = this.sanitizer.bypassSecurityTrustHtml(this.checkFestivalImage(this.notificationData.liveSaleTemplate?.template));
          this.style = JSON.parse(this.notificationData.style);
          this.desktopView = this.notificationData.desktopView ? JSON.parse(this.notificationData.desktopView) : null;
          this.mobileView = this.notificationData.mobileView ? JSON.parse(this.notificationData.mobileView) : null;
          this.specificPages = this.notificationData.specificPages;
          this.checkRoute();
          this.onResize();
          this.getOneNotification();
        }
      },
      error: (err) => {
      }
    }));
  }

  /**
   * Method used to get one notification details
   */
  getOneNotification(): void {
    if (this.notificationData.isAutomatic) {
      this.subscriptionObj.add(this.sharedService.orderDetailLiveSaleNotification(this.storeId).subscribe({
        next: (res: LiveSaleOrderList) => {
          if (res && res.orderDetails) {
            this.orderDetails = res.orderDetails;
            if (this.orderDetails.count > 0 && this.showNotificationBySize && this.showNotificationByRoute) {
              this.displayDelay = setTimeout(() => {
                this.toDisplay = true;
                this.setMessageStyle();
              }, Number(this.timingData.initialDelay) * 1000);
            }
          }
        },
        error: (err) => {
        }
      }));
    }
    else {
      if (this.showNotificationBySize && this.showNotificationByRoute) {
        this.displayDelay = setTimeout(() => {
          this.toDisplay = true;
          this.setMessageStyle();
        }, Number(this.timingData.initialDelay) * 1000);
      }
    }
  }

  /**
   * Method used to set message style
   */
  setMessageStyle(): void {
    document.getElementById("messageData") && document.getElementById("messageData").remove();
    document.getElementById("message") && document.getElementById("message").remove();

    let productRandom: number;
    let productId: number;
    let variantId: number;
    let message = this.notificationData.message;
    let productName: string, customerName: string, location: string, format: string;

    const image = this.elementRef.nativeElement.querySelector('#imageBack');

    if (this.notificationData.isAutomatic && this.orderDetails && this.orderDetails.rows) {
      productName = this.orderDetails.rows[this.orderNumber].orderedProducts && this.orderDetails.rows[this.orderNumber].orderedProducts[this.orderLength].name;
      customerName = this.orderDetails.rows[this.orderNumber].customerName;
      location = this.orderDetails.rows[this.orderNumber].city + ', ' + this.orderDetails.rows[this.orderNumber].statename;

      if (image) {
        let originalImage = (this.orderDetails.rows[this.orderNumber].orderedProducts && this.orderDetails.rows[this.orderNumber].orderedProducts[this.orderLength].featuredImage != null ? this.awsImage + this.orderDetails.rows[this.orderNumber].orderedProducts[this.orderLength].featuredImage : "assets/no-image.png");
        // image.src = this.commonService.imageOptimization(originalImage, this.imageExtension.AVIF_FORMAT);
        image.src = originalImage;
      }

      let currentDate = new Date();
      let orderDate = new Date(this.orderDetails.rows[this.orderNumber].createdAt);
      let dateDiff = currentDate.getTime() - orderDate.getTime();
      format = this.timeDiff(dateDiff);

      productId = this.orderDetails.rows[this.orderNumber]?.orderedProducts && this.orderDetails.rows[this.orderNumber].orderedProducts[this.orderLength].productId;
      variantId = this.orderDetails.rows[this.orderNumber]?.orderedProducts && this.orderDetails.rows[this.orderNumber].orderedProducts[this.orderLength].variantId;
    }

    else {
      if (this.notificationData.specificProducts) {
        productRandom = this.generateRandomNumber(this.notificationData.specificProducts.length);
        productName = this.notificationData && this.notificationData.specificProducts[productRandom] && this.notificationData.specificProducts[productRandom].name;
      }
      customerName = this.notificationData.customerNames && this.notificationData.customerNames[this.generateRandomNumber(this.notificationData.customerNames && this.notificationData.customerNames.length)];
      location = this.notificationData.locations && this.notificationData.locations[this.generateRandomNumber(this.notificationData.locations && this.notificationData.locations.length)];
      format = this.notificationData.timeList && this.notificationData.timeList[this.generateRandomNumber(this.notificationData.timeList && this.notificationData.timeList.length)];

      if (image) {
        let originalImage = (this.notificationData.specificProducts && this.notificationData.specificProducts[productRandom] && this.notificationData.specificProducts[productRandom].featuredImage && this.notificationData.specificProducts[productRandom].featuredImage != null ? this.awsImage + this.notificationData.specificProducts[productRandom].featuredImage : "./assets/no-image.png")
        // image.src = this.commonService.imageOptimization(originalImage, this.imageExtension.AVIF_FORMAT);
        image.src = originalImage;
      }
      // if (this.notificationData.specificProducts && this.notificationData.specificProducts[productRandom] && this.notificationData.specificProducts[productRandom].featuredImage && this.notificationData.specificProducts[productRandom].featuredImage != null) {
      //   if (image)
      //     image.src = this.awsImage + this.notificationData.specificProducts[productRandom].featuredImage;
      // }
      // else {
      //   if (image)
      //     image.src = this.awsImage + "livesalenotification/common.jpg";
      // }

      productId = this.notificationData.specificProducts && this.notificationData.specificProducts[productRandom].productId;
      variantId = this.notificationData.specificProducts && this.notificationData.specificProducts[productRandom].id;
    }

    message = message && message.replace(/\n/g, "<br>").replace(/{customer_Data}/g, customerName)
      .replace(/{location}/g, location)
      .replace(/{product_Data}/g, "<span class='productLink' style = 'font-size:18px; color: blue; cursor: pointer;'>" + productName + "</span>")
      .replace(/{format}/g, "<span class='time' style = 'margin-top: -4px; color: red'>" + format + "</span>");

    const div = this.renderer.createElement("div");
    div.setAttribute("id", "message");
    div.innerHTML = message;

    const element = this.elementRef.nativeElement.querySelector("#messageContainer");
    if (element)
      element.appendChild(div);

    const productLink = document.getElementsByClassName('productLink');
    const time = document.getElementsByClassName('time');
    const backGround = document.getElementById('backGround');
    const messageDiv = document.getElementById('message');

    if (this.renderer && messageDiv && backGround && time && productLink) {
      this.renderer.setStyle(messageDiv, 'font-family', this.style && this.style.fontStyle);
      this.renderer.setStyle(messageDiv, 'color', this.style && this.style.textColor);
      this.renderer.setStyle(messageDiv, 'font-size', this.style && this.style.textFontSize + 'px');
      this.renderer.setStyle(messageDiv, 'width', this.notificationData.isCloseNotification ? '85%' : '100%');
      this.renderer.setStyle(messageDiv, 'padding', '10px 0');

      this.renderer.setStyle(backGround, 'background-color', this.style && this.style.backGroundColor);
      this.renderer.setStyle(backGround, 'width', '100%');

      // this.renderer.setStyle(time, 'color', this.style && this.style.timeAgoColor);

      // this.renderer.setStyle(productLink, 'color', this.style && this.style.productLinkColor);
      // this.renderer.setStyle(productLink, 'font-size', this.style && this.style.productLinkFontSize + 'px');
      // this.renderer.setStyle(productLink, 'transition', 'color 0.2s');
    }

    if (productLink) {
      Array.from(productLink).forEach((element: HTMLElement) => {
        element.addEventListener('mouseenter', () => {
          this.renderer.setStyle(element, 'color', this.style && this.style.popupOverlayColor);
        });
        element.addEventListener('mouseleave', () => {
          this.renderer.setStyle(element, 'color', this.style && this.style.productLinkColor);
        });
        element.addEventListener('click', () => {
          this.sharedService.navigateTo('productdetails', null, { productId: productId });
          // if(this.notificationData.isAutomatic){
          //   console.log('this.notificationData.specificProducts[productRandom]', productId)

          //   if(this.orderDetails.rows[this.orderNumber].orderedProducts[this.orderLength].isVariant){
          //     console.log('variant')
          //     this.sharedService.navigateTo('productdetails', null, {productId: productId, variantId: variantId});
          //   }
          //   else{
          //     console.log('notvariant')
          //     this.sharedService.navigateTo('productdetails', null, {productId: productId});
          //   } 
          // }
          // else{
          //   console.log('this.notificationData.specificProducts[productRandom]', productId)

          //   if(this.notificationData.specificProducts[productRandom].isVariant){
          //     console.log('variant')
          //     this.sharedService.navigateTo('productdetails', null, {productId: productId, variantId: variantId});
          //   }
          //   else{
          //     console.log('notvariant')
          //     this.sharedService.navigateTo('productdetails', null, {productId: productId});
          //   }
          // }
        });
        element.style.color = this.style && this.style.productLinkColor;
        element.style.fontSize = this.style && this.style.productLinkFontSize + 'px';
        element.style.transition = 'color 0.2s';
      });
    }
    if (time) {
      Array.from(time).forEach((element: HTMLElement) => {
        const el = element as HTMLElement;
        el.style.color = this.style && this.style.timeAgoColor;
      });
    }
    if (this.orderDetails) {
      if (this.orderDetails.rows && this.orderDetails.rows[this.orderNumber] && this.orderDetails.rows[this.orderNumber].orderedProducts && (this.orderDetails.rows[this.orderNumber].orderedProducts.length - 1 > this.orderLength)) {
        ++this.orderLength;
        --this.orderNumber;
      }
      else {
        this.orderLength = 0;
      }
    }
    this.showLiveSaleNotification();
  }

  /**
   * Method used to show live sale notification
   */
  showLiveSaleNotification(): void {
    ++this.displaynumber;
    ++this.orderNumber;
    document.getElementById('liveSaleNotify') && document.getElementById('liveSaleNotify').classList && document.getElementById('liveSaleNotify').classList.remove(this.slideAnimation.slideOut);
    document.getElementById('liveSaleNotify') && document.getElementById('liveSaleNotify').classList && document.getElementById('liveSaleNotify').classList.add(this.slideAnimation.slideIn);
    this.displayLiveSale = setTimeout(() => {
      document.getElementById('liveSaleNotify') && document.getElementById('liveSaleNotify').classList && document.getElementById('liveSaleNotify').classList.remove(this.slideAnimation.slideIn);
      document.getElementById('liveSaleNotify') && document.getElementById('liveSaleNotify').classList && document.getElementById('liveSaleNotify').classList.add(this.slideAnimation.slideOut);
      this.displayNext = setTimeout(() => {
        if (((this.notificationData.specificProducts && this.notificationData.specificProducts.length > 0) || (this.orderDetails && this.displaynumber < this.orderDetails.count))
          && this.displaynumber < this.timingData.maximumNotification && this.isCloseNotification == false) {
          this.setMessageStyle();
        }
        else {
          clearTimeout(this.displayLiveSale);
          clearTimeout(this.displayNext);
        }
      }, this.timingData.delayNotification * 1000);
    }, this.timingData.displayTime * 1000);
  }

  /**
   * Method used to find time difference
   * @param diff has time difference in second
   * @returns has calucated difference
   */
  timeDiff(diff: number): string {
    let ms_Min = 60 * 1000; // milliseconds in Minute 
    let ms_Hour = ms_Min * 60; // milliseconds in Hour 
    let ms_Day = ms_Hour * 24; // milliseconds in day 
    let ms_Mon = ms_Day * 30; // milliseconds in Month 
    let ms_Yr = ms_Day * 365; // milliseconds in Year 

    // If the diff is less then milliseconds in a minute 
    if (diff < ms_Min) {
      return Math.round(diff / 1000) + " " + this.translationService.getTranslation('SHARED.SECONDS');

      // If the diff is less then milliseconds in a Hour 
    } else if (diff < ms_Hour) {
      return Math.round(diff / ms_Min) + " " + this.translationService.getTranslation('SHARED.MINUTES');

      // If the diff is less then milliseconds in a day 
    } else if (diff < ms_Day) {
      return Math.round(diff / ms_Hour) + " " + this.translationService.getTranslation('SHARED.HOURS');

      // If the diff is less then milliseconds in a Month 
    } else if (diff < ms_Mon) {
      return Math.round(diff / ms_Day) + " " + this.translationService.getTranslation('SHARED.DAYS');

      // If the diff is less then milliseconds in a year 
    } else if (diff < ms_Yr) {
      return Math.round(diff / ms_Mon) + " " + this.translationService.getTranslation('SHARED.MONTHS');

    } else {
      return Math.round(diff / ms_Yr) + " " + this.translationService.getTranslation('SHARED.YEARS');
    }
  }

  /**
   * Method used to close notification
   * @param event has button event
   */
  closeNotification(event?: any): void {
    this.isCloseNotification = true;
    clearTimeout(this.displayLiveSale);
    clearTimeout(this.displayNext);
    clearTimeout(this.displayDelay);
    clearTimeout(this.closeTimer);
    if (event) {
      if (document.getElementById('liveSaleNotify') && document.getElementById('liveSaleNotify').classList && document.getElementById('liveSaleNotify').classList.contains(this.slideAnimation && this.slideAnimation.slideIn)) {
        document.getElementById('liveSaleNotify').classList.remove(this.slideAnimation?.slideIn);
        document.getElementById('liveSaleNotify').classList.add(this.slideAnimation?.slideOut);
        setTimeout(() =>{
          this.toDisplay = false;
        }, 2500)
      }
      this.closeTimer = setTimeout(() => {
        this.isCloseNotification = false;
        if (((this.notificationData && this.notificationData.specificProducts && this.notificationData.specificProducts.length > 0) || (this.orderDetails && this.displaynumber < this.orderDetails.count))
          && this.displaynumber < this.timingData.maximumNotification && this.isCloseNotification == false) {
          this.toDisplay = true;
          this.setMessageStyle();
        }
      }, 3600000);
    }
    else {
      document.getElementById('liveSaleNotify')?.classList.remove(this.slideAnimation?.slideOut);
    }
  }

  /**
   * Method used to check route
   * @param event has route
   */
  checkRoute(event?: { url: string; }): void {
    this.toDisplay = false;
    let route = event ? event.url : this.router.url;
    let specificPagesArray = this.specificPages && this.specificPages.length > 0 ? this.specificPages : ['home', 'products', 'productdetails', 'cart'];
    if (specificPagesArray.length > 0) {
      this.showNotificationByRoute = false;
      specificPagesArray.forEach((item: string) => {
        let result = route.split('/')[route.split('/').length - 1].includes(item) &&
          !(route.split('/')[route.split('/').length - 1].includes("type=cart"));
        if (result)
          this.showNotificationByRoute = result;
      })
    }
    if (event && this.notificationData) {
      this.closeNotification();
      if (this.showNotificationByRoute) {
        this.restartNotification();
      }
    }
  }

  /**
   * Method to check screen size
   * @param event has inner width px
   */
  @HostListener("window:resize", ['$event'])
  onResize(event?: { target: { innerWidth: number } }): void {
    const innerWidth = event && event.target && event.target.innerWidth ? event.target.innerWidth : window.innerWidth;
    const display = this.display;
    const slideAnimation = this.slideAnimation;
    if (innerWidth <= 600) {
      this.showNotificationBySize = this.mobileView != null ? true : false;
      this.display = 'MOBILE'
    }
    else {
      this.showNotificationBySize = this.desktopView != null ? true : false;
      this.display = 'DESKTOP'
    }
    if (this.display != display || !event) {
      this.changeClassName(innerWidth.toString());
      if (event && this.notificationData) {
        this.toDisplay = false;
        this.closeNotification();
        if (slideAnimation) {
          document.getElementById('liveSaleNotify').classList.remove(slideAnimation.slideIn);
          document.getElementById('liveSaleNotify').classList.remove(slideAnimation.slideOut);
        }
        if (this.showNotificationBySize) {
          this.restartNotification();
        }
      }
    }
  }

  /**
   * Method used to add class and style for notification
   * @param innerWidth has innner width px
   */
  changeClassName(innerWidth?: string): void {
    if (this.showNotificationBySize && this.display == 'MOBILE' && this.mobileView) {
      // Top Mobile
      this.liveSaleClass = 'liveSaleNotifyMob';
      this.position = {
        top: this.mobileView.mobilePosition + 'px',
        bottom: null,
        right: null,
        left: null
      };
      this.animationLeftLS = '-' + innerWidth + 'px';
      this.animationRigthLS = '6%';
      if (this.mobileView.mobileAnimation == "Slide from Left") {
        this.slideAnimation = {
          slideIn: 'slideInLeft',
          slideOut: 'slideOutLeft'
        };
      }
      else if (this.mobileView.mobileAnimation == "Slide from Right") {
        this.slideAnimation = {
          slideIn: 'slideInRight',
          slideOut: 'slideOutRight'
        };

      }
      else {
        this.slideAnimation = {
          slideIn: 'fadeIn',
          slideOut: 'fadeOut'
        };
      }
    }
    else if (this.showNotificationBySize && this.display == 'DESKTOP' && this.desktopView) {
      this.liveSaleClass = this.desktopView.desktopDisplay == "Bottom Right" ? 'liveSaleNotifyBR' : this.desktopView.desktopDisplay == "Top Right" ? 'liveSaleNotifyTR' : 'liveSaleNotifyTL';
      this.animationLeftLS = this.liveSaleClass == "liveSaleNotifyBR" || this.liveSaleClass == "liveSaleNotifyTR" ? '-455px' : '-430px';
      this.animationRigthLS = this.desktopView.desktopDirection + 'px';
      this.position = {
        top: this.liveSaleClass == "liveSaleNotifyTR" || this.liveSaleClass == "liveSaleNotifyTL" ? this.desktopView.desktopPosition + 'px' : null,
        bottom: this.liveSaleClass == "liveSaleNotifyBR" ? this.desktopView.desktopPosition + 'px' : null,
        right: null,
        left: null
      };

      if (this.desktopView && this.desktopView.desktopAnimation == "Slide In") {
        if (this.liveSaleClass == "liveSaleNotifyTR" || this.liveSaleClass == "liveSaleNotifyBR") {
          //Right Direction
          this.slideAnimation = {
            slideIn: 'slideInRight',
            slideOut: 'slideOutRight'
          };
        }
        else {
          //Left Direction
          this.slideAnimation = {
            slideIn: 'slideInLeft',
            slideOut: 'slideOutLeft'
          };
        }
      }
      else {
        this.position.right = this.liveSaleClass == "liveSaleNotifyBR" || this.liveSaleClass == "liveSaleNotifyTR" ? this.desktopView.desktopDirection + 'px' : null;
        this.position.left = this.liveSaleClass == "liveSaleNotifyTL" ? this.desktopView.desktopDirection + 'px' : null
        // Top Right Desktop
        this.slideAnimation = {
          slideIn: 'fadeIn',
          slideOut: 'fadeOut'
        };
      }

    }
  }

  /**
   * Method used to return random number based on length of the array
   * @param length has length of the array
   * @returns random number
   */
  generateRandomNumber(length: number): number {
    return Math.floor(Math.random() * length)
  }

  /**
   * Method used to restart notification
   */
  restartNotification(): void {
    this.orderNumber = 0;
    this.orderLength = 0;
    this.displaynumber = 0;
    if (((this.notificationData && this.notificationData.isAutomatic && this.orderDetails && this.orderDetails.count > 0) || (this.notificationData && !this.notificationData.isAutomatic))
      && this.showNotificationBySize && this.showNotificationByRoute) {
      this.isCloseNotification = false;
      this.displayDelay = setTimeout(() => {
        this.toDisplay = true;
        this.setMessageStyle();
      }, this.timingData.initialDelay * 1000);
    }
  }

  /**
   * Method used to return template
   * @param template has template as string
   */
  checkFestivalImage(template: string): string {
    const parser = new DOMParser();
    const doc = parser.parseFromString(template, 'text/html');
    const imgElement = doc.querySelector('#festivalImage') as HTMLImageElement;

    if (imgElement && imgElement.src) {
      imgElement.src = this.awsImage + 'livesalenotification' + imgElement.src.split('livesalenotification')[1];
    }

    return new XMLSerializer().serializeToString(doc)
  }

  /**
   * Component OnDestroy life cycle hook.
   * And unsubscribe all the subscriptions in the component.
   */
  ngOnDestroy(): void {
    clearTimeout(this.displayLiveSale);
    clearTimeout(this.displayNext);
    clearTimeout(this.displayDelay);
    clearTimeout(this.closeTimer);
    this.subscriptionObj.unsubscribe();
  }
}
