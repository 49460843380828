/**
 * Constants which is used to theme datas.
*/
export class ThemeConstants {
    /**
     * Variable which is used for theme colors.
    */
    themeColors = {
        restaurant: [{
            id: 5,
            primaryColor: '#c30f30',
            secondaryColor: '#80c341',
        }, {
            id: 6,
            primaryColor: '#232f3e',
            secondaryColor: '#fec524',
            tertiaryColor: '#d92727',
        }],
        retail: [{
            id: 1,
            primaryColor: '#f72048',
            secondaryColor: '#74d6d5'
        }],
        grocery: [{
            id: 9,
            primaryColor: '#005374',
            secondaryColor: ' #ff8517'
        }],
        coffee: [{
            id: 3,
            primaryColor: '#744a1d',
            secondaryColor: '#744a1d',
            tertiaryColor: '#f4a460'
        }],
        bakery: [{
            id: 7,
            primaryColor: 'black',
            secondaryColor: 'peru'
        }, {
            id: 8,
            primaryColor: '#a25a1a',
            secondaryColor: '#f19759'
        }],
        default: {
            primaryColor: '#005374',
            secondaryColor: '#ff8517'
        }
    }
    /**
     * Variable which is used for subscription details.
    */
    subscriptionDetails = {
        bannerDetails: {
            bannerProperty: 1,
            bannerDetails: {
                bannerImage: [
                    '../../../assets/grocery_1604669318650_1611125476243.png'
                ],
                bannerText: 'We Bring the Store to Your Door!',
                textAlign: 'center',
                textColor: "white",
            }
        },
        bannerImage: '',
        pageHeading: 'SUBSCRIPTION BUNDLE',
        boxesHeading: 'SUBSCRIPTION BOXES',
        boxHeading: 'SUBSCRIPTION BOX',
        newBoxHeading: 'NEW SUBSCRIPTION BOX',
        emptyBundleText: 'Sorry, No Boxes available in this bundle.',
        emptyBundleButtonText: 'Explore Other Bundles',
        boxEmptyText: 'Sorry, Box is Empty!',
        boxEmptyButtonText: 'Explore Other Boxes',
        boxProductEmptyText: 'Sorry, No Products in this Box. Please Select the products!',
        boxProductEmptyButtonText: 'Choose Products',
        createBoxButtonText: 'Add Custom Box',
        exploreBundle: 'Explore Bundle',
        cardHeading: 'Box Details',
        price: 'Price',
        boxLabel: 'Box Name',
        messageLabel: 'Add a Message',
        boxNoteText: 'You need to add products in this box',
        boxNoteLabel: 'Note',
        addButtonText: 'ADD TO BOX',
        addToCartText: 'ADD TO CART',
        resetButtonText: 'RESET',
        viewProductButtonText: 'VIEW PRODUCTS',
        backButtonText: 'BACK'
    }
    /**
     * Variable which is used for themes list.
    */
    themeDashboard = [
        {
            image: '../../../assets/themes.jpg',
            buttonText: 'Select Theme',
            text: ["Want to change the whole look of your website? Click",
                "button to choose theme"],

        },
        {
            image: '../../../assets/theme2.jpg',
            buttonText: 'Start Editing',
            text: ["Want to edit the current theme?",
                "Click the below button to just edit the website"]
        }
    ]
    /**
     * Variable which is used for dialog alerts.
    */
    dialogType = { alert: 'alert', confirmation: 'confirmation', information: 'information', success: 'success', failure: 'failure' };
    /**
     * Variable which is used for dialog messages.
    */
    dialogMessages = {
        successHeader: 'Success',
        failureHeader: 'Failure',
        confirmation: 'Confirmation',
        getProductDetailsFailed: "Failed to load the page. Please try again after some time",
        okButtonText: "Okay",
        cancelButtonText: "Cancel",
        warningHeader: "Warning",
        themeSetUp: "Store theme is not fully setup. Please contact the administrator",
        reviewTitleRequired: "Title is Required",
        reviewMessageRequired: "Message is Required",
        reviewCreationFailed: "Failed to add your review. Please try again after some time",
        reviewCreationSuccess: "Your review has been successfully posted",
        reviewUpdationFailed: "Failed to update your review. Please try again after some time",
        reviewUpdationSuccess: "Your review has been updated successfully",
        likeFailure: "Sorry, Your attempt to like this review is failed..Please try again after some time",
        dislikeFailure: "Sorry, Your attempt to dislike this review is failed..Please try again after some time",
        errorMessage: 'Error! Please try again.',
        productDetailFailed: 'Sorry! There is no data for this product',
        addToWishlistSuccess: "Product Added to Wish list Successfully",
        addToWishlistFailed: "We failed to add the product to the wish list",
        removeFromWishlistSuccess: "Product Removed from Wish list Successfully",
        removeFromWishlistFailed: "We failed to remove the product from the wish list",
        reviewDeleteSuccess: "Review deleted successfully",
        reviewDeleteFailure: "Falied to delete your review.Please try again after some time",
        reviewDeleteConfirmation: "Are you sure to delete your review?",
        featuredProductFailure: 'Failed to load the featured products.',
        featuredCategoryFailure: 'Failed to load the featured categories.',
        singleProductDetailFailure: 'Failed to load the single product details',
        offers: 'Failed to load the offers',
        pageDetailsFailure: "Failed to get Page details",
        testimonialFailure: "Failed to get Testimonial details",
        failureMessage: "Failed to get the details",
        deleteConfirmation: "Are you sure you want to delete?",
        cancelConfirmation: "Are you sure you want to cancel?",
        ageConfirmation: "Please verify that you are 21 years of age or older",
        locationFailure: "Failed to get location details",
        topRatedProductsFailure: "Failed to get top rated product details",
        bestSellerProductsFailure: "Failed to get best seller products details",
        coffeeCategoryFailure: "Failed to get coffee category products",
        categoryFailure: "Failed to get category details",
        productByCategoryFailure: "Failed to get Products by category",
        searchTextFailure: "Failed to get search text",
        filterFailure: "Failed to get filtered products",
        dataEmitFailure: "Failed to load the products",
        websiteSettingsFailure: "Failed to get website settings details",
        productOrderFailure: "Failed to get product orders",
        selectedCategoryfailure: "Failed to get selected category details",
        setCategoryIdFailure: "Failed to set category Id",
        variantsFailure: "Failed to get variants details",
        brandsFailure: "Failed to get brand details",
        storefrontPathFailure: "Your store display name or domain name might be incorrect. Please contact your store owner",
        blogFailure: "Failed to get Blog details",
        storedIdMissing:"Store id is missing"
    };
    /**
     * Variable which is used for additional tabs in product details page.
    */
    displayDetails = [
        { name: 'Description', objectName: 'description', isClicked: false },
        { name: 'Video', objectName: 'description', isClicked: false },
        { name: 'Product Reviews & ratings', objectName: 'productReviews', isClicked: false },
        { name: 'FAQ', objectName: 'productFrequentlyAskedQuestions', isClicked: false }
    ];
    /**
     * Variable which is used for attachments type(img/video)
    */
    attachmentTypes = [{
        id: 1,
        Name: 'Image'
    }, {
        id: 2,
        Name: 'Video'
    }]
    /**
     * Variable used to store video extentions in array
     */
    videoExtensions = ['mp4', 'mov', 'wmv', 'webm'];
    /**
     * Variable used to show shop closed message text.
     */
    shopClosedText = 'Sorry for the inconvenience, The shop is currently closed !';
    /**
     * Variable used to store role id of customer
     */
    customerRoleId = 5;
    /**
     * Variable used to store guest role name.
     */
    guest = 'Guest';
    /**
     * Variable used to store food Categories.
     */
    restaurantFoodCategories = [
        {
            "id": 0,
            "name": "All"
        },
        {
            "id": 1,
            "name": "Veg",
            "color": "green"
        },
        {
            "id": 2,
            "name": "Non-veg",
            "color": "red"
        }
    ];
    /**
     * Variable used to store upsell section.
     */
    relatedProductCategoryUpsell: 'upsell' = 'upsell';
    /**
     * Variable used to store crosssell section.
     */
    relatedProductCategoryCrosssell: 'crosssell' = 'crosssell';
    /***
     * Variable used to store bought together section.
     */
    relatedProductCategoryBoughtTogether: 'boughtTogether' = 'boughtTogether';
    /**
     * Variable which is used to fetch google analytics details.
    */
    googleAnalytics = 'GOOGLE ANALYTICS';
    /**
     * Variable used to store cart related messages.
     */
    cartMessages = {
        insertCart: "Product Added to Cart Successfully",
        deleteCart: "Product Removed from Cart Successfully",
        cartFailure: "Failed to add product to cart"
    }
    /**
   * Variable used to store notify message.
   */
    notifyMessage = {
        waitListCreated: 'Thank you! We will notify you once it is back in stock.',
        waitListUpdated: 'Product already added to wait list.'
    }
    /**
     * Variable used to retail product types.
     */
    productType = {
        featuredProduct: 'getFeaturedProducts', topRated: 'getTopRatedProducts', bestSeller: 'getBestSellerProducts'
    }
    /**
     * Variable used to store industry types.
     */
    industryTypes = {
        retail: 'retail',
        restaurant: 'restaurant',
        bakery: 'bakery',
        coffee: 'coffee',
        beverage: 'beverages',
        grocery: 'grocery'
    }
    /**
     * Variable is used to store fbt dialog messages.
     */
    fbtMessage = {
        productAdded: 'Product added to the cart.',
        alreadyInCart: 'Products already in cart.',
        selectAnyOne: 'Please select one of the products to add to your cart.'
    }
}