<mat-drawer-container class="cart-quick-view">
  <mat-drawer [mode]="'over'" #navbarCart id="navbarCart" class="leftSideNav" [ngStyle]="{'width': '300px'}"
    [position]="'end'" [ngClass]="{'right': true, 'pointernone pointer-z-index':disablePointer}"
    (openedChange)="onOpenedChange($event)">
    <cen-loader *ngIf="isLoader || listLoader" [type]="'content'" [name]="'cen-line-spinner'">
    </cen-loader>

    <ng-container *ngIf="!isLoader">
      <div fxLayout="row" fxLayoutAlign="space-between center" class="navbar-cart-header cart-header">
        <span class="material-icons-outlined closeCart" (click)="dialogRef?null:navbarCart?.close()"
          style="cursor: pointer;" data-control="close" [matTooltip]="'SHARED.CLOSE' | translate">
          close
        </span>
        <span class="my-cart-heading">{{'SHARED.MY_CART' | translate}}</span>
      </div><br>

      <div class="navbar-cart-container" fxLayout="column" fxLayoutGap="10px">
        <div fxLayout="column" *ngIf="cartDetails?.cartProducts?.length; else emptyCart">
          <ng-container *ngFor="let data of cartDetails?.cartProducts; let index = index">
            <ng-container
              *ngIf="data?.cartSubscriptionId && (data?.subscriptionPlanId || !data?.subscriptionPlanId); else cartProducts">
              <div fxLayout="column" style="margin-bottom: 10px; position: relative;">
                <span class="subscription-products quick-cart-ribbon-text">
                  {{data?.subscriptionPlanId ? ('SHARED.SUBSCRIPTION_BUNDLE' | translate) :
                  ('SHARED.SUBSCRIPTION_PRODUCT' | translate)}}
                </span>
                <div fxLayout="row" fxLayoutAlign="center center" style="padding: 10px 0px;">
                  <img style="height: 100px;max-width: 90%;"
                    [actualImageURL]="data?.image ? awsUrl + data?.image: './assets/image-not-available-3.png'"
                    alt="product-image" [imageExtension]="imageExtension?.AVIF_FORMAT"
                    [onErrorEvent]="{src:'./assets/image-not-available-3.png'}">
                </div>
                <div fxFlex fxLayout="column" style="padding: 0px 5px" fxLayoutGap="10px">
                  <div fxLayout="row" fxLayoutAlign="space-between " fxLayoutGap="5px">
                    <div fxLayout="column" fxFlex fxLayoutGap="5px">
                      <span class="cart-product">{{(data?.name ? data?.name : '')+' '+(data?.variantName ? '- ' +
                        data?.variantName : '')}}</span>
                      <span class="subscription-text" *ngIf="data?.quantity">{{'SHARED.QUANTITY' |
                        translate}}:&nbsp;{{data?.quantity}}</span>
                      <div fxLayout="row" fxLayoutAlign=" end" fxLayoutGap="5px">
                        <ng-container *ngIf="!(data?.isFreeProduct && !data?.isLt100percentApplied)">
                          <ng-container *ngIf="data?.subTotal; else centPercentDiscount">
                            <s *ngIf="data?.discount"
                              style="font-size: 10px;">{{(data?.orderAmount)|currency:websiteSettings?.currency?.currencyCode}}</s>
                            <span
                              style="font-size: 12px;">{{(data?.subTotal)|currency:websiteSettings?.currency?.currencyCode}}</span>
                          </ng-container>
                        </ng-container>
                        <ng-template #centPercentDiscount>
                          <div style="position:absolute; top: 0;"> <img src="../../../assets/free.png" alt="free"
                              style="width: 20%;float: right;"></div>
                        </ng-template>
                      </div>
                    </div>
                    <div fxLayout="row" fxFlex="10%" fxLayoutAlign="end ">
                      <button mat-icon-button fxLayout="row" fxLayoutAlign="center center"
                        (click)="onRemoveSubscriptionItems(index,data)"
                        *ngIf="(data?.isFreeProduct && data?.isLt100percentApplied)  || !data?.isFreeProduct ">
                        <mat-icon style="font-size: 17px;color: red;cursor: pointer" fxLayout="row"
                          fxLayoutAlign="center center">delete</mat-icon>
                      </button>
                    </div>
                  </div>
                  <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="20px">
                    <span class="subscription-text">{{'SHARED.DELIVERY_FREQUENCY' | translate}}:
                      <span style="color: var(--primary-color);">{{data?.frequency | titlecase}}
                      </span>
                    </span>
                    <!-- <div fxLayout="row" fxFlex="40%">
                      <mat-form-field appearance="outline" class="select-frequency-dropdown">
                        <mat-select [(value)]="data && data.frequency"
                          (selectionChange)="setFrequency(data, $event, index)">
                          <mat-option *ngFor="let frequency of data?.deliverFrequency" [value]="frequency"
                            class="select-frequency-options">
                            {{frequency}}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div> -->
                  </div>
                </div>
              </div>
            </ng-container>


            <ng-template #cartProducts>
              <div fxLayout="column" style="margin-bottom: 10px; position: relative;">
                <span class="out-of-stock quick-cart-ribbon-text"
                  *ngIf="!data?.discountProductId && data?.isUnstock && !data?.isPayXProduct">
                  {{'SHARED.OUT_OF_STOCK' | translate}}
                </span>
                <span class="customized-products quick-cart-ribbon-text"
                  [ngClass]="{'opacity': (!data?.discountProductId && data?.isUnstock && !data?.isPayXProduct)}"
                  *ngIf="data?.isProductCustomizable && data?.customizeProductData?.length">
                  Customized
                </span>
                <div fxLayout="row" fxLayoutAlign="center center" style="padding: 10px 0px;"
                  [ngClass]="{'opacity': (!data?.discountProductId && data?.isUnstock && !data?.isPayXProduct)}">
                  <img style="height: 100px;max-width: 90%;"
                    [actualImageURL]="data?.product?.featuredImage ? awsUrl + data?.product?.featuredImage: './assets/image-not-available-3.png'"
                    alt="product-image" [imageExtension]="imageExtension?.AVIF_FORMAT"
                    [onErrorEvent]="{src:'./assets/image-not-available-3.png'}">
                </div>
                <div fxFlex fxLayout="column" style="padding: 0px 5px" fxLayoutGap="10px">
                  <div fxLayout="row" fxLayoutAlign="space-between " fxLayoutGap="5px">
                    <div fxLayout="column" fxFlex fxLayoutGap="5px"
                      [ngClass]="{'opacity': (!data?.discountProductId && data?.isUnstock && !data?.isPayXProduct)}">
                      <span class="cart-product">{{(data?.product?.name ? data?.product?.name : '')+' '+
                        (data?.combinationName?'- '+data?.combinationName:'')}}</span>
                      <div fxLayout="row" fxLayoutAlign=" end" fxLayoutGap="5px">
                        <ng-container *ngIf="!(data?.isFreeProduct && !data?.isLt100percentApplied)">
                          <ng-container *ngIf="data?.discountDetails; else fixedPrice">
                            <s
                              style="font-size: 10px;">{{(data?.productPrice*data?.quantity)|currency:websiteSettings?.currency?.currencyCode}}</s>
                            <ng-container
                              *ngIf="data?.discountDetails?.appliedDiscountType === 'AUTOMATIC'; else actualPrice">
                              <span
                                style="font-size: 12px;">{{(data?.price*data?.quantity)|currency:websiteSettings?.currency?.currencyCode}}</span>
                            </ng-container>
                            <ng-template #actualPrice>
                              <span
                                style="font-size: 12px;">{{(data?.productWithOptionPrice*data?.quantity)|currency:websiteSettings?.currency?.currencyCode}}</span>
                            </ng-template>
                          </ng-container>
                        </ng-container>
                        <ng-template #fixedPrice>
                          <span
                            style="font-size: 12px;">{{(data?.productWithOptionPrice*data?.quantity)|currency:websiteSettings?.currency?.currencyCode}}</span>
                        </ng-template>
                        <ng-container *ngIf="data?.discountDetails?.appliedDiscountType === 'AUTOMATIC'">
                          <ng-container
                            *ngIf="data?.discountDetails?.discountType?.discountTypeCode === 'PERCENTAGE'; else fixed">
                            <span
                              style="font-size: 14px; font-weight: bold; color: var(--primary-color);">{{data?.discountDetails?.discountValue?.toString()?.split('.')[0]}}%
                              {{'SHARED.OFF' | translate}}</span>

                          </ng-container>
                          <ng-template #fixed>
                            <span
                              style="font-size: 14px; font-weight: bold; color: var(--primary-color);">{{data?.discountDetails?.discountValue|currency:websiteSettings?.currency?.currencyCode}}
                              {{'SHARED.OFF' | translate}}
                            </span>
                          </ng-template>
                        </ng-container>
                        <ng-container
                          *ngIf="data?.isFreeProduct && (data?.discountDetails?.appliedDiscountType === 'BuyXGetY' || data?.discountDetails?.appliedDiscountType === 'PAY_X_GET_FREE')">
                          <span *ngIf="data?.isLt100percentApplied; else lt100PercentTemplate"
                            style="font-size: 14px; font-weight: bold; color: var(--primary-color);">{{data?.buyXDiscountValue}}%
                            {{'SHARED.OFF' | translate}}</span>
                        </ng-container>
                        <ng-template #lt100PercentTemplate>
                          <div style="position:absolute; top: 0;"> <img src="../../../assets/free.png" alt="free"
                              style="width: 20%;float: right;"></div>
                        </ng-template>
                      </div>
                      <div *ngIf="data?.addonImageData?.length" fxLayout="row" fxLayoutAlign=" end" fxLayoutGap="5px">
                        <span class="addon-text">Add-on Price :</span>
                        <span
                          class="addon-price">{{(data?.modifierPrice*data?.quantity)|currency:websiteSettings?.currency?.currencyCode}}</span>
                      </div>
                    </div>
                    <div fxLayout="row" fxFlex="10%" fxLayoutAlign="end ">
                      <button mat-icon-button fxLayout="row" fxLayoutAlign="center center"
                        (click)="onDeleteCartItem(index,data)"
                        *ngIf="(data?.isFreeProduct && data?.isLt100percentApplied)  || !data?.isFreeProduct ">
                        <mat-icon style="font-size: 17px;color: red;cursor: pointer" fxLayout="row"
                          fxLayoutAlign="center center">delete</mat-icon>
                      </button>
                    </div>
                  </div>
                  <div fxLayout="row" fxLayoutAlign="center center"
                    *ngIf="!data?.isFreeProduct && ((!data?.isMenuClosed && !data?.isModifierNotAvailable && !data?.isProductNotAvailable) || data?.isUnstock || data?.isDisabled)">
                    <div fxLayout="row" class="quantity-button" fxFlex="40%">
                      <div fxFlex="30%" fxLayout="row" fxLayoutAlign="center center" data-control="decrease-icon"
                        [ngClass]="{'opacity pointernone': (!data?.discountProductId && data?.isUnstock && !data?.isPayXProduct)}"
                        (click)="dialogRef?null:onUpdateCart({operation: '-', quantity: data?.quantity, productDetail: data, cartProductId: data?.id}, index)">
                        -
                      </div>
                      <span fxFlex class="count" fxLayout="row" fxLayoutAlign="center center">{{data?.quantity
                        | number: '1.0-2' }}</span>
                      <div fxFlex="30%" fxLayout="row" fxLayoutAlign="center center" data-control="increace-icon"
                        [ngClass]="{'opacity pointernone': (!data?.discountProductId && data?.isUnstock && !data?.isPayXProduct)}"
                        (click)="dialogRef?null:onUpdateCart({operation: '+', quantity: data?.quantity, productDetail: data , cartProductId: data?.id}, index)">
                        +
                      </div>
                    </div>
                  </div>
                  <div fxLayout="row" fxLayoutAlign="center center"
                    *ngIf="(data?.isMenuClosed || data?.isProductNotAvailable || data?.isModifierNotAvailable || data?.isDisabled) && !data?.isUnstock && !data?.isFreeProduct">
                    <ng-container
                      *ngIf="!data?.isMenuClosed && !data?.isProductNotAvailable && !data?.isDisabled && (data?.modifierNotAvailableMsg || data?.addonNotAvailableMsg)">
                      <span class="error-message"
                        *ngIf="data?.modifierNotAvailableMsg">{{data?.modifierNotAvailableMsg}}</span>
                      <span class="error-message"
                        *ngIf="!data?.modifierNotAvailableMsg && data?.addonNotAvailableMsg">{{data?.addonNotAvailableMsg}}</span>
                    </ng-container>
                    <span class="error-message"
                      *ngIf="data?.isMenuClosed && !data?.isProductNotAvailable">{{'SHARED.NA_TIME' |
                      translate}}</span>
                    <span class="error-message"
                      *ngIf="!data?.isMenuClosed && !data?.isProductNotAvailable">{{data?.qtyUpdateAlert}}</span>
                    <span class="error-message" *ngIf="data?.isProductNotAvailable">{{'SHARED.NA' |
                      translate}}</span>
                  </div>
                </div>
              </div>
            </ng-template>


            <mat-divider style="margin: 5px 0px;"></mat-divider>
          </ng-container>
        </div>
        <ng-template #emptyCart>
          <div style="width: 100%;height: 80vh;" fxLayout="column" fxLayoutAlign="center center">
            <img alt="Empty Bag" [actualImageURL]="awsUrl+'themes/shared-assets/Bag.png'"
              [imageExtension]="imageExtension?.AVIF_FORMAT" [onErrorEvent]="{src:'./assets/image-not-available-3.png'}"
              class="emptycartImage">
            <div fxLayout="column" style="text-align: center;margin-top: -30px;" fxLayoutGap="5px">
              <span style="font-size: 14px;font-weight: bold;">{{'SHARED.EMPTY_BAG' | translate}}</span>
              <span style="font-size: 12px;">{{'SHARED.NO_SELECTION' | translate}}</span>
            </div>
          </div>
        </ng-template>
      </div>
      <div fxLayout="row" fxLayoutAlign="center center" class="navbar-cart-header cart-button">
        <button fxFlex="60%" fxLayout="row" fxLayoutAlign="center center" mat-flat-button type="button"
          class="gotocartButton" (click)="dialogRef?null:navigateToCart()">{{'SHARED.GO_TO_CART' |
          translate}}</button>
      </div>
    </ng-container>
  </mat-drawer>
</mat-drawer-container>


<button mat-fab aria-label="Example icon button with a delete icon" data-control="quick cart" class="quick_cart"
  [ngClass]="isProductDetails ? 'bottom-margin slide-in' :'bottom slide-in'" id="quickCart"
  *ngIf="showCartQuickView  && websiteSettings?.isCartQuickViewEnabled"
  [matTooltip]="'SHARED.CART_QUICK_VIEW' | translate" fxLayout="row" fxLayoutAlign="center center"
  (click)="getProducts()">
  <mat-icon style="color: var(--light-color);">shopping_cart</mat-icon>
</button>