import { Injectable } from '@angular/core';
import { HttpRoutingService } from '@phase-ii/common';
import { API } from 'libs/common/src/lib/constants/api-routes';
import { SharedService, ThemesModuleConstants } from '@phase-ii/shared-theme';
import { of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ThemesService {
  /**
 * Variable which is used to check menu timing availablity
 * @type {array}
 */
  menuTimingAvailablityCheckingIndustryList: string[];
  /**
   * Service constructor which is used to inject the required services.
   * @param httpRoutingService to get the http routing methods from http routing service
   */
  constructor(private httpRoutingService: HttpRoutingService,
    private sharedService: SharedService) {
    this.menuTimingAvailablityCheckingIndustryList = new ThemesModuleConstants().menuTimingAvailablityCheckingIndustryList;

  }
  /**
   * Method getStorePages used to get the all the pages of the current theme of the company
   * @param storeId defines the store id
   */
  getStorePages(data) {
    const url = this.httpRoutingService.replaceUrlParam(API.STORE_PAGES, { storeId: data.storeId });
    return this.httpRoutingService.getMethod(url, { roleId: JSON.stringify(data.roleId), isStoreAdmin: JSON.stringify(false) });
  }
  /**
   * Method getSingleStorePageSetup used to get single pages of the current theme of the company
   * @param data defines the store id & page details
   */
  getSingleStorePageSetup(data) {
    const url = this.httpRoutingService.replaceUrlParam(API.SINGLE_STOREPAGESETUP,{storeId: data.storeId});
    return this.httpRoutingService.getMethod(url  , data);
  }
  /**
   * Method getVerifiedCartProducts used to get the all the cart prodcuts
   * @param customerId defines the customer id 
   */
  getVerifiedCartProducts(customerId, queryParams, storeId?) {
    const url = this.httpRoutingService.replaceUrlParam(API.ADD_GET_CART, { storeId, customerId });
    return this.httpRoutingService.getMethod(url, queryParams);
  }

  /**
 * this method used to fetchReview data.
 */
  fetchReview(storeId, orderId, productId) {
    const url = this.httpRoutingService.replaceUrlParam(API.Fetch_REVIEW, { storeId: storeId, productId: productId });
    return this.httpRoutingService.getMethod(url, { orderId: orderId });
  }

  /**
 * this method used to update review data.
 * @param id unique review id.
 * @param data review data for update.
 * @returns response from backend.
 */
  updateReview(storeId, id, data) {
    const url = this.httpRoutingService.replaceUrlParam(API.UPDATE_REVIEW, { storeId: storeId, reviewId: id });
    return this.httpRoutingService.putMethod(url, data);
  }

  /**
  * Method getSignedImages used to get the signed image ulr of the given images
  * @param pageData defines the page content of a single page
  */
  getSignedImages(pageData) {
    return this.httpRoutingService.postMethod('page/signedImages', pageData);
  }
  // /**
  //  * Method used to get product categories for particular store
  //  * @param storeId id of the store.
  //  * @returns return the response.
  //  */
  // getProductCategories(storeId) {
  //   return this.httpRoutingService.getMethod('stores/' + storeId + '/storefrontcategories');
  // }
  // /**
  //  * Method used to get product categories and sub categories for particular store
  //  * @param storeId id of the store.
  //  * @returns return the response.
  //  */
  // getAllSubCategories(storeId, locationId) {
  //   return this.httpRoutingService.getMethod('stores/' + storeId + '/subcategories/', { locationId: locationId });
  // }
  /**
   * Method getCategoryProducts used to get category wise products.
   * @param storeId id of the store.
   * @param data Data to be passed as query params.
   * @returns return the response.
   */
  getCategoryProducts(storeId, data) {
    const url = this.httpRoutingService.replaceUrlParam(API.CATEGORY_PRODUCTS, { storeId: storeId });
    return this.httpRoutingService.getMethod(url, data);
  }
  /**
   * Method getCategoryBasedBrand used to get the catgory wise brands
   * @param storeId id of the store.
   * @param query query param data
   * @returns the brands based on category selected
   */
  getCategoryBasedBrand(storeId, query) {
    const url = this.httpRoutingService.replaceUrlParam(API.CATEGORY_BASED_BRAND, { storeId: storeId });
    return this.httpRoutingService.getMethod(url, query);
  }
  /**
   * Method getCategoryBasedVariantOptions used to get the catgory wise variant options
   * @param storeId id of the store.
   * @returns the variant options based on category selected
   */
  getCategoryBasedVariantOptions(storeId) {
    const url = this.httpRoutingService.replaceUrlParam(API.CATEGORY_BASED_VARIANTS_OPTIONS, { storeId: storeId });
    return this.httpRoutingService.getMethod(url);
  }

  // /**
  //  * Method used to get products based on the selected category.
  //  * @param storeId id of the store.
  //  * @param data category details and limit , offset details.
  //  * @returns the response.
  //  */
  // getProductByCategory(storeId, data) {
  //   // console.log('data check in service', data);
  //   return this.httpRoutingService.getMethod('store/' + storeId + '/categorywiseProducts', data);
  // }
  // /**
  //  * Method used to get categories details.
  //  * @param storeId id of the store.
  //  * @returns the response.
  //  */
  // getCategories(storeId, locationId?) {
  //   return this.httpRoutingService.getMethod('stores/' + storeId + '/subCategories', { locationId: locationId });
  // }

  /**
   * Function addToCart which is used to add the products into cart
   * @param data it holds the product data into the cart
   * @param storeId have the store id
   * @param customerId have the logged in customer id
   * @returns 
   */
  addToCart(data, storeId, customerId) {
    const url = this.httpRoutingService.replaceUrlParam(API.ADD_GET_CART, { storeId, customerId });
    return this.httpRoutingService.postMethod(url, data);
  }
  /**
   * Function updateCart which is used to update the quantity of the products to the cart.
   * @param data it holds the product data into the cart
   * @param storeId have the store id
   * @param cartProductId have the cart prodduct id od particular product
   * @returns 
   */
  updateCart(data, storeId, cartProductId) {
    const url = this.httpRoutingService.replaceUrlParam(API.UPDATE_CART, { storeId, cartProductId });
    return this.httpRoutingService.putMethod(url, data);
  }
  /**
   * Method addToCartProducts to add product to the cart.
   * @param data holds the customer id and store id.
   * @param cartDetails product details and cart details
   * @param isLoggedIn holds whether logged in or not.
   * @returns returns the response.
   */
  addToCartProducts(data, cartDetails, isLoggedIn?: boolean) {
    const url = this.httpRoutingService.replaceUrlParam(API.ADD_GET_CART, { storeId: data.storeId, customerId: data.customerId });
    return this.httpRoutingService.postMethod(url, isLoggedIn ? { cart: cartDetails, isLoggedIn: true } : cartDetails);
  }
  /**
   * Method addToWishlist to call api of add product to the wishlist.
   * @param data storeId and customerId.
   * @param wishlistDetails product details and wishlist details
   * @returns returns the response.
   */
  addToWishlist(data, wishlistDetails) {
    const url = this.httpRoutingService.replaceUrlParam(API.ADD_TO_WISHLIST, { storeId: data.storeId, customerId: data.customerId });
    return this.httpRoutingService.postMethod(url, wishlistDetails);
  }
  /**
   * Method removeFromWishlist to call api of remove product from wishlist.
   * @param storeId holds the store id.
   * @param productId id of the product.
   * @returns returns the response.
   */
  removeFromWishlist(storeId, data) {
    const url = this.httpRoutingService.replaceUrlParam(API.REMOVE_FROM_WISHLIST, { storeId: storeId, productId: data.productId, variantId: data.variantSkuId });
    return this.httpRoutingService.deleteMethod(url);
  }
  /**
   * Method createReview to call api of create new review.
   * @param productId id of the product.
   * @param data data to be created
   * @returns returns the response.
   */
  createReview(storeId, productId, data) {
    const url = this.httpRoutingService.replaceUrlParam(API.CREATE_REVIEW, { storeId: storeId, productId: productId });
    return this.httpRoutingService.postMethod(url, data);
  }
  // /**
  //  * Method to call api of update the review.
  //  * @param reviewId id of the review.
  //  * @param data data to be updated
  //  * @returns returns the response.
  //  */
  // updateReview(reviewId, data) {
  //   return this.httpRoutingService.putMethod("reviews/" + reviewId, data);
  // }
  /**
   * Method likeClick to call api for clicking like button
   * @param reviewId id of the review.
   * @returns returns the response.
   */
  likeClick(storeId, reviewId) {
    return this.httpRoutingService.getMethod("stores/" + storeId + "/reviews/" + reviewId + "/like");
  }
  /**
   * Method dislikeClick to call api for clicking dislike button
   * @param reviewId id of the review.
   * @returns returns the response.
   */
  dislikeClick(storeId, reviewId) {
    return this.httpRoutingService.getMethod("stores/" + storeId + "/reviews/" + reviewId + "/unlike");
  }
  /**
   * Method getSubCategories used to get Sub categories data.
   * @param storeId id of the store.
   * @param locationId holds the location id.
   * @returns the response.
   */
  getSubCategories(storeId, locationId, searchText, customerId, timeZone, industryName) {
    if (timeZone) {
      const url = this.httpRoutingService.replaceUrlParam(API.SUBCATEGORIES, { storeId });
      return this.httpRoutingService.getMethod(url, { locationId: locationId, value: searchText, customerId: customerId, timeZone: timeZone });
    } else {
      const url = this.httpRoutingService.replaceUrlParam(API.SUBCATEGORIES, { storeId });
      return this.httpRoutingService.getMethod(url, { locationId: locationId, value: searchText, customerId: customerId });
    }
  }
  // /**
  //  * Method used to delete the particular review.
  //  * @param reviewId id of the review to delete.
  //  * @returns the response.
  //  */
  // deleteReview(reviewId) {
  //   return this.httpRoutingService.deleteMethod("productReviews/" + reviewId);
  // }
  /**
   * Method getTopRatedProducts used to get top rated products.
   * @param storeId id of the store.
   * @returns the response.
   */
  getTopRatedProducts(storeId: number, locationId: number) {
    const url = this.httpRoutingService.replaceUrlParam(API.TOP_RATED_PRODUCTS, { storeId: storeId, locationId: locationId });
    return this.httpRoutingService.getMethod(url);
  }

  getRelatedProducts(storeId: number, productId: number | null, locationId: number, customerId: number) {
    if (productId) {
      const url = this.httpRoutingService.replaceUrlParam(API.RELATED_PRODUCTS_LIST, { storeId: storeId, productId: productId });
      return this.httpRoutingService.getMethod(url, { locationId: locationId, customerId: customerId });
    }
    else {
      return of([]);
    }
  }
  /**
   * Method getBestSellerProducts used to get best seller products.
   * @param storeId id of the store.
   * @returns the response.
   */
  getBestSellerProducts(storeId, locationId) {
    const url = this.httpRoutingService.replaceUrlParam(API.BEST_SELLER_PRODUCTS, { storeId: storeId, locationId: locationId });
    return this.httpRoutingService.getMethod(url);
  }
  // /**
  //  * Method used to get testimonial data.
  //  * @param storeId id of the store.
  //  * @returns the response
  //  */
  // getTestimonials(storeId) {
  //   return this.httpRoutingService.getMethod("testimonial/store/" + storeId);
  // }
  /**
   * Method removeCartProducts used to remove a product from cart.
   * @param data holds cart details.
   * @param productId holds product id.
   * @returns the response
   */
  removeCartProducts(storeId, cartProductId) {
    const url = this.httpRoutingService.replaceUrlParam(API.UPDATE_CART, { storeId, cartProductId });
    return this.httpRoutingService.deleteMethod(url, false);
  }
  /**
   * Method getRoleId used to get role id.
   * @param storeId  holds store id.
   * @param roleName holds role name.
   * @returns the response.
   */
  getRoleId(storeId, roleName) {
    const url = this.httpRoutingService.replaceUrlParam(API.GET_ROLE_ID, { storeId: storeId, roleName: JSON.stringify(roleName) });
    return this.httpRoutingService.getMethod(url);
  }
  /**
 * Method getStoreInfo which is used to get store information 
 * @param query To get store name or website url of store
 * @returns 
 */
  getStoreInfo(query: any) {
    return this.httpRoutingService.getMethod('stores/DEFAULT/storedetails', query);
  }
  /**
   * Method getWishlistDetails used to get wishlist data.
   * @param storeId holds store id.
   * @param customerId holds customer id.
   * @param productId holds product id.
   * @returns the response.
   */
  getWishlistDetails(storeId, customerId, productId, data) {
    const url = this.httpRoutingService.replaceUrlParam(API.WISHLIST_DETAIL, { storeId: storeId, customerId: customerId, productId: productId });
    return this.httpRoutingService.getMethod(url, data);
  }
  /**
   * Method getOrderedProducts used to get the ordered product details.
   * @param storeId holds the store id.
   * @param customerId holds the customer id.
   * @param productId holds the product id.
   * @returns the response.
   */
  getOrderedProducts(storeId, customerId, productId) {
    const url = this.httpRoutingService.replaceUrlParam(API.ORDERED_PRODUCTS, { storeId: storeId, customerId: customerId, productId: productId })
    return this.httpRoutingService.getMethod(url);
  }
  /**
   * Method createWaitList used to create a wait list.
   * @param storeId  holds the store id.
   * @param data contains wait list details.
   * @returns the response.
   */
  createWaitList(storeId, data) {
    const url = this.httpRoutingService.replaceUrlParam(API.WAIT_LISTS, { storeId: storeId });
    return this.httpRoutingService.postMethod(url, data);
  }
  /**
   * Method used to get category wise products.
   * @param storeId holds the store id.
   * @param data holds the conditions.
   * @returns the response.
   */
  getProductsWithCategories(storeId, data) {
    const url = this.httpRoutingService.replaceUrlParam(API.PRODUCTS_WITH_CATEGORIES, { storeId: storeId });
    return this.httpRoutingService.getMethod(url, data);
  }
  /**
   * Method used to get category wise products based on selected category id.
   * @param storeId holds the store id.
   * @param data holds the conditions.
   * @returns the response.
   */
  getAllProductsForCategory(storeId, data) {
    const url = this.httpRoutingService.replaceUrlParam(API.GET_ALL_PRODUCTS, { storeId: storeId });
    return this.httpRoutingService.getMethod(url, data);
  }
  /**
   * Method used to get the products based on category ids.
   * @param storeId holds the store id.
   * @param data holds the conditions.
   * @returns the response.
   */
  getAllProductsBySearchText(storeId, data) {
    const url = this.httpRoutingService.replaceUrlParam(API.PRODUCTS_BY_SEARCH_TEXT, { storeId: storeId });
    return this.httpRoutingService.getMethod(url, data);
  }
  /**
 * Method used to get Featured Categories details.
 * @param storeId id of the store.
 * @returns the response.
 */
  getAllFeaturedCategories(storeId, data) {
    const url = this.httpRoutingService.replaceUrlParam(API.FEATURED_CATEGORIES, { storeId });
    return this.httpRoutingService.getMethod(url, data);
  }
  /**
* Method used to get Featured products details.
* @param storeId id of the store.
* @returns the response.
*/
  getAllFeaturedProducts(storeId, data) {
    const url = this.httpRoutingService.replaceUrlParam(API.FEATURED_PRODUCTS, { storeId });
    return this.httpRoutingService.getMethod(url, data);
  }
  /**
   * Method used to get product details for information section.
   * @param productId id of the product.
   * @param param parameter to get product details
   * @returns the response.
   */
  getOneProductDetails(productId, storeId, param) {
    const url = this.httpRoutingService.replaceUrlParam(API.ONE_PRODUCT_DETAIL, {
      productId: productId, storeId: storeId
    });
    return this.httpRoutingService.getMethod(url, param);
  }
  /**
 * Method used to get product details for additional information section.
 * @param productId id of the product.
 * @param param parameter to get product details
 * @returns the response.
 */
  getDetailsOfProduct(productId, storeId, param) {
    const url = this.httpRoutingService.replaceUrlParam(API.DETAILS_OF_PRODUCTS, {
      productId: productId, storeId: storeId
    });
    return this.httpRoutingService.getMethod(url, param);
  }
  /**
* Method used to get featured categories with sub categories.
* @param storeId holds the store id.
* @param locationId holds the location id.
* @returns the response.
*/
  getFeaturedCategoriesWithSubCategories(storeId, locationId?) {
    const url = this.httpRoutingService.replaceUrlParam(API.SUB_FEATURED_CATEGORIES, { storeId });
    return this.httpRoutingService.getMethod(url, { locationId: locationId });
  }
  /**
   * Method getProductsByStore used to get products bsed on store for storefront editor preview.
   * @param storeId id of the store.
   * @param data Data to be passed as query params.
   * @returns return the response.
   */
  getProductsByStore(storeId, data) {
    const url = this.httpRoutingService.replaceUrlParam(API.PRODUCTS_FOR_STOREFRONT_EDITOR, { storeId: storeId });
    return this.httpRoutingService.getMethod(url, data);
  }
  /**
   * Method used to get automatic discount details for a particular product.
   * @param data holds storeId,productId and variantId.
   * @returns the response.
   */
  getAutomaticDiscountForSingleProduct(data) {
    const url = this.httpRoutingService.replaceUrlParam(API.SINGLE_PRODUCT_DISOUNT, { storeId: data?.storeId, productId: data?.productId, variantId: data?.variantId });
    return this.httpRoutingService.getMethod(url, { customerId: data?.customerId, roleId: data?.roleId, currentDate: new Date() });
  }
  /**
   * Method used to get buyx gety discount details for a particular product.
   * @param data holds storeId,productId and variantId.
   * @returns the response.
   */
  getBuyxGetyDetailsForSingleProduct(data) {
    const url = this.httpRoutingService.replaceUrlParam(API.BUYX_GETY_DISCOUNT, { storeId: data?.storeId, productId: data?.productId, variantId: data?.variantId });
    return this.httpRoutingService.getMethod(url, { customerId: data?.customerId, roleId: data?.roleId, currentDate: new Date(), locationId: data?.locationId });
  }
  /**
  * Method used to get categories details in quick order products page.
  * @param storeId id of the store.
  * @param data cart details to get quick order product data.
  * @returns the response.
  */
  getAllQuickOrderProducts(storeId, data) {
    const url = this.httpRoutingService.replaceUrlParam(API.QUICK_ORDER_PRODUCTS, { storeId: storeId });
    return this.httpRoutingService.getMethod(url, data);
  }
  /**
  * Method used to get product list details in quick order products page.
  * @param storeId id of the store.
  * @param locationId location id of the store.
  * @param timeZone time zone of the store.
  * @returns the response.
  */
  getAllCategoryDetails(storeId, locationId?, timeZone?) {
    const url = this.httpRoutingService.replaceUrlParam(API.QUICK_ORDER_CATEGORIES, { storeId: storeId });
    return this.httpRoutingService.getMethod(url, { locationId: locationId, timeZone: timeZone });
  }
  /**
   * Method used to get category name for product.
   * @param storeId holds the store id.
   * @param categoryId holds the category id.
   * @returns the response.
   */
  getCategoryNameForProduct(storeId, categoryId) {
    const url = this.httpRoutingService.replaceUrlParam(API.CATEGORY_NAME_FOR_PRODUCT, { storeId: storeId, categoryId: categoryId });
    return this.httpRoutingService.getMethod(url);
  }
  /**
   * Method createRecentlyViewedProducts used to create or update products in recently viewed.
   * @param storeId id of the store.
   * @param data Data to be passed as body.
   * @returns return the response.
   */
  createRecentlyViewedProducts(storeId, data) {
    const url = this.httpRoutingService.replaceUrlParam(API.RECENTLY_VIEWED_PRODUCTS, { storeId: storeId });
    return this.httpRoutingService.postMethod(url, data);
  }
  /**
  * Method getRecentlyViewedProducts used to fetch products in recently viewed.
  * @param storeId id of the store.
  * @param data Data to be passed as query.
  * @returns return the response.
  */
  getRecentlyViewedProducts(storeId, data) {
    const url = this.httpRoutingService.replaceUrlParam(API.RECENTLY_VIEWED_PRODUCTS, { storeId: storeId });
    return this.httpRoutingService.getMethod(url, data);
  }
  /**
   * Method used to get categories with product count.
   * @param storeId holds the store id.
   * @param locationId holds the location id.
   * @param timeZone holds the time zone.
   * @returns return the categories with product count.
   */
  getCategoriesWithProductCount(storeId, locationId, timeZone) {
    const url = this.httpRoutingService.replaceUrlParam(API.CATEGORIES_PRODUCT_COUNT, { storeId: storeId });
    return this.httpRoutingService.getMethod(url, { locationId: locationId, timeZone: timeZone });
  }
  /**
* Method which is used to get App id.
* @returns response
*/
  getFbPixelCredentials(storeId: any) {
    const url = this.httpRoutingService.replaceUrlParam(API.GET_FB_PIXEL_VALUES, { storeId: storeId });
    return this.httpRoutingService.getMethod(url);
  }
  /**
   * Method used to get single product details.
   * @param productId hold the selected product id.
   * @param storeId hold the store id.
   * @param data data to be passed as query.
   * @returns the details of product.
   */
  getSingleProductDetails(productId, storeId, data) {
    const url = this.httpRoutingService.replaceUrlParam(API.GET_SINGLE_PRODUCT_DETAILS, { storeId: storeId, productId: productId });
    return this.httpRoutingService.getMethod(url, data);
  }
  /**
   * Method used to get all available products name and id.
   * @param storeId hold the store id.
   * @param data data to be passed as query
   * @returns product list with name and id.
   */
  getSingleProductOptions(storeId, data) {
    const url = this.httpRoutingService.replaceUrlParam(API.GET_SINGLE_PRODUCT_OPTIONS, { storeId: storeId });
    return this.httpRoutingService.getMethod(url, data);
  }
  /**
   * Method used to get size chart for single product.
   * @param data holds store id and product id.
   * @returns size chart details.
   */
  getProductSizeChartDetails(data) {
    const url = this.httpRoutingService.replaceUrlParam(API.GET_PRODUCT_SIZE_CHART, { storeId: data.storeId, productId: data.productId });
    return this.httpRoutingService.getMethod(url);
  }
}
