import { AfterViewInit, Directive, ElementRef, Input, Renderer2 } from '@angular/core';
import { fromEvent } from 'rxjs';
import { throttleTime, debounceTime } from 'rxjs/operators';

/**
 * Directive used to set top for attention bar and some other fixed elements based on dynamic top element.
 */
@Directive({
  selector: '[phaseIiDynamicTop]'
})
export class DynamicTopDirective implements AfterViewInit {
  /**
   * Variable used to store top element's class name.
   * @type {string}
   */
  @Input() className = null;
  /**
   * Variable used to store the style property name which is to be changed.
   * @type {string}
   */
  @Input() elementName = null;
  /**
   * Variable used to store the dom element.
   * @type {HTMLElement}
   */
  private domElement: HTMLElement;
  /**
   * Constructor which is used to inject the required service.
  * @param renderer To access methods inside Renderer2.
  * @param elementRef To access methods inside ElementRef.
   */
  constructor(private renderer: Renderer2, private elementRef: ElementRef) {
    this.domElement = this.elementRef.nativeElement as HTMLElement;
    // register on window resize event
    fromEvent(window, "resize")
      .pipe(throttleTime(500), debounceTime(500))
      .subscribe(() => this.setTop());
  }
  /**
   * Component AfterViewInit life cycle hook.
   */
  ngAfterViewInit(): void {
    this.setTop();
  }
  /** 
   * Method used to set top of the element dynamically.
   */
  private setTop() {
    const bannerElement = document.documentElement.getElementsByClassName(this.className) as any;
    const adminTopElement = document.documentElement.getElementsByClassName('nav-toolbar-admin') as any;
    if (adminTopElement && adminTopElement.length) {
      const elementTop = adminTopElement.length === 2 ? (adminTopElement[0].offsetHeight + adminTopElement[1].offsetHeight) : adminTopElement[0].offsetHeight;
      if (this.elementName) {
        if (bannerElement && bannerElement[0]) {
          const topOffset = bannerElement[0].offsetHeight ? bannerElement[0].offsetHeight : 0;
          this.renderer.setStyle(this.domElement, this.elementName, `${this.elementName === 'margin-top' ? (bannerElement[0].offsetTop > topOffset ? (bannerElement[0].offsetTop - topOffset) : bannerElement[0].offsetTop) : topOffset}px`);
        }
      } else {
        this.renderer.setStyle(this.domElement, 'top', `${+elementTop}px`);
      }
    } else {
      const topElement = document.documentElement.getElementsByClassName('nav-toolbar') as any;
      if (this.elementName) {
        if (bannerElement && bannerElement[0]) {
          const topOffset = bannerElement[0].offsetHeight ? bannerElement[0].offsetHeight : 0;
          this.renderer.setStyle(this.domElement, this.elementName, `${(this.elementName === 'margin-top' ? (bannerElement[0].offsetTop - (topElement[0] && topElement[0].offsetHeight)) : (topElement[0] && topElement[0].offsetHeight)) + topOffset}px`);
        }
        else if (topElement && topElement[0]) {
          this.renderer.setStyle(this.domElement, 'top', `${topElement[0].offsetHeight}px`);
        }
      }
      else if (topElement && topElement[0]) {
        this.renderer.setStyle(this.domElement, 'top', `${topElement[0].offsetHeight}px`);
      }
    }
  }
}
