<cen-loader-vii *ngIf="isLoading">
</cen-loader-vii>
<div>
    <phase-ii-header-page [heading]="heading" [buttonInfo]="buttonInfo" (methodClicked)="functionTriggered($event)">
    </phase-ii-header-page>
    <div fxLayout="row" fxLayoutAlign="center center" class="page-container">
        <div fxFlex="68%" mat-elevation-z11 [ngClass]="{'outer-card': !isLoading}">
            <div fxLayout="row" *ngIf="!isLoading">
                <h2 class="heading" data-control="billing-information">Billing Information</h2>
                <span fxFlex></span>
                <div fxLayoutAlign="none center">
                    <button mat-raised-button class="primary-button" *ngIf="!isEdit && customerCardDetails"
                        type="button" (click)="editCard()" data-control="cardEdit"> Edit </button>
                </div>
            </div>
            <div *ngIf="!isEdit && customerCardDetails && !isLoading" data-control="savedCardContainer">
                <div data-control="cardHolderName">
                    <p fxFlex="40" class="title"> Card Holder Name </p>
                    <p fxFlex=5>:</p>
                    <p fxFlex="55">{{customerCardDetails?.name ? customerCardDetails?.name : '-'}}</p>
                </div>
                <div data-control="cardNumber">
                    <p fxFlex="40" class="title"> Card Number </p>
                    <p fxFlex=5>:</p>
                    <p fxFlex="55">**** **** **** {{customerCardDetails?.last4}}</p>
                </div>
                <div data-control="cvc">
                    <p fxFlex="40" class="title"> CVC </p>
                    <p fxFlex=5>:</p>
                    <p fxFlex="55">***</p>
                </div>
                <div data-control="expiry">
                    <p fxFlex="40" class="title"> Expired on </p>
                    <p fxFlex=5>:</p>
                    <p fxFlex="55">{{customerCardDetails?.exp_month}} / {{customerCardDetails?.exp_year}}</p>
                </div>
            </div>
            <form *ngIf="(isEdit || !customerCardDetails) && paymentType === 'CHECKOUT'" id="payment-form">
                <div class="one-liner">
                    <div class="card-frame"></div>
                </div>
                <p class="error-message"></p>
            </form>
            <div id="cardInfoGroup" data-control="stripeCard">
            </div>
            <form [formGroup]="billingInfo" *ngIf="(isEdit || !customerCardDetails) && billingInfo && !isLoading">
                <mat-form-field class="top-space full-width" appearance="outline" *ngIf="billingInfo?.get('name')">
                    <mat-label>Name on Card</mat-label>
                    <input matInput placeholder="Name On Card" type="text" size="20" autocomplete="off"
                        formControlName="name" required data-control="cardName" trimVal/>
                    <mat-error *ngIf="billingInfo?.get('name')?.errors && billingInfo?.get('name')?.touched">
                        <span *ngFor="let k of billingInfo?.get('name')?.errors | keyvalue"
                            [attr.data-control]="'card name '+ k?.key | dataControl">
                            {{ (errorMessage?.ERROR)['NAME'][k.key] }}</span>
                    </mat-error>
                </mat-form-field>
                <h2 class="heading" data-control="billing address">Billing Address</h2>
                <div fxLayout="row" fxLayoutGap="15px" fxLayoutGap.xs='0' fxLayout.xs="column">
                    <mat-form-field fxFlex="38%" appearance="outline" *ngIf="billingInfo?.get('streetName')">
                        <mat-label>Address Line 1</mat-label>
                        <input matInput placeholder="Address Line 1" required type="text" size="20" autocomplete="off"
                            formControlName="streetName" data-control="addressLineOne" trimVal/>
                        <mat-error
                            *ngIf="billingInfo?.get('streetName')?.errors &&billingInfo?.get('streetName')?.touched">
                            <span *ngFor="let k of billingInfo?.get('streetName')?.errors | keyvalue"
                                [attr.data-control]="'address line one '+ k?.key | dataControl">
                                {{ (errorMessage?.ERROR)['ADDRESS_LINE'][k.key] }}</span>
                        </mat-error>
                    </mat-form-field>
                    <mat-form-field fxFlex="25%" appearance="outline" *ngIf="billingInfo?.get('doorNo')">
                        <mat-label>Address Line 2</mat-label>
                        <input matInput placeholder="Address Line 2" type="text" size="20" autocomplete="off"
                            formControlName="doorNo" data-control="addressLineTwo" trimVal/>
                        <mat-error *ngIf="billingInfo?.get('doorNo')?.errors &&billingInfo?.get('doorNo')?.touched"
                            data-control="addressLineTwoValidError">
                            Please enter a valid address.
                        </mat-error>
                    </mat-form-field>
                    <mat-form-field fxFlex="31%" appearance="outline" *ngIf="billingInfo?.get('city')">
                        <mat-label>City</mat-label>
                        <input matInput placeholder="City" type="text" size="20" autocomplete="off"
                            formControlName="city" required data-control="city" trimVal/>
                        <mat-error *ngIf="billingInfo?.get('city')?.errors &&billingInfo?.get('city')?.touched">
                            <span *ngFor="let k of billingInfo?.get('city')?.errors | keyvalue"
                                [attr.data-control]="'city '+k?.key | dataControl">
                                {{ (errorMessage?.ERROR)['CITY'][k.key] }}</span>
                        </mat-error>
                    </mat-form-field>
                </div>
                <div fxLayout="row" fxLayoutGap="15px" fxLayoutGap.xs='0' fxLayout.xs="column">
                    <mat-form-field fxFlex="31%" appearance="outline" *ngIf="billingInfo?.get('country')">
                        <mat-label>Country</mat-label>
                        <mat-select required (selectionChange)="getStates()" formControlName="country"
                            hideSingleSelectionIndicator data-control="country">
                            <div fxLayout="row" class="search-country">
                                <input fxFlex="90%" fxFlex.lt-md="92%" fxFlex.lt-sm="85%" data-control="searchCountryFl"
                                    placeholder="Search Country" [formControl]="countrySearchFilter"
                                    (keyup)="onCountrySearchFilter($event?.target?.value)"
                                    data-control="country search">
                                <mat-icon class="close-icon" *ngIf="countrySearchFilter?.value" fxFlex="10%"
                                    fxFlex.lt-md="8%" fxFlex.lt-sm="15%" (click)="onCountryFilterClose()"
                                    data-control="closeIconCountry" data-control="country search close">
                                    close
                                </mat-icon>
                            </div>
                            <mat-divider></mat-divider>
                            <mat-option *ngIf="searchCountries?.length == 0" data-control="no country">No Countries
                                Found
                            </mat-option>
                            <mat-option *ngFor="let country of searchCountries" [value]="country"
                                [attr.data-control]="'country '+country?.name | dataControl">
                                {{country?.name}}
                            </mat-option>
                        </mat-select>
                        <mat-error *ngIf=" billingInfo?.get('country')?.errors &&billingInfo?.get('country')?.touched">
                            <span *ngFor="let k of billingInfo?.get('country')?.errors | keyvalue"
                                [attr.data-control]="'country '+k?.key | dataControl">
                                {{ (errorMessage?.ERROR)['COUNTRY'][k.key] }}</span>
                        </mat-error>
                    </mat-form-field>
                    <mat-form-field fxFlex="32%" appearance="outline" *ngIf="billingInfo?.get('state')">
                        <mat-label>State</mat-label>
                        <mat-select required formControlName="state" data-control="state" hideSingleSelectionIndicator>
                            <div fxLayout="row" class="search-country">
                                <input fxFlex="90%" placeholder="Search State" [formControl]="stateSearchFilter"
                                    data-control="stateSearch" (keyup)="onStateSearchFilter($event?.target?.value)"
                                    autocomplete="off" data-control="state search">
                                <mat-icon class="close-icon" fxFlex="10%" (click)="clearStateFilter()"
                                    data-control="closeiconState" *ngIf="stateSearchFilter?.value"
                                    data-control="state search close">
                                    close
                                </mat-icon>
                            </div>
                            <mat-divider></mat-divider>
                            <mat-option *ngIf="searchStates?.length == 0" data-control="no state">No State Found
                            </mat-option>
                            <mat-option *ngFor="let state of searchStates" [value]="state"
                                [attr.data-control]="'state '+state?.name | dataControl">
                                {{state?.name}}
                            </mat-option>
                        </mat-select>
                        <mat-error *ngIf="billingInfo?.get('state')?.errors &&billingInfo?.get('state')?.touched">
                            <span *ngFor="let k of billingInfo?.get('state')?.errors | keyvalue"
                                [attr.data-control]="'state '+k?.key | dataControl">
                                {{ (errorMessage?.ERROR)['STATE'][k.key] }}</span>
                        </mat-error>
                    </mat-form-field>
                    <mat-form-field fxFlex="31%" appearance="outline" *ngIf="billingInfo?.get('zipCode')">
                        <mat-label>Zip Code</mat-label>
                        <input matInput placeholder="Zip Code" type="text" size="20" autocomplete="off"
                            formControlName="zipCode" required data-control="zipcode" trimVal/>
                        <mat-error data-control="zipCodeError" *ngIf="billingInfo?.get('zipCode')?.hasError('required')"
                            data-control="zipcodeRequiredError">
                            A zip code is required.
                        </mat-error>
                        <mat-error data-control="zipCodeErrorMaxlength"
                            *ngIf="billingInfo?.get('zipCode')?.hasError('maxlength')">
                            Zipcode should not exceed 15 characters.
                        </mat-error>
                    </mat-form-field>
                </div>
            </form>
            <div *ngIf="(!customerCardDetails || isEdit) && !isLoading" fxLayoutGap="10px"
                fxLayoutAlign="center center">
                <button mat-stroked-button type="button" (click)="cancelCard()" data-control="cancelCard">
                    Cancel
                </button>
                <button mat-raised-button class="primary-button" type="button" [disabled]="isButtonLoading"
                    (click)="onSubmit()" data-control="saveButton">
                    <div *ngIf="!isButtonLoading">
                        Save
                    </div>
                    <cen-loader style="width: 10px;" *ngIf="isButtonLoading" [type]="'content'"
                        [name]="'cen-dot-spinner'">
                    </cen-loader>
                </button>
            </div>
        </div>
    </div>
</div>