import { Inject, Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';
import { dataConfig } from 'libs/common/src/lib/services/config';
// import { Workbook } from 'exceljs'; unused......
import { BehaviorSubject } from 'rxjs';
import * as _ from 'lodash';
/**
* Common data service used to access from all component.
*/
@Injectable({
  providedIn: 'root',
})
export class CommonDataService {

  /**
   * Variable used to store shipping method
   */
  shippingMethod = [];
  /**
   * Variable used to store environment details
   */
  environment: any;
  /**
   * Variable used to store isDisabled details
   */
  isDisabled: boolean;
  /**
   * Variable used to store store currency details
   */
  storeCurrency = new BehaviorSubject<any>(null);
  /**
   * Variable used to store common website details
   */
  commonWebsiteDetails = new BehaviorSubject<any>(null);

  /**
   * Constructor which is used to inject services.
   */
  constructor(@Inject('environment') environment) {
    this.environment = environment;
  }
  /**
   * Method which is used to encrypt the param value.
   * @param data Parameter which is used to hold the data to encrypt.
   */
  setParamsObj(data) {
    if (data) {
      return CryptoJS.AES.encrypt(JSON.stringify(data), dataConfig.secretKey).toString().replace(/\+/g, 'p1L2u3S').replace(/\//g, 's1L2a3S4h').replace(/=/g, 'e1Q2u3A4l');
    }
  }
  /**
   * Method which is used to encrypt the param value.
   * @param data Parameter which is used to hold the data to encrypt.
   */
  setParam(data) {
    if (data) {
      return CryptoJS.AES.encrypt(data.toString(), dataConfig.secretKey).toString().replace(/\+/g, 'p1L2u3S').replace(/\//g, 's1L2a3S4h').replace(/=/g, 'e1Q2u3A4l');
    }
  }
  /**
   * Method which is used to decrypt the param value.
   * @param ciphertext Parameter Which is used to hold the data to decrypt.
   */
  getParam(ciphertext) {
    if (ciphertext) {
      const bytes = CryptoJS.AES.decrypt(ciphertext.toString().replace(/p1L2u3S/g, '+').replace(/s1L2a3S4h/g, '/').replace(/e1Q2u3A4l/g, '='), dataConfig.secretKey);
      return bytes.toString(CryptoJS.enc.Utf8);
    } else {
      return false;
    }
  }
  /**
   * function for encrypting the secured details
   * @param data which is used to get the data
   */
  encryptDetails = function (data) {
    const text = CryptoJS.AES.encrypt(data.toString(), dataConfig.encryptionSecretKey).toString();
    return text.replace(/\\/g, '|');
  }
  /**
   * function for decrypting the secured details
   * @param data which is used to get the data
   */
  decryptDetails = function (data) {
    if (data) {
      const bytes = CryptoJS.AES.decrypt(data.toString(), dataConfig.encryptionSecretKey);
      const result = bytes.toString(CryptoJS.enc.Utf8).replace('|', /\\/g);
      return result;
    } else {
      return null;
    }
  }
  /**
   * function for create xlsx
   * @param file which is used for get the href
   * @param fileName which is used to get the file name
   */
  createXLSX(file, fileName) {
    const tempLink = document.createElement('a');
    tempLink.href = file;
    document.body.appendChild(tempLink);
    tempLink.download = (fileName ? ('export_' + fileName) : 'ExportFile') + '.xlsx';
    tempLink.click();
    tempLink.remove();
    return true;
  }
  /**
  * function for change buffer
  * @param s which is used for get the value
  */
  changeBuffer(s) {
    const buf = new ArrayBuffer(s.length); //convert s to arrayBuffer
    const view = new Uint8Array(buf);  //create uint8array as viewer
    // tslint:disable-next-line:no-bitwise
    for (let i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xFF; //convert to octet
    return view;
  }
  // /**
  // * function for send message
  // * @param data which is used for get the data
  // */
  // whatsappMessage(data: any) {
  //   if (data && data.text && data.phoneNumber && data.countryCode) {
  //     const link = "https://web.whatsapp.com/send?text=" + data.text + "&phone=" + data.countryCode + data.phoneNumber;
  //     window.open(link, '_blank');
  //   } else {
  //     throw new Error("countryCode or text or phoneNumber value are missing!")
  //   }
  // }

  // getScreenSize(height: number, width: number) {
  //   if (height <= 800 && width <= 600) {
  //     return true;
  //   }
  //   else {
  //     return false;
  //   }
  // }
  /**
   * function for encrypting the secured details
   * @param data which is used to get the data
   */
  encryptOrder = function (data) {
    const key = CryptoJS.SHA256(dataConfig.encryptionSecretKey);
    const encrypted = CryptoJS.AES.encrypt(data, key, {
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Pkcs7
    });
    return encrypted.toString();
  }
  /**
   * function for decrypting the secured details
   * @param data which is used to get the data
   */
  decryptOrder = function (data) {
    const key = CryptoJS.SHA256(dataConfig.encryptionSecretKey);
    const decrypted = CryptoJS.AES.decrypt(data, key, {
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Pkcs7
    });
    return decrypted.toString(CryptoJS.enc.Utf8);
  }
}