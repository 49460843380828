import { Injectable } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { AuthService } from '@phase-ii/auth';
import { HttpRoutingService } from 'libs/common/src/lib/services/httpRouting.service';
// import { API } from './../constants/api-routes';
import { API } from '@phase-ii/common';
import { BehaviorSubject } from 'rxjs';

/**
 * Service used for the define the required users service API 
 */
@Injectable({
    providedIn: 'root'
})
export class UsersService {
    /**
  * Variable used to store the addtemplate details
  */
    addTemplate = new BehaviorSubject<any>(null);
    /**
      * Service constructor which is used to inject the required services.
      * @param httpRoutingService To access methods from http service
      * @param authService To access methods from auth service
      * @param sanitizer To access methods from sanitizer service
     */
    constructor(private httpRoutingService: HttpRoutingService,
        private sanitizer: DomSanitizer, private authService: AuthService) { }
    /**
     * This method used to get all roles
     * @param storeId storeId
     * @param data filter data
     * @returns fetched data
     */
    getAllRoles(storeId, data?: any) {
        const url = this.httpRoutingService.replaceUrlParam(API.ROLES, { storeId: storeId });
        return this.httpRoutingService.getMethod(url, data);
    }
    /**
    * This method is used to create role 
    * @param data role data
    * @param storeId store id
    * @returns response
    */
    createRole(data, storeId) {
        const url = this.httpRoutingService.replaceUrlParam(API.ROLES, { storeId: storeId });
        return this.httpRoutingService.postMethod(url, data);
    }
    /**
     * This method is used to update existing role
     * @param data role data
     * @param id role id
     * @returns 
     */
    updateRole(data, id, storeId) {
        const url = this.httpRoutingService.replaceUrlParam(API.ROLE_ONE, { storeId: storeId, id: id });
        return this.httpRoutingService.putMethod(url, data);
    }
    /**
    * This method is used to delete a rolw
    * @param id role id
    * @returns deletion response
    */
    deleteRole(id, storeId) {
        const url = this.httpRoutingService.replaceUrlParam(API.ROLE_ONE, { storeId: storeId, id: id });
        return this.httpRoutingService.deleteMethod(url);
    }
    /**
     * This method is used to get one role data
     * @param id role id
     * @returns fetched role data
     */
    getOneRole(id, storeId) {
        const url = this.httpRoutingService.replaceUrlParam(API.ROLE_ONE, { storeId: storeId, id: id });
        return this.httpRoutingService.getMethod(url);
    }
    /**
     * Method used to get all the invite reason os store
     * @param storeId has storeId of logged in user
     * @returns 
     */
    getAllInvitationReasons(storeId) {
        const url = this.httpRoutingService.replaceUrlParam(API.GET_ALL_INVITATION_REASONS, { storeId: storeId });
        return this.httpRoutingService.getMethod(url);
    }
    /**
     * Method which is used for get a particular invitation details
     * @param storeId has storeId of logged in user
     * @param data holds the token details
     * @returns 
     */
    getOneInvitation(storeId, data) {
        const url = this.httpRoutingService.replaceUrlParam(API.GET_ONE_INVITATION, { storeId: storeId });
        return this.httpRoutingService.postMethod(url, data);
    }
    /**
     * Function which is used to invita user to particular role
     * @param storeId has storeId of logged in user
     * @param data holds the data to create entry
     * @returns 
     */
    createInvitation(storeId, data) {
        const url = this.httpRoutingService.replaceUrlParam(API.CREATE_INVITATION, { storeId: storeId });
        return this.httpRoutingService.postMethod(url, data);
    }
    /**
     * Function which is used to resend invition to particular role
     * @param storeId has storeId of logged in user
     * @param id has invitation id of already send invitation
     * @param data holds the data to create entry
     * @returns 
     */
    resendInvitationMail(storeId, id, data) {
        const url = this.httpRoutingService.replaceUrlParam(API.RESEND_INVITATION, { storeId: storeId, id: id });
        return this.httpRoutingService.putMethod(url, data);
    }
    /**
     * Function which is used to create user entries
     * @param storeId has storeId of logged in user
     * @param data holds the data to create entry
     * @returns 
     */
    // createUserInfo(storeId, data) {
    //     return this.httpRoutingService.postMethod('stores/' + storeId + '/users/inviteuser', data);
    // }
    /**
    * Function which is used to create user entries
    * @param storeId has storeId of logged in user
    * @param data holds the data to create entry
    * @returns 
    */
    createUserInfo(storeId: any, data: any) {
        const key = 'registration';
        this.authService.getBase64String(data.email, data.password, 'stores/' + storeId + (data.isFromMail ? '/staff/invite' : '/staff'), key);
        delete data.email;
        delete data.password;
        return this.httpRoutingService.postMethod('stores/' + storeId + (data.isFromMail ? '/staff/invite' : '/staff'), data);
    }
    /**
     * Method which is used for get a particular user's info
     * @param storeId has storeId of logged in user
     * @param userId holds the id of user to get
     * @returns 
     */
    getOneUserInfo(storeId: number, userId: number, clientId: any) {
        const url = this.httpRoutingService.replaceUrlParam(API.USER_ONE, { storeId: storeId, userId: userId });
        return this.httpRoutingService.getMethod(url, clientId ? { clientId: clientId } : null);
    }
    /**
     * Method which is used for update the user details
     * @param storeId has storeId of logged in user
     * @param userId holds the id of user to update
     * @param data holds the data to update the user
     * @returns 
     */
    updateUserInfo(storeId: any, userId: any, data: any) {
        const url = this.httpRoutingService.replaceUrlParam(API.USER_ONE, { storeId: storeId, userId: userId });
        return this.httpRoutingService.putMethod(url, data);
    }
    /**
     * Method used to get admin roles
     * @param storeId storeId
     * @returns fetched data
     */
    getAdminRoles(storeId) {
        const url = this.httpRoutingService.replaceUrlParam(API.ADMIN_ROLES, { storeId: storeId });
        return this.httpRoutingService.getMethod(url);
    }
    getAllLoginDetails(data, storeId, userId) {
        const url = this.httpRoutingService.replaceUrlParam(API.LOGIN_HISTORY, { storeId: storeId, userId: userId })
        return this.httpRoutingService.getMethod(url, data);
    }
    /**
     * Method used to get all the invited userd of store
     * @param storeId has storeId of logged in user
     * @param data has limit and offset related details
     * @returns 
     */
    getAllInvitations(storeId, data) {
        const url = this.httpRoutingService.replaceUrlParam(API.GET_ALL_INVITATION, { storeId: storeId });
        return this.httpRoutingService.getMethod(url, data);
    }
    /**
     * Function which is used to delete invited user details from list
     * @param storeId has storeId of logged in user
     * @param invitationId holds the id to remove
     * @returns 
     */
    deleteInvitation(storeId, invitationId) {
        const url = this.httpRoutingService.replaceUrlParam(API.DELETE_INVITATION, { storeId: storeId, id: invitationId });
        return this.httpRoutingService.deleteMethod(url);
    }
    /**
     * Function which is used to create customer entries
     * @param storeId has storeId of logged in user
     * @param data holds the data to create entry
     * @returns 
     */
    createCustomer(storeId, data) {
        const url = this.httpRoutingService.replaceUrlParam(API.CUSTOMERS, { storeId: storeId });
        return this.httpRoutingService.postMethod(url, data);
    }
    /**
     * Function which is used to create customer group entries
     * @param storeId has storeId of logged in user
     * @param data holds the data to create entry
     * @returns 
     */
    createCustomerGroup(storeId, data) {
        const url = this.httpRoutingService.replaceUrlParam(API.CUSTOMER_GROUP, { storeId: storeId });
        return this.httpRoutingService.postMethod(url, data);
    }
    /**
     * Method which is used for get a particular customer group info
     * @param storeId has storeId of logged in user
     * @param customerGroupId holds the id of customer to get
     * @returns 
     */
    getOneCustomerGroup(storeId, customerGroupId) {
        const url = this.httpRoutingService.replaceUrlParam(API.CUSTOMER_GROUP_ONE, { storeId: storeId, customerGroupId: customerGroupId });
        return this.httpRoutingService.getMethod(url);
    }
    /**
     * Method which is used for update the customer group details
     * @param storeId has storeId of logged in user
     * @param customerGroupId holds the id of customer group to update
     * @param data holds the data to update the customer group
     * @returns 
     */
    updateCustomerGroup(storeId, customerGroupId, data) {
        const url = this.httpRoutingService.replaceUrlParam(API.CUSTOMER_GROUP_ONE, { storeId: storeId, customerGroupId: customerGroupId });
        return this.httpRoutingService.putMethod(url, data);
    }
    /**
     * Method used to get all users
     * @param data filter data
     * @param storeId storeId
     * @returns fetched data
     */
    getAllUsers(data, storeId, uuid) {
        const url = this.httpRoutingService.replaceUrlParam(API.USERS, { storeId: storeId, uuid: uuid, isActive: data.isActive });
        return this.httpRoutingService.getMethod(url);
    }
    /**
     * Method used to update user
     * @param storeId storeId
     * @param id update particular user data
     * @param data filter data
     * @returns fetched data
     */
    userStatusUpdation(storeId, id, data) {
        const url = this.httpRoutingService.replaceUrlParam(API.USER_STATUS, { storeId: storeId, id: id });
        return this.httpRoutingService.putMethod(url, data);
    }
    /**
     * Method used to get the customer group
     * @param data used to fetch the customer group
     * @param storeId storeId
     * @returns fetched data
     */
    getCustomerGroup(data, storeId) {
        const url = this.httpRoutingService.replaceUrlParam(API.CUSTOMER_GROUP, { storeId: storeId });
        return this.httpRoutingService.getMethod(url, data);
    }
    /**
     * Method used to delete a particular customer group.
     * @param storeId unique store id.
     * @param id customer group id to delete.
     * @returns 
    */
    deleteCustomerGroup(storeId, customerGroupId) {
        const url = this.httpRoutingService.replaceUrlParam(API.CUSTOMER_GROUP_ONE, { storeId: storeId, customerGroupId: customerGroupId });
        return this.httpRoutingService.deleteMethod(url);
    }
    /**
     * Method used to get all the customer mapping of customer group
     * @param storeId Hold the store id of login user
     * @param groupId To have selected customer group id
     * @param data To have limit and offset details
     */
    getAllCustomerGroupMapping(storeId, groupId, data) {
        const url = this.httpRoutingService.replaceUrlParam(API.CUSTOMER_GROUP_CUSTOMERS, { storeId: storeId, groupId: groupId });
        return this.httpRoutingService.getMethod(url, data);
    }
    /**
    * Method which is used to display the chip in table.
    * @param tabName To define the selected tab.
    * 
    */
    tableChip(chipText, bgcolor?, color?) {
        bgcolor = bgcolor ? bgcolor : 'green';
        color = color ? color : 'white';
        return this.sanitizer.bypassSecurityTrustHtml(
            `<span style="font-size:12px;padding:2px 10px 4px 10px; border-radius:50px; color:${color}; background-color:${bgcolor}">${chipText}</span>`);
    }
    /**
     * Method used to get all the inventory locations.
     * @param storeId has storeId of logged in user
     * @returns 
     */
    getInventoryLocations(storeId, isFromMail?: boolean) {
        return this.httpRoutingService.getMethod('stores/' + storeId + '/locations/v3', { isForUser: true });
    }
    /**
     * Method used to fetch the notification details
     * @param notificationId which detaild to fetch
     * @param storeId storeId
     * @returns fetched data
     */
    getOneNotification(notificationId, storeId) {
        const url = this.httpRoutingService.replaceUrlParam(API.PUSH_NOTIFICATION, { storeId: storeId, notificationId: notificationId });
        return this.httpRoutingService.getMethod(url);
    }
    /**
     * Method used to fetch the user count
     * @param storeId StoreId
     * @returns fetch the user count of the store
     */
    getAllUserCount(storeId) {
        const url = this.httpRoutingService.replaceUrlParam(API.USER_COUNT, { storeId: storeId });
        return this.httpRoutingService.getMethod(url);
    }
    /**
     * Method used to fetch the user plan feature limit
     * @param storeId storeId
     * @param featureCode used to fetch that particular feature count
     * @returns fetched data
     */
    getplanLimit(storeId, featureCode, clientId) {
        const url = this.httpRoutingService.replaceUrlParam(API.GET_PLAN_LIMIT, { storeId: storeId, featureCode: featureCode, clientId: clientId });
        return this.httpRoutingService.getMethod(url);
    }
    /**
     * Method used to fetch the location for the users
     * @param storeId storeId
     * @returns fetched data
     */
    getUserLocation(storeId) {
        const url = this.httpRoutingService.replaceUrlParam(API.USER_STORE_LOCATIONS, { storeId: storeId });
        return this.httpRoutingService.getMethod(url);
    }
    getOneStaffInvitation(id: number, storeId: number) {
        const url = this.httpRoutingService.replaceUrlParam(API.GET_ONE_STAFF_INVITATION, { storeId: storeId, id: id });
        return this.httpRoutingService.getMethod(url);
    }
    /**
  * This method used to get all stores
  * @returns 
  */
    getAllStoresName() {
        return this.httpRoutingService.getMethod('storenames');
    }
    /**
    * this method used to import requested data
    * @param apiUrlDb backend url
    * @param files files to be imported
    * @param method which holds method name.
    * @param formField constrains
    * @param mainImage images to upload
    * @returns 
    */
    importData(apiUrlDb, files, method, formField?, mainImage?, queryParams?) {
        const storageImage = [];
        const formData: any = new FormData();
        if (formField && formData) {
            formData.append('data', JSON.stringify(formField));
        }
        if (mainImage) {
            formData.append("mainImage", mainImage, mainImage['name']);
        }
        for (let i = 0; i < (files && files.length); i++) {

            if (files[i] && files[i].name && (files[i].name.substring(files[i].name.lastIndexOf('.')) === '.xlsx' || files[i].name.substring(files[i].name.lastIndexOf('.')) === '.csv')) {
                formData.append("xlsxFiles", files[i], files[i]['name']);
            } else if (files[i] && files[i].name && files[i].name.substring(files[i].name.lastIndexOf('.')) !== '.xlsx' && !files[i].changeFileName) {
                formData.append("images", files[i], files[i]['name']);
            }
            else if (files[i] && files[i].name && files[i].name.substring(files[i].name.lastIndexOf('.')) !== '.xlsx' && files[i].changeFileName) {
                formData.append("images", files[i], files[i]['changeFileName']);
            }
            else {
                storageImage.push(files[i]);
            }
        }
        formData.append("storageImage", JSON.stringify(storageImage));
        // return this.httpRoutingService[method](apiUrlDb, formData);
        return this.httpRoutingService[method](apiUrlDb, formData, queryParams ? queryParams : null);
    }
}