/**
 * Service used for get blog related information
 * Author:Ponmani B
 */
import { Injectable } from '@angular/core';
import { HttpRoutingService, API } from '@phase-ii/common';
/**
 * Service used to make http requests.
 */
@Injectable({
  providedIn: 'root'
})
export class BlogsService {
  /**
  * component constructor which is used to inject the required services and initialise the variables.
  *  @param httpRoutingService To get and use the functions and observables in httpRoutingService Service.
  */
  constructor(
    private httpRoutingService: HttpRoutingService,
  ) { }

  /**
* Method getOneBlogDetails which is used to get one blog details.
*/
  getOneBlogDetails(storeId, id, languageCode) {
    const url = this.httpRoutingService.replaceUrlParam(API.GET_ONE_BLOG_DETAIL, { storeId: storeId, blogId: id })
    return this.httpRoutingService.getMethod(url, { languageCode: languageCode });
  }

  /**
* Method postComment which is used to post comment for particular blog.
*/
  postComment(storeId, data) {
    const url = this.httpRoutingService.replaceUrlParam(API.POST_COMMAND, { storeId: storeId })
    return this.httpRoutingService.postMethod(url, { blogId: data.blogId, comment: data });
  }

  /**
* Method getAllCommentDetails which is used to get all comment for particular blog.
*/
  getAllCommentDetails(storeId, id) {
    const url = this.httpRoutingService.replaceUrlParam(API.GET_ONE_BLOGCOMMENT, { storeId: storeId, id: id })
    return this.httpRoutingService.getMethod(url);
  }

  /**
* Method getAllblogDetails which is used to get all blog details.
*/
  getAllblogDetails(storeId, data) {
    const url = this.httpRoutingService.replaceUrlParam(API.LIST_ALL_BLOG, { storeId: storeId })
    return this.httpRoutingService.getMethod(url, data);
  }

  /**
* Method getAllBlogCategory which is used to get all blog category details.
*/
  getAllBlogCategory(storeId) {
    const url = this.httpRoutingService.replaceUrlParam(API.GET_ALL_CATEGORY_LIST_FOR_STOREFRONT, { storeId: storeId })
    return this.httpRoutingService.getMethod(url);
  }
}
