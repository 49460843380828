import { Injectable } from '@angular/core';
import { API } from 'libs/common/src/lib/constants/api-routes';
import { HttpRoutingService } from 'libs/common/src/lib/services/httpRouting.service';

@Injectable({
  providedIn: 'root'
})
export class ReferralCodeService {
  /**
   * @param commonService To access service from httprouting service
   */
  constructor(private httpService: HttpRoutingService) { }
  /**
  * Method used to get details to show referral code details.
  * @param data holds data.
  * @returns http response.
  */
  getReferralCode(data) {
    return this.httpService.getMethod(API.AFFILIATE_REFERRAL_CODE, data);
  }

  /**
 * Method used to delete referral code.
 * @param referralId holds referral id.
 * @returns http response.
 */
  deleteReferralCode(referralId) {
    const url = this.httpService.replaceUrlParam(API.AFFILIATE_REFERRAL_CODE_UPDATION, { id: referralId });
    return this.httpService.deleteMethod(url);
  }

  /**
 * Method used to update referral code details.
 * @param referralId holds referral id.
 * @param data holds data.
 * @returns http response.
 */
  updateReferralCode(referralId, data) {
    const url = this.httpService.replaceUrlParam(API.AFFILIATE_REFERRAL_CODE_UPDATION, { id: referralId });
    return this.httpService.putMethod(url, data);
  }

  /**
 * Method used to create referral code details.
 * @param data holds data.
 * @returns http response.
 */
  createReferralCode(data) {
    return this.httpService.postMethod(API.AFFILIATE_REFERRAL_CODE, data);
  }

  /**
 * Method used to create referral code customer details.
 * @param data holds data.
 * @returns http response.
 */
  getReferralCustomer(id,data){
    const url = this.httpService.replaceUrlParam(API.AFFILIATE_REFERRAL_CUSTOMER, { id: id }); 
    return this.httpService.getMethod(url, data);
  }
  
  /**
   * Method used to create referral code commission details.
   * @param data holds data.
   * @returns http response.
   */
  getreferralCommission(data){
    return this.httpService.getMethod(API.AFFILIATE_REFERRAL_COMMISSION,data);
  }
}
