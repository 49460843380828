<cen-loader-vii *ngIf="pageDetails?.isLoader"></cen-loader-vii>
<phase-ii-header-page [heading]="pageDetails?.heading" [buttonInfo]="pageDetails?.buttonInfo"
  (methodClicked)="functionTriggered($event)"></phase-ii-header-page>
<div class="page-container" fxLayout="column">
  <div *ngIf="settingsForm" fxFlex="100">
    <form [formGroup]="settingsForm">
      <div fxLayout="column" class="mat-elevation-z2">
        <div fxLayout="row" fxLayoutAlign="start center" class="card-header">
          <div class="card-title"> Commission Details</div>
          <mat-icon class="icon"
          matTooltip="Configure your commission preferences here"
          matTooltipPosition="above"> info </mat-icon>
        </div>
        <div fxLayout="row" fxLayout.lt-md="column">
          <div fxFlex="50%" fxLayout="row" fxLayoutAlign="center center">
            <mat-form-field fxFlex="90%">
              <mat-label>Commission type</mat-label>
              <mat-select data-control="commissionType" (selectionChange)="handleSettings($event,'handlingCommission')"
                formControlName="commissionType">
                <mat-option *ngFor="let type of types" [attr.data-control]="'commissionType '+type" [value]="type">
                  {{type}}
                </mat-option>
              </mat-select>
              <mat-error data-control="commissionTypeRequiredError"
                *ngIf="settingsForm?.get('commissionType')?.hasError('required')">
                {{affiliateSettingsConstant?.errorMessages?.commissionTypeRequired}}
              </mat-error>
            </mat-form-field>
          </div>
          <div fxLayout="row" fxFlex="50%" fxLayoutAlign="center center">
            <mat-form-field fxFlex="90%">
              <mat-label>
                {{settingsForm?.get('commissionType')?.value==='Fixed'?
                'Enter commission value in amount':(
                settingsForm?.get('commissionType')?.value==='Percentage'?
                'Enter commission value in percentage':
                'Enter commission value in percentage')}}
              </mat-label>
              <input [readonly]="settingsForm?.get('commissionType')?.value===null" type="number"
                data-control="commissionValue" matInput
                (focusin)="holdAndAdditionalAmountDataChanged('commissionType','commissionValue')"
                formControlName="commissionValue" trimVal>
              <mat-error data-control="commissionValueRequiredError"
                *ngIf="settingsForm?.get('commissionValue')?.hasError('required')">
                {{affiliateSettingsConstant?.errorMessages?.commissionValueRequired}}
              </mat-error>
              <mat-error data-control="commissionValueMinError"
                *ngIf="settingsForm?.get('commissionValue')?.hasError('min')">
                {{settingsForm?.get('commissionType')?.value==='Fixed'?
                affiliateSettingsConstant?.errorMessages?.commissionValueMinFixed:
                affiliateSettingsConstant?.errorMessages?.commissionValueMinPercentage}}
              </mat-error>
              <mat-error data-control="commissionValueMaxError"
                *ngIf="settingsForm?.get('commissionValue')?.hasError('max')">
                {{settingsForm?.get('commissionType')?.value==='Fixed'?
                affiliateSettingsConstant?.errorMessages?.commissionValueMaxFixed:
                affiliateSettingsConstant?.errorMessages?.commissionValueMaxPercentage}}
              </mat-error>
              <mat-error data-control="commissionValuePatternError"
                *ngIf="settingsForm?.get('commissionValue')?.hasError('pattern') &&
                      !settingsForm?.get('commissionValue')?.hasError('max') &&!settingsForm?.get('commissionValue')?.hasError('min')">
                {{settingsForm?.get('commissionType')?.value==='Fixed'?
                affiliateSettingsConstant?.errorMessages?.commissionValuePatternFixed:
                affiliateSettingsConstant?.errorMessages?.commissionValuePatternPercentage}}
              </mat-error>
            </mat-form-field>
          </div>
        </div>
        <div fxLayout="row" fxLayout.lt-md="column">
          <div fxLayout="row" fxFlex="50%" fxLayoutAlign="center center">
            <mat-form-field fxFlex="90%">
              <mat-label>
                Commission Withdrawal Threshold
              </mat-label>
              <input type="number" data-control="thresholdValue" matInput formControlName="thresholdValue" trimVal>
              <mat-error data-control="thresholdValueRequiredError"
                *ngIf="settingsForm?.get('thresholdValue')?.hasError('required')">
                {{affiliateSettingsConstant?.errorMessages?.thresholdValueRequired}}
              </mat-error>
              <mat-error data-control="thresholdValueMaxError"
                *ngIf="settingsForm?.get('thresholdValue')?.hasError('max')">
                {{affiliateSettingsConstant?.errorMessages?.thresholdValueMax}}
              </mat-error>
              <mat-error data-control="thresholdValueMinError"
                *ngIf="settingsForm?.get('thresholdValue')?.hasError('min')">
                {{affiliateSettingsConstant?.errorMessages?.thresholdValueMin}}
              </mat-error>
              <mat-error data-control="thresholdValuePatternError"
                *ngIf="settingsForm?.get('thresholdValue')?.hasError('pattern') &&
                      !settingsForm?.get('thresholdValue')?.hasError('max') &&!settingsForm?.get('thresholdValue')?.hasError('min')">
                {{affiliateSettingsConstant?.errorMessages?.thresholdValuePattern}}
              </mat-error>
            </mat-form-field>
          </div>
        </div>
        <div class="padding" fxLayout="row" fxLayout.lt-md="column">
          <div fxLayout="row" fxFlex="53%" fxLayoutAlign="start center">
            <mat-checkbox (change)="discountValueChanged($event.checked)" formControlName="isDiscount"
              data-control="isDiscount" value="true">
              Enable discount for customers
            </mat-checkbox>
          </div>
          <div fxLayout="row" fxFlex="47%" fxLayoutAlign="start center" class="commission-field">
            <mat-checkbox formControlName="isAllTime" data-control="isAllTime" value="true">
              Apply commission all time
            </mat-checkbox>
            <mat-icon class="icon"
              matTooltip="If enabled, the commission will be applied every time while changing the plan by making a payment. Otherwise, the commission will be applied only to the initial payment."
              matTooltipPosition="above"> info </mat-icon>
          </div>
        </div>
      </div>
      <div *ngIf="settingsForm?.get('isDiscount')?.value"  fxLayout="column" class="mat-elevation-z2 card">
        <div fxLayout="row" fxLayoutAlign="start center" class="card-header">
          <div class="card-title">Discount Details</div>
          <mat-icon class="icon"
          matTooltip="Set the discount amount for  customers referred by affiliate users. As the super admin, you can define the discount rate that applies when a customer joins the ZenBasket through an affiliate."
          matTooltipPosition="above"> info </mat-icon>
        </div>
        <div fxLayout="row" fxLayout.lt-sm="column"
          fxLayout.lt-md="column" fxLayoutGap="0px" fxLayoutGap.lt-md="15px">
          <div fxFlex="50%" fxLayout="row" fxLayoutAlign="center center">
            <mat-form-field fxFlex="90%">
              <mat-label>Discount type
                {{settingsForm?.get('isDiscount')?.value?'':''}}</mat-label>
              <mat-select data-control="discountType" (selectionChange)="handleSettings($event,'handlingDiscount')"
                formControlName="discountType">
                <mat-option [attr.data-control]="'discountType '+type" *ngFor="let type of types" [value]="type">
                  {{type}}
                </mat-option>
              </mat-select>
              <mat-error data-control="discountTypeRequiredError"
                *ngIf="settingsForm?.get('discountType')?.hasError('required')">
                {{affiliateSettingsConstant?.errorMessages?.discountTypeRequired}}
              </mat-error>
            </mat-form-field>
          </div>
          <div  fxFlex="50%" fxLayout="row" fxLayoutAlign="center center">
            <mat-form-field fxFlex="90%">
              <mat-label>{{settingsForm?.get('discountType')?.value==='Fixed'?
                'Enter the discount amount':(
                settingsForm?.get('discountType')?.value==='Percentage'?
                'Enter the discount percentage':
                'Enter the discount amount')}}</mat-label>
              <input [readonly]="settingsForm?.get('discountType')?.value===null" type="number"
                (keyup)="holdAndAdditionalAmountDataChanged('discountType','discountValue')"
                data-control="discountValue" matInput formControlName="discountValue" trimVal>
              <mat-error data-control="discountValueRequiredError"
                *ngIf="settingsForm?.get('discountValue')?.hasError('required')">
                {{affiliateSettingsConstant?.errorMessages?.discountValueRequired}}
              </mat-error>
              <mat-error data-control="discountValueMinError"
                *ngIf="settingsForm?.get('discountValue')?.hasError('min')">
                {{settingsForm?.get('discountType')?.value==='Fixed'?
                affiliateSettingsConstant?.errorMessages?.discountValueMinFixed:
                affiliateSettingsConstant?.errorMessages?.discountValueMinPercentage}}
              </mat-error>
              <mat-error data-control="discountValueMaxError"
                *ngIf="settingsForm?.get('discountValue')?.hasError('max')">
                {{settingsForm?.get('discountType')?.value==='Fixed'?
                affiliateSettingsConstant?.errorMessages?.discountValueMaxFixed:
                affiliateSettingsConstant?.errorMessages?.discountValueMaxPercentage}}
              </mat-error>
              <mat-error data-control="discountValuePatternError"
                *ngIf="settingsForm?.get('discountValue')?.hasError('pattern') &&
                      !settingsForm?.get('discountValue')?.hasError('max') &&!settingsForm?.get('discountValue')?.hasError('min')">
                {{settingsForm?.get('discountType')?.value==='Fixed'?
                affiliateSettingsConstant?.errorMessages?.discountValuePatternFixed:
                affiliateSettingsConstant?.errorMessages?.discountValuePatternPercentage}}
              </mat-error>
            </mat-form-field>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>