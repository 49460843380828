/**
 * Component used to update card details
 * Author: Venkatalakshmi M.
 */
import { Component, Inject, NgZone, OnDestroy, OnInit } from '@angular/core';
import { loadStripe, StripeCardElement } from '@stripe/stripe-js';
import { Subscription } from 'rxjs';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { CommonConstants } from '../../../../../shared/src/lib/constants/shared-constant';
import { CommonService } from '../../../../../shared/src/lib/services/common.service';
import { DialogService } from 'libs/common/src/lib/services/dialog.service';
import { AuthService } from '@phase-ii/auth';
import { dataConfig } from 'libs/common/src/lib/services/config';
import { CommonDataService } from '../../../../../shared/src/lib/services/common-data.service';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { mergeMap } from 'rxjs/operators';

/**
 * Varaible holds the checkout frames.
 */
declare var Frames: any;
@Component({
  selector: 'phase-ii-update-card',
  templateUrl: './update-card.component.html',
  styleUrls: ['./update-card.component.scss']
})
export class UpdateCardComponent extends CommonConstants implements OnInit, OnDestroy {

  /**
  * Array used to push all the subscriptions for subscribe and unsubscribe
  */
  subscriptionArray: Subscription[] = [];
  /**
   * Variable used to store the saved card detail of the store
   */
  customerCardDetails: any;
  /**
   * Variable used to load the card elements
   */
  cardElement: StripeCardElement;
  /**
   * Variable used to load the stripe
   */
  stripe: any;
  /**
   * FormGroup used to store the billing address details
   */
  billingInfo: UntypedFormGroup;
  /**
   * Variable used to decide to edit the card or not
   */
  isEdit: boolean = true;
  /**
   * Variable used to show or hide the loader
   */
  isLoading: boolean;
  /**
  * Variable used to store subscriptionId
  * @type {string}
  */
  subscriptionId: string;
  /**
   * Variable used to store the list of countries from db
   */
  countries: any;
  /**
   * Variable used to store the list of states relevant to the country from db
   */
  states: any = [];
  /**
   * Variable used to store the store details
   */
  storeDetails: any;
  /**
   * Variable used to store the searched countries
   */
  searchCountries: any;
  /**
   * Variable used to store the searched states
   */
  searchStates: any;
  /**
   * Variable which is used to search country 
   * @type {FormControl}
   */
  countrySearchFilter = new UntypedFormControl(null);
  /**
   * Variable which is used to search state 
   * @type {FormControl}
   */
  stateSearchFilter = new UntypedFormControl(null);
  /**
   * Variable used for button loading during payment
   */
  isButtonLoading: boolean;
  /**
   * Variable used to store storeId
   */
  storeId: number;
  /**
   * Variable used to store previous url query parameter
   */
  previousUrl: any;
  /**
   * Variable used to store heading and description details 
   */
  heading: any =
    {
      title: 'Update Card', description: 'Update your card details...'
    };
  /**
   * Variable used to store button information
   */
  buttonInfo: any = [
    {
      name: 'Back', method: 'back', class: 'primary-button'
    },
  ];
  /**
   * Variable is used to store paymentType
   * @type {string}
   */
  paymentType: string;
  /**
   * variable used to store environment details\
   * @type {any}
   */
  environment: any;
  /**
   * Variable used to define isProd or not for loading payment scripts.
   * @type {boolean}
   */
  isProd: boolean = false;
  /**
   * Component constructor to inject the required services.
   * @param commonService to use the function calls in common service
   * @param dialogService to use the dialog functions
   * @param authService to get the user details
   * @param router to use the router functionalities
   * @param commonDataService to encrypt and decrypt details
   * @param route to get the query parameter
   */
  constructor(private commonService: CommonService,
    private dialogService: DialogService,
    private authService: AuthService,
    private router: Router,
    private zone: NgZone,
    private commonDataService: CommonDataService,
    private route: ActivatedRoute,
    @Inject('environment') environment) {
    super();
    this.environment = environment;
  }

  /**
   * Component on init life cycle hook
   */
  ngOnInit(): void {
    this.isLoading = true;
    this.subscriptionArray.push(this.route.queryParams.pipe(mergeMap((params: any) => {
      if (params && params.previousUrl) {
        this.previousUrl = params.previousUrl;
        this.subscriptionId = params.subscriptionId && this.commonDataService.getParam(params.subscriptionId);
      }
      return this.authService.user;
    }), mergeMap((res: any) => {
      if (res) {
        this.storeDetails = res;
        if (this.environment && this.environment.app && (this.environment.app === 'prod' || this.environment.app === 'us-prod')) {
          if (this.storeDetails && this.storeDetails.store && this.storeDetails.store.isTestAccount)
            this.isProd = false;
          else this.isProd = true;
        } else this.isProd = false;
      }
      return this.commonService.getSavedCardDetails(this.storeDetails && this.storeDetails.store && this.storeDetails.store.storeId, this.storeDetails && this.storeDetails.store && this.storeDetails.store.isTestAccount);
    })).subscribe((res: any) => {
      this.getCountries();
      if (res && res['details'] && res['details']['paymentType'])
        this.paymentType = res['details']['paymentType'];
      if (res && res['details']) {
        if (res['details']['cardDetails']) {
          this.customerCardDetails = res['details']['cardDetails'];
          this.isEdit = false;
        }
        if (this.paymentType && this.paymentType === dataConfig.paymentOption.CHECKOUT && !this.customerCardDetails) {
          this.loadCheckoutScript().then(() => {
            this.loadCheckoutElements();
          });
        } else if (this.paymentType && this.paymentType === dataConfig.paymentOption.STRIPE && !this.customerCardDetails) {
          this.load();
        }
      }
      this.isLoading = false;
    }, () => {
      this.isLoading = false;
      this.dialog('Failed to load card details.', this.dialogType.failure);
    }));
  }
  /**
   * Function used to load the stripe
   */
  async load(): Promise<void> {
    if (!this.cardElement) {
      this.stripe = await loadStripe(this.isProd ? dataConfig.stripeProductionKey : dataConfig.stripeKey);
      this.loadStripeElements();
      this.onNewCard();
    }
  }
  /**
    * Function used to load the checkout script
    */
  loadCheckoutScript() {
    return new Promise((resolve, reject) => {
      try {
        let script = document.getElementsByTagName('script');
        if (script && script.length > 0) {
          let index = [].findIndex.call(script, (src) => src && src.src && src.src === 'https://cdn.checkout.com/js/framesv2.min.js');
          if (index !== -1) {
            script[index] && script[index].remove();
            if (Frames !== null) {
              Frames && Frames.Events && Frames.removeAllEventHandlers(Frames.Events.CARD_VALIDATION_CHANGED);
              Frames && Frames.Events && Frames.removeAllEventHandlers(Frames.Events.FRAME_VALIDATION_CHANGED);
              Frames && Frames.Events && Frames.removeAllEventHandlers(Frames.Events.CARD_TOKENIZED);
              Frames && Frames.Events && Frames.removeAllEventHandlers(Frames.Events.CARD_TOKENIZATION_FAILED);
            }
          }
        }
        let checkoutScript = document.createElement("script");
        checkoutScript.setAttribute("src", "https://cdn.checkout.com/js/framesv2.min.js");
        checkoutScript.setAttribute("type", "text/javascript");
        document.head.appendChild(checkoutScript);
        this.onNewCard();
        checkoutScript.onload = function () {
          resolve(true);
        };
      }
      catch (err) {
        reject(false);
      }
    })
  }
  /**
   * getCountries method used to fetch the country details 
   */
  getCountries(): void {
    this.subscriptionArray.push(this.commonService.getCountries().subscribe((res: any) => {
      if (res && res.country && res.country.length) {
        this.countries = res.country;
        this.searchCountries = res.country;
      }
    }));
  }

  /**
  * Function used to get the states of particular country
  */
  getStates(): void {
    if (this.billingInfo && this.billingInfo.value && this.billingInfo.value.state) {
      this.billingInfo.patchValue({ 'state': "" });
    }
    if (this.billingInfo && this.billingInfo.value && this.billingInfo.value.country && this.billingInfo.value.country.id) {
      this.billingInfo.get('state').enable();
      this.subscriptionArray.push(this.commonService.getStates(this.billingInfo.value.country.id).subscribe((response: any) => {
        if (response) {
          this.states[0] = response.states;
          this.searchStates = response.states;
        }
      }));
    }
  }

  /**
   * Function used to get the searched countries result
   * @param value defines the country name
   */
  onCountrySearchFilter(value: string): void {
    if (value && value.length) {
      this.searchCountries = this.searchCountry(value);
    }
    else {
      this.onCountryFilterClose();
    }
  }

  /**
   * Function used to get the searched states
   * @param value defines the state name
   */
  onStateSearchFilter(value: string): void {
    if (value && value.length)
      this.searchStates = this.searchState(value);
    else {
      this.clearStateFilter();
    }
  }

  /**
   * Function used to search the country from the country list
   * @param value defines the name of the country
   * @returns the selected countries based on the given name
   */
  searchCountry(value: string): any {
    const filterValue = value && value.toLowerCase();
    return this.countries && this.countries.filter((option: any) => option && option.name && option.name.toLowerCase().startsWith(filterValue));
  }

  /**
   * Function used to search the state from the state list
   * @param value defines the name of the state
   * @returns the selected states based on the given name
   */
  searchState(value: string): any {
    const filterValue = value && value.toLowerCase();
    return this.states && this.states[0] && this.states[0].filter(option => option && option.name && option.name.toLowerCase().startsWith(filterValue));
  }

  /**
   * Function used to close the searched result for countries
   */
  onCountryFilterClose(): void {
    this.countrySearchFilter.setValue(null);
    this.searchCountries = [];
    this.searchCountries = this.countries;
  }

  /**
   * Function which is used for clear the state filter values
   */
  clearStateFilter(): void {
    this.stateSearchFilter.setValue(null);
    this.searchStates = [];
    this.searchStates = this.states && this.states[0];
  }

  /**
   * Function used to create new card
   */
  onNewCard(): void {
    this.billingInfo = new UntypedFormGroup({
      name: new UntypedFormControl(null, [
        Validators.required,
        Validators.pattern(dataConfig.patternValidators.acceptOnlyAlphabets),
      ]),
      doorNo: new UntypedFormControl(null, [
        Validators.pattern(dataConfig.patternValidators.DoorNoValidationPattern),
      ]),
      streetName: new UntypedFormControl(null, [
        Validators.required,
        Validators.maxLength(50),
        Validators.pattern(dataConfig.patternValidators.addressValidationPattern),
      ]),
      zipCode: new UntypedFormControl(null, [
        Validators.required, Validators.maxLength(15)
      ]),
      city: new UntypedFormControl(null, [
        Validators.required,
        Validators.maxLength(50),
        Validators.pattern(dataConfig.patternValidators.acceptOnlyAlphabets),
      ]),
      state: new UntypedFormControl(null, [
        Validators.required
      ]),
      country: new UntypedFormControl(null, [
        Validators.required
      ])
    });
    this.billingInfo.get('state').disable();
  }
  /**
   * Function used to load the stripe card elements
   */
  loadStripeElements(): void {
    const rootNode = document.getElementById('cardInfoGroup');
    const cardWrapper = document.createElement('div');
    rootNode.appendChild(cardWrapper);
    const elements = this.stripe.elements();
    this.cardElement = elements.create('card', {
      style: {
        base: {
          color: 'currentColor',
          lineHeight: '40px',
          fontSize: '18px'
        },
      },
      hidePostalCode: true,
    });
    this.cardElement.mount(cardWrapper);
  }
  /**
   * Function used to load the checkout card elements
   */
  async loadCheckoutElements() {
    var errorStack = [], errorMessage, errorMessageElement = document.querySelector(".error-message");
    let publicApiKey = this.isProd ? dataConfig.checkoutProductionPublicApiKey : dataConfig.checkoutPublicApiKey;
    Frames.init({ publicKey: publicApiKey, modes: [Frames.modes.DISABLE_COPY_PASTE] });
    Frames.addEventHandler(Frames.Events.CARD_VALIDATION_CHANGED, () => {
      errorMessageElement.textContent = !Frames.isCardValid() ? 'The card is not valid.' : '';
    }
    );
    Frames.addEventHandler(Frames.Events.FRAME_VALIDATION_CHANGED, onValidationChanged);
    Frames.addEventHandler(Frames.Events.CARD_TOKENIZATION_FAILED, () => {
      Frames.enableSubmitForm();
      this.isButtonLoading = false;
    }
    );
    Frames.addEventHandler(Frames.Events.CARD_TOKENIZED, (event) => {
      this.zone.run(() => {
        this.saveCard(event);
      })
    });

    /**
     * Function used for CheckoutDotCom Payment validation
     * @param event Parameters pass validation details
    */
    function onValidationChanged(event: any): any {
      if (event && event.element) {
        if (!event.isValid && !event.isEmpty) {
          errorStack.push(event.element);
        } else {
          errorStack = errorStack && errorStack.filter(function (element) {
            return element !== event.element;
          });
        }
        errorMessage = errorStack.length ? getErrorMessage(errorStack[errorStack.length - 1]) : event.isFormValid === false ? 'The card is not valid.' : '';
        if (errorMessageElement) {
          errorMessageElement.textContent = errorMessage;
        }
      }
    }
    /**
     * Function used to choose CheckoutDotCom payment error message
     * @param element Parameters pass error element name
     */
    function getErrorMessage(element: string): any {
      var errors = {
        "card-number": "Please enter a valid card number.",
        "expiry-date": "Please enter a valid expiration date.",
        "cvv": "Please enter a valid CVV code.",
      };
      return errors[element];
    }
  }
  /**
   * This function is used to submit checkout payment form.
   */
  async onSubmit() {
    if (this.billingInfo && this.billingInfo.valid && this.billingInfo.value) {
      if (this.paymentType === dataConfig.paymentOption.CHECKOUT) {
        try {
          this.isButtonLoading = true;
          Frames.cardholder = {
            billingAddress: {
              addressLine1: this.billingInfo.value.doorNo,
              addressLine2: this.billingInfo.value.streetName,
              zip: this.billingInfo.value.zipCode,
              city: this.billingInfo.value.city,
              state: this.billingInfo.value.state && this.billingInfo.value.state.code,
              country: this.billingInfo.value.country && this.billingInfo.value.country.countryIsoCode
            }
          };
          await Frames.submitCard();
        } catch (err) {
          this.isButtonLoading = false;
          Frames.enableSubmitForm();
          this.dialog(err && err.message ? err.message : err, this.dialogType.failure);
        }
      } else if (this.paymentType === dataConfig.paymentOption.STRIPE) {
        this.isButtonLoading = true;
        await this.stripe.createToken(this.cardElement, {
          name: this.billingInfo.value.name,
          address_line1: this.billingInfo.value.doorNo + ' ' + this.billingInfo.value.streetName,
          address_line2: this.billingInfo.value.doorNo + ' ' + this.billingInfo.value.streetName,
          address_city: this.billingInfo.value.city,
          address_state: this.billingInfo.value.state && this.billingInfo.value.state.name,
          address_zip: this.billingInfo.value.zipCode,
          address_country: this.billingInfo.value.country && this.billingInfo.value.country.countryISOCode
        }).then((obj: any) => {
          this.isButtonLoading = false;
          if (obj['error']) {
            this.dialog(obj['error'].message, this.dialogType.failure);
          } else if (obj && obj.token && obj.token.id) {
            this.isButtonLoading = false;
            this.saveCard(obj);
          }
        });
      }
    } else
      this.billingInfo.markAllAsTouched();
  }
  /**
   * Function called when you want to edit your card details
   */
  async editCard() {
    this.isEdit = true;
    if (this.paymentType === dataConfig.paymentOption.CHECKOUT) {
      this.loadCheckoutScript().then(() => {
        this.loadCheckoutElements();
      });
    }
    else
      await this.load();
  }

  /**
   * Function called when cancel the card edit option
   */
  cancelCard(): void {
    this.isEdit = false;
    this.billingInfo.reset();
    if (this.paymentType === dataConfig.paymentOption.STRIPE) {
      this.cardElement = null;
      const rootNode = document.getElementById('cardInfoGroup');
      if (rootNode && rootNode.firstChild && this.customerCardDetails) {
        rootNode.removeChild(rootNode.firstChild);
      } else {
        this.back();
      }
    } else if (!this.customerCardDetails) {
      this.back();
    }
  }
  /**
   * Function used to get the token for new card payment
   */
  saveCard(event?: any) {
    if (this.billingInfo && this.billingInfo.valid) {
      this.isButtonLoading = true;
      let data;
      if (this.paymentType === dataConfig.paymentOption.CHECKOUT) {
        data = {
          type: 'token',
          token: event && event.token && this.commonDataService.encryptDetails(event.token),
          storeUuid: this.storeDetails && this.storeDetails.store && this.storeDetails.store.storeId,
          subscriptionId: this.subscriptionId && this.commonDataService.encryptDetails(this.subscriptionId),
          email: this.storeDetails && this.storeDetails.email,
          storeName: this.storeDetails && this.storeDetails.store && this.storeDetails.store.name,
          name: this.billingInfo && this.billingInfo.value && this.billingInfo.valid && this.billingInfo.value.name
        };
      } else if (this.paymentType === dataConfig.paymentOption.STRIPE) {
        let token = event.token.id;
        let encryptedToken = this.commonDataService.encryptDetails(token);
          data = {
            token: encryptedToken,
            storeUuid: this.storeDetails && this.storeDetails.store && this.storeDetails.store.storeId,
            name: this.storeDetails && this.storeDetails.name,
            email: this.storeDetails && this.storeDetails.email,
            subscriptionId: this.subscriptionId && this.commonDataService.encryptDetails(this.subscriptionId)
          }
      }
      this.updateCard(data);
    } else {
      this.billingInfo.markAllAsTouched();
    }
  }
  updateCard(data) {
    Object.assign(data, {
      paymentType: this.paymentType,
      isTestStore: this.storeDetails && this.storeDetails.store && this.storeDetails.store.isTestAccount,
      storeId: this.storeDetails && this.storeDetails.storeId
    });
    this.subscriptionArray.push(this.commonService.updateCard(data).subscribe((res: any) => {
      this.isButtonLoading = false;
      if (res && res.success) {
        this.billingInfo.markAsPristine();
        this.back();
        this.dialog(this.dialogMessages.updateCard, this.dialogType.success);
      }
      else {
        this.dialog(this.dialogMessages.updateCardError, this.dialogType.failure);
        if (this.paymentType === dataConfig.paymentOption.CHECKOUT)
          Frames.enableSubmitForm();
      }
    }, () => {
      this.dialog(this.errorMessage.fetchError, this.dialogType.failure);
      if (this.paymentType === dataConfig.paymentOption.CHECKOUT)
        Frames.enableSubmitForm();
      this.isButtonLoading = false;
    }));
  }
  /**
   * updateRenewalData method used to Renewal store plan
   */
  // updateRenewalData(): void {
  //   this.isButtonLoading = true;
  //   this.subscriptionArray.push(this.commonService.updateRenewalData({ SK: this.subscriptionId, renewal: true }).subscribe((res: any) => {
  //     if (res) {
  //       this.dialogService.openDialog({
  //         header: this.errorMessage.successHeader,
  //         message: this.dialogMessages.sucessAutoRenewalenable,
  //         actionType: this.dialogType.success,
  //         button: { right: this.buttonText.ok }
  //       }).afterClosed().subscribe((response: any) => {
  //         if (response) {
  //           this.router.navigate([`/app/${this.previousUrl}`]);
  //         }
  //       });
  //     }
  //     this.isButtonLoading = false;
  //   }, () => {
  //     this.isButtonLoading = false;
  //     this.dialogService.openDialog({
  //       header: this.errorMessage.failureHeader,
  //       message: this.dialogMessages.errAutoRenewal,
  //       actionType: this.dialogType.failure,
  //       button: { right: this.buttonText.ok }
  //     });
  //   }));
  // }
  /**
   * Method used to trigger the function.
   *  @param event To get the selected event
  */
  functionTriggered(event): void {
    this[event]();
  }

  /**
   * Method which is used to navigate
  */
  back(): void {
    this.router.navigate([`/app/${this.previousUrl}`]);
  }

  /**
   * To indicate during leaving of the page
   * @returns the form gets dirty or not
   */
  canDeactivate(): boolean {
    return (this.billingInfo && this.billingInfo.pristine) || !this.isEdit;
  }
  /**
   * Method is used to open the dialog.
   * @param message to get dialog message.
   * @param type to get dialog type.
   */
  dialog(message: string, type: string): void {
    this.dialogService.openDialog({
      message: message,
      actionType: type,
      button: { right: this.buttonText.ok }
    });
  }
  /**
   * Component ondestroy life cycle hook.
   * All subscriptions are unsubscribe here.
   */
  ngOnDestroy(): void {
    if (this.paymentType === dataConfig.paymentOption.STRIPE) {
    this.cardElement = null;
    const rootNode = document.getElementById('cardInfoGroup');
    if (rootNode && rootNode.firstChild && this.customerCardDetails)
      rootNode.removeChild(rootNode.firstChild);
  } 
    if (this.subscriptionArray && this.subscriptionArray.length) {
      this.subscriptionArray.forEach((item: any) => {
        if (item) { item.unsubscribe(); }
      });
    }
    let script = document.getElementsByTagName('script');
    if (script && script.length > 0 && this.paymentType === dataConfig.paymentOption.CHECKOUT) {
      let index = [].findIndex.call(script, (src) => src && src.src && src.src === 'https://cdn.checkout.com/js/framesv2.min.js');
      if (index !== -1) {
        script[index] && script[index].remove();
        if (Frames !== null) {
          Frames && Frames.Events && Frames.removeAllEventHandlers(Frames.Events.CARD_VALIDATION_CHANGED);
          Frames && Frames.Events && Frames.removeAllEventHandlers(Frames.Events.FRAME_VALIDATION_CHANGED);
          Frames && Frames.Events && Frames.removeAllEventHandlers(Frames.Events.CARD_TOKENIZED);
          Frames && Frames.Events && Frames.removeAllEventHandlers(Frames.Events.CARD_TOKENIZATION_FAILED);
        }
      }
    }
  }
}
