<cen-loader-vii *ngIf="isLoader">
</cen-loader-vii>

<div *ngIf="!isLoader" class="drawer-container">
    <mat-drawer-container>
        <mat-drawer #drawer class="mat-elevation-z1 sidenav-width sideMenu" [mode]="(isHandset | async)!.matches ? 'over' : 'side'" [opened]="true">
            <phase-ii-home-menu-drawer [drawer]="drawer" [menus]="menus" [currentUser]="currentUser" style="width:100%">
            </phase-ii-home-menu-drawer>
        </mat-drawer>
        <mat-drawer-content fxLayout="column">
            <phase-ii-home-menu-toolbar [drawer]="drawer" [menus]="menu_tool" [currentUser]="currentUser"
                [storeName]="userName" [isAffiliateUser]="isAffiliateUser">
            </phase-ii-home-menu-toolbar>
            <div class="wholepage-container container-limit">
                <router-outlet></router-outlet>
            </div>
        </mat-drawer-content>
    </mat-drawer-container>
</div>