import { Component } from '@angular/core';
import { ButtonInfo, CommonDataService, Heading, PaginationService } from '@phase-ii/shared';
import { AccountDetailsConstant } from '../../constants/account-details-constant';
import { ActivatedRoute, Router } from '@angular/router';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { AccountDetailsService } from '../../services/account-details.service';
import { Subscription, of } from 'rxjs';
import { AuthService, CustomAsyncValidatorService, CustomValidatorService } from '@phase-ii/auth';
import { DialogService } from '@phase-ii/common';
import { AffiliateAuthService } from '../../../auth/services/auth.service';
import { mergeMap } from 'rxjs/operators';


@Component({
  selector: 'phase-ii-add-edit-account-details',
  templateUrl: './add-edit-account-details.component.html',
  styleUrls: ['./add-edit-account-details.component.scss'],
})
export class AddEditAccountDetailsComponent {
  /**
    * Variable which is used to have all the needed variables.
    * @type {object}
  */
  pageDetails: {
    heading: Heading,
    customerId: number,
    buttonInfo: ButtonInfo[],
    isLoader: boolean;
  } = {
      heading: null,
      customerId: null,
      buttonInfo: [
        { name: 'Cancel', class: 'secondary-button', method: 'cancel' },
        { name: 'Save', class: 'primary-button', method: 'saveAccountDetails' }
      ],
      isLoader: false,
    }

  /**
    * Variable which is used to subscribe and unsubscribe the subscriptions.
    * @type {Subscription}
  */
  subscriptionObj: Subscription = new Subscription();

  /**
   * Accountdetailsconstant used to acccess the AccountDetails constant values. 
  */
  accountDetailsConstant = new AccountDetailsConstant();

  /**
    * accountDetails used to store the details 
  */
  accountDetails = {
    accountDetailsData: null,
    isActive: null,
    formLength: null,
    accountId: null
  };

  /**
    * accountDetailsForm is used to declare formcontrols for accountDetailsForm.
    * @type {FormGroup}
  */
  accountDetailsForm: UntypedFormGroup;

  /**
    * variable which is used to store the decrypted data 
  */
  decrypt: any;

  /**
    * Component constructor to inject the required services.
    * @param route To get the user id from params.
    * @param router To navigate between pages.
    * @param accountDetailService To access methods from  AccountDetailsService .
    * @param dialogService To access methods from DialogService.
    * @param commonDataService To access methods from CommonDataService.
    * @param paginationService To access methods from PaginationService.
    * @param authService To access methods from AuthService.
    * @param affiliateAuth To access methods from AffiliateAuthService.
    * 
  */
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private accountDetailservice: AccountDetailsService,
    private dialogService: DialogService,
    private commonDataService: CommonDataService,
    private paginationService: PaginationService,
    private authService: AuthService,
    private affiliateAuth: AffiliateAuthService
  ) {
  }

  /**
    * Component constructor to inject the required services.
    * @param injector is to access the injector.
  */
  ngOnInit() {
    this.pageDetails.isLoader = true;
    this.formInitialize();
    this.variableIntialization();
    this.getValues();
  }

  /**
    *Method functionTriggered to trigger the functions used in header section.
  */
  functionTriggered(event: any) {
    this[event]();
  }

  /**
    * The method variableIntialization used to initialize the variables.
   */
  variableIntialization() {
    this.pageDetails.heading = {
      title: this.accountDetailsConstant && this.accountDetailsConstant.AddAccountHeading && this.accountDetailsConstant.AddAccountHeading.title,
      description: '',
    };
  }

  /**
    * The method getValues used to initialize the account details list. 
   */
  getValues() {
    this.pageDetails.isLoader = true;
    this.subscriptionObj.add(this.affiliateAuth.user.pipe(mergeMap((res: any) => {
      if (res && res.id) {
        this.pageDetails.customerId = res.id;
      }
      return this.route.queryParams;
    }), mergeMap((res: any) => {
      if (res && res.id) {
        this.accountDetails.accountId = res.id;
        this.accountDetails.formLength = res.length;
        this.accountDetails.isActive = res.isActive;
        const paramId = this.commonDataService.getParam(res.id);
        return this.accountDetailservice.getOneDetails(paramId);
      }
      else {
        this.accountDetails.formLength = res.length;
        this.accountDetails.isActive = res.isActive;
        return of(false);
      }
    })).subscribe((res: any) => {
      if (res) {
        const getEncrypt = this.commonDataService.decryptDetails(res.result);
        this.decrypt = JSON.parse(getEncrypt);
        this.accountDetails.accountDetailsData = this.decrypt;
        this.formInitialize(this.decrypt);
        this.pageDetails.isLoader = false;
      }
      else {
        this.formInitialize();
      }
    }, (err) => {
      this.pageDetails.isLoader = false;
      this.dialogService.dialogMethod(this.accountDetailsConstant.dialogmessages.failedToLoad, this.accountDetailsConstant.dialogType.failure, true);
    }))
  }

  /**
    * formInitialize is the method which used to initialize the form.
    * @param data  is the ordersData.
  */
  formInitialize(data?: any) {
    const defaulAccount = (this.accountDetails && this.accountDetails.isActive === "true") ? false : true;
    this.accountDetailsForm = new UntypedFormGroup({
      bankName: new UntypedFormControl(data && data.bankName ? data.bankName : null, [Validators.required]),
      branchName: new UntypedFormControl(data && data.branchName ? data.branchName : null, [Validators.required]),
      accountNumber: new UntypedFormControl(data && data.accountNumber ? data.accountNumber : null, {
        validators: [
          Validators.required,
          Validators.pattern(this.accountDetailsConstant.errorMessage.pattern.acceptOnlyNumbers)],
        asyncValidators: [
          CustomAsyncValidatorService.asyncAccountValidation(this.authService, data && data.id ? data.id : null),
        ]
      }
      ),
      confirmAccountNumber: new UntypedFormControl(data && data.accountNumber ? data.accountNumber : null, Validators.required),
      accountHolderName: new UntypedFormControl(data && data.accountHolderName ? data.accountHolderName : null, [Validators.required, Validators.pattern(this.accountDetailsConstant.errorMessage.pattern.acceptOnlyAlphabets)]),
      ifscCode: new UntypedFormControl(data && data.ifscCode ? data.ifscCode : null),
      micrCode: new UntypedFormControl(data && data.micrCode ? data.micrCode : null, [Validators.required , Validators.maxLength(9) ,Validators.pattern(this.accountDetailsConstant.errorMessage.pattern.acceptOnlyNumbers)]),
      vpaId: new UntypedFormControl(data && data.vpaId ? data.vpaId : null),
      isActive: new UntypedFormControl(data && data.isActive ? data.isActive : defaulAccount),
      customerId: new UntypedFormControl(this.pageDetails && this.pageDetails.customerId)
    }, CustomValidatorService.formValidation.bind(this));
    this.pageDetails.isLoader = false;
  }

  /**
    * Method which is used to enable and disable the primary account.
  */
  enableAccount(event: any) {
    if (!event) {
      this.changeDefaultAccount();
    }
    else {
      this.setDefaultAccount();
    }
  }

  /**
    * Method which is used to set the primary account.
  */
  setDefaultAccount() {
    const dialogRef = this.dialogService.dialogMethod(this.accountDetailsConstant.dialogmessages.defaultConfirmation, this.accountDetailsConstant.dialogType.confirmation, true);
    this.subscriptionObj.add(dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.accountDetailsForm.get('isActive').patchValue(true);
      }
      else {
        this.accountDetailsForm.get('isActive').patchValue(false);
        this.accountDetailsForm.get('isActive').markAsPristine();
      }
    }));

  }

  /**
  * Method which is used to set the primary account.
  */
  isDefaultFormControlChanges() {
    this.accountDetailsForm.get('isActive').markAsPristine();
    this.accountDetailsForm.get('isActive').patchValue(true);
  }

  /**
    * Method which is used to change the primary account.
  */
  changeDefaultAccount() {
    if (((this.accountDetails.formLength === "1" && this.accountDetails.accountId) || this.accountDetails.formLength < 1)) {
      const dialogRef = this.dialogService.dialogMethod(this.accountDetailsConstant.dialogmessages.oneDefaultAccountRequired, this.accountDetailsConstant.dialogType.alert, true, true);
      if (dialogRef) {
        this.isDefaultFormControlChanges();
      }
    }
    else if (this.accountDetails && this.accountDetails.accountDetailsData && this.accountDetails.accountDetailsData.isActive) {
      const dialogRef = this.dialogService.dialogMethod(this.accountDetailsConstant.dialogmessages.alertTochangeDefaultAccount, this.accountDetailsConstant.dialogType.alert, true, true);
      if (dialogRef) {
        this.isDefaultFormControlChanges();
      }
    }
  }

  /**
    * saveStoreLocations method used to save the Account details.
  */
  saveAccountDetails() {
    this.pageDetails.isLoader = true;
    if (this.accountDetailsForm && this.accountDetailsForm.value && this.accountDetailsForm.valid) {
      this.checkForFormDirtyAndSave();
    }
    else {
      const dialog = this.dialogService.dialogMethod(this.accountDetailsConstant.dialogmessages.mandatoryField, this.accountDetailsConstant.dialogType.alert, true);
      this.pageDetails.isLoader = false;
      this.accountDetailsForm && this.accountDetailsForm.markAllAsTouched();
    }
  }

  /**
   * checkForFormDirtyAndSave method used to create or update the Account details
   */
  checkForFormDirtyAndSave() {
    if (this.accountDetailsForm && !this.accountDetailsForm.dirty) {
      this.pageDetails.isLoader = false;
      const dialog = this.dialogService.dialogMethod(this.accountDetailsConstant.dialogmessages.formAlert, this.accountDetailsConstant.dialogType.alert, true);
    }
    else if (this.accountDetails && this.accountDetails.accountDetailsData && this.accountDetails.accountDetailsData.id) {
      this.updateAccount();
    }
    else {
      this.createAccount();
    }
  }

  /**
    *  method which is used to update the Account details.
  */
  updateAccount() {
    this.subscriptionObj.add(this.accountDetailservice.updateAccountDetails(this.accountDetails && this.accountDetails.accountDetailsData && this.accountDetails.accountDetailsData.id, this.accountDetailsForm.value).subscribe((res: any) => {
      if (res) {
        const dialogRef = this.dialogService.dialogMethod(this.accountDetailsConstant.dialogmessages.userUpdate, this.accountDetailsConstant.dialogType.success, true)
        if (dialogRef) {
          this.pageDetails.isLoader = true;
          this.navigateToList();
        }
      }
      this.accountDetailsForm.markAsPristine();
    }, (err) => {
      this.pageDetails.isLoader = false;
      this.dialogService.dialogMethod(this.accountDetailsConstant.dialogmessages.updateDetailsFailure, this.accountDetailsConstant.dialogType.failure, true);
    }))

  }

  /**
    * Method which is used to navigate to the account list page.
  */
  navigateToList() {
    this.router.navigate(['app/account-list'])
  }

  /**
    *  method which is used to create the Account details.
  */
  createAccount() {
    this.subscriptionObj.add(this.accountDetailservice.createAccountDetails(this.accountDetailsForm && this.accountDetailsForm.value).subscribe((res: any) => {
      if (res) {
        const dialog = this.dialogService.dialogMethod(this.accountDetailsConstant.dialogmessages.accountCreatedSuccess, this.accountDetailsConstant.dialogType.success, true);
        if (dialog) {
          this.pageDetails.isLoader = true;
          this.accountDetailsForm.markAsPristine();
          this.navigateToList();
        }
      }
    }, (err) => {
      this.pageDetails.isLoader = false;
      this.dialogService.dialogMethod(this.accountDetailsConstant.dialogmessages.createFailed, this.accountDetailsConstant.dialogType.failure, true);
    }
    ));
  }


  /**
    *  method which is used to get back to  the Account list page.
  */
  cancel() {
    this.router.navigate(['app/account-list']);
  }

  /**
    * Method used to deactivate the canDeactivate guard when the form is dirty.
    * @returns dialog box
  */
  canDeactivate() {
    return this.accountDetailsForm && !this.accountDetailsForm.dirty;
  }

  /**
    * Component OnDestroy life cycle hook.
    * And unsubscribe all the subscriptions in the component.
  */
  ngOnDestroy() {
    if (this.subscriptionObj) {
      this.paginationService.listPaginatorData.next(null);
      this.subscriptionObj.unsubscribe();
    }
  }
}