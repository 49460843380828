import { Component, OnInit, OnDestroy } from '@angular/core';
import { DatePipe } from '@angular/common';
import * as  _ from 'lodash';
import { filter, mergeMap } from 'rxjs/operators';
import { of, Subscription } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { ReportsService } from '../../services/reports.service';
import { ColumnsRef, CommonAdminShardConstants, ListProperties } from '../../constants/shared-constant';
import { Router } from '@angular/router';
import { AuthService } from '@phase-ii/auth';
import { PaginationService } from '../../../../../shared/src/lib/services/pagination.service';
import { DialogService } from '@phase-ii/common';
import { ExportComponent } from '../export/export.component';
import { ExportObject } from '../../models/export-details';
import { CommonService } from 'libs/shared/src/lib/services/common.service';
import { dateObject } from '../../models/common-admin-model';
import { ButtonInfo } from 'libs/shared/src/lib/models/header-page';
import * as moment from 'moment';

/**
* Component which is used to view/export revenue report.
*/
@Component({
  selector: 'phase-ii-revenue-report',
  templateUrl: './revenue-report.component.html',
  styleUrls: ['./revenue-report.component.scss']
})
export class RevenueReportComponent implements OnInit, OnDestroy {
  /**
   * Variable which is used to define the pagesize
   */
  pageSize: any = new CommonAdminShardConstants().paginator.defaultPageSize;
  /**
  * Variable which is used to subscribe and unsubscribe.
  * @type {Subscritpion}
  */
  subscriptionObj: Subscription = new Subscription();
  /**
 * Variable which is used to define the pagesize
 */
  sort: any;
  /**
* Variable which is used to store from date value
*/
  fromDate: any;
  /**
* Variable which is used to store to date value
*/
  toDate: any;
  /**
* Variable which is used to data values for displaying chart
*/
  chartOptions: any;
  /**
* Variable which is used to store sales data value
*/
  salesData: any;
  /**
* Variable which contains store id
*/
  storeId: any;
  /**
* Variable which is used to store location id
*/
  locationId: any;
  /**
* Variable which is used to define the page details value to setup a page
*/
  pageDetails: {
    heading: any,
    buttonInfo: ButtonInfo[],
    listProperty: ListProperties,
    columnRef: ColumnsRef[],
    isLoader: boolean,
    listDataSource: any,
    showFilterAndSort: boolean,
    actions: any,
    filterConstrain: any,
    checker: any,
    errorHandler: boolean,
    currencySymbol: any
    paginator: any
    chart: boolean,
    tab: boolean,
    storeList: any,
    storeId: number,
    roleId: number
  } = {
      heading: null,
      buttonInfo: null,
      listProperty: null,
      columnRef: null,
      isLoader: null,
      listDataSource: null,
      showFilterAndSort: false,
      actions: [],
      filterConstrain: null,
      checker: null,
      errorHandler: false,
      currencySymbol: null,
      paginator: null,
      chart: true,
      tab: false,
      storeList: null,
      storeId: null,
      roleId: null
    };
  /**
* paginator data for export
*/
  paginatorValue = { limit: null, offset: null };
  /**
* variable which is used to store current user details
*/
  currentUser: any;
  /**
* variable which is used to store available list of stores
*/
  storeList = [];
  /**
* variable which is used to store whether page is changing or not.
*/
  isPageChange: boolean = false;
  /**
* varibale which is used to store paginator offset data for export
*/
  paginatorOffset: number;
  /**
* varibale which is used to store paginator limit data for export
*/
  paginatorLimit: any;
  /**
* varibale which is used to store current role details
*/
  currentRole: string;
  /**commonConstant used to acccess the common constant values. */
  commonConstant = new CommonAdminShardConstants();
  /**
* varibale which is used to store report calculation information
*/
  reportCalculationInfo: Array<{
    key: String,
    value: String
  }>
  /*
   * Variable which is used to store the created date of the store.
   * @type {Date}
   */
  createdDate!: Date;
  /**
* component constructor which is used to inject the required services.
* @param reportService  To access methods inside ReportService.
* @param authService  To access methods inside AuthService.
* @param displayDialog  To access methods inside MatDialog.
* @param paginatorService  To access methods inside PaginationService.
* @param commonService  To access methods inside CommonService.
* @param datePipe  To access methods inside DatePipe.
* @param router  To access methods inside Router.
*/
  constructor(
    private reportService: ReportsService,
    private authService: AuthService,
    private displayDialog: MatDialog,
    private paginatorService: PaginationService,
    private commonService: CommonService,
    private datePipe: DatePipe,
    private dialogService: DialogService,
    private router: Router
  ) { }

  /**
* Angular hook life cycle to initialize the component
*/
  ngOnInit(): void {
    this.sort = { direction: 'desc' };
    this.locationId = 'All';
    this.subscriptionObj.add(this.authService.user.pipe(filter((res) => {
      if (res) {
        return res;
      }
    }), mergeMap((user: any) => {
      if (user) {
        this.currentUser = user;
        this.currentRole = this.currentUser?.roleCode ? this.currentUser.roleCode : this.currentUser?.role?.code;
        this.pageDetails.roleId = this.currentUser?.roleId;
        this.storeId = this.currentUser && this.currentUser?.storeId ? this.currentUser.storeId : null;
        this.pageDetails.currencySymbol = user.store && user.store.currencyData && user.store.currencyData.currencySymbol;
      }
      if (this.currentRole && this.currentRole === 'SUPERADMIN') {
        return this.commonService.getAllStoresName();
      } else {
        this.pageDetails.storeId = this.currentUser && this.currentUser.storeId ? this.currentUser.storeId : null;
        return of({});
      }
    }), mergeMap((store: any) => {
      if (store && store['stores']) {
        this.storeList.push({ name: "All", id: "All" });
        store['stores'].forEach((element) => {
          this.storeList.push({
            id: element?.id,
            name: element?.name
          });
        });
        this.storeId = this.storeList[0].id;
        this.pageDetails.storeList = this.storeList;
      }
      return this.reportService.getStoreData(this.pageDetails.storeId);;
    }), mergeMap((data: any) => {
      if (data) {
        this.createdDate = data.settings && data.settings.createdAt;
      }
      return this.authService.dashboardFilter;
    })).subscribe((value: dateObject) => {
      if (value) {
        if (value.filterText == 'Lifetime') {
          this.fromDate = this.createdDate;
        }
        else {
          this.fromDate = value.fromDate ? value.fromDate : new Date();
          this.fromDate.setHours(0, 0, 0, 0);
        }
      }
      else {
        this.fromDate = new Date();
        this.fromDate.setHours(0, 0, 0, 0);
      }
      this.toDate = new Date();
      this.toDate.setHours(23, 59, 59, 999);
      this.storeId = this.currentUser?.storeId ? this.currentUser.storeId : null;
      this.getSalesOverTime(0, 2 * this.pageSize, true);
    }, (err) => {
      this.pageDetails.isLoader = false;
    }));
    this.pageDetails.heading = {
      title: 'Revenue Report',
      description: 'Generates report based on revenue',
      isHeaderIcon: true,
      iconName: 'link',
      iconToolTip: 'Help',
      iconLink: 'https://help.getzenbasket.com/revenue_report.html'
    };
    this.pageDetails.buttonInfo =
      [
        {
          name: 'Back', method: 'back()', class: 'secondary-button'
        },
        {
          name: 'Export Report', class: 'primary-button', method: 'exportexcel()'
        }
      ];
    this.pageDetails.columnRef = [
      { column: 'availabledate', type: 'Date', suffixText: 'Ordered Date' },
      { column: 'orderscount', type: 'Number', suffixText: 'Orders' },
      { column: 'order_amount', type: 'Amount', suffixText: 'Gross Sale', currency: this.pageDetails.currencySymbol },
      { column: 'net_sale', type: 'Amount', suffixText: 'Net Sale', currency: this.pageDetails.currencySymbol },
      { column: 'tax', type: 'Amount', suffixText: 'Tax', currency: this.pageDetails.currencySymbol },
      { column: 'order_charge', type: 'Amount', suffixText: 'Delivery Charge', currency: this.pageDetails.currencySymbol },
      // { column: 'discount_amount', type: 'Amount', suffixText: 'Discount Amount', currency: this.pageDetails.currencySymbol },
      // { column: 'service_charge', type: 'Amount', suffixText: 'Service charge', currency: this.pageDetails.currencySymbol },
      // { column: 'tip', type: 'Amount', suffixText: 'Tip', currency: this.pageDetails.currencySymbol },
      // { column: 'refunded_amount', type: 'Amount', suffixText: 'Refund', currency: this.pageDetails.currencySymbol },
      // { column: 'discount', type: 'Amount', suffixText: 'Coupon Discount', currency: this.pageDetails.currencySymbol },
      { column: 'extra_amounts', type: 'Amount', suffixText: 'Extra Amount', currency: this.pageDetails.currencySymbol },
      // { column: 'amount_to_pay', type: 'Amount', suffixText: 'Pending Amount', currency: this.pageDetails.currencySymbol },
      { column: 'total_amount', type: 'Amount', suffixText: 'Total Sales', currency: this.pageDetails.currencySymbol },
      // { column: 'amount_paid', type: 'Amount', suffixText: 'Received Amount', currency: this.pageDetails.currencySymbol },
    ];
    this.reportCalculationInfo = this.commonConstant.revenueReportCalculationInfo;
  }

  /**
   *Function which is used to get the revenue details.
   */
  getSalesOverTime(offset: number, limit: number, isNew): any {
    this.paginatorOffset = offset;
    this.paginatorLimit = this.pageSize;
    let newData = isNew;
    const start_date = this.fromDate ? _.cloneDeep(this.fromDate) : null;
    const to_date = this.toDate ? this.toDate : null;
    let data = {
      offset: offset,
      limit: limit ? limit : this.pageSize,
      location: this.locationId ? this.locationId : null,
      store: this.storeId ? this.storeId : null,
      from_date: moment.utc(this.datePipe.transform(start_date, 'yyyy-MM-dd')).utcOffset('+0:00').format('YYYY-MM-DD 00:00:00.000 +00:00'),
      to_date: moment.utc(this.datePipe.transform(to_date, 'yyyy-MM-dd')).utcOffset('+0:00').format('YYYY-MM-DD 23:59:59.000 +00:00'),
      filterText: '',
      sort: this.sort,
      pageSize: this.pageSize
    }
    if (this.storeId === 'All') {
      this.reportService.getAllSalesOverTime(data).subscribe((res) => {
        if (res && res['salesData'] && res['totalSalesData']) {
          this.frameChart(res, newData);
        }
      });
    } else if (this.storeId !== null) {
      this.reportService.getSalesOverTime(this.storeId, data).subscribe((res) => {
        if (res && res['salesData'] && res['totalSalesData']) {
          this.frameChart(res, newData);
        }
      });
    } else {
      this.dialogDisplay(this.commonConstant &&
        this.commonConstant.errorMessage
        && this.commonConstant.errorMessage.errorMessage,
        this.commonConstant.dialogType.failure);
    }
  }
  /**
     *Function which is used to frame chart details.
     */
  frameChart(salesResponse, isNew): void {
    const start_date = this.fromDate ? _.cloneDeep(this.fromDate) : null;
    const to_date = this.toDate ? this.toDate : null;
    if (salesResponse && salesResponse['salesData']) {
      salesResponse['salesData'].forEach((item) => {
        item.giftcardamt = (item.giftcardamt ? Number(item.giftcardamt) : 0) - (item.refundedgiftcardamount ? Number(item.refundedgiftcardamount) : 0);
        item.loyalty_value = (item.loyalty_value ? Number(item.loyalty_value) : 0) - (item.loyalty_refunded_value ? Number(item.loyalty_refunded_value) : 0);
        item.amount_paid = ((item.giftcardamt ? Number(item.giftcardamt) : 0) - (item.refundedgiftcardamount ? Number(item.refundedgiftcardamount) : 0)) + (item.amount_paid ? Number(item.amount_paid) : 0);
        item.total_income =
          (item.order_charge ? Number(item.order_charge) : 0) + (item.tax ? Number(item.tax) : 0) + (item.tip ? Number(item.tip) : 0) + (item.giftcardamt ? Number(item.giftcardamt) : 0) +
          (item.total_amount ? Number(item.total_amount) : 0) - (item.discount ? Number(item.discount) : 0) - (item.refund_amount ? Number(item.refund_amount) : 0) - (item.refundedgiftcardamount ? item.refundedgiftCardamount : 0);
        item.discount = item.discount && item.discount !== 'NaN' ? Number(item.discount) : 0;
        item.extra_amounts = (item.tip && item.tip >= 0) || (item.service_charge && item.service_charge >= 0) || (item.additional_charge && item.additional_charge >= 0) || (item.cod_amount && item.cod_amount >= 0) ? (Number(item.tip) + Number(item.service_charge) + Number(item.additional_charge) + Number(item.cod_amount)).toFixed(2) : 0.00;
        item.amount_to_pay = ((item.sub_total ? (Number(item.sub_total)) : 0) + (item.tax ? (Number(item.tax)) : 0) + (item.extra_amounts ? (Number(item.extra_amounts)) : 0) + (item.order_charge ? (Number(item.order_charge)) : 0) - (((item.giftcardamt ? (Number(item.giftcardamt)) : 0) - (item.refundedgiftcardamount ? Number(item.refundedgiftcardamount) : 0)) + (item.discount ? (Number(item.discount)) : 0))).toFixed(2);
        // item.discounted_price = item.sub_total && Number(item.discount) >= 0 && Number(item.other_discount) >= 0 ? (Number(item.sub_total) - (Number(item.discount) + Number(item.other_discount))).toFixed(2) : item.sub_total;
        item.discount_amount = ((item.order_amount ? Number(item.order_amount) : 0) - (item.sub_total ? Number(item.sub_total) : 0)) + (item.loyalty_value ? Number(item.loyalty_value) : 0);
        item.refunded_amount = (item.refundedgiftcardamount ? Number(item.refundedgiftcardamount) : 0) + (item.refund_amount ? Number(item.refund_amount) : 0) + (item.loyalty_refunded_value ? Number(item.loyalty_refunded_value) : 0);
        item.net_sale = (item.order_amount ? Number(item.order_amount) : 0) - (item.discount_amount ? Number(item.discount_amount) : 0) 
        // - (item.refunded_amount ? Number(item.refunded_amount) : 0)
         - (item.giftcardamt ? Number(item.giftcardamt) : 0);
        item.total_amount = (item.order_amount ? Number(item.order_amount) : 0) - (item.discount_amount ? Number(item.discount_amount) : 0) - (item.giftcardamt ? Number(item.giftcardamt) : 0) - (item.refunded_amount ? Number(item.refunded_amount) : 0) + (item.tax ? Number(item.tax) : 0) + (item.order_charge ? Number(item.order_charge) : 0) + (item.extra_amounts ? Number(item.extra_amounts) : 0);
      });
      this.pageDetails.listDataSource = salesResponse['salesData'] ? salesResponse['salesData'] : [];
      this.pageDetails.paginator = {
        new: isNew,
        rows: this.pageDetails.listDataSource,
        count: salesResponse['salesCount'] ? salesResponse['salesCount'] : '',
      }
      let date = [];
      let income = [];
      let dateValue;
      if (start_date && to_date) {
        for (let i = start_date; i <= to_date; i.setDate(i.getDate() + 1)) {
          dateValue = this.datePipe.transform(i, 'yyyy-MM-dd');
          date.push(dateValue);
          if (salesResponse['salesData'] && salesResponse['salesData'].length !== 0) {
            let index = salesResponse['salesData'].findIndex(x => x.availabledate === dateValue);
            const salesData = salesResponse['salesData'];
            if (index >= 0) {
              salesData[index].total_amount ? income.push(Math.round(salesData[index].total_amount)) : income.push(0);
            } else {
              income.push(0);
            }
          }

          else {
            income.push(0);
          }
        }
        this.chartOptions = {
          chart: {
            type: "line"
          },
          title: {
            text: "Revenue Report"
          },
          xAxis: {
            categories: date
          },
          yAxis: {
            title: {
              text: "Total Income"
            }
          },
          series: [{
            name: 'Total Sales',
            data: income
          }]
        };
      }
      this.pageDetails.listProperty = {
        globalSearch: false,
        columns: 2,
        dataOnly: true,
        suffixText: true,
        checkBox: false,
        isImageDisplay: false
      };
      this.pageDetails.checker = false;
      this.pageDetails.isLoader = false;
      this.paginatorService.listPaginatorData.next(this.pageDetails.paginator);
      this.salesData = {};

      this.salesData = salesResponse['totalSalesData'];
    }
  }
  /**
   *Function which is used to get the revenue details based on given date range.
   */
  onDataChange(event: any): void {
    this.getSalesOverTime(event.offset, event.limit, false);
  }
  /**
 * Method which is used to get changed page data.
 * @param event To get offset and limit.
 */
  changedData(event: any): void {
    if (event) {
      this.isPageChange = false;
      this.paginatorValue.limit = event.limit;
      this.paginatorValue.offset = event.offset;
    }
    this.getSalesOverTime(event.offset, event.limit, false);
  }
  /**
   * Method which is used to get paginator data.
   * @param event To get offset and limit.
   */
  paginatorData(event: any): void {
    if (event) {
      this.isPageChange = false;
      this.paginatorLimit = event.pageSize ? event.pageSize : null;
      this.paginatorOffset = event?.pageIndex * event?.pageSize;
      this.paginatorValue.limit = event.pageSize ? event.pageSize : 0;
      this.paginatorValue.offset = event?.pageIndex * event?.pageSize;
      this.pageSize = event.pageSize ? event.pageSize : null;
    }
  }

  /**
 * Method which is used to get filter data.
 * @param event To get location id, store id, from date, to date, offset and limit.
 */
  filterAction(event: any): void {
    if (event) {
      event.limit = event.pageSize ? event.limit : this.pageSize * 2;
      this.locationId = event.location_id ? event.location_id : null;
      this.storeId = event.store_id ? event.store_id : null;
      if (event.from_date && event.to_date) {
        this.paginatorValue.limit = event.limit ? event.limit : this.pageSize * 2;
        this.paginatorValue.offset = event.offset;
      }
      this.fromDate = event.from_date ? event.from_date : this.fromDate;
      this.toDate = event.to_date ? event.to_date : this.toDate;
      this.sort = event.sort ? event.sort : null;
      if (event.exportexcel) {
        this.exportTotalSales(event.offset, event.limit);
      } else {
        this.getSalesOverTime(event.offset, event.limit, true);
      }
    }
  }

  /**
 *Function which is used to export revenue details.
 */
  exportTotalSales(offset, limit): void {
    if (this.pageDetails && this.pageDetails.listDataSource && this.pageDetails.listDataSource.length > 0) {
      let filterConstrain = {
        from_date: this.fromDate ? this.datePipe.transform(this.fromDate, 'yyyy-MM-dd') : null,
        to_date: this.toDate ? this.datePipe.transform(this.toDate, 'yyyy-MM-dd') : null,
        locationId: this.locationId ? this.locationId : 'All',
        storeId: this.currentUser?.storeId ? this.currentUser.storeId : this.storeId ? this.storeId : null
      }
      const data: ExportObject = {
        title: 'Export ',
        exportApiUrl: 'stores/' + this.currentUser.storeId + '/export/report/revenue',
        limit: this.paginatorLimit ? this.paginatorLimit : this.paginatorValue.limit ? this.paginatorValue.limit : this.pageSize,
        offset: this.paginatorOffset ? this.paginatorOffset : this.paginatorValue.offset ? this.paginatorValue.offset : 0,
        type: false,
        fileName: 'sales',
        expansion: true,
        filterData: filterConstrain ? filterConstrain : null,
        selectedData: []
      };
      const dialogRefData = this.displayDialog.open(ExportComponent, { disableClose: true });
      if (dialogRefData && dialogRefData.componentInstance) {
        dialogRefData.componentInstance.contentData = data;
      }
    } else {
      this.dialogDisplay(this.commonConstant &&
        this.commonConstant.dialogMessages
        && this.commonConstant.dialogMessages.noDataExport,
        this.commonConstant.dialogType.alert);
    }
  }

  /**
* Method used to display the dialog
* @param message has the message
* @param type has the dialog type.
*/
  dialogDisplay(message, type): any {
    return this.dialogService.dialogMethod(message, type, true);
  }

  /**
 *Function which is used to navigate to dashboard page.
 */
  back(): void {
    this.router.navigate(['app/dashboard']);
  }

  /**
  * Component OnDestroy life cycle hook.
  * And unsubscribe all the subscriptions in the component.
  */
  ngOnDestroy(): void {
    this.subscriptionObj.unsubscribe();
  }
}
