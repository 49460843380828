<!-- Footer with solid background color -->
<div *ngIf="footerBackgroundDetails?.backgroundColorType; else elseBackground">
    <!-- style="clip-path: polygon(0 34%, 100% 15%, 100% 100%, 0 100%);" -->
    <ng-container [ngTemplateOutlet]="getTemplateName(footerBackgroundDetails?.backgroundColorType)"
        [ngTemplateOutletContext]="{footerDetails: footerDetails, footerBackgroundDetails: footerBackgroundDetails}"></ng-container>
</div>

<ng-template #elseBackground>
    <div class="large-new" style="background-color:#373737" fxLayout="column">
        <ng-container [ngTemplateOutlet]="footerTemplate"
            [ngTemplateOutletContext]="{footerDetails: footerDetails}"></ng-container>
    </div>
</ng-template>

<ng-template #solid let-footerDetails="footerDetails" let-footerBackgroundDetails="footerBackgroundDetails">
    <div class="large-new"
        [ngStyle]="{'background-color': footerBackgroundDetails?.backgroundColor ? footerBackgroundDetails?.backgroundColor : '#373737'}"
        fxLayout="column">
        <ng-container [ngTemplateOutlet]="footerTemplate"
            [ngTemplateOutletContext]="{footerDetails: footerDetails}"></ng-container>
    </div>
</ng-template>

<ng-template #gradient let-footerDetails="footerDetails" let-footerBackgroundDetails="footerBackgroundDetails">
    <div class="large-new" fxLayout="column"
        [ngStyle]="{'background-image': 'linear-gradient(to right,'+footerBackgroundDetails?.gradientColor1+','+footerBackgroundDetails?.gradientColor2+')'}">
        <ng-container [ngTemplateOutlet]="footerTemplate"
            [ngTemplateOutletContext]="{footerDetails: footerDetails}"></ng-container>
    </div>
</ng-template>

<ng-template #footerTemplate let-footerDetails="footerDetails">
    <div fxLayout="column" fxLayoutAlign="end none">
        <div fxLayout="row wrap" fxLayoutGap="5%" fxLayoutAlign="center start" fxLayoutAlign.lt-sm="start start"
            class="main-footer" [ngStyle]="{'margin.px': footerDetails?.margin ? footerDetails?.margin : '20'}">
            <ng-container *ngIf="footerDetails?.footerContentAlignment">
                <ng-container *ngFor="let footerSection of footerDetails?.footerContentAlignment">
                    <ng-container [ngTemplateOutlet]="getTemplateName(footerSection?.templateName)"
                        [ngTemplateOutletContext]="{footerDetails: footerDetails}"></ng-container>
                </ng-container>
            </ng-container>
            <!-- <ng-template #elseBlock>
                <ng-container *ngFor="let footerCont of footerAlignment">
                    <ng-container [ngTemplateOutlet]="getTemplateName(footerCont?.templateName)"
                        [ngTemplateOutletContext]="{footerDetails: footerDetails}"></ng-container>
                </ng-container>
            </ng-template> -->
        </div>
        <div *ngIf="footerDetails?.isDisplayDisclaimer" class="footer-disclaimer"
            [ngStyle]="{'font-size.px': footerDetails?.disclaimerTextSize ? footerDetails?.disclaimerTextSize : '15', 'color': footerDetails?.disclaimerTextColor ? footerDetails?.disclaimerTextColor : '#ffffff', 'font-family': footerDetails?.disclaimerTextFontFamily ? footerDetails?.disclaimerTextFontFamily : 'Montserrat', 'font-weight': footerDetails?.disclaimerTextFontStyle?.includes(fontStyleValues?.bold) ? 'bold' : 'normal', 'font-style': footerDetails?.disclaimerTextFontStyle?.includes(fontStyleValues?.italic) ? 'italic' : 'normal', 'text-decoration' : footerDetails?.disclaimerTextFontStyle?.includes(fontStyleValues?.underline) ? 'underline' : 'none'}"
            fxLayout.gt-xs="row" fxLayoutAlign.gt-xs="center center">
            {{footerDetails?.disclaimerText | convertLanguage:language}}
        </div>
    </div>



</ng-template>
<ng-template #about>
    <div style="margin-right: 4px;" *ngIf="footerDetails?.isDisplayAbout">
        <div class="Heading"
            [ngStyle]="{'font-size.px': footerDetails?.titleTextSize ? footerDetails?.titleTextSize : '17', 'color': footerDetails?.titleTextColor ? footerDetails?.titleTextColor : '#ffffff', 'font-family': footerDetails?.textFontFamily ? footerDetails?.textFontFamily : 'Montserrat', 'font-weight': footerDetails?.textFontStyle?.includes(fontStyleValues?.bold) ? 'bold' : 'normal', 'font-style': footerDetails?.textFontStyle?.includes(fontStyleValues?.italic) ? 'italic' : 'normal', 'text-decoration' : footerDetails?.textFontStyle?.includes(fontStyleValues?.underline) ? 'underline' : 'none'}">
            {{footerDetails?.aboutTitle | convertLanguage:language}}</div>
        <div class="aboutus-text"
            [ngStyle]="{'font-size.px': footerDetails?.subtextSize ? footerDetails?.subtextSize : '15', 'color': footerDetails?.subtextColor ? footerDetails?.subtextColor : '#ffffff', 'font-family': footerDetails?.subtextFontFamily ? footerDetails?.subtextFontFamily : 'Montserrat', 'font-weight': footerDetails?.subtextFontStyle?.includes(fontStyleValues?.bold) ? 'bold' : 'normal', 'font-style': footerDetails?.subtextFontStyle?.includes(fontStyleValues?.italic) ? 'italic' : 'normal', 'text-decoration' : footerDetails?.subtextFontStyle?.includes(fontStyleValues?.underline) ? 'underline' : 'none'}">
            {{footerDetails?.aboutText | convertLanguage:language}}</div>
    </div>
</ng-template>
<ng-template #otherMenus>
    <div *ngIf="customMenuHeading.length">
        <div class="Heading"
            [ngStyle]="{'font-size.px': footerDetails?.titleTextSize ? footerDetails?.titleTextSize : '17', 'color': footerDetails?.titleTextColor ? footerDetails?.titleTextColor : '#ffffff', 'font-family': footerDetails?.textFontFamily ? footerDetails?.textFontFamily : 'Montserrat', 'font-weight': footerDetails?.textFontStyle?.includes(fontStyleValues?.bold) ? 'bold' : 'normal', 'font-style': footerDetails?.textFontStyle?.includes(fontStyleValues?.italic) ? 'italic' : 'normal', 'text-decoration' : footerDetails?.textFontStyle?.includes(fontStyleValues?.underline) ? 'underline' : 'none'}">
            {{footerDetails?.otherMenusTitle | convertLanguage:language}}</div>
        <ng-container *ngFor="let header of customMenuHeading">
            <div class="footer-menu"
                [ngStyle]="{'font-size.px': footerDetails?.subtextSize ? footerDetails?.subtextSize : '15', 'color': footerDetails?.subtextColor ? footerDetails?.subtextColor : '#ffffff', 'font-family': footerDetails?.subtextFontFamily ? footerDetails?.subtextFontFamily : 'Montserrat', 'font-weight': footerDetails?.subtextFontStyle?.includes(fontStyleValues?.bold) ? 'bold' : 'normal', 'font-style': footerDetails?.subtextFontStyle?.includes(fontStyleValues?.italic) ? 'italic' : 'normal', 'text-decoration' : footerDetails?.subtextFontStyle?.includes(fontStyleValues?.underline) ? 'underline' : 'none'}"
                (click)="navigate(header?.menuCustomLink)" [attr.data-control]="'navigateTo' + header?.menuName">
                {{header?.translatedMenuName?.[language] ?? header?.menuName}}</div>
        </ng-container>
    </div>
</ng-template>
<ng-template #information>
    <div *ngIf="menuHeading.length">
        <div class="Heading"
            [ngStyle]="{'font-size.px': footerDetails?.titleTextSize ? footerDetails?.titleTextSize : '17', 'color': footerDetails?.titleTextColor ? footerDetails?.titleTextColor : '#ffffff', 'font-family': footerDetails?.textFontFamily ? footerDetails?.textFontFamily : 'Montserrat', 'font-weight': footerDetails?.textFontStyle?.includes(fontStyleValues?.bold) ? 'bold' : 'normal', 'font-style': footerDetails?.textFontStyle?.includes(fontStyleValues?.italic) ? 'italic' : 'normal', 'text-decoration' : footerDetails?.textFontStyle?.includes(fontStyleValues?.underline) ? 'underline' : 'none'}">
            {{footerDetails?.informationTitle | convertLanguage:language}}</div>
        <ng-container *ngFor="let header of menuHeading">
            <div class="footer-menu" *ngIf="header?.menuCustomLink==='aboutus' || header?.menuCustomLink==='store/faq'"
                [ngStyle]="{'font-size.px': footerDetails?.subtextSize ? footerDetails?.subtextSize : '15', 'color': footerDetails?.subtextColor ? footerDetails?.subtextColor : '#ffffff', 'font-family': footerDetails?.subtextFontFamily ? footerDetails?.subtextFontFamily : 'Montserrat', 'font-weight': footerDetails?.subtextFontStyle?.includes(fontStyleValues?.bold) ? 'bold' : 'normal', 'font-style': footerDetails?.subtextFontStyle?.includes(fontStyleValues?.italic) ? 'italic' : 'normal', 'text-decoration' : footerDetails?.subtextFontStyle?.includes(fontStyleValues?.underline) ? 'underline' : 'none'}"
                (click)="navigate(header?.menuCustomLink)" [attr.data-control]="'navigateTo' + header?.menuName">
                {{ getTranslatedTitle(header?.menuName)}}</div>
            <!-- <div *ngFor=" let menu of header?.subMenu">
                <a class="service-list Heading"
                    [ngStyle]="{'font-size.px': footerDetails?.subtextSize ? footerDetails?.subtextSize : '15', 'color': footerDetails?.subtextColor ? footerDetails?.subtextColor : '#ffffff', 'font-family': footerDetails?.subtextFontFamily ? footerDetails?.subtextFontFamily : 'Montserrat', 'font-weight': footerDetails?.subtextFontStyle?.includes(fontStyleValues?.bold) ? 'bold' : 'normal', 'font-style': footerDetails?.subtextFontStyle?.includes(fontStyleValues?.italic) ? 'italic' : 'normal', 'text-decoration' : footerDetails?.subtextFontStyle?.includes(fontStyleValues?.underline) ? 'underline' : 'none'}"
                    (click)="navigate(menu?.menuCustomLink)" [attr.data-control]="'navigateTo' + menu?.menuName"
                    routerLink={{menu?.menuCustomLink}}>{{menu?.menuName}}</a><br />
            </div> -->
        </ng-container>
        <ng-container *ngFor="let policy of storePolicies">
            <div class="footer-menu"
                [ngStyle]="{'font-size.px': footerDetails?.subtextSize ? footerDetails?.subtextSize : '15', 'color': footerDetails?.subtextColor ? footerDetails?.subtextColor : '#ffffff', 'font-family': footerDetails?.subtextFontFamily ? footerDetails?.subtextFontFamily : 'Montserrat', 'font-weight': footerDetails?.subtextFontStyle?.includes(fontStyleValues?.bold) ? 'bold' : 'normal', 'font-style': footerDetails?.subtextFontStyle?.includes(fontStyleValues?.italic) ? 'italic' : 'normal', 'text-decoration' : footerDetails?.subtextFontStyle?.includes(fontStyleValues?.underline) ? 'underline' : 'none'}"
                [attr.data-control]="'navigateTo' + policy?.policyName" (click)="navigateTo(policy?.id)">
                {{policy?.policyName}}</div>
        </ng-container>
    </div>

</ng-template>
<ng-template #contact>
    <div fxLayout="column">
        <div class=" Heading"
            [ngStyle]="{'font-size.px': footerDetails?.titleTextSize ? footerDetails?.titleTextSize : '17', 'color': footerDetails?.titleTextColor ? footerDetails?.titleTextColor : '#ffffff', 'font-family': footerDetails?.textFontFamily ? footerDetails?.textFontFamily : 'Montserrat', 'font-weight': footerDetails?.textFontStyle?.includes(fontStyleValues?.bold) ? 'bold' : 'normal', 'font-style': footerDetails?.textFontStyle?.includes(fontStyleValues?.italic) ? 'italic' : 'normal', 'text-decoration' : footerDetails?.textFontStyle?.includes(fontStyleValues?.underline) ? 'underline' : 'none'}">
            {{footerDetails?.contactTitle | convertLanguage:language}}</div>
        <div fxLayout="column" class="address-align">
            <ng-container *ngFor="let location of locationDetail">
                <div fxLayout="column"
                    [ngStyle]="{'font-size.px': footerDetails?.subtextSize ? footerDetails?.subtextSize : '15', 'color': footerDetails?.subtextColor ? footerDetails?.subtextColor : '#ffffff', 'font-family': footerDetails?.subtextFontFamily ? footerDetails?.subtextFontFamily : 'Montserrat', 'font-weight': footerDetails?.subtextFontStyle?.includes(fontStyleValues?.bold) ? 'bold' : 'normal', 'font-style': footerDetails?.subtextFontStyle?.includes(fontStyleValues?.italic) ? 'italic' : 'normal', 'text-decoration' : footerDetails?.subtextFontStyle?.includes(fontStyleValues?.underline) ? 'underline' : 'none'}">
                    <div *ngIf="location?.address" fxLayout="row" style="margin-bottom: 10px;">
                        <mat-icon class="contact-icon"
                            [ngStyle]="{'height.px': footerDetails?.contactIconSize ? footerDetails?.contactIconSize : '15', 'width.px': footerDetails?.contactIconSize ? footerDetails?.contactIconSize : '15', 'font-size.px': footerDetails?.contactIconSize ? footerDetails?.contactIconSize : '15', 'color': footerDetails?.contactIconColor ? footerDetails?.contactIconColor : '#ffffff'}">place</mat-icon>
                        <div fxLayout="column" style="width:270px"
                            [ngStyle]="{'font-family': footerDetails?.subtextFontFamily ? footerDetails?.subtextFontFamily : 'Montserrat', 'font-weight': footerDetails?.subtextFontStyle?.includes(fontStyleValues?.bold) ? 'bold' : 'normal', 'font-style': footerDetails?.subtextFontStyle?.includes(fontStyleValues?.italic) ? 'italic' : 'normal', 'text-decoration' : footerDetails?.subtextFontStyle?.includes(fontStyleValues?.underline) ? 'underline' : 'none'}">
                            <span *ngIf="location?.address?.addressLine1" class="contact-font">
                                {{location?.address?.addressLine1}}
                            </span>
                            <span *ngIf="location?.address?.city" class="contact-font">
                                {{location?.address?.stateDetail?.name ? location?.address?.city+',':
                                location?.address?.city}}{{location?.address?.countryDetail?.name ?
                                location?.address?.stateDetail?.name+','
                                : location?.address?.stateDetail?.name}}
                            </span>
                            <span *ngIf="location?.address?.countryDetail?.name" class="contact-font">
                                {{location?.address?.zipCode ? location?.address?.countryDetail?.name+' - ':
                                location?.address?.countryDetail?.name}}{{location?.address?.zipCode ?
                                location?.address?.zipCode:
                                ''}}
                            </span>
                        </div>
                    </div>
                    <span *ngIf="location?.address?.mobileNumber" fxLayout="row" class="contact-font">
                        <mat-icon class="contact-icon"
                            [ngStyle]="{'height.px': footerDetails?.contactIconSize ? footerDetails?.contactIconSize : '15', 'width.px': footerDetails?.contactIconSize ? footerDetails?.contactIconSize : '15', 'font-size.px': footerDetails?.contactIconSize ? footerDetails?.contactIconSize : '15', 'color': footerDetails?.contactIconColor ? footerDetails?.contactIconColor : '#ffffff'}">phone</mat-icon>
                        <span>+{{location?.address?.countryCode}}&nbsp;{{location?.address?.mobileNumber}}</span>
                    </span>
                    <span *ngIf="location?.address?.email" fxLayout="row" class="contact-font">
                        <mat-icon class="contact-icon"
                            [ngStyle]="{'height.px': footerDetails?.contactIconSize ? footerDetails?.contactIconSize : '15', 'width.px': footerDetails?.contactIconSize ? footerDetails?.contactIconSize : '15', 'font-size.px': footerDetails?.contactIconSize ? footerDetails?.contactIconSize : '15', 'color': footerDetails?.contactIconColor ? footerDetails?.contactIconColor : '#ffffff'}">email</mat-icon>
                        <span>{{location?.address?.email}}</span>
                    </span>
                </div>
            </ng-container>
            <!-- section for displaying social media links -->
            <div fxLayout="row" fxLayoutGap="2%">
                <div *ngIf="storeDetails?.faceBook" (click)="navigate(storeDetails?.faceBook)">
                    <img class="social-media-image" src="assets\theme-images\facebook.png" alt="facebookImage">
                </div>
                <div *ngIf="storeDetails?.instagram" (click)="navigate(storeDetails?.instagram)">
                    <img class="social-media-image" src="assets\theme-images\instagram.png" alt="instagramImage">
                </div>
                <div *ngIf="storeDetails?.linkedIn" (click)="navigate(storeDetails?.linkedIn)">
                    <img class="social-media-image" style="margin-left: 5px;" src="assets\theme-images\linkedin.png"
                        alt="linkedinImage">
                </div>
                <div *ngIf="storeDetails?.pinterest" (click)="navigate(storeDetails?.pinterest)">
                    <img class="social-media-image" style="margin-left: 5px;" src="assets\theme-images\pinterest.png"
                        alt="pinterestImage">
                </div>
                <div *ngIf="storeDetails?.twitter" (click)="navigate(storeDetails?.twitter)">
                    <img class="social-media-image" style="margin-left: 5px;" src="assets\theme-images\twitter.png"
                        alt="twitterImage">
                </div>
                <div *ngIf="storeDetails?.youtube" (click)="navigate(storeDetails?.youtube)">
                    <img class="social-media-image" style="margin-left: 5px;" src="assets\theme-images\youtube.png"
                        alt="youtubeImage">
                </div>
            </div>
        </div>
    </div>
</ng-template>

<div class="footer-copyright"
    [ngStyle]="{'background-color': footerBackgroundDetails?.copyRightBackgroundColor ? footerBackgroundDetails?.copyRightBackgroundColor : '#2c2b2b'}"
    fxLayout.gt-xs="row" fxLayoutAlign.gt-xs="center center">
    <footer id="footer" fxFlex.gt-xs="80%" fxFlex.xs="100%">
        <div style="margin-top: -4px; padding-bottom: 0px;" fxLayoutAlign="center center">
            <div fxLayoutAlign.lt-xs="center center"
                [ngStyle]="{'font-size.px': footerDetails?.copyrightTextSize ? footerDetails?.copyrightTextSize : '15', 'color': footerDetails?.copyrightTextColor ? footerDetails?.copyrightTextColor : '#ffffff', 'font-family': footerDetails?.copyrightTextFontFamily ? footerDetails?.copyrightTextFontFamily : 'Montserrat', 'font-weight': footerDetails?.copyrightTextFontStyle?.includes(fontStyleValues?.bold) ? 'bold' : 'normal', 'font-style': footerDetails?.copyrightTextFontStyle?.includes(fontStyleValues?.italic) ? 'italic' : 'normal', 'text-decoration' : footerDetails?.copyrightTextFontStyle?.includes(fontStyleValues?.underline) ? 'underline' : 'none'}">
                {{'SHARED.COPY_RIGHTS' | translate}} {{currentYear}} | {{'SHARED.ALL_RIGHTS_RESERVED' | translate}}.
                <span *ngIf="footerDetails?.showPoweredBy">
                    <span *ngIf="!language || language === 'en'">Powered by</span><a href="https://getzenbasket.com/"
                        target="_blank" rel="noopener noreferrer" data-control="goToZenbasketPage"
                        style="padding-left: 5px;">ZenBasket
                    </a>&nbsp;1.0.212 <span *ngIf="language && language !== 'en'">&nbsp;{{'SHARED.POWERED_BY' |
                        translate}}</span></span>
            </div>
        </div>
    </footer>
</div>