/**
 * Component used to redeem coupon code.
 * AUTHOR: SUJIN S S (CEN250)
 */
import { UntypedFormControl, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from "@angular/material/dialog";
import { Component, Inject, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { DialogService, LoadTranslationService } from '@phase-ii/common';
import { dataConfig } from 'libs/common/src/lib/services/config';
import { Subscription } from 'rxjs';
import { SharedService } from 'libs/themes/shared-theme/src/lib/services/shared.service';

/**
 * Component used to redeem coupon code.
 */
@Component({
  selector: 'phase-ii-redeem-coupon-code',
  templateUrl: './redeem-coupon-code.component.html',
  styleUrls: ['./redeem-coupon-code.component.scss']
})
export class RedeemCouponCodeComponent implements OnInit {
  /**
* View child property used to display the reason to display category level products.
* @type {TemplateRef}
*/
  @ViewChild('categoryLevelProducts', { static: true }) categoryLevelProducts: TemplateRef<any>;
  /**
   * Variable used to store the details of selected coupon
   */
  selectedCoupon: any;
  /**
   * Variable used to store the product details
   */
  productId: UntypedFormControl;
  /**
   * Variable used to store the category related products
   */
  categoryProducts = [];
  /**
   * Variable which is used to create a form control for the coupon code.
   */
  couponCode = new UntypedFormControl(null, [Validators.required, Validators.pattern(dataConfig.patternValidators.noSpaceValidationPattern), Validators.pattern(dataConfig.patternValidators.noSpaceInBegAndEnd)]);
  /**
   * Variable used to store the coupon code data
   */
  couponCodeObject: any;
  /**
   * subscription object
   */
  subscriptionObject: Subscription = new Subscription();
  /**
   * Variable which is used to store currency details.
   */
  details: any;
  /**
   * Variable used to store the current index.
   */
  index: any;

  /**
   * component constructor which is used to inject the required services.
   * @param sharedService To access methods and properties from shared service
   * @param dialogService To access methods and properties from dialog service
   * @param dialogRef To access methods and properties from dialogRef
   * @param data To access dialog data
   */
  constructor(
    private dialogService: DialogService,
    private sharedService: SharedService,
    private dialog: MatDialog,
    private dialogRef: MatDialogRef<RedeemCouponCodeComponent>,
    @Inject(MAT_DIALOG_DATA) data,
    private translationService: LoadTranslationService
  ) {
    this.couponCodeObject = data;
  }
  /**
   * Angluar inital life cycle hook
   */
  ngOnInit(): void {
    this.subscriptionObject.add(this.sharedService.commonWebsiteDetails.subscribe((res: any) => {
      if (res && res.currency) {
        this.details = res.currency;
      }
    }));
    if (this.couponCodeObject.singleOrder) {
      this.couponCode.setValidators([Validators.required]);
    }
  }
  /**
   * Method which used to close the dialog
   **/
  onNoClick() {
    this.dialogRef.close();
  }
  /**
  * Method which used to submit the coupon code.
  **/
  submit() {
    let couponValue;
    let eligibleproductList = [];
    if (this.couponCode.valid && this.couponCode.value) {
      if (this.couponCodeObject && this.couponCodeObject.title === 'Claim Offer') {
        // console.log('this.selectedCoupon', this.selectedCoupon);

        if (this.selectedCoupon.buy || this.selectedCoupon.get) {
          if (this.selectedCoupon.isCategory) {
            const queryParams = {
              locationId: this.couponCodeObject.locationId,
              customerId: this.couponCodeObject.customerId,
              categoryId: this.selectedCoupon.get && this.selectedCoupon.get.length && this.selectedCoupon.get[0] && this.selectedCoupon.get[0].id,
              orders: this.couponCodeObject.singleOrder ? true : null
            };
            this.subscriptionObject.add(this.sharedService.getAllQuickOrderProducts(this.couponCodeObject.storeId, queryParams).subscribe((res) => {
              // console.log('res value', res);
              if (res && res['productData'] && res['productData'].length) {
                // this.categoryProducts = res['productData'];
                this.categoryProducts = res['productData'].filter((item) => item && item.multiLocationVariantStocks && item.multiLocationVariantStocks.length && item.multiLocationVariantStocks[0] && item.multiLocationVariantStocks[0].stock);
              } else {
                this.categoryProducts = [];
              }
              this.productId = new UntypedFormControl(null);
              this.dialog.open(this.categoryLevelProducts, { disableClose: true, width: '500px' });
            }));
          } else {
            couponValue = {
              "buyXgetY": {
                discountId: this.selectedCoupon.discountId ? this.selectedCoupon.discountId : null,
                isCategory: this.selectedCoupon.isCategory
              }
            }
            this.dialogRef.close(couponValue);
          }
        } else {
          this.selectedCoupon.eligibleProductId.forEach((value) => {
            eligibleproductList.push(value.productId);
          });
          couponValue = {
            "automatic": [{
              brandId: this.selectedCoupon.brandId ? this.selectedCoupon.brandId : null,
              categoryId: this.selectedCoupon.categoryId ? this.selectedCoupon.categoryId : null,
              discountId: this.selectedCoupon.id ? this.selectedCoupon.id : null,
              productId: eligibleproductList
            }]
          }
          this.dialogRef.close(couponValue);
        }
      } else {
        couponValue = this.couponCode.value;
        this.dialogRef.close(couponValue);
      }
      // this.dialogRef.close(couponValue);
    } else {
      this.dialogService.openSnackBar('Please choose a valid discount.');
    }
  }
  /**
  * Method used to select the category level product
  * @param value selected category level product
  */
  ChosenCategoryProduct() {
    let couponValue;
    couponValue = {
      "buyXgetY": {
        isCategory: this.selectedCoupon && this.selectedCoupon.isCategory,
        name: this.productId && this.productId.value && this.productId.value.product && this.productId.value.product.name, variantId: this.productId && this.productId.value && this.productId.value.id,
        discountId: (this.selectedCoupon && this.selectedCoupon.discountId) ? this.selectedCoupon.discountId : null,
      }
    };
    this.dialog.closeAll();
    this.dialogRef.close(couponValue);
  }
  /**
   * Method used to select the particular coupon code
   * @param selCoupon selected coupon code
   */
  selectCouponCode(selCoupon: any, index: any) {
    this.selectedCoupon = selCoupon;
    this.index = index;
    this.couponCode.setValue(selCoupon.promoCode);
  }

  /**
   * Method used to translate static messages with dynamic contents.
   * @param key contains constant object key.
   * @param values contains dynamic contents.
   * @returns translated text.
   */
  getTranslationContent(key: string, values: any): string {
    return this.translationService.dynamicMessageTranslation(key, values);
  }

  /**
  * Component onDestroy life cycle hook.
  * All subscriptions are unsubscribe here.
 */
  ngOnDestroy(): void {
    this.subscriptionObject.unsubscribe();
  }
}
