/**
 * Guard which is used to check whether the changes are saved when navigate to other page.
 */
import { Injectable } from '@angular/core';
import { CanDeactivate } from '@angular/router';
import { LoadTranslationService } from '@phase-ii/common';
import { SharedConstant } from 'libs/common/src/lib/constants/shared-constant';
import { DialogService } from 'libs/common/src/lib/services/dialog.service';
import { Observable, Observer } from 'rxjs';
import { filter } from 'rxjs/operators';
/**
 * Abstract class method which is used to declare the canDeactivate method.
 */
export abstract class FormCanDeactivate {
  /**
   * abstract function,It can be implemented in component. Observable<boolean>|
   * @return {boolean}
   */
  abstract canDeactivate(): boolean;
}
/**
 * Guard which is used to check whether the changes are saved when navigate to other page
 */
@Injectable()
export class CanDeactivateGuard extends SharedConstant implements CanDeactivate<FormCanDeactivate> {
  /**
   * Component constructor which is used to inject the required services.
   * @param dialogService To access the functions inside the DialogService.
   */
  constructor(private dialogService: DialogService, private translationService: LoadTranslationService) {
    super();
  }
  /**
   * Method which is used to dispaly dialog when navigate to another route without saving from data.
   * @param component To get the FormCanDeactivate component.
   */
  canDeactivate(component: FormCanDeactivate): Observable<boolean> | boolean {
    // To check whether the component have unsaved changes.
    if (component && !component.canDeactivate()) {
      // If component have unsaved changes then create observable to return the selected value.
      return new Observable((observer: Observer<boolean>) => {
        const dialogRef = this.dialogService.openDialog({
          header: this.translationService.getTranslation('COMMON.ARE_YOU_SURE'),
          message: this.translationService.getTranslation('COMMON.CONFIRMATION_MESSAGE'),
          actionType: this.dialogType.confirmation,
          button: { left: this.translationService.getTranslation('COMMON.NO'), right: this.translationService.getTranslation('COMMON.YES') },
          disableClose: true
        });
        dialogRef.afterClosed().pipe(filter((result: boolean) => {
          return result;
        })).subscribe((res) => {
          observer.next(true);
          observer.complete();
        });
      }
      );
    } else {
      return true;
    }
  }
}
