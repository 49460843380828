import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { filter, mergeMap } from 'rxjs/operators';

import { DialogService } from 'libs/common/src/lib/services/dialog.service';
import { SharedConstant } from 'libs/common/src/lib/constants/shared-constant';
import { UsersService } from '../../services/users.service';
import { CommonAdminShardConstants } from '../../constants/shared-constant';
/**
 * Component which is used for select store
 */
@Component({
  selector: 'phase-ii-common-store-select',
  templateUrl: './common-store-select.component.html',
  styleUrls: ['./common-store-select.component.scss']
})
export class CommonStoreSelectComponent extends SharedConstant implements OnInit, OnDestroy {
  /**
   * Variable which is used to get constant values of shared module
   * @type {object}
   */
  commonContants = new CommonAdminShardConstants();
  /**
   * Variable which is used to store the store name details
   */

  storeName: UntypedFormControl = new UntypedFormControl(null);
  /**
   * Object which is used to store the subscription data.
   */
  subscriptionObj: Subscription = new Subscription();
  /**
   * Variable is used to emit selected store id
   */
  @Output() storeInfo = new EventEmitter<any>();
    /**
  * Variable which is used to search store control.
  */
    storeSearchFilter = new UntypedFormControl(null);
  /**
   * Variable which is used to have all the needed variables.
   * @type {object}
   */
  storeDetails: {
    storeId: number,
    storesInfo: any,
    filteredStore:any
  } = {
      storeId: null,
      storesInfo: [],
      filteredStore:[]
    }
  /**
   * Component constructor to inject reqiured service.
   * @param route To get the user id from params
   * @param commonService To get all the store info
   * @param dialogService to display dialog box
   */
  constructor(
    private route: ActivatedRoute,
    private dialogService: DialogService,
    private userService:UsersService
  ) {
    super()
  }
  /**
   * Component OnInit life cycle hook
   */
  ngOnInit(): void {
    this.storeName.valueChanges.subscribe(val => {
      this.storeInfo.emit(val);
    })
    this.subscriptionObj.add(this.userService.getAllStoresName().pipe(filter((res: any) => {
      if (res && res.stores) {
        this.storeDetails.storesInfo = res.stores;
        this.storeDetails.filteredStore= res.stores;

      }
      return this.storeDetails.storesInfo.length;
    }), mergeMap(() => {
      return this.route.params;
    })).subscribe((params) => {
      if (params && params.storeId) {
        // console.log("params.storeId", params.storeId);
        const storeName = this.storeDetails.storesInfo.find(item => item && item.id && (+item.id === +params.storeId))
        this.storeName.setValue(storeName);
      }
    }, err => {
      this.dialogService.dialogMethod(this.commonContants.dialogMessages.failedToLoad, this.dialogType.failure, true);
    }));
  }
    /**
* Method which is used to search stores data.
* @param value To get store's search value.  
*/
onStoreSearchFilter(value?: string): void {
  if (value &&value.trim() &&value.trim().length) {
   
    const filterValue = value.trim().toLowerCase();
    
    const filteredArray = this.storeDetails.storesInfo.filter(storeValue => storeValue.name.toLowerCase().includes(filterValue));
  
    this.storeDetails.filteredStore = filteredArray;

    
  }
  else {
    this.storeDetails.filteredStore = [];
    this.storeDetails.filteredStore = this.storeDetails.storesInfo;
  }
}
    /**
 * Method which is used to close stores filter.
 */
    onStoreFilterClose(): void {
      this.storeSearchFilter.setValue(null);
      // this.onSelectValue();
      // this.setTagValue();
      this.storeDetails.filteredStore = [];
      this.storeDetails.filteredStore = this.storeDetails.storesInfo ;
    }
  /**
   * A lifecycle hook to unsubscribe all details
   */
  ngOnDestroy() {
    if (this.subscriptionObj) {
      this.subscriptionObj.unsubscribe();
    }
  }
}
