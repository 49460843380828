import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '@phase-ii/shared';
import { CommonAdminModuleModule } from '@phase-ii/common-admin-module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AdminProfileComponent } from './components/admin-profile/admin-profile.component';
import { AdminDashboardComponent } from './components/admin-dashboard/admin-dashboard.component';
import { TermsAndServiceComponent } from './components/terms-and-service/terms-and-service.component';
import { AffiliateMarketingSigninComponent } from './components/affiliate-marketing-signin/affiliate-marketing-signin.component';

@NgModule({
  declarations: [
    AdminProfileComponent,
    AdminDashboardComponent,
    AffiliateMarketingSigninComponent,
    TermsAndServiceComponent,
  ],
  imports: [CommonModule, BrowserAnimationsModule, SharedModule , CommonAdminModuleModule],
})
export class AuthModule { }
