export const payoutRequestConstant = {
  HEADING : {
    TITLE: 'Payout Requests List',
    DESCRIPTION: ''
  },
  /**
   * VARIABLE WHICH IS USED TO STORE DIALOG TYPE.
   */
  DIALOG_TYPE :{ ALERT: 'alert', CONFIRMATION: 'confirmation', INFORMATION: 'information', SUCCESS: 'success', FAILURE: 'failure', WARNING_HEADER: 'Warning' },
  DIALOG_HEADER :{
    FAILURE: 'failure',
    CONFIRMATION: 'Confirmation',
    STORE_GETS_FAILED: "Failed to get store names",
    SUCCESS: 'Success'
  },
  /**
   * VARIABLE WHICH IS USED FOR PAGINATOR
   * @TYPE {OBJECT}
   */
  PAGINATOR :{
    PAGE_LENGTH_OPTIONS: [5, 10, 20, 30, 40],
    DEFAULT_PAGE_SIZE: 10
  },
  PAYOUT_REQUEST_COLUMNS: [
    { column: 'affiliateUser.name', suffixText: 'User Name', type: 'Object' },
    { column: 'totalAmount', suffixText: 'Amount', type: 'Text' },
    { column: 'createdAt', suffixText: 'Requested On', type: 'DateTime' },
    { column: 'status', suffixText: 'Status', type: 'Chip', sort: false, classCondition: { "Yet to Begin": "orangeChip", "Payment Initiated": "purpleChip", 'Payment Completed': "greenChip" } }
  ],
  DIALOG_MESSAGES :{
    NO_CHANGE_ALERT: "You have no changes to save",
    UNSAVED_DIALOG_CLOSE: 'Are you sure want to close this dialog?',
    UPDATE_SUCCESS: "Status updated successfully",
    FAILED: "Failed to get payout request",
    PAYMENT_STATUS_ERROR: 'Failed to get payment status details',
    ACCOUNT_FAILED: "Failed to get account details",
    MANDATORY_FIELDS: "Please fill out the mandatory fields.",
    NO_ACCOUNT: "There is no Account details for this user",
    UPDATE_FAILED: "Failed to update payout request status"
  },
  ERROR_MESSAGES :{
    TRANSACTION_ID_REQ: "Transaction Id is required",
    TRANSACTION_ID_MAX: "Transaction Id exceeds maximum length",
    TRANSACTION_ID_MIN: "Transaction Id should have at least 10 characters in length",
    DESCRIPTION_MAX: "Description exceeds maximum length"
  }
}

