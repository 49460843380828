<div data-control="commonCard">
    <div fxLayout="row" fxLayout.xs="column">
        <div fxLayout="column" fxFlex="30">
            <div *ngIf="(!hideSearch && listProperties?.globalSearch) || commonSevice?.searchField?.value?.length > 0"
                class="searchBar">
                <!-- <mat-form-field appearance="outline"> -->
                <mat-form-field>
                    <mat-label>{{'COMMON.SEARCH'| translate }}</mat-label>
                    <input [formControl]="commonSevice.searchField" matInput type="text" (input)="searchKey($event)"
                        data-control="search"
                        [placeholder]="listProperties.searchPlaceHolder? listProperties.searchPlaceHolder :  ''" />
                    <mat-icon matSuffix class="mat-icon-color" data-control="searchIcon">search</mat-icon>
                </mat-form-field>
            </div>
        </div>
        <div fxFlex="70" fxLayout="row wrap" fxLayoutAlign="center end" fxLayoutAlign.gt-xs="end end">
            <!-- right side location select section -->
            <div *ngIf="rightTopFilter && rightTopFilter.values && rightTopFilter.values.length" fxFlexOffset="10px">
                <mat-form-field>
                    <mat-label>{{rightTopFilter?.label ? rightTopFilter?.label : 'COMMON.RIGHT_TOP_FILTER_LBL'|
                        translate }}</mat-label>
                    <mat-select (selectionChange)="rightFilterOptionChanges($event)"
                        (openedChange)="rightTopFilterSearchFieldChange($event)" [value]="rightTopFilter?.defaultOption"
                        data-control="rightTopFilter">
                        <div fxLayout="row" fxLayoutAlign="center center" class="search">
                            <input matInput fxFlex="80%" fxFlex.lt-md="80%" fxFlex.lt-sm="80%"
                                data-control="searchTopFilterSearchField"
                                placeholder="{{'COMMON.SEARCH'| translate }}"
                                [formControl]="rightTopFilterSearchField"
                                (keyup)="rightTopFilterSearch($event.target.value)"
                                (keydown.space)="$event.stopPropagation();">
                            <mat-icon *ngIf="rightTopFilterSearchField?.value" class="icon mat-icon-color"
                                fxLayoutAlign="center center" fxFlex="20%" fxFlex.lt-md="20%" fxFlex.lt-sm="20%"
                                (click)="rightTopFilterClose()" data-control="closeIconSearchTopFilterSearchField">
                                close
                            </mat-icon>
                        </div>
                        <mat-option *ngIf="rightTopFilterFilteredData?.length === 0" [disabled]="true"
                            data-control="noSearchDataFound">
                            {{'COMMON.RIGHT_TOP_FILTER_DATA_FOUND'| translate }}
                        </mat-option>
                        <mat-option *ngFor="let value of rightTopFilterFilteredData; let id = index"
                            [value]="value[rightTopFilter?.matValueField]"
                            [attr.data-control]="('option ' + id) | dataControl">
                            {{value[rightTopFilter?.matLabelField]}}
                        </mat-option>
                    </mat-select>

                </mat-form-field>
            </div>
            <!-- Language dropdown selector -->
            <div *ngIf="rightTopLanguageFilter && rightTopLanguageFilter.values && rightTopLanguageFilter.values.length"
                fxFlexOffset="10px">
                <mat-form-field>
                    <mat-label> {{rightTopLanguageFilter?.label ? rightTopLanguageFilter?.label : 'Select Option'}}
                    </mat-label>
                    <mat-select (selectionChange)="rightTopLanguageFilterOptionChanges($event)"
                        [value]="rightTopLanguageFilter?.defaultOption" data-control="rightTopLanguageFilter">
                        <!-- <div fxLayout="row" fxLayoutAlign="center center" class="search">
                            <input matInput fxFlex="85%" fxFlex.lt-md="85%" fxFlex.lt-sm="85%"
                                data-control="searchTopLanguageFilterSearchField" placeholder="Search "
                                [formControl]="rightTopLanguageFilterSearchField"
                                (keyup)="rightTopLanguageFilterSearch($event.target.value)"
                                (keydown.space)="$event.stopPropagation();">
                            <mat-icon class="search-close-icon icon" *ngIf="rightTopLanguageFilterSearchField?.value"
                                fxFlex="15%" fxFlex.lt-md="15%" fxFlex.lt-sm="15%"
                                (click)="rightTopLanguageFilterClose()"
                                data-control="closeIconsearchTopLanguageFilterSearchField">
                                close
                            </mat-icon>
                        </div> -->
                        <!-- <mat-option *ngIf="rightTopFilterLanguageData?.length == 0" [disabled]="true"
                            data-control="searchTopLanguageFilterNoData">
                            No Search Data Found
                        </mat-option> -->
                        <mat-option *ngFor="let value of rightTopFilterLanguageData;let id = index"
                            [value]="value[rightTopLanguageFilter?.matValueField]"
                            [attr.data-control]="('option ' + id) | dataControl">
                            {{value[rightTopLanguageFilter?.matLabelField]}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <!-- right side date range filter -->
            <div *ngIf=" isTopRightDateFilter" fxFlexOffset="10px">
                <mat-form-field>
                    <mat-label>{{'COMMON.RIGHT_TOP_DATE_FILTER'| translate}}</mat-label>
                    <mat-date-range-input [rangePicker]="picker" [formGroup]="dateFilterForm"
                        [min]="isTopRightDateFilter?.minDate ? isTopRightDateFilter.minDate : null"
                        [max]="isTopRightDateFilter?.maxDate ? isTopRightDateFilter.maxDate : null">
                        <input readonly matStartDate formControlName="startDate"
                            placeholder="{{'COMMON.RIGHT_TOP_DATE_FILTER_START'| translate }}"
                            attr.data-control="dateFilterBegin">
                        <input readonly matEndDate formControlName="endDate"
                            placeholder="{{'COMMON.RIGHT_TOP_DATE_FILTER_END'| translate }}"
                            (dateChange)="onDateFilterChange()" attr.data-control="dateFilterEnd">
                    </mat-date-range-input>
                    <mat-datepicker-toggle matSuffix [for]="picker"
                        data-control="commonDatePickerToggle"></mat-datepicker-toggle>
                    <mat-date-range-picker #picker></mat-date-range-picker>
                    <mat-error *ngIf="dateFilterForm?.get('startDate')?.hasError('required') || 
                                      dateFilterForm?.get('endDate')?.hasError('required')"
                        data-control="topRightDateFilterError">
                        {{'COMMON.RIGHT_TOP_DATE_FILTER_REQ_MSG'| translate }}
                    </mat-error>
                </mat-form-field>
            </div>
            <div *ngIf="actions||rightTopSort && rightTopSort.values && rightTopSort.values.length" fxFlexOffset="10px"
                fxLayout="row">
                <div *ngIf="actions">
                    <!-- <ng-container *ngIf=" actions"> -->
                    <ng-container *ngFor="let icon of actions">
                        <em *ngIf="icon.name && !icon.buttonText " class="material-icons-outlined rightTopIcons"
                            (click)="iconEvent(icon?.value,icon)" (mouseover)="headerIconFocused(icon)"
                            [ngClass]="actionConditionCheck(icon.isConditionApplied, icon.conditionType)? 'no-events': 'restore-events' "
                            [matTooltip]="icon.toolTip"
                            [ngStyle]="icon.iconColor ?  {'color':icon.iconColor}: {'color': 'black'}"
                            [attr.data-control]="(icon?.value) | dataControl">{{icon?.name}}</em>
                        <button *ngIf="icon.buttonText"
                            [disabled]="actionConditionCheck(icon.isConditionApplied, icon.conditionType)"
                            mat-stroked-button
                            [ngClass]="[icon.buttonType ? icon.buttonType: 'primary-button', actionConditionCheck(icon.isConditionApplied)? 'disabled-button':'' ]"
                            (click)="iconEvent(icon?.value,icon)" class="topRightButtons"
                            [attr.data-control]="(icon?.value) | dataControl">{{icon?.buttonText}}</button>
                    </ng-container>
                    <!-- </ng-container> -->
                </div>
                <!-- sorting option icon section -->
                <div *ngIf="rightTopSort && rightTopSort.values && rightTopSort.values.length">
                    <em class="material-icons-outlined rightTopIcons"
                        [matTooltip]="rightTopSort.toolTip ? rightTopSort.toolTip : 'COMMON.SORT_ICON'| translate "
                        [matMenuTriggerFor]="icon_sub_menu"
                        [ngClass]="displayData?.length == 0 ? 'no-events': 'restore-events'"
                        data-control="rightTopSort">import_export</em>
                    <mat-menu #icon_sub_menu="matMenu">
                        <div class="menu-name" *ngFor="let value of rightTopSort.values;let id = index"
                            (click)="sortClick(value)" [ngClass]="{'selected': selectedSort?.label == value.label }"
                            [attr.data-control]="('sort '+ id) | dataControl">
                            {{value.label}}
                        </div>
                    </mat-menu>
                </div>
            </div>
        </div>
        <!-- <div fxLayout="column" fxFlex="55">
        <div *ngIf="actions" fxFlexOffset="4" fxLayoutAlign.gt-xs="end end">
            <ng-container *ngIf="!hideSearch">
                <ng-container *ngFor="let icon of actions">
                    <mat-icon class="icons" [attr.data-control]="icon?.value" (mouseover)="headerIconFocused(icon)" [ngClass]="{'iconColorChange':(icon?.value==='deselectAll'&&!MultipleSelect?.length)}" (click)="iconEvent(icon?.value,icon)" [matTooltip]="icon?.toolTip">{{icon?.name}}</mat-icon>
                </ng-container>
            </ng-container>
        </div>
    </div> -->
    </div>
    <ng-container *ngIf="displayData?.length === 0 ">
        <div fxLayout="column" *ngIf="!listProperties?.isNewImage || !listProperties?.isNewImage?.isNew"
            fxLayoutAlign="center center">
            <br>
            <!-- <img src="./assets/no-records-found.png" class="empty-img" alt="no records found" /> -->
            <img [src]="listProperties?.noRecordFound?.image ? listProperties?.noRecordFound?.image : './assets/no-records-found.png'"
                class="empty-img" alt="no records found" data-control="noRecordFoundImg" />

            <div *ngIf="!errorHandler" fxLayout="row" fxLayoutAlign="center center" class="empty-data-css"
                data-control="noRecordFound">
                {{ listProperties?.noRecordFound?.text ? listProperties?.noRecordFound?.text:
                'COMMON.NO_RECORD_MSG'|
                translate}}
            </div>
        </div>
        <div *ngIf="errorHandler" fxLayout="row" fxLayoutAlign="center center" class="empty-data-css"
            data-control="somethingWentWrong">
            {{'COMMON.ERROR_HANDLER_MSG'| translate }}
        </div>
        <div *ngIf="listProperties?.isNewImage && listProperties?.isNewImage?.isNew" fxLayout="column"
            fxLayoutAlign="center center">
            <br>
            <!-- <img src="./assets/no-records-found.png" class="empty-img" alt="no records found" /> -->
            <img [src]="listProperties?.isNewImage?.url ? listProperties?.isNewImage?.url: './assets/no-records-found.png'"
                class="new-img" alt="no records found" data-control="newEmptyImg" />
            <br>
            <div class="empty-data-css" data-control="newEmptyMsg">
                {{listProperties?.isNewImage?.text ? listProperties?.isNewImage?.text: 'COMMON.NO_RECORD_MSG'|
                translate}}
            </div>
        </div>
    </ng-container>
    <div class="loader-height" *ngIf="!displayData && displayLoader">
        <cen-loader type="content" name="cen-cube-loader" data-control="loader"></cen-loader>
    </div>
    <div>
        <div *ngIf="columnsRef">
            <div class="tableHeaderCard mat-elevation-z2" *ngIf="displayData && displayData?.length > 0 " fxLayout="row"
                fxLayoutAlign="space-evenly center" fxFlex="100" fxLayout.lt-sm="column" fxLayoutGap="0px"
                fxLayoutGap.lt-sm="10px"
                [ngStyle]="(listProperties && listProperties.cardHeaderFontColor) ?  {'color':listProperties?.cardHeaderFontColor}: {'color': 'var(--primarynormal)'}"
                data-control="cardHeader">
                <div *ngIf="listProperties?.radioButton" fxLayoutAlign="center center" fxFlex="5" fxFlex.lt-md="5"
                    fxLayout.lt-sm="column" data-control="cardHeaderMasterRadioBtn">
                </div>
                <div *ngIf="listProperties?.checkBox" fxLayoutAlign="center center" fxFlex="5" fxFlex.lt-md="5"
                    fxLayout.lt-sm="column" class="card-check-box" data-control="cardHeaderMasterCheckBox">
                    <mat-checkbox *ngIf="!isMasterToggleDisable" (change)="masterToggle()" [checked]="isAllSelected()"
                        [aria-label]="checkboxLabel()" [ngClass]="clickable ? 'no-events': 'restore-events'"
                        data-control="masterCheckBox">
                    </mat-checkbox>
                </div>
                <div *ngFor="let column of columnsRef" [ngClass]="isLeftAlign? 'leftAlign' : ''" class="cardHeader"
                    [fxFlex]="column.fxFlex ? column.fxFlex : fxFlex"
                    [ngStyle]="{'min-width.%': (column && column.fxFlex) ?  column.fxFlex : 0}"
                    data-control="cardHeaderLbl">
                    <ng-container *ngIf="column?.suffixText">
                        {{column?.suffixText}}
                    </ng-container>
                </div>
                <div *ngIf="((actionsIcon && actionsIcon.length>0) || (isRowActionIcons) || (moreActions && moreActions.length>0))"
                    fxLayoutAlign="center center" class="cardHeader" fxFlex="15" fxFlex.lt-sm="15"
                    data-control="cardHeaderActions">
                    {{'COMMON.ACTION'| translate}}
                </div>
            </div>
        </div>
        <ng-container *ngIf="displayData && displayData?.length > 0 && columnsRef">
            <div *ngFor="let data of displayData;index as i">
                <!-- <div fxFlex="100"> -->
                <div fxLayout="column" class="card mat-elevation-z4" fxFlex="100"
                    [ngClass]="[listProperties.cardBorderCondition ? getClassForBorderCard(data, listProperties.cardBorderClass) : listProperties.cardBorderClass? listProperties.cardBorderClass : 'defaultBorder' ]"
                    [ngStyle]="listProperties.cardMinHeight? {'min-height': listProperties.cardMinHeight } : {}">

                    <!-- <div class="ribbon1 ribbon-top-left1"><span	 style="color:#f8f7f7">Ribbon</span></div> -->

                    <div fxLayout="row" fxLayoutAlign="space-evenly center" fxLayout.lt-sm="column"
                        fxLayoutGap.lt-sm="12px" fxLayoutGap="0px">
                        <div *ngIf="listProperties?.checkBox" fxLayoutAlign="center center" fxFlex="5" fxFlex.lt-md="5"
                            class="card-check-box">
                            <!-- <mat-checkbox class="example-margin" (change)="singleCheckBoxClick(data, $event);" [attr.data-control]="'checkbox'+i"></mat-checkbox> -->
                            <ng-container *ngIf="!data?.isHideCheckBox">
                                <mat-checkbox (click)="$event.stopPropagation()"
                                    (change)="singleCheckBoxClick($event, data)" [checked]="data.checked"
                                    [aria-label]="checkboxLabel(data)"
                                    [ngClass]="clickable ? 'no-events': 'restore-events'"
                                    [attr.data-control]="'checkBox ' + i">
                                </mat-checkbox>
                            </ng-container>
                        </div>
                        <div *ngIf="listProperties?.radioButton" fxLayoutAlign="center center" fxFlex="5"
                            fxFlex.lt-md="10" fxLayout.lt-sm="column">
                            <!-- <mat-radio-group [(ngModel)]="singleItemSelect"> -->
                            <mat-radio-button [checked]="singleItemSelect?.id === data.id"
                                (click)="radioButtonSelect(data)" [attr.data-control]="'radioSelect ' + i">
                            </mat-radio-button>
                            <!-- </mat-radio-group> -->
                        </div>

                        <div *ngFor="let column of columnsRef" [ngClass]="isLeftAlign? 'leftAlign' : 'default'"
                            [fxFlex]="column.fxFlex ? column.fxFlex : fxFlex" (click)="rowClick(data)"
                            [ngStyle]="listProperties?.rowElementClicked ? {'cursor': 'pointer'} :  {'cursor': 'default'}"
                            [ngStyle]="{'min-width.%': (column && column.fxFlex) ?  column.fxFlex : 0}">

                            <ng-container
                                *ngTemplateOutlet="displayTemplate;context:{data: data, column: column, index:i}">
                            </ng-container>
                            <div *ngIf="column?.subText" class="subText">
                                <ng-container
                                    *ngTemplateOutlet="displayTemplate;context:{data: data, column: column.subText, index:i}">
                                </ng-container>
                                <div *ngIf="column?.subText?.subText" class="subText">
                                    <ng-container
                                        *ngTemplateOutlet="displayTemplate;context:{data: data, column: column.subText.subText,  index:i}">
                                    </ng-container>
                                </div>
                            </div>

                        </div>

                        <div fxLayoutAlign="center center" fxFlex="15" fxFlex.lt-sm="15"
                            *ngIf="!actionsIcon && data && data?.rowActionsIcon && data?.rowActionsIcon?.length>0">
                            <ng-container *ngFor="let icon of data?.rowActionsIcon">
                                <ng-container *ngIf="icon?.iconName; else noIcon">
                                    <mat-icon class="icon"
                                        [ngStyle]="icon.iconColor ? {'color': icon?.iconColor} : {'color': 'black'}"
                                        (click)="actionClick($event, icon, data);" [matTooltip]="icon?.toolTip"
                                        [attr.data-control]="(icon?.iconName + ' ' + i) | dataControl">
                                        {{ icon?.iconName }}
                                    </mat-icon>
                                </ng-container>
                                <ng-template #noIcon>
                                    <span class="rowContent textWrapElipsis" data-control="iconNotAvailable"> N/A
                                    </span>
                                </ng-template>
                            </ng-container>
                            <ng-container
                                *ngIf="customMatMenu && listProperties && listProperties.customMatMenuIsDisplay">
                                <em class="material-icons-outlined icon moreIcon" [matMenuTriggerFor]="customMatMenu"
                                    (click)="actionClick($event, {iconName: 'customMatMenu'}, data)"
                                    matTooltip="{{'COMMON.TOOL_TIP_SHARE'| translate }}"
                                    data-control="moreActions">
                                    {{ listProperties && listProperties.customMatMenuIcon ?
                                    listProperties.customMatMenuIcon : 'more_vert' }}
                                </em>
                            </ng-container>
                            <ng-container *ngIf="moreActions && moreActions.length > 0">
                                <em class="material-icons-outlined icon moreIcon"
                                    [matMenuTriggerFor]="listProperties && listProperties.customMatMenuTrigger ? listProperties.customMatMenuTrigger : more_actions_menu"
                                    matTooltip="{{'COMMON.TOOL_TIP_MORE_ACTION'| translate }}"
                                    data-control="moreActions">
                                    <!-- more_vert -->
                                    <!-- share -->
                                    {{ listProperties && listProperties.customMoreActionIcon ?
                                    listProperties.customMoreActionIcon : 'more_vert' }}
                                </em>
                                <mat-menu #more_actions_menu="matMenu">
                                    <div *ngFor="let value of moreActions; let id = index" class="menu-name"
                                        (click)="moreActionClick(value, data)"
                                        [attr.data-control]="(value.displayName ? 'moreActions ' + value.displayName : 'moreActions ' + i) | dataControl">
                                        {{ value.displayName }}
                                    </div>
                                </mat-menu>
                            </ng-container>
                            <!-- <ng-container *ngIf="customMatMenu">
                                <em class="material-icons-outlined icon moreIcon" matTooltip="More Actions"
                                data-control="more_actions" [matMenuTriggerFor]="customMatMenu" (click)="actionClick($event, {iconName:'customMatMenu'} ,data)" >
                                {{ listProperties && listProperties.customMatMenuIcon ? listProperties.customMatMenuIcon: 'more_vert' }}
                                </em>
                            </ng-container> -->
                            <ng-container
                                *ngIf="data && data?.rowMoreActionsIcon && data?.rowMoreActionsIcon?.length>0">
                                <em [matMenuTriggerFor]="listProperties && listProperties.customMatMenuTrigger ? listProperties.customMatMenuTrigger:  more_actions_menu"
                                    class="material-icons-outlined icon moreIcon"
                                    matTooltip="{{'COMMON.TOOL_TIP_MORE_ACTION'| translate }}"
                                    data-control="moreActions">
                                    <!-- more_vert -->
                                    <!-- share     -->
                                    {{ listProperties && listProperties.customMoreActionIcon ?
                                    listProperties.customMoreActionIcon: 'more_vert' }}
                                </em>
                                <mat-menu #more_actions_menu="matMenu">
                                    <div *ngFor="let value of data?.rowMoreActionsIcon; let id = index"
                                        class="menu-name" (click)="moreActionClick(value, data)"
                                        [attr.data-control]="(value.displayName?'moreActions '+ value.displayName:'moreActions '+i) |dataControl">
                                        {{value.displayName}}
                                    </div>
                                </mat-menu>
                            </ng-container>

                        </div>

                        <div fxLayoutAlign="center center" fxFlex="15" fxFlex.lt-sm="15"
                            *ngIf="(actionsIcon && actionsIcon.length>0) || (moreActions && moreActions.length>0 && !data?.rowActionsIcon)">
                            <ng-container *ngIf="actionsIcon && actionsIcon.length>0">
                                <ng-container *ngFor="let icon of actionsIcon">
                                    <mat-icon class="icon"
                                        [ngStyle]="icon.iconColor ?  {'color':icon?.iconColor}: {'color': 'black'}"
                                        (click)="actionClick($event,icon,data);" [matTooltip]="icon.toolTip"
                                        [attr.data-control]="(icon?.iconName + ' ' + i) | dataControl">
                                        {{icon?.iconName}}</mat-icon>
                                </ng-container>
                            </ng-container>
                            <ng-container
                                *ngIf="customMatMenu && listProperties && listProperties.customMatMenuIsDisplay">
                                <em class="material-icons-outlined icon moreIcon" [matMenuTriggerFor]="customMatMenu"
                                    (click)="actionClick($event,  {iconName:'customMatMenu'} ,data)"
                                    matTooltip="{{'COMMON.TOOL_TIP_SHARE'| translate }}"
                                    data-control="moreActions">
                                    {{ listProperties && listProperties.customMatMenuIcon ?
                                    listProperties.customMatMenuIcon: 'more_vert' }}
                                </em>
                            </ng-container>
                            <ng-container *ngIf="moreActions && moreActions.length>0 && !data?.rowActionsIcon">
                                <em class="material-icons-outlined icon moreIcon"
                                    [matMenuTriggerFor]="listProperties && listProperties.customMatMenuTrigger ? listProperties.customMatMenuTrigger:  more_actions_menu"
                                    matTooltip="{{'COMMON.TOOL_TIP_MORE_ACTION'| translate }}"
                                    data-control="moreActions">
                                    <!-- more_vert -->
                                    <!-- share -->
                                    {{ listProperties && listProperties.customMoreActionIcon ?
                                    listProperties.customMoreActionIcon: 'more_vert' }}
                                </em>
                                <mat-menu #more_actions_menu="matMenu">
                                    <div *ngFor="let value of moreActions; let id = index" class="menu-name"
                                        (click)="moreActionClick(value, data)"
                                        [attr.data-control]="(value.displayName?'moreActions '+value.displayName:'moreActions '+i) | dataControl">
                                        {{value.displayName}}
                                    </div>
                                </mat-menu>
                            </ng-container>
                            <ng-container
                                *ngIf="data && data?.rowMoreActionsIcon && data?.rowMoreActionsIcon?.length>0">
                                <em class="material-icons-outlined icon moreIcon"
                                    [matMenuTriggerFor]="listProperties && listProperties.customMatMenuTrigger ? listProperties.customMatMenuTrigger:  more_actions_menu"
                                    matTooltip="{{'COMMON.TOOL_TIP_MORE_ACTION'| translate }}" data-control="moreActions">
                                    <!-- more_vert -->
                                    <!-- share -->
                                    {{ listProperties && listProperties.customMoreActionIcon ?
                                    listProperties.customMoreActionIcon: 'more_vert' }}

                                </em>
                                <mat-menu #more_actions_menu="matMenu">
                                    <div *ngFor="let value of data?.rowMoreActionsIcon; let id = index"
                                        class="menu-name" (click)="moreActionClick(value, data)"
                                        [attr.data-control]="(value.displayName ? 'moreActions ' + value.displayName + ' ' +id:'moreActions  ' + id) | dataControl">
                                        {{value.displayName}} {{id}}
                                    </div>
                                </mat-menu>
                            </ng-container>

                        </div>

                    </div>
                    <!-- </div>  -->
                    <span *ngIf="listProperties.badgeCondition && isBadgeDisplay(data)"
                        class="badgeClass mat-elevation-z4">
                        {{ isBadgeDisplay(data) }}
                    </span>

                </div>
            </div>
        </ng-container>

    </div>


</div>


<ng-template #displayTemplate let-column="column" let-data="data" let-i="index">
    <!-- {{column |json}} -->
    <!-- <ng-container *ngIf="column?.icon || column?.iconCondition" >
        <mat-icon>person</mat-icon>
    </ng-container> -->
    <!-- <i *ngIf="column?.icon || column?.iconCondition"  class="material-icons-outlined" matTooltip=" Delivery Type ">person</i> &nbsp;&nbsp; -->

    <div *ngIf="column?.type ==='Image'" class="rowDiv">
        <!-- <img [src]="data[column?.column] " class="img " alt="row image" onerror="this.src='./assets/no-image.png';"
            [ngClass]="{ 'imgCircle': column?.style=='circle' , 'imgSquare': column?.style=='square' } "> -->
        <img [actualImageURL]="data[column?.column]" class="img " alt="row image"
            [imageWidth]="column?.imageSize=='large'?600:column?.imageSize=='medium'?300:column?.imageSize=='small'?100:null"
            [onErrorEvent]="{src:'./assets/no-image.png'}" [imageExtension]="imageExtension?.AVIF_FORMAT"
            [ngClass]="{ 'imgCircle': column?.style=='circle' , 'imgSquare': column?.style=='square' } ">
    </div>

    <div *ngIf="listProperties?.profileImage && column?.type ==='ProfileImage'" class="element"
        [ngClass]="[column?.class? column?.class: 'default', column?.classCondition ? getClassForColumn(column?.classCondition, column?.alternativeField ? data[column?.alternativeField] : data[column?.column]): '', isLeftAlign? '' : 'alignCenter']">
        <span class="profile-img">{{data[column?.column] | uppercase | slice:0:2}}</span>
    </div>

    <div *ngIf="column?.type ==='Text'" class="element"
        [ngClass]="[column?.class? column?.class: 'default', column?.classCondition ? getClassForColumn(column?.classCondition, column?.alternativeField ? data[column?.alternativeField] : data[column?.column]): '', isLeftAlign? '' : 'alignCenter']">
        <em fxFlex="5%" fxFlex.lt-md="7%" *ngIf="column?.icon || column?.iconCondition"
            class="material-icons-outlined rowIcon"
            [matTooltip]="column?.toolTipCondition ? getToolTipForColumn(column?.toolTipCondition, data[column?.column], column?.iconToolTip): column?.iconToolTip"
            [ngStyle]="column.iconColor ?  {'color':column?.iconColor}: {'color': 'black'}">{{column?.iconCondition ?
            getIconForColumn(column?.iconCondition, data[column?.column], column?.icon): column?.icon}}</em>
        <span class="rowContent textWrapElipsis" [matTooltip]="data[column?.column]"
            [ngStyle]=" (column && column.isColumnClick) ? {'cursor': 'pointer'} :  {'cursor': 'default'}"
            [matTooltipDisabled]="data[column?.column] && (data[column?.column]).length <= 30"
            (click)="onColumnClick(column, data)">{{data[column?.column] }}</span>
    </div>
    <div *ngIf="column?.type ==='Amount'" class="element"
        [ngClass]="[column?.class? column?.class: 'default', column?.classCondition ? getClassForColumn(column?.classCondition, column?.alternativeField ? data[column?.alternativeField] : data[column?.column]): '', isLeftAlign? '' : 'alignCenter']">
        <em fxFlex="5%" fxFlex.lt-md="7%" *ngIf="column?.icon || column?.iconCondition"
            class="material-icons-outlined rowIcon"
            [matTooltip]="column?.toolTipCondition ? getToolTipForColumn(column?.toolTipCondition, data[column?.column], column?.iconToolTip): column?.iconToolTip"
            [ngStyle]="column.iconColor ?  {'color':column?.iconColor}: {'color': 'black'}">{{column?.iconCondition ?
            getIconForColumn(column?.iconCondition, data[column?.column], column?.icon): column?.icon}}</em>
        <span class="rowContent">
            <!-- {{column?.currency ? column?.currency : '$'}} -->
            <ng-container *ngIf="data[column?.column]"> {{data[column?.column] | currency: column?.currency }}
            </ng-container>
            <ng-container
                *ngIf="data[column?.column] == null || data[column?.column] === 0 || data[column?.column] === 0.00 ">
                {{'0.00 ' | currency: column?.currency }}</ng-container>
        </span>

    </div>
    <div *ngIf="column?.type ==='Date'" class="element"
        [ngClass]="[column?.class? column?.class: 'default', column?.classCondition ? getClassForColumn(column?.classCondition, column?.alternativeField ? data[column?.alternativeField] : data[column?.column]): '', isLeftAlign? '' : 'alignCenter']">
        <em fxFlex="5%" fxFlex.lt-md="7%" *ngIf="column?.icon || column?.iconCondition"
            class="material-icons-outlined rowIcon"
            [matTooltip]="column?.toolTipCondition ? getToolTipForColumn(column?.toolTipCondition, data[column?.column], column?.iconToolTip): column?.iconToolTip"
            [ngStyle]="column.iconColor ?  {'color':column?.iconColor}: {'color': 'black'}">{{column?.iconCondition ?
            getIconForColumn(column?.iconCondition, data[column?.column], column?.icon): column?.icon}}</em>
        <span class="rowContent">{{data[column?.column] | date: (column?.format? column?.format: 'dd/MM/yyyy' ) }}
        </span>
    </div>
    <div *ngIf="column?.type ==='Time'" class="element"
        [ngClass]="[column?.class? column?.class: 'default', column?.classCondition ? getClassForColumn(column?.classCondition, column?.alternativeField ? data[column?.alternativeField] : data[column?.column]): '', isLeftAlign? '' : 'alignCenter']">
        <em fxFlex="5%" fxFlex.lt-md="7%" *ngIf="column?.icon || column?.iconCondition"
            class="material-icons-outlined rowIcon"
            [matTooltip]="column?.toolTipCondition ? getToolTipForColumn(column?.toolTipCondition, data[column?.column], column?.iconToolTip): column?.iconToolTip"
            [ngStyle]="column.iconColor ?  {'color':column?.iconColor}: {'color': 'black'}">{{column?.iconCondition ?
            getIconForColumn(column?.iconCondition, data[column?.column], column?.icon): column?.icon}}</em>
        <span class="rowContent"> {{data[column?.column] | date: (column?.format? column?.format: 'hh:mm:ss a' )
            }}</span>
    </div>
    <div *ngIf="column?.type ==='DateTime'" class="element"
        [ngClass]="[column?.class? column?.class: 'default', column?.classCondition ? getClassForColumn(column?.classCondition, column?.alternativeField ? data[column?.alternativeField] : data[column?.column]): '', isLeftAlign? '' : 'alignCenter']">
        <em fxFlex="5%" fxFlex.lt-md="7%" *ngIf="column?.icon || column?.iconCondition"
            class="material-icons-outlined rowIcon"
            [matTooltip]="column?.toolTipCondition ? getToolTipForColumn(column?.toolTipCondition, data[column?.column], column?.iconToolTip): column?.iconToolTip"
            [ngStyle]="column.iconColor ?  {'color':column?.iconColor}: {'color': 'black'}">{{column?.iconCondition ?
            getIconForColumn(column?.iconCondition, data[column?.column], column?.icon): column?.icon}}</em>
        <span class="rowContent">
            {{ data[column?.column] | date: (column?.format? column?.format: 'dd/MM/yyyy hh:mm:ss a') }}
        </span>
    </div>
    <div *ngIf="column?.type ==='Number'" class="element"
        [ngClass]="[column?.class? column?.class: 'default', column?.classCondition ? getClassForColumn(column?.classCondition, column?.alternativeField ? data[column?.alternativeField] : data[column?.column]): '', isLeftAlign? '' : 'alignCenter']">
        <ng-container *ngIf="!column?.isFloat ">
            <em fxFlex="5%" fxFlex.lt-md="7%" *ngIf="column?.icon || column?.iconCondition"
                class="material-icons-outlined rowIcon"
                [matTooltip]="column?.toolTipCondition ? getToolTipForColumn(column?.toolTipCondition, data[column?.column], column?.iconToolTip): column?.iconToolTip"
                [ngStyle]="column.iconColor ?  {'color':column?.iconColor}: {'color': 'black'}">{{column?.iconCondition
                ? getIconForColumn(column?.iconCondition, data[column?.column], column?.icon): column?.icon}}</em>
            <span class="rowContent"> {{data[column?.column] | number: '1.0-0'}}</span>
        </ng-container>
        <ng-container *ngIf="column?.isFloat ">
            <em fxFlex="5%" fxFlex.lt-md="7%" *ngIf="column?.icon || column?.iconCondition"
                class="material-icons-outlined rowIcon"
                [matTooltip]="column?.toolTipCondition ? getToolTipForColumn(column?.toolTipCondition, data[column?.column], column?.iconToolTip): column?.iconToolTip"
                [ngStyle]="column.iconColor ?  {'color':column?.iconColor}: {'color': 'black'}">{{column?.iconCondition
                ? getIconForColumn(column?.iconCondition, data[column?.column], column?.icon): column?.icon}}</em>
            <span class="rowContent">
                {{data[column?.column] | number: '1.2-2'}}</span>
        </ng-container>
    </div>
    <div *ngIf="column?.type ==='Link'" class="element"
        [ngClass]="[column?.class? column?.class: 'default', column?.classCondition ? getClassForColumn(column?.classCondition, column?.alternativeField ? data[column?.alternativeField] : data[column?.column]): '', isLeftAlign? '' : 'alignCenter']">
        <em fxFlex="5%" fxFlex.lt-md="7%" *ngIf="column?.icon || column?.iconCondition"
            class="material-icons-outlined rowIcon"
            [matTooltip]="column?.toolTipCondition ? getToolTipForColumn(column?.toolTipCondition, data[column?.column], column?.iconToolTip): column?.iconToolTip"
            [ngStyle]="column.iconColor ?  {'color':column?.iconColor}: {'color': 'black'}">{{column?.iconCondition ?
            getIconForColumn(column?.iconCondition, data[column?.column], column?.icon): column?.icon}}</em>
        <span class="rowContent">
            <a [href]="data[column?.column] " [target]="column?.newTab? '_blank': '' "> {{column?.linkText}}</a></span>
    </div>

    <div *ngIf="column?.type ==='InnerHtml'" class="element" style="height: 26px;"
        [ngClass]="[column?.class? column?.class: 'default', column?.classCondition ? getClassForColumn(column?.classCondition, column?.alternativeField ? data[column?.alternativeField] : data[column?.column]): '', isLeftAlign? '' : 'alignCenter']">
        <em fxFlex="5%" fxFlex.lt-md="7%" *ngIf="column?.icon || column?.iconCondition"
            class="material-icons-outlined rowIcon"
            [matTooltip]="column?.toolTipCondition ? getToolTipForColumn(column?.toolTipCondition, data[column?.column], column?.iconToolTip): column?.iconToolTip"
            [ngStyle]="column.iconColor ?  {'color':column?.iconColor}: {'color': 'black'}">{{column?.iconCondition ?
            getIconForColumn(column?.iconCondition, data[column?.column], column?.icon): column?.icon}}</em>
        <span class="rowContent textWrapElipsis">
            <span [innerHtml]="data[column?.column]">
            </span></span>
    </div>
    <div *ngIf="column?.type ==='Chip' && data[column?.column]" [ngClass]="[column?.class? column?.class: 'default']"
        class="chipDiv">

        <span class="chip"
            [ngStyle]="column?.backgroundColor ?  { 'background-color' :  column.backgroundColor } : { 'background-color' : '#4888a8'}"
            [ngClass]="column?.classCondition ? getClassForColumn(column?.classCondition, column?.alternativeField ? data[column?.alternativeField] : data[column?.column]): ''">
            <em fxFlex="5%" fxFlex.lt-md="7%" *ngIf="column?.icon || column?.iconCondition"
                class="material-icons-outlined rowIcon"
                [matTooltip]="column?.toolTipCondition ? getToolTipForColumn(column?.toolTipCondition, data[column?.column], column?.iconToolTip): column?.iconToolTip"
                [ngStyle]="column.iconColor ?  {'color':column?.iconColor}: {'color': 'black'}">{{column?.iconCondition
                ? getIconForColumn(column?.iconCondition, data[column?.column], column?.icon): column?.icon}}</em>
            <span class="rowContent">
                {{data[column?.column] }}</span>
        </span>
    </div>
    <div *ngIf="column?.type ==='Ratings'">
        <ng-container *ngFor="let rating of [1,2,3,4,5];let i=index">
            <mat-icon [ngStyle]="{'color':'#ffa41c'}" *ngIf="i<data[column?.column]">
                star_rate</mat-icon>
            <mat-icon [ngStyle]="{'color':'#ffa41c'}" *ngIf="i>=data[column?.column] || !data[column?.column] ">
                star_border</mat-icon>
        </ng-container>
    </div>
    <div *ngIf="column?.type ==='Toggle'">
        <mat-slide-toggle [disabled]="clickable || isRowWiseToggleEnabled(data, column)"
            [attr.data-control]="('slideToggle ' + i) | dataControl" (change)="toggleChange($event, data, column)"
            [checked]="data[column?.column]">
        </mat-slide-toggle>
    </div>
    <div *ngIf="column?.type ==='Object'" class="element"
        [ngClass]="[column?.class? column?.class: 'default', column?.classCondition ? getClassForColumn(column?.classCondition, column?.alternativeField ? data[column?.alternativeField] : data[column?.column]): '', isLeftAlign? '' : 'alignCenter']">
        <em fxFlex="5%" fxFlex.lt-md="7%" *ngIf="column?.icon || column?.iconCondition"
            class="material-icons-outlined rowIcon"
            [matTooltip]="column?.toolTipCondition ? getToolTipForColumn(column?.toolTipCondition, data[column?.column], column?.iconToolTip): column?.iconToolTip"
            [ngStyle]="column.iconColor ?  {'color':column?.iconColor}: {'color': 'black'}">{{column?.iconCondition ?
            getIconForColumn(column?.iconCondition, data[column?.column], column?.icon): column?.icon}}</em>
        <span class="rowContent textWrapElipsis">
            {{column?.type==='Object' ? getValue(i, column?.column) : '-'}}</span>
    </div>
    <div *ngIf="column?.type ==='Icon'" class="element"
        [ngClass]="[column?.class? column?.class: 'default', column?.classCondition ? getClassForColumn(column?.classCondition, column?.alternativeField ? data[column?.alternativeField] : data[column?.column]): '', isLeftAlign? '' : 'alignCenter']">
        <em fxFlex="5%" fxFlex.lt-md="7%" *ngIf="column?.icon || column?.iconCondition"
            class="material-icons-outlined rowIcon"
            [matTooltip]="column?.toolTipCondition ? getToolTipForColumn(column?.toolTipCondition, data[column?.column], column?.iconToolTip): column?.iconToolTip"
            [ngStyle]="column.iconColor ?  {'color':column?.iconColor}: {}">{{column?.iconCondition ?
            getIconForColumn(column?.iconCondition, data[column?.column], column?.icon): column?.icon}}</em>
        <!-- <span class="rowContent">  {{data[column?.column] | date: (column?.format? column?.format: 'dd/MM/yyyy hh:mm:ss a' ) }}</span> -->
    </div>
    <span
        *ngIf="(!data[column?.column] && column?.type != 'Object' && column?.type !='Image' && data[column?.column] !=0 && column?.type !='InnerHtml' && column?.type !='Icon' && column?.type !='Amount' && column?.type !='Toggle' && column?.type !='Ratings'  ) ||  (data[column?.column] == null && column?.type !='Amount' && column?.type !='Object'  && column?.type !='Image'  && column?.type !='InnerHtml' && column?.type !='Icon' && column?.type !='Toggle' && column?.type !='Ratings' && data[column?.column] !=0 ) || (data[column?.column] == ''  && column?.type !='Amount' && column?.type !='Object'  && column?.type !='Image'  && column?.type !='InnerHtml' && column?.type !='Icon' && column?.type !='Toggle' && column?.type !='Ratings' && data[column?.column] !=0 )">
        N/A
    </span>
    <div *ngIf="column && column.customRibbon">
        <div *ngIf="column?.customRibbon?.isDisplay && isRibbonDisplay(data, column,false)" class="customRibbon"
            [ngClass]="column.customRibbon.isAlignLeft ? '' : 'custom-ribbon-align-center'"
            [ngStyle]="column.customRibbon.backgroundColor?  {'background-color': column.customRibbon.backgroundColor}: {}">
            {{ isRibbonDisplay(data, column,false)}}
        </div>
    </div>
    <div *ngIf="column && column.customRibbon && column.customRibbon.subCustomRibbon">
        <div *ngIf="column.customRibbon.subCustomRibbon.isDisplay && isRibbonDisplay(data, column,true)"
            class="customRibbon"
            [ngClass]="column.customRibbon.subCustomRibbon.isAlignLeft ? '' : 'custom-ribbon-align-center'"
            [ngStyle]="column.customRibbon.subCustomRibbon.backgroundColor?  {'background-color': column.customRibbon.subCustomRibbon.backgroundColor}: {}">
            {{ isRibbonDisplay(data, column,true)}}
        </div>
    </div>

</ng-template>

<ng-container *ngIf="needPaginator || listProperties?.isLocalPagination">
    <phase-ii-paginator *ngIf="paginatorVersion == 1" (emitData)="onDataEmit($event)"
        (valueChanged)="getChangedData($event)" (paginatorData)="getPaginatorData($event)"
        [isLocalPagination]="listProperties?.isLocalPagination ?  listProperties?.isLocalPagination: false">
    </phase-ii-paginator>

    <phase-ii-paginator-vii *ngIf="paginatorVersion == 2" (emitData)="onDataEmit($event)"
        (valueChanged)="getChangedData($event)" (paginatorData)="getPaginatorData($event)"
        [isFilterReset]="isFilterReset"
        [isLocalPagination]="listProperties?.isLocalPagination ?  listProperties?.isLocalPagination: false">
    </phase-ii-paginator-vii>
</ng-container>



<!-- [matBadge]="listProperties.badgeCondition? isBadgeDisplay(data) : ''" matBadgeColor="warn"> -->