/**
 * Import statements for the directive.
 */
import { Directive, forwardRef, HostListener } from '@angular/core';
import { DefaultValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
/**
 * Variable used to access the trim value directive.
 */
const TRIM_VALUE_ACCESSOR: any = {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => TrimValueAccessorDirective),
    multi: true
};
/**
 * The trim accessor for writing trimmed value and listening to changes that is
 * used by the {@link NgModel}, {@link FormControlDirective}, and
 * {@link FormControlName} directives.
 */
@Directive({
    // tslint:disable-next-line:directive-selector
    selector: '[trimVal]',
    providers: [TRIM_VALUE_ACCESSOR]
})
/**
 * Class to access the trim value.
 */
export class TrimValueAccessorDirective extends DefaultValueAccessor {
    /**
     * Method which is used to trigggered the input
     * @param val has the input value
     */
    @HostListener('input', ['$event.target.value'])
    /**
     * Life cycle hook
     */
    ngOnChange = (val: string) => {
        let value = val.trim();
        if (value === "") {
            value = null;
        }
        this.onChange(value);
    };
    /**
     * Method which is used to triggered after the input type completed
     * @param val has the value
     */
    @HostListener('blur', ['$event.target.value'])
    /**
     * Mehtod used to trim the value and apply the value
     */
    applyTrim(val: string) {
        let value = val.trim();
        if (value === "") {
            value = null;
        }
        this.writeValue(value);
    }
    /**
     * Method used to write value after the trim
     * @param value 
     */
    writeValue(value: any): void {
        if (typeof value === 'string') {
            value = value.trim();
        }
        if (value === "") {
            value = null;
        }
        super.writeValue(value);
    }
}