import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import * as CryptoJS from 'crypto-js';
import { BehaviorSubject, merge, of, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { HeaderService } from './header.service';
import { BroadcastChannel } from 'broadcast-channel';
import { CommonDataService } from 'libs/shared/src/lib/services/common-data.service';
import { API } from 'libs/common/src/lib/constants/api-routes';
// import { RolePermissionService ,HttpRoutingService,Common_DataService} from '@phase-ii/common';
import { HttpRoutingService } from 'libs/common/src/lib/services/httpRouting.service';
import { dataConfig } from 'libs/common/src/lib/services/config';
import { SharedService } from 'libs/themes/shared-theme/src/lib/services/shared.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  /**
   * Component constructor to inject the required services.
   * @param httpRoutingService To get the httpRoutingservice.
   * @param headerService To get the header service.
   */
  environment: any;
  /**
   * BehaviorSubject used to get the user details.
   */
  user = new BehaviorSubject<any>(null);
  /**
  * BehaviorSubject used to get the read or write.
  */
  isReadOnly = new BehaviorSubject<boolean>(false);
  /**
   * Behavior Subject used to save the store industy type
   */
  storeIndustry = new BehaviorSubject<any>(null);
  /**
* Behavior Subject used to save the filter given in dashboard.
*/
  dashboardFilter = new BehaviorSubject<any>(null);
  industry;
  /**
   * Variable user to send Data between Two Browser Tabs
   */
  broadCast = new BroadcastChannel('authenticate');

  tokens = { accessToken: null, refreshToken: null };
  /**
   * 
   * @param httpRoutingService used to access the functions in the http routing service
   * @param headerService used to access the functions in the header service
   * @param router used to navigate to the particular page
   * @param commonDataService used to access the functions in the common service
   * @param environment used to access the environment variabled
   */
  constructor(
    private httpRoutingService: HttpRoutingService,
    private headerService: HeaderService,
    private router: Router,
    private sharedService: SharedService,
    private commonDataService: CommonDataService,
    @Inject('environment') environment) {
    this.environment = environment;
  }
  /**
   * this method used to fetch user details
   * @param storeId 
   * @returns 
   */
  getOneUserNotificationDetails(storeId, userId) {
    return this.httpRoutingService.getMethod('stores/' + storeId + '/users/' + userId + '/notification');
  }
  /**
   * this method is used to decrypt the verification code
   * @param data encrypted code
   * @returns 
   */
  decryptionCode(data) {
    if (data) {
      const bytes = CryptoJS.AES.decrypt(data.toString(), this.environment.secretKey);
      const result = bytes.toString(CryptoJS.enc.Utf8).replace('|', /\\/g);
      return result;
    } else {
      return null;
    }
  }
  /**
  * Method which is used to decode the url
  * @param path Route path with params
  */
  urlDecode(path) {
    path = path.replace(/%20/gi, ' ');
    path = path.replace(/%3D/gi, '=');
    path = path.replace(/%40/gi, '@');
    path = path.replace(/%3A/gi, ':');
    path = path.replace(/%24/g, '$');
    path = path.replace(/%2B/gi, '+');
    path = path.replace(/%26/gi, '&');
    path = path.replace(/%2F/gi, '/');
    return path;
  }
  /**
  * Method which is used make to API call for signin.
  * @param data holds the signin details.
  * @returns the response.
  */
  signin(data) {
    const key = 'Registration';
    this.getBase64String(data.email, data.password, 'users/zenbasket/login', key);
    delete data.email;
    delete data.password;
    return this.httpRoutingService.postMethod('users/zenbasket/login', data).pipe(map((res: any) => {
      if (res && res.login && res.login.user) {
        this.setLocalStorage(res.login);
        this.tokens.accessToken = res.login.accessToken;
        this.user.next(res.login.user);
      }
      return res;
    }))
  }
  /**
  * this method is used to get signin user details
  * @param emailId mail id
  * @param storeId has store id
  * @returns
  */
  getSignInUser(data) {
    const url = this.httpRoutingService.replaceUrlParam('customer/zenbasket/forgotpassword', data)
    return this.httpRoutingService.postMethod(url, data);
  }
  /**
   * method to configure password
   * @param data
   * @returns
   */
  resetSignInPassword(data) {
    const key = 'Registration';
    this.getBase64String(null, data.confirmPassword, 'customer/configurepassword', key);
    delete data.password;
    delete data.confirmPassword;
    const url = this.httpRoutingService.replaceUrlParam(API.RESET_PASSWORD, {})
    return this.httpRoutingService.postMethod(url, data);
  }
  /**
 * method to configure password
 * @param data
 * @returns
 */
  setSignInPassword(data) {
    const key = 'Registration';
    this.getBase64String(null, data.confirmPassword, 'customer/setpassword', key);
    delete data.password;
    delete data.confirmPassword;
    const url = this.httpRoutingService.replaceUrlParam(API.SET_PASSWORD, {})
    return this.httpRoutingService.postMethod(url, data);
  }

  /**
   * Method which is used to API call for sigin.
   */
  signinUser(data) {
    const key = 'Registration';
    this.getBase64String(data.email, data.password, 'master/login', key);
    delete data.email;
    delete data.password;
    return this.httpRoutingService.postMethod('master/login', data).pipe(map((res: any) => {

      if (res && res.login && res.login.user) {
        this.setLocalStorage(res.login);
        this.tokens.accessToken = res.login.accessToken;
        this.tokens.refreshToken = res.login.refreshToken;
        // this.tokens.lambdaAccessToken = res.login.lambdaAccessToken;
        this.user.next(res.login.user);
        return res.login.user;
      }
      else {
        return res.details;
      }
    }));
  }

  /**
   * this method is used to set tokens in local storage
   * @param data 
   */
  setLocalStorage(data) {
    this.headerService.setHeaders('default', 'Authorization', data.accessToken);
    localStorage.setItem('jwt_token', data.accessToken);
    localStorage.setItem('history_id', data && data.userLoginHistory);
    // localStorage.setItem('lambda_jwt_token', data.lambdaAccessToken);
  }

  /**
   * this method is used to set tokens in local storage for storefront
   * @param data 
   */
  setLocalStoregeForCustomer(data) {
    this.headerService.setHeaders('default', 'Authorization', data.accessToken);
    localStorage.setItem('jwt_token', data.accessToken);
    // localStorage.setItem('lambda_jwt_token', data.lambdaAccessToken);
    localStorage.setItem('history_id', data && data.userLoginHistory);
  }
  /**
   * method to check whether the display name already exists
   * @param data 
   * @returns 
   */
  displayNameExists(data) {
    return this.httpRoutingService.getMethod(API.DISPLAY_NAME_EXISTS, data);
  }
  /**
   * method to signin via social media
   * @param data 
   * @returns 
   */
  socialsignIn(data: { email: string, firstName: string, lastName: string, provider: string }) {
    const url = 'sociallogin';
    return this.httpRoutingService.postMethod(url, {
      data: data
    }).pipe(map((res: {
      login: {
        user: { id, storeId, roleId, clientId, email, phone, countryCode, store: { industryId, currencyData, themeId?}, customerId?, customerGroupId?}, refreshToken, accessToken
      }
    }) => {
      if (res && res.login && res.login.user) {
        this.headerService.setHeaders('default', 'Authorization', res.login.accessToken);
        localStorage.setItem('logInfo', JSON.stringify({
          refresh_token: res.login.refreshToken
        }));
        localStorage.setItem('jwt_token', res.login.accessToken);
        this.user.next(res.login.user);
      }

      return res.login.user;
    }));
  }
  /**
   * Method which is used to API call for logout the user.
   */
  logout(data?) {
    if (this.user && this.user.value && this.user.value.infoId) {
      this.httpRoutingService.postMethod('admin/logout', { infoId: this.user.value.infoId }).subscribe((res) => {
      });

    }
    this.headerService.clearHeaders('default', 'Authorization');
    if (this.user?.value?.roleCode == dataConfig.storeAdminCode)
      this.headerService.clearHeaders('default', 'Plan-Id');
    localStorage.removeItem('logInfo');
    localStorage.removeItem('jwt_token');
    localStorage.removeItem('lambda_jwt_token');
    sessionStorage.removeItem('appliedDiscounts');
    localStorage.removeItem('history_id');
    this.user.next(null);
    this.broadCast.postMessage("Logout");
    this.sharedService.cartCountValue.next(0);
    this.sharedService.subscriptionCartCountValue.next(0);
    return true;
  }
  /**
  * Method which is used to API call for get the refresh token for the user.
  */
  // refreshToken() {
  //   const currentUser = JSON.parse(localStorage.getItem('logInfo'));
  //   const token = currentUser ? currentUser.refresh_token : null;
  //   return this.httpRoutingService.postMethod('refreshToken', { refreshToken: token }).pipe(catchError((err) => {
  //     return throwError(err);
  //   }), map(res => {
  //     return res;
  //   }));
  // }
  /**
   * Method used to check whether user is authenticated or not
   */
  isAuthenticated() {
    if (this.getToken()) {
      return true;
    } else {
      return false;
    }
  }
  /**
  * method used for get one plugin detail
  * @param storeId store id
  * @param data plugin name
  * @returns 
  */
  getOnePlugin(storeId, data) {
    const url = this.httpRoutingService.replaceUrlParam(API.GET_ONE_PLUGIN, { storeId: storeId });
    return this.httpRoutingService.getMethod(url, data);
  }
  /**
   * method used for get all plugin detail
   * @param storeId  store id
   * @param data plugin name
   * @returns 
   */
  PluginByCode(storeId, data) {
    const url = this.httpRoutingService.replaceUrlParam(API.PLUGIN_BY_CODE, { storeId: storeId });
    return this.httpRoutingService.getMethod(url, data);
  }
  /**
  * Method which is used to API call for check password.
  */
  checkPasswordMatchForAdmin(data) {
    const key = 'registration';
    this.getBase64String(data.email, data.password, API.CHECK_PASSWORD_EXISTS, key);
    return this.httpRoutingService.getMethod(API.CHECK_PASSWORD_EXISTS);
  }
  /**
* Method which is used to API call for check password.
*/
  checkPasswordMatchForSuperAdmin(data) {
    const key = 'registration';
    this.getBase64String(data.email, data.password, API.CHECK_SUPER_ADMIN_PASSWORD_EXISTS, key);
    return this.httpRoutingService.getMethod(API.CHECK_SUPER_ADMIN_PASSWORD_EXISTS);
  }

  /**
   * method to get the plugins by using their code
   * @param storeId 
   * @param data 
   * @returns 
   */
  getPluginsByCode(storeId, data) {
    const url = this.httpRoutingService.replaceUrlParam(API.GET_PLUGIN_BY_CODE, { storeId: storeId });
    return this.httpRoutingService.getMethod(url, data);
  }
  /**
 * method used for get  plugin detail
 * @param storeId store id
 * @param data plugin names
 * @returns 
 */
  getPlugins(storeId, data) {
    return this.httpRoutingService.getMethod('stores/' + storeId + '/pluginNames', data);
  }
  /**
   * Method used to generate & get code.
   * @returns returns the response from server.
   */
  codeGeneration(storeId, data) {
    return this.httpRoutingService.getMethod('stores/' + storeId + '/code', data);
  }
  /**
   * Method which is used to API call for get the token.
   */
  getToken(): string {
    const token = localStorage.getItem('jwt_token');
    return token;
  }
  /**
  * Method which is used to API call for check password.
  */
  checkPasswordMatch(data) {
    return this.httpRoutingService.postMethod('customer/checkpassword', data);
  }
  /**
 * method to send reset password mail
 * @param data
 * @returns
 */
  resetPasswordForAdmin(data) {
    return this.httpRoutingService.postMethod(API.RESET_PASSWORD_FOR_ADMIN, data);
  }

  /**
   * Method used get the current user details
   */
  getCurrentUser(code?, storeId?) {
    this.headerService.setHeaders('default', 'Authorization', localStorage.getItem('jwt_token'));
    return this.httpRoutingService.getMethod('users/getCurrentUser', { storeId: storeId })
      .pipe(map((res: { currentUser: { user: { id, storeId, roleId, email, phone, store: { industryId, themeId }, customerId?, customerGroupId?} } }) => {
        if (res && res.currentUser && res.currentUser.user) {
          this.user.next(res.currentUser.user);
        }
        return res.currentUser.user;
      }), catchError((err) => {
        this.logout();
        return of(null);
      }));
  }
  /**
 * method to verify the token for admin
 * @param data
 * @returns
 */
  verifyTokenForAdmin(data) {
    return this.httpRoutingService.postMethod(API.VERIFY_TOKEN_FOR_ADMIN, data);
  }


  /**
   * method to get the admin details
   */
  getAdminDetails(storeId) {
    const url = this.httpRoutingService.replaceUrlParam(API.GET_ADMIN_DETAILS, { storeId: storeId });
    return this.httpRoutingService.getMethod(url);
  }
  getSuperAdminDetails() {
    return this.httpRoutingService.getMethod(API.GET_CURRENT_USER_SUPERADMIN);
  }
  /**
   * Method which is used to add the user details in user table.
   * @param userInfo To get the user details.
   */
  registerAdmin(userInfo: any) {
    return this.httpRoutingService
      .postMethod('registerAdmin', userInfo)
      .pipe(
        map((res) => {
          if (res) {
            return res;
          }
        }),
        catchError((err) => {
          return throwError(err);
        })
      );
  }
  /**
    * Method which is used to fetch all industry types.
    * @returns Returns response.
    */
  getLabels(id) {
    return this.httpRoutingService.getMethod('/industry/' + id);
  }
  registeruser(userInfo: any) {
    return this.httpRoutingService
      .postMethod('customer/registeruser', userInfo)
      .pipe(
        map((res) => {
          if (res) {
            return res;
          }
        }),
        catchError((err) => {
          return throwError(err);
        })
      );
  }
  /**
   * method to conver the email and password to base 64 format
   * @param userName 
   * @param password 
   * @param url 
   * @param key 
   */
  getBase64String(userName, password?, url?, key?,version:string ='v1') {
    let authdata;
    if (password) {
      authdata = 'Basic ' + window.btoa(userName + ':' + password);
    } else {
      authdata = 'Basic ' + window.btoa(userName);
    }
    const apiUrl=this.httpRoutingService.generateUrl(url,version);
    this.headerService.setHeaders(apiUrl, key, authdata);
  }
  /**
   * method to get all the industry types
   * @returns 
   */
  getAllIndustryType() {
    return this.httpRoutingService.getMethod(API.GET_INDUSTRIES);
  }

  /**
   * method to get the custom registration
   * @param storeId 
   * @returns 
   */
  getOneCustomregistration(storeId) {
    return this.httpRoutingService.getMethod('stores/' + storeId + '/customregistration');
  }

  /**
   * this method used to get all security questons of storeId
   * @param storeId store id
   * @returns 
   */
  getAllSequrityQuestions(storeId, userId) {
    const url = this.httpRoutingService.replaceUrlParam(API.GET_SECURITY_QUESTIONS, { storeId: storeId, id: userId })
    return this.httpRoutingService.getMethod(url);
  }
  /**
   * this method is used gto get user details
   * @param emailId mail id
   * @returns 
   */
  getUserDetails(emailId) {
    return this.httpRoutingService.getMethod('getuserdetails/' + emailId);
  }
  /**
   * this method used to check answers of sequrity question
   * @param userId 
   * @param data 
   * @returns 
   */
  checkAnswers(storeId, userId, data) {
    const url = this.httpRoutingService.replaceUrlParam(API.CHECK_ANSWERS, { storeId: storeId, id: userId })
    return this.httpRoutingService.postMethod(url, data);
  }
  /**
   * method to send mail
   * @param data 
   * @returns 
   */
  sendEmail(data) {
    const url = this.httpRoutingService.replaceUrlParam(API.FORGOT_PASSWORD, {})
    return this.httpRoutingService.postMethod(url, data);
  }
  /**
 * method to send mail
 * @param data 
 * @returns 
 */
  sendEmailForSuperAdmin(data) {
    const url = this.httpRoutingService.replaceUrlParam(API.SUPER_ADMIN_FORGOT_PASSWORD, {})
    return this.httpRoutingService.postMethod(url, data);
  }

  /**
   * method to check whether the email already exists
   * @param email 
   * @returns 
   */
  checkMailExist(email: string) {
    return this.httpRoutingService
      .postMethod('checkMailExist', { email: email })
      .pipe(
        map((res) => {
          return res;
        }),
        catchError((err) => {
          return of({ error: true });
        })
      );
  }
  /**
   * method to get the user's email
   */
  getUserEmail(email: string, storeId: number) {
    return this.httpRoutingService
      .postMethod('getUserEmail', { email: email, storeId: storeId })
      .pipe(
        map((res) => {
          return res;
        }),
        catchError((err) => {
          return of({ error: true });
        })
      );
  }
  /**
   * method to get user email for admin
   * @param email 
   * @returns 
   */
  getUserEmailForAdmin(email: string) {
    return this.httpRoutingService
      .postMethod('getUserEmailForAdmin', { email: email })
      .pipe(
        map((res) => {
          return res;
        }),
        catchError((err) => {
          return of({ error: true });
        })
      );
  }
  /**
   * method to get the store id
   * @returns 
   */
  getStoreId() {
    if (this.router.url && this.router.url.indexOf('store/')) {
      const pathElements = this.router.url.split('/');
      if (pathElements && pathElements[2]) {
        return pathElements[2];
      }
    }
  }
  /**
   * method to verify user
   * @param data
   * @returns
   */
  verifyUser(data) {
    return this.httpRoutingService.postMethod('verifyUser', data);
  }
  /**
   * method to verify customer
   * @param data
   */
  verifyCustomer(data) {
    return this.httpRoutingService.postMethod('verifyCustomer', data);
  }
  /**
   * Method used to verify customer details.
   * @param data holds the customer data.
   * @returns the response.
   */
  verifyCustomers(data) {
    const url = this.httpRoutingService.replaceUrlParam(API.VERIFY_CUSTOMER, {})
    return this.httpRoutingService.getMethod(url, data);
  }
  /**
   * method to verify token for admin
   * @param data
   * @returns
   */
  verifyToken(data) {
    const url = this.httpRoutingService.replaceUrlParam(API.VERIFY_TOKEN, {})
    return this.httpRoutingService.postMethod(url, data);
  }

  /**
 * Method used to verify customer details.
 * @param data holds the customer data.
 * @returns the response.
 */
  storeFrontVerifyCustomer(data) {
    const url = this.httpRoutingService.replaceUrlParam(API.VERIFY_CUSTOMER, {})
    return this.httpRoutingService.getMethod(url, data);
  }
  /**
   * method to configure password
   * @param data 
   * @returns 
   */
  resetPassword(data) {
    const url = this.httpRoutingService.replaceUrlParam(API.RESET_PASSWORD, {})
    return this.httpRoutingService.postMethod(url, data);
  }
  /**
   * method to set password staff role
   * @param data 
   * @param roleId 
   * @returns 
   */
  setPassword(data, roleId?) {
    const url = this.httpRoutingService.replaceUrlParam(API.SET_PASSWORD, {})
    if (roleId)
      data.roleId = roleId;
    return this.httpRoutingService.postMethod(url, data);
  }
  /**
   * method to change password
   * @param data 
   * @returns 
   */
  changePassword(data) {
    const url = this.httpRoutingService.replaceUrlParam(API.CHANGE_PASSWORD, {})
    return this.httpRoutingService.postMethod(url, data);
  }
  /**
   * Author:Ponmani B
 * method to deactivate user account
 */
  deactivateAccount(storeId, userId, historyId?) {
    const url = this.httpRoutingService.replaceUrlParam(API.DEACTIVATE_ACCOUNT, {
      storeId: storeId,
      userId: userId
    });
    return this.httpRoutingService.deleteMethod(url, { historyId: historyId });
  }
  /**
   * Method which is used to fetch Custom Registration.
   * @returns Returns response.
   */
  getAdditionalFields(storeId) {
    return this.httpRoutingService.getMethod('stores/' + storeId + '/customregistration')
  }
  /**
   * method to check whether the email already exists
   * @param email 
   * @returns 
   */
  checkMailAlreadyExist(email: string) {
    return this.httpRoutingService
      .postMethod('admin/check/mail', { email: email })
      .pipe(
        map((res) => {
          return res;
        }),
        catchError((err) => {
          return of({ error: true });
        })
      );
  }
  /**
 * method to check whether the email already exists for super admin forgot password
 * @param email 
 * @returns 
 */
  checkMailAlreadyExistForSuperAdmin(email: string) {
    return this.httpRoutingService
      .getMethod('master/mail', { email: email })
      .pipe(
        map((res) => {
          return res;
        }),
        catchError((err) => {
          return of({ error: true });
        })
      );
  }

  /**
  * Method which is used to add notification token.
  * @param data holds the data to add token
  * @returns Returns response.
  */
  addToken(storeId, data) {
    const url = this.httpRoutingService.replaceUrlParam(API.ADD_TOKEN, {
      storeId: storeId,
    });
    return this.httpRoutingService.postMethod(url, data);
  }

  /**
* Method which is used to fetch Store Details.
* @returns Returns response.
*/
  getStoreDetails(storeId) {
    return this.httpRoutingService.getMethod('stores/' + storeId + '/settings')
  }

  /**
    * Method getInventoryLocations is used to get all inventory location details from api.
    * @param storeId which holds storeId
    */
  getInventoryLocations(storeId) {
    return this.httpRoutingService.getMethod('stores/' + storeId + '/locations/v4');
  }

  /**
  * Method which is used to fetch user list for custom push notification.
  * @param storeId has storeId of logged in user
  * @returns Returns response.
  */
  getUserDetailsForNotification(storeId) {
    const url = this.httpRoutingService.replaceUrlParam(API.GET_USER_EMAIL, {
      storeId: storeId,
    });
    return this.httpRoutingService.getMethod(url);
  }

  /**
  * Method which is used to send push notification.
  * @param data holds the data to send push notification
  * @returns Returns response.
  */
  sendPushNotification(storeId, data) {
    const url = this.httpRoutingService.replaceUrlParam(API.SEND_PUSH_NOTIFICATION, {
      storeId: storeId,
    });
    return this.httpRoutingService.postMethod(url, data);
  }

  /**
  * Method which is used to create custom push notification details.
  * @param data holds the data to create custom push notification details
  * @returns Returns response.
  */
  customPushNotification(storeId, data) {
    const url = this.httpRoutingService.replaceUrlParam(API.CUSTOM_PUSH_NOTIFICATION, {
      storeId: storeId,
    });
    return this.httpRoutingService.postMethod(url, data);
  }
  /**
 * Method which is used to add the user details in user table.
 * @param userInfo To get the user details.
 */
  registerStoreDetails(storeInfo: any) {
    return this.httpRoutingService.postMethod('registerStoreDetails', storeInfo);
  }
  verifyEmail(id) {
    const url = this.httpRoutingService.replaceUrlParam(API.verificationMail, {
      userId: id,
    });
    return this.httpRoutingService.getMethod(url);
  }
  /**
  * Method which is used to fetch Store Currency Details.
  * @returns Returns response.
  */
  getStoreCurrencyDetails(storeId) {
    const url = this.httpRoutingService.replaceUrlParam(API.GET_STORE_CURRENCY_DETAILS, {
      storeId: storeId,
    });
    return this.httpRoutingService.getMethod(url);
  }
  /**
   * method to save the logout details
   * @param id 
   * @returns 
   */
  saveLogoutDetails(id, data) {
    return this.httpRoutingService.postMethod(API.SAVE_LOGOUT, { id: id, historyId: data });
  }
  /**
   * this method is used gto get user details
   * @param emailId mail id
   * @param storeId has store id
   * @returns 
   */
  getUser(emailId, storeId) {
    const url = this.httpRoutingService.replaceUrlParam(API.GET_USER, { storeId: storeId, emailId: emailId })
    return this.httpRoutingService.getMethod(url);
  }
  /**
 * Method which used for get inventory locations based on current user
 * @param storeId has current store id
 * @returns list of inventory locations 
 */
  getUserBasedInventoryLocations(storeId) {
    return this.httpRoutingService.getMethod('stores/' + storeId + '/locations/users');
  }
  /**
   * Method which is used to set session storage for guest user details
   * @param token 
   * @param userInfo 
   */
  setGuestTokenSessionStorage(token, userInfo) {
    sessionStorage.setItem('GusetUserJWT', token);
    sessionStorage.setItem('GuestUserInfo', userInfo ? this.commonDataService.setParam(JSON.stringify(userInfo)) : null)
  }
  /**
   * Method which is used to get the guest user details and set token for authorized api call.
   * @param token 
   * @returns 
   */
  setGuestUserToken(token) {
    const guestUserInfoString = this.commonDataService.getParam(sessionStorage.getItem('GuestUserInfo'));
    const guestUserInfo = JSON.parse(guestUserInfoString ? guestUserInfoString : null);
    if (guestUserInfo) {
      this.headerService.setHeaders('default', 'Authorization', token);
      this.user.next(guestUserInfo);
      return true;
    }
    return false;
  }
  /**
* Method which is used to fetch customer list for custom email notification.
* @param storeId has storeId of logged in user
* @returns Returns response.
*/
  getCustomerEmail(storeId) {
    const url = this.httpRoutingService.replaceUrlParam(API.GET_CUSTOMER_EMAIL, {
      storeId: storeId,
    });
    return this.httpRoutingService.getMethod(url);
  }

  /**
* Method which is used to store custom email notification.
* @param data has data which are sent as custom email notification
* @returns Returns response.
*/

  customEmailNotification(storeId, data) {
    const url = this.httpRoutingService.replaceUrlParam(API.CUSTOM_EMAIL_NOTIFICATION, {
      storeId: storeId,
    });
    return this.httpRoutingService.postMethod(url, data);
  }

  /**
   * Method used get the current user details
   */
  getCurrentUserForAdmin(code?, storeId?,version:string='v1') {
    if (code === dataConfig.storeAdminCode && !storeId) {
      this.headerService.setHeaders('default', 'Authorization', localStorage.getItem('jwt_token'));
      const apiUrl=this.httpRoutingService.generateUrl(API.USER_DETAIL_FOR_ADMIN,version); 
      this.headerService.setHeaders(apiUrl, 'Authorization', localStorage.getItem('jwt_token'));
      return this.httpRoutingService.getMethod(API.USER_DETAIL_FOR_ADMIN)
        .pipe(map((res: any) => {
          if (res && res.currentUser) {
            localStorage.setItem('jwt_token', res.token);
            this.headerService.setHeaders('default', 'Authorization', res.token);
            this.user.next(res.currentUser);
            this.storeIndustry.next(res.currentUser.industry);
            return res.currentUser
          }
        }), catchError((err) => {
          this.logout();
          throw new Error(err);
        }));
    } else if (code === dataConfig.storeAdminCode && storeId) {
      const url = this.httpRoutingService.replaceUrlParam(API.USER_DETAIL_FOR_SUPERADMIN, { storeId: storeId })
      return this.httpRoutingService.getMethod(url)
        .pipe(map((res: any) => {
          if (res && res.currentUser) {
            this.user.next(res.currentUser);
          }
          localStorage.setItem('jwt_token', res.token);
          this.headerService.setHeaders('default', 'Authorization', res.token);
          return res.currentUser;
        }), catchError((err) => {
          return err;
        }));
    }
  }
  /**method to get sms authentication
   * 
   * @param Id 
   * @param code 
   * @param number 
   * @returns 
   */
  getSmsAuthentication(Id: number,countryCode:any ,code: any, number: number) {
    const url = this.httpRoutingService.replaceUrlParam(API.SIGNUP_OTP_VERIFICATION, { storeId: Id,countryCode:countryCode,userCountryCode: code, phone: number })
    return this.httpRoutingService.getMethod(url);
  }
  /**
   * 
   * @param sotreId method used to get store setting.
   * @returns 
   */
  getStoreSettings(sotreId) {
    const url = this.httpRoutingService.replaceUrlParam(API.GET_STORE_SETTINGS, { storeId: sotreId })
    return this.httpRoutingService.getMethod(url);
  }
  /**
   * method use to check duplicate value 
   * @param tagValue 
   * @param url to set url to get the response
   * @param urlId to set params 
   */
  checkDuplicate(tagValue: any, url: any, urlId: any, UserId?: any) {
    let changeurl;
    if (urlId) {
      changeurl = this.httpRoutingService.replaceUrlParam(url, {
        userId: urlId
      });
    }
    return this.httpRoutingService.postMethod(changeurl ? changeurl : url, tagValue).pipe(
      map((res) => {
        return res;
      }),
      catchError((err) => {
        return of({ error: true });
      })
    );
  }


  /**
   * Method used to get users contact number
   * @param contactNumber 
   * @param storeId 
   * @param role 
   * @returns 
   */
  getUserContactNumber(contactNumber: string, storeId: number, role?) {
    return this.httpRoutingService
      .getMethod('customer/phone', { contactNumber: contactNumber, storeId: storeId, role: role })
      .pipe(
        map((res) => {
          return res;
        }),
        catchError((err) => {
          return of({ error: true });
        })
      );
  }
  /**
  * Method used to get user email id.
  * @param email holds the email id.
  * @param storeId holds the store id.
  * @param role holds the role.
  * @returns the response.
  */
  getCustomerEmailForValidation(email: string, storeId: number, role?) {
    return this.httpRoutingService
      .getMethod('customer/mail', { email: email, storeId: storeId, role: role })
      .pipe(
        map((res) => {
          return res;
        }),
        catchError((err) => {
          return of({ error: true });
        })
      );
  }
  /**
   * Method which is used to register new user.
   * @param userInfo holds the user details.
   * @returns the response.
   */
  registerUser(userInfo: any) {
    const url = this.httpRoutingService.replaceUrlParam(API.REGISTER_CUSTOMER, {})
    return this.httpRoutingService.postMethod(url, userInfo);
  }
  /**
  * Method which is used make to API call for signin.
  * @param data holds the signin details.
  * @returns the response.
  */
  signinCustomer(data) {
    const key = 'Registration';
    this.StorefrontgetBase64String(data.email, data.password, 'users/login', key);
    delete data.email;
    delete data.password;
    return this.httpRoutingService.postMethod('users/login', data).pipe(map((res: {
      login: {
        user: { id, storeId, roleId, clientId, role, phone, countryCode, email, store: { industryId, currencyData, themeId?}, customerId?, customerGroupId?}, refreshToken, accessToken,
      }
    }) => {
      if (res && res.login && res.login.user && !res.login.user['isWholesale'] && data.storeId === res.login.user['storeId']) {
        this.setLocalStoregeForCustomer(res.login);
        this.tokens.accessToken = res.login.accessToken;
        this.tokens.refreshToken = res.login.refreshToken;
        // this.tokens.lambdaAccessToken = res.login.lambdaAccessToken;
        this.user.next(res.login.user);
      }
      sessionStorage.removeItem('GusetUserJWT');
      sessionStorage.removeItem('GuestUserInfo');
      return res;
    }));
  }
  /**
   * Method which is used for whole sale customer sigin.
   * @param data holds the token details.
   * @returns the response.
   */
  wholeSaleCustomerLogin(data) {
    this.setLocalStorage(data.login);
    this.tokens.accessToken = data.login.accessToken;
    this.tokens.refreshToken = data.login.refreshToken;
    // this.tokens.lambdaAccessToken = data.login.lambdaAccessToken;
    this.user.next(data.login.user);
    return true;
  }
  /**
   * Method used to get custom field details.
   * @param storeId holds the store id.
   * @param data holds the custom field code.
   * @returns the response.
   */
  getOneCustomField(storeId, data) {
    const url = this.httpRoutingService.replaceUrlParam(API.CUSTOM_FIELD, { storeId: storeId })
    return this.httpRoutingService.getMethod(url, data);
  }
  checkSuperadminMailAlreadyExist(email: string) {
    return this.httpRoutingService
      .postMethod('superadmin/mailcheck', { email: email })
      .pipe(
        map((res) => {
          return res;
        }),
        catchError((err) => {
          return of({ error: true });
        })
      );
  }

  /**
  * Method used to check the invitation mails whether it is exist or not
  */
  checkInvitationMails(email: any, storeId: number) {
    const url = this.httpRoutingService.replaceUrlParam(API.CHECK_INVITATION_MAILS, { storeId: storeId });
    return this.httpRoutingService.postMethod(url, email);
  }

  /**
 * method used to get Recaptcha credentials
 * 
 */
  getRecaptchaCredential(getRecaptchaCredential: any) {
    const url = this.httpRoutingService.replaceUrlParam(API.GET_RECAPTCHA_CREDENTIAL, {})
    return this.httpRoutingService.getMethod(url, getRecaptchaCredential);
  }


  /**
 * method used to logout for the shopapp user
 * 
 */
  shopAppLogout() {
    this.headerService.clearHeaders('default', 'Authorization');
    localStorage.removeItem('jwt_token');
    localStorage.removeItem('lambda_jwt_token');
    localStorage.removeItem('CurrentOrderStoreId');
    localStorage.removeItem('CurrentStoreLocation');
    // localStorage.removeItem('cart');
    localStorage.removeItem('shopappUser');
    localStorage.removeItem('redirectUrl');
    this.user.next(null);
    this.sharedService.cartCountValue.next(0);
    this.sharedService.subscriptionCartCountValue.next(0);
    this.sharedService.storeId = null;
    this.router.navigate(['/shopapp/signin']);
    this.tokens.accessToken = null;
    return true;
  }

  /**
* method to get the account number
*/
  getAccount(accountNumber: number, id?: number) {
    return this.httpRoutingService
      .getMethod('accountDetails/account', { accountNumber: accountNumber, id: id })
      .pipe(
        map((res) => {
          return res;
        }),
        catchError((err) => {
          return of({ error: true });
        })
      );
  }

  /**
  * method to get the contact number whether it is exist or not
  */
  checkPhoneAlreadyExists(contactNumber, id) {
    return this.httpRoutingService.getMethod('affiliateuser/phone', { mobile: contactNumber, id: id }).pipe(map((res) => {
      return res;
    }),
      catchError((err) => {
        return of({ error: true })
      }))
  }
  /**
 * method used for send otp for verification
 * 
 */
  sendOtpVerification(Id: number, code: any, number: number,userCountryCode?:any) {
    const url = this.httpRoutingService.replaceUrlParam(API.SIGNUP_OTP_VERIFICATION, { storeId: Id, countryCode: code, phone: number, userCountryCode: userCountryCode })
    return this.httpRoutingService.getMethod(url);

  }

  /**
* method used for otp verification in signin
* 
*/
  signinverifyOtp(data: any, storeId: any) {
    const key = 'Registration';
    // this.getBase64String(data.email, data.password, 'customer/signin/verifyotp', key);
    this.StorefrontgetBase64String(data.email, data.password, 'customer/signin/verifyotp', key);
    delete data.email;
    delete data.password;
    // return this.httpRoutingService.postMethod(API.OTP_VERIFY,{data});
    return this.httpRoutingService.postMethod(API.SIGNIN_OTP_VERIFY, { verifyDetails: this.commonDataService.encryptDetails(JSON.stringify(data)), storeId: storeId }
    ).pipe(map((res: {
      login: {
        user: { id, storeId, roleId, clientId, role, phone, countryCode, email, store: { industryId, currencyData, themeId?}, customerId?, customerGroupId?}, refreshToken, accessToken
      }
    }) => {
      if (res && res.login && res.login.user && !res.login.user['isWholesale'] && data.storeId === res.login.user['storeId']) {
        this.setLocalStoregeForCustomer(res.login);
        this.tokens.accessToken = res.login.accessToken;
        this.tokens.refreshToken = res.login.refreshToken;
        // this.tokens.lambdaAccessToken = res.login.lambdaAccessToken;
        this.user.next(res.login.user);
      }
      sessionStorage.removeItem('GusetUserJWT');
      sessionStorage.removeItem('GuestUserInfo');
      return res;
    }));

  }

  /**
* method used for otp verification in signup
* 
*/
  signupverifyOtp(data: any, id: any) {
    return this.httpRoutingService.postMethod(API.SIGNUP_OTP_VERIFY, { data, id })
  }
  /**
 * methpd used to verify the user's email
 * @param id which holds userid
 * @param storeId which holds storeid
 * @returns 
 */
  shopappVerifyEmail(id, storeId) {
    const url = this.httpRoutingService.replaceUrlParam(API.SHOPAPP_EMAIL_VERIFICATION, {
      id: id,
      storeId: storeId
    });
    return this.httpRoutingService.getMethod(url);
  }
  /**
   * method is used to get mobile plan settings
   * @param code which holds property code
   * @returns
   */
  getMobilePlanSettings(storeId: any, discountCode?:string) {
    const url = this.httpRoutingService.replaceUrlParam(API.GET_MOBILE_PAYMENT_DATA, { storeId: storeId });
    return this.httpRoutingService.getMethod(url, (discountCode ? {discountCode} : {}));
  }
  /**
   * method is used to get store wallet details
   * @returns
   */
  getOneStoreWalletDetails() {
    return this.httpRoutingService.getMethod(API.WALLET_DETAILS);
  }
  /**
   * 
   * @param userName variable used to get email  
   * @param password variable used to get password
   * @param url variable used to get url
   * @param key variable used to store secret key  
   */
  StorefrontgetBase64String(userName, password?, url?, key?,version:string ='v1') {
    let authdata;
    if (password) {
      let decryptdetails = userName + ':' + password
      authdata = 'Basic ' + window.btoa(CryptoJS.AES.encrypt(decryptdetails.toString(), dataConfig.encryptionSecretKey).toString());
    } else {
      authdata = 'Basic ' + window.btoa(CryptoJS.AES.encrypt(userName.toString(), dataConfig.encryptionSecretKey).toString());

    }
    const apiUrl=this.httpRoutingService.generateUrl(url,version);
    this.headerService.setHeaders(apiUrl, key, authdata);
  }

  /**
   * method is used to get store wallet details
   * @returns
   */
  getCurrentMobilePlan(clientId: any) {
    const url = this.httpRoutingService.replaceUrlParam(API.CURRENT_MOBILE_PLAN, { clientId: clientId });
    return this.httpRoutingService.getMethod(url);
  }
  /**
* method used for send otp for verification
* 
*/
  sendSignupOtpVerification(Id: number, code: any, number: number, userCountrycode?: any) {
    const url = this.httpRoutingService.replaceUrlParam(API.SIGNUP_OTP_VERIFICATION, { storeId: Id, countryCode: code, userCountryCode: userCountrycode, phone: number });
    return this.httpRoutingService.getMethod(url);

  }
  /**
   * Method used to change password for superadmin
   * @param data 
   * @returns 
   */
  superadminResetPassword(data) {
    const url = this.httpRoutingService.replaceUrlParam(API.SUPERADMIN_RESET_PASSWORD, {})
    return this.httpRoutingService.postMethod(url, data);
  }
  /**
   * method to change password
   * @param data 
   * @returns 
   */
  superadminChangePassword(data) {
    const url = this.httpRoutingService.replaceUrlParam(API.SUPERADMIN_CHANGE_PASSWORD, {})
    return this.httpRoutingService.postMethod(url, data);
  }
  /**
 * method to verify token for superadmin
 * @param data
 * @returns
 */
  superadminVerifyToken(data) {
    const url = this.httpRoutingService.replaceUrlParam(API.SUPERADMIN_VERIFY_TOKEN, {})
    return this.httpRoutingService.postMethod(url, data);
  }
  /**
 * Method used get the superadmin current user details
 */
  getSuperCurrentUser(code?, storeId?) {
    this.headerService.setHeaders('default', 'Authorization', localStorage.getItem('jwt_token'));
    return this.httpRoutingService.getMethod('master/getCurrentUser')
      .pipe(map((res: { currentUser: { user: { id, storeId, roleId, email, phone, store: { industryId, themeId }, customerId?, customerGroupId?} } }) => {
        if (res && res.currentUser && res.currentUser.user) {
          this.user.next(res.currentUser.user);
        }
        return res.currentUser.user;
      }), catchError((err) => {
        this.logout();
        return of(null);
      }));
  }

  /**
 * Method is used to API call for unsubscribe User.
 * @param data hold customer id
 * @returns hold the response
 */
  unsubscribeUser(data) {
    return this.httpRoutingService.putMethod(API.unsubscribeUserEmail, data);
  }

  /**
* method used for get one plugin detail
* @param storeId store id
* @param data plugin name
* @returns 
*/
  getLoyaltyPlugin(storeId: number) {
    const url = this.httpRoutingService.replaceUrlParam(API.GET_LOYALTY_PLUGIN, { storeId: storeId });
    return this.httpRoutingService.getMethod(url);
  }
  
  /**
   * Method which is used to get property based on code
   * @param code , code for property
   * @returns
   */
  getSubscriptionPluginDetails(storeId: number) {
    const url = this.httpRoutingService.replaceUrlParam(API.GET_SUBSCRIPTION_PLUGIN_DETAILS, { storeId: storeId });
    return this.httpRoutingService.getMethod(url, {isStorefront: true});
  }
}




