<div fxLayout="column" mat-elevation-z8 class="filter-card" data-control="filterCard">
	<div fxLayout="row">
		<div fxFlex="75%">
			<h2 class="filter-header" data-control="filterCardHeader">Filters</h2>
		</div>
		<div fxFlex="25%" fxLayout="row" fxLayoutGap="5%" fxLayoutAlign="end">
			<mat-icon class="filter-icon mat-icon-color" matTooltip="Reset filter" (click)="reset()"
				data-control="filterReset">
				refresh
			</mat-icon>
			<mat-icon class="filter-icon mat-icon-color" matTooltip="Close filter" (click)="closeFilter()"
				data-control="filterClose">close</mat-icon>
		</div>
	</div>
	<!-- Ng container Use to Displayed the Note: -->
	<ng-container *ngIf="isDisabled">
		<span class="savedfilter-message" data-control="savedFilterMsg"> Note: when the saved filter is selected, other
			filters are disabled.
		</span><br>
	</ng-container>
	<mat-divider class="filter-above-divder-settings"></mat-divider>
	<div fxFlex="100%"
		[fxLayout]="(isSavedFilter && (isSaveButton || isUpdateButton)) &&(selectedFilters?.length||appliedFilters?.length) && filterForm?.valid ? 'column' : 'row'"
		class="filter-status-settings" fxLayoutGap="15px" fxLayoutAlign="space-between center">
		<!-- Div tag use to Display Selected And Applyed Filter count displayed -->
		<div fxFlex="70%"
			*ngIf="(selectedFilters && selectedFilters?.length >0||appliedFilters && appliedFilters?.length >0)  && filterForm?.valid"
			fxLayout="column"
			[fxLayoutAlign]="(isSavedFilter && (isSaveButton || isUpdateButton)) && (selectedFilters?.length||appliedFilters?.length)? 'center ' : 'start' ">
			<span *ngIf="appliedFilters && appliedFilters.length >=1" fxLayout="row" fxFlex="100%" fxLayoutAlign="none center"
				class="applyed-filter-count">
				<span fxFlex="12px" fxLayoutAlign=" none center" class="applied-filter_colorNote">
				</span>
				<span fxFlex data-control="appliedFilterCount">
					{{appliedFilters.length}} {{appliedFilters.length
					<=1 ? 'Filter' :'Filters'}} Applied </span>
				</span>
				<span *ngIf="selectedFilters && selectedFilters.length-appliedFilters?.length>=1" fxLayout="row" fxFlex="100%"
					fxLayoutAlign="none center " class="selected-filter-count">
					<span fxFlex="12px" fxLayoutAlign=" none center" class="selected-filter_colorNote">
					</span>
					<span fxFlex data-control="selectedFilterCount">
						{{selectedFilters.length-appliedFilters.length}} {{selectedFilters.length-appliedFilters.length
						<=1 ? 'Filter' :'Filters'}} Selected </span>
					</span>
		</div>
		<!-- To display Saved Filter Functionality Save and Apply Button button controls -->
		<div fxLayout="row" fxFlex="30%"
			[fxLayoutAlign]="(isSavedFilter && (isSaveButton || isUpdateButton)) && (selectedFilters?.length||appliedFilters?.length) && filterForm?.valid ? 'center ': 'end' "
			fxLayoutGap="5px" class="filter-status-button-settings"
			[ngStyle]="{'margin-bottom':(isSavedFilter && (isSaveButton || isUpdateButton)) && (selectedFilters?.length||appliedFilters?.length) && filterForm?.valid ? '7px': '0px'}">
			<div
				*ngIf="isSavedFilter && isSaveButton &&  (selectedFilters?.length||appliedFilters?.length) && filterForm?.valid">
				<button mat-flat-button data-control="filterSave" class="primary-button cursor-pointer"
					(click)="isSaveButton=false; onSave()">
					Save
				</button>
			</div>
			<!-- <div *ngIf="isSavedFilter && isUpdateButton && selectedFilters?.length && filterForm?.valid"
                style="margin-top:-2px">
                <button mat-flat-button data-control="filterUpdate" class="primary-button" (click)="onUpdate()">
                    Update
                </button>
            </div> -->
			<div *ngIf="(selectedFilters?.length||appliedFilters?.length) && filterForm?.valid">
				<button mat-flat-button data-control="filterApply" class="primary-button cursor-pointer" (click)="onFilter()">
					Apply
				</button>
			</div>
		</div>
	</div>
	<mat-divider
		*ngIf="(selectedFilters && selectedFilters?.length >0||appliedFilters && appliedFilters?.length >0) && filterForm?.valid"
		class="filter-below-divder-settings">
	</mat-divider>
	<form [formGroup]="filterForm">
		<div class="custom-panel">
			<mat-expansion-panel *ngFor="let filter of filters; let i=index" [expanded]="(indexExpanded === i)"
				class="expansion-panel" (click)="togglePanels(i,indexExpanded)" data-control="expansionPanel">
				<mat-expansion-panel-header [attr.data-control]="filter?.title | dataControl">
					<mat-panel-title>
						<h3
							[ngClass]="{'selected-filter':findselectedFilter(i)!== -1 && !finderrorFilter(filter), 'error_filter': finderrorFilter(filter),'applied-filter': findAppliedFilter(i)!== -1 && !finderrorFilter(filter) }">
							{{filter?.title}}
						</h3>
					</mat-panel-title>
					<mat-icon *ngIf="findselectedFilter(i)!== -1 && findAppliedFilter(i)!== -1" matTooltip="Reset this filter"
						class="refresh-icon mat-icon-color" (click)="clearOneFilter(i,filter?.field)"
						[attr.data-control]="filter?.title + ' Refresh' | dataControl">refresh</mat-icon>
				</mat-expansion-panel-header>
				<!-- To display radio button controls -->
				<ng-container *ngIf="filter?.type==='radio'&& filter?.title!=='Saved Filters'">
					<mat-radio-group [formControlName]="filter?.field" (change)="onFilterChange(i,$event)">
						<mat-radio-button *ngFor="let s of filter?.options; let i=index"
							[value]="filter.valueName ? s[filter.valueName] :s" class="radio-style" fxLayout="row"
							[disabled]="isDisabled">
							<p fxFlex="100%" class="radio-text-style"
								[attr.data-control]="(filter.displayName ? filter.title + ' ' + s[filter.displayName] : filter.title + ' ' + s) | dataControl: ['-']">
								{{filter.displayName ? s[filter.displayName] :s}}</p>
						</mat-radio-button>
					</mat-radio-group>
				</ng-container>
				<!-- To display selector Drop Down controls -->
				<ng-container *ngIf="filter?.type === 'selector' || filter?.type === 'multipleSelector'">
					<mat-select (selectionChange)="onFilterChange(i, filter.valueName, filter?.field, $event)"
						(closed)="onSelectorSearchFilterClose()" placeholder="{{ filter?.title | titlecase }}"
						[formControlName]="filter?.field" [multiple]="filter?.type === 'multipleSelector' ? true : false">
						<mat-select-trigger *ngIf="filter?.type ==='multipleSelector'">
							{{multipleselectorvalue}}
							<span *ngIf="((filterForm?.value?.[filter?.field]?.length || 0) > 1)"
								class="example-additional-selection">
								(+{{(filterForm?.value?.[filter?.field]?.length || 0) - 1}}
								{{filterForm?.value?.[filter?.field]?.length === 2 ? 'other' : 'others'}})
							</span>
						</mat-select-trigger>
						<div fxLayout="row" fxLayoutAlign="center center" class="search">
							<input matInput fxFlex="80%" fxFlex.lt-md="78%" fxFlex.lt-sm="85%" class="form-selector-search-input"
								[placeholder]="'Search'+' '+ (filter?.title | lowercase) " [formControl]="selectorSearchFilter"
								(keyup)="onSelectorSearchFilter($event.target.value,filter)" (keydown.space)="$event.stopPropagation();"
								data-control="searchInput" [readonly]="isDisabled">
							<mat-icon *ngIf="selectorSearchFilter?.value" class="search-close-icon pointer mat-icon-color"
								fxLayoutAlign="center center" fxFlex="20%" fxFlex.lt-md="22%" fxFlex.lt-sm="15%"
								(click)="onSelectorSearchFilterClose()" data-control="closeIcon">
								close
							</mat-icon>
						</div>
						<ng-container *ngIf="selectorOptionValue?.length >= 0 && isSelectorOptions">
							<mat-option *ngFor="let s of selectorOptionValue; let idx = index" class="radio-style" fxLayout="row"
								[value]="filter.valueName ? s[filter.valueName] : s"
								[attr.data-control]="(filter.displayName ? filter.title + ' ' + s[filter.displayName] : filter.title + ' ' + s) | dataControl"
								[disabled]="isDisabled">
								{{ selectorOptionValue ? s[filter.displayName] : s }}
							</mat-option>
							<p *ngIf="isSelectorOptions && selectorOptionValue?.length <= 0" class="noRecordFound"
								data-control="noRecordFound">
								No Record Found
							</p>
						</ng-container>
						<ng-container *ngIf="!isSelectorOptions">
							<mat-option *ngFor="let s of filter?.options; let idx = index" class="radio-style" fxLayout="row"
								[value]="filter.valueName ?s[filter.valueName] :s"
								[attr.data-control]="(filter.displayName ? filter.title + ' ' + s[filter.displayName] : filter.title + ' ' + s) | dataControl"
								[disabled]="isDisabled">
								{{ filter.displayName ? s[filter.displayName] : s }}
							</mat-option>
							<p *ngIf=" !isSelectorOptions && filter?.options?.length <=0" id="noRecordFound"
								data-control="noRecordFound">
								No Record Found
							</p>
						</ng-container>
					</mat-select>
				</ng-container>
				<!-- To display textbox control -->
				<ng-container *ngIf="filter?.type === 'text'">
					<mat-form-field class="text-width" appearance="outline">
						<input matInput type="text" (input)="onFilterChange(i, $event.target.value, filter?.field)"
							[formControlName]="filter?.field" [placeholder]="filter?.title"
							[attr.data-control]="(filter?.field) | dataControl" [disabled]="isDisabled">
						<mat-error *ngIf="this.filterForm?.get(filter?.field)?.hasError('pattern')">
							{{ errorMessage?.SPACE_ERROR_MESSAGE }}
						</mat-error>
					</mat-form-field>
				</ng-container>
				<!-- To display numeric type controls -->
				<ng-container *ngIf="filter?.type === 'numeric'">
					<div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="space-around center" fxLayoutGap="3%"
						fxLayoutGap.lt-md="10%"
						[ngStyle]="{'margin-bottom': (filterForm?.controls[filter?.field]?.get('to')?.hasError('greaterValue') ||filterForm?.controls[filter?.field]?.get('to')?.hasError('max') ) ? '6px' : '0px'}"
						[formGroupName]="filter?.field">
						<mat-form-field appearance="outline" class="range-box">
							<mat-label>From Range</mat-label>
							<input matInput type="number" (input)="checkValue($event, filter?.field, i)" formControlName="from"
								[min]="(filter?.minValue ? filter?.minValue : 0)"
								[max]="(filter?.maxValue ? (filter?.maxValue - 1) : 99999)"
								[attr.data-control]="(filter?.field + ' From') | dataControl" [disabled]="isDisabled">
							<mat-error *ngIf="filterForm?.controls[filter?.field]?.get('from')?.hasError('min')">
								{{ errorMessage?.ERROR?.FROMFILTER?.min }}{{ (filter?.minValue ? filter?.minValue : 0) }}
							</mat-error>
							<mat-error *ngIf="filterForm?.controls[filter?.field]?.get('from')?.hasError('max')">
								{{ errorMessage?.ERROR?.FROMFILTER?.max }}{{ (filter?.maxValue ? filter?.maxValue - 1 : 99999) }}
							</mat-error>
						</mat-form-field>
						<mat-form-field appearance="outline" class="range-box">
							<mat-label>To Range</mat-label>
							<input matInput type="number" (input)="checkValue($event, filter?.field, i)" formControlName="to"
								[min]="(filter?.minValue ? (filter?.minValue + 1) : 1)"
								[max]="(filter?.maxValue ? filter?.maxValue : 100000)"
								[attr.data-control]="(filter?.field + ' To') | dataControl" [disabled]="isDisabled">
							<mat-error *ngIf="filterForm?.controls[filter?.field]?.get('to')?.hasError('min')">
								{{ errorMessage?.ERROR?.TOFILTER?.min }}{{ (filter?.minValue ? (filter?.minValue + 1) : 1) }}
							</mat-error>
							<mat-error *ngIf="filterForm?.controls[filter?.field]?.get('to')?.hasError('max')">
								{{ errorMessage?.ERROR?.TOFILTER?.max }}{{ (filter?.maxValue ? filter?.maxValue : 100000) }}
							</mat-error>
							<mat-error *ngIf="filterForm?.controls[filter?.field]?.get('to')?.hasError('greaterValue')">
								{{ errorMessage?.ERROR?.TOFILTER?.graterValue }}
							</mat-error>
						</mat-form-field>
					</div>
					<mat-error *ngIf="filter.error" [attr.data-control]="(filter?.field + ' Error') | dataControl">
						Please enter from range and to range
					</mat-error>
				</ng-container>
				<!-- To display checkbox controls -->
				<ng-container *ngIf="filter?.type === 'checkbox'">
					<section [formGroupName]="filter?.field">
						<mat-checkbox *ngFor="let s of filter?.options; let idx = index " fxLayout="row" class="radio-style"
							[formControlName]="filter.valueName ? s[filter.valueName] : s"
							(change)="onCheckBoxChange(i, filter?.field)" [value]="filter.valueName ? s[filter.valueName] : s"
							[attr.data-control]="(filter.displayName ? filter?.title + ' ' + s[filter?.displayName] : filter?.title + ' ' + s) | dataControl"
							[disabled]="isDisabled">
							<p
								[attr.data-control]="(filter.displayName ? filter?.title + ' ' + s[filter?.displayName] : filter?.title + ' ' + s) | dataControl">
								{{ filter.displayName ? s[filter.displayName] : s }}
							</p>
						</mat-checkbox>
					</section>
				</ng-container>
				<!-- To display options for datepicker -->
				<mat-form-field *ngIf="filter?.type === 'customDate'" fxLayout="column" appearance="outline" fxFlexFill>
					<mat-label>{{ filter?.title }}</mat-label>
					<mat-select (selectionChange)="dateFilter($event, i)" [(ngModel)]="filter.dateValue"
						[ngModelOptions]="{ standalone: true }" panelClass="panelClass" [disabled]="isDisabled">
						<mat-option *ngFor="let status of filter?.filterByStatus; let idx = index" [value]="status?.value"
							[attr.data-control]="(status?.value) | dataControl">
							{{ status?.viewValue }}
						</mat-option>
					</mat-select>
				</mat-form-field>
				<!-- To display Custom Date Filter -->
				<ng-container
					*ngIf="filter?.dateVisible || filter?.type === 'dateRange' || filter?.type === 'customDatePicker'">
					<div fxFlex="100" fxLayout="column">
						<div fxFlex="50">
							<mat-form-field appearance="outline" fxLayout="column" class="custom-date-filter">
								<mat-label>{{ filter.type === 'customDate' ? filter.title : 'Choose a date range' }}</mat-label>
								<mat-date-range-input [rangePicker]="picker" [formGroupName]="filter?.field"
									[max]="filter?.isMaxDateToday ? today : null" [min]="filter?.isMinDateToday ? today : null"
									[disabled]="isDisabled">
									<input readonly matStartDate formControlName="begin" placeholder="Start date"
										[attr.data-control]="(filter.title + ' start date') | dataControl">
									<input readonly matEndDate formControlName="end" placeholder="End date"
										(dateChange)="setDateValue(i, filter?.type); onFilterChange(i, $event); clearDateError(i)"
										[attr.data-control]="(filter.title + ' end date') | dataControl">
								</mat-date-range-input>
								<mat-datepicker-toggle matSuffix [for]="picker" data-control="datePickerToggle"></mat-datepicker-toggle>
								<mat-date-range-picker #picker></mat-date-range-picker>
							</mat-form-field>
							<mat-error *ngIf="filter.error" [attr.data-control]="(filter?.field + ' error') | dataControl">
								Please enter begin date and end date
							</mat-error>
						</div>
						<ng-container *ngIf="filter?.type === 'customDatePicker'">
							<div fxLayout="row">
								<div fxFlex="100%" fxLayout="row wrap" fxLayoutAlign="center" fxLayoutGap="2%" class="customDatePicker-toggle-list">
									<ng-container *ngFor="let option of filter?.options; let idx = index">
										<button *ngIf="idx < 4" mat-stroked-button
											[ngClass]="{'option-button': (selectedDateValue !== option), 'selected-option-button': (selectedDateValue === option)}"
											(click)="daysClick(option, filter.type, filter.field); onFilterChange(i, option)"
											[attr.data-control]="(filter.title + ' ' + option) | dataControl" [disabled]="isDisabled">
											{{ option }}
										</button>
									</ng-container>
								</div>
							</div>
						</ng-container>
					</div>
				</ng-container>
				<!-- To display toggleGroup Filter -->
				<ng-container *ngIf="filter?.type === 'toggleGroup'">
					<div fxFlex="100%" fxLayout="row wrap" fxLayoutAlign="center" fxLayoutGap="2%" class="toggle-list">
						<ng-container *ngFor="let option of filter?.options; let idx = index">
							<button mat-stroked-button
								[ngClass]="{'option-button':(selectedValue !== option),'selected-option-button':(selectedValue === option)}"
								(click)="daysClick(option);onFilterChange(i,option)"
								[attr.data-control]="(filter.title + ' ' + option) | dataControl" [disabled]="isDisabled">
								{{option}}
							</button>
						</ng-container>
					</div>
				</ng-container>
				<!-- To display slider control -->
				<!-- <ng-container *ngIf="filter?.type=='slider'">
                    <mat-slider data-control="slider" thumbLabel [displayWith]="formatLabel"
                        [tickInterval]="filter?.options?.tickInterval" [step]="filter?.options?.step"
                        [min]="filter?.options?.min" [max]="filter?.options?.max" [disabled]="isDisabled">
                    </mat-slider>
                </ng-container> -->
				<!-- To display description control -->
				<ng-container
					*ngIf="filter?.type === 'radio' && filter?.title === 'Saved Filters' && savedFiltersName && savedFiltersName.length <= 0">
					<p class="saved-filter-unavailable-message" data-control="savedFilterNoRecordFound">
						{{ savedFilterUnavailableMessage }}
					</p>
				</ng-container>
				<!-- To display radio button control in SavedFilter -->
				<ng-container
					*ngIf="filter?.type === 'radio' && filter?.title === 'Saved Filters' && savedFiltersName && savedFiltersName.length">
					<mat-radio-group [formControlName]="filter?.field" (change)="onFilterChange(i, $event, filter?.field)">
						<div *ngFor="let s of savedFiltersName; let idx = index" fxLayout="row"
							fxLayoutAlign="space-between center">
							<mat-radio-button class="radio-style savedFilterRadio" [value]="s">
								<p [fxFlex]="s === this.deleteFilterName ? '0%' : '100%'" class="radio-savedfilter-text-style"
									[attr.data-control]="(filter.displayName ? filter.title + ' ' + s[filter.displayName] : filter.title + ' ' + s) | dataControl">
									{{ s }}
								</p>
							</mat-radio-button>
							<div fxLayout="row" fxLayoutAlign="start center">
								<mat-icon *ngIf="s === this.deleteFilterName && filter?.visibilityIcon?.Visibility" class="icon"
									(click)="onView(s)"
									[ngStyle]="filter?.visibilityIcon?.iconColor ? {'color': filter?.visibilityIcon?.iconColor} : {'color': 'black'}"
									[matTooltip]="filter?.visibilityIcon?.toolTip"
									[attr.data-control]="(s + ' ' + 'visibility icon') | dataControl">
									{{ filter?.visibilityIcon?.iconName }}
								</mat-icon>
								<mat-icon *ngIf="s === this.deleteFilterName && filter?.deleteIcon?.Visibility" class="icon"
									(click)="deleteActionClick(s);"
									[ngStyle]="filter?.deleteIcon?.iconColor ? {'color': filter?.deleteIcon?.iconColor} : {'color': 'black'}"
									[matTooltip]="filter?.deleteIcon?.toolTip"
									[attr.data-control]="(s + ' ' + 'delete icon') | dataControl">
									{{ filter?.deleteIcon?.iconName }}
								</mat-icon>
							</div>
						</div>
					</mat-radio-group>
				</ng-container>
			</mat-expansion-panel>
			<br />
		</div>
	</form>
</div>
<!-- Save Filter Dialog Box -->
<ng-template #saveDialog>
	<div mat-dialog-title class="dialog-title-button" fxLayout="row">
		<span fxFlex="90" fxLayoutAlign="start center">Save Filter</span>
		<span fxFlex="10" fxLayoutAlign="end center"><mat-icon class="close-icon icon mat-icon-color" matTooltip="Close"
				(click)="dialogClose();">close</mat-icon></span>
	</div>
	<div mat-dialog-content class="mat-dialog-content-area">
		<form [formGroup]="saveFilterForm">
			<div fxLayout="column">
				<mat-form-field appearance="outline" class="filter-form-field">
					<mat-label>Filter name</mat-label>
					<input matInput formControlName="filterName" data-control="filterName" required />
					<mat-error *ngIf="saveFilterForm?.get('filterName')?.hasError('required')">
						{{errorMessage?.FIELD_REQUIRED}}
					</mat-error>
					<mat-error *ngIf="saveFilterForm?.get('filterName')?.hasError('pattern') ||  
                            !saveFilterForm?.get('filterName')?.hasError('maxlength')&& 
                            !saveFilterForm?.get('filterName')?.hasError('required')&&
                            !saveFilterForm?.get('filterName')?.hasError('alreadyExists')">
						{{errorMessage?.SPACE_ERROR_MESSAGE}}
					</mat-error>
					<mat-error *ngIf="saveFilterForm?.get('filterName')?.hasError('maxlength') && 
                            !saveFilterForm?.get('filterName')?.hasError('pattern') && 
                            !saveFilterForm?.get('filterName')?.hasError('required')&&
                            !saveFilterForm?.get('filterName')?.hasError('alreadyExists')">
						{{errorMessage?.SAVEDFILTER_COMMENT_LENGTH}}
					</mat-error>
					<mat-error *ngIf="saveFilterForm?.get('filterName')?.hasError('alreadyExists') &&
                            !saveFilterForm?.get('filterName')?.hasError('required') &&
                            !saveFilterForm?.get('filterName')?.hasError('maxlength') && 
                            !saveFilterForm?.get('filterName')?.hasError('pattern')">
						Filter text already exists!
					</mat-error>
				</mat-form-field>
				<div *ngIf="(saveFilterForm?.pending && (saveFilterForm?.dirty || saveFilterForm?.touched))" class="formstatus">
					<span> Processing filter name
						<em class="fas fa-circle-notch fa-spin"></em></span>
				</div>
				<div fxLayout="row" fxLayoutAlign="center">
					<div mat-dialog-actions>
						<button mat-stroked-button class="secondary-button" data-control="cancelButton"
							(click)="dialogClose();">Cancel</button>
					</div>
					<div mat-dialog-actions>
						<button mat-raised-button class="primary-button" data-control="saveButton" (click)="saveFilter()"
							[disabled]=" isSaveLoader">
							<ng-container *ngIf="!isSaveLoader">Save</ng-container>
							<cen-loader *ngIf="isSaveLoader" [type]="'content'" [name]="'cen-dot-spinner'">
							</cen-loader>
						</button>
					</div>
				</div>
			</div>
		</form>
	</div>
</ng-template>
<!-- Save customized Filter view Dialog Box -->
<ng-template #SavedFiltersDialog class="saved-filters-dialog">
	<div mat-dialog-title class="dialog-title-button" fxLayout="row">
		<span fxFlex="90" fxLayoutAlign="start center">Saved Filter Names</span>
		<span fxFlex="10" fxLayoutAlign="end center"><mat-icon class="close-icon icon mat-icon-color" matTooltip="Close"
				(click)="onCloseViewFilterName();">close</mat-icon></span>
	</div>
	<div mat-dialog-content class="saved-filters-dialog-content">
		<mat-list>
			<mat-list-item *ngFor="let Name of AppliedFilterList; let i=index" class="applied-filter-list">
				<div fxLayout="row" fxLayoutGap="20px">
					<mat-icon matListItemIcon class="mat-icon-color">filter_{{AppliedFilterList.length}}</mat-icon>
					<div fxLayout="column">
						<strong class="applied-filter-list-name">{{Name.Title}}</strong>
						<span> <span>{{Name.Title}}</span>
							<span> -- {{Name.Value}}</span>
						</span>
					</div>
				</div>
			</mat-list-item>
		</mat-list>
		<!-- <br> -->
	</div>
</ng-template>