<div class="container" [ngClass.xs]="'container-mob'" [ngClass.sm]="'container-ipad'">
    <!-- mat Dialog Title -->
    <div fxLayout="column" style="padding:0px">
        <h5 mat-dialog-title style="font-weight: bold;">
            <span [ngClass.xs]="'example-spacer'">{{contentData?.title}}</span>
            <mat-icon class="show-cursor close-icon" data-control="export-close" [mat-dialog-close]="false">
                close
            </mat-icon>
        </h5>
    </div>
    <!-- mat Dialog Content -->
    <div mat-dialog-content fxLayout="column" fxLayoutGap="3%" 
        [ngClass]="{'dialog-content-container': exportType?.value == 5}">
        <div [ngClass]="{'upload-container': exportType?.value == 5 }" fxLayout="column">
            <div *ngIf="exportType.value !== 5">
                    <h3>Pick your export option </h3>
                <mat-radio-group class="example-radio-group" [formControl]="exportType">
                    <mat-radio-button [attr.data-control]="export?.name" class="example-radio-button"
                        *ngFor="let export of exportOption" [value]="export?.id">
                        {{export?.name}}
                    </mat-radio-button>
                </mat-radio-group>
            </div>
            <!-- Themes Export -->
            <ng-container *ngIf="exportType.value == 5">
                <div class="sectionList" fxLayout="row" fxLayoutAlign="space-between center">
                    <h5 class="heading">Select the themes to export </h5>
                    <mat-form-field appearance="fill">
                        <mat-label>Search</mat-label>
                        <input matInput data-control="searchItem'" type="text" trimVal id="searchThemes"
                            [formControl]="searchAppliesToItem" (input)="searchThemes()"
                            (keydown.space)="$event.stopPropagation();">
                        <mat-icon matSuffix *ngIf="searchAppliesToItem?.value" data-control='closeSearchIcon'
                            (click)="clearSearch()">close</mat-icon>
                    </mat-form-field>
                </div>
                <div fxLayout="row" class="theme-list" fxLayoutAlign="space-between center"
                    *ngFor="let value of displayData">
                    {{ value.name}}
                    <mat-checkbox fxLayout="row" class="radio-style" (change)="onCheckBoxChange(value)" [value]="value"
                        [data-control]="" [checked]=" value['isChecked']">
                    </mat-checkbox>
                </div>
                <div *ngIf="displayData?.length===0" fxFlex="100%" fxLayoutAlign="center center">
                    <img class="no-themes-img" src="assets/Food-Not-available-png-with-bg.png"
                        alt="image-Not-available-png">
                </div>
            </ng-container>
            <!-- <mat-error *ngIf="!exportType.value">Please choose any one option</mat-error> -->

        </div>
        <div fxLayoutAlign="center center">
            <cen-loader *ngIf="loading" [type]="'page'" [name]="'cen-line-spinner'">
            </cen-loader>
        </div>
    </div>
    <!-- mat-dialog-actions -->
    <div mat-dialog-actions fxLayoutAlign="end">
        <button class="secondary-button" [mat-dialog-close]="false" data-control="export-cancel" mat-raised-button>Close
        </button>
        <button class="primary-button" data-control="export" mat-raised-button (click)="exportFile()">
            Start Export
        </button>
    </div>
</div>