<h2 class="header">Gift Cards
  <mat-icon data-control="close" (click)="onClose()" class="closeicon">close</mat-icon>
</h2>

<div fxLayout="column" [ngStyle.xs]="{'max-width':'300px','max-height':'500px'}" class="giftContainer"
  [ngStyle.sm]="{'max-width':'600px'}" [ngStyle.md]="{'max-height':'400px'}">

  <div fxLayout="column" fxLayoutAlign="center">
    <div style="margin-bottom:5px" class="cardBox" [ngClass.sm]="'cardBox_sm'" [ngStyle.xs]="{'display':'block'}">
      <div *ngFor="let giftCard of giftCardObject?.purchasedgiftCardDetails;index as i" class="gift-card-details"
        (click)="selectGiftCard(giftCard)" [attr.data-control]="'giftCard-'+i" fxLayout="column"
        [ngClass]="{active:selectedCard===giftCard?.coupenCode}">
        <img
          [src]="giftCard?.giftCardImage ? environment?.AWSImageUrl+giftCard?.giftCardImage : '../assets/giftCard1.png'"
          height="100px" width="150px" alt="Gift Card" style="align-self:center"
          onerror="this.src='../assets/giftCard1.png'">
        <p>
          <span style="font-weight: bolder;word-break:break-word">
            {{giftCard?.coupenCode}}</span><br>
          Card Balance: {{curreny?.currencySymbol + giftCard?.giftCardBalance}} <br>
          Expires on: {{giftCard?.expiryDate==null ? 'Unlimited' : giftCard?.expiryDate | date:'MMM d, y'}}
        </p>
      </div>
    </div><br>
    <button mat-raised-button data-control="apply-btn" [disabled]="!selectedCard" (click)="submit()"
      class="apply-btn">Apply</button>
  </div>
</div>