<div fxLayout="column" fxFlex="100%" *ngIf="discountList?.length > 0" class="mat-elevation-z5 card-style">
  <div fxLayout="column">
    <div fxFlex="95">
      <ng-container>
        <mat-card class="cart-card-design" data-control="cartDiscountContainer">
          <div fxLayout="row">
            <div fxLayoutAlign.lt-sm="center center" class="card-heading" data-control="cartDiscountHeading">
              <h2 class="heading">{{'CART_DISCOUNTS.AVAILABLE_OFFERS' | translate}}</h2>
            </div>
          </div>
          <mat-divider class="divider"></mat-divider>
          <div [ngClass]="(discountList?.length > 2) ? 'cart-product-list-multiple' : 'cart-product-list-one'">
            <ng-container *ngTemplateOutlet="cartProductList; context: {data: {product: discountList}}">
            </ng-container>
          </div>
        </mat-card>
      </ng-container>
    </div>
  </div>
</div>
<ng-template #cartProductList let-data="data">
  <ng-container *ngFor="let discount of data?.product; let lastOne = last; let i = index">

    <!-- AUTOMATIC DISCOUNTS -->
    <div fxLayout="column">
      <div fxLayout="row" fxFlex="30" fxLayoutAlign.lt-md="center center" class="discount-title"
        [ngClass.gt-md]="'left-margin'" [ngClass.md]="'left-margin'">
        {{discount?.title}}
      </div>
      <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="10px" class="automatic-container" *ngIf="!discount?.buy">
        <div fxLayout="column" fxLayoutAlign="center center" fxFlex="30">
          <div [matTooltip]="discount?.details?.name" class="product-box">
            <ng-container>
              <img [alt]="discount?.details?.name" [imageExtension]="imageExtension?.AVIF_FORMAT"  class="main-img" data-control="automaticProductImage"
                [actualImageURL]="(discount?.details?.image) ? (awsUrl+discount?.details?.image) : './assets/image-not-available-2.png'"
                [onErrorEvent]="{src:'./assets/image-not-available-2.png'}" />
            </ng-container>
            <div class="product-name-image" fxLayoutAlign="center end" data-control="automaticProductName">
              {{ discount?.details?.name | slice:0:7 }}{{discount?.details?.name?.length > 7 ? '...' : ''}}
            </div>

          </div>
        </div>

        <div fxLayout="row" fxFlex="45" fxLayoutAlign="start center" fxLayoutAlign.lt-md="center center"
          class="product-name">
          <div [ngClass.lt-md]="'align-center'" data-control="automaticDiscountDescription">
            <!-- percentage -->
            <span
              *ngIf="discount?.discountType?.discountTypeCode === 'PERCENTAGE' && discount?.eligibleProductId.length > 1 && discount?.maxAmount">
              {{getTranslationContent('CART_DISCOUNTS.PERCENTAGE_WITH_MAX_AND_MULTIPLE_PRODUCTS',
              [discount?.discountValue?.toString()?.split(".")[0], (discount?.maxAmount)|currency:details?.currencyCode,
              discount?.products, discount?.lastProduct]) }}
            </span>
            <span
              *ngIf="discount?.discountType?.discountTypeCode === 'PERCENTAGE' && discount?.eligibleProductId.length > 1 && !discount?.maxAmount">
              {{getTranslationContent('CART_DISCOUNTS.PERCENTAGE_WITHOUT_MAX_AND_MULTIPLE_PRODUCTS',
              [discount?.discountValue?.toString()?.split(".")[0],
              discount?.products, discount?.lastProduct]) }}
            </span>
            <span
              *ngIf="discount?.discountType?.discountTypeCode === 'PERCENTAGE' && discount?.eligibleProductId.length === 1 && discount?.maxAmount">
              {{getTranslationContent('CART_DISCOUNTS.PERCENTAGE_WITH_MAX_AND_SINGLE_PRODUCT',
              [discount?.discountValue?.toString()?.split(".")[0], (discount?.maxAmount)|currency:details?.currencyCode,
              discount?.lastProduct]) }}
            </span>
            <span
              *ngIf="discount?.discountType?.discountTypeCode === 'PERCENTAGE' && discount?.eligibleProductId.length === 1 && !discount?.maxAmount">
              {{getTranslationContent('CART_DISCOUNTS.PERCENTAGE_WITHOUT_MAX_AND_SINGLE_PRODUCT',
              [discount?.discountValue?.toString()?.split(".")[0], discount?.lastProduct]) }}
            </span>
            <!-- fixed -->
            <span
              *ngIf="discount?.discountType?.discountTypeCode === 'FIXED' && discount?.eligibleProductId.length > 1">
              {{getTranslationContent('CART_DISCOUNTS.FIXED_WITH_MULTIPLE_PRODUCTS',
              [(discount?.discountValue)|currency:details?.currencyCode,
              discount?.products, discount?.lastProduct]) }}
            </span>
            <span
              *ngIf="discount?.discountType?.discountTypeCode === 'FIXED' && discount?.eligibleProductId.length === 1">
              {{getTranslationContent('CART_DISCOUNTS.FIXED_WITH_SINGLE_PRODUCT',
              [(discount?.discountValue)|currency:details?.currencyCode, discount?.lastProduct]) }}
            </span>
          </div>
        </div>

        <div fxLayout="row" fxFlex="25" fxLayoutAlign="end center" fxLayoutAlign.lt-md="center center">
          <button mat-raised-button class="add-button" (click)="onAdd(discount, 'automatic')"
            data-control="cartAutomaticDiscountClaimButton" matTooltip="{{'CART_DISCOUNTS.CLICK_TO_CLAIM' | translate}}">
            {{'CART_DISCOUNTS.CLAIM' | translate}}
          </button>
        </div>
      </div>
    </div>

    <!-- BUY X GET Y DISCOUNTS -->
    <div *ngIf="discount?.buy" fxLayout="row" fxLayout.lt-md="column" class="automatic-container" fxLayoutGap="10px">

      <div fxLayout="row wrap" fxLayoutAlign="center center" fxFlex="30">
        <div *ngFor="let productDetail of discount?.buy; let index = index; let lastItem = last">
          <div fxLayout="row">

            <div [matTooltip]="productDetail?.name" fxLayout="column" fxLayoutAlign="center center"
              class="product-box-buyx">
              <ng-container>
                <img [imageExtension]="imageExtension?.AVIF_FORMAT"  [alt]="productDetail?.name" class="main-img" data-control="buyProductImage"
                  [actualImageURL]="(productDetail?.image) ? (awsUrl+productDetail?.image) : './assets/image-not-available-2.png'"
                  [onErrorEvent]="{src:'./assets/image-not-available-2.png'}" />
              </ng-container>
              <div class="product-name-image" fxLayoutAlign="end end" data-control="buyProductName">
                {{ productDetail?.name | slice:0:7 }}{{productDetail?.name?.length > 7 ? '...' : ''}}
              </div>
            </div>

            <div class="plus" fxLayout="row" fxLayoutAlign="center center">
              +
            </div>

          </div>
        </div>
        <div [matTooltip]="discount?.get && discount?.get[0]?.name" fxLayout="column" fxLayoutAlign="center center"
          class="product-box-buyx">
          <div class="free-product" fxLayoutAlign="end end" data-control="freeProduct"
            *ngIf="discount?.get && discount?.get[0]?.percentage === 100">
            Free
          </div>
          <div class="free-product" fxLayoutAlign="end end" data-control="freeProduct"
            *ngIf="discount?.get && discount?.get[0]?.percentage !== 100">
            {{discount?.get[0]?.percentage}}% {{'CART_DISCOUNTS.DISCOUNT' | translate}}
          </div>
          <ng-container>
            <img [imageExtension]="imageExtension?.AVIF_FORMAT" [alt]="discount?.get && discount?.get[0]?.name" class="main-img" data-control="getProductImage"
              [actualImageURL]="(discount?.get && discount?.get[0]?.image) ? (awsUrl + (discount?.get && discount?.get[0]?.image)) : './assets/image-not-available-2.png'"
              [onErrorEvent]="{src:'./assets/image-not-available-2.png'}" />
          </ng-container>
          <div class="product-name-image" fxLayoutAlign="end end" data-control="buyProductName">
            {{ discount?.get && discount?.get[0]?.name | slice:0:7 }}{{discount?.get &&
            discount?.get[0]?.name?.length > 7 ? '...' : ''}}
          </div>
        </div>
      </div>

      <div fxLayout="row" fxFlex="45" fxLayoutAlign="start center" fxLayoutAlign.lt-md="center center"
        data-control="getProductDescription" class="buyX-discount-description">
        <span [ngClass.lt-md]="'align-center'">
          <span *ngIf="discount?.get && discount?.get[0]?.percentage === 100 && discount?.isCategory">{{
            getTranslationContent('CART_DISCOUNTS.FREE_UNDER_CATEGORY',
            [discount?.get[0]?.name]) }}</span>
          <span *ngIf="discount?.get && discount?.get[0]?.percentage === 100 && !discount?.isCategory">{{
            getTranslationContent('CART_DISCOUNTS.FREE_PRODUCT',
            [discount?.get[0]?.name]) }}</span>
          <span *ngIf="discount?.get && discount?.get[0]?.percentage !== 100 && discount?.isCategory">{{
            getTranslationContent('CART_DISCOUNTS.GET_OFF_ON_CATEGORY', [discount?.get[0]?.percentage,
            discount?.get[0]?.name]) }}
          </span>
          <span *ngIf="discount?.get && discount?.get[0]?.percentage !== 100 && !discount?.isCategory">{{
            getTranslationContent('CART_DISCOUNTS.GET_OFF_ON_PRODUCT', [discount?.get[0]?.percentage,
            discount?.get[0]?.name]) }}</span>
        </span>
      </div>

      <div fxLayout="row" fxFlex="25" fxLayoutAlign="end center" fxLayoutAlign.lt-md="center center">
        <button mat-raised-button class="add-button" (click)="onAdd(discount, 'buyXgetY')" *ngIf="discount?.isCategory"
          [matTooltip]="getTranslationContent('CART_DISCOUNTS.CLAIM_CATEGORY', [discount?.get && discount?.get[0]?.name])"
          data-control="cartBuyxDiscountClaimButton" [disabled]="isLoader">
          <div *ngIf="!isLoader">
            {{'CART_DISCOUNTS.CLAIM' | translate}}
          </div>
          <cen-loader class="loader" [type]="'content'" [name]="'cen-dot-spinner'" *ngIf="isLoader">
          </cen-loader>
        </button>
        <button mat-raised-button class="add-button" (click)="onAdd(discount, 'buyXgetY')" *ngIf="!discount?.isCategory"
          [matTooltip]="getTranslationContent('CART_DISCOUNTS.CLAIM_PRODUCT', [discount?.get && discount?.get[0]?.name])"
          data-control="cartBuyxDiscountClaimButton">{{'CART_DISCOUNTS.CLAIM' | translate}}</button>
      </div>
    </div>
    <mat-divider *ngIf="!lastOne"></mat-divider>
  </ng-container>
</ng-template>
<ng-template #selectProduct>
  <p mat-dialog-title>
    <span class="products-header">Products</span>
    <mat-icon class="close-icon" data-control="closeProductsBtn" (click)="onCloseDialog();">close</mat-icon>
  </p>

  <div mat-dialog-content>
    <div *ngIf="categoryProducts.length">
      <mat-radio-group aria-labelledby="example-radio-group-label" class="radio-group" [formControl]="product">
        <mat-radio-button class="radio-button" *ngFor="let item of categoryProducts" [value]="item">
          <div *ngIf="item?.variantName">
            <span>{{item?.product?.name}} ({{item?.variantName}}) - {{(item?.price)|currency:
              details?.currencyCode}}</span>
          </div>
          <div *ngIf="!item?.variantName">
            <span>{{item?.product?.name}} - {{(item?.price)|currency: details?.currencyCode}} </span>
          </div>
        </mat-radio-button>
      </mat-radio-group>
    </div>
    <div *ngIf="!categoryProducts.length">
      No products found...
    </div>
  </div>

  <div class="button-container" fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="20px">
    <div><button mat-raised-button data-control="cancelProductsBtn" (click)="onCloseDialog();">Cancel</button></div>
    <div><button mat-raised-button color="primary" data-control="productsAddBtn" (click)="onCloseDialog(true)">
        <span>Save</span>
      </button>
    </div>
  </div>

</ng-template>