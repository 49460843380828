/**
 * Class which hold the shared theme module's constants
 */
export class SharedThemeConstants {
  /**
   * Variable which is used to restrict the product based on minimum quantity and amount
   */
  restrictionBased = {
    amount: 'Minimum purchase amount',
    quantity: 'Minimum quantity of items'
  }

  /**
   *  Variable which is used to store the type of the size of the arrowe.
   *  @type{Array}
   */
  arrowSizeType = {
    small: 'arrow-svg-icon-small', medium: 'arrow-svg-icon-medium', large: 'arrow-svg-icon-large'
  }
  /**
   *  Variable which is used to store the type of the arrow.
   *  @type{object}
   */
  arrowType = {
    left: { normal: 'left-arrow', round: 'left-arrow-slider' },
    right: { normal: 'right-arrow', round: 'right-arrow-slider' }
  };
  /**
   * Variable used to store extensions of image.
   */
  videoExtensions = ['jpg', 'png', 'jpeg', 'gif', 'webp']

  /**
   *  Variable which is used to attribute of the arrow.
   *  @type{Array}
   */
  iconAttribute = ['leftArrowElement', 'rightArrowElement'];
  /**
   * Variable which have the common display details of store
   */
  commonDetails = {
    categoryHeading: 'Product Categories',
    unAvaiableProduct: ' This product is not available in this location',
    outOfStcok: 'Out of Stock',
    noProduct: ' Sorry, No products matched for this location.',
    explore: 'Explore Other Items',
    emptyCart: 'Your cart is empty.',
    cancel: 'Cancel',
    addCart: 'Add to Cart',
    viewCart: 'View Cart',
    emptyCartText: "Looks like you haven't made your list yet",
    orderText: ' Start Ordering',
    colorText: 'Color',
    specification: 'Specification',
    wholeSale: "(for wholesale customer only)",
    dollarSign: '$',
    developedByText: 'developed by',
    priceText: 'Price',
    productText: 'Product'
  };
  /**
   * Array which have the legal policies of store
   */
  legalPolicies = [
    {
      industryType: 'Coffee', Policy: [{
        name: 'Terms and Condition',
        info: [{ name: 'Personal Information', info: ' Centizen Inc  is the licensed owner of the brand centizencoffeeshop.com and the website centizencoffeeshop.com  respects your privacy. This Privacy Policy provides succinctly the manner your data is collected and used by IRCPL on the Site. As a visitor to the Site/ Customer you are advised to please read the Privacy Policy carefully. By accessing the services provided by the Site you agree to the collection and use of your data by IRCPL in the manner provided in this Privacy Policy.' },
        { name: 'Services overview', info: 'As part of the registration process on the Site, IRCPL may collect the following personally identifiable information about you: Name including first and last name, alternate email address, mobile phone number and contact details, Postal code, Demographic profile (like your age, gender, occupation, education, address etc.) and information about the pages on the site you visit/access, the links you click on the site, the number of times you access the page and any such browsing information' },
        { name: 'Eligibility', info: 'Services of the Site would be available to only select geographies in India. Persons who are "incompetent to contract" within the meaning of the Indian Contract Act, 1872 including un-discharged insolvents etc. are not eligible to use the Site. If you are a minor i.e. under the age of 18 years but at least 13 years of age you may use the Site only under the supervision of a parent or legal guardian who agrees to be bound by these Terms of Use. If your age is below 18 years your parents or legal guardians can transact on behalf of you if they are registered users. You are prohibited from purchasing any material which is for adult consumption and the sale of which to minors is prohibited' },
        { name: 'License & Site access', info: 'IRCPL grants you a limited sub-license to access and make personal use of this site and not to download (other than page caching) or modify it, or any portion of it, except with express written consent of IRCPL. This license does not include any resale or commercial use of this site or its contents; any collection and use of any product listings, descriptions, or prices; any derivative use of this site or its contents; any downloading or copying of account information for the benefit of another merchant; or any use of data mining, robots, or similar data gathering and extraction tools. This site or any portion of this site may not be reproduced, duplicated, copied, sold, resold, visited, or otherwise exploited for any commercial purpose without express written consent of IRCPL. You may not frame or utilize framing techniques to enclose any trademark, logo, or other proprietary information (including images, text, page layout, or form) of the Site or of IRCPL and its affiliates without express written consent. You may not use any meta tags or any other "hidden text" utilizing the Site’s or IRCPL’s name or SGSPL’s name or trademarks without the express written consent of IRCPL. Any unauthorized use terminates the permission or license granted by IRCPL' }]
      }
        //   , { name: 'Privacy Policy', info: [{ name: 'Personal Information:', info: 'Centizen Inc  is the licensed owner of the brand centizencoffeeshop.com and the website centizencoffeeshop.com  respects your privacy. This Privacy Policy provides succinctly the manner your data is collected and used by IRCPL on the Site. As a visitor to the Site/ Customer you are advised to please read the Privacy Policy carefully. By accessing the services provided by the Site you agree to the collection and use of your data by IRCPL in the manner provided in this Privacy Policy.' }] },
        // {
        //   name: 'Return Policy', info: [{ name: 'Return & Refunds', info: 'We have a "no questions asked return and refund policy" which entitles all our members to return the product at the time of delivery if due to some reason they are not satisfied with the quality or freshness of the product. We will take the returned product back with us and issue a credit note for the value of the return products which will be credited to your account on the Site. This can be used to pay your subsequent shopping bills.' }]
        // }
      ]
    },
    // {
    //   industryType: 'Grocery', Policy: [{
    //     name: 'Terms and Condition',
    //     info: [{ name: 'Personal Information', info: ' Centizen Inc  is the licensed owner of the brand centizengrocery.com and the website centizengrocery.com  respects your privacy. This Privacy Policy provides succinctly the manner your data is collected and used by IRCPL on the Site. As a visitor to the Site/ Customer you are advised to please read the Privacy Policy carefully. By accessing the services provided by the Site you agree to the collection and use of your data by IRCPL in the manner provided in this Privacy Policy.' },
    //     { name: 'Services overview', info: 'As part of the registration process on the Site, IRCPL may collect the following personally identifiable information about you: Name including first and last name, alternate email address, mobile phone number and contact details, Postal code, Demographic profile (like your age, gender, occupation, education, address etc.) and information about the pages on the site you visit/access, the links you click on the site, the number of times you access the page and any such browsing information' },
    //     { name: 'Eligibility', info: 'Services of the Site would be available to only select geographies in India. Persons who are "incompetent to contract" within the meaning of the Indian Contract Act, 1872 including un-discharged insolvents etc. are not eligible to use the Site. If you are a minor i.e. under the age of 18 years but at least 13 years of age you may use the Site only under the supervision of a parent or legal guardian who agrees to be bound by these Terms of Use. If your age is below 18 years your parents or legal guardians can transact on behalf of you if they are registered users. You are prohibited from purchasing any material which is for adult consumption and the sale of which to minors is prohibited' },
    //     { name: 'License & Site access', info: 'IRCPL grants you a limited sub-license to access and make personal use of this site and not to download (other than page caching) or modify it, or any portion of it, except with express written consent of IRCPL. This license does not include any resale or commercial use of this site or its contents; any collection and use of any product listings, descriptions, or prices; any derivative use of this site or its contents; any downloading or copying of account information for the benefit of another merchant; or any use of data mining, robots, or similar data gathering and extraction tools. This site or any portion of this site may not be reproduced, duplicated, copied, sold, resold, visited, or otherwise exploited for any commercial purpose without express written consent of IRCPL. You may not frame or utilize framing techniques to enclose any trademark, logo, or other proprietary information (including images, text, page layout, or form) of the Site or of IRCPL and its affiliates without express written consent. You may not use any meta tags or any other "hidden text" utilizing the Site’s or IRCPL’s name or SGSPL’s name or trademarks without the express written consent of IRCPL. Any unauthorized use terminates the permission or license granted by IRCPL' }]
    //   }
    //     , {
    //     name: 'Privacy Policy', info: [{ name: 'Personal Information', info: ' Centizen Inc  is the licensed owner of the brand centizengrocery.com and the website centizengrocery.com  respects your privacy. This Privacy Policy provides succinctly the manner your data is collected and used by IRCPL on the Site. As a visitor to the Site/ Customer you are advised to please read the Privacy Policy carefully. By accessing the services provided by the Site you agree to the collection and use of your data by IRCPL in the manner provided in this Privacy Policy.' },
    //     { name: 'Services overview', info: 'As part of the registration process on the Site, IRCPL may collect the following personally identifiable information about you: Name including first and last name, alternate email address, mobile phone number and contact details, Postal code, Demographic profile (like your age, gender, occupation, education, address etc.) and information about the pages on the site you visit/access, the links you click on the site, the number of times you access the page and any such browsing information' },
    //     { name: 'Eligibility', info: 'Services of the Site would be available to only select geographies in India. Persons who are "incompetent to contract" within the meaning of the Indian Contract Act, 1872 including un-discharged insolvents etc. are not eligible to use the Site. If you are a minor i.e. under the age of 18 years but at least 13 years of age you may use the Site only under the supervision of a parent or legal guardian who agrees to be bound by these Terms of Use. If your age is below 18 years your parents or legal guardians can transact on behalf of you if they are registered users. You are prohibited from purchasing any material which is for adult consumption and the sale of which to minors is prohibited' },
    //     { name: 'License & Site access', info: 'IRCPL grants you a limited sub-license to access and make personal use of this site and not to download (other than page caching) or modify it, or any portion of it, except with express written consent of IRCPL. This license does not include any resale or commercial use of this site or its contents; any collection and use of any product listings, descriptions, or prices; any derivative use of this site or its contents; any downloading or copying of account information for the benefit of another merchant; or any use of data mining, robots, or similar data gathering and extraction tools. This site or any portion of this site may not be reproduced, duplicated, copied, sold, resold, visited, or otherwise exploited for any commercial purpose without express written consent of IRCPL. You may not frame or utilize framing techniques to enclose any trademark, logo, or other proprietary information (including images, text, page layout, or form) of the Site or of IRCPL and its affiliates without express written consent. You may not use any meta tags or any other "hidden text" utilizing the Site’s or IRCPL’s name or SGSPL’s name or trademarks without the express written consent of IRCPL. Any unauthorized use terminates the permission or license granted by IRCPL' }]
    //   },
    //   {
    //     name: 'Return Policy', info: [{ name: 'Return & Refunds', info: 'We have a "no questions asked return and refund policy" which entitles all our members to return the product at the time of delivery if due to some reason they are not satisfied with the quality or freshness of the product. We will take the returned product back with us and issue a credit note for the value of the return products which will be credited to your account on the Site. This can be used to pay your subsequent shopping bills.' }]
    //   }]
    // },
    // {
    //   industryType: 'Bakery', Policy: [{
    //     name: 'Terms and Condition',
    //     info: [{ name: 'Personal Information', info: ' Centizen Inc  is the licensed owner of the brand centizenbakery.com and the website centizenbakery.com  respects your privacy. This Privacy Policy provides succinctly the manner your data is collected and used by IRCPL on the Site. As a visitor to the Site/ Customer you are advised to please read the Privacy Policy carefully. By accessing the services provided by the Site you agree to the collection and use of your data by IRCPL in the manner provided in this Privacy Policy.' },
    //     { name: 'Services overview', info: 'As part of the registration process on the Site, IRCPL may collect the following personally identifiable information about you: Name including first and last name, alternate email address, mobile phone number and contact details, Postal code, Demographic profile (like your age, gender, occupation, education, address etc.) and information about the pages on the site you visit/access, the links you click on the site, the number of times you access the page and any such browsing information' },
    //     { name: 'Eligibility', info: 'Services of the Site would be available to only select geographies in India. Persons who are "incompetent to contract" within the meaning of the Indian Contract Act, 1872 including un-discharged insolvents etc. are not eligible to use the Site. If you are a minor i.e. under the age of 18 years but at least 13 years of age you may use the Site only under the supervision of a parent or legal guardian who agrees to be bound by these Terms of Use. If your age is below 18 years your parents or legal guardians can transact on behalf of you if they are registered users. You are prohibited from purchasing any material which is for adult consumption and the sale of which to minors is prohibited' },
    //     { name: 'License & Site access', info: 'IRCPL grants you a limited sub-license to access and make personal use of this site and not to download (other than page caching) or modify it, or any portion of it, except with express written consent of IRCPL. This license does not include any resale or commercial use of this site or its contents; any collection and use of any product listings, descriptions, or prices; any derivative use of this site or its contents; any downloading or copying of account information for the benefit of another merchant; or any use of data mining, robots, or similar data gathering and extraction tools. This site or any portion of this site may not be reproduced, duplicated, copied, sold, resold, visited, or otherwise exploited for any commercial purpose without express written consent of IRCPL. You may not frame or utilize framing techniques to enclose any trademark, logo, or other proprietary information (including images, text, page layout, or form) of the Site or of IRCPL and its affiliates without express written consent. You may not use any meta tags or any other "hidden text" utilizing the Site’s or IRCPL’s name or SGSPL’s name or trademarks without the express written consent of IRCPL. Any unauthorized use terminates the permission or license granted by IRCPL' }]
    //   }
    //     , { name: 'Privacy Policy', info: [{ name: 'Personal Information:', info: 'Centizen Inc  is the licensed owner of the brand centizencoffeeshop.com and the website centizencoffeeshop.com  respects your privacy. This Privacy Policy provides succinctly the manner your data is collected and used by IRCPL on the Site. As a visitor to the Site/ Customer you are advised to please read the Privacy Policy carefully. By accessing the services provided by the Site you agree to the collection and use of your data by IRCPL in the manner provided in this Privacy Policy.' }] },
    //   {
    //     name: 'Return Policy', info: [{ name: 'Return & Refunds', info: 'We have a "no questions asked return and refund policy" which entitles all our members to return the product at the time of delivery if due to some reason they are not satisfied with the quality or freshness of the product. We will take the returned product back with us and issue a credit note for the value of the return products which will be credited to your account on the Site. This can be used to pay your subsequent shopping bills.' }]
    //   }]
    // },
    // {
    //   industryType: 'Retail', Policy: [{
    //     name: 'Terms and Condition',
    //     info: [{ name: 'Personal Information', info: ' Centizen Inc  is the licensed owner of the brand centizenretail.com and the website centizenretail.com  respects your privacy. This Privacy Policy provides succinctly the manner your data is collected and used by IRCPL on the Site. As a visitor to the Site/ Customer you are advised to please read the Privacy Policy carefully. By accessing the services provided by the Site you agree to the collection and use of your data by IRCPL in the manner provided in this Privacy Policy.' },
    //     { name: 'Services overview', info: 'As part of the registration process on the Site, IRCPL may collect the following personally identifiable information about you: Name including first and last name, alternate email address, mobile phone number and contact details, Postal code, Demographic profile (like your age, gender, occupation, education, address etc.) and information about the pages on the site you visit/access, the links you click on the site, the number of times you access the page and any such browsing information' },
    //     { name: 'Eligibility', info: 'Services of the Site would be available to only select geographies in India. Persons who are "incompetent to contract" within the meaning of the Indian Contract Act, 1872 including un-discharged insolvents etc. are not eligible to use the Site. If you are a minor i.e. under the age of 18 years but at least 13 years of age you may use the Site only under the supervision of a parent or legal guardian who agrees to be bound by these Terms of Use. If your age is below 18 years your parents or legal guardians can transact on behalf of you if they are registered users. You are prohibited from purchasing any material which is for adult consumption and the sale of which to minors is prohibited' },
    //     { name: 'License & Site access', info: 'IRCPL grants you a limited sub-license to access and make personal use of this site and not to download (other than page caching) or modify it, or any portion of it, except with express written consent of IRCPL. This license does not include any resale or commercial use of this site or its contents; any collection and use of any product listings, descriptions, or prices; any derivative use of this site or its contents; any downloading or copying of account information for the benefit of another merchant; or any use of data mining, robots, or similar data gathering and extraction tools. This site or any portion of this site may not be reproduced, duplicated, copied, sold, resold, visited, or otherwise exploited for any commercial purpose without express written consent of IRCPL. You may not frame or utilize framing techniques to enclose any trademark, logo, or other proprietary information (including images, text, page layout, or form) of the Site or of IRCPL and its affiliates without express written consent. You may not use any meta tags or any other "hidden text" utilizing the Site’s or IRCPL’s name or SGSPL’s name or trademarks without the express written consent of IRCPL. Any unauthorized use terminates the permission or license granted by IRCPL' }]
    //   }
    //     , { name: 'Privacy Policy', info: [{ name: 'Personal Information:', info: 'Centizen Inc  is the licensed owner of the brand centizencoffeeshop.com and the website centizencoffeeshop.com  respects your privacy. This Privacy Policy provides succinctly the manner your data is collected and used by IRCPL on the Site. As a visitor to the Site/ Customer you are advised to please read the Privacy Policy carefully. By accessing the services provided by the Site you agree to the collection and use of your data by IRCPL in the manner provided in this Privacy Policy.' }] },
    //   {
    //     name: 'Return Policy', info: [{ name: 'Return & Refunds', info: 'We have a "no questions asked return and refund policy" which entitles all our members to return the product at the time of delivery if due to some reason they are not satisfied with the quality or freshness of the product. We will take the returned product back with us and issue a credit note for the value of the return products which will be credited to your account on the Site. This can be used to pay your subsequent shopping bills.' }]
    //   }]
    // },
  ];
  /**
   *  Variable which is used to store the type of the slider
   *  @type{Array}
   */
  sliderType = [{ name: 'Left', className: 'image-slide-right', condition: -1 },
  { name: 'Right', className: 'image-slide-left', condition: 1 }];
  /**
   *  Variable which is used to store the type of the direction to slide.
   *  @type{Array}
   */
  directionType = { left: 'Left', right: 'Right' };


}
/**
 * Class which hold the shared theme module's constants
 */
export class ThemesModuleConstants {
  navbarSections = [
    {
      "name": "Logo",
      "templateName": "logoTemplate"
    },
    {
      "name": "Location",
      "templateName": "locationTemplate"
    },
    {
      "name": "Quick Access",
      "templateName": "quickAccessTemplate"
    },
    {
      "name": "Search Bar",
      "templateName": "searchBarTemplate"
    },
    {
      "name": "Multilanguage",
      "templateName": "languageTemplate"
    },
    {
      "name": "My Account",
      "templateName": "myAccountTemplate"
    },
    {
      "name": "Contact",
      "templateName": "contactTemplate"
    },
    {
      "name": "Custom Menus",
      "templateName": "customMenuTemplate"
    },
    {
      "name": "Wishlist",
      "templateName": "wishlistTemplate"
    },
    {
      "name": "Cart",
      "templateName": "cartTemplate"
    }
  ];
  footerAlignment = [
    {
      "name": "About",
      "templateName": "about"
    },
    {
      "name": "Other Menus",
      "templateName": "otherMenus"
    },
    {
      "name": "Information",
      "templateName": "information"
    },
    {
      "name": "Contact",
      "templateName": "contact"
    }
  ];
  /**
   * Variable used to store price filter details.
   */
  digitalProductConstantDetails = {
    filterPrice: [
      { begin: 0, end: 499 },
      { begin: 500, end: 999 },
      { begin: 1000, end: 1999 },
      { begin: 2000, end: 3999 },
      { begin: 4000, end: 10000 },
    ]
  };
  /**
   * Variable used to store the cart constant details.
   * @type {object}
   */
  cartConstantDetails = {
    sku: 'Sku',
    note: 'Note',
    quantity: 'Quantity',
    amount: 'Amount',
    deposit: 'Deposit',
    discount: 'Save',
    summaryDiscount: 'Other Discount (-)',
    subscriptionDiscount: 'Coupon Discount (-)',
    tax: 'Tax',
    price: 'Order Subtotal',
    extra: 'Additional Charge',
    name: 'Name',
    email: 'Email',
    message: 'Message',
    subtotal: 'Discounted Price',
    retuned: 'Returned',
    refunded: 'Refunded',
    stockUpdated: 'Stock Updated',
    refundDetails: 'Refund Details',
    freeProductPrice: 'Price',
    addonPrice: 'Add-on Price',
    inclusiveTax: '(Inclusive of all taxes)',
    addonDetails:'Add-on Details',
    viewAddonDetails:'View Add-on Details'
  };
  /**
  * Variable used to store the subscription product constants
  */
  subscriptionProductConstant = {
    listHeading: 'Subscription Bundle List'
  }
  /**
   * variable which is used to define the SocialMediaLinks
   */
  socialMediaLinks = {
    fbUrl: "https://www.facebook.com/ZenBasket/",
    igUrl: "https://www.instagram.com/zenbasket/",
    twUrl: "https://twitter.com/zenbasket/",
    ytUrl: "https://www.youtube.com/channel/UCKxvVDVd8clV2oEHFJVY6AA"
  }
  /**
   * Variable used to store the checkout page constants
   */
  checkoutConstants = {
    contactInfoHeading: "Contact Information",
    viewFirstThreeAddress: "View First Three Address",
    shippingDetailsHeading: "Shipping Details",
    addNewAddressLabel: "Add New Address",
    billingAddressHeading: "Billing Adddress",
    paymentHeading: "Payment Method",
    deliveryLabel: "Delivery",
    summaryLabel: "Summary",
    totalProductsLabel: "Total Products",
    subTotalLabel: "Discounted Price",
    couponDiscountLabel: "Coupon Discount",
    discountLabel: "Other Discount",
    shippingLabel: "Shipping",
    taxLabel: "Tax",
    addOnsLabel: "AddOns",
    tipLabel: "Tip",
    addTipLabel: "Add Tip",
    EditTipLabel: "Edit Tip",
    holdAmountLabel: "Hold Amount",
    handlingFeeLabel: 'Handling Fee',
    additionalChargelabel: 'Additional Charge',
    orderAmountLabel: 'Order Subtotal',
    grandAmountLabel: 'Grand Amount',
    amountToPayLabel: "Amount to pay",
    applyButtonText: "Apply",
    addToWishlist: 'Add to Wishlist',
    wishListContent: 'Remove item from bag?\nAdd it to your wishlist to purchase it later!',
    customTipLabel: "Custom Tips",
    notNowLabel: "Not Now",
    applyCouponHeading: "Select Offer / Apply Coupon",
    applyStoreCreditHeading: "Apply Store Credit",
    applyGiftCardHeading: "Apply Gift card",
    contactShippingStepperLabel: "Contact & Shipping Details",
    billingPaymentStepperLabel: "Billing & Payment Details",
    emailLabel: "Email",
    phoneLabel: "Phone",
    countryCodeLabel: "Country Code",
    changeLink: "Change",
    editContactHeading: "Edit Contact",
    cancelButton: "Cancel",
    saveButton: "Save",
    removeButton: "Remove",
    moveToWishlist: "Add To Wishlist",
    confirmationHeader: 'Confirmation',
    shopappDialogContent: 'Remove item from cart?',
    dialogContent: 'Remove item from cart?\nTip: Add it to your wishlist to purchase it later!',
    termsAndConditionLabel: "Allow Terms & Conditions",
    allowMarketingLabel: "Allow Marketing",
    shippingAddressHeading: "Shipping Address",
    viewAllAddressLink: "View All Address",
    shippingMethodHeading: "Shipping & Delivery Methods",
    pickupMethodHeading: "Pickup Methods",
    makeShippingAsBillingLabel: "Make shipping address as billing address",
    makeDefaultAsBillingLabel: "Make default address as billing address",
    newBillingLabel: "New billing address",
    cashOnDeliveryLabel: "Cash on delivery",
    creditOrDebitCardLabel: "Online Payment",
    walletLabel: "Wallet",
    editAddressLabel: "Edit Address",
    fullNameLabel: "Full Name",
    countryLabel: "Country",
    stateLabel: "State",
    cityLabel: "City",
    postalCodeLabel: "Zip Code",
    addressLabel1: "Address Line1",
    addressLabel2: "Address Line2",
    addAddressLabel: "Add Address",
    saveAndDeliverButton: "Save and deliver here",
    updateAddressLabel: "Update Address",
    depositAmountLabel: "Deposit Amount",
    landmarkMaxLength: 'landmark should not exceed 60 characters',
    landmarkPattern: "Invalid landmark",
    otherAddressMaxLength: 'Address should not exceed 60 characters',
    otherAddressLinePattern: "Invalid address",
  };
  /**
  * Variable used to store the snackbar contents
  */
  snackbarContent = {
    cannotIncrease: 'You cannot increase further since there is no stock.',
    maximumLimitReached: 'You have reached your maximum order limit.',
    cannotDecrease: 'You cannot reduce further since it reached minimum order quantity.',
    minimumLimitReached: 'You have reached your minimum order limit.',
  }
  /**
   * Variable used to store banner details of store
   */
  bannerProperties = [{
    id: 1,
    name: 'Image',
  }, {
    id: 2,
    name: 'Slider',
  }, {
    id: 3,
    name: 'Video',
  }, {
    id: 4,
    name: 'Solid Color',
  }, {
    id: 5,
    name: 'Gradient Color'
  }, {
    id: 6,
    name: 'Painting Theme'
  }, {
    id: 7,
    name: 'Cafe Theme'
  },
  {
    id: 8,
    name: 'Carousel'
  },
  {
    id: 9,
    name: 'Sporting Goods Theme'
  }, {
    id: 10,
    name: 'Pets Canine'
  },
  {
    id: 11,
    name: 'Chocolate Theme Three'
  },
  {
    id: 12,
    name: 'Digital Product'
  },
  {
    id: 13,
    name: 'Women Accessories Theme'

  },
  {
    id: 14,
    name: 'Bliss Theme'
  },
  {
    id: 15,
    name: 'Painting Theme Three'
  },
  {
    id: 16,
    name: 'Radiance Theme'
  },
  {
    id: 17,
    name: 'Jewellery Theme Two'
  }
  ];
  /**
   * Variable used to store banner property types.
   * @type {array}
   */
  gridProperties = [
    {
      id: 1,
      name: 'Radiance Theme',
      count: 7
    },
    {
      id: 2,
      name: 'Tools2 Theme',
      count: 5
    }
  ];
  /**
   * Variable which is used to store all the inputs of slider
   */
  sliderInputData = {
    isManual: false,
    isHover: false,
    timeInterval: 0,
    iconType: 'round',
    iconSize: 'medium',
    iconColor: 'lightgrey',
    width: 240
  };
  addressType = ['Home', 'Office', 'Others'];
  /**
   * Variable which is used to store validators details.
   * @type{object}
   */
  validator = {
    emailValidationPattern: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,3}))$/,
    messageValidationPattern: '^[^\s].+[^\s]$',
    contactValidationPattern: /^[\(][2-9]\d{2}[\)][\s]\d{3}[\-]\d{4}$/,
    acceptOnlyCharacters: '^[-a-zA-Z]+(\\s+[-a-zA-Z]+)*$',
  };
  /**
   * Variable used to store error messages.
   * @type{object}
   */
  errorMessages = {
    "ERROR": {
      "NAME": {
        "required": "Name is required",
        "pattern": "Name accepts characters only."
      },
      "EMAIL": {
        "required": "Email is required",
        "pattern": "Please provide a valid email address",
        "emailAlreadyExists": "Email already exists"
      },
      "CONTACT": {
        "required": "Contact number is required",
        "pattern": "Contact number must contain 10 digits and first number cannot be 0 or 1",
        "minlength": "Contact number should be 10 digits long",
        "maxlength": "Contact number should be less than 12 digits."
      },
      "MESSAGE": {
        "required": "Message is required",
        "pattern": "Message doesn't accept empty spaces and special characters exclude .,?,_,%,$,!,@,#, and ,"
      },
      "PICKUP_DATE": {
        "required": "Pickup date is required",
        "emptySlots": "Slots is empty"
      },
      "PICKUP_TIME": {
        "required": "Pickup time is required",
        "emptySlots": "Slots is empty"
      }
    },
    warningHeader: "Warning!",
    failureHeader: "Failure!",
    FailureError: "Error in retrieving details",
    branchNotFound: "Store has no locations",
    websiteDetailsError: "Error in getting website details",
    reviewTitleRequired: "Review title is Required",
    reviewMessageRequired: "Review Description is Required",
    reviewTitleCharacterOnly: 'Review Title accepts only characters.',
    reviewMessageCharacterOnly: 'Review Description accepts only characters.',
    ratingsError: 'Star rating is required.',
    instructionError: 'Instructions does not accept space alone.',
    reviewTitleMaxLengthExceed: 'Review title should not exceed 200 characters.'
  };
  /**
   * Variable which holds the maximum discount restriction
   */
  maxDicountInstruction = 'As your discount value exceeds our store maximum discount usage constraint,you will be getting our maximum dsicount limit of';
  /**
   * Variable which holds the back button text in quick order products
   */
  backButton = "Back to Products";
  /**
   * Variable to store discount type values.
   * @type{object}
   */
  discountTypes = { fixedValue: 'Fixed', percentageValue: 'Percentage', percentageSymbol: '%', percentage: 'PERCENTAGE' }
  /**
   * Variable to store default currency.
   * @type{string}
   */
  defaultCurrency = '$';
  /**
   * Variable used to store the common location constants
   */
  locationObject = {
    clickForMore: "Click here for more!",
    cityLabel: "City",
    locationLabel: "Location",
    closedLabel: "Closed on",
    phoneLabel: "Phone",
    emailLabel: "Email",
    addressLabel: "Address",
    openingHoursLabel: "Opening Hours",
    shopClosedLabel: "Closed",
    workingHoursLabel: "Working Hours",
    closedDaysLabel: "Shop Closed On"
  }
  /**
   * Variable which store the product details
   */
  commonProductDetails = {
    outOfStock: "Out of Stock",
    notifyMe: "Notify Me!",
    filtersApplied: "Filters Applied",
    clearFilter: "Clear Filter",
    menuNotAvailable: "Not available on this time"
  }
  /**
   * Variable used to store the review rating object
   */
  reviewRatingObject = {
    verifiedRatingsText: "verified ratings",
    writeReviewText: "Write a Review",
    cancelButton: "Cancel",
    submitButton: "Submit"
  };
  /**
   * variable which is used to print number of stars.
   */
  stars: number[] = [1, 2, 3, 4, 5];
  /**
   * Variable used to store edit and delete text.
   */
  reviewOptions = {
    edit: 'Edit',
    create: 'create'
  }

  /**
   * Variable used to store related products section ids.
   */
  relatedProductsSection = {
    upsellId: "upsell",
    crosssellId: "crosssell",
    boughtTogetherId: "boughtTogether"
  }
  /**
   * Variable used to store wishlist message.
   */
  wishListMessage = "Please login to add a product to wishlist."
  /**
   * Variable used to store notifyme message.
   */
  notifyMeMessage = "Please login so that we can notify you when the product becomes available."
  /**
   * Variable used to store product stock message.
   */
  stockMessage = "The product count is greater than the available stock."
  /**
   * Variable used to display view more button message.
   */
  viewMoreButton = "View More"
  /**
   * Variable used to store like and dislike message.
   */
  reviewMessages = {
    like: 'Please login to like the review.',
    dislike: 'Please login to dislike the review.'
  }
  /**
   * variable which is used to store the header of the dialog.
   */
  dialogHeader = {
    areYouSure: 'Are you sure?',
    message: 'Alert',
    failure: 'Failure',
    success: 'Success'
  };
  /**
    * variable which is used to store all the messages to be displayed.
    */
  dialogMessages = {
    discountUpdate: 'Discount Update',
    claimOffer: 'Claim Offer',
    modifierUpdate: 'Modifier update',
    modifierUpdateSuccess: 'Modifiers updated successfully',
    apiNotCalled: 'Api Not called',
    restoreFailed: 'Failed to restore stock',
    stockRestored: 'Stock updated successfully.',
    alreadyDeleted: 'This Product is already deleted',
    restoreStock: 'Are you sure you want to restore stock for this product?',
    sureToClose: 'Are You Sure You want to close?',
    productLevel: 'productLevel',
    statusChange: 'statusChange',
    changeStatus: 'Are you sure to change the status?',
    successfullReturn: "Return status updated successfully",
    failedreturnStatus: 'Failed to update return status',
    returnFailure: 'Failure in return',
    successfullRequest: 'Return request for product send successfully',
    noDiscount: 'Discounts are unavailable for this product!',
    nonReturnableProduct: 'This is a non-returnable Product. Are you sure you want to return it?',
    unsavedChanges: "You have unsaved changes. Are you sure you want to leave this page?",
    shopClosedMessage: "Shop is currently closed. Please try when shop is open.",
    noCheckoutOption: "Shipping settings has not enabled.",
    checkoutLocationChange: 'You cannot change your location during payment',
    removeConfirmation: "Are you sure you want to remove this product from cart?",
    removeConfirmationMessage: "Are you sure you want to remove this product?",
    contactYourAdmin: "Failed load please contact admin.",
    fileSizeExceeded: 'Maximum file size (2 MB) has been exceeded.  Please upload a smaller size file.',
    fileFormatInvalid: 'Invalid file format. Kindly check your file format',
    imageCount: 'Only five images can be uploaded.',
    noChangeAlert: 'You have no changes to save!',
    mandatoryFieldFill: 'Please fill the mandatory fields properly',
    modifierNotAvailable: 'Modifiers are unavailable for this product!',
    dialogDirtyMessage: 'You have unsaved changes. Are you sure you want to leave this page?',
    updatedSuccessfully: 'Modifiers updated successfully.',
    DiscountApplied: 'Discount updated successfully',
    productFetchFailed: 'Failed to get product details',
    subscriptionFetchFailed: 'Failed to get subscription list',
    subscriptionDetailsFetchFailed: 'Failed to get subscription details',
    haveToLogin: "Please sign up to place subscription order",
    fileFormatInvalidForImage: 'Invalid file format.Only images with "jpg" and "png" extensions are allowed.',
    failedToAddCart: 'Failed to add cart',
    failedToAddToSubsriptionCart: 'Failed to add subscription cart',
    failedToLoad: 'Failed to load data',
    subscriptionUnavailable: 'This subscription is not available for your current location',
    storeFrontLogout: 'Are you sure you want to logout?',
    deleteSubscription: 'Are you sure you want to remove this subscription from cart?',
    salesTimerFetchFailed: 'Failed to get sales countdown timer offers',
    haveToLoginPreorder: "Please sign up to place Pre-order",
  };
  /**
  * variable which is used to store the button name.
  */
  buttonText = {
    leave: 'Leave',
    stay: 'Stay',
    ok: 'Ok',
    yes: 'Yes',
    no: 'No',
    okay: 'Okay'
  };
  /**
  * variable which is used to store the button name.
  */
  dialogType = { alert: 'alert', confirmation: 'confirmation', information: 'information', success: 'success', failure: 'failure' };

  /**
   * Variable used to store the payment text
   */
  payment = 'Payment';
  /**
   * Variable used to store week days
   */
  weekDays = [
    'Sun',
    'Mon',
    'Tue',
    'Wed',
    'Thu',
    'Fri',
    'Sat'
  ];
  /**
   * Variable used to store variant out of stock message.
   */
  variantOutOfStockMessage = "The selected variant is out of stock!"
  /**
   * Variable used to store variant combination not available message.
   */
  combinationNotAvailable = "This combination is not available.Please try another combination";
  /**
   * Variable which is store the all the time zones
   */
  TimeZones = [
    { label: '(GMT-12:00) International Date Line West', value: 'Etc/GMT+12' },
    { label: '(GMT-11:00) Midway Island, Samoa', value: 'Pacific/Midway' },
    { label: '(GMT-10:00) Hawaii', value: 'Pacific/Honolulu' },
    { label: '(GMT-09:00) Alaska', value: 'US/Alaska' },
    {
      label: '(GMT-08:00) Pacific Time (US & Canada)',
      value: 'America/Los_Angeles',
    },
    { label: '(GMT-08:00) Tijuana, Baja California', value: 'America/Tijuana' },
    { label: '(GMT-07:00) Arizona', value: 'US/Arizona' },
    {
      label: '(GMT-07:00) Chihuahua, La Paz, Mazatlan',
      value: 'America/Chihuahua',
    },
    { label: '(GMT-07:00) Mountain Time (US & Canada)', value: 'US/Mountain' },
    { label: '(GMT-06:00) Central America', value: 'America/Managua' },
    { label: '(GMT-06:00) Central Time (US & Canada)', value: 'US/Central' },
    {
      label: '(GMT-06:00) Guadalajara, Mexico City, Monterrey',
      value: 'America/Mexico_City',
    },
    { label: '(GMT-06:00) Saskatchewan', value: 'Canada/Saskatchewan' },
    {
      label: '(GMT-05:00) Bogota, Lima, Quito, Rio Branco',
      value: 'America/Bogota',
    },
    { label: '(GMT-05:00) Eastern Time (US & Canada)', value: 'US/Eastern' },
    { label: '(GMT-05:00) Indiana (East)', value: 'US/East-Indiana' },
    { label: '(GMT-04:00) Atlantic Time (Canada)', value: 'Canada/Atlantic' },
    { label: '(GMT-04:00) Caracas, La Paz', value: 'America/Caracas' },
    { label: '(GMT-04:00) Manaus', value: 'America/Manaus' },
    { label: '(GMT-04:00) Santiago', value: 'America/Santiago' },
    { label: '(GMT-03:30) Newfoundland', value: 'Canada/Newfoundland' },
    { label: '(GMT-03:00) Brasilia', value: 'America/Sao_Paulo' },
    {
      label: '(GMT-03:00) Buenos Aires, Georgetown',
      value: 'America/Argentina/Buenos_Aires',
    },
    { label: '(GMT-03:00) Greenland', value: 'America/Godthab' },
    { label: '(GMT-03:00) Montevideo', value: 'America/Montevideo' },
    { label: '(GMT-02:00) Mid-Atlantic', value: 'America/Noronha' },
    { label: '(GMT-01:00) Cape Verde Is.', value: 'Atlantic/Cape_Verde' },
    { label: '(GMT-01:00) Azores', value: 'Atlantic/Azores' },
    {
      label: '(GMT+00:00) Casablanca, Monrovia, Reykjavik',
      value: 'Africa/Casablanca',
    },
    {
      label:
        '(GMT+00:00) Greenwich Mean Time : Dublin, Edinburgh, Lisbon, London',
      value: 'Etc/Greenwich',
    },
    {
      label: '(GMT+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna',
      value: 'Europe/Amsterdam',
    },
    {
      label: '(GMT+01:00) Belgrade, Bratislava, Budapest, Ljubljana, Prague',
      value: 'Europe/Belgrade',
    },
    {
      label: '(GMT+01:00) Brussels, Copenhagen, Madrid, Paris',
      value: 'Europe/Brussels',
    },
    {
      label: '(GMT+01:00) Sarajevo, Skopje, Warsaw, Zagreb',
      value: 'Europe/Sarajevo',
    },
    { label: '(GMT+01:00) West Central Africa', value: 'Africa/Lagos' },
    { label: '(GMT+02:00) Amman', value: 'Asia/Amman' },
    {
      label: '(GMT+02:00) Athens, Bucharest, Istanbul',
      value: 'Europe/Athens',
    },
    { label: '(GMT+02:00) Beirut', value: 'Asia/Beirut' },
    { label: '(GMT+02:00) Cairo', value: 'Africa/Cairo' },
    { label: '(GMT+02:00) Harare, Pretoria', value: 'Africa/Harare' },
    {
      label: '(GMT+02:00) Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius',
      value: 'Europe/Helsinki',
    },
    { label: '(GMT+02:00) Jerusalem', value: 'Asia/Jerusalem' },
    { label: '(GMT+02:00) Minsk', value: 'Europe/Minsk' },
    { label: '(GMT+02:00) Windhoek', value: 'Africa/Windhoek' },
    { label: '(GMT+03:00) Kuwait, Riyadh, Baghdad', value: 'Asia/Kuwait' },
    {
      label: '(GMT+03:00) Moscow, St. Petersburg, Volgograd',
      value: 'Europe/Moscow',
    },
    { label: '(GMT+03:00) Nairobi', value: 'Africa/Nairobi' },
    { label: '(GMT+03:00) Tbilisi', value: 'Asia/Tbilisi' },
    { label: '(GMT+03:30) Tehran', value: 'Asia/Tehran' },
    { label: '(GMT+04:00) Abu Dhabi, Muscat', value: 'Asia/Muscat' },
    { label: '(GMT+04:00) Baku', value: 'Asia/Baku' },
    { label: '(GMT+04:00) Yerevan', value: 'Asia/Yerevan' },
    { label: '(GMT+04:30) Kabul', value: 'Asia/Kabul' },
    { label: '(GMT+05:00) Yekaterinburg', value: 'Asia/Yekaterinburg' },
    {
      label: '(GMT+05:00) Islamabad, Karachi, Tashkent',
      value: 'Asia/Karachi',
    },
    {
      label: '(GMT+05:30) Chennai, Kolkata, Mumbai, New Delhi',
      value: 'Asia/Calcutta',
    },
    { label: '(GMT+05:30) Sri Jayawardenapura', value: 'Asia/Calcutta' },
    { label: '(GMT+05:45) Kathmandu', value: 'Asia/Katmandu' },
    { label: '(GMT+06:00) Almaty, Novosibirsk', value: 'Asia/Almaty' },
    { label: '(GMT+06:00) Astana, Dhaka', value: 'Asia/Dhaka' },
    { label: '(GMT+06:30) Yangon (Rangoon)', value: 'Asia/Rangoon' },
    { label: '(GMT+07:00) Bangkok, Hanoi, Jakarta', value: 'Asia/Bangkok' },
    { label: '(GMT+07:00) Krasnoyarsk', value: 'Asia/Krasnoyarsk' },
    {
      label: '(GMT+08:00) Beijing, Chongqing, Hong Kong, Urumqi',
      value: 'Asia/Hong_Kong',
    },
    {
      label: '(GMT+08:00) Kuala Lumpur, Singapore',
      value: 'Asia/Kuala_Lumpur',
    },
    { label: '(GMT+08:00) Irkutsk, Ulaan Bataar', value: 'Asia/Irkutsk' },
    { label: '(GMT+08:00) Perth', value: 'Australia/Perth' },
    { label: '(GMT+08:00) Taipei', value: 'Asia/Taipei' },
    { label: '(GMT+09:00) Osaka, Sapporo, Tokyo', value: 'Asia/Tokyo' },
    { label: '(GMT+09:00) Seoul', value: 'Asia/Seoul' },
    { label: '(GMT+09:00) Yakutsk', value: 'Asia/Yakutsk' },
    { label: '(GMT+09:30) Adelaide', value: 'Australia/Adelaide' },
    { label: '(GMT+09:30) Darwin', value: 'Australia/Darwin' },
    { label: '(GMT+10:00) Brisbane', value: 'Australia/Brisbane' },
    {
      label: '(GMT+10:00) Canberra, Melbourne, Sydney',
      value: 'Australia/Canberra',
    },
    { label: '(GMT+10:00) Hobart', value: 'Australia/Hobart' },
    { label: '(GMT+10:00) Guam, Port Moresby', value: 'Pacific/Guam' },
    { label: '(GMT+10:00) Vladivostok', value: 'Asia/Vladivostok' },
    {
      label: '(GMT+11:00) Magadan, Solomon Is., New Caledonia',
      value: 'Asia/Magadan',
    },
    { label: '(GMT+12:00) Auckland, Wellington', value: 'Pacific/Auckland' },
    {
      label: '(GMT+12:00) Fiji, Kamchatka, Marshall Is.',
      value: 'Pacific/Fiji',
    },
    { label: "(GMT+13:00) Nuku'alofa", value: 'Pacific/Tongatapu' },
  ];
  /**
   * Variable used to store image maximum size.
   */
  maxFileSize = 31457280;
  /**
   * Variable used to store menu timing availablity checking industry list.
   * @type {obj}
   */
  menuTimingAvailablityCheckingIndustryList = ['bakery', 'coffee', 'restaurants'];
  /**
   * Variable which is used to store the role code of guest user
   */
  ROLECODE = { GUEST: 'GUEST' };
  /**
   * Variable which is used to store the return request status
   */
  returnrequestStatus = {
    pending: 'Pending', approved: 'Approved', declined: 'Declined', returned: 'Returned', returnedCode: 'RETURNED', partiallyReturnedCode: 'PARTIALLY_RETURNED', partiallyReturned: 'Partially returned'
  };
  /**
   * Variable used to store order status
   */
  orderstatus = {
    delivered: 'Delivered', pickedUp: 'Picked up', partiallyReturned: 'Partially returned', processing: 'Processing', returned: 'Returned'
  };
  /**
  * Variable used to store payment status
  */
  paymentStatus = {
    authorized: 'Authorized'
  }
  /**
   * Variable used to store banner details of store
   */
  imageBannerProperties = [{
    id: 1,
    name: 'Image',
  }, {
    id: 2,
    name: 'Solid Color',
  }, {
    id: 3,
    name: 'Gradient Color'
  }];
  /**
   * Variable used to store theme code details.
   */
  themeCode = {
    desineighbor: 'DESINEIGHBOR',
    smallScaleShop: 'SMALLSCALESHOP'
  };
  /**
  * Variable used to store the file format details.
  */
  fileFormat = ['jpg', 'png'];
  /**
   * Variable used to hold font style values.
   */
  fontStyleValues = {
    bold: 'B',
    italic: 'I',
    underline: 'U'
  }
  hoverAnimation = {
    hover1: 'hoverStyle1',
    hover2: 'hoverStyle2',
    hover3: 'hoverStyle3',
  }
  borderPositionValues = {
    top: 't',
    bottom: 'b',
    left: 'l',
    right: 'r'
  };
  /**
 * Variable used to show button position types
 * @type {Array}
 */
  buttonPositionOptions = [
    {
      "name": "Top",
      "value": "top"
    },
    {
      "name": "Bottom",
      "value": "bottom"
    }
  ];
  /**
 * Variable used to show button text position types
 * @type {Array}
 */
  buttonTextPosition = [
    {
      "name": "Left",
      "value": "left"
    },
    {
      "name": "Center",
      "value": "center"
    },
    {
      "name": "Right",
      "value": "right"
    }
  ];
  /**
   * Variable used to show button animation types
   * @type {Array}
   */
  buttonAnimation = [
    {
      "name": "None",
      "value": "none"
    },
    {
      "name": "Animation Type 1",
      "value": "animationType1"
    }
  ];
  /**
   * Variable used to show icon types
   * @type {Array}
   */
  iconType = [
    {
      "name": "Arrows",
      "value": "normal"
    },
    {
      "name": "Rounded Arrows",
      "value": "round"
    }
  ];
  /**
 * Variable used to show slider types
 * @type {Array}
 */
  sliderType = [
    {
      "name": "Slider",
      "value": "slider"
    },
    {
      "name": "Without slider",
      "value": "withoutSlider"
    }

  ];
  /**
 * Variable used to show image types
 * @type {Array}
 */
  imageSize = [
    {
      "name": "Cover",
      "value": "cover"
    },
    {
      "name": "Fill (100% 100%)",
      "value": "100% 100%"
    }
  ];
  /**
   * Variable used to show automatic types
   * @type {Array}
   */
  automaticType = [
    {
      "name": "Manual Slider",
      "value": "manualSlider"
    },
    {
      "name": "With Seconds",
      "value": "withSeconds"
    },
    {
      "name": "Without Seconds",
      "value": "withoutSeconds"
    }
  ];
  backgroundType = [
    {
      "name": "Background Image",
      "value": "backgroundImage"
    },
    {
      "name": "Solid Color",
      "value": "solidColor"
    },
    {
      "name": "Gradient Color",
      "value": "grdientColor"
    }
  ];
  colorPosition = [
    {
      "name": "Top to Bottom",
      "value": "bottom"
    },
    {
      "name": "Bottom to Top",
      "value": "top"
    },
    {
      "name": "Left to Right",
      "value": "right"
    },
    {
      "name": "Right to Left",
      "value": "left"
    },
  ]
  alignmentStyle =
  {
    topCenter: {
      'position': 'absolute',
      'width': '100%',
      'height': '100%',
      'display': 'flex',
      'align-items': 'start',
      'justify-content': 'center',
      'z-index' : '2',
      'pointer-events': 'none',
      'top' : '0px'
    },
    topRight: {
      'position': 'absolute',
      'width': '100%',
      'height': '100%',
      'display': 'flex',
      'align-items': 'start',
      'justify-content': 'end',
      'z-index' : '2',
      'pointer-events':'none',
      'top' : '0px'
    },
    topLeft: {
      'position': 'absolute',
      'width': '100%',
      'height': '100%',
      'display': 'flex',
      'align-items': 'start',
      'justify-content': 'start',
      'z-index': '2',
      'pointer-events':'none',
      'top' : '0px'
    },
    middleCenter: {
      'position': 'absolute',
      'width': '100%',
      'height': '100%',
      'display': 'flex',
      'align-items': 'center',
      'justify-content': 'center',
      'z-index' : '2',
      'pointer-events':'none',
      'top' : '0px'
    },
    middleRight: {
      'position': 'absolute',
      'width': '100%',
      'height': '100%',
      'display': 'flex',
      'align-items': 'center',
      'justify-content': 'end',
      'z-index' : '2',
      'pointer-events':'none',
      'top' : '0px'
    },
    middleLeft: {
      'position': 'absolute',
      'width': '100%',
      'height': '100%',
      'display': 'flex',
      'align-items': 'center',
      'justify-content': 'start',
      'z-index' : '2',
      'pointer-events':'none',
      'top' : '0px'
    },
    bottomCenter: {
      'position': 'absolute',
      'width': '100%',
      'height': '100%',
      'display': 'flex',
      'align-items': 'end',
      'justify-content': 'center',
      'z-index' : '2',
      'pointer-events':'none',
      'top' : '0px'
    },
    bottomRight: {
      'position': 'absolute',
      'width': '100%',
      'height': '100%',
      'display': 'flex',
      'align-items': 'end',
      'justify-content': 'end',
      'z-index' : '2',
      'top' : '0px',
      'pointer-events':'none',
    },
    bottomLeft: {
      'position': 'absolute',
      'width': '100%',
      'height': '100%',
      'display': 'flex',
      'align-items': 'end',
      'justify-content': 'start',
      'z-index' : '2',
      'pointer-events':'none',
      'top' : '0px'
    }
  };
  //prem
  snackBarType = {
    SUCCESS: { type: 'success', panelClass: 'success-bar', icon: 'done' },
    ERROR: { type: 'error', panelClass: 'error-bar', icon: 'error' },
    INFO: { type: 'info', panelClass: 'info-bar', icon: 'info' },
    WARNING: { type: 'warning', panelClass: 'warning-bar', icon: 'warning' },
    DEFAULT: { type: 'default', panelClass: 'default-bar', icon: 'remove_circle' },
  }
  snackBarPosition = {
    TopRight: { hPosition: 'right', vPosition: 'top' },
    TopCenter: { hPosition: 'center', vPosition: 'top' },
    TopLeft: { hPosition: 'left', vPosition: 'top' },
    BottomRight: { hPosition: 'right', vPosition: 'bottom' },
    BottomCenter: { hPosition: 'center', vPosition: 'bottom' },
    BottomLeft: { hPosition: 'left', vPosition: 'bottom' },
  }
  snackBarButtonText = {
    cancel: 'cancel',
    okay: 'Okay',
    yes: 'Yes',
    no: 'No'
  }
    /**
   * Variable used to store font families.
   */
    fontFamily = [
      {
        "name": "Times New Roman",
        "value": "'Times New Roman',serif"
      },
      {
        "name": "Heebo",
        "value": "'Heebo',sans-serif"
      },
      {
        "name": "Georgia",
        "value": "'Georgia',serif"
      },
      {
        "name": "Garamond",
        "value": "'Garamond',serif"
      },
      {
        "name": "Arial",
        "value": "'Arial',sans-serif"
      },
      {
        "name": "Lato",
        "value": "'Lato',sans-serif"
      },
      {
        "name": "Verdana",
        "value": "'Verdana',sans-serif"
      },
      {
        "name": "Helvetica",
        "value": "'Helvetica',sans-serif"
      },
      {
        "name": "Roboto",
        "value": "'Roboto',sans-serif"
      },
      {
        "name": "Roboto Condensed",
        "value": "'Roboto Condensed',sans-serif"
      },
      {
        "name": "Courier New",
        "value": "'Courier New',serif"
      },
      {
        "name": "Lucida Console",
        "value": "'Lucida Console',monospace"
      },
      {
        "name": "Monaco",
        "value": "'Monaco',monospace"
      },
      {
        "name": "Brush Script MT",
        "value": "'Brush Script MT',cursive"
      },
      {
        "name": "Lucida Handwriting",
        "value": "'Lucida Handwriting',cursive"
      },
      {
        "name": "Papyrus",
        "value": "'Papyrus',fantasy"
      },
      {
        "name": "Copperplate",
        "value": "'Copperplate',fantasy"
      },
      {
        "name": "Trebuchet MS",
        "value": "'Trebuchet MS',sans-serif"
      },
      {
        "name": "Tahoma",
        "value": "'Tahoma',sans-serif"
      },
      {
        "name": " Sofia",
        "value": "'Sofia',sans-serif"
      },
      {
        "name": "Montserrat",
        "value": "'Montserrat',sans-serif"
      },
      {
        "name": "SourceSansPro-It",
        "value": "'SourceSansPro-It',sans-serif"
      },
      {
        "name": "Jost",
        "value": "'Jost', sans-serif"
      },
      {
        "name": "Palatino Linotype",
        "value": "'Palatino Linotype',serif"
      },
      {
        "name": "Playfair Display",
        "value": "'Playfair Display',serif"
      },
      {
        "name": "Archivo",
        "value": "'Archivo', sans-serif"
      },
      {
        "name": "Lora",
        "value": "'Lora', serif"
      },
      {
        "name": "Raleway",
        "value": "'Raleway', sans-serif"
      },
      {
        "name": "Tangerine",
        "value": "'Tangerine', cursive"
      },
      {
        "name": "Poppins",
        "value": "'Poppins', sans-serif"
      },
      {
        "name": "Inter",
        "value": "'Inter', sans-serif"
      },
      {
        "name": "Instrument Sans",
        "value": "'Instrument Sans', sans-serif"
      },
      {
        "name": "AR One Sans",
        "value": "'AR One Sans', sans-serif"
      },
      {
        "name": "ABeeZee",
        "value": "'ABeeZee', sans-serif"
      },
      {
        "name": "Nunito",
        "value": "'Nunito', sans-serif"
      },
      {
        "name": "Dancing Script",
        "value": "'Dancing Script', serif"
      },
      {
        "name": "Press Start 2P",
        "value": "'Press Start 2P', cursive"
      }
    ];
}

