import { Injectable } from '@angular/core';
import { HttpRoutingService } from './httpRouting.service';
import { BehaviorSubject } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class ErrorMessageService {


  /**
   * Variable which is used to store the errorMessages.
   * @type BehaviorSubject<any>(null)
   */
  errorMessages = new BehaviorSubject<any>(null);
  /**
 * Constructor  which is used to inject services.
 * @param httpRoutingService Service to send the api request
 */
  constructor(private httpRoutingService: HttpRoutingService) { }
  /**
    * Method which is used to get the error messages from the json file
    * Store it in variable.
    */
  setErrorMessages() {
    // Used to get the error messages from the json file
    this.httpRoutingService.getJsonData('assets/formErrorMessages.json').subscribe((res: any) => {
      if (res) {
        // store the response to the subject
        // console.log('error object store = ',res);
        this.errorMessages.next(res);
      }
    });
  }

  isElementExsist(array: any[], element: string): Boolean {
    return array.includes(element);
  }
}
