export class AuthConstants {
    /**
    * Constants used for pattern validation
    */
    validator = {
        emailValidationPattern: '^[_A-Za-z0-9-\\+]+(\\.[_A-Za-z0-9-]+)*@[A-Za-z0-9-]+(\\.[A-Za-z0-9]+)*(\\.[A-Za-z]{2,})$',
        contactValidationPattern: /^[\(][2-9]\d{2}[\)][\s]\d{3}[\-]\d{4}$/,
        mobileNumberPattern: '^[2-9]{1}[0-9]{8}$',
        passwordValidationPattern: /^(?=^.{6,20}$)((?=.*\d)(?=.*\W+))(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/,
        noSpaceValidationPattern: '.*\\S.*[a-zA-z0-9]*',
        beginingSpaceValidationPattern: '\\S.*[a-zA-z0-9]*',
        exact10NumberValidationPattern: /^[2-9]\d{2}\d{3}\d{4}$/,
        acceptOnlyCharacters: '^[-a-zA-Z]+(\\s+[-a-zA-Z]+)*$',
        acceptOnlyNumbers: '^[0-9]*$',
        urlValidation: '(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?',
        amountValidationPattern: /^(\d+(?:[\.]\d+)?|\.\d+)$/,
        zipcodeValidationPattern: /^((\d{5}-\d{4})|(\d{5})|([A-Z]\d[A-Z]\s\d[A-Z]\d))$/,
        stateValidationPattern: '[A-Za-z]{2}',
        specialcharacterValidatorPattern: /^[\w\s]+$/,
        cardValidationPattern: '[0-9]{13,16}',
        cvvValidationPattern: '[0-9]{3,4}',
        acceptOnlyAlphabets: '^[a-zA-Z\/\s\'-().\-]+(\\s[a-zA-Z\/\s\'-().]+)*$',
        phoneNumberPattern: '^\\d{3}[\\-]\\d{3}[\\-]\\d{4}$',
        numbersFromOne: '^[1-9]*$',
        acceptIntegerWithoutSymbols: /^[0-9]\d*$/,
        acceptsAlphabetsWithSpecialCharacters: '^[a-z._%+-]+\\.[a-z.-]+\\.[a-z]{2,4}$',
        spaceValidationPattern: '.*\\S.*[a-zA-z0-9]*',
        messageValidationPattern: '^[^\s].+[^\s]$',
        pricePattern: '[0-9]+(\\.[0-9][0-9]?)?',
        acceptThreeDecimalPointPattern: '^[0-9]+(?:\.[0-9]{1,3})?$',
        maxReturnDaysPattern: '[0-9]{0,3}',
        // addressValidationPattern: '^[-a-zA-Z0-9,.]+(\\s+[-a-zA-Z0-9.,]+)*$',
        addressValidationPattern: '^[-a-zA-Z0-9,.\'@#&()"/]+(\\s+[-a-zA-Z0-9.,\'@#&()"/]+)*$',
        // DoorNoValidationPattern: '^[-a-zA-Z0-9/,]+(\\s+[-a-zA-Z0-9/,]+)*$',
        DoorNoValidationPattern: '^[-a-zA-Z0-9,.\'@#&()"/]+(\\s+[-a-zA-Z0-9.,\'@#&()"/]+)*$',
        categoryAndSubCategoryName: /^[A-Z@~`!@#$%^&*()_=+\\\\';: \"\\/?>.<,-]*$/i,
        acceptNumbersFromOne: '^[1-9]+([0-9]*$)',
        latitudePattern: /^(\+|-)?(?:90(?:(?:\.0{1,6})?)|(?:[0-9]|[1-8][0-9])(?:(?:\.[0-9]{1,6})?))$/,
        longitudePattern: /^(\+|-)?(?:180(?:(?:\.0{1,6})?)|(?:[0-9]|[1-9][0-9]|1[0-7][0-9])(?:(?:\.[0-9]{1,6})?))$/,
        zipcodesValidationPattern: /^\d{5}(?:-\d{4})?(?:,\s*\d{5}(?:-\d{4})?)+$/,
        pricePatterns: '[1-9]+(\\.[0-9][0-9]?)?',
        contactValidationPatternonlyNumeric: /^[0-9]+$/,
        storefrontpasswordValidationPattern: /^(?=^.{6,}$)(?=.*?\d)(?=.*?[^a-zA-Z0-9]).*$/,
        commonChangePasswordPattern: /^(?=^.{6,20}$)(?=.*\d)(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/
    }
    /**
    * Constants used for show the error messages
    */
    errorMessage = {

        TIMEEXPIRED: "OTP Time is expired",
        LIMITEXCEEDED: "OTP Limit exceeded",

        adminVerificationSucess: "A registration email has been sent to your email address. Check your inbox or spam folder and verify your account",
        mailVerificationFailed: "Your account could not be verified. Kindly contact admin to verify your account",
        "name_required": "A name is required",
        "name_pattern": "Name accept characters only",
        "first_name_required": "The first name is required",
        "first_name_pattern": "First name accepts characters only",
        "last_name_required": "The last name is required",
        "last_name_pattern": "Last name accepts characters only",
        "email_required": "Email is required",
        "email_pattern": "Please provide a valid email address",
        "emailAlreadyExists": "Email already exists",
        "contact_required": "A contact number is required",
        "contact_pattern": "The contact number must contain 10 digits, and the first number cannot be 0 or 1",
        "minlength": "The contact number should be 10 digits long",
        "maxlength": "The contact number should be less than 12 digits",
        "message_required": "A message is required",
        "message_pattern": "Message doesn't accept empty spaces and special characters exclude .,?,_,%,$,!,@,#, and ,",
        // "banner_text_required": "Banner Text is required",
        // "button_text_required": "Button Text is required",
        // "redirect_to_required": "Page to redirect is required",
        // "height_required": "Image height is required",
        // "time_interval_required": "Time interval is required",
        "failureHeader": "Failure",
        "successHeader": "Success",
        "registrationFailureHeader": "Registration Failed",
        "alertHeader": "Alert",
        "confirmationHeader": "Confirmation",
        "REQUEST_FAILED": "Please try again after some time",
        "mandatory_feild_required": "Please fill out the mandatory fields properly",
        "mandatory_field_Properly": "Please fill out the mandatory fields properly",
        // maxFileCount: "Please contact admin to add more than {{count}} files",
        deactivateSuccess: 'The account was deactivated successfully',
        unsavedChanges: "You have unsaved changes. Are you sure you want to leave this page?",
        // checkConnection: "Check your internet connection",
        invalidCredential: "Invalid username or password, please try again",
        inactiveAccount: "Your account is not active at this time.Please contact Admin to activate your account",
        fieldRequired: "This field is required",
        // invalidField: "This field is invalid",
        // invalidName: "Please enter a valid name",
        invalidEmail: "Please enter a valid email",
        invalidEmailOrPhone: "Email or phone number is invalid",
        emailOrPhoneRequired: "An email or phone number is required",
        incorrectPassword: "The password you entered is incorrect. Please try again",
        // incorrectPasswordLength: "Password should contain 8 characters!",
        invalidPhoneNumber: "Please enter a valid phone number",
        emailExists: "Email already exists",
        emailNotExist: "Email does not exist. Please provide a registered email",
        uploadSuccess: "Details uploaded successfully",
        uploadFailure: "Failed to upload the details. Please check the entered data",
        passwordMismatch: "The password does not match",
        // noRecordsFound: "No Records Found!",
        // userSuccess: "User added successfully!",
        // dataSuccess: "Data added successfully!",
        websiteSettingError: 'Error in getting store details',
        // dataSaved: "Data saved successfully!",
        // userUpdate: "User updated successfully!",
        // dataUpdate: "Data updated successfully!",
        cancel: "Are you sure you want to cancel?",
        delete: "Are you sure you want to delete?",
        passwordChanged: "Password has been changed successfully!",
        updatePasswordError: "Error in updating password. Please try again later",
        passwordRequired: "Password is required",
        charactersOnly: "Please enter only characters",
        // specialCharactersOnly: "Please enter only special characters",
        // setTheme: 'Are you sure to set the theme to your store industry?',
        // setThemeSuccess: 'Theme set successfully!',
        // setThemeFailed: 'Setting the theme failed. Please try again!',
        // dataDelete: "Data deleted successfully!",
        registerSucess: "Your account has been created successfully. Kindly check your email to confirm your registration.",
        shopappRegisterEmailExists: "Failed to create account, Email already exists",
        otpSucess: "Your account has been registered successfully.",
        adminRegisterSuccess: "Your account has been created successfully. Kindly contact admin to verify your account",
        // fetchAdditionalFields: 'Failed to fetch Addtional details',
        // fetchsmsVerification: 'Failed to fetch Website settings details',
        successWithMailFailed: "Your account has been created successfully. Kindly contact admin to verify your account.",
        resetPasswordSuccess: "The password has been reset successfully",
        // oldPasswordMismatch: "Password given does not match your current password",
        UNSECCESSFULL: "You have made three unsuccessful attempts. Please generate a new OTP",
        // "INDUSTRYTYPE": "Industry type is required",
        // "SEODESCRIPTION": "SEO Description is required",
        "TokenEXPIRED": "Your token has expired. Please contact admin",
        "tokenSuccess": "Your account has been verified successfully.",
        "RECAPTHAFAILURE": "A suspicious user was detected",
        "SOMETHINGWENTWRONG": "Oops! Something went wrong",
        SITEKEYMISSINGHEADER: "Oops! The site key seems missing.Please try again after some time",
        "ERROR": {
            "otpRequired": "OTP is required",
            "invalidOtp": "OTP is invalid. Please try again",
            // "TAX_ID": {
            //     "required": "Tax Id is required",
            //     "pattern": "Tax Id accepts number only.",
            //     "minlength": "Tax Id should be 9 characters long"
            // },
            // "COMPANY_NAME": {
            //     "required": "Company Name is required",
            //     "pattern": "Company Name accepts characters only."
            // },
            "FIRST_NAME": {
                "required": "First name is required",
                "pattern": "First name accepts characters only",
                "maxlength": "First name should not exceed 40 characters"
            },
            "LAST_NAME": {
                "required": "Last name is required",
                "pattern": "Last name accepts the characters only",
                "maxlength": "Last name should not exceed 40 characters"
            },
            "GENDER": {
                "required": "Gender is required",
            },
            "DATE_OF_BIRTH": {
                "required": "Date of birth is required",
            },
            "EMAIL": {
                "required": "Email is required",
                "pattern": "Email is invalid",
                "emailAlreadyExists": "Email already exists"
            },
            "ADMIN_PASSWORD_CHANGE": {
                "required": "Password is required",
                "pattern": "Password should include at least one number, one lowercase letter, one uppercase letter, one special character and between 6 and 20 characters in length",
                "passwordMatched": "The new password cannot be the same as the old password"
            },
            "PASSWORD": {
                "wrong_old_pwd": "The old password you entered does not match the current one",
                "changed_failure": "Change password failed",
                "passwordMatch": "The old password does not match",
                "required": "Password is required",
                "pattern": "Password should include at least one number, one lowercase letter, one uppercase letter, one special character and between 6 and 20 characters in length",
                "oldNewPasswordMatch": "Your old password and new password should not be the same",
                "minlength": " Password should be 6 characters long",
                "maxlength": " Password should be less than 20 characters",
                "nameIsForbidden": "You can't use your old password, so please enter a new password"
            },
            // "NEW_PASSWORD": {
            //     "required": "New Password is required",
            //     "pattern": " Password should have at least one number, lowercase, uppercase, special character and minimum length of 6 and maximum length of 20",
            //     "oldNewPasswordMatch": "Your old password and new password should not be same",
            //     "minlength": "New Password should be 6 characters long",
            //     "maxlength": "New Password should be less than 20 characters.",
            //     "nameIsForbidden": "You can't able to use your old password so please enter new password"
            // },
            // "CONFIRM_PASSWORD": {
            //     "required": "Confirm Password is required",
            //     "pattern": " Password should have at least one number, lowercase, uppercase and minimum length of 6",
            //     "passwordMismatch": "Your password and confirm password is not same",
            //     "minlength": "Confirm Password should be 6 characters long",
            //     "maxlength": "Confirm Password should be less than 20 characters."
            // },
            "CONTACT": {
                "required": "Contact Number is required",
                "pattern": "First Number cannot be 0 or 1",
                "minlength": "The contact number should be 10 digits long",
                "maxlength": "The contact number should be less than 12 digits",
                "contactNumberAlreadyExists": "Contact Number Already Exists"
            },
            "COUNTRYCODE": {
                "required": "Country code is required",
            },
            "ZIPCODE": {
                "required": "A zip code is required",
                "pattern": "Invalid zip code",
                "deliveryAddressError": "The zip code must be close to the selected location",
                "USZipCode": "Invalid zip code",
                "INZipCode": "A zip code must be 6 digits long"
            },
            "CITY": {
                "required": "City is required",
                "pattern": "City accepts only characters",
                "deliveryAddressError": "The city must be close to the selected location"
            },
            "STATE": {
                "required": "State is required",
                "pattern": "The state accepts only two characters",
                "deliveryAddressError": "The state must be close to the selected location"
            },
            "COUNTRY": {
                "required": "Country is required",
                "pattern": "Country accepts only 2 characters",
                "deliveryAddressError": "The country must be close to the selected location"
            },
            "COUNTRY_CODE": {
                "required": "A country code is required"
            },
            // "AMOUNT_RANGE": {
            //     "required": "Amount is required",
            //     "min": "Amount value should be greater than 0",
            //     "pattern": "Purchase value should not be zero",
            //     "amountrange": "Purchase value must not be less than the offer amount",
            //     "maxamountrange": "Amount range must not be less than Max amount"
            // },
            "AMOUNT": {
                "required": "Amount is required",
                "min": "The amount value should be at least 1",
                "pattern": "Amount accepts only numeric values"
            },
            // "COUNT": {
            //     "required": "Count is required",
            //     "min": "Count value should be atleast 1",
            //     "max": "Count value should be less than 99999",
            //     "pattern": "Count accepts only numeric values."
            // },
            "STREET_NAME": {
                "required": "Address is required",
                "pattern": "The address does not accept empty spaces",
                "maxlength": "The address must not exceed 50 characters"
            },
            "ADDRESS": {
                "required": "Address is required",
                "maxlength": "The address must not exceed 100 characters"
            },
            // "DOOR_NO": {
            //     "required": "A door number is required",
            //     "pattern": "Invalid door number"
            // },
            // "ADDRESS_CATEGORY": {
            //     "required": "Address category is required"
            // },
            "PERCENTAGE": {
                "required": "Percentage is required",
                "min": "The percentage value should be at least 1",
                "pattern": "Percentage accepts only numeric values",
                "percentageexceed": "The percentage does not exceed 100"
            },
            "PROMO_CODE": {
                "required": "A promo code is required",
                "promocodeMatch": "The promo code already exists"
            },
            // "VALID_FROM": {
            //     "required": "Valid from is required"
            // },
            // "VALID_TILL": {
            //     "required": "Valid till is required"
            // },
            "DESCRIPTION": {
                "required": "Description is required"
            },
            "MESSAGE": {
                "required": "A message is required",
                "pattern": "Message doesn't accept empty spaces and special characters exclude .,?,_,%,$,!,@,#, and ,"
            },
            // "LENGTH": {
            //     "required": "Length is required",
            //     "pattern": "Invalid length"
            // },
            // "HEIGHT": {
            //     "required": "Height is required",
            //     "pattern": "Invalid height"
            // },
            // "WIDTH": {
            //     "required": "Width is required",
            //     "pattern": "Invalid width"
            // },
            // "WEIGHT": {
            //     "required": "Weight is required",
            //     "pattern": "Invalid weight"
            // },
            // "DIMENSION": {
            //     "required": "Dimension is required"
            // },
            // "DISTANCE_UNIT": {
            //     "required": "Distance unit is required"
            // },
            // "MASS_UNIT": {
            //     "required": "Mass unit is required"
            // },
            // "TEMPLATE_NAME": {
            //     "required": "Template name is required",
            //     "pattern": "The template name doesn't accept empty spaces and special characters"
            // },
            // "LOCATION_TYPE": {
            //     "required": "Location type is required"
            // },
            // "LOCATION_TYPE_INSTRUCTION": {
            //     "required": "Instruction is required",
            //     "pattern": "Instruction doesn't accept empty spaces and special characters"
            // },
            "START_DATE": {
                "required": "The requested date is required"
            },
            // "POUNDS": {
            //     "required": "Pound is required",
            //     "pattern": "Invalid Pound"
            // },
            // "OUNCES": {
            //     "required": "Ounce is required",
            //     "pattern": "Invalid Ounce"
            // },
            // "FIRSTCLASSMAIL": {
            //     "required": "First Class mail type is required"
            // }
        },
    }
    /**
     * role id details
     */
    RoleId = {
        storeAdmin: 'STOREADMIN',
        superAdmin: 'SUPERADMIN',
        customer: 'CUSTOMER'
    }
    /**
     * variable which is used to store all the messages to be displayed.
     */
    message = {

        EMAILSENT: "OTP sent to email successfully",
        SMSSENT: "OTP sent to SMS successfully",
        otpSentSuccess: "OTP sent successfully",
        otpSentFailed: "OTP failed to send",
        // codeAlert: "please enter the Code.!",
        emailInvalid: "The email ID is invalid",
        emailExist: "The email ID does not exist. Please provide a registered email",
        emailOrNumberExist: "The given email / phone number does not exist",
        // invalidCode: "Code is inValid",
        wrongAnswer: "The answer you entered is incorrect",
        questionFailed: "Security questions cannot be answered at the moment. Please try again later",
        // mailSendSuccess: "Code send to Your Mail",
        mailSendFailure: "Error in sending mail. Please try again",
        // messageSendSuccess: "Code send to Your Mobile",
        // codeExpired: "Code expired..!",
        somethingWrong: "Something went wrong. Please contact the administrator",
        customerVerified: "Customer already verified",
        // maxFileCount: "Pls Contact Admin to add more than {{count}} files",
        unsavedChanges: "You have unsaved changes. Are you sure you want to leave this page?",
        // checkConnection: "Check your internet connection",
        // invalidCredential: "Invalid username or password, please try again",
        fieldRequired: "This field is required",
        // invalidField: "This field is invalid",
        // invalidName: "Please enter a valid name!",
        invalidEmail: "Please enter a valid email",
        // incorrectPassword: "The password you entered is incorrect. Please try again.",
        // incorrectPasswordLength: "Password should contain 8 characters!",
        // invalidPhoneNumber: "Please enter a valid phone number!",
        // emailExists: "Email already exist!",
        // uploadSuccess: "Details uploaded successfully!",
        // uploadFailure: "Failed to upload the details! Please check the entered data",
        // passwordMismatch: "Password does not match!",
        // noRecordsFound: "No Records Found!",
        // userSuccess: "User added successfully!",
        // dataSuccess: "Data added successfully!",
        // dataSaved: "Data saved successfully!",
        // userUpdate: "User updated successfully!",
        // dataUpdate: "Data updated successfully!",
        cancel: "Are you sure you want to cancel?",
        delete: "Are you sure you want to delete?",
        // passwordChanged: "Password has been changed successfully!",
        charactersOnly: "Please enter only characters",
        // specialCharactersOnly: "Please enter only special characters",
        // setTheme: 'Are you sure to set the theme to your store industry?',
        // setThemeSuccess: 'Theme set successfully!',
        // setThemeFailed: 'Setting the theme failed. Please try again!',
        // dataDelete: "Data deleted successfully!",
        mandatoryField: "Please fill out the mandatory fields properly",
        // countryFailed: "Countries cannot be fetched at the moment. Please try again later",
        selectOption: "Please select an option",
        actAsCustomer: 'You will be a customer until your store owner approves you as a wholesale customer. To know further, kindly contact the store at {{phone}}',
        notAllowed: "You can't login to the store as a customer. Please contact your store owner to proceed further",
        accountNotVerified: 'Your account has not been verified. Kindly contact your store owner',
        accountNotActive: 'Your account is inactive now. Kindly contact your store owner to activate your account',
        termsFiled: "Please accept the terms and conditions to register",
        fetchError: "Oops! Something went wrong",
        unsaved: 'You have unsaved changes. Are you sure you want to leave this page?',
        unsubscribeMail: 'You have successfully unsubscribed from all marketing emails.',
        unsubscribeMailFailure: 'Failed to unsubscribe. Please try again after some time',
        unsubscribeInvalidCustomer: 'Invalid Customer',
        alreadyUnsubscribed: 'You have already unsubscribed',
        contactAdmin: 'Kindly contact your admin',
        mailNotVerifyed: 'Your email is not verified. Kindly provide the registered contact number to change the password',
        yourAreNotactive: 'Your account has not been activated as of now. Kindly contact your store owner',
        failedtoSignUp: "Failed to create account, Please try again after some time.",
        passwordChangedSuccessfully: "Password changed successfully."
    };
    /**
     * page heading
     */
    pageHeading = {
        authenticationHeading: "Two Step Authentication",
        authenticationSubHeading: "Please enter the verification code"
    };
    /**
    * variable which is used to store dialog type.
    */
    dialogType = { alert: 'alert', warning: 'warning', confirmation: 'confirmation', information: 'information', success: 'success', failure: 'failure' };
    /**
     * Constants used for button text
    */
    buttonText = {
        ok: 'Okay',
        yes: 'Yes',
        no: 'No',
        cancel: 'Cancel',
        submit: 'Submit',
        save: 'Save',
        leave: 'Leave',
        stay: 'Stay',
        continue: 'Continue'
    };
    /**
    * variable which is used to store browser names.
    */
    safari = 'safari'
    fontStyleValues = {
        bold: 'B',
        italic: 'I',
        underline: 'U'
    };
}





