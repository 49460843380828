<ng-container *ngFor="let option of productOptions;let optionIndex=index">
    <h3 class="option-heading">{{option?.name}}</h3>
    <div fxLayout="column" *ngIf="option?.controlType === 'CHECKBOX';else radioControl">
        <ng-container *ngFor="let optionVal of option?.optionValues; let optionValueIndex = index">
            <mat-checkbox [value]="optionVal?.optionValue" [checked]="checkValue(optionVal?.optionValue, option?.name)"
                [attr.data-control]="'option '+optionIndex+' optionValue '+optionValueIndex"
                (change)="productOptionChange(optionIndex, optionValueIndex,optionVal?.optionValue)">
                {{optionVal?.optionValue}}
            </mat-checkbox>
        </ng-container>
    </div>
    <ng-template #radioControl>
        <div fxLayout="row wrap" fxLayoutGap="7px">
            <ng-container *ngFor="let optionVal of option?.optionValues; let optionValueIndex = index">
                <button mat-flat-button [attr.data-control]="'option '+optionIndex+' optionValue '+optionValueIndex"
                    (click)="productOptionChange(optionIndex, optionValueIndex,optionVal?.optionValue)"
                    class="option-set"
                    [ngClass]="{active: optionVal?.optionValue == (optionObject && optionObject[option?.name] && optionObject[option?.name][0]?.optionValue) }">
                    {{ optionVal?.optionValue }}
                </button>
            </ng-container>
        </div>
    </ng-template>
</ng-container>