/**
 * Class which exports the required validation pattern and error messages which is used in subscription product module
 */
export class addEditUserConstant {


    /**
    * Variable which is used to store the display content of the dialogue messages for tax component  
    * @type Object
    */
    validators = {
        "email_pattern": "Email is invalid",
        "emailAlreadyExists": "Email already exists",
        passwordValidation: 'Password should be between 6-20 characters with at least one number, special character, lowercase and uppercase',
        addressMaxlength1: 'Address line1 should not exceed 100 characters',
        addressMaxlength2: 'Address line2 should not exceed 100 characters',
        addressInvalid: 'Please provide a valid address.',
        invalidMobileNumber: 'Contact number is invalid',
        stateRequired: 'State is required',
        zipcodeRequired: 'A postal code is required',
        zipcodeNotValid: 'Postal code is invalid',
        cityInvalid: 'City accepts only characters',
        cityMaxlength: "City should not exceed 30 characters",
        cityRequired: 'City is required',
        ContactNumber: "Contact number is required",
        countryRequired: 'Country is required',
        addressRequired: 'Address line1 is required',
        userNamealreadyExist: 'User name alredy exist',
        nameLengthExceeds: 'User name should not exceed a maximum of 25 characters',
        fieldRequired: "This field is required",
        userNameRequired: "Name is required",
        ContactNumberAlreadyExist: "Contact number alreadyexist",
        thersholdLesser: "Thershold Value should be lesser than 10000",
        thersholdGreater: "Thershold Value should be greater than or equal to 100",
        thersholdRequired: "Thershold Value is required",
        thresholdPattern: "Threshold value accepts only whole numbers",
        commissionRequired: "Commission Value is required",
        commissionMinFixed: "Commission value should range from 1 to 10000",
        commissionMinPercentage: "Commission value should range from 1 to 100",
        commissionMaxFixed: "Commission value should not exceed 10000",
        commissionMaxPercentageValue: "Commission value should not exceed 100",
        commissionPatternfixed: "Commission value accepts up to two decimal places only",
        commissionminPatternPercentage: "Commission value accepts only whole numbers",
        DiscountRequired: "Discount Value is required",
        DiscountMinFixed: "Discount value should range from 1 to 10000",
        DiscountMinPercentage: "Discount value should range from 1 to 100",
        DiscountMaxFixed: "Discount value should not exceed 10000",
        DiscountMaxPercentage: "Discount value should not exceed 100",
        DiscountPatternFixed: "Discount value accepts up to two decimal places only",
        DiscountPatternPercentage: "Discount value accepts only whole numbers",
        pattern: "Name accepts characters only",
        phoneNumberExceeds: "Contact number should not exceed 20 digits",
        zipcodeMaxLengthExceed:"Zipcode should not exceed 15 characters.",
    }

    /**
    * title and description for Subscription product-list.
    */
    userheading = {
        userlistTitle: 'Users List',
        userlistDescryption: '',
    };

    AddUserHeading = {
        AddUserTitle: 'Add User',
        AddUserDescryption: '',
    };


    EditUserHeading = {
        EditUserTitle: 'Edit User',
        EditUserDescryption: '',
    };

    customerheading = {
        productlistTitle: 'Customer List',
        productlistDescryption: '',
    };
    /**
    * variable which is used to table heading for manual.
    * @type {Array} of object
    */
    columnsForUserDetails = [
        { column: 'name', suffixText: 'Name', type: 'Text' },
        { column: 'email', suffixText: 'Email', type: 'Text' },
        { column: 'mobile', suffixText: 'Contact Number', type: 'Text' },
        { column: 'Status', suffixText: 'Status', type: 'Chip', sort: false, classCondition: { 'Resume': "inActiveChip", 'Suspended': "orangeChip", 'Active': "greenChip", 'Inactive': "draftRedChip" } },
    ];

    customersDetails = [
        { column: 'Name', suffixText: 'Customer Name', type: 'Text' },
        { column: 'Email', suffixText: 'Email', type: 'Text' },
        { column: 'CommissionAmount', suffixText: 'Commission Amount', type: 'Text' },
        { column: 'ContactNumber', suffixText: 'Contact Number', type: 'Text', },
    ];

    UserBasedoption = ['Suspended', 'Active', 'Inactive']
    buttonText = {
        ok: 'Okay',
        yes: 'Yes',
        no: 'No',
        cancel: 'Cancel',
        save: 'Save',
        leave: 'Leave',
    }

    dialogMessages = {
        failureHeader: 'Failure',
        successHeader: 'success',
        failedToLoad: 'Failed to load details.',
        ActivateUserdetails: 'Are you sure you want to activate this user?',
        successfullDeleteDetails: 'User details removed successfully!',
        failureDeleteSubscriptionPlan: 'Subscription plan remove failed.',
        unSavedChanges: 'You have unsaved changes. Are you sure you want to leave this page?',
        failureMessage: 'Failed to load product details.',
        failedToSave: 'Failed to save details.',
        mandatoryFieldFill: "Please fill out the mandatory fields.",
        noChangeAlert: 'You have no changes to save.',
        deactivateUser: "Are you sure you want to deactivate this user?",
        deactivateSuccess: "User deactivated successfully!",
        activateSuccess: "User activated successfully!",
        failedCustomerDetails: "Failed to load customers details",
        noAccountDetails: "There is no account details",
        failedCommissionDetails: "Failed to load commission details",
        suspendUserSuccess: "The user suspended successfully!",
        resumeUser: "The user resumed successfully!",
        failSuspend: "Failed to suspended the user",
        failResumed: "Failed to resumed the user",
    };

    dialogType = { alert: 'alert', confirmation: 'confirmation', warningHeader: 'Warning', information: 'information', success: 'success', failure: 'failure' };

    dialogHeader = {
        confirmation: 'Confirmation',
        information: 'Information',
        alert: 'Alert',
        warning: 'Warning',
        success: 'Success',
        failure: 'Failure',
        areYouSure: 'Are you sure?'
    };

    commissionDetails = [
        { column: 'date', suffixText: 'Date', type: 'Text' },
        { column: 'amount', suffixText: 'Amount', type: 'Text' },
        { column: 'PlanAmount', suffixText: 'Plan Amount', type: 'Text' },
        { column: 'PlanName', suffixText: 'Plan Name', type: 'Text' },
    ]

}