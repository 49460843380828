<cen-loader-vii *ngIf="pageDetails?.isLoader "></cen-loader-vii>
<div class="page-container" *ngIf="!pageDetails?.isLoader">
    <phase-ii-header-page [heading]="pageDetails?.heading" [buttonInfo]="pageDetails?.buttonInfo"
        (methodClicked)="functionTriggered($event)">
    </phase-ii-header-page>
    <div fxLayout="row wrap" fxLayoutAlign="center center"
        *ngIf="!showPayment && planTypes?.length !== 0">
        <ng-container *ngFor="let plan of planTypes;let id=index;">
            <div *ngIf="(plan?.SubscriptionPlans?.length) ">
                <mat-card [attr.data-control]="'plan'+id" class="planCard">
                    <div class="planNameDiv">
                        <h1 class="planName">{{plan?.PlanName}}
                        </h1>
                    </div>
                    <div class="price-details">
                        <ng-container *ngFor="let subscription of plan?.SubscriptionPlans" >
                            <div fxLayout="row"  fxLayoutAlign="center center" *ngIf="(subscription?.FrequencyType ==='Monthly' || subscription?.FrequencyType ==='Annually')">
                                <div [ngClass]="subscription?.FrequencyType ==='Monthly' ? 'monthlyPrice':'price'">
                                    {{subscription?.Price | currency: currencyUnit}}{{'/'}}
                                </div>
                                <div [ngClass]="subscription?.FrequencyType ==='Monthly' ? 'month':'year'">
                                    {{subscription?.FrequencyType ==='Monthly' ? 'Month':'Year'}}
                                </div>
                            </div>
                        </ng-container>
                    </div>
                    <mat-divider></mat-divider>
                    <div [ngClass]="((plan?.IsAdditionalCharge) && (plan?.SetUpCost>=0)) ? 'both':'setupcost'"
                        *ngIf="(plan?.IsAdditionalCharge) || (plan?.SetUpCost>=0)">
                        <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="8px"
                            *ngIf="(plan?.SetUpCost>=0)">
                            <div class="font-style">
                                {{(plan?.SetUpCost ? plan?.SetUpCost :0) |
                                currency:
                                currencyUnit}}
                            </div>
                            <div class="setup-additional-charge-text">
                                Setup cost
                            </div>
                        </div>
                        <div fxLayout="row" fxLayoutAlign="center center" *ngIf="(plan?.IsAdditionalCharge)"
                            fxLayoutGap="8px">
                            <div class="font-style">
                                {{plan?.AdditionalChargeType==='PERCENTAGE' ? ((plan?.AdditionalCharge ?
                                plan?.AdditionalCharge
                                :"") + '%'):(plan?.AdditionalCharge ? plan?.AdditionalCharge
                                :"")| currency: currencyUnit}}
                            </div>
                            <div class="setup-additional-charge-text">
                                {{plan?.AdditionalChargeType==='PERCENTAGE' ? (' of '+(plan?.AdditionalChargeLabel ?
                                plan?.AdditionalChargeLabel : "")) :(plan?.AdditionalChargeLabel ?
                                plan?.AdditionalChargeLabel :
                                "")}}
                            </div>
                        </div>
                    </div>
                    <mat-divider></mat-divider>
                    <mat-radio-group class="radio-div" [formControl]="frequency">
                        <mat-radio-button [disabled]="pageDetails&&pageDetails.planLoader" class="radio-button"
                            *ngFor="let subscription of plan?.SubscriptionPlans" [value]="subscription"
                            [checked]="frequency?.value?.SK === subscription?.SK && frequency?.value?.planId === subscription?.planId "
                            data-control="planAmount">
                            <div fxLayout="row" fxLayoutAlign="space-between center" *ngIf="subscription?.FrequencyType ==='Free Trial'">
                                <div fxLayout="row">
                                    <div class="price">
                                        {{0.00 | currency: currencyUnit}}{{'/'}}
                                    </div>
                                    <div class="days">
                                        {{subscription?.FreeTrialDurationDays===1 ?
                                        subscription?.FreeTrialDurationDays + '
                                        Day'
                                        :
                                        subscription?.FreeTrialDurationDays + ' Days' }}
                                    </div>
                                </div>
                                <div class="frequency">
                                    {{subscription?.FrequencyType}}
                                </div>
                            </div>
                            <div fxFlex="48px" fxLayout="row" fxLayoutAlign="space-between center"
                                *ngIf="(subscription?.FrequencyType ==='Monthly' || subscription?.FrequencyType ==='Annually')">
                                <div fxLayout="row">
                                    <div class="price">
                                        {{subscription?.Price | currency: currencyUnit}}{{'/'}}
                                    </div>
                                    <div class="days">
                                        {{subscription?.FrequencyType ==='Monthly' ? 'Month':'Year'}}
                                    </div>
                                </div>
                                <div class="frequency" *ngIf="subscription?.FrequencyType==='Annually'">
                                    Save {{plan?.yearlySaving | number :'1.2-2'}}%
                                </div>
                            </div>
                            <div fxLayout="row" fxLayoutAlign="space-between center"
                                *ngIf="(subscription?.FrequencyType ==='Semi Annually' || subscription?.FrequencyType ==='Quarterly')">
                                <div fxLayout="row">
                                    <div class="price">
                                        {{subscription?.Price | currency: currencyUnit}}{{'/'}}
                                    </div>
                                    <div class="days">
                                        {{subscription?.FrequencyType ==='Semi Annually' ? '6months':'3months'}}
                                    </div>
                                </div>
                            </div>
                        </mat-radio-button>
                    </mat-radio-group>
                    <mat-divider></mat-divider>
                    <div class="feature">
                        <ng-container *ngFor="let feature of features">
                            <ng-container *ngFor="let planFeature of plan?.PlanFeatures">
                                <ng-container *ngIf="feature?.SK===planFeature?.FeatureId">
                                    <div fxLayout="row">
                                        <mat-icon *ngIf="planFeature?.FeatureCount || planFeature?.FeatureEnabled"
                                            class="iconStyle">check_circle</mat-icon>
                                        <mat-icon *ngIf="!planFeature?.FeatureCount && !planFeature?.FeatureEnabled"
                                            class="iconStylered">clear</mat-icon>
                                        <div class="feature-description">
                                            <div *ngIf="planFeature?.FeatureCount">
                                                {{planFeature?.Description}}
                                            </div>
                                            <div *ngIf="!planFeature?.FeatureCount">
                                                {{feature?.FeatureName}}
                                            </div>
                                        </div>
                                    </div>
                                </ng-container>
                            </ng-container>
                        </ng-container>
                    </div>
                    <div fxLayout="row" fxLayoutAlign="center center" >
                        <button [disabled]="pageDetails&&pageDetails.planLoader" mat-raised-button
                           class="primary-button subscribe-btn" data-control="subscribeNowButton"
                            (click)="openDialog((frequency?.value ? frequency?.value:null),plan)">
                            <div *ngIf="!plan['loading']">
                            Subscribe Now
                            </div>
                            <cen-loader *ngIf="plan&&plan['loading']" [type]="'content'" class="buttonLoader"
                                [name]="'cen-dot-spinner'" fxLayout="row" fxLayoutAlign="center center">
                            </cen-loader>
                        </button>
                    </div>
                </mat-card>
            </div>
        </ng-container>
    </div>
    <ng-container *ngIf="planTypes?.length === 0 && !pageDetails?.isLoader">
        <div class="imgcenter">
            <img height="300" width="300" src="./assets/subscription-plans-no-image.png" alt="no_image" />
            <p> Currently no plan available for this country. Try again later!</p>
        </div>
    </ng-container>
    <phase-ii-subscription-plan-payment *ngIf="showPayment" [selectedPlanFeatures]="selectedFeatures"
        [selectedPlanDetails]="selectedPlanDetails" (paymentDone)="savePlan($event)" [fromUpdatePlan]="fromUpdatePlan"
        (showPayment)="onShowPayment($event)">
    </phase-ii-subscription-plan-payment>
</div>
