import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ThemesModuleConstants } from '../../constants/shared-theme-constants';
import { MatDialogRef } from '@angular/material/dialog';
import { CommonService } from 'libs/shared/src/lib/services/common.service';
import { DialogService } from 'libs/common/src/lib/services/dialog.service';
import { dataConfig } from 'libs/common/src/lib/services/config';
import { LoadTranslationService } from '@phase-ii/common';
import { FILE_EXTENSION } from '@phase-ii/shared';
/**
 * Component which is used to write review for a product.
*/
@Component({
  selector: 'phase-ii-write-review',
  templateUrl: './write-review.component.html',
  styleUrls: ['./write-review.component.scss']
})
export class WriteReviewComponent extends ThemesModuleConstants implements OnInit {
  /**
   * Variable which is used to get product name from parent component.
   * @type {any}
   */
  productName: any;
  /**
   * Variable which is used to get product name from parent component.
   * @type {any}
   */
  data: any;
  /**
   * Variable which is used to get constant review details from parent component.
   * @type {any}
   */
  constantReviewDetails: any;
  /**
   * Variable which is used to store review title and description.
   * @type {FormControl}
   */
  reviewForm: UntypedFormGroup;
  /**
   * variable used to bind image
   * @type {string}
   */
  awsImage: string;
  /**
   * Variable which is used to get product's main image from parent component.
   * @type {string}
   */
  mainImage: string;
  /**
   * Variable which is used to store product's image for a review.
   * @type {Array}
   */
  files: Array<any> = [];
  /**
* Variable which is used to get onlyAlphabet pattern for validation
* @type {any}
*/
  pattern: any = dataConfig.patternValidators;
  /**
   * Variable used to check whether image has been updated or not. 
   * @type {boolean}
   */
  fileChanged: boolean = false;
  /**
  * Variable used to store the image extension
  */
  imageExtension = FILE_EXTENSION;
  /**
  * Component constructor which is used to inject the required services.
  * @param commonService to use the services provided in common service
  * @param dialogRef used to refer the component opened in dialog
  * @param dialogService To get functions and observables in the dialog service
  */
  constructor(private commonService: CommonService,
    private dialogService: DialogService,
    public dialogRef: MatDialogRef<WriteReviewComponent>,
    private translateService: LoadTranslationService
  ) { super(); }
  /**
   * Component onInit life cycle.
   */
  ngOnInit(): void {
    this.awsImage = this.commonService.environment.AWSImageUrl;
    this.reviewForm = new UntypedFormGroup({
      rating: new UntypedFormControl(this.data && this.data.rating ? this.data.rating : null, {
        validators: [Validators.required]
      }),
      title: new UntypedFormControl(this.data && this.data.reviewTitle ? this.data.reviewTitle : null, {
        validators: [Validators.required, Validators.pattern(this.pattern.emptySpace), Validators.maxLength(200)]
      }),
      description: new UntypedFormControl(this.data && this.data.reviewMessage ? this.data.reviewMessage : null, [Validators.maxLength(500)])
    });
    if (this.data && this.data.ratings) {
      this.countStar(this.data.ratings);
    }
    if (this.data && this.data.featuredImage && this.data.featuredImage.length) {
      this.data.featuredImage.forEach(element => {
        if (element.id) {
          this.files.push({
            fileName: null,
            url: element.fileUrl,
            ext: element.ext,
            size: 0,
            fileData: '',
            id: element.id,
          });
        }
      });
    }
    this.reviewForm.get('rating').setErrors(null);
  }
  /**
   * Method which is used to display the ratings.
   * @param rating To get rating value.
   * @param isEdit To check whether review is to be edited or added
   */
  countStar(rating: number, isEdit?: boolean): void {
    if (isEdit) {
      this.reviewForm.markAsDirty();
    }
    if (rating === (this.reviewForm && this.reviewForm.value && this.reviewForm.value.rating)) {
      this.reviewForm.get('rating').setValue(0);
    } else if (this.reviewForm && this.reviewForm.get('rating')) {
      this.reviewForm.get('rating').setValue(rating);
    }
    if ((this.reviewForm && this.reviewForm.value && this.reviewForm.value.rating) === 0) {
      this.reviewForm.get('rating').setErrors({ required: true });
    } else if (this.reviewForm && this.reviewForm.get('rating')) {
      this.reviewForm.get('rating').setErrors(null);
    }
  }
  /**
   * Method which is used to show the icons for rating stars.
   * @param index To get rating index. 
   * @returns Return icon name for rating's count.
   */
  showIcon(index: number): any {
    return (this.reviewForm.value.rating >= index + 1) ? 'star' : 'star_border';
  }
  /**
  * Method which is used to select the image and to read the data as binary string.
  * @param event To store the file data.
  */
  handleFileSelect(event: any): void {
    if (event && event.target && event.target.files && event.target.files.length && event.target.files[0].size > this.maxFileSize) {
      this.dialogService.dialogMethod(this.translateService.getTranslation('WRITE_REVIEW.FILE_SIZE_EXCEEDED'), this.dialogType.failure, true);
    } else if (event && event.target && event.target.files && event.target.files.length && event.target.files.length <= 5 && ((this.files?.length + event.target.files.length) <= 5)) {
      this.reviewForm.markAsDirty();
      const fileReader = [];
      const reader = [];
      for (let i = 0; i < event.target.files.length; i++) {
        const doc = {
          fileName: '',
          url: '',
          ext: '',
          size: 0,
          fileData: ''
        };
        reader[i] = new FileReader();
        fileReader[i] = new FileReader();
        const ext = event.target.files[i] && event.target.files[i].name && event.target.files[i].name.substr(event.target.files[i].name.lastIndexOf('.') + 1);
        doc.ext = ext.toLowerCase();
        const index = event.target.files[i] && event.target.files[i].name && event.target.files[i].name.indexOf('.');
        doc.fileName = event.target.files[i] && event.target.files[i].name && event.target.files[i].name.slice(0, index);
        doc.size = event.target.files[i].size / 1024 / 1024;
        reader[i].readAsDataURL(event.target.files[i]);
        reader[i].onload = (data) => {
          doc.url = data && data.target && data.target.result;
        };
        fileReader[i].readAsBinaryString(event.target.files[i]);
        fileReader[i].onload = (data) => {
          doc.fileData = btoa(data && data.target && data.target.result);
        };
        if (this.fileFormat && this.fileFormat.length) {
          if ((this.fileFormat.findIndex((item) => item === doc.ext)) === -1) {
            this.dialogService.dialogMethod(this.translateService.getTranslation('WRITE_REVIEW.FILE_SIZE_EXCEEDED'), this.dialogType.alert, true);
            break;
          } else {
            this.files.push(doc);
          }
        }
      }
    } else {
      this.dialogService.openDialog({
        header: this.translateService.getTranslation('WRITE_REVIEW.DIALOG_HEADER'),
        message: this.translateService.getTranslation('WRITE_REVIEW.DIALOG_COUNT'),
        actionType: this.dialogType.alert,
        button: { right: this.translateService.getTranslation('WRITE_REVIEW.DIALOG_BTN') },
        disableClose: true
      });
    }
    // }
    // }
  }
  /**
   * Method used to remove the images
   * @param id it defines the index of the image files
   */
  removeImage(id: any): void {
    this.fileChanged = true;
    this.files.splice(id, 1);
  }
  /**
  * Method used to add and update a review. 
  */
  onSubmit(): void {
    if (this.data && this.data.id) {
      if (!this.reviewForm.pristine || this.fileChanged) {
        this.onSave();
      } else {
        this.dialogService.dialogMethod(this.translateService.getTranslation('WRITE_REVIEW.NO_CHANGES_ALERT'), this.dialogType.alert, true);
      }
    } else {
      this.onSave();
    }
  }
  /**
   * Method which is used to emit review data to the parent compnent.
   */
  onSave(): void {
    if (this.reviewForm.valid) {
      let reviewData = {
        ratings: this.reviewForm.value.rating,
        reviewTitle: this.reviewForm.value.title,
        reviewMessage: this.reviewForm.value.description,
        reviewImages: this.files
      };
      this.dialogRef.close(reviewData);
      this.reviewForm.reset();
      this.files = [];
    } else {
      this.reviewForm.markAllAsTouched();
      this.dialogService.dialogMethod(this.translateService.getTranslation('WRITE_REVIEW.MANDATORY_FIELDS'), this.dialogType.alert, true);
    }
  }
  /**
 * Method used to close the dialog.
 * called by clicking close button .
 */
  onCancel(): void {
    if (this.reviewForm.dirty || this.fileChanged) {
      const dialogRef = this.dialogService.openDialog({
        header: this.translateService.getTranslation('WRITE_REVIEW.ARE_YOU_SURE'),
        message: this.translateService.getTranslation('WRITE_REVIEW.UNSAVED_CHANGES'),
        actionType: this.dialogType.confirmation,
        button: { left: this.translateService.getTranslation('WRITE_REVIEW.NO'), right: this.translateService.getTranslation('WRITE_REVIEW.YES') },
        disableClose: true
      });
      dialogRef.afterClosed().subscribe((result: boolean) => {
        if (result) {
          this.reviewForm.reset();
          this.dialogRef.close();
        }
      });
    }
    else {
      this.reviewForm.reset();
      this.dialogRef.close();
    }
  }
}