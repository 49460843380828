<h3 class="primaryColor">{{headingValue}}</h3>
<div *ngIf="!readWriteRestriction" fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="end end">
    <button data-control="addQuestion" mat-raised-button class="primary-button"
        (click)="$event?.preventDefault();addConfigurationQuestions(true)">
        <mat-icon class="add-icon" matPrefix>add</mat-icon>Add
        Questions
    </button>
</div><br>

<form [formGroup]="customizedForm">
    <div formArrayName="configurationQuestions" cdkDropList fxLayout="column" (cdkDropListDropped)="drop($event)">
        <div *ngFor="let plugin of customizedForm?.get('configurationQuestions')?.controls;let i=index" cdkDrag
            [cdkDragDisabled]="!this.formValues?.pluginControls?.sortField">
            <mat-card [formGroupName]="i" class="mat-card-alignment" fxLayout="row" fxFlex="100" fxLayoutGap="20px">
                <span class="read-write">
                    {{customizedForm?.get('configurationQuestions')['controls'][i]?.get('fieldTitle')?.value ?
                    customizedForm?.get('configurationQuestions')['controls'][i]?.get('fieldTitle')?.value :
                    'Question '+(i+1)}}</span>
                <div *ngIf="!readWriteRestriction" fxLayout="row" fxFlex="20" class="actions">
                    <mat-slide-toggle *ngIf="this.formValues?.pluginControls?.visibility" color="primary"
                        matTooltip="visibility" (change)="visibilityChange()" formControlName="isVisible"
                        class="align-sort-icon"></mat-slide-toggle>
                    <mat-icon *ngIf="this.formValues?.pluginControls?.sortField" matTooltip="Sort"
                        [attr.data-control]="'sort' + i" class="align-sort-icon"
                        cdkDragHandle>format_align_justify</mat-icon>
                    <mat-icon matTooltip="Edit" [attr.data-control]="'edit' + i" class="cursor-pointer"
                        class="align-edit-icon"
                        (click)="addConfigurationQuestions(true,plugin?.value,i)">edit</mat-icon>
                    <mat-icon matTooltip="Remove" [attr.data-control]="'delete' + i" class="cursor-pointer"
                        class="align-delete-icon" (click)="removeController(i)">delete</mat-icon>
                </div>
                <div *ngIf="readWriteRestriction" fxFlex="10">
                    <mat-icon matTooltip="View" class="cursor-pointer" class="align-view-icon"
                        (click)="addConfigurationQuestions(true,plugin?.value,i)">visibility</mat-icon>
                </div>
            </mat-card>
        </div>
    </div>
</form>

<ng-template #customForm>
    <h5 mat-dialog-title>
        <span> Configurations Questions</span>
        <mat-icon class="show-cursor close-icon" (click)="close()">close</mat-icon>
    </h5>
    <div mat-dialog-content>
        <form [formGroup]="customizedForm" [ngClass]="readWriteRestriction? 'no-events': 'restore-events'">
            <div formArrayName="configurationQuestions">
                <ng-container
                    *ngFor="let plugin of customizedForm?.get('configurationQuestions')?.controls; let i = index">
                    <ng-container *ngIf="i== editCustomFormIndex">
                        <div fxLayoutAlign="center center" class="edit-custom">
                            <div [formGroupName]="i" fxFlex="100%">
                                <div fxLayout="row" *ngIf="this.formValues?.pluginControls?.header" class="sec-header">
                                    <mat-form-field fxFlex="85%">
                                        <mat-label>Section Header</mat-label>
                                        <input data-control="header" matInput formControlName="header" trimVal />
                                        <mat-hint class="primaryColor">If you intend to add it under an
                                            existing section header, enter the name of that specific section
                                            header.</mat-hint>
                                        <mat-error *ngIf="plugin?.get('header')?.hasError('maxlength')"
                                            data-control="headerMaxErr">Section header exceeds maxlength.</mat-error>
                                    </mat-form-field>
                                </div>
                                <div fxLayout="column">
                                    <div fxFlex="90%">
                                        <mat-form-field fxFlex="85%">
                                            <mat-label>Configuration Questions</mat-label>
                                            <input data-control="configQuestion" matInput formControlName="fieldTitle"
                                                required trimVal />
                                            <mat-error data-control="questionRequiredErr"
                                                *ngIf="plugin?.get('fieldTitle')?.hasError('required')">
                                                Configuration Question is required.
                                            </mat-error>
                                            <mat-error data-control="questionPatternErr"
                                                *ngIf="plugin?.get('fieldTitle')?.hasError('pattern')">
                                                Configuration Question accept only characters.
                                            </mat-error>
                                            <mat-error data-control="questionMaxLengthErr"
                                                *ngIf="plugin?.get('fieldTitle')?.hasError('maxlength')">
                                                Configuration question should not exceed 120 characters.
                                            </mat-error>
                                        </mat-form-field>
                                    </div>
                                </div>
                                <div fxLayout="row"
                                    *ngIf="this.formValues?.pluginControls?.description||this.formValues?.pluginControls?.customFieldCode">
                                    <mat-form-field fxFlex="55%" *ngIf="this.formValues?.pluginControls?.description">
                                        <mat-label>Description</mat-label>
                                        <input data-control="description" matInput formControlName="description"
                                            trimVal />
                                        <mat-error *ngIf="plugin?.get('description')?.hasError('maxlength')"
                                            data-control="descriptionMaxErr">Description exceeds maxlength.</mat-error>
                                    </mat-form-field>
                                    <mat-form-field class="code" fxFlex="25%"
                                        *ngIf="this.formValues?.pluginControls?.customFieldCode">
                                        <mat-label>Code</mat-label>
                                        <input data-control="code" matInput formControlName="customFieldCode" trimVal />
                                        <mat-error *ngIf="plugin?.get('customFieldCode')?.hasError('maxlength')"
                                            data-control="codeMaxErr">Code exceeds maxlength.</mat-error>
                                    </mat-form-field>
                                </div>
                                <div fxLayout="column" fxLayout.lt-md="row">
                                    <div fxFlex="90%">
                                        <mat-form-field fxFlex="85%">
                                            <mat-label>Control Type</mat-label>
                                            <mat-select data-control="controlType" formControlName="controlType"
                                                required>
                                                <mat-option *ngFor="let item of customizedDetails?.controlType"
                                                    (click)="selectedFormField(i,item,null,true)"
                                                    [value]="item?.actualValue">{{item?.displayValue}}
                                                </mat-option>
                                            </mat-select>
                                            <mat-error data-control="typeRequiredErr"
                                                *ngIf="plugin?.get('controlType')?.hasError('required')">
                                                Control Type is required.
                                            </mat-error>
                                        </mat-form-field>
                                        <div fxFlex="5%"></div>
                                        <div fxFlex="20%" fxLayout="row" *ngIf="customizedForm?.
                                        get('configurationQuestions')['controls'][i]?.get('allowMultiple')"
                                            class="multi-allow">
                                            <mat-checkbox data-control="allowMultiple" class="align-check-box"
                                                formControlName="allowMultiple"> Allow Multiple </mat-checkbox>
                                        </div>
                                    </div>
                                </div>
                                <div *ngIf="customizedForm?.get('configurationQuestions')?.['controls']?.[i]?.get('controlType').value
                                    ==='imageJson'" class="urlCard" fxLayout="row" fxLayoutAlign="start">
                                    <div fxLayout="row" fxFlex="25" fxLayoutAlign="center center" class="imgCard">
                                        <img alt="uploadImage" src="./assets/upload 1.png" (click)="file.click()">
                                        <input #file type='file' (change)="onChooseFile($event)" hidden
                                            data-control="files">
                                    </div>
                                    <div fxLayout="column" fxFlex="75" class="urlsDiv" fxLayoutAlign="start start">
                                        <span>Uploaded svg icon URLs are displayed here...</span>
                                        <ng-container *ngFor="let item of uploadedUrls">
                                            <span>{{item}}</span>
                                        </ng-container>
                                    </div>
                                </div>
                                <div *ngIf="customizedForm?.get('configurationQuestions')?.['controls']?.[i]?.get('controlType').value
                                    ==='imageJson'" class="bottom5px"><mat-icon class="sliderMsg">warning</mat-icon>
                                    {{jsonObject.jsonMsg}} </div>
                                <div fxLayout="column" fxLayout.lt-md="row" *ngIf="customizedForm?.get('configurationQuestions')['controls'][i]?.
          get('controlType')?.value && customizedForm?.
          get('configurationQuestions')['controls'][i]?.get('values')?.controls">
                                    <div fxFlex="75%" formArrayName="values" fxLayout="column">
                                        <ng-container *ngFor="let formControl of customizedForm?.
          get('configurationQuestions')['controls'][i]?.get('values')?.controls;let k = index">
                                            <div fxFlex="85%">
                                                <mat-form-field fxFlex="80%">
                                                    <mat-label>Value</mat-label>
                                                    <input data-control="valueName" matInput formControlName="{{k}}"
                                                        required trimVal (input)="update(i,k)"/>
                                                    <mat-error data-control="valueRequiredErr"
                                                        *ngIf="formControl.hasError('required')">
                                                        Value is required.
                                                    </mat-error>
                                                    <mat-error data-control="valueExistsErr"
                                                        *ngIf="formControl.hasError('isDuplicate')">
                                                        Value already exists.
                                                    </mat-error>
                                                    <mat-error *ngIf="formControl.hasError('notValid')"
                                                        data-control="valueNotValid">Unexpected token '{{formControl.value.charAt(0)}}',"{{formControl.value}}"is not valid JSON
                                                    </mat-error>
                                                </mat-form-field>
                                                <div *ngIf="!readWriteRestriction" fxFlex="6%" fxFlexAlign="center">
                                                    <mat-icon class="cursor-pointer" class="align-delete-icon"
                                                        data-control="deleteValue" (click)="removeValueController(i,k)">
                                                        delete
                                                    </mat-icon>
                                                </div>
                                            </div>

                                        </ng-container>
                                        <div fxFlex="5%"></div>
                                        <div *ngIf="!readWriteRestriction" fxFlex="40%" fxLayout="row">
                                            <button [ngClass]="customizedForm?.get('configurationQuestions')['controls'][i]?.get('values').status==='INVALID'
                                                ?'disabled button-aligns':'addValue button-aligns'" mat-raised-button
                                                [disabled]="customizedForm?.get('configurationQuestions')['controls'][i]?.get('values').status==='INVALID'
                                                ? true : false" (click)="$event?.preventDefault();addQuestionValue(i)"
                                                data-control="addValue">Add Value</button>
                                        </div>
                                        <br>
                                    </div>
                                </div>
                                <div *ngIf="customizedForm?.get('configurationQuestions')?.['controls']?.[i]?.get('controlType').value==='slider'"
                                    class="bottom5px"><mat-icon class="sliderMsg">warning</mat-icon>
                                    Slider must have Default Value </div>
                                <div fxLayout="column" *ngIf="showDefaultBox">
                                    <mat-checkbox data-control="hasDefault" formControlName="hasDefault"
                                        (change)="plugin?.get('defaultValue')?.reset()" class="align-check-box">
                                        Do you need default value for this question ?</mat-checkbox>
                                    <div
                                        *ngIf="customizedForm?.get('configurationQuestions')?.controls[i]?.get('hasDefault')?.value">
                                        <mat-form-field class="form-field" data-control="defaultValueInput"
                                            *ngIf="customizedForm?.get('configurationQuestions')?.controls[i]?.get('controlType')?.value!=='slideToggle'">
                                            <mat-label>Default value</mat-label>
                                            <input formControlName="defaultValue" matInput trimVal />
                                            <mat-error *ngIf="plugin?.get('defaultValue')?.hasError('maxlength')"
                                                data-control="defaultValueMax">
                                                Default Value exceeds maxlength.</mat-error>
                                        </mat-form-field>
                                        <mat-radio-group *ngIf="customizedForm?.get('configurationQuestions')?.controls[i]
                                            ?.get('controlType')?.value==='slideToggle'" formControlName="defaultValue"
                                            fxLayout="row" class="radio-grp">
                                            <mat-radio-button class="radio-btn" [value]="true">True</mat-radio-button>
                                            <mat-radio-button [value]="false">False</mat-radio-button>
                                        </mat-radio-group>
                                    </div>
                                </div>
                                <div fxLayout="column">
                                    <div fxFlex="20%">
                                        <mat-checkbox data-control="validator" class="align-check-box"
                                            formControlName="isValidatoreEnabled" (change)="allowValidator($event,i)">
                                            Do you need validators for this question ? </mat-checkbox>
                                    </div>

                                </div>
                                <div fxLayout="column" *ngIf="this.formValues?.pluginControls?.isApiCall">
                                    <div fxFlex="20%">
                                        <mat-checkbox data-control="isApiCall" class="align-check-box"
                                            formControlName="isApiCall">Do you need api call ?</mat-checkbox>
                                    </div>
                                </div>
                                <div fxLayout="column"
                                    *ngIf="customizedForm?.get('configurationQuestions')?.controls[i]?.get('isValidatoreEnabled')?.value">
                                    <h3>Default Validator </h3>
                                    <ng-container
                                        *ngFor="let constant of customizedDetails?.defaultValidator; let j=index">
                                        <div fxLayout="row" fxLayout.lt-md="column">
                                            <div formGroupName="validator" fxLayout="column" fxFlex="10%"
                                                *ngIf="customizedForm?.get('configurationQuestions')?.controls[i]?.get('validator')?.get(constant?.field+'checkbox')">
                                                <mat-checkbox [attr.data-control]="'validator' + j"
                                                    class="align-check-box"
                                                    formControlName="{{constant?.field + 'checkbox'}}"
                                                    (change)="checkValidator($event,constant?.field,i)">
                                                    {{constant?.displayTitle}} </mat-checkbox>
                                            </div>
                                            <div fxFlex="10%"></div>
                                            <div fxLayout="column" fxLayout.lt-md="row" fxFlex="80%">
                                                <div fxLayout="row" fxLayout.lt-md="column">

                                                    <div formGroupName="errorMessage" fxFlex="100%">
                                                        <ng-container
                                                            *ngIf="customizedForm?.get('configurationQuestions')?.controls[i]?.get('errorMessage')?.get(constant.field)">
                                                            <mat-form-field fxFlex="90%" fxFlex.lt-md="100%">
                                                                <mat-label>Error Message</mat-label>
                                                                <input data-control="{{constant}}" matInput
                                                                    formControlName="{{constant?.field}}" required
                                                                    trimVal />
                                                                <mat-error
                                                                    [attr.data-control]="'errorMessageRequired' + j"
                                                                    *ngIf="customizedForm?.get('configurationQuestions')?.controls[i]?.get('errorMessage')?.get(constant.field)?.hasError('required')">
                                                                    This field is required!
                                                                </mat-error>
                                                                <mat-error [attr.data-control]="'validErrorMessage' + j"
                                                                    *ngIf="customizedForm?.get('configurationQuestions')?.controls[i]?.get('errorMessage')?.get(constant.field)?.hasError('pattern')">
                                                                    Please provide a valid Error Message!
                                                                </mat-error>
                                                            </mat-form-field>
                                                        </ng-container>
                                                    </div>
                                                    <div formGroupName="validator" fxFlex="100%">
                                                        <ng-container
                                                            *ngIf="customizedForm?.get('configurationQuestions')?.controls[i]?.get('validator')?.get(constant.field) && constant?.hasValue">
                                                            <mat-form-field fxFlex="90%" fxFlex.lt-md="100%">
                                                                <mat-label>Validator</mat-label>
                                                                <input data-control="{{constant}}" matInput
                                                                    formControlName="{{constant?.field}}" required
                                                                    trimVal />
                                                                <mat-error [attr.data-control]="'validatorRequired' + j"
                                                                    *ngIf="customizedForm?.get('configurationQuestions')?.controls[i]?.get('validator')?.get(constant.field)?.hasError('required')">
                                                                    This field is required!
                                                                </mat-error>
                                                                <mat-error [attr.data-control]="'validValidator' + j"
                                                                    *ngIf="customizedForm?.get('configurationQuestions')?.controls[i]?.get('validator')?.get(constant.field)?.hasError('pattern')">
                                                                    Please provide a valid validator!
                                                                </mat-error>
                                                            </mat-form-field>
                                                        </ng-container>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </ng-container>
                                </div>
                                <div class="multi-language" *ngIf="showMultiLanguage(i) && showDefaultBox">
                                    <mat-slide-toggle formControlName="isMultiLanguage">
                                        Multi language
                                    </mat-slide-toggle>
                                </div>
                            </div>
                        </div>
                        <br>
                    </ng-container>
                </ng-container>
                <div *ngIf="!readWriteRestriction" mat-dialog-actions fxLayoutAlign="end end">
                    <button data-control="addButton" class="add-btn" mat-raised-button
                        class="primary-button button-aligns" (click)="saveFormDetails()">Add</button>
                </div>
            </div>
        </form>
    </div>
</ng-template>