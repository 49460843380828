

export interface CurrentUserData {
  roleCode: String;
  roleId: number;
  storeId: number;
  store: {
    id: number,
    themeId: number,
    industryId: number,
    name: String,
    storeId: String,
    isNew: boolean,
    isTestAccount: boolean,
    isActive: boolean,
    displayName: String,
    isSuspended: boolean,
    websiteUrl: String,
    isMobileApp: boolean,
    mobileThemeCode: String,
    planDetails: {

      planId: String,
      Sk: String,
      PlanName: String,
      FrequencyType: String
    },
    currencyData: {
      currencySymbol: string,
      currencyName: String,
      currencyUnit: String
    }
  },
  role: {
    code: String
  }
}

export interface HeadingObject {
  title: String,
  description: String
}

export interface paginatorObject {
  new: boolean
  rows: Array<listDataSourceObject>,
  count: number
}

export interface listDataSourceObject {
  count: number,
  totalAmount: number,
  title: string
}

export interface dateObject {
  fromDate: Date,
  toDate: Date,
  filterText: string
}

export interface getDiscountReportObject {
  Discount?: discountObject,
}


export interface discountObject {
  automaticDiscount?: any,
  buyXgetYDiscount?: any,
  manualDiscount?: any,
  payXGetFreeDiscount?: any
}

export interface getStoreObject {
  id: number,
  name: string
}

export interface totalProductsCountObject {
  totalProduct?: number;
}

export interface payxGetFreePaginatorObject {
  new?: boolean,
  count?: number,
  rows?: Array<payxGetFreeListDataSourceObject>
}
export interface payxGetFreeListDataSourceObject {
  checked?: boolean,
  count?: number,
  title?: string,
  totalProductCount?: number
}

export interface totalOfferAmountObject {
  totalOfferAmount?: number
}
