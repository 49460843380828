export class SharedConstant {

    /**
     * Dialog messages for affilate home page
     */
    dialogMessages = {
        'failure': 'Failed to load details',
    }

    /**
 * variable which is used to store dialog type.
 * @type {object}
 */
    dialogType = {
        alert: 'alert',
        confirmation: 'confirmation',
        information: 'information',
        success: 'success',
        failure: 'failure',
        warning: 'warning'
    };

}
