/**
 * AffiliateMarketingSigninComponent used for affiliate
 * Signin,signup,forgot password & change password functionality
 * Author: Balasubramanian M
 */
import { Subscription } from 'rxjs';
import { CommonService } from '@phase-ii/shared';
import { filter, mergeMap } from 'rxjs/operators';
import { Component, HostListener, Inject } from '@angular/core';
import { AuthConstants } from '../../constants/authConstant';
import { ActivatedRoute, Router } from '@angular/router';
import { DeviceDetectorService } from 'ngx-device-detector';
import { AffiliateAuthService } from '../../services/auth.service';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { AuthService, CustomAsyncValidatorService, CustomValidatorService } from '@phase-ii/auth';
import { API, BackendConfigService, DialogService } from '@phase-ii/common';

@Component({
  selector: 'phase-ii-affiliate-marketing-signin',
  templateUrl: './affiliate-marketing-signin.component.html',
  styleUrls: ['./affiliate-marketing-signin.component.scss'],
})
export class AffiliateMarketingSigninComponent extends AuthConstants {
  /**
   * Object to store common page variable
   */
  pageDetails: {
    dot: any,
    loop: any,
    length: number,
    email: string,
    paramUrl: any,
    deviceInfo: any,
    signinToken: any,
    isSuperAdmin: any,
    isLoader: boolean,
    signInUrl: string,
    signUpUrl: string,
    isSignIn: boolean,
    isSignUp: boolean,
    buttonLoader: boolean,
    registrationData: any,
    passwordField: boolean,
    forgotPasswordToken: any,
    isVerifiedToken: boolean,
    changePasswordUrl: string,
    isForgotPassword: boolean,
    isChangePassword: boolean,
    forgotPasswordUrl: string,
    showConfirmPassword: boolean,
    subscriptionObj: Subscription,
  } = {
      dot: [],
      length: 0,
      loop: null,
      email: null,
      paramUrl: null,
      isLoader: true,
      isSignIn: null,
      isSignUp: null,
      deviceInfo: null,
      signinToken: null,
      isSuperAdmin: null,
      buttonLoader: null,
      passwordField: null,
      signInUrl: 'signin',
      signUpUrl: 'signup',
      registrationData: {},
      isVerifiedToken: true,
      isForgotPassword: null,
      isChangePassword: null,
      showConfirmPassword: null,
      forgotPasswordToken: null,
      changePasswordUrl: 'changepassword',
      forgotPasswordUrl: 'forgotpassword',
      subscriptionObj: new Subscription()
    }
  /**
   * Variable which is used to store country code and name
   * @type {any}
   */
  countries: any;
  /**
   * Variable which is used to filtered country code and name
   * @type {any}
   */
  countriesArray: any;
  /**
   * Variable which is used to store state code and name for filter
   * @type {any}
   */
  state: any;
  /**
   * Variable which is used to store state code and name
   * @type {any}
   */
  statesArray: any;
  /**
   * Variable to check accept for terms
   * @type {FormGroup}
   */
  terms = new UntypedFormControl(false)
  /**
   * Variable which is used to search country 
   * @type {FormControl}
   */
  countrySearchFilter = new UntypedFormControl(null);
  /**
   * Variable which is used to search country 
   * @type {FormControl}
   */
  stateSearchFilter = new UntypedFormControl(null);
  /**
   * Form group while signing in
   * @type {FormGroup}
   */
  signInForm = new UntypedFormGroup({});
  /**
   * Form group for sign up and forgot password
   * @type {FormGroup}
   */
  forgotPasswordForm = new UntypedFormGroup({});
  /**
   * Form group for change password
   * @type {FormGroup}
   */
  changePasswordForm = new UntypedFormGroup({});
  /**
   * Variable to save the user details
   * @type {FormGroup}
   */
  userForm: UntypedFormGroup = new UntypedFormGroup({});
  /**
 * Environment variable
 */
  environment: any;

  /**
   * Country list
   */
  countryOptions = [
    { flag: 'in', iconColor: '#E1306C', text: 'IN' },
    { flag: 'us', iconColor: '#0E76A8', text: 'US' },
  ];
  /**
   * Open country menu
   */
  isMenuOpen = false;
  /**
   * Cardwrapper element
   * @type {any}
   */
  cardwrapper: any;
  /**
   * Variable used to store width of each card.
   * @type {number}
   */
  cardWidth: number;
  /**
   * Component constructor to inject reqiured service.
   * @param route 
   * @param router 
   * @param authService 
   * @param commonService 
   * @param dialogService 
   * @param deviceService
   * @param backendConfig
   * @param commonauthService
   */
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private commonService: CommonService,
    private dialogService: DialogService,
    private commonauthService: AuthService,
    private authService: AffiliateAuthService,
    private backendConfig: BackendConfigService,
    private deviceService: DeviceDetectorService,
    @Inject('environment') environment

  ) {
    super();
    this.environment = environment;
  }
  /**
   * Angular lifecycle begins
   */
  ngOnInit(): void {
    this.verification();
    if (this.pageDetails.isSignUp) {
      this.pageDetails.subscriptionObj.add(this.commonService.getCountries(true).subscribe((response) => {
        if (response) {
          this.countriesArray = this.countries = response && response['country'];
        }
      }, (err) => {
        this.dialogService.openDialog({
          actionType: this.dialogType.failure,
          message: this.errorMessage.countryFail
        })
      }));
      this.pageDetails.subscriptionObj.add(this.countrySearchFilter && this.countrySearchFilter.valueChanges.subscribe(res => {
        this.countriesArray = res ? this.countries.filter(val =>
          res && val && (val['name'] || val['country']) && ((val['name'] + '').toLowerCase().includes((res + '').toLowerCase()) ||
            (val['country'] + '').includes(res + '') || ('+' + val['country'] + '').includes(res + ''))
        ) : this.countries;
      }));
    }
  }
  /**
   *  Component afterViewInit life cycle hook.
   */
  ngAfterViewInit() {
    setTimeout(() => {
      this.cardwrapper = document.querySelector('.card-wrapper');
      this.pageDetails.dot[0] = document.querySelector('.box1');
      this.pageDetails.dot[1] = document.querySelector('.box2');
      this.pageDetails.dot[2] = document.querySelector('.box3');
      this.pageDetails.dot[this.pageDetails.length].classList.add('val');
      this.pageDetails.dot[this.pageDetails.length++].firstChild.classList.add('slider');
      this.cardWidth = this.cardwrapper && this.cardwrapper.firstChild && this.cardwrapper.firstChild.clientWidth;
      this.pageDetails.loop = setInterval(() => this.autoPlay(), 3500);
    }, 0)
  }
  /**
   * Infinite scroll for slide
   */
  infinite() {
    if (this.cardwrapper.scrollLeft >= this.cardwrapper.scrollWidth - this.cardwrapper.offsetWidth - 2) {
      this.cardwrapper.classList.add("no-transition");
      this.cardwrapper.scrollLeft = 0;
      this.cardwrapper.classList.remove("no-transition");
    }
  }
  /**
   * Function used to check the current page url and navigate to the particular page
   */
  verification(): void {
    this.pageDetails.subscriptionObj.add(this.route.url.subscribe((res) => {
      if (res && res[0] && res[0].path && res[0].path)
        this.pageDetails.paramUrl = res && res[0] && res[0].path && res[0].path
    }));
    if (this.pageDetails.paramUrl === this.pageDetails.signInUrl) {
      this.pageDetails.isLoader = false;
      this.pageDetails.subscriptionObj.add(this.route.params.pipe(filter((res: any) => {
        if (res && res.token) {
          this.pageDetails.signinToken = res.token;
          return true;
        }
      }), mergeMap(() => {
        return this.authService.verifyUser({ token: this.pageDetails.signinToken })
      })).subscribe((res: any) => {
        if (res) {
          this.pageDetails.isLoader = false;
          if (res && res.result)
            this.dialogService.openDialog({ message: this.errorMessage.TOKENSUCCESS, actionType: this.dialogType.success });
        }
      }, (err) => {
        this.pageDetails.isLoader = false;
      }));
      this.pageDetails.isSignIn = true;
      this.signInForm = new UntypedFormGroup({
        email: new UntypedFormControl(null,
          [Validators.required, Validators.pattern(this.validator.emailValidationPattern)],
        ),
        password: new UntypedFormControl(null, [Validators.required])
      });
    }
    else if (this.pageDetails.paramUrl === this.pageDetails.signUpUrl) {
      this.pageDetails.isLoader = false;
      this.pageDetails.isSignUp = true;
      this.userForm = new UntypedFormGroup({
        name: new UntypedFormControl(null, [Validators.required, Validators.pattern(this.validator.acceptOnlyAlphabets), Validators.maxLength(25)]),
        email: new UntypedFormControl(null,
          {
            validators: [Validators.required, Validators.pattern(this.validator.emailValidationPattern)],
            asyncValidators: CustomAsyncValidatorService.asyncNoEmailValidationForAdminFromLambda(this.authService)
          }
        ),
        password: new UntypedFormControl(null, [Validators.required, Validators.pattern(this.validator.passwordValidationPattern)]),
        state: new UntypedFormControl(null, [Validators.required]),
        country: new UntypedFormControl(null, [Validators.required]),
        addressLine2: new UntypedFormControl(null, [Validators.maxLength(100)]),
        addressLine1: new UntypedFormControl(null, [Validators.required, Validators.maxLength(100)]),
        city: new UntypedFormControl(null, [Validators.required, Validators.maxLength(20), Validators.pattern(this.validator.acceptOnlyAlphabets)]),
        contactNumber: new UntypedFormControl(null, {
          validators: [Validators.required, Validators.maxLength(20), Validators.pattern(this.validator.acceptNumbersFromOne)],
          asyncValidators: [CustomAsyncValidatorService.checkPhoneAlreadyExists(this.commonauthService, null)]
        }),
        zipCode: new UntypedFormControl(null, [Validators.required, Validators.maxLength(15)]),
      },
        CustomValidatorService.formValidation.bind(this)
      )
    }
    else if (this.pageDetails.paramUrl === this.pageDetails.forgotPasswordUrl) {
      this.pageDetails.isLoader = false;
      this.pageDetails.isForgotPassword = true;
      this.forgotPasswordForm = new UntypedFormGroup({
        email: new UntypedFormControl(null,
          {
            validators: [Validators.required, Validators.pattern(this.validator.emailValidationPattern)],
            asyncValidators: [
              CustomAsyncValidatorService.asyncEmailValidationForAdminFromLambda(this.authService)
            ]
          })
      });
    }
    else if (this.pageDetails.paramUrl === this.pageDetails.changePasswordUrl) {
      this.pageDetails.isLoader = true;
      this.pageDetails.isChangePassword = true;
      this.pageDetails.subscriptionObj.add(this.route.params.pipe(filter((res: any) => {
        if (res) {
          this.pageDetails.forgotPasswordToken = res.token;
          return true;
        }
      }),
        mergeMap(() => {
          return this.authService.verifyToken({ token: this.pageDetails.forgotPasswordToken })
        })).subscribe((res: any) => {
          if (res) {
            this.pageDetails.isLoader = false;
            this.pageDetails.isVerifiedToken = true;
            this.pageDetails.email = res && res.result && res.result.email;
            this.changePasswordForm = new UntypedFormGroup({
              password: new UntypedFormControl(null, {
                validators: [Validators.required, Validators.pattern(this.validator.passwordValidationPattern)],
                asyncValidators: CustomAsyncValidatorService.asyncExistsPasswordValidationForAdmin(this.authService, this.pageDetails.email)
              }),
              confirmPassword: new UntypedFormControl(null, [Validators.required]),
            },
              CustomValidatorService.formValidation.bind(this)
            );
          }
        }, (err) => {
          this.pageDetails.isVerifiedToken = false;
          this.pageDetails.isLoader = false;
        }));
    }
  }
  /**
   * Method is used to clear input field after close the drop down
   */
  onCountryFilterClose(): void {
    this.countrySearchFilter.reset();
  }
  /**
   * Method to filter state
   */
  getState() {
    this.userForm.get('state').reset();
    if (this.userForm.get('country').value) {
      this.pageDetails.subscriptionObj.add(this.commonService.getStates(this.userForm.get('country').value.id).subscribe((response) => {
        if (response) {
          this.state = this.statesArray = response['states'];
        }
      }, (err) => {
        this.dialogService.openDialog({
          actionType: this.dialogType.failure,
          message: this.errorMessage.stateFailed
        })
      }));
      this.pageDetails.subscriptionObj.add(this.stateSearchFilter && this.stateSearchFilter.valueChanges.subscribe(res => {
        this.state = res ? this.statesArray.filter(val =>
          res && val && (val['name']) && ((val['name'] + '').toLowerCase().includes((res + '').toLowerCase()) ||
            (val['state'] + '').includes(res + '') || ('+' + val['state'] + '').includes(res + ''))
        ) : this.statesArray;
      }));
    }
    else {
      this.state = null;
    }
  }
  /**
   * Method used to clear the state search filter
   */
  onStateFilterClose(): void {
    this.stateSearchFilter.reset();
  }
  /**
   * Method used for SignUp the user.
   */
  onSignUp(): void {
    if (this.userForm && this.userForm.valid && this.terms && this.terms.value) {
      this.pageDetails.registrationData = {
        email: this.userForm.value.email,
        password: this.userForm.value.password,
        generalDetails: {
          isFromPlatForm: false,
          isFromSuperadmin: false,
          name: this.userForm && this.userForm.value && this.userForm.value.name,
          mobile: this.userForm && this.userForm.value && this.userForm.value.contactNumber.replace(/\D/g, ""),
          address: {
            city: this.userForm && this.userForm.value && this.userForm.value.city,
            pinCode: this.userForm && this.userForm.value && this.userForm.value.zipCode,
            stateId: this.userForm && this.userForm.value && this.userForm.value.state.id,
            state: this.userForm && this.userForm.value && this.userForm.value.state.name,
            countryId: this.userForm && this.userForm.value && this.userForm.value.country.id,
            country: this.userForm && this.userForm.value && this.userForm.value.country.name,
            address1: this.userForm && this.userForm.value && this.userForm.value.addressLine1,
            address2: this.userForm && this.userForm.value && this.userForm.value.addressLine2,
          },
          countryIsoCode: this.userForm && this.userForm.value && this.userForm.value.country.countryIsoCode,
          currencyId: this.userForm && this.userForm.value && this.userForm.value.country.currency.id
        }
      };
      this.onRegister(this.pageDetails.registrationData);
    } else if (this.userForm && this.userForm.invalid) {
      this.userForm.markAllAsTouched();
      this.dialogService.openDialog({ message: this.errorMessage.mandatory_feild_required, actionType: this.dialogType.alert });
    } else if (this.terms && !this.terms.value) {
      this.userForm.markAllAsTouched();
      this.dialogService.openDialog({ message: this.errorMessage.terms, actionType: this.dialogType.alert });
    }
  }
  /**
  * Method used for regsiter the user.
  * @param data which gets the user details
  */
  onRegister(data: any) {
    this.pageDetails.buttonLoader = true;
    this.pageDetails.subscriptionObj.add(this.authService.registerUser(data).subscribe((res: any) => {
      if (res) {
        this.dialogService.openDialog({
          actionType: this.dialogType.success,
          message: this.errorMessage.registerSucess,
        });
        this.userForm.reset();
        this.navigation(this.pageDetails.signInUrl);
        this.pageDetails.buttonLoader = false;
      }
      else {
        this.dialogService.openDialog({
          actionType: this.dialogType.failure,
          message: this.errorMessage.failedtoSignUp,
        });
        this.pageDetails.buttonLoader = false;
      }
    }, (err) => {
      if (err) {
        this.dialogService.openDialog({
          actionType: this.dialogType.failure, message: this.errorMessage.REQUEST_FAILED
        });
        this.pageDetails.buttonLoader = false;
      }
    }));
  }
  /**
   * Method used for Singin the user.
   */
  onSignIn(): void {
    if (this.signInForm && this.signInForm.valid) {
      this.pageDetails.buttonLoader = true;
      this.pageDetails.deviceInfo = this.deviceService.getDeviceInfo();
      const data = {
        email: this.signInForm.get('email') && this.signInForm.get('email').value ? (this.signInForm.get('email').value.trim()).toLowerCase() : null,
        password: this.signInForm.get('password') && this.signInForm.get('password').value,
        userDetails: {
          ipAddress: null,
          browser: this.pageDetails.deviceInfo && this.pageDetails.deviceInfo.browser,
          deviceName: this.pageDetails.deviceInfo && this.pageDetails.deviceInfo.deviceType
        }
      };
      this.pageDetails.subscriptionObj.add(this.authService.signin(data).subscribe((res: any) => {
        this.pageDetails.buttonLoader = false;
        if (res && res.result && res.result.user) {
          this.pageDetails.isSuperAdmin = res.result.user.isSuperadmin;
          if (this.pageDetails.isSuperAdmin) {
            this.router.navigate(["/app/payoutrequest"]);
          }
          else {
            this.router.navigate(["/app/dashboard"]);
          }
        }
        else if (res && res.details == "Invalid credentials") {
          this.dialogService.openDialog({ message: this.errorMessage.incorrectPassword, actionType: this.dialogType.failure });
        } else if (res && res.details == "Not a user") {
          this.dialogService.openDialog({ message: this.errorMessage.emailNotExist, actionType: this.dialogType.alert });
        }
        else if (res && res.details == "Operation timeout") {
          this.dialogService.openDialog({ message: this.errorMessage.REQUEST_FAILED, actionType: this.dialogType.failure });
        } else if (res && res.details == "Suspended Account") {
          this.dialogService.openDialog({ message: this.errorMessage.suspendedAccount, actionType: this.dialogType.failure });
        } else if (res && res.details == "Account inActive") {
          this.dialogService.openDialog({ message: this.errorMessage.inactiveAccount, actionType: this.dialogType.failure });
        }
      }, (err) => {
        this.pageDetails.buttonLoader = false;
      }))
    } else {
      this.dialogService.openDialog({ message: this.errorMessage.mandatory_feild_required, actionType: this.dialogType.alert });
    }
  }
  /**
   * Method for sending mail for resetting password
   */
  sendEmail(): void {
    if (this.forgotPasswordForm && this.forgotPasswordForm.valid) {
      this.pageDetails.buttonLoader = true;
      this.pageDetails.subscriptionObj.add(this.authService.sendEmail({
        email: this.forgotPasswordForm.get('email').value
      }).subscribe((res) => {
        if (res) {
          this.pageDetails.buttonLoader = false;
          this.dialogService.openDialog({
            message: this.errorMessage.resetPassworLink,
            actionType: this.dialogType.success,
          });
          this.router.navigate(['/signin']);
        }
      }, (err) => {
        this.pageDetails.buttonLoader = false;
        this.dialogService.openDialog({
          actionType: this.dialogType.failure,
          message: this.errorMessage.REQUEST_FAILED
        });
      }));
    } else {
      this.dialogService.openDialog({ message: this.errorMessage.invalidEmail, actionType: this.dialogType.alert });
    }
  }
  /**
   * Method which is used to chnage the password.
   */
  changePassword(): void {
    if (this.changePasswordForm && this.changePasswordForm.valid) {
      this.pageDetails.buttonLoader = true;
      this.authService.getBase64String(null, this.changePasswordForm.value.password, API.AFFILIATE_RESET_PASSWORD, 'Registration');
      const token = this.pageDetails.forgotPasswordToken ? this.pageDetails.forgotPasswordToken : '';
      this.pageDetails.subscriptionObj.add(this.authService.resetPassword(token).subscribe(res => {
        if (res) {
          this.pageDetails.buttonLoader = false;
          this.dialogService.openDialog({
            actionType: this.dialogType.success,
            message: this.errorMessage.resetPasswordSuccess,
          });
          this.router.navigate(['/signin']);
        }
      },
        (err) => {
          this.pageDetails.buttonLoader = false;
          this.dialogService.openDialog({
            actionType: this.dialogType.failure,
            message: this.errorMessage.REQUEST_FAILED
          });
        }
      ));
    }
    else {
      this.changePasswordForm.markAllAsTouched();
      this.dialogService.openDialog({ message: this.errorMessage.mandatory_feild_required, actionType: this.dialogType.alert });
    }
  }
  /**
   * Method used to deactivate the canDeactivate guard when the form us dirty.
   * @returns dialog box
   */
  canDeactivate() {
    return this.userForm && !this.userForm.dirty;
  }
  /**
   * Method to navigate
   */
  navigation(url) {
    this.router.navigate([url]);
  }
  /**
   * Function for select the country option
   */
  selectCountryOption(optionText: string) {
    this.backendConfig.getBackendUrl({ info: optionText });
    this.isMenuOpen = false;
  }
  /**
   * Togglemenu to close the country option
   */
  toggleMenu() {
    this.isMenuOpen = !this.isMenuOpen;
  }
  /**
   * Method to get width
   */
  @HostListener('window:resize', [])
  resize() {
    if (!this.cardWidth)
      this.cardWidth = this.cardwrapper && this.cardwrapper.firstChild && this.cardwrapper.firstChild.clientWidth;
  }
  /**
   * Stop sliding
   */
  stop() {
    clearInterval(this.pageDetails.loop);
  }
  /**
   * Method used to for automatic scolling  
   */
  autoSlide() {
    this.pageDetails.loop = setInterval(() => this.autoPlay(), 3500);
  }
  /**
   * Automatic scroll of card element method
   */
  autoPlay() {
    this.pageDetails.dot[this.pageDetails.length - 1].classList.remove('val');
    this.pageDetails.dot[this.pageDetails.length - 1].firstChild.classList.remove('slider');
    if (this.pageDetails.length >= 3)
      this.pageDetails.length = 0;
    this.pageDetails.dot[this.pageDetails.length].classList.add('val');
    this.pageDetails.dot[this.pageDetails.length++].firstChild.classList.add('slider');
    this.cardwrapper.scrollLeft += this.cardWidth;
  }
  /**
   * Unsubscribe all the subscriptions
   */
  ngOnDestroy(): void {
    this.pageDetails.subscriptionObj.unsubscribe();
  }
}