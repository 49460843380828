/**
 * Component used for subscription plan's payment
 * Orginal Author : S.P Priya
 * Author: K. Raja Abishek(CEN282).
 */
import { Component, EventEmitter, HostListener, Inject, Input, NgZone, Output } from '@angular/core';
import { of, Subscription } from 'rxjs';
import { loadStripe, StripeCardElement } from '@stripe/stripe-js';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { CommonConstants } from '../../../../../shared/src/lib/constants/shared-constant';
import { CommonService } from '../../../../../shared/src/lib/services/common.service';
import { filter, mergeMap } from 'rxjs/operators';
import { DialogService } from '../../../../../common/src/lib/services/dialog.service';
import { AuthService } from '@phase-ii/auth';
import { dataConfig } from '../../../../../common/src/lib/services/config';
import { CommonDataService } from '../../../../../shared/src/lib/services/common-data.service';
import { MatTableDataSource } from '@angular/material/table';
import { Location } from '@angular/common';
import { IPayPalConfig, ICreateOrderRequest, IOnApproveCallbackData } from 'ngx-paypal';
import { BillingInfoModel, CardDetails, CountryDetails, DiscountData, GetCountry, GetStates, PaymentDataModel, PaymentLogs, ResponseModel, SavedCard, StateDetails } from '../../models/subscription-plan-payment';
import { SharedService } from '@phase-ii/shared-theme';
import { NavigationStart, Router, Event as NavigationEvent } from '@angular/router';
/**
 * Variable used to load the razorpay pyament form
 */
declare let Razorpay: any;
/**
 * Varaible holds the checkout frames.
 */
declare let Frames: any;

/**
 * Component used for subscription plan's payment
 */
@Component({
  selector: 'phase-ii-subscription-plan-payment',
  templateUrl: './subscription-plan-payment.component.html',
  styleUrls: ['./subscription-plan-payment.component.scss']
})
export class SubscriptionPlanPaymentComponent extends CommonConstants {
  /**
   * Variable used to define paypal config details
   * @type {IPayPalConfig}
   */
  public payPalConfig?: IPayPalConfig;
  /**
   * Array used to push all the subscriptions for subscribe and unsubscribe
   * @type {Array}
   */
  subscriptionArray: Subscription[] = [];
  /**
   * Variable used to store the saved card detail of the store
   * @type {CardDetails}
   */
  customerCardDetails: CardDetails;
  /**
   * Variable used to load the card elements
   * @type {StripeCardElement}
   */
  cardElement: StripeCardElement;
  /**
   * Variable used to load the stripe
   * @type {any}
   */
  stripe: any;
  /**
   * FormGroup used to store the billing address details
   * @type {FormGroup}
   */
  billingInfo: FormGroup<BillingInfoModel>;
  /**
   * Variable used to decide to edit the card or not
   * @type {boolean}
   */
  isEdit: boolean;
  /**
   * Variable used to show or hide the loader
   * @type {boolean}
   */
  isLoading: boolean;
  /**
   * Variable used to store the list of countries from db
   * @type {CountryDetails}
   */
  countries: Array<CountryDetails>;
  /**
   * Variable used to store the list of states relevant to the country from db
   * @type {Array}
   */
  states: Array<StateDetails> = [];
  /**
   * Variable used to get the selected plan details from parent component
   * @type {object}
   */
  @Input() selectedPlanDetails: any;
  /**
   * Variable used to emit when the payment was successfully done
   * @type {EventEmitter}
   */
  @Output() paymentDone = new EventEmitter();
  /**
   * Variable used to check or uncheck the auto renewal option
   * @type {FormControl}
   */
  isAutoRenewal: FormControl<boolean> = new FormControl(true);
  /**
   * Variable used to store the store details
   * @type {any}
   */
  storeDetails: any;
  /**
   * Variable used to store the searched countries
   *  @type {CountryDetails}
   */
  searchCountries: Array<CountryDetails>;
  /**
   * Variable used to store the searched states
   *  @type {StateDetails}
   */
  searchStates: Array<StateDetails>;
  /**
   * Variable which is used to search country 
   * @type {FormControl}
   */
  countrySearchFilter = new FormControl(null);
  /**
   * Variable which is used to search state 
   * @type {FormControl}
   */
  stateSearchFilter = new FormControl(null);
  /**
   * Variable used for button loading during payment
   *  @type {boolean}
   */
  isButtonLoading: boolean;
  /**
   * Variable used to store inprogress payment counts.
   * @type {number}
   */
  paymentCount: number;
  /**
   * Variable used to store plan features
   * @type {Array}
   */
  @Input() selectedPlanFeatures: any = [];
  /**
   * variable used to emit whether the payment page need to be displayed or not
   * @type {EventEmitter}
   */
  @Output() showPayment = new EventEmitter();
  /**
   * Variable used to hold table data
   * @type {MatTableDataSource}
   */
  dataSource = new MatTableDataSource<any>();
  /**
   * Variable used to hold which columns need to be displayed
   * @type {string[]}
   */
  displayColumns: string[] = ['name', 'value'];
  /**
   * variable used to store environment details\
   * @type {any}
   */
  environment: any
  /**
   * variable used to find whether plan payment page called from update plan page
   * @type {boolean}
   */
  @Input() fromUpdatePlan: boolean;
  /**
   * Variable used to store the coupon code details.
   * @type {string}
   */
  couponCode: string;
  /**
   * Variable used to set subscription payment type.
   * @type {string}
   */
  paymentType: string;
  /**
   * Variable used to set subscription payment type.
   * @type
   */
  constants: any = dataConfig;
  /**
   * Variable used to define isProd or not for loading payment scripts.
   * @type {boolean}
   */
  isProd: boolean = false;
  /**
   * Variable used to define isProd or not for loading payment scripts.
   * @type {boolean}
   */
  paymentData: PaymentDataModel;
  /**
   * Variable used to check if razorpay or paypal clicked.
   * @type {boolean}
   */
  isPaymentClicked: boolean;
  /**
   * Variable used to check is from update plan or plan selection.
   * @type {boolean}
   */
  isupgrade: string;
  /**
   * Variable is used to store preProcessOrderId;
   * @type {number}
   */
  preProcessOrderId: number;
  /**
   * Component constructor to inject the required services.
   * @param commonService to use the function calls in common service
   * @param dialogService to use the dialog functions
   * @param authService to get the user details
   * @param zone to re run our zone 
   * @param commonDataService to encrypt and decrypt details
   * @param sharedService to use the function from shared service
   */
  constructor(private commonService: CommonService,
    private dialogService: DialogService,
    private authService: AuthService,
    private zone: NgZone,
    private commonDataService: CommonDataService,
    private _location: Location,
    private sharedService: SharedService,
    private router: Router,
    @Inject('environment') environment) {
    super();
    this.environment = environment;
  }

  /**
   * Component on init life cycle hook
   */
  ngOnInit(): void {
    this.dataSource = this.selectedPlanFeatures && this.selectedPlanFeatures.filter((feature) => feature.value || feature.value > 0);
    this.isLoading = true;
    this.isupgrade = this.selectedPlanDetails && this.selectedPlanDetails.isupgrade ? this.selectedPlanDetails.isupgrade : "false";
    this.subscriptionArray.push(this.authService.user.pipe(
      mergeMap((res: any) => {
        if (res && !this.selectedPlanDetails.isSuperAdmin) {
          this.storeDetails = res;
          if (this.environment && this.environment.app && (this.environment.app === 'prod' || this.environment.app === 'us-prod')) {
            if (this.storeDetails && this.storeDetails.store && this.storeDetails.store.isTestAccount)
              this.isProd = false;
            else this.isProd = true;
          } else this.isProd = false;
          this.storeDetails.address = typeof (this.storeDetails.address) == 'string' ? JSON.parse(this.storeDetails.address) : this.storeDetails.address;
          return of(true);
        } else if (!res && !this.selectedPlanDetails.isSuperAdmin) {
          return this.authService.getCurrentUser(dataConfig.storeAdminCode);
        } else if (this.selectedPlanDetails.isSuperAdmin) {
          return this.authService.getAdminDetails(this.selectedPlanDetails.storeId);
        }
      }), mergeMap((res: any) => {
        if (res && this.selectedPlanDetails.isSuperAdmin) {
          this.storeDetails = res.currentUser;
          this.storeDetails.address = JSON.parse(this.storeDetails.address);
        }
        return this.commonService.getPaymentLog(this.selectedPlanDetails.isMobilePage);
      }), filter((res: PaymentLogs) => {
        this.paymentCount = res.logs;
        if (this.paymentCount > 0) {
          this.isButtonLoading = false;
          this._location.back();
          this.dialog(this.dialogMessages.paymentProcess, this.dialogType.alert);
          return false;
        }
        else {
          if (this.storeDetails && this.storeDetails.purchasedCurrency && this.storeDetails.purchasedCurrency.currencyUnit === "INR") {
            this.paymentType = dataConfig.paymentOption.RAZORPAY;
            this.loadRazorpayScript();
            this.isLoading = false;
            return false;
          } else {
            this.getCountries();
            return true;
          }
        }
      }), mergeMap(() => {
        return this.commonService.getSavedCardDetails(this.storeDetails && this.storeDetails.store && this.storeDetails.store.storeId, this.storeDetails && this.storeDetails.store && this.storeDetails.store.isTestAccount);
      })).subscribe({
        next: (response: SavedCard) => {
          if (response && response['details'] && response['details']['paymentType'])
            this.paymentType = response['details']['paymentType'];
          if (response && response['details'] && response['details']['cardDetails']) {
            this.customerCardDetails = response['details']['cardDetails'];
            this.isLoading = false;
          } else if (this.paymentType && this.paymentType === dataConfig.paymentOption.CHECKOUT && !this.customerCardDetails) {
            this.isLoading = false;
            this.loadCheckoutScript().then(() => {
              this.loadCheckoutElements();
            });
          } else if (this.paymentType === dataConfig.paymentOption.PAYPAL) {
            this.createPreProcessEntry().then(()=>{
              this.initConfig();
              this.isLoading = false;
            }, ()=>{
              this.isLoading = this.isButtonLoading = false;
              this.dialog(this.message.somethingWrong, this.dialogType.failure);
              this.navigateToPlans();
            });
          } else if (!this.customerCardDetails && this.paymentType === dataConfig.paymentOption.STRIPE) {
            this.load();
            this.isLoading = false;
          }
        }, error: () => {
          this.isLoading = false;
          this.navigateToPlans();
          this.dialog('Failed to get payment details', this.dialogType.failure);
        }
      }));
    this.preventNavigation();
  }
  /**
   * Method used to prevent navigation.
   */
  preventNavigation(): void {
    let isNavigating: boolean = false;
    this.subscriptionArray.push(this.router.events.subscribe((event: NavigationEvent) => {
      if (event instanceof NavigationStart && !isNavigating && (this.isPaymentClicked || (this.billingInfo && this.billingInfo.dirty))) {
        const confirmNavigation = confirm('You have unsaved changes. Do you really want to leave?');
        if (!confirmNavigation) {
          isNavigating = true
          this.router.navigateByUrl(this.router.url).then(() => {
            isNavigating = false;
          });
        }
      }
    }));
  }
  /**
   * Method used to prevent page from reloading.
   * @param {BeforeUnloadEvent} $event to get event details
   */
  @HostListener('window:beforeunload', ['$event'])
  unloadNotification($event: BeforeUnloadEvent): void {
    if ((this.billingInfo && !this.billingInfo.pristine) || this.isPaymentClicked) {
      $event.preventDefault();
      $event.returnValue = 'Are you sure you want to leave? Changes you made may not be saved.';
    }
  }
  /**
   * Method used to prevent keyboard combinations from reloading.
   * @param {KeyboardEvent} event to get keyboard events.
   */
  @HostListener('window:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    if (event.key === 'F5' || (event.ctrlKey && event.key === 'r') || (event.ctrlKey && event.shiftKey && event.key === 'R')) {
      if ((this.billingInfo && !this.billingInfo.pristine) || this.isPaymentClicked)
        event.preventDefault();
    }
  }
  /**
   * Function used to load the stripe
   */
  async load(): Promise<void> {
    if (!this.cardElement) {
      this.stripe = await loadStripe(this.isProd ? dataConfig.stripeProductionKey : dataConfig.stripeKey);
      this.loadStripeElements();
      this.onNewCard();
    }
  }
  /**
   * Function used to load the razorpay script
   */
  loadRazorpayScript(): void {
    const razorpayScript = document.createElement("script");
    razorpayScript.setAttribute("src", "https://checkout.razorpay.com/v1/checkout.js");
    razorpayScript.setAttribute("type", "text/javascript");
    document.head.appendChild(razorpayScript);
  }
  /**
  * Function used to load the checkout script
  */
  loadCheckoutScript(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      try {
        const script = document.getElementsByTagName('script');
        if (script && script.length > 0) {
          const index = [].findIndex.call(script, (src) => src && src.src && src.src === 'https://cdn.checkout.com/js/framesv2.min.js');
          if (index !== -1) {
            script[index] && script[index].remove();
            if (Frames !== null) {
              Frames && Frames.Events && Frames.removeAllEventHandlers(Frames.Events.CARD_VALIDATION_CHANGED);
              Frames && Frames.Events && Frames.removeAllEventHandlers(Frames.Events.FRAME_VALIDATION_CHANGED);
              Frames && Frames.Events && Frames.removeAllEventHandlers(Frames.Events.CARD_TOKENIZED);
              Frames && Frames.Events && Frames.removeAllEventHandlers(Frames.Events.CARD_TOKENIZATION_FAILED);
            }
          }
        }
        const checkoutScript = document.createElement("script");
        checkoutScript.setAttribute("src", "https://cdn.checkout.com/js/framesv2.min.js");
        checkoutScript.setAttribute("type", "text/javascript");
        document.head.appendChild(checkoutScript);
        this.onNewCard();
        checkoutScript.onload = function () {
          resolve(true);
        };
      }
      catch (err) {
        reject(false);
      }
    })
  }
  /**
   * getCountries method used to fetch the country details 
   */
  getCountries(): void {
    this.subscriptionArray.push(this.commonService.getCountries().subscribe((res: GetCountry) => {
      if (res && res.country && res.country.length) {
        this.countries = res.country;
        this.searchCountries = res.country;
      }
    }));
  }
  /**
  * Function used to get the states of particular country
  */
  getStates(): void {
    if (this.billingInfo && this.billingInfo.value && this.billingInfo.value.state) {
      this.billingInfo.patchValue({ 'state': null });
    }
    if (this.billingInfo.value.country && this.billingInfo.value.country.id) {
      this.billingInfo.get('state').enable();
      this.subscriptionArray.push(this.commonService.getStates(this.billingInfo.value.country.id).subscribe((response: GetStates) => {
        if (response) {
          this.states = response.states;
          this.searchStates = response.states;
        }
      }));
    }
  }
  /**
   * Function used to get the searched countries result
   * @param {string} value defines the country name
   */
  onCountrySearchFilter(value: string): void {
    if (value && value.length) {
      this.searchCountries = this.searchCountry(value);
    }
    else {
      this.countrySearchFilter.setValue(null);
      this.searchCountries = [];
      this.searchCountries = this.countries;
    }
  }
  /**
   * Function used to get the searched states
   * @param {string} value defines the state name
   */
  onStateSearchFilter(value: string): void {
    if (value && value.length)
      this.searchStates = this.searchState(value);
    else {
      this.stateSearchFilter.setValue(null);
      this.searchStates = [];
      this.searchStates = this.states;
    }
  }
  /**
   * Function used to search the country from the country list
   * @param {string} value defines the name of the country
   * @returns the selected countries based on the given name
   */
  searchCountry(value: string): Array<CountryDetails> {
    const filterValue = value && value.toLowerCase();
    return this.countries.filter(option => option && option.name && option.name.toLowerCase().startsWith(filterValue));
  }
  /**
   * Function used to search the state from the state list
   * @param {string} value defines the name of the state
   * @returns the selected states based on the given name
   */
  searchState(value: string): Array<StateDetails> {
    const filterValue = value && value.toLowerCase();
    return this.states.filter(option => option && option.name && option.name.toLowerCase().startsWith(filterValue));
  }
  /**
   * Function used to close the searched result for countries
   */
  onCountryFilterClose(): void {
    this.countrySearchFilter.setValue(null);
    this.searchCountries = [];
    this.searchCountries = this.countries;
  }
  /**
   * Function which is used for clear the state filter values
   */
  clearStateFilter(): void {
    this.stateSearchFilter.setValue(null);
    this.searchStates = [];
    this.searchStates = this.states;
  }
  /**
   * Function used to create new card
   */
  onNewCard(): void {
    this.billingInfo = new FormGroup({
      name: new FormControl(null, [
        Validators.required,
        Validators.pattern(dataConfig.patternValidators.acceptOnlyAlphabets),
      ]),
      doorNo: new FormControl(null, [
        Validators.pattern(dataConfig.patternValidators.DoorNoValidationPattern),
      ]),
      streetName: new FormControl(null, [
        Validators.required,
        Validators.maxLength(50),
        Validators.pattern(dataConfig.patternValidators.addressValidationPattern),
      ]),
      zipCode: new FormControl(null, [
        Validators.required, Validators.maxLength(15)
      ]),
      city: new FormControl(null, [
        Validators.required,
        Validators.maxLength(50),
        Validators.pattern(dataConfig.patternValidators.acceptOnlyAlphabets),
      ]),
      state: new FormControl(null, [
        Validators.required
      ]),
      country: new FormControl(null, [
        Validators.required
      ])
    });
    this.billingInfo.get('state').disable();
  }
  /**
   * Function used to load the stripe card elements
   */
  loadStripeElements(): void {
    const rootNode = document.getElementById('cardInfoGroup');
    const cardWrapper = document.createElement('div');
    if (rootNode)
      rootNode.appendChild(cardWrapper);
    const elements = this.stripe && this.stripe.elements();
    // setup card Element
    this.cardElement = elements && elements.create('card', {
      style: {
        base: {
          color: 'currentColor',
          lineHeight: '40px',
          fontSize: '18px'
        },
      },
      hidePostalCode: true,
    });
    this.cardElement && this.cardElement.mount(cardWrapper);
  }
  /**
   * Function used to load the checkout card elements
   */
  async loadCheckoutElements(): Promise<void> {
    let errorStack = [], errorMessage, errorMessageElement = document.querySelector(".error-message");
    const publicApiKey = this.isProd ? dataConfig.checkoutProductionPublicApiKey : dataConfig.checkoutPublicApiKey;
    Frames.init({ publicKey: publicApiKey, modes: [Frames.modes.DISABLE_COPY_PASTE] });
    Frames.addEventHandler(Frames.Events.FRAME_VALIDATION_CHANGED, onValidationChanged);
    Frames.addEventHandler(Frames.Events.CARD_TOKENIZED, (event) => {
      this.zone.run(() => {
        this.saveCard(event);
      })
    });
    Frames.addEventHandler(Frames.Events.CARD_VALIDATION_CHANGED, () => {
      errorMessageElement.textContent = !Frames.isCardValid() ? 'Card is not valid..!' : '';
    }
    );
    Frames.addEventHandler(Frames.Events.CARD_TOKENIZATION_FAILED, () => {
      Frames.enableSubmitForm();
      this.isButtonLoading = false;
    }
    );

    /**
     * Function used for CheckoutDotCom Payment validation
     * @param {any} event Parameters pass validation details
     */
    function onValidationChanged(event: any): any {
      if (event && event.element) {
        if (!event.isValid && !event.isEmpty) {
          errorStack.push(event.element);
        } else {
          errorStack = errorStack && errorStack.filter((element) => {
            return element !== event.element;
          });
        }
        if (errorStack.length > 0)
          errorMessage = getErrorMessage(errorStack[errorStack.length - 1]);
        else if (!event.isFormValid)
          errorMessage = 'Card is not valid..!';
        else
          errorMessage = null;
        if (errorMessageElement) {
          errorMessageElement.textContent = errorMessage;
        }
      }
    }
    /**
     * Function used to choose CheckoutDotCom payment error message
     * @param {string} element Parameters pass error element name
     */
    function getErrorMessage(element: string): string {
      const errors = {
        "card-number": "Please enter a valid card number",
        "expiry-date": "Please enter a valid expiry date",
        "cvv": "Please enter a valid cvv code",
      };
      return errors[element];
    }
  }
  /**
   * This function is used to submit checkout payment form.
   */
  async onSubmit(): Promise<void> {
    if (this.billingInfo && this.billingInfo.valid && this.billingInfo.value) {
      if (this.paymentType === dataConfig.paymentOption.CHECKOUT) {
        try {
          this.isButtonLoading = true;
          Frames.cardholder = {
            billingAddress: {
              addressLine1: this.billingInfo.value.doorNo,
              addressLine2: this.billingInfo.value.streetName,
              zip: this.billingInfo.value.zipCode,
              city: this.billingInfo.value.city,
              state: this.billingInfo.value.state && this.billingInfo.value.state.code,
              country: this.billingInfo.value.country && this.billingInfo.value.country.countryIsoCode
            }
          };
          await Frames.submitCard();
        } catch (err) {
          this.isButtonLoading = false;
          Frames.enableSubmitForm();
          this.dialog(err && err.message ? err.message : err, this.dialogType.failure);
        }
      } else if (this.paymentType === dataConfig.paymentOption.STRIPE) {
        await this.stripe && this.stripe.createToken(this.cardElement, {
          name: this.billingInfo.value.name,
          address_line1: this.billingInfo.value.doorNo + ' ' + this.billingInfo.value.streetName,
          address_line2: this.billingInfo.value.doorNo + ' ' + this.billingInfo.value.streetName,
          address_city: this.billingInfo.value.city,
          address_state: this.billingInfo.value.state && this.billingInfo.value.state.name,
          address_zip: this.billingInfo.value.zipCode,
          address_country: this.billingInfo.value.country && this.billingInfo.value.country.countryIsoCode
        }).then((obj) => {
          if (obj['error']) {
            this.dialog(obj['error'].message, this.dialogType.failure);
            this.isButtonLoading = false;
          }
          else if (obj.token && obj.token.id) {
            this.isButtonLoading = false;
            this.saveCard(obj);
          }
        });
      }
    } else {
      this.isButtonLoading = false;
      this.billingInfo.markAllAsTouched();
    }
  }
  /**
  * Method used to initialize paypal button function
  */
  private initConfig(): void {
    try {
      this.isLoading = true;
      this.payPalConfig = {
        currency: this.storeDetails && this.storeDetails.purchasedCurrency && this.storeDetails.purchasedCurrency.currencyUnit,
        clientId: this.isProd ? dataConfig.paypalProductionPublicApiKey : dataConfig.paypalPublicApiKey,
        onClick: () => {
          this.isPaymentClicked = true;
        },
        createOrderOnClient: (): ICreateOrderRequest => <ICreateOrderRequest>{
          intent: 'CAPTURE',
          purchase_units: [
            {
              amount: {
                currency_code: this.storeDetails && this.storeDetails.purchasedCurrency && this.storeDetails.purchasedCurrency.currencyUnit,
                value: this.selectedPlanDetails && this.selectedPlanDetails.currentAmount && this.selectedPlanDetails.currentAmount.toFixed(2),
                breakdown: {
                  item_total: {
                    currency_code: this.storeDetails && this.storeDetails.purchasedCurrency && this.storeDetails.purchasedCurrency.currencyUnit,
                    value: this.selectedPlanDetails && this.selectedPlanDetails.currentAmount && this.selectedPlanDetails.currentAmount.toFixed(2),
                  }
                }
              }
            }
          ],
          application_context: {
            shipping_preference: 'NO_SHIPPING'
          }
        },
        style: {
          label: 'paypal',
          layout: 'vertical',
          color: 'silver'
        },
        onApprove: (data: IOnApproveCallbackData, actions: any): void => {
          if (actions && actions.order) {
            const funcName = 'capture';
            actions.order[funcName]().then(details => {
              this.zone.run(() => {
                if (details && details.purchase_units && details.purchase_units[0] && details.purchase_units[0].payments.captures &&
                  details.purchase_units[0].payments.captures[0] && details.purchase_units[0].payments.captures[0].id && details.purchase_units[0].payments.captures[0].status &&
                  (details.purchase_units[0].payments.captures[0].status == 'COMPLETED' || details.purchase_units[0].payments.captures[0].status == 'PENDING')) {
                  this.payment({
                    orderCode: details.purchase_units && details.purchase_units[0] && details.purchase_units[0].payments.captures &&
                      details.purchase_units[0].payments.captures[0] && details.purchase_units[0].payments.captures[0].id,
                    preProcessOrderId: this.preProcessOrderId
                  });
                } else
                  this.dialog('Card details invalid! Provide valid details', this.dialogType.alert);
              });
            }).catch(err => {
              this.zone.run(() => {
                this.payment({ errorMessage: err && err.message ? err.message : 'Error in paypal payment' });
              });
            });
          }
        },
        onCancel: (): void => {
          this.isPaymentClicked = false;
        },
        onError: (err: any): void => {
          this.payment({ errorMessage: err && err.message ? err.message : 'Error in paypal payment' });
        }
      };
    } catch (err) {
      this.payment({ errorMessage: err && err.message ? err.message : 'Error in paypal payment' });
    }
    this.isLoading = false;
  }
  /**
   * Function called when you want to edit your card details
   */
  editCard(): void {
    this.isEdit = true;
    this.isLoading = true;
    this.loadCard();
    this.isLoading = false;
  }
  /**
   * Function called when cancel the card  edit option
   */
  cancelCard(): void {
    this.isButtonLoading = false;
    this.isEdit = false;
    this.billingInfo.reset();
    if (this.paymentType === dataConfig.paymentOption.STRIPE) {
      this.cardElement = null;
      const rootNode: HTMLElement = document.getElementById('cardInfoGroup');
      if (rootNode && rootNode.firstChild) {
        rootNode.removeChild(rootNode.firstChild);
      }
    }
  }
  /**
   * Function used to get the token for new card payment
   * @param {any} event to get event from third party payments.
   */
  saveCard(event?: any): void {
    if (this.selectedPlanDetails && this.selectedPlanDetails.currentAmount) {
      let data;
      this.isButtonLoading = true;
      if (this.paymentType === dataConfig.paymentOption.CHECKOUT) {
        data = {
          isCardEntry: true,
          type: 'token',
          token: event && event.token,
          storeName: this.storeDetails && this.storeDetails.store && this.storeDetails.store.name,
          name: this.billingInfo && this.billingInfo.value && this.billingInfo.valid && this.billingInfo.value.name
        }
      } else if (this.paymentType === dataConfig.paymentOption.STRIPE) {
        data = {
          token: event && event.token && event.token.id,
          isCardEntry: true,
          name: this.storeDetails && this.storeDetails.store && this.storeDetails.store.name
        }
      }
      this.isButtonLoading = false;
      this.payment(data);
    } else {
      this.payment();
    }
  }
  /**
   * Function used to make payment for the plan
   * @param {any} data argument which will have data required for payment
   */
  payment(data?: any): void {
    this.isButtonLoading = true;
    const { DiscountCode = null, currentAmount } = this.selectedPlanDetails;
    const { store: { id, storeId, name, isTestAccount = false }, purchasedCurrency: { currencyUnit = 'USD' } = {}, email } = this.storeDetails;
    if (this.selectedPlanDetails && this.selectedPlanDetails.currentAmount) {
      if (this.storeDetails && this.storeDetails.purchasedCurrency && this.storeDetails.purchasedCurrency.currencyUnit !== "INR") {
        if (!data && this.paymentType === dataConfig.paymentOption.CHECKOUT) {
          data = { type: 'id', token: this.customerCardDetails && this.customerCardDetails.customer }
        } else if (!data && this.paymentType === dataConfig.paymentOption.STRIPE) {
          data = { customerId: this.customerCardDetails && this.customerCardDetails.customer };
        }
        data = {
          ...data, ...{
            isTestStore: isTestAccount,
          }
        };
      }
    }
    data = {
      ...data,
      ...{
        storeId: id,
        storeUuid: storeId,
        amount: currentAmount.toFixed(2),
        currency: currencyUnit,
        paymentType: this.paymentType,
        discountCode: DiscountCode, email,
        storeName: name
      }
    };
    if (this.paymentType === dataConfig.paymentOption.CHECKOUT || this.paymentType === dataConfig.paymentOption.STRIPE) {
      data['isAutoRenewal'] = this.isAutoRenewal.value;
      this.createPreProcessEntry().then((preProcessOrderId: number) => {
        Object.assign(data, { preProcessOrderId });
        this.paymentDone.emit({ data: data });
      }, () => {
        this.isButtonLoading = false;
        this.dialog(this.message.somethingWrong, this.dialogType.failure);
        this.navigateToPlans();
      });
    } else {
      this.paymentDone.emit({ data: data });
      this.isButtonLoading = false;
    }
    if (data && data.isCardEntry && this.paymentType === dataConfig.paymentOption.CHECKOUT) {
      Frames.enableSubmitForm();
    }
  }
  /**
   * Function used to create the order in razorpay
   */
  createOrderInRazorpay(): void {
    this.isButtonLoading = true;
    const { currentAmount } = this.selectedPlanDetails;
    const { store: { id }, purchasedCurrency: { currencyUnit = 'INR' } } = this.storeDetails;
    let option = {
      amount: currentAmount >= 0 && this.commonDataService.encryptDetails(currentAmount.toFixed(2)),
      currency: currencyUnit,
      storeId: id,
      transactionType: 'SUBSCRIPTION PLAN',
      isTestStore: this.storeDetails && this.storeDetails.store && this.storeDetails.store.isTestAccount
    };
    this.createPreProcessEntry().then((preProcessOrderId: number) => {
      Object.assign(option, { preProcessOrderId });
      this.isButtonLoading = true;
      this.subscriptionArray.push(this.commonService.createOrderInRazorpay(option).subscribe({
        next: (response) => {
          this.isButtonLoading = false;
          if (response && response['order'] && response['order'].id) {
            this.payWithRazor(response['order'], { preProcessOrderId, paymentOrderId: response['paymentOrderId'] });
          } else {
            this.payment({ errorMessage: "Failed to create the order in razorpay" });
          }
        }, error: (error) => {
          this.payment({ errorMessage: (error && error.error && error.error.error) ? error.error.error.error : "Failed to create the order in razorpay" });
        }
      }));
    }, ()=>{
      this.isButtonLoading = false;
      this.dialog(this.message.somethingWrong, this.dialogType.failure);
      this.navigateToPlans();
    });
  }
  /**
   * Method used to create preProcess entry.
   * @returns {number} preProcessOrderId
   */
  createPreProcessEntry(): Promise<number> {
    return new Promise((resolve, reject) => {
      const { planId, Sk, isupgrade = "false", DiscountCode = null, PlanName, FrequencyType, currentAmount } = this.selectedPlanDetails;
      const { store: { id, storeId, name, isTestAccount = false }, purchasedCurrency: { currencyUnit = 'INR' }, email } = this.storeDetails;
      this.paymentData = {
        body: {
          selectedplanDetails: {
            planId, Sk, isupgrade
          },
          data: {
            data: {
              storeId: id,
              storeUuid: storeId,
              amount: currentAmount.toFixed(2),
              currency: currencyUnit,
              paymentType: this.paymentType,
              discountCode: DiscountCode, email,
              storeName: name,
              isTestStore: this.storeDetails && this.storeDetails.store && this.storeDetails.store.isTestAccount
            }
          },
          mailContent: {
            PlanName, FrequencyType,
            storeName: name, isTestAccount
          }
        }, storeId
      };
      const data = {
        data: this.paymentData,
        type: 'SUBSCRIPTION PLAN'
      };
      this.isButtonLoading = true;
      this.subscriptionArray.push(this.sharedService.createPreProcessOrder(data, id).subscribe({
        next: (res: ResponseModel) => {
          if (res && res.success && res.result) {
            this.preProcessOrderId = res.result['id'];
            resolve(this.preProcessOrderId);
          }
          this.isButtonLoading = false;
        },
        error: (e) => {
          this.isButtonLoading = false;
          reject(e);
        }
      }));
    });
  }
  /**
   * Method checkDiscountExists used to check the given coupon code is valid or not and appplies the coupon code.
   * @param {string} code having coupon code.
   */
  checkDiscountExists(code?: string): void {
    this.isLoading = true;
    let isCodeExist: boolean = false;
    if (this.paymentType === dataConfig.paymentOption.STRIPE) {
      this.cardElement = null;
      const rootNode: HTMLElement = document.getElementById('cardInfoGroup');
      if (rootNode && rootNode.firstChild) {
        rootNode.removeChild(rootNode.firstChild);
      }
    }
    let data: DiscountData = {
      selectedplanDetails: {
        planId: this.selectedPlanDetails.planId,
        Sk: this.selectedPlanDetails.Sk
      },
      storeId: this.storeDetails && this.storeDetails.store && this.storeDetails.store.storeId
    };
    this.selectedPlanDetails['DiscountCode'] = null;
    if (code) {
      data['discountCode'] = code;
      const discountData = { todaysDate: new Date(), code: code };
      this.subscriptionArray.push(this.commonService.checkSignupDiscountExists({ data: JSON.stringify(discountData) }).subscribe({
        next: (res: any) => {
          if (res && res.discount && res.discount.discountValue) {
            isCodeExist = true;
            this.isLoading = false;
            this.getDiscountValues(data, code, isCodeExist);
          } else {
            this.couponCode = null;
            this.dialog('This coupon code does not exist', this.dialogType.alert);
            if (((this.isEdit && this.selectedPlanDetails.currentAmount > 0) || !this.customerCardDetails))
              this.loadCard();
            this.isLoading = false;
          }
        }, error: () => {
          this.isLoading = false;
          this.dialog('Failed to get discount details', this.dialogType.failure);
          this.navigateToPlans();
        }
      }));
    } else {
      this.couponCode = null;
      this.getDiscountValues(data, code, isCodeExist);
    }
  }
  /**
   * Method is used to get discount values.
   * @param {DiscountData} data to get body data.
   * @param {string} code to get discount code.
   * @param {boolean} isCodeExist to get is code exist or not.
   */
  getDiscountValues(data: DiscountData, code: string, isCodeExist: boolean): void {
    this.isLoading = true;
    const fromUpdatePlan = this.fromUpdatePlan ? this.fromUpdatePlan : false;
    if ((code && isCodeExist) || !code && this.selectedPlanDetails) {
      this.subscriptionArray.push((!this.selectedPlanDetails.isMobilePage ? this.commonService.getPaymentData(data, fromUpdatePlan) : this.authService.getMobilePlanSettings(data.storeId, code)).subscribe({
        next: (selectedPlanDetails: any) => {
          if (selectedPlanDetails && selectedPlanDetails.success && selectedPlanDetails.detail) {
            this.selectedPlanDetails = this.selectedPlanDetails.isMobilePage ? { isMobilePage: true, isupgrade: this.isupgrade } : { isupgrade: this.isupgrade };
            Object.assign(this.selectedPlanDetails, selectedPlanDetails.detail);
            this.isLoading = false;
          }
          if (((this.isEdit && this.selectedPlanDetails.currentAmount > 0) || !this.customerCardDetails))
            this.loadCard();
        }, error: (e) => {
          this.isLoading = false;
          this.dialog(e && e.error && e.error.error && e.error.error.details ? e.error.error.details : 'Failed to get discount details', this.dialogType.failure);
          this.navigateToPlans();
        }
      }));
    }
  }
  /**
   * Method which is used to call razor fucntion for payment
   * @param {any} val parameter used for razorpay credentials
   * @param {number} preProcessOrderId to get preproccessorder table id.
   */
  payWithRazor(val: any, { preProcessOrderId, paymentOrderId }): void {
    if (val) {
      const options: any = {
        key: val.keyId,
        amount: val.amount,
        currency: val.currency,
        order_id: val.id,
        image: `${this.environment.AWSImageUrl}zblogo.png`,
        notes: {
          planName: this.selectedPlanDetails && this.selectedPlanDetails.PlanName,
          planFrequency: this.selectedPlanDetails && this.selectedPlanDetails.FrequencyType,
          preProcessOrderId, paymentOrderId,
          AccessKey: this.environment.AccessKey,
          storeDetails: JSON.stringify({
            email: this.storeDetails && this.storeDetails.email,
            name: this.storeDetails && this.storeDetails.firstName,
            storeName: this.storeDetails && this.storeDetails.store && this.storeDetails.store.name,
            isTestStore: this.storeDetails && this.storeDetails.store && this.storeDetails.store.isTestAccount
          }),
          storeId: this.storeDetails && this.storeDetails.store && this.storeDetails.store.id,
          isSubscription: true,
          transactionType: 'SUBSCRIPTION PLAN'
        },
        modal: {
          // We should prevent closing of the form when esc key is pressed.
          escape: false,
        },
        theme: {
          color: '#005374'
        }
      };
      options.handler = ((response: any) => {
        this.zone.run(() => {
          if (response && response.razorpay_payment_id) {
            this.isPaymentClicked = false;
            this.payment({ orderCode: response.razorpay_payment_id });
          } else if (response && response.error) {
            this.payment({ errorMessage: "Error during razorpay Payment" });
          }
        });
      });
      options.modal.ondismiss = (() => {
        this.zone.run(() => {
          this.isPaymentClicked = false;
          // handle the case when user closes the form while transaction is in progress
          this.payment({ errorMessage: "Your transaction has been cancelled" });
        });
      });
      const rzp = new Razorpay(options);
      this.isPaymentClicked = true;
      rzp.open();
    }
  }
  /**
   * Method used to navigate back to the plan selection
   */
  navigateToPlans(): void {
    if (!this.isButtonLoading)
      this.showPayment.emit(false);
  }
  /**
   * To indicate during leaving of the page
   * @returns the form gets dirty or not
   */
  canDeactivate(): boolean {
    return !this.billingInfo.dirty && this.billingInfo.valid;
  }
  /**
   * Method is used to open the dialog.
   * @param {string} message to get dialog message.
   * @param {string} type to get dialog type.
   */
  dialog(message: string, type: string): void {
    this.dialogService.openDialog({
      message: message,
      actionType: type,
      button: { right: this.buttonText.ok }
    });
  }
  /**
   * This method is used to load the card element from third party.
   */
  loadCard(): void {
    if (this.paymentType === dataConfig.paymentOption.CHECKOUT) {
      this.loadCheckoutScript().then(() => {
        this.loadCheckoutElements();
      });
    } else if (this.paymentType === dataConfig.paymentOption.STRIPE) {
      this.load();
    }
  }
  /**
   * Component ondestroy life cycle hook.
   * All subscriptions are unsubscribe here.
   */
  ngOnDestroy(): void {
    if (this.paymentType === dataConfig.paymentOption.STRIPE) {
      this.cardElement = null;
      const rootNode = document.getElementById('cardInfoGroup');
      if (rootNode && rootNode.firstChild) {
        rootNode.removeChild(rootNode.firstChild);
      }
    }
    if (this.subscriptionArray && this.subscriptionArray.length) {
      this.subscriptionArray.forEach((item) => {
        if (item) { item.unsubscribe(); }
      });
    }
    const script = document.getElementsByTagName('script');
    if (script && script.length > 0 && this.paymentType === dataConfig.paymentOption.CHECKOUT) {
      const index = [].findIndex.call(script, (src) => src && src.src && src.src === 'https://cdn.checkout.com/js/framesv2.min.js');
      if (index !== -1) {
        script[index] && script[index].remove();
        if (Frames !== null) {
          Frames && Frames.Events && Frames.removeAllEventHandlers(Frames.Events.CARD_VALIDATION_CHANGED);
          Frames && Frames.Events && Frames.removeAllEventHandlers(Frames.Events.FRAME_VALIDATION_CHANGED);
          Frames && Frames.Events && Frames.removeAllEventHandlers(Frames.Events.CARD_TOKENIZED);
          Frames && Frames.Events && Frames.removeAllEventHandlers(Frames.Events.CARD_TOKENIZATION_FAILED);
        }
      }
    }
  }
}