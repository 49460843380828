/**
 * TermsOfServiceComponent used for terms and conditions.
 * Author: Balasubramanian M
 */
import { Subscription } from 'rxjs';
import { Component } from '@angular/core';
import { CommonDataService } from '@phase-ii/shared';
import { AffiliateAuthService } from '../../services/auth.service';
@Component({
  selector: 'phase-ii-terms-and-service',
  templateUrl: './terms-and-service.component.html',
  styleUrls: ['./terms-and-service.component.scss'],
})
export class TermsAndServiceComponent {
  /**
   * Loader flags to display loader icon.
   */
  isLoading: any = true;
  /**
   * Variable used to store terms & policy
   */
  terms: any;
  /**
  * Subscriptionobj for managing observables.
  */
  subscriptionObj = new Subscription();
  /**
   * policyHeading used to store policy heading.
   */
  policyHeading = {
    title: "Service Agreement"
  };
  /**
   * Component constructor to inject reqiured service.
   * @param authService 
   */
  constructor(private authService: AffiliateAuthService, private common: CommonDataService) {
  }
  /**
   * Initiate Angular life cycle hooks
   */
  ngOnInit(): void {
    this.subscriptionObj.add(this.authService.getTerms().subscribe(res => {
      if (res && res['result'] && res['result']['termsOfService']) {
        this.terms = res['result']['termsOfService'];
        this.terms = this.common.decryptDetails(this.terms);
      }
      this.isLoading = false;
    }, (err) => {
      this.isLoading = false;
    }));
  }
  /**
   * Angular life cycle hook which is used to unsubscribe the legal details subscription.
   */
  ngOnDestroy(): void {
    this.subscriptionObj.unsubscribe();
  }
}
