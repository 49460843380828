import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { MatRadioModule } from '@angular/material/radio';
import { MatSliderModule } from '@angular/material/slider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatSelectModule } from '@angular/material/select';
import { MatMenuModule } from '@angular/material/menu';
import { MatTabsModule } from '@angular/material/tabs';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatTableModule } from '@angular/material/table';
import { MatCardModule } from '@angular/material/card';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatChipsModule } from '@angular/material/chips';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatStepperModule } from '@angular/material/stepper';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatSortModule } from '@angular/material/sort';
import { MatNativeDateModule } from '@angular/material/core';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatTreeModule } from '@angular/material/tree';
import { MatDividerModule } from '@angular/material/divider';
// import { AgmCoreModule } from '@agm/core';
import { RecaptchaFormsModule, RecaptchaModule } from 'ng-recaptcha';
import { SantizerPipe } from './pipes/sanitize.pipe';
// import { DynamicTableModule } from 'dynamic-table';
import { HttpRoutingService } from './services/httpRouting.service';
import { CenLoaderModule } from 'cen-loader';
import { MenuService } from './services/menu.service';
import { DialogService } from './services/dialog.service';
import { DialogComponent } from './components/dialog/dialog.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MenuPipePipe } from './pipes/menu-pipe.pipe';
import { DeferLoadModule } from '@trademe/ng-defer-load';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { RouterModule } from '@angular/router';
import { TrimValueAccessorDirective } from './directive/trimValue';
import { TitleCasePipe } from '@angular/common';
import { ContainerDirective } from './directive/container.directive';
import { SentenceCasePipe } from './pipes/sentence-case.pipe';
import { BadgeListDesignDirective } from './directive/badge-list-design.directive';
import { MatRippleModule } from '@angular/material/core';
import { SnackbarComponent } from './components/snackbar/snackbar.component';
import { ZenFlexLayoutModule } from 'zen-flex-layout';
import { CountToKFormatPipe } from './pipes/count-to-kformat.pipe';
import { TranslatePipe } from './pipes/translate.pipe';
import { DisableEditorControlDirective } from './directive/disable-editor-control.directive';
import { AccessGuard } from './services/access-guard.service';
import { ImageOptimizerDirective } from './directive/image-optimizer.directive';
import { ImageFileNameFormatPipe } from './pipes/image-file-name-format.pipe';
import { AutoResizeDirective } from './directive/auto-resize.directive';

@NgModule({
  declarations: [
    SantizerPipe,
    DialogComponent,
    MenuPipePipe,
    TrimValueAccessorDirective,
    ContainerDirective,
    SentenceCasePipe,
    BadgeListDesignDirective,
    SnackbarComponent,
    CountToKFormatPipe,
    TranslatePipe,
    DisableEditorControlDirective,
    ImageOptimizerDirective,
    ImageFileNameFormatPipe,
    AutoResizeDirective
  ],
  imports: [
    DragDropModule,
    CommonModule,
    // HttpClientModule,
    // BrowserModule,
    MatRadioModule,
    MatSnackBarModule,
    MatPaginatorModule,
    MatTooltipModule,
    MatChipsModule,
    MatBadgeModule,
    MatTableModule,
    MatIconModule,
    MatStepperModule,
    MatTabsModule,
    MatButtonToggleModule,
    MatSlideToggleModule,
    MatBottomSheetModule,
    MatListModule,
    MatMenuModule,
    RecaptchaFormsModule,
    MatAutocompleteModule,
    MatDialogModule,
    MatToolbarModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatGridListModule,
    MatSelectModule,
    MatSidenavModule,
    MatInputModule,
    MatSliderModule,
    MatFormFieldModule,
    MatExpansionModule,
    MatProgressBarModule,
    // BrowserAnimationsModule,
    MatProgressSpinnerModule,
    MatButtonModule,
    MatCardModule,
    MatSortModule,
    MatCheckboxModule,
    MatChipsModule,
    MatTreeModule,
    MatDividerModule,
    // ZenFlexLayoutModule,
    FormsModule,
    ReactiveFormsModule,
    DragDropModule,
    ClipboardModule,
    // AgmCoreModule.forRoot({
    //   apiKey: '',
    //   libraries: ['places'],
    // }),
    RecaptchaModule,
    // DynamicTableModule,
    CenLoaderModule,
    DeferLoadModule,
    RouterModule,
    MatRippleModule,
    ZenFlexLayoutModule,
    // StoreModule.forFeature('CommonModule', { Template: TemplateReducer }),
  ],
  exports: [
    CommonModule,
    // HttpClientModule,
    // BrowserModule,
    MatRadioModule,
    MatSnackBarModule,
    MatPaginatorModule,
    MatTooltipModule,
    MatChipsModule,
    MatBadgeModule,
    MatTableModule,
    MatIconModule,
    MatStepperModule,
    MatTabsModule,
    MatButtonToggleModule,
    MatSlideToggleModule,
    MatBottomSheetModule,
    MatListModule,
    MatMenuModule,
    MatAutocompleteModule,
    MatDialogModule,
    MatToolbarModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatGridListModule,
    MatSelectModule,
    MatSidenavModule,
    MatInputModule,
    MatSliderModule,
    MatFormFieldModule,
    MatExpansionModule,
    MatProgressBarModule,
    // BrowserAnimationsModule,
    MatProgressSpinnerModule,
    MatButtonModule,
    MatCardModule,
    MatSortModule,
    MatCheckboxModule,
    MatChipsModule,
    MatTreeModule,
    MatDividerModule,
    // ZenFlexLayoutModule,
    FormsModule,
    ReactiveFormsModule,
    // AgmCoreModule,
    RecaptchaModule,
    SantizerPipe,
    MenuPipePipe,
    // DynamicTableModule,
    CenLoaderModule,
    DialogComponent,
    DragDropModule,
    DeferLoadModule,
    MatRippleModule,
    ClipboardModule,
    TrimValueAccessorDirective,
    RouterModule,
    ContainerDirective,
    SentenceCasePipe,
    BadgeListDesignDirective,
    ZenFlexLayoutModule,
    CountToKFormatPipe,
    TranslatePipe,
    DisableEditorControlDirective,
    ImageOptimizerDirective,
    ImageFileNameFormatPipe,
    AutoResizeDirective
  ],
  // StoreModule.forFeature('CommonModule', { Template: TemplateReducer }),
  providers: [AccessGuard, DatePipe, HttpRoutingService, MenuService, TitleCasePipe],
})
export class CoreModule { }
