import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
/**
 *  CommonHistoryListComponent used to list the history details. 
 * */
@Component({
  selector: 'phase-ii-common-history-list',
  templateUrl: './common-history-list.component.html',
  styleUrls: ['./common-history-list.component.scss']
})
/**
 *  CommonHistoryListComponent used to list the history details. 
 * */
export class CommonHistoryListComponent implements OnInit {
  /**
    * historyLogs which is used to store the display info of history details.
    * @type {any}
    */
  @Input() historyLogs: any;
  /**
   * pageDetails which is used to store the display info of pageDetails.
   * @type {any}
   */
  @Input() pageDetails: {
    limit: any,
    offset: any
  } = {
      limit: null,
      offset: null
    };
  /**
  * Variable valueChanged which is used to emit the method to the parent component while click action icon.
  * @type {any}
 */
  @Output() valueChanged = new EventEmitter<any>(null);
  /**
    * Variable searchedText which is used to emit the method to the parent component while click action icon.
    * @type {any}
   */
  @Output() searchedText = new EventEmitter<any>(null);
  /**
  * Variable actionClicked which is used to emit the method to the parent component while click action icon.
  * @type {any}
 */
  @Output() actionClicked = new EventEmitter<any>(null);
  /**
  * isShowMore which is used to store the display info of list.
  * @type {boolean}
  */
  isShowMore: boolean;
  /**
   * Component constructor to inject the required services.
   */
  constructor() { }
  /**
   * Component OnInit life cycle hook
   */
  ngOnInit(): void {
    this.isShowMore = false;
  }
  /**
   * Method getPaginatorData used to paginate the customer list
   * @param event to get event
   */
  getPaginatorData(event) {
    this.pageDetails.limit = event.pageSize;
    this.pageDetails.offset = event.pageIndex * event.pageSize;
  }
  /**
  * @param event id of selected row
  * method for action button click
  */
  listAction(event: any) {
    this.actionClicked.emit(event);
  }
  /**
  * Method which is used to get changed page data.
  * @param event To get offset and limit.
  */
  getChangedData(event: any) {
    this.valueChanged.emit(event);
  }
  /**
   * Method which is used to changed records based on search.
   * @param event To get searchtext.
   */
  onSearch(event: any) {
    this.searchedText.emit(event);
  }
  /**
   * Fucntion showMore is called when we click more data to show.
   */
  showMore() {
    this.isShowMore = !this.isShowMore;
  }
}
