import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { PayoutStatusDetails, QueryDetailsForListApi, RequestDetails, UpdatePayoutRequest, updatePayoutParamaData } from '../models/payout-request.model';
import { HttpRoutingService } from 'libs/common/src/lib/services/httpRouting.service';
import { API } from 'libs/common/src/lib/constants/api-routes';
// import { API, HttpRoutingService } from '@phase-ii/common';

@Injectable({
  providedIn: 'root'
})
export class PayoutRequestService {
  /**
  * constructor which is used to inject the required services and initialise the variables.
  *  @param httpRoutingService To get and use the functions and observables in httpRoutingService Service.
  */
  constructor(
    private httpRoutingSerice: HttpRoutingService
  ) { }
  /**
 * Method getAllpayoutRequest which is used to fetch all the payout request
 * @param {*} data is the fetch payout request filter, search, page data
 * @returns response from backend.
 */
  getAllpayoutRequest(data: QueryDetailsForListApi) {
    return this.httpRoutingSerice.getMethod(API.GET_ALL_PAYOUT_REQUEST, data);
  }
  /**
* Method getAllPayoutStatus which is used to fetch all the payout request status
* @returns response from backend.
*/
  getAllPayoutStatus() {
    return this.httpRoutingSerice.getMethod(API.GET_ALL_PAYOUT_STATUS);
  }
  /**
* Method updatePayoutRequest which is used to update the existing payout requests
* @param {*} data is the update payout request status data
* @param id is the updating payout request id
* @returns response from backend.
*/
  updatePayoutRequest(data: updatePayoutParamaData, id:number){
    const url = this.httpRoutingSerice.replaceUrlParam(API.UPDATE_PAYOUT_REQUEST, { id: id });
    return this.httpRoutingSerice.putMethod(url, data);
  }
}
