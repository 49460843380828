import { Component, ElementRef, EventEmitter, Inject, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { UntypedFormArray, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { dataConfig } from 'libs/common/src/lib/services/config';
import { DialogService } from '../../../../../common/src/lib/services/dialog.service';
import { CommonAdminShardConstants } from '../../constants/shared-constant';
import { CustomValidators } from '../custom-validators/custom-validators';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Subscription } from 'rxjs';
import { SharedService } from '../../../../../themes/shared-theme/src';

/**
 * Component which is used for the common tab.
 */
@Component({
  selector: 'phase-ii-customized-form',
  templateUrl: './customized-form.component.html',
  styleUrls: ['./customized-form.component.scss']
})
export class CustomizedFormComponent extends CommonAdminShardConstants implements OnInit {
  /**
   * Variable which is used to define the inputFile control.
   */
  @ViewChild('file', { static: false }) file: ElementRef;
  /**
   * Variable used for the form group
   */
  customizedForm!: UntypedFormGroup;
  /**
   * variable used to find the form index.
   */
  editCustomFormIndex!: number;
  /**
   * Variable which is used to show the schedule form component
   */
  @ViewChild('customForm', { static: false }) customForm!: TemplateRef<any>;
  /**
   * Variable used to view the details
   */
  detailsView!: boolean;
  /**
   * Variable used to common value changes
   */
  commonValue: CommonAdminShardConstants;
  /**
   * Variable dialogAlertMessages used to store the alert message to delete configuration question when only one quest is there.
   */
  @Input() dialogAlertMessages: any;
  /**
   * Variable which is used access the patternsValidators from the common service.
   */
  pattern = dataConfig.patternValidators;
  /**
   * Object used to customized form details
   */
  customizedDetails: {
    defaultValidator: any
    controlType: any
  } = {
      defaultValidator: null,
      controlType: null
    };
    /**
     * variable used to store selected dropdownValue
     */
    selectedValue:string;
  /**
   * Emitted the output data to the parent component
   */
  @Output() configurationQuestionValue = new EventEmitter();
  /**
   * Variable used to emitted the action click to parant component
   */
  @Output() isAddQuestionClicked = new EventEmitter();
  /**
   * Variable used to get the plugin value.
   */
  @Input() pluginValue: any;
  /** 
   * Variable used to have the heading value;
  */
  @Input() headingValue = '';
  /** 
   * Variable used to have the heading value;
  */
  @Input() readWriteRestriction!: boolean;
  /** 
  * Variable used to have the defaultValidator & formControlType;
  */
  @Input() formValues: any;
  /**
  * Variable used to store the api call subscriptions.
  */
  subscriptionObj: Subscription = new Subscription();
  /** 
  * Variable used to show/hide Default Box;
  */
  showDefaultBox: any;
  jsonObject: { jsonValue: any, showJsonError: any, jsonMsg: any } = { jsonValue: false, showJsonError: '', jsonMsg: '' };
  /**
   * Array used to store uploaded image name.
   */
  uploadedUrls = [];
  /** 
  *Variable used to store imageData url.
  */
  image: string;
  /** 
  * Variable used to store image name.
  */
  imageExt: string;
  /** 
  * Variable used to store aws url.
  */
  awsImageUrl: any;
  /**
   * Inject the service used in the component
   * @param dialog display the dialog for error message and succesmessage.
   * @param displayDialog used for display the dialog
   * @param environment has environment data.
   */
  constructor(private dialog: DialogService, private displayDialog: MatDialog,
    private sharedService: SharedService,
    @Inject('environment') environment,
  ) {
    super();
    this.commonValue = new CommonAdminShardConstants();
    this.awsImageUrl = environment.AWSImageUrl;
  }
  /**
   * Life cycle hook.
   */
  ngOnInit(): void {
    this.editCustomFormIndex = 0;
    this.formIntializeValue();
    this.customizedDetails.defaultValidator = this.formValues?.defaultValidator;
    this.customizedDetails.controlType = this.formValues?.formControlType;
    if (this.pluginValue) {
      this.configurationValue(this.pluginValue);
    }
  }

  /**
   * Method used to patch the value for the configuration question value.
   * @param data has the data for configuration question.
   */
  configurationValue(data: any): void {
    if (data && data.length) {
      data.forEach((element: any, index: any) => {
        (this.customizedForm?.get('configurationQuestions') as UntypedFormArray).insert(index, new UntypedFormGroup({
          id: new UntypedFormControl(element && element.id),
          fieldTitle: new UntypedFormControl(element && element.fieldTitle, [Validators.required, Validators.maxLength(120)]),
          controlType: new UntypedFormControl(element && element.controlType, [Validators.required]),
          isValidatoreEnabled: new UntypedFormControl(null),
          description: new UntypedFormControl(element && element.description, [Validators.maxLength(250)]),
          hasDefault: new UntypedFormControl(element && element.hasDefault),
          defaultValue: new UntypedFormControl(element?.defaultValue && element?.controlType === 'slideToggle' ?
            (element?.defaultValue === 'false' ? false : true) : element.defaultValue, [Validators.maxLength(120)]),
          header: new UntypedFormControl(element && element.header, [Validators.maxLength(120)]),
          customFieldCode: new UntypedFormControl(element && element.customFieldCode, [Validators.maxLength(120)]),
          isApiCall: new UntypedFormControl(element?.isApiCall ? element.isApiCall : null),
          isMultiLanguage: new UntypedFormControl(element?.isMultiLanguage ? element.isMultiLanguage : null),
          index: new UntypedFormControl(element?.index ? element.index : null),
          isVisible: new UntypedFormControl(element?.isVisible ? element.isVisible : null),
        }));
        this.addConfigurationQuestions(false, element, index);
        if (element && element.validator && Object.keys(element.validator).length > 0 && element.validator.constructor === Object) {
          const item = (this.customizedForm?.get('configurationQuestions') as UntypedFormArray).at(index).get('isValidatoreEnabled');
          if (item) item.setValue(true);
          for (const obj in element.validator) {
            this.allowValidator({ checked: true }, index, obj, element);
          }
        }
      });
    }
  }


  /**
   * Method used to initialize the form value
   * @param data has the data for  the form.
   */
  formIntializeValue(data?: any): void {
    this.customizedForm = new UntypedFormGroup({
      configurationQuestions: new UntypedFormArray([]),
    });
  }


  /**
   * Method used to display the validator controll.
   * @param event has the checkbox event.
   * @param i has the index.
   * @param name has the name of the event.
   * @param objectData has the data
   */
  allowValidator(event: any, i: number, name?: any, objectData?: any): void {
    const item = (this.customizedForm?.get('configurationQuestions') as UntypedFormArray).at(i);
    if (event.checked) {
      (<UntypedFormGroup>item)?.addControl('validator', new UntypedFormGroup({}));
      this.customizedDetails.defaultValidator?.forEach((validator: any) => {
        (<UntypedFormGroup>item?.get('validator'))?.addControl(validator.field + 'checkbox', new UntypedFormControl(null));
      });
      (<UntypedFormGroup>item)?.addControl('errorMessage', new UntypedFormGroup({}));
    } else {
      (<UntypedFormGroup>item)?.removeControl('validator');
      (<UntypedFormGroup>item)?.removeControl('errorMessage');
    }
    if (name && objectData) {
      this.checkValidator({ checked: true }, name, i, objectData.validator[name], objectData.errorMessage[name]);
    } else {
      this.checkValidator({ checked: true }, 'required', i);
    }
  }
  /**
   * Method used to select the form field.
   * @param i has the index value
   */
  selectedFormField(i: any, control?: any, type?: boolean, isValueCheck?: boolean): void {
    if (control?.actualValue)
      this.selectedValue = control?.actualValue;
      this.assignDefault(control?.actualValue, i);
    if (isValueCheck)
      (this.customizedForm?.get('configurationQuestions') as UntypedFormArray)['controls'][i]?.get('hasDefault')?.setValue(false);
    const item = (this.customizedForm?.get('configurationQuestions') as UntypedFormArray).at(i);
    if (control?.jsonField) {
      // (<UntypedFormGroup>item)?.removeControl('values');
      if (item?.value?.values?.length) {
        for (let j = 0; j < item.value.values.length; j++)
          this.update(i, j);
      }
      (<UntypedFormGroup>item)?.addControl('values', new UntypedFormArray(isValueCheck ? [new UntypedFormControl(null, Validators.required)] : [],
        { validators: CustomValidators.checkDuplicateValues }));
      this.jsonObject.jsonMsg = control.jsonMsg;
    } else if (control && control.display) {
      if (item?.value?.values?.length) {
        for (let j = 0; j < item.value.values.length; j++)
          this.update(i, j);
      }
      (<UntypedFormGroup>item)?.addControl('values', new UntypedFormArray(isValueCheck ? [new UntypedFormControl(null, Validators.required)] : [], { validators: CustomValidators.checkDuplicateValues }));
    } else if ((<UntypedFormGroup>item)['controls']['values']) {
      (<UntypedFormGroup>item)?.removeControl('values');
    }
    if (control && control.isMultiple) {
      (<UntypedFormGroup>item)?.addControl('allowMultiple', new UntypedFormControl(type ? type : false));
    } else if ((<UntypedFormGroup>item)['controls']['allowMultiple']) {
      (<UntypedFormGroup>item)?.removeControl('allowMultiple');
    }
  }
  /**
 * Method is used to validate JSON form controls
 * @param controlName is used to get form control name
 * @param control is used to get the FormControl
 */
  update(i?: number, j?: number): Promise<any> {
    const val = ((this.customizedForm?.get('configurationQuestions') as UntypedFormArray).controls[i]?.get('values') as UntypedFormArray)?.at(j);
    if (this.selectedValue === "imageJson") {
      try {
        JSON.parse(val.value);
        // this.jsonObject.showJsonError = "";
        return Promise.resolve(null);
      } catch (err) {
        // this.jsonObject.showJsonError = err?.message;
        return Promise.resolve(val?.setErrors({ notValid: true }))
      }
    }
    else {
      delete val?.errors?.notValid
      val?.setValue(val.value);
    }
  }
  /**
   * Method used to add the question value.
   * @param index has the index value.
   */
  addQuestionValue(index: any, data?: any): void {
    const item = (this.customizedForm?.get('configurationQuestions') as UntypedFormArray).at(index)?.get('values');
    if (item && item instanceof UntypedFormArray)
      (<UntypedFormArray>item).push(new UntypedFormControl(data ? data : null));
  }
  /**
   * Method used to add the check validator
   * @param event has the event name
   * @param name has name of validator.checkValidator
   */
  checkValidator(event: any, name: any, i: number, data?: any, errorData?: any): void {
    const item = (this.customizedForm?.get('configurationQuestions') as UntypedFormArray).at(i)?.get('validator');
    const errorItem = (this.customizedForm?.get('configurationQuestions') as UntypedFormArray).at(i)?.get('errorMessage');
    if ((<UntypedFormGroup>item)?.get(name + 'checkbox')) {
      if (event.checked) {
        (<UntypedFormGroup>item)?.get(name + 'checkbox')?.setValue(true);
        (<UntypedFormGroup>item)?.addControl(name, new UntypedFormControl(data ? data : null,
          [Validators.pattern(dataConfig.patternValidators.spaceValidationPattern)]));
        (<UntypedFormGroup>errorItem)?.addControl(name, new UntypedFormControl(errorData ? errorData : null,
          [Validators.pattern(dataConfig.patternValidators.spaceValidationPattern)]));
      } else {
        (<UntypedFormGroup>item)?.get(name + 'checkbox')?.setValue(false);
        (<UntypedFormGroup>item)?.removeControl(name);
        (<UntypedFormGroup>errorItem)?.removeControl(name);
      }
    }

  }
  /**
   * Method used to add the configuration fieldTitle.
   * @param data has the details of configuration fieldTitle.
   */
  addConfigurationQuestions(isEdit: boolean, data?: any, index?: number): void {
    this.editCustomFormIndex = index ? index : 0;
    if (isEdit) {
      this.displayDialog.open(this.customForm, {
        disableClose: true,
        autoFocus: false,
        width: '80%'
      })
    }
    this.isAddQuestionClicked.emit(true);
    if (isEdit && !data) {
      (this.customizedForm?.get('configurationQuestions') as UntypedFormArray).insert(0, new UntypedFormGroup({
        fieldTitle: new UntypedFormControl(null, [Validators.required, Validators.maxLength(120)]),
        controlType: new UntypedFormControl(null, [Validators.required]),
        isValidatoreEnabled: new UntypedFormControl(null),
        description: new UntypedFormControl(null, [Validators.maxLength(250)]),
        hasDefault: new UntypedFormControl(null),
        defaultValue: new UntypedFormControl(null, [Validators.maxLength(120)]),
        header: new UntypedFormControl(null, [Validators.maxLength(120)]),
        customFieldCode: new UntypedFormControl(null, [Validators.maxLength(120)]),
        isApiCall: new UntypedFormControl(null),
        isMultiLanguage: new UntypedFormControl(null),
        index: new UntypedFormControl(1),
        isVisible: new UntypedFormControl(true),
      }));
    } else if (data && data.controlType) {
      const controlTypeData = this.customizedDetails.controlType.find((s: any) => s.actualValue === data.controlType);
      this.assignDefault(controlTypeData?.actualValue, index);
      if (data.values && data.values.length > 0) {
        this.selectedFormField(index, controlTypeData, data?.allowMultiple);
        if (!isEdit) {
          for (let questionVal of data.values) {
            this.addQuestionValue(index, questionVal);
          }
        }
      } else if (data.values && controlTypeData?.jsonField) {
        this.selectedFormField(index, controlTypeData, data?.allowMultiple);
        if (!isEdit) {
          this.addQuestionValue(index, JSON.stringify(data.values));
        }
      }
    }
  }
  /**
   * Method used to assign the default value
   * @param value has the value.
   * @param i has the index id.
   */
  assignDefault(value: any, i: any): void {
    const controlValue = this.customizedDetails.controlType?.filter((val: any) => val.actualValue === value);
    this.showDefaultBox = controlValue?.length > 0 && controlValue[0]?.isDefault ? controlValue[0].isDefault : false;
    if (!this.showDefaultBox)
      (this.customizedForm?.get('configurationQuestions') as UntypedFormArray)['controls'][i]?.get('hasDefault')?.setValue(false);
  }
  /**
   * Method used to remove the controller from the array index
   * @param index has the index id.
   */
  removeController(index: number): void {
    this.isAddQuestionClicked.emit(false);
    if ((this.customizedForm?.get('configurationQuestions') as UntypedFormArray).length > 1) {
      this.dialog.dialogMethod(this.commonValue.errorMessage.deleteConfigurationQuestionConfirmation, this.dialogType.confirmation, true).afterClosed().subscribe((res: any) => {
        if (res) {
          (this.customizedForm?.get('configurationQuestions') as UntypedFormArray).removeAt(index);
          this.validatorFormatChange();
          this.configurationQuestionValue.emit(this.customizedForm.value);
        }
      });
    } else {
      this.dialog.dialogMethod(this.dialogAlertMessages ? this.dialogAlertMessages : this.commonValue.errorMessage.oneFormArray, this.dialogType.alert, true);
    }
  }
  /**
   * Method used to change the validator object format.
   */
  validatorFormatChange(): void {
    if (this.customizedForm && this.customizedForm.value.configurationQuestions && this.customizedForm.value.configurationQuestions.length > 0) {
      this.customizedForm.value.configurationQuestions.forEach((element: any) => {
        if (element && element.validator) {
          let filteredValidators = {};
          for (const key in element.validator) {
            if (!key.includes('checkbox')) {
              filteredValidators = { ...filteredValidators, ...{ [key]: element.validator[key] } };
            }
          }
          element.validator = filteredValidators;
        }
      });
    }
  }
  /**
   * Method used to check duplicate value of fieldTitle.
   * @returns boolean
   */
  duplicateValidator(arr: any): boolean {
    if (arr?.length > 0) {
      for (let i = 0; i < arr.length; i++) {
        for (let j = i + 1; j < arr.length; j++) {
          if (arr[i].fieldTitle === arr[j].fieldTitle) {
            return true;
          }
        }
      }
    }
    return false;
  }
  /**
   * Method used to save the form details.
   */
  saveFormDetails(): void {
    if (this.duplicateValidator(this.customizedForm?.get('configurationQuestions')?.value)) {
      this.customizedForm.markAllAsTouched();
      this.dialog.dialogMethod(this.commonValue.errorMessage.titleAlreadyExists, this.dialogType.alert, true);
    } else if (this.customizedForm.valid) {
      if (!this.customizedForm.dirty) {
        this.dialog.dialogMethod(this.commonValue.dialogMessages.noChangeAlert, this.dialogType.alert, true);
      } else {
        this.uploadedUrls = [];
        this.validatorFormatChange();
        this.configurationQuestionValue.emit(this.customizedForm.value);
        this.displayDialog.closeAll();
      }
    } else {
      this.customizedForm.markAllAsTouched();
      this.dialog.dialogMethod(this.commonValue.errorMessage.mandatoryFieldFill, this.dialogType.alert, true);
    }
  }

  /**
   * Method used to remove the value control
   * @param questionIndex has the question index
   * @param valueIndex has the value index
   */
  removeValueController(questionIndex: number, valueIndex: number): void {

    if (this.customizedForm && this.customizedForm.get('configurationQuestions') && (this.customizedForm.get('configurationQuestions') as UntypedFormArray).controls &&
      (this.customizedForm.get('configurationQuestions') as UntypedFormArray).controls[questionIndex] &&
      ((this.customizedForm.get('configurationQuestions') as UntypedFormArray).controls[questionIndex].get('values') as UntypedFormArray) &&
      ((this.customizedForm.get('configurationQuestions') as UntypedFormArray).controls[questionIndex].get('values') as UntypedFormArray).length > 1
    ) {
      ((this.customizedForm.get('configurationQuestions') as UntypedFormArray).controls[questionIndex].get('values') as UntypedFormArray).removeAt(valueIndex);
    } else {
      this.dialog.dialogMethod(this.commonValue.errorMessage.oneValueArray, this.dialogType.alert, true);
    }
    this.customizedForm.markAsDirty();
  }
  /**
   * Method used to close the dialog.
   */
  close(): void {
    if (this.customizedForm.dirty) {
      const dialog = this.dialog.dialogMethod(this.commonValue.message.cancel, this.dialogType.confirmation, true);
      dialog.afterClosed().subscribe((res: any) => {
        if (res) {
          this.displayDialog.closeAll();
          (<UntypedFormArray>this.customizedForm?.get('configurationQuestions')).clear();
          this.configurationValue(this.pluginValue);
        }
      });
    } else {
      this.displayDialog.closeAll();
      (<UntypedFormArray>this.customizedForm?.get('configurationQuestions')).clear();
      this.configurationValue(this.pluginValue);
    }
  }
  /**
  * Method used to drag and drop for the form value
  * @param event has the drop value of previous index and current index of form array.
  */
  drop(event: CdkDragDrop<string[]>): void {
    (this.customizedForm?.get('configurationQuestions') as UntypedFormArray)['controls'][event.previousIndex]?.get('index')?.setValue(event.currentIndex);
    moveItemInArray(this.customizedForm.get('configurationQuestions')['controls'], event.previousIndex, event.currentIndex);
    moveItemInArray(this.customizedForm.get('configurationQuestions').value, event.previousIndex, event.currentIndex);
    this.validatorFormatChange();
    this.configurationQuestionValue.emit(this.customizedForm.value);
  }
  /**
   * Method used to emit visibilityChange values.
   */
  visibilityChange(): void {
    this.validatorFormatChange();
    this.configurationQuestionValue.emit(this.customizedForm.value);
  }

  showMultiLanguage(index: number): boolean {
    const controlType = (this.customizedForm?.get('configurationQuestions') as UntypedFormArray)?.at(index)?.get('controlType')?.value;
    return controlType === 'input' || controlType === 'textarea' || controlType === 'radiobutton' || controlType === 'dropdown';
  }
  /**
  * Method used to read the file.
  * @param event has the file name.
  */
  onChooseFile(event: any) {
    // this.pageDetails.loader = true;
    for (let i = 0; i < event.target.files.length; i++) {
      const data = event.target.files[i], ext = data.name.substr(data.name.lastIndexOf('.'));
      this.imageExt = ext;
      if (ext === 'svg') {
        this.dialog.dialogMethod(this.commonValue.errorMessage.imgErrorMessage, this.dialogType.alert, true);
        // this.pageDetails.loader = false;
      } else if (data.size / 1024 / 1024 <= 2) {                                    //to restrict image file size as 2mb
        const reader = [];
        // this.pageDetails.loader = false;
        const file = event.target.files[i];
        reader[i] = new FileReader();
        reader[i].onload = this.handleReaderLoaded.bind(this);
        reader[i].readAsBinaryString(file);
      } else {
        this.dialog.dialogMethod(this.commonValue.errorMessage.imgSizeErrorMessage, this.dialogType.alert, true);
        // this.pageDetails.loader = false;
      }
    };
    if (this.file && this.file.nativeElement && this.file.nativeElement.value)
      this.file.nativeElement.value = '';
  }
  /**
  * Method used handle the reader function
  * @param event has the file name
  */
  handleReaderLoaded(event: any): void {
    if (event && event.target) {
      const imageUrl = 'data:image/png;base64,' + btoa(event.target.result);
      this.image = imageUrl;
      const imageData = { image: this.image, imageExt: this.imageExt };
      this.subscriptionObj.add(this.sharedService.saveImageData(imageData)
        .subscribe((res) => {
          if (res) {
            // const filename = res['Image'] ? this.awsImageUrl + res['Image'] : './assets/upload1.png';
            this.uploadedUrls.push(res['Image']['Key']);
          }
        }, (err) => {
          this.dialog.dialogMethod(this.commonValue.dialogMessages.getFailed, this.dialogType.failure, true);
        }));
    }
  }
}
