import { Pipe, PipeTransform } from '@angular/core';
import { AuthService } from 'libs/auth/src/lib/services/auth.service';

// Pipe which is used to transform menu's.
@Pipe({
  name: 'menuPipe'
})
/**
 * Class which is used to load the menu's.
 */
export class MenuPipePipe implements PipeTransform {
  /**
   * Component constructor which is used to inject the required services.
   * @param authService To prevent cross site scripting.
   */
  constructor(private authService: AuthService) {}
  /**
   * Function which is used to load menu label's 
   * @param menuName , defines menu label
   * @returns
   */
  transform(menuName: string): string {
    this.authService.storeIndustry.subscribe(res => {
      if(res) {
        if(menuName === 'Products') {  
          menuName = (res && res.primary_label)? res.primary_label : menuName;
        }
      }
    });
    return menuName;
  }
}
