import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, CanActivateChild, Router } from '@angular/router';
import { SharedConstant } from 'libs/common/src/lib/constants/shared-constant';
import { DialogService } from 'libs/common/src/lib/services/dialog.service';
import { MenuService } from 'libs/common/src/lib/services/menu.service';
import { Observable } from 'rxjs';
/**
 * Service to control access
 */
@Injectable()
export class AccessGuard extends SharedConstant implements CanActivateChild {
    /**
     * Component constructor to inject the required services.
     * @param menuService To get menu details.
     * @param dialogService To open mat dialog.
     * @param router To get router navigation.
     */
    constructor(private menuService: MenuService, private dialogService: DialogService) {
        super();
    }
    /**
     * Method which is used to check permission for current route.
     * @param route To get the current url.
     * @param state To get route params.
     */
    canActivateChild(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        if (state) {
            // console.log(state.url, 'in state url', "route.params, route.queryParams", route.params, route.queryParams);
            const isPermitted = this.menuService.checkPermissions(state.url, route.params, route.queryParams);
            // console.log("isPermitted....", isPermitted);
            if (!isPermitted) {
                this.dialogService.dialogMethod(this.message.notAuthorized, this.dialogType.failure, true);
            }
            return isPermitted;
        }
    }
}
