import { NgModule } from '@angular/core';
import { RevenueReportComponent } from './components/revenue-report/revenue-report.component';
import { CommonReportComponent } from './components/common-report/common-report.component';
import { ProductReportComponent } from './components/product-report/product-report.component';
import { CustomerReportComponent } from './components/customer-report/customer-report.component';
import { OfferReportComponent } from './components/offer-report/offer-report.component';
import { OrderReportComponent } from './components/order-report/order-report.component';
import { CommonModule } from '@angular/common';
import { CustomizedFormComponent } from './components/customized-form/customized-form.component';
import { HighchartsChartModule } from 'highcharts-angular';
import { SharedModule } from '@phase-ii/shared';
import { CommonStartEndTimeComponent } from './components/common-start-end-time/common-start-end-time.component';
import { CommonHistoryListComponent } from './components/common-history-list/common-history-list.component';
import { AdminPaymentComponent } from './components/admin-payment/admin-payment.component';
import { ActivityLogsComponent } from './components/activity-logs/activity-logs.component';
import { EmailMarketingPreviewComponent } from './components/email-marketing-preview/email-marketing-preview.component';
import { MailLogsComponent } from './components/mail-logs/mail-logs.component';
import { UpgradePlanComponent } from './components/upgrade-plan/upgrade-plan.component';
import { SubscriptionPlanPaymentComponent } from './components/subscription-plan-payment/subscription-plan-payment.component';
import { SubscriptionMemberComponent } from './components/subscription-member/subscription-member.component';
import { NgxPayPalModule } from 'ngx-paypal';
import { UpdateCardComponent } from './components/update-card/update-card.component';
import { CommonStoreSelectComponent } from './components/common-store-select/common-store-select.component';
import { TransactionLogListComponent } from './components/transaction-log-list/transaction-log-list.component';
import { CommonTimerComponent } from './components/common-timer/common-timer.component';
import { SizeChartComponent } from './components/size-chart/size-chart.component';
import { MobilePlanPaymentComponent } from './components/mobile-plan-payment/mobile-plan-payment.component';
import { ImportComponent } from './components/import/import.component';
import { HomeMenuDrawerComponent } from './components/home-menu-drawer/home-menu-drawer.component';
import { HomeMenuToolbarComponent } from './components/home-menu-toolbar/home-menu-toolbar.component';
import { CommonSortComponent } from './components/common-sort/common-sort.component';
import { RibbonEditorComponent } from './components/ribbon-editor/ribbon-editor.component';
import { ExportComponent } from './components/export/export.component';
import { HeaderPageComponent } from './components/header-page/header-page.component';
import { HeaderCardDirective } from './directives/header-card.directive';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { AutomaticOfferReportComponent } from './components/automatic-offer-report/automatic-offer-report.component';
import { PayxGetFreeReportComponent } from './components/payx-get-free-report/payx-get-free-report.component';
import { BuyxGetyReportComponent } from './components/buyx-gety-report/buyx-gety-report.component';
import { SubscribedPluginListComponent } from './components/subscribed-plugin-list/subscribed-plugin-list.component';
import { UnfulfilledOrdersComponent } from './components/unfulfilled_orders/unfulfilled-orders.component';

@NgModule({
  declarations: [
    CustomizedFormComponent,
    RevenueReportComponent,
    CustomerReportComponent,
    ProductReportComponent,
    OrderReportComponent,
    OfferReportComponent,
    AutomaticOfferReportComponent,
    CommonReportComponent,
    CommonStartEndTimeComponent,
    CommonHistoryListComponent,
    AdminPaymentComponent,
    ActivityLogsComponent,
    EmailMarketingPreviewComponent,
    MailLogsComponent,
    UpgradePlanComponent,
    SubscriptionPlanPaymentComponent,
    SubscriptionMemberComponent,
    UpdateCardComponent,
    CommonStoreSelectComponent,
    TransactionLogListComponent,
    CommonTimerComponent,
    SizeChartComponent,
    MobilePlanPaymentComponent,
    ImportComponent,
    HomeMenuDrawerComponent,
    HomeMenuToolbarComponent,
    CommonSortComponent,
    RibbonEditorComponent,
    ExportComponent,
    HeaderPageComponent,
    HeaderCardDirective,
    PayxGetFreeReportComponent,
    BuyxGetyReportComponent,
    SubscribedPluginListComponent,
    UnfulfilledOrdersComponent
  ],
  exports: [
    CustomizedFormComponent,
    RevenueReportComponent,
    CustomerReportComponent,
    ProductReportComponent,
    OrderReportComponent,
    OfferReportComponent,
    CommonReportComponent,
    CommonStartEndTimeComponent,
    CommonHistoryListComponent,
    AdminPaymentComponent,
    ActivityLogsComponent,
    EmailMarketingPreviewComponent,
    MailLogsComponent,
    UpgradePlanComponent,
    SubscriptionPlanPaymentComponent,
    SubscriptionMemberComponent,
    CommonStoreSelectComponent,
    TransactionLogListComponent,
    CommonTimerComponent,
    SizeChartComponent,
    MobilePlanPaymentComponent,
    ImportComponent,
    HomeMenuDrawerComponent,
    HomeMenuToolbarComponent,
    CommonSortComponent,
    RibbonEditorComponent,
    ExportComponent,
    HeaderPageComponent,
    NgxMaterialTimepickerModule,
    AutomaticOfferReportComponent,
    PayxGetFreeReportComponent,
    BuyxGetyReportComponent,
    HeaderCardDirective,
    SubscribedPluginListComponent,
    UnfulfilledOrdersComponent
  ],
  imports: [CommonModule, NgxMaterialTimepickerModule, SharedModule, HighchartsChartModule, NgxPayPalModule],
  // entryComponents: [ImportComponent, ExportComponent]
})
export class CommonAdminModuleModule { }
