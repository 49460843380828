<cen-loader-vii *ngIf="pageDetails?.isLoader"></cen-loader-vii>
<div class="whole" fxLayout="row" fxLayoutAlign="space-between">
  <div fxFlex="100%" fxFlex.lt-sm="100%" class="left">
    <div class="innner-card" fxLayout="row" fxFlex="90%" fxFlex.lt-md="100%" fxLayoutGap.lt-md="0" fxLayoutGap="3px"
      fxLayoutAlign="center">
      <mat-card fxFlex="36%" fxFlex.lt-md="60%" fxFlex.lt-sm="100%">
        <div *ngIf="pageDetails?.isSignIn" class="social-icon" fxLayoutAlign="end center" fxLayoutGap.lt-sm="10%">
          <ng-container *ngTemplateOutlet="country"></ng-container>
        </div>
        <div class="heading" fxLayout="column" fxLayoutAlign="center center">
          <div *ngIf="pageDetails?.isSignIn" class="logo">
            <img src="assets/zenbasketlogo.png" alt="">
          </div>
          <h2 *ngIf="pageDetails?.isSignUp">Join Us Today!</h2>
          <h2 *ngIf="pageDetails?.isSignIn">Sign In to Your Account</h2>
          <h2 *ngIf="pageDetails?.isForgotPassword">Forgot Password</h2>
          <h2 *ngIf="pageDetails?.isChangePassword">Reset Password</h2>
        </div>
        <!-- Sign In -->
        <form [formGroup]="signInForm" *ngIf="pageDetails?.isSignIn">
          <!-- Email -->
          <div class="input_div">
            <mat-form-field class="example-full-width" appearance="outline">
              <input placeholder="Email *" matInput data-control="emailField" formControlName="email" trimVal>
              <mat-icon matSuffix>person</mat-icon>
              <mat-error data-control="emailError"
                *ngIf="signInForm?.get('email')?.errors ||signInForm?.get('email')?.touched">
                <span class="error-message" *ngFor="let k of signInForm?.get('email')?.errors| keyvalue">
                  {{(errorMessage?.ERROR)['EMAIL'][k?.key] }}</span>
              </mat-error>
            </mat-form-field>
          </div>
          <!-- Password -->
          <div class="input_div">
            <mat-form-field appearance="outline" class="example-full-width">
              <mat-icon matSuffix class="show" data-control="showpassword"
                (click)="pageDetails.passwordField = !pageDetails?.passwordField" matSuffix>
                {{pageDetails?.passwordField ? 'visibility' : 'visibility_off'}}</mat-icon>
              <input matInput placeholder="Password *" [type]="pageDetails?.passwordField ? 'text' : 'password'"
                data-control="password" formControlName="password">
              <mat-error data-control="vaildPassword" *ngIf="signInForm?.get('password')?.hasError('required')">
                {{errorMessage?.passwordRequired}}
              </mat-error>
            </mat-form-field>
          </div>
          <div fxLayoutAlign="center center">
            <button (click)="onSignIn()" data-control="signinfunction" class="button" fxLayoutAlign="center center">
              <div *ngIf="!pageDetails?.buttonLoader">
                Sign In
              </div>
              <cen-loader *ngIf="pageDetails?.buttonLoader" [type]="'content'" [name]="'cen-dot-spinner'">
              </cen-loader>
            </button>
          </div>
          <div class="option-bottom">
            <h4> <a (click)="navigation(pageDetails?.forgotPasswordUrl)"> Forgot Password?</a> </h4>
            <h4>Not an member? &emsp;<a class="underline" (click)="navigation(pageDetails?.signUpUrl)">Sign Up</a>
            </h4>
          </div>
        </form>

        <!-- SignUp -->
        <form [formGroup]="userForm" *ngIf="pageDetails?.isSignUp">
          <div class="input_div">
            <mat-form-field class="example-full-width" appearance="outline">
              <input placeholder="Name *" data-control="name" matInput formControlName="name" trimVal>
              <mat-icon matSuffix>person</mat-icon>
              <mat-error data-control="nameError"
                *ngIf="userForm?.get('name')?.errors ||userForm?.get('name')?.touched">
                <span class="error-message" *ngFor="let k of userForm?.get('name')?.errors| keyvalue;let i=index;">
                  <span *ngIf="i===0">
                    {{(errorMessage?.ERROR)['NAME'][k?.key] }}
                  </span>
                </span>
              </mat-error>
            </mat-form-field>
          </div>
          <div class="input_div">
            <mat-form-field class="example-full-width" appearance="outline">
              <mat-icon matSuffix>mail</mat-icon>
              <input placeholder="Email *" matInput formControlName="email" data-control="emailField" trimVal>
              <mat-error data-control="mailError"
                *ngIf="userForm?.get('email')?.errors ||userForm?.get('email')?.touched">
                <span class="error-message" *ngFor="let k of userForm?.get('email')?.errors| keyvalue">
                  {{(errorMessage?.ERROR)['EMAIL'][k?.key] }}</span>
              </mat-error>
            </mat-form-field>
          </div>
          <!-- password -->
          <div class="input_div">
            <mat-form-field class="example-full-width" appearance="outline">
              <input placeholder="Password *" matInput formControlName="password" data-control="password"
                [type]="pageDetails?.passwordField ? 'text' : 'password'" trimVal autocomplete="new-password">
              <mat-icon class="show" data-control="showpassword"
                (click)="pageDetails.passwordField = !pageDetails?.passwordField" matSuffix>
                {{pageDetails?.passwordField ? 'visibility' : 'visibility_off'}}</mat-icon>
              <mat-icon class="show margin" matSuffix matTooltip="
                        * At least One number. &#13; 
                        * At least one special character( !@#$%^&*?{}[]+_|;:).&#13; 
                        * One Uppercase and one Lowercase character. &#13;
                        * Minimum length of 7 and Maximum length of 20.">info</mat-icon>
              <mat-error data-control="validPassword" *ngIf="userForm?.get('password')?.hasError('required')">
                {{errorMessage?.passwordRequired}}
              </mat-error>
              <mat-error data-control="validPassword" *ngIf="userForm?.get('password')?.hasError('pattern')">
                {{errorMessage?.passwordPattern}}
              </mat-error>
            </mat-form-field>
          </div>
          <!-- Address -->
          <div class="input_div">
            <mat-form-field class="example-full-width" appearance="outline">
              <textarea placeholder="Address line 1 *" cdkTextareaAutosize rows="1" data-control="addressLine1" matInput
                formControlName="addressLine1" trimVal></textarea>
              <mat-error data-control="addressLine1Error"
                *ngIf="userForm?.get('addressLine1')?.errors ||userForm?.get('addressLine1')?.touched">
                <span class="error-message" *ngFor="let k of userForm?.get('addressLine1')?.errors| keyvalue">
                  {{(errorMessage?.ERROR)['ADDRESS'][k?.key]}}</span>
              </mat-error>
            </mat-form-field>
          </div>
          <div class="input_div">
            <mat-form-field class="example-full-width" appearance="outline">
              <textarea placeholder="Address line 2" cdkTextareaAutosize rows="1" data-control="addressLine2" matInput
                formControlName="addressLine2" trimVal></textarea>
              <mat-error data-control="addressLine2Error"
                *ngIf="userForm?.get('addressLine2')?.hasError('maxlength') && !userForm?.get('addressLine2')?.hasError('pattern')">
                {{errorMessage?.ERROR?.ADDRESS?.maxlength}}
              </mat-error>
            </mat-form-field>
          </div>
          <!-- Contact number -->
          <div class="input_div">
            <mat-form-field class="example-full-width" appearance="outline">
              <mat-icon matSuffix>phone</mat-icon>
              <input placeholder="Contact number *" matInput data-control="contactNumber"
                formControlName="contactNumber" appAllowNumbersOnly />
              <mat-error data-control="contactError"
                *ngIf="userForm?.get('contactNumber')?.errors ||userForm?.get('contactNumber')?.touched">
                <span class="error-message" *ngFor="let k of userForm?.get('contactNumber')?.errors| keyvalue;let i=index;">
                  <span *ngIf="i===0">
                  {{(errorMessage?.ERROR)['CONTACT'][k?.key] }}</span>
                </span>
              </mat-error>
            </mat-form-field>
            <br *ngIf="userForm?.get('contactNumber')?.hasError('pattern').touched">
          </div>
          <!-- country and State -->
          <div fxLayout="row" fxLayoutAlign="space-between center">
            <div class="input_div" fxFlex="48%">
              <mat-form-field appearance="outline" class="example-full-width">
                <mat-select placeholder="Country *" panelClass="contry" formControlName="country" data-control="country"
                  (selectionChange)="getState()" (openedChange)="$event===false?onCountryFilterClose():null">
                  <div class="search">
                    <div class="search-country" fxLayout="row" fxLayoutAlign="center center">
                      <input matInput data-control="searchCountry" placeholder="Search Country"
                        [formControl]="countrySearchFilter"
                        (keydown)=" ($event.keyCode === 32 ) ?  $event.stopPropagation() : null" />
                      <mat-icon class="show" *ngIf="countrySearchFilter?.value" (click)="onCountryFilterClose()"
                        data-control="closeIconCountry">
                        close
                      </mat-icon>
                    </div>
                    <mat-divider></mat-divider>
                  </div>
                  <mat-option *ngIf="countriesArray?.length === 0 || !countriesArray" class="center">No
                    Countries Found
                  </mat-option>
                  <mat-option *ngFor="let country of countriesArray" [value]="country">
                    {{country?.name}}
                  </mat-option>
                </mat-select>
                <mat-error data-control="validCountry" *ngIf="userForm?.get('country')?.hasError('required')">
                  {{errorMessage?.COUNTRY}}
                </mat-error>
              </mat-form-field>
            </div>
            <div class="input_div" fxFlex="48%">
              <mat-form-field appearance="outline" class="example-full-width">
                <mat-select placeholder="State *" formControlName="state" data-control="state"
                  (openedChange)="$event===false?onStateFilterClose():null">
                  <div class="search">
                    <div fxLayout="row" class="search-country" fxLayoutAlign="center center">
                      <input data-control="searchstate" placeholder="Search state" matInput
                        [formControl]="stateSearchFilter"
                        (keydown)=" ($event.keyCode === 32 ) ?  $event.stopPropagation() : null" />
                      <mat-icon class="show" *ngIf="stateSearchFilter?.value" (click)="onStateFilterClose()"
                        data-control="closeIconState">
                        close
                      </mat-icon>
                    </div>
                    <mat-divider></mat-divider>
                  </div>
                  <mat-option *ngFor="let state of state" [value]="state">
                    {{state?.name}}
                  </mat-option>
                  <mat-option *ngIf="state?.length === 0 || !state" class="center">No States Found
                  </mat-option>
                </mat-select>
                <mat-error data-control="validState" *ngIf="userForm?.get('state')?.hasError('required')">
                  {{errorMessage?.ERROR?.STATE.required}}
                </mat-error>
              </mat-form-field>
            </div>
          </div>
          <!-- city zipcode -->
          <div fxLayoutAlign="space-between center">
            <div class="input_div" fxFlex="48%">
              <mat-form-field appearance="outline" class="example-full-width">
                <input placeholder="City *" matInput formControlName="city" data-control="cityName" trimVal />
                <mat-error data-control="cityError"
                  *ngIf="userForm?.get('city')?.errors ||userForm?.get('city')?.touched">
                  <span class="error-message" *ngFor="let k of userForm?.get('city')?.errors| keyvalue;let i=index;">
                    <span *ngIf="i===0">
                    {{(errorMessage?.ERROR)['CITY'][k?.key]}}</span>
                  </span>
                </mat-error>
              </mat-form-field>
            </div>
            <div class="input_div" fxFlex="48%">
              <mat-form-field appearance="outline" class="example-full-width">
                <input placeholder="Postal/Zipcode *" data-control="zipcode" matInput formControlName="zipCode"
                  trimVal />
                <mat-error data-control="postalCodeError"
                  *ngIf="userForm?.get('zipCode')?.errors ||userForm?.get('zipCode')?.touched">
                  <span class="error-message" *ngFor="let k of userForm?.get('zipCode')?.errors| keyvalue">
                    {{(errorMessage?.ERROR)['ZIPCODE'][k?.key] }}</span>
                </mat-error>
              </mat-form-field>
            </div>
          </div>
          <br
            *ngIf="userForm?.get('city')?.hasError('maxlength') || !userForm?.get('zipCode')?.valid && userForm?.get('zipCode')?.touched">
          <div class="padding">
            <mat-checkbox [formControl]="terms">
              I Agree With <span>
                <a href="termsofservice" target="_blank">Terms & Conditions</a>
              </span> *
            </mat-checkbox>
          </div>
          <div fxLayoutAlign="center center">
            <button (click)="onSignUp()" data-control="signUp" class="button" fxLayoutAlign="center center">
              <div *ngIf="!pageDetails?.buttonLoader">
                Sign Up
              </div>
              <cen-loader *ngIf="pageDetails?.buttonLoader" [type]="'content'" [name]="'cen-dot-spinner'">
              </cen-loader>
            </button>
          </div>
          <div class="option-bottom">
            <h4>Already a member? &emsp;<a (click)="navigation(pageDetails?.signInUrl)" class="underline">Sign In</a>
            </h4>
          </div>
        </form>

        <!-- Forgot password -->
        <div *ngIf="pageDetails?.isForgotPassword" class="forgotPassword">
          <mat-divider></mat-divider>
          <p>
            We will send you a link for resetting your password to your registered email.</p>
          <form [formGroup]="forgotPasswordForm">
            <div fxLayout="row" fxLayoutGap="2%" fxLayoutAlign="start center">
              <mat-form-field class="example-full-width">
                <mat-label>Email</mat-label>
                <input data-control="emailField" matInput formControlName="email" />
                <div fxLayout="row" matSuffix>
                  <mat-icon matSuffix>email</mat-icon>
                  <span *ngIf="
                                        !forgotPasswordForm?.get('email')?.valid && 
                                        forgotPasswordForm?.get('email')?.value &&
                                         !forgotPasswordForm?.get('email')?.hasError('emailAlreadyExists') ===true">
                    <cen-loader [type]="'content'" [name]="'cen-dot-spinner'"></cen-loader>
                  </span>
                  <mat-icon *ngIf="forgotPasswordForm?.valid" class="check" matSuffix>check</mat-icon>
                  <mat-icon
                    *ngIf="forgotPasswordForm?.get('email')?.value && forgotPasswordForm?.get('email')?.hasError('emailAlreadyExists') ===true"
                    matSuffix class="red error">error</mat-icon>
                </div>
                <mat-error *ngIf="forgotPasswordForm?.get('email')?.hasError('pattern')">
                  {{errorMessage?.invalidEmail}}
                </mat-error>
                <mat-error *ngIf="forgotPasswordForm?.get('email')?.hasError('required')">
                  {{errorMessage?.emailrequire}}
                </mat-error>
                <mat-error *ngIf="forgotPasswordForm?.get('email')?.hasError('emailAlreadyExists')===true">
                  {{errorMessage?.emailNotExist}}
                </mat-error>
              </mat-form-field>
            </div>
            <br>
            <div fxLayoutAlign="center">
              <button mat-raised-button class="sendMail" (click)="sendEmail()">
                <div *ngIf="!pageDetails?.buttonLoader">
                  Send Mail
                </div>
                <cen-loader *ngIf="pageDetails?.buttonLoader" [type]="'content'" [name]="'cen-dot-spinner'">
                </cen-loader>
              </button>
            </div>
          </form>
          <ng-container [ngTemplateOutlet]="orOption">
          </ng-container>
        </div>

        <!--Change password form -->
        <ng-container *ngIf="!pageDetails?.isLoader && changePasswordForm && pageDetails?.isChangePassword">
          <div *ngIf="pageDetails?.isVerifiedToken;else elseBlock">
            <mat-divider></mat-divider>
            <br>
            <form [formGroup]="changePasswordForm">
              <div class="input_div">
                <mat-form-field appearance="outline" class="example-full-width">
                  <input data-control="newPassword" placeholder="New Password *" matInput
                    [type]="pageDetails?.passwordField ? 'text' : 'password'" formControlName="password">
                  <mat-icon matSuffix class="show" data-control="showpassword"
                    (click)="pageDetails.passwordField = !pageDetails?.passwordField" matSuffix>
                    {{pageDetails?.passwordField ? 'visibility' :
                    'visibility_off'}}
                  </mat-icon>
                  <mat-icon class="show" matSuffix matTooltip="
                        * At least One number. &#13; 
                        * At least one special character( !@#$%^&*?{}[]+_|;:).&#13; 
                        * One Uppercase and one Lowercase character. &#13;
                        * Minimum length of 7 and Maximum length of 20.">info</mat-icon>
                  <mat-error *ngIf="changePasswordForm?.get('password')?.hasError('required')">
                    {{errorMessage?.passwordRequired}}
                  </mat-error>
                  <mat-error data-control="vaildPassword"
                    *ngIf="changePasswordForm?.get('password')?.hasError('pattern')">
                    {{errorMessage?.passwordPattern}}
                  </mat-error>
                  <mat-error *ngIf="changePasswordForm?.get('password')?.hasError('passwordMatched')">
                    {{errorMessage?.ERROR?.PASSWORD?.oldNewPasswordMatch}}
                  </mat-error>
                </mat-form-field>
              </div>
              <div class="input_div">
                <mat-form-field appearance="outline" class="example-full-width">
                  <input data-control="confirmPassword" placeholder="Confirm Password *" matInput
                    [type]="pageDetails?.showConfirmPassword ? 'text' : 'password'" formControlName="confirmPassword" />
                  <mat-icon class="show" data-control="confirmPasswordvisibility" matSuffix
                    (click)="pageDetails.showConfirmPassword = !pageDetails?.showConfirmPassword"
                    data-control="passwordIcon">
                    {{pageDetails?.showConfirmPassword ? 'visibility' :'visibility_off'}} </mat-icon>
                  <mat-error *ngIf="changePasswordForm?.get('confirmPassword')?.hasError('required')">
                    {{errorMessage?.passwordRequired}}
                  </mat-error>
                  <mat-error *ngIf="changePasswordForm?.get('confirmPassword')?.hasError('passwordMismatch')===true">
                    {{errorMessage?.passwordMismatch}}
                  </mat-error>
                </mat-form-field>
              </div>
            </form>
            <div fxLayoutAlign="center">
              <button data-control="resetpassword" class="sendMail" mat-raised-button (click)="changePassword()">
                <div *ngIf="!pageDetails?.buttonLoader">Reset Password</div>
                <cen-loader *ngIf="pageDetails?.buttonLoader" [type]="'content'" [name]="'cen-dot-spinner'">
                </cen-loader>
              </button>
            </div>
            <ng-container [ngTemplateOutlet]="orOption">
            </ng-container>
          </div>
          <ng-template #elseBlock>
            <div fxLayout="column" fxLayoutAlign="center center">
              <div>
                <img src="../assets/sad1.png" alt="sadImage" />
              </div>
              <div class="center red">
                <h3>Oops...! Your password reset link has been expired!</h3>
              </div>
            </div>
            <ng-container [ngTemplateOutlet]="orOption">
            </ng-container>
          </ng-template>
        </ng-container>
      </mat-card>
      <mat-card fxLayout="column" fxFlex="36%" fxHide.lt-md fxLayoutAlign="center">
        <div class="card-wrapper" (scroll)="infinite()">
          <!-- 1st -->
          <div class="card-item">
            <div class="inner-card" fxLayoutAlign="center" fxLayout="column">
              <div class="image-container">
                <img src="assets/TapHand_Join.svg" alt="" onerror="this.src='./assets/noimage.jpg'" draggable="false">
              </div>
              <h4>Why Join Us?</h4>
              <div class="product-name">
                <p> Join our community of successful affiliates and start monetizing your online presence.
                </p>
                <ul>
                  <li>Generous Commission Structure</li>
                  <li>Access to Exclusive Promotions and Discounts</li>
                  <li>Real-Time Tracking and Analytics</li>
                  <li>Dedicated Affiliate Support Team</li>
                </ul>
              </div>
            </div>
          </div>
          <!-- 2nd -->
          <div class="card-item">
            <div class="inner-card" fxLayoutAlign="center" fxLayout="column">
              <div class="image-container">
                <img src="assets/RecommendPage.svg" alt="" onerror="this.src='./assets/noimage.jpg'" draggable="false">
              </div>
              <h4>Unlock boundless opportunities by referring code!</h4>
              <div class="product-name">
                <p> Share a vast array of code with your audience using our
                  tailored linking tools designed for large publishers, individual bloggers,
                  and social media influencers.
                </p>
              </div>
            </div>
          </div>
          <!-- 3rd -->
          <div class="card-item">
            <div class="inner-card" fxLayoutAlign="center" fxLayout="column">
              <div class="image-container">
                <img src="assets/EarnBag.svg" alt="" onerror="this.src='./assets/noimage.jpg'" draggable="false">
              </div>
              <h4>Earn Commissions Through Referrals!</h4>
              <div class="product-name">
                <p> Unlock a world of commission opportunities by referring our code to others.
                  Join our affiliate program and turn your network into a source of continuous income.
                </p>
              </div>
            </div>
          </div>
          <!-- copy of 1st -->
          <div class="card-item">
            <div class="inner-card" fxLayoutAlign="center" fxLayout="column">
              <div class="image-container">
                <img src="assets/TapHand_Join.svg" alt="" onerror="this.src='./assets/noimage.jpg'" draggable="false">
              </div>
              <h4>Why Join Us?</h4>
              <div class="product-name">
                <p> Join our community of successful affiliates and start monetizing your online presence.
                </p>
                <ul>
                  <li>Generous Commission Structure</li>
                  <li>Access to Exclusive Promotions and Discounts</li>
                  <li>Real-Time Tracking and Analytics</li>
                  <li>Dedicated Affiliate Support Team</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div class="dot">
          <div class="box1">
            <div class="ani1"></div>
          </div>
          <div class="box2">
            <div class="ani2"></div>
          </div>
          <div class="box3">
            <div class="ani3"></div>
          </div>
        </div>
      </mat-card>
    </div>
  </div>
</div>
<ng-template #orOption>
  <div *ngIf="pageDetails?.isVerifiedToken">
    <div class="height"></div>
    <mat-divider></mat-divider>
    <div class="circle_or" fxLayoutAlign="center center">
      <div fxLayoutAlign="center center" class="or-circle">
        <span class="or">or</span>
      </div>
    </div>
  </div>
  <!-- option -->
  <div fxLayoutAlign="center center" fxLayout="column">
    <button class="chip mat-elevation-z4" data-control="newaccount" class="button-one"
      (click)="navigation(pageDetails?.signUpUrl)">
      Create New Account
    </button>
    <button class="chip mat-elevation-z4" data-control="gotosigninpage" class="button-one"
      (click)="navigation(pageDetails?.signInUrl)">
      Sign In
    </button>
    <br />
  </div>
</ng-template>

<ng-template #country>
  <ng-container>
    <div class="select-menu" [ngClass]="{'active': isMenuOpen}">
      <div class="select-btn" (click)="toggleMenu()">
        <!-- <span class="material-symbols-outlined">
                    language
                  </span> -->
        <span class="sBtn-text" *ngFor="let option of countryOptions">
          <span class="flag-icon flag-icon-{{option?.flag}}" *ngIf="option?.text=== environment?.countryCode"></span>
          <span class="option-text" *ngIf="option?.text=== environment?.countryCode">
            {{option?.text}}</span>
        </span>
        <!-- <span class="sBtn-text">
          {{ environment.countryCode }}</span> -->
        <em><span class="material-symbols-outlined">
            expand_more
          </span></em>
      </div>
      <ul class="options">
        <a *ngFor="let option of countryOptions" (click)="selectCountryOption(option?.text)">
          <li class="option" fxLayoutAlign="space-between center">
            <span class="flag-icon flag-icon-{{option?.flag}}"></span>
            <span class="option-text"> {{option?.text}}</span>
          </li>
        </a>
      </ul>
    </div>
  </ng-container>
</ng-template>