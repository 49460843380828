<cen-loader-vii *ngIf="isLoader || listLoader"></cen-loader-vii>
<div *ngIf="!isLoader" fxLayoutGap="0px" class="common-page-container">
    <div>
        <phase-ii-header-page [heading]="pageDetails?.heading" (methodClicked)="exportexcel($event)"
            [buttonInfo]="pageDetails?.buttonInfo">
        </phase-ii-header-page>
    </div>
    <mat-card [ngStyle]="{'margin-top':pageDetails?.tab ? '-41px': '0px' }">
        <div fxLayout="column">
            <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="1%" class="report">
                <div fxFlex="40" fxFlex.lt-md="100%" *ngIf="dateForm">
                    <form [formGroup]="dateForm" fxLayoutGap="2%">
                        <mat-form-field class="filter-align" required fxFlex='49%'>
                            <mat-label>From</mat-label>
                            <input data-control="from" readonly matInput [matDatepicker]="from" placeholder="From"
                                autocomplete="off" formControlName="from" useValueAsDate required
                                [max]="dateForm?.value?.to" (dateChange)="dateChange()">
                            <mat-datepicker-toggle matSuffix data-control="fromDatePicker" [for]="from">
                            </mat-datepicker-toggle>
                            <mat-datepicker #from></mat-datepicker>
                        </mat-form-field>
                        <mat-form-field class="filter-align" required fxFlex='49%'>
                            <mat-label>To</mat-label>
                            <input data-control="to" [disabled]="!dateForm?.value?.from" readonly matInput
                                [matDatepicker]="to" placeholder="End Date" autocomplete="off" formControlName="to"
                                useValueAsDate required [min]="dateForm?.value?.from" [max]="toMaxDate"
                                (dateChange)="dateChange()">
                            <mat-datepicker-toggle matSuffix data-control="toDatePicker" [for]="to">
                            </mat-datepicker-toggle>
                            <mat-datepicker #to></mat-datepicker>
                        </mat-form-field>
                    </form>
                </div>
                <div>
                    <mat-error data-control="toDateError" class="errorStyle" *ngIf="dateForm?.get('to')?.hasError('required') ||
            dateForm?.get('to')?.hasError('matDatepickerMin')">
                        Enter valid date
                    </mat-error>
                </div>
                <div fxLayout="row" fxFlex="60%" fxFlex.lt-md="100%" fxFlex.lt-md="100%" fxLayoutGap="20px"
                    fxLayoutGap.lt-md="5px" fxLayoutAlign="end center" fxLayout.lt-md="column"
                    fxLayoutAlign.lt-md="center">
                    <div class="filter-align" *ngIf="isReport">
                        <mat-form-field fxFlex.lt-md="100%">
                            <mat-label>Reports</mat-label>
                            <mat-select [(value)]="reportName" data-control="report"
                                (selectionChange)="getReport($event)">
                                <mat-option [attr.data-control]="reports" *ngFor="let report of reports"
                                    [value]="report">
                                    {{ report }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="filter-align"
                        *ngIf="pageDetails && pageDetails.storeList && currentUser && currentRole === 'SUPERADMIN'">
                        <mat-form-field fxFlex.lt-md="100%">
                            <mat-label>Store</mat-label>
                            <mat-select [(value)]="storeId" data-control="store"
                                (selectionChange)="selectStore($event)">
                                <mat-option [attr.data-control]="store.name" *ngFor="let store of pageDetails.storeList"
                                    [value]="store.id">
                                    {{ store.name }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="filter-align" *ngIf="locations && locations[0] && locations[0].location_id">
                        <mat-form-field fxFlex.lt-md="100%">
                            <mat-label>Location</mat-label>
                            <mat-select [(value)]="location" data-control="location"
                                (selectionChange)="selectLocation($event)">
                                <mat-option [attr.data-control]="location.location_name"
                                    *ngFor="let location of locations" [value]="location.location_id">
                                    {{ location.location_name }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
            </div>
            <!-- <h5 *ngIf="dataList || pageDetails?.tab" class="note">Note: You can see maximum one year
            records</h5><br>
        <h5 *ngIf="!dataList && !pageDetails?.tab" class="notes">Note: You can see maximum one year
            records</h5><br> -->
            <div *ngIf="pageDetails && !pageDetails?.offer" class="grid" fxLayoutGap="0px">
                <div *ngIf="dataList" fxFlex="100%" class="total-card" fxLayoutGap="0px">
                    <div fxLayout="column" fxLayoutGap="0px" class="summary-align" fxFlex="100%">
                        <div fxLayout="row wrap" fxLayoutGap="0px" fxFlex="100%">
                            <div *ngFor="let data of listSummaryData" fxFlex="25%" fxFlex.lt-lg="33%" fxFlex.lt-md="50%"
                                fxFlex.lt-sm="100%">
                                <div class="summary-card-padding">
                                    <mat-card class="mat-card-class">
                                        <div fxLayout="column" fxFlex="" class="head-title">
                                            <!-- <div fxFlex="100%" fxLayoutGap="0px"
                                            *ngIf="(data?.key === 'Delivery Charge') || (data?.key === 'Extra Amount') || (data?.key === 'Refund')"
                                            class="revenueSubHeader" data-control="key"><span fxFlex="100%">{{ data?.key
                                                }}</span> -->
                                            <!-- <span class='info-card-align' *ngIf="(data?.key === 'Delivery Charge')" fxFlex="10%"><mat-icon  matSuffix
                                                            class="informationCard" matTooltip="Shipping Charge + Pickup Charge"
                                                            [matTooltipPosition]="'above'">
                                                            info</mat-icon></span>
                                                    <span class='info-card-align' *ngIf="(data?.key === 'Extra Amount')" fxFlex="10%"><mat-icon matSuffix
                                                            class="informationCard" matTooltip="Tip + Handling Fee + Additional Charge"
                                                            [matTooltipPosition]="'above'">
                                                            info</mat-icon></span>
                                                            <span class='info-card-align' *ngIf="(data?.key === 'Refund')" fxFlex="10%"><mat-icon matIconSuffix
                                                                class="informationCard"
                                                                matTooltip="Amount Refund + Gift Card Refund"
                                                                    [matTooltipPosition]="'above'">
                                                                    info</mat-icon></span> -->
                                            <!-- </div>
                                        <div fxLayoutGap="0px"
                                            *ngIf="(data?.key === 'Delivery Charge') || (data?.key === 'Extra Amount') || (data?.key === 'Refund')"
                                            class="revenueSubHeaderContent" data-control="keyValue"><span>{{
                                                data?.value | currency:storeCurrency}}</span>
                                        </div> -->
                                            <div fxLayout="row" fxFlex="100%">
                                                <div fxFlex="94%" fxLayoutAlign="none center" fxLayoutGap="0px"
                                                    class="revenueSubHeader" data-control="key">{{ data?.key }}</div>
                                                <span *ngIf='data?.info' fxFlex="16%"
                                                    fxLayoutAlign="end center"><mat-icon fxLayoutAlign="end center"
                                                        matIconSuffix class="informationCard1" matTooltip={{data?.info}}
                                                        [matTooltipPosition]="'above'">
                                                        info</mat-icon></span>
                                            </div>
                                            <div fxLayoutGap="0px" class="revenueSubHeaderContent"
                                                data-control="keyValue">{{ data?.value |
                                                currency:storeCurrency}}
                                            </div>
                                        </div>
                                    </mat-card>
                                </div>
                            </div>
                            <div fxLayoutGap="0px" *ngFor="let data of listSummary" fxFlex="25%" fxFlex.lt-lg="33%"
                                fxFlex.lt-md="50%" fxFlex.lt-sm="100%">
                                <!-- <div fxLayoutGap="0px" fxLayout="column" fxFlex="20%" class="head-title">
                                                                                 <div class="revenueSubHeader" data-control="listKey"><span fxFlex="75%">{{ data?.key }}</span>
                                                                                     <span *ngIf="(data?.key === 'Discounted Price')" fxFlex="10%"><mat-icon matSuffix style="cursor: pointer;" matTooltip="SubTotal - (Other Discount + Coupon Discount)">
                                                                                         info</mat-icon></span>
                                                                                     <span *ngIf="(data?.key === 'Amount To Pay')" fxFlex="10%"><mat-icon matSuffix style="cursor: pointer;" matTooltip="Discounted Price + Tax + Delivery Charge + Extra Amount">
                                                                                         info</mat-icon></span></div>
                                                                                 <div class="revenueSubHeaderContent" data-control="listKeyValue" fxFlex="75%">{{ data?.value | currency:storeCurrency}}
                                                                                 </div>
                                                                             </div> -->
                                <div class="summary-card-padding">
                                    <mat-card class="mat-card-class">
                                        <div fxLayoutGap="0px" fxLayout="column" fxFlex="" class="head-title">
                                            <!-- <div  data-control="listKey"> -->
                                            <div fxLayout="row" fxFlex="100%">
                                                <span fxFlex="94%" class="revenueSubHeader" fxLayoutAlign="none center"
                                                    fxFlex="100%">{{ data?.key }}</span>
                                                <span *ngIf='data?.info' fxFlex="16%"
                                                    fxLayoutAlign="end center"><mat-icon fxLayoutAlign="end center"
                                                        matIconSuffix class="informationCard1" matTooltip={{data?.info}}
                                                        [matTooltipPosition]="'above'">
                                                        info</mat-icon></span>
                                            </div>
                                            <!-- <span class='info-card-align' *ngIf="(data?.key === 'Discounted Price')" fxFlex="10%"><mat-icon matIconSuffix
                                                                     class="informationCard1" matTooltip="Net Sale - (Other Discount + Coupon Discount)"
                                                                     [matTooltipPosition]="'above'">
                                                                     info</mat-icon></span>
                                                             <span class='info-card-align' *ngIf="(data?.key === 'Pending Amount')" fxFlex="10%"><mat-icon matIconSuffix
                                                                     class="informationCard"
                                                                     matTooltip="Discounted Price + Tax + Delivery Charge + Extra Amount - Gift Card Amount (Paid/Refund)"
                                                                     [matTooltipPosition]="'above'">
                                                                     info</mat-icon></span>
                                                             <span class='info-card-align' *ngIf="(data?.key === 'Received Amount')" fxFlex="10%"><mat-icon matIconSuffix
                                                                     class="informationCard" matTooltip="Amount Paid + Gift Card Amount(Paid/Refund)"
                                                                     [matTooltipPosition]="'above'">
                                                                     info</mat-icon></span>
                                                            <span class='info-card-align' *ngIf="(data?.key === 'Gross Sale')" fxFlex="10%"><mat-icon matIconSuffix
                                                                    class="informationCard" matTooltip="Net Sale + Delivery Charge + Extra Amount" [matTooltipPosition]="'above'">
                                                                    info</mat-icon></span>
                                                            <span class='info-card-align' *ngIf="(data?.key === 'Average Order Value')" fxFlex="10%"><mat-icon matIconSuffix
                                                                    class="informationCard" matTooltip="(Gross Sale - Discounted Price) / No Of Orders"
                                                                    [matTooltipPosition]="'above'">
                                                                    info</mat-icon></span> -->
                                            <!-- </div> -->

                                            <div class="revenueSubHeaderContent" data-control="listKeyValue"><span
                                                    *ngIf="(data?.key !== 'Total Amount Paid')" fxFlex="100%">{{
                                                    data?.value | currency:storeCurrency}}</span>
                                                <span *ngIf="(data?.key === 'Total Amount Paid')" fxFlex="100%">{{
                                                    data?.value | currency:storeCurrency}}</span>
                                            </div>
                                        </div>
                                    </mat-card>
                                </div>
                                <!-- <div fxLayoutGap="0px" *ngIf="(data?.key === 'Paid')" fxLayout="column" fxFlex="20%" class="head-title">
                                                                                 <div class="revenueSubHeader" data-control="listKey">{{ data?.key }}</div>
                                                                                 <div class="revenueSubHeaderContent" data-control="listKeyValue">{{ data?.value | currency:storeCurrency}}
                                                                                 </div>
                                                                             </div> -->
                            </div>
                        </div>
                        <!-- <div class=summary-info *ngIf='reportCalculationInfo && reportCalculationInfo.length'>
                        <div fxLayout="column " fxLayoutAlign="flex-start" *ngFor="let info of reportCalculationInfo">
                            <div fxLayout="row " fxLayoutAlign="flex-start">
                                <span fxFlex=15%>
                                    {{info.key}}
                                </span>
                                <span fxFlex=5%>
                                    -
                                </span>
                                <span fxFlex=80%>
                                    {{info.value}}
                                </span>
                            </div>
                        </div>
                    </div> -->
                        <div fxLayout="row wrap" fxLayout.xs="column" fxLayoutAlign="center center" fxLayoutGap="10px"
                            class="list-card1">

                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="pageDetails && pageDetails?.offer" fxLayoutGap="0px" class="grid">
                <div *ngIf="dataList" fxFlex="100%" fxLayoutGap="0px" class="offer-total-card">
                    <div fxLayout="column" *ngIf="dataList?.totalProduct >= 0" fxLayoutGap="15px"
                        class="common-page-container">
                        <div fxLayout="row wrap" fxLayoutAlign="center center" fxLayoutGap="5px" class="list-card">
                            <div fxLayoutGap="0px" fxLayout="column" class="head-title">
                                <div fxLayoutGap="0px" class="offerHeader" data-control="productCount">Total Products
                                </div>
                                <div fxLayoutGap="0px" class="offerHeaderContent" data-control="totalOffer">
                                    {{dataList?.totalProduct}}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div fxLayout="column" *ngIf="dataList?.totalOfferAmount >= 0" fxLayoutGap="15px"
                        class="common-page-container">
                        <div fxLayout="row wrap" fxLayoutAlign="center center" fxLayoutGap="5px" class="list-card">
                            <div fxLayoutGap="0px" fxLayout="column" class="head-title">
                                <div fxLayoutGap="0px" class="offerHeader" data-control="offerAmount">Total Offer Amount
                                </div>
                                <div fxLayoutGap="0px" class="offerHeaderContent" data-control="totalOffer">
                                    {{dataList?.totalOfferAmount | currency: storeCurrency}}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </mat-card>
    <div *ngIf="pageDetails && pageDetails?.chart && chartOptions" class="chart-align">
        <mat-card fxFlex="100" fxFlex.lt-sm="100">
            <mat-card-content>
                <highcharts-chart data-control="chart" *ngIf="chartOptions" class="chart" [Highcharts]="highcharts"
                    [options]="chartOptions">
                </highcharts-chart>
            </mat-card-content>
        </mat-card>
    </div>
    <br>
    <div class="mat-elevation-z2 table">
        <!-- <cen-loader-vii *ngIf="isTableLoading"></cen-loader-vii> -->
        <mat-card>
            <phase-ii-common-card-v-ii [needPaginator]="true" [listProperties]="pageDetails?.listProperty"
                [columnsRef]="pageDetails?.columnRef" [clickable]="false" [paginatorVersion]=2
                (valueChanged)="getChangedData($event)" (paginatorData)="getPaginatorData($event)">
            </phase-ii-common-card-v-ii>
        </mat-card>
    </div>
</div>