import { Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { UntypedFormArray, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { CommonAdminShardConstants } from '../../constants/shared-constant';
import { Subscription } from 'rxjs';
import { AuthService } from '@phase-ii/auth';
import { UsersService } from '../../services/users.service';
import { MatDialog } from '@angular/material/dialog';

import { filter, mergeMap } from 'rxjs/operators';
import { DialogService } from 'libs/common/src/lib/services/dialog.service';


@Component({
  selector: 'phase-ii-size-chart',
  templateUrl: './size-chart.component.html',
  styleUrls: ['./size-chart.component.scss'],
})
export class SizeChartComponent implements OnInit {
  /**
 * Declaring form group.
 * @type {FormGroup}
 */
  chartForm: UntypedFormGroup;
  /**
  * Variable which is used to check if the user is admin or super admin.
  */
  @Input() isSuperAdmin;
  /**
   * Variable which is used to store the storeid value
   */
  @Input() storeId;
  /**
   * Variable which is store the plugin display both system value
   */
  displayBothSystem: boolean;
  /**
   * Varibale which is used to store the default measurement value
   */
  defaultMeasurementSystem: any
  /**
   * Varibale which is used to store  first tab name
   */
  defaultMeasurement: any;
  /**
  * Varibale which is used to store  second tab name
  */
  defaultMeasurement1: any;
  /** 
  *commonConstant used to acccess the common constant measurements.
  */
  commonConstant = new CommonAdminShardConstants();
  /**
   * Variable which is used for store tha tab name
   */
  tabName: any;
  /**
  * Variable which is used to subscribe and unsubscribe the subscriptions.
  * @type {Subscription}
  */
  subscriptionObj: Subscription = new Subscription();
  /**
   * Variable which is used to emit the form value
   */
  @Output() pageDetailsEmit = new EventEmitter();
  /**
  * Variable which is used to emit the form value
  */
  @Output() formEdit = new EventEmitter();
  /**
   * Variable which is used to store the form data in inches
   */
  @Input() sizeChartData: any;
  /**
 * Variable which is used to store the form data in centimeters
 */
  @Input() sizeChartCentimeters: any;
  /**
   * Varibale which is used to store the read only value
   */
  isReadOnly: boolean;
  /**
* this is used to refer the dialog box
*/
  dialogRef: any;
  /**
* this dialog box is used for add/edit category
*/
  @ViewChild('measurementDialog', { static: true }) measurementDialog: TemplateRef<any>;
  /**
   * 
   * @param dialogService access the dialog service function
   * @param userService access the user service function
   * @param authService access the auth service function
   */
  constructor(
    private dialogService: DialogService,
    private userService: UsersService,
    private authService: AuthService,
    private dialog: MatDialog,
  ) { }
  /**
 * Component OnInit life cycle hook
 */
  ngOnInit(): void {
    this.subscriptionObj.add(this.authService.isReadOnly.subscribe(res => {
      this.isReadOnly = res;
    }));
    if (this.storeId) {
      this.subscriptionObj.add(this.authService.getOnePlugin(this.storeId, { pluginCode: 'PRODUCT SIZE CHART' })
        .pipe(filter((res) => {
          if (res && res['result'] && res['result'].customfields && res['result'].customfields.length) {
            for (var i = 0; i < res['result'].customfields.length; i++) {
              if (res['result'].customfields[i] && res['result'].customfields[i].fieldTitle === "Display both system") {
                this.displayBothSystem = res['result'].customfields[i].customFieldsAnswerMappings && res['result'].customfields[i].customFieldsAnswerMappings[0] && res['result'].customfields[i].customFieldsAnswerMappings[0].value;
              }
              if (res['result'].customfields[i] && res['result'].customfields[i].fieldTitle === "Default measurement system") {
                this.defaultMeasurementSystem = res['result'].customfields[i].customFieldsAnswerMappings && res['result'].customfields[i].customFieldsAnswerMappings[0] && res['result'].customfields[i].customFieldsAnswerMappings[0].value;
                if (this.defaultMeasurementSystem == 'Metric (Centimeters)') {
                  this.defaultMeasurement = 'Centimeters';
                  this.defaultMeasurement1 = 'Inches';
                  this.tabName = 'Centimeters';
                }
                if (this.defaultMeasurementSystem == 'Imperial (Inches)') {
                  this.defaultMeasurement = 'Inches';
                  this.defaultMeasurement1 = 'Centimeters';
                  this.tabName = 'Inches';

                }
              }
            }
            this.formInitialization();
          }
          return true;
        }), mergeMap(() => {
          return this.userService.addTemplate;
        })).subscribe((res) => {
          if (res) {
            console.log('res', res);
            this.formEmit();
          }
        }, (err) => {
          this.dialogService.dialogMethod(this.commonConstant.dialogMessages.getOnePlugin, this.commonConstant.dialogType.alert, true);
        }))
    }
    else if (this.isSuperAdmin) {
      this.tabName = 'Inches';
      this.defaultMeasurement = 'Inches';
      this.defaultMeasurement1 = 'Centimeters';
      this.formInitialization();
      this.userService.addTemplate.subscribe((res) => {
        if (res) {
          this.formEmit();
        }
      });
    }


  }
  /**
   * Function which is used to initialize the chart form
   */
  formInitialization() {
    this.chartForm = new UntypedFormGroup({
      Inches: new UntypedFormArray([]),
      Centimeters: new UntypedFormArray([]),
    });
    if (this.sizeChartData && this.sizeChartData.length) {
      this.sizeChartData.forEach((res, index) => {
        this.Inches(res, res && res.measurements[0], this.sizeChartCentimeters[index], this.sizeChartCentimeters[index] && this.sizeChartCentimeters[index].measurements[0]);
      });
      for (let i = 1; i < this.sizeChartData[0].measurements?.length; i++)
        this.addNewValue(null, null, this.sizeChartData, i, this.sizeChartCentimeters);
    } else {
      this.Inches();
    }
    this.subscriptionObj.add(this.chartForm.valueChanges.subscribe(res => {
      if (res) {
        if (this.chartForm && this.chartForm.valid) {
          this.formEdit.emit(this.chartForm.value);
        }
        else {
          const data = {
            chartForm: this.chartForm.value,
            isValid: false
          }
          this.formEdit.emit(data);
        }
      }
    }));
  }
  /**
  * Function which is used to add the size chart form group in form array
  * @param value To fetch form control value
  * @param index Used to find the index of particular form group.
  */
  Inches(data?: any, measurementsData?: any, centimeterData?: any, centimeterMeasurmentData?: any): void {
    if (this.chartForm && (this.chartForm.get('Inches') as UntypedFormArray).length < 15) {
      if (this.chartForm.valid) {
        //Inches
        (this.chartForm.get('Inches') as UntypedFormArray).push(new UntypedFormGroup({
          title: new UntypedFormControl(data ? data.title : null, { validators: this.defaultMeasurementSystem == 'Imperial (Inches)' ? [Validators.required, Validators.maxLength(25)] : [] }),
          measurements: new UntypedFormArray([])
        }));
        // Centimeters
        (this.chartForm.get('Centimeters') as UntypedFormArray).push(new UntypedFormGroup({
          title: new UntypedFormControl(centimeterData ? centimeterData.title : null, { validators: this.defaultMeasurementSystem == 'Metric (Centimeters)' ? [Validators.required, Validators.maxLength(25)] : [] }),
          measurements: new UntypedFormArray([])
        }));
        this.addNewValue((this.chartForm.get('Inches') as UntypedFormArray).length - 1, measurementsData, null, centimeterData, centimeterMeasurmentData);
      }
      else {
        this.dialogService.dialogMethod(this.commonConstant.dialogMessages.FormValid, this.commonConstant.dialogType.alert, true);

        this.chartForm?.markAllAsTouched();

      }
    }
    else {
      this.dialogService.dialogMethod(this.commonConstant.dialogMessages.titleExceeds, this.commonConstant.dialogType.alert, true);

    }
  }
  /**
   * function which is used to add new value of chart form it means measurement values
   */
  addNewValue(mainIndex, data?, editMeasurmentData?, measurementsIndex?, centimeterData?, type?: string) {
    if (editMeasurmentData && editMeasurmentData.length > 0) {
      data = editMeasurmentData;
    }
    if (this.chartForm && this.chartForm.invalid && type === 'isHtml') {
      this.dialogService.dialogMethod(this.commonConstant.dialogMessages.FormValid, this.commonConstant.dialogType.alert, true);
      this.chartForm?.markAllAsTouched();
    } else {
      this.chartForm && (this.chartForm.get('Inches') as UntypedFormArray) && (this.chartForm.get('Inches') as UntypedFormArray).controls.forEach((res, index) => {
        if (mainIndex && mainIndex === index) {
          if ((this.chartForm.get('Inches')['controls'][index - 1].get('measurements') as UntypedFormArray)?.length > 0) {
            //Inches
            (this.chartForm.get('Inches')['controls'][index - 1].get('measurements') as UntypedFormArray)['controls'].forEach((resp, subIndex) => {
              (this.chartForm.get('Inches')['controls'][index].get('measurements') as UntypedFormArray).push(
                new UntypedFormControl(Array.isArray(data) ?
                  (data[index]?.measurements[measurementsIndex] ?
                    data[index]?.measurements[measurementsIndex] : null) : data ? data :
                    null, { validators: this.defaultMeasurementSystem == 'Imperial (Inches)' ? [Validators.required, Validators.maxLength(25)] : [] })
              )
            });
            // Centimeters
            (this.chartForm.get('Centimeters')['controls'][index - 1].get('measurements') as UntypedFormArray)['controls'].forEach((resp, subIndex) => {
              (this.chartForm.get('Centimeters')['controls'][index].get('measurements') as UntypedFormArray).push(
                new UntypedFormControl(Array.isArray(centimeterData) ?
                  (centimeterData[index]?.measurements[measurementsIndex] ?
                    centimeterData[index]?.measurements[measurementsIndex] : null) : centimeterData ? centimeterData :
                    null, { validators: this.defaultMeasurementSystem == 'Metric (Centimeters)' ? [Validators.required, Validators.maxLength(25)] : [] })
              )
            });
          }
          else {
            //Inches
            (this.chartForm.get('Inches')['controls'][index].get('measurements') as UntypedFormArray).push(
              new UntypedFormControl(Array.isArray(data) ?
                (data[index]?.measurements[measurementsIndex] ?
                  data[index]?.measurements[measurementsIndex] : null) : data ? data :
                  null, { validators: this.defaultMeasurementSystem == 'Imperial (Inches)' ? [Validators.required, Validators.maxLength(25)] : [] })
            );
            //Centimeters
            (this.chartForm.get('Centimeters')['controls'][index].get('measurements') as UntypedFormArray).push(
              new UntypedFormControl(Array.isArray(centimeterData) ?
                (centimeterData[index]?.measurements[measurementsIndex] ?
                  centimeterData[index]?.measurements[measurementsIndex] : null) : centimeterData ? centimeterData :
                  null, { validators: this.defaultMeasurementSystem == 'Metric (Centimeters)' ? [Validators.required, Validators.maxLength(25)] : [] })
            );
          }


        }
        else if (!mainIndex) {
          //Inches
          this.chartForm && this.chartForm.get('Inches') && this.chartForm.get('Inches')['controls'][index] &&
            (this.chartForm.get('Inches')['controls'][index].get('measurements') as UntypedFormArray).push(
              new UntypedFormControl(Array.isArray(data) ?
                (data[index]?.measurements[measurementsIndex] ?
                  data[index]?.measurements[measurementsIndex] : null) : data ? data :
                  null, { validators: this.defaultMeasurementSystem == 'Imperial (Inches)' ? [Validators.required, Validators.maxLength(25)] : [] })
            );
          //Centimeters
          (this.chartForm?.get('Centimeters')['controls'][index]?.get('measurements') as UntypedFormArray).push(
            new UntypedFormControl(Array.isArray(centimeterData) ?
              (centimeterData[index]?.measurements[measurementsIndex] ?
                centimeterData[index]?.measurements[measurementsIndex] : null) : centimeterData ? centimeterData :
                null, { validators: this.defaultMeasurementSystem == 'Metric (Centimeters)' ? [Validators.required, Validators.maxLength(25)] : [] })
          );
        }
      });
    }
  }
  /**
   * function which is used to delete the value of chart form value
   * @param index which holds the index value
   * @param ind which holds the measurement value
   */
  deleteNewValue(index, ind: number): void {
    if ((((this.chartForm.get('Inches') as UntypedFormArray).controls[index] as UntypedFormGroup).get('measurements') as UntypedFormArray)['controls'].length > 1) {
      //Inches
      (this.chartForm.get('Inches') as UntypedFormArray)['controls'].forEach((res, index) => {
        (this.chartForm.get('Inches')['controls'][index].get('measurements') as UntypedFormArray).removeAt(ind)
      });
      //Centimeters
      (this.chartForm.get('Centimeters') as UntypedFormArray)['controls'].forEach((res, index) => {
        (this.chartForm.get('Centimeters')['controls'][index].get('measurements') as UntypedFormArray).removeAt(ind)
      });
    }
    else {
      this.dialogService.dialogMethod(this.commonConstant.dialogMessages.valueRemove, this.commonConstant.dialogType.alert, true);
    }
  }
  /**
  * function which is used to delte the title and values
  * @param index which holds the index value
  * @param ind which holds the measurement value
  */
  deleteTitle(index: number, ind: number): void {
    if ((this.chartForm && this.chartForm.get('Inches') as UntypedFormArray) && (this.chartForm.get('Inches') as UntypedFormArray)['controls'].length > 1) {
      //Inches
      (this.chartForm.get('Inches') as UntypedFormArray).removeAt(index);
      //Centimeters
      (this.chartForm.get('Centimeters') as UntypedFormArray).removeAt(index);
      this.chartForm.markAsDirty();
    }
    else {
      this.dialogService.dialogMethod(this.commonConstant.dialogMessages.titleRemove, this.commonConstant.dialogType.alert, true);
    }
  }
  /**
   * function which is used to emit the chart form
   */
  formEmit() {
    if (this.storeId) {
      if (this.defaultMeasurementSystem == 'Metric (Centimeters)') {
        if ((this.chartForm && this.chartForm.get('Centimeters') as UntypedFormArray) && (this.chartForm.get('Centimeters') as UntypedFormArray)['controls'].length) {
          (this.chartForm.get('Centimeters') as UntypedFormArray)['controls'].forEach((resp, ind) => {
            if (this.chartForm && this.chartForm.get('Inches') && this.chartForm.get('Inches')['controls'] &&
              this.chartForm.get('Inches')['controls'][ind] && this.chartForm.get('Inches')['controls'][ind]['controls']['title']) {

              this.chartForm.get('Inches')['controls'][ind]['controls']['title'].setValue(resp.get('title').value);
              (resp.get('measurements') as UntypedFormArray)['controls'].forEach((mea, index) => {
                if (mea.value) {
                  this.chartForm.get('Inches')['controls'][ind]['controls']['measurements']['controls'][index].setValue(isNaN(mea.value) ? mea.value : (mea.value / 2.54).toFixed(2));
                }
              })
            }
          });
        }

      }
      if (this.defaultMeasurementSystem == 'Imperial (Inches)') {
        if ((this.chartForm && this.chartForm.get('Inches') as UntypedFormArray) && (this.chartForm.get('Inches') as UntypedFormArray)['controls'].length) {
          (this.chartForm.get('Inches') as UntypedFormArray)['controls'].forEach((resp, ind) => {
            if (this.chartForm && this.chartForm.get('Centimeters') && this.chartForm.get('Centimeters')['controls'] &&
              this.chartForm.get('Centimeters')['controls'][ind] && this.chartForm.get('Centimeters')['controls'][ind]['controls']['title']) {
              this.chartForm.get('Centimeters')['controls'][ind]['controls']['title'].setValue(resp.get('title').value);
              (resp.get('measurements') as UntypedFormArray)['controls'].forEach((mea, index) => {
                if (mea.value) {
                  this.chartForm.get('Centimeters')['controls'][ind]['controls']['measurements']['controls'][index].setValue(isNaN(mea.value) ? mea.value : (mea.value * 2.54).toFixed(2));
                }
              })
            }
          });


        }
      }
    }
    if (this.chartForm && this.chartForm.valid) {
      this.pageDetailsEmit.emit(this.chartForm.value);
    }
    else {
      this.pageDetailsEmit.emit('Form is not valid');
      this.chartForm?.markAllAsTouched();
    }

  }
  /**
   * Function which is used to tab change
   * @param event which holds the mat tab change event
   */
  onTabChanged(event: any): void {
    if (event && event.tab && event.tab.textLabel) {
      this.tabName = event.tab.textLabel;
    }
  }
  viewMeasuremet() {
    this.dialogRef = this.dialog.open(this.measurementDialog, {
      width: '2000px',
      disableClose: true,
    });
    if (this.defaultMeasurementSystem == 'Metric (Centimeters)') {
      if ((this.chartForm && this.chartForm.get('Centimeters') as UntypedFormArray) && (this.chartForm.get('Centimeters') as UntypedFormArray)['controls'].length) {
        (this.chartForm.get('Centimeters') as UntypedFormArray)['controls'].forEach((resp, ind) => {
          if (this.chartForm && this.chartForm.get('Inches') && this.chartForm.get('Inches')['controls'] &&
            this.chartForm.get('Inches')['controls'][ind] && this.chartForm.get('Inches')['controls'][ind]['controls']['title']) {

            this.chartForm.get('Inches')['controls'][ind]['controls']['title'].setValue(resp.get('title').value);
            (resp.get('measurements') as UntypedFormArray)['controls'].forEach((mea, index) => {
              if (mea.value) {
                this.chartForm.get('Inches')['controls'][ind]['controls']['measurements']['controls'][index].setValue(isNaN(mea.value) ? mea.value : (mea.value / 2.54).toFixed(2));
              }
            })
          }
        });
      }

    }
    if (this.defaultMeasurementSystem == 'Imperial (Inches)') {
      if ((this.chartForm && this.chartForm.get('Inches') as UntypedFormArray) && (this.chartForm.get('Inches') as UntypedFormArray)['controls'].length) {
        (this.chartForm.get('Inches') as UntypedFormArray)['controls'].forEach((resp, ind) => {
          if (this.chartForm && this.chartForm.get('Centimeters') && this.chartForm.get('Centimeters')['controls'] &&
            this.chartForm.get('Centimeters')['controls'][ind] && this.chartForm.get('Centimeters')['controls'][ind]['controls']['title']) {
            this.chartForm.get('Centimeters')['controls'][ind]['controls']['title'].setValue(resp.get('title').value);
            (resp.get('measurements') as UntypedFormArray)['controls'].forEach((mea, index) => {
              if (mea.value) {
                this.chartForm.get('Centimeters')['controls'][ind]['controls']['measurements']['controls'][index].setValue(isNaN(mea.value) ? mea.value : (mea.value * 2.54).toFixed(2));
              }
            })
          }
        });


      }
    }
  }
  onCancel() {
    this.dialog.closeAll();
  }
  /**
   * A lifecycle hook to unsubscribe all details
   */
  ngOnDestroy(): void {
    if (this.subscriptionObj) {
      this.subscriptionObj.unsubscribe();
    }
    this.userService.addTemplate.next(null);
  }
}
