<div class="main-div" [ngClass.lt-sm]="'mob-main-div'" [formGroup]="reviewForm">
    <!--Display product image with rating star-->
    <div fxLayout="row" fxLayoutGap="2%">
        <!-- <div fxFlex="15%" fxLayoutAlign="center">
            <img [src]="awsImage+mainImage" class='mobile-dialog-product-image' [alt]="productName">
        </div> -->
        <div fxLayout="column" class="product-style" fxFlex="90%" fxLayoutGap="2%" fxLayoutAlign="start">
            <div fxLayout="row wrap" class="heading" fxLayoutGap.lt-sm="2px">
                <span>{{'WRITE_REVIEW.EXPERIENCE_TEXT' | translate}}</span>&nbsp;
                <span class="product-name">{{productName}}?</span>
            </div>
            <!--Display rating stars-->
            <div fxLayout="row" fxLayoutGap="2%">
                <ng-container *ngFor="let ratingId of stars;index as i">
                    <input matInput formControlName="rating" required hidden>
                    <mat-icon class="stars" [ngClass.xs]="'mobile-stars-size'" [id]="'star_'+i"
                        (click)="countStar(i+1,true)" [attr.data-control]="i+1 + ' ' + 'rating'">
                        {{showIcon(i)}}
                    </mat-icon>
                </ng-container>
            </div>
            <mat-error class="ratings-error" data-control="ratingsRequiredError"
                *ngIf="reviewForm?.get('rating')?.touched && reviewForm?.get('rating')?.hasError('required')">
                {{'WRITE_REVIEW.RATING_ERROR' | translate}}
            </mat-error>
        </div>
        <div fxFlex="10%" fxLayoutAlign="end">
            <mat-icon class="show-cursor" (click)="onCancel()">close</mat-icon>
        </div>
    </div>
    <h3 style="margin-bottom: 5px;">{{'WRITE_REVIEW.WRITE_REVIEW' | translate}}</h3>
    <div fxLayout="column" class="form-scroller">
        <!--Form for review title and description-->
        <div fxLayout="row">
            <mat-form-field fxFlex="100%">
                <input matInput [placeholder]="'WRITE_REVIEW.REVIEW_TITLE' | translate" formControlName="title" data-control="titleInput">
                <mat-error *ngIf="reviewForm?.get('title')?.hasError('required')"
                    data-control="reviewTitleRequiredError">{{'WRITE_REVIEW.REVIEW_TITLE_ERROR' | translate}}
                </mat-error>
                <mat-error *ngIf="reviewForm?.get('title')?.hasError('pattern')" data-control="reviewTitlePatternError">
                    {{'WRITE_REVIEW.ACCEPTS_CHARACTER' | translate}} </mat-error>
                <mat-error data-control="maxLengthExceeds" *ngIf="reviewForm?.get('title')?.hasError('maxlength')">
                    {{'WRITE_REVIEW.REVIEW_LENGTH_ERROR' | translate}}
                </mat-error>
            </mat-form-field>
        </div>
        <div fxLayout="row">
            <mat-form-field fxFlex="100%">
                <textarea matInput [placeholder]="'WRITE_REVIEW.REVIEW_DESCRIPTION' | translate" formControlName="description"
                    data-control="descriptionInput"></textarea>
                <!-- <mat-error *ngIf="reviewForm?.get('description')?.hasError('required')">
                    {{errorMessages?.reviewMessageRequired}}
                </mat-error> -->
                <mat-error *ngIf="reviewForm?.get('description')?.hasError('pattern')"
                    data-control="reviewMessageCharacterOnlyError">
                    {{'WRITE_REVIEW.DESCRIPTION_REVIEW_ERROR' | translate}} </mat-error>
                <mat-error *ngIf="reviewForm?.get('description')?.errors?.maxlength"
                    data-control="descriptionMaxLengthExceed">
                    {{'WRITE_REVIEW.DESCRIPTION_LENGTH_ERROR' | translate}}
                </mat-error>

            </mat-form-field>
        </div>
        <!--Display Uploaded images and upload button-->
        <div fxLayout="column">
            <h3 style="margin-bottom: 0px;margin-top: 0px;">{{'WRITE_REVIEW.UPLOAD_IMAGE_TITLE' | translate}}</h3>
            <p>{{'WRITE_REVIEW.UPLOAD_IMAGE_DESCRIPTION' | translate}}</p>
            <!--Display uploaded images-->
            <div fxLayout="row" fxFlex="10" fxLayoutGap="20px" fxLayout="row wrap" *ngIf="files?.length">
                <div *ngFor="let image of files;let id=index" class="review-image">
                    <img [actualImageURL]="image?.url?.includes('base64') && image?.url?.includes(',') ?image?.url: awsImage+image?.url"
                        [alt]="productName" [imageExtension]="imageExtension?.AVIF_FORMAT" [onErrorEvent]="{src:'./assets/image-not-available-3.png'}">
                    <mat-icon class="image-close" (click)="removeImage(id)" data-control="removeImage">close</mat-icon>
                </div>
            </div>
            <!--Display upload image button-->
            <div fxLayoutAlign="start" fxFlex="50" *ngIf="files?.length<5">
                <!-- <button mat-stroked-button class="upload-button" (click)=file?.click()
                    data-control="uploadImage">{{constantReviewDetails?.uploadImageButtonText}}</button> -->
                <div class="upload-button" (click)=file?.click() data-control="uploadImage">
                    {{'WRITE_REVIEW.UPLOAD_IMAGE_BUTTON' | translate}} </div>
            </div>
            <input #file multiple id="fileInput" type='file' accept=".jpg,.png" (click)="file.value = null"
                (change)="handleFileSelect($event)" data-control="fileSelect">

        </div>
        <br>
        <!--Display submit and cancel buttons-->
        <div fxLayout="row" fxLayoutGap="5%" fxLayoutAlign="center center">
            <button mat-button class="button-class" (click)="onCancel()"
                data-control="cancelReview">{{'WRITE_REVIEW.CANCEL_BTN' | translate}}</button>
            <button mat-raised-button class="add-button" (click)="onSubmit()" data-control="submitReview">
                {{'WRITE_REVIEW.SUBMIT_BTN' | translate}}
            </button>
        </div>
    </div>
</div>