import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { ButtonInfo, ColumnsRef, CommonConstants, CommonDataService, Heading, ListProperties } from '@phase-ii/shared';
import { UsersService } from '../../services/users.service';
import { DialogService } from '@phase-ii/common';
import { addEditUserConstant } from '../../constants/users-constant';
import { Subscription } from 'rxjs';

@Component({
  selector: 'phase-ii-view-customer-list',
  templateUrl: './view-customer-list.component.html',
  styleUrls: ['./view-customer-list.component.scss'],
})
export class ViewCustomerListComponent implements OnInit, OnDestroy {

  /**
  * Variable which is used to subscribe and unsubscribe.
  * @type {Subscription}
  */
  subscriptionObj = new Subscription();

  /**
  * addEditUserConstant used to acccess the addedituser Constants values.
  */
  addEditUserConstant: addEditUserConstant;

  /**
 * customerList array variable used to store the customerList variable.
 */
  customerList: [];

  /**
  * Variable which is used to have all the needed variables.
  * @type {object}
  */
  pageDetails: {
    heading: Heading,
    buttonInfo: ButtonInfo[],
    columnRef: ColumnsRef[],
    isLoader: boolean,
    userdetailsId: any,
  } = {
      heading: null,
      buttonInfo: null,
      columnRef: null,
      isLoader: false,
      userdetailsId: null
    };

  /**
  * Variable which is used to store pagination Details.
  */
  paginationDetails: {
    offset: number,
    pageSize: number;
    itemsCount?: number;
    limit?: number;
  } = {
      pageSize: new CommonConstants().paginator.defaultPageSize,
      offset: 0,
      itemsCount: null,
      limit: 10
    };

  /**
  * Variable which is used to store property needed property of card list
  * @type {ListProperties}
  */
  listProperty: ListProperties = {
    columns: 5,
    suffixText: true,
    searchPlaceHolder: 'User name',
    localSearchFields: ['name'],
    noPagination: true,
    isNewImage: {
      isNew: false,
      url: './assets/empty-customer-list.png',
      text: 'No Customer List Found'
    }
  };

  /**
   * It initalize object before component is loaded
   * @param usersService used to get userdetails customer list
   * @param router this service is used for page navigation
   * @param paginationService contains behaviour subject variable used to set data into common card 
   * @param dialogService contains dialog related informartion
  *@param commonDataService used to detect changes
  * */
  constructor(
    private router: Router,
    private usersService: UsersService,
    private dialogService: DialogService,
    private route: ActivatedRoute,
    private commonDataService: CommonDataService,
  ) {
    this.addEditUserConstant = new addEditUserConstant();
  }


  /**
 * Angular life cycle hooks
 */
  ngOnInit(): void {
    this.variableInitialization();
    this.getAllCustomerList();
  }

  /**
  *variableInitialization method user to initiaze.
  * @type {object}
  */
  variableInitialization() {
    this.route.params.subscribe((params: Params) => {
      if (params && params.id) {
        this.pageDetails.userdetailsId = this.commonDataService.getParam(params.id);
      }
    });

    this.pageDetails.heading = {
      title: this.addEditUserConstant.customerheading.productlistTitle,
      description: this.addEditUserConstant.customerheading.productlistDescryption,
    };

    this.pageDetails.columnRef = this.addEditUserConstant.customersDetails;

    this.pageDetails.buttonInfo = [{ name: 'Back', class: 'primary-button', method: 'onNavigation', disabled: false },];
  }


  /**
  *getAllCustomerList method is used to get customerdetails
  * @type {object}
  */
  getAllCustomerList() {
    this.pageDetails.isLoader = true;
    this.subscriptionObj.add(this.usersService.getCustomerDetails(this.pageDetails.userdetailsId).subscribe((res: any) => {
      if (res && res.customerList) {
        this.customerList = res.customerList;
        res.customerList.forEach(element => {
          if (element) {
            if (element.Email && element.Email.includes('@')) {
              const email = this.censorEmail(element.Email);
              element.Email = email;
            }
            const phoneString = element && element.ContactNumber && element.ContactNumber.toString();
            const numberFormatChange = '*******' + phoneString.substr(phoneString.length - 3);
            element.ContactNumber = numberFormatChange;
            element.CommissionAmount = Number(element && element.CommissionAmount).toFixed(2);
          }
        })
      }
      this.listProperty.isNewImage.isNew = res.customerList == 0 ? true : false;
      this.pageDetails.isLoader = false;
    }, err => {
      this.pageDetails.isLoader = false;
      this.dialogService.dialogMethod(this.addEditUserConstant.dialogMessages.failedCustomerDetails,
        this.addEditUserConstant.dialogType.failure);
    }))
  }

  /**
  * Method which is used for trigger the function of header buttons
  * @param event To get the function name 
  */
  buttonAction(event: any): void {
    this[event]();
  }

  /** 
 * Function which is used to navigate back
 */
  onNavigation(): void {
    this.router.navigate(['app/userlist']);
  }
  /**
 * method to hide name in email using *
 */
  censorWord(str) {
    return str[0] + "*".repeat(str.length - 2) + str.slice(-1);
  }

  /**
  * method to hide first half email using *
  * @param email 
  * @returns 
  */
  censorEmail(email) {
    var [arr, domain] = email.split("@");
    return this.censorWord(arr) + "@" + domain;
  }

  /**
  * Component ondestroy life cycle hook.
  * All subscriptions are unsubscribe here.
  */
  ngOnDestroy(): void {
    this.subscriptionObj.unsubscribe();
  }
}
