/**
 * This component is used to display the option list
 * Author: S.P Priya
 */
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SharedService } from '../../services/shared.service';
/**
 * This component is used to display the option list
 */
@Component({
  selector: 'phase-ii-options-display',
  templateUrl: './options-display.component.html',
  styleUrls: ['./options-display.component.scss']
})
export class OptionsDisplayComponent implements OnInit {
  /**
   * Variable to store the List of options available.
   * @type {object}
   */
  @Input() productOptions:any;
  /**
   * Variable used to display the selected option object
   * @type {object}
   */
  optionObject = {};
  /**
   * To emit the selected option data.
   * @type {EventEmitter}
   */
  @Output() optionData = new EventEmitter();
  /**
   * Constructor which is used to injects the services.
   * @param sharedService To get the service form shared service
   */
  constructor(private sharedService: SharedService) { }

  /**
   * Component on init life cycle hook
   */
  ngOnInit(): void {
    if (this.productOptions && this.productOptions.length) {
      for (let a = 0; a < this.productOptions.length; a++) {
        if (this.productOptions[a] && this.productOptions[a].controlType !== "CHECKBOX" &&
          this.productOptions[a].optionValues && this.productOptions[a].optionValues.length) {
          let optionValueIndex = this.productOptions[a].optionValues.findIndex(value => value && value.productOptionValueMappings && value.productOptionValueMappings[0] && value.productOptionValueMappings[0].isDefault);
          if (optionValueIndex >= 0) {
            this.productOptions[a].defaultValue = optionValueIndex;
            this.productOptionChange(a, optionValueIndex, this.productOptions[a].optionValues[optionValueIndex].optionValue);
          }
        }
      }
    }
  }
  /**
    * Method which can be used to process the product options
    */
  productOptionChange(optionIndex: number, optionValueIndex: number, optionValueName: any):void {
    const resultData = this.sharedService.productOptionChange(this.productOptions, this.optionObject, optionIndex, optionValueIndex, optionValueName);
    if (resultData) {
      this.optionObject = resultData.optionObject;
    }
    this.optionData.emit(resultData);
  }
  /**
   * Method used to check selected value in the check box
   * @param optionValueName defines the option vlaue name
   * @param optionName defines the option name
   * @returns the checkbox selected or not
   */
  checkValue(optionValueName:string, optionName:string) :boolean{
    if (this.optionObject && this.optionObject[optionName] && this.optionObject[optionName].find(val => val && val.optionValue === optionValueName)) {
      return true;
    } else {
      return false;
    }
  }
}
