/**
 * Directive used to format image tag.
 * Author: Sajin S
 */
import { Directive, ElementRef, HostListener, Input, Renderer2 } from '@angular/core';
/**
 * Directive used to format image.
 */
@Directive({
  selector: '[actualImageURL],[imageExtension],[imageWidth],[onErrorEvent]',
})
export class ImageOptimizerDirective {
    /**
  * component constructor which is used to inject the required services.
  * @param el used to store the reference of the element.
  * @param renderer used to set properties.
  */
  constructor(private el :ElementRef,
    private renderer: Renderer2
  ) { }
/**
 * variable used to get src image url.
 */
  @Input() actualImageURL:any;
  /**
   * Variable additionally check to load onError event.
  */
  loadOnError = false;
    /**
   * Variable used to get the image extension.
   */
  @Input() imageExtension:any;
    /**
   * Variable used to get image width.
   */
  @Input() imageWidth:any;
    /**
   * Variable used to get the onerror event.
   */
  @Input() onErrorEvent:any;
    /**
   * Variable used to store formatted file name.
   */
  formattedFileName!:string;
      /**
    * Variable used to store the available image extensions.
    */
  fileExtension  = {
    GIF_FORMAT: 'gif',
    WEPB_FORMAT: 'webp',
    AVIF_FORMAT: 'webp'
   };
/**
 * Triggers on onError 
 * @param event holds the error event.
 */
   @HostListener('error', ['$event']) onError(event:Event) {
    this.onImageError(event);
  }
/**
 * Method on angular life cycle hooks.
 */
  ngOnChanges(): void {
    this.loadOnError = false;
    if (this.actualImageURL && this.imageExtension) {
      this.actualImageURL = this.actualImageURL.replaceAll(" ","%20");
    if (!this.actualImageURL?.includes('base64') && !this.actualImageURL?.includes(',') && !this.actualImageURL?.includes('assets/')) {
      let path = this.actualImageURL.slice(0, this.actualImageURL.lastIndexOf('/') + 1);
      let fileName = this.actualImageURL.slice(this.actualImageURL.lastIndexOf('/') + 1, this.actualImageURL.lastIndexOf('.'));
      let originalExtension = this.actualImageURL.slice(this.actualImageURL.lastIndexOf('.') + 1);
      this.imageExtension = originalExtension === this.fileExtension.WEPB_FORMAT || originalExtension === this.fileExtension.AVIF_FORMAT ? originalExtension : (originalExtension === this.fileExtension.GIF_FORMAT && this.imageExtension === this.fileExtension.AVIF_FORMAT ?  this.fileExtension.WEPB_FORMAT : this.imageExtension);
      if (fileName && this.imageWidth && this.imageExtension) {
        this.formattedFileName = path + fileName + '-' + this.imageWidth + '.' + this.imageExtension;
      }
      else if (fileName && this.imageExtension) {
        this.formattedFileName = path + fileName + '.' + this.imageExtension;
      }
      this.setSrcAttribute(this.formattedFileName);
    }else{
      this.setSrcAttribute(this.actualImageURL);  
    }
  }else if(this.actualImageURL){
    this.setSrcAttribute(this.actualImageURL);
  }else if (this.onErrorEvent) {
    if(this.onErrorEvent.src) this.renderer.setAttribute(this.el.nativeElement, 'src', this.onErrorEvent.src);
    if(this.onErrorEvent.style){
      const styles = this.onErrorEvent.style.split(/[,;]/);
      styles.forEach((style:any) => {
        const [key, value] = style.split(':');
        if (key && value) {
          this.renderer.setStyle(this.el.nativeElement, key.trim(), value.replace('!important', '').trim());
        }
      });
    }
  }
  }
    /**
   * Method used to set the src attribute to the image tag.
   * @param imageSource holds the source url to set in image tag.
   */
  setSrcAttribute(imageSource:any){
    this.renderer.setAttribute(this.el.nativeElement, 'src', imageSource);
  }
/**
 * Method triggers during onError.
 * @param event holds the onerror event.
 */
  onImageError(event:any){
    if (event && event.target && event.target.src && event.target.src === this.formattedFileName && !this.loadOnError) {
      event.target.src = this.actualImageURL;
      this.loadOnError = true;
    } else if (this.onErrorEvent) {
      Object.entries(this.onErrorEvent).forEach(([key, value]) => {
        if (key && value) event.target[key] = value;
      })
    }
  }
}
