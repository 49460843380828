import { Injectable } from '@angular/core';
import { HttpRoutingService } from 'libs/common/src/lib/services/httpRouting.service';
import { API } from '../../../../common/src/lib/constants/api-routes';

@Injectable({
  providedIn: 'root'
})

export class ReportsService {

   /**
   * Component constructor to inject required services.
   * @param httpService to route for API.
   */
  constructor(
    private httpService: HttpRoutingService
  ) { }

/**
* This method is used to get overall sales of store for admin
* @param storeId store id
* @param data data
* @returns fetched revenue data
*/
  getSalesOverTime(storeId, data) {
    const url = this.httpService.replaceUrlParam(API.REVENUE_REPORT, {
      storeId: storeId,
    });
    return this.httpService.postMethod(url, data);
  }

  /**
* This method is used to get overall sales of store for superadmin
* @param data data
* @returns fetched revenue data
*/
  getAllSalesOverTime(data) {
    return this.httpService.postMethod(API.OVERALL_REVENUE_REPORT, data);
  }
  /**
* This method is used to export overall sales of store for admin
* @param storeId store id
* @param data data
* @returns export revenue data
*/
  exportTotalSales(storeId, data) {
    return this.httpService.postMethod('stores/' + storeId + '/export/report/revenue', data);
  }

/**
* This method is used to get overall sold product of store for admin
* @param storeId store id
* @param data data
* @returns fetched sold product data
*/
  getProductReport(storeId, data) {
    const url = this.httpService.replaceUrlParam(API.PRODUCT_REPORT, {
      storeId: storeId,
    });
    return this.httpService.postMethod(url, data);
  }
  /**
* This method is used to get overall sold product of store for superadmin
* @param data data
* @returns fetched sold product data
*/
  getAllProductReport(data) {
    return this.httpService.postMethod(API.OVERALL_PRODUCT_REPORT, data);
  }
  /**
* This method is used to export overall sold product of store for admin
* @param storeId store id
* @param data data
* @returns export sold product data
*/
  exportProductReport(storeId, data) {
    return this.httpService.postMethod('stores/' + storeId + '/productReport', data);
  }

  /**
* This method is used to get overall customer who placed orders for admin
* @param storeId store id
* @param data data
* @returns fetched customer orders
*/
  getCustomerReport(storeId, data) {
    const url = this.httpService.replaceUrlParam(API.CUSTOMER_REPORT, {
      storeId: storeId,
    });
    return this.httpService.postMethod(url, data);
  }
    /**
* This method is used to get overall customer who placed orders for superadmin
* @param data data
* @returns fetched customer orders
*/
  getAllCustomerReport(data) {
    return this.httpService.postMethod(API.OVERALL_CUSTOMER_REPORT, data);
  }
    /**
* This method is used to export overall customer who placed orders for admin
* @param storeId store id
* @param data data
* @returns export customer orders
*/
  exportCustomerReport(storeId, data) {
    return this.httpService.postMethod('stores/' + storeId + '/customerReport', data);
  }
    /**
* This method is used to get overall offers applied on orders for admin
* @param storeId store id
* @param data data
* @returns fetched orders with offers
*/

  getOfferReport(storeId, data) {
    const url = this.httpService.replaceUrlParam(API.OFFER_REPORT, {
      storeId: storeId,
    });
    return this.httpService.postMethod(url, data);
  }
      /**
* This method is used to get overall offers applied on orders for superadmin
* @param data data
* @returns fetched orders with offers
*/
  getAllOfferReport(data) {
    return this.httpService.postMethod(API.OVERALL_OFFER_REPORT, data);
  }
      /**
* This method is used to export overall automatic offers applied on orders for admin
* @param storeId store id
* @param data data
* @returns export orders with automatic offers
*/
  exportAutomaticOfferReport(storeId, data) {
    return this.httpService.postMethod('stores/' + storeId + '/automaticOfferReport', data);
  }
        /**
* This method is used to export overall manual offers applied on orders for admin
* @param storeId store id
* @param data data
* @returns export orders with manual offers
*/
  exportManualOfferReport(storeId, data) {
    return this.httpService.postMethod('stores/' + storeId + '/manualOfferReport', data);
  }
/**
* This method is used to export overall orders for admin
* @param storeId store id
* @param data data
* @returns export orders with income
*/
  exportOrderReport(storeId, data) {
    return this.httpService.postMethod('stores/' + storeId + '/orderReport', data);
  }

}
