/**
 * this service used for executing bulk operation api's
 */
import { Injectable } from '@angular/core';
import { HttpRoutingService } from 'libs/common/src/lib/services/httpRouting.service';
/**
 * this service used for executing bulk operation api's
 */
@Injectable({
  providedIn: 'root'
})
export class BulkOperationService {
  /**
   * Component constructor which is used to inject the required services.
   * @param httpRoutingService this service is used for request & response of backend api.
   */

  constructor(private httpRoutingService: HttpRoutingService) { }
  /**
   * this method used to download sample import sheet
   * @param apiUrl sheet url
   * @returns 
   */
  getsampleTemplate(apiUrl) {
    return this.httpRoutingService.getMethod(apiUrl);
  }
  /**
   * this method used to import requested data
   * @param apiUrlDb backend url
   * @param files files to be imported
   * @param method which holds method name.
   * @param formField constrains
   * @param mainImage images to upload
   * @returns 
   */
  importData(apiUrlDb, files, method, formField?, mainImage?, queryParams?) {
    const storageImage = [];
    const formData: any = new FormData();
    if (formField && formData) {
      formData.append('data', JSON.stringify(formField));
    }
    if (mainImage) {
      formData.append("mainImage", mainImage, mainImage['name']);
    }
    for (let i = 0; i < (files && files.length); i++) {

      if (files[i] && files[i].name && (files[i].name.substring(files[i].name.lastIndexOf('.')) === '.xlsx' || files[i].name.substring(files[i].name.lastIndexOf('.')) === '.csv')) {
        formData.append("xlsxFiles", files[i], files[i]['name']);
      } else if (files[i] && files[i].name && files[i].name.substring(files[i].name.lastIndexOf('.')) !== '.xlsx' && !files[i].changeFileName) {
        formData.append("images", files[i], files[i]['name']);
      }
      else if (files[i] && files[i].name && files[i].name.substring(files[i].name.lastIndexOf('.')) !== '.xlsx' && files[i].changeFileName) {
        formData.append("images", files[i], files[i]['changeFileName']);
      }
      else {
        storageImage.push(files[i]);
      }
    }
    formData.append("storageImage", JSON.stringify(storageImage));
    // return this.httpRoutingService[method](apiUrlDb, formData);
    return this.httpRoutingService[method](apiUrlDb, formData, queryParams ? queryParams : null);
  }
  /**
   * this method used to export requested data
   * @param apiUrlDb backend url.
   * @param data constrains for export.
   * @returns 
   */
  exportData(apiUrlDb, data) {
    // console.log(data);
    return this.httpRoutingService.postMethod(apiUrlDb, data);
  }
  /**
  * Method is used to export order data
  * @param apiUrl Define the export url in env
  * @param data Define the data
  * @return response
  */
  exportOrderData(apiUrl: any, data: any) {
    return this.httpRoutingService.postMethodForExport(apiUrl, data);
  }
}
