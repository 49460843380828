import { Component, Inject, OnDestroy, OnInit, PLATFORM_ID, TemplateRef, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '@phase-ii/auth';
import { DialogService } from 'libs/common/src/lib/services/dialog.service';
import { of, Subscription } from 'rxjs';
import { mergeMap, switchMap } from 'rxjs/operators';
import { UntypedFormControl } from '@angular/forms';
import { CommonConstants } from 'libs/shared/src/lib/constants/shared-constant';
import { CommonDataService } from 'libs/shared/src/lib/services/common-data.service';
import { CommonService } from 'libs/shared/src/lib/services/common.service';
import { ButtonInfo, Heading } from '@phase-ii/shared';
import { plans } from '../../models/upgrade-plan';
import { isPlatformBrowser } from '@angular/common';
import { ExcelService } from '../../services/excel.service';

/**
 * AUTHOR: SANTHOSH S and VIGNESHWARAN R
 * Component is used to upgrade/downgrade plan of a particular client for both admin and superadmin
 */
@Component({
  selector: 'phase-ii-upgrade-plan',
  templateUrl: './upgrade-plan.component.html',
  styleUrls: ['./upgrade-plan.component.scss']
})
export class UpgradePlanComponent extends CommonConstants implements OnInit, OnDestroy {

  /**
   * Subscription object array
   * @type {Subscription[]}
   */
  subscriptionArray: Subscription[] = [];
  /**
   * Variable to store the plans that are fetched form the back end
   * @type {any}
   */
  planTypes: any = [];
  /**
   * Variable to store all the features for the particular plan
   * @type {any}
   */
  features: any = [];
  selectedFeatures: any = [];
  /**
   * Variable used to store  previouslySelect PlanHierarchy
   * @type {any}
   */
  previouslySelectPlanHierarchy: any;
  frequency = new UntypedFormControl();

  /**
   * PageDetails object is used to store the variables used inside this component.
   * @type {object}
   */
  pageDetails: {
    heading: Heading,
    buttonInfo: ButtonInfo[],
    pageSize: number,
    offset: number,
    limit: number,
    isLoader: boolean,
    clicked: boolean,
    isallow: boolean,
    proceedPlan: boolean,
    planLoader : boolean
  } = {
      heading: null,
      pageSize: 5,
      buttonInfo: null,
      offset: 0,
      limit: 10,
      isLoader: null,
      clicked: null,
      isallow: null,
      proceedPlan: null,
      planLoader:false
    };
  /**
   * Variable to store the store id
   * @type {string}
   */
  storeId: string;
  /**
   * Variable to store the current subscription (ex: monthly/yearly)
   * @type {any}
   */
  previouslySelectedSubscription: any;
  /**
   * Variable to store the current plan and its details
   * @type {any}
   */
  previouslySelectedPlan: any;
  /**
   * Variable to store the hierarchy of the plans
   * @type {number}
   */
  hierarchy: number;
   /**
   * Variable to store the after closed event of a dialog boc
   * @type {any}
   */
  dialogRef: any;
  /**
   * Variable to store the new selected plan
   * @type {any}
   */
  newSelectedPlan: any;
  /**
   * Variable to store the new selected subscription
   * @type {any}
   */
  newSelectedSubscription: any;
  /**
   * Variable to store the previous wallet amount details
   * @type {any}
   */
  walletAmount: any;
  /**
   * Variable to check whether the plan is a free plan or not
   * @type {boolean}
   */
  isFreePlan: boolean;
  /**
   * Variable to check whether the page is for superadmin
   * @type {boolean}
   */
  isSuperAdmin: boolean;
  planEndDate: any;
  /**
   * Variable used to the payment page or plan list page
   * @type {boolean}
   */
  showPayment: boolean;
  /**
   * Variable to store the newly selected plan details along with the subscription details
   * @type {any}
   */
  selectedPlanDetails: any;
  /**
   * Variable to store the plan details
   * @type {any}
   */
  planDetails: any;
  /**
   * Variable to store the current store details
   * @type {any}
   */
  storeDetails: any;
  /**
   * Variable to store the currency unit of the particular store
   * @type {string}
   */
  currencyUnit = 'USD';
  /**
   * Variable to store the balance amount
   * @type {number}
   */
  balanceAmount: number;
  /**
   * Variable to store the old plan data
   * @type {any}
   */
  oldPlan: any = null;
  /**
   * Variable to store the new subscription data
   * @type {any}
   */
  newSubscriptionData: any = null;
  /**
   * Variable to store the currency symbol
   * @type {any}
   */
  currencySymbol: any;
  /**
   * Variable used to find whether plan payment page called from update plan page
   * @type {boolean}
   */
  fromUpdatePlan = true;
  /**
* Variable used to store referalCodeDetails .
* @type {any}
*/
  referalCodeDetails: any
  /**
* Variable used to store referal Discount Value .
* @type {any}
*/
  referalDiscountValue: any
  /**
   * Component constructor for injecting needed services
   * @param commonService variable to access all the API calls within the common service
   * @param dialogService variable to open and close the dialog box
   * @param commonDataService variable to access the functions for accessing the get/set params function
   * @param route variable to subscribe the params from the URL
   * @param router variable to store the function to navigate to other pages
   * @param dialog variable to store the functions used to open or close the dialog box
   * @param authService variable to access all the functions of the auth service
   */
  constructor(
    private commonService: CommonService,
    private dialogService: DialogService,
    private commonDataService: CommonDataService,
    private route: ActivatedRoute,
    private router: Router,
    private dialog: MatDialog,
    private authService: AuthService,
    private excelService: ExcelService,
    @Inject(PLATFORM_ID) private platformId: object
  ) {
    super();
  }
  /**
   * Angular life cycle begins
   * @type {void}
   */
  ngOnInit(): void {
    this.pageDetails.isLoader = true;
    this.pageDetails.heading = {
      title: this.planHeading && this.planHeading.title,
      description: this.planHeading && this.planHeading.listDescription
    };
    this.pageDetails.buttonInfo = [
      {
        name: 'Cancel', class: 'secondary-button', method: 'navigateToMemberPage'
      },
    ];
    this.subscriptionArray.push(this.route.params.pipe(mergeMap((res: any) => {
      if (res && res.id) {
        this.storeId = this.commonDataService.getParam(res.id);
        return this.authService.user;
      }
    }), switchMap((userData: any) => {
      this.planEndDate = userData && userData.endDate && userData.endDate.split('T')[0];
      if (userData && userData.storeId === null) {
        this.isSuperAdmin = true;
        return this.authService.getAdminDetails(this.storeId);
      }
      else {
        return of(userData);
      }
    })).subscribe((res) => {
      if (res) {
        if (this.isSuperAdmin) {
          this.storeDetails = res.currentUser;
        }
        else {
          this.storeDetails = res;
        }
        this.storeDetails.address = typeof (this.storeDetails.address) === 'string' ? JSON.parse(this.storeDetails.address) : this.storeDetails.address;
        this.currencyUnit = this.isSuperAdmin ? (this.storeDetails.store && this.storeDetails.store.currencyData && this.storeDetails.store.currencyData.currencyUnit ? this.storeDetails.store.currencyData.currencyUnit : 'USD') :
          (this.storeDetails.purchasedCurrency && this.storeDetails.purchasedCurrency.currencyUnit ? this.storeDetails.purchasedCurrency.currencyUnit : 'USD');
        this.currencySymbol = this.isSuperAdmin ? (this.storeDetails.store && this.storeDetails.store.currencyData && this.storeDetails.store.currencyData.currencySymbol ? this.storeDetails.store.currencyData.currencySymbol : null) :
          (this.storeDetails.purchasedCurrency && this.storeDetails.purchasedCurrency.currencySymbol ? this.storeDetails.purchasedCurrency.currencySymbol : null);
        this.getPlanDetails();
      }
    }, (err) => {
      this.dialogService.dialogMethod('Error in getting user details!', this.dialogType.failure, true);
    }));
  }
  /**
   * Method used to trigger the function.
   * @param event is the event.
   * @type {void}
   */
  functionTriggered(event): void {
    if (event) {
      this[event]();
    }
  }
  /**
   * Method to get the particular client's plan details and display it to them
   * @type {void}
   */
  getPlanDetails(): void {
    this.subscriptionArray.push(this.commonService.getAllPlans({ countryCode: this.authService.environment.countryCode }).pipe(mergeMap((res: any) => {
      if (res && res.result) {
        this.planTypes = res.result;
        this.planTypes.forEach((item : plans) => {
          this.getSelectNewPlan(item);
        });
        this.arrangePlanDetails();
        return this.commonService.getAllFeatures();
      }
      else {
        this.pageDetails.isLoader = false;
        return of(null);
      }
    }), mergeMap((response: any) => {
      if (response.result) {
        this.features = response.result;
        this.replaceContent();
        return this.commonService.getPlanDetailsOfUser(this.storeId);
      }
      return of(null);
    })).subscribe((res: any) => {
      if (res && res.planDetails && res.planDetails.result.length == 1) {
        this.previouslySelectedPlan = res.planDetails.result[0];
        if( this.previouslySelectedPlan){
          this.previouslySelectedPlan.PlanDetails =this.previouslySelectedPlan.PlanDetails ?  JSON.parse(this.previouslySelectedPlan.PlanDetails):null;
        }
        this.frequency = new UntypedFormControl(this.previouslySelectedPlan.PlanDetails);       
        this.planTypes.forEach((plan: any) => {
          if (this.previouslySelectedPlan && this.previouslySelectedPlan.PlanDetails &&this.previouslySelectedPlan.PlanDetails.PlanId === plan.SK) {
            this.previouslySelectPlanHierarchy = plan.PlanHierarchy
          }
        });
        this.previouslySelectedSubscription = this.previouslySelectedPlan.PlanDetails;
      }
      else {
        this.dialogService.dialogMethod(this.dialogMessages.upgradingDisable, this.dialogType.failure, true);
      }
      this.pageDetails.isLoader = false;
    }, (err) => {
      this.dialogService.dialogMethod(this.dialogMessages.errorGettingAllPlans, this.dialogType.failure, true);
      this.pageDetails.isLoader = false;
    }));
  }
  /**
   * Method used to get selected plans
   * @param plan 
   */
  getSelectNewPlan(plan: plans) {
    const index = plan.SubscriptionPlans.findIndex((element) => {
      return element.FrequencyType === 'Monthly';
    });
    if (index >= 0)
      plan.monthlyPrice = plan && plan.SubscriptionPlans && plan.SubscriptionPlans.length && plan.SubscriptionPlans[index] && plan.SubscriptionPlans[index].Price;
    this.calculateSavePercent(plan);
  }
  /**
   * Method used to calculate yearly saving precentage
   * @param plan
   */
  calculateSavePercent(plan: plans): void {
    plan.SubscriptionPlans.forEach((element) => {
      let savePrice, yearlyPercent;
      switch (element.FrequencyType) {
        case 'Annually':
          savePrice = plan.monthlyPrice * 12 - element.Price;
          yearlyPercent = (plan.monthlyPrice * 12) / 100;
          plan.yearlySaving = savePrice / yearlyPercent;
          break;
        case 'Semi Annually':
          savePrice = plan.monthlyPrice * 6 - element.Price;
          yearlyPercent = (plan.monthlyPrice * 6) / 100;
          plan.semiAnnualSaving = savePrice / yearlyPercent;
          break;
        case 'Quarterly':
          savePrice = plan.monthlyPrice * 3 - element.Price;
          yearlyPercent = (plan.monthlyPrice * 3) / 100;
          plan.quarterlySaving = savePrice / yearlyPercent;
          break;
        default:
          break;
      }
    });
  }
  /**
   * Method to get feature list
   */
  getfeatures() {
    this.newSelectedPlan.PlanFeatures.forEach((featureId) => {
      this.features.forEach((element) => {
        if (element.SK === featureId.FeatureId)
          this.selectedFeatures.push({ name: element.FeatureName, value: featureId.FeatureEnabled || featureId.FeatureCount, type: typeof (featureId.FeatureEnabled || featureId.FeatureCount) });
      });
    });
  }
  /**
   * Method to arrange the plans in their hierarchy
   * @type {void}
   */
  arrangePlanDetails(): void {
    const sortedArray: Array<any> = this.planTypes.sort((element1, element2): number => { if (element1.PlanHierarchy > element2.PlanHierarchy) { return 1; } if (element1.PlanHierarchy < element2.PlanHierarchy) { return -1; } return 0; })
    sortedArray.forEach((element) => {
      if (element && element.SubscriptionPlans) {
        let sortedSubscription = [], monthly = [], quarterly = [], semiAnnual = [], annual = [];
        monthly = element.SubscriptionPlans.filter((subscription) => { return subscription.FrequencyType == 'Monthly' });
        quarterly = element.SubscriptionPlans.filter((subscription) => { return subscription.FrequencyType == 'Quarterly' });
        semiAnnual = element.SubscriptionPlans.filter((subscription) => { return subscription.FrequencyType == 'Semi Annually' });
        annual = element.SubscriptionPlans.filter((subscription) => { return subscription.FrequencyType == 'Annually' });
        if (monthly && monthly.length) {
          sortedSubscription = [monthly[0]];
        }
        if (quarterly && quarterly.length) {
          sortedSubscription.push(quarterly[0]);
        }
        if (semiAnnual && semiAnnual.length) {
          sortedSubscription.push(semiAnnual[0]);
        }
        if (annual && annual.length) {
          sortedSubscription.push(annual[0]);
        }
        element.SubscriptionPlans = sortedSubscription;
      }
    });
    this.planTypes = sortedArray;
  }
  /**
   * Method to navigate to the subscriptions page
   * @type {void}
   */
  navigateToMemberPage(): void {
    if (this.isSuperAdmin) {
      const userId = this.commonDataService.setParam(this.storeId);
      this.router.navigate(['/app/subscriptionhistory', userId]);
    } else {
      this.router.navigate(['/app/subscriptionhistory']);
    }
  }
  /**
   * Method to open the dialog box while clicking a particular plan and showing the subscriptions in them
   * @type {void}
   * @param plan variable to store the new selected plan details
   */
  openDialog(subscription, plan): void {
    let frequencyIndex = null;
    if (this.frequency && this.frequency.value) {
      frequencyIndex = plan.SubscriptionPlans.findIndex(
        (item) => item.SK === this.frequency.value.SK
      );
    }
    this.newSelectedPlan = plan;
    if (frequencyIndex >= 0) { 
      const selectedPlanIndex = this.planTypes.findIndex(item => item.SK === (this.newSelectedPlan && this.newSelectedPlan.SK));
      if (subscription && subscription.SK === (this.previouslySelectedPlan && this.previouslySelectedPlan.PlanDetails && this.previouslySelectedPlan.PlanDetails.SK)) {
        this.dialogService.openDialog({
          header: 'Alert!',
          message: (this.dialogMessages.currentPlan),
          actionType: this.dialogType.alert,
          button: { right: this.buttonText.ok },
          disableClose: true
        });
      }
      else if (this.previouslySelectPlanHierarchy > (this.newSelectedPlan && this.newSelectedPlan.PlanHierarchy) && selectedPlanIndex >= 0) {
        this.planTypes[selectedPlanIndex]['loading'] = true;
        this.pageDetails.planLoader = true;
        this.subscriptionArray.push(this.commonService.getCheckFeatureLimit(this.storeDetails.storeId, plan.SK).subscribe((res: any) => {
          if (res && res.isAllow) {
            this.pageDetails.planLoader = true;
            this.upcomingPlan(subscription,plan, selectedPlanIndex, this.dialogMessages.downgradeRequest);
          }
          else {
            if(res &&res.data && isPlatformBrowser(this.platformId))
            {
              const file = new Blob([res['data']], { type: "text/csv" });
              const fileURL = URL.createObjectURL(file);
              this.excelService.createXLSX(fileURL, 'Feature','.csv' );
            }
            this.dialogService.dialogMethod(
              this.dialogMessages.getCheckFeatureLimit,
              this.dialogType.alert,
              true
            );
            this.planTypes[selectedPlanIndex]['loading'] = false;
            this.pageDetails.planLoader = false;
          }
        }, (err) => {
          this.planTypes[selectedPlanIndex]['loading'] = false;
          this.pageDetails.planLoader = false;
          this.dialogService.dialogMethod(this.dialogMessages.getCheckFeatureLimit3, this.dialogType.failure, true);
        }))
      }
      else if (((subscription && subscription.FrequencyType == "Monthly") && (this.previouslySelectedPlan && this.previouslySelectedPlan.PlanDetails && this.previouslySelectedPlan.PlanDetails.FrequencyType == "Annually"))) {
        this.planTypes[selectedPlanIndex]['loading'] = true;
        this.upcomingPlan(subscription, plan, selectedPlanIndex, this.dialogMessages.upgradeRequest);
      }
      else {
        this.planTypes[selectedPlanIndex]['loading'] = true;
        this.pageDetails.planLoader = true;
        if (((subscription && subscription.FrequencyType == "Monthly") && (this.previouslySelectedPlan && this.previouslySelectedPlan.PlanDetails && this.previouslySelectedPlan.PlanDetails.FrequencyType == "Monthly"))) {
          const dialogMessage = this.dialogMessages.planUpdateConfirmation + this.planEndDate +'.';
          this.changePlan(subscription, plan, dialogMessage);
        }
        else {
          this.changePlan(subscription, plan, this.dialogMessages.planUpdateConfirmation1)
        }
      }
    }
    else {
      this.dialogService.dialogMethod(
        this.dialogMessages.selectFrequency,
        this.dialogType.alert, true
      );
    }
     
  }
  /**
 * Method to open the dialog box while clicking a particular plan and showing the subscriptions in them
 * @type {void}
 * @param plan variable to store the new selected plan details
 * @param subscription variable to store the new selected subscription details
 * @param dialogMessage variable to store dialog content for upcoming plan
 */
  upcomingPlan(subscription,plan,planIndex,dialogMessage):void {
    const data = {
      planDetails: {
        planId: subscription && subscription.PlanId,
        Sk: subscription && subscription.SK,
        PlanName: plan.PlanName,
        FrequencyType: subscription.FrequencyType
      },
      storeId: this.storeId,
    }
    this.subscriptionArray.push(this.commonService.updateUpcomingPlan(data).subscribe((res: any) => {
      if (res) {
        this.dialogService.dialogMethod(
          dialogMessage,
          this.dialogType.success,
          true
        );
        this.router.navigate(['/app/subscriptionhistory']);
        this.planTypes[planIndex]['loading'] = false;
        this.pageDetails.planLoader = false;
      }
      else {
        this.dialogService.dialogMethod(this.dialogMessages.UpcomingPlanError, this.dialogType.failure);
      }
    }, (err) => {
      this.planTypes[planIndex]['loading'] = false;
      this.pageDetails.planLoader = false;
      this.dialogService.openDialog({
        header: this.errorMessage.failureHeader,
        message: this.dialogMessages.errorUpdatingPlanRequest,
        actionType: this.dialogType.failure,
        button: { right: this.buttonText.ok },
        disableClose: true
      });
    }))
  }
   /**
   * Method used to upgrade or downgrade from one plan to another, update the details, generate invoice and send mail to the client
   * @type {void}
   * @param newSubscriptionData variable to store the new subscription data
   * @param SetUpCost 
   * @param additionalChargeLabel
   * @param isAdditionalCharge 
  */
  changePlan(newSubscriptionData, plan,dialogMessage?): void {
    const selectedPlanIndex = this.planTypes.findIndex(item => item.SK === (plan && plan.SK));
    if ((this.previouslySelectedSubscription && this.previouslySelectedSubscription.SK) !== newSubscriptionData.SK) {
          const dialogRef = this.dialogService.openDialog({
            header: 'Confirmation!',
            message: dialogMessage,
            actionType: this.dialogType.confirmation,
            button: { right: this.buttonText.yes, left: this.buttonText.no },
            disableClose: true
          });
          this.subscriptionArray.push(dialogRef.afterClosed().pipe(mergeMap((res: any) => {
            if (res) {
              const data = {
                selectedplanDetails: {
                  planId: newSubscriptionData && newSubscriptionData.PlanId,
                  Sk: newSubscriptionData && newSubscriptionData.SK,
                },
                storeId: this.storeId
              }
              return this.commonService.getPaymentData(data, true);
            }
            return of(null);
          })).subscribe((res: any) => {
            if (res && res.detail) {
              this.selectedPlanDetails = res.detail;
              this.selectedPlanDetails = { ...this.selectedPlanDetails, isupgrade: 'true' };
              this.onClose();
              this.getfeatures()
              this.showPayment = true;
            }
            this.planTypes[selectedPlanIndex]['loading'] = false;
            this.pageDetails.planLoader = false;
          }, (err) => {
            this.planTypes[selectedPlanIndex]['loading'] = false;
            this.pageDetails.planLoader = false;
            this.dialogService.openDialog({
              header: this.errorMessage.failureHeader,
              message: this.dialogMessages.errorUpdatingPlan,
              actionType: this.dialogType.failure,
              button: { right: this.buttonText.ok },
              disableClose: true
            });
          }));
    }
  }
  /**
   * 
   * Method used to upgrade or downgrade from one plan to another, update the details, generate invoice and send mail to the client
   * @param event 
   * @type {void}
   */
  savePlan(event): void {
    this.pageDetails.isLoader = true;
    this.showPayment = false;
    if (event && event.data && event.data && event.data.orderCode && event.data.paymentType == 'RAZORPAY') {
      this.dialogService.dialogMethod(this.previouslySelectedPlan ? 'Plan upgraded successfully. Please login again to continue with your New Plan' : 'Plan chosen successfully!', this.dialogType.success, true);
      this.pageDetails.isLoader = false;
      if (!this.isSuperAdmin) {
        this.router.navigate(['/signin']);
      }
    }
    else {
      this.payment(event);
    }
  }
  /**
  * 
  * Method used to upgrade or downgrade from one plan to another, update the details, generate invoice and send mail to the client for us store
  * @param event 
  * @type {void}
  */
  payment(event) {
    this.newSubscriptionData = {
      selectedplanDetails:
      {
        planId: this.selectedPlanDetails && this.selectedPlanDetails.planId,
        Sk: this.selectedPlanDetails && this.selectedPlanDetails.Sk,
        isupgrade: 'true'
      },
      data: event,
      mailContent: {
        PlanName: this.selectedPlanDetails && this.selectedPlanDetails.PlanName,
        FrequencyType: this.selectedPlanDetails && this.selectedPlanDetails.FrequencyType,
        storeName: this.storeDetails && this.storeDetails.store && this.storeDetails.store.name,
        isTestAccount: this.storeDetails && this.storeDetails.store && this.storeDetails.store.isTestAccount
      }
    };
    this.newSubscriptionData = this.commonDataService.encryptDetails(
      JSON.stringify(this.newSubscriptionData)
    );
    this.commonService.generateSubscriptionDetails({ data: this.newSubscriptionData, storeId: this.storeId }).subscribe((res: any) => {
      this.dialogService.dialogMethod(this.previouslySelectedPlan ? 'Plan upgraded successfully. Please login again to continue with your New Plan' : 'Plan chosen successfully!', this.dialogType.success, true);
      this.pageDetails.isLoader = false;
      if (!this.isSuperAdmin) {
        this.router.navigate(['/signin']);
      }
    }, (err) => {
      if (err && err.error && err.error.error && err.error.error.payment) {
        this.dialogService.dialogMethod(this.dialogMessages.paymentProcess, this.dialogType.alert, true);
        this.router.navigate(['/app/subscriptionhistory']);
      }
      else {
        this.dialogService.dialogMethod('Error in plan renewal!', this.dialogType.failure, true);
      }
      this.pageDetails.isLoader = false;
    });
  }
  /**
   * Method used to change showPayment value
   */
  onShowPayment(event) {
    this.selectedFeatures = [];
    this.showPayment = event;
  }
  /**
   * Method which is used for close all the current dialog box and reset the reactive forms
   * @type {void}
   */
  onClose(): void {
    this.dialog.closeAll();
  }

  /**
 * Method used to replace ${count} in feature description.
 */

  replaceContent() {
    for (const plan of this.planTypes) {
      for (const feature of this.features) {
        // eslint-disable-next-line no-unsafe-optional-chaining
        for (const planFeature of plan?.PlanFeatures) {
          if (feature.SK === planFeature.FeatureId) {
            const replaceText = '${count}';
            if (feature?.Description && planFeature?.FeatureCount >= 1) {
              planFeature['Description'] = feature?.Description.split(replaceText).join(planFeature?.FeatureCount);
            }
            else if (feature?.Description && planFeature?.FeatureCount === -1) {
              planFeature['Description'] = feature?.Description.split(replaceText).join('Unlimited');
            }
            else {
              planFeature['Description'] = null;
            }
          }
        }
      }
    }
  }
  /**
 * Method which is used to alert the user when he navigates to the page
 * without saving the current page data's.
 */
  canDeactivate(): boolean {
    if (this.pageDetails.planLoader)
      return this.frequency.pristine;
    else
      return true;
  }
  /**
   * Used to unsubscribe all the subscribed variables
   * @type {void}
   */
  ngOnDestroy(): void {
    if (this.subscriptionArray && this.subscriptionArray.length) {
      this.subscriptionArray.forEach((item) => item.unsubscribe());
    }
  }
}
