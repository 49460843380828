import { NgModule } from '@angular/core';
import { FooterComponent } from './components/footer/footer.component';
// import { ReviewsRatingsComponent } from './components/reviews-ratings/reviews-ratings.component';
import { TopNavBarComponent } from './components/top-nav-bar/top-nav-bar.component';
import { CustomQuestionsComponent } from './components/custom-questions/custom-questions.component';
import { WriteReviewComponent } from './components/write-review/write-review.component';
import { TitleCasePipe, UpperCasePipe } from '@angular/common';
import { OptionsDisplayComponent } from './components/options-display/options-display.component';
import { DynamicTopDirective } from './directives/dynamic-top.directive';
import { CoreModule } from 'libs/common/src/lib/common.module';
import { ScrollTopBottomComponent } from './components/scroll-top-bottom/scroll-top-bottom.component';
import { RedeemGiftCardComponent } from './components/redeem-gift-card/redeem-gift-card.component';
import { CartQuickViewComponent } from './components/cart-quick-view/cart-quick-view.component';
import { LocationTemplate2Component } from './components/location-template2/location-template2.component';
import { DisplayLiveSaleNotificationComponent } from './components/display-live-sale-notification/display-live-sale-notification.component';
import { DisplaySoldCountNotificationComponent } from './components/display-sold-count-notification/display-sold-count-notification.component';
import { DisplayOnlineVisitorsNotificationComponent } from './components/display-online-visitors-notification/display-online-visitors-notification.component';
import { SharerMethod } from 'ngx-sharebuttons';
import { ShareModule } from 'ngx-sharebuttons';
import { ScrollTopComponent } from './components/scroll-top/scroll-top.component';
import { ProductFilterComponent } from './components/product-filter/product-filter.component';
import { MultiLanguagePipe } from './pipes/multi-language.pipe';
@NgModule({
  imports: [
    // SharedModule,
    CoreModule,
    ShareModule.withConfig({
        sharerMethod: SharerMethod.Window,
        windowWidth: 800,
        windowHeight: 500,
    }),
    MultiLanguagePipe
],
  declarations: [
    // ReviewsRatingsComponent,
    TopNavBarComponent,
    FooterComponent,
    CustomQuestionsComponent,
    WriteReviewComponent,
    OptionsDisplayComponent,
    DynamicTopDirective,
    ScrollTopBottomComponent,
    RedeemGiftCardComponent,
    CartQuickViewComponent,
    LocationTemplate2Component,
    ScrollTopComponent,
    DisplayLiveSaleNotificationComponent,
    DisplaySoldCountNotificationComponent,
    DisplayOnlineVisitorsNotificationComponent,
    ProductFilterComponent
  ],
  exports: [
    CoreModule,
    // ReviewsRatingsComponent,
    CustomQuestionsComponent,
    TopNavBarComponent,
    FooterComponent,
    OptionsDisplayComponent,
    DynamicTopDirective,
    CartQuickViewComponent,
    ScrollTopComponent,
    DisplayLiveSaleNotificationComponent,
    DisplaySoldCountNotificationComponent,
    DisplayOnlineVisitorsNotificationComponent,
    ProductFilterComponent,
    MultiLanguagePipe
  ],
  providers: [TitleCasePipe, UpperCasePipe],
})
export class SharedThemeModule { }
