<div fxLayout="row" fxLayoutGap="5px" fxLayout.lt-sm="column" fxLayoutGap.lt-sm="5px" class="header-div">
    <!-- Title Side Heading -->
    <div [fxFlex]="custromTitleSize" fxLayout="row" fxLayoutAlign="start center" fxLayout.lt-sm="column"
        fxLayoutAlign.lt-sm="center center">
        <div *ngIf="heading" fxLayout="column" class="heading-content-div">
            <span fxLayout="row" fxLayoutAlign=" center" fxLayoutAlign.lt-sm="center center">
                <h2 checkElipsis [elementName]="'title'" (titleElipsisEnabled)="isTitleEnable($event)"
                    class="header-card-title head-title-wrap" [matTooltip]="heading?.title"
                    [matTooltipDisabled]="!titleElipsis" [ngStyle.lt-sm]="{'text-align':'center'}">
                    {{heading?.title}}</h2>
                <mat-icon *ngIf="heading?.isHeaderIcon" class="headerIcon" data-control="header-link"
                    (click)="headerIconNaviagate(heading)"
                    [matTooltip]="heading?.iconToolTip?heading?.iconToolTip:'Help'">
                    <span>{{heading?.iconName?heading.iconName:'link'}}</span>
                </mat-icon>
            </span>
            <h3 *ngIf="heading?.description" checkElipsis [elementName]="'description'"
                (descElipsisEnabled)="isDescriptionEnable($event)" class="header-card-subtitle head-subtitle-wrap"
                [matTooltip]="heading?.description" [matTooltipDisabled]="!descriptionElipsis"
                [ngStyle.lt-sm]="{'text-align':'center'}">
                {{heading?.description}}
            </h3>
        </div>
    </div>
    <!-- Center Content -->
    <div *ngIf="content" fxFlex="35%" fxFlex.lt-md="60%">
        <div fxLayoutAlign="center center" fxLayoutAlign.lt-md="center center" class="center-content-inner-div">
            <div
                [ngClass]="{'warning':content.type==='warning','failure':content.type==='failure','success':content.type==='success'}">
                <h3>{{content?.data}}</h3>
            </div>
        </div>
    </div>
    <!-- Right side menu -->
    <div *ngIf="buttonInfo && buttonInfo.length && !heading?.isSearch" fxFlex>
        <!-- Menu Display with button only on large and small screen -->
        <div fxShow.gt-md fxShow.lt-sm fxHide.lt-md fxLayout="row wrap" fxLayoutAlign="end center" fxLayoutGap="5px"
            fxLayout.lt-sm="row wrap" fxLayoutAlign.lt-sm="center center" fxLayoutGap.lt-sm="5px"
            ngStyle.lt-sm="padding-bottom:10px" class="buttons-inner-div">
            <ng-container *ngFor="let item of buttonInfo">
                <!-- Display Normal Button -->
                <div *ngIf="(!item?.hasOwnProperty('condition') || item?.condition) && (item?.type!='menu')">
                    <button mat-stroked-button [attr.data-control]="item?.name | dataControl"
                        class="{{item?.class}} normal-button-style" [disabled]="item?.disabled || item?.buttonLoader"
                        (click)="buttonClicked(item)">
                        <div class="normal-button-info">
                            <span class="material-icons-outlined pointer-event" class="{{item?.iconClass}}"
                                *ngIf=" item?.icon && !item?.buttonLoader"
                                [attr.data-control]="item?.icon | dataControl"
                                [matTooltip]="item?.iconToolTip?item?.iconToolTip:null">
                                {{item?.icon}}</span>
                            <span *ngIf="!item?.buttonLoader">{{item?.name}}</span>
                        </div>
                        <div>
                            <cen-loader *ngIf="item?.buttonLoader" [type]="'content'" [name]="'cen-dot-spinner'">
                            </cen-loader>
                        </div>
                    </button>
                </div>
                <!-- Display menu type button -->
                <div *ngIf="(!item?.hasOwnProperty('condition') || item?.condition) && (item?.type==='menu')">
                    <button mat-stroked-button [attr.data-control]="item?.name"
                        class="{{item?.class}} menu-type-button-style" [disabled]="item?.disabled"
                        [matMenuTriggerFor]="menuButton" fxLayout="row">
                        <div fxLayout="row" fxLayoutAlign="center center">
                            <span class="material-icons-outlined" class="{{item?.iconClass}}" *ngIf=" item?.icon"
                                [attr.data-control]="item?.icon">
                                {{item?.icon}}</span>
                            <span fxLayout="row" fxLayoutAlign="center center">
                                {{item?.name}}
                                <mat-icon class="menu-button-arrow-icon {{item?.class}}">keyboard_arrow_down</mat-icon>
                            </span>
                        </div>

                    </button>
                    <mat-menu #menuButton="matMenu" xPosition="before">
                        <div *ngFor="let menuOption of item?.options">
                            <button mat-menu-item fxLayout="row" fxLayoutAlign=" center"
                                (click)="buttonClicked(menuOption)" [disabled]="menuOption?.disabled"
                                [ngClass]="menuOption?.menuClass?menuOption.menuClass:(menuOption?.disabled?'default-disabled-menuIcon':'default-menuIcon')">
                                <div class="menu-button-info">
                                    <span class="material-icons-outlined" *ngIf="menuOption?.icon"
                                        [attr.data-control]="menuOption?.icon">
                                        {{menuOption?.icon}}
                                    </span>
                                    <span *ngIf="menuOption?.svgIcon" class="svg-icon">
                                        <img [ngClass]="menuOption?.svgClass?menuOption.svgClass:'default-svg-class'"
                                            [src]="'assets/'+menuOption?.svgIcon" [alt]="menuOption?.svgIcon">
                                    </span>
                                    <span class="button-name">
                                        {{menuOption?.name}}
                                    </span>
                                </div>
                            </button>
                        </div>
                    </mat-menu>
                </div>
            </ng-container>
        </div>
        <!--Menu Display with icon only on medium  screen -->
        <div fxHide fxHide.lt-sm fxShow.lt-md fxLayoutAlign="end center">
            <div [matMenuTriggerFor]="menu" class="more-menu-button" data-control="actionMenu">
                <mat-icon class="header-more-menu-icon">more_vert</mat-icon>
            </div>

            <mat-menu #menu="matMenu" class="hide-header-page-menu-List">
                <div *ngFor="let item of buttonInfo" fxHide fxHide.lt-sm fxShow.lt-md>
                    <!-- Display Normal Button -->
                    <div *ngIf="(!item?.hasOwnProperty('condition') || item?.condition) && (item?.type!='menu')">
                        <button mat-menu-item [disabled]="item?.disabled || item?.buttonLoader"
                            (click)="buttonClicked(item)" fxLayout="row" fxLayoutAlign=" center" fxLayoutGap="3px"
                            [attr.data-control]="item?.name">
                            <div class="more-menu-buttons">
                                <span class="material-icons-outlined pointer-event"
                                    [ngClass]="item.iconClass?item.iconClass:'defaultIconClass'"
                                    [matTooltip]="item?.iconToolTip?item?.iconToolTip:null"
                                    *ngIf="item?.icon && !item?.buttonLoader"
                                    [attr.data-control]="item?.icon | dataControl">
                                    {{item?.icon}}
                                </span>
                                <span *ngIf="!item?.buttonLoader">{{item?.name}}</span>
                                <div>
                                    <cen-loader *ngIf="item?.buttonLoader" [type]="'content'"
                                        [name]="'cen-dot-spinner'">
                                    </cen-loader>
                                </div>
                            </div>
                        </button>
                    </div>
                    <!-- Display menu type button -->
                    <div *ngIf="(!item?.hasOwnProperty('condition') || item?.condition) && (item?.type==='menu')">
                        <button mat-menu-item [disabled]="item?.disabled" fxLayout="row" fxLayoutAlign=" center"
                            fxLayoutGap="3px" [attr.data-control]="item?.name" [matMenuTriggerFor]="menuButton">
                            <div class="more-menu-buttons">
                                <span class="material-icons-outlined" class="{{item?.iconClass}}" *ngIf=" item?.icon"
                                    [attr.data-control]="item?.icon">
                                    {{item?.icon}}</span>
                                {{item?.name}}
                            </div>
                        </button>
                        <mat-menu #menuButton="matMenu">
                            <div *ngFor="let menuOption of item?.options">
                                <button mat-menu-item fxLayout="row" fxLayoutAlign=" center"
                                    [disabled]="menuOption?.disabled" (click)="buttonClicked(menuOption)"
                                    [ngClass]="menuOption?.menuClass?menuOption.menuClass:(menuOption?.disabled?'default-disabled-menuIcon':'default-menuIcon')">
                                    <div class="more-menu-button-info">
                                        <span class="material-icons-outlined" *ngIf=" menuOption?.icon"
                                            [attr.data-control]="menuOption?.icon">
                                            {{menuOption?.icon}}
                                        </span>
                                        <span *ngIf="menuOption?.svgIcon" class="svg-icon">
                                            <img [ngClass]="menuOption?.svgClass?menuOption.svgClass:'default-svg-class'"
                                                [src]="'assets/'+menuOption?.svgIcon" [alt]="menuOption?.svgIcon">
                                        </span>
                                        <span class="button-name">
                                            {{menuOption?.name}}
                                        </span>
                                    </div>
                                </button>
                            </div>
                        </mat-menu>
                    </div>
                </div>
            </mat-menu>

        </div>
    </div>
    <!-- Right side menu with search text -->
    <div *ngIf="heading?.isSearch" fxFlex fxLayoutAlign.lt-md="center center">
        <mat-form-field>
            <mat-label>Search</mat-label>
            <input [formControl]="commonService?.searchField" type="text" data-control="search"
                [placeholder]="heading?.searchPlaceHolder" matInput trimVal>
            <mat-icon style="color: #7b7b7b;" matSuffix>search</mat-icon>
        </mat-form-field>
    </div>
    <!-- Right side menu with button toggle -->
    <div *ngIf="buttonToggleInfo && buttonToggleInfo?.length && !buttonInfo && !heading?.isSearch"
        fxLayoutAlign="center center" fxFlex>
        <mat-button-toggle-group data-control="header-button-toggle" class="header-button-toggle">
            <mat-button-toggle *ngFor="let info of buttonToggleInfo" class="{{info?.class}}"
                [attr.data-control]="info?.name" (click)="buttonClicked(info)"
                [ngStyle]="{'pointer-events': info?.active ? 'none' :'auto' }">
                {{info?.name}}
            </mat-button-toggle>
        </mat-button-toggle-group>
    </div>
</div>