<ng-container *ngIf="data?.isEditor">
    <div class="border-bottom" style="height: 66px;" mat-dialog-title fxLayout="row"
        fxLayoutAlign="space-between center">
        <!-- <span>{{translator?.preview??"Preview"}}</span> -->
        <span>Preview</span>
        <div style="display: flex;" fxLayoutGap="10px">
            <ng-container *ngFor="let item of previewOptions | keyvalue : returnZero">
                <mat-icon *ngIf="item?.value?.condition" [matTooltip]="item?.value?.tooltip"
                    [ngClass]="{'selected-preview-icon':previewOptions?.selected === item?.key}"
                    class="cursor-pointer preview-icon" [attr.data-control]="item?.key+' Preview Icon' | dataControl"
                    (click)="previewOptions.selected = item?.key">
                    {{item?.value?.icon}}
                </mat-icon>
            </ng-container>
        </div>
        <mat-icon matTooltip="Close" class="cursor-pointer" style="float: right;" data-control="dialogCloseBtn"
        (click)="!data?.isButtonLoader && onClose()">
            <ng-template matTooltip>close</ng-template>
            close </mat-icon>
    </div>

    <mat-dialog-content class="border-bottom transition-effect"
        style="padding: 20px !important; max-height: none;position: relative;"
        [style]="{ 'width': previewOptions[previewOptions?.selected]?.dialogWidth ?? '90vw' }">
        <div id="iframeWrapper"
            [style]="{'width':previewOptions[previewOptions?.selected]?.width,'height': 'calc(100vh - 220px)','max-height': iframeHeight}"
            class="transition-effect iframe-wrapper border-design">
            <iframe id="iframeElement" #iframe class="transition-effect iframe-element" (load)="displaycontent(iframe)"
                title="Template Preview"
                [style]="{'width': previewOptions[previewOptions?.selected]?.iframeWidth ?? previewOptions[previewOptions?.selected]?.width,'max-height': iframeHeight }"></iframe>
        </div>
        <div *ngIf="iframeLoader" class="iframe-loader"
            [style]="{'width': 'calc('+previewOptions[previewOptions?.selected]?.dialogWidth+' - 40px)'}">
            <cen-loader style="width: 10px;" [type]="'content'" [name]="'cen-dot-spinner'">
            </cen-loader>
        </div>
    </mat-dialog-content>

    <mat-dialog-actions style="height: 102px;">
        <div fxLayout="row" style="width: 100%;"
            fxLayoutAlign="end center">
            <!-- [fxLayoutAlign]="data?.isSuperAdmin ? 'space-between center' :'end center'"
            <div style="font-size: 12px;margin-left: 0.5%;" *ngIf="data?.isSuperAdmin">
                <mat-form-field>
                    <mat-label>{{rightTopFilter?.label ? rightTopFilter?.label : 'Select Option'}}</mat-label>
                    <mat-select (selectionChange)="rightFilterOptionChanges($event)" [disableOptionCentering]="true"
                        (openedChange)="rightTopFilterSearchFieldChange($event)" [value]="rightTopFilter?.defaultOption"
                        data-control="rightTopFilter">
                        <div fxLayout="row" class="search"
                            style="border-bottom: 1px solid #64646466;align-items: center;">
                            <input matInput fxFlex="90%" fxFlex.lt-md="92%" fxFlex.lt-sm="85%"
                                style="padding-left: 5px;" data-control="searchTopFilterSearchField"
                                placeholder="Search" [formControl]="rightTopFilterSearchField"
                                (keyup)="rightTopFilterSearch($event?.target?.value)"
                                (keydown.space)="$event.stopPropagation();">
                            <mat-icon class="search-close-icon"
                                style="margin-right: 10px;cursor:pointer; font-size: 20px"
                                *ngIf="rightTopFilterSearchField?.value" fxFlex="10%" fxFlex.lt-md="8%"
                                fxFlex.lt-sm="15%" (click)="rightTopFilterClose()"
                                data-control="closeIconSearchTopFilterSearchField" matTooltip="Close">
                                close
                            </mat-icon>
                        </div>
                        <mat-option *ngIf="rightTopFilterFilteredData?.length == 0" disabled="true">No Search Data Found
                        </mat-option>
                        <mat-option *ngFor="let value of rightTopFilterFilteredData;let id = index"
                            [value]="value[rightTopFilter?.matValueField]"
                            [attr.data-control]="'option - ' + id | dataControl">
                            {{value[rightTopFilter?.matLabelField]}}
                        </mat-option>
                    </mat-select>

                </mat-form-field>
            </div> -->
            <div fxLayout="row" fxLayoutAlign="space-evenly center">
                <div style="font-size: 12px;margin-top: 10px;">
                    <mat-form-field class="form-field" appearance="outline">
                        <!-- <mat-label>{{translator?.templateName ?? "Template Name"}}</mat-label> -->
                        <mat-label>Template name</mat-label>
                        <input style="font-size: 14px;" data-control="templateName" matInput
                            [formControl]="templateName" trimVal />
                        <mat-error data-control="templateNameRequired" style="font-size: 13px;"
                            *ngIf="templateName?.errors || templateName?.touched">
                            <ng-container *ngFor=" let data of templateName?.errors | keyvalue;let index = index">
                                <span class="error-message" *ngIf="index === 0">
                                    {{(constants?.templateErrorMessage?.ERROR)['template'][data.key] }}
                                    <!-- {{translator?.templateNameErr??"Template name is required"}} -->
                                </span>
                            </ng-container>
                        </mat-error>
                    </mat-form-field>
                </div>
                <button class="primary-button create-button" type="submit" data-control="updateCreateBtn"
                    mat-raised-button (click)="functionTrigger()" [disabled]="!templateName?.valid"
                    [ngClass]="{'disabled-button':!templateName?.valid}">
                    <div style="padding: 2px" *ngIf="!data?.isButtonLoader"> {{data?.buttonLabel}} </div>
                    <cen-loader style="width: 10px;" *ngIf="data?.isButtonLoader" [type]="'content'"
                        [name]="'cen-dot-spinner'">
                    </cen-loader>
                </button>
            </div>
        </div>
    </mat-dialog-actions>
</ng-container>

<div *ngIf="!data.isEditor" style="width: 100%;position: relative;">
    <div id="iframeWrapper"
        [style]="{'width':data?.width === '100%' ? 'calc('+data?.width+' - 16px)' : data?.width,'height': data?.height ?? iframeHeight}"
        class="iframe-wrapper" [ngClass]="{'border-design': data?.border}">
        <iframe id="iframeElement" #iframe class="iframe-element" (load)="displaycontent(iframe)"
            title="Template Preview"
            [style]="{'width': data?.iframeWidth ?? data?.width,'height': data?.height ?? iframeHeight }"></iframe>
    </div>
    <div *ngIf="iframeLoader" class="iframe-loader" style="width: 100%;">
        <cen-loader style="width: 10px;" [type]="'content'" [name]="'cen-dot-spinner'">
        </cen-loader>
    </div>
</div>