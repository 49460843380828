/** Component which is used to get payx-get-free report
 *  AUTHOR: Manimala S (CEN 143)
 */
import { DatePipe } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import * as  _ from 'lodash';
import { of, Subscription } from 'rxjs';
import { filter, mergeMap } from 'rxjs/operators';
import { ReportsService } from '../../services/reports.service';
import { ColumnsRef, CommonAdminShardConstants, ListProperties } from '../../constants/shared-constant';
// import { DialogService } from '@phase-ii/common';
import { ExportComponent } from '../export/export.component';
import { ExportObject } from '../../models/export-details';
import { HeadingObject, totalProductsCountObject, payxGetFreePaginatorObject, payxGetFreeListDataSourceObject, getDiscountReportObject, CurrentUserData, discountObject, getStoreObject, dateObject } from '../../models/common-admin-model';
import { AuthService } from 'libs/auth/src/lib/services/auth.service';
import { PaginationService } from 'libs/shared/src/lib/services/pagination.service';
import { CommonService } from 'libs/shared/src/lib/services/common.service';
import { DialogService } from 'libs/common/src/lib/services/dialog.service';
import { ButtonInfo } from 'libs/shared/src/lib/models/header-page';
import * as moment from 'moment';
// import { AuthService } from '@phase-ii/auth';
// import { ButtonInfo, CommonService, PaginationService } from '@phase-ii/shared';

@Component({
  selector: 'phase-ii-payx-get-free-report',
  templateUrl: './payx-get-free-report.component.html',
  styleUrls: ['./payx-get-free-report.component.css']
})
export class PayxGetFreeReportComponent implements OnInit, OnDestroy {
  /**
   * Variable which is used to subscribe and unsubscribe.
   * @type {Subscritpion}
   */
  subscriptionObj: Subscription = new Subscription();
  /**
   * Variable which is used to store the to date of the report.
   * @type {Date}
   */
  toDate!: Date;
  /**
   * Variable which is used to store the from date of the report.
   * @type {Date}
   */
  fromDate!: Date;
  /**
   * Variable which is used to define the pagesize
   * @type {number}
   */
  pageSize: number = new CommonAdminShardConstants().paginator.defaultPageSize;
  /**
   * Variable which is used to store the text to be searched.
   * @type {String}
   */
  searchText!: string;
  /**
   * Variable which is used to store the selected location.
   * @type {any}
   */
  selectLocationId: any;
  /**
   * Variable which is used to store the sort object, which has the field and direction to be sorted.
   * @type {boolean}
   */
  sortData: boolean;
  /**
   * Variable which is used to store the discount response.
   * @type {totalProductsCountObject}
   */
  totalProductsCount!: totalProductsCountObject;
  /**
   * Variable which is used to export paginator data 
   * @type {object}
   */
  paginatorValue = { limitPagination: null, offsetPagination: null };

  /**
   * Variable which is used to store the created date of the store.
   * @type {Date}
   */
  createdDate!: Date;

  /**
   * Variable which is used to display an object of datas 
   */
  pageDetails: {
    heading: HeadingObject,
    buttonInfo: ButtonInfo[],
    listProperty: ListProperties,
    columnRef: ColumnsRef[],
    isLoader: boolean,
    listDataSource: payxGetFreeListDataSourceObject[],
    showFilterAndSort: boolean,
    checker: boolean,
    errorHandler: boolean,
    currencySymbol: string,
    paginator: payxGetFreePaginatorObject,
    storeId: number,
    storeIdValue: any,
    roleId: number,
    offer: boolean
  } = {
      heading: null,
      buttonInfo: null,
      listProperty: null,
      columnRef: null,
      isLoader: null,
      listDataSource: null,
      showFilterAndSort: false,
      checker: null,
      errorHandler: false,
      currencySymbol: null,
      paginator: null,
      storeId: null,
      storeIdValue: null,
      roleId: null,
      offer: true
    };
  /**
   * Variable which is used to store total offer amount.
   * @type {number}
   */
  totalAmount: number = 0;
  /**
   * Variable which is used to store offer response.
   * @type {discountObject}
   */
  offerDetails: discountObject;
  /**
   * Variable which is used to define whether the data is exported.
   * @type {boolean}
   */
  isExport: boolean = false;
  /**
   * Variable which is used to store current user details.
   * @type {object}
   */
  currentUser: CurrentUserData;
  /**
   * Variable which is used to store current store list.
   * @type {array}
   */
  currentStoreList: [{
    name: string,
    id: any
  }];
  /**
   * Variable which is used to store available store list.
   * @type {array}
   */
  storeList: [{
    name: string,
    id: any
  }];
  /**
   * Variable which is used to define whether the data is retrieved or not.
   * @type {boolean}
   */
  isRetrieve: boolean = false;
  /**
   * Variable which is used to store limit value of paginator for export.
   * @type {number}
   */
  paginatorLimit!: number;
  /**
   * Variable which is used to store offset value of paginator for export.
   * @type {number}
   */
  paginatorOffset: number;
  /**
   * Variable which is used to store current role details.
   * @type {String}
   */
  currentRole!: String;
  /** Variable which is used to  acccess the common constant values.
   */
  commonConstant = new CommonAdminShardConstants();
  /**
   * Variable which is used to store current offset value.
   * @type {number}
   */
  offsetValue: number;
  /**
   * Variable which is used to store current limit value.
   * @type {number}
   */
  limitValue: number;
  /**
   * component constructor which is used to inject the required services.
   * @param reportService  To access methods inside ReportService.
   * @param authService  To access methods inside AuthService.
   * @param displayDialog  To access methods inside MatDialog.
   * @param paginatorService  To access methods inside PaginationService.
   * @param commonService  To access methods inside CommonService.
   * @param dialogService  To access methods inside DialogService.
   * @param datePipe  To access methods inside DatePipe.
   */
  constructor(private reportService: ReportsService,
    private authService: AuthService,
    private displayDialog: MatDialog,
    private paginatorService: PaginationService,
    private commonService: CommonService,
    private dialogService: DialogService,
    private datePipe: DatePipe) { }
  /**
   * Angular life cycle hook that initiates the component
   */
  ngOnInit(): void {
    this.selectLocationId = this.commonConstant?.reportData?.allLocation;
    this.subscriptionObj.add(this.authService.user.pipe(filter((res: CurrentUserData) => {
      if (res) {
        this.pageDetails.isLoader = false;
        this.currentUser = res;
        this.pageDetails.storeIdValue = this.currentUser && this.currentUser.storeId;
        this.pageDetails.currencySymbol = this.currentUser && this.currentUser.store && this.currentUser.store.currencyData && this.currentUser.store.currencyData.currencySymbol;
        this.currentRole = this.currentUser && this.currentUser.roleCode ? this.currentUser.roleCode : this.currentUser.role.code;
        this.pageDetails.roleId = this.currentUser && this.currentUser.roleId;
      }
      return true;
    }), mergeMap(() => {
      if (this.currentRole && this.currentRole === this.commonConstant?.reportData?.superadmin) {
        let sdf = this.commonService.getAllStoresName()
        return this.commonService.getAllStoresName();
      } else {
        this.pageDetails.storeId = this.currentUser?.storeId ? this.currentUser.storeId : null;
        return of({});
      }
    }), mergeMap((store: getStoreObject) => {
      if (store && store['stores']) {
        this.currentStoreList.push({ name: this.commonConstant?.reportData?.allLocation, id: this.commonConstant?.reportData?.allLocation });
        store['stores'].forEach((element: getStoreObject) => {
          if (element) {
            this.currentStoreList.push({
              id: element?.id,
              name: element?.name
            });
          }
        });
        this.pageDetails.storeIdValue = this.currentStoreList[0]?.id;
        this.storeList = this.currentStoreList;
      }
      return this.reportService.getStoreData(this.pageDetails.storeId);;
    }), mergeMap((data: any) => {
      if (data) {
        this.createdDate = data.settings && data.settings.createdAt;
      }
      return this.authService.dashboardFilter;
    })).subscribe({
      next: (value: dateObject) => {
        if (value) {
          if (value.filterText == 'Lifetime') {
            this.fromDate = this.createdDate;
          }
          else {
            this.fromDate = value.fromDate ? value.fromDate : new Date();
            this.fromDate.setHours(0, 0, 0, 0);
          }
        }
        else {
          this.fromDate = new Date();
          this.fromDate.setHours(0, 0, 0, 0);
        }
        this.toDate = new Date();
        this.toDate.setHours(23, 59, 59, 999);
        this.pageDetails.storeIdValue = this.currentUser && this.currentUser.storeId ? this.currentUser.storeId : null;
        this.getDiscountReport(0, 2 * this.pageSize, true);
      },
      error: (err) => {
        if (err) {
          this.pageDetails.isLoader = false;
          this.dialogService.dialogMethod(this.commonConstant &&
            this.commonConstant.dialogMessages
            && this.commonConstant.dialogMessages.getFailed,
            this.commonConstant.dialogType.failure);
        }
      }
    }));
  }

  /**
   * Function which is used to get the report details for table.
   * @param offset defines the offset/starting value.
   * @param limit defines the limit/count of data to be get.
   * @param isNew defines whether the page is newly loaded or specific page.
   */
  getDiscountReport(offset: number, limit: number, isNew: boolean): void {
    this.paginatorOffset = offset;
    this.paginatorLimit = this.pageSize;
    const startDate: Date = this.fromDate ? _.cloneDeep(this.fromDate) : null;
    const toDate: Date = this.toDate ? this.toDate : null;
    let reportData = {
      offset: offset ? offset : 0,
      limit: limit ? limit : this.pageSize,
      location: this.selectLocationId ? this.selectLocationId : null,
      store: this.pageDetails.storeIdValue ? this.pageDetails.storeIdValue : null,
      from_date: moment.utc(this.datePipe.transform(startDate, 'yyyy-MM-dd')).utcOffset('+0:00').format('YYYY-MM-DD 00:00:00.000 +00:00'),
      to_date: moment.utc(this.datePipe.transform(toDate, 'yyyy-MM-dd')).utcOffset('+0:00').format('YYYY-MM-DD 23:59:59.000 +00:00'),
      filterText: this.searchText ? this.searchText.trim() : '',
      sort: this.sortData ? this.sortData : null,
      payXGetFree: true
    };
    if (this.pageDetails.storeIdValue === this.commonConstant?.reportData?.allLocation) {
      this.subscriptionObj.add(this.reportService.getAllOfferReport(reportData).subscribe({
        next: (res: getDiscountReportObject) => {
          if (res && res['Discount']) {
            this.pageDetails.isLoader = false;
            this.offerDetails = res['Discount'];
            this.offerDetails['payXGetFreeDiscount']['totalProduct'] = 0;
            if (this.offerDetails['payXGetFreeDiscount'] && this.offerDetails['payXGetFreeDiscount'].length) {
              this.offerDetails['payXGetFreeDiscount'].forEach((element: payxGetFreeListDataSourceObject) => {
                if (element) {
                  this.offerDetails['payXGetFreeDiscount']['totalProduct'] += element.totalProductCount ? Number(element.totalProductCount) : 0;
                }
              });
            }
            this.tabDataRetrieve(isNew);
            this.setPageData();
          }
        },
        error: (err) => {
          if (err) {
            this.pageDetails.isLoader = false;
            this.dialogService.dialogMethod(this.commonConstant &&
              this.commonConstant.dialogMessages
              && this.commonConstant.dialogMessages.getFailed,
              this.commonConstant.dialogType.failure);
          }
        }
      }));
    } else if (this.pageDetails.storeIdValue !== null) {
      this.subscriptionObj.add(this.reportService.getOfferReport(this.pageDetails.storeIdValue, reportData).subscribe({
        next: (res: getDiscountReportObject) => {
          if (res && res['Discount']) {
            this.pageDetails.isLoader = false;
            this.offerDetails = res['Discount'];
            this.offerDetails['payXGetFreeDiscount']['totalProduct'] = 0;
            if (this.offerDetails['payXGetFreeDiscount'] && this.offerDetails['payXGetFreeDiscount'].length) {
              this.offerDetails['payXGetFreeDiscount'].forEach((element: payxGetFreeListDataSourceObject) => {
                if (element) {
                  this.offerDetails['payXGetFreeDiscount']['totalProduct'] += element.totalProductCount ? Number(element.totalProductCount) : 0;
                }
              });
            }
            this.offsetValue = offset;
            this.limitValue = limit;
            this.pageDetails.listDataSource = this.offerDetails && this.offerDetails['payXGetFreeDiscount'] ? this.offerDetails['payXGetFreeDiscount'] : [];
            this.tabDataRetrieve(isNew);
            this.setPageData();
          }
        },
        error: (err) => {
          if (err) {
            this.pageDetails.isLoader = false;
            this.dialogService.dialogMethod(this.commonConstant &&
              this.commonConstant.dialogMessages
              && this.commonConstant.dialogMessages.getFailed,
              this.commonConstant.dialogType.failure);
          }
        }
      }));
    } else {
      this.dialogService.dialogMethod(this.commonConstant &&
        this.commonConstant.errorMessage
        && this.commonConstant.errorMessage.errorMessage,
        this.commonConstant.dialogType.failure);
    }
  }

  /**
   * Method which is used to set page data.
   */
  setPageData() {
    this.pageDetails.heading = {
      title: this.commonConstant?.offerReport?.payXgetFree,
      description: this.commonConstant?.offerReport?.payXgetFreeDescription,
      // isHeaderIcon: true,
      // iconName: 'link',
      // iconToolTip: 'Help',
      // iconLink: 'https://help.getzenbasket.com/offer_report.html'
    };
    this.pageDetails.buttonInfo =
      [
        {
          name: 'Back', method: 'back()', class: 'secondary-button'
        },
        {
          name: 'Export Report', class: 'primary-button', method: 'exportexcel()'
        }
      ];
    this.pageDetails.listProperty = {
      globalSearch: false,
      columns: 2,
      dataOnly: true,
      suffixText: true,
      checkBox: false,
      isImageDisplay: false
    };
    this.pageDetails.checker = false;
    this.pageDetails.isLoader = false;
  }

  /**
   * Function which is used to retrieve the data related to selected tab.
   * @param value To get the value for paginator.
   */
  tabDataRetrieve(value: boolean): void {
    if (!this.isExport) {
      this.pageDetails.listDataSource = this.offerDetails && this.offerDetails['payXGetFreeDiscount'] ? this.offerDetails['payXGetFreeDiscount'] : [];
      this.pageDetails.paginator = {
        new: value,
        rows: this.pageDetails.listDataSource,
        count: this.pageDetails && this.pageDetails.listDataSource && this.pageDetails.listDataSource.length,
      }
      this.pageDetails.columnRef = [
        { column: 'title', type: 'Text', suffixText: 'Title' },
        { column: 'count', type: 'Number', suffixText: 'No. of orders' },
        { column: 'totalProductCount', type: 'Number', suffixText: 'Total Products' }
      ];
      this.pageDetails.listDataSource = this.offerDetails && this.offerDetails['payXGetFreeDiscount'].slice(this.offsetValue, this.offsetValue + this.limitValue);
      this.pageDetails.paginator.rows = this.pageDetails.listDataSource;
      this.totalProductsCount = {
        totalProduct: this.offerDetails && this.offerDetails['payXGetFreeDiscount'] && this.offerDetails['payXGetFreeDiscount'].totalProduct
      };
      this.isRetrieve = true;
      this.paginatorService.listPaginatorData.next(this.pageDetails.paginator);
    }
    else {
      this.pageDetails.paginator = {
        new: value,
        rows: this.pageDetails.listDataSource,
        count: this.pageDetails && this.pageDetails.listDataSource && this.pageDetails.listDataSource.length,
      }
      this.isRetrieve = true;
      this.paginatorService.listPaginatorData.next(this.pageDetails.paginator);
      this.pageDetails.listDataSource = this.offerDetails && this.offerDetails['payXGetFreeDiscount'] ? this.offerDetails['payXGetFreeDiscount'].slice(this.offsetValue, this.offsetValue + this.limitValue) : [];
    }
  }

  /**
   * Method which is used to get changed page data.
   * @param event defines the offset and limit.
   */
  onDataChange(event: { offset: number, limit: number }): void {
    if (event) {
      this.getDiscountReport(event.offset, event.limit, false);
    }
  }

  /**
   * Method which is used to get changed page data.
   * @param event defines the  offset and limit.
   */
  changedData(event: { limit: number, offset: number }): void {
    if (event) {
      this.paginatorValue.limitPagination = event.limit ? event.limit : null;
      this.paginatorValue.offsetPagination = event.offset ? event.offset : 0;
      this.getDiscountReport(event.offset, event.limit, false);
    }

  }

  /**
   * Method which is used to get paginator data.
   * @param event defines offset and limit.
   */
  paginatorData(event: { pageSize: number, pageIndex: number }): void {
    if (event) {
      this.paginatorLimit = event.pageSize;
      this.paginatorOffset = event.pageIndex * event.pageSize;
      this.paginatorValue.limitPagination = event.pageSize;
      this.paginatorValue.offsetPagination = event.pageIndex * event.pageSize;
      this.pageSize = event.pageSize;
    }
  }

  /**
   * Function which gets called during location, searchText, from date, to date and sort change events in common report.
   * @param event defines the event value.
   */
  filterAction(event: { location_id: number, store_id: number, from_date: Date, to_date: Date, searchText: string, limit: number, offset: number, sort: boolean, exportexcel: boolean }): void {
    if (event) {
      this.selectLocationId = event?.location_id ? event.location_id : null;
      this.pageDetails.storeIdValue = event?.store_id ? event.store_id : null;
      this.fromDate = event.from_date ? event.from_date : this.fromDate;
      this.toDate = event.to_date ? event.to_date : this.toDate;
      this.searchText = event?.searchText ? event.searchText : '';
      this.paginatorValue.limitPagination = event?.limit ? event.limit : null;
      this.paginatorValue.offsetPagination = event?.offset ? event.offset : 0;
      this.sortData = event?.sort;
      if (event?.exportexcel) {
        this.exportOffer();
      } else {
        this.getDiscountReport(event?.offset, event?.limit, true);
      }
    }
  }

  /**
   * Function which gets called during export is true in common report.
   */
  exportOffer(): void {
    let filterConstrainData = {
      from_date: this.fromDate ? this.datePipe.transform(this.fromDate, 'yyyy-MM-dd') : null,
      to_date: this.toDate ? this.datePipe.transform(this.toDate, 'yyyy-MM-dd') : null,
      locationId: this.selectLocationId ? this.selectLocationId : null,
    }
    if (this.offerDetails && this.offerDetails['payXGetFreeDiscount'] && this.offerDetails['payXGetFreeDiscount'].length > 0) {
      const exportData: ExportObject = {
        title: 'Export ',
        exportApiUrl: 'stores/' + this.pageDetails.storeIdValue + '/export/report/payxgetfreeoffer',
        limit: this.paginatorLimit ? this.paginatorLimit : (this.paginatorValue.limitPagination ? this.paginatorValue.limitPagination : (this.limitValue ? this.limitValue : this.pageSize)),
        offset: this.paginatorOffset ? this.paginatorOffset : (this.paginatorValue.offsetPagination ? this.paginatorValue.offsetPagination : (this.offsetValue ? this.offsetValue : 0)),
        type: false,
        fileName: 'pay x get free offer',
        expansion: true,
        filterData: filterConstrainData ? filterConstrainData : null,
        selectedData: [],
        payXGetFree: true
      };
      const dialogRefData = this.displayDialog.open(ExportComponent, { disableClose: true });
      if (dialogRefData && dialogRefData.componentInstance) {
        dialogRefData.componentInstance.contentData = exportData;
      }
    } else {
      this.dialogService.dialogMethod(this.commonConstant &&
        this.commonConstant.dialogMessages
        && this.commonConstant.dialogMessages.noDataExport,
        this.commonConstant.dialogType.alert);
    }
  }

  /**
   * Angular life cycle hook which is used to unsubscribe the subscriptions.
   */
  ngOnDestroy(): void {
    if (this.subscriptionObj) {
      this.subscriptionObj.unsubscribe();
    }
  }
}
