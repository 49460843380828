<cen-loader-vii *ngIf="pageDetails?.isLoader"></cen-loader-vii>
<phase-ii-header-page [heading]="pageDetails?.heading" [buttonInfo]="pageDetails?.buttonInfo">
</phase-ii-header-page>
<div class=" page-container" fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="10px" fxLayoutGap.lt-md="0">
    <mat-card fxFlex="100">
        <phase-ii-common-card-v-ii [paginatorVersion]="2" [needPaginator]="true"
            [listProperties]="pageDetails?.listProperty" [columnsRef]="pageDetails?.columnRef" [actionsIcon]="pageDetails?.actionIcons"
            (actionClicked)="listAction($event)" (valueChanged)="getChangedData($event)">
        </phase-ii-common-card-v-ii>
    </mat-card>
</div>