<form fxLayout="column" [formGroup]="timingDetailsForm" *ngIf="timingDetailsForm">
    <div fxLayout="row" fxLayoutAlign="end end" fxFlex="100" fxLayoutGap="10px">
        <button *ngIf="!readWriteRestriction" class="working-hours-add-button" data-control="addWorkingHours"
            (click)="$event.preventDefault(); addWorkingHours()" mat-stroked-button class="primary-button">
            <mat-icon class="common-add-button" matPrefix>add</mat-icon>
            {{timeHeading}}
        </button>
    </div>
    <br>
    <div fxFlex="100" fxLayoutGap="10px" fxLayout="column" *ngIf="timingDetailsForm?.get('workingHours')"
        formArrayName="workingHours">
        <div *ngFor="let workingHoursForm of timingDetailsForm?.get('workingHours').controls;last as k; let j = index;"
            [ngClass]="readWriteRestriction? 'no-events': 'restore-events'">
            <div class="working-hours-card" [formGroup]="workingHoursForm" *ngIf="workingHoursForm"
                fxLayoutGap.md="10px" fxLayoutGap.gt-md="20px" fxLayout.xs="column" fxLayout.sm="column"
                fxLayout.gt-md="row" fxLayout.md="column" fxFlex.gt-md="100%" fxFlex.md="80%"
                fxLayoutAlign.gt-md="center center">
                <div *ngIf="!readWriteRestriction" fxLayout="row" fxFlex.gt-md="10" fxHide.gt-md fxLayoutAlign="end end"
                    class="remove-working-hours-button-wrapper">
                    <button data-control="removeWorkingHours" mat-icon-button>
                        <mat-icon matTooltip="Remove" (click)="removeWorkingHours(workingHoursForm,j)">
                            cancel
                        </mat-icon>
                    </button>
                </div>
                <div fxLayout="row" fxFlex.gt-md="30" fxLayoutAlign="center center">
                    <mat-form-field fxFlex.gt-md="100" fxFlex.md="90" fxFlex.lt-md="90">
                        <mat-label>Select Day</mat-label>
                        <mat-select multiple data-control="days" formControlName="days">
                            <mat-option [attr.data-control]="'selectDay'+ day?.day" *ngFor="let day of days"
                                [value]="day?.day">
                                {{day?.label}}
                            </mat-option>
                        </mat-select>
                        <mat-error data-control="daysError" *ngIf="workingHoursForm?.get('days')?.hasError('required')">
                            Select day is required.
                        </mat-error>
                    </mat-form-field>
                </div>
                <div fxLayout="row" fxFlex.gt-md="30" fxLayoutAlign="center center">
                    <mat-form-field fxFlex.gt-md="100" fxFlex.md="90" fxFlex.lt-md="90">
                        <mat-label>Start Time</mat-label>
                        <input matInput data-control="startTime" formControlName="startTime" [ngxTimepicker]="picker"
                            readonly>
                        <ngx-material-timepicker #picker></ngx-material-timepicker>
                        <mat-error data-control="startTimeError"
                            *ngIf="workingHoursForm?.get('startTime')?.hasError('required')">
                            Start time is required.
                        </mat-error>
                    </mat-form-field>
                </div>
                <div fxLayout="row" fxFlex.gt-md="30" fxLayoutAlign="center center">
                    <mat-form-field fxFlex.gt-md="100" fxFlex.md="90" fxFlex.lt-md="90">
                        <mat-label>End Time</mat-label>
                        <input matInput data-control="endTime" formControlName="endTime" [ngxTimepicker]="timepicker"
                            readonly>
                        <ngx-material-timepicker #timepicker>
                        </ngx-material-timepicker>
                        <mat-error data-control="endTimeError"
                            *ngIf="workingHoursForm?.get('endTime')?.hasError('required')">
                            End time is required.
                        </mat-error>
                        <mat-error data-control="endTimeLimitExceedsError"
                            *ngIf="workingHoursForm?.get('endTime')?.hasError('ToTimeExceeds')">
                            End time should be greater than start time.
                        </mat-error>
                    </mat-form-field>
                </div>
                <div *ngIf="!readWriteRestriction" fxFlex.gt-md="10" fxHide.md fxHide.lt-md fxHide.lt-sm fxHide.xs
                    fxLayoutAlign="center center" class="remove-working-hours-button-wrapper">
                    <button data-control="removeWorkingHours" mat-icon-button>
                        <mat-icon matTooltip="Remove" class="common-remove-button"
                            (click)="removeWorkingHours(workingHoursForm,j)">
                            cancel
                        </mat-icon>
                    </button>
                </div>
            </div>
        </div>
    </div>
</form>