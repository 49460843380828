import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
/**IpAddressService used to get the client IP Address */
@Injectable({
  providedIn: 'root'
})
/**IpAddressService used to get the client IP Address */
export class IpAddressService {
  /**
   * constructor used to access the services
   * @param http to access the httpclient.
   */
  constructor(private http: HttpClient) { }
  /**getIpAddress method used to get the ip address of the user. */
  getIpAddress() {
    // console.log(window.NetworkInformation)
    // return this.http.get("https://cors-anywhere.herokuapp.com/https://api64.ipify.org/?format=json");
    return this.http.get("https://api64.ipify.org/?format=json");
  }
}
