export interface ImportObject {
    /**
     * Import title to display for that Dailoge.
     */
    title: string;
    /**
     * importApiUrl to make a api call for import the data
     */
    importApiUrl: string;
    /**
     * sampleFileUrl to get sample template from asset folder
     */
    sampleFileUrl?: string;
    /**
     * sampleApiUrl to get sample template from backend using coding 
     */
    sampleApiUrl?: string;
    /**
     * maxFileCount
     */
    maxFileCount?: number;
    /**
    * type of the image
    */
    imageType?: string;
    /**
     * object for additinal information
     */
    importData?: object;
    /**
     * boolean to choose multiple files
     */
    isMultiple?: boolean;
}