export class AuthConstants {
  /**
  * Constants used for pattern validation
  */
  validator = {
    emailValidationPattern: '^[_A-Za-z0-9-\\+]+(\\.[_A-Za-z0-9-]+)*@[A-Za-z0-9-]+(\\.[A-Za-z0-9]+)*(\\.[A-Za-z]{2,})$',
    contactValidationPattern: /^[\(][2-9]\d{2}[\)][\s]\d{3}[\-]\d{4}$/,
    passwordValidationPattern: /^(?=^.{7,20}$)((?=.*\d)(?=.*\W+))(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/,
    noSpaceValidationPattern: '.*\\S.*[a-zA-z0-9]*',
    beginingSpaceValidationPattern: '\\S.*[a-zA-z0-9]*',
    exact10NumberValidationPattern: /^[2-9]\d{2}\d{3}\d{4}$/,
    acceptOnlyCharacters: '^[-a-zA-Z]+(\\s+[-a-zA-Z]+)*$',
    acceptOnlyNumbers: '^[0-9]*$',
    urlValidation: '(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?',
    amountValidationPattern: /^(\d+(?:[\.]\d+)?|\.\d+)$/,
    stateValidationPattern: '[A-Za-z]{2}',
    specialcharacterValidatorPattern: /^[\w\s]+$/,
    acceptOnlyAlphabets: '^[a-zA-Z\/\s\'-().\-]+(\\s[a-zA-Z\/\s\'-().]+)*$',
    acceptIntegerWithoutSymbols: /^[0-9]\d*$/,
    acceptsAlphabetsWithSpecialCharacters: '^[a-z._%+-]+\\.[a-z.-]+\\.[a-z]{2,4}$',
    spaceValidationPattern: '.*\\S.*[a-zA-z0-9]*',
    messageValidationPattern: '^[^\s].+[^\s]$',
    categoryAndSubCategoryName: /^[A-Z@~`!@#$%^&*()_=+\\\\';: \"\\/?>.<,-]*$/i,
    acceptNumbersFromOne: '^[1-9]+([0-9]*$)',
  }
  /**
  * Constants used for show the error messages
  */
  errorMessage = {
    countryFail: "Failed to get country",
    stateFailed: 'Failed to get states',
    failedtoSignUp: "Failed to create account, Please try again after some time.",
    passwordPattern: "Password is invalid",
    name_required: "A name is required",
    name_pattern: "Name accept characters only",
    permit: 'You are not permitted to view this page.',
    mailVerificationFailed: "Your account could not be verified. Kindly contact admin to verify your account.",
    confirmationHeader: "Confirmation",
    REQUEST_FAILED: "Please try again after some time",
    mandatory_feild_required: "Please fill out the mandatory fields properly",
    terms: "Please accept the terms and conditions to register",
    unsavedChanges: "You have unsaved changes. Are you sure you want to leave this page?",
    invalidCredential: "Invalid username or password, please try again",
    inactiveAccount: "Your account is not active at this time. Please contact Admin to activate your account",
    suspendedAccount: "Your account is deactivated. Please contact Admin",
    emailrequire: "Email ID is required",
    invalidEmail: "Please enter a valid email",
    invalidEmailOrPhone: "Email or phone number is invalid",
    emailOrPhoneRequired: "An email or phone number is required",
    incorrectPassword: "The password you entered is incorrect. Please try again",
    invalidPhoneNumber: "Please enter a valid phone number",
    emailExists: "Emails already exist",
    emailNotExist: "Email does not exist. Please provide a registered email",
    passwordMismatch: "The password does not match",
    cancel: "Are you sure you want to cancel?",
    delete: "Are you sure you want to delete?",
    passwordChanged: "Password has been changed successfully!",
    updatePasswordError: "Error in updating password. Please try again later",
    passwordRequired: "Password is required",
    charactersOnly: "Please enter only characters",
    registerSucess: "Your account has been created successfully. Kindly check your email to confirm your registration",
    resetPassworLink: 'Password reset link has been sent to your mail successfully. Please check your mail to reset your password',
    resetPasswordSuccess: "The password has been reset successfully",
    TokenEXPIRED: "Your token has expired. Please contact admin",
    TOKENSUCCESS: "Your account has been verified successfully",
    COUNTRY: 'Country is required',
    generalError: "Something went wrong, Failed to get details",
    dataRetrievalError: "Failed to get details",
    paymentError: "Something went wrong; please ask for payment later",
    ERROR: {
      NAME: {
        required: "Name is required",
        pattern: "Name accepts characters only",
        maxlength: "Name should not exceed 25 characters"
      },
      LAST_NAME: {
        required: "Last name is required",
        pattern: "Last name accepts the characters only",
        maxlength: "Last name should not exceed 25 characters"
      },
      EMAIL: {
        required: "Email ID is required",
        pattern: "Email ID is invalid",
        emailAlreadyExists: "Email ID already exists"
      },
      PASSWORD: {
        wrong_old_pwd: "The old password you entered does not match the current one",
        changed_failure: "Change password failed",
        passwordMatch: "The old password does not match",
        required: "Password is required",
        pattern: "Password should include at least one number, one lowercase letter, one uppercase letter, one special character and between 6 and 20 characters in length",
        oldNewPasswordMatch: "New Password should not be your old password",
        minlength: " Password should be 6 characters long",
        maxlength: " Password should be less than 20 characters",
        nameIsForbidden: "You can't use your old password, so please enter a new password"
      },
      CONTACT: {
        required: "Contact number is required",
        pattern: "Contact number is invalid",
        maxlength: "Contact number should not exceed 20 digit",
        mobileNumberAlreadyExists: "Contact Number already exists",
      },
      ZIPCODE: {
        required: "Postal/Zipcode is required",
        maxlength: 'Postal/Zipcode should not exceed 15 characters',
        deliveryAddressError: "The postal/zip code must be close to the selected location",
      },
      CITY: {
        required: "City is required",
        pattern: "City accepts only characters",
        deliveryAddressError: "The city must be close to the selected location",
        maxlength: "City should not exceed 20 characters"
      },
      STATE: {
        required: "State is required",
        pattern: "The state accepts only two characters",
        deliveryAddressError: "The state must be close to the selected location",
      },
      ADDRESS: {
        required: "Address line 1 is required",
        maxlength: "Address line must not exceed 100 characters"
      }
    },
  }
  /**
  * Constant to store the error messages
  */
  successMessages = {
    paymentSuccess: "Payment request has been sent successfully",
    profileUpdated: "Profile details updated successfully!",
    passwordUpdated: "Your password details has been updated successfully!",
    deactivatedAccound: " has been deactivated successfully"
  }
  /**
   * Constant to store the confirm message
   */
  confirmMessages = {
    paymentCancellation: "Are you sure you want to cancel your payment request?",
    requestPayment: "Are you sure you want to request payment?",
    leavePage: "You have unsaved changes. Are you sure you want to leave this page?",
    cancelEdit: "You have unsaved changes. Are you sure you want to cancel?",
    updateProfile: "Are you sure you want to change your profile details?",
    updatePassword: "Are you sure you want to change your password details?",
    deactivateAccount: "Are you sure you want to deactivate your account?"
  }
  /**
  * variable which is used to store dialog type.
  */
  dialogType = { alert: 'alert', warning: 'warning', confirmation: 'confirmation', information: 'information', success: 'success', failure: 'failure' };
  /**
   * Constants used for button text
  */
  buttonText = {
    ok: 'Okay',
    yes: 'Yes',
    no: 'No',
    cancel: 'Cancel',
    submit: 'Submit',
    save: 'Save',
    leave: 'Leave',
    stay: 'Stay',
    continue: 'Continue'
  };
  /**
  * variable which is used to store browser names.
  */
  safari = 'safari'
  fontStyleValues = {
    bold: 'B',
    italic: 'I',
    underline: 'U'
  };
}