<!--Navbar-->
<div class="navbar width-100" fxLayout="column" *ngIf="!mobileView"
  [ngStyle]="{'pointer-events': isEditor ? 'none': 'all'}">
  <div class="fixed-attention-banner font-bold width-100"
    [ngStyle]="{'position': isEditor ? 'relative': 'fixed', 'background-color':  navBarDetails?.attentionBannerBackgroundColor ? navBarDetails?.attentionBannerBackgroundColor : 'var(--primary-color)','color':  navBarDetails?.attentionBannerTextColor ?  navBarDetails?.attentionBannerTextColor : 'var(--light-color)'}"
    *ngIf="(navBarDetails?.showAttentionBanner)" fxLayout="row" fxLayoutAlign="center center">
    {{navBarDetails?.attentionBannerText[language] ? navBarDetails?.attentionBannerText[language] :
    navBarDetails?.attentionBannerText?.en ? navBarDetails?.attentionBannerText?.en :
    navBarDetails?.attentionBannerText}}
  </div>
  <div fxLayout="row" class="fixed-navbar width-100" id="navBar"
    [fxLayoutAlign]="(isEditor || navBarDetails?.navbarCategory !== 'withCategory')? 'space-around center': 'space-between center'"
    [ngStyle]="{'position': isEditor ? 'relative': 'fixed', 'top': !isEditor && (showAttentionBanner && navBarDetails?.showAttentionBanner) ? '30px': '0px'}">
    <ng-container *ngIf="navBarDetails?.navbarMenus?.length > 0; else navbarSectionContant">
      <ng-container *ngFor="let navbarMenu of navBarDetails?.navbarMenus">
        <ng-container [ngTemplateOutlet]="getTemplate(navbarMenu?.templateName)"></ng-container>
      </ng-container>
    </ng-container>
    <ng-template #navbarSectionContant>
      <ng-container *ngFor="let navbarMenu of navbarSections">
        <ng-container [ngTemplateOutlet]="getTemplate(navbarMenu?.templateName)"></ng-container>
      </ng-container>
    </ng-template>
  </div>
  <ng-container [ngTemplateOutlet]="navbarcategoryTemplate" *ngIf="showCategoryNavbar || isEditor"></ng-container>
</div>

<ng-template #logoTemplate>
  <div fxFlex="10%" class="logo-container cursor" (click)="navigateTo('home')" data-control="homeLink" fxLayout="row"
    fxLayoutAlign=" center" [matTooltip]="storeName">
    <img class="logo" [ngStyle]="{'width': isEditor ? '50%': ''}" *ngIf="websiteSettings?.logoUrl"
      [actualImageURL]="awsUrl + websiteSettings?.logoUrl" alt="Logo" [imageExtension]="imageExtension?.AVIF_FORMAT"
      [onErrorEvent]="{src:'./assets/image-not-available-3.png'}" data-control="company-logo" />
    <p *ngIf="!websiteSettings?.logoUrl && websiteSettings?.store?.name"
      class="company-name margin-left-20 text-ellipses overflow-hidden" data-control="company-logo">
      {{ storeName ? storeName : websiteSettings?.store?.name}}
    </p>
  </div>
</ng-template>

<ng-template #locationTemplate>
  <div fxFlex="9%" class="location-container border-radius-5" data-control="changeLocation" fxLayout="row"
    fxLayoutGap="2px" [matTooltip]="'SHARED.CHOOSE_LOCATION' | translate"
    [matTooltipDisabled]="location && locations.length <= 1"
    [ngStyle]="{'cursor': !mobileView && location && locations.length > 1 ? 'pointer' : 'auto' }"
    (click)="locations.length > 1 ? changeLocation(): null" fxLayoutAlign="center center"
    *ngIf="!mobileView && location && locations?.length > 1 || (navBarDetails?.showSingleLocation && locations?.length === 1)"
    [ngClass]="{'location-container-primary':navBarDetails?.isTransparent,'location-container-secondary':!navBarDetails?.isTransparent}">
    <mat-icon fxHide.lt-lg class="hide-location-icon location-icon">place</mat-icon>
    <div fxLayout="column">
      <div fxLayout="row">
        <span class="location-prefix">{{'SHARED.SHOP_FROM' | translate}}</span>
      </div>
      <div fxLayout="row" fxLayoutAlign=" center" class="location-name-wrapper">
        <span class="location text-ellipses overflow-hidden font-bold">{{ location?.locationName |
          titlecase }}</span>
        <mat-icon *ngIf="locations?.length > 1">expand_more</mat-icon>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #quickAccessTemplate>
  <div fxLayout="row" fxLayoutAlign="center center" *ngIf=" (isEditor && (navBarDetails?.navbarCategory !=='withCategory' )) || (!isEditor && ((navBarDetails?.navbarCategory !=='withCategory' )
      || !showCategoryNavbar))" [matMenuTriggerFor]="navbarMenus">
    <div fxLayout="row" fxLayoutAlign=" center" class="quick-access pad-10-5 cursor border-radius-5">
      <span style="white-space: nowrap;" [dynamicFontResizer]="language"> {{'SHARED.QUICK_ACCESS' | translate}}
      </span>
      <mat-icon fxLayout="row" fxLayoutAlign=" center">expand_more</mat-icon>
    </div>
  </div>
  <mat-menu class="navbarMenus" #navbarMenus="matMenu">
    <div mat-menu-item fxLayout="row" fxLayoutAlign=" center" class="coupon cursor border-radius-5"
      (click)="navigateTo(isDeals?.menuCustomLink,isDeals?.id)" *ngIf="isDeals">
      <span class="material-icons-outlined coupon-icon rotate">sell</span>
      <span class="nav-links font-bold cursor text-ellipses overflow-hidden"
        data-control="allDeals">{{'SHARED.ALL_DEALS' | translate}}</span>
    </div>
    <div mat-menu-item fxLayout="row" fxLayoutAlign=" center" class="coupon cursor border-radius-5"
      (click)="navigateTo('products')">
      <span class="material-symbols-outlined coupon-icon">
        inventory_2
      </span>
      <span class="nav-links font-bold cursor text-ellipses overflow-hidden"
        data-control="ourProducts">{{'SHARED.PRODUCTS' | translate}}</span>
    </div>
    <div mat-menu-item fxLayout="row" *ngIf="isQuickOrder && displayOrderMenu" fxLayoutAlign=" center"
      class="coupon cursor border-radius-5" (click)="navigateTo(isQuickOrder?.menuCustomLink,isQuickOrder?.id)">
      <span class="material-icons-outlined coupon-icon margin-top-2">
        add_shopping_cart
      </span>
      <span class="nav-links font-bold cursor text-ellipses overflow-hidden"
        data-control="quickOrder">{{'SHARED.QUICK_ORDER' | translate}}</span>
    </div>
    <div mat-menu-item fxLayout="row" fxLayoutAlign=" center" (click)="navigateTo('giftcards')"
      *ngIf="websiteSettings?.isGiftcardEnabled" class="coupon cursor border-radius-5" data-control="giftCardMenu">
      <span class="material-icons-outlined coupon-icon">
        card_giftcard
      </span>
      <span class="nav-links font-bold cursor text-ellipses overflow-hidden">{{'SHARED.GIFT_CARD' |
        translate}}</span>
    </div>
    <div mat-menu-item fxLayout="row" fxLayoutGap="2px" fxLayoutAlign=" center" class="coupon cursor border-radius-5"
      (click)="navigateTo('loyaltypoints')" *ngIf="isLoyaltyPluginEnabled">
      <span class="material-icons-outlined coupon-icon">emoji_events</span>
      <span class="nav-links font-bold cursor text-ellipses overflow-hidden" data-control="rewards">{{'SHARED.REWARDS' |
        translate}}</span>
    </div>
    <div mat-menu-item fxLayout="row" fxLayoutAlign=" center" *ngIf="websiteSettings?.isBlog"
      (click)="navigateTo('blogs')" class="coupon cursor border-radius-5" data-control="blogs">
      <span class="material-icons-outlined coupon-icon">
        feed
      </span>
      <span class="nav-links font-bold cursor text-ellipses overflow-hidden">{{'SHARED.BLOGS' | translate}}</span>
    </div>
  </mat-menu>
</ng-template>

<ng-template #searchBarTemplate>
  <div *ngIf="onFocus" class="bg width-100"></div>
  <div
    [fxFlex]="(navBarDetails?.navbarCategory !== 'withCategory') ? (navBarDetails?.searchBarSize ? (navBarDetails?.searchBarSize-3)+'%': '45%') : (navBarDetails?.searchBarSize ? navBarDetails?.searchBarSize+'%' : '45%')"
    #searchFormDiv class="border-radius-5"
    [ngStyle]="{'box-shadow': onFocus ? '0 0px 5px 1px var(--navbar-background-color);': '0 0px 5px 1px #d8d8d8','z-index':onFocus ? 1 : 0} ">
    <form [formGroup]="searchForm" class="search-bar overflow-hidden" (click)="toggleSearch()" fxFlex="92%"
      fxLayout="row" fxLayoutAlign=" center">
      <ng-container *ngIf="navBarDetails?.showSearchCategory">
        <div *ngIf="!isDesiNeighborhood && !inQuickOrderPage;else elseBlock" class="category-container cursor"
          fxLayout="row" fxLayoutAlign=" center" [matMenuTriggerFor]="category" data-control="search-category">
          <span [dynamicFontResizer]="language" data-control="search-category-text"
            class="text-ellipses overflow-hidden">{{isEditor ?
            ('SHARED.ALL_PRODUCTS' | translate)
            :(selectedCategory?.category?.name ||
            selectedCategory?.name ||
            selectedCategory?.categoryName)}}</span>
          <mat-icon class="category-container-dropdown-icon">arrow_drop_down</mat-icon>
        </div>
        <mat-menu class="category-matmenu" #category="matMenu">
          <ng-container>
            <div mat-menu-item *ngFor="let category of categoryList;" fxLayout="row" fxLayoutAlign=" center"
              (click)="onCategorySelect(category)" data-control="category-list">
              <span class="category-text text-ellipses overflow-hidden" [attr.data-control]="category?.categoryName">
                {{category?.id === 0 ? ('SHARED.ALL_PRODUCTS' | translate) :category?.categoryName}}
              </span>
            </div>
          </ng-container>
        </mat-menu>
        <ng-template #elseBlock>
          <div class="category-container cursor" fxLayout="row" fxLayoutAlign=" center" data-control="search-category">
            <span class="category-container-all-product text-ellipses overflow-hidden"
              data-control="search-category-text">{{'SHARED.ALL_PRODUCTS' | translate}}</span>
          </div>
        </ng-template>
      </ng-container>
      <div fxFlex="85%" class="navbar-category-input-container" *ngIf="searchForm?.get('search')">
        <mat-form-field class="search width-100 legacy" (click)="toggleSearch()">
          <input class="searchInput"
            [placeholder]="(isEditor || isDesiNeighborhood || inQuickOrderPage) ? (('SHARED.SEARCH' | translate) + ' ' + ('SHARED.ALL_PRODUCTS' | translate))
                            : (('SHARED.SEARCH' | translate) + ' ' + (selectedCategory?.category?.name || selectedCategory?.name || selectedCategory?.categoryName))"
            matInput data-control="search" formControlName="search" (keyup.enter)="searchText()" #search trimVal
            id="for-navbar-search" />
        </mat-form-field>
      </div>
    </form>
    <button mat-flat-button fxLayout="row" fxFlex="8%" fxLayoutAlign="center center" class="searchButton cursor"
      [ngStyle]="{'background-color':navBarDetails?.navbarColor}" (click)="searchText()" data-control="searchButton">
      <ng-container *ngIf="searchForm.get('search')?.value || inQuickOrderPage; else searchByCategory ">
        <mat-icon [ngStyle]="{'color':navBarDetails?.color}">search</mat-icon>
      </ng-container>
      <ng-template #searchByCategory>
        <mat-icon [ngStyle]="{'color':navBarDetails?.color}" class="arrow-forward-animation" fxLayout="row"
          fxLayoutAlign="center center">arrow_forward</mat-icon>
      </ng-template>
    </button>
  </div>
</ng-template>

<ng-template #myAccountTemplate>
  <div fxLayout="column" fxLayoutAlign="center " class="profile-container border-radius-5 cursor"
    (click)="navigateTo('login')" [matTooltip]="'SHARED.MD_SIGN_IN' | translate" data-control="profile"
    *ngIf="userRole === 'Guest User' && !mobileView && (!user || user?.roleCode === guestRoleCode?.guest)">
    <div class="margin-bottom-4">
      <span data-control="profileHeading">{{'SHARED.HELLO_GUEST' | translate}}</span>
    </div>
    <div>
      <span fxHide.lt-lg data-control="profileSubHeading">{{'SHARED.SIGN_IN' | translate}}</span>
      <span fxShow.md fxShow.lt-md fxHide.gt-md data-control="profileSubHeading">{{'SHARED.MD_SIGN_IN' |
        translate}}</span>
    </div>
  </div>
  <div fxLayout="column" fxLayoutAlign="center " class="profile-container border-radius-5 overflow-hidden cursor"
    [fxFlex]="customMenus && customMenus.length && !isEditor ? '8%' :'9%'" [matMenuTriggerFor]="profileMenu"
    [matTooltip]="'SHARED.PROFILE' | translate" data-control="profile"
    *ngIf="!mobileView && isLoginPage?.subMenu && isLoginPage?.subMenu?.length > 0 && user && user?.id && user?.roleCode !== guestRoleCode?.guest">
    <div class="margin-bottom-4" fxLayout="row" fxLayoutAlign="space-between center">
      <span data-control="registeredHeading">{{'SHARED.REGISTER_HEADING' | translate}}</span>
      <mat-icon class="submenu-icon view-more" fxLayout="row"
        fxLayoutAlign="center center">keyboard_arrow_down</mat-icon>
    </div>
    <div class="text-ellipses overflow-hidden width-100">
      <span class="font-bold" data-control="registeredSubheading">{{user?.firstName}}&nbsp;{{user?.lastName}}</span>
    </div>
  </div>
  <mat-menu class="profile" #profileMenu="matMenu">
    <div class="profile-wrapper width-100" fxLayout="column" fxLayoutGap="5px">
      <div fxFlex="20%" fxLayout="row" fxLayoutGap="3px" fxLayoutAlgin=" center">
        <div class="profile-icon width-40" [matTooltip]="'SHARED.ACCOUNT' | translate">
          <span class="material-icons">
            account_circle
          </span>
        </div>
        <div class="user-details-container" fxLayout="column" fxLayoutAlign="center " fxLayoutGap="5px">
          <span *ngIf="user?.firstName && user?.lastName" class="user-name text-ellipses overflow-hidden"
            data-control="userName">
            {{user?.firstName}}&nbsp;{{user?.lastName}}</span>
          <ng-container *ngIf="user?.email && user?.isUserVerified; else phoneNo">
            <ng-container [ngTemplateOutlet]="email"></ng-container>
          </ng-container>
          <ng-template #email>
            <span *ngIf="user?.email" class="user-email text-ellipses overflow-hidden" data-control="userEmail">
              {{user?.email}}</span>
          </ng-template>
          <ng-template #phoneNo>
            <span *ngIf="user?.phone && user?.countryCode && user?.isSmsVerified; else email"
              class="user-email text-ellipses overflow-hidden" data-control="userMobileNo.">
              <span *ngIf="user?.countryCode">+{{user?.countryCode}}</span> {{user?.phone }}</span>
          </ng-template>
        </div>
      </div>
      <mat-divider></mat-divider>
      <div *ngIf="user?.shopappUser" fxFlex="60%" fxLayout="column" fxLayoutGap="5px">
        <div fxFlex="49%">
          <div class="profile-menus cursor" fxLayout="row" fxLayoutAlign=" center"
            (click)="routeNavigate('shopapp/user/account/edits')" data-control="myAccount"
            routerLinkActive="active-link">
            {{'SHARED.MY_ACCOUNT_AFTER_LOGGEDIN' | translate}}</div>
        </div>
        <div fxFlex="49%">
          <div class="profile-menus cursor" fxLayout="row" fxLayoutAlign=" center"
            (click)="routeNavigate('/shopapp/user/account/manage-address')" data-control="manageAddress"
            routerLinkActive="active-link">
            {{'SHARED.MANAGE_ADDRESS' | translate}}</div>
        </div>
        <div fxFlex="49%">
          <div class="profile-menus cursor" fxLayout="row" fxLayoutAlign=" center"
            (click)="routeNavigate('shopapp/user/account/myorder')" data-control="myOrders"
            routerLinkActive="active-link">
            {{'SHARED.MY_ORDERS' | translate}}</div>
        </div>
      </div>
      <div *ngIf="!user?.shopappUser" fxFlex="60%" fxLayout="row">
        <div fxFlex="49%" fxLayout="column" fxLayoutAlign="space-between ">
          <ng-container *ngFor="let menu of menus">
            <div class="profile-menus cursor" fxLayout="row" fxLayoutAlign=" center"
              (click)="navigateToProfile(menu?.pageCustomLink)" [attr.data-control]="menu?.pageCustomTitle"
              *ngIf="(menu?.pageCustomLink?.includes('wishlist') ? websiteSettings?.isWishListEnabled :true) && (menu?.pageCustomTitle !== 'My Account') && (menu?.pageCustomLink?.includes('subscription') ? isSubscriptionPlugin :true) && (menu?.pageCustomLink?.includes('rewards') ? isLoyaltyPluginEnabled :true) && (menu?.pageCustomLink?.includes('purchasedgiftcards') ? websiteSettings?.isGiftcardEnabled :true) && (menu?.pageCustomLink?.includes('reviews') ? isReviewPluginEnabled :true)">
              {{getTranslatedMenu(menu?.pageCustomTitle)}}
            </div>
          </ng-container>
        </div>
        <mat-divider vertical></mat-divider>
        <div fxFlex="49%">
          <div class="profile-menus cursor" fxLayout="row" fxLayoutAlign=" center"
            (click)="navigateTo(isProfile?.menuCustomLink,isProfile?.id)" data-control="myAccount">
            {{'SHARED.MY_ACCOUNT_AFTER_LOGGEDIN' | translate}}</div>
        </div>
      </div>
      <div fxFlex="10%" fxLayout="row" fxLayoutAlign="center center">
        <button mat-flat-button class="logout-button"
          [ngStyle]="{'background-color':navBarDetails?.navbarColor, 'color': navBarDetails?.color}" fxLayout="row"
          fxLayoutAlign="center center" (click)="onClickLogout()" data-control="loginLink">{{'SHARED.LOG_OUT' |
          translate}}</button>
      </div>
    </div>
  </mat-menu>
</ng-template>

<ng-template #contactTemplate>
  <div fxLayout="row" fxLayoutAlign="center " class="profile-container contact-us-wrapper border-radius-5 cursor">
    <div *ngIf="isContactUs" (click)="navigateTo(isContactUs?.menuCustomLink,isContactUs?.id)" fxLayout="column"
      [matTooltip]="'SHARED.CONTACT_US' | translate" fxLayoutAlign="center ">
      <div class="margin-bottom-4">
        <span fxHide.lt-lg data-control="contactUs">{{'SHARED.ANY_QUERIES' | translate}}</span>
      </div>
      <div>
        <span class="font-bold" data-control="contactUs">{{'SHARED.CONTACT_US' | translate}}</span>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #customMenuTemplate>
  <div fxLayout="row" class="profile-container border-radius-5 cursor" fxLayoutAlign=" center"
    *ngIf="customMenus && customMenus.length && !isEditor" [matMenuTriggerFor]="otherMenu">
    <div data-control="otherMenus" class="font-bold">{{'SHARED.MORE' | translate}}</div>
    <mat-icon class="submenu-icon view-more" fxLayout="row" fxLayoutAlign="center center">keyboard_arrow_down</mat-icon>
  </div>
  <mat-menu class="otherMenus" #otherMenu="matMenu">
    <ng-container *ngFor="let menu of customMenus;">
      <div mat-menu-item fxLayout="row" fxLayoutAlign=" center" (click)="navigateTo(menu?.menuCustomLink)">
        <span class="category-text overflow-hidden text-ellipses"
          [attr.data-control]="menu?.menuName">{{menu?.translatedMenuName?.[language] ?? menu?.menuName}}</span>
      </div>
    </ng-container>
  </mat-menu>
</ng-template>

<ng-template #wishlistTemplate>
  <div fxFlex="4%" fxFlex.lt-md="35px" class="navbar-wishlist" fxLayout="row" fxLayoutAlign="center center"
    (click)="navigateToWishlist()" *ngIf="websiteSettings?.isWishListEnabled || isEditor" data-control="wishlist">
    <button mat-flat-button [matTooltip]="'SHARED.MY_WISHLIST' | translate" class="navbar-wishlist__button">
      <em class="material-icons-outlined navbar-wishlist__icon" ngClass.lt-md="navbar-wishlist__icon-mobile">
        favorite_border
      </em>
      <!-- Icon with badge  -->
      <!-- <em class="material-icons-outlined navbar-wishlist__icon" ngClass.lt-md="navbar-wishlist__icon-mobile"
        matBadge="8" matBadgePosition="after" [matBadgeSize]="badgeSize">
        favorite_border
      </em> -->
    </button>
  </div>
</ng-template>

<ng-template #cartTemplate>
  <div class="cart-container cart-wrapper cursor" fxFlex="5%" fxLayout="row" id="cart" fxLayoutAlign="center center"
    fxLayoutGap="0px" id="shake" *ngIf="isCart" [ngClass]="{'face':isCartValueIncreased}"
    (click)="navigateTo(isCart?.menuCustomLink)" data-control="cart">
    <div [matTooltip]="'SHARED.MY_CART' | translate" style="position: relative;">
      <em class="material-icons-outlined cartIcon" [ngStyle.lt-lg]="{'font-size.px': 35}"
        *ngIf="cartCount >= 0 && isCart?.menuCustomIcon && isCart?.menuCustomIcon === 'shopping_cart'">
        shopping_cart
      </em>
      <div *ngIf="cartCount >= 0" class="cart-number font-bold" fxLayoutAlign="center center">
        <p *ngIf="cartCount < 100">{{cartCount | number:'1.0-2'}}</p>
        <p *ngIf="cartCount > 99">99+</p>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #languageTemplate>
  <div fxLayout="row" class="language-container"
    *ngIf="websiteSettings?.isMultiLanguageEnabled && (showLanguageMenu || isEditor)" fxLayoutAlign=" center"
    [matMenuTriggerFor]="languageMenu">
    <div data-control="selected-language" style="font-weight: bold">{{selectedValue | uppercase}}</div>
    <mat-icon class="submenu-icon more-lang" fxLayout="row" fxLayoutAlign="center center">keyboard_arrow_down</mat-icon>
  </div>
  <mat-menu class="languageMenus" #languageMenu="matMenu">
    <ng-container *ngFor="let lang of languages">
      <div mat-menu-item fxLayout="row" *ngIf="lang?.langCode === selectedValue" fxLayoutAlign=" center"
        (click)="onLanguageSelect(lang?.langCode)">
        <mat-radio-button [checked]="lang?.langCode === selectedValue"><span class="language-text">{{lang?.langName}} -
            {{lang?.langCode.toUpperCase()}}</span></mat-radio-button>
      </div>
    </ng-container>
    <ng-container *ngFor="let lang of languages">
      <div mat-menu-item fxLayout="row" *ngIf="lang?.langCode !== selectedValue" fxLayoutAlign=" center"
        (click)="onLanguageSelect(lang?.langCode)" [ngStyle]="{'pointer-events':isLanguageSelected ? 'all':'none' }">
        <mat-radio-button><span class="language-text">{{lang?.langName}} -
            {{lang?.langCode.toUpperCase()}}</span></mat-radio-button>
      </div>
    </ng-container>
  </mat-menu>
</ng-template>

<!--SideNav for category navbar-->
<mat-drawer-container class="sidenav-container" *ngIf="!isEditor">
  <mat-drawer [mode]="'over'" #leftSideNav id="leftSideNav" fixedTopGap="56" class="leftSideNav"
    [ngStyle]="{'width': '400px'}" [ngStyle.lt-sm]="{'width': '300px'}" [position]="mobileView?'start':'end'"
    [ngClass]="mobileView ? 'left': 'right'" (openedChange)="onOpenedChange($event)">
    <ng-container>
      <div fxLayout="column">
        <div fxLayout="row" fxLayoutAlign="space-between center" class="sidenav-header"
          [ngStyle]="{'width': '400px','padding': '0px 20px 0px 50px'}"
          [ngStyle.lt-sm]="{'width': '300px','padding-inline': '20px'}">
          <h3 data-control="SideNav-Category-Heading">{{'SHARED.CATEGORIES' | translate}}</h3>
          <mat-icon (click)="leftSideNav?.toggle()" class="cursor"
            data-control="SideNav-Category-Close">close</mat-icon>
        </div>
        <div class="overflow-hidden sidenav-category-container">
          <mat-tree [dataSource]="dataSource" [treeControl]="treeControl" class="tree-node">
            <!-- This is the tree node template for leaf nodes -->
            <mat-tree-node *matTreeNodeDef="let node;let index=index" matTreeNodePadding class="min-height-35">
              <!-- use a disabled button to provide padding for tree leaf -->
              <button mat-icon-button disabled></button>
              <span (click)="categoryClick(node)" class="subcategory text-ellipses overflow-hidden cursor"
                [ngClass]="{'category': node?.id === selectedCategoryId}" [attr.data-control]="'categoryname'+index">
                {{node?.name}}
              </span>
            </mat-tree-node>
            <!-- This is the tree node template for expandable nodes -->
            <mat-tree-node *matTreeNodeDef="let node;let index=index;when: hasChild" matTreeNodePadding
              class="min-height-35">
              <div class="right-icon cursor" matTreeNodeToggle [attr.aria-label]="'Toggle ' + node.name" fxLayout="row"
                fxLayoutAlign="center center">
                <mat-icon class="mat-icon-rtl-mirror text-ellipses overflow-hidden"
                  [ngClass]="{'category': node?.id === selectedCategoryId}">
                  {{treeControl.isExpanded(node)
                  ? 'expand_more'
                  : 'chevron_right' }} </mat-icon>
              </div>
              <span matTreeNodeToggle (click)="categoryClick(node)"
                class="subcategory text-ellipses overflow-hidden cursor"
                [ngClass]="{'category': node?.id === selectedCategoryId}" [attr.data-control]="'name'+index">
                {{node?.name}}
              </span>
            </mat-tree-node>
          </mat-tree>

        </div>
      </div>
    </ng-container>
  </mat-drawer>
</mat-drawer-container>

<!--SideNav for category image navbar-->
<mat-drawer-container class="sidenav-container" *ngIf="!isEditor">
  <mat-drawer [mode]="'over'" #leftSideNav2 id="leftSideNav" fixedTopGap="56" class="leftSideNav"
    [ngStyle]="{'width': '400px'}" [ngStyle.lt-sm]="{'width': '300px'}" [position]="mobileView?'start':'end'"
    [ngClass]="mobileView ? 'left': 'right'" (openedChange)="onOpenedChange($event)">
    <ng-container>
      <div fxLayout="column">
        <div fxLayout="row" fxLayoutAlign="space-between center" class="sidenav-header" [ngStyle]="{'width': '395px'}"
          [ngStyle]="{'width': '395px', 'padding': '0px 20px 0px 20px'}" [ngStyle.lt-sm]="{'width': '295px'}">
          <div fxLayout="row" fxLayoutGap="10px" fxLayoutAlign=" center">
            <mat-icon class="cursor" (click)="backToParentCategory()"
              *ngIf="imageCategory?.length != categories?.length"
              data-control="SideNav-Category-Back">keyboard_backspace</mat-icon>
            <h3 data-control="SideNav-Category-Heading">{{'SHARED.CATEGORIES' | translate}}</h3>
          </div>
          <mat-icon (click)="leftSideNav2?.toggle()" class="cursor"
            data-control="SideNav-Category-Close">close</mat-icon>
        </div>
        <div fxLayout="row wrap" class="overflow-hidden sidenav-category-container">
          <div *ngFor="let category of imageCategory;" fxFlex="50%" fxLayout="column" fxLayoutAlign=" center"
            [ngClass]="{'category-img-highlight': category?.categoryName === (!isSideNavOpened ? parentCategory?.categoryName
            : ((currentCategory?.category?.name && currentCategory?.subCategory?.name ? (currentCategory?.subSubCategory?.name ? currentCategory?.subSubCategory?.name :  currentCategory?.subCategory?.name ) :  currentCategory?.category?.name ) || currentCategory?.categoryName))}"
            class="category-img-container cursor margin-bottom-20" (click)="navigateToCategory(category,null,true)">
            <div [attr.data-control]="category?.categoryName+'image'">
              <ng-container *ngIf="category?.categoryImageUrl; else defaultimage">
                <img [actualImageURL]="awsUrl +category?.categoryImageUrl" [ngClass]="'category-sidenav-image'"
                  [ngClass.lt-sm]="'category-image'" [imageExtension]="imageExtension?.AVIF_FORMAT"
                  [onErrorEvent]="{src:'./assets/image-not-available-3.png'}">
              </ng-container>
              <ng-template #defaultimage>
                <img [ngClass]="'category-sidenav-image'" [ngClass.lt-sm]="'category-image'"
                  src="./assets/image-not-available-3.png" alt="no-image">
              </ng-template>
            </div>
            <div fxLayout="row" class="width-100" fxLayoutAlign="center center">
              <span class="category-list-sidenav-2 text-ellipses overflow-hidden cursor"
                [attr.data-control]="category?.categoryName">{{category?.categoryName}}</span>
              <mat-icon *ngIf="category?.subCategory?.length > 0"
                class="sidenav-submenu-icon">keyboard_arrow_right</mat-icon>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
  </mat-drawer>
</mat-drawer-container>

<!--mobile view-->
<div class="navbar-mobile width-100" fxLayout="column" *ngIf="mobileView"
  [ngStyle]="{'pointer-events': isEditor ? 'none': 'all'}">
  <div class="navbar-mobile-wrapper width-100" id="navbar-mobile"
    [ngStyle]="{'position': isEditor ? 'relative': 'fixed'}">
    <div class="fixed-attention-banner width-100 font-bold"
      [ngStyle]="{'background-color':  navBarDetails?.attentionBannerBackgroundColor ? navBarDetails?.attentionBannerBackgroundColor : 'var(--primary-color)','color':  navBarDetails?.attentionBannerTextColor ?  navBarDetails?.attentionBannerTextColor : 'var(--light-color)'}"
      *ngIf="(showAttentionBanner && navBarDetails?.showAttentionBanner)" fxLayout="row" fxLayoutAlign=" center">
      <div class="marquee width-100">
        <span>{{navBarDetails?.attentionBannerText | convertLanguage:language}}</span>
      </div>
    </div>
    <div fxLayout="row" class="mobile-menu" fxLayoutAlign="space-between center">
      <div fxLayout="row" fxLayoutAlign="space-between center">
        <div fxFlex class="logo-container" fxLayout="row" fxLayoutAlign=" center" fxLayoutGap="10px">
          <div (click)="navigateTo('home')" data-control="homeLink">
            <img class="logo-mobile" *ngIf="websiteSettings?.logoUrl"
              [actualImageURL]="awsUrl + websiteSettings?.logoUrl" alt="Logo"
              [imageExtension]="imageExtension?.AVIF_FORMAT" [onErrorEvent]="{src:'./assets/image-not-available-3.png'}"
              data-control="company-logo">
            <p *ngIf="!websiteSettings?.logoUrl && websiteSettings?.store?.name" [ngClass]="'company-name'"
              [ngClass.lt-sm]="'logo-ellipsis text-ellipses overflow-hidden'" data-control=" company-logo">
              {{storeName ? storeName : websiteSettings?.store?.name}}
            </p>
          </div>
        </div>
      </div>
      <div fxLayout="row" fxFlex="25%" fxFlex.lt-sm="45%" fxLayoutAlign="space-between center">
        <div fxLayout="column" class="profile-mobile cursor font-bold" (click)="navigateTo('login')"
          *ngIf="userRole === 'Guest User' && (!user || user?.roleCode === guestRoleCode?.guest)"
          data-control="profile">
          <span>{{'SHARED.MY_ACCOUNT' | translate}}</span>
          <span>{{'SHARED.MD_SIGN_IN' | translate}}!</span>
        </div>
        <div fxLayout="column" class="profile-mobile cursor"
          (click)="user?.shopappUser ? routeNavigate('shopapp/user/account') : navigateTo('account',isProfile?.id)"
          data-control="profile"
          *ngIf=" isLoginPage?.subMenu && isLoginPage?.subMenu?.length > 0 && user && user?.id && user?.roleCode !== guestRoleCode?.guest">
          <span>{{'SHARED.REGISTER_HEADING' | translate}}</span>
          <span class="text-ellipses overflow-hidden" [ngStyle.lt-sm]="{'max-width': '85px'}"
            [ngStyle]="{'max-width': '120px'}">{{user?.firstName}}</span>
        </div>
        <ng-container [ngTemplateOutlet]="wishlistTemplate"></ng-container>
        <div class="cart-container cart-wrapper cursor" fxFlex="25%" fxLayout="row" id="cart"
          fxLayoutAlign="center center" fxLayoutGap="0px" id="shake" *ngIf="isCart"
          [ngClass]="{'face':isCartValueIncreased}" (click)="navigateTo(isCart?.menuCustomLink)" data-control="cart">
          <div [matTooltip]="'SHARED.MY_CART' | translate" style="position: relative;">
            <!-- <em class="material-icons-outlined cartIcon-mobile"
              *ngIf="!cartCount && isCart?.menuCustomIcon && isCart?.menuCustomIcon === 'shopping_cart'">{{isCart?.menuCustomIcon}}</em> -->
            <em class="material-icons-outlined cartIcon-mobile"
              *ngIf="cartCount >= 0 && isCart?.menuCustomIcon && isCart?.menuCustomIcon === 'shopping_cart'">
              shopping_cart
            </em>
            <div *ngIf="cartCount >= 0" class="cart-number" fxLayoutAlign="center center"
              [ngStyle.lt-md]="{'height': '18px', 'width': '18px'}"
              [ngStyle.lt-sm]="{'height': '16px', 'width': '16px'}">
              <p [ngStyle.lt-md]="{'font-size.px': 10}" [ngStyle.lt-sm]="{'font-size.px': 8}" *ngIf="cartCount < 100">
                {{cartCount | number:'1.0-2'}}</p>
              <p [ngStyle.lt-md]="{'font-size.px': 10}" [ngStyle.lt-sm]="{'font-size.px': 8}" *ngIf="cartCount > 99">99+
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div fxLayout="row" class="mobile-search-container" fxLayoutAlign="center ">
      <div fxFlex="95%" fxLayout="row">
        <form [formGroup]="searchForm" class="search-bar search-mobile-container overflow-hidden" fxFlex="50%"
          fxLayout="row" fxFlex="100%" fxLayoutAlign=" center">
          <ng-container *ngIf="navBarDetails?.showSearchCategory">
            <div *ngIf="!isDesiNeighborhood && !inQuickOrderPage;else elseBlock" class="category-container cursor"
              fxLayout="row" fxLayoutAlign=" center" [matMenuTriggerFor]="category" data-control="search-category">
              <span [ngStyle]="{ 'max-width': 'calc(100% - 24px)'}" class="text-ellipses overflow-hidden"
                data-control="search-category-text" *ngIf="selectedCategory?.category?.name ||
                selectedCategory?.categoryName; else allProduct">{{selectedCategory?.category?.name ||
                selectedCategory?.categoryName}}</span>
              <ng-template #allProduct>
                <span [ngStyle]="{ 'max-width': 'calc(100% - 24px)'}" class="text-ellipses overflow-hidden"
                  data-control="search-category-text">{{'SHARED.ALL_PRODUCTS' | translate}}</span>
              </ng-template>
              <mat-icon class="category-container-dropdown-icon-mobile">arrow_drop_down</mat-icon>
            </div>
          </ng-container>
          <mat-menu class="category-matmenu" #category="matMenu">
            <div mat-menu-item *ngFor="let category of categoryList;" fxLayout="row" fxLayoutAlign=" center"
              (click)="onCategorySelect(category)" data-control="category-list">
              <span class="category-text text-ellipses overflow-hidden" [attr.data-control]="category?.categoryName">
                {{category?.id === 0 ? ('SHARED.ALL_PRODUCTS' | translate) :category?.categoryName}}
              </span>
            </div>
          </mat-menu>
          <ng-template #elseBlock>
            <div class="category-container cursor" fxLayout="row" fxLayoutAlign=" center"
              data-control="search-category">
              <span [dynamicFontResizer]="language" class="text-ellipses overflow-hidden category-container-all-product"
                data-control="search-category-text">{{'SHARED.ALL_PRODUCTS' | translate}}</span>
            </div>
          </ng-template>
          <div fxFlex="100%" class="mobile-search-category-divider">
            <mat-form-field class="legacy width-100">
              <input matInput #search data-control="search" class="search-mobile width-100" formControlName="search"
                [placeholder]="(isEditor || isDesiNeighborhood || inQuickOrderPage) ? (('SHARED.SEARCH' | translate) + ' ' + ('SHARED.ALL_PRODUCTS' | translate)) 
                      : (('SHARED.SEARCH' | translate) + ' ' + (selectedCategory?.category?.name || selectedCategory?.name || selectedCategory?.categoryName))"
                (keyup.enter)="searchText()" trimVal id="for-navbar-search-mobile" />
            </mat-form-field>
          </div>
        </form>
        <button mat-flat-button fxLayout="row" fxFlex="10%" fxLayoutAlign="center center" class="searchButton cursor"
          [ngStyle]="{'background-color':navBarDetails?.navbarColor}" (click)="searchText()"
          data-control="searchButton">
          <mat-icon [ngStyle]="{'color':navBarDetails?.color, 'margin': '0px'}">search</mat-icon>
        </button>
      </div>
    </div>
    <div fxLayout="row" class="height-31" fxLayoutAlign="center ">
      <div fxLayout="row" fxFlex="95%" fxLayoutAlign="space-between center">
        <div class="menu" fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="20px" fxLayoutGap.xs="10px"
          fxLayoutGap.lt-sm="10px">
          <div fxLayout="row" fxLayoutAlign=" center" class="quick-access cursor border-radius-5"
            [matMenuTriggerFor]="navbarMenus">
            <span [dynamicFontResizer]="language"> {{'SHARED.QUICK_ACCESS' | translate}} </span>
            <mat-icon fxLayout="row" fxLayoutAlign=" center">expand_more</mat-icon>
          </div>
          <mat-menu class="navbarMenus" #navbarMenus="matMenu">
            <div mat-menu-item fxLayout="row" fxLayoutGap="2px" fxLayoutAlign=" center" *ngIf="isDeals"
              class="coupon cursor border-radius-5" (click)="navigateTo(isDeals?.menuCustomLink,isDeals?.id)">
              <span class="material-icons-outlined coupon-icon rotate">sell</span>
              <span class="nav-links font-bold cursor text-ellipses overflow-hidden"
                data-control="allDeals">{{'SHARED.ALL_DEALS' | translate}}</span>
            </div>
            <div mat-menu-item fxLayout="row" fxLayoutGap="2px" fxLayoutAlign=" center"
              class="coupon cursor border-radius-5" (click)="navigateTo('products')">
              <span class="material-symbols-outlined coupon-icon">
                inventory_2
              </span>
              <span class="nav-links font-bold cursor text-ellipses overflow-hidden"
                data-control="ourProducts">{{'SHARED.PRODUCTS' | translate}}</span>
            </div>
            <div mat-menu-item fxLayout="row" *ngIf="isQuickOrder && displayOrderMenu" fxLayoutAlign=" center"
              class="coupon cursor border-radius-5" fxLayoutGap="2px"
              (click)="navigateTo(isQuickOrder?.menuCustomLink,isQuickOrder?.id)">
              <span class="material-icons-outlined coupon-icon margin-top-2">
                add_shopping_cart
              </span>
              <span class="nav-links font-bold cursor text-ellipses overflow-hidden"
                data-control="quickOrder ">{{'SHARED.QUICK_ORDER' | translate}}</span>
            </div>
            <div mat-menu-item fxLayout="row" fxLayoutAlign=" center" fxLayoutGap="2px"
              *ngIf="websiteSettings?.isGiftcardEnabled" (click)="navigateTo('giftcards')"
              class="coupon cursor border-radius-5" data-control="giftCardMenu">
              <span class="material-icons-outlined coupon-icon">
                card_giftcard
              </span>
              <span class="nav-links font-bold cursor text-ellipses overflow-hidden">{{'SHARED.GIFT_CARD' |
                translate}}</span>
            </div>
            <div *ngIf="isLoyaltyPluginEnabled" mat-menu-item fxLayout="row" fxLayoutGap="2px" fxLayoutAlign=" center"
              class="coupon cursor border-radius-5" (click)="navigateTo('loyaltypoints')">
              <span class="material-icons-outlined coupon-icon">emoji_events</span>
              <span class="nav-links font-bold cursor text-ellipses overflow-hidden"
                data-control="rewards">{{'SHARED.REWARDS' | translate}}</span>
            </div>
            <div mat-menu-item fxLayout="row" fxLayoutAlign=" center" fxLayoutGap="2px" *ngIf="websiteSettings?.isBlog"
              (click)="navigateTo('blogs')" class="coupon cursor border-radius-5" data-control="blogs">
              <span class="material-icons-outlined coupon-icon">
                feed
              </span>
              <span class="nav-links font-bold cursor text-ellipses overflow-hidden">{{'SHARED.BLOGS' |
                translate}}</span>
            </div>
          </mat-menu>
          <div fxLayout="row" class="profile-container border-radius-5 cursor" fxLayoutAlign=" center"
            *ngIf="customMenus && customMenus.length && !isEditor" [matMenuTriggerFor]="otherMenu"
            data-control="otherMenus">
            <div class="nav-links font-bold cursor">{{'SHARED.MORE' | translate}}</div>
            <mat-icon class="submenu-icon view-more" fxLayout="row"
              fxLayoutAlign="center center">keyboard_arrow_down</mat-icon>
          </div>
          <mat-menu class="otherMenus" #otherMenu="matMenu">
            <ng-container *ngFor="let menu of customMenus;">
              <div mat-menu-item fxLayout="row" fxLayoutAlign=" center" (click)="navigateTo(menu?.menuCustomLink)"
                [attr.data-control]="menu?.menuName">
                <span class="nav-links font-bold cursor text-ellipses overflow-hidden">{{menu?.menuName}}</span>
              </div>
            </ng-container>
          </mat-menu>
        </div>
        <div *ngIf="isContactUs" (click)="navigateTo(isContactUs?.menuCustomLink,isContactUs?.id)"
          fxLayoutAlign=" center" fxLayout="row" class="mobile-contact-us-container" data-control="contactUs">
          <span class="nav-links font-bold cursor">{{'SHARED.CONTACT_US' | translate}}</span>
        </div>
      </div>
    </div>
    <div fxLayout="row" class="height-31" fxLayoutAlign="end center"
      *ngIf="(locations?.length > 1 || (navBarDetails?.showSingleLocation && locations?.length === 1)) || (websiteSettings?.isMultiLanguageEnabled && (showLanguageMenu || isEditor))">
      <div *ngIf="locations?.length > 1 || (navBarDetails?.showSingleLocation && locations?.length === 1)"
        fxLayout="row" fxLayoutAlign=" center" (click)="locations.length > 1 ? changeLocation(): null"
        data-control="changeLocation" class="margin-right-1" fxLayoutGap="3px">
        <mat-icon fxLayout="row" fxLayoutAlign="center center" class="location-icon-mobile">place</mat-icon>
        <div fxLayout="row" fxLayoutAlign=" center">
          <span class="mobile-location text-ellipses overflow-hidden cursor font-bold">{{ location?.locationName |
            titlecase }}</span>
          <mat-icon fxLayout="row" fxLayoutAlign="center center" class="mobile-more-location-icon"
            *ngIf="location && locations.length > 1">expand_more</mat-icon>
        </div>
      </div>
      <ng-container *ngTemplateOutlet="languageTemplate"></ng-container>
    </div>
    <ng-container [ngTemplateOutlet]="navbarcategoryTemplate"
      *ngIf="(showCategoryNavbar || isEditor) && categories?.length > 0"></ng-container>
  </div>
</div>

<!--Choose Location Dialog Template-->
<ng-template #chooseLocation>
  <div class="outerspacer">
    <mat-icon class="location-close-icon cursor" matDialogClose (click)="onClose()" data-control="closeIcon"
      *ngIf="location">close
    </mat-icon>
    <div fxLayout="column">
      <p class="location-view-header">{{'SHARED.CHOOSE_LOCATION' | translate}}</p>
    </div>
    <br />
    <mat-divider></mat-divider>
  </div>
  <div mat-dialog-content class="pad-0">
    <ul fxLayoutAlign="center" class="location-list" fxLayout="column" fxLayoutGap="-10px">
      <ng-container *ngFor="let locationElement of locations">
        <li class="location-item cursor" (click)="selectLocation(locationElement)"
          [ngClass]="{'selectedLocation': locationElement?.locationName === location?.locationName}"
          [attr.data-control]="'location'+locationElement?.locationName" matDialogClose>
          <h4 class="location-name"
            [ngClass]="{'selectedLocation': locationElement?.locationName === location?.locationName}">
            {{ locationElement?.locationName | uppercase }}
          </h4>
          <p *ngIf="locationElement?.address?.addressLine1">{{ locationElement?.address?.addressLine1 }},</p>
          <p *ngIf="locationElement?.address?.addressLine2">{{ locationElement?.address?.addressLine2 }},</p>
          <p>
            {{ locationElement?.address?.city }},
            {{ locationElement?.address?.stateDetail?.name }}, {{ locationElement?.address?.zipCode }}
          </p>
        </li>
      </ng-container>
    </ul>
  </div>
</ng-template>

<!--Shop Closed Dialog Template-->
<ng-template #closedShop>
  <div *ngIf="!locations?.length" fxLayoutAlign="center center" class="shop-closed-container">
    <div class="shop-closed">
      <img class='image-align' [actualImageURL]="awsUrl+'themes/shared-assets/shopClosed.png'"
        [imageExtension]="imageExtension?.AVIF_FORMAT" [onErrorEvent]="{src:'./assets/image-not-available-3.png'}"
        alt="Shop Closed Image">
      <p>
        {{'SHARED.NO_LOCATION' | translate}}<br /> {{'SHARED.SORRY' | translate}}
      </p>
    </div>
  </div>
</ng-template>

<!--Template with category navbar-->
<ng-template #withCategory>
  <div fxLayout="row" class="category-navbar shadow overflow-hidden width-100" fxLayoutGap="10px"
    [ngClass]="{'shadow' : !mobileView }" fxLayoutAlign=" center"
    [ngStyle]="{'margin-top': isEditor || mobileView ? '-1px': (showAttentionBanner && navBarDetails?.showAttentionBanner) ? '115px' :'85px', 'position': isEditor ? 'relative': 'fixed'}"
    id="navbarWithoutImage">
    <div class="menu width-100" fxLayout="row" [fxLayoutAlign]="!mobileView ? ' center': 'center center'">
      <div class="menu" *ngIf="!mobileView" fxLayout="row" fxLayoutAlign="center center">
        <div fxLayout="row" class="cursor margin-inline-7" (click)=" navigateTo(isDeals?.menuCustomLink,isDeals?.id)"
          *ngIf="isDeals" fxLayoutGap="2px" [matTooltip]="'SHARED.ALL_DEALS' | translate">
          <span class="material-icons-outlined coupon-icon rotate navlink-align">sell</span>
          <span class="nav-links font-bold cursor" data-control="allDeals">{{'SHARED.ALL_DEALS' | translate}}</span>
        </div>
        <div fxLayout="row" class="margin-inline-7 cursor" fxLayoutGap="2px"
          [matTooltip]="'SHARED.PRODUCTS' | translate" (click)="navigateTo('products')">
          <span class="material-symbols-outlined coupon-icon navlink-align">
            inventory_2
          </span>
          <span class="nav-links font-bold cursor" data-control="ourProducts">{{'SHARED.PRODUCTS' |
            translate}}</span>
        </div>
        <div fxLayout="row" class="margin-inline-7 cursor" fxLayoutGap="2px"
          [matTooltip]="'SHARED.GO_TO_QUICKORDER' | translate" *ngIf="isQuickOrder && displayOrderMenu"
          (click)="navigateTo(isQuickOrder?.menuCustomLink,isQuickOrder?.id)">
          <span class="material-icons-outlined coupon-icon navlink-align">
            add_shopping_cart
          </span>
          <span class="nav-links font-bold cursor" data-control="quickOrder">{{'SHARED.QUICK_ORDER' |
            translate}}</span>
        </div>
        <div fxLayout="row" class="margin-inline-7 cursor" fxLayoutGap="2px"
          [matTooltip]="'SHARED.GIFT_CARD' | translate" (click)="navigateTo('giftcards')"
          *ngIf="websiteSettings?.isGiftcardEnabled">
          <span class="material-icons-outlined coupon-icon navlink-align">
            card_giftcard
          </span>
          <span class="nav-links font-bold cursor" data-control="giftCardMenu">{{'SHARED.GIFT_CARD' |
            translate}}</span>
        </div>
        <div fxLayout="row" fxLayoutGap="2px" fxLayoutAlign=" center" (click)="navigateTo('loyaltypoints')"
          *ngIf="isLoyaltyPluginEnabled" [matTooltip]="'SHARED.REWARDS' | translate" class="margin-inline-7 cursor">
          <span class="material-icons-outlined coupon-icon navlink-align1">emoji_events</span>
          <span class="nav-links font-bold cursor text-ellipses overflow-hidden"
            data-control="rewards">{{'SHARED.REWARDS' | translate}}</span>
        </div>
        <div fxLayout="row" fxLayoutAlign=" center" class="margin-inline-7 cursor" fxLayoutGap="2px"
          [matTooltip]="'SHARED.BLOGS' | translate" *ngIf="websiteSettings?.isBlog" (click)="navigateTo('blogs')"
          data-control="blogs">
          <span class="material-icons-outlined coupon-icon navlink-align1">
            feed
          </span>
          <span class="nav-links font-bold cursor text-ellipses overflow-hidden">{{'SHARED.BLOGS' |
            translate}}</span>
        </div>
      </div>
      <mat-divider [vertical]="true" class="menu-category-divider" *ngIf="!mobileView"></mat-divider>
      <div fxLayout="row" class="category-without-image-container category-list-wrapper" fxLayoutAlign=" center" fxFlex
        [ngStyle]="{'margin' : mobileView ? '0 2.5% !important': ''}">
        <div class="menu width-100" fxLayout="row" fxLayoutAlign=" center" fxLayoutGap="10px">
          <div *ngFor="let category of categories;" fxLayout="row" fxLayoutAlign=" center" class="underline"
            [ngClass]="{'category-highlight': category?.id === selectedCategoryId}"
            [attr.data-control]="category?.categoryName">
            <span fxLayout="row" fxLayoutAlign="center center" class="category-list cursor"
              (click)="navigateToCategory(category)">{{category?.categoryName}}</span>
            <mat-icon *ngIf="category?.subCategory?.length > 0" class="submenu-icon" #menuTrigger2="matMenuTrigger"
              [matMenuTriggerFor]="subcategory" (click)="navigateToCategory(category, menuTrigger2)"
              [ngClass]="{'submenu-icon-highlight': category?.id === selectedCategoryId}">keyboard_arrow_down</mat-icon>
          </div>
        </div>
        <mat-menu class="subcategory-matmenu" #subcategory="matMenu">
          <ng-container *ngFor="let category of selectedSubCategory;">
            <div mat-menu-item *ngIf="category?.subCategory?.length > 0" fxLayout="row" fxLayoutAlign=" center"
              [matMenuTriggerFor]="subsubcategory" (menuOpened)="getSubSubCategory(category)"
              (click)="navigateToCategory(category)" [attr.data-control]="category?.categoryName">
              <span class="category-text text-ellipses overflow-hidden">
                {{category?.categoryName}}
              </span>
            </div>
            <div mat-menu-item *ngIf="!category?.subCategory || category?.subCategory?.length === 0" fxLayout="row"
              fxLayoutAlign=" center" (click)="navigateToCategory(category)">
              <span class="category-text text-ellipses overflow-hidden">
                {{category?.categoryName}}
              </span>
            </div>
          </ng-container>
        </mat-menu>
        <mat-menu class="subcategory-matmenu" #subsubcategory="matMenu">
          <ng-container>
            <div mat-menu-item *ngFor="let category of subSubCategory" fxLayout="row" fxLayoutAlign=" center"
              (click)="navigateToCategory(category)" [attr.data-control]="category?.categoryName">
              <span class="category-text text-ellipses overflow-hidden">
                {{category?.categoryName}}
              </span>
            </div>
          </ng-container>
        </mat-menu>

      </div>
      <ng-container *ngIf="categories?.length > 0 && !mobileView">
        <div fxLayout="row" fxLayoutAlign="center center" class="hamburger-menu width-40" data-control="categoryMenu">
          <mat-icon class="hamburger cursor" [matTooltip]="'SHARED.SHOW_ALL_CATEGORIES' | translate"
            (click)="leftSideNav?.toggle()">menu</mat-icon>
        </div>
      </ng-container>
    </div>
  </div>
</ng-template>

<!--Template with category image navbar-->
<ng-template #withCategory2>
  <ng-container *ngIf="categories?.length > 0">
    <div fxLayout="row" class="category-navbar-2 overflow-hidden width-100" fxLayoutGap="10px"
      [ngClass]="{'shadow' : !mobileView }"
      [ngStyle]="{'margin-top': isEditor || mobileView ? '-1px': (showAttentionBanner && navBarDetails?.showAttentionBanner) ? '115px' : '85px', 'position': isEditor ? 'relative': 'fixed'}"
      id="navbarWithImage">
      <div fxLayout="row" fxFlex="100%" fxFlex-lt-lg="80%" fxLayoutAlign=" center" class="pad-left-10">
        <div fxFlex="96%" class="category-list-wrapper category-with-image-container" fxLayout="row"
          fxLayoutAlign=" center" fxLayoutGap="10px">
          <div *ngFor="let category of categories;" fxLayout="column" fxLayoutAlign=" center"
            [ngClass]="{'category-img-highlight': category?.categoryName === (currentCategory?.category?.name || currentCategory?.categoryName)}"
            class="category-img-container cursor" [attr.data-control]="category?.categoryName">
            <div (click)="navigateToCategory(category)">
              <ng-container *ngIf="category?.categoryImageUrl; else defaultimage">
                <img [actualImageURL]="awsUrl +category?.categoryImageUrl" class="category-image"
                  [imageExtension]="imageExtension?.AVIF_FORMAT"
                  [onErrorEvent]="{src:'./assets/image-not-available-3.png'}">
              </ng-container>
              <ng-template #defaultimage>
                <img class="category-image" src="./assets/image-not-available-3.png" alt="no-image">
              </ng-template>
            </div>
            <div fxLayout="row" class="width-100" fxLayoutAlign=" center">
              <span class="category-list-2 text-ellipses overflow-hidden cursor" [matTooltip]="category?.categoryName"
                (click)="navigateToCategory(category)">{{category?.categoryName}}</span>
              <mat-icon *ngIf="category?.subCategory?.length > 0" class="submenu-icon" [matMenuTriggerFor]="subcategory"
                (click)="navigateToCategory(category, menuTrigger2)"
                #menuTrigger2="matMenuTrigger">keyboard_arrow_down</mat-icon>
            </div>
          </div>
        </div>
        <mat-menu class="subcategory-matmenu" #subcategory="matMenu">
          <ng-container *ngFor="let category of selectedSubCategory;">
            <div mat-menu-item *ngIf="category?.subCategory?.length > 0" fxLayout="row" fxLayoutAlign=" center"
              [matMenuTriggerFor]="subsubcategory" (menuOpened)="getSubSubCategory(category)"
              (click)="navigateToCategory(category)" [attr.data-control]="category?.categoryName">
              <span class="category-text text-ellipses overflow-hidden">
                {{category?.categoryName}}
              </span>
            </div>
            <div mat-menu-item *ngIf="!category?.subCategory || category?.subCategory?.length === 0" fxLayout="row"
              fxLayoutAlign=" center" (click)="navigateToCategory(category)">
              <span class="category-text text-ellipses overflow-hidden" [attr.data-control]="category?.categoryName">
                {{category?.categoryName}}
              </span>
            </div>
          </ng-container>
        </mat-menu>
        <mat-menu class="subcategory-matmenu" #subsubcategory="matMenu">
          <ng-container>
            <div mat-menu-item *ngFor="let category of subSubCategory" fxLayout="row" fxLayoutAlign=" center"
              (click)="navigateToCategory(category)" [attr.data-control]="category?.categoryName">
              <span class="category-text text-ellipses overflow-hidden">
                {{category?.categoryName}}
              </span>
            </div>
          </ng-container>
        </mat-menu>
        <div fxFlex="4%" fxLayout="row" fxLayoutAlign="center center" class="hamburger-menu" *ngIf="!mobileView"
          (click)="leftSideNav2?.toggle()">
          <mat-icon class="hamburger cursor" data-control="categoryMenu">menu</mat-icon>
        </div>
      </div>
    </div>
  </ng-container>
</ng-template>