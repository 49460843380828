<cen-loader-vii *ngIf="loader"></cen-loader-vii>
<cen-loader-vii *ngIf="payoutLoader"></cen-loader-vii>
<div class="main-container" fxLayout="column" *ngIf="!loader">
  <div class="welcome-text">
    <div class="waviy">
      <span class="w">W</span>
      <span class="e">e</span>
      <span class="l">l</span>
      <span class="c">c</span>
      <span class="o">o</span>
      <span class="m">m</span>
      <span class="e-two">e</span>
      &nbsp;
      &nbsp;
      <span class="b">B</span>
      <span class="a">a</span>
      <span class="c-two">c</span>
      <span class="k">k</span>
      <span class="mark">!</span>
    </div>
  </div>
  <div class="sub-heading">
    This dashboard is your gateway to success
  </div>
  <div class="sub-container" fxLayout="row wrap" fxLayoutAlign="space-around center" fxFlex="100%">
    <div class="card" fxLayout="column" fxLayoutAlign="center center">
      <div class="card-header" fxLayout="row" fxLayoutAlign="start center">
        <div class="card-header-icon" fxLayoutAlign="center center">
          <mat-icon class="icon">event_note</mat-icon>
        </div>
        <div class="card-header-text">Users Count</div>
      </div>
      <div class="card-content">{{count[0]}}</div>
    </div>
    <div class="card" fxLayout="column" fxLayoutAlign="center center">
      <div class="card-header" fxLayout="row" fxLayoutAlign="start center">
        <div class="card-header-icon" fxLayoutAlign="center center">
          <mat-icon class="icon">event_note</mat-icon>
        </div>
        <div class="card-header-text">Payment Count</div>
      </div>
      <div class="card-content">{{count[1]}}</div>
    </div>
    <div class="card" fxLayout="column" fxLayoutAlign="center center">
      <div class="card-header" fxLayout="row" fxLayoutAlign="start center">
        <div class="card-header-icon" fxLayoutAlign="center center">
          <mat-icon class="icon">event_note</mat-icon>
        </div>
        <div class="card-header-text">Total Revenue</div>
      </div>
      <div class="card-content">{{currencySymbol}}{{count[2]}}</div>
    </div>
    <div class="card" fxLayout="column" fxLayoutAlign="center center">
      <div class="card-header" fxLayout="row" fxLayoutAlign="start center">
        <div class="card-header-icon" fxLayoutAlign="center center">
          <mat-icon class="icon">event_note</mat-icon>
        </div>
        <div class="card-header-text">Balance Value</div>
      </div>
      <div class="card-content">{{currencySymbol}}{{count[3]}}</div>
    </div>
  </div>
  <div class="cash-update-container" fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="2%">
    <div class="cash-update-content-container">
      <div *ngIf="display" fxLayout="column">
        <div class="heading">Good news arrives shortly !</div>
        <div class="cash-update-container-description">
          We appreciate your hard work and dedication towards marketing. While your efforts have been
          commendable,
          it
          appears that you have not yet reached the minimum payment threshold required to receive a payout.
        </div>
        <div class="note">
          <span class="note-header">Note:</span>
          You're almost there, just {{currencySymbol}}{{completedThresholdValue}} away from reaching
          {{currencySymbol}}{{thresholdValue}} threshold amount
        </div>
      </div>
      <div *ngIf="!display" fxLayout="column">
        <div class="heading">Great News !</div>
        <div class="cash-update-container-description">
          You've successfully reached the payment threshold({{currencySymbol}}{{thresholdValue}}), and it's time to
          collect your
          hard-earned commissions. We
          appreciate your dedication and the results you've achieved through marketing.
        </div>
      </div>
      <div class="cash-update-content-button-container" fxLayoutAlign="start center">
        <button class="cash-update-container-button" mat-raised-button (click)="openPayoutDialogBox(payoutDialogBox)"
          [disabled]="disableButton" *ngIf="!display">Ask for payment</button>
        <mat-icon class="info-icon" [matTooltip]=maTipAskForPayment matTooltipPosition="right"
          *ngIf="showInfoIcon">info</mat-icon>
      </div>
      <div class="note" *ngIf="disableButton && paymentOnProcess">
        <span class="note-header">Note:</span> The payment is currently in the process. Kindly wait before making a
        request for the next payment.
      </div>
    </div>
    <div class="nav-card-container" fxLayout="row wrap" fxLayoutAlign="space-around center">
      <div class="nav-card" fxLayout="row" fxLayoutAlign="space-between center">
        <div>Manage banking information for efficient transactions</div>
        <div class="nav-card-margin-container"></div>
        <button mat-icon-button (click)="navigateTo('account-details')">
          <mat-icon>chevron_right</mat-icon>
        </button>
      </div>
      <div class="nav-card" fxLayout="row" fxLayoutAlign="space-between center">
        <div>
          Share unique referral codes to target customers
        </div>
        <div class="nav-card-margin-container"></div>
        <button mat-icon-button (click)="navigateTo('referral-code')">
          <mat-icon>chevron_right</mat-icon>
        </button>
      </div>
      <div class="nav-card nav-last-card" fxLayout="row" fxLayoutAlign="space-between center">
        <div>Track your earnings and view detailed logs of your payouts</div>
        <div class="nav-card-margin-container"></div>
        <button mat-icon-button (click)="navigateTo('payout-logs')">
          <mat-icon>chevron_right</mat-icon>
        </button>
      </div>
    </div>
  </div>
</div>


<!-- Payout dialog box -->
<ng-template #payoutDialogBox>
  <h5 mat-dialog-title>Ask For Payment</h5>
  <div class="payout-dialog-box">
    <div class="field" [formGroup]="PayoutForm">
      <mat-form-field appearance="outline">
        <mat-label>Enter the amount</mat-label>
        <input matInput formControlName="amount">
        <mat-error *ngIf="PayoutForm?.get('amount')?.hasError('required') && PayoutForm?.get('amount')?.touched">
          This field is required
        </mat-error>
        <mat-error *ngIf="PayoutForm?.get('amount')?.hasError('max')">
          Your account balance has exceeded its maximum limit {{maxPayoutValue}}
        </mat-error>
        <mat-error *ngIf="PayoutForm?.get('amount')?.hasError('min')">
          The minimum amount must be atleast 1
        </mat-error>
        <mat-error
          *ngIf="PayoutForm?.get('amount')?.hasError('pattern') && !PayoutForm?.get('amount')?.hasError('max') && !PayoutForm?.get('amount')?.hasError('min')">
          Please provide the payment amount with 2 decimal places, For example 100.50
        </mat-error>
      </mat-form-field>
    </div>
    <div class="dialog-box-buttons" fxLayoutAlign="end end">
      <button class="dialog-box-cancel-button" mat-stroked-button (click)="ExitPayoutDialogBox()">Cancel</button>
      <button class="cash-update-container-button" mat-raised-button
        (click)="openRequestPaymentDialogBox()">Submit</button>
    </div>
  </div>
</ng-template>