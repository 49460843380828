import { Pipe, PipeTransform } from '@angular/core';
// Pipe which is used to transform count format.
@Pipe({
  name: 'countToKFormat',
})
export class CountToKFormatPipe implements PipeTransform {
  /**
  * Method which is used to convert count format.
  */
  transform(value: number): string {
    const suffixes = ['', 'K', 'M', 'B', 'T'];
    let suffixIndex = 0;
    while (value >= 1000 && suffixIndex < suffixes.length - 1) {
      value /= 1000;
      suffixIndex++;
    }
    let formattedValue = String(value);
    let i = formattedValue.indexOf('.')
    if(i>-1){
      formattedValue= formattedValue.slice(0,i+2)
      formattedValue = formattedValue.endsWith('.0') ? formattedValue.slice(0, -2) : formattedValue;
    }  
    return formattedValue + suffixes[suffixIndex];
  }

}
