/**
* Component used to create timer for discounts
*/
import { ChangeDetectorRef, Component, ElementRef, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild } from '@angular/core';
@Component({
  selector: 'phase-ii-common-timer',
  templateUrl: './common-timer.component.html',
  styleUrls: ['./common-timer.component.scss'],
})
/**
 * Component used to create timer for discounts
 */
export class CommonTimerComponent implements OnInit, OnDestroy, OnChanges {
  /**
   * Variable used to get start date
   */
  @Input() startDate!: any;
  /**
   * Variable used to get end date
   */
  @Input() endDate!: any;
  /**
   * Variable used to get start time
   */
  @Input() startTime!: any;
  /**
   * Variable used to get end time
   */
  @Input() endTime!: any;
  /**
   * Variable used to get templateName
   */
  @Input() templateName!: any;
  /**
   * Varibable used to get time design
   */
  @Input() timerDesign!: any;
  /**
   * Variable used to handle remaining hours
   */
  remainingHours: any = 0 + '' + 0;
  /**
   * Variable used to handle remaining seconds
   */
  remainingSeconds: any = 0 + '' + 0;
  /**
   * Variable used to handle remaining minutes
   */
  remainingMinutes: any = 0 + '' + 0;
  /**
   * Variable used to handle remaining time
   */
  remainingTime!: any;
  /**
   * Variable used to handle remaining days
   */
  remainingDays: any = 0;
  /**
   * Vairbale used to handle interval
   */
  intervalId: any;
  /**
  * Vairbale used to handle interval for digital timer
  */
  digitalId: any;
  /**
  * Vairbale used to handle interval for matrix timer
  */
  matrixId: any;
  /**
  * Vairbale used to handle interval for flip timer
  */
  flipperId: any;
  /**
   * Variable used to handle fliper remaining days
   */
  fliperDay: any = 0 + '' + 0;
  /**
   * Variable used to handle fliper remaining hours
   */
  fliperHours: any = 0 + '' + 0;
  /**
   * Variable used to handle fliper remaining minutes
   */
  fliperMinutes: any = 0 + '' + 0;
  /**
   * Variable used to handle fliper remaining seconds
   */
  fliperSeconds: any = 0 + '' + 0;
  /**
  * Variable used to handle matrix timer days
  */
  matrixDays: any = 0;
  /**
    * Variable used to handle digital timer days
    */
  digitalDays: any = 0;
  /**
   * Angular runs change detection and when it finds that some values changed throws error
   * @param cdr In order to handle this error we use cdr
   */
  constructor(private cdr: ChangeDetectorRef) { }
  /**
   * Angular life cycle hooks
   */
  ngOnInit(): void {

  }
  /**
   * Angular life cycle hooks
   * @param changes contains data where changed from parent component
   */
  ngOnChanges(changes: SimpleChanges) {
    if (changes) {
      this.stopInterval(this.intervalId);
      this.stopInterval(this.digitalId);
      this.stopInterval(this.matrixId);
      this.stopInterval(this.flipperId);
      // Inlize removes toggle class for all fliper elements except seconds. if happend to add.
      if (this.templateName == 'Flipper Timer') {
        const timeContainer = ['days', 'hours', 'minutes'];
        timeContainer.forEach((elementId: string) => {
          if (document && document.getElementById(elementId)) {
            const dom = document.getElementById(elementId);
            const classList = dom && dom.classList;
            if (classList && classList.value.includes('toggle')) {
              document.getElementById(elementId).classList.remove('toggle');
            }
          }
        });
      }
      this.startTimer();
      this.cdr.detectChanges();
    }
  }
  /**
   * Function used to get start timer
   */
  startTimer() {
    const startDateTime = new Date(`${this.startDate && this.startDate.toDateString()} ${this.startTime}`);
    const endDateTime = new Date(`${this.endDate && this.endDate.toDateString()} ${this.endTime}`);
    const currentTime = new Date();
    if (currentTime >= startDateTime) {
      this.digitalTimer(endDateTime);//for digital timer
      this.matrixTimer(endDateTime);//for matrix dot timer
      this.initializeClock(endDateTime);//for Flip timer
      this.remainingTime = endDateTime.getTime() - currentTime.getTime();
      this.intervalId = setInterval(() => {
        const currentTime = new Date();
        this.remainingTime = endDateTime.getTime() - currentTime.getTime();
        const daysDiff = Math.ceil(this.remainingTime / (1000 * 60 * 60 * 24));
        this.remainingDays = daysDiff > 0 ? ((daysDiff - 1) > 9 ? daysDiff - 1 : '0' + (daysDiff - 1)) : 0;
        if (this.templateName === 'Radial Timer') {
          this.radialDays(daysDiff, this.remainingDays);
        }
        if (this.remainingTime > 0) {
          this.formatTime(this.remainingTime);
        } else {
          this.stopInterval(this.intervalId);
        }
      }, 1000);
    }
  }
  /**
   * Function used to format time
   * @param milliseconds 
   */
  formatTime(milliseconds: number): any {
    this.remainingSeconds = Math.floor((milliseconds / 1000) % 60);
    this.radialTimerSeconds(this.remainingSeconds);//For radial timer
    if (this.remainingSeconds >= 0 && this.remainingSeconds < 10) {
      this.remainingSeconds = 0 + '' + this.remainingSeconds;
    }
    this.remainingMinutes = Math.floor((milliseconds / (1000 * 60)) % 60);
    this.radialTimerMinutes(this.remainingMinutes);//For radial timer

    if (this.remainingMinutes >= 0 && this.remainingMinutes < 10) {
      this.remainingMinutes = 0 + '' + this.remainingMinutes;
    }
    this.remainingHours = Math.floor((milliseconds / (1000 * 60 * 60)) % 24);
    this.radialTimerHours(this.remainingHours);//For radial timer
    if (this.remainingHours >= 0 && this.remainingHours < 10) {
      this.remainingHours = 0 + '' + this.remainingHours;
    }
  }
  /**
   * Function used to design circle for radial timer seconds
   * @param remainingSeconds contains remaining seconds
   */
  radialTimerSeconds(remainingSeconds: number): any {
    if (document.getElementById("circular-progress-seconds")) {
      document.getElementById("circular-progress-seconds").style.background = `conic-gradient(
        ${this.timerDesign?.radialTimerPrimaryColor ? this.timerDesign?.radialTimerPrimaryColor : '#4d5bf9'} ${remainingSeconds * 6}deg,
        ${this.timerDesign?.radialTimerSecondaryColor ? this.timerDesign?.radialTimerSecondaryColor : '#cadcff'} ${remainingSeconds * 6}deg
      )`
    }
  }
  /**
   * Function used to design circle for radial timer minutes
   * @param remainingMinutes contains remaining minutes
   */
  radialTimerMinutes(remainingMinutes: number): any {
    if (document.getElementById("circular-progress-minutes")) {
      document.getElementById("circular-progress-minutes").style.background = `conic-gradient(
        ${this.timerDesign?.radialTimerPrimaryColor ? this.timerDesign?.radialTimerPrimaryColor : '#4d5bf9'} ${remainingMinutes * 6}deg,
        ${this.timerDesign?.radialTimerSecondaryColor ? this.timerDesign?.radialTimerSecondaryColor : '#cadcff'} ${remainingMinutes * 6}deg
      )`
    }
  }
  /**
 * Function used to design circle for radial timer minutes
 * @param remainingHours containing remaining hours 
 */
  radialTimerHours(remainingHours: number): any {
    if (document.getElementById("circular-progress-hours")) {
      document.getElementById("circular-progress-hours").style.background = `conic-gradient(
          ${this.timerDesign?.radialTimerPrimaryColor ? this.timerDesign?.radialTimerPrimaryColor : '#4d5bf9'} ${remainingHours * 15}deg,
          ${this.timerDesign?.radialTimerSecondaryColor ? this.timerDesign?.radialTimerSecondaryColor : '#cadcff'} ${remainingHours * 15}deg
        )`
    }
  }
  /**
   * Function used to design circle for radial timer days
   * @param todalDays containing total days of discount
   * @param remainingDays containing remaining days
   */
  radialDays(totalDays: number, remainingDays: number): any {
    if (document.getElementById("circular-progress-days")) {
      document.getElementById("circular-progress-days").style.background = `conic-gradient(
          ${this.timerDesign?.radialTimerPrimaryColor ? this.timerDesign?.radialTimerPrimaryColor : '#4d5bf9'} ${(360 / totalDays) * remainingDays}deg,
          ${this.timerDesign?.radialTimerSecondaryColor ? this.timerDesign?.radialTimerSecondaryColor : '#cadcff'} ${(360 / totalDays) * remainingDays}deg
        )`
    }
  }
  /**
   * Function used for setting time for flip timer
   * @param endtime 
   */
  initializeClock(endtime: any) {
    this.fliperDay = this.fliperHours = this.fliperMinutes = this.fliperSeconds = '00'
    const setTime = this.getTimeRemaining(endtime);
    if (endtime && setTime) {
      this.fliperDay = setTime.days < 10 ? "0" + ((setTime.days > 0) ? setTime.days : "0") : setTime.days;
      this.fliperHours = setTime.hours < 10 ? "0" + ((setTime.hours > 0) ? setTime.hours : "0") : setTime.hours;
      this.fliperMinutes = setTime.minutes < 10 ? "0" + ((setTime.minutes > 0) ? setTime.minutes : "0") : setTime.minutes;
      this.fliperSeconds = setTime.seconds < 10 ? "0" + ((setTime.seconds > 0) ? setTime.seconds : "0") : setTime.seconds;
      this.flipperId = setInterval(() => {
        const time = this.getTimeRemaining(endtime);
        const days = time.days < 10 ? "0" + ((time.days > 0) ? time.days : "0") : time.days + '';
        const hours = time.hours < 10 ? "0" + ((time.hours > 0) ? time.hours : "0") : time.hours + '';
        const minutes = time.minutes < 10 ? "0" + ((time.minutes > 0) ? time.minutes : "0") : time.minutes + '';
        this.fliperSeconds = time.seconds < 10 ? "0" + ((time.seconds > 0) ? time.seconds : "0") : time.seconds;
        if (parseInt(this.fliperMinutes) !== parseInt(minutes) && minutes) {
          this.toggleTime('minutes', minutes);
        }
        if (parseInt(this.fliperHours) !== parseInt(hours) && hours) {
          this.toggleTime('hours', hours);
        }
        if (parseInt(this.fliperDay) !== parseInt(days) && days) {
          this.toggleTime('days', days);
        }
        if (time && time.RemaingTime && time.RemaingTime <= 0) {
          document && document.getElementById('Seconds') && document.getElementById('Seconds').classList.remove('toggle');
          clearInterval(this.flipperId);
        }
      }, 1000);
    }
  }
  /**
   * Method used for adding and removing css class for specific element
   * @param elementId element which need to apply css 
   * @param time time that need to change in flipper.
   */
  toggleTime(elementId: string, time: any) {
    const element = document && document.getElementById(elementId);
    element && element.classList && element.classList.add('toggle');
    setTimeout(() => {
      element && element.classList && element.classList.remove('toggle');
      if (elementId == 'minutes') {
        this.fliperMinutes = time;
      }
      else if (elementId == 'hours') {
        this.fliperHours = time;
      }
      else if (elementId == 'days') {
        this.fliperDay = time;
      }
    }, 500);
  }
  /**
  * Function used for setting time for flip timer
  * @param endtime 
  */
  getTimeRemaining(endtime: any) {
    const curentTime = new Date();
    const RemaingTime = endtime.getTime() ? endtime.getTime() - curentTime.getTime() : 0;
    const seconds = Math.floor((RemaingTime / 1000) % 60);
    const minutes = Math.floor((RemaingTime / (1000 * 60)) % 60);
    const hours = Math.floor((RemaingTime / (1000 * 60 * 60)) % 24);
    const days = Math.floor(RemaingTime / (1000 * 60 * 60 * 24));
    return {
      RemaingTime,
      days,
      hours,
      minutes,
      seconds
    };
  }
  /**
   * Function used for setting time for digital timer
   * @param endDateTime 
   */
  digitalTimer(endDateTime: any): any {
    const digitalTimerDays = document.getElementsByClassName('days');
    const digitalTimerHours = document.getElementsByClassName('hours');
    const digitalTimerMinutes = document.getElementsByClassName('minutes');
    const digitalTimerSeconds = document.getElementsByClassName('seconds');
    var endDate = new Date(endDateTime);
    this.digitalId = setInterval(() => {
      let dateNow = new Date();
      let millisec = endDate.getTime() - dateNow.getTime();
      if (millisec > 0) {
        const days = Math.floor((millisec / (1000 * 60 * 60 * 24)));
        const hours = Math.floor((millisec / (1000 * 60 * 60)) % 24);
        const minutes = Math.floor((millisec / (1000 * 60)) % 60);
        const seconds = Math.floor((millisec / 1000) % 60);
        this.digitalDays = Math.floor((millisec / (1000 * 60 * 60 * 24)));
        if (days) {
          this.setNumber(digitalTimerDays[0], Math.floor(days / 10), 'segment');
          this.setNumber(digitalTimerDays[1], days % 10, 'segment');
        }
        this.setNumber(digitalTimerHours[0], Math.floor(hours / 10), 'segment');
        this.setNumber(digitalTimerHours[1], hours % 10, 'segment');

        this.setNumber(digitalTimerMinutes[0], Math.floor(minutes / 10), 'segment');
        this.setNumber(digitalTimerMinutes[1], minutes % 10, 'segment');

        this.setNumber(digitalTimerSeconds[0], Math.floor(seconds / 10), 'segment');
        this.setNumber(digitalTimerSeconds[1], seconds % 10, 'segment');
      }
      else {
        this.setNumber(digitalTimerDays[0], 0, 'segment');
        this.setNumber(digitalTimerDays[1], 0, 'segment');

        this.setNumber(digitalTimerHours[0], 0, 'segment');
        this.setNumber(digitalTimerHours[1], 0, 'segment');

        this.setNumber(digitalTimerMinutes[0], 0, 'segment');
        this.setNumber(digitalTimerMinutes[1], 0, 'segment');

        this.setNumber(digitalTimerSeconds[0], 0, 'segment');
        this.setNumber(digitalTimerSeconds[1], 0, 'segment');
        this.stopInterval(this.digitalId);
      }
    }, 1000);
  }
  /**
 * Method used for adding and removing css class for specific number segment
 * @param digit used to obtain the element for which CSS is being updated
 * @param num used to get number assigned for the particular element
 * @param className used to differentiate between digital and matrix timer
 */
  setNumber(digit: any, num: any, className: any) {
    if (this.templateName === 'Digital Timer') {
      const digitSegments: number[][] = [
        [0, 1, 2, 3, 4, 5],
        [1, 2],
        [0, 1, 6, 4, 3],
        [0, 1, 6, 2, 3],
        [5, 6, 1, 2],
        [0, 5, 6, 2, 3],
        [0, 5, 4, 3, 2, 6],
        [0, 1, 2],
        [0, 1, 2, 3, 4, 5, 6],
        [0, 1, 6, 2, 5]
      ];
      const matrixSegments: number[][] = [
        [0, 1, 2, 3, 5, 6, 8, 9, 11, 12, 13, 14],
        [1, 4, 7, 10, 13],
        [0, 1, 2, 5, 8, 7, 6, 9, 12, 13, 14],
        [0, 1, 2, 5, 8, 7, 6, 11, 14, 13, 12],
        [0, 3, 6, 7, 8, 2, 5, 11, 14],
        [2, 1, 0, 3, 6, 7, 8, 11, 14, 13, 12],//5
        [2, 1, 0, 3, 6, 9, 12, 13, 14, 11, 8, 7],
        [0, 1, 2, 5, 8, 11, 14],//7
        [0, 1, 2, 3, 5, 6, 7, 8, 9, 11, 12, 13, 14],
        [0, 1, 2, 3, 5, 6, 7, 8, 11, 14]//9
      ];
      let arraySection = (className === 'segment') ? digitSegments : null;
      var segments = digit && digit.getElementsByClassName(className);
      var current = parseInt(digit && digit.getAttribute('data-value'));
      // only switch if number has changed or wasn't set
      if (!isNaN(current) && current != num) {
        // unset previous number
        arraySection[current].forEach(function (digitSegment, index) {
          setTimeout(function () {
            if (segments) {
              segments[digitSegment]?.classList?.remove('on');
            }
          }, 0)
        });
      }
      if (isNaN(current) || current != num) {
        // set new number after
        // setTimeout(function () {
        arraySection[num].forEach(function (digitSegment, index) {
          setTimeout(function () {
            if (segments) {
              segments[digitSegment]?.classList.add('on');
            }
          }, 0)
        });
        // }, 250);
        if (digit) {
          digit.setAttribute('data-value', num);
        }
      }
    }
  }
  /**
   * Function used for setting time for matrix timer
   * @param endDateTime used to set time for matrix timer
   */
  matrixTimer(endDateTime: any): any {
    const matrixTimerDays = document.getElementsByClassName('matrix-days');
    const matrixTimerHours = document.getElementsByClassName('matrix-hours');
    const matrixTimerMinutes = document.getElementsByClassName('matrix-minutes');
    const matrixTimerSeconds = document.getElementsByClassName('matrix-seconds');

    var endDate = new Date(endDateTime);
    this.matrixId = setInterval(() => {
      let dateNow = new Date();
      let millisec = endDate.getTime() - dateNow.getTime();
      if (millisec > 0) {
        const days = Math.floor((millisec / (1000 * 60 * 60 * 24)));
        const hours = Math.floor((millisec / (1000 * 60 * 60)) % 24);
        const minutes = Math.floor((millisec / (1000 * 60)) % 60);
        const seconds = Math.floor((millisec / 1000) % 60);
        this.matrixDays = Math.floor((millisec / (1000 * 60 * 60 * 24)));
        if (days) {
          this.setMatNumber(matrixTimerDays[0], Math.floor(days / 10), 'matrix-col');
          this.setMatNumber(matrixTimerDays[1], days % 10, 'matrix-col');
        }
        this.setMatNumber(matrixTimerHours[0], Math.floor(hours / 10), 'matrix-col');
        this.setMatNumber(matrixTimerHours[1], hours % 10, 'matrix-col');

        this.setMatNumber(matrixTimerMinutes[0], Math.floor(minutes / 10), 'matrix-col');
        this.setMatNumber(matrixTimerMinutes[1], minutes % 10, 'matrix-col');

        this.setMatNumber(matrixTimerSeconds[0], Math.floor(seconds / 10), 'matrix-col');
        this.setMatNumber(matrixTimerSeconds[1], seconds % 10, 'matrix-col');
      }
      else {
        this.setMatNumber(matrixTimerDays[0], 0, 'matrix-col');
        this.setMatNumber(matrixTimerDays[1], 0, 'matrix-col');

        this.setMatNumber(matrixTimerHours[0], 0, 'matrix-col');
        this.setMatNumber(matrixTimerHours[1], 0, 'matrix-col');

        this.setMatNumber(matrixTimerMinutes[0], 0, 'matrix-col');
        this.setMatNumber(matrixTimerMinutes[1], 0, 'matrix-col');

        this.setMatNumber(matrixTimerSeconds[0], 0, 'matrix-col');
        this.setMatNumber(matrixTimerSeconds[1], 0, 'matrix-col');
        this.stopInterval(this.matrixId);
      }
    }, 1000);
  }
  /**
   * method used to set matrix timer digit value for element on segment level by adding and removing class
   * @param digit holds the matrix timer element
   * @param num hold the digit to be set on a specific element
   * @param className holds class name of segment
   */
  setMatNumber(digit: any, num: any, className: any) {
    if (this.templateName === 'Matrix Timer') {

      const matrixSegments: number[][] = [
        [0, 1, 2, 3, 5, 6, 8, 9, 11, 12, 13, 14],
        [1, 4, 7, 10, 13],
        [0, 1, 2, 5, 8, 7, 6, 9, 12, 13, 14],
        [0, 1, 2, 5, 8, 7, 6, 11, 14, 13, 12],
        [0, 3, 6, 7, 8, 2, 5, 11, 14],
        [2, 1, 0, 3, 6, 7, 8, 11, 14, 13, 12],//5
        [2, 1, 0, 3, 6, 9, 12, 13, 14, 11, 8, 7],
        [0, 1, 2, 5, 8, 11, 14],//7
        [0, 1, 2, 3, 5, 6, 7, 8, 9, 11, 12, 13, 14],
        [0, 1, 2, 3, 5, 6, 7, 8, 11, 14]//9
      ];
      let arraySection = (className === 'matrix-col') ? matrixSegments : null;
      var segments = digit && digit.getElementsByClassName(className);
      var current = parseInt(digit && digit.getAttribute('data-value'));
      // only switch if number has changed or wasn't set
      if (!isNaN(current) && current != num) {
        // unset previous number
        arraySection[current].forEach(function (digitSegment, index) {
          setTimeout(function () {
            if (segments) {
              segments[digitSegment]?.classList.remove('on');
            }
          }, 0)
        });
      }

      if (isNaN(current) || current != num) {
        // set new number after
        // setTimeout(function () {
        arraySection[num].forEach(function (digitSegment, index) {
          setTimeout(function () {
            if (segments) {
              segments[digitSegment]?.classList.add('on');
            }
          }, 0)
        });
        // }, 250);
        if (digit) {
          digit.setAttribute('data-value', num);
        }
      }
    }
  }
  /**
   * Function used to stop timer either on leaving the page or on completion of timer period
   */
  stopInterval(intervalId) {
    this.remainingHours = 0 + '' + 0;
    this.remainingSeconds = 0 + '' + 0;
    this.remainingMinutes = 0 + '' + 0;
    this.remainingTime = 0 + '' + 0;
    this.remainingDays = 0;
    this.fliperDay = 0 + '' + 0;
    this.fliperHours = 0 + '' + 0;
    this.fliperMinutes = 0 + '' + 0;
    this.fliperSeconds = 0 + '' + 0;
    clearInterval(intervalId);
  }
  /**
   * Angular life cycle hooks
   */
  ngOnDestroy(): void {
    this.stopInterval(this.intervalId);
    this.stopInterval(this.digitalId);
    this.stopInterval(this.matrixId);
    this.stopInterval(this.flipperId);
  }

}
