import { Directive, ElementRef, Renderer2, AfterViewInit, OnDestroy, HostListener, Input, SimpleChanges } from '@angular/core';

@Directive({
  selector: '[dynamicFontResizer]',
})
export class AutoResizeDirective implements AfterViewInit, OnDestroy {
  private observer: MutationObserver;
  @Input('dynamicFontResizer') language: string;
  private languageFontMap: { [key: string]: string } = {
    'ta': '"Noto Sans Tamil", sans-serif',  // Tamil
    'hi': '"Noto Sans Devanagari", sans-serif',  // Hindi
    'ml': '"Noto Sans Malayalam", sans-serif',  // Malayalam
  };

  constructor(private el: ElementRef, private renderer: Renderer2) { }

  ngAfterViewInit() {
    this.applyStylesAndResizeText();

    this.observer = new MutationObserver(() => {
      this.applyStylesAndResizeText();
    });

    this.observer.observe(this.el.nativeElement, {
      childList: true,
      subtree: true,
      characterData: true,
    });
  }
  ngOnChanges(changes: SimpleChanges) {
    if (changes.language) {
      this.applyStylesAndResizeText();
    }
  }
  ngOnDestroy() {
    if (this.observer) {
      this.observer.disconnect();
    }
  }

  @HostListener('window:resize')
  onResize() {
    this.applyStylesAndResizeText();
  }

  private applyStylesAndResizeText() {
    if (this.language && this.language !== 'en') {
      const container = this.el.nativeElement;
      const fontFamily = this.languageFontMap[this.language];
      this.renderer.setStyle(container, 'font-family', fontFamily);
      this.resizeText();
    }
  }

  private resizeText() {
    const container = this.el.nativeElement;
    const defaultfontSize = this.getFontSize(window.getComputedStyle(container).fontSize);
    let fontSize = defaultfontSize;
    const minFontSize = 9;
    const maxRetries = 20;
    let retries = 0;
    this.renderer.setStyle(container, 'font-size', `${fontSize}px`);
    while (this.isOverflowing(container) && fontSize > minFontSize && retries < maxRetries) {
      fontSize -= 1;
      retries += 1;
      this.renderer.setStyle(container, 'font-size', `${fontSize}px`);
    }
  }

  private isOverflowing(element: HTMLElement): boolean {
    const parent = element.parentElement;
    return element.scrollWidth > element.clientWidth || element.scrollHeight > element.clientHeight || element.scrollHeight > parent.clientHeight || element.scrollWidth > parent.clientWidth;
  }

  private getFontSize(fontSize: string): number {
    return parseFloat(fontSize);
  }
}
