import { Component, Inject, Input, SimpleChanges, ViewChild, PLATFORM_ID } from '@angular/core';
import { DialogService } from 'libs/common/src/lib/services/dialog.service';
import { ThemesService } from 'libs/shared/src/lib/services/themes.service';
import { of, Subscription } from 'rxjs';
import { filter, mergeMap } from 'rxjs/operators';
import { ThemesModuleConstants } from '../../constants/shared-theme-constants';
import { SharedService } from '../../services/shared.service';
import { AuthService } from '@phase-ii/auth';
import { NavigationEnd, Router } from '@angular/router';
import { isPlatformBrowser } from '@angular/common';
import * as Model from '../../models/cart.model';
import { LoadTranslationService } from '@phase-ii/common';
import { FILE_EXTENSION } from '@phase-ii/shared';
@Component({
  selector: 'phase-ii-cart-quick-view',
  templateUrl: './cart-quick-view.component.html',
  styleUrls: ['./cart-quick-view.component.scss'],
})
export class CartQuickViewComponent extends ThemesModuleConstants {
  /**
   * Variable which is used to handle the http call and make it to unsubscribe.
   *  @type {Subscription}
   */
  subscriptionObj: Subscription = new Subscription();
  /**
   * Variable holds the cart product details
   * @type {any} 
   */
  cartDetails: any;
  /**
   * Variable used to access methods from instance environment.
   * @type {any} 
   */
  environment: any;
  /**
   * Variable used to hold the aws image url. 
   * @type {any} 
   */
  awsUrl: string;
  /**
   * Variable used to hold the user details.
   * @type {UserModel} 
   */
  user: Model.UserModel;
  /**
   * Variable used to hold the store location details.
   * @type {LocationDetails} 
   */
  location: Model.LocationDetails;
  /**
   * Variable that used as a reference for the cart drawer.
   * @type {any} 
   */
  @ViewChild('navbarCart') navbarCart: any;
  /**
   * Variable used to show cart quick view or not.
   * @type {any} 
   */
  @Input() showCartQuickView: boolean;
  /**
   * Variable used to hold websiteSettings
   * @type {WebsiteSettingsModel}
   */
  websiteSettings: Model.WebsiteSettingsModel;
  /**
   * Variable used to turn loader on or off
   */
  isLoader: boolean;
  /**
   * Variable used to store guest details
   */
  isGuestUser: boolean;
  /**
  * Variable used to hold the dialog box reference
  */
  dialogRef;
  /**
   * Variable used to store loader info
   */
  listLoader: boolean;
  /**
   * Variable used to disable and enable the pointer
   */
  disablePointer: boolean = false;
  /**
   * Variable used to set whether we are in product detail page or not.
   */
  isProductDetails: boolean;
  /**
  * Variable used to store the image extension
  */
  imageExtension = FILE_EXTENSION;
  /**
   * component constructor which is used to inject the required services.
   * @param sharedService To get the functions and observables in the shared service
   * @param authService To get the functions and observables in the auth service
   * @param themeService  To access methods inside themeService.  
   * @param dialogService To display a message in snackbar.
 */
  constructor(
    private sharedService: SharedService,
    private dialogService: DialogService,
    private themeService: ThemesService,
    private router: Router,
    private authService: AuthService,
    @Inject(PLATFORM_ID) private platformId: object,
    @Inject('environment') environment,
    private translateService: LoadTranslationService
  ) {
    super();
    this.environment = environment;
  }
  /**
   * Component onInit life cycle.
   */
  ngOnInit(): void {
    this.awsUrl = this.environment.AWSImageUrl;
    let url = this.router && this.router.url && this.router.url.split('/');
    this.isProductDetails = url && url.length && url[url.length - 1] && url[url.length - 1]?.includes('productdetails') ? true : false;
    this.subscriptionObj.add(this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe((route: any) => {
      if (route) {
        let url = route?.urlAfterRedirects?.split('/');
        this.isProductDetails = url && url.length && url[url.length - 1] && url[url.length - 1]?.includes('productdetails') ? true : false;
      }
    }));
    this.subscriptionObj.add(this.sharedService.commonWebsiteDetails.pipe(mergeMap((websiteSettings: any) => {
      if (websiteSettings) {
        this.websiteSettings = websiteSettings;
      }
      return this.sharedService.locationDetails;
    }), mergeMap((location) => {
      if (location) {
        this.location = location;
      }
      return this.authService.user;
    })).subscribe((res) => {
      if (res && res['id'] && res['customerId'] && res['roleCode'] !== this.ROLECODE.GUEST)
        this.user = res;
      else
        this.user = null;
    }));
    this.subscriptionObj.add(this.sharedService.updateCartDetails.subscribe((res: any) => {
      if (res && this.cartDetails && this.cartDetails.cartProducts && this.cartDetails.cartProducts.length) {
        let index = this.cartDetails.cartProducts.findIndex((element) => res.variantId === element.variantId)
        if (index !== -1) {
          if (this.cartDetails.cartProducts[index])
            res.quantity ? this.cartDetails.cartProducts[index].quantity = res.quantity : this.cartDetails.cartProducts.splice(index, 1);
        }
      }
    }));
    if (isPlatformBrowser(this.platformId)) {
      window.addEventListener('click', (event: any) => {
        // event.preventDefault();
        let quickcart = document.getElementById('quickCart');
        let navbarCart = document.getElementById('navbarCart');
        let dialog = document.querySelector('.cdk-overlay-container');
        if (quickcart && navbarCart) {
          if (!quickcart.contains(event.target) && !navbarCart.contains(event.target) && !(dialog && dialog.contains(event.target)))
            this.navbarCart.close();
        }
      });
    }
  }
  /**
   * Component onChanges life cycle
   * @param changes which indicate changes
   */
  ngOnChanges(changes: SimpleChanges): void {
    if (changes) {
      if (changes.showCartQuickView) {
        this.showCartQuickView = changes.showCartQuickView.currentValue;
      }
    }
  }
  /**
   * Method used to apply or remove scroll to the body based on the cart quick view.
   * @param event holds whether the cart quick view is opened or not.
   */
  onOpenedChange(event: any): void {
    if (event)
      document.body.style.overflow = 'hidden';
    else
      document.body.style.overflow = 'auto';
  }
  /**
   * Method used for cart navigation.
   */
  navigateToCart(): void {
    this.navbarCart.close();
    this.sharedService.navigateTo('cart');
  }

  /**
   * Method used to delete products from cart.
   * @param productIndex holds index.
   * @param productDetail holds product details.
   */
  onDeleteCartItem(productIndex: number, productDetail: Model.CartProducts) {
    if (this.user && this.user.customerId && this.websiteSettings && this.websiteSettings.isWishListEnabled && !(productDetail && productDetail.isProductNotAvailable)) {
      this.subscriptionObj.add(this.dialogService.openDialog({
        header: this.translateService.getTranslation('SHARED.DIALOG_DELETE_HEADER'),
        message: this.translateService.getTranslation('SHARED.DIALOG_DELETE_MESSAGE'),
        actionType: this.dialogType.confirmation,
        button: { left: this.translateService.getTranslation('SHARED.DIALOG_DELETE_REMOVEBTN'), right: this.translateService.getTranslation('SHARED.DIALOG_DELETE_ADDBTN') },
        arrowNotRequired: true
      }).afterClosed().subscribe((res: string) => {
        if (res === "") {
          this.removeCartItem(productIndex, productDetail, false);
        }
        else if (res) {
          this.removeCartItem(productIndex, productDetail, true);
        }
      }));
    }
    else {
      this.subscriptionObj.add(this.dialogService.dialogMethod(this.translateService.getTranslation('SHARED.DIALOG_REMOVE_PRODUCT'), 'confirmation').afterClosed().subscribe((res: string) => {
        if (res) {
          this.listLoader = true;
          this.disablePointer = true;
          this.user && this.user.customerId ? this.removeCartItem(productIndex, productDetail, false) : this.removeCartItemForGuestUser(productIndex, productDetail);
        }
      }));
    }
  }

  /**
   * Method used to remove products from verified customer cart.
   * @param productIndex holds index.
   * @param productDetail holds product details.
   * @param isWishlist holds a boolean to check wishlist.
   */
  removeCartItem(productIndex: number, productDetail: Model.CartProducts, isWishlist: boolean) {
    this.listLoader = true;
    this.disablePointer = true;
    this.subscriptionObj.add(this.themeService.removeCartProducts(this.user.storeId, productDetail && productDetail.id).subscribe((response: Model.RemoveCartProduct) => {
      if (response && response.cartData && response.cartData.isDeleted) {
        this.cartDetails && this.cartDetails.cartProducts && this.cartDetails.cartProducts.length && this.cartDetails.cartProducts.splice(productIndex, 1);
        if (productDetail && productDetail.quantity) {
          productDetail.quantity = 0;
          this.sharedService.cartDetails.next({ productDetail: productDetail, quantity: productDetail.quantity });
        }
        this.getCartProducts();
        this.sharedService.cartCountValue.next(this.cartDetails && this.cartDetails.cartCount ? this.cartDetails.cartCount : 0);
        if (!isWishlist) {
          this.disablePointer = false;
          this.dialogService.openSnackBar(this.translateService.getTranslation('SHARED.PRODUCT_TO_REMOVE'));
        }
        else {
          this.addToWishlist(productDetail);
        }
      }
    }, () => {
      this.listLoader = false;
      this.disablePointer = false;
      this.dialogService.openSnackBar(this.translateService.getTranslation('SHARED.FAILED_TO_REMOVE'));
    }));
  }

  /**
   * Method used to add products to wishlist.
   * @param productDetail holds product details.
   */
  addToWishlist(productDetail: Model.CartProducts) {
    let data = { storeId: this.user.storeId, customerId: this.user.customerId };
    let wishlistDetails = { wishlistItems: { variantSkuId: productDetail && productDetail.variantId, isDeleted: false, productId: productDetail && productDetail.productId }, wishlistName: 'wishlist' + this.user.customerId, isFromCart: true }
    this.subscriptionObj.add(this.themeService.addToWishlist(data, wishlistDetails).subscribe((response: Model.AddToWishlist) => {
      this.disablePointer = false;
      if (response.wishList && !response.wishList.isAddedToWishlist) {
        this.dialogService.openSnackBar(this.translateService.getTranslation('SHARED.PRODUCT_ADDED_WISHLIST'));
      }
      else {
        this.dialogService.openSnackBar(this.translateService.getTranslation('SHARED.ITEM_ALREADY_WISHLIST'));
      }
    }, () => {
      this.listLoader = false;
      this.disablePointer = false;
      this.dialogService.openSnackBar(this.translateService.getTranslation('SHARED.FAILED_TO_ADD'));
    }));
  }

  /**
   * Method used to remove products from guest user cart.
   * @param productIndex holds index.
   * @param productDetail holds product details.
   */
  removeCartItemForGuestUser(productIndex: number, productDetail: Model.CartProducts) {
    if (productDetail && productDetail.quantity) {
      productDetail.quantity = 0;
    }
    this.sharedService.updateGuestUserCart(productDetail);
    this.cartDetails && this.cartDetails.cartProducts && this.cartDetails.cartProducts.length && this.cartDetails.cartProducts.splice(productIndex, 1);
    this.sharedService.cartDetails.next({ productDetail: productDetail, quantity: productDetail && productDetail.quantity });
    this.getCartProducts();
    this.disablePointer = false;
  }

  /**
   * Method used to update cart to remove/replace item, delete, increase quantity or delete item.
   * @param productIndex To get the index of the product. 
   * @param data To get the product info to update.
   */
  onUpdateCart(data: any, productIndex: any): void {
    if (data && data.productDetail) {
      let weightCount = data && data.productDetail && data.productDetail.variant && data.productDetail.variant.weightCount ? Number(data.productDetail.variant.weightCount) : 1;
      if (data.operation === '-' && (weightCount >= data.quantity)) {
        this.onDeleteCartItem(productIndex, data.productDetail)
      }
      else {
        this.listLoader = true;
        const changedProductData = {
          product: {
            multiLocationVariantStocks: data && data.productDetail && data.productDetail.product && data.productDetail.product.multiLocationVariantStocks ? data.productDetail.product.multiLocationVariantStocks : null,
            orderQuantityMaximum: data && data.productDetail && data.productDetail.product && data.productDetail.product.orderQuantityMaximum ? data.productDetail.product.orderQuantityMaximum : null,
            orderQuantityMinimum: data && data.productDetail && data.productDetail.product && data.productDetail.product.orderQuantityMinimum ? data.productDetail.product.orderQuantityMinimum : null,
          }
        }
        const productQuantity = parseFloat((data.operation === '+' ? data.quantity + weightCount : data.quantity - weightCount).toFixed(2));
        productQuantity ? changedProductData['quantity'] = productQuantity : changedProductData['quantity'] = weightCount;
        const checkMinMaxQty = this.sharedService.checkMinimumAndMaximumQty(changedProductData);
        if (checkMinMaxQty && checkMinMaxQty.qtyUpdateAlert) {
          this.disablePointer = true;
          this.listLoader = false;
          const dialogRef = this.dialogService.openDialog({
            header: this.translateService.getTranslation('SHARED.DIALOG_HEADER'),
            message: checkMinMaxQty.qtyUpdateAlert,
            actionType: 'information',
            button: { right: this.translateService.getTranslation('SHARED.DIALOG_OKAY') },
            disableClose: true
          });
          this.disablePointer = false;
        }
        else {
          data.quantity = parseFloat((data.operation === '+' ? data.quantity + weightCount : data.quantity - weightCount).toFixed(2));
          if (this.user && this.user.customerId) {
            this.subscriptionObj.add(this.themeService.updateCart({ quantity: data && data.quantity }, this.user.storeId, data && data.cartProductId).subscribe((res: any) => {
              if (res) {
                data.productDetail.quantity = data.quantity;
                this.getCartProducts();
                this.dialogService.openSnackBar(this.translateService.getTranslation('SHARED.QUANTITY_UPDATED'));
              }
            }, err => {
              this.listLoader = false;
              this.disablePointer = false;
              this.dialogService.openSnackBar(this.translateService.getTranslation('SHARED.FAILED_QUANTITY_UPDATED'));
            }));

          }
          else {
            data.productDetail.quantity = data.quantity;
            this.sharedService.updateGuestUserCart(data.productDetail);
            this.getCartProducts();
          }
        }
        this.sharedService.cartDetails.next(data);
      }
    }
  }
  /**
   * Method used to get cart products 
   */
  getCartProducts(): any {
    let cartSubscription;
    if (this.user && this.user.customerId && this.user.roleCode !== this.ROLECODE.GUEST && this.location && !this.user.shopappUser) {
      localStorage.removeItem('cart');
      cartSubscription = this.sharedService.getVerifiedCartProducts(this.user.customerId, { storeId: this.user.storeId, locationId: this.location.id, timeZone: this.location && this.location.timeZone, discountDetails: JSON.stringify((sessionStorage.getItem('appliedDiscounts') || sessionStorage.getItem('buyXgetY')) ? { automatic: sessionStorage.getItem('appliedDiscounts') ? JSON.parse(sessionStorage.getItem('appliedDiscounts')) : null, buyXgetY: sessionStorage.getItem('buyXgetY') ? JSON.parse(sessionStorage.getItem('buyXgetY')) : null } : {}), isCart: true }, this.user.storeId);
    } else {
      const value = JSON.parse(localStorage.getItem('cart'));
      if (value && value['cartProduct'] && value['cartProduct'].products && value['cartProduct'].products.length && this.location) {
        cartSubscription = this.sharedService.getGuestCartProducts(this.sharedService.storeId, JSON.parse(localStorage.getItem('cart')), { locationId: this.location.id, discountDetails: JSON.stringify((sessionStorage.getItem('appliedDiscounts') || sessionStorage.getItem('buyXgetY')) ? { automatic: sessionStorage.getItem('appliedDiscounts') ? JSON.parse(sessionStorage.getItem('appliedDiscounts')) : null, buyXgetY: sessionStorage.getItem('buyXgetY') ? JSON.parse(sessionStorage.getItem('buyXgetY')) : null } : {}), isCart: true });
      }
      else {
        cartSubscription = of({ cartProducts: { cartProducts: [] } });
      }
    }
    if (cartSubscription) {
      this.subscriptionObj.add(cartSubscription.pipe(mergeMap((res: any) => {
        if (res && res.cartProducts) {
          this.cartDetails = res.cartProducts;
          if (this.user && this.user.customerId && this.user.roleCode !== this.ROLECODE.GUEST) {
            this.isLoader = true;
            this.listLoader = true;
            return this.sharedService.getCartSubscriptionDetails(this.user.storeId, this.user.customerId, { locationId: this.location && this.location.id, timeZone: this.location && this.location.timeZone })
          }
          return of(false);
        }
        return of(false);
      })).subscribe((subscriptionCartDetails: any) => {
        if (subscriptionCartDetails) {
          subscriptionCartDetails?.cartSubscriptionDetails?.cartDetails?.forEach(cartDetail => {
            if (cartDetail)
              this.cartDetails?.cartProducts.push(cartDetail?.subscriptionDetails)
          });
          this.sharedService.subscriptionCartCountValue.next(subscriptionCartDetails.cartSubscriptionDetails && subscriptionCartDetails.cartSubscriptionDetails.count ? subscriptionCartDetails.cartSubscriptionDetails.count : 0);
        }
        this.sharedService.cartCountValue.next(this.cartDetails && this.cartDetails.cartCount ? this.cartDetails.cartCount : 0);
        this.sharedService.listAfterDeletion(this.cartDetails && this.cartDetails.cartProducts, sessionStorage.getItem('appliedDiscounts') ? JSON.parse(sessionStorage.getItem('appliedDiscounts')) : []);
        if (!this.user)
          this.sharedService.updateGuest(this.cartDetails && this.cartDetails.cartProducts);
        this.isLoader = false;
        this.listLoader = false;
      }, (err) => {
        this.disablePointer = false;
        this.isLoader = false;
        this.listLoader = false;
      }));
    }
  }
  /**
   * Method used to remove subscription items from the quick cart
   * @param index holds the index of the cart product
   * @param data holds the cart details
   */
  onRemoveSubscriptionItems(index: number, data: any): void {
    this.disablePointer = true;
    this.subscriptionObj.add(this.dialogService.dialogMethod(this.translateService.getTranslation('SHARED.DIALOG_SUBSCRIPTION_REMOVE'), 'confirmation', true).afterClosed().pipe(filter((result: boolean) => {
      this.disablePointer = false;
      return result;
    }), mergeMap(() => {
      if (data && data.cartSubscriptionId && data.cartId && this.cartDetails && this.cartDetails.cartProducts && this.cartDetails.cartProducts.length && this.cartDetails.cartProducts[index]) {
        this.isLoader = true;
        return this.sharedService.removeCartSubscriptionDetails(this.user.storeId, data.cartSubscriptionId, data.cartId);
      }
      return of(null)
    })).subscribe((res: any) => {
      if (res && res.success) {
        this.cartDetails.cartProducts.splice(index, 1);
        this.getCartProducts();
      }
      this.isLoader = false;
    }, (err) => {
      this.disablePointer = false;
      this.isLoader = false;
    }));
  }
  /**
   * Method used to invoke getCartProducts method
   */
  getProducts() {
    this.isLoader = true;
    this.navbarCart.toggle();
    if (this.location) {
      this.getCartProducts();
    }
  }
  // /**
  //  * Method used to update the frequency.
  //  * @param event holds the selected product details.
  //  * @param frequency holds the event triggered.
  //  * @param index holds the index of the selected products.
  //  */
  // setFrequency(event, frequency, index): void {
  //   if (frequency && frequency.value && event && event.cartSubscriptionId && event.cartId && this.cartDetails && this.cartDetails.cartProducts && this.cartDetails.cartProducts.length && this.cartDetails.cartProducts[index] && this.cartDetails.cartProducts[index].frequency) {
  //     this.isLoader = true;
  //     this.subscriptionObj.add(this.sharedService.updateCartSubscriptionDetails(this.user.storeId, event.cartSubscriptionId, event.cartId, { quantity: event.quantity ? event.quantity : null, frequency: frequency.value }).subscribe((res: any) => {
  //       if (res && res.success) {
  //         this.cartDetails.cartProducts[index].frequency = frequency.value;
  //         this.dialogService.openSnackBar('Plan frequency updated');
  //         this.sharedService.cartDetails.next(event);
  //         this.getCartProducts();
  //       }
  //     }, (err) => {
  //       this.isLoader = false;
  //       this.dialogService.openSnackBar('Failed to update plan frequency');
  //     }));
  //   }
  // }
  /**
   * Angular life cycle hook to destroy the component.
   * @type {void}
   */
  ngOnDestroy(): void {
    this.sharedService.cartDetails.next(null);
    this.subscriptionObj.unsubscribe();
  }
}
