import { Injectable, EventEmitter } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { filter, mergeMap } from 'rxjs/operators';
import { HttpRoutingService } from './httpRouting.service';
import { AuthService } from 'libs/auth/src/lib/services/auth.service';
import { API } from '@phase-ii/common';

@Injectable({
  providedIn: 'root'
})
export class SettingsService {
  testimonialId = new BehaviorSubject<number>(null);
  storeIndustry;
  pageConfig = new BehaviorSubject<any>(null);
  constructor(private httpRoutingService: HttpRoutingService, private authService: AuthService) { }
  currentActiveTab = new EventEmitter<string>();
  deleteLogo = new BehaviorSubject<boolean>(false);

  storePaymentAccDetails(data) {
    return this.httpRoutingService.postMethod('payment/storePaymentAccDetails', data);
  }
  getStoreIndustryType() {
    return this.httpRoutingService.getMethodShopApp('industry/storecount');
  }
  getSingleUserModuleMapping(data): Observable<any> {
    return this.httpRoutingService.postMethod('menu/getSingleUserModuleMapping', data);
  }
  getCurrencyDetails() {
    return this.httpRoutingService.getMethod('company/getCurrencyDetails');
  }
  getSettings() {
    return this.httpRoutingService.getMethod('company/getWebsiteSetting');
  }
  orderUpload(data) {
    return this.httpRoutingService.postMethod('user/orderImport', data);
  }
  getDetails(data) {
    return this.httpRoutingService.postMethod('plugins/getList', data);
  }
  addDetails(data) {
    return this.httpRoutingService.postMethod('plugins/addList', data);
  }
  deleteList(data) {
    return this.httpRoutingService.postMethod('plugins/deleteList', data);
  }
  getPluginCategory() {
    return this.httpRoutingService.getMethod('plugins/getPluginCategory');
  }
  getMyPlugin(data?: { filterText: any }) {
    return this.httpRoutingService.postMethod('plugins/getMyPlugin', data);
  }
  createSettings(data) {
    return this.httpRoutingService.postMethod('company/createWebsiteSetting', data);
  }
  templateView(id) {
    return this.httpRoutingService.postMethod('status/getTemplate', id);
  }
  updateTemplate(data) {
    return this.httpRoutingService.postMethod('status/updateTemplate', data);
  }
  updateSettings(data) {
    return this.httpRoutingService.putMethod('company/updateWebsiteSetting', data);
  }
  updateOrderSettings(data) {
    return this.httpRoutingService.putMethod('company/updateOrderSetting', data);
  }
  updateProductSettings(data) {
    return this.httpRoutingService.putMethod('company/updateProductSetting', data);
  }
  updateTipSettings(data) {
    return this.httpRoutingService.putMethod('company/updateTipSetting', data);
  }
  updateSettingsForSuperAdmin(data) {
    return this.httpRoutingService.putMethod('company/updateWebsiteSettingForSuperAdmin', data);
  }
  createSettingsForSuperAdmin(data) {
    return this.httpRoutingService.postMethod('company/createWebsiteSettingForSuperAdmin', data);
  }
  getInventoryLocation(data?) {
    return this.httpRoutingService.postMethod('location/getInventoryLocation', data ? data : {});
  }
  getSingleInventoryLocation(data) {
    return this.httpRoutingService.postMethod('location/getSingleInventoryLocation', data);
  }
  getProducts(data) {
    return this.httpRoutingService.postMethod('user/getProducts', data);
  }
  updateInventoryLocation(data) {
    return this.httpRoutingService.putMethod('location/updateInventoryLocation', data);
  }
  createInventoryLocation(data) {
    return this.httpRoutingService.postMethod('location/createInventoryLocation', data);
  }
  deleteInventoryLocation(id) {
    return this.httpRoutingService.deleteMethod('location/isDeletedInventoryLocation', id);
  }

  createTestimonial(data) {
    return this.httpRoutingService.postMethod('testimonial/createTestimonial', data);
  }

  getAllImages(data) {
    return this.httpRoutingService.postMethod('product/getAllImages', data);
  }
  deleteImages(data) {
    return this.httpRoutingService.postMethod('product/deleteImage', data);
  }
  updateTestimonial(data) {
    return this.httpRoutingService.postMethod('testimonial/updateTestimonial', data);
  }
  deleteTestimonial(data) {
    return this.httpRoutingService.postMethod('testimonial/isDeletedTestimonial', data);
  }
  getOneTestimonial(data) {
    return this.httpRoutingService.postMethod('testimonial/getOnetestimonial', data);
  }
  getOneReview(data) {
    return this.httpRoutingService.postMethod('testimonial/getOneReview', data);
  }
  getReviewForOneProduct(data) {
    return this.httpRoutingService.postMethod('testimonial/getReviewForOneProduct', data);
  }
  deleteReview(data) {
    return this.httpRoutingService.postMethod('testimonial/deleteReview', data);
  }
  getPolicyTypes() {
    return this.httpRoutingService.getMethod('company/getPolicyTypes');
  }
  createPolicyType(data) {
    return this.httpRoutingService.postMethod('company/createPolicyType', data);
  }
  updatePolicyType(data) {
    return this.httpRoutingService.postMethod('company/updatePolicyType', data);
  }
  deletePolicyType(data) {
    return this.httpRoutingService.postMethod('company/DeletePolicyType', data);
  }

  getCompanyPolicis() {
    return this.httpRoutingService.getMethod('company/getAllPolicis');
  }
  createCompanyPolicy(data) {
    return this.httpRoutingService.postMethod('company/createCompanyPolicy', data);
  }
  updateCompanyPolicy(data) {
    return this.httpRoutingService.putMethod('company/updateCompanyPolicy', data);
  }
  deleteCompanyPolicy(data) {
    return this.httpRoutingService.postMethod('company/deleteCompanyPolicy', data);
  }
  // createCustomCompanyPolicy(data) {
  //   return this.httpRoutingService.postMethod('company/createCustomCompanyPolicy', data);
  // }
  getAllUserLists(data) {
    return this.httpRoutingService.postMethod('user/getAllUserLists', data);
  }

  getDeliveryCharge(data) {
    return this.httpRoutingService.postMethod('company/getDeliveryCharge', data);
  }
  createDeliveryCharge(data) {
    return this.httpRoutingService.postMethod('company/createDeliveryCharge', data);
  }
  updateDeliveryCharge(data) {
    return this.httpRoutingService.postMethod('company/updateDeliveryCharge', data);
  }
  getUsersOrder(data) {
    return this.httpRoutingService.postMethod('user/getUserOrder', data);
  }

  getRegistrationDate(data) {
    return this.httpRoutingService.postMethod('user/getRegistrationDate', data);
  }
  deleteUser(data) {
    return this.httpRoutingService.postMethod('user/isDeletedUser', data);
  }
  updateData(data) {
    return this.httpRoutingService.putMethod('user/updateUserData', data);
  }
  createUserData(data) {
    return this.httpRoutingService.postMethod('user/createUser', data);
  }

  getRoleByUser(data) {
    return this.httpRoutingService.postMethod('user/getUserRole', data);
  }
  disableUser(data) {
    return this.httpRoutingService.postMethod('user/disableUser', data);
  }

  createUserRoleData(data) {
    return this.httpRoutingService.postMethod('user/createUserRole', data);
  }

  getDisableUser(data) {
    return this.httpRoutingService.postMethod('user/getDisableUser', data);
  }
  enableUser(data) {
    return this.httpRoutingService.postMethod('user/enableUser', data);
  }


  getAllTestimonials(data) {
    return this.httpRoutingService.postMethod('testimonial/getTestimonial', data);
  }
  getAllProducts() {
    return this.httpRoutingService.getMethod('product/getAllProducts');
  }

  getAllReviews(data) {
    return this.httpRoutingService.postMethod('testimonial/getAllReviews', data);
  }

  getPostmatesSetup(): Observable<any> {
    return this.httpRoutingService.getMethod('company/getPostmatesSetup');
  }
  createPostmatesSetup(data): Observable<any> {
    return this.httpRoutingService.postMethod('company/createPostmatesSetup', data);
  }
  updatePostmatesSetup(data): Observable<any> {
    return this.httpRoutingService.postMethod('company/updatePostmatesSetup', data);
  }

  getCurrencyUnits(): Observable<any> {
    return this.httpRoutingService.getMethod('company/getUnits');
  }
  getReviewCountForOneProduct(data) {
    return this.httpRoutingService.postMethod('testimonial/getReviewCountForOneProduct', data);
  }
  getPaymentOptions() {
    return this.httpRoutingService.getMethod('payment/getPaymentOptionsBasedOnMapping');
  }
  createPaymentMapping(data) {
    return this.httpRoutingService.postMethod('payment/createPaymentMapping', data);
  }
  getPaymentMappings() {
    return this.httpRoutingService.getMethod('payment/getPaymentMappings');
  }
  getPaymentOptionsByCompanyId() {
    return this.httpRoutingService.getMethod('payment/getPaymentOptionsByCompanyId');
  }
  isEnablePaymentMapping(data) {
    return this.httpRoutingService.postMethod('payment/isEnablePaymentMapping', data);
  }
  deleteDeliveryCharge(data) {
    return this.httpRoutingService.postMethod('company/deleteDeliveryCharge', data);
  }
  deleteLogoImage(data) {
    return this.httpRoutingService.postMethod('company/deleteLogoImage', data);
  }
  deleteFavIcon(data) {
    return this.httpRoutingService.postMethod('company/deleteFavIcon', data);
  }
  deleteStoreIcon(data) {
    return this.httpRoutingService.postMethod('company/deleteStoreIcon', data);
  }
  createFAQ(data) {
    return this.httpRoutingService.postMethod('testimonial/createFAQ', data);
  }
  getFAQ() {
    return this.httpRoutingService.getMethod('testimonial/getFAQ');
  }
  getSingleFAQ(data) {
    return this.httpRoutingService.postMethod('testimonial/getSingleFAQ', data);
  }
  deleteFAQ(id) {
    return this.httpRoutingService.deleteMethod('testimonial/deleteFAQ', id);
  }
  updateFAQ(data) {
    return this.httpRoutingService.putMethod('testimonial/updateFAQ', data);
  }
  sortFAQ(data) {
    return this.httpRoutingService.postMethod('testimonial/sortFAQ', data);
  }
  exportFAQ() {
    return this.httpRoutingService.getMethod('testimonial/exportFAQ')
  }
  getStatusCategory() {
    return this.httpRoutingService.getMethod('status/getStatusCategory');
  }
  createStatusCategory(data) {
    return this.httpRoutingService.postMethod('status/createStatusCategory', data);
  }
  updateStatusCategory(data) {
    return this.httpRoutingService.postMethod('status/updateStatusCategory', data);
  }
  deleteStatusCategory(data) {
    return this.httpRoutingService.postMethod('status/deleteStatusCategory', data);
  }


  getWeightUnits() {
    return this.httpRoutingService.getMethod('product/getWeightUnits');
  }
  createWeightUnits(data) {
    return this.httpRoutingService.postMethod('product/createWeightUnits', data);
  }
  updateWeightUnits(data) {
    return this.httpRoutingService.postMethod('product/updateWeightUnits', data);
  }
  deleteWeightUnits(data) {
    return this.httpRoutingService.postMethod('product/deleteWeightUnits', data);
  }
  getCompanySettings(data) {
    return this.httpRoutingService.postMethod('company/getCompanyWebsiteSetting', data);
  }
  getPaymentForSettings(data) {
    // console.log("Inside");
    return this.httpRoutingService.postMethod('payment/getPaymentForSettings', data);
  }
  updateTemplateSettings(data) {
    // console.log('Data', data);
    return this.httpRoutingService.postMethod('status/updateTemplateSettings', data);
  }
  // getTemplateSettings() {
  //   return this.httpRoutingService.getMethod('status/getTemplateSettings');
  // }
  getTemplateSettingsById(id) {
    return this.httpRoutingService.postMethod('status/getTemplateSettingsById', id);
  }
  getTemplateDetails(data?) {
    return this.httpRoutingService.postMethod('status/getTemplateDetails', data);
  }
  updateTemplateContent(data) {
    return this.httpRoutingService.postMethod('status/updateTemplateContent', data);
  }
  getStaffAndProductCount() {
    return this.httpRoutingService.getMethod('company/getStaffAndProductCount');
  }
  getRefundForSettings(data) {
    return this.httpRoutingService.postMethod('payment/getRefundForSettings', data);
  }
  getRefundAmountForSettings(data) {
    return this.httpRoutingService.postMethod('payment/getRefundAmountForSettings', data);
  }
  getStaffCount() {
    return this.httpRoutingService.getMethod('company/getStaffCount');
  }
  getStaffDetails(id) {
    return this.httpRoutingService.postMethod('user/getStaffDetails', id);
  }
  getTemplateContent(id) {
    return this.httpRoutingService.postMethod('status/getTemplateContent', id);
  }
  getLocation() {
    return this.httpRoutingService.getMethod('location/getLocationList');
  }
  // getPageConfig(data) {
  //   // console.log('config data',data);
  //   return this.httpRoutingService.postMethod('storeIndustryType/getStoreIndustryTypeDetails', data);
  // }
  imageUpload(data) {
    return this.httpRoutingService.postMethod('image/imageImport', data);
  }
  getAllWholesaleRequest(data) {
    return this.httpRoutingService.postMethod('user/getAllWholesaleRequest', data);
  }
  getAllWholesaleRequestForApprove(data) {
    return this.httpRoutingService.postMethod('user/getAllWholesaleRequestForApprove', data);
  }
  updateWholesaleRequest(data) {
    return this.httpRoutingService.postMethod('user/updateWholesaleRequest', data);
  }
  getRoleById(data) {
    return this.httpRoutingService.postMethod('role/getRoleById', data);
  }
  getSingleRoleModuleMapping(data): Observable<any> {
    return this.httpRoutingService.postMethod('menu/getSingleRoleModuleMapping', data);
  }

  getRole() {
    return this.httpRoutingService.getMethod('role/getRole');
  }
  getRoleByCompany(data?) {
    return this.httpRoutingService.postMethod('role/getRoleByCompany', data);
  }
  createRole(data?) {
    return this.httpRoutingService.postMethod('role/createRole', data);
  }
  deleteRole(data?) {
    return this.httpRoutingService.postMethod('role/deleteRole', data);
  }
  updateRole(data?) {
    return this.httpRoutingService.postMethod('role/updateRole', data);
  }
  getLocationByUser() {
    return this.httpRoutingService.getMethod('location/getLocationListByUser');
  }
  setPageConfig() {
    // Used to get the config messages from the json file
    this.authService.storeIndustry.pipe(filter((res) => {
      // console.log('res of industry', res);
      this.storeIndustry = res;
      return res;
    }), mergeMap(() => {
      return this.httpRoutingService.getJsonData('assets/pageConfig.json');
    })).subscribe((res) => {
      // console.log('mergemap res',res);
      Object.keys(res).forEach((key: any) => {
        // console.log('key value',key);

        if (key === this.storeIndustry) {
          // console.log(key, this.storeIndustry, res[key]);
          const config = { config: res[key] };
          // console.log('config',config);

          this.pageConfig.next(config);
          // console.log(a);
        }
      });

    }, (err) => {
      // console.log('Error in setPageConfig service', err);
    });
  }
  getCompanyDetails() {
    return this.httpRoutingService.getMethod('company/getCompanyDetails');
  }
  getConfig() {
    return this.httpRoutingService.getMethod('company/getConfig');
  }
  getPageConfigurarion() {
    return this.httpRoutingService.getMethod('company/getPageConfiguration');
  }
  savePageConfigurarion(data) {
    return this.httpRoutingService.postMethod('company/savePageConfiguration', data);
  }
  updateConfig(data?) {
    return this.httpRoutingService.postMethod('company/updateConfig', data);
  }
  getCountryCode() {
    return this.httpRoutingService.getMethod('company/getCountryCode');
  }
  getTwilioPlugin() {
    return this.httpRoutingService.getMethod('plugins/getTwilioPlugin');
  }
  deleteTemplate(data?) {
    return this.httpRoutingService.postMethod('status/deleteTemplate', data);
  }
  getTagProducts(data?) {
    return this.httpRoutingService.postMethod('collection/getTagProducts', data);
  }
  deleteTagProduct(data?) {
    return this.httpRoutingService.postMethod('collection/deleteTagProduct', data);
  }
  exportData(data) {
    if (data === 'general-settings') {
      return this.httpRoutingService.getMethod('company/exportWebsiteSetting');
    } else if (data === 'location') {
      return this.httpRoutingService.getMethod('location/exportLocation');
    }
  }
  getInventoryList() {
    return this.httpRoutingService.getMethod('location/getInventoryList');
  }
  getTheme() {
    return this.httpRoutingService.getMethod('company/getAllThemes');
  }
  getThemeDetails(data) {
    return this.httpRoutingService.postMethod('company/getThemeDetails', data);
  }
  createTheme(data) {
    return this.httpRoutingService.postMethod('company/createTheme', data);
  }
  updateTheme(data) {
    return this.httpRoutingService.postMethod('company/updateTheme', data);
  }
  getIsMultiLocation() {
    return this.httpRoutingService.getMethod('company/getIsMultiLocation');
  }
  /**
   * Method used to check wether the domain gets activated or not. 
   * A copy of checkActivationStatus fn
   * This method in this service is only to cover asyncDomainNameValidation in customAsyncValidator, it is not been used by actual domain configuration component. 
   * That actual method is present in settings.service which is in storeadmin
   * @param storeId has storeId.
   * @param data has request data.
   * @param id has id.
   * @returns response.
   */
  checkActivationStatus(storeId, data, id?) {
    let url;
    if (id)
      url = this.httpRoutingService.replaceUrlParam(API.CHECK_ACTIVATION_STATUS, { storeId: storeId, id: id });
    else
      url = this.httpRoutingService.replaceUrlParam(API.CHECK_DOMAIN_ALREADY_EXISTS, { storeId: storeId });
    return this.httpRoutingService.getMethod(url, {
      url: data
    });
  }
}
