import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'convertLanguage',
  standalone: true,
  pure: false
})
export class MultiLanguagePipe implements PipeTransform {
  transform(value: any, language: string): string {
    const defaultLanguage = 'en';
    if (value && typeof (value) === 'object') {
      return (language && value[language]) ? `${value[language]}` : value[defaultLanguage] ? `${value[defaultLanguage]}` : ''
    }
    else {
      return value ? value : '';
    }
  }
}
