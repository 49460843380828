/**
 * Component used to display discounts.
 * AUTHOR: SUJIN S S (CEN250)
 */
import { Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { CommonService } from 'libs/shared/src/lib/services/common.service';
import { CommonDataService, FILE_EXTENSION } from '@phase-ii/shared';
import { MatDialog } from '@angular/material/dialog';
import { UntypedFormControl } from '@angular/forms';
import { ThemesService } from 'libs/shared/src/lib/services/themes.service';
import { DialogService, LoadTranslationService } from '@phase-ii/common';
import { ThemesModuleConstants } from 'libs/themes/shared-theme/src/lib/constants/shared-theme-constants';
import { SharedService } from '@phase-ii/shared-theme';
import { AuthService } from '@phase-ii/auth';

/**
 * Component used to display discounts
 */
@Component({
  selector: 'phase-ii-cart-discounts',
  templateUrl: './cart-discounts.component.html',
  styleUrls: ['./cart-discounts.component.scss']
})
export class CartDiscountsComponent extends ThemesModuleConstants implements OnInit {
   /**
   * Variable used to store the image extension
   */
   imageExtension = FILE_EXTENSION;
  /**
   * Variable which used to store the details about cart summary data.
   */
  @Input() details: any;
  /**
   * Variable which used to store the destination details
   */
  @Input() destination: any;
  /**
   * Variable which hold the cart details of particular user
   * @type{any}
   */
  @Input() cartDetails: any;
  /**
   * Variable used to emit the applied discount details.
   * @type {EventEmitter}
   */
  @Output() onDiscountApplied = new EventEmitter<any>();
  /**
   * Variable used to store cart products
   */
  discountDetails: any
  /**
    * Variable used to store filtered discount list
   */
  discountList: any;
  /**
   * Variable used to store product based discount list
   */
  temporaryDiscountList: any = [];
  /**
   * Variable used to store applied discounts from the discount list
   */
  appliedDiscounts: any = [];
  /** 
   * subscription object
   */
  subscriptionObject: Subscription = new Subscription();
  /**
   * variable used to bind image.
   * @type {string}
   */
  awsUrl!: string;
  /**
   * variable used to store buyXgetY discount details.
   */
  productDiscountDetails: any;
  /**
   * variable used to check whether the user is guest or not.
   */
  isGuestUser!: boolean;
  /**
   * Variable which is used to store the selected storeid.
   */
  storeId!: number;
  /**
   * Variable which is used to store the selected category products.
   */
  categoryProducts: any = [];
  /**
   * Variable used to access the category products template.
   */
  @ViewChild('selectProduct', { static: false }) selectProduct: TemplateRef<any>;
  /**
   * Variable which is used to select free product from a category.
   */
  product!: UntypedFormControl;
  /**
   * Variable which is used to check whether to display loader or not.
   */
  isLoader!: boolean;

  /**
    * component constructor which is used to inject the required services.
    * @param sharedService To Access methods and properties from shared Service.
    * @param commonService To access the methods inside CommonService.
    * @param commonDataService To access the methods inside CommonDataService.
    * @param authService To access the methods inside AuthService.
    * @param dialog To access MatDialog.
    * @param themeService To access methods inside ThemesService.
    * @param dialogService To access methods inside DialogService.
    */
  constructor(
    private sharedService: SharedService,
    private commonService: CommonService,
    private commonDataService: CommonDataService,
    private authService: AuthService,
    private dialog: MatDialog,
    private themeService: ThemesService,
    private dialogService: DialogService,
    private translationService: LoadTranslationService,
  ) {
    super();
  }

  /**
   * Component onInit life cycle hook.
   */
  ngOnInit(): void {
    this.awsUrl = this.commonService.environment.AWSImageUrl;
    this.authService.user.subscribe(res => {
      if (res) {
        this.isGuestUser = false;
        this.storeId = res.storeId;
      }
      else {
        this.isGuestUser = true;
      }
    });
    if (this.destination === 'CART') {
      this.appliedDiscounts = sessionStorage.getItem('appliedDiscounts') ? JSON.parse(sessionStorage.getItem('appliedDiscounts')) : [];
      //   this.onDiscountApplied.emit(this.appliedDiscounts ? this.appliedDiscounts : null);
    }
  }

  /**
   * A lifecycle hook to detect the changes in input bound
   * @param changes To have the previous data and currently changed data
   */
  ngOnChanges(changes: any): void {
    this.discountList = [];
    if (changes && changes.cartDetails && changes.cartDetails.currentValue && changes.cartDetails.currentValue['cartProducts']) {
      this.discountDetails = changes.cartDetails.currentValue['cartProducts'];
      if (this.isGuestUser || this.cartDetails.isManualOrder) {
        this.sharedService.updateGuest(this.discountDetails);
      }
      if (this.destination === 'CART' && !changes.cartDetails.firstChange) {
        this.appliedDiscounts = sessionStorage.getItem('appliedDiscounts') ? JSON.parse(sessionStorage.getItem('appliedDiscounts')) : [];
        if (this.appliedDiscounts && this.appliedDiscounts.length) {
          this.sharedService.listAfterDeletion(this.discountDetails, this.appliedDiscounts);
        }
      }
    }
    let tempArray = [], buyXgetYArray = [], tempBuyXgetYArray = [];
    this.discountDetails && this.discountDetails.map((res1: any) => {
      if (res1 && res1['discountList'] && res1['discountList'].length) {
        res1['discountList'].forEach((res2: any) => {
          if (res2 && tempArray) {
            tempArray.push(res2);
          }
        });
      }
      if (res1 && res1['buyXgetYDiscounts'] && res1['buyXgetYDiscounts'].length) {
        res1['buyXgetYDiscounts'].forEach(res => {
          if (res) {
            tempBuyXgetYArray.push(res)
          }
        });
      }
    });
    tempArray.forEach((response: any) => {
      if (this.temporaryDiscountList && response && response.appliesToValue && response.appliesToValue.code && response.appliesToValue.code === "PRODUCT") {
        this.temporaryDiscountList.push(response);
      }
      else {
        this.discountList = tempArray && tempArray.filter((value: any, index: number, total: any) =>
          total && index === total.findIndex((singleObject: any) => {
            if (value && value.id && value.brandId && value.appliesToValue && value.appliesToValue.code && value.appliesToValue.code === "BRAND" && singleObject && singleObject.brandId && singleObject.id) {
              return singleObject.id === value.id && singleObject.brandId === value.brandId;
            }
            else if (value && value.id && value.categoryId && value.appliesToValue && value.appliesToValue.code && value.appliesToValue.code === "CATEGORY" && singleObject && singleObject.categoryId && singleObject.id) {
              return singleObject.id === value.id && singleObject.categoryId === value.categoryId;
            }
            else {
              return false;
            }
          })
        );
      }
    });
    buyXgetYArray = tempBuyXgetYArray && tempBuyXgetYArray.filter((value, index, total) => total && index === total.findIndex((singleObject) => singleObject && value && singleObject.discountId === value.discountId));
    this.discountList = [...buyXgetYArray, ...this.discountList, ...this.temporaryDiscountList];
    this.temporaryDiscountList = [];
    this.discountList && this.discountList.length && this.discountList.forEach((res: any) => {
      if (res && res.eligibleProductId && res.eligibleProductId.length) {
        const productNames = res.eligibleProductId.map((product: any) => product.productName);
        res['products'] = productNames.slice(0, res.eligibleProductId.length - 1).join(', ');
        res['lastProduct'] = productNames[res.eligibleProductId.length - 1];
      }
    });
  }
  /**
   * Method which is used to call when the user clicks the discount
   * @param details To hold the discount details
   */
  onAdd(details: any, discount: string): void {
    // console.log('dis', details);
    if (discount === 'automatic') {
      if (this.appliedDiscounts && this.appliedDiscounts.length) {
        let matchedIndex = this.appliedDiscounts.findIndex((e: any) => e && (e.discountId === details.id) && ((e.brandId && details.brandId && e.brandId === details.brandId) || (e.categoryId && details.categoryId && e.categoryId === details.categoryId)));
        if (matchedIndex !== -1) {
          for (let i of details.eligibleProductId) {
            this.appliedDiscounts[matchedIndex].productId.push(i.productId);
          }
        }
      }
      let productId = [];
      details && details.eligibleProductId && details.eligibleProductId.map((res: any) => {
        if (res) {
          res.productId && productId.push(res.productId);
        }
      });
      let discounts = { discountId: details && details.id, brandId: details && details.brandId, categoryId: details && details.categoryId, productId: productId };

      this.appliedDiscounts.push(discounts);
      if (this.destination === 'CART') {
        sessionStorage.setItem('appliedDiscounts', JSON.stringify(this.appliedDiscounts));
      }
      // this.onDiscountApplied.emit(this.appliedDiscounts ? this.appliedDiscounts : null);
      this.onDiscountApplied.emit({ automatic: this.appliedDiscounts ? this.appliedDiscounts : null, buyXgetY: this.productDiscountDetails ? this.productDiscountDetails : null });
    }
    else {
      // console.log('details', details);
      if (details && details.isCategory && details.discountId && details.get && details.get.length && details.get[0] && details.get[0].id) {
        if (this.cartDetails.isManualOrder && this.storeId) {
          this.isLoader = true;
          this.subscriptionObject.add(this.themeService.getAllQuickOrderProducts(this.storeId, {
            locationId: this.cartDetails.locationId,
            customerId: this.cartDetails.customerId,
            categoryId: details.get[0].id,
            orders: true
          }).subscribe((res) => {
            if (res && res['productData'] && res['productData'].length) {
              this.categoryProducts = res['productData'];
            } else {
              this.categoryProducts = [];
            }
            this.isLoader = false;
            this.product = new UntypedFormControl(null);
            sessionStorage.setItem('discountId', JSON.stringify(details.discountId));
            this.dialog.open(this.selectProduct, {
              disableClose: true,
              autoFocus: false,
              width: '45%'
            });
          }, err => {
            this.isLoader = false;
            this.dialogService.dialogMethod(this.dialogMessages.productFetchFailed, this.dialogType.failure, true)
          }));
        } else {
          if (this.destination === 'CART') {
            this.sharedService.navigateTo('products', null, { categoryId: details.get[0].id, filterDetails: null, discountId: this.commonDataService.setParam(details.discountId) });
          } else {
            this.sharedService.changedNewOrders.next(this.cartDetails.cartProducts);
            this.sharedService.navigateTo('products', null, { categoryId: details.get[0].id, filterDetails: null, discountId: this.commonDataService.setParam(details.discountId), orderId: this.cartDetails && this.cartDetails.cartProducts && this.cartDetails.cartProducts.length && this.cartDetails.cartProducts[0] && this.cartDetails.cartProducts[0].orderId });
          }
        }
      }
      else {
        if (details && details.discountId) {
          this.productDiscountDetails = { discountId: details.discountId, isCategory: false };
          // console.log(this.cartDetails);
        }
        this.onDiscountApplied.emit({ automatic: this.appliedDiscounts ? this.appliedDiscounts : null, buyXgetY: this.productDiscountDetails ? this.productDiscountDetails : null });
      }
    }
  }
  /**
   * Method which is called when category based discount is selected for manual order.
   * @param event To know which button is selected.
   */
  onCloseDialog(event?: any): void {
    if (event) {
      this.productDiscountDetails = {
        name: this.product.value.product.name,
        variantId: this.product.value.id,
        discountId: JSON.parse(sessionStorage.getItem('discountId')),
        isCategory: true
      };
      this.onDiscountApplied.emit({ automatic: this.appliedDiscounts ? this.appliedDiscounts : null, buyXgetY: this.productDiscountDetails ? this.productDiscountDetails : null });
    }
    sessionStorage.removeItem('discountId');
    this.dialog.closeAll();
  }

  /**
   * Method used to translate static messages with dynamic contents.
   * @param key contains constant object key.
   * @param values contains dynamic contents.
   * @returns translated text.
   */
  getTranslationContent(key: string, values: any): string {
    return this.translationService.dynamicMessageTranslation(key, values);
  }

  /**
  * Component onDestroy life cycle hook.
  * All subscriptions are unsubscribe here.
 */
  ngOnDestroy(): void {
    if (this.destination === 'CART') {
      this.appliedDiscounts = sessionStorage.getItem('appliedDiscounts') ? JSON.parse(sessionStorage.getItem('appliedDiscounts')) : [];
      if (this.appliedDiscounts && this.appliedDiscounts.length) {
        this.sharedService.listAfterDeletion(this.discountDetails, this.appliedDiscounts);
      }
    }
    this.subscriptionObject.unsubscribe();
  }
}
