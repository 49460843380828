import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { SharedConstant } from "libs/common/src/lib/constants/shared-constant";
import { DialogService } from "libs/common/src/lib/services/dialog.service";
import { SharedService } from "libs/themes/shared-theme/src/lib/services/shared.service";
import { Observable, throwError } from "rxjs";
import { catchError } from 'rxjs/operators';
import { AuthService } from "./auth.service";
import { HeaderService } from "./header.service";
/**
* Interceptor class is used to intercept the all request to the api.
*/
@Injectable()
/**
* Interceptor class is used to intercept the all request to the api.
*/
export class TokenInterceptor extends SharedConstant implements HttpInterceptor {
    /**
    * Constructor used to inject the required services.
    * @param headerService To get header from the header service.
    * @param router To navigate to specific url
    * @param authService To refresh the token 
    * @param route To get the data from route.
    */
    constructor(
        private headerService: HeaderService,
        private dialogService: DialogService,
        private router: Router,
        private authService: AuthService,
        private sharedService: SharedService,
        // private httpService: HttpRoutingService,
        // private route: ActivatedRoute,
        // @Inject('environment') environment
    ) {
        super();
        // this.environment = environment;
        // this.router.events.pipe(mergeMap(res => {
        //     if (res instanceof RoutesRecognized) {
        //         if (this.router.url) {
        //             const pathElements = this.router.url.split('/');
        //             if (pathElements[1] && pathElements[2]) {
        //                 this.industryName = pathElements[1];
        //                 this.storeName = pathElements[2];

        //             }
        //         }
        //     }
        //     return this.refreshTokenChanges;
        // })).subscribe(res => {
        //     if (!res) {
        //         if (this.industryName && this.storeName) {
        //             this.router.navigate([this.industryName, this.storeName]);
        //         }
        //         else {
        //             this.router.navigate(['/']);
        //         }
        //     }
        // });
    }




    /**
    * Method which is used to intercept the request.
    * @param request Which is used to define the request of the url.
    * @param next which is used to define the request passed for further processing.
    */
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // And passed the request to the next handler and process the returned response.

        return next.handle(this.setHeaders(request)).pipe(catchError((err) => {
            // console.log("server...1.", err);
            if (err instanceof HttpErrorResponse && err.status === 401 &&  !(err.url).includes("api.rollbar.com")) {
                this.authService.logout();
                if (this.sharedService.isStorefront) {
                    this.sharedService.navigateTo('home');
                }
                else {
                    // this.dialogService.dialogMethod(this.decideErrorMessage(err), this.dialogType.failure, true);
                    this.router.navigate(['/signin']);
                }
                // If 401 error returned ,then we handle it.
                // return this.handle401Error(request, next);
            }
            else if (err instanceof HttpErrorResponse && err.status !== 406 &&
                err.status !== 422 && err.status !== 408 && err.status !== 400 && err.status !== 403 &&  !(err.url).includes("api.rollbar.com")) {
                // this.decideErrorMessage(err);
                this.dialogService.dialogMethod(this.decideErrorMessage(err), this.dialogType.failure, true);

            }
            else if (err instanceof HttpErrorResponse && err.status === 406 &&  !(err.url).includes("api.rollbar.com")) {
                if (this.sharedService.isStorefront) {
                    this.sharedService.navigateTo('home');
                }
            }
            return throwError(err);
        }));
    }
    /**
     * this method is used to return error message .
     * @param errorResponse error response
     * @returns 
     */
    decideErrorMessage(errorResponse) {
        if (errorResponse.statusText === 'Unknown Error') {
            return 'Something went wrong. Please check your network connection.';
        } else if (errorResponse.statusText === 'Unauthorized') {
            return 'Unauthorized to access the service.Please sign in again.';
        } else if (errorResponse.statusText === 'Not Found') {
            return 'No service is provided by the server.';
        } else if (errorResponse.statusText === 'Unprocessable Entity') {
            return 'Please check your network connection.';
        } else if (errorResponse.statusText === 'Internal Server Error') {
            return 'Something has gone wrong with the website.';
        }
        else{
            return 'Something went wrong. Please check your network connection.';
        }
    }
    // /**
    // * Method used to handle Error of the token
    // * @param request Which is used to define the request of the url..
    // * @param next as the httphandler.
    // */
    //private handle401Error(request: HttpRequest<any>, next: HttpHandler) {
    //     if (!this.isRefreshingToken) {
    //         // Set to true when token refreshing start.
    //         this.isRefreshingToken = true;
    //         // Set the refreshTokenSubject to null so that subsequent API calls will wait until the new token has been retrieved
    //         this.refreshTokenSubject.next(null);
    //         // To refersh the jwt_token
    //         return this.authService.refreshToken().pipe(
    //             switchMap((token: any) => {
    //                 this.isRefreshingToken = false;
    //                 this.refreshTokenChanges.next(true);
    //                 // To emit the token value to releasing the blocked request.
    //                 // Set the authorization header and update the local storage with newly generated jwt-token.
    //                 this.headerService.setHeaders('default', 'Authorization', token['accessToken']['accessToken']);
    //                 localStorage.setItem('jwt_token', token['accessToken']['accessToken']);
    //                 this.refreshTokenSubject.next(token['accessToken']['accessToken']);
    //                 // Again send the request for the failed request
    //                 return next.handle(this.setHeaders(request));
    //             }), catchError((err: any) => {
    //                 this.isRefreshingToken = false;
    //                 this.refreshTokenChanges.next(false);
    //                 // If refershtoken is not valid then logout the application.
    //                 // console.log("server...2.", err);
    //                 return throwError(err);
    //             }));
    //     } else {
    //         // when the new token is ready and we can retry the request again.
    //         return this.refreshTokenSubject.pipe(filter(token => token !== null),
    //             take(1),
    //             switchMap(() => next.handle(this.setHeaders(request))));
    //     }
    // }
    /**
    * Method which is used to set the headers for the request.
    * @param request Which is used to define the request of the url.
    */
    setHeaders(request: HttpRequest<any>) {
        // To get the headers for the given url
        // let isLamda;
        // this.httpService.isLamda.subscribe((res) => {
        //     isLamda = res;
        //     if (this.environment.lambdaKey && isLamda) {
        //         this.headerService.setHeaders(request.url, 'x-api-key', this.environment.lambdaKey);
        // this.headerService.setHeaders(request.url, 'Authorization', localStorage.getItem('jwt_token'));
        //     }
        // })
        // console.log(request.url, this.environment.lambdaUrlDb);
        // if (request.url.includes(this.environment.lambdaUrlDb)) {
        // let path = request.url.split("/");
        // console.log(path, request.method);
        // if (path.length > 6 || (path.length === 6 && (path[5] !== 'plan' || request.method !== 'GET'))) {
        // console.log('inside', path);
        // this.headerService.setHeaders(request.url, 'lambdaAuthorization', localStorage.getItem('lambda_jwt_token'));
        // this.headerService.setHeaders(request.url, 'applicationid', 'ZENBASKET_PHASE_2');
        // }
        // this.headerService.setHeaders(request.url, 'x-api-key', this.environment.lambdaKey);
        // }
        const headers = request.url?.includes('i18n') ? null : this.headerService.getHeaders(request.url);
        // Add the headers to the cloned request and return it.
        // console.log('token', request)
        return headers ? request.clone({
            setHeaders: headers
        }) : request;
    }


}