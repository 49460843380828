/**
 *Interface which is used to store retail contact us map section details
 */
export interface ContactUsDetails {
    /**
     * Variable used to display with or without background image
     * @type {boolean}
     */
    showBackgroundImage: boolean;
    /**
     * Variable used to store background image.
     * @type {string}
     */
    backgroundImage: string
    /**
     * Variable used to store the heading of the location section
     * @type {string}
     */
    locationHeading: string;
    /**
     * Variable used to store the social media icons and its links
     * @type {Array}
     */
    socialMedia: Array<SocialIcons>;
    /**
     * Variable used to store the instagram link
     * @type {string}
     */
    // instagramLink: string;
    /**
     * Variable used to show or hide the contact page navigation link
     * @type {boolean}
     */
    showContactNavigation: boolean;
    /**
     * Variable used to store the contact page link
     * @type {string}
     */
    contactLink?: string;
    /**
     * Variable used to show or hide the filter section
     * @type {boolean}
     */
    showFilter: boolean;
    /**
     * Variable used to display or hide the location button.
     * @type {boolean}
     */
    displayLocationButton: boolean;
    /**
     * Variable used to store the location button text.
     * @type {string}
     */
    locationButtonText?: string;
    /**
     * Variable used to store the store details label
     * @type {string}
     */
    storeDetailsLabel: string;
    /**
     * Variable used to display or hide the map.
     * @type {boolean}
     */
    showMap: boolean;
    /**
     * Variable which is used to store the zoom level of the map.
     * @type {number}
     */
    zoom: number;
    /**
     * Variable which is used to store the layout type of the locations either in column or row.
     * @type {string}
     */
    locationLayoutType: string;
}
/**
 *Interface which is used to store social media icons and links
 */
export interface SocialIcons {
    /**
     * Variable which is used to store the social media icon
     * @type {string}
     */
    id: string;
    /**
     * Variable which is used to store the social media link
     * @type {string}
     */
    link: string;
}
export interface SubscriptionType {
    id: number,
    subscriptionName: string,
    icon: string
}

export interface SubscriptionPeriod {
    id: number,
    value: string
}

export interface PaymentType {
    id: number,
    value: string
}
/**
 * Interface used to store the additional product details
 */
export interface AdditionalProductDetails {
    /**
     * Variable to store additional info section
     */
    isVisible: boolean;
    /**
     * Variable used to store the product description tab heading
     * @type {string}
     */
    productDescriptionTabText: string;
    /**
     * Variable used to store the review tab heading
     * @type {string}
     */
    reviewTabText: string;
    /**
     * Variable used to store the details tab heading
     * @type {string}
     */
    specificationTabText: string;
    /**
     * Variable used to store the video tab heading
     * @type {string}
     */
    videoTabText: string;
    /**
     * Variable used to store the faq tab heading
     * @type {string}
     */
    faqTabText: string;
    /**
     * Variable to store whether product description tab is needed.
     */
    showProductDescriptionTab: boolean,
    /**
     * Variable to store whether review tab is needed.
     */
    showReviewTab: boolean,
    /**
     * Variable to store whether specification tab is needed.
     */
    showSpecificationTab: boolean,
    /**
     * Variable to store whether Video tab is needed.
     */
    showVideoTab: boolean,
    /**
    * Variable to store whether Faq tab is needed.
    */
    showFaqTab: boolean,

}
/**
 * Interface used to store the related product details
 */
export interface RelatedProductDetails {
    /**
     * Variable used to store the add to bag button text
     * @type {string}
     */
    addToBagButtonText: string;
    /**
     * Variable used to store the upsell products heading
     * @type {string}
     */
    upsellHeading: string;
    /**
     * Variable used to store the cross sell products heading
     * @type {string}
     */
    crosssellHeading: string;
    /**
     * Variable used to store the frequently bought products heading
     * @type {string}
     */
    frequentlyBoughtHeading: string;
}
/**
* Interface used to store the upsell, cross sell products type
*/
export interface UpsellCrosssellProducts {
    /**
     * Variable used to store the section name
     */
    section: 'upsell' | 'crosssell' | 'boughtTogether';
    /**
     * Variable used to store the title to be displayed for the section
     * @type {string}
     */
    title: string;
    /**
     * Variable used to store the products array in the section
     * @type {Array}
     */
    products: Array<any>;
}
/**
 * Interface used to store the testimonials of a company
 */
export interface TestimonialSection {
    /**
     * Variable used to store the section title
     * @type {string}
     */
    sectionTitle: string;
    /**
     * Variable used to show or hide the customer name
     * @type {boolean}
     */
    showCustomerName?: boolean;
    /**
     * Variable used to show or hide the customer image
     * @type {boolean}
     */
    showCustomerImage?: boolean;
}
/**
* Interface used to store the customer testimonials
*/
export interface CustomerTestimonial {
    /**
     * Variable used to store the name of the customer
     * @type {string}
     */
    author: string;
    /**
     * Variable used to store the image of the customer
     * @type {string}
     */
    imageUrl?: string;
    /**
     * Variable used to store the review of the customer
     * @type {string}
     */
    content: string;
}
/**
 * Interface which is used to define the properties of Slider.
 */
export interface SliderContent {
    /**
     * Variable which is used to store the start index of the slide.
     * @type{number}
     */
    index: number;
    /**
     * Variable which is used to length of the slide.
     * @type{number}
     */
    length: number;
    /**
     * Variable which is used to store the name of the class based on the sliding direction.
     * @type{string}
     */
    class: string;
    /**
     * Variable which is used to store the content of the slide.
     * @type{array}
     */
    value: Array<object>;
}
/**
 * Interface used to store the team members data of a company
 */
export interface TeamData {
    /**
     * Variable used to store the section title
     * @type {string}
     */
    sectionTitle: string;
    /**
     * Variable used to store the quote about a company
     * @type {string}
     */
    retailQuote: string;
    /**
     * Varaible used to store the content about the team
     * @type {string}
     */
    teamContent: string;
    /**
     * Variable used to show or hide the profession of team members
     * @type {boolean}
     */
    showProfession?: boolean;
    /**
     * Variable used to show or hide the image of team members
     * @type {boolean}
     */
    showImage?: boolean;
    /**
     * Varaible used to store the team members details
     * @type {Array}
     */
    teamDetails: Array<TeamMember>;
}
/**
 * Interface used to store the single team member detail
 */
export interface TeamMember {
    /**
     * Variable used to store the name of a team member
     * @type {string}
     */
    name: string;
    /**
     * Variable used to store the profession of a team member
     * @type {string}
     */
    profession: string;
    /**
     * Variable used to store the image of a team member
     * @type {string}
     */
    img: string;
    /**
     * Variable used to store the about content of a team member
     * @type {string}
     */
    about: string;
}
/**
 * Interface which is used to store functions used in storefront.
 */
export interface StorefrontFunctionNames {
    /**
     * Method used to call other function to retrieve details.
     * @param functionName To store the name of the function to be called.
     * @param data To store component's instance.
     * @param details To store the published page details.
     */
    getRequiredData(functionName, data, details?);
    /**
     * Method used to call other function to based on emitted data.
     * @param functionName To store the name of the function to be called.
     * @param event To store the emitted data.
     * @param data To store component's instance.
     * @param details To store the published page details.
     */
    emittedData(functionName, event, data, details);
    /**
     * Method used to get featured categories based on location id.
     * @param data To store the component's values.
     */
    getAllFeaturedCategories(data);
    /**
     * Method used to get all active location of a store.
     * @param data To store the component's values.
     */
    getAllLocation(data);
    /**
     * Method used to get testimonial details of a store.
     * @param data To store the component's values.
     */
    getTestimonials(data);
    /**
     * Method used to get blog details of a store.
     * @param data To store the component's values.
     */
    getAllBlog(data);
    /**
     * Method which is used to navigate to the pages.
     * @param data To store the component's values.
     */
    pageToNavigate(data);
    /**
     * Method used to set working days for location.
     * @param data To store the component's values.
     */
    setWorkingDays(data);
    /**
     * Method which is used to display working hours and day.
     * @param workingTime contains working time value.
     * @param weekDays TO store the names of week days.
     */
    formatWorkingDays(workingTime, weekDays);
    /**
     * Method which is used get locations based on search value.
     * @param event which holds the search value to get locations.
     * @param data which holds the data values to search.
     */
    searchValue(event, data);
    /**
     * Method which is used search based on city or location.
     * @param event has the event to display city or location.
     */
    searchBy(event);
    /**
     * Method used to get featured products based on location id.
     * @param data To store the component's values.
     */
    getAllFeaturedProducts(data);
    /**
     * Method used to get top rated products based on location id.
     * @param data To store the component's values.
     */
    getTopRatedProducts(data);
    /**
     * Method used to get best seller products based on location id.
     * @param data To store the component's values.
     */
    getBestSellerProducts(data);
    /**
     * Method used to get all featured products.
     * @param data To store the component's values.
     */
    getFeaturedProducts(data);
    /**
     * Method used to retrieve products based on selected tag index..
     * @param data To store the component's values.
     * @param details To store the tag details.
     */
    getTagProductDetails(data, details);
    /**
     * Method used to get products based on categories for coffee theme.
     * @param data To store the component's values.
     * @param details To store the published page details.
     */
    getCategoryforCoffee(data, details);
    /**
     * Method used to get all categories.
     * @param data To store the component's values.
     * @param details To store the published page details.
     */
    getCategories(data, details);
    /**
     * Method used to get the products based on category
     * @param isNew defines the data displayed from the paginator is a new set or not.
     * @param data To store the component's values.
     * @param details To store the published page details.
     * @param selectedCategory To store the selected category id and name.
     * @param isAllCategory To store the whether selected category is all categories or not.
     * @param searchText To store search text value.
     * @param filterCondition To store filter condition.
     * @param foodTypeEvent To store the preiously selected food type such as veg/non veg.
     * @param currentFoodType To store the current food type such as veg/non veg.
     * @param offset defines the value of the offset when the page per items count changes and page changes.
     * @param limit defines the value of the limit when the page per items count changes and page changes.
     */
    getProductsByCategory(details, data, selectedCategory, isAllCategory, searchText, filterCondition, foodTypeEvent, currentFoodType, offset?, limit?);
    /**
     * Method used to set search text and get products based on search text.
     * @param event has the value of search string.
     * @param data To store the component's values.
     * @param details To store the published page details.
     */
    searchTextEmit(event, data, details);
    /**
     * Method which is emitted from child component to give filtered array.
     * @param event defines the filtered values of filter array.
     * @param data To store the component's values.
     * @param details To store the published page details.
     */
    filterAction(event, data, details);
    /**
     * Method used to load more product list.
     * @param event defines the pagination details.
     * @param data To store the component's values.
     * @param details To store the published page details.
     */
    onDataEmit(event, data, details);
    /**
     * Method used to add product to cart from product list page.
     * @param event defines the cart product details.
     * @param data To store the component's values.
     */
    addToCartProduct(event, data);
    /**
     * Method used to return default food categories.
     */
    getFoodCategories();
    /**
     * Method used to retrieve food category.
     * @param event defines the selected food category.
     * @param data To store the component's values.
     * @param details To store the published page details.
     */
    getFoodCategory(event, data, details);
    /**
     * Method used to calculate the product inclusive tax.
     * @param productArray To store the product list.
     * @param zones To store the zone details
     * @param data To store the component's values.
     */
    productTaxCalculation(productArray, zones, data);
    /**
    * Method used to get website settings details.
    */
    getWebsiteSettings();
    /**
     * Method used to get product order details.
     */
    getProductOrders();
    /**
     * Method used to add product in waitlist.
     * @param event defines the product detail.
     * @param data To store the component's values.
     */
    addToWaitList(event, data);
    /**
     * Method used to add/remove product to/from customer's wishlist.
     * @param event defines the product detail.
     * @param data To store the component's values.
     */
    addRemoveFromWishlist(event, data);
    /**
     * Method used to get brand and variant filter data based on selected category.
     * @param data To store the component's values.
     * @param details To store the published page details.
     */
    getFilterDetails(data: any, details);
    /**
     * Method used to get the selected category products from parent component except retail theme.
     * @param event defines the value of the selected category details.
     * @param data To store the component's values.
     * @param details To store the published page details.
     */
    selectedCategoryDetails(event, data, details);
    /**
     * Method used to get the selected category products from parent component for retail theme.
     * @param event defines the value of the selected category details.
     * @param data To store the component's values.
     * @param details To store the published page details.
     */
    selectedCategoryEmit(event, data, details);
    /**
     * Method used to get all the options for filter.
     * @param selectedCategory To store the selected category.
     */
    getVariantOptions(selectedCategory);
    /**
     * Method used to get all the brands for filter.
     *  @param selectedCategory To store the selected category.
     */
    getBrands(selectedCategory);
    /**
     * Method used to get the product details.
     * @param data holds the component details.
     */
    getOneProductDetails(data);
    /**
     * Method used to get the short and full name of the user.
     * @param tabSectionDetails holds the product review details
     * @param user holds the user details.
     */
    basicSetup(tabSectionDetails, user);
    /**
     * Method which is used to add a product to cart.
     * @param event contains the product details to be added to cart.
     * @param data holds the component details.
     */
    addToCart(event: any, data);
    /**
     * Method which is used to create a review.
     * @event To store the review data to be added.
     * @param data holds the component details.
     */
    createReview(event, data);
    /**
     * Method used to like some review.
     * @param reviewId holds the review id.
     * @param data holds the component details.
     */
    likeClicked(reviewId, data);
    /**
     * Method used to dislike some review.
     * @param reviewId defines the review id.
     * @param data holds the component value.
     */
    dislikeClicked(reviewId, data);
    /**
     * Method used to get the cart products.
     * @param data holds the component details.
     */
    getCartProducts(data);
    /**
     * Method used to set data for coffee product summary.
     * @param data which holds the component details.
     * @param details To store the published page details.
     * @returns product category response.
     */
    getAllSubCategories(data, details);
    /**
     * Method used to get the selected category id.
     * @param data To store the component's values.
     */
    getSelectedSubCategoryId(data);
    /**
     * Method used to add products to cart for coffee summary.
     * @param event holds quantity details
     * @param data holds the component details.
     * @param details To store the published page details.
     * @returns response of quantity changes 
     */
    addToCartCoffee(event, data, details);
    /**
     * Method used to Open snack Bar.
     * @param message message to display in snack bar.
     */
    openSnackBar(message);
    /**
   * Method used to return selected category details.
   * @param event which holds category details
   * @param data holds the component details.
   * @returns current category response.
   */
    selectCategory(event, data);
    /**
     * Method used to get filter array.
     * @param data holds the component details.
     * @param details To store the published page details.
     */
    getFilterArray(data, details);
    /**
     * Method used to get whether the category should display or not.
     * @param data holds the component details.
     * @param details To store the published page details.
     */
    getCategoryDisplay(data, details);
    /**
     * Method used to get width of the window.
     */
    getInnerWidth();
    /**
     * Method used to return current location details.
     * @param data holds the component details.
     * @param details To store the published page details.
     */
    getCurrentLocation(data: any, details: any);
    /**
     * Method used to reset search text and get products.
     * @param event holds the search text.
     * @param data holds the component details.
     */
    getClearSearchProducts(event: any, data: any)
    /**
     * Method used to change filter data currency symbol.
     * @param data holds the component details.
     * @param details To store the published page details.
     */
    getCurrentFilterData(data: any, details: any)
    /**
     * Method used to get all sales countdown timer discount details.
     * @param data holds the component details.
     */
    getAllSalesCountdownTimerDiscounts(data: any)
}

export interface FbtResponseModel{
    /**
     * Variable used to get status code for response.
     * @type {string}
     */
    code: string
    /**
     * Variable used to get message for response.
     * @type {string}
     */
    message: string
    /**
     * Variable used check response is success or not.
     * @type {boolean}
     */
    success: boolean
    /**
     * Variable used to get fbt response.
     * @type {FbtProductsModel}
     */
    fbtResponse: FbtProductsModel
}

export interface FbtResolveModel{
    getFrequentlyBoughtTogether: FbtProductsModel
}

export interface FbtProductsModel{
    /**
     * Variable used to get fbt id
     * @type {number}
     */
    id?: number
    /**
     * Variable used to check the fbt is active or not.
     * @type {boolean}
     */
    isActive: boolean
    /**
     * Variable used to get fbt products.
     * @type {FbtProductMappingModel}
     */
    fbtProductMappings?: Array<FbtProductMappingModel>
}

interface FbtProductMappingModel{
    /**
     * Variable used to get combination name of the product
     * @type {string}
     */
    combinationName: string
    /**
     * Variable used to get cost compare price of the product.
     * @type {number}
     */
    costComparePrice: number
    /**
     * Variable used to get fbt discount type of the product.
     * @type {string}
     */
    discountType?: string
    /**
     * Variable used to get fbt discount value.
     * @type {number}
     */
    discountValue?: number
    /**
     * Variable used to get fbt id.
     * @type {number}
     */
    fbtId: number
    /**
     * Variable used to get id of fbt product mapping entry
     * @type {number}
     */
    id: number
    /**
     * Variable used to get image url of the product.
     * @type {string}
     */
    image: string
    /**
     * Variable used to get minimum order quantity value for the product.
     * @type {number}
     */
    minimumOrderQuantity: number
    /**
     * Variable used to get product name.
     * @type {string}
     */
    name: string
    /**
     * Variable used to get product price.
     * @type {number}
     */
    price: number
    /**
     * Variable used to ger product id.
     * @type {number}
     */
    productId: number
    /**
     * Variable used to get stock of that product.
     * @type {number}
     */
    stock: number
    /**
     * Variable used to get storeId.
     * @type {number}
     */
    storeId: number
    /**
     * Variant used to get variant id of that product.
     * @type {number}
     */
    variantId: number
    /**
     * Variant used to get weight count of that product.
     * @type {number}
     */
    weightCount: number
    /**
     * Variant used to get weight unit of that product.
     * @type {string}
     */
    weightUnit: string
    /**
     * Variable used to get discount percentage for main product of fbt.
     * @type {number}
     */
    discountPercentage?: number
    /**
     * Variable used to differentiate the main product with fbt products.
     * @type {boolean}
     */
    isDefault?:boolean
}

export interface NonVariantProductValuesModel{
    /**
     * Variable used to ger variant id of non variant.
     * @type {number}
     */
    variantId: number
    /**
     * Variable used to get cost compare price of non variant product.
     * @type {number}
     */
    costComparePrice: number
    /**
     * Variable used to get price of non variant product.
     * @type {number}
     */
    price: number
    /**
     * Variable used to get stock count of non variant product.
     * @type {Array}
     */
    stockCount: Array <{stock: number}>
}