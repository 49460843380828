import { NgModule } from '@angular/core';
import { SharedModule } from '@phase-ii/shared';

import { CommonModule } from '@angular/common';
import { AddEditAccountDetailsComponent } from './components/add-edit-account-details/add-edit-account-details.component';
import { AccountDetailsListComponent } from './components/account-details-list/account-details-list.component';
import { CommonAdminModuleModule } from '@phase-ii/common-admin-module';



@NgModule({
  declarations: [AddEditAccountDetailsComponent, AccountDetailsListComponent],
  imports: [CommonModule, SharedModule,CommonAdminModuleModule],
})
export class AccountDetailsModule { }
