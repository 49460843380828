import { Directive, ElementRef, Input, Renderer2 } from '@angular/core';
import { AbstractControl, FormArray, FormControl, FormGroup } from '@angular/forms';
/**
 * This directive is used to disable the editor controls on language change.
 * Author : Silpa Sankar(CEN181)
 */
@Directive({
  selector: '[phaseIiDisableEditorControl]'
})
export class DisableEditorControlDirective {
  /**
   * Variable used to store the form control details.
   * @type {FormGroup}
   */
  @Input('phaseIiDisableEditorControl') formGroup: FormGroup;
  /**
   * Variable used to store the language code.
   * @type {string}
   */
  @Input() languageCode: string;
  /**
   * Component constructor is used to inject the required services.
   * @param element to access the dom elements.
   * @param renderer to access the dom and set styles.
   */
  constructor(private element: ElementRef, private renderer: Renderer2) { }
  /**
   * Component onInit life cycle hook.
   */
  ngOnInit() { }
  /**
   * Component ngAfterViewInit life cycle hook.
   */
  ngAfterViewInit() {
    if (!this.formGroup || this.languageCode === 'en') {
      return;
    }
    setTimeout(()=>{
      this.disableControls(this.formGroup);
    });
  }
  /**
   * Method used to get the dom elements based on form control name.
   * @param control holds the control details.
   */
  private disableControls(control: AbstractControl) {
    if (!control) {
      return;
    }
    if (control instanceof FormGroup) {
      if(control?.controls){
        Object.keys(control.controls).forEach(key => {
          if(control?.controls?.[key])
          this.disableControls(control.controls[key]);
        });
      }   
    } else if (control instanceof FormArray) {
      if(control?.controls?.length){
        control.controls.forEach(ctrl => {
          if(ctrl) {
            this.disableControls(ctrl)
          }
        });
      }
    } else if (control instanceof FormControl) {
      const controlName = this.getControlName(control);
      if (controlName) {
        const elements = this.element?.nativeElement?.querySelectorAll(`[formControlName="${controlName}"], [data-control="${controlName}"]`);
        if (elements && elements.length) {
          elements.forEach((element: HTMLElement) => {
            this.disableElement(element);
          });
        } else {
          const colorPickerElements = this.element?.nativeElement?.querySelectorAll('.color-picker-input');
          if (colorPickerElements && colorPickerElements.length) {
            colorPickerElements.forEach((element: HTMLElement) => {
              this.disableElement(element);
            });
          }
        }
      }
    }
  }
  /**
   * Method used to disable the controls except text.
   * @param element holds the dom element details.
   */
  private disableElement(element: HTMLElement) {
    const tagName = element && element.tagName ? element.tagName.toLowerCase() : null;
    if (tagName === 'input' || tagName === 'textarea') {
      const inputType = (element as HTMLInputElement)?.type;
      if (element?.classList?.contains('color-picker-input')) {
        this.renderer.addClass(element, 'disabled-control');
      } else if (inputType === 'number') {
        const parenLabel = element?.closest('mat-form-field');
        if(parenLabel) {
          this.renderer.addClass(parenLabel, 'disabled-control'); 
          const matSuffix = parenLabel.querySelector('span[matsuffix]');
          if (matSuffix) {
            this.renderer.setStyle(matSuffix, 'pointer-events', 'none');
          }
          const matLabel = parenLabel.querySelector('mat-label');
          if (matLabel) {
            this.renderer.setStyle(matLabel, 'pointer-events', 'none');
          }
          const label = parenLabel.querySelector('label');
          if (label) {
            this.renderer.setStyle(label, 'pointer-events', 'none');
          }
        } else {
          this.renderer.addClass(element, 'disabled-control');
        }
      } else if (inputType === 'text' || inputType === 'password' || inputType === 'email' || tagName == 'textarea') {
        return;
      } else if(inputType === 'checkbox' || inputType ==='radio') {
        this.renderer.addClass(element, 'disabled-control');
        const parentLabel = element?.closest('label');
        if (parentLabel) {
          this.renderer.addClass(parentLabel, 'disabled-control');
        }
        const inputId = element?.getAttribute('id');
        if (inputId) {
          const label = this.element?.nativeElement?.querySelector(`label[for="${inputId}"]`);
          if (label) {
            this.renderer.addClass(label, 'disabled-control');
          }
        }
      } else if(inputType === 'range'){
        const parentLabel = element?.closest('mat-slider');
        if(parentLabel){
          this.renderer.setStyle(parentLabel,'opacity',0.4);
          this.renderer.setStyle(parentLabel,'cursor','unset');
        }
        this.renderer.setStyle(element,'pointer-events','none');
      }else {
        this.renderer.addClass(element, 'disabled-control');
      }
    } else if (tagName === 'mat-select') {
      const parentFormField = element.closest('mat-form-field');

      if (parentFormField) {
        this.renderer.addClass(parentFormField, 'disabled-control');
        const matLabel = parentFormField.querySelector('mat-label');
        if (matLabel) {
          this.renderer.setStyle(matLabel, 'pointer-events', 'none');
        }
        const label = parentFormField.querySelector('label');
        if (label) {
          this.renderer.setStyle(label, 'pointer-events', 'none');
        }
      } else {
        this.renderer.addClass(element, 'disabled-control');
      }
    } else if(tagName === 'mat-slide-toggle'){
      this.renderer.addClass(element, 'disabled-control');
    }else if (tagName === 'mat-slider') {
      this.renderer.setStyle(element,'opacity',0.4);
      const inputElement = element.querySelector( 'input[matSliderThumb]' ) ;
      if (inputElement) {
        this.renderer.setStyle(inputElement, 'pointer-events', 'none');
      }
    } else if (element?.classList?.contains('color-picker-input')) {
      this.renderer.addClass(element, 'disabled-control');
    } else if(tagName === 'mat-slide-toggle' ){
      this.renderer.addClass(element, 'disabled-control');
    } else {
      this.renderer.addClass(element, 'disabled-control');
    }
  }
  /**
   * Method used to get the control name.
   * @param control holds control details.
   */
  private getControlName(control: AbstractControl): string | null {
    const formGroup = control && control.parent ? control.parent as FormGroup : null;
    if (!formGroup) return null;
    let controlName: string | null = null;
    if(formGroup?.controls) {
      Object.keys(formGroup.controls).forEach(name => {
        if (control === formGroup?.controls?.[name]) {
          controlName = name;
        }
      });
    }
    return controlName;
  }
}