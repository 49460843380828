import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UserListComponent } from './components/user-list/user-list.component';
import { AddEditUserComponent } from './components/add-edit-user/add-edit-user.component';
import { ViewCustomerListComponent } from './components/view-customer-list/view-customer-list.component';
import { SharedModule } from '@phase-ii/shared';
import { CoreModule } from '@phase-ii/common';
import { CommonAdminModuleModule } from '@phase-ii/common-admin-module';


@NgModule({
  declarations: [UserListComponent, AddEditUserComponent, ViewCustomerListComponent],
  imports: [CommonModule, SharedModule, CoreModule,CommonAdminModuleModule
  ],
})
export class UsersModule { }
