/**Author: Ponmani B */
import { Component, EventEmitter, Inject, Injector, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription, of } from 'rxjs';
import { filter, mergeMap } from 'rxjs/operators';
import { SharedService } from '@phase-ii/shared-theme';
import { BlogsService, CommonDataService,FILE_EXTENSION } from '@phase-ii/shared';
import { DomSanitizer } from '@angular/platform-browser';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { DialogService, LoadTranslationService, SharedConstant, dataConfig } from '@phase-ii/common';
import { BlogConstants } from '../../constants/blogConstants';
import { CommonService } from '../../services/common.service';
import { DatePipe } from '@angular/common';
// import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'phase-ii-blog-detail',
  templateUrl: './blog-detail.component.html',
  styleUrls: ['./blog-detail.component.scss'],
})
export class BlogDetailComponent {
  /**
* Variable used to store blog data.
*/
  @Input() blogDetails;
  /**
* Variable used to store blog comments.
*/
  @Input() blogComment;
  /**
   * Variable used to store the image extension
   */
  imageExtension = FILE_EXTENSION;
  /**
* Variable used to handle page is loaded or not.
*/
  isLoader;
  /**
  * isShowMore which is used to store the display info of list.
  * @type {boolean}
  */
  isShowMore: boolean;

  /**
* Variable used to store the constant variable.
* @type {SharedConstant}
*/
  sharedConstant

  /**
* Variable used to store the blog constant variable.
* @type {BlogConstant}
*/
  blogConstant

  /**
 * Variable which is used to get regex pattern for validation
 * @type {object}
 */
  pattern = dataConfig.patternValidators;
  /**
 * Variable which is used to store current blog id
 */
  @Input() blogId;
  /**
 * Variable used to store subscription data.
 */
  subscriptionObject: Subscription = new Subscription();
  /**
 * Variable used to store comment details.
 */
  commentDetails;
  /**
* Variable which is used for emitting the value
*/
  @Output() emittedValue = new EventEmitter();

  /**
* Variable used to store inner html content details.
*/
  htmlContent;

  /**
* Variable used to store command related data.
*/
  commantForm: UntypedFormGroup
  /**
* Variable used to store whether the page is loading from admin or storefront.
*/
  @Input() isPreview;
  /**
* Variable used to store store id value.
*/
  @Input() storeId;
  /**
   * Variable used to access methods from instance environment.
   */
  environment: any;
  /**
    * Variable used to store aws image url in environment file
    */
  awsImage: any;
  /**
   * Variable which holds the pipe methods.
   */
  datePipe: DatePipe;
  /**
* Variable which is used to store the DeactivateAccount panel is open or not.
* @type{any}
*/
  isCommentForm: boolean = false;
  /**
* component constructor which is used to inject the required services.
* @param blogService to access the blog servies methods
* @param sharedService To access methods from shared service
* @param commonDataService To access methods from commonDataService service.
* @param CommonService To access methods from Common service methods.
* @param route To access route data
*/
  constructor(
    private route: ActivatedRoute,
    private sharedService: SharedService,
    private dialogService: DialogService,
    private blogService: BlogsService,
    private sanitizer: DomSanitizer,
    private translationService: LoadTranslationService,
    private commonDataService: CommonDataService,
    private injector: Injector,
    private commonService : CommonService,
    @Inject('environment') environment

  ) {
    this.sharedConstant = new SharedConstant(),
      this.blogConstant = new BlogConstants();
    this.environment = environment;
    this.datePipe = injector.get<DatePipe>(DatePipe);
  }
  /**
* Angular life cycle hook that initiates the component.
*/
  ngOnInit(): void {
    this.awsImage = this.environment.AWSImageUrl;
    this.isLoader = true;
    this.isShowMore = false;
    if (this.blogDetails && this.blogDetails.content ) {
      const dateInstance = new Date(this.blogDetails.createdAt);
      if(!isNaN(dateInstance.getTime())){
        const dateString = this.datePipe.transform(dateInstance, 'MMM d, y');
        const getTranslationDate = this.commonService  && this.commonService.translateDate(dateString);
        this.blogDetails.createdAt = getTranslationDate ? getTranslationDate : dateString;
      }
      this.blogDetails.authorName = this.blogDetails.authorName && (this.blogDetails.authorName.charAt(0) && this.blogDetails.authorName.charAt(0).toUpperCase() + this.blogDetails.authorName.slice(1));
      this.blogDetails.content = this.blogDetails.content
        .replaceAll('</oembed>', '</iframe>')
        .replaceAll('<oembed url', '<iframe src')
        .replaceAll('youtu.be', 'www.youtube.com/embed')
        .replaceAll('www.youtube.com/watch?v=', 'www.youtube.com/embed/');
      this.htmlContent = this.sanitizer.bypassSecurityTrustHtml(this.blogDetails.content);
      if (this.blogDetails.blogCategory && (this.blogDetails.blogCategory.commentSettings === this.blogConstant.commentSettings.pending || this.blogDetails.blogCategory.commentSettings === this.blogConstant.commentSettings.autoPublished)) {
        this.commantForm = new UntypedFormGroup({
          name: new UntypedFormControl({ value: null, disabled: this.isPreview }, [Validators.required, Validators.maxLength(40), Validators.pattern(this.pattern.acceptOnlyCharacters)]),
          email: new UntypedFormControl({ value: null, disabled: this.isPreview }, [Validators.required, Validators.pattern(this.pattern.emailValidationPattern)]),
          comment: new UntypedFormControl({ value: null, disabled: this.isPreview }, [Validators.required, Validators.maxLength(255)]),
        })
      }
    }
    else if (this.blogDetails && this.blogDetails.code) {
      this.dialogService.dialogMethod(this.translationService.getTranslation('BLOG.BLOG_PAGE_UNAVAILABLE'),
        this.sharedConstant.dialogType.alert, true)
      this.sharedService.navigateTo('blogs');
    }
    if (!this.isPreview && (this.blogDetails?.blogCategory?.commentSettings === 'Pending' ||
      this.blogDetails?.blogCategory?.commentSettings === 'Auto Published')) {
      this.getAllCommentDetails();
    }
    else {
      this.isLoader = false;
    }
    if (this.commantForm) {
      this.commantForm.valueChanges.subscribe((res) => {
        this.emittedValue.emit({ 'commentFormPristine': this.commantForm.pristine })
      });
    }
  }

  /**
* Method used for get all comment details
*/
  getAllCommentDetails() {
    this.subscriptionObject.add(this.blogService.getAllCommentDetails(this.storeId, this.blogId).subscribe((res) => {
      if (res && res['response']) {
        this.commentDetails = res['response'];
        this.commentDetails = this.commentDetails.map((item) => {
          if (item && item['createdAt']) {
            item.createdAt = new Date(item.createdAt).toLocaleDateString('en-us', { year: "numeric", month: "short", day: "numeric" });
          }
          return item;
        })
        this.isLoader = false;
      }
    }))
  }

  /**
* Method used to navigate blog list page
*/
  backToList() {
    this.sharedService.navigateTo('blogs');
  }

  /**
* Method used for post comment for particular blog
*/
  postComment() {
    let data;
    this.isLoader = true;
    if (this.commantForm && this.commantForm.get('comment') && this.commantForm.get('comment').value && this.commantForm.valid) {
      data = { blogId: this.blogDetails && this.blogDetails.id, formData: this.commantForm && this.commantForm.value }
      this.subscriptionObject.add(this.blogService.postComment(this.storeId, data).subscribe((res) => {
        if (res && this.blogDetails && res['success']) {
          this.isLoader = false;
          this.commantForm.reset();
          if (this.blogDetails && this.blogDetails.blogCategory && this.blogDetails.blogCategory.commentSettings === 'Auto Published')
            this.dialogService.openSnackBar(this.translationService.getTranslation('BLOG.POST_COMMENT_SUCCESS'));
          else if (this.blogDetails && this.blogDetails.blogCategory && this.blogDetails.blogCategory.commentSettings === 'Pending')
            this.dialogService.openSnackBar(this.translationService.getTranslation('BLOG.PENDING_COMMENT_SUCCESS'));
          if (this.blogDetails && this.blogDetails.blogCategory && this.blogDetails.blogCategory.commentSettings === 'Auto Published') {
            this.getAllCommentDetails();
          }
        }
      }, (err) => {
        this.isLoader = false;
      }))
    }
    else {
      this.commantForm.markAllAsTouched();
      this.dialogService.dialogMethod(
        this.translationService.getTranslation('BLOG.MANDATORY_FIELD'),
        this.sharedConstant.dialogType.alert, true
      )
      this.isLoader = false;
    }
  }

  /**
   * Method used to deactivate the canDeactivate guard when the form us dirty.
   * @returns booleans based on the condition
   */
  canDeactivate() {
    return this.commantForm && this.commantForm.dirty ? false : true;
  }

  /**
   * Fucntion showMore is called when we click more data to show.
   */
  showMore() {
    this.isShowMore = !this.isShowMore;
  }

  /**
* Component onDestroy life cycle hook.
* All subscriptions are unsubscribe here.
*/
  ngOnDestroy() {
    if (this.subscriptionObject)
      this.subscriptionObject.unsubscribe();
  }

}
