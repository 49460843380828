/**
 * Interface which is used to define the properties of Slider.
 */
export interface SliderInputData {
  /**
   * Variable which is used to display the arrow icon.
   * @type{boolean}
   */
  isManual: boolean;
  /**
   * Variable which is used to display the arrow icon on hover.
   * @type{boolean}
   */
   isHover: boolean;
  /**
   * Variable which is used to store the time interval of the auto slides.
   * @type{number}
   */
  timeInterval: number;
  /**
   * Variable which is used to store the type of the icon.
   * @type{string}
   */
  iconType: string;
  /**
   * Variable which is used to store the size of the icon.
   * @type{string}
   */
  iconSize: string;
  /**
   * Variable which is used to store the color of the icon.
   * @type{string}
   */
  iconColor: string;
  /**
   * Variable which is used to store the size of the slide.
   * @type{number}
   */
  width: number;
}