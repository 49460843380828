<cen-loader-vii *ngIf="isLoader || invoiceLoader"></cen-loader-vii>
<div *ngIf="!isLoader">
    <phase-ii-header-page *ngIf="isSuperAdmin && !isMobileApp" [heading]="Constants?.memberHeading"
        [buttonInfo]="buttonInfoForSuperAdmin" (methodClicked)="functionTriggered($event)">
    </phase-ii-header-page>
    <phase-ii-header-page *ngIf="isSuperAdmin && isMobileApp" [heading]="Constants?.memberHeading"
        [buttonInfo]="buttonInfoForSuperAdmin1" (methodClicked)="functionTriggered($event)">
    </phase-ii-header-page>
    <phase-ii-header-page *ngIf="!isSuperAdmin && !isMobileApp" [heading]="Constants?.memberHeading"
        [buttonInfo]="buttonInfo" (methodClicked)="functionTriggered($event)">
    </phase-ii-header-page>
    <phase-ii-header-page *ngIf="!isSuperAdmin && isMobileApp" [heading]="Constants?.memberHeading"
        [buttonInfo]="buttonInfo1" (methodClicked)="functionTriggered($event)">
    </phase-ii-header-page>
</div>
<br />
<div class="page-container" *ngIf="!isMobileApp">
    <mat-card fxLayout="column" *ngIf="!isLoader" class="card-container" fxLayout="column" fxLayoutGap="3%"
        fxLayoutGap.lt-md="0">
        <div fxFlex="100%">
            <h3 class="heading-text">Active Plan </h3>
            <phase-ii-common-card-v-ii [needPaginator]="false" [listData]="activePlan" [isLeftAlign]="true"
                [actionsIcon]="activeActionIcons" [listProperties]="listProperty" [columnsRef]="columnRefForActivePlan"
                (actionClicked)="listAction($event)">
            </phase-ii-common-card-v-ii>
        </div>
        <div>
            <br />
            <mat-divider></mat-divider>
            <br />
        </div>
        <div fxFlex="100%">
            <h3 class="heading-text">Expired Plan </h3>
            <phase-ii-common-card-v-ii [needPaginator]="true" [listData]="inActivePlan" [actionsIcon]="actionIcons"
                [isLeftAlign]="true" [listProperties]="listPropertyForExpired" [columnsRef]="columnRefForInactivePlan"
                (actionClicked)="listAction($event)">
            </phase-ii-common-card-v-ii>
        </div>
    </mat-card>
</div>
<div class="page-container" *ngIf="isMobileApp">
    <div fxLayoutAlign="center center" fxLayout="column" *ngIf="isNewMobileSubscription && !isSuperAdmin">
        <div fxLayout="row">
            <img src="../assets/mobile-subscription.png" class="empty-img" alt="No Mobile subscriptions" />
        </div>
        <h3 class="heading-text" fxLayout="row">No Active Plan </h3>
        <button mat-raised-button class="primary-button" data-control="link-button" fxLayout="row"
            (click)="onNavigate()">
            Subscribe Now
        </button>
    </div>
    <mat-card fxLayout="column" *ngIf="!isLoader && !isNewMobileSubscription" class="card-container" fxLayout="column"
        fxLayoutGap="3%" fxLayoutGap.lt-md="0">
        <div fxFlex="100%">
            <h3 class="heading-text">Active Plan </h3>
            <phase-ii-common-card-v-ii [needPaginator]="false" [listData]="mobileActivePlan" [isLeftAlign]="true"
                [listProperties]="listProperty1" [columnsRef]="columnRefForMobileActivePlan"
                [actionsIcon]="activeActionIcons" (actionClicked)="listAction($event)">
            </phase-ii-common-card-v-ii>
        </div>
        <div>
            <br />
            <mat-divider></mat-divider>
            <br />
        </div>
        <div fxFlex="100%">
            <h3 class="heading-text">History </h3>
            <phase-ii-common-card-v-ii [needPaginator]="true" [listData]="mobileHistoryPlan" [isLeftAlign]="true"
                [listProperties]="listPropertyForHistory" [columnsRef]="columnRefForMobileHistory"
                [actionsIcon]="actionIcons" (actionClicked)="listAction($event)">
            </phase-ii-common-card-v-ii>
        </div>
    </mat-card>
</div>