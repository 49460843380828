import { Component, Inject, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { UntypedFormArray, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DomSanitizer } from '@angular/platform-browser';
import { Subscription } from 'rxjs';
import { filter, mergeMap } from 'rxjs/operators';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CommonConstants } from 'libs/shared/src/lib/constants/shared-constant';
import { CommonService } from 'libs/shared/src/lib/services/common.service';
import { DialogService } from 'libs/common/src/lib/services/dialog.service';
/**
 * This component is used to select ribbon template designs
 */
@Component({
  selector: 'phase-ii-ribbon-editor',
  templateUrl: './ribbon-editor.component.html',
  styleUrls: ['./ribbon-editor.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class RibbonEditorComponent extends CommonConstants implements OnInit, OnDestroy {
  /** 
  * Form group which contains contact form form controls.
  * @type {FormGroup}
  */
  ribbon: UntypedFormGroup;
  /**
   * variable used to store position types.
   * @type {object}
   */
  positionType;
  /**
   * Variable used to store ribbon template name.
   * @type {string}
   */
  ribbonName: string;
  /**
   * variable used to store ribbon data.
   * @type {Array}
   */
  ribbonData: Array<any> = [];
  /**
   * variable used to store ribbon template.
   * @type {any}
   */
  html: any;
  /**
   * variable used to store selected index in form array.
   * @type {number}
   */
  selectedIndex: number;
  /**
   * Variable used to subscribe and unsubscribe
   * @type {Subscription}
   */
  subscriptionObj = new Subscription();
  /**
   * Variable used to store the loader loading status.
   * @type {boolean}
   */
  isLoader: boolean = false;
  /**
   * Variable used to store aws url.
   * @type {string}
   */
  awsUrl: string;
  /**
   * component constructor which is used to inject the required services.
   * @param commonService  To access methods inside CommonService.
   * @param sanitizer  To access methods inside DomSanitizer.
   * @param snackBar  To access methods inside MatSnackBar.
   * @param dialogService  To access methods inside DialogService.
   * @param dialogRef  To access methods inside RibbonEditorComponent component.
   * @param data data which is taken from mat dialog . 
   */
  constructor(
    private commonService: CommonService,
    private sanitizer: DomSanitizer,
    private snackBar: MatSnackBar,
    private dialogService: DialogService,
    public dialogRef: MatDialogRef<RibbonEditorComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    super();
  }
  /**
   * component onInit life cycle hook.
   */
  ngOnInit(): void {
    this.awsUrl = this.commonService.environment.AWSImageUrl;
    this.isLoader = true;
    this.subscriptionObj.add(this.commonService.getPosition(this.data.storeId, this.ribbonDetails.positionName).pipe(filter((res: any) => {
      if (res) {
        this.positionType = res['propertyValue'];
        return true;
      }
    }), mergeMap(() => {
      return this.commonService.getAllRibbon(this.data.storeId);
    })).subscribe((response: any) => {
      if (response && response.ribbons && response.ribbons.rows && response.ribbons.rows.length) {
        this.ribbonData = response.ribbons.rows;
        this.isLoader = false;

      }
      else {
        this.isLoader = false;
        this.ribbonData = [];
      }
      this.ribbon = new UntypedFormGroup({
        design: new UntypedFormArray([])
      });
      if (this.ribbonData && this.ribbonData.length > 0) {
        if (this.data && this.data.ribbonDetail && this.data.ribbonDetail.length > 0) {
          this.createRibbonFormGroup(true, this.data.ribbonDetail);
        } else {
          this.createRibbonFormGroup(true);
          this.selectedIndex = 0;
          this.ribbon.get('design')['controls'][this.selectedIndex].get('ribbons').setValue(this.ribbonData[0]);
          this.setData(this.ribbonData[0], this.ribbon.get('design')['controls'][this.selectedIndex].get('overlayRibbonPosition').value);
        }
      }
    }));
  }
  /**
   * Method used to create form array.
   * @param isAdd boolean used to compare form array length and ribbon data length.
   * @param ribbonDetail object which holds already selected ribbon data.
   */
  createRibbonFormGroup(isAdd: boolean, ribbonDetail?: any): void {
    let data;
    if (isAdd) {
      if (ribbonDetail && ribbonDetail.length) {
        data = ribbonDetail.length;
      } else {
        data = 1;
      }
      for (let i = 0; i < data; i++) {
        let res;
        if (ribbonDetail && ribbonDetail.length && ribbonDetail[i].ribbonId) {
          res = this.ribbonData.find(v => v.id === ribbonDetail[i].ribbonId);
        }
        const g = new UntypedFormGroup({
          id: new UntypedFormControl(ribbonDetail ? ribbonDetail[i] && ribbonDetail[i].id : null),
          ribbons: new UntypedFormControl(res ? res : null, [Validators.required]),
          overlayRibbonPosition: new UntypedFormControl(ribbonDetail ? ribbonDetail[i] && ribbonDetail[i].overlayRibbonPosition : null, [Validators.required]),
          isOverlayHeader: new UntypedFormControl(ribbonDetail && ribbonDetail[i] && ribbonDetail[i].isOverlayHeader ? ribbonDetail[i].isOverlayHeader : false)
        });
        (this.ribbon.get('design') as UntypedFormArray).push(g);
      }
      if (ribbonDetail && ribbonDetail.length) {
        this.selectedIndex = ribbonDetail.length - 1;
        this.setData(this.ribbon.value.design[this.selectedIndex].ribbons, this.ribbon.value.design[this.selectedIndex].overlayRibbonPosition)
      }
    }
    else {
      this.snackBar.open('All ribbons are selected', 'cancel', {
        duration: 3000
      });
    }
  }
  /**
   * method used to remove from formArray.
   * @param i index for deleting  from formArray.
   */
  removeRibbonForm(i: number): void {
    if (i === 0) {
      this.selectedIndex = i;
    } else {
      this.selectedIndex = i - 1;
      this.setData(this.ribbon.get('design')['controls'][this.selectedIndex].get('ribbons').value, this.ribbon.get('design')['controls'][this.selectedIndex].get('overlayRibbonPosition').value);
    }
    const data = this.ribbon.get('design')['controls'][i].get('ribbons').value;
    this.ribbon.get('design')['controls'].forEach((items) => {
      if (items.get('ribbons').value === data) {
        if (items.get('ribbons').errors.isDuplicate) {
          items.get('ribbons').setErrors(null);
        }
      }
    });
    (this.ribbon.get('design') as UntypedFormArray).removeAt(i);
  }
  /**
   * Method used to select ribbons.
   * @param value ribbon data object.
   * @param index index value of form array.
   */
  selectedRibbon(value: any, index: number): void {
    if (this.ribbon.get('design')['controls']?.length > index) {
      let count = 0;
      this.ribbon.get('design').value.forEach(item => {
        if (item.ribbons && item.ribbons.text === value.text) count++;
      });
      if (count > 1) this.ribbon.get('design')['controls'][index]['controls'].ribbons.setErrors({ isDuplicate: true });
      for (let i = 0; i < this.ribbon.get('design')['controls']?.length; i++) {
        let duplicateCount = 0;
        for (let j = 0; j < this.ribbon.get('design')['controls']?.length; j++) {
          if ((i !== j) && (this.ribbon.get('design')['controls'][i]['controls'].ribbons.value !== this.ribbon.get('design')['controls'][j]['controls'].ribbons.value)) {
            duplicateCount++;
          }
        }
        if (duplicateCount === this.ribbon.get('design')['controls']?.length - 1 && this.ribbon.get('design')['controls'][i]['controls'].ribbons.errors && this.ribbon.get('design')['controls'][i]['controls'].ribbons.errors['isDuplicate']) {
          this.ribbon.get('design')['controls'][i]['controls'].ribbons.setErrors(null);
        }
      }
      if (this.selectedIndex < index || this.selectedIndex == null) {
        this.selectedIndex = index;
        this.setData(value);
      } else {
        this.selectedIndex = index;
        this.setData(value, this.ribbon.get('design')['controls'][this.selectedIndex].get('overlayRibbonPosition').value);
      }
    }
  }
  /**
   * method used to change design.
   * @param i index used to change design.
   */
  onCheckboxChange(i: number): void {
    if (this.selectedIndex !== i) {
      this.selectedIndex = i;
      this.setData(this.ribbon.get('design')['controls'][this.selectedIndex].get('ribbons').value, this.ribbon.get('design')['controls'][this.selectedIndex].get('overlayRibbonPosition').value);
    }
  }
  /**
   * Method used to change position design.
   * @param value value contains position name.
   * @param i index used to change design.
   */
  changePosition(value: any, i: number): void {
    if (this.selectedIndex !== i) {
      this.selectedIndex = i;
      this.setData(this.ribbon.get('design')['controls'][this.selectedIndex].get('ribbons').value, value);
    } else {
      this.setData(this.ribbon.get('design')['controls'][this.selectedIndex].get('ribbons').value, value);
    }
  }
  /**
   * Method used to set values for template.
   * @param value contains design value.
   * @param positionValue contains position name.
   */
  setData(value: any, positionValue?: any): void {
    this.html = positionValue && value.className[positionValue].className[0] ? value.ribbonTemplate.replace('className1', value.className[positionValue].className[0]) : (value.className.topLeft.className[0] ? value.ribbonTemplate.replace('className1', value.className.topLeft.className[0]) : value.ribbonTemplate);
    this.html = positionValue && value.className[positionValue].className[1] ? this.html.replace('className2', value.className[positionValue].className[1]) : (value.className.topLeft.className[1] ? this.html.replace('className2', value.className.topLeft.className[1]) : this.html);
    this.html = positionValue && value.className[positionValue].className[2] ? this.html.replace('className3', value.className[positionValue].className[2]) : (value.className.topLeft.className[2] ? this.html.replace('className3', value.className.topLeft.className[2]) : this.html);
    this.html = positionValue && value.className[positionValue].styleAttribute[0] ? this.html.replace('style1', value.className[positionValue].styleAttribute[0]) : (value.className.topLeft.styleAttribute && value.className.topLeft.styleAttribute[0] ? this.html.replace('style1', value.className.topLeft.styleAttribute[0]) : this.html);
    this.html = positionValue && value.className[positionValue].styleAttribute[1] ? this.html.replace('style2', value.className[positionValue].styleAttribute[1]) : (value.className.topLeft.styleAttribute && value.className.topLeft.styleAttribute[1] ? this.html.replace('style2', value.className.topLeft.styleAttribute[1]) : this.html);
    this.html = this.html.replace('textName', value.text);

    if (value.textColor) {
      this.html = this.html.replace('var(--light-color)', value.textColor);
    }
    if (value.className.ribbonName) {
      this.ribbonName = value.className.ribbonName;
    }
    else {
      this.ribbonName = null;
    }
    if (value.ribbonColor) {
      this.html = this.html.replace('var(--primary-color)', value.ribbonColor);
      this.html = this.html.replace('var(--secondary-color)', value.ribbonColor);
      document.documentElement.style.setProperty('--new-ribbon-color', value.ribbonColor);
    }
    this.html = this.sanitizer.bypassSecurityTrustHtml(this.html);
    this.ribbon.get('design')['controls'][this.selectedIndex].get('overlayRibbonPosition').setValue(positionValue ? positionValue : this.positionType[0].value);
  }
  /**
   * Method used to save ribbon data.
   */
  save(): void {
    if (this.ribbon.valid) {
      if (this.data && this.data.ribbonDetail && this.data.ribbonDetail.length) {
        if (!this.ribbon.pristine) {
          this.dialogRef.close(this.ribbon.get('design').value);
        } else {
          this.dialogService.dialogMethod(this.dialogMessages.noChangeAlert, this.dialogType.alert, true);
        }
      } else {
        this.dialogRef.close(this.ribbon.get('design').value);
      }
    } else {
      this.ribbon.markAllAsTouched();
    }
  }
  /**
   * Method used to close dialog with ribbon data. 
   */
  onClose(): void {
    if (this.ribbon.dirty) {
      const dialogRef = this.dialogService.openDialog({
        header: this.dialogType.confirmation,
        message: this.message.unsavedChanges,
        actionType: this.dialogType.confirmation,
        button: { left: this.buttonText.no, right: this.buttonText.yes },
        disableClose: true
      });
      dialogRef.afterClosed().subscribe((result: boolean) => {
        if (result) {
          this.dialogRef.close();
        };
      });
    } else {
      this.dialogRef.close();
    }
  }

  /**
   * Method used for unsubscribe the request.
   */
  ngOnDestroy(): void {
    this.subscriptionObj.unsubscribe();
  }
}