// Dialog Service which is used to open the dialog box from dialog component.
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DialogComponent } from '../components/dialog/dialog.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SharedConstant } from '../constants/shared-constant';
import { BehaviorSubject } from 'rxjs';
import { SnackbarComponent } from '../components/snackbar/snackbar.component';
import { LoadTranslationService } from './load-translation.service';
// import { CardDialogComponent } from '../components/card-dialog/card-dialog.component';
/**
 * Dialog Service which is used to open the dialog box from dialog component.
 */
@Injectable({
  providedIn: 'root'
})
export class DialogService extends SharedConstant {
  // /**
  //  * Service constructor to inject the other needed services here.
  //  * @param dialog To access the functions inside the material dialog box.
  //  * @param snackBar used to open snack Bar.
  //  */
  messages: { [key: string]: string };

  //  subscriptionObj: Subscription[] = [];
  fileName = new BehaviorSubject<string>(null);
  // dismissalSubject: Subject<boolean> = new Subject<boolean>();

  // constructor(
  //   public dialog: MatDialog,
  //   private _snackBar: MatSnackBar,
  //   private errorMessageService: ErrorMessageService) {
  //   super();
  // }
  // /**
  //  * Method which is used to open the confirmation dialog box.
  //  * @param dialogData The data which is used to display in the dialog box.
  //  */
  // openDialog(dialogData: any) {
  //   const dialogRef = this.dialog.open(DialogComponent, {
  //     data: dialogData,
  //     autoFocus: false,
  //     width: '300px',
  //     backdropClass: dialogData.backdropClassName,
  //     disableClose: dialogData.disableClose
  //   });
  //   return dialogRef;
  // }
  // /**
  //    * Method used to Open snack Bar.
  //    * @param message message to display in snack bar.
  //    */
  // openSnackBar(message) {
  //   return this._snackBar.open(message, 'X', { duration: 2000 });
  // }
  // /**
  //   * Method used to Open snack Bar.
  //   * @param message message to display in snack bar.
  //   */
  // openSnackBars(message) {
  //   return this._snackBar.open(message, 'X');
  // }
  // /**
  // * Method used for the display the dialog box
  // * @param message dialoge box message
  // * @param messageType dialogue box type
  // * @returns
  // */
  // dialogMethod(message: string, messageType: string, disableClose?: boolean) {
  //   return this.openDialog({
  //     header: (messageType === this.dialogType.alert) ? this.dialogHeader.alert : (messageType === this.dialogType.confirmation) ? this.dialogHeader.confirmation : (messageType === this.dialogType.success) ? this.dialogHeader.success : this.dialogHeader.failure,
  //     message: message,
  //     actionType: messageType,
  //     button: (messageType === this.dialogType.confirmation) ? { left: this.buttonText.no, right: this.buttonText.yes } : { right: this.buttonText.ok },
  //     disableClose: disableClose

  //   })
  // }
  // /**
  //  * Method which is used to show the dialog component for displaying the response message
  //  * @param data To get the dialog data
  //  */
  //  showDialog(data) {
  //   const dialogRef = this.dialog.open(CardDialogComponent, {
  //     data: data,
  //     autoFocus: false,
  //     width: '300px',
  //     disableClose: true
  //   });
  //   return dialogRef;
  // }
  // showFilePickPopup(data, prevSelectedFiles) {
  //   const dialogRef = this.dialog.open(UploadFileComponent, {
  //     closeOnNavigation: true,
  //     disableClose: true
  //   });
  //   dialogRef.componentInstance.contentData = data;
  //   dialogRef.componentInstance.filesToUpload = prevSelectedFiles ? prevSelectedFiles : [];
  //   return dialogRef;
  // }
  // showFilePickPopupCommon(data, prevSelectedFiles, directoryName) {
  //   this.fileName.next(directoryName);
  //   // console.log(this.fileName);
  //   const dialogRef = this.dialog.open(UploadFileCommonComponent, {
  //     closeOnNavigation: true,
  //     disableClose: true
  //   });
  //   dialogRef.componentInstance.contentData = data;
  //   dialogRef.componentInstance.filesToUpload = prevSelectedFiles ? prevSelectedFiles : [];
  //   return dialogRef;
  // }

  // showSnackBar(message: string, duration?) {
  //   this._snackBar.open(message, 'close', {
  //     duration: duration ? duration : 5000,
  //     panelClass: ['snack-bar-class'],
  //     verticalPosition: 'top',
  //     horizontalPosition: 'right',
  //   });
  // }

  // openDialogShopApp(header, data, info?) {
  //   return this.showDialog({
  //     header: this.messages[header],
  //     message: this.messages[data],
  //     btnInfo: info,
  //   });
  // }
  /**
  * Service constructor to inject the other needed services here.
  * @param dialog To access the functions inside the material dialog box.
  * @param snackBar used to open snack Bar.
  */
  constructor(
    public dialog: MatDialog,
    private snackBar: MatSnackBar,
    private translationService: LoadTranslationService) {
    super();
  }
  /**
   * Method which is used to open the confirmation dialog box.
   * @param dialogData The data which is used to display in the dialog box.
   */
  openDialog(dialogData: any) {
    if ((dialogData.button?.left && dialogData.button?.right)) {
      const dialogRef = this.dialog.open(DialogComponent, {
        data: dialogData,
        autoFocus: false,
        width: '300px',
        backdropClass: dialogData.backdropClassName,
        disableClose: dialogData.disableClose
      });
      return dialogRef;
    }
    else {
      const snackBarRef = this.OpenSnackBar(dialogData);
      return snackBarRef;
    }
  }
  /**
     * Method used to Open snack Bar.
     * @param message message to display in snack bar.
     */
  openSnackBar(message: any) {
    return this.snackBar.open(message, 'X', { duration: 2000 });
  }
  /**
     * Method used to Open snack Bar in top right corner.
     * @param message message to display in snack bar.
     */
  topRightSnackBar(message: any) {
    return this.snackBar.open(message, 'X', {
      duration: 2000, horizontalPosition: 'right',
      verticalPosition: 'top',
    });
  }
  /**
    * Method used to Open snack Bar.
    * @param message message to display in snack bar.
    */
  openSnackBars(message: any) {
    return this.snackBar.open(message, 'X');
  }
  /**
  * Method used for the display the dialog box
  * @param message dialoge box message
  * @param messageType dialogue box type
  * @returns
  */
  dialogMethod(message: string, messageType: string, disableClose?: boolean, isDialog?: boolean) {
    const okay = this.translationService.getTranslation('COMMON.OKAY');
    const yes = this.translationService.getTranslation('COMMON.YES');
    const no = this.translationService.getTranslation('COMMON.NO');
    const alert = this.translationService.getTranslation('COMMON.ALERT');
    const confirmation = this.translationService.getTranslation('COMMON.CONFIRMATION');
    const success = this.translationService.getTranslation('COMMON.SUCCESS');
    const failure = this.translationService.getTranslation('COMMON.FAILURE');
    return this.openDialog({
      header: (messageType === this.dialogType.alert) ? (alert !== 'COMMON.ALERT' ? alert : this.dialogHeader.alert) : (messageType === this.dialogType.confirmation) ? (confirmation !== 'COMMON.CONFIRMATION' ? confirmation : this.dialogHeader.confirmation) : (messageType === this.dialogType.success) ? (success !== 'COMMON.SUCCESS' ? success : this.dialogHeader.success) : (failure !== 'COMMON.FAILURE' ? failure : this.dialogHeader.failure),
      message: message,
      actionType: messageType,
      button: (messageType === this.dialogType.confirmation) ? { left: (no !== 'COMMON.NO') ? no : this.buttonText.no, right: (yes !== 'COMMON.YES') ? yes : this.buttonText.yes } : { right: (okay !== 'COMMON.OKAY') ? okay : this.buttonText.ok },
      disableClose: disableClose
    })
  }
  /**
   * Method which is used to display loyalty points dialog box.
   * @param message To get dialoge box message.
   * @param actionType To get dialogue box type.
   * @param earningPoints To get earned points.
   * @param isDialog To disable the snackbar.
   * @returns
   */
  loyaltyPointsDialog(message: string, actionType: string, earningPoints: number, buttonText:string, disableClose?: boolean,) {
    let dialogRef = this.dialog.open(DialogComponent, {
      data: {
        message: message ? message : '',
        actionType: actionType ? actionType : '',
        noOfPoints: earningPoints ? earningPoints : 0,
        button: { right: buttonText ? buttonText : this.buttonText.ok },
      },
      autoFocus: false,
      width: '300px',
      disableClose: disableClose ? disableClose : false
    });
    return dialogRef;
  }
  // openDialogShopApp(header: any, data: any, info?: any) {
  //   return this.showDialogshopapp({
  //     header: this.messages[header],
  //     message: this.messages[data],
  //     btnInfo: info,
  //   });
  // }
  showSnackBar(message: string, duration?: any) {
    this.snackBar.open(message, 'close', {
      duration: duration ? duration : 5000,
      panelClass: ['snack-bar-class'],
      verticalPosition: 'top',
      horizontalPosition: 'right',
    });
  }
  // showDialogshopapp(data: any) {
  //   const dialogRef = this.dialog.open(CardDialogComponent, {
  //     data: data,
  //     autoFocus: false,
  //     width: '300px',
  //     disableClose: true
  //   });
  //   return dialogRef;
  // }

  // prem

  OpenSnackBar(snackBarData: any): any {
    const type = this.snackBarDetails.find(({ type }) => type === snackBarData.actionType);
    const snackBarRef = this.snackBar.openFromComponent(SnackbarComponent, {
      data: {
        msg: snackBarData.message,
        icon: type?.icon,
        prefixIcon: (snackBarData.prefixIcon === false) ? snackBarData.prefixIcon : true,
        actionIcon: (snackBarData.actionIcon === false) ? snackBarData.actionIcon : true,
        closeButton: snackBarData.closeButton ? snackBarData.closeButton : (snackBarData?.button?.right ? snackBarData?.button?.right : false),
      },
      duration: !(type?.type == 'failure' || type?.type == 'alert') ? (snackBarData.duration ? snackBarData.duration * 1000 : 5000) : undefined,
      horizontalPosition: snackBarData?.position?.hPosition ? snackBarData?.position?.hPosition : 'center',
      verticalPosition: snackBarData?.position?.vPosition ? snackBarData?.position?.vPosition : 'bottom',
      panelClass: [type?.panelClass]
    });
    return snackBarRef;
  }
}