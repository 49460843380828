import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

// Pipe which is used to santize the video url.
@Pipe({
    name: 'safeUrl'
})
/**
 * Class which is used to load the url.
 */
export class SantizerPipe implements PipeTransform {
    /**
     * Component constructor which is used to inject the required services.
     * @param santize To prevent cross site scripting.
     */
    constructor(private sanitize: DomSanitizer) {
    }
    /**
  * Method which is used to load the url.
  * @param url To defines the path.
  */
    transform(url): any {
        return this.sanitize.bypassSecurityTrustResourceUrl(url);
    }
}