export const UNFULFILLED_ORDERS_CONSTANT = {
columnForUnfulfilledOrders: [
  { column: 'customerName', header: 'Customer Name', type: 'Text', sort: false, suffixText: 'Customer Name' },
  { column: 'email', header: 'Customer Email', type: 'Text', sort: false, suffixText: 'Customer Email' },
  { column: 'paymentType', header: 'Payment Type', type: 'Text', sort: false, suffixText: 'Payment Type' },
  { column: 'paymentStatus', header: 'Payment Status', type: 'Text', sort: false, suffixText: 'Payment Status' },
  { column: 'amount', header: 'Amount', type: 'Amount', sort: false, suffixText: 'Amount' },
  { column: 'createdAt', header: 'Created At', type: 'DateTime', class: 'date-time', sort: false, suffixText: 'Created At' },
  { column: 'type', header: 'Order Type', type: 'Text', sort: false, suffixText: 'Order Type' },
],
columnSuperAdminUnfulfilledOrders: [
  { column: 'storeId', header: 'Store Id', type: 'Text', sort: false, suffixText: 'Store Id' },
  { column: 'storeName', header: 'Store Name', type: 'Text', sort: false, suffixText: 'Store Name' },
  { column: 'paymentStatus', header: 'Payment Status', type: 'Text', sort: false, suffixText: 'Payment Status' },
  { column: 'type', header: 'Order Type', type: 'Text', sort: false, suffixText: 'Order Type' },
  { column: 'amount', header: 'Amount', type: 'Amount', sort: false, suffixText: 'Amount' },
  { column: 'createdAt', header: 'Created At', type: 'DateTime', class: 'date-time', sort: false, suffixText: 'Created At' },
],
actionIcons: [
  { iconName: 'refresh', method: 'onRetryOrder', toolTip: 'Retry Order', iconColor: '#03c04a' },
  { iconName: 'delete', method: 'onRemove', toolTip: 'Delete Order', iconColor: '#f72121' }
],
dialogMessages: { unfulfilledOrdersListFailed: 'Failed to get details', orderCreationFailed: 'Failed to create order', orderCreationSuccess: 'Order Created Successfully',deleteUnfulfilledOrder: 'Are you sure you want to remove the unfulfilled order?',deleteUnfulfiledOrderSuccess: 'Unfulfilled order deleted successfully', deleteUnfulfiledOrderFailure: 'Failed to delete Unfulfilled order', unsavedChanges: 'You have unsaved changes. Are you sure you want to leave this page?', verifyPaymentOrderFailed: 'Payment order verification failed.', verifyPaymentOrderSuccess: 'Payment order verified successfully', retryConfirmation:'Your payment has been verified. Would you like to retry the order?'},
dialogType: { alert: 'alert', confirmation: 'confirmation', information: 'information', success: 'success', failure: 'failure' },
errorMessages: { requiredMsg: 'required'},
RAZORPAY: ['Log in to the Razorpay Dashboard.','Select the "Products" menu.','Choose the "Transactions" submenu.','Click on the Orders tab to view all the orders placed.','You can use the search bar or filters to find the specific order','Once you find the relevant order, copy the Order ID from the list.']
}