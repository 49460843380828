<div class="container" [ngClass.xs]="'container-mob'" [ngClass.sm]="'container-ipad'">
    <p mat-dialog-title>
        <span style="margin: 10px;" data-control="pickupRequestHeader">{{contentData?.title}}</span>
        <mat-icon *ngIf="!loading" class="dialog-close-icon" [mat-dialog-close]="uploadbtn ? false : true"
            data-control="import-close">
            close
        </mat-icon>
    </p>
    <div mat-dialog-content fxLayout="column" fxLayoutGap="3%">
        <div *ngIf="requiredError">
            <div class="list-item">
                <span style="font-size:17px;" class="import-error">
                    {{requiredError}}</span>
            </div>
        </div>
        <cen-loader style="position:absolute;top:50%;left:50%;" *ngIf="isLoader" [type]="'content'"
            [name]="'cen-line-spinner'">
        </cen-loader>
        <div class="upload-container" *ngIf="uploadbtn || errorData">
            <div>Download a <span data-control="getsampleTemplate" (click)="getsampleTemplate()"
                    data-control="sample-template" class="cursor">sample
                    template</span> to see an example of the format required.</div>
            <div mat-dialog-content class="padding_address" *ngIf="importForm">
                <form [formGroup]="importForm">
                    <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="3%" fxLayoutGap.lt-md="0">
                        <div fxFlex="50%" fxLayout="row" fxLayout.lt-md="column">
                            <mat-form-field type="text" fxFlex="100%">
                                <mat-label>Description</mat-label>
                                <input data-control="description" type="text" matInput formControlName="description" />
                                <mat-error data-control="mat-error-maxLength" class="mat-error-size"
                                    *ngIf="importForm?.get('description')?.hasError('maxlength')">
                                    Description should be atmost 250 characters long.
                                </mat-error>
                                <mat-error data-control="mat-error-acceptschar"
                                    *ngIf="!importForm?.get('description')?.hasError('maxlength') &&importForm?.get('description')?.hasError('pattern')">
                                    Description Should be valid.
                                </mat-error>
                            </mat-form-field>
                        </div>
                    </div>
                </form>
            </div>
            <div *ngIf="importFileFormat || !isFileTypeVerified">
                <div class="format-error">The given file/file(s) is not in correct format.</div>
            </div>
            <br *ngIf="importFileFormat">
            <div *ngIf="data?.instruction?.length" class="notes-align">Notes :</div><br>
            <div *ngIf="data?.instruction">
                <div *ngFor="let item of data?.instruction; let id=index">
                    {{id+1}}. {{item}}
                </div>
            </div>
            <br *ngIf="data?.instruction">

            <div class="list">
                <ng-container *ngIf="!errorData">
                    <ng-container *ngFor="let file of filesToUpload">
                        <div class="list-items">
                            <div fxFlex="80%">{{file?.name}}
                            </div>
                            <div fxFlex="20%" [ngStyle]="{'pointer-events': loading ? 'none' :'auto' }"><em
                                    class="far fa-trash-alt" matTooltip="Remove" (click)="onDelete(file)"
                                    [attr.data-control]="file?.name"></em>
                            </div>
                        </div>
                    </ng-container>
                </ng-container>
                <ng-container *ngIf="errorData && errorData?.length || requiredError">
                    <h2 *ngIf="contentData.imageType=='products'" class="data-import-product">Product Import
                        process
                        successfully initiated, Check dashboard for status update</h2>
                    <h2 *ngIf="contentData.imageType=='products' && importInProgress "
                        class="data-import-product-process">Kindly hold on for a moment! Due to the ongoing products
                        import, you cannot initiate another import in products.</h2>
                    <h2 *ngIf="contentData.imageType!='products'" class="data-import-success">Data Imported Successfully
                    </h2>
                    <h4 class="import-success">Completed with Error</h4>
                </ng-container>
                <ng-container *ngIf="errorData && errorData?.length ">
                    <ng-container *ngFor="let error of errorData">
                        <div style="font-size:16px;" class="import-error"
                            [attr.data-control]="error?.errorMessage?error?.errorMessage:error" class="list-item">
                            <span>{{error?.errorMessage?error?.errorMessage:error}}</span>
                        </div>
                    </ng-container>
                </ng-container>
            </div>
        </div>
        <div fxlayoutAlign="center center" class="upload-container"
            *ngIf="!uploadbtn && errorData && errorData?.length == 0">
            <h2 *ngIf="contentData.imageType=='products' && !importInProgress " class="data-import-product">Product
                Import
                process
                successfully initiated, Check dashboard for status update</h2>
            <h2 *ngIf="contentData.imageType=='products' && importInProgress " class="data-import-product-process">
                Kindly hold on for a moment! Due to the ongoing products import, you cannot initiate another import in
                products.</h2>
            <h2 *ngIf="contentData.imageType!='products'" class="data-import-success">Data Imported Successfully
            </h2>

        </div>
        <div fxlayoutAlign="center center" *ngIf="loading">
            <h2 class="data-import-note">This process may take some time..!</h2>
        </div>
    </div>
    <div style="margin-left: 36px;"><button mat-raised-button class="import-raised-button" [disabled]="loading"
            *ngIf="(!contentData?.maxFileCount || (contentData?.maxFileCount > filesToUpload?.length)) && uploadbtn"
            (click)="contentData.isMultiple?multipleFile.click():file.click()" data-control="import-add-file">Add
            File</button>
        <input #multipleFile type='file' (change)="onFileSelected($event)" hidden multiple>
        <input #file type='file' (change)="onFileSelected($event)" hidden>
    </div>
    <div mat-dialog-actions fxLayoutAlign="end">
        <button *ngIf="!loading" class="secondary-button" [mat-dialog-close]="uploadbtn ? false : true"
            mat-raised-button data-control="import-cancal">
            {{errorData && errorData?.length ? 'Back to List Page' : 'Close'}}
        </button>
        <button class="primary-button" *ngIf="uploadbtn" mat-raised-button (click)="importFile()"
            data-control="import-upload">
            <div *ngIf="!loading">
                Upload
            </div>
            <cen-loader style="width: 10px;" *ngIf="loading" [type]="'content'" [name]="'cen-dot-spinner'">
            </cen-loader>
        </button>
    </div>
</div>