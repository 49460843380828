/**
 * Interface used to store payout status details
 */
export interface PayoutStatusDetails{
  result: {
    property_name:string,
    code: string,
    id:number,
    propertyValues: Array<StatusData>
  }
  statusCode: string,
  message: string,
  success:boolean
}
/**
 * Interface used to store status values
 */
export interface StatusData{
  value: string,
  code: string,
  id: number,
  additionalField: string,
}
/**
 * Interface used to store account details of the user
 */
export interface AccountDetails{
  message: string,
  result: string,
  statusCode: string,
  success: string
}
/**
 * Interface used to store decrypted account details of the user
 */
export interface DecryptedAccountDetails{
  id:number,
  paymentType: string,
  bankName: string,
  branchName: string,
  accountHolderName: string,
  accountNumber: string,
  ifscCode: string,
  micrCode: string,
  vpaId: string,
  isActive:boolean,
  isDeleted:boolean,
  customerId:number
}
/**
 * Interface used to store Filter details
 */
export interface FilterData{
  status:string
}
/**
 * Interface used to store all the payout request details
 */
export interface RequestDetails {
  payoutLogDetails: {
    count: number,
    rows:Array<PayoutRequestData>
  }
  statusCode:string,
  message: string,
  success:boolean
}
/**
 * Interface used to store all the payout request datas
 */
export interface PayoutRequestData {
  id: number,
  totalAmount: string,
  status: string,
  createdAt: string,
  updatedAt: string,
  isDeleted:boolean,
  description: string,
  transactionId: string,
  userId: number,
  affiliateUser:{
    id: number,
    name: string,
    email: string,
    customerType: string,
  }
}
/**
 * Interface used to store update payout request
 */
export interface UpdatePayoutRequest{
  code: string,
  message: string,
  success: boolean
}
/**
 * Interface used to store event details for listAction,onEdit,formInitialize,OnViewAccountDetails
 */
export interface EventDetails{
  data: PayoutRequestData,
  iconColor: string,
  iconName: string,
  method: string,
  toolTip: string
}
/**
 * Interface used to store form value details
 */
export interface OnpaymentCompleteDetails {
  value?: {
    value: string,
    code: string,
    id: number,
    additionalField: string,
    disableStatus?: boolean
  }
  data?: PayoutRequestData
}
/**
 * Interface used to store payload details to getAllPayoutRequest api
 */
export interface QueryDetailsForListApi {
  offset:number,
  limit: number,
  searchText:string,
  filterData: string
}
/**
 * Interface used to store payload details for updatePayoutRequest api
 */
export interface updatePayoutParamaData{
  transactionId?: string,
  description?: string,
  status:number
}