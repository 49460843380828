import {
  Validators,
  AbstractControl,
  ValidatorFn,
  ValidationErrors,
  UntypedFormGroup,
} from '@angular/forms';

export class CustomValidatorService extends Validators {
  /**
   * * Static method for custom validation
   *  @param control To define the custom form contols.
   */
  static formValidation(control: AbstractControl) {
    if (control && control.value) {
      // Format the phone number to us format
      if (control.value.contactNumber && control.get('contactNumber').invalid) {
        const match = ('' + control.value.contactNumber)
          .replace(/\D/g, '')
          .match(/^(\d{3})(\d{3})(\d{4})$/);
        if (match) {
          control.patchValue({
            // tslint:disable-next-line:object-literal-key-quotes
            contactNumber: '(' + match[1] + ') ' + match[2] + '-' + match[3],
          });
        }
      }
      if (control.value.oldpassword && control.value.password) {
        // If password not match then set error for confirm password field else set error as null.

        if (control.value.oldpassword === control.value.password) {
          control.get('password').setErrors({ oldNewPasswordMatch: true });
        }
      }
      if (control.value.password && control.value.confirmPassword) {
        // If password not match then set error for confirm password field else set error as null.
        control
          .get('confirmPassword')
          .setErrors(
            control.value.password === control.value.confirmPassword
              ? null
              : { passwordMismatch: true }
          );
      }
      if (control.value.deliveryInfoGroup && control.value.deliveryInfoGroup.country) {
        if (control.value.deliveryInfoGroup.country == 'US') {
          if (control.value.deliveryInfoGroup.zip_code) {
            const match = (control.value.deliveryInfoGroup.zip_code).match(/^((\d{5}-\d{4})|(\d{5})|([A-Z]\d[A-Z]\s\d[A-Z]\d))$/);
            if (!match) {
              control.get('deliveryInfoGroup').get('zip_code').setErrors({ USZipCode: true });
            }
          }
        } else if (control.value.deliveryInfoGroup.country == 'IN') {
          if (control.value.deliveryInfoGroup.zip_code) {
            const match = (control.value.deliveryInfoGroup.zip_code).match(/^(\d{6})$/);
            if (!match) {
              control.get('deliveryInfoGroup').get('zip_code').setErrors({ INZipCode: true });
            }
          }
        }
      }
    }
  }

  static contactFormValidation(control: AbstractControl) {
    // Format the phone number to us format
    if (control && control.value && control.value.toString().length === 10) {
      const match = ('' + control.value).replace(/\D/g, '').match(/^(\d{3})(\d{3})(\d{4})$/);
      if (match) {
        control.setValue('(' + match[1] + ') ' + match[2] + '-' + match[3]);
      }
    }
  }
  /**
    * * Static method for validate delivery address
    *  @param zipcodes To define the allowed zip code details.
    *  @param locationDetails To define the city,state and country details
    */
  static localDeliveryAddressValidation(zipcodes: any[], locationDetails): ValidatorFn {
    return (control): ValidationErrors | null => {
      control = control as UntypedFormGroup;
      if (control && control.value) {
        if (control.value.zip_code && control.value.zip_code.length >= 5 &&
          zipcodes &&
          zipcodes.length > 0 &&
          zipcodes.indexOf(control.value.zip_code) == -1
        ) {
          control
            .get('zip_code')
            .setErrors({ deliveryAddressError: true });
          // return { deliveryAddressError: true };
        }
        if (control.value.city && locationDetails.city && control.value.city.toLowerCase() !== locationDetails.city.toLowerCase()) {
          control
            .get('city')
            .setErrors({ deliveryAddressError: true });
          // return { deliveryAddressError: true };
        }
        if (control.value.state && control.value.state !== locationDetails.state) {
          control
            .get('state')
            .setErrors({ deliveryAddressError: true });
          // return { deliveryAddressError: true };
        }
        if (control.value.country && control.value.country !== locationDetails.country) {
          control
            .get('country')
            .setErrors({ deliveryAddressError: true });
          // return { deliveryAddressError: true };
        }
      }
      return null;
    };
  }

  static zipCodeErrorMessage(country): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {

      if (country) {

        if (country == 'US' || country == 'United States') {
          if (control.value) {
            const match = (control.value).toString().match(/^((\d{5}-\d{4})|(\d{5})|([A-Z]\d[A-Z]\s\d[A-Z]\d))$/);
            if (!match) {
              return { USZipCode: true };
            }
          }
        } else if (country == 'IN' || country == 'India') {
          if (control.value) {
            const match = (control.value).toString().match(/^(\d{6})$/);
            if (!match) {
              return { INZipCode: true };
            }
          }
        }
      }
      return null;
    };
  }

  static deliveryAddressValidation(zipcodes: any[]): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (control && control.value && control.value.length >= 5) {
        if (
          zipcodes &&
          zipcodes.length > 0 &&
          zipcodes.indexOf(control.value) == -1
        ) {
          return { deliveryAddressError: true };
        }
      }
      return null;
    };
  }
  static pickupTimeValidation(control: AbstractControl) {
    let t = control;
    let To;
    let From;
    let ToMin;
    let FromMin;
    if (t && t.value) {


      if (t && t.value && t.value.to) {
        let indexTo = t.value.to.indexOf(':');
        To = Number(t.value.to.slice(0, indexTo));
        if (t.value.to.indexOf('PM') >= 0) {
          To = To === 12 ? To : To + 12;
        } else {
          if (To === 12) {
            To = 0;
          }
        }
        ToMin = t.value.to.slice(indexTo + 1, t.value.to.length - 2);
      }
      if (t && t.value && t.value.from) {
        let indexFrom = t.value.from.indexOf(':');
        From = Number(t.value.from.slice(0, indexFrom));
        if (t.value.from.indexOf('PM') >= 0) {
          From = From === 12 ? From : From + 12;
        } else {
          if (From === 12) {
            From = 0;
          }
        }
        FromMin = t.value.from.slice(indexFrom + 1, t.value.from.length - 2);
      }
      if (From > To) {

        control.get('to').setErrors({ ToTimeExceeds: true });
      }
      if (From === To && FromMin > ToMin) {
        control.get('to').setErrors({ ToTimeExceeds: true });
      }
      if (To === "12 AM") {
        control.get('to').setErrors({ ToTimeExceeds: true });
      }
    } else {
      return null;
    }
  }
  static tipPercentValidation(control: AbstractControl) {
    // console.log('control',control);
    let tipValue = control.value;
    // console.log('tip value',tipValue);
    let numbers = /^[0-9]+([,-][0-9]+)?$/;
    if (tipValue == '') {
      return { required: true };
    }
    else if (tipValue !== null && tipValue !== undefined) {
      let tipList = tipValue.split(',');
      if (tipList && tipList.length && tipList.length <= 3) {
        for (let i = 0; i < tipList.length; i++) {
          if ((tipList[i]).match(numbers)) {
            if ((parseInt(tipList[i])) > 100) {
              return { tipError: true };
            } else if ((parseInt(tipList[i])) < 0) {
              return { tipError: true };
            }
          } else {
            return { tipError: true };
          }

        }
        return null;
      } else {
        return { tipLengthError: true };
      }

    }
    return null;
  }
  // static deliveryCharge(form) {

  //   let deliveryArray = form.controls.deliveryChargeArray.controls;
  //   for (let i = 0; i < deliveryArray.length; i++) {
  //     if (
  //       deliveryArray[i].controls.from.value != null ||
  //       deliveryArray[i].controls.to.value != null
  //     ) {
  //       let priceFrom = deliveryArray[i].controls.from.value;
  //       let priceTo = deliveryArray[i].controls.to.value;
  //       if (priceTo <= priceFrom) {
  //         deliveryArray[i].get('to').setErrors({ deliveryPriceError: true });
  //       }
  //     }
  //     // else
  //     //   return null;
  //   }
  //   // let priceFrom = form.controls.from.value;
  //   // let priceTo =  form.controls.to.value;
  //   // if(priceTo<= priceFrom){
  //   //   return  { 'deliveryPriceError' : true };
  //   // }
  // }
  //   static deliveryCharge(form): { [key: string]: boolean }  {
  //     let deliveryArray = form.controls.deliveryChargeArray.controls;
  //     for(let i =0;i< deliveryArray.length;i++){
  //     if(deliveryArray[i].controls.from.value!=null || deliveryArray[i].controls.to.value!=null ){
  //     let priceFrom = deliveryArray[i].controls.from.value;
  //     let priceTo = deliveryArray[i].controls.to.value;
  //     if(priceTo<= priceFrom){
  //       return  { 'deliveryPriceError' : true };
  //     }
  //   } else{
  //     return null;
  //   }
  // }
  //    return null;
  //   }
  // static deliveryAddressValidation(control: AbstractControl, zipcodes) {
  //   if (control && control.value) {
  //     // Format the phone number to us format
  //     if (zipcodes) {
  //       const zipcodeArray = zipcodes.split(',');
  //       if (zipcodeArray.indexOf(control.value) === -1) {
  //         control.setErrors({ deliveryAddressError: true })
  //       }
  //     }
  //   }
  // }

}
