import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { isPlatformBrowser } from '@angular/common';
import { AffiliateAuthService } from './auth/services/auth.service';
import { Component, Inject, NgZone, PLATFORM_ID } from '@angular/core';
@Component({
  selector: 'phase-ii-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  // title = 'affiliate-marketing';
  /**
   * Variable which is used to handle the http call and make it to unsubscribe.
   * @type {Subscription}
   */
  subscriptionObj: Subscription = new Subscription();
  /**
   * Variable which is used to handle the loader.
   */
  isLoader: boolean = true;
  /**
   * To store jwt token 
   */
  token = localStorage.getItem('jwt_token');
  /**
   * Variable which is used to store the splited urls.
   */
  strings: any;
  /**
   * Variable which is used to store the url.
   */
  url!: string;
  /**
   * component constructor which is used to inject the required services.
   * @param platformId 
   * @param router To navigate to routes.
   * @param authService To access methods from auth service.
   */
  constructor(
    private router: Router,
    private ngZone: NgZone,
    private authService: AffiliateAuthService,
    @Inject(PLATFORM_ID) private platformId: object,) { }
  /**
   * Angular life cyle hook that initiates the component.
   */
  ngOnInit(): any {
    this.authService.broadCast.onmessage = (event => {
      let val = window.location.href.split(window.location.host)[1].split('/');
      if (event === "Logout" && val.indexOf('app') === 1)
        this.ngZone.run(() => {
          this.router.navigate(['/signin']);
        });
    });
    if (isPlatformBrowser(this.platformId)) {
      this.strings = window.location.href.split(window.location.host);
    }
    const commonUrl = ['/signin', '/signup', '/forgotpassword', '/changepassword'];
    this.url = this.strings[this.strings.length - 1];
    if (this.token) {
      if ((commonUrl.indexOf(this.url) === -1)) {
        this.subscriptionObj.add(this.authService.getCurrentUser().subscribe((res) => {
          if (this.url === '/') {
            if (res && res.currentUser && res.currentUser.user && res.currentUser.user.isSuperadmin === true)
              this.router.navigate(['/app/payoutrequest']);
            else
              this.router.navigate(['/app/dashboard']);
          }
          return true;
        }, (err) => {
          this.isLoader = false;
          this.router.navigate(['/signin']);
        }));
      }
    } else {
      this.isLoader = false;
      const urlstring = this.url.split('/');
      const urlsplit = this.url.split('?');
      const app = urlstring.indexOf('app');
      if (app === -1) {
        if (this.url != '/') {
          this.router.navigate([urlsplit[0]]);
          return true
        }
      }
      this.router.navigate(['/signin']);
    }
  }
  /**
   * Angular life cycle hook to destroy the component.
   * @type {void}
   */
  ngOnDestroy(): void {
    this.subscriptionObj.unsubscribe();
  }
}
