import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DialogService } from 'libs/common/src/lib/services/dialog.service';
import { SharedService } from 'libs/themes/shared-theme/src/lib/services/shared.service';
import { of, Subscription } from 'rxjs';
import { filter, mergeMap } from 'rxjs/operators';
import { ThemeConstants } from '../constants/theme.constant';
import { ThemesService } from './themes.service';
/**
 * Service used to store all product details services.
 */
@Injectable({
  providedIn: 'root'
})
export class ProductDetailsService extends ThemeConstants {
  /**
   * Variable used to store all subscription.
   * @type {Array}
   */
  subscriptionArray = new Subscription();
  /**
   * Constructor is used to inject services.
   * @param themeService used to access the methods in theme service.
   * @param snackBar used to open a snack bar.
   * @param dialogService used to access the methods in dialog service.
   * @param sharedSerivce used to access the methods in shared service.
   */
  constructor(
    private themeService: ThemesService,
    private snackBar: MatSnackBar,
    private dialogService: DialogService,
    private sharedService: SharedService) { super(); }
  /**
    * Method used to add product to the cart .
    * @param cartData used to hold the product details to be added to cart.
    */
  addToCartProductList(cartData): Promise<any> {
    return new Promise((resolve) => {
      let cartProducts, index;
      if (JSON.parse(localStorage.getItem('cart'))) {
        cartProducts = JSON.parse(localStorage.getItem('cart'));
      }
      if (cartProducts && cartProducts.cartProduct && cartProducts.cartProduct.products.length) {
        index = cartProducts.cartProduct.products.findIndex((product) => product.productId === cartData.cartDetails.cartProduct.products[0].productId);
        (index !== -1) ? (cartProducts.cartProduct.products[index] = cartData.cartDetails.cartProduct.products[0]) : cartProducts.cartProduct.products.push(cartData.cartDetails.cartProduct.products[0]);
      } else {
        cartProducts = cartData.cartDetails;
      }
      if (cartData.cartDetails.cartProduct.products[0].quantity) {
        if (cartData.customerId) {
          this.subscriptionArray.add(this.themeService.addToCartProducts(cartData, cartData.cartDetails).subscribe((res: any) => {
            if (res) {
              if (res && res.cartData && res.cartData.cartProduct && res.cartData.cartProduct[1]) {
                this.sharedService.cartCountValue.next(cartData.countValue + 1);
              }
              resolve({ cartProduct: res.cartData.cartProduct, cartModifier: res && res.cartData && res.cartData.cartModifier ? res.cartData.cartModifier : [] });
            }
          }, (err) => {
            if (err) {
              resolve(err);
            }
          }));
        } else {
          localStorage.setItem('cart', JSON.stringify(cartProducts));
          cartProducts = JSON.parse(localStorage.getItem('cart'));
          const countValue = cartProducts.cartProduct.products.length;
          this.sharedService.cartCountValue.next(countValue);
          resolve({ cartProduct: cartData.cartDetails.cartProduct, cartModifier: cartData.cartDetails.cartProduct.products[0].modifiers ? cartData.cartDetails.cartProduct.products[0].modifiers : [] });
          resolve(cartData.cartDetails);
        }
      } else {
        if (cartData && cartData.customerId && cartData.cartId) {
          this.subscriptionArray.add(this.themeService.removeCartProducts(cartData, cartData.cartDetails && cartData.cartDetails.cartProduct && cartData.cartDetails.cartProduct.products && cartData.cartDetails.cartProduct.products.length && cartData.cartDetails.cartProduct.products[0] && cartData.cartDetails.cartProduct.products[0].productId).subscribe((res: any) => {
            if (res) {
              this.subscriptionArray.add(this.themeService.getVerifiedCartProducts(cartData.customerId, { isProductDetails: true }, this.sharedService.storeId).subscribe((response: any) => {
                if (response && response.cartProducts) {
                  if (cartData.countValue !== response.cartProducts.cartProducts.length) {
                    this.sharedService.cartCountValue.next(response.cartProducts.cartProducts.length);
                  }
                }
              }));
              resolve(cartData.cartDetails);
            }
          }, (err) => {
            if (err) {
              resolve(err);
            }
          }));
        } else {
          cartProducts.cartProduct.products.splice(index, 1);
          if (cartProducts.cartProduct.products.length) {
            localStorage.setItem('cart', JSON.stringify(cartProducts));
          }
          else {
            localStorage.removeItem('cart');
          }
          cartProducts = JSON.parse(localStorage.getItem('cart'));
          const countValue = cartProducts && cartProducts.cartProduct && cartProducts.cartProduct.products && cartProducts.cartProduct.products.length ? cartProducts.cartProduct.products.length : 0;
          this.sharedService.cartCountValue.next(countValue);
          resolve(cartData.cartDetails);
        }
      }
    });
  }
  /**
   * Method used to add a product to wishlist.
   * @param data used to hold customerId and storeId.
   * @param event used to hold the details of product.
   */
  addToWishlist(data, event) {
    if (event.isAddedToFav) {
      event.data.wishlistName = 'wishlist' + data.customerId;
      this.subscriptionArray.add(this.themeService.addToWishlist(data, event.data).subscribe((res: any) => {
        this.openSnackBar('Product Added to Wish list Successfully');
      }));
    }
    else {
      this.subscriptionArray.add(this.themeService.removeFromWishlist(data && data.storeId, event.data.wishlistItems).subscribe((res: any) => {
        this.openSnackBar('Product Removed from Wish list Successfully');
      }));
    }
    return true;
  }
  /**
  * Method used to Open snack Bar.
  * @param message message to display in snack bar.
  */
  openSnackBar(message) {
    this.snackBar.open(message, 'X', { duration: 3000 });
  }
  /**
   * Method which is used to get new review data from child component.
   * @param data To get review data.
   * @param productId holds the product id.
  */
  createReview(storeId, data, productId) {
    return new Promise((resolve, reject) => {
      this.subscriptionArray.add(this.themeService.createReview(storeId, productId, { reviewData: data }).subscribe(res => {
        if (res && res['reviewData']) {
          resolve(res['reviewData']);
        }
      }, (err) => {
        if (err) {
          reject(err);
        }
      }));
    })
  }
  /**
   * Method used to dislike some review
   * @param tabSectionDetails defines the tab section details
   * @param reviewId defines the review id 
   */
  dislikeClicked(tabSectionDetails, reviewId) {
    return new Promise((resolve, reject) => {
      this.subscriptionArray.add(this.themeService.dislikeClick(this.sharedService.storeId, reviewId).subscribe(res => {
        if (res && res['unLikesData']) {
          const index = tabSectionDetails.productReviews.findIndex(review => review.id === reviewId);
          if (index >= 0) {
            tabSectionDetails.productReviews[index].likes = { users: res['unLikesData'].likesArray };
            tabSectionDetails.productReviews[index].disLikes = { users: res['unLikesData'].disLikesArray };
            resolve(tabSectionDetails);
          }
        }
      }, err => {
        if (err) {
          reject(err);
        }
      }));
    });
  }
  /**
  * Method used to get the products based on category
  * @param storeId holds the store id.
  * @param queryParams holds the selected category details.
  */
  getProductsByCategory(storeId, queryParams, isStoreEditor?) {
    let productData, cartId, productCount, zones;
    let param = {
      offset: queryParams.offset,
      limit: queryParams.limit,
      categoryId:queryParams.categoryId ? queryParams.categoryId : null
    }
    if (isStoreEditor) {
      return this.themeService.getProductsByStore(storeId, param).pipe(filter((res: any) => {
        if (res && res['products']) {
          productCount = res['products']?.length;
          productData = res['products'];
        }
        return true;
      }), mergeMap(() => {
        return of({
          productData: productData,
          productCount: productCount,
        })
      }));
    }
    else {
      return this.themeService.getCategoryProducts(storeId, queryParams).pipe(filter((res: any) => {
        if (res && res['products']) {
          zones = res['zones'];
          productCount = res['products'].length;
          productData = res['products'];
          productData.map(value => value.quantity = 0);
          const localStorageProducts = JSON.parse(localStorage.getItem('cart'));
          if (localStorageProducts && localStorageProducts.cartProduct) {
            localStorageProducts.cartProduct.products.map((localStorageProduct) => {
              if (localStorageProduct && localStorageProduct.productId && !localStorageProduct.discountProductId && !localStorageProduct.fbtId) {
                const index = productData.findIndex((product) => product && product.id === localStorageProduct.productId);
                if (index >= 0){
                  productData[index].quantity = localStorageProduct.quantity;
                  productData[index]['cartProductId'] = localStorageProduct.id ?? null;
                }
              }
            });
          }
        }
        return true;
      }), mergeMap(() => {
        if (queryParams && queryParams.customerId) {
          return this.themeService.getVerifiedCartProducts(queryParams.customerId, { locationId: queryParams.locationId, isProductDetails: true }, storeId);
        } else {
          return of(true);
        }
      }), mergeMap((res) => {
        if (res && res['cartProducts'] && res['cartProducts'].cartProducts.length) {
          let cartProducts;
          cartProducts = res['cartProducts'].cartProducts;
          cartId = cartProducts[0].cartId;
          cartProducts.map((cart) => {
            if (cart && !cart.discountProductId && !cart.fbtId) {
              productData.find((product) => {
                if (product && cart.productId === product.id) {
                  product.quantity = cart.quantity;
                  product.cartProductId = cart.id;
                }
              });
            }
          });
        }
        return of({
          productData: productData,
          productCount: productCount,
          cartId: cartId,
          zones
        });
      }));
    }
  }
  /**
   * Method used to get all categories.
   */
  getCategories(locationId, timeZone?, storeId?) {
    return new Promise((resolve, reject) => {
      this.subscriptionArray.add(this.sharedService.getAllCategories(storeId ? storeId : this.sharedService.storeId,
        locationId, timeZone).subscribe((res) => {
          if (res && res['category']) {
            resolve({
              categoryList: res['category'],
              categoryIds: res['categoryIds'],
              menuCategoryAvailability: res['menuCategoryAvailability']
            });
          }
        }, (err) => {
          if (err) {
            // this.dialogService.openDialog({
            //   header: this.dialogMessages.failureHeader,
            //   message: this.dialogMessages.errorMessage,
            //   actionType: this.dialogType.failure,
            //   button: { right: this.dialogMessages.okButtonText }
            // });
            this.dialogService.openSnackBar(this.dialogMessages && this.dialogMessages.errorMessage);
            reject(err);
          }
        }));
    });
  }
  /**
   * Method is used to add a product to wait list.
   */
  addToWaitList(waitListDetails) {
    if (waitListDetails) {
      return this.subscriptionArray.add(this.themeService.createWaitList(this.sharedService.storeId, waitListDetails).subscribe((response: any) => {
        if (response && response.result && response.result.length) {
          if (response.result && !response.result[1]) {
            this.openSnackBar(this.notifyMessage.waitListUpdated);
          } else {
            this.openSnackBar(this.notifyMessage.waitListCreated);
          }
        }
        return true;
      }, (err) => {
        if (err) {
          this.dialogService.openDialog({
            header: this.dialogMessages.failureHeader,
            message: this.dialogMessages.errorMessage,
            actionType: this.dialogType.failure,
            button: { right: this.dialogMessages.okButtonText }
          });
          return true;
        }
      }));
    }

  }
  /**
 * Method used to like some review
 * @param tabSectionDetails defines the tab section details
 * @param reviewId defines the review id 
 */
  likeClicked(tabSectionDetails, reviewId) {
    return new Promise((resolve, reject) => {
      this.subscriptionArray.add(this.themeService.likeClick(this.sharedService.storeId, reviewId).subscribe(res => {
        if (res && res['likesData']) {
          const index = tabSectionDetails.productReviews.findIndex(review => review.id === reviewId);
          if (index >= 0) {
            tabSectionDetails.productReviews[index].likes = { users: res['likesData'].likesArray };
            tabSectionDetails.productReviews[index].disLikes = { users: res['likesData'].disLikesArray };
            resolve(tabSectionDetails);
          }
        }
      }, likeErr => {
        if (likeErr) {
          reject(likeErr);
        }
      }));
    });
  }
}
